import { Box, Text, useTheme } from '@razorpay/blade/components';
import React, { useMemo, useRef, useState } from 'react';
import { ChatContextType, useChatContext } from '../ChatContext';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {getRegimeBreakupCalculation} from './RegimeBreakup/Calculation';
import {getTaxBreakupCalculation} from './TaxSavingBreakup/Calculation';
import { useChatFeedback } from '../useChatFeedback';
import EmployeeShare from './Share/EmployeeShare';
import EmployerShare from './Share/EmployerShare';

const UserTextInputContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    padding: ${theme.spacing[6]}px ${theme.spacing[4]}px;
    background: linear-gradient(rgba(8,13,14,0),rgba(11,17,47, 0.4853),rgba(15,21,54,1));

    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing[6]}px ${theme.spacing[8]}px;
    }
`,
);

const StyledInput = styled.textarea<{ disabled?: boolean; height?: string }>(
  ({ theme, disabled, height }) => `
  width: 100%;
  padding: ${theme.spacing[4]}px ${theme.spacing[10]}px ${theme.spacing[4]}px ${theme.spacing[5]}px;
  border: ${theme.border.width.thin}px solid ${theme.colors.surface.border.gray.subtle};
  border-radius: ${theme.border.radius.large}px;
  background: ${
    disabled ? 'transparent' : 'linear-gradient(91deg, rgba(29, 35, 69, 0.9) 0%, #163456 104.41%)'
  };
  caret-color: ${theme.colors.interactive.icon.gray.normal};
  color: ${theme.colors.interactive.text.gray.normal};
  cursor: ${disabled ? 'not-allowed' : 'text'};
  resize: none;
  height: ${height ? `${height}px` : 'auto'};
`,
);

const InputSuggestionContainer = styled.div(
  ({ theme }) => `
    margin-bottom: ${theme.spacing[4]}px;
    overflow-x: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
`,
);

const InputSuggestion = styled.button<{ disabled?: boolean, highlighted?: boolean }>(
  ({ theme, disabled, highlighted }) => `
    padding: ${theme.spacing[3]}px ${theme.spacing[6]}px;
    border: none;
    border-radius: ${theme.border.radius.large}px;
    background-color: ${highlighted ? "rgba(21, 102, 241, 0.00)" :"rgb(209, 210, 218, 0.09)"};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    ${highlighted ? "border: 1px solid #5CA2F7;" : ""}
`,
);

interface SubmitIconProps {
  onClick: () => void;
  variant?: 'default' | 'disabled' | 'active';
  isDisabled?: boolean;
}

const IconButton = styled.button<{ disabled?: boolean }>(
  ({ theme, disabled }) => `
    background: none;
    border: none;
    padding: ${theme.spacing[2]}px;
    margin: 0;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
`,
);

const SubmitIconButton = ({ variant = 'default', isDisabled, onClick }: SubmitIconProps) => {
  const { theme } = useTheme();
  const iconColor = useMemo(() => {
    if (variant === 'active') {
      return theme.colors.feedback.icon.information.intense;
    }

    if (variant === 'disabled' || isDisabled) {
      return theme.colors.interactive.icon.gray.disabled;
    }

    if (variant === 'default') {
      return theme.colors.interactive.icon.gray.normal;
    }
  }, [variant]);

  return (
    <IconButton onClick={onClick} disabled={isDisabled}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none">
        <path
          d="M18.623 8.28983L2.91071 0.701883C2.15746 0.337191 1.22767 0.466598 0.603887 1.0901C0.00364332 1.69008 -0.125821 2.52534 0.133107 3.33708L2.15746 9.91329H18.2581C18.5877 9.91329 18.8466 10.1721 18.8466 10.5015C18.8466 10.8309 18.5877 11.0897 18.2581 11.0897H2.14569L0.121338 17.6659C-0.125821 18.4777 -0.00812643 19.3129 0.592117 19.9129C1.20413 20.5246 2.13392 20.6658 2.89894 20.3011L18.6112 12.7249C19.4586 12.3132 20 11.4426 20 10.5133C20 9.58389 19.4586 8.70157 18.6112 8.30159L18.623 8.28983Z"
          fill={iconColor ?? ''}
        />
      </svg>
    </IconButton>
  );
};

const UserTextInput = () => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [textInput, setTextInput] = useState('');
  const {
    pushMessage,
    inputSuggestions,
    isBotProcessing,
    setCaptcha,
    chatId,
    conversationCount,
    setInputSuggestions,
    pollData,
    isFeedbackPhase,
    isEmployer
  } = useChatContext() as ChatContextType;
  const formRef = useRef<HTMLFormElement>(null);
  const isLoading = isBotProcessing;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { sendFeedback } = useChatFeedback(() => setInputSuggestions(["Share with your team"]));
  const [isShareOpen, setShareOpen] = useState(false);

  const submitIconVariant = useMemo<SubmitIconProps['variant']>(() => {
    if (isLoading) {
      return 'disabled';
    }

    if (textInput) {
      return 'active';
    }

    if (!textInput) {
      return 'default';
    }
  }, [textInput, isLoading]);

  const pushInput = () => {
    pushMessage({
      type: 'text',
      initiator: 'user',
      messageData: textInput.trim(),
    });

    setTextInput('');
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
    }

    if (recaptchaRef.current && !chatId) {
      recaptchaRef.current.reset();
    }
  };
  const submitMessage = () => {
    if (textInput.trim()) {
      if (recaptchaRef.current && !chatId) {
        recaptchaRef.current.execute();
      } else {
        pushInput();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    submitMessage();
  };

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setTextInput(e.currentTarget.value);

    if (textAreaRef.current) {
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${Math.min(scrollHeight, 150)}px`;
    }
  };

  const handleInvisibleCaptchaChange = (value: string | null) => {
    setCaptcha(value);
    pushInput();
  };

  const showInputSuggestions = !!inputSuggestions?.length && inputSuggestions.length > 0;

  const getCalculationComponent = (showFrom: string) => {
    return conversationCount === 1 ? (
      getRegimeBreakupCalculation(pollData)
    ) : (
      getTaxBreakupCalculation(pollData, showFrom)
    );
  };

  const updateFeedback = (feedback: string) => {
    pushMessage(
      {
        type: 'text',
        initiator: 'user',
        messageData: feedback,
      },
      () => {
        setInputSuggestions(["Share with your team"]);
        sendFeedback.mutate({chatId, feedback})
        pushMessage({
          type: 'text',
          initiator: 'bot',
          messageData: "Thanks for your feedback!",
        });
      },
    );
  }

  const suggestionHandler = (suggestion: string) => {
    switch (suggestion) {
      case 'Show calculation':
      case 'Show calculation (suggested)':
        pushMessage(
          {
            type: 'text',
            initiator: 'user',
            messageData: suggestion,
          },
          () => {
            if (conversationCount === 1) {
              setInputSuggestions(['Yes, continue to save tax']);
            } else {
              setInputSuggestions([
                suggestion.includes('suggested')
                  ? 'Show options in other regime'
                  : 'Show options in suggested regime',
              ]);
            }

            pushMessage({
              type: 'calculation',
              initiator: 'bot',
              messageData: getCalculationComponent(
                suggestion.includes('suggested') ? 'suggested' : 'other',
              ),
            });
          },
        );
        break;
      case 'Show options in other regime':
        pushMessage(
          {
            type: 'text',
            initiator: 'user',
            messageData: suggestion,
          },
          () => {
            setInputSuggestions(['Show options in suggested regime', 'Show calculation']);
            pushMessage({
              type: 'taxBreakup',
              initiator: 'bot',
              messageData: {
                data: pollData?.response,
                showSuggested: false,
              },
            });
          },
        );
        break;
      case 'Show options in suggested regime':
        pushMessage(
          {
            type: 'text',
            initiator: 'user',
            messageData: suggestion,
          },
          () => {
            setInputSuggestions(['Show options in other regime', 'Show calculation (suggested)']);
            pushMessage({
              type: 'taxBreakup',
              initiator: 'bot',
              messageData: {
                data: pollData?.response,
                showSuggested: true,
              },
            });
          },
        );
        break;
      case '😍 Awesome!':
      case '🙂 It was okay':
      case '😡 Awful':
        const feedback: string = suggestion;
        updateFeedback(feedback);
        break;
      case 'Share with your team':
        setShareOpen(true);
        break;
      default:
        pushMessage({
          type: 'text',
          initiator: 'user',
          messageData: suggestion,
        });
    }
  };

  return (
    <UserTextInputContainer>
      {showInputSuggestions && (
        <InputSuggestionContainer>
          <Box width="max-content" display="flex" gap="spacing.4">
            {inputSuggestions?.map((suggestion) => {
              const isHighlighted = suggestion.includes('Share with your team');
              return (
                <InputSuggestion
                  disabled={isLoading}
                  onClick={() => suggestionHandler(suggestion)}
                  highlighted={isHighlighted}>
                  <Text
                    color={
                      isHighlighted
                        ? 'interactive.text.primary.normal'
                        : 'interactive.text.gray.normal'
                    }>
                    {suggestion}
                  </Text>
                </InputSuggestion>
              );
            })}
          </Box>
        </InputSuggestionContainer>
      )}
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        style={{
          width: '100%',
          position: 'relative',
        }}>
        {!chatId && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdeU2MpAAAAABG1gZpQgzk5B5nhsp_mCwJGcMY7"
            onChange={handleInvisibleCaptchaChange}
            size="invisible"
          />
        )}
        <StyledInput
          value={textInput}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          name="textInput"
          placeholder="Type your response"
          disabled={isLoading || isFeedbackPhase}
          ref={textAreaRef}
          rows={1}
          maxLength={!chatId ? 40 : 700}
        />
        <Box position="absolute" right="spacing.5" top="50%" transform="translateY(-50%)">
          <SubmitIconButton
            isDisabled={isLoading || isFeedbackPhase}
            onClick={submitMessage}
            variant={submitIconVariant}
          />
        </Box>
      </form>
      <Text
        size="small"
        color="surface.text.gray.disabled"
        marginLeft={'spacing.5'}
        marginTop={'spacing.1'}>
        As an experimental AI tool, Ray may make mistakes, so double-check its responses. It also
        doesn’t collect and share personal data with other AI models. Don't share any personal information in any response.
      </Text>
      {isEmployer ? (
        <EmployerShare isOpen={isShareOpen} onClose={() => setShareOpen(false)} chatId={chatId} />
      ) : (
        <EmployeeShare isOpen={isShareOpen} onClose={() => setShareOpen(false)} />
      )}
    </UserTextInputContainer>
  );
};

export { UserTextInput };
