import isUnacademy from 'utils/isUnacademy';

export const getFeatureList = (organizationID: number): Array<string> => {
  if (isUnacademy(organizationID)) {
    return [
      'Reimbursements: Apply, approve and stay ahead of your requests',
      'View holidays',
      'and more features coming soon',
    ];
  } else {
    return [
      'Leaves: Apply, approve and get notified of leave status',
      'Attendance: Check in and check out to mark attendance',
      'Check Leaves: Check your leave balance and see who’s on leave from your team',
      'Reimbursements: Apply, approve and stay ahead of your requests',
      'View payslips, holidays and get reminded on upcoming leaves',
      'and more features coming soon',
    ];
  }
};
