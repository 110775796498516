import React, {FormEvent, useState} from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import JibbleAttendanceDataSchema from "../../../../../../schemas/JibbleAttendanceDataSchema";
import {Input, InputElement} from "../../../../../ui/Form/Input";
import styles from "./index.module.scss";
import {StandardButton} from "../../../../../ui/Button";
import api from "../../../../../../api";
import {format} from "date-fns";
import {useMutation} from "react-query";
import APIError from "../../../../../../types/apiError";
import {UpdateLopDaysProps} from "../../../../../../api/jibbleSettings";
import {Card} from "../../../../../ui/Card";
import {WarningAlert} from "../../../../../ui/Alert";
import { useQuery } from 'react-query';
import { FETCH_LOP_AMOUNT } from 'components/RunPayroll/utils/queries';
import { AppError, getAppErrorDetails } from 'utils/AppError';

export interface EditAttendanceLopModalProps {
    individualData: typeof JibbleAttendanceDataSchema['attendance_data']['data'];
    onCloseModal: () => void;
    onSubmitModal: () => void;
}

const EditAttendanceLopModal = ({
    individualData,
    onCloseModal,
    onSubmitModal
}: EditAttendanceLopModalProps) => {
    const oldLopDays = individualData.attendance_data.lopDays;
    const totalWorkingDays = individualData.attendance_data.totalWorkingDays;
    const [newLopDays, setNewLopDays] = useState(individualData.attendance_data.lopDays);

    const updateLopMutation = useMutation((requestData: UpdateLopDaysProps) =>
        api.jibbleSettings.updateAttendanceLopDays(requestData), {
        onSuccess: () => {
            onSubmitModal();
        },
        onError: (error: APIError) => {
            onCloseModal();
        },
    });

    const getLOPAmount = useQuery(
        [FETCH_LOP_AMOUNT,individualData.payroll_staging_id],
        () => {
          if (!individualData.payroll_staging_id) {
            return null;
          }
          return api.runPayroll.getLOPAmount(individualData.payroll_staging_id, newLopDays);
        }
      );

    const lopAmount = getLOPAmount.data?.lop_amount;

    const isInputValid = (value: number) => {
        return value <= totalWorkingDays;
    }

    const onLopDaysChange = (value: string) => {
        const lopDays = value === '' ? 0 :parseFloat(value);
        setNewLopDays(lopDays);
    }

    const onFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (oldLopDays !== newLopDays && isInputValid(newLopDays)) {
            const payrollMonth = format(new Date(Date.parse(individualData.payroll_month)), 'yyyy-MM-dd');
            updateLopMutation.mutate({
                organization_id: individualData.organization_id,
                people_id: individualData.people_id,
                payroll_month: payrollMonth,
                old_lop_days: oldLopDays,
                new_lop_days: newLopDays
            })
        } else {
            onCloseModal();
        }
    }

    return (
        <Modal>
            <form className={styles['editLopModal']} onSubmit={(event) => onFormSubmit(event)}>
                <h1 className={styles['modalHeader']}>Edit loss of pay days</h1>
                <InputElement required label="Loss of pay days" className="mt-4 mb-10">
                    <Input
                        id={`lop-${individualData.people_id}`}
                        required
                        type="number"
                        step="0.25"
                        value={newLopDays}
                        className={styles['lopInput']}
                        onChange={(e) => onLopDaysChange(e.target.value)}
                        onBlur={(event) => {getLOPAmount.refetch()}}
                        autoFocus={true}
                        disabled={getLOPAmount.isLoading || getLOPAmount.isRefetching}
                    />
                </InputElement>
                {  isInputValid(newLopDays) && (getLOPAmount.isLoading || getLOPAmount.isRefetching) &&
                    <Card className={styles['amountCard']}>
                        👉 Calculating deduction amount...
                    </Card>
                }
                {  isInputValid(newLopDays) && !getLOPAmount.isError && 
                   !(getLOPAmount.isLoading || getLOPAmount.isRefetching) &&
                    <Card className={styles['amountCard']}>
                        👉 Deduction amount: {lopAmount?.toLocaleString()}
                    </Card>
                }
                { !isInputValid(newLopDays) &&
                    <WarningAlert className={styles['warning']}>
                        LOP days cannot be more than total working days ({totalWorkingDays})
                    </WarningAlert>
                }
                {  getLOPAmount.isError && isInputValid(newLopDays) &&
                    <WarningAlert className={styles['warning']}>
                        {getAppErrorDetails(getLOPAmount.error).messageForUser}
                    </WarningAlert>
                }
                <StandardButton
                    type="submit"
                    className={styles['saveButton']}
                    disabled={!isInputValid(newLopDays)}
                    isPending={updateLopMutation.isLoading}
                >
                    Save
                </StandardButton>
                <StandardButton
                    className={styles['closeButton']}
                    onClick={() => onCloseModal()}
                    disabled={updateLopMutation.isLoading}
                >
                    Close
                </StandardButton>
            </form>
        </Modal>
    )
}

export default EditAttendanceLopModal;