import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { FeatureFlags } from 'types';

type FeatureReturnType<T extends FeatureFlags> = {
  [K in T as `is${Capitalize<string & K>}Enabled`]: boolean;
};

const useFeature = <T extends FeatureFlags>(feature: T): FeatureReturnType<T> => {
  const features = useSelector(loggedInUserSelectors.features);
  const isFeatureEnabled = Boolean(features?.[feature]);
  return {
    [`is${feature.charAt(0).toUpperCase() + feature.slice(1)}Enabled`]: isFeatureEnabled,
  } as FeatureReturnType<T>;
};

export default useFeature;
