import React, { ReactElement} from 'react';
import { PfRegistrationSchemaContract } from 'schemas/PfRegistrationSchema';
import { TableWithLoader } from 'components/ui/Table';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import MailIcon from '../MailIcon';
import ButtonWrapper from './ButtonWrapper';

import {
  ActionType,
  INITIATE,
  UPDATE,
  RETRY,
  UPDATE_CRED,
  FAILED,
  NOT_INITIATED,
  DISABLED,
  OrgType,
} from '../contants';
import classes from '../../EsicRegistrationDashboard/index.module.scss';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const statusComponentMapping = {
  DISABLED: () => <Pill type={pillClassTypeMap.warning} content="Disabled" />,
  REGISTERED: () => <Pill type={pillClassTypeMap.success} content="Success" />,
  IN_PROGRESS: () => <Pill type={pillClassTypeMap.neutral} content="In Progress" />,
  FAILED: () => <Pill type={pillClassTypeMap.danger} content="Failed" />,
  NOT_INITIATED: () => <Pill type={pillClassTypeMap.default} content="Not Initiated" />,
};

function createMailtoLink(data: PfRegistrationSchemaContract['data'], orgName: OrgType) {
  const subject = encodeURIComponent(`PF Registration Failure | ${orgName}`);
  const employeeDetails = data
    .filter((employee: any) => employee.registration_status === FAILED)
    .map((employee: any, index: any) => `${employee.name} ( ${employee.employee_id_prefix} )`)
    .join('\n');
  const body = encodeURIComponent(
    `Hi Team XPayroll,\n\n` +
      `We are facing an issue PF registration for the following set of employees for ${orgName}.\n\n` +
      `List of employees - \n\n${employeeDetails}\n\n` +
      `Please help us resolve this issue.\n` +
      `Thank you.`,
  );
  return `mailto:xpayroll@razorpay.com?subject=${subject}&body=${body}`;
}

function TableView({
  data,
  isLoading,
  handleButtonClick,
  orgName,
}: {
  data: PfRegistrationSchemaContract['data'];
  isLoading: boolean;
  handleButtonClick: (people_id: number, action: ActionType) => void;
  orgName: OrgType;
}): ReactElement {

  const disablePfRegistrationFlag = useSelector(loggedInUserSelectors.disablePfRegistrationFlag);

  return (
    <TableWithLoader
      isLoading={isLoading}
      showAlternateColors={true}
      className={`clear-both mt-8 export w-full ${classes['esic_registration']}`}>
      <thead>
        <tr>
          <th>Employee ID</th>
          <th>Name</th>
          <th>Registration Status</th>
          <th>Remarks</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          
          <tr key={index}>
            <td>
              <a href={`/employeeDetail?userId=${row.people_id}`}>{row.employee_id_prefix}</a>
            </td>
            <td>
              <a href={`/employeeDetail?userId=${row.people_id}`}>{row.name}</a>
            </td>
            <td>
              {statusComponentMapping[row.registration_status]
                ? statusComponentMapping[row.registration_status]()
                : null}
            </td>
            <td>{row.registration_status === FAILED && row.friendly_error}</td>
            <td>
              {row.registration_status === NOT_INITIATED && (
                <ButtonWrapper
                  onClick={() => handleButtonClick(row.people_id, INITIATE)}
                  disabled={ disablePfRegistrationFlag ?? false }
                >
                  START REGISTRATION
                </ButtonWrapper>
              )}
              {row.registration_status === DISABLED && (
                <ButtonWrapper
                  onClick={() => handleButtonClick(row.people_id, DISABLED)}
                  disabled={false}
                >
                  ENABLE PF
                </ButtonWrapper>
              )}
              {row.registration_status === FAILED && (
                <>
                  {(() => {
                    switch (row.error_category) {
                      case 'update_retry_registration':
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, UPDATE)}
                            disabled={disablePfRegistrationFlag ?? false}
                          >
                            UPDATE INFORMATION
                          </ButtonWrapper>
                        );
                      case 'invalid_cred':
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, UPDATE_CRED)}
                            disabled={false}
                          >
                            UPDATE
                          </ButtonWrapper>
                        );
                        case 'contact_xpayroll_support_or_retry':
                        const mailto = createMailtoLink(data, orgName);
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <ButtonWrapper onClick={() => window.open(mailto, '_blank')} disabled={false}>
                              <MailIcon className={`${classes['mail-icon']} ${classes['blue-fill']}`} />
                            </ButtonWrapper>
                            <div style={{ width: '10px' }}></div>
                            <ButtonWrapper onClick={() => handleButtonClick(row.people_id, RETRY)} disabled={false}>
                              RETRY REGISTRATION
                            </ButtonWrapper>
                          </div>
                        );                           
                      case 'contact_xpayroll_support':
                        const mailtoLink = createMailtoLink(data, orgName);
                        return (
                          <ButtonWrapper
                            onClick={() => window.open(mailtoLink, '_blank')}
                            disabled={false}
                          >
                            <MailIcon className={`${classes['mail-icon']} ${classes['blue-fill']}`} />
                          </ButtonWrapper>
                        );
                      default:
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, RETRY)}
                            disabled={disablePfRegistrationFlag ?? false}
                          >
                            RETRY REGISTRATION
                          </ButtonWrapper>
                        );
                    }
                  })()}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </TableWithLoader>
  );
}

export default TableView;
