import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainContent } from './components/MainContent';
import {
    Box,
    Button,
    Heading,
    Link,
    ArrowRightIcon,
    Spinner,
    Alert,
    FilePlusIcon,
    Text,
} from '@razorpay/blade/components';
import { OnboardingLayout, Sidebar } from '../../components/ui/OnboardingLayout';
import { routePaths } from '../Routes/data';
import { HeaderContent, getDefaultFormProps, FormProps, bonusSource, BonusStatus, bonusStatus } from './types';
import CreateBonusForm from './components/CreateBonusForm';
import api from '../../api/index';
import { useQuery, useMutation } from 'react-query';
import { AppError, getAppErrorDetails } from '../../utils/AppError';
import { useHistory } from 'react-router-dom';
import { ValidatePage, headerContent, updateFormProps, useShouldFetchUserData, validateFormVal } from './utils';
import { EditBonusRequestSchemaContract } from '../../schemas/EditBonusRequestSchema';
import Errors from 'components/ui/Form/Errors';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import permissions from 'constants/permissions';
import { showToastViaEvent } from 'utils/ToastEvents';

export default function EditBonus(): ReactElement {

    const loggedInUserName = useSelector(loggedInUserSelectors.name);
    const permission = useSelector(loggedInUserSelectors.permissions);
    const [formProps, setFormProps] = useState<FormProps>(() => getDefaultFormProps());
    const [stepCount, setStepCount] = useState<number>(1);
    const [userName, setUserName] = useState<string | null>(loggedInUserName)
    const pathname = useLocation().pathname;
    const history = useHistory();
    const queryParam = new URLSearchParams(useLocation().search);
    const userId = queryParam.get('userId');
    const bonusId = queryParam.get('id');
    let shouldFetchUserData = useShouldFetchUserData(userId);
    let canEdit = permission.includes(permissions.BONUS_EDIT);


    if (!ValidatePage() || !canEdit) {
        history.push(routePaths.unknown);
    }

    const getBonusTypes = useQuery('GET_ENABLED_BONUS_TYPES', () => {
      return api.bonus.enabledBonusTypes();
    });

    const userDataQuery = useQuery('GET_USER_DATA', () => {

        if (userId && shouldFetchUserData) {
            return api.people.fetchUserData(parseInt(userId));
        }

    }, {
        onSuccess: (data) => {
            setUserName(data?.name ?? null);
        },
        onError: (error: typeof AppError) => { },
        enabled: shouldFetchUserData
    });

    const fetchBonusesQuery = useQuery('FETCH_BONUS_DATA', () => {
        if (userId && bonusId) {
            return api.bonus.fetchBonuses({ people_id: parseInt(userId), bonus_id: parseInt(bonusId) });
        }
    }, {
        onSuccess: (data) => {
            if (data) {
                setFormProps(() => updateFormProps(data));

                if (data.data[0].status?.toString() === bonusStatus.BONUS_STATUS_PAID && data.data[0].source === bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL) {
                    setStepCount(2);
                }
            }
        },
        onError: (error: typeof AppError) => { }
    });

    let bonusData = fetchBonusesQuery.data?.data[0];

    const editBonusMutation = useMutation(
        ({ bonusId, requestData }: { bonusId: number, requestData: EditBonusRequestSchemaContract }) => {
            return api.bonus.editBonus(bonusId, requestData);
        },
        {
            onSuccess: (data) => {
                history.push(`${routePaths.bonus.view}?userId=${userId}`);
                showToastViaEvent({
                    text: `${data.data[0].bonus_name} was modified successfully!`,
                    timeout: 5000,
                    type: 'success',
                    icon: 'success',
                });
            },
            onError: (error: typeof AppError) => { }
        },
    );

    const updateBonus = () => {
        if (userId && bonusId) {
            let requestData: EditBonusRequestSchemaContract;
            requestData = {
                organization_bonus_setting_id: formProps.organization_bonus_setting_id ?? undefined,
                amount: formProps.amount ?? undefined,
                source: formProps.source ?? undefined,
                payout_month: formProps.payout_month ? (new Date(formProps.payout_month)).toISOString().slice(0, 10) : undefined,
                tds_recovery_option: formProps.tds_recovery_option ?? undefined,
                part_of_ctc: formProps.part_of_ctc ?? undefined,
                clawback_applicable: formProps.clawback_applicable ?? undefined,
                clawback_period: formProps.clawback_period ?? undefined,
                remarks: formProps.remarks ?? undefined
            }

            editBonusMutation.mutate({ bonusId: parseInt(bonusId), requestData: requestData });

        }
    }

    if (userDataQuery.isLoading || getBonusTypes.isLoading) {
        return <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
        </Box>
    }

    return (
        (<OnboardingLayout>
            <Sidebar
                title={userDataQuery.isLoading ? 'Manage Bonus' : `Manage Bonus of ${userName}`}
                hideBackButton={false}
                backButtonTitle='BACK TO VIEW BONUSES SCREEN'
                onBackClick={() => {
                    history.push(`${routePaths.bonus.view}?userId=${userId}`);
                }}
                progress={headerContent(pathname, stepCount).progress}
            >

                <Box display="flex" flexDirection="column" gap="spacing.6" marginTop={'spacing.3'}>
                    <Text size="large">Helpful Links</Text>
                    <Link size="medium" variant="anchor" href={undefined}>
                        How to create a bonus for an employee?
                    </Link>
                </Box>

            </Sidebar>
            <MainContent
                title={<Box display={'flex'} flexDirection={'row'}>{headerContent(pathname, stepCount).titleIcon}{headerContent(pathname, stepCount).title}</Box>}
                hideButton={true}
                description={headerContent(pathname, stepCount).description}
            >

                <Box display={'flex'} flexDirection={'column'} gap={'spacing.8'}>
                    {shouldFetchUserData && userDataQuery.isError &&
                        <Alert title={getAppErrorDetails(userDataQuery.error).code} description={<Errors errorResponse={userDataQuery.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                    }
                    {editBonusMutation.isError &&
                        <Alert title={getAppErrorDetails(editBonusMutation.error).code} description={<Errors errorResponse={editBonusMutation.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                    }
                    {fetchBonusesQuery.isError &&
                        <Alert title={getAppErrorDetails(fetchBonusesQuery.error).code} description={<Errors errorResponse={fetchBonusesQuery.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                    }

                    {fetchBonusesQuery.isLoading ?
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            height={'100%'}
                            width={'100%'}>
                            <Spinner accessibilityLabel="loading" />
                        </Box> :
                        <>
                            {bonusData?.status?.toString() === bonusStatus.BONUS_STATUS_PAID  && bonusData?.source === bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL &&
                                <Alert
                                    description={<>Bonus is already paid out. You can now only edit the clawback rules. To view details about this bonus go to <Link
                                        size='small'
                                        onClick={() => { window.location.href = routePaths.reports.bonusReport; }}> bonus report</Link></>}
                                    color="information"
                                />
                            }
                            <CreateBonusForm stepCount={stepCount} formProps={formProps} setFormProps={setFormProps} bonusTypes={getBonusTypes.data?.types ?? []} />
                            <Box alignContent={'left'} marginBottom={'spacing.7'}>
                                {stepCount === 1 &&
                                    <Box width={'212px'}>
                                        <Button isDisabled={!validateFormVal('organization_bonus_setting_id',formProps) || !validateFormVal('amount',formProps)} variant='primary' icon={ArrowRightIcon} iconPosition='right' isFullWidth={true} onClick={() => { setStepCount(2); }}>Next</Button>
                                    </Box>
                                }

                                {
                                    stepCount === 2 &&
                                    <Box display={'flex'} flexDirection={'row'} gap={'spacing.4'}>
                                        {!(bonusData?.status?.toString() === bonusStatus.BONUS_STATUS_PAID && bonusData?.source === bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL) &&
                                            <Box width={'212px'}>
                                                <Button variant='secondary' isFullWidth={true} onClick={() => { setStepCount(1); }}>Back: Edit Bonus</Button>
                                            </Box>
                                        }
                                        <Box width={'212px'}>
                                            <Button isDisabled={!validateFormVal('organization_bonus_setting_id',formProps) || !validateFormVal('amount',formProps) || ( formProps.clawback_applicable ? !validateFormVal('clawback_period',formProps) : false)} variant='primary' isFullWidth={true} onClick={updateBonus}>Save Changes</Button>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </>}
                </Box>
            </MainContent>
        </OnboardingLayout>)
    );

}