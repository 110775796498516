import styled from 'styled-components';

export const RadioWrapper = styled.div<{ isSelected: boolean }>`
  padding: ${({ theme }) => theme.spacing['4']}px;
  border-radius: ${({ theme }) => theme.border.radius.large}px;
  background: ${({ isSelected }) =>
    isSelected
      ? 'linear-gradient(90deg, rgba(21, 102, 241, 0.18) 0.07%, rgba(21, 102, 241, 0) 103.19%)'
      : 'transparent'};
`;

export const NameCircularWrapper = styled.div`
  width: 44px;
  height: 44px;
  display: grid;
  place-items: center;
  border-radius: ${({ theme }) => theme.border.radius.max}px;
  background: ${({ theme }) => theme.colors.feedback.icon.negative.intense};
  overflow: clip;
`;
