import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import JupiterIntroduction from './components/JupiterIntroduction';

import React, { ReactElement } from 'react';

function JupiterSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase center>
        <JupiterIntroduction />
      </MainCase>
      <RightSidebar></RightSidebar>
    </PageWrapper>
  );
}

export default JupiterSettingsWithPageWrapper;
