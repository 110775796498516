import exitBulkUploadsExit from 'assets/bulkUploads/exit_bulk_uploads_exit.svg';
import { HeaderWithProgressBar } from 'components/BulkUploads/components/HeaderWithProgressBar';
import { PrimaryButton, StandardSecondaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import React from 'react';

type PageHeaderProps = {
  currentStep: number;
  headerText: string;
};

const PageHeader = ({ currentStep, headerText }: PageHeaderProps) => {
  const { openModal, closeModal } = useModal();
  return (
    <HeaderWithProgressBar
      headerText={headerText}
      currentStep={currentStep}
      totalSteps={2}
      callbackOnCloseButtonClick={() => {
        openModal(
          <div
            className={`w-full justify-center flex pb-10 flex-col items-center p-8`}
            style={{ gap: '32px' }}>
            <img
              loading="lazy"
              src={exitBulkUploadsExit}
              alt=""
              style={{ height: '88px', width: '88px' }}
            />
            <div className="text-center">
              <h2 className="m-3 mt-4 text-2xl font-semibold"> Exit bulk upload?</h2>
              <p style={{ color: 'rgba(255, 255, 255, 0.54)', fontSize: '16px' }}>
                Please note any progress made till now will not be saved
              </p>
            </div>
            <div className="flex gap-8">
              <PrimaryButton
                style={{ fontSize: '14px', marginTop: '0', width: '150px' }}
                onClick={() => {
                  window.location.href = '/dashboard';
                  closeModal();
                }}>
                Exit now
              </PrimaryButton>
              <StandardSecondaryButton
                style={{ fontSize: '13px' }}
                onClick={() => {
                  closeModal();
                }}>
                Back to setup
              </StandardSecondaryButton>
            </div>
          </div>,
        );
      }}
    />
  );
};

export default PageHeader;
