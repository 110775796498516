import React, { ReactElement, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import copy from 'copy-to-clipboard';
import { getStaticMediaUrl } from 'utils/Urls';
import api from 'api';
import { PrimaryButton, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { InputElement, Input } from 'components/ui/Form/Input';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import Breadcrumb from 'components/ui/Breadcrumb';
import { useModal } from 'components/ui/Modals';
import ExternalLink from 'components/ui/ExternalLink';
import ErrorMessage from 'components/ui/ErrorMessage';
import Spinner from 'components/ui/Spinner';
import { GET_PARTNER_ID_QUERY } from '../../queries';
import partnerIntegrations from 'constants/partnerIntegrations';
import { SuccessAlert, WarningAlert } from 'components/ui/Alert';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { Card } from 'components/ui/Card';
import tick from 'assets/tick.png';
import styles from './index.module.scss';
import pazcareDeactivationModalLogo from 'assets/pazcare/pazcare-disconnected.svg';

function PazcareActivation(): ReactElement {
  const [apiKey, setApiKey] = useState('');
  const {openModal, closeModal } = useModal();
  const [error, setError] = useState<typeof AppError>();
  const [isApiKeyCopied, setIsApiKeyCopied] = useState(false);
  const [successAlert, setSuccessAlert] = useState('');

  
  const getPartnerDetailsQuery = useQuery(
    GET_PARTNER_ID_QUERY,
    () => {
      return api.partnerSettings.getPartnerDetails(partnerIntegrations.PAZCARE);
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );
  
  const partnerId = getPartnerDetailsQuery.data?.partnerId;
  
  const isOrgConnectedToPartner = getPartnerDetailsQuery.data?.isOrgIntegratedToPartner;

  const handleGenerateApiKeyMutation = useMutation(
    () => {
      return api.partnerSettings.generateApiKey({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: (data) => {
        if (data.isKeyGenerated) {
          setSuccessAlert('New client key generated successfully!');
        }
        setApiKey(data.apiKey);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleRefreshApiKeyMutation = useMutation(
    () => {
      return api.partnerSettings.refreshApiKey({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: (data) => {
        setApiKey(data.apiKey);
        setIsApiKeyCopied(false);
        closeModal();
      },
      onError: (error: typeof AppError) => {
        closeModal();
      },
    },
  );

  const handleGenerateApiKey = () => {
    handleGenerateApiKeyMutation.mutate();
  }

  const handleCopy = () => {
    copy(apiKey);
    setIsApiKeyCopied(true);
    setTimeout(() => {
      setIsApiKeyCopied(false);
    }, 3000);
  }

  const handleRefreshKey = () => {
    handleRefreshApiKeyMutation.mutate();
  }

  const openRefreshModal = () => {
    openModal(
      <>
        <div
          className={`flex flex-col items-center flex-grow pt-8 mb-8  ${styles['ActivationModal']}`}>
          <div className="flex flex-col items-center px-16 py-10">
            <img
              src={getStaticMediaUrl(pazcareDeactivationModalLogo)}
              alt="deactivation modal logo"
            />
            <div className={`text-4xl text-white-o-80 font-bold text-white mb-8 mt-4 text-center`}>
              You are about to refresh the client key!
            </div>
            <p className="text-center px-8 mb-11 text-1.7xl text-white-o-70">
              Please note, if you are connected to Pazcare you will have to integrate again with the new client key.
            </p>
          </div>
          <PrimaryButton
            className="text-1.5xl"
            onClick={handleRefreshKey}
            isPending={handleRefreshApiKeyMutation.status === 'loading'}>
            Refresh Key
          </PrimaryButton>
        </div>
      </>,
    );
  }

  if (getPartnerDetailsQuery.status === 'loading') {
    return <Spinner />;
  }
  if (getPartnerDetailsQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        <Errors errorResponse={getPartnerDetailsQuery.error}></Errors>
      </ErrorMessage>
    );
  }
  if (handleGenerateApiKeyMutation.status === 'error') {
    setError(handleGenerateApiKeyMutation.error);
  }
  if (handleRefreshApiKeyMutation.status === 'error') {
    setError(handleRefreshApiKeyMutation.error);
  }

  return (
    <>
      {successAlert !== '' && (
        <SuccessAlert className="mb-16">
          {successAlert}
        </SuccessAlert>
      )}
      <div className={styles['page']}>
        <h1 className="text-4xl font-bold">Connect RazorpayX Payroll with Pazcare 🚀</h1>
        <p className={styles['heading']}>
          Now employee data will automatically sync from RazorpayX Payroll to Pazcare
        </p>
        <div className="pt-20">
          {error && (
            <WarningAlert className="mb-8">
              <Errors errorResponse={error}></Errors>
            </WarningAlert>
          )}
          <p className="font-bold mb-5"> Step 1 : Generate the API key</p>
          {!handleGenerateApiKeyMutation.data &&
            <PrimaryButton
              className="text-1.5xl"
              onClick={handleGenerateApiKey}
              isPending={handleGenerateApiKeyMutation.status === 'loading'}
            >
              {isOrgConnectedToPartner ? "Show Key" : "Generate Now"}
            </PrimaryButton>
          }

          {handleGenerateApiKeyMutation.status === 'success' && handleGenerateApiKeyMutation.data && (
            <>
              <InputElement className="pt-4 pr-4 mb-5">
                <Input type="text" readOnly value={apiKey}></Input>
              </InputElement>

              <div className={styles['button']}>
                {!isApiKeyCopied && (
                  <StandardButton
                    className="text-1.5xl capitalize"
                    onClick={handleCopy}
                    disabled={handleRefreshApiKeyMutation.isLoading}>
                    Copy Key
                  </StandardButton>
                )}
                {isApiKeyCopied && (
                  <button
                    disabled
                    className="px-6 py-2.5 text-2xl bg-button-secondary-background hover:bg-button-secondary-background text-white-400 disabled">
                    <div className={styles['copyKey']}>
                      <img className="mr-4" src={getStaticMediaUrl(tick)} alt='tick' />
                      <span>{'Copied!'}</span>
                    </div>
                  </button>
                )}
                <StandardSecondaryButton className="text-1.5xl capitalize" onClick={openRefreshModal}>
                  Refresh Key
                </StandardSecondaryButton>
              </div>
            </>
          )}
          <div className="pt-20">
            <p className="font-bold"> Step 2 : Input the API key in Pazcare</p>

            <Card className="mt-8" withTopBorder>
              <NumberedList >
                <NumberedListItem >
                  {' '}
                  <span className={styles['card']} >Go to{' '}
                  <ExternalLink
                    className="text-1.5xl text-primary"
                    href="https://app.pazcare.com/dashboard/integrations">
                    {' '}
                    app.pazcare.com/dashboard/integrations{' '}
                  </ExternalLink></span>
                </NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Locate RazorpayX payroll</span></NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Click on Integrate</span></NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Enter the API key and click Authenticate</span></NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Map all the relevant employee fields required by Pazcare</span></NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Map the relevant dependents' details required by Pazcare</span></NumberedListItem>
                <NumberedListItem><span className={styles['card']}>Submit, kick back and relax</span></NumberedListItem>
              </NumberedList>
            </Card>

            <p className={styles['card']}>That's it! You are done 👏</p>
          </div>
        </div>
      </div>
    </>
  );
}

function PazcareActivationWithWrapper(): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Pazcare"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase className={styles['mainCase']} center>
        <PazcareActivation />
      </MainCase>
    </PageWrapper>
  );
}

export default PazcareActivationWithWrapper;
