import {
  Alert,
  ActionList,
  ActionListItem,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectInput,
  TextArea,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import { NewCustomWindow } from './types';
import { FieldContainer } from './styles';
import DatePickerBlade from 'components/ui/DatePickerBlade';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';

type Props = Pick<React.ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  onSubmit: (window: NewCustomWindow) => void;
  isLoading: boolean;
  customWindowModalCondition?: (startDate: Date | null, endDate: Date | null, type?: string) => string;
  creationErrors?: typeof AppError | null;
};

const CreateCustomWindowModal = ({ isOpen, onDismiss, onSubmit, isLoading, customWindowModalCondition, creationErrors }: Props) => {
  const [data, setData] = useState<{
    startDate: Date | null;
    endDate: Date | null;
    type: string;
    employees?: string;
  }>({
    startDate: null,
    endDate: null,
    type: '',
    employees: '',
  });

  const doesErrorExist = customWindowModalCondition && !!customWindowModalCondition(data.startDate, data.endDate, data.type) ;

  const isValid = !!data.endDate && !!data.startDate && !!data.type && !!data.employees && (data.endDate >= data.startDate) && !doesErrorExist ;

  const handleCreateWindowClick = () => {
    if (isValid) {
      onSubmit(data as NewCustomWindow);
    }
  };

  return (
    (<Modal isOpen={isOpen} onDismiss={onDismiss} size="medium">
      <ModalHeader
        title="Create new window"
        subtitle="Define the period during which employees upload their proof for their flexible benefits declarations"
      />
      <ModalBody>
        {customWindowModalCondition && customWindowModalCondition(data.startDate, data.endDate, data.type) &&
          <Alert
            isDismissible={false}
            description={customWindowModalCondition(data.startDate, data.endDate, data.type)}
            isFullWidth
            marginBottom={'spacing.7'}
            color="notice"
          />
        }
        {creationErrors &&
          <Alert
            isDismissible={false}
            description={<Errors errorResponse={creationErrors} />}
            isFullWidth
            marginBottom={'spacing.7'}
            color="negative"
          />
        }
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <FieldContainer>
            <Dropdown selectionType="single">
              <SelectInput
                label="Type"
                placeholder="Select window"
                name="action"
                value={data.type}
                onChange={({ values }) => setData((prev) => ({ ...prev, type: values[0] }))}
              />
              <DropdownOverlay>
                <ActionList>
                  <ActionListItem title="Declaration window" value="declaration_window" />
                  <ActionListItem title="Proof upload window" value="proof_upload_window" />
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
            <DatePickerBlade
              value={data.startDate}
              variant="inline"
              label="Open from"
              placeholder="Select date"
              onChange={(date) => setData((prev) => ({ ...prev, startDate: date }))}
            />
            <DatePickerBlade
              value={data.endDate}
              variant="inline"
              label="Till"
              placeholder="Select date"
              onChange={(date) => setData((prev) => ({ ...prev, endDate: date }))}
            />
          </FieldContainer>
          <TextArea
            label="Add employee(s) email ID"
            placeholder="emp1@example.com, emp2@example.com, emp3@example.com"
            value={data.employees}
            onChange={({ value }) => setData((prev) => ({ ...prev, employees: value }))}
          />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
          <Button variant="secondary" onClick={onDismiss} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleCreateWindowClick} isLoading={isLoading} isDisabled={ !isValid }>
            Create window
          </Button>
        </Box>
      </ModalFooter>
    </Modal>)
  );
};

export default CreateCustomWindowModal;
