import React from "react";
import { Box, Heading, Text, TextInput } from '@razorpay/blade/components';
import DropdownSettingsCard, { DropdownSettingsProps } from '../../ui/DropdownSettingsCard/index';
import SwitchSettingsCard from '../../ui/SwitchSettingsCard/index';
import { getFinancialYearList } from "components/Reports/TaxReport/utils";
import { TaxDeclarationSettingsSchemaContract } from '../../../schemas/TaxDeclarationSettingsSchema';
import { calculateTaxFieldAlertText } from "./utils";


const GeneralSettings = (
    { settings, onChange, hasProPlan, taxCalculationsOnVerifiedProofs }:

        {
            settings: TaxDeclarationSettingsSchemaContract;
            onChange: (
                key: keyof TaxDeclarationSettingsSchemaContract,
                value: number | boolean | null,
            ) => void;
            taxCalculationsOnVerifiedProofs: boolean;
            hasProPlan?: boolean;
        }
) => {

    const handleSwitchChange = (
        { isChecked, value, event, }: {
            isChecked: boolean,
            event?: React.ChangeEvent,
            value?: string
        }) => {
        if (value === 'autoOpenProofWindowForNewEmployees' && isChecked === false) {
            onChange('windowDurationForNewEmployees', null);
        }

        if(value === 'allowEmployeeToUpdateTaxDeductions' && isChecked === false){
            // handleSwitchChange({isChecked: true, value: 'disable80g'});
        }
        onChange(value as keyof TaxDeclarationSettingsSchemaContract, isChecked);
    }

    const handleDropdownChange = (
        { name, values }: { name?: string | undefined; values: string[]; }
    ) => {
        if (name === 'financialYear') {
            onChange('financialYear', values[0] === 'null' ? null : Number(values[0]));
        }
        else {

            onChange(name as keyof TaxDeclarationSettingsSchemaContract, values[0] === 'true');

            if (name === 'letXpayrollVerifyDocs' && values[0] === 'true') {
                onChange('taxCalculationsOnVerifiedProofs', taxCalculationsOnVerifiedProofs);
            }
        }
    };

    const fyList = getFinancialYearList();
    const fyListArray: DropdownSettingsProps['listItemArray'] = [];
    fyListArray.push({ title: `Let XPayroll choose (recommended)`, value: 'null' });
    fyList.map(fy => fyListArray.push({ title: `${fy} - ${fy + 1}`, value: fy.toString() }));


    return (
        (<Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" paddingY="spacing.11">
            <Heading size="small">⚙️ General</Heading>
            <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.9">

                <SwitchSettingsCard
                    isChecked={settings.allowEmployeeToUpdateTaxDeductions}
                    mainText={'Allow employees to update their tax deductions'}
                    subText={'Enable employees to declare and upload tax-saving investments seamlessly through the entire tax declaration and proof upload module.'}
                    label="Allow employees to update"
                    name={'allowEmployeeToUpdateTaxDeductions'}
                    onChange={handleSwitchChange}
                />

                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.4"
                >
                    <Text
                        variant="caption"
                    >
                        Verification, 80G & more
                    </Text>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'spacing.7'}
                    >
                        <DropdownSettingsCard
                            heading="Opt for Verification"
                            description="Please select if you would like XPayroll to verify your employee's tax declarations. This is a one-time process usually carried out in January."
                            label=""
                            name="letXpayrollVerifyDocs"
                            value={hasProPlan ? String(settings.letXpayrollVerifyDocs) : 'false'}
                            onChange={handleDropdownChange}
                            isRequired
                            listItemArray={[
                                { title: 'Let XPayroll Verify', value: 'true', isProOption: true, hasProPlan: hasProPlan },
                                { title: 'Let Organisation Verify', value: 'false' }
                            ]}
                            isDisabled={settings.freezeXpayrollVerifyDocs}
                            alertText={
                                settings.letXpayrollVerifyDocs ?
                                    "⚡️ RazorpayX Payroll will verify your IT proof documents of all your employees" :
                                    "You will verify the IT proof documents of all your employees"
                            }
                        />

                        <DropdownSettingsCard
                            heading="Select Financial Year"
                            description="Please select the financial year for which you want your employees to edit their tax deductions, enter past payroll information, and update flexible benefits."
                            label=""
                            name="financialYear"
                            value={settings.financialYear ? settings.financialYear.toString() : 'null'}
                            onChange={handleDropdownChange}
                            isRequired
                            listItemArray={fyListArray}
                        />

                        <DropdownSettingsCard
                            heading="Calculate tax on the basis of"
                            description="Please choose whether you want tax calculations to be done on the basis of declarations made by the employee (irrespective of proofs provided) or declarations with verified proofs only (please see our FAQ here)"
                            label=""
                            name="taxCalculationsOnVerifiedProofs"
                            value={String(settings.taxCalculationsOnVerifiedProofs)}
                            onChange={handleDropdownChange}
                            isRequired
                            isDisabled={settings.letXpayrollVerifyDocs}
                            listItemArray={
                                [
                                    { title: 'Declaration and verified proofs', value: 'true' },
                                    { title: 'Amount declared by the employee', value: 'false' }
                                ]
                            }
                            alertText={calculateTaxFieldAlertText(settings)}
                        />
                    </Box>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.4"
                >
                    <Text
                        variant="caption"
                    >
                        Auto Open Window
                    </Text>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'spacing.7'}
                    >

                        <SwitchSettingsCard
                            isChecked={settings.allowEmployeeToUpdateTaxDeductions ? settings.autoOpenProofWindowForNewEmployees : settings.allowEmployeeToUpdateTaxDeductions}
                            mainText={'Auto open declaration window for new employees'}
                            subText={'Auto-open declaration window for a certain time after joining. Employees will be notified via email.'}
                            label="Allow open declaration window"
                            onChange={handleSwitchChange}
                            isDisabled={!settings.allowEmployeeToUpdateTaxDeductions}
                            hiddenTray={
                                <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
                                    <Text
                                        size="small"
                                    >
                                        How many days should the declaration window remain open after the employee joins
                                    </Text>
                                    <Box width={'136px'}>
                                        <TextInput
                                            label="Number of Days"
                                            placeholder="Eg. 15"
                                            value={settings.windowDurationForNewEmployees ? String(settings.windowDurationForNewEmployees as number) : '0'}
                                            onChange={(e) => {
                                                if (settings.autoOpenProofWindowForNewEmployees) {
                                                    onChange('windowDurationForNewEmployees', (e.value ? parseInt(e.value) : 0));
                                                }
                                                else {
                                                    onChange('windowDurationForNewEmployees', null);
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            }
                            name={'autoOpenProofWindowForNewEmployees'}
                        />

                        <SwitchSettingsCard
                            isChecked={settings.allowEmployeeToUpdateTaxDeductions ? settings.autoOpenProofWindowForFnf : settings.allowEmployeeToUpdateTaxDeductions}
                            mainText={'Auto open proof upload window for dismissed employees'}
                            subText={'Employees under the dismissals status, can submit their proofs before their exit.'}
                            label="Auto open proof upload window"
                            name={'autoOpenProofWindowForFnf'}
                            onChange={handleSwitchChange}
                            isDisabled={!settings.allowEmployeeToUpdateTaxDeductions}
                        />

                    </Box>
                </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="spacing.4"
                    >
                        <Text
                            variant="caption"
                        >
                            Manage 80G Section
                        </Text>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'spacing.7'}
                        >
                            <SwitchSettingsCard
                                isChecked={settings.disable80g}
                                mainText={'Disable 80G'}
                                subText={'This will disable Investment under Section 80G from Tax Deductions section and any declared/approved amount will be removed for tax calculation for all employees.'}
                                label="Disable 80G"
                                name={'disable80g'}
                                onChange={handleSwitchChange}
                                // isDisabled={!settings.allowEmployeeToUpdateTaxDeductions}
                            />

                        </Box>
                    </Box>
            </Box>
        </Box>)
    );

}

export default GeneralSettings;