import {
  Box,
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalHeader,
  OTPInput,
  Text,
} from '@razorpay/blade/components';
import React, { useState } from 'react';

import otpIcon from 'assets/verify-otp.png';
import { getStaticMediaUrl } from 'utils/Urls';

import { SUPPORT_EMAIL } from 'constants/companyContacts';
import { trackSegment } from 'utils/segment';
import OtpTimer from './OtpTimer';
import { OTP_REGEX } from 'utils/regexes';

export type OtpModalProps = {
  isOpen: boolean;
  data: {
    isResendAttempt?: boolean;
    is_authenticator_enabled?: boolean;
    email?: string;
    remainingTime: number;
    error?: string;
    errorCode?: string;
  };
  isLoading?: boolean;
  isDisabled?: boolean;
  onVerify?: (e: React.MouseEvent, otp: string) => void;
  onResend?: (e: React.SyntheticEvent<Element, Event>, resetTimerCallback: Function) => void;
  onClose?: () => void;
  onDismiss: () => void;
  actionName: string;
};

const OtpModalBlade = ({
  data,
  onVerify,
  onResend,
  isOpen,
  onDismiss,
  isLoading = false,
  actionName,
  isDisabled = false,
}: OtpModalProps) => {
  const [otp, setOtp] = useState<string>('');

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;

    if (OTP_REGEX.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  const handleVerifyClick = (e: React.MouseEvent) => {
    trackSegment('two_factor_authentication.verify_click', {
      properties: { actionName },
    });
    onVerify?.(e, otp);
  };

  const handleResendClick = (
    e: React.SyntheticEvent<Element, Event>,
    resetTimerCallback: Function,
  ) => {
    trackSegment('two_factor_authentication.resend_click', {
      properties: { actionName },
    });
    onResend?.(e, resetTimerCallback);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalHeader />
      <ModalBody>
        <Box
          margin="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={getStaticMediaUrl(otpIcon)} alt="" width="120px" />
            <Heading size="medium">
              {data?.is_authenticator_enabled
                ? 'Authentication code'
                : 'Enter OTP for verification'}
            </Heading>
            <Text marginTop="spacing.3" color="surface.text.gray.subtle" textAlign="center">
              {data?.is_authenticator_enabled
                ? 'Open the two-factor authenticator app on your mobile device to view the authentication code'
                : `One Time Password sent successfully to ${data?.email}`}
            </Text>
            <OTPInput
              marginTop="spacing.8"
              label=""
              errorText={data.error}
              value={otp}
              name="otp-field"
              onChange={onOtpChange}
              otpLength={6}
              placeholder=""
              validationState={data.error ? 'error' : 'none'}
            />
            {data.isResendAttempt ? (
              <Text variant="caption" color="feedback.text.positive.intense" marginTop="spacing.3">
                A new OTP has been sent
              </Text>
            ) : null}
            <input type="hidden" name="otp" value={otp} />
            <Box display="flex" alignItems="center">
              {!data?.is_authenticator_enabled && (
                <Box marginTop="spacing.6" display="flex" alignItems="center">
                  <Text color="surface.text.gray.muted"> Did not receive OTP yet?</Text>
                  <OtpTimer duration={data.remainingTime} format="mmss" isVisible={true}>
                    {({
                      timerDone,
                      resetTimer,
                    }: {
                      timerDone: boolean;
                      resetTimer: () => void;
                    }) => (
                      <Box marginLeft="spacing.4">
                        {timerDone ? (
                          <Link
                            variant="button"
                            isDisabled={isDisabled || isLoading}
                            onClick={(e) => {
                              handleResendClick(e, resetTimer);
                            }}>
                            Resend now
                          </Link>
                        ) : (
                          <Text color="surface.text.gray.subtle">Resend in</Text>
                        )}
                      </Box>
                    )}
                  </OtpTimer>
                </Box>
              )}
            </Box>
            <Button
              isLoading={isLoading}
              marginY="20px"
              isDisabled={!otp}
              onClick={(e) => handleVerifyClick(e)}
              isFullWidth>
              Verify
            </Button>

            {!data?.is_authenticator_enabled && (
              <Box
                marginTop="spacing.10"
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                alignItems="center">
                <Text>Can't find the OTP? Reach out to us at</Text>
                <Link target="_blank" href={`mailto:${SUPPORT_EMAIL}`} marginLeft="spacing.2">
                  {SUPPORT_EMAIL}
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default OtpModalBlade;
