import React, { useMemo } from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import { Box, Button, Text, Heading } from '@razorpay/blade/components';
import { FieldsSyncCard, FieldsToSync } from './components/FieldsSyncCard';
import { Redirect, useHistory } from 'react-router-dom';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { GetKnitHrmsIntegrationSchemaContract } from 'schemas/GetKnitHrmsIntegrationSchema';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';
import { useKnitHrmsApp } from '../useKnitHrmsApp';

const getEmployeeFieldsData = (
  syncedFields: GetKnitHrmsIntegrationSchemaContract['synced_fields'],
  availableFields: GetKnitHrmsIntegrationSchemaContract['available_fields'],
) => {
  const allFields = availableFields.map<FieldsToSync>(({ field, name, is_mandatory }) => {
    const isAlreadySynced = syncedFields.find(({ field: syncedField }) => syncedField === field);
    if (isAlreadySynced) {
      return {
        field,
        name,
        isSyncEnabled: true,
        isMandatory: is_mandatory,
      };
    }

    return {
      field,
      name,
      isMandatory: is_mandatory,
    };
  });

  const bankFields = ['bank-account-holder-name', 'bank-account-number', 'bank-ifsc'];
  const bankDetailsFields = allFields.filter((field) => bankFields.includes(field.field));
  const personalFields = allFields.filter((field) => !bankFields.includes(field.field));

  const arePersonalFieldsSelected =
    personalFields.filter(({ isSyncEnabled }) => isSyncEnabled).length > 0;
  const areBankFieldsSelected =
    bankDetailsFields.filter(({ isSyncEnabled }) => isSyncEnabled).length > 0;

  return {
    bankDetailsFields,
    personalFields,
    areBankFieldsSelected,
    arePersonalFieldsSelected,
  };
};

const FieldsSync = () => {
  const { knitHrmsApp, hrmsAppRoutePaths, hrmsAppMetadata } = useKnitHrmsApp();
  const history = useHistory();

  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);

  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    retryLimitExhausted,
    updateSyncedFields,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);
  const { totalSteps, currentStep, integrationProgress } = useIntegrationProgress();

  const syncedFields = knitHrmsIntegrationQuery.data?.synced_fields ?? [];
  const availableFields = knitHrmsIntegrationQuery.data?.available_fields ?? [];

  const { personalFields, bankDetailsFields, areBankFieldsSelected, arePersonalFieldsSelected } =
    useMemo(
      () => getEmployeeFieldsData(syncedFields, availableFields),
      [syncedFields, availableFields],
    );

  const canProceedToNextStep = areBankFieldsSelected || arePersonalFieldsSelected;

  // redirecting to hrms home if integration is not active
  if (knitHrmsIntegrationQuery.isSuccess && !isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  // redirecting to sync-in-progress screen if employee data sync is in progress
  if (knitHrmsIntegrationQuery.isSuccess && isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.settings.syncInProgress} />;
  }

  const handleFieldSyncEnableChange = (isChecked: boolean, fieldToSync: FieldsToSync) => {
    const filterSyncedFields = syncedFields.filter(({ field }) => field !== fieldToSync.field);
    if (isChecked) {
      filterSyncedFields.push({
        field: fieldToSync.field,
        name: fieldToSync.name,
      });
    }

    updateSyncedFields(filterSyncedFields);
  };

  const handleBackCtaClick = () => {
    history.goBack();
  };

  const handleNextCtaClick = () => {
    history.push(hrmsAppRoutePaths.settings.employeeContractorMapping, {
      totalSteps,
      currentStep: currentStep + 1,
    });
  };

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  return (
    (<IntegrationWindow
      progress={integrationProgress}
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={org?.name ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}>
      <Box display="flex" flexDirection="column" gap="spacing.9" maxWidth="592px">
        <Box display="flex" flexDirection="column" gap="spacing.2">
          <Heading as="h2" size="xlarge" color="surface.text.gray.muted">
            🧑‍💼Employee details to sync from {hrmsAppMetadata.name}
          </Heading>
          <Text color="surface.text.gray.disabled">
            The below employee details can be synced from {hrmsAppMetadata.name} to RazorpayX
            Payroll
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <FieldsSyncCard
            key={0}
            title="Employee personal & organization details"
            description="These details will be used to create profiles of your employees on RazorpayX Payroll and allot them to the right department in your organization"
            fields={personalFields}
            onFieldSwitch={handleFieldSyncEnableChange}
          />
          {bankDetailsFields.length > 0 && (
            <FieldsSyncCard
              key={1}
              title="Employee bank details"
              description="These details will be used to create profiles of your employees on RazorpayX Payroll and allot them to the right department in your organization"
              fields={bankDetailsFields}
              onFieldSwitch={handleFieldSyncEnableChange}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          <Button onClick={handleNextCtaClick} isDisabled={!canProceedToNextStep}>
            Next
          </Button>
        </Box>
      </Box>
    </IntegrationWindow>)
  );
};

export { FieldsSync };
