import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@razorpay/blade/components';
import HeaderSection from './HeaderSection';
import InfoSection from './InfoSection';

const WageCalculationInfo = () => {
  return (
    <>
      <HeaderSection
        title={'Wage Calculation'}
        description={
          'Select if the component should be included when calculating the compliance contributions.'
        }
      />

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Component is part of PF wage'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Enabled: The component will be part of the wage used for calculating Employee State
              Insurance (ESI) contributions (currently 0.75% from the employee and 3.25% from the
              employer for wages below ₹21,000).
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Disabled: The component will be excluded from the ESI wage calculation.
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Component is part of ESI wage'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Enabled: The component will be part of the wage used for calculating Employee State
              Insurance (ESI) contributions (currently 0.75% from the employee and 3.25% from the
              employer for wages below ₹21,000).
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Disabled: The component will be excluded from the ESI wage calculation.
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Component is part of LWF wage'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Enabled: The component will be included in the Labour Welfare Fund (LWF) calculation,
              where applicable. Contributions vary by state, with both employer and employee
              contributing a small fixed amount.{' '}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Disabled: The component will not be considered in LWF wage calculation.
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Component is part of PT wage'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Enabled: The component will be included in the Professional Tax (PT) wage, impacting
              the PT deduction, which varies by state and is based on wage slabs.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Disabled: The component will be excluded from the PT wage calculation.{' '}
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>
    </>
  );
};

export default WageCalculationInfo;
