export enum DeliveryType {
  DISPATCH_TO_EMPLOYEE = 1,
  DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION = 2,
}

export enum FuelCarType {
  CAR_BELOW_1600_CC = 'carBelow1600cc',
  CAR_ABOVE_1600_CC = 'carAbove1600cc',
}

export enum FlexiPartners {
  ZAGGLE = 1,
}

export const ZAGGLE_MANAGE_TAB_OPTIONS = {
  WALLETS: 'WALLETS',
  COMPONENT: 'COMPONENT',
  ADDRESS: 'ADDRESS',
  CONFIRM: 'CONFIRM',
};

export enum KYC_STATUS {
  STATUS_NOT_INITIATED = 0,
  STATUS_PENDING = 1,
  STATUS_VERIFIED = 2,
  STATUS_ON_HOLD = 3,
  STATUS_REJECTED = 4,
}

export const links = {
  learnMoreAboutFbp:
    'https://intercom.help/XPayroll/en/articles/5160685-what-are-flexible-benefits-and-how-can-we-use-them',
};
