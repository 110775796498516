import React from 'react';
import { Switch } from 'react-router-dom';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import PreCheck from './PreCheck';
import Activation from './Activation';
import Activated from './Activated';

import { Box, Spinner } from '@razorpay/blade/components';
import useRxIntegration from './hooks/useRxIntegration';
import Errors from 'components/ui/Form/Errors';
import Error from './Error';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';

const RXIntegration = () => {
  const { rxIntegrationQuery } = useRxIntegration();

  if (rxIntegrationQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
        <Spinner accessibilityLabel="loading" />
      </Box>
    );
  }

  if (rxIntegrationQuery.error) {
    return (
      <Error>
        <ErrorWithRetry
          onRetry={rxIntegrationQuery.refetch}
          title="An error occured"
          description={<Errors errorResponse={rxIntegrationQuery.error} />}
        />
      </Error>
    );
  }

  return (
    <Switch>
      <RestrictedRoute
        path={routePaths.settings.rxIntegration.root}
        exact
        component={Activated}
        allowedFeatures={['rxSelfServe']}
      />
      <RestrictedRoute
        path={routePaths.settings.rxIntegration.preCheck}
        exact
        component={PreCheck}
        allowedFeatures={['rxSelfServe']}
      />
      <RestrictedRoute
        path={routePaths.settings.rxIntegration.activate}
        exact
        component={Activation}
        allowedFeatures={['rxSelfServe']}
      />
    </Switch>
  );
};

export default RXIntegration;
