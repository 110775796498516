import { Modal } from 'components/ui/Modal';
import React from 'react';
import styles from './index.module.css';
import successCheckIcon from 'assets/icons/success_check_icon.svg';
import { PrimaryButton } from 'components/ui/Button';
import { useHistory } from 'react-router-dom';
import {
  employeeOnboardingAnnualCompensationPageStateActionType,
  employeeOnboardingAnnualCompensationPageStateType,
} from './types';
import { pageStateActionEnum, VIEW_COMPENSATION_DETAILS } from '.';
import { routePaths } from '../../Routes/data';

interface Props {
  pageState: employeeOnboardingAnnualCompensationPageStateType;
  pageStateDispatch: React.Dispatch<employeeOnboardingAnnualCompensationPageStateActionType>;
  isWorkflowEnabled: boolean;
}

const EmployeeAnnualCompensationSuccessUpdateModal: React.FC<Props> = ({
  pageState,
  pageStateDispatch,
  isWorkflowEnabled
}) => {
  const history = useHistory();

  return (
    <>
      <Modal
        showModal={pageState?.annualCompensationDetailsUpdateState}
        heading=""
        className={`${styles['employee-annual-compensation-update-success']}`}
        contentClass="flex flex-col h-auto"
        closeModal={() => { }}
        disableClose={true}>
        <>
          <div
            className={`w-full justify-center flex pb-10 flex-col ${styles['employee-annual-compensation-update-success']}`}>
            <img loading="lazy" src={successCheckIcon} alt="" />
            <h2 className="m-3 mt-4 text-2xl font-semibold">
              {' '}
              {isWorkflowEnabled ? 'Request for employee salary revision has been sent successfully 🎉 ' : 'Employee salary details are updated successfully.'}
            </h2>
            {isWorkflowEnabled ?
              <PrimaryButton
                style={{ fontSize: '16px' }}
                onClick={() => {
                  history.push(routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe);
                }}>
                View Approvals
              </PrimaryButton> :
              <PrimaryButton
                // style={{ fontSize: '16px' }}
                className='!text-100'
                onClick={() => {
                  pageStateDispatch({
                    type: pageStateActionEnum.annualCompensationDetailsUpdateState,
                    data: false,
                  });
                  pageStateDispatch({
                    type: pageStateActionEnum.pageView,
                    data: VIEW_COMPENSATION_DETAILS,
                  });
                }}>
                Continue
              </PrimaryButton>
            }
          </div>
        </>
      </Modal>
    </>
  );
};

export default EmployeeAnnualCompensationSuccessUpdateModal;
