import { Input } from 'components/ui/Form/Input';
import { Table } from 'components/ui/Table';
import React from 'react';
import { toIndianCurrencyString } from 'utils/Numbers';
import styles from '../../index.module.scss';
import { EditPayrollProps, PayrollAllowance } from '../types';

type AdditionsProps = EditPayrollProps & {
  allowances: PayrollAllowance[];
  onAllowanceChange: (updateIndex: number, propToUpdate: string, value: string | number) => void;
};

const Additions = ({ payrollEntry, allowances, onAllowanceChange }: AdditionsProps) => {
  return (
    <div>
      <Table
        className="w-full  border border-solid border-surfaceBorderGraySubtle"
        showAlternateColors={false}>
        <thead>
          <tr>
            <th>Label</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {payrollEntry.adhocAllowances?.map((otp) => {
            return (
              <tr key={otp.name}>
                <td>{otp.name}*</td>
                <td>{toIndianCurrencyString(otp.amount, true)}</td>
              </tr>
            );
          })}
          {[...Array(5).keys()].map((_, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <Input
                    type="text"
                    data-testid={`allowance-${allowances[idx].name}-name`}
                    name={`name-${idx}`}
                    value={allowances[idx].name ?? ''}
                    placeholder="Eg. Bonus, Travel Allowance etc."
                    onChange={(event) => {
                      onAllowanceChange(idx, 'name', event.target.value);
                    }}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    data-testid={`allowance-${allowances[idx].name}-value`}
                    className={styles['run-payroll-edit-number']}
                    name={`amount-${idx}`}
                    value={allowances[idx].amount ? allowances[idx].amount : ''}
                    onChange={(event) => {
                      onAllowanceChange(idx, 'amount', event.target.value);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {payrollEntry.adhocAllowances && payrollEntry.adhocAllowances.length > 0 && (
        <div className="mt-5">* indicates one-time payment paid to employee.</div>
      )}
    </div>
  );
};

export default Additions;
