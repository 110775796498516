import { Box, Text, UserCheckIcon, UserIcon, UserXIcon } from '@razorpay/blade/components';
import { ACTION_APPROVED, ACTION_REJECTED } from 'components/ApprovalWorkflows/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';

function ActorWithComments({
  person,
  comment,
  status,
  date,
}: {
  person?: GetWorkflowRequestDetailsContract['people_metadata'][string];
  comment?: string;
  status: string;
  date: string;
}) {
  const loggedInPeopleId = useSelector(loggedInUserSelectors.peopleId);

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.0">
      <Box display="flex" gap="spacing.2">
        <Box display="flex" gap="spacing.3" padding={['spacing.2', 'spacing.1']}>
          {((status) => {
            switch (status) {
              case ACTION_APPROVED:
                return (
                  <UserCheckIcon
                    size="small"
                    color="feedback.icon.positive.intense"
                    position="relative"
                    top="-2px"
                  />
                );
              case ACTION_REJECTED:
                return (
                  <UserXIcon
                    size="small"
                    color="feedback.icon.negative.intense"
                    position="relative"
                    top="-2px"
                  />
                );
              default:
                return <UserIcon size="small" color="interactive.icon.gray.normal" />;
            }
          })(status)}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="spacing.2">
            {person ? 
            <Text weight="semibold" size="small" color="surface.text.gray.muted">
              {`${person.name}${loggedInPeopleId === person.people_id ? ' (You)' : ''}`}
            </Text> : 
            <Text weight="semibold" size="small" color="surface.text.gray.muted">
              {`via API`}
            </Text>
            }
            <Text weight="regular" size="xsmall" color="surface.text.gray.disabled">
              ●
            </Text>
            <Text size="small" color="surface.text.gray.disabled">
              {date}
            </Text>
          </Box>
          <Box display="flex" gap="spacing.3">
            {comment && (
              <Text variant="caption" color="surface.text.gray.disabled" size='small'>
                {comment}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>)
  );
}

export default ActorWithComments;
