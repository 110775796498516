import React from "react";
import { useQuery } from "react-query";
import api from "../../../api";
import APIError from "../../../types/apiError";
import Spinner from "../../ui/Spinner";
import ErrorMessage from "../../ui/ErrorMessage";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "./utils/queries";
import JibbleIntroduction from "./components/JibbleIntroduction";
import PageWrapper from "../../ui/PageWrapper";
import Breadcrumb from "../../ui/Breadcrumb";
import MainCase from "../../ui/MainCase";
import RightSidebar from "../../ui/RightSidebar";
import { useHistory } from "react-router-dom";
import {routePaths} from "../../Routes/data";

const JibbleSettings = () => {
    const history = useHistory();
    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    if (integrationStatusQuery.status === 'loading' || integrationStatusQuery.status === 'idle') {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occured!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery?.data?.is_enabled) {
        if (integrationStatusQuery.data.is_onboarding_completed) {
            history.push(routePaths.settings.jibble.manage);
        } else {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    return (
        <JibbleIntroduction/>
    );
}

const JibbleSettingsWithPageWrapper = () => (
    <PageWrapper>
        <Breadcrumb
            name="Jibble"
            urlMaps={{
                Integrations: '/integrations',
            }}
        />
        <MainCase center>
            <JibbleSettings />
        </MainCase>
    </PageWrapper>
)

export default JibbleSettingsWithPageWrapper;
