import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { CheckBox, Input } from 'components/ui/Form/Input';
import Note from 'components/ui/Note';
import Label from 'components/ui/Label';
import ExternalLink from 'components/ui/ExternalLink';
import { Table } from 'components/ui/Table';
import { Select, Option } from 'components/ui/Form/Select';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { ESICUpdateRequest } from 'api/companyOnboarding';
import api from 'api';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { routePaths } from 'components/Routes/data';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import Spinner from 'components/ui/Spinner';
import { trackSegment } from 'utils/segment';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';


const ESI_ENABLED = 1;
const ESI_DISABLED = 0;

const EsicSetup = () => {
  const dispatch = useDispatch();
  const {isAdditionsDisabled} = useIsFeatureDisabledForRearch()
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const [isESICPaymentEnabled, setIsESICPaymentEnabled] = useState(
    !!currentOrganization?.paymentOptions?.payEsi,
  );
  const [esiStatus, setEsiStatus] = useState(
    !!currentOrganization?.isEsi ? ESI_ENABLED : ESI_DISABLED,
  );
  const [shouldIncludeEmployerContributionInCtc, setShouldIncludeEmployerContributionInCtc] =
    useState(!!currentOrganization?.paymentOptions?.includeEmployerEsiContributionInCtc);
  const [shouldIncludeAdditions, setShouldIncludeAdditions] = useState(
    !!currentOrganization?.paymentOptions?.includePayrollAdditionsToESIWages,
  );
  const [esicUsername, setEsicUsername] = useState(
    currentOrganization?.externalCredentials?.esicUser || '',
  );
  const [esicPassword, setEsicPassword] = useState(
    currentOrganization?.externalCredentials?.esicPass || '',
  );
  const [showPassword, setShowPassword] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const links = useSelector(loggedInUserSelectors.referenceLinks);

  const updatePFMutation = useMutation(
    ({
      isESICEnabled,
      isESICPaymentEnabled,
      shouldIncludeEmployerContributionInCtc,
      shouldIncludeAdditions,
      esicUsername,
      esicPassword,
    }: ESICUpdateRequest) =>
      api.companyOnboarding.updateESICSetup({
        isESICEnabled,
        isESICPaymentEnabled,
        shouldIncludeEmployerContributionInCtc,
        shouldIncludeAdditions,
        esicUsername,
        esicPassword,
      }),
    {
      onSuccess: (data) => {
        trackSegment("onboarding.setup_compliance.esic_click");
        setIsRedirecting(true);
        dispatch(fetchLoggedInUser());
        window.location.href = '/';
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const isESICEnabled = esiStatus === ESI_ENABLED;
    updatePFMutation.mutate({
      isESICEnabled,
      isESICPaymentEnabled: !isESICEnabled ? false : isESICPaymentEnabled,
      shouldIncludeEmployerContributionInCtc,
      shouldIncludeAdditions,
      esicUsername,
      esicPassword,
    });
  };

  const isFormBeingSubmitted = updatePFMutation.status === 'loading';

  if (isRedirecting) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className='flex flex-col items-start'>
        {updatePFMutation.status === 'error' && (
          <WarningAlert className="mb-4">
            <Errors errorResponse={updatePFMutation.error} />
          </WarningAlert>
        )}
        <Note>
          ESI is mandatory if the organization size is more than 10 employees. ESI should be
          deducted for employees whose salary is less than ₹21,000.
        </Note>
        <Label>ESI Status</Label>
        <Select
          value={esiStatus}
          onChange={(e) => {
            setEsiStatus(parseInt(e.target.value));
          }}
          disabled={isFormBeingSubmitted}
          className="mb-20"
        >
          <Option value={ESI_ENABLED}>Enabled</Option>
          <Option value={ESI_DISABLED}>Disabled</Option>
        </Select>

        {esiStatus === ESI_ENABLED && (
          <>
            <Label>ESI payments</Label>
            <Note>
              Please note that disabling the payment of ESI does not disable the deduction of the
              same in your employees' payslips.
            </Note>
            <CheckBox
              id="esic-payment-enabled"
              label="ESI payments and filing (if applicable)"
              className="mb-8"
              value="esic-payment-enabled"
              checked={isESICPaymentEnabled}
              onChange={(event) => {
                setIsESICPaymentEnabled(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />

            {isESICPaymentEnabled && (
              <>
                <Label className="mt-16">ESIC</Label>
                <Note>
                  Your company's <ExternalLink href={links.linkEsic}>ESIC</ExternalLink> login is
                  required to pay ESIC dues and file returns.
                </Note>

                <Table className="w-full mb-10">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="text"
                          name="esic-username"
                          disabled={isFormBeingSubmitted}
                          maxLength={255}
                          value={esicUsername}
                          onChange={(e) => setEsicUsername(e.target.value)}
                        />
                      </td>
                      <td>
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          name="esic-password"
                          disabled={isFormBeingSubmitted}
                          maxLength={255}
                          value={esicPassword}
                          onChange={(e) => setEsicPassword(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <StandardSecondaryButton
                  type="button"
                  className="mb-16"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  Click here to {showPassword ? 'hide' : 'show'} password
                </StandardSecondaryButton>
                <br />
              </>
            )}
            <Label className="mt-16">ESI settings</Label>
            {!isAdditionsDisabled && <CheckBox
              id="payroll-additions-esi-wages"
              label="Include payroll additions and one-time payments to ESI wages."
              className="mb-8"
              value="payroll-additions-esi-wages"
              checked={shouldIncludeAdditions}
              onChange={(event) => {
                setShouldIncludeAdditions(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />}
            <CheckBox
              id="include-employer-contribution-esi-ctc"
              label="Include employer contribution to ESI in employee CTC"
              className="mb-16"
              value="include-employer-contribution-esi"
              checked={shouldIncludeEmployerContributionInCtc}
              onChange={(event) => {
                setShouldIncludeEmployerContributionInCtc(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />
          </>
        )}

        <StandardButton type="submit" isPending={isFormBeingSubmitted}>
          Save & Next
        </StandardButton>
      </form>
    </div>
  );
};

export default EsicSetup;
