import React, { ReactElement } from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import styles from './index.module.scss';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import fileExecutionFailed from '../../../../assets/file--execution--failed.svg';
import fileExecutionInProgress from '../../../../assets/file--execution--in--progress.svg';


export const STATUS_IN_PROGRESS = 1;
export const STATUS_FAILED = 2;

export function ExecutionStatusModal({
  mainText,
  subText,
  status,
  errorBlock
}: {
  mainText: string;
  subText?: string;
  status: number;
  errorBlock?: ReactElement;
}): ReactElement {

  function getIcon(status: number): ReactElement {
    switch (status) {
      case STATUS_IN_PROGRESS:
        return (
          <>
            <div className={styles['file-loader-wrapper']}>
              <div className={styles['file-loader']}>
              </div>
              <img className={styles['in-progress-icon']} src={getStaticMediaUrl(fileExecutionInProgress)} />
            </div>
          </>
        );

      case STATUS_FAILED:
        return (<img alt="" className={styles['img']} src={getStaticMediaUrl(fileExecutionFailed)} ></img>);

      default:
        return (<></>);
    }
  }

  return (
    <div className={styles['Modal-Parent']}>
      <Modal
        disableClose={true}
      >
        <div className={status === STATUS_IN_PROGRESS ? styles['execution-in-progress-preview-modal'] : styles['execution-failed-preview-modal']}>
          {getIcon(status)}
          <h3 className={styles['main-text']}>{mainText}</h3>
          {subText && <p className={styles['sub-text']}>
            {subText}
          </p>}
          <div className={styles['error-block']}>{errorBlock && status === STATUS_FAILED ? errorBlock : <></>}</div>
        </div>
      </Modal>
    </div>
  );
};  