import { FormProvider } from 'forms';
import React from 'react';
import CreateDeductionsForm from './CreateDeductionsForm';
import KnowMoreView from '../KnowMoreView/KnowMoreView';

const CreateDeductionsView = () => {
  return (
    <FormProvider>
      <CreateDeductionsForm />
      <KnowMoreView />
    </FormProvider>
  );
};

export default CreateDeductionsView;
