import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ApproveRejectWithdrawCallback,
  MyRequestsContextType,
  useMyRequestsContext,
} from './MyRequestsContext';
import { RowCheckHandler, RowData } from './components/Table/types';

interface UseApproveRejectRequests<T extends RowData> {
  tableData: T[];
  setTableData: Dispatch<SetStateAction<T[]>>;
}

const useApproveRejectRequests = <T extends RowData>({
  tableData,
  setTableData,
}: UseApproveRejectRequests<T>) => {
  const {
    openBulkApprovalModal,
    openBulkRejectionModal,
    openSingleApprovalModal,
    openSingleRejectionModal,
  } = useMyRequestsContext() as MyRequestsContextType;

  const onRowChecked: RowCheckHandler<T> = ({ index, isChecked }) => {
    setTableData((data) =>
      data.map((rowData, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...rowData,
            isChecked,
          };
        }

        return rowData;
      }),
    );
  };

  const selectedRowsCount = tableData.reduce(
    (totalSoFar, row) => (row.isChecked ? totalSoFar + 1 : totalSoFar),
    0,
  );

  const actionCtasDisabled = useMemo(
    () => tableData.filter((rowData) => rowData.isChecked).length === 0,
    [tableData],
  );

  const handleMultiApproveCtaClicked = (cb?: ApproveRejectWithdrawCallback) => {
    const selectedRowIds = tableData.filter((row) => row.isChecked).map((row) => row.id);

    openBulkApprovalModal(selectedRowIds, cb);
  };

  const handleSingleApproveClicked = (rowData: T, cb?: ApproveRejectWithdrawCallback) => {
    openSingleApprovalModal(rowData.id, cb);
  };

  const handleMultiRejectCtaClicked = (cb?: ApproveRejectWithdrawCallback) => {
    const selectedRowIds = tableData.filter((row) => row.isChecked).map((row) => row.id);

    openBulkRejectionModal(selectedRowIds, cb);
  };

  const handleSingleRejectClicked = (rowData: T, cb?: ApproveRejectWithdrawCallback) => {
    openSingleRejectionModal(rowData.id, cb);
  };

  return {
    onRowChecked,
    actionCtasDisabled,
    selectedRowsCount,
    handleMultiApproveCtaClicked,
    handleMultiRejectCtaClicked,
    handleSingleApproveClicked,
    handleSingleRejectClicked,
  };
};

export { useApproveRejectRequests };
