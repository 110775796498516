import React, { ReactElement, useState } from "react";
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from "components/ui/MainCase";
import KYCVerificationHeader from './KYCVerificationHeader';
import KYCVerificationDocumentBody from './KYCVerificationDocumentBody';
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import { useQuery } from "react-query";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { getOrgFlexiDetails } from "../../utils/init";
import api from '../../../../../api';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "../../utils/queries";
import { AppError } from "utils/AppError";
import { WarningAlert } from "components/ui/Alert";
import Errors from "components/ui/Form/Errors";
import Spinner from "components/ui/Spinner";
import { useSelector } from "react-redux";
import { KYC_STATUS } from 'constants/flexibenefits';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

const ZaggleAdminKYCVerificationPage = () : ReactElement => {

    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const history = useHistory();
    const [flexiDetails, setFlexiDetails] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails())
    const { isLoading, isFetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      }, {
        onSuccess: (data) => data && setFlexiDetails(data),
        onError: (error: typeof AppError) => {
            console.log("Error on fetching Org's flexi details data : ", error);
        }
      });

    if (isLoading) {
        return (
        <div className={'flex justify-center'}>
            <Spinner size={40}/>
        </div>); 
    }

    if (flexiDetails.kycData.status === KYC_STATUS.STATUS_VERIFIED) {
        history.push(routePaths.partnerFlexiBenefits.admin.actionItems);
    }

    if(error){
        return (<WarningAlert><Errors errorResponse={error}/></WarningAlert>);
    }

    return (
        <PageWrapper>
            <MainCase>
                <KYCVerificationHeader data = {flexiDetails}/>
                <KYCVerificationDocumentBody data = {flexiDetails} />
            </MainCase>
        </PageWrapper>
    );
}

export default ZaggleAdminKYCVerificationPage;