import React from 'react';
import { Box, CheckCircleIcon, Heading, Text } from '@razorpay/blade/components';
import { PaymentType } from 'components/RunPayroll/ComplianceActions/components/types';

const paymentName: Record<PaymentType, string> = {
  KA_PT_PAYMENT: 'Karanataka PT Payment',
};

export const SuccessScreen = ({ paymentType }: { paymentType: PaymentType }) => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing.1"
        alignItems="center"
        backgroundColor="surface.background.gray.subtle"
        paddingY="spacing.9">
        <CheckCircleIcon color="feedback.icon.positive.intense" size="xlarge" />
        <Heading size="medium" color="feedback.text.positive.intense" weight="semibold">
          {paymentName[paymentType]} Initiated
        </Heading>
        <Text size="small" color="surface.text.gray.subtle">
          Estimated time: 15-20 minutes
        </Text>
      </Box>
      <Box padding="spacing.6" display="flex" flexDirection="column" gap="spacing.2">
        <Heading color="surface.text.gray.subtle" size="small" weight="semibold">
          Behind the scenes
        </Heading>
        <Text color="surface.text.gray.muted" size="small">
          Your payment has been initiated. We’ll update you with an email on the payment status as
          soon as it’s processed.
        </Text>
      </Box>
    </Box>
  );
};
