import React from 'react';
import {
  Alert,
  AlertTriangleIcon,
  Amount,
  AmountProps,
  Box,
  Button,
  InfoIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { useMutation, useQueryClient } from 'react-query';
import { PaymentType } from 'components/RunPayroll/ComplianceActions/components/types';
import {
  GET_COMPLIANCE_ACTIONS,
  MARK_AS_PAID_COMPLIANCE_ACTION,
} from 'components/RunPayroll/ComplianceActions/queries';
import api from 'api';
import { compliancePaymentModalsZIndex } from 'components/RunPayroll/ComplianceActions/components/data';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { parseError } from 'utils/Api';
import { AppError } from 'utils/AppError';
import { ResetLocalOverridingStyles } from 'components/RunPayroll/ComplianceActions/styles';

export const MarkAsPaidModal = ({
  isOpen,
  onDismiss,
  paymentType,
  amount,
  currency,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  paymentType: PaymentType;
  amount: number;
  currency: AmountProps['currency'];
}) => {
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth || '';
  const queryClient = useQueryClient();
  const {
    mutate: markAsPaidPayment,
    isLoading,
    error,
    status,
  } = useMutation({
    mutationKey: MARK_AS_PAID_COMPLIANCE_ACTION,
    mutationFn: api.complianceActions.markAsPaidComplianceAction,
    onSuccess: () => {
      onDismiss();
      queryClient.invalidateQueries({
        queryKey: [GET_COMPLIANCE_ACTIONS, payrollMonth],
      });
    },
    onError: (e: typeof AppError) => {
      //do nothing
    },
  });

  const handleDismiss = () => {
    if (isLoading) {
      return;
    }
    onDismiss();
    queryClient.invalidateQueries('MARK_AS_PAID_COMPLIANCE_ACTION');
  };

  const handleMarkAsPaidClick = () => {
    markAsPaidPayment({
      payrollMonth,
      type: paymentType,
    });
  };

  const errorMessage = error ? parseError(error) : '';

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} zIndex={compliancePaymentModalsZIndex}>
      <ModalHeader title="Mark as Paid Externally" />
      <ModalBody padding="spacing.0">
        <Box
          display="flex"
          flexDirection="column"
          gap="spacing.7"
          padding={['spacing.6', 'spacing.6', 'spacing.4', 'spacing.6']}>
          <Box>
            <Box display="flex" gap="spacing.2" justifyContent="space-between" alignItems="center">
              <Text size="large">💰 Amount Payable</Text>
              <Amount type="heading" weight="semibold" value={amount} currency={currency} />
            </Box>
          </Box>
          <Box>
            <Alert
              icon={AlertTriangleIcon}
              description="Once marked as paid externally, a payment cannot be reverted to unpaid."
              color="information"
              isDismissible={false}
            />
          </Box>
          <Box>
            <Text size="small" color="surface.text.gray.muted">
              The compliance amount will be credited back to your XPayroll account for manual
              payment.
            </Text>
          </Box>
        </Box>
        {status === 'error' ? (
          <Alert
            icon={InfoIcon}
            color="negative"
            isDismissible={false}
            description={errorMessage}
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <ResetLocalOverridingStyles>
          <Box display="flex" gap="spacing.4" justifyContent="flex-end">
            <Button isDisabled={isLoading} variant="tertiary" onClick={handleDismiss}>
              Cancel
            </Button>
            <Button isLoading={isLoading} variant="primary" onClick={handleMarkAsPaidClick}>
              Paid Externally
            </Button>
          </Box>
        </ResetLocalOverridingStyles>
      </ModalFooter>
    </Modal>
  );
};
