import React, { ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import copy from 'copy-to-clipboard';
import { getStaticMediaUrl } from 'utils/Urls';
import api from 'api';
import { PrimaryButton, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { InputElement, Input } from 'components/ui/Form/Input';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import { useModal } from 'components/ui/Modals';
import ExternalLink from 'components/ui/ExternalLink';
import { routePaths } from 'components/Routes/data';
import ErrorMessage from 'components/ui/ErrorMessage';
import Spinner from 'components/ui/Spinner';
import { GET_PARTNER_ID_QUERY } from './queries';
import partnerIntegrations from 'constants/partnerIntegrations';
import { WarningAlert } from 'components/ui/Alert';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { Card } from 'components/ui/Card';
import tick from 'assets/tick.png';

function SpringVerifyActivation(): ReactElement {
  const [apiKey, setApiKey] = useState('');
  const {  closeModal } = useModal();

  const [isApiKeyCopied, setIsApiKeyCopied] = useState(false);
  const getPartnerDetailsQuery = useQuery(
    GET_PARTNER_ID_QUERY,
    () => {
      return api.partnerSettings.getPartnerDetails(partnerIntegrations.SPRING_VERIFY);
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );
  const partnerId = getPartnerDetailsQuery.data?.partnerId;
  const isOrgConnectedToPartner = getPartnerDetailsQuery.data?.isOrgIntegratedToPartner;

  const handleGenerateApiKeyMutation = useMutation(
    () => {
      return api.partnerSettings.generateApiKey({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: (data) => {
        setApiKey(data.apiKey);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleRefreshApiKeyMutation = useMutation(
    () => {
      return api.partnerSettings.refreshApiKey({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: (data) => {
        setApiKey(data.apiKey);
        setIsApiKeyCopied(false);
        closeModal();
      },
      onError: (error: typeof AppError) => {
        closeModal();
      },
    },
  );

  const handleDisableMutation = useMutation(
    () => {
      return api.partnerSettings.disableIntegration({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: () => {
        closeModal();
      },
      onError: (error: typeof AppError) => {},
    },
  );

  function handleGenerateApiKey() {
    handleGenerateApiKeyMutation.mutate();
  }
  function handleCopy() {
    copy(apiKey);
    setIsApiKeyCopied(true);
  }
  function handleRefreshKey() {
    handleRefreshApiKeyMutation.mutate();
  }
  if (handleDisableMutation.status === 'success') {
    return <Redirect to={routePaths.settings.springVerify.settings} />;
  }

  if (getPartnerDetailsQuery.status === 'loading') {
    return <Spinner />;
  }
  if (getPartnerDetailsQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        <Errors errorResponse={getPartnerDetailsQuery.error}></Errors>
      </ErrorMessage>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-between">
        <h1 className="text-4xl font-bold">Connect RazorpayX Payroll with SpringVerify 🚀</h1>
        <p className="1.7xl pt-4 text-white-o-50">
          Now employee data will automatically sync from RazorpayX Payroll to SpringVerify
        </p>
        <div className="pt-20">
          {handleGenerateApiKeyMutation.status === 'error' && (
            <WarningAlert className="mb-8">
              <Errors errorResponse={handleGenerateApiKeyMutation.error}></Errors>
            </WarningAlert>
          )}
          {handleRefreshApiKeyMutation.status === 'error' && (
            <WarningAlert className="mb-8">
              <Errors errorResponse={handleRefreshApiKeyMutation.error}></Errors>
            </WarningAlert>
          )}
          <p className="font-bold"> Step 1 : Generate the API key</p>
          {!handleGenerateApiKeyMutation.data &&
            (isOrgConnectedToPartner ? (
              <PrimaryButton
                className="text-1.5xl"
                onClick={handleGenerateApiKey}
                isPending={handleGenerateApiKeyMutation.status === 'loading'}>
                Show Key
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className="text-1.5xl"
                onClick={handleGenerateApiKey}
                isPending={handleGenerateApiKeyMutation.status === 'loading'}>
                Generate Now
              </PrimaryButton>
            ))}

          {handleGenerateApiKeyMutation.status === 'success' && handleGenerateApiKeyMutation.data && (
            <>
              <InputElement className="pt-4 pr-4">
                <Input type="text" readOnly value={apiKey}></Input>
              </InputElement>

              <div className="flex pt-4 justify-between">
                {!isApiKeyCopied && (
                  <StandardButton
                    className="text-1.5xl capitalize"
                    onClick={handleCopy}
                    disabled={handleRefreshApiKeyMutation.isLoading ? true : false}>
                    Copy Key
                  </StandardButton>
                )}
                {isApiKeyCopied && (
                  <button
                    disabled
                    className="px-6 py-2.5 text-2xl bg-button-secondary-background hover:bg-button-secondary-background text-white-400 disabled">
                    <div className="flex flex-row mr-8 justify-center">
                      <img className="mr-4" src={getStaticMediaUrl(tick)} alt='tick' />
                      <span>{'Copied!'}</span>
                    </div>
                  </button>
                )}
                <div className="flex flex-row ">
                  <StandardSecondaryButton
                    className="text-1.5xl capitalize"
                    onClick={handleRefreshKey}
                    isPending={handleRefreshApiKeyMutation.status === 'loading'}>
                    Refresh Key
                  </StandardSecondaryButton>
                </div>
              </div>
            </>
          )}
          <div className="pt-20">
            <p className="font-bold"> Step 2 : Input the API key in SpringVerify</p>

            <Card className="mt-8" withTopBorder>
              <NumberedList >
                <NumberedListItem >
                  {' '}
                  <span className='text-1.5xl mt-8 text-white-o-50' >Go to{' '}
                  <ExternalLink
                    className="text-1.5xl text-primary"
                    href="https://portal.in.springverify.com/integrations">
                    {' '}
                    SpringVerify/settings{' '}
                  </ExternalLink></span>
                </NumberedListItem>
                <NumberedListItem><span className='text-1.5xl mt-8 text-white-o-50'>Navigate to the Integrations tab</span></NumberedListItem>
                <NumberedListItem><span className='text-1.5xl mt-8 text-white-o-50'>Go to the RazorpayX Payroll card and click Connect XPayroll{' '}</span>
                  
                </NumberedListItem>
                <NumberedListItem><span className='text-1.5xl mt-8 text-white-o-50'>Enter the API Key and click Connect.</span></NumberedListItem>
              </NumberedList>
            </Card>

            <p className="text-1.5xl mt-8 text-white-o-50">Thats it! You are done 👏</p>
          </div>
        </div>
      </div>
    </>
  );
}

function SpringVerifyActivationWithWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase center>
        <SpringVerifyActivation />
      </MainCase>
    </PageWrapper>
  );
}

export default SpringVerifyActivationWithWrapper;
