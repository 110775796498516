import React, { ReactElement } from "react";
import { SuccessAlert,WarningAlert } from 'components/ui/Alert';

const ZaggleActionItemsAlert = ({success,message} : {success? : boolean|null, message? : string | null}): ReactElement => {

    if(success === null){
        return (<div className="mt-12 mb-16"></div>);
    }
    else if(success === true){
        return(
            <>
                <SuccessAlert className="mt-12 mb-16">
                { message? message : '🎉 Zaggle configuration is successfully saved!'}
                </SuccessAlert>
            </>
        )
    }
    else{
        return(
            <>
                <WarningAlert className="mt-12 mb-16">
                    { message? message : 'There was an issue performing this action. Please try again later or contact XPayroll Support.'}
                </WarningAlert>
            </>
        )
    }
}

export default ZaggleActionItemsAlert;