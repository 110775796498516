import React from 'react';
import styles from './index.module.scss';
import { getStaticMediaUrl } from 'utils/Urls';
import whatsappImage from 'assets/whatsapp/whatsapp-intro.svg';
import { Card } from 'components/ui/Card';
import { PrimaryButton } from '../../../../ui/Button';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../../../api';
import { WHATSAPP_STATUS_QUERY } from '../../queries';
import { SuccessAlert } from 'components/ui/Alert';
import ErrorMessage from 'components/ui/ErrorMessage';
import { AppError } from 'utils/AppError';
import { TickList, TickListItem } from 'components/ui/TickList';

function WhatsAppIntroduction() {
  const queryClient = useQueryClient();

  const ActivationMutation = useMutation(() => api.whatsappSettings.activate(), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(WHATSAPP_STATUS_QUERY);
    },
    onError: (error: typeof AppError) => {},
  });

  const handleActivation = () => {
    ActivationMutation.mutate();
  };

  if (ActivationMutation.status === 'error') {
    return (
      <ErrorMessage title="Sorry! Something went wrong!">{ActivationMutation.error}</ErrorMessage>
    );
  }

  return (
    <>
      <SuccessAlert className="border-green-100 mb-12">
        WhatsApp integration is disabled for employees
      </SuccessAlert>
      <div className="flex flex-col items-center">
        <img className={styles['HeadlineImage']} src={getStaticMediaUrl(whatsappImage)} />
        <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
          WhatsApp integration is disabled for your employees
        </div>
        <div className="text-white-o-70 text-center">
          Employees will not be able to connect WhatsApp to their accounts.
        </div>
        <div>
          <Card className={`${styles['Card']} my-12 px-12 py-12`} withTopBorder>
            <div className="font-bold text-white-o-80 mb-8">
              If enabled, through WhatsApp employees can:
            </div>
            <TickList>
              <TickListItem>Apply for reimbursements</TickListItem>
              <TickListItem>Get monthly payslips</TickListItem>
              <TickListItem>Request and access past month payslips</TickListItem>
            </TickList>
          </Card>
        </div>

        <PrimaryButton
          className="mt-12"
          onClick={handleActivation}
          isPending={ActivationMutation.status === 'loading'}>
          Enable WhatsApp for employees
        </PrimaryButton>
      </div>
    </>
  );
}

export default WhatsAppIntroduction;
