import React from 'react';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import { FlexiWalletBudget } from '../../../../api/flexiPartnerDetails';
import { FuelCarType } from '../../../../constants/flexibenefits';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';

export function WalletWiseDeclaration({
                                        employeeFlexiBenefitsDetails,
                                        detailsToSync,
                                        setDetailsToSync,
                                        setErrorMessage,
                                      }: { employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract, detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract, setDetailsToSync: (detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract) => void, setErrorMessage: (message: string) => void }) {

  let totalAmount = 0;

  let walletsHash: Record<number, FlexiWalletBudget> = {};

  for (let wallet of detailsToSync.walletBudgets) {
    walletsHash[wallet.id] = wallet;
    totalAmount += wallet.amount;
  }

  const updateWallet = (walletToUpdate: FlexiWalletBudget) => {
    setErrorMessage('');
    let walletBudgets: FlexiWalletBudget[] = [];
    for (let walletDetail of detailsToSync.walletBudgets) {
      if (walletDetail.id === walletToUpdate.id) {
        walletBudgets.push({
          ...walletDetail,
          amount: walletToUpdate.amount,
          compliance: walletToUpdate.compliance,
        });
      } else {
        walletBudgets.push({ ...walletDetail });
      }
    }

    setDetailsToSync({ ...detailsToSync, walletBudgets });

  };

  const currentCaseComplianceRule = (walletId: number | null, currentCase: string | null) => {

    for (let wallet of employeeFlexiBenefitsDetails.walletBudgets) {
      if (wallet.id === walletId) {
        for (const rule of wallet.wallet.compliance.caseWiseRules) {
          if (rule.case === currentCase) {
            return rule;
          }
        }
      }
    }

    return {
      min: 0,
      max: 0,
      case: null,
    };
  };


  return (<form className={'w-full'}>
    <table className={'table__style--fullWidth'}>
      <tr className={'h-24'}>
        <th>
          Flexible Wallets
        </th>
        <th>
          Min. limit per month
        </th>
        <th>
          Max. limit per month
        </th>
        <th>
          Monthly declaration
        </th>
      </tr>
      {
        employeeFlexiBenefitsDetails.walletBudgets.map((wallet) => {
          const rule = currentCaseComplianceRule(wallet.id, walletsHash[wallet.id].compliance.case ?? null);
          return (<tr className={'min-h-24'}>
            <td style={{ textAlign: 'left' }}>
              {wallet.wallet.name}
              {wallet.wallet.compliance.type === 'fuel' ?
                <div className={'py-4'}>
                  <label className={'label--values label--values ml-4 text-xl'}>
                    <input type='checkbox' name={FuelCarType.CAR_ABOVE_1600_CC}
                           defaultChecked={rule.case === FuelCarType.CAR_ABOVE_1600_CC}
                           onChange={(event) => {
                             updateWallet({
                               id: (walletsHash[wallet.id].id ?? wallet.id),
                               amount: (walletsHash[wallet.id].amount ?? wallet.amount),
                               compliance: { case: event.target.checked ? FuelCarType.CAR_ABOVE_1600_CC : FuelCarType.CAR_BELOW_1600_CC },
                             });
                           }} />
                    Car above 1600CC
                  </label>
                </div>
                : <></>
              }
            </td>
            <td>
              {rule.min}
            </td>
            <td>
              {rule.max}
            </td>
            <td>
              <label>
                <input type='text'
                       name=''
                       placeholder=''
                       defaultValue={walletsHash[wallet.id] ? walletsHash[wallet.id].amount : 0}
                       onChange={(event) => {
                         updateWallet({
                           id: wallet.id,
                           amount: +event.currentTarget.value,
                           compliance: { case: rule.case },
                         });
                       }}
                />
              </label>
            </td>
          </tr>);
        })
      }
      <tr className={'h-24'}>
        <td>
          Total amount
        </td>
        <td>

        </td>
        <td>

        </td>
        <td>
          {toIndianCurrencyString(totalAmount)}
        </td>
      </tr>
    </table>
  </form>);
}