import React from "react";
import PageWrapper from "components/ui/PageWrapper";
import MainCase from "components/ui/MainCase";
import RightSidebar from 'components/ui/RightSidebar';
import jibbleCommonStyles from "../../index.module.scss";
import {getStaticMediaUrl} from "../../../../../utils/Urls";
import jibbleSignature from "../../../../../assets/jibble/signature--jibble.svg";
import AttendanceSettingsForm from "../AttendanceSettingsForm";
import styles from "./index.module.scss";
import {LinkButtonV2} from "../../../../ui/Button";
import linkArrow from "../../../../../assets/link-arrow.svg";
import {ClickableCard} from "../../../../ui/SidebarCard";
import {routePaths} from "../../../../Routes/data";
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../../reducers/loggedInUser/selectors";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "../../utils/queries";
import api from "../../../../../api";
import APIError from "../../../../../types/apiError";
import ErrorMessage from "../../../../ui/ErrorMessage";

const EmployeeDetailsPageCard = () => {
    const history = useHistory();
    return (
        <ClickableCard
            heading="Jibble Employee Details"
            onClick={() => history.push(routePaths.settings.jibble.employees)}
            imageSymbolType={'requests'}
        >
            View all employees’ Jibble account integration status
        </ClickableCard>
    )
}

const JibbleSettingsConfiguration = () => {
    const { jibbleDashboardLink } = useSelector(loggedInUserSelectors.referenceLinks);
    const history = useHistory();

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occurred!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery?.data) {
        if (!integrationStatusQuery.data.is_enabled) {
            history.push(routePaths.settings.jibble.onboarding.activation);
        }
        if (!integrationStatusQuery.data.is_onboarding_completed) {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    return (
        <div className={jibbleCommonStyles['container']}>
            <h1 className="text-4.5xl font-bold mb-10">Leaves & Attendance</h1>
            <div className={styles['infoContainer']}>
                <div className={styles['info']}>
                    <div className={"flex items-center"}>
                        <span className="text-1.29xl italic font-bold ">Managed by</span>
                        <img className="h-6 ml-3" src={getStaticMediaUrl(jibbleSignature)} alt={"Jibble"}/>
                    </div>
                    <p className="text-1.5xl opacity-85 mt-6 mb-8">Leaves and attendance for your organisation are managed by Jibble. To configure & track employees leaves and attendance visit Jibble</p>
                    <a className="flex items-center text-1.5xl" target="_blank" rel="noreferrer noopener" href={jibbleDashboardLink}>
                        <LinkButtonV2>Go to Jibble settings</LinkButtonV2>
                        <img src={getStaticMediaUrl(linkArrow)} alt="" className="pl-4 h-5" />
                    </a>
                </div>
            </div>
            <AttendanceSettingsForm isOnboardingComplete={true} />
        </div>
    );
}

const JibbleSettingsConfigurationPageWrapper = () => (
    <PageWrapper>
        <MainCase center>
            <JibbleSettingsConfiguration/>
        </MainCase>
        <RightSidebar>
            <EmployeeDetailsPageCard />
        </RightSidebar>
    </PageWrapper>
)

export default JibbleSettingsConfigurationPageWrapper;