import { Experiments } from 'api/splitz';
import { splitzExperiments } from 'constants/splitz';
import { SplitzExperiment } from 'types';
import { Values } from 'types/utils';

type ExperimentNames = Values<typeof splitzExperiments>;

export const getSplitzExperimentData = (
  experiments: Experiments = {},
  experimentName: ExperimentNames,
) => {
  const experiment = (experiments[experimentName] || {}) as SplitzExperiment;
  return experiment;
};

export const isSplitzExperimentActive = (
  experiments: Experiments,
  experimentName: ExperimentNames,
  fieldName = 'result',
  checkValue = 'on',
) => {
  const experiment = getSplitzExperimentData(experiments, experimentName);
  return experiment.variables?.[fieldName] === checkValue;
};
