import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

interface BulletListItemProps {
  children?: React.ReactNode;
  className?: string;
}

interface BulletListProps {
  children?: React.ReactNode;
  className?: string;
}

export const BulletListItem = ({ children, className = '' }: BulletListItemProps) => (
  <li className={cx(styles['BulletListItem'], 'text-white-o-80', className)}>{children}</li>
);

const BulletList = ({ children, className = '' }: BulletListProps) => {
  return <ul className={cx(styles['BulletList'], className)}>{children}</ul>;
};

export default BulletList;
