import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* { 
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.surface.text.gray.subtle};
}

body {
  margin: 0;
  padding: 0;
  font-family: ${(props) => props.theme.typography.fonts.family.text}
}

h1, h2, h3, h4, h5, h6 {
  font-family: ${(props) => props.theme.typography.fonts.family.heading};
}

code {
    font-family: ${(props) => props.theme.typography.fonts.family.code};
  }
`;

export default GlobalStyles;
