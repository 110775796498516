import React, { ReactElement, useState, useEffect } from 'react';
import { TableWithLoader } from 'components/ui/Table';
import { toIndianCurrencyString } from 'utils/Numbers';
import styles from '../index.module.scss';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { LoanRecoveryPreviewResponseSchemaContract } from '../../../schemas/LoanRecoveryPreviewResponseSchema';
import { useMutation } from 'react-query';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import api from '../../../api/index';
import { LoanRecoveryStatus } from '../constants';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { LOAN_RECOVERY_PREVIEW } from '../queries';

function getStatusPill(status: number) {
  switch (status) {
    case LoanRecoveryStatus.PAID:
      return <Pill content="Paid" type={pillClassTypeMap.success} />;
    case LoanRecoveryStatus.PENDING:
      return <Pill content="Pending" type={pillClassTypeMap.warning} />;
    case LoanRecoveryStatus.SKIPPED:
      return <Pill content="Skipped" type={pillClassTypeMap.default} />;
    case LoanRecoveryStatus.PARTIAL_RECOVERY:
      return <Pill content="Partial Recovery" type={pillClassTypeMap.default} />;
    case LoanRecoveryStatus.FULL_RECOVERY:
      return <Pill content="Full Recovery" type={pillClassTypeMap.default} />;
    default:
      return <Pill content="Unknown" type={pillClassTypeMap.default} />;
  }
}

const LoanRecoveryPreviewTable = function ({
  loanId,
  requestData,
  children,
  setPreviewError,
}: {
  children?: ({ isLoading }: { isLoading: boolean }) => ReactElement;
  loanId: number;
  requestData: {
    action?: string;
    option?: number;
    amount?: number;
    tenure?: number;
    payroll_month?: string;
  };
  setPreviewError?: (message: typeof AppError) => void;
}): ReactElement {
  const getPreviewRecords = useQuery(
    LOAN_RECOVERY_PREVIEW,
    () => {
      return api.loans.recoveryPreview(
        loanId,
        requestData.action,
        requestData.option,
        requestData.amount,
        requestData.tenure,
        requestData.payroll_month,
      );
    },
    {
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        setPreviewError && setPreviewError(error);
      },
    },
  );

  useEffect(() => {
    getPreviewRecords.refetch();
  }, [requestData, loanId]);

  return (
    <>
      {children &&
        children({ isLoading: getPreviewRecords.isLoading || getPreviewRecords.isFetching })}
      {getPreviewRecords.status !== 'error' && (
        <div>
          <TableWithLoader
            showAlternateColors={true}
            isLoading={getPreviewRecords.isLoading || getPreviewRecords.isFetching}
            className={styles['emi-preview-table']}>
            <tbody>
              <tr>
                <th className={styles['header-text-font']}>Payroll Month</th>
                <th className={styles['header-text-font']}>EMI Amount </th>
                <th className={styles['header-text-font']}>Outstanding Amount </th>
                <th className={styles['header-text-font']}>Status</th>
              </tr>
              {!getPreviewRecords.isError &&
                getPreviewRecords.data &&
                getPreviewRecords.data.data.length > 0 &&
                getPreviewRecords.data.data.map(
                  (recoveryRecord: LoanRecoveryPreviewResponseSchemaContract['data'][0]) => (
                    <tr>
                      <td className={styles['value-text-font']}>
                        {format(new Date(recoveryRecord.payroll_month), 'MMMM yy')}
                      </td>
                      <td className={styles['value-text-font']}>
                        {recoveryRecord.emi_amount !== null
                          ? toIndianCurrencyString(recoveryRecord.emi_amount, true)
                          : '-'}
                      </td>
                      <td className={styles['value-text-font']}>
                        {recoveryRecord.remaining_total_amount !== null
                          ? toIndianCurrencyString(recoveryRecord.remaining_total_amount, true)
                          : '-'}
                      </td>
                      <td className={styles['value-text-font']}>
                        {getStatusPill(recoveryRecord.status)}
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </TableWithLoader>
        </div>
      )}
      {getPreviewRecords.status === 'error' && (
        <WarningAlert className="my-6">
          <Errors errorResponse={getPreviewRecords.error} />
        </WarningAlert>
      )}
    </>
  );
};

export default LoanRecoveryPreviewTable;
