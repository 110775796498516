import { Box } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import {
  WizardHeader,
  WizardProgressView,
  WizardWrapper
} from 'components/WizardViews/components';
import { WizardInfoProvider } from 'components/WizardViews/providers';
import { Wizard, WizardStep } from 'forms/Wizard';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createDeductionsRouteToStepMap, deductionsSteps } from '../constants';
import GeneralDetailsStep from './GeneralDetailsStep';
import PayAndTaxStep from './PayAndTaxStep';
import ReviewStep from './ReviewStep';

const CreateDeductionsForm = () => {
  const location = useLocation();
  const currentStep =
    createDeductionsRouteToStepMap[
      location.pathname as keyof typeof createDeductionsRouteToStepMap
    ] || deductionsSteps.step1;

  const history = useHistory();

  const handleCloseClick = () => {
    history.push(routePaths.salaryComponents.deductionsListView);
  };

  return (
    <WizardInfoProvider currentStep={currentStep} totalSteps={3}>
      <WizardWrapper>
        <WizardHeader
          heading="New"
          subHeading="Deduction Component"
          onCloseClick={handleCloseClick}>
          <WizardProgressView
            steps={[
              { label: 'General & Type', position: deductionsSteps.step1 },
              { label: 'Pay & Taxability', position: deductionsSteps.step2 },
              { label: 'Review', position: deductionsSteps.step3 },
            ]}
          />
        </WizardHeader>
        <Box flex={1} overflowY="auto" height="100%">
          <Wizard>
            <WizardStep step={deductionsSteps.step1} component={GeneralDetailsStep} />
            <WizardStep step={deductionsSteps.step2} component={PayAndTaxStep} />
            <WizardStep step={deductionsSteps.step3} component={ReviewStep} />
          </Wizard>
        </Box>
      </WizardWrapper>
    </WizardInfoProvider>
  );
};

export default CreateDeductionsForm;
