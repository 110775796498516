import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps
} from '@razorpay/blade/components';

type Props = ModalProps & {
  headingText: string;
  submitButton: React.ReactNode;
};

const ActionModal = ({
  isOpen,
  onDismiss,
  headingText,
  submitButton,
  children
}: Props) => (
  <Modal isOpen={isOpen} onDismiss={onDismiss} zIndex={1002}>
    <ModalHeader title={headingText} />
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        gap={'spacing.5'}
        padding={['spacing.0', 'spacing.4', 'spacing.4']}>
        <Button size={'medium'} onClick={onDismiss} variant={'tertiary'}>
          Cancel
        </Button>
        {submitButton}
      </Box>
    </ModalFooter>
  </Modal>
);

export default ActionModal;