import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  ActionList,
  ActionListItem,
  Button,
  Dropdown,
  DropdownOverlay,
  Link,
  SelectInput,
  TextArea,
  TextInput,
} from '@razorpay/blade/components';
import { FormContainer } from 'components/Onboarding/CompanyDetails/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useCompanyVerificationData } from '../hooks/useCompanyVerificationDetails';
import { statesOfIndia } from 'utils/StatesOfIndia';
import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { PINCODE_REGEX } from 'utils/regexes';
import { GetDetailsByPincodeSchemaContract } from 'schemas/GetDetailsByPincodeSchema';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { useVerifyCompanyDetailsContext } from './VerifyCompanyDetailsContext';
import { useDispatch } from 'react-redux';
import { fetchLoggedInUser } from 'reducers/loggedInUser';

const AddAddress = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pincode, address1, address2, stateCode, isCompanyVerified } =
    useCompanyVerificationData();
  const { setAlertBannerPropsContext } = useVerifyCompanyDetailsContext();
  const [pincodeDetails, setPincodeDetails] = useState<GetDetailsByPincodeSchemaContract>();
  const [formData, setFormData] = useState({
    pincode: pincode ?? '',
    stateCode: stateCode?.toString() ?? '',
    addressLineOne: address1 ?? '',
    addressLineTwo: address2 ?? '',
  });
  const [validationErrors, setValidationErrors] = useState({
    pincode: '',
    stateCode: '',
  });

  const { state: { gstin, cinOrLlpin, signatoryPan } = {} } = useLocation<{
    gstin?: string;
    cinOrLlpin?: string;
    signatoryPan?: string;
    errorFetchingAddressFromBvs?: boolean;
  }>();
  const isThisSecondStep = !!gstin || !!cinOrLlpin || !!signatoryPan;

  const pincodeDetailsQuery = useQuery({
    queryFn: () => api.companyOnboarding.getDetailsByPincode(formData.pincode),
    onSuccess: (data) => {
      if (data) {
        setPincodeDetails(data);

        if (data.stateCode) {
          setFormData((formData) => ({
            ...formData,
            stateCode: data.stateCode?.toString() as string,
          }));
        }
      }
    },
    onError: (error) => {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        pincode: errorParser(getAppErrorDetails(error))[0],
      }));
    },
    enabled: false,
  });

  useEffect(() => {
    if (formData.pincode && PINCODE_REGEX.test(formData.pincode)) {
      pincodeDetailsQuery.refetch();
    }
  }, [formData.pincode]);

  const validateFields = () => {
    let result = true;
    if (!PINCODE_REGEX.test(formData.pincode)) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        pincode: 'Please enter a valid pincode',
      }));
      result = false;
    }

    if (Number(formData.stateCode) > statesOfIndia.length || Number(formData.stateCode) < 1) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        stateCode: 'Please select a valid state',
      }));
      result = false;
    }

    if (pincodeDetails?.stateCode?.toString() !== formData.stateCode) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        stateCode: 'State chosen does not match the pincode',
      }));
      result = false;
    }

    return result;
  };

  const updateAddressMutation = useMutation({
    mutationFn: api.companyOnboarding.updateCompanyAddress,
    onSuccess: (data) => {
      if (data.success) {
        setAlertBannerPropsContext(undefined);
        dispatch(fetchLoggedInUser());
        history.push(routePaths.kyc.bankDetails);
      }
    },
    onError: (error) => {
      setAlertBannerPropsContext({
        title: 'Verification failed',
        color: 'negative',
        description: errorParser(getAppErrorDetails(error))[0],
        isDismissible: false,
      });
    },
  });

  const redirectToDashboard = () => {
    window.location.href = '/dashboard';
  };

  // if the company verification is already completed, redirect back to dashboard
  if (isCompanyVerified) {
    redirectToDashboard();
    return null;
  }

  const handleTextFieldsChange = (e: { name?: string; value?: string }) => {
    if (!e.name) return;

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      [e.name as string]: '',
    }));

    setFormData((formData) => ({
      ...formData,
      [e.name as string]: e.value,
    }));
  };

  const handleDropdownFieldsChange = (e: { name?: string; values: string[] }) => {
    if (!e.name) return;

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      [e.name as string]: '',
    }));

    setFormData({
      ...formData,
      [e.name]: e.values[0],
    });
  };

  const handleHaveGstinClick = (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault();

    history.push(routePaths.kyc.companyDetailsVerification.gstin);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    updateAddressMutation.mutate({
      pincode: formData.pincode,
      state: Number(formData.stateCode),
      addressLineOne: formData.addressLineOne,
      addressLineTwo: formData.addressLineTwo,
    });
  };

  const handlePinCodeFieldBlur = () => {
    if (formData.pincode && !PINCODE_REGEX.test(formData.pincode)) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        pincode: 'Please enter a valid pincode',
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <TextInput
          label="Pincode"
          name="pincode"
          placeholder="Eg. 208011"
          value={formData.pincode}
          onChange={handleTextFieldsChange}
          onBlur={handlePinCodeFieldBlur}
          isRequired
          necessityIndicator="required"
          validationState={validationErrors.pincode ? 'error' : 'none'}
          errorText={validationErrors.pincode}
          isLoading={pincodeDetailsQuery.isFetching}
        />
        <Dropdown>
          <SelectInput
            label="State"
            name="stateCode"
            value={formData.stateCode}
            onChange={handleDropdownFieldsChange}
            isRequired
            necessityIndicator="required"
            isDisabled={pincodeDetailsQuery.isFetching}
            validationState={validationErrors.stateCode ? 'error' : 'none'}
            errorText={validationErrors.stateCode}
          />
          <DropdownOverlay>
            <ActionList>
              {statesOfIndia.map(({ code, name }) => (
                <ActionListItem title={name} value={code.toString()} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        <TextArea
          label="Address Line 1"
          name="addressLineOne"
          value={formData.addressLineOne}
          placeholder="Address Line 1"
          onChange={handleTextFieldsChange}
          isRequired
          necessityIndicator="required"
        />
        <TextArea
          label="Address Line 2"
          name="addressLineTwo"
          value={formData.addressLineTwo}
          placeholder="Address Line 2"
          onChange={handleTextFieldsChange}
        />
        {!isThisSecondStep && (
          <Link onClick={handleHaveGstinClick}>Have GSTIN? Pre-fill address→</Link>
        )}
      </FormContainer>
      <Button type="submit" marginTop="spacing.10" isLoading={updateAddressMutation.isLoading}>
        Continue
      </Button>
    </form>
  );
};

export { AddAddress };
