import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';
import React, { useMemo, useState } from 'react';
import { Switch } from 'react-router-dom';
import { CompanyDetails } from './CompanyDetails';
import { DelightScreenContext, OnboardingContext } from './OnboardingContext';

const Onboarding = () => {
  const [delightScreen, setDelightScreen] = useState<DelightScreenContext>({
    isVisible: false,
    content: <></>,
  });

  const contextValue = useMemo(
    () => ({
      delightScreen,
      setDelightScreen,
    }),
    [delightScreen],
  );

  return (
    <OnboardingContext.Provider value={contextValue}>
      <Switch>
        <RestrictedRoute
          path={routePaths.onboarding.companyDetails.root}
          component={CompanyDetails}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
      </Switch>
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
