import { IInsurance } from 'reducers/insurance';
import { IInsuranceEstimate } from '../../../reducers/insuranceEstimate';
import { ILoggedInUser } from '../../../reducers/loggedInUser';
import { IInsuranceEndorsements } from '../../../reducers/insuranceEndorsements';

export function getConditionsNotMetToGetExactEstimate(
  insuranceEstimate: IInsuranceEstimate,
  loggedInUser: ILoggedInUser,
): string[] {
  if (insuranceEstimate.data && insuranceEstimate.data.policyRestrictionsForEstimate.hasError) {
    return insuranceEstimate.data.policyRestrictionsForEstimate.issues;
  }
  return [];
}

export function getConditionsNotMetToPurchaseInsurance(
  insuranceEstimate: IInsuranceEstimate,
  loggedInUser: ILoggedInUser,
  insurance: IInsurance,
): string[] {
  if (insuranceEstimate.data && insuranceEstimate.data.policyRestrictionsForPurchase.hasError) {
    return insuranceEstimate.data.policyRestrictionsForPurchase.issues;
  }
  return [];
}

export function getConditionsNotMetForEndorsement(endorsement: IInsuranceEndorsements): string[] {
  if (endorsement.data && endorsement.data.policyRestrictionsForEndorsements.hasError) {
    return endorsement.data.policyRestrictionsForEndorsements.issues;
  }
  return [];
}
