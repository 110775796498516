import { ACCOUNT_TYPE, CHANNEL } from './constants';

export const getBankName = (channel: string) => {
  switch (channel) {
    case CHANNEL.RBL:
      return 'RBL';
    case CHANNEL.YESBANK:
      return 'Yes Bank';
    case CHANNEL.ICICI:
      return 'ICICI';
    default:
      return channel;
  }
};

export const getAccountType = (type: string) => {
  switch (type) {
    case ACCOUNT_TYPE.CURRENT:
      return 'Current Account';
    case ACCOUNT_TYPE.NODAL:
      return 'Virtual Account';
    default:
      return type;
  }
};
