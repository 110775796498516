import React from 'react';
import { Box, Text, Badge, Button, Heading, Amount, useTheme } from '@razorpay/blade/components';
import styled from 'styled-components';
import { ChatContextType, useChatContext } from 'components/PayrollAIAssistant/ChatContext';
import { useBreakpoint } from '@razorpay/blade/utils';

export const TaxSavingItemsWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: ${theme.spacing[4]}px 0px;
    
    border: 1px solid rgb(139, 255, 235, 0.5);
    border-right: none;
    border-bottom: none;
    max-width: 700px;
    background: linear-gradient(86deg, rgba(8, 13, 41, 0) -11.08%, rgba(29, 75, 99, 0.64) 116.23%);
  `,
);

export const TaxSavingItemWrapper = styled.div<{ hasborder: boolean }>(
  ({ theme, hasborder }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${theme.spacing[4]}px ${theme.spacing[7]}px;
    gap: ${theme.spacing[5]}px;
    ${hasborder ? 'border-bottom: 1px solid rgb(255, 255, 255, 0.1);' : ''}

    @media (min-width: ${theme.breakpoints.s}px) {
      flex-wrap: nowrap;
      flex-direction: row;
      gap: ${theme.spacing[5]}px;
    }
  `,
);

const TaxSavingImpact = {
  HIGH: 'High Impact',
  POPULAR: 'Popular',
  LOW: 'Low',
};

const TaxSavingItems = ({
  sections,
  regime,
}: {
  sections: {
    [k: string]: unknown;
    name: string;
    savings_possible: number;
    description?: string | undefined;
    regime: string;
    know_more?: string | undefined;
    impact?: string | undefined;
  }[];
  regime: string;
}) => {
  const sectionsToShow = sections.filter((item) => item.regime === regime);

  if (!sectionsToShow.length) {
    return null;
  }

  return (
    (<TaxSavingItemsWrapper>
      <Box
        display={{
          base: 'none',
          s: 'flex',
        }}
        gap="spacing.3"
        padding={['spacing.4', 'spacing.7']}
        width="100%">
        <Box
          flex="1"
          flexBasis={{
            base: '0',
            s: '120px',
          }}>
          <Text size="small" weight="semibold">
            Category
          </Text>
        </Box>
        <Box
          flex="1"
          flexBasis={{
            base: '0',
            s: '50px',
          }}>
          <Text size="small" weight="semibold">
            Invest upto
          </Text>
        </Box>
        <Box
          flex="1"
          flexBasis={{
            base: '0',
            s: '50px',
          }}
        />
      </Box>
      {sectionsToShow.map((item, index) => {
        return (
          <TaxItem
            key={item.name}
            header={item.name}
            subHeader={item.description ?? ''}
            taxSaving={item.savings_possible}
            impact={
              item.impact
                ? item.impact === 'high'
                  ? TaxSavingImpact.HIGH
                  : item.impact === 'medium'
                  ? TaxSavingImpact.POPULAR
                  : TaxSavingImpact.LOW
                : ''
            }
            hasBorder={index + 1 < sectionsToShow.length}
            knowMoreContent={item.know_more}
          />
        );
      })}
    </TaxSavingItemsWrapper>)
  );
};

const TaxItem = ({
  header,
  subHeader,
  taxSaving,
  impact,
  hasBorder,
  knowMoreContent,
}: {
  header: string;
  subHeader: string;
  taxSaving: number;
  impact?: string;
  hasBorder: boolean;
  knowMoreContent?: string;
}) => {
  const { pushMessage, setInputSuggestions } = useChatContext() as ChatContextType;
  const { theme } = useTheme();
  const { matchedBreakpoint } = useBreakpoint({ breakpoints: theme.breakpoints });

  const onClickKnowMore = () => {
    pushMessage(
      {
        type: 'text',
        initiator: 'user',
        messageData: `I want to know more about ${header} tax saving options`,
      },
      () => {
        setInputSuggestions([]);
        pushMessage({
          type: 'knowMore',
          initiator: 'bot',
          messageData: `${knowMoreContent ? knowMoreContent : "I'm Sorry, I don't know more about this."}\n\n🤝 That’s all! Do you want to add or remove any investment options?`
        });
      },
    );
  };

  return (
    (<TaxSavingItemWrapper hasborder={hasBorder}>
      <Box display="flex" flexDirection="column" flex="1" flexBasis={{ base: '0', s: '120px' }}>
        <Box display="flex" flexDirection="row" gap={'spacing.3'}>
          <Text size="large">{header}</Text>
          {impact && <Badge>{impact}</Badge>}
        </Box>
        <Text size="xsmall" color="surface.text.gray.muted">
          {subHeader}
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" flex="1" flexBasis={{ base: '0', s: '50px' }}>
        {(matchedBreakpoint === 'base' || matchedBreakpoint === 'xs') && (
          <Text size="small" weight="semibold">
            Invest upto
          </Text>
        )}
        <Amount value={taxSaving} suffix="none" type="heading" size="large" />
      </Box>
      <Box
        display="flex"
        flex="1"
        flexBasis={{ base: '0', s: '50px' }}
        justifyContent={{
          base: 'flex-start',
          s: 'end',
        }}
        width={{
          base: '100%',
          s: 'auto',
        }}>
        <Button
          size="small"
          variant="secondary"
          onClick={onClickKnowMore}
          isFullWidth={matchedBreakpoint === 'base' || matchedBreakpoint === 'xs'}>
          Know More
        </Button>
      </Box>
    </TaxSavingItemWrapper>)
  );
};

export default TaxSavingItems;
