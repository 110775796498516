import jibbleSignature from 'assets/jibble/signature--jibble.svg';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import api from 'api';
import { SyncAttendanceDataProps } from 'api/jibbleSettings';
import { JibbleAttendanceDataSchemaContract } from 'schemas/JibbleAttendanceDataSchema';
import APIError from 'types/apiError';
import { dateDiffInDays } from 'utils/Dates';
import { getStaticMediaUrl } from 'utils/Urls';
import { routePaths } from 'components/Routes/data';
import ErrorMessage from 'components/ui/ErrorMessage';
import {
  AttendanceSyncPendingProps,
  FetchingExistingAttendanceProps,
  FetchingNewAttendanceProps,
  HidePreSyncStateProps,
} from '../../data';
import { usePollAttendanceData } from '../../hooks/usePollAttendanceData';
import { JIBBLE_INTEGRATION_STATUS_QUERY } from '../../utils/queries';
import DataDisplay from './DataDisplay';
import DateRangeForm from './DateRangeForm';
import PreSyncDisplay from './PreSyncDisplay';
import ReportMonthSelector from './ReportMonthSelector';
import styles from './index.module.scss';
import { useSearchParams } from 'hooks/useSearchParams';
import Spinner from 'components/ui/Spinner';
import { WarningAlert } from 'components/ui/Alert';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';

const JibbleAttendanceReport = () => {
  const queryParam = useSearchParams().urlParams;
  const queryReportMonth = queryParam.get('report_month');
  const [reportMonth, setReportMonth] = useState<string | null>(queryReportMonth ?? null);
  const [reportFromDate, setReportFromDate] = useState<Date | null>(null);
  const [reportToDate, setReportToDate] = useState<Date | null>(null);
  const [lastReportFromDate, setLastReportFromDate] = useState<Date | null>(null);
  const [lastReportToDate, setLastReportToDate] = useState<Date | null>(null);
  const [lastSyncDate, setLastSyncDate] = useState<Date | null>(null);
  const [orgAttendanceData, setOrgAttendanceData] = useState<JibbleAttendanceDataSchemaContract>();
  const [page, setPage] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [payrollBlockedIds, setPayrollBlockedIds] = useState<number[]>([]);
  const [payrollFinalizedIds, setPayrollFinalizedIds] = useState<number[]>([]);
  const [canAddDeductionIds, setCanAddDeductionIds] = useState<number[]>([]);
  const [canEditLopDaysIds, setCanEditLopDaysIds] = useState<number[]>([]);
  const [isDateSelectionDisabled, setIsDateSelectionDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const history = useHistory();

  const integrationStatusQuery = useQuery(
    JIBBLE_INTEGRATION_STATUS_QUERY,
    () => {
      return api.jibbleSettings.fetchIntegrationStatus();
    },
    {
      onError: (error: APIError) => setError(error.message),
    },
  );

  const setPageData = (data: JibbleAttendanceDataSchemaContract) => {
    const orgAttendanceData = data.attendance_data.data;
    setOrgAttendanceData(data);
    setTotalRows(data.attendance_data.total);
    setTotalPages(Math.ceil(data.attendance_data.total / rowsLimit));
    const shouldRetainDates = data.payroll_month === reportMonth;
    setReportMonth(data.payroll_month);
    history.replace(`/jibble/attendance-report?report_month=${data.payroll_month}`);
    if (orgAttendanceData.length > 0) {
      setReportFromDate(new Date(orgAttendanceData[0].attendance_data.fromDate));
      setReportToDate(new Date(orgAttendanceData[0].attendance_data.toDate));
      setLastReportFromDate(new Date(orgAttendanceData[0].attendance_data.fromDate));
      setLastReportToDate(new Date(orgAttendanceData[0].attendance_data.toDate));
      setLastSyncDate(new Date(orgAttendanceData[0].attendance_data.syncTime));
    } else {
      if (!shouldRetainDates) {
        setReportFromDate(null);
        setReportToDate(null);
        setLastReportFromDate(null);
        setLastReportToDate(null);
      }
      setLastSyncDate(null);
    }
    const payrollStatus = data['payroll_status'] ?? [];
    setPayrollBlockedIds([]);
    setPayrollFinalizedIds([]);
    const pendingPayrollMeta = payrollStatus.filter((meta) => {
      if (meta.isBlocked) {
        setPayrollBlockedIds((existingIds) => [...existingIds, meta.attendanceId]);
        return false;
      }
      if (meta.isFinal) {
        setPayrollFinalizedIds((existingIds) => [...existingIds, meta.attendanceId]);
        return false;
      }
      return true;
    });
    setCanEditLopDaysIds(payrollStatus.map((meta) => meta.attendanceId));
    setCanAddDeductionIds(pendingPayrollMeta.map((meta) => meta.attendanceId));
    if (payrollStatus.length !== pendingPayrollMeta.length) {
      setIsDateSelectionDisabled(true);
    } else {
      setIsDateSelectionDisabled(false);
    }
  };

  const pollAttendanceData = usePollAttendanceData({
    reportMonth,
    page,
    rowsLimit,
    onError: (error) => setError(error),
    onSuccess: (data) => setPageData(data),
  });

  const jibbleSyncNewAttendanceData = useMutation(
    (requestData: SyncAttendanceDataProps) => {
      setError('');
      return api.jibbleSettings.syncAttendanceData(requestData);
    },
    {
      onError: (error: APIError) => {
        setError(error.message);
      },
      onSuccess: (data) => {
        setReportFromDate(new Date(data.from_date));
        setReportToDate(new Date(data.to_date));
        pollAttendanceData.beginPolling();
      },
    },
  );

  useEffect(() => {
    pollAttendanceData.refetch();
  }, [page, rowsLimit]);

  useEffect(() => {
    if (reportMonth !== orgAttendanceData?.payroll_month) {
      pollAttendanceData.refetch();
    }
  }, [reportMonth]);

  const validateDateRange = () => {
    if (reportFromDate && reportToDate) {
      if (reportFromDate > reportToDate) {
        return 'Invalid date range: Attendance start date cannot be greater than attendance end date';
      } else if (dateDiffInDays(reportFromDate, reportToDate) > 30) {
        return 'Invalid date range: Date range cannot include more than 31 days';
      } else {
        return '';
      }
    }
    return '';
  };

  if (integrationStatusQuery.status === 'loading' || integrationStatusQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (integrationStatusQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
      </ErrorMessage>
    );
  }

  if (integrationStatusQuery?.data) {
    if (!integrationStatusQuery.data.is_enabled) {
      history.push(routePaths.settings.jibble.onboarding.activation);
    }
    if (!integrationStatusQuery.data.is_onboarding_completed) {
      history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
    }
  }

  const onDateRangeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (reportFromDate && reportToDate && orgAttendanceData) {
      jibbleSyncNewAttendanceData.mutate({
        payroll_month: reportMonth ?? '',
        from_date: format(reportFromDate, 'yyyy-MM-dd'),
        to_date: format(reportToDate, 'yyyy-MM-dd'),
        page: page,
        rows_limit: rowsLimit,
      });
    }
  };

  const onRowsLimitChange = (value: number) => {
    setRowsLimit(value);
    setTotalPages(Math.ceil(totalRows / value));
  };

  const getPreSyncStateProps = () => {
    if (pollAttendanceData.isPolling) {
      return FetchingNewAttendanceProps;
    }
    if (pollAttendanceData.isFetching) {
      return FetchingExistingAttendanceProps;
    }
    if (
      !pollAttendanceData.isPolling &&
      !pollAttendanceData?.isFetching &&
      orgAttendanceData &&
      orgAttendanceData.attendance_data.total === 0
    ) {
      return AttendanceSyncPendingProps;
    }
    return HidePreSyncStateProps;
  };

  const dateValidationError = validateDateRange();

  return (
    <div>
      {error !== '' && <WarningAlert className="mb-12">{error}</WarningAlert>}
      <div className={styles['header']}>
        <h1 className={styles['pageHeading']}>Loss of pay report for</h1>
        <ReportMonthSelector
          reportMonth={reportMonth}
          setReportMonth={setReportMonth}
          className={styles['monthSelector']}
        />
        <img
          src={getStaticMediaUrl(jibbleSignature)}
          alt={'Jibble'}
          className={styles['jibbleImage']}
        />
      </div>
      {dateValidationError !== '' && (
        <WarningAlert className="my-12">{dateValidationError}</WarningAlert>
      )}
      <DateRangeForm
        fromDate={reportFromDate}
        toDate={reportToDate}
        lastFromDate={lastReportFromDate}
        lastToDate={lastReportToDate}
        lastSyncDate={lastSyncDate}
        isDateSelectionDisabled={isDateSelectionDisabled}
        isAttendanceSyncDisabled={canAddDeductionIds.length < 1}
        isFormSubmitDisabled={
          jibbleSyncNewAttendanceData.isLoading ||
          !!error ||
          reportFromDate === null ||
          reportToDate == null ||
          dateValidationError !== ''
        }
        onChangeFromDate={(newFromDate) => setReportFromDate(newFromDate || null)}
        onChangeToDate={(newToDate) => setReportToDate(newToDate || null)}
        onFormSubmit={(event) => onDateRangeSubmit(event)}
      />
      <PreSyncDisplay props={getPreSyncStateProps()} />
      {!pollAttendanceData.isPolling &&
        !pollAttendanceData.isFetching &&
        orgAttendanceData &&
        orgAttendanceData.attendance_data.total > 0 && (
          <DataDisplay
            payrollMonth={reportMonth ?? ''}
            fromDate={reportFromDate}
            toDate={reportToDate}
            attendanceData={orgAttendanceData['attendance_data']['data']}
            payrollBlockedIds={payrollBlockedIds}
            payrollFinalizedIds={payrollFinalizedIds}
            canAddDeductionIds={canAddDeductionIds}
            page={page}
            onPageChange={(pageNumber) => setPage(pageNumber)}
            rowsLimit={rowsLimit}
            onLimitChange={(limit) => onRowsLimitChange(limit)}
            totalPages={totalPages}
            totalRows={totalRows}
            fetchData={() => pollAttendanceData.refetch()}
            canEditLopDaysIds={canEditLopDaysIds}
          />
        )}
    </div>
  );
};

const JibbleAttendanceReportPageWrapper = () => (
  <PageWrapper>
    <MainCase className={styles['mainCase']}>
      <JibbleAttendanceReport />
    </MainCase>
  </PageWrapper>
);

export default JibbleAttendanceReportPageWrapper;
