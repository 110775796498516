import React from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { useModal } from 'components/ui/Modals';
import { StandardSecondaryButton } from 'components/ui/Button';
import { RunPayrollSchemaContract } from 'schemas/RunPayrollSchema';
import { toIndianCurrencyString } from 'utils/Numbers';

import styles from './index.module.scss';

const RazorpayChargesBreakdown = ({
  requiredAmount,
  flexiPartnerName,
  opfinCharges
}: {
  requiredAmount?: RunPayrollSchemaContract['requiredAmount'];
  flexiPartnerName?: string;
  opfinCharges?: number;
}) => {
  const { closeModal } = useModal();

  const {
    xpayrollCharges,
    xpayrollGst,
    numberOfPayrolls,
    flexiPlatformChargesWithoutGst,
    flexiPartnerPlatformChargesGst,
    peopleOrgIdsForFlexiPartnerPlatformCharges,
    flexiPartnerCardChargesWithoutGst,
    flexiPartnerCardChargesGst,
    peopleOrgIdsForFlexiPartnerCardCharges,
  } = requiredAmount?.['opfin-charges'] ?? {};

  const employeeExecutionCharges = (xpayrollCharges ?? 0) + (xpayrollGst ?? 0);
  const flexiPlatformCharges = (flexiPlatformChargesWithoutGst ?? 0) + (flexiPartnerPlatformChargesGst ?? 0);
  const flexiCardCharges = (flexiPartnerCardChargesWithoutGst ?? 0) + (flexiPartnerCardChargesGst ?? 0);

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Razorpay Charges
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <table className="w-full">
            <tbody>
            <tr>
              <th>Component</th>
              <th>Amount</th>
            </tr>
            {employeeExecutionCharges > 0 && (
              <tr>
                <td>{`Employees payroll execution (${
                  numberOfPayrolls ?? 0
                } employees)`}</td>
                <td>{toIndianCurrencyString(employeeExecutionCharges, true)}</td>
              </tr>
            )}
            {flexiPlatformCharges > 0 && (
              <tr>
                <td>{`${flexiPartnerName} platform charges (${peopleOrgIdsForFlexiPartnerPlatformCharges?.length ?? 0} employees)`}</td>
                <td>{toIndianCurrencyString(flexiPlatformCharges, true)}</td>
              </tr>
            )}
            {flexiCardCharges > 0 && (
              <tr>
                <td>{`${flexiPartnerName} card charges (${peopleOrgIdsForFlexiPartnerCardCharges?.length ?? 0} employees)`}</td>
                <td>{toIndianCurrencyString(flexiCardCharges, true)}</td>
              </tr>
            )}
            <tr>
              <td>
                <strong>Total Amount</strong>
              </td>
              <td>
                <strong>{toIndianCurrencyString(opfinCharges ?? 0, true)}</strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardSecondaryButton onClick={() => closeModal()}>CLOSE</StandardSecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default RazorpayChargesBreakdown;
