import React from 'react';
import { getAppErrorDetails, AppError } from 'utils/AppError';
import { errorParser } from 'utils/Api';
import { Alert } from '@razorpay/blade/components';

const Errors = ({ errorResponse }: { errorResponse: typeof AppError }) => {
  return (
    <>
      {errorParser(getAppErrorDetails(errorResponse)).map((error) => (
        <React.Fragment key={error}>
          {error}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export const ErrorsAlert = ({
  errorResponse,
  isFullWidth = false,
}: {
  errorResponse: typeof AppError;
  isFullWidth?: boolean;
}) => {
  return (
    <>
      {errorParser(getAppErrorDetails(errorResponse)).map((error) => (
        <Alert
          isFullWidth={isFullWidth}
          key={error.toString()}
          description={error.toString()}
          isDismissible={true}
          color="negative"
        />
      ))}
    </>
  );
};

export default Errors;
