import { Box, Text } from '@razorpay/blade/components';
import React from 'react';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';
import { transformFinalizePayrollRequestData } from '../FinalizePayroll/utils';
import { format } from 'date-fns';
import { DetailsSkeleton } from 'components/ui/Skeletons/DetailsSkeleton';

export interface FinalizePayrollDetailsProps {
  workflowRequestDetails?: GetWorkflowRequestDetailsContract;
  isLoading?: boolean;
}

const FinalizePayrollDetails = ({
  workflowRequestDetails,
  isLoading,
}: FinalizePayrollDetailsProps) => {
  if (isLoading || !workflowRequestDetails) {
    return <DetailsSkeleton rowCount={4} />;
  }

  const requestData = transformFinalizePayrollRequestData(
    workflowRequestDetails.workflow_requests_data,
  );

  const details = [
    {
      label: 'Payroll Month',
      content: (
        <Text color="surface.text.gray.muted">{format(new Date(requestData.payrollMonth as string), 'MMMM')}</Text>
      ),
    },
    {
      label: 'Finalized Employees',
      content: <Text color="surface.text.gray.muted">{requestData.finalizedEmployees}</Text>,
    },
    {
      label: 'Skipped Employees',
      content: <Text color="surface.text.gray.muted">{requestData.skippedEmployees}</Text>,
    },
  ];

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.5">
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text weight="semibold" size="large" color="surface.text.gray.muted">
          Finalize Payroll
        </Text>
        <Text size="large" color="surface.text.gray.disabled">
          Details
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" gap="spacing.5">
        {details.map(({ label, content }, index) => (
          <Box display="flex" gap="spacing.5" alignItems="baseline" key={index}>
            <Box width="120px">
              <Text color="surface.text.gray.muted">{label}</Text>
            </Box>
            <Box>{content}</Box>
          </Box>
        ))}
      </Box>
    </Box>)
  );
};

export { FinalizePayrollDetails };
