import React from "react";

import { getStaticMediaUrl } from "utils/Urls";
import ExternalLink from "../../../ui/ExternalLink";
import videoIcon from "assets/partnerFlexiBenefits/scheduleACallIcon.svg";

const ScheduleDemoLink = () => {
    return <div className={"text-center"}>
        <ExternalLink 
            target={"_blank"} 
            rel="noopener noreferrer" 
            href={'https://calendly.com/xpayroll-zaggle/30min'} 
            className="flex justify-center align-center space-x-4">
            <img width="20px" height="13.3px" src={getStaticMediaUrl(videoIcon)} />
            <span className="text-primary font-bold	">Schedule a demo call with us</span>
        </ExternalLink>
    </div>;
}

export default ScheduleDemoLink;