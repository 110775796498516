import React, { ReactElement } from "react";
import { WarningAlert, SuccessAlert } from '../../../../ui/Alert';

const FileSubmitAlert = ({isWarning,message} : {message? : string | null, isWarning? : boolean }): ReactElement => {

    let retVal = <></>;
    if(message !== null && isWarning !== undefined){
        if(isWarning){
            retVal =
                <>
                    <WarningAlert className="mt-12 mb-16">
                        { message? message : 'There was an issue performing this action. Please try again later or contact XPayroll Support.'}
                    </WarningAlert>
                </>
            
        }
        else{
            retVal =
                <>
                    <SuccessAlert className="mt-12 mb-16">
                        { message? message : ''}
                    </SuccessAlert>
                </>
        }
            
    }

    return retVal;
}

export default FileSubmitAlert;