import { Box, Button, Heading } from '@razorpay/blade/components';
import { SideSheet } from 'components/ApprovalWorkflows/MyRequests/components/SideSheet';
import React, { useEffect, useState } from 'react';
import { customReportFilterTypes } from './constants';
import { renderCustomReportField } from './reportHelper';
import { ReportFilter } from './types';

interface ReportFiltersProps {
  filters: ReportFilter[];
  onChange: (key: string, value: string | string[] | null) => void;
  currentFilters: Record<string, string>;
  onApplyFilters: (filters: { filter: string; value: string }[]) => void;
  isLoading: boolean;
}

const ReportFilters = ({
  filters,
  onChange,
  currentFilters,
  onApplyFilters,
  isLoading,
}: ReportFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [values, setValues] = useState<Record<string, string>>(
    filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.key]: currentFilters[filter.key] ?? '',
      }),
      {},
    ),
  );

  useEffect(() => {
    setValues(currentFilters);
  }, [currentFilters]);

  const totalFilters = filters.length;

  const searchPeopleFilter = filters.find(
    (filter) => filter.type === customReportFilterTypes.TYPE_FILTER_SEARCH_PEOPLE_BY_NAME,
  );
  const restFilters = searchPeopleFilter
    ? filters.filter((filter) => filter.key !== searchPeopleFilter.key)
    : filters;

  const handleApplyFiltersClick = () => {
    onApplyFilters(Object.keys(values).map((key) => ({ filter: key, value: values[key] })));
    setIsOpen(false);
  };

  const handleFieldChange = (key: string, value: string | string[] | null) => {
    setValues((prev) => ({ ...prev, [key]: value as string }));
  };

  return (<>
    <Box display="flex" gap="spacing.5" flexWrap="wrap" alignItems="flex-end">
      {searchPeopleFilter ? (
        <Box width="242px">
          {renderCustomReportField({
            field: searchPeopleFilter,
            onChange: handleFieldChange,
            isLoading,
            currentfields: values,
            onSearchClick: handleApplyFiltersClick,
          })}
        </Box>
      ) : null}
      {totalFilters > 1 ? (
        <Button variant="tertiary" onClick={() => setIsOpen(true)}>
          Filters
        </Button>
      ) : null}
    </Box>
    {totalFilters > 1 && (
      <SideSheet isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <Box width="100%" display="grid" gridTemplateRows={'auto 1fr auto'} rowGap="spacing.7">
          <Heading size="medium">Apply Filters</Heading>
          <Box
            display="flex"
            flexDirection="column"
            gap="spacing.7"
            paddingTop="spacing.9"
            width="100%">
            {restFilters.map((filter) =>
              renderCustomReportField({
                field: filter,
                onChange: handleFieldChange,
                isLoading,
                currentfields: values,
                onSearchClick: handleApplyFiltersClick,
              }),
            )}
          </Box>
          <Button isFullWidth onClick={handleApplyFiltersClick} isDisabled={isLoading}>
            Apply Filters
          </Button>
        </Box>
      </SideSheet>
    )}
  </>);
};

export default ReportFilters;
