import { Box, Text, BoxProps } from '@razorpay/blade/components';
import noTeamMembersFound from 'assets/no_team_members.svg';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getStaticMediaUrl } from 'utils/Urls';

interface NoResultsTableViewProps {
  title?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  boxProps?: BoxProps;
}

const NoTaskFoundImage = styled.img`
  width: 180px;
  height: 180px;
`;

const NoResultsView = ({
  title = 'No result found!',
  description = 'Please reset your filters and try again.',
  actions,
  boxProps = {},
}: NoResultsTableViewProps) => {
  return (
    (<Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="spacing.7"
      {...boxProps}>
      <NoTaskFoundImage src={getStaticMediaUrl(noTeamMembersFound)} />
      <Box display="flex" flexDirection="column" alignItems="center" gap="spacing.2">
        <Text textAlign="center" weight="semibold" size="large">
          {title}
        </Text>
        <Text textAlign="center" color="surface.text.gray.muted">
          {description}
        </Text>
      </Box>
      {actions ? actions : null}
    </Box>)
  );
};

export default NoResultsView;
