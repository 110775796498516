import { Modal } from "components/ui/Modal";
import React from "react";
import styles from './index.module.css';
import failureIconInsurance from 'assets/insurance/insurance_alert_icon.png';
import { PrimaryButton } from "components/ui/Button";
import { useHistory } from "react-router-dom";
import { routePaths } from "components/Routes/data";
import {renewalPageStateType, RenewalPurchaseState} from "./index";

export function RenewalPurchaseFailureModal({pageState}:{pageState: renewalPageStateType})
{
    const history = useHistory();

    return (<>
                <Modal
                    showModal={pageState?.renewalPurchaseState === RenewalPurchaseState.Failure }
                    heading=""
                    className={`${styles['renewal-page-purchase-success']}`}
                    contentClass="flex flex-col h-auto"
                    closeModal={()=>{}}
                    disableClose={true}
                >
                    <>
                        <div className={`w-full justify-center flex pb-10 flex-col ${styles['renewal-page-purchase-success']}`}>
                            <img
                                style={{width:'8em'}}
                                loading="lazy"
                                src={failureIconInsurance}
                                alt="failure case"
                            />
                            <h2 className="ml-3 mr-3 mt-4 mb-1 text-3xl font-semibold" >Failed to renew the insurance for your team.</h2>
                            <h2 className="m-3 mt-4 mb-4 text-2xl font-semibold" style={{margin: '0 2em 1em',textAlign:'center'}}>  Kindly can you please refresh the page or try after sometime! <br/> If issue still persist please contact support.</h2>
                            <PrimaryButton
                                onClick={() => {
                                    history.push(routePaths.insurance.admin.details);
                                }}
                                style={{padding:'padding: 0 40%',fontSize:'1em'}}
                            >
                                Insurance Dashboard
                            </PrimaryButton>
                        </div>
                    </>
                </Modal></>)
}