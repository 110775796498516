import { Box, CloseIcon, IconButton, Text } from '@razorpay/blade/components';
import React from 'react';

type FilterTagProps = {
  name: string;
  value: string | string[];
  onDismiss: () => void;
};

const FilterTag = ({ name, value, onDismiss }: FilterTagProps) => {
  const tagValue = Array.isArray(value) ? value.join(', ') : value;
  return (
    <Box
      display="flex"
      alignItems="baseline"
      gap="spacing.2"
      borderRadius="small"
      padding={'9px 8px'}
      borderWidth="thin"
      borderColor="surface.border.primary.normal"
      backgroundColor="surface.background.primary.subtle">
      <Text weight='semibold' color="surface.text.gray.subtle">
        {name}:
      </Text>
      <Text color="surface.text.gray.subtle">
        {tagValue}
      </Text>
      <IconButton
        size="small"
        icon={CloseIcon}
        accessibilityLabel={`close tag for ${name.toLowerCase()}`}
        onClick={onDismiss}
      />
    </Box>
  );
};

export default FilterTag;
