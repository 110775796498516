import React, { useState } from 'react';
import { format } from 'date-fns';

import Modal from 'components/ui/Modals/components/Modal';
import { useModal } from 'components/ui/Modals';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import { Input, InputElement } from 'components/ui/Form/Input';

import styles from './index.module.scss';
import { trackXPayrollEvent } from 'utils/analytics';

type ExcludeNull<T> = T extends null ? never : T;

const ArrearBreakdown = ({
  breakdown,
  setBreakdown
}: {
  breakdown: GetArrearsSchemaContract['arrear_breakdown'],
  setBreakdown: (breakdown: GetArrearsSchemaContract['arrear_breakdown']) => void
}) => {
  const { closeModal } = useModal();

  const [arrearsBreakdown, setArrearsBreakdown] = useState<ExcludeNull<GetArrearsSchemaContract['arrear_breakdown']>>(breakdown ?? {});

  const onSubmit = (event: React.FormEvent) => {
    trackXPayrollEvent(`edit_salary.edit_month_wise_arrear_saved`);
    event.preventDefault();
    setBreakdown(arrearsBreakdown);
    closeModal();
  }

  const onArrearChange = (monthKey: string, componentToUpdate: string, type: string, value: string) => {
    const oldArrears = arrearsBreakdown[monthKey];
    const newArrears = oldArrears?.map((component) => {
      if (component.name === componentToUpdate && type == component.type) {
        return {
          ...component,
          amount: isNaN(parseInt(value)) ? 0 : parseInt(value)
        };
      }
      return component;
    });
    setArrearsBreakdown({
      ...arrearsBreakdown,
      [monthKey]: newArrears
    });
  };

  const arrearMonths = Object.keys(arrearsBreakdown).map((key) => {
    const monthMap: { [key:string] : number } = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
    };
    const match = key.match(/(\w{3}) (\d{4})/);
    if(match) {
      const [, month, year] = match;
      const dateObject = new Date(+year, monthMap[month]);
      return dateObject;
    }
    return null;
  });
  
  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Arrears
        </div>
        <form className={`${styles['run-payroll-modal-content']}`} onSubmit={onSubmit}>
          <div className={`flex flex-col gap-10 mb-10 ${styles['run-payroll-arrear-breakdown-content']}`}>
          {
            arrearMonths
            .sort((month1, month2) => {
              // doing this as the array might contain null values as well
              if (month1 === null) return 1; 
              if (month2 === null) return -1; 
              return month1.getTime() - month2.getTime();
            })
            .map(arrearMonth => {
              if(!arrearMonth) {
                return null;
              }

              const keyFormat = format(arrearMonth, 'MMM yyyy')
              const arrearEarnings = arrearsBreakdown[keyFormat].filter((component) => component.type === 'Earnings');
              const arrearDeductions = arrearsBreakdown[keyFormat].filter((component) => component.type === 'Deductions');

              return (
                <div key={keyFormat}>
                  <span>{format(arrearMonth, 'MMMM yyyy')}</span>
                  <>
                  {arrearEarnings.length > 0 && (
                    <>
                      <div className="pt-6 pb-2 ">
                      Earnings
                      </div>
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        {arrearEarnings.map((component) => {
                          return (
                            <tr key={component.name}>
                              <td>
                                <Input
                                  type="text"
                                  name={`name-${component.name}`}
                                  value={component.name ?? ''}
                                />
                              </td>
                              <td>
                                <InputElement>
                                  <Input
                                    type="number"
                                    className={styles['run-payroll-edit-number']}
                                    name={`amount-${component.amount}`}
                                    value={component.amount}
                                    onChange={(event) => {
                                      onArrearChange(keyFormat, component.name, component.type, event.target.value);
                                    }}
                                    disabled={component.name.includes('PF Employer Contribution')}
                                  />
                                </InputElement>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </>
                  )}
                  {arrearDeductions.length > 0 && (
                    <>
                      <div className="pt-8 pb-2">Deductions</div>
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        {arrearDeductions.map((component) => {
                          return (
                            <tr key={component.name}>
                              <td>
                                <Input
                                  type="text"
                                  name={`name-${component.name}`}
                                  value={component.name ?? ''}
                                />
                              </td>
                              <td>
                                <InputElement>
                                  <Input
                                    type="number"
                                    className={styles['run-payroll-edit-number']}
                                    name={`amount-${component.amount}`}
                                    value={component.amount}
                                    onChange={(event) => {
                                      onArrearChange(keyFormat, component.name, component.type, event.target.value);
                                    }}
                                  />
                                </InputElement>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </>
                  )}
                  </>
                </div>
              )
            })
          }
          </div>
          <>
            <StandardButton
              isPending={false}
              disabled={false}
              type={'submit'}
              >
              SAVE & CONTINUE
            </StandardButton>
            <StandardSecondaryButton
              onClick={() => {
                trackXPayrollEvent(`edit_salary.edit_month_wise_arrear_closed`);
                closeModal();
              }}
              isPending={false}
              disabled={false}
              >
              CLOSE
            </StandardSecondaryButton>
          </>
        </form>
  
      </div>
    </Modal>
  );
};

export default ArrearBreakdown;
