import { routePaths } from './data';

const bladeRoutes = [
  '/kyc',
  '/kyc/bank-details',
  '/kyc/bank-details/manual',
  '/kyc/user-selection',
  '/kyc/documents-upload',
  '/kyc/company-details-verification',
  '/kyc/company-details-verification/gstin',
  '/kyc/company-details-verification/cin-or-llpin',
  '/kyc/company-details-verification/manual',
  '/kyc/company-details-verification/add-address',
  '/kyc/company-details-verification/pan',
  '/onboarding',
  '/onboarding/details',
  '/onboarding/details/basic-details',
  '/onboarding/details/pan-prefill',
  '/workflow',
  '/workflow/edit-payroll',
  '/workflow/finalize-payroll',
  '/workflow/salary-revision',
  '/my-requests',
  '/my-requests/edit-payroll',
  '/my-requests/edit-payroll/pending-approvals',
  '/my-requests/edit-payroll/initiated-by-me',
  '/my-requests/edit-payroll/completed',
  '/my-requests/finalize-payroll',
  '/my-requests/finalize-payroll/pending-approvals',
  '/my-requests/finalize-payroll/initiated-by-me',
  '/my-requests/finalize-payroll/completed',
  '/my-requests/salary-revision',
  '/my-requests/salary-revision/pending-approvals',
  '/my-requests/salary-revision/initiated-by-me',
  '/my-requests/salary-revision/completed',
  routePaths.flexibleBenefits.root,
  routePaths.flexibleBenefits.declaration,
  routePaths.flexibleBenefits.settings,
  routePaths.flexibleBenefits.settingsGeneral,
  routePaths.flexibleBenefits.settingsDeclaration,
  routePaths.flexibleBenefits.settingsProofUpload,
  routePaths.flexibleBenefits.settingsWindowAdavanced,
  '/nps',
  '/nps/settings',
  '/nps/declaration',
  '/bonus/view',
  '/bonus/create',
  routePaths.settings.darwinbox.root,
  routePaths.settings.darwinbox.introduction,
  routePaths.settings.darwinbox.manage,
  routePaths.settings.darwinbox.integration,
  routePaths.settings.darwinbox.settings.root,
  routePaths.settings.darwinbox.settings.entitySelection,
  routePaths.settings.darwinbox.settings.fields,
  routePaths.settings.darwinbox.settings.employeeContractorMapping,
  routePaths.settings.darwinbox.settings.syncConfirmation,
  routePaths.settings.darwinbox.settings.syncInProgress,
  routePaths.settings.darwinbox.settings.disableIntegration,
  routePaths.settings.hibob.root,
  routePaths.settings.hibob.introduction,
  routePaths.settings.hibob.manage,
  routePaths.settings.hibob.integration,
  routePaths.settings.hibob.settings.root,
  routePaths.settings.hibob.settings.entitySelection,
  routePaths.settings.hibob.settings.fields,
  routePaths.settings.hibob.settings.employeeContractorMapping,
  routePaths.settings.hibob.settings.syncConfirmation,
  routePaths.settings.hibob.settings.syncInProgress,
  routePaths.settings.hibob.settings.disableIntegration,
  routePaths.settings.worq.root,
  routePaths.settings.worq.introduction,
  routePaths.settings.worq.manage,
  routePaths.settings.worq.integration,
  routePaths.settings.worq.settings.root,
  routePaths.settings.worq.settings.entitySelection,
  routePaths.settings.worq.settings.fields,
  routePaths.settings.worq.settings.employeeContractorMapping,
  routePaths.settings.worq.settings.syncConfirmation,
  routePaths.settings.worq.settings.syncInProgress,
  routePaths.settings.worq.settings.disableIntegration,
  routePaths.settings.workline.root,
  routePaths.settings.workline.introduction,
  routePaths.settings.workline.manage,
  routePaths.settings.workline.integration,
  routePaths.settings.workline.settings.root,
  routePaths.settings.workline.settings.entitySelection,
  routePaths.settings.workline.settings.fields,
  routePaths.settings.workline.settings.employeeContractorMapping,
  routePaths.settings.workline.settings.syncConfirmation,
  routePaths.settings.workline.settings.syncInProgress,
  routePaths.settings.workline.settings.disableIntegration,
  routePaths.fnf.root,
  routePaths.fnf.initiate,
  routePaths.fnf.releaseNetPay,
  routePaths.taxDeclarationAndProofSettings.root,
  routePaths.taxDeclarationAndProofSettings.settings,
  routePaths.taxDeclarationAndProofSettings.settingsGeneral,
  routePaths.taxDeclarationAndProofSettings.settingsDeclaration,
  routePaths.taxDeclarationAndProofSettings.settingsProofUpload,
  routePaths.taxDeclarationAndProofSettings.settingsCustomWindow,
  routePaths.customReport.root,
  '/404',
  routePaths.settings.rxIntegration.root,
  routePaths.settings.rxIntegration.preCheck,
  routePaths.settings.rxIntegration.activate,
  routePaths.payrollAiAssistant,
  routePaths.integrations.v2,
  routePaths.payrollSetupSettings.root,
  routePaths.salaryExplainer,
  routePaths.orgSettings.apiAccess,
  routePaths.salaryComponents.root,
  routePaths.salaryComponents.earningsListView,
  routePaths.salaryComponents.deductionsListView,
  routePaths.salaryComponents.nonPayableBenefitsListView,
  routePaths.salaryComponents.perquisitesListView,
  routePaths.salaryComponents.create.earnings.root,
  routePaths.salaryComponents.create.earnings.generalDetails,
  routePaths.salaryComponents.create.earnings.taxationDetails,
  routePaths.salaryComponents.create.earnings.review,
  routePaths.salaryComponents.create.deductions.root,
  routePaths.salaryComponents.create.deductions.generalDetails,
  routePaths.salaryComponents.create.deductions.payAndTaxDetails,
  routePaths.salaryComponents.create.deductions.review,
  routePaths.salaryComponents.edit.earnings.root,
  routePaths.salaryComponents.edit.earnings.generalDetails,
  routePaths.salaryComponents.edit.earnings.taxationDetails,
  routePaths.salaryComponents.edit.earnings.review,
  routePaths.salaryComponents.edit.deductions.root,
  routePaths.salaryComponents.edit.deductions.generalDetails,
  routePaths.salaryComponents.edit.deductions.payAndTaxDetails,
  routePaths.salaryComponents.edit.deductions.review,
  routePaths.salaryComponents.edit.predefinedComponent,
  routePaths.attendance.root,
  routePaths.salaryStructure.root,
  routePaths.payDetails.root,
  routePaths.payDetails.compensation.root,
  routePaths.payDetails.compensation.ctc,
  routePaths.payDetails.compensation.deductions,
  routePaths.payDetails.compensation.perquisites,
  routePaths.payDetails.compensation.flexi
  // routePaths.reimbursements.pending,// DONT UNCOMMENT THIS LINE
  // routePaths.reimbursements.pending.approval, // DONT UNCOMMENT THIS LINE
  // routePaths.reimbursements.pending.payment, // DONT UNCOMMENT THIS LINE
];

export default bladeRoutes;
