import React, { useState } from 'react';
import { Table } from '../components/Table';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Link,
  ProgressBar,
  RefreshIcon,
  Text,
} from '@razorpay/blade/components';
import { Pagination } from '../components/Table/Pagination';
import { Column, RowClickHandler } from '../components/Table/types';
import { useWorkflowDetailsView } from '../useWorkflowDetailsView';
import { useWithdrawRequests } from '../useWithdrawRequests';
import { useQuery, useQueryClient } from 'react-query';
import { useFilters } from '../useFilters';
import { GetWorkflowRequestsData } from 'api/workflows';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import api from 'api';
import { invalidateFinalizePayrollCache, transformFinalizePayrollRequestData } from './utils';
import { NoResultsTableView } from '../components/NoResultsTableView';
import { ErrorResultsTableView } from '../components/ErrorResultsTableView';
import { AllRowsCheckSkeleton } from '../components/AllRowsCheckSkeleton';
import { WorkflowRequestsCacheKeys, WorkflowRequestsStatusMap } from '../constants';
import { FinalizePayrollListItem } from './types';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { transformDataForWorkflowRequests } from 'api/gql-utils/workflows/transformers';
import { usePagination } from 'hooks/usePagination';

const finalizePayrollInitiatedTableColumns: Column<FinalizePayrollListItem>[] = [
  {
    title: 'Payroll Month',
    render: (item) => {
      return <Text color="surface.text.gray.muted">{format(new Date(item.payrollMonth as string), 'MMMM')}</Text>;
    },
  },
  {
    title: 'Status',
    render: (item) => {
      if (item.approvalLevel) {
        return <Badge color="notice">L{item.approvalLevel.toString()}</Badge>;
      }

      switch (item.status) {
        case WorkflowRequestsStatusMap.approved:
          return <Badge color="positive">Approved</Badge>;
        case WorkflowRequestsStatusMap.rejected:
          return <Badge color="negative">Rejected</Badge>;
      }
    },
    styles: {
      textAlign: 'center',
    },
  },
  {
    title: 'Finalized Employees',
    render: (item) => {
      return <Text color="surface.text.gray.muted">{item.finalizedEmployees}</Text>;
    },
    styles: {
      textAlign: 'right',
    },
  },
  {
    title: 'Skipped Employees',
    render: (item) => {
      return <Text color="surface.text.gray.muted">{item.skippedEmployees}</Text>;
    },
    styles: {
      textAlign: 'right',
    },
  },
];

const InitiatedByMe = () => {
  const queryClient = useQueryClient();
  const { workflowRequestId } = useWorkflowDetailsView();
  const [tableData, setTableData] = useState<FinalizePayrollListItem[]>([]);
  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];

  const { currentPage, itemsPerPage, setCurrentPage, setItemsPerPage } = usePagination({
    page: 1,
    limit: 10,
  });

  const { currentFilters } = useFilters();
  const queryParams: GetWorkflowRequestsData = {
    ...currentFilters,
    action_type: WorkflowTypesMap.FINALIZE_PAYROLL,
    is_initiated_by_me: 1,
  };

  const { data, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: [WorkflowRequestsCacheKeys.finalizePayroll.initiatedByMe, queryParams],
    queryFn: () => api.workflowApis.getRequests(queryParams, isGraphQLEnabled),
    onSuccess: ({ data }) => {
      const tableData = data?.map((rowData) => transformFinalizePayrollRequestData(rowData)) ?? [];
      setTableData(tableData);
    },
    select: transformDataForWorkflowRequests,
    cacheTime: 0,
  });
  const {
    onRowChecked,
    actionCtasDisabled,
    selectedRowsCount,
    handleMultiWithdrawCtaClicked,
    handleSingleWithdrawCtaClicked,
  } = useWithdrawRequests({ tableData, setTableData });

  const { openDetailsView } = useWorkflowDetailsView();

  const onRowClicked: RowClickHandler<FinalizePayrollListItem> = (rowData) => {
    const id = rowData.id;

    openDetailsView(id);
  };

  const hoverTray = (rowData: FinalizePayrollListItem) => {
    return (
      <Box display="flex" gap="spacing.6">
        <Button
          size="xsmall"
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            handleSingleWithdrawCtaClicked(rowData, () =>
              invalidateFinalizePayrollCache(queryClient),
            );
          }}>
          WITHDRAW
        </Button>
      </Box>
    );
  };

  const tableTopContent = tableData.length ? (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <Box display="flex" gap="spacing.5">
        <Checkbox
          onChange={({ isChecked }) => {
            setTableData((data) =>
              data.map((rowData) => ({
                ...rowData,
                isChecked: rowData.allowAction ? isChecked : false,
              })),
            );
          }}
          isDisabled={tableData.reduce((prev, rowData) => prev && !rowData.allowAction, true)}>
          <></>
        </Checkbox>
        <Text weight="semibold" color="surface.text.gray.muted">
          Showing {data?.from}-{data?.to} of {data?.total} initiated requests
        </Text>
      </Box>
      <Box display="flex" gap="spacing.5" alignItems="center">
        <Link
          icon={RefreshIcon}
          variant="button"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            refetch();
          }}
        />
        <Button
          onClick={() => {
            handleMultiWithdrawCtaClicked(() => invalidateFinalizePayrollCache(queryClient));
          }}
          isDisabled={actionCtasDisabled}>
          Withdraw {selectedRowsCount > 0 ? `(${selectedRowsCount})` : ''}
        </Button>
      </Box>
    </Box>
  ) : null;

  const tableTopSkeletonContent = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <AllRowsCheckSkeleton withCheckbox={true} />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflowY="auto"
      height={tableData.length === 0 || isError ? '100%' : 'auto'}>
      {isFetching && <ProgressBar isIndeterminate />}
      {isLoading ? tableTopSkeletonContent : tableTopContent}
      <Box display="flex" overflowY="scroll" flexGrow={1}>
        <Table
          columns={finalizePayrollInitiatedTableColumns}
          data={tableData.map((data) => ({
            ...data,
            isClicked: data.id === Number(workflowRequestId),
          }))}
          onRowChecked={onRowChecked}
          onRowClick={onRowClicked}
          hoverTray={hoverTray}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          noResultsView={
            <NoResultsTableView
              onRefresh={refetch}
              workflowExists={actionsWithActiveWorkflows.includes(
                WorkflowTypesMap.FINALIZE_PAYROLL,
              )}
              workflowType={WorkflowTypesMap.FINALIZE_PAYROLL}
            />
          }
          errorResultsView={<ErrorResultsTableView onRetry={refetch} />}
        />
      </Box>
      {tableData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={data?.total ?? 0}
          itemsPerPage={itemsPerPage}
          onCurrentPageChange={setCurrentPage}
          onItemsPerPageChange={setItemsPerPage}
          isDisabled={isLoading}
        />
      )}
    </Box>
  );
};

export { InitiatedByMe };
