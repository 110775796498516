import { IInsurance } from '../../../reducers/insurance';
import { IInsuranceEstimate } from '../../../reducers/insuranceEstimate';
import { getConditionsNotMetToGetExactEstimate } from './computeMinRequirements';
import { loggedInUser, ILoggedInUser } from '../../../reducers/loggedInUser';

export function canSelectPlan(
  insurance: IInsurance,
  insuranceEstimate: IInsuranceEstimate,
  planId: number,
  loggedInUser: ILoggedInUser,
) {
  if (insurance.data && insurance.data?.status === 'ESTIMATION_IN_PROGRESS') {
    return true;
  }

  if (
    insuranceEstimate.data &&
    insurance.data &&
    insurance.data?.status === 'DATA_COLLECTION_IN_PROGRESS'
  ) {
    // Todo: minimum employees count should come from config
    const data = { ...insuranceEstimate.data, minimumEmployeesRequired: planId === 1 ? 2 : 5 };

    return (
      getConditionsNotMetToGetExactEstimate({ ...insuranceEstimate, data }, loggedInUser).length ===
      0
    );
  }

  return false;
}
