import React, { ReactElement, useState } from 'react';
import ZohoLogo from 'assets/zoho/zoho_text_logo.png';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import { SecondaryButton } from 'components/ui/Button';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Redirect, useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import cx from 'classnames';
import { CheckBox } from 'components/ui/Form/Input';
import ConnectToZoho from '../components/ConnectToZoho';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import withActivationQueryWrapper from '../withActivationQueryWrapper';

import styles from './index.module.scss';

function PreCondition({
  id,
  label,
  value,
  onChange,
  checked,
  disabled,
  children,
  className = '',
}: React.PropsWithChildren<{
  id: string;
  label: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}>) {
  return (
    <div className={cx(styles['precondition-wrapper'], className)}>
      <CheckBox
        id={id}
        label={label}
        className="mb-2"
        labelClassName="text-white font-bold"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={styles['description']}>{children}</div>
    </div>
  );
}

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoIntroPreconditions({ zohoData }: Props): ReactElement {
  const [isPlanConditionChecked, setIsPlanConditionChecked] = useState(false);
  const [isOnboardingConditionChecked, setIsOnboardingConditionChecked] = useState(false);
  const history = useHistory();
  const [isConnecting, setIsConnecting] = useState(false);

  if (zohoData.enabled) {
    return <Redirect to={routePaths.settings.zoho.introduction} />;
  }

  if (zohoData.active_integration) {
    return <Redirect to={routePaths.settings.zoho.introduction} />;
  }

  return (
    <div className={cx(styles['zoho-preconditions-banner-wrapper'])}>
      <img className={styles['logo']} src={ZohoLogo} alt="Zoho Logo" />
      <div className={styles['title']}>
        Before you proceed, the below tasks should be completed 🙂
      </div>
      <div className="mt-12">
        <PreCondition
          id="active-plan"
          label="Active Zoho People plan"
          checked={isPlanConditionChecked}
          value="active-plan"
          disabled={isConnecting}
          onChange={(event) => {
            setIsPlanConditionChecked(event.target.checked);
          }}>
          Your organisation should be on any paid plan of Zoho People. Integration will be
          successful on paid plan only
        </PreCondition>
        <PreCondition
          id="onboarding-complete"
          className="mt-10"
          label="All employees onboarded to Zoho People"
          checked={isOnboardingConditionChecked}
          value="onboarding-complete"
          disabled={isConnecting}
          onChange={(event) => {
            setIsOnboardingConditionChecked(event.target.checked);
          }}>
          All your current employees should be onboarded to Zoho People as employee data will sync
          from Zoho to RazorpayX Payroll
        </PreCondition>
      </div>
      <div className="flex justify-between mt-24">
        <SecondaryButton
          disabled={isConnecting}
          onClick={() => {
            history.goBack();
          }}>
          Back
        </SecondaryButton>
        <div className="flex flex-col">
          <ConnectToZoho
            disabled={!isOnboardingConditionChecked || !isPlanConditionChecked}
            isPending={isConnecting}
            onClick={() => {
              setIsConnecting(true);
            }}>
            Connect to Zoho People
          </ConnectToZoho>
          <span className="italic text-2xl mt-6 text-right">
            You will be redirected to Zoho People
          </span>
        </div>
      </div>
    </div>
  );
}

function ZohoIntroPreconditionsWithPageWrapper({ zohoData }: Props): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Zoho People"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase className={styles['zoho-page-wrapper']} type="none">
        <ZohoIntroPreconditions zohoData={zohoData} />
      </MainCase>
    </PageWrapper>
  );
}

export default withActivationQueryWrapper(ZohoIntroPreconditionsWithPageWrapper);
