import { BulkUploadStatus } from 'constants/bulkUpload';
import React from 'react';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import ErrorBlock from '../components/ErrorBlock';
import {
  ExecutionStatusModal,
  STATUS_FAILED,
  STATUS_IN_PROGRESS,
} from '../components/ExecutionModals';
import { SuccessfullStatusModal } from '../components/SuccessfullModal';
import { BulkUploadPreviewModals } from '../types';

type UsePreviewModalsProps = {
  bulkUploadRecord: BulkUploadDetailsSchemaContract[0];
  modalToDisplay: BulkUploadPreviewModals;
  labelMap?: {
    success?: {
      mainText?: string;
      subText?: string;
      buttonText: string;
    };
  };
  clickHandlers: {
    onClickGetErrorReport: () => void;
    onClickGoBack: () => void;
    onClickSuccessCallback: () => void;
  };
};

const usePreviewModals = ({
  bulkUploadRecord,
  modalToDisplay,
  clickHandlers,
  labelMap,
}: UsePreviewModalsProps) => {
  switch (modalToDisplay) {
    case BulkUploadPreviewModals.SHOW_IN_PROGRESS_MODAL:
      return (
        <ExecutionStatusModal
          mainText="Processing your uploads"
          subText={`This may take a while...sit tight. ${
            bulkUploadRecord.percentage_completed !== null &&
            bulkUploadRecord.percentage_completed !== undefined
              ? bulkUploadRecord.percentage_completed + '%'
              : ''
          }`}
          status={STATUS_IN_PROGRESS}
        />
      );

    case BulkUploadPreviewModals.SHOW_PARTIAL_UPLOAD_MODAL:
      return (
        <ExecutionStatusModal
          mainText="Uploading failed"
          subText={`${bulkUploadRecord?.number_of_rows_with_error}/${bulkUploadRecord?.total_number_of_rows} records failed to upload due to error in data. Please retry uploading after fixing the errors. `}
          status={STATUS_FAILED}
          errorBlock={
            bulkUploadRecord &&
            [
              BulkUploadStatus.STATUS_EXECUTION_FAILED,
              BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS,
            ].includes(bulkUploadRecord.status) &&
            bulkUploadRecord.error_file_url ? (
              <ErrorBlock
                mainText={`⚠️ ${bulkUploadRecord?.number_of_rows_with_error} errors found`}
                subText="Please download error report > fix ALL errors > re-upload file "
                buttonText="Get error report"
                downloadURL={bulkUploadRecord?.error_file_url}
                onClick={clickHandlers.onClickGetErrorReport}
              />
            ) : (
              <ErrorBlock
                mainText="⚠️ There was an unexpected error ."
                subText="Please contact support or try again later"
                buttonText="Go Back"
                onClick={clickHandlers.onClickGoBack}
              />
            )
          }
        />
      );

    case BulkUploadPreviewModals.SHOW_SUCCESS_MODAL:
      return (
        <SuccessfullStatusModal
          mainText={
            labelMap?.success?.mainText
              ? labelMap.success.mainText
              : 'Upload successfully processed🎉'
          }
          subText={
            labelMap?.success?.subText
              ? labelMap.success.subText
              : 'View updated information using the link below.'
          }
          buttonText={labelMap?.success?.buttonText}
          callBackOnClick={clickHandlers.onClickSuccessCallback}
        />
      );

    default:
      return <></>;
  }
};

export default usePreviewModals;
