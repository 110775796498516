import { type GetFlexiBenefitsSettingsResponse } from 'api/flexibleBenefits';
import { type FlexiBenefitsSettings } from './types';
import { uniqueId } from 'utils/Numbers';
import { format, parse } from 'date-fns';
import { dateFormats } from 'utils/Dates';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';

export const parseSettings = (
  settings: GetFlexiBenefitsSettingsResponse,
): FlexiBenefitsSettings => {
  const { employeeDeclarationWindow, employeeProofUploadWindow, ...rest } = settings;
  const parsed = { ...rest } as FlexiBenefitsSettings;
  const declarationWindow = { ...employeeDeclarationWindow } as GenericDeclaration;
  if (declarationWindow.type === 'custom') {
    if (employeeDeclarationWindow.dates && Array.isArray(employeeDeclarationWindow.dates)) {
      let dates = employeeDeclarationWindow.dates || [];
      const parsedDates = dates.reduce(
        (a, c) => ({
          ...a,
          [uniqueId()]: {
            startDate: parse(c.startDate, dateFormats.dayMonth, new Date()),
            endDate: parse(c.endDate, dateFormats.dayMonth, new Date()),
          },
        }),
        {},
      );
      declarationWindow.dates = parsedDates;
    }
  }

  const proofWindow = { ...employeeProofUploadWindow } as GenericDeclaration;
  if (proofWindow.type === 'custom') {
    if (employeeProofUploadWindow.dates && Array.isArray(employeeProofUploadWindow.dates)) {
      let dates = employeeProofUploadWindow.dates || [];
      const parsedDates = dates.reduce(
        (a, c) => ({
          ...a,
          [uniqueId()]: {
            startDate: parse(c.startDate, dateFormats.dayMonth, new Date()),
            endDate: parse(c.endDate, dateFormats.dayMonth, new Date()),
          },
        }),
        {},
      );
      proofWindow.dates = parsedDates;
    }
  }
  parsed.employeeDeclarationWindow = declarationWindow;
  parsed.employeeProofUploadWindow = proofWindow;
  parsed.isTaxableAfterMonthEnabled = !!parsed.fbpDefaultTaxableSettingAutoTurnOn;
  parsed.isAutoReleaseEnabledForUnclaimedBenefits =
    !!parsed.shouldHoldFlexiAmountSettingAutoTurnOff;

  parsed.isAutoOpenWindowForNewEmployeesEnabled = !!parsed.autoOpenDeclarationWindowForNewEmployees;
  parsed.isAutoOpenWindowForSalaryRevisedEmployeesEnabled =
    !!parsed.autoOpenDeclarationWindowForSalaryRevisedEmployees;

  return parsed as FlexiBenefitsSettings;
};

export const createPayload = (
  settings: FlexiBenefitsSettings,
): Partial<GetFlexiBenefitsSettingsResponse> => {
  const {
    employeeDeclarationWindow,
    employeeProofUploadWindow,
    isAutoReleaseEnabledForUnclaimedBenefits,
    isTaxableAfterMonthEnabled,
    isAutoOpenWindowForNewEmployeesEnabled,
    isAutoOpenWindowForSalaryRevisedEmployeesEnabled,
    ...rest
  } = settings;
  const payload = { ...rest } as Partial<GetFlexiBenefitsSettingsResponse>;

  const declarationWindow = {
    ...employeeDeclarationWindow,
  } as GetFlexiBenefitsSettingsResponse['employeeDeclarationWindow'];
  if (declarationWindow.type === 'custom' && employeeDeclarationWindow.type === 'custom') {
    const dates = employeeDeclarationWindow.dates || {};
    const parsedDates = Object.values(dates).map((e) => ({
      ...e,
      startDate: format(e.startDate || new Date(), dateFormats.dayMonth),
      endDate: format(e.endDate || new Date(), dateFormats.dayMonth),
    }));
    declarationWindow.dates = parsedDates;
  }

  const proofWindow = {
    ...employeeProofUploadWindow,
  } as GetFlexiBenefitsSettingsResponse['employeeDeclarationWindow'];
  if (proofWindow.type === 'custom' && employeeProofUploadWindow.type === 'custom') {
    const dates = employeeProofUploadWindow.dates || {};
    const parsedDates = Object.values(dates).map((e) => ({
      ...e,
      startDate: format(e.startDate || new Date(), dateFormats.dayMonth),
      endDate: format(e.endDate || new Date(), dateFormats.dayMonth),
    }));
    proofWindow.dates = parsedDates;
  }

  payload.employeeDeclarationWindow = declarationWindow;
  payload.employeeProofUploadWindow = proofWindow;

  payload.fbpDefaultTaxableSettingAutoTurnOn = isTaxableAfterMonthEnabled
    ? payload.fbpDefaultTaxableSettingAutoTurnOn
    : null;
  payload.shouldHoldFlexiAmountSettingAutoTurnOff = isAutoReleaseEnabledForUnclaimedBenefits
    ? payload.shouldHoldFlexiAmountSettingAutoTurnOff
    : null;

  payload.autoOpenDeclarationWindowForNewEmployees =
    isAutoOpenWindowForNewEmployeesEnabled && payload.autoOpenDeclarationWindowForNewEmployees
      ? payload.autoOpenDeclarationWindowForNewEmployees
      : null;
  payload.autoOpenDeclarationWindowForSalaryRevisedEmployees =
    isAutoOpenWindowForSalaryRevisedEmployeesEnabled &&
    !!payload.autoOpenDeclarationWindowForSalaryRevisedEmployees
      ? payload.autoOpenDeclarationWindowForSalaryRevisedEmployees
      : null;

  return payload;
};
