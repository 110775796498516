import { format } from 'date-fns';
import useFetchUserDetails from './useFetchUserDetails';
import useFetchCompensationDetails from './useFetchCompensationDetails';
import { AppError } from 'utils/AppError';
import { FetchCompensationDetailsResponse } from './useFetchCompensationDetails';

export type PayDetailsData = {
  userData: any;
  compensationData: FetchCompensationDetailsResponse | undefined;
  firstName: string | null;
  error: AppError | null;
  isLoading: boolean;
  refetch: () => void;
};

const useFetchPayDetailsData = (userId: string | null): PayDetailsData => {
  const {
    data: userData,
    firstName,
    error: userError,
    isLoading: isUserDataLoading,
    refetch: refetchUserData,
  } = useFetchUserDetails(userId);

  const deactivateDate = userData?.deactivateDate
    ? format(new Date(userData.deactivateDate ?? ''), 'yyyy-MM-dd')
    : null;
  const currentDate = format(Date.now(), 'yyyy-MM-dd');
  const payrollMonth = deactivateDate
    ? deactivateDate >= currentDate
      ? deactivateDate
      : currentDate
    : currentDate;

  const {
    data: compensationData,
    isLoading: isCompensationDataLoading,
    error: compensationError,
    refetch: refetchCompensationData,
  } = useFetchCompensationDetails(userId, payrollMonth);

  const isLoading = isUserDataLoading || isCompensationDataLoading;
  const error = userError || compensationError;

  const refetch = () => {
    refetchUserData();
    refetchCompensationData();
  };

  return {
    userData,
    compensationData,
    firstName,
    error,
    isLoading,
    refetch,
  };
};

export default useFetchPayDetailsData; 