import { Box, Button } from '@razorpay/blade/components';
import React from 'react';

export const Receipts = ({
  docs,
}: {
  docs?: Array<{
    id?: string;
    link?: string;
  }>;
}) => {
  if (!docs || docs.length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap="spacing.4" flexWrap="wrap" marginTop="spacing.3">
      {docs.map((doc, idx) => (
        <Box key={doc.id} width={'calc(50% - 6px)'}>
          <Button target="_blank" isFullWidth variant="secondary" href={doc.link}>
            #{idx + 1}
          </Button>
        </Box>
      ))}
    </Box>
  );
};
