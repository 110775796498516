import { getRandomPick } from 'components/PayrollAIAssistant/useRandomPick';
import { PayrollAiAssistantPollSchemaContract } from 'schemas/PayrollAiAssistantPollSchema';

export const getTaxBreakupCalculation = (
  pollData: PayrollAiAssistantPollSchemaContract | null,
  fromRegime: string,
) => {
  const messagePrefix = getRandomPick([
    'Absolutely! 🙈 Let me break down how I got to those results:',
    "Of course! 🙈 Here's the breakdown of how I reached those results:",
    'Definitely! 🙈 Let me walk you through how I reached those results:',
    "Certainly! 🙈 Here's the breakdown of the process behind those results",
  ]);
  const calculation =
    (fromRegime === 'suggested'
      ? pollData?.response?.tax_saving_breakup?.suggested_regime === 'old'
        ? pollData?.response?.tax_saving_breakup?.old_calculation_explanation
        : pollData?.response?.tax_saving_breakup?.new_calculation_explanation
      : pollData?.response?.tax_saving_breakup?.suggested_regime === 'old'
      ? pollData?.response?.tax_saving_breakup?.new_calculation_explanation
      : pollData?.response?.tax_saving_breakup?.old_calculation_explanation) ??
    "I'm Sorry, I don't have the calculation with me right now.";
  return `${messagePrefix} \n\n${calculation}\n\nDo you want to add or remove any investment options?`;
};
