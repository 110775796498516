import api from 'api';
import Pagination from 'components/ui/Pagination/index';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { BulkUploadStagingDetailsSchemaContract } from 'schemas/BulkUploadStagingDetailsSchema';
import { AppError } from 'utils/AppError';
import Spinner from '../../ui/Spinner/index';
import { TableWithLoader } from '../../ui/Table/index';
import ErrorCard from '../components/ErrorCard';
import styles from '../index.module.scss';
import { GET_BULK_UPLOAD_STAGING_RECORD } from '../queries/queries';

export type arrearsBreakdownType = {
  name?: string | null;
  amount?: number | null;
};

export type PreviewTableProps = {
  bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
  columns: Array<{
    title: string;
    render: (
      item: BulkUploadStagingDetailsSchemaContract['bulk_uploads_staging']['data'][0],
    ) => React.ReactNode;
  }>;
  tableTopView?: (
    bulkUploadRecord?: BulkUploadDetailsSchemaContract[0] | null,
    record?: BulkUploadStagingDetailsSchemaContract,
  ) => React.ReactNode;
  tableBottomView?: (
    bulkUploadRecord?: BulkUploadDetailsSchemaContract[0] | null,
    record?: BulkUploadStagingDetailsSchemaContract,
  ) => React.ReactNode;
};

const PreviewTable = ({
  bulkUploadRecord,
  columns,
  tableBottomView,
  tableTopView,
}: PreviewTableProps) => {
  const [rowLimit, setRowLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const bulkUploadRecordId = bulkUploadRecord?.id ?? 0;

  const {
    isLoading,
    isRefetching,
    error,
    data: bulkUploadStagingRecord,
  } = useQuery(
    [GET_BULK_UPLOAD_STAGING_RECORD, rowLimit, page, bulkUploadRecordId],
    ({ queryKey }) => {
      const rowLimit = queryKey[1] as number;
      const page = queryKey[2] as number;
      const bulkUploadRecordId = queryKey[3] as number;
      if (!bulkUploadRecord) {
        return Promise.reject('Bulk upload record not found');
      }

      return api.bulkUploads.fetchBulkUploadStagingRecord(bulkUploadRecordId, page, rowLimit);
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );

  const totalRows = bulkUploadStagingRecord?.bulk_uploads_staging?.total ?? 0;
  const totalPages = Math.ceil(totalRows / rowLimit);

  if (isLoading || isRefetching) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorCard
        error={error}
        buttonText={'Back to Dashboard'}
        onClick={() => {
          window.location.href = '/dashboard';
        }}
      />
    );
  }

  const tableData = bulkUploadStagingRecord?.bulk_uploads_staging?.data ?? [];
  const isTableEmpty = tableData.length === 0;

  return (
    <>
      {tableTopView ? tableTopView(bulkUploadRecord, bulkUploadStagingRecord) : null}
      <TableWithLoader
        isLoading={isLoading}
        showAlternateColors={true}
        className={`w-full mt-0 ${styles['preview-table']} `}
        style={{ marginTop: 0 }}>
        <tbody>
          <tr className={styles['dynamic-row']}>
            {columns.map((column) => (
              <th key={column.title} className="text-left">
                {column.title}
              </th>
            ))}
          </tr>
          {tableData.map((stagingRecord) => (
            <tr key={stagingRecord.id} className={styles['dynamic-row']}>
              {columns.map((column) => (
                <td key={column.title}>{column.render(stagingRecord)}</td>
              ))}
            </tr>
          ))}
          {isTableEmpty && (
            <tr>
              <td className="text-center" colSpan={7}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </TableWithLoader>

      <Pagination
        className="mt-4"
        totalRows={totalRows}
        limitOptions={[5, 10, 15, 20]}
        limit={rowLimit}
        onLimitChange={setRowLimit}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
      {tableBottomView ? tableBottomView(bulkUploadRecord, bulkUploadStagingRecord) : null}
    </>
  );
};

export default PreviewTable;
