import React from 'react';
import { Input, InputElement } from 'components/ui/Form/Input';
import { RadioButton } from 'components/ui/RadioButton';
import { formatISOToLaravelTimestamp } from 'utils/Dates';

const DependentBeneficiaryDetails = ({
  label,
  name,
  dob,
  gender,
  isEligible,
  compulsoryError,
  shouldShowGender,
  onDelete,
  showDelete,
  updateName,
  updateDob,
  updateGender,
}: {
  label: React.ReactNode;
  name?: string | null;
  dob?: string | null;
  gender?: 'm' | 'f' | 'male' | 'female' | 'o' | null;
  isEligible: boolean;
  compulsoryError?: string | null;
  shouldShowGender?: boolean;
  onDelete: CallableFunction;
  showDelete?: boolean;
  updateName: CallableFunction;
  updateDob: CallableFunction;
  updateGender: CallableFunction;
  isAdmin: boolean;
}) => {
  const error =
    compulsoryError && compulsoryError.length > 0
      ? compulsoryError
      : !isEligible
      ? 'Not eligible'
      : null;
  const deleteButton = (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9C8.55229 9 9 9.44771 9 10V16C9 16.5523 8.55229 17 8 17C7.44772 17 7 16.5523 7 16V10C7 9.44771 7.44772 9 8 9Z"
        fill="#C4C4C4"
      />
      <path
        d="M13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V10Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4V3C5 1.34315 6.34315 0 8 0H12C13.6569 0 15 1.34315 15 3V4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H18V19C18 20.6569 16.6569 22 15 22H5C3.34315 22 2 20.6569 2 19V6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H5ZM7 3C7 2.44772 7.44772 2 8 2H12C12.5523 2 13 2.44772 13 3V4H7V3ZM16 6V19C16 19.5523 15.5523 20 15 20H5C4.44772 20 4 19.5523 4 19V6H16Z"
        fill="#C4C4C4"
      />
    </svg>
  );

  return (
    <div className="text-3xl flex flex-col lg:space-y-4">
      <div className="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0 w-full">
        <div
          className={`flex w-full lg:w-40 font-bold lg:text-right mr-16 text-2xl my-auto pt-10 `}
        >
          &nbsp;
          <div className={`flex-1 font-bold lg:text-right text-2xl ${error ? 'text-red-700' : ''}`}>
            {label} {error ? `(${error})` : ''}
          </div>{' '}
          <div className=" inline-block lg:hidden" onClick={() => onDelete()}>
            {deleteButton}
          </div>
        </div>
        <InputElement label="Full name" className="lg:w-96 my-auto">
          <Input
            type="text"
            value={name ? name : undefined}
            onChange={(event) => updateName(event.target.value)}
          />
        </InputElement>
        <InputElement label="Date of birth" className="lg:w-64 my-auto">
          <Input
            type="date"
            value={dob ? dob.split('T')[0] : ''}
            onChange={(event) => {
              updateDob(
                formatISOToLaravelTimestamp(event.currentTarget.valueAsDate?.toISOString()),
              );
            }}
          />
        </InputElement>
        <InputElement
          label="Gender"
          className={`lg:w-96 my-auto ${
            shouldShowGender === false ? 'hidden lg:block lg:opacity-0' : ''
          }`}
        >
          <div className="space-x-2">
            <RadioButton
              isDisabled={shouldShowGender === false}
              onSelect={() => updateGender('m')}
              isSelected={gender === 'm' || gender === 'male'}
            >
              Male
            </RadioButton>
            <RadioButton
              isDisabled={shouldShowGender === false}
              onSelect={() => updateGender('f')}
              isSelected={gender === 'f' || gender === 'female'}
            >
              Female
            </RadioButton>
          </div>
        </InputElement>
        {showDelete !== false && (
          <div
            className="lg:my-auto hidden lg:block lg:pt-10 cursor-pointer"
            onClick={() => onDelete()}
          >
            {deleteButton}
          </div>
        )}
      </div>
    </div>
  );
};

export default DependentBeneficiaryDetails;
