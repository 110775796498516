import React from 'react';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export interface DelightScreenContext {
  isVisible: boolean;
  content: JSX.Element[] | JSX.Element;
  onFinish?: () => void;
}

export interface OnboardingContextType {
  delightScreen: DelightScreenContext;
  setDelightScreen: Dispatch<SetStateAction<DelightScreenContext>>;
}

export const OnboardingContext = createContext<OnboardingContextType>({
  delightScreen: {
    isVisible: false,
    content: <></>,
  },
  setDelightScreen: () => {
    //no-op
  },
});

export const useOnboardingContext = () => {
  const { delightScreen, setDelightScreen } = useContext(OnboardingContext);

  return {
    delightScreen,
    setDelightScreen,
  };
};
