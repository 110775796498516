
import React, { useState, useEffect, ReactElement } from 'react';
import FileUpload from '../../ui/FileUpload/index';
import { FileUploadStatus, MimeType } from '../../ui/FileUpload/constants';
import { BulkUploadStatus, routeToTypeMap } from 'constants/bulkUpload';
import { useMutation } from 'react-query';
import api from '../../../api/index';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import ErrorBlock from '../components/ErrorBlock/index';
import { useLocation } from 'react-router-dom';
import styles from './../index.module.scss';
import BulkUploadsPolling from '../../BulkUploadsPolling/index';
import { BulkUploadsValidationPollStatuses } from '../../../constants/bulkUpload';
import { AppError } from 'utils/AppError';
import { getStatusBasedOnBulkRecord } from './../Utils/utils';
import { trackXPayrollEvent } from 'utils/analytics';
import { truncateString } from 'utils/Strings';



const FileUploadSection = ({
    bulkUploadRecord,
    setBulkUploadRecord
}:
    {
        bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
        setBulkUploadRecord: (bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null) => void;
    }): ReactElement => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUploadCancelled, setFileUploadCancelled] = useState<Boolean>(false);
    const [status, setStatus] = useState<FileUploadStatus>(FileUploadStatus.NOT_INITIATED);
    const [error, setError] = useState<string>('');
    const location = useLocation();

    useEffect(() => {
        setStatus(getStatusBasedOnBulkRecord((bulkUploadRecord)));
        
        if(bulkUploadRecord?.error_file_url === null && bulkUploadRecord?.errors && bulkUploadRecord.errors.length > 0){
            setError(bulkUploadRecord?.errors[0]);
        }
        
    }, [bulkUploadRecord]);

    const sendFile = useMutation((requestData: FormData) => {

        sessionStorage.removeItem('bulkUploadPollId');

        if (requestData && requestData.has('type') && requestData.has('file')) {
            return api.bulkUploads.sendFile(requestData);
        } else {
            throw "Could not upload your file."
        }
    }, {
        onSuccess: (data) => {
            setBulkUploadRecord((data.length > 0) ? data[0] : null);

            sessionStorage.setItem('bulkUploadPollId', data[0].id.toString());

            if (data.length > 0 && data[0].status === BulkUploadStatus.STATUS_VALIDATION_FAILED) {
                setStatus(FileUploadStatus.FAILURE);
            }

            else if (data.length > 0 && data[0].status === BulkUploadStatus.STATUS_VALIDATION_SUCCESS) {
                setStatus(FileUploadStatus.SUCCESS);
            }
        },
        onError: (error: typeof AppError) => {
            setError(error.toString());
            setStatus(FileUploadStatus.FAILURE);
        }
    });

    function shouldPoll(){
        if(bulkUploadRecord?.id && 
            sessionStorage.getItem('bulkUploadPollId') &&
        !fileUploadCancelled &&
        BulkUploadsValidationPollStatuses.includes(bulkUploadRecord.status)){
            return true;
        }
        return false;
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFile(event.target.files ? event.target.files[0] : null);
        setFileUploadCancelled(false);
        setStatus(FileUploadStatus.UPLOADING);

        trackXPayrollEvent("addition_deduction_lop.file_upload","interaction","bulk-action")

        if (event.target.files && event.target.files[0].type !== MimeType.XLSX) {
            setStatus(FileUploadStatus.FAILURE);
            setError('File uploaded is not of type .xlsx');
            trackXPayrollEvent("addition_deduction_lop.file_upload.incorrect_filetype","interaction","bulk-action")

            return;
        }

        if (event.target.files && (event.target.files[0].size / 1048576 > 5)) {
            setStatus(FileUploadStatus.FAILURE);
            setError('File uploaded is greater than 5 MB');
            trackXPayrollEvent("addition_deduction_lop.file_upload.exceeded_size","interaction","bulk-action")

            return;
        }

        let requestData = new FormData();
        requestData.append('type', (routeToTypeMap[location.pathname]).toString());
        requestData.append('file', event.target.files ? event.target.files[0] : '');

        sendFile.mutate(requestData);

    }

    function onCancel(){

        setFileUploadCancelled(true);
        sessionStorage.removeItem('bulkUploadPollId');

        if (bulkUploadRecord) {
            
            api.bulkUploads.cancelBulkUpload(bulkUploadRecord.id)
                .then((response) => {
                    setBulkUploadRecord(null);
                })
                .catch((error: Error) => {
                    console.error("Error on cancelling org's bulk upload : ", error);
                });
            
        }
    }

    return (
        <>
            {shouldPoll() &&
                <BulkUploadsPolling
                    id={String(bulkUploadRecord?.id)}
                    action={'file-upload'}
                    tries={400}
                    interval={2000}
                    setBulkUploadRecordData={setBulkUploadRecord}
                />}
            <div className={styles['section']}>
                <span className={styles['section-main-text']}>{'2. Upload the updated template below👇'}</span>

                <FileUpload
                    id={'bulk-upload-input-file'}
                    onChange={onChange}
                    onCancel={onCancel}
                    status={status}
                    value={truncateString((bulkUploadRecord && bulkUploadRecord.file_name) ? bulkUploadRecord.file_name : (selectedFile ? selectedFile.name : 'File Not Found'), 25)}
                    allowedMimeTypes={[MimeType.XLSX]}
                    maxSize={5}
                    className='mt-8'
                    error={(bulkUploadRecord && bulkUploadRecord.error_file_url) ? '' : error}
                    disableFileReuploadButton={
                        [BulkUploadStatus.STATUS_VALIDATION_PENDING, BulkUploadStatus.STATUS_VALIDATION_PROGRESS].includes(bulkUploadRecord?.status ?? 0)
                    }
                    disableCancelButton = {!shouldPoll()}
                />

                {
                    bulkUploadRecord && 
                    bulkUploadRecord.status === BulkUploadStatus.STATUS_VALIDATION_FAILED &&
                    bulkUploadRecord.error_file_url &&
                    <div className={styles['error-block']}>
                        <ErrorBlock mainText={'⚠️ ' + bulkUploadRecord.number_of_rows_with_error?.toString() + ' errors found'}
                            subText={'Please download error report > fix ALL errors > re-upload file '}
                            buttonText={'Get error report'}
                            downloadURL={bulkUploadRecord.error_file_url}
                            onClick={() => trackXPayrollEvent("addition_deduction_lop.error_file_download","interaction","bulk-action")}
                        />
                    </div>
                }


            </div>
        </>
    );
}

export default FileUploadSection;
