import React from 'react';
import { Box, Text } from '@razorpay/blade/components';
import { convertToOrdinal } from 'utils/Dates';
import { CycleProps } from '../types';
import { getCycleText, getSummaryText } from '../utils';

interface SummaryCardProps {
    payrollDate: number;
    payrollCycle: CycleProps;
    attendanceCycle: CycleProps;
    newJoinerCutOff: number | null;
    isNewJoinerArrearEnabled: boolean;
}

const SummaryCard = ({
    payrollDate,
    payrollCycle,
    attendanceCycle,
    newJoinerCutOff,
    isNewJoinerArrearEnabled
}: SummaryCardProps) => {

    const isNewJoinerDetailsVisible = isNewJoinerArrearEnabled && !!newJoinerCutOff;
    return (
        (<Box
            backgroundColor={'surface.background.gray.subtle'}
            padding={['spacing.6', 'spacing.7', 'spacing.8', 'spacing.7']}
            borderRadius={'medium'}
            display={'flex'}
            flexDirection={'column'}
            gap={'spacing.6'}
        >
            <Text
                size="large"
                weight="semibold"
                color="surface.text.gray.subtle"
            >
                ✅  Summary
            </Text>
            <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'spacing.7'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.1"
                    width={'192px'}
                >
                    <Text
                        color="surface.text.gray.subtle"
                    >
                        Payroll Date
                    </Text>
                    <Text
                        size="large"
                        weight="semibold"
                        color="surface.text.gray.subtle"
                    >
                        {`${convertToOrdinal(payrollDate)} of every month`}
                    </Text>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.1"
                    width={'192px'}
                >
                    <Text
                        color="surface.text.gray.subtle"
                    >
                        Payroll Cycle
                    </Text>
                    <Text
                        size="large"
                        weight="semibold"
                        color="surface.text.gray.subtle"
                    >
                        {getSummaryText(payrollCycle)}
                    </Text>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.1"
                    width={'192px'}
                >
                    <Text
                        color="surface.text.gray.subtle"
                    >
                        Attendance Cycle
                    </Text>
                    <Text
                        size="large"
                        weight="semibold"
                        color="surface.text.gray.subtle"
                    >
                        {getSummaryText(attendanceCycle)}
                    </Text>
                </Box>

                {isNewJoinerDetailsVisible &&
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="spacing.1"
                        width={'192px'}
                    >
                        <Text
                            color="surface.text.gray.subtle"
                        >
                            New Joiner Cut-Off
                        </Text>
                        <Text
                            size="large"
                            weight="semibold"
                            color="surface.text.gray.subtle"
                        >
                            {newJoinerCutOff ? `${convertToOrdinal(newJoinerCutOff)} of the month` : 'N/A'}
                        </Text>
                    </Box>
                }

            </Box>
        </Box>)
    );
}

export default SummaryCard;