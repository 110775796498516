import React from 'react';
import { useModal } from 'components/ui/Modals';
import FreshTeamsHelpModal from '../FreshTeamsHelpModal';
import { ClickableCard } from 'components/ui/SidebarCard';

const FreshTeamsHelpSidebarElement = () => {
  const { openModal } = useModal();

  const handleShowDetails = () => {
    openModal(<FreshTeamsHelpModal />);
  };

  return (
    <ClickableCard
      heading="Integration Details"
      onClick={handleShowDetails}
      imageSymbolType={'requests'}
    >
      More details about role mapping, data fields, etc.
    </ClickableCard>
  );
};

export default FreshTeamsHelpSidebarElement;
