import React from 'react';
import {
  KycPageMainViewWrapper,
  KycPageSideViewWrapper,
  KycPageWrapper,
  KycViewContent,
  KycViewHeader,
} from '../styles';
import {
  Alert,
  Box,
  InfoIcon,
  Text,
  Heading,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import SidebarHeader from './SidebarHeader';
import SidebarSection from './SidebarSection';
import { documentUploadLinks, needHelpLinks } from '../constants';

const KycRejectedView = () => {
  return (
    (<KycPageWrapper>
      <KycPageSideViewWrapper>
        <Box
          overflowY={'auto'}
          display={'flex'}
          flexDirection={'column'}
          paddingY={'spacing.9'}
          paddingX={'spacing.10'}>
          <SidebarHeader onBackClick={() => (window.location.href = '/dashboard')} />
          <Box display={'flex'} gap={'spacing.2'} alignItems={'center'} marginTop={'spacing.4'}>
            <Text color="surface.text.gray.muted">Why do KYC?</Text>
            <Tooltip
              content={'Complete KYC to unlock salary slips and salary transfers'}
              placement="right">
              <TooltipInteractiveWrapper>
                <InfoIcon color="interactive.icon.gray.normal" size="small" />
              </TooltipInteractiveWrapper>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.10" marginTop="spacing.10">
            <SidebarSection title="Helpful Links" links={documentUploadLinks} />
            <SidebarSection title="Need Help?" links={needHelpLinks} />
          </Box>
        </Box>
      </KycPageSideViewWrapper>
      <KycPageMainViewWrapper>
        <Box height="100vh" display={'grid'} gridTemplateRows={'auto 1fr'}>
          <KycViewHeader>
            <Heading size="large">📑 Failed to verify documents</Heading>
          </KycViewHeader>

          <KycViewContent>
            <Alert
              isDismissible={false}
              title="Verification failed"
              description="Your KYC could not be verified. Please reach out to support for further updates."
              color="negative"
            />
          </KycViewContent>
        </Box>
      </KycPageMainViewWrapper>
    </KycPageWrapper>)
  );
};

export default KycRejectedView;
