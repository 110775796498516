export const bankDetailsHelpfulLinks = [
  {
    href: 'https://razorpay.com/docs/x/xpayroll/employers/setup/kyc/#get-started',
    icon: '🔗',
    label: 'How to do KYC',
    target: '_blank',
  },
  {
    href: 'https://razorpay.com/docs/x/xpayroll/employers/setup/kyc/#best-practices',
    icon: '🔗',
    label: 'Doc Guidelines',
    tooltip: `Best practices on uploading documents`,
    target: '_blank',
  },
];

export const documentUploadLinks = [
  {
    href: 'https://razorpay.com/docs/x/xpayroll/employers/setup/kyc/#get-started',
    icon: '🔗',
    label: 'How to do KYC',
    target: '_blank',
  },
  {
    href: 'https://razorpay.com/docs/x/xpayroll/employers/setup/kyc/#best-practices',
    icon: '🔗',
    label: 'Doc Guidelines',
    tooltip: `Best practices on uploading documents`,
    target: '_blank',
  },
];

export const needHelpLinks = [
  {
    href: 'https://xproduct.razorpay.com/payroll/webinars',
    icon: '💪🏻',
    label: 'Book a webinar',
    target: '_blank',
  },
  {
    href: 'mailto:xpayroll@razorpay.com',
    icon: '📪',
    label: 'Reach out to us',
    target: '_blank',
  },
];

export const kycErrorMapping = {
  inactive_account:
    "Looks like your Razorpay account isn't active. You can retry verification or provide the documents below to initiate KYC.",
  company_name_mismatch:
    "Looks like the company details aren't matching in your existing account. You can retry verification or provide the documents below to initiate KYC.",
  gstin_mismatch:
    "Looks like your company GSTIN on XPayroll don't match with your Razorpay account. You can retry verification or provide the documents below to initiate KYC.",
  success: 'Kyc is Verified',
} as const;

export enum DocumentStatus {
  NOT_INITIATED = 1,
  UPLOADING = 2,
  SUCCESS = 3,
  ERROR = 4,
  VERIFYING = 5,
  VERIFIED = 6,
  WAITING = 7,
}

export const orgTypes = {
  PRIVATE_LIMITED: 1,
  SOLE_PROP: 2,
  PARTNERSHIPS: 3,
  TRUST: 4,
} as const;

export const bvsDocumentTypes = {
  PERSONAL_PAN: 'personal_pan',
  BUSINESS_PAN: 'business_pan',
  AADHAAR: 'aadhaar', // for E Aadhaar
  AADHAAR_FRONT: 'aadhaar_front',
  AADHAAR_BACK: 'aadhaar_back',
  CIN: 'cin',
  GST_CERTIFICATE: 'gst_certificate',
  VOTERS_ID: 'voters_id',
  VOTERS_ID_BACK: 'voters_id_back',
  PASSPORT: 'passport',
  PASSPORT_BACK: 'passport_back',
  LLP_DEED: 'llp_deed',
  BUSINESS_WEBSITE: 'business_website',
  SHOP_ESTABLISHMENT: 'shop_establishment',
  MSME: 'msme',
  PARTNERSHIP_DEED: 'partnership_deed',
  CERTIFICATE_OF_INCORPORATION: 'certificate_of_incorporation',
  TRUST_SOCIETY_NGO_BUSINESS_CERTIFICATE: 'trust_society_ngo_business_certificate',
  DRIVERS_LICENSE: 'drivers_license',
  SHOP_REG_CERTIFICATE: 'shop_reg_certificate',
  SALES_TAX: 'sales_tax',
  GRAM_PANCHAYAT_CERTIFICATE: 'gram_panchayat_certificate',
  SOCIETY_REG_CERTIFICATE: 'society_reg_certificate',
  NGO_CERTIFICATE: 'ngo_certificate',
  BANK_ACCOUNT: 'bank_account',
  UDYAM_REGISTRATION: 'udyam_registration',
  IEC: 'iec',
  FSSAI: 'fssai',
} as const;

export const manualDocumentTypes = {
  CANCELLED_CHEQUE: 'cancelled_cheque',
  ACCOUNT_STATEMENT: 'account_statement',
  VAT_TIN_REGISTRATION: 'vat_tin_registration',
  TRADE_LICENSE: 'trade_license',
} as const;

export const bvsIdentityProofs = [bvsDocumentTypes.SALES_TAX, bvsDocumentTypes.SHOP_ESTABLISHMENT];
export const bvsAddressProofs = [
  bvsDocumentTypes.AADHAAR_BACK,
  bvsDocumentTypes.AADHAAR_FRONT,
  bvsDocumentTypes.AADHAAR,
  bvsDocumentTypes.PASSPORT,
  bvsDocumentTypes.PASSPORT_BACK,
  bvsDocumentTypes.VOTERS_ID,
  bvsDocumentTypes.VOTERS_ID_BACK,
];

export const solePropIdentityProofs = [
  { name: 'GST Certificate', value: bvsDocumentTypes.GST_CERTIFICATE },
  { name: 'Shops & Establishment Certificate', value: bvsDocumentTypes.SHOP_ESTABLISHMENT },
  { name: 'Trade License', value: manualDocumentTypes.TRADE_LICENSE },
  { name: 'Sales Tax Registration', value: bvsDocumentTypes.SALES_TAX },
  { name: 'VAT/TIN Registration', value: manualDocumentTypes.VAT_TIN_REGISTRATION },
];

export const partnershipsIdentityProofs = [
  { name: 'GST Certificate', value: bvsDocumentTypes.GST_CERTIFICATE },
  { name: 'Shops & Establishment Certificate', value: bvsDocumentTypes.SHOP_ESTABLISHMENT },
  { name: 'Trade License', value: manualDocumentTypes.TRADE_LICENSE },
  { name: 'Sales Tax Registration', value: bvsDocumentTypes.SALES_TAX },
  { name: 'VAT/TIN Registration', value: manualDocumentTypes.VAT_TIN_REGISTRATION },
];

export const OrgFields = {
  [orgTypes.PRIVATE_LIMITED]: {
    companyDetailsFields: [
      bvsDocumentTypes.BUSINESS_PAN,
      bvsDocumentTypes.CERTIFICATE_OF_INCORPORATION,
    ],
    isIdentityProofSelectionRequired: false,
    identityProofOptions: [],
  },
  [orgTypes.SOLE_PROP]: {
    companyDetailsFields: [],
    isIdentityProofSelectionRequired: true,
    identityProofOptions: solePropIdentityProofs,
  },
  [orgTypes.PARTNERSHIPS]: {
    companyDetailsFields: [bvsDocumentTypes.PARTNERSHIP_DEED, bvsDocumentTypes.BUSINESS_PAN],
    isIdentityProofSelectionRequired: true,
    identityProofOptions: partnershipsIdentityProofs,
  },
  [orgTypes.TRUST]: {
    companyDetailsFields: [
      bvsDocumentTypes.BUSINESS_PAN,
      bvsDocumentTypes.TRUST_SOCIETY_NGO_BUSINESS_CERTIFICATE,
    ],
    isIdentityProofSelectionRequired: false,
    identityProofOptions: [],
  },
} as const;

export const adhaarFields = [bvsDocumentTypes.AADHAAR_BACK, bvsDocumentTypes.AADHAAR_FRONT];

export const addressProofOptions = [
  {
    name: 'E-Aadhar',
    value: bvsDocumentTypes.AADHAAR,
  },
  {
    name: 'Aadhar',
    value: bvsDocumentTypes.AADHAAR_FRONT,
  },
  { name: 'Passport', value: bvsDocumentTypes.PASSPORT },
  { name: 'Voter ID', value: bvsDocumentTypes.VOTERS_ID },
];

export const addressProofGroups = {
  VOTER_ID_FRONT_BACK: {
    id: bvsDocumentTypes.VOTERS_ID,
    items: [bvsDocumentTypes.VOTERS_ID, bvsDocumentTypes.VOTERS_ID_BACK],
    labels: ['Front Side', 'Back Side'],
  },
  AADHAAR_FRONT_BACK: {
    id: bvsDocumentTypes.AADHAAR_FRONT,
    items: [bvsDocumentTypes.AADHAAR_FRONT, bvsDocumentTypes.AADHAAR_BACK],
    labels: ['Front Side', 'Back Side'],
  },
  E_AADHAAR: {
    id: bvsDocumentTypes.AADHAAR,
    items: [bvsDocumentTypes.AADHAAR],
    labels: [''],
  },
  PASSPORT_FRONT_BACK: {
    id: bvsDocumentTypes.PASSPORT,
    items: [bvsDocumentTypes.PASSPORT, bvsDocumentTypes.PASSPORT_BACK],
    labels: ['Front Side', 'Back Side'],
  },
};

export const bankVerificationProofOptipons = [
  { name: 'Upload Cancelled Cheque', value: manualDocumentTypes.CANCELLED_CHEQUE },
  { name: 'Upload Bank A/C Statement', value: manualDocumentTypes.ACCOUNT_STATEMENT },
];

export const documentsBankAccountVerificationManualProof = [
  manualDocumentTypes.ACCOUNT_STATEMENT,
  manualDocumentTypes.CANCELLED_CHEQUE,
];

export const documentsBankAccountVerificationProof = [
  manualDocumentTypes.ACCOUNT_STATEMENT,
  manualDocumentTypes.CANCELLED_CHEQUE,
  bvsDocumentTypes.BANK_ACCOUNT,
];

export const bvsStatuses = {
  NotInitiated: 1,
  Pending: 2,
  OnHold: 3,
  Verified: 4,
  Rejected: 5,
};

export const BANK_VERIFICATION_MAX_ATTEMPTS = 5;

export const kycVersions = {
  V1: 'v1',
  V2: 'v2',
  UnAssigned: '',
};

export const eventCurrentAccountEntered = 'onboarding.ca_entered';

export const customerTypes = {
  RZP_CUSTOMER: 1,
  NOT_RZP_CUSTOMER: 2,
  DEFAULT: 0,
};
