import { Box, Text } from '@razorpay/blade/components';
import React from 'react';

type ConfigurationItemProps = {
  label: string;
  value: string;
};

const ConfigurationItem = ({ label, value }: ConfigurationItemProps) => {
  return (
    <Box>
      <Text color="surface.text.gray.muted" size="small">
        {label}
      </Text>
      <Text color="surface.text.gray.subtle" weight="medium" testID={label}>
        {value}
      </Text>
    </Box>
  );
};

export default ConfigurationItem;
