import React from 'react';
import { Modal, ModalBody, ModalHeader, Text, Box } from '@razorpay/blade/components';
import { getStaticMediaUrl } from 'utils/Urls';
import ShareIcon from '../../../../assets/payrollAiAssistant/ShareIcon.svg';
import Copy from '../../../../assets/payrollAiAssistant/link.svg';
import Mail from '../../../../assets/payrollAiAssistant/mail.svg';
import LinkedIn from '../../../../assets/payrollAiAssistant/linkedin-fill.svg';
import Whatsapp from '../../../../assets/payrollAiAssistant/whatsapp-line.svg';
import { showToastViaEvent } from 'utils/ToastEvents';

type EmployeeShareProps = {
  isOpen: boolean;
  onClose: () => void;
};

const APP_URL = window.location.origin + '/tax-optimizer';
export default function EmployeeShare({ isOpen, onClose }: EmployeeShareProps) {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(APP_URL);
    showToastViaEvent({
      text: 'Copied',
      timeout: 2000,
      type: 'success',
      icon: 'success',
    });
  };

  const handleShareLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(APP_URL)}`);
  };

  const handleShareSlack = () => {
    // to implement slack sharing logic
  };

  const handleShareWhatsApp = () => {
    window.open(`whatsapp://send?text=${encodeURIComponent(APP_URL)}`);
  };

  const handleShareEmail = () => {
    window.open(`mailto:?body=${encodeURIComponent(APP_URL)}`);
  };

  return (
    (<Modal isOpen={isOpen} onDismiss={onClose} size="small">
      <ModalHeader title="Share the joy of tax savings!" />
      <ModalBody>
        <Box display="flex" flexDirection={'column'} alignItems={'flex-start'} gap="24px">
          <Box
            display="flex"
            padding={'spacing.4'}
            alignItems="flex-start"
            gap="9px"
            width={'100%'}
            backgroundColor="surface.background.gray.intense"
            borderRadius={'large'}
            borderWidth={'thinner'}
            borderColor={'surface.border.gray.subtle'}>
            <img src={getStaticMediaUrl(ShareIcon)} alt="" height="56px" width="56px" />
            <Box display="flex" height="36px" flexDirection="column" alignItems={'flex-start'}>
              <Text weight="semibold" color="surface.text.gray.muted">
                Optimise tax with Ray
              </Text>
              <Text size="small" color="surface.text.gray.muted">
                XPayroll
              </Text>
            </Box>
          </Box>
          <Box display="flex" alignItems={'flex-start'} gap="spacing.5" alignSelf={'stretch'}>
            <ShareItem icon={Copy} onClick={handleCopyLink} />
            <ShareItem icon={LinkedIn} onClick={handleShareLinkedIn} />
            <ShareItem icon={Whatsapp} onClick={handleShareWhatsApp} />
            <ShareItem icon={Mail} onClick={handleShareEmail} />
          </Box>
        </Box>
      </ModalBody>
    </Modal>)
  );
}

const ShareItem = ({ icon, onClick }: { icon: string; onClick: () => void }) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        padding="spacing.4"
        width={'52px'}
        height={'52px'}
        backgroundColor="surface.background.gray.moderate"
        borderRadius={'medium'}
        borderWidth={'thin'}
        borderColor={'surface.border.gray.subtle'}>
        <img src={getStaticMediaUrl(icon)} alt="" height="22px" width="22px" />
      </Box>
    </div>
  );
};
