import { ArrowLeftIcon, Box, BoxProps, Heading, Link, Spinner } from '@razorpay/blade/components';
import leftImg from 'assets/layouts/circle_left.svg';
import rightImg from 'assets/layouts/right_circle.svg';
import React from 'react';
import { LeftImg, RightImg } from './styles';

interface PageLayoutProps {
  title?: string;
  RightSideLayout?: React.ReactNode;
  onBackClick?: (e: React.SyntheticEvent<Element, Event>) => void;
  isLoading?: boolean;
  boxProps?: BoxProps;
  containerProps?: BoxProps;
  headingProps?: BoxProps;
  isEmpty?: boolean;
  isError?: boolean;
  emptyView?: React.ReactNode;
  errorView?: React.ReactNode;
  withoutBackgroundImg?: boolean;
  hideHeading?: boolean;
  breadcrumb?: React.ReactNode;
}

const PageLayout = ({
  title,
  RightSideLayout,
  children,
  onBackClick,
  isLoading = false,
  boxProps = {},
  containerProps = {},
  headingProps = {},
  isEmpty = false,
  isError = false,
  emptyView,
  errorView,
  withoutBackgroundImg,
  hideHeading,
  breadcrumb,
}: React.PropsWithChildren<PageLayoutProps>) => {
  const getViewToRender = () => {
    if (isError) {
      return errorView;
    } else if (isLoading) {
      return (
        <Box display="grid" placeItems="center" width="100%" height="100%">
          <Spinner accessibilityLabel="Loading" margin="auto" />
        </Box>
      );
    } else if (isEmpty) {
      return emptyView;
    } else {
      return children;
    }
  };

  return (
    <Box width="100%" height="100vh" display="flex" flexDirection="column" {...boxProps}>
      {breadcrumb ? <Box>{breadcrumb} </Box> : null}
      {!withoutBackgroundImg && (
        <>
          <LeftImg height="300px" src={leftImg} />
          <RightImg src={rightImg} />
        </>
      )}
      {!hideHeading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          padding={['spacing.5', 'spacing.8']}
          backgroundColor="surface.background.gray.moderate"
          zIndex="1"
          {...headingProps}>
          <Box display="flex" gap="spacing.8" alignItems="center">
            <Link variant="button" icon={ArrowLeftIcon} onClick={onBackClick} size="large" />
            <Heading size="medium">{title}</Heading>
          </Box>
          {RightSideLayout}
        </Box>
      )}
      <Box height="1px" width="100%" backgroundColor={containerProps.backgroundColor} />
      <Box flex="1" height="100%" {...containerProps}>
        {getViewToRender()}
      </Box>
    </Box>
  );
};

export default PageLayout;
