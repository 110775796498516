import { Button, Spinner } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import KYC from 'constants/kyc';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useKycValidations } from './hooks/useKycValidations';
import { useIsSelfServeP3M1Enabled } from './hooks/useIsSelfServeP3M1Enabled';

interface DocumentUploadCtasProps {
  kycStatus?: number;
  isVerifying: boolean;
}

const DocumentUploadCtas = ({
  kycStatus = KYC.STATUS_NOT_INITIATED,
  isVerifying,
}: DocumentUploadCtasProps) => {
  const history = useHistory();
  const { isSelfServeP3M1Enabled, isLoading: isFetchingSelfServeP3M1Experiment } = useIsSelfServeP3M1Enabled();
  
  const {
    isVerifyAvailable,
    isBankAccountDetailsAdded,
    isMethodPennyTesting,
    isErrorInBankDetails,
    isErrorInKycDocuments,
    isKycDocumentNotAdded,
  } = useKycValidations({
    kycStatus,
    isSelfServeP3M1Enabled
  });

  if (isFetchingSelfServeP3M1Experiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  if (kycStatus === KYC.STATUS_NOT_INITIATED) {
    if (isKycDocumentNotAdded) {
      return (
        <Button
          type="submit"
          isDisabled={isErrorInKycDocuments || !isVerifyAvailable}
          isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else if (!isBankAccountDetailsAdded) {
      return (
        <Button
          onClick={() =>
            history.push(
              isMethodPennyTesting ? routePaths.kyc.bankDetails : routePaths.kyc.bankDetailsManual,
            )
          }>
          Next
        </Button>
      );
    }
  } else if (kycStatus === KYC.STATUS_PENDING) {
    return null;
  } else if (kycStatus === KYC.STATUS_ON_HOLD) {
    if (isErrorInKycDocuments || isKycDocumentNotAdded) {
      return (
        <Button
          type="submit"
          isDisabled={isErrorInKycDocuments || !isVerifyAvailable}
          isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else if (isErrorInBankDetails) {
      return (
        <Button
          type="submit"
          isLoading={isVerifying}
          onClick={() =>
            history.push(
              isMethodPennyTesting ? routePaths.kyc.bankDetails : routePaths.kyc.bankDetailsManual,
            )
          }>
          Next
        </Button>
      );
    } else if (isVerifyAvailable) {
      return (
        <Button
          type="submit"
          isDisabled={isErrorInKycDocuments || !isVerifyAvailable}
          isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else {
      return null;
    }
  }

  return (
    <Button type="submit" isDisabled={!isVerifyAvailable} isLoading={isVerifying}>
      Verify
    </Button>
  );
};

export default DocumentUploadCtas;
