import React from 'react';
import { PayrollActionSummary } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/PayrollActionSummary';
import { ProfessionalTaxActionSummary } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/ProfessionalTaxActionSummary';
import { complianceActionsMap } from 'components/RunPayroll/ComplianceActions/data';
import { useComplianceActionsContext } from '../contexts/ComplianceActionsContext';

export const SummaryComponent = () => {
  const complianceActionsContext = useComplianceActionsContext();

  const selectedActionName = complianceActionsContext.selectedComplianceAction.actionName;

  if (!selectedActionName) {
    return null;
  }

  if (selectedActionName === complianceActionsMap.runPayroll) {
    return <PayrollActionSummary />;
  }

  if (selectedActionName === complianceActionsMap.payPt) {
    return <ProfessionalTaxActionSummary state="Karnataka" />;
  }

  return null;
};
