import { DeclarationWindowType, GenericDeclaration } from "components/ui/DeclarationWindow/types";

export type NpsSettings = {
  isNpsEnabled: boolean;
  employeeCanDeclareNpsLimit: boolean;
  NpsDeclarationWindow: GenericDeclaration;
};

export interface GetOrgNpsSettingsResponse {
  isNpsEnabled: boolean;
  employeeCanDeclareNpsLimit: boolean;
  declarationWindow: GenericDeclaration;
}

export interface UpdateOrgNpsSettingsPayload {
  isNpsEnabled: boolean;
  employeeCanDeclareNpsLimit: boolean;
  declarationWindow: {
    type: DeclarationWindowType;
    dates?: {
      startDate: string | null;
      endDate: string | null;
    }[];
  };
}

export interface GetEmployeeNpsDeclarationResponse {
  isNpsEnabled: boolean,
  employeeCanDeclareNpsLimit: boolean,
  npsEmployeeDeclarationPercentage: number | null,
  pran: string | null,
  salary: {
    basic: number | null,
    da: number | null
  },
  isDeclarationWindowOpen: boolean,
  npsEmployerContributionLimitPercentage: number | null
}

export interface DeclarationValidations {
  npsDeclaration: {
    state: 'none' | 'error',
    message: string | null
  },
  pran: {
    state: 'none' | 'error',
    message: string | null
  }
}

export const initDeclarationValidations = (): DeclarationValidations => {
  return {
    npsDeclaration: {
      state: 'none',
      message: ''
    },
    pran: {
      state: 'none',
      message: ''
    }
  }
}

export const initDeclarationData = (): GetEmployeeNpsDeclarationResponse => {
  return {
    employeeCanDeclareNpsLimit: false,
    isNpsEnabled: false,
    npsEmployeeDeclarationPercentage: null,
    pran: null,
    salary: {
      basic: null,
      da: null
    },
    isDeclarationWindowOpen: false,
    npsEmployerContributionLimitPercentage: null
  }
}