import React from 'react';
import styles from './index.module.scss';
import { getStaticMediaUrl } from 'utils/Urls';
import whatsappImage from 'assets/whatsapp/whatsapp-intro.svg';
import { Card } from 'components/ui/Card';
import {
  LinkButtonV2,
  StandardSecondaryButton,
  StandardButton,
} from '../../../../ui/Button';
import { useModal } from '../../../../ui/Modals';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../../../api';
import { WHATSAPP_STATUS_QUERY } from '../../queries';
import ErrorMessage from 'components/ui/ErrorMessage';
import { AppError } from 'utils/AppError';
import { TickList, TickListItem } from 'components/ui/TickList';

function WhatsAppUninstall() {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();

  const deactivationMutation = useMutation(() => api.whatsappSettings.deactivate(), {
    onSuccess: (data) => {
      closeModal();
      queryClient.invalidateQueries(WHATSAPP_STATUS_QUERY);
    },
    onError: (error: typeof AppError) => {},
  });

  const handleDeactivation = () => {
    deactivationMutation.mutate();
  };

  const handleDeactivationConfirmation = () => {
    openModal(
      <div className="py-12 max-w-5xl">
        <h3 className="pl-12 pb-12 border-b-2 border-gray-700 text-4xl font-bold text-white-o-80">
          Disable WhatsApp for employees?
        </h3>
        <div className="my-8 px-12 text-3xl font-bold text-white-o-70">
          We recommend not disabling WhatsApp for your employees
        </div>
        <div className="my-8 px-12 text-2xl font-bold text-white-o-50">
          Note: For employees who have already connected WhatsApp, their integration will not be
          disabled.
        </div>
        <div className="flex justify-end">
          <StandardSecondaryButton
            className="mr-6"
            onClick={closeModal}
            disabled={deactivationMutation.status === 'loading'}>
            Close
          </StandardSecondaryButton>
          <StandardButton
            onClick={handleDeactivation}
            isPending={deactivationMutation.status === 'loading'}>
            Disable
          </StandardButton>
        </div>
      </div>,
    );
  };

  if (deactivationMutation.status === 'error') {
    return (
      <ErrorMessage title="Sorry! Something went wrong!">{deactivationMutation.error}</ErrorMessage>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(whatsappImage)} />
      <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
        WhatsApp integration is enabled for your employees
      </div>
      <div className="text-white-o-70 text-center max-w-2xl">
        Employees can enable WhatsApp integration by logging in to their RazorpayX Payroll account
      </div>
      <div>
        <Card className={`${styles['Card']} my-12 px-12 py-12`} withTopBorder>
          <div className="font-bold text-white-o-80 mb-8">Now through WhatsApp employees can:</div>
          <TickList>
            <TickListItem>Apply for reimbursements</TickListItem>
            <TickListItem>Get monthly payslips</TickListItem>
            <TickListItem>Request and access past month payslips</TickListItem>
          </TickList>
        </Card>
      </div>

      <LinkButtonV2 onClick={handleDeactivationConfirmation}>
        Disable WhatsApp for employees
      </LinkButtonV2>
    </div>
  );
}

export default WhatsAppUninstall;
