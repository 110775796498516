import React from 'react';
import { Card } from '../../ui/Card';
import { ProgressBar } from '../../ui/ProgressBar';

export default function ({
  className,
  totalEmployees,
  totalEligibleEmployees,
  totalEmployeesGivenDetails,
  areEstimatesAvailable,
}: {
  className?: string;
  totalEmployees: number;
  totalEligibleEmployees: number;
  totalEmployeesGivenDetails: number;
  areEstimatesAvailable: boolean;
}) {
  return (
    <Card className={`${className || ''}`}>
      <div className="text-5xl font-bolder font-heading">
        Employee and add on details collection
      </div>
      <div className="text-primary mt-3 font-bolder">
        In progress. XPayroll has sent out a notification to your employees to fill their insurance
        details.
      </div>
      {areEstimatesAvailable && (
        <>
          {totalEmployees - totalEligibleEmployees > 0 ? (
            <div className="mt-4 text-gray-600">
              Note: {totalEmployees - totalEligibleEmployees} employees are not eligible.
            </div>
          ) : (
            <></>
          )}
          <div className="mt-4">
            {totalEmployeesGivenDetails} / {totalEligibleEmployees} employees have provided details
          </div>
          <ProgressBar
            className="mt-2"
            count={totalEmployeesGivenDetails}
            total={totalEligibleEmployees}
          />
        </>
      )}
    </Card>
  );
}
