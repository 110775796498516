import {
  AlertTriangleIcon,
  ArrowRightIcon,
  Box,
  Button,
  Card,
  CardBody,
  ExternalLinkIcon,
  InfoIcon,
  Link,
  Text,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import styled from 'styled-components';
import { pastPayrollExecutionHelpLink } from '../constants';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import useIsFinalizationRestrictionEnabled from '../hooks/useIsFinalizationRestrictionEnabled';

const Wrapper = styled.div`
  border-left: ${(props) => props.theme.border.width.thicker}px solid
    ${(props) => props.theme.colors.interactive.border.information.default};
  border-left-color: ${(props) => props.theme.colors.interactive.border.information.default};
  border-radius: ${(props) => props.theme.border.radius.medium}px;
`;
const ErrorWrapper = styled.div`
  border-left: ${(props) => props.theme.border.width.thicker}px solid
    ${(props) => props.theme.colors.interactive.border.notice.default};
  border-left-color: ${(props) => props.theme.colors.interactive.border.notice.default};
  border-radius: ${(props) => props.theme.border.radius.medium}px;
`;
const ErrorBackground = styled.div`
  background-color: ${(props) => props.theme.colors.feedback.background.notice.subtle};
  padding: ${(props) => props.theme.spacing[7]}px;
`;

type Props = {
  doesFinalizeNeedApproval: boolean;
  isBulkAdditionDeductionInProgress: boolean;
  onFinalizePayroll: () => void;
  finalizationErrorCount: number;
  payrollMonth: string;
  skippedEmployees?: number;
};

const FinalizationError = ({
  finalizationErrorCount,
  onFinalizePayroll,
  doesFinalizeNeedApproval,
  isBulkAdditionDeductionInProgress,
  payrollMonth,
}: Props): React.ReactElement => {
  return (
    <ErrorWrapper>
      <Card padding="spacing.0" width="100%">
        <CardBody>
          <ErrorBackground>
            <Box display="flex" alignItems="center" marginBottom="spacing.3">
              <AlertTriangleIcon color="feedback.icon.notice.intense" size="xlarge" />
              <Text marginLeft="spacing.2" size="medium" weight="medium">
                {finalizationErrorCount} employee issues
              </Text>
            </Box>
            <Text color="surface.text.gray.muted" marginTop="spacing.3">
              Resolve the issues to proceed with payroll execution. Once resolved, finalize payroll
              again
            </Text>
            <Box marginTop="spacing.6" display="flex" flexDirection="column" gap="spacing.5">
              <Button
                href={`${routePaths.customReport.root}?type=14&payrollMonth=${payrollMonth}`}
                target="_blank"
                icon={ArrowRightIcon}
                iconPosition="right">
                View issue report
              </Button>
              <Button
                type="button"
                variant="tertiary"
                onClick={onFinalizePayroll}
                isDisabled={isBulkAdditionDeductionInProgress}>
                {doesFinalizeNeedApproval ? 'Request Finalize Payroll' : 'Finalize Payroll Again'}
              </Button>
            </Box>
          </ErrorBackground>
        </CardBody>
      </Card>
    </ErrorWrapper>
  );
};

const SkippedInfo = ({
  onFinalizePayroll,
  doesFinalizeNeedApproval,
  isBulkAdditionDeductionInProgress,
  payrollMonth,
  skippedEmployees,
  finalizationErrorCount
}: Props): React.ReactElement => {
  return (
    <>
    <Wrapper>
      <Card padding="spacing.7" width="100%">
        <CardBody>
          <Box display="flex" alignItems="center" marginBottom="spacing.3">
            <InfoIcon color="interactive.icon.information.normal" size="medium" />
            <Text
              marginLeft="spacing.2"
              size="medium"
              weight="medium"
              color="interactive.text.information.normal">
              {skippedEmployees} pending payrolls
            </Text>
          </Box>
          <Text color="surface.text.gray.muted" marginTop="spacing.3">
            {skippedEmployees} pending payroll in this financial year. Execute past payroll if
            missed.{' '}
            <Link href={pastPayrollExecutionHelpLink} icon={ExternalLinkIcon} iconPosition="right">
              Learn more
            </Link>
          </Text>
          <Box marginTop="spacing.6">
            { finalizationErrorCount == 0 && (
                <Button
                  isFullWidth
                  variant="primary"
                  marginBottom="spacing.5"
                  onClick={onFinalizePayroll}
                  isDisabled={isBulkAdditionDeductionInProgress}>
                  {doesFinalizeNeedApproval ? 'Request Finalize Payroll' : 'Finalize Payroll'}
                </Button>
              )
            }
            <Button
              isFullWidth
              href={`${routePaths.customReport.root}?type=15&payrollMonth=${payrollMonth}`}
              target="_blank"
              variant="tertiary"
              icon={ExternalLinkIcon}
              iconPosition="right"
              isDisabled={isBulkAdditionDeductionInProgress}>
              View pending report
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Wrapper>
    {
      finalizationErrorCount > 0 && (
        <FinalizationError
          finalizationErrorCount={finalizationErrorCount}
          onFinalizePayroll={onFinalizePayroll}
          doesFinalizeNeedApproval={doesFinalizeNeedApproval}
          isBulkAdditionDeductionInProgress={isBulkAdditionDeductionInProgress}
          payrollMonth={payrollMonth}
        />
      )
    }
    </>
  );
};


const FinalizeCard = ({
  doesFinalizeNeedApproval,
  isBulkAdditionDeductionInProgress,
  onFinalizePayroll,
  finalizationErrorCount,
  payrollMonth
}: Props): React.ReactElement => {
  const data = useRunPayrollDataContext();
  const { isFinalizationRestrictionEnabled } = useIsFinalizationRestrictionEnabled();
  const skippedEmployees = data.skippedEmployeeCountForFY;

  const hasSkippedEmployees = skippedEmployees && isFinalizationRestrictionEnabled;
  
  if (hasSkippedEmployees) {
    return (
      <SkippedInfo
        finalizationErrorCount={finalizationErrorCount}
        onFinalizePayroll={onFinalizePayroll}
        doesFinalizeNeedApproval={doesFinalizeNeedApproval}
        isBulkAdditionDeductionInProgress={isBulkAdditionDeductionInProgress}
        payrollMonth={payrollMonth}
        skippedEmployees={skippedEmployees}
      />
    );
  }

  if (finalizationErrorCount > 0 ) {
    return (
      <FinalizationError
        finalizationErrorCount={finalizationErrorCount}
        onFinalizePayroll={onFinalizePayroll}
        doesFinalizeNeedApproval={doesFinalizeNeedApproval}
        isBulkAdditionDeductionInProgress={isBulkAdditionDeductionInProgress}
        payrollMonth={payrollMonth}
      />
    );
  }

  return (
    <Button
      type="button"
      onClick={onFinalizePayroll}
      isDisabled={isBulkAdditionDeductionInProgress}>
      {doesFinalizeNeedApproval ? 'Request Finalize Payroll' : 'Finalize Payroll'}
    </Button>
  );
};

export default FinalizeCard;
