import React, {ReactElement} from 'react';
import styles from '../index.module.scss';
import { getStaticMediaUrl } from '../../../utils/Urls';
import documentIcon from 'assets/icons/icon_document.svg';


export interface SummaryObject{
    id:string;
    title:string;
    count?:number | null;
  }

export default function FileSummaries({summaryKeyValueArray}:{summaryKeyValueArray : Array<SummaryObject>}):ReactElement {

    return (
        <>
            <div className={`${styles['summary-bar']} flex flex-row`}>
                {summaryKeyValueArray.map((summary) => (
                    <>
                        <div className={styles['summary']}>
                            <div className={styles['summary-icon']}>
                                <img className={styles['img']} src={getStaticMediaUrl(documentIcon)} />
                            </div>
                            <div className={styles['title']}>{summary.title}</div>
                            <div className={styles['count']}>{summary.count ?? '-'}</div>
                        </div>
                    </>
                ))}
            </div>
        </>
    )

}