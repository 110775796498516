import { Card } from 'components/ui/Card';
import React, { ReactElement } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import klaarIntro from 'assets/klaar/klaarIntro.svg';
import rocket from 'assets/klaar/rocket.png';
import target from 'assets/klaar/target.png';
import userStatistic from 'assets/klaar/user-statistic.png';
import { TickList, TickListItem } from 'components/ui/TickList';
import { routePaths } from 'components/Routes/data';
import { LinkButtonV2, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/ui/ExternalLink';
import { useModal } from 'components/ui/Modals';
import styles from './index.module.scss';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { StandardTable } from 'components/ui/Table';

function KlaarIntro(): ReactElement {
  const { openModal } = useModal();
  function openDetailsModal() {
    openModal(
      <ConfirmModal confirmOnly confirmText="Got it">
        <div className={`${styles['HelpModalWrapper']} pl-10 pr-5 py-10`}>
          <h3 className="text-4xl font-bold text-white-o-80">Integration details</h3>
          <p>The following fields will be synced from RazorpayX Payroll to Klaar</p>
          <StandardTable className="mt-8">
            <tbody>
              <tr>
                <td>{'RazorpayX Payroll field'}</td>
                <td>{'Klaar field mapping'}</td>
              </tr>
              <tr>
                <td>{'Employee id'}</td>
                <td>{'Employee id'}</td>
              </tr>
              <tr>
                <td>{'Email'}</td>
                <td>{'Email'}</td>
              </tr>
              <tr>
                <td>{'Name'}</td>
                <td>{'Name'}</td>
              </tr>
              <tr>
                <td>{'Department'}</td>
                <td>{'Department'}</td>
              </tr>
              <tr>
                <td>{'Title'}</td>
                <td>{'Job Title'}</td>
              </tr>
              <tr>
                <td>{'Hiring Date'}</td>
                <td>{'Hire Date'}</td>
              </tr>
              <tr>
                <td>{'Manager'}</td>
                <td>{'Manager'}</td>
              </tr>
            </tbody>
          </StandardTable>
        </div>
      </ConfirmModal>,
    );
  }
  return (
    <>
      <img src={getStaticMediaUrl(klaarIntro)} />
      <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
        Now integrate RazorpayX Payroll with <span className="text-orange font-bold">Klaar</span>
      </div>
      <p className="text-center text-1.7xl text-white-o-70">
        To set the right foundations for performance management, OKRs & employee engagement for your
        organisation
      </p>
      <Card className={`mt-12 px-10 py-10 border-orange border-t-2`}>
        <div className="font-bold text-white-o-80 mb-8 text-100">With Klaar, you can</div>
        <div className="flex justify-between">
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(rocket)}></img>
            <p className="text-1.5xl text-white-o-70">
              Unlock high performance effortlessly from employees
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(target)}></img>
            <p className="text-1.5xl text-white-o-70">
              Achieve your organization’s most ambitious goals
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(userStatistic)}></img>
            <p className="text-1.5xl text-white-o-70">
              Engage your people and retain your top talent
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center mt-16">
          <ExternalLink href="https://bit.ly/XPayrollKlaar">
            <StandardSecondaryButton className="capitalize">Book a demo</StandardSecondaryButton>
          </ExternalLink>
          {/* TODO : Update learn more when LP is ready */}
          {/* <ExternalLink>Learn More</ExternalLink> */}
        </div>
      </Card>
      <Card className={`mt-12 px-10 py-10 border-orange border-t-2`}>
        <div className="font-bold text-white-o-80 mb-8 text-100">Why integrate with RazorpayX Payroll ?</div>
        <TickList className="pl-6">
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Employee information will automatically sync from RazorpayX Payroll to Klaar. Now no
              need to manually onboard employees to Klaar again!
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Employee data will automatically sync at 1:30 AM IST everyday.
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              To know more about the employee data that will get synced {''}
            </span>
            <LinkButtonV2 onClick={openDetailsModal}>click here</LinkButtonV2>.
          </TickListItem>
        </TickList>
      </Card>
      <Link to={routePaths.settings.klaar.activation}>
        <StandardButton className="mt-12">Connect Now</StandardButton>
      </Link>
    </>
  );
}

export default KlaarIntro;
