import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { FeatureFlags } from 'types';
import { RearchFeatureNames } from './types';
import { getDisabledFeaturesForRearch } from './utils';

const useIsFeatureDisabledForRearch = (): Record<RearchFeatureNames, boolean> => {
  const features = useSelector(loggedInUserSelectors.features);

  const disabledFeaturesList = getDisabledFeaturesForRearch(
    features || ({} as Record<FeatureFlags, boolean>),
  );

  return disabledFeaturesList;
};

export default useIsFeatureDisabledForRearch;
