import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { ApplyLeaveResponse } from 'schemas/Attendance';
import { ApplyLeave } from './ApplyLeave';
import { ATTENDANCE_STATUS } from './constants';
import { isSameDay, parse } from 'date-fns';
import { getFormattedDateValue, dateFormats } from 'utils/Dates';

export const ApplyLeaveComponent = () => {
  const [isApplyingForLeave, setIsApplyingForLeave] = useState(false);
  const [leaveResponse, setLeaveResponse] = useState<ApplyLeaveResponse | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const handleLeaveSuccess = (status: number, data: ApplyLeaveResponse) => {
    setIsApplyingForLeave(false);
    setLeaveResponse(data);
    setStatus(status);
  };

  const handleCloseLeaveApplicationModal = () => {
    setIsApplyingForLeave(false);
  };

  const handleCloseLeaveSuccessModal = () => {
    setLeaveResponse(null);
    setStatus(null);
  };

  const getSuccessMessage = () => {
    let message = '';
    let warning = '';

    if (!leaveResponse) {
      return { message, warning };
    }

    if (status === ATTENDANCE_STATUS.STATUS_PRESENT) {
      message = 'Your attendance has been updated.';
    } else {
      const startDate = parse(
        leaveResponse.dates[0].date.substring(0, 10),
        'yyyy-MM-dd',
        new Date(),
      );
      const endDate = parse(
        leaveResponse.dates[leaveResponse.dates.length - 1].date.substring(0, 10),
        'yyyy-MM-dd',
        new Date(),
      );

      if (status === ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE) {
        if (leaveResponse.hasAttendanceSet) {
          warning =
            "Your attendance could not be marked for some days. It's because attendance for them has already been marked.";
        }
      } else if (leaveResponse.hasHolidaySet || leaveResponse.hasAttendanceSet) {
        warning =
          "Your leave could not be applied for some days. It's either because they are holidays or attendance for them has already been marked.";
      }

      const formattedStartDate = getFormattedDateValue({
        date: startDate,
        formatString: dateFormats.humanDateMonthYear,
      });

      if (leaveResponse.isRequest) {
        if (isSameDay(startDate, endDate)) {
          message = `Your request for ${leaveResponse.leaveDescription} on ${formattedStartDate} has been submitted.`;
        } else {
          message = `Your request for ${
            leaveResponse.leaveDescription
          } from ${formattedStartDate} to ${getFormattedDateValue({
            date: endDate,
            formatString: dateFormats.humanDateMonthYear,
          })} has been submitted.`;
        }
      } else if (leaveResponse.isProxyRequest) {
        message = 'Marked attendance successfully.';
      } else {
        if (isSameDay(startDate, endDate)) {
          message = `${leaveResponse.leaveDescription} for ${formattedStartDate} applied successfully.`;
        } else {
          message = `Applied for ${
            leaveResponse.leaveDescription
          } from ${formattedStartDate} to ${getFormattedDateValue({
            date: endDate,
            formatString: dateFormats.humanDateMonthYear,
          })} successfully.`;
        }
      }
    }
    return { message, warning };
  };

  const successMessage = getSuccessMessage();

  return (
    <Box>
      <Box>
        <Button
          onClick={() => {
            setIsApplyingForLeave(true);
          }}>
          Apply Leave
        </Button>
      </Box>
      <Modal
        isOpen={isApplyingForLeave}
        onDismiss={handleCloseLeaveApplicationModal}
        size="small"
        accessibilityLabel="Apply for leave">
        <ModalHeader title="Apply for leave" />
        <ModalBody>
          <ApplyLeave onSuccess={handleLeaveSuccess} onCancel={handleCloseLeaveApplicationModal} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={!!leaveResponse}
        onDismiss={handleCloseLeaveSuccessModal}
        size="small"
        accessibilityLabel="Leave application is successful">
        <ModalHeader title="Leave application successful" />
        <ModalBody>
          <Text>{leaveResponse && successMessage.message}</Text>
          {successMessage.warning && (
            <Alert
              color="notice"
              marginTop="spacing.4"
              description={successMessage.warning}
              isDismissible={false}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" marginTop="spacing.4" justifyContent="flex-end">
            <Button
              accessibilityLabel="Close success message"
              variant="primary"
              onClick={handleCloseLeaveSuccessModal}>
              Close
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </Box>
  );
};
