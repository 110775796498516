import React from 'react';
import { format } from 'date-fns';

import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import { Table } from 'components/ui/Table';
import { useRunPayrollFilterContext } from '../contexts/RunPayrollFiltersContext';
import { trackXPayrollEvent } from 'utils/analytics';
import { LinkButtonV2 } from 'components/ui/Button';
import useIsHoldSalaryPayComplianceEnabled from 'components/Fnf/useIsHoldSalaryPayComplianceEnabled';
import useNewJoinerArrearEnabled from '../utils/useNewJoinerArrearEnabled';

const PayrollStats = () => {
  const data = useRunPayrollDataContext();
  const { isHoldSalaryPayComplianceEnabled } = useIsHoldSalaryPayComplianceEnabled();
  const { isNewJoinerArrearFeatureEnabled } = useNewJoinerArrearEnabled();
  const payrollMonthDate = data?.payrollMonth
    ? new Date(data?.payrollMonth + 'T00:00:00')
    : new Date();
  const filters = useRunPayrollFilterContext();

  const setStatusFilter = (statusFilter: string | null) => {
    trackXPayrollEvent(`runpayroll.status_filter.${statusFilter}`);
    filters?.setStatusFilter(statusFilter);
  };

  if (!data) {
    return null;
  }

  const { numPaid, numFinalized, numSkipped, numTotal, numNetPayOnHold = 0, numGrossPayOnHold = 0 } = data;

  if (!(numPaid > 0 || numFinalized > 0 || numSkipped > 0)) {
    return null;
  }

  const colSpanCount = [isHoldSalaryPayComplianceEnabled, isNewJoinerArrearFeatureEnabled].map((enabled) => enabled ? 2 : 0).reduce((a, b) => a + b, 8);

  return (
    <>
      <Table className={`w-full`}>
        <tbody>
          <tr>
            <th colSpan={colSpanCount} className="text-center">
              Payroll Summary ({format(payrollMonthDate, 'MMMM yyyy')})
            </th>
          </tr>
          <tr>
            <td className="font-bold text-white text-center">Paid</td>
            <td>
              <LinkButtonV2
                onClick={() => {
                  numPaid > 0 && setStatusFilter('paid');
                }}>
                {numPaid}
              </LinkButtonV2>
            </td>
            <td className="text-center">Finalized</td>
            <td>
              <LinkButtonV2
                onClick={() => {
                  (numFinalized > 0) && setStatusFilter('finalized');
                }}>
                {numFinalized}
              </LinkButtonV2>
            </td>
            <td className="text-center">Skipped</td>
            <td>
              <LinkButtonV2
                onClick={() => {
                  numSkipped > 0 && setStatusFilter('skipped');
                }}>
                {numSkipped}
              </LinkButtonV2>
            </td>
            {isHoldSalaryPayComplianceEnabled && (
              <>
                <td className="text-center">Net Pay On-Hold</td>
                <td>
                  <LinkButtonV2
                    onClick={() => {
                      numNetPayOnHold > 0 && setStatusFilter('net-pay-on-hold');
                    }}>
                    {numNetPayOnHold}
                  </LinkButtonV2>
                </td>
              </>
            )}

            {isNewJoinerArrearFeatureEnabled &&
              numGrossPayOnHold > 0 && (
                <>
                  <td className="text-center">Gross Pay On-Hold</td>
                  <td>
                    <LinkButtonV2
                      onClick={() => {
                        numGrossPayOnHold > 0 && setStatusFilter('gross-pay-on-hold');
                      }}>
                      {numGrossPayOnHold}
                    </LinkButtonV2>
                  </td>
                </>
              )}

            <td className="text-center">Total</td>
            <td>
              <LinkButtonV2
                onClick={() => {
                  numTotal > 0 && setStatusFilter(null);
                }}>
                {numTotal}
              </LinkButtonV2>
            </td>
          </tr>
        </tbody>
      </Table>
      <br />
    </>
  );
};

export default PayrollStats;
