import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import * as H from 'history';

interface UnsavedWorkflowNavigationProps {
  when: boolean;
}

const UnsavedWorkflowNavigation = ({ when }: UnsavedWorkflowNavigationProps) => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<H.Location>();
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);

  useEffect(() => {
    if (isNavigationConfirmed && nextLocation) {
      history.push(nextLocation?.pathname);
      resetStates();
    }
  }, [history, isNavigationConfirmed, nextLocation]);

  const resetStates = () => {
    setIsOpen(false);
    setNextLocation(undefined);
    setIsNavigationConfirmed(false);
  };

  const handleNavigationChange = (nextLocation: H.Location) => {
    if (isNavigationConfirmed) {
      return true;
    }

    if (nextLocation.pathname !== pathname) {
      setIsOpen(true);
      setNextLocation(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigation = () => {
    if (nextLocation) {
      setIsNavigationConfirmed(true);
    }
  };

  const handleRejectNavigation = () => {
    setIsOpen(false);
    setNextLocation(undefined);
  };

  return (
    <>
      <Prompt when={when} message={handleNavigationChange} />
      <Modal isOpen={isOpen} onDismiss={handleRejectNavigation}>
        <ModalHeader title="Leave Workflow" />
        <ModalBody>
          <Text>
            No changes will be saved once you leave this workflow. Are you sure you want leave?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleConfirmNavigation}>Yes, leave</Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  );
};

export { UnsavedWorkflowNavigation };
