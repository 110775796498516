import styled from 'styled-components';
import { getStaticMediaUrl } from 'utils/Urls';
import lockIcon from '../../../assets/icons/icon_lock_filled_grey.svg';

export const LockIcon = styled.img.attrs({
  src: getStaticMediaUrl(lockIcon),
})<{ right?: number }>`
  width: 16px;
  height: 16px;
  position: absolute;
  right: ${({ right = 10 }) => right}px;
  top: calc(50% - 8px);
  z-index: 1;
`;
