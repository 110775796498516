import { Option, Select } from 'components/ui/Form/Select';
import React from 'react';
import cx from "classnames";

interface TimeThresholdInputProps {
    id: string;
    name: string;
    value: string;
    options: Array<string>;
    onChange: (value: string) => void;
    title: string;
    description: string;
    isOnboardingComplete: boolean;
}

const TimeThresholdInput = ({
    id,
    name,
    value,
    options,
    onChange,
    title,
    description,
    isOnboardingComplete
}: TimeThresholdInputProps) => {
    return (
        <div className={cx({ 'mb-8': isOnboardingComplete, 'mb-12': !isOnboardingComplete }, 'flex')}>
            <label className="w-6/12" htmlFor={id}>
                <h2 className="mb-4 opacity-90">{title}</h2>
                <p className="text-xl opacity-75 text-left">{description}</p>
            </label>
            <div className="w-6/12 flex justify-end">
                <Select
                    id={id}
                    name={name}
                    className="w-5/12"
                    value={value}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}>
                    {options.map((option) => {
                        return <Option value={option}>{option}</Option>;
                    })}
                </Select>
                <span className="text-1.7xl opacity-50 ml-8 mt-4">Hours</span>
            </div>
        </div>
    );
};

export default TimeThresholdInput;