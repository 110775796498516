export const timeThresholdOptions = ["1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0", "5.5", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0", "10.5", "11.0", "11.5", "12.0"];

export type accountCreationStatusesType = {
    [k: string]: {
        label: string;
        values: number[];
    };
}

export const accountCreationStatuses: accountCreationStatusesType = {
    "default": {
        "label": "Integration Status",
        "values": []
    },
    "all": {
      "label": "All",
      "values": []
    },
    "not_synced": {
        "label": "Not Synced",
        "values": [4, 5],
    },
    "in_progress": {
        "label": "In Progress",
        "values": [2, 3],
    },
    "synced": {
        "label": "Synced",
        "values": [1]
    }
}

export const accountCreationStatusValuesMapping = {
    1: {"status": "synced"}, // actual status = successful
    2: {"status": "in_progress"}, // actual status = initiated
    3: {"status": "in_progress"}, // actual status = in progress
    4: {"status": "not_synced"}, // actual status = failed
    5: {"status": "not_synced"} // actual status = pending
}

export const jibbleLandingPageUrl = "https://www.jibble.io/";