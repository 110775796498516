import React from 'react';
import { Box, Radio } from '@razorpay/blade/components';

interface BoxedRadioButtonProps {
  isSelected: boolean;
  value: string;
  label: string;
}

const BoxedRadioButton = (props: BoxedRadioButtonProps) => {
  const boxProps: React.ComponentProps<typeof Box> = props.isSelected
    ? {
        borderColor: 'surface.border.primary.normal',
        backgroundColor: 'surface.background.primary.subtle',
      }
    : {
        borderColor: 'surface.border.gray.normal',
        backgroundColor: 'surface.background.gray.moderate',
      };

  return (
    <label>
      <Box
        key={props.value}
        gap="spacing.3"
        padding={['spacing.2', 'spacing.3']}
        borderWidth={'thick'}
        borderRadius={'small'}
        {...boxProps}>
        <Radio value={props.value}>{props.label}</Radio>
      </Box>
    </label>
  );
};

export default BoxedRadioButton;
