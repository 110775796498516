import {
  OTP_EXPIRY_TIME,
  OTP_LIMIT_EXCEEDED,
  OTP_LIMIT_EXCEEDED_BLOCK_TIME_IN_SECONDS,
} from 'constants/otp';
import { parseError } from './Api';
import { AppError, getAppErrorDetails } from './AppError';
import { isTest } from './getEnvironment';

export const getOtpModalProps = ({
  error,
  isAuthenticatorAppEnabled,
  email,
}: {
  error: typeof AppError | null;
  isAuthenticatorAppEnabled: boolean;
  email?: string | null;
}) => {
  const errorCode = error ? getAppErrorDetails(error).code : '';

  const isEnvLocal = isTest();
  const expiryTime = isEnvLocal ? 0 : OTP_EXPIRY_TIME;

  return {
    remainingTime:
      errorCode === OTP_LIMIT_EXCEEDED ? OTP_LIMIT_EXCEEDED_BLOCK_TIME_IN_SECONDS : expiryTime,
    is_authenticator_enabled: isAuthenticatorAppEnabled,
    error: error ? parseError(error) : '',
    email: email || '',
  };
};
