import React from 'react';

import DataDisplay from './DataDisplay';
import PayrollStats from './PayrollStats';
import RunPayrollFilters from './Filters';
import Spinner from 'components/ui/Spinner';
import FilterStats from './FilterStats';
import { useRunPayrollFilterContext } from '../contexts/RunPayrollFiltersContext';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import ErrorMessage from 'components/ui/ErrorMessage';
import { LinkButtonV2 } from 'components/ui/Button';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const Grid = ({
  payrollMonth,
  isLoading,
  isRefetching
}: {
  payrollMonth: string | null;
  isLoading: boolean;
  isRefetching: boolean;
}) => {
  const filters = useRunPayrollFilterContext();
  const data = useRunPayrollDataContext();
  const { isAdditionsDisabled } = useIsFeatureDisabledForRearch();

  const employeeOnboardingLink = '/employeeOnboarding?add=1&type=employee';
  const peopleLink = '/people';

  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);

  const feature = useSelector(loggedInUserSelectors.features)
  const isBulkSkipEnabledForOrg = !!feature?.runPayrollBulkSkip;
  
  const isBulkActionsEnabled = (isOnProPlan || isBulkSkipEnabledForOrg || isAdditionsDisabled) && !data?.isPayrollBeingFinalized;

  return (
    <>
      <RunPayrollFilters
        isLoading={isRefetching || isLoading}
        isBulkActionsEnabled={isBulkActionsEnabled}
      />
      {(!isLoading && !isRefetching) && <FilterStats />}
      {isLoading ? (
        <div className={'flex justify-center'}>
          <Spinner size={40} />
        </div>
      ) : !!filters?.hasFilters() && !data?.payrollData?.length ? (
        <ErrorMessage title="Search did not return any results. Please try again." />
      ) : data && !data?.payrollData?.length ? (
        <ErrorMessage title="">
          We do not have any payroll data for you. Perhaps you need to{' '}
          <a href={employeeOnboardingLink}>
            <LinkButtonV2>add</LinkButtonV2>
          </a>{' '}
          some employees, or{' '}
          <a href={peopleLink}>
            <LinkButtonV2>set</LinkButtonV2>
          </a>{' '}
          their salaries?
        </ErrorMessage>
      ) : (
        <div className='overflow-x-auto'>
          {!filters?.hasFilters() && payrollMonth && !isLoading && !isRefetching && <PayrollStats />}
          <DataDisplay isLoading={isRefetching} isBulkActionsEnabled={isBulkActionsEnabled} />
        </div>
      )}
    </>
  );
};

export default Grid;
