import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import permissions from 'constants/permissions';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useHasPermission from 'utils/useHasPermission';

export const useCanViewComplianceActions = () => {
  const data = useRunPayrollDataContext();
  const isAuthorizedToExecute = useHasPermission([permissions.PAYROLL_EXECUTION]);

  const features = useSelector(loggedInUserSelectors.features);
  const isPayPtOnRunPayrollEnabled = !!features?.enablePayPtOnRunPayroll;

  return isPayPtOnRunPayrollEnabled && data
    ? data.isPayrollFinalized && isAuthorizedToExecute
    : false;
};
