import { useQuery } from 'react-query';
import { queryKeys } from '../constants';
import api from 'api';
import { AppError } from 'utils/AppError';
import { CtcBreakdownData, SummaryCardData } from '../types';
import { paiseAmountToRupee } from 'utils/Numbers';
import { capitalizeAllWordsInString } from 'utils/Strings';
import { PayrollGetEmployeeCompensationDataResponse } from 'api/gql-utils/payDetails/types';
import { SalaryComponentEarningPayType } from 'api/gql-utils/payDetails/compensationTypes';

export type FetchCompensationDetailsResponse = {
  summaryCardData: SummaryCardData;
  ctcBreakdownData: CtcBreakdownData;
};

const useFetchCompensationDetails = (userId: string | null, payrollMonth: string) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [queryKeys.FETCH_COMPENSATION_DETAILS, userId, payrollMonth],
    queryFn: () =>
      api.compensationQueries.getEmployeeCompensationData({
        userId,
        payrollMonth,
      }),
    select: (
      response: PayrollGetEmployeeCompensationDataResponse,
    ): FetchCompensationDetailsResponse => {

      const payrollData = response?.payrollGetCompensationData.data?.payrolls?.[0]?.data;
      const ctcComponent = payrollData?.compensation.ctcComponent;
      const earningComponents = ctcComponent?.earningComponents;
      const statutoryComponents = ctcComponent?.statutoryComponents;

      const totalAmounts = earningComponents
        ? earningComponents.reduce(
            (acc, item) => {
              return { ...acc, [item.payType]: acc[item.payType] + item.amount };
            },
            {
              adhoc: 0,
              fixed: 0,
              variable: 0,
              recurring: 0,
            } as Record<SalaryComponentEarningPayType, number>,
          )
        : {
            adhoc: 0,
            fixed: 0,
            variable: 0,
            recurring: 0,
          };

      const totalOtherCtcValue = statutoryComponents
        ? statutoryComponents.reduce((acc, item) => acc + item.amount, 0)
        : 0;

      const percentageTotal = totalAmounts.fixed + totalAmounts.variable + totalOtherCtcValue;
      const fixedPercentage = (totalAmounts.fixed / percentageTotal) * 100;
      const variablePercentage = (totalAmounts.variable / percentageTotal) * 100;
      const otherCtcPercentage = (totalOtherCtcValue / percentageTotal) * 100;

      const summaryCardData: SummaryCardData = {
        annualCtc: paiseAmountToRupee(payrollData?.ctc) ?? 0,
        totalFixedValue: paiseAmountToRupee(totalAmounts.fixed),
        totalVariableValue: paiseAmountToRupee(totalAmounts.variable),
        totalOtherCtcValue: paiseAmountToRupee(totalOtherCtcValue),
        fixedPercentage: fixedPercentage,
        variablePercentage: variablePercentage,
        otherCtcPercentage: otherCtcPercentage,
        totalRecurringDeductionAmount: 12000,
      };

      const fixedDataArray = earningComponents
        ? earningComponents
            .filter((earning) => earning.payType === 'fixed')
            .map((earning, index) => ({
              id: index.toString(),
              componentName: earning.name,
              perYearAmount: paiseAmountToRupee(earning.amount),
              perMonthAmount: paiseAmountToRupee(earning.amount / 12),
            }))
        : [];

      const variableDataArray = earningComponents
        ? earningComponents
            .filter((earning) => earning.payType === 'variable')
            .map((earning, index) => ({
              id: index.toString(),
              componentName: earning.name,
              perYearAmount: paiseAmountToRupee(earning.amount),
            }))
        : [];

      const otherCtcDataArray = statutoryComponents
        ? statutoryComponents.map((component, index) => ({
            id: index.toString(),
            componentName: capitalizeAllWordsInString(component.type),
            perYearAmount: paiseAmountToRupee(component.amount),
            perMonthAmount: paiseAmountToRupee(component.amount / 12),
          }))
        : [];

      const ctcBreakdownData: CtcBreakdownData = {
        fixed: {
          data: fixedDataArray,
          totalMonthlyAmount: fixedDataArray.reduce((acc, item) => acc + item.perMonthAmount, 0),
          totalYearlyAmount: fixedDataArray.reduce((acc, item) => acc + item.perYearAmount, 0),
        },
        variable: {
          data: variableDataArray,
          totalYearlyAmount: variableDataArray.reduce((acc, item) => acc + item.perYearAmount, 0),
        },
        otherCtc: {
          data: otherCtcDataArray,
          totalMonthlyAmount: otherCtcDataArray.reduce((acc, item) => acc + item.perMonthAmount, 0),
          totalYearlyAmount: otherCtcDataArray.reduce((acc, item) => acc + item.perYearAmount, 0),
        },
      };

      return { summaryCardData, ctcBreakdownData };
    },
    onError: (error: AppError) => {},
  });

  return { data, isLoading, error, refetch };
};

export default useFetchCompensationDetails;
