import React from 'react';
import InputBoxWrapper from './FnfInputBoxWrapper';
import { Box, BoxProps, TextInput } from '@razorpay/blade/components';
import DatePickerBlade from 'components/ui/DatePickerBlade';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { getErrorMessageForDismissalDate } from '../utils';

type FnfDismissalInformationProps = {
  dismissalDateInput: Date;
  reason: string;
  onChangeDismissalDate: (date: Date | null) => void;
  onChangeReason: (reason: string) => void;
  payrollStatus: GetFnfResponseSchemaContract['payroll_status'] | null;
  isDismissalSyncedByHRMS: boolean;
} & BoxProps;

const FnfDismissalInformation = ({
  dismissalDateInput,
  reason,
  onChangeDismissalDate,
  onChangeReason,
  payrollStatus,
  isDismissalSyncedByHRMS,
  ...boxProps
}: FnfDismissalInformationProps) => {
  const errorMessage = payrollStatus ? getErrorMessageForDismissalDate(payrollStatus) : '';

  let helperText =
    'XPayroll will automatically calculate a pro-rated salary for the employee for this month.';
  if (isDismissalSyncedByHRMS) {
    helperText +=
      ' You cannot edit the dismissal date from XPayroll because you have an HRMS integration active.';
  }

  const isPayrollFinalized = payrollStatus?.is_finalized ?? false;
  const isPayrollBlocked = payrollStatus?.is_paid ?? false;

  return (
    <InputBoxWrapper heading="Dismissal information" {...boxProps}>
      <Box display="flex" flexDirection="column" gap="36px">
        <DatePickerBlade
          variant="inline"
          label="Last working date"
          placeholder="Select date"
          helpText={helperText}
          value={dismissalDateInput}
          dateFormat="dd MMMM yyyy"
          onChange={(date) => onChangeDismissalDate(date)}
          errorText={errorMessage}
          validationState={errorMessage ? 'error' : undefined}
          isDisabled={isDismissalSyncedByHRMS}
          isRequired={true}
        />
        {(!isPayrollFinalized || (isPayrollFinalized && isPayrollBlocked)) && (
          <TextInput
            label="Reason for dismissal"
            placeholder="Reason here"
            helpText="This will be displayed on employees fnf payslip"
            value={reason}
            onChange={(e) => onChangeReason(e.value ?? '')}
            isDisabled={isDismissalSyncedByHRMS}
          />
        )}
      </Box>
    </InputBoxWrapper>
  );
};

export default FnfDismissalInformation;
