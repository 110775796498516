import { format } from 'date-fns';
import React from 'react';
import { useIsFetching } from 'react-query';

import { InputElement } from 'components/ui/Form/Input';
import { Option, Select } from 'components/ui/Form/Select';
import { getRunPayrollMonthList } from 'utils/Dates';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';


const PayrollMonthSelector = ({
  label,
  payrollMonth,
  setPayrollMonth,
  className
}: {
  label?: string;
  payrollMonth: string | null;
  setPayrollMonth: (payrollMonth: string| null) => void;
  className?: string
}) => {

  const isFetchingData = useIsFetching(FETCH_RUNPAYROLL_DATA);
  
  const monthList = getRunPayrollMonthList(payrollMonth);

  const onSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let selectedValue = event.target.value;
    setPayrollMonth(selectedValue);
  };

  if (!payrollMonth) {
    return null;
  }

  return (
    <>
      <InputElement required label={label ? label : 'Select Payroll Month'} className={"mb-10 mt-10"}>
        <Select
          name="pm"
          onChange={onSelectionChange}
          value={payrollMonth}
          disabled={!!isFetchingData}
          className={className ? className : ''}
          aria-label='Select Payroll Month'
          >
          {monthList.map((month, index) => (
            <Option key={month.toDateString()} value={format(month, 'yyyy-MM-dd')} data-date={month}>
              {format(month, 'MMMM yyyy')}
            </Option>
          ))}
        </Select>
      </InputElement>
    </>
  );
};

export default PayrollMonthSelector;
