import React from 'react';
import styles from './index.module.css';
import {
  AllowanceForSalaryModalEmployeeOnboardingAnnualCompensationType,
  EmployeeOnboardingAnnualCompensationDataType,
  employeeOnboardingAnnualCompensationPageStateActionType,
  employeeOnboardingAnnualCompensationPageStateType,
} from '../types';
import { PrimaryButton, SecondaryButton } from 'components/ui/Button';
import { pageStateActionEnum, VIEW_UPDATE_COMPENSATION } from '..';
import { toIndianCurrencyString } from 'utils/Numbers';
import { SimpleColorTable } from 'components/ui/Table';
import infoIcon from 'assets/employeeOnboarding/info.svg';
import { UseQueryResult } from 'react-query';
import { EmployeeOnBoardingFetchAnnualCompensationResponse } from 'api/employeeOnboarding';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import payrollIcon from 'assets/employeeOnboarding/payroll_icon.svg';
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../reducers/loggedInUser/selectors";
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

interface Props {
  pageStateDispatch: React.Dispatch<employeeOnboardingAnnualCompensationPageStateActionType>;
  employeeAnnualCompensationQuery: UseQueryResult<
    EmployeeOnBoardingFetchAnnualCompensationResponse,
    AppError
  >;
  annualCompensationData: EmployeeOnboardingAnnualCompensationDataType;
}
const AnnualCompensationDetails: React.FC<Props> = ({
  pageStateDispatch,
  employeeAnnualCompensationQuery,
  annualCompensationData,
}) => {
  function getCustomSalaryTotalCompensation() {
    let generalCustomSalartCompensation =
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel.basic ?? 0) +
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel.da ?? 0) +
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel.hra.actualHRA ?? 0) +
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel?.specialAllowance ?? 0) +
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel?.lta ?? 0) +
      (employeeAnnualCompensationQuery?.data?.data?.salaryModel?.npsEmployerContributionLimit ?? 0);

    if (employeeAnnualCompensationQuery?.data?.data?.isEligibleForEmployerESIContribution) {
      generalCustomSalartCompensation +=
        employeeAnnualCompensationQuery?.data?.data?.salaryModel?.esiContribution;
    }
    if (employeeAnnualCompensationQuery?.data?.data?.isPf) {
      generalCustomSalartCompensation +=
        employeeAnnualCompensationQuery?.data?.data?.salaryModel?.pfContribution ?? 0;
    }
    if (
      employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
        .hasOrgOptedForPartnerFlexiBenefit
    ) {
      generalCustomSalartCompensation +=
        employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
          ?.flexiPartnerAllowanceAmount;
    }

    employeeAnnualCompensationQuery?.data?.data?.salaryModel?.allowances
      .filter(
        (allowance) =>
          !(
            allowance.type == 5 &&
            employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
              .hasOrgOptedForPartnerFlexiBenefit
          ),
      )
      .forEach((allowance: AllowanceForSalaryModalEmployeeOnboardingAnnualCompensationType) => {
        generalCustomSalartCompensation += allowance.amount;
      });

    return generalCustomSalartCompensation;
  }

  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const features = currentOrganization?.features||{}


  return (
    <>
      {employeeAnnualCompensationQuery?.data?.data?.shouldShowFutureCompensation && (
        <div className={`${styles['future-compensation-container']}`}>
          <div className={`${styles['future-compensation-img']}`}>
            <img
              loading="lazy"
              style={{ margin: 'auto', marginTop: '14px' }}
              src={payrollIcon}
              alt=""
              width="20px"
            />
          </div>
          <p style={{ margin: 'auto 0', fontSize: '16px' }}>
            Revised compensation of{' '}
            <span className="font-bold">
              {toIndianCurrencyString(
                employeeAnnualCompensationQuery?.data?.data?.futureCompensationAmount ?? 0,
                true,
              )}
            </span>{' '}
            will be effective from{' '}
            <span className="font-bold">
              {new Date(
                employeeAnnualCompensationQuery?.data?.data?.futureCompensationDate ?? 0,
              ).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: '2-digit' })}
            </span>
            .
          </p>
        </div>
      )}

      {employeeAnnualCompensationQuery?.data?.data?.isCombinedSalary && (
        <div className={`${styles['future-compensation-container']}`}>
          <div className={`${styles['future-compensation-img']}`}>
            <img
              loading="lazy"
              style={{ margin: 'auto', marginTop: '14px' }}
              src={payrollIcon}
              alt=""
              width="20px"
            />
          </div>
          <p style={{ margin: 'auto 0' }}>
            For {new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short' })}{' '}
            combined salary will be considered for payroll.
          </p>
        </div>
      )}

      {annualCompensationData.validationErrors.map((error: string) => (
        <WarningAlert className="mt-4" children={error} />
      ))}
      <div className="flex flex-row mt-8 mb-1 ml-0 mr-0 justify-between gap-4 flex-wrap">
        <div>
          <h1 className="font-heading font-bold text-left mb-1" style={{ fontSize: '24px' }}>
            Compensation
          </h1>
          <p
            className={`${styles['info-text']} text-base mt-1`}
            style={{ fontSize: '17px', fontStyle: 'italic' }}>
            For {employeeAnnualCompensationQuery?.data?.data?.name ?? ''}
          </p>
        </div>

        <PrimaryButton
          style={{ fontSize: '1rem', fontWeight:600 }}
          disabled={
            annualCompensationData.pendingWorkflowRequestExists ||
            annualCompensationData.isPayrollBeingFinalized
          }
          onClick={() => {
            pageStateDispatch({
              type: pageStateActionEnum.pageView,
              data: VIEW_UPDATE_COMPENSATION,
            });
          }}>
          Revise Compensation
        </PrimaryButton>
      </div>

      <div className="flex flex-col gap-4">
        <div
          className={`${styles['compensation-details-container']} ${styles['radiant-border-top']} relative`}
          style={{ maxHeight: '12em' }}>
          <p>Annual Compensation</p>
          <h1 className="font-bold text-center" style={{ fontSize: '32px' }}>
            {toIndianCurrencyString(
              Number(employeeAnnualCompensationQuery?.data?.data?.annualSalary ?? 0) +
                Number(employeeAnnualCompensationQuery?.data?.data?.variablePay ?? 0),
              true,
            )}
          </h1>
          <p className="mt-4" style={{ fontStyle: 'italic' }}>
            💡{' '}
            {employeeAnnualCompensationQuery?.data?.data?.customSalaryStructure
              ? 'Custom '
              : 'Default '}{' '}
            Salary Structure
          </p>
          {employeeAnnualCompensationQuery?.data?.data?.effectiveFromDate && (
            <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
              {' '}
              Effective from:{' '}
              {new Date(
                employeeAnnualCompensationQuery?.data?.data?.effectiveFromDate ?? 0,
              ).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: '2-digit' })}
            </p>
          )}

          <span className={`${styles['oval1-parent']}`}>
            <span className={`${styles['oval1']}`}></span>
          </span>
          <span className={`${styles['oval2-parent']}`}>
            <span className={`${styles['oval2']}`}></span>
          </span>
          <span className={`${styles['oval3-parent']}`}>
            <span className={`${styles['oval3']}`}></span>
          </span>
        </div>
        <div className="flex gap-4 flex-wrap">
          <div className={`${styles['compensation-details-container']} pt-1 pb-1`}>
            <p>Fixed Compensation</p>
            <h1 className="font-bold text-center" style={{ fontSize: '32px' }}>
              {toIndianCurrencyString(
                employeeAnnualCompensationQuery?.data?.data?.annualSalary ?? 0,
                true,
              )}
            </h1>
          </div>
          <IsFeatureDisabledForRearch feature='isVariablePayDisabled'>
          <div className={`${styles['compensation-details-container']} pt-1 pb-1`}>
            <p>Variable Pay</p>
            <h1 className="font-bold text-center" style={{ fontSize: '32px' }}>
              {toIndianCurrencyString(
                Number(employeeAnnualCompensationQuery?.data?.data?.variablePay ?? 0),
                true,
              )}
            </h1>
          </div>
          </IsFeatureDisabledForRearch>
        </div>
      </div>
      {employeeAnnualCompensationQuery?.data?.data?.customSalaryStructure && (
        <div>
          <h1 className="font-heading font-bold text-left mb-6" style={{ fontSize: '20px' }}>
            Salary structure breakup
          </h1>
          <div className={`${styles['radiant-border-top']} mt-8 `}>
            <SimpleColorTable className="clear-both export">
              <thead>
                <tr>
                  <th style={{ width: '40%' }} className="font-bold text-2xl">
                    Salary Head
                  </th>
                  <th className="font-bold text-2xl">Yearly Amount</th>
                  <th className="font-bold text-2xl">Monthly Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.basic ?? 0
                          : 0) * 12,
                      ),
                      true,
                    )}{' '}
                  </td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.basic ?? 0
                          : 0,
                      ),
                      true,
                    )}{' '}
                  </td>
                </tr>
                <tr>
                  <td>Dearness Allowances</td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.da ?? 0
                          : 0) * 12,
                      ),
                      true,
                    )}
                  </td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.da ?? 0
                          : 0,
                      ),
                      true,
                    )}
                  </td>
                </tr>
                <tr>
                  <td>HRA</td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.hra
                              ?.actualHRA ?? 0
                          : 0) * 12,
                      ),
                      true,
                    )}{' '}
                  </td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ? employeeAnnualCompensationQuery?.data?.data?.salaryModel?.hra
                              ?.actualHRA ?? 0
                          : 0,
                      ),
                      true,
                    )}{' '}
                  </td>
                </tr>
                <tr>
                  <td>Special Allowance</td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ?.specialAllowance ?? 0) * 12,
                      ),
                      true,
                    )}
                  </td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        employeeAnnualCompensationQuery?.data?.data?.salaryModel
                          ?.specialAllowance ?? 0,
                      ),
                      true,
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Leave & Travel Allowance</td>
                  <td>
                    {toIndianCurrencyString(
                      Number(
                        (employeeAnnualCompensationQuery?.data?.data?.salaryModel?.lta ?? 0) * 12,
                      ),
                      true,
                    )}
                  </td>
                  <td>
                    {toIndianCurrencyString(
                      Number(employeeAnnualCompensationQuery?.data?.data?.salaryModel?.lta ?? 0),
                      true,
                    )}
                  </td>
                </tr>
                {(employeeAnnualCompensationQuery?.data?.data?.isNpsEnabled ||
                  employeeAnnualCompensationQuery?.data?.data?.salaryModel
                    ?.npsEmployerContributionLimit > 0) && (
                  <tr>
                    <td>Employer NPS Contribution</td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ? employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                ?.npsEmployerContributionLimit ?? 0
                            : 0) * 12,
                        ),
                        true,
                      )}{' '}
                    </td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ? employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                ?.npsEmployerContributionLimit ?? 0
                            : 0,
                        ),
                        true,
                      )}{' '}
                    </td>
                  </tr>
                )}

                {employeeAnnualCompensationQuery?.data?.data
                  ?.isEligibleForEmployerESIContribution && (
                  <tr>
                    <td>Employer ESI Contribution</td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ?.esiContribution ?? 0) * 12,
                        ),
                        true,
                      )}
                    </td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ?.esiContribution ?? 0,
                        ),
                        true,
                      )}
                    </td>
                  </tr>
                )}

                {employeeAnnualCompensationQuery?.data?.data?.isPf && (
                  <tr>
                    <td>Employer PF Contribution</td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          (employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ?.pfContribution ?? 0) * 12,
                        ),
                        true,
                      )}
                    </td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          employeeAnnualCompensationQuery?.data?.data?.salaryModel
                            ?.pfContribution ?? 0,
                        ),
                        true,
                      )}
                    </td>
                  </tr>
                )}

                {employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
                  .hasOrgOptedForPartnerFlexiBenefit && (
                  <tr>
                    <td>
                      {
                        employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
                          .flexiPartnerName
                      }{' '}
                      flexible benefits
                    </td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          (employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
                            ?.flexiPartnerAllowanceAmount ?? 0) * 12,
                        ),
                        true,
                      )}{' '}
                    </td>
                    <td>
                      {toIndianCurrencyString(
                        Number(
                          employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
                            ?.flexiPartnerAllowanceAmount ?? 0,
                        ),
                        true,
                      )}{' '}
                    </td>
                  </tr>
                )}

                {employeeAnnualCompensationQuery?.data?.data?.salaryModel?.allowances
                  .filter(
                    (allowance) =>
                      !(
                        allowance.type == 5 &&
                        employeeAnnualCompensationQuery?.data?.data?.flexiPartnerDetails
                          .hasOrgOptedForPartnerFlexiBenefit
                      ),
                  )
                  .map(
                    (
                      allowance: AllowanceForSalaryModalEmployeeOnboardingAnnualCompensationType,
                      index: number,
                    ) => (
                      <tr>
                        <td>{allowance?.name}</td>
                        <td>
                          {toIndianCurrencyString(Number((allowance?.amount ?? 0) * 12), true)}
                        </td>
                        <td>{toIndianCurrencyString(Number(allowance?.amount ?? 0), true)}</td>
                      </tr>
                    ),
                  )}
                <tr style={{ borderTop: '2px solid rgba(255, 255, 255, 0.24)' }}>
                  <td>Total</td>
                  <td>
                    {toIndianCurrencyString(
                      Number((getCustomSalaryTotalCompensation() ?? 0) * 12),
                      true,
                    )}
                  </td>
                  <td>
                    {toIndianCurrencyString(Number(getCustomSalaryTotalCompensation()) ?? 0, true)}
                  </td>
                </tr>
              </tbody>
            </SimpleColorTable>
          </div>
        </div>
      )}

      <IsFeatureDisabledForRearch feature={['isVPFDisabled']}>
      <div className="flex flex-col mt-12 mb-16" style={{ gap: '32px' }}>
        {(employeeAnnualCompensationQuery?.data?.data?.salaryModel?.vpf ?? 0) <= 0 &&
        (employeeAnnualCompensationQuery?.data?.data?.salaryModel?.deductions.length ?? 0) <= 0 ? (
          <>
            <p className={`flex flex-row gap-4 p-4 pl-6 pr-6 ${styles['container-bg']}`}>
              <img loading="lazy" src={infoIcon} alt="" />
              Deductions and Voluntary PF are not added, click on revise compensation to add them.
            </p>
          </>
        ) : (
          <>
            <h1 className="font-heading font-bold text-left" style={{ fontSize: '20px' }}>
              VPF and Deductible benefits
            </h1>
            {(employeeAnnualCompensationQuery?.data?.data?.salaryModel?.vpf ?? 0) <= 0 ? (
              <p className={`flex flex-row gap-4 p-4 pl-6 pr-6 ${styles['container-bg']}`}>
                <img loading="lazy" src={infoIcon} alt="" />
                Voluntary PF (VPF) are not added for the employee.
              </p>
            ) : (
              <div>
                <h1 className="font-heading font-bold text-left mb-6" style={{ fontSize: '16px' }}>
                  Voluntary PF (VPF)
                </h1>
                <span
                  className={`${styles['radiant-border-top']} flex p-3 pl-4 pr-4 ${styles['container-bg']} justify-between radiant-border-top`}>
                  <p>Monthly contribution</p>
                  <p>
                    {toIndianCurrencyString(
                      Number(employeeAnnualCompensationQuery?.data?.data?.salaryModel?.vpf ?? 0),
                      true,
                    )}
                  </p>
                </span>
              </div>
            )}

            <div>
              {(employeeAnnualCompensationQuery?.data?.data?.salaryModel.deductions.length ?? 0) <=
              0 ? (
                <p className={`flex flex-row gap-4 p-4 pl-6 pr-6 ${styles['container-bg']}`}>
                  <img loading="lazy" src={infoIcon} alt="" />
                  Deductions are not added for the employee.
                </p>
              ) : (
                <>
                  <h1 className="font-bold mb-4" style={{ fontSize: '16pxs' }}>
                    Deductible Benefits
                  </h1>
                  <div className={`${styles['radiant-border-top']} `}>
                    <SimpleColorTable className={`clear-bothexport`}>
                      <thead>
                        <tr>
                          <th
                            style={{ verticalAlign: 'middle', width: '30%' }}
                            className="font-bold text-2xl">
                            Label
                          </th>
                          <th className="font-bold text-2xl">Monthly Amount</th>
                          <th className="font-bold text-2xl">Taxable</th>
                          <th className="font-bold text-2xl">Separate Head</th>
                          <th className="font-bold text-2xl">ProRated?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {new Array(
                          employeeAnnualCompensationQuery?.data?.data?.deductibleBenefitsDetails.countOfDeductibleBenefits,
                        )
                          .fill(0)
                          .map(
                            (item, index) =>
                              employeeAnnualCompensationQuery?.data?.data?.salaryModel?.deductions[
                                index
                              ]?.name &&
                              employeeAnnualCompensationQuery?.data?.data?.salaryModel?.deductions[
                                index
                              ]?.amount > 0 && (
                                <tr>
                                  <td className="font-bold text-2xl">
                                    {
                                      employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                        ?.deductions[index]?.name
                                    }{' '}
                                  </td>
                                  <td>
                                    {toIndianCurrencyString(
                                      Number(
                                        employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                          ?.deductions[index]?.amount ?? 0,
                                      ),
                                      true,
                                    )}{' '}
                                  </td>
                                  <td>
                                    {employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                      ?.deductions[index]?.taxable
                                      ? 'YES'
                                      : 'NO'}{' '}
                                  </td>
                                  <td>
                                    {employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                      ?.deductions[index]?.showInEarnings
                                      ? 'YES'
                                      : 'NO'}{' '}
                                  </td>
                                   <td>
                                    {employeeAnnualCompensationQuery?.data?.data?.salaryModel
                                        ?.deductions[index]?.isProRated
                                        ? 'YES'
                                        : 'NO'}{' '}
                                   </td>
                                </tr>
                              ),
                          )}
                      </tbody>
                    </SimpleColorTable>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {Object.keys(
          employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails.perquisite ?? {},
        ).length <= 0 ? (
          <p className={`flex flex-row gap-4 p-4 pl-6 pr-6 ${styles['container-bg']}`}>
            <img loading="lazy" src={infoIcon} alt="" />
            Perquisites are not added for the employee.
          </p>
        ) : (
          <div>
            <h1 className="font-heading font-bold text-left mb-3.5" style={{ fontSize: '16px' }}>
              Perquisites
            </h1>
            <p className={`${styles['info-text']} text-2xl  mb-4`}>Perks above compensation</p>
            <div className={`${styles['radiant-border-top']}`}>
              <SimpleColorTable className={`clear-both export`}>
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle' }} className="font-bold text-2xl">
                      Type
                    </th>
                    <th className="font-bold text-2xl">Total Value (annual)</th>
                    <th className="font-bold text-2xl">Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(
                    employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                      .perquisitesWithTypeAndDescription ?? {},
                  ).map((item: string, index: number) => (
                    <tr>
                      <td className="font-bold text-2xl">
                        {
                          employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                            .perquisitesWithTypeAndDescription[item]
                        }
                      </td>
                      <td>
                        {toIndianCurrencyString(
                          Number(
                            employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                              ?.perquisite
                              ? Object.keys(
                                  employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                                    ?.perquisite ?? [],
                                ).includes(item)
                                ? employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                                    ?.perquisite[Number(item)].value ?? 0
                                : 0
                              : 0,
                          ),
                          true,
                        )}
                      </td>
                      <td>
                        {employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails?.perquisite
                          ? Object.keys(
                              employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                                ?.perquisite ?? [],
                            ).includes(item)
                            ? employeeAnnualCompensationQuery?.data?.data?.perquisitesDetails
                                ?.perquisite[Number(item)].paidByEmployee
                            : 0
                          : 0}{' '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </SimpleColorTable>
            </div>
          </div>
        )}

        {employeeAnnualCompensationQuery?.data?.data.loanPerquisiteDetails &&
        employeeAnnualCompensationQuery?.data?.data.loanPerquisiteDetails.length === 0 ? (
          <p className={`flex flex-row gap-4 p-4 pl-6 pr-6 ${styles['container-bg']}`}>
            <img loading="lazy" src={infoIcon} alt="" />
            This employee does not have any loans.
          </p>
        ) : (
          <div>
            <h1 className="font-heading font-bold text-left mb-4" style={{ fontSize: '16px' }}>
              Loan Perquisites
            </h1>
            <div className={`${styles['radiant-border-top']}`}>
              <SimpleColorTable className={`clear-both export`}>
                <thead>
                  <tr>
                    <th className="font-bold text-2xl">Loan Name</th>
                    <th className="font-bold text-2xl">Total Loan Amount</th>
                    <th className="font-bold text-2xl">Total Perquisite Amount</th>
                    <th className="font-bold text-2xl">Perquisite Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {annualCompensationData.loanPerquisiteDetails.map((item) => {
                    return (
                      <tr key={item.loanName}>
                        <td className="font-bold text-2xl">{item.loanName}</td>
                        <td className="font-bold text-2xl">
                          {toIndianCurrencyString(item.loanAmount, true)}
                        </td>
                        <td className="font-bold text-2xl">
                          {toIndianCurrencyString(item.totalPerquisiteAmount ?? 0, true)}
                        </td>
                        <td className="font-bold text-2xl">{`${item.perquisiteRate ?? 0.0}%`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </SimpleColorTable>
            </div>
          </div>
        )}
      </div>
      </IsFeatureDisabledForRearch>
    </>
  );
};

export default AnnualCompensationDetails;
