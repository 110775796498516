import { splitzExperiments } from 'constants/splitz';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { isSplitzExperimentActive } from 'utils/splitz';
import useReduxSelector from 'utils/useReduxSelector';
import useSplitz from './useSplitz';

const useTwoFactorAuth = () => {
  const splitzQuery = useSplitz();

  const isExperimentActive = isSplitzExperimentActive(
    splitzQuery.data,
    splitzExperiments.xpayroll2fa,
  );

  const isPhase2ExperimentActive = isSplitzExperimentActive(
    splitzQuery.data,
    splitzExperiments.xpayroll2faPhase2,
  );

  const isPhase3ExperimentActive = isSplitzExperimentActive(
    splitzQuery.data,
    splitzExperiments.xpayroll2faPhase3,
  );

  const isAuthenticatorAppEnabled = useReduxSelector(
    loggedInUserSelectors.isAuthenticatorAppEnabled,
  );

  const email = useReduxSelector(loggedInUserSelectors.email);

  return {
    isExperimentActive,
    isPhase2ExperimentActive,
    isPhase3ExperimentActive,
    isAuthenticatorAppEnabled,
    email,
    splitzQuery,
  };
};

export default useTwoFactorAuth;
