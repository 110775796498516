import React, { useState } from 'react';
import {
  Table,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Text,
  Box,
  Heading,
  EditIcon,
  Link,
  ModalHeader,
  ModalBody,
  Modal,
  Badge,
  MapPinIcon,
  Tooltip,
} from '@razorpay/blade/components';
import { useSelector } from 'react-redux';
import { EditAttendance } from './EditAttendance';
import { AttendanceRecord, AttendanceResponse } from 'schemas/Attendance';
import { useAttendanceData } from 'components/Attendance/hooks/useAttendanceData';
import { ATTENDANCE_STATUS } from './constants';
import { currentOrganization, leaveAttendanceConfig } from 'reducers/loggedInUser/selectors';
import { isLocationError } from './utils/getLocation';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';
import { GetMeApiSchemaContract } from 'schemas/GetMeApiSchema';

type TextColorProp = React.ComponentProps<typeof Text>['color'];

export interface TransformedAttendanceRecord extends Omit<AttendanceRecord, 'id'> {
  id: string;
  actualId: number | null;
}

export interface TransformedAttendanceResponse extends Omit<AttendanceResponse, 'attendance'> {
  attendance: TransformedAttendanceRecord[];
}

const timeToMinutes = (time: string | null): number => {
  if (!time) return 0;
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export function transformAttendanceRecords(
  data: AttendanceResponse,
): TransformedAttendanceRecord[] {
  // the backend does not have IDs for when there is no attendance marked
  // hence we are using the date as the ID in the table
  const attendanceRecords = data.map((record) => ({
    ...record,
    id: `${record.date.date}-${record.status ? record.status : ''}`,
    actualId: record.id,
  }));

  return attendanceRecords;
}

const getLocationLink = (location: string) => {
  return `/location?location=${location}`;
};

const AttendanceCurrentStatusBadge = ({ record }: { record: TransformedAttendanceRecord }) => {
  if (record.status === null) {
    return <>-</>;
  }

  const description = record.statusDescription || 'Unknown';

  switch (record.status) {
    case ATTENDANCE_STATUS.STATUS_PRESENT:
      return <Badge color="positive">{description}</Badge>;
    case ATTENDANCE_STATUS.STATUS_LEAVE:
      return (
        <Tooltip content={description}>
          <Badge color="negative">Leave</Badge>
        </Tooltip>
      );
    case ATTENDANCE_STATUS.STATUS_HALF_DAY:
      return (
        <Tooltip content={description}>
          <Badge color="negative">Half day leave</Badge>
        </Tooltip>
      );
    case ATTENDANCE_STATUS.STATUS_HOLIDAY_WEEKEND:
    case ATTENDANCE_STATUS.STATUS_HOLIDAY:
      return <Badge>{description}</Badge>;
    case ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE:
    case ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY:
      return <Badge color="negative">{description}</Badge>;
    case ATTENDANCE_STATUS.STATUS_DELETION_REQUESTED:
      return <Badge color="notice">{description}</Badge>;
    default:
      return <Badge color="neutral">{description}</Badge>;
  }
};

export const getCodeColor = ({
  record,
  type,
  leaveAttendanceConfiguration,
  isShiftTimingEnabled,
}: {
  leaveAttendanceConfiguration: NonNullable<
    GetMeApiSchemaContract['currentOrganization']
  >['leaveAttendanceConfig'];
  record: TransformedAttendanceRecord;
  type: 'checkIn' | 'checkOut' | 'duration';
  isShiftTimingEnabled: boolean;
}): TextColorProp => {
  if (!leaveAttendanceConfiguration || !isShiftTimingEnabled) {
    return 'feedback.text.neutral.intense';
  }

  const checkIn = record.checkIn ? timeToMinutes(record.checkIn) : null;
  const checkOut = record.checkOut ? timeToMinutes(record.checkOut) : null;
  const duration = timeToMinutes(record.duration);
  const shiftStart = timeToMinutes(leaveAttendanceConfiguration.shiftStart);
  const shiftEnd = timeToMinutes(leaveAttendanceConfiguration.shiftEnd);
  let requiredShift = 0;

  if (record.status === ATTENDANCE_STATUS.STATUS_PRESENT) {
    requiredShift = timeToMinutes(leaveAttendanceConfiguration.minimumShiftDurationFullDay);
  } else if (record.status === ATTENDANCE_STATUS.STATUS_HALF_DAY) {
    requiredShift = timeToMinutes(leaveAttendanceConfiguration.minimumShiftDurationHalfDay);
  }

  if (
    (type === 'checkIn' && checkIn !== null && checkIn > shiftStart) ||
    (type === 'checkOut' && checkOut !== null && checkOut < shiftEnd) ||
    (type === 'duration' && duration < requiredShift)
  ) {
    return 'feedback.text.negative.intense';
  }

  return 'feedback.text.neutral.intense';
};

export const AttendanceHistory: React.FC = () => {
  const [editRecord, setEditRecord] = useState<TransformedAttendanceRecord | null>(null);
  const leaveAttendanceConfiguration = useSelector(leaveAttendanceConfig);
  const organization = useSelector(currentOrganization);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const {
    data: attendanceData,
    isLoading,
    isRefetching,
    error,
    userId,
  } = useAttendanceData({
    select: transformAttendanceRecords,
  });

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  if (error) {
    // The error is already shown in the calendar
    return <></>;
  }

  return (
    <Box>
      <Box>
        <Heading as="h2">Attendance</Heading>
        <Text marginTop="spacing.2" marginBottom="spacing.4">
          To update your attendance data, please click on the edit button next to each date.
        </Text>
      </Box>
      <Table
        data={{ nodes: attendanceData || [] }}
        isFirstColumnSticky
        isHeaderSticky
        isLoading={isLoading}
        isRefreshing={isRefetching}>
        {(tableData) => (
          <>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>Date</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Check In</TableHeaderCell>
                <TableHeaderCell>Check Out</TableHeaderCell>
                <TableHeaderCell>Duration</TableHeaderCell>
                <TableHeaderCell>Remarks</TableHeaderCell>
                <TableHeaderCell>Edit</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {tableData.map((record: TransformedAttendanceRecord) => (
                <TableRow key={record.id} item={record}>
                  <TableCell>
                    <Text
                      color={
                        record.status === ATTENDANCE_STATUS.STATUS_HOLIDAY_WEEKEND ||
                        record.status === ATTENDANCE_STATUS.STATUS_HOLIDAY
                          ? 'feedback.text.neutral.subtle'
                          : 'feedback.text.neutral.intense'
                      }>
                      {getFormattedDateValue({
                        date: record.date.date,
                        formatString: dateFormats.dateMonthFullYearWithSlash,
                      })}
                    </Text>
                  </TableCell>
                  <TableCell>
                    {record.requestedStatus !== null ? (
                      <Badge color="notice">Open Request</Badge>
                    ) : (
                      <AttendanceCurrentStatusBadge record={record} />
                    )}
                  </TableCell>
                  <TableCell>
                    {record.requestedStatus === null ? (
                      record.locationCheckin && !isLocationError(record.locationCheckin) ? (
                        <Link
                          icon={MapPinIcon}
                          href={getLocationLink(record.locationCheckin)}
                          color={
                            getCodeColor({
                              record,
                              type: 'checkIn',
                              leaveAttendanceConfiguration,
                              isShiftTimingEnabled: organization?.isShiftTimingEnabled || false,
                            })?.includes('negative')
                              ? 'negative'
                              : 'primary'
                          }
                          target="_blank"
                          rel="noreferrer noopener">
                          {record.checkIn?.substring(0, 5)}
                        </Link>
                      ) : (
                        <Text
                          size="medium"
                          color={getCodeColor({
                            record,
                            type: 'checkIn',
                            leaveAttendanceConfiguration,
                            isShiftTimingEnabled: organization?.isShiftTimingEnabled || false,
                          })}>
                          {record.checkIn?.substring(0, 5)}
                        </Text>
                      )
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {record.requestedStatus === null ? (
                      record.locationCheckOut && !isLocationError(record.locationCheckOut) ? (
                        <Link
                          icon={MapPinIcon}
                          href={getLocationLink(record.locationCheckOut)}
                          color={
                            getCodeColor({
                              record,
                              type: 'checkOut',
                              leaveAttendanceConfiguration,
                              isShiftTimingEnabled: organization?.isShiftTimingEnabled || false,
                            })?.includes('negative')
                              ? 'negative'
                              : 'primary'
                          }
                          target="_blank"
                          rel="noreferrer noopener">
                          {record.checkOut?.substring(0, 5)}
                        </Link>
                      ) : (
                        <Text
                          size="medium"
                          color={getCodeColor({
                            record,
                            type: 'checkOut',
                            leaveAttendanceConfiguration,
                            isShiftTimingEnabled: organization?.isShiftTimingEnabled || false,
                          })}>
                          {record.checkOut?.substring(0, 5)}
                        </Text>
                      )
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {record.requestedStatus === null
                      ? record.duration && (
                          <Text
                            size="medium"
                            color={getCodeColor({
                              record,
                              type: 'duration',
                              leaveAttendanceConfiguration,
                              isShiftTimingEnabled: organization?.isShiftTimingEnabled || false,
                            })}>
                            {record.duration.substring(0, 5)}
                          </Text>
                        )
                      : null}
                  </TableCell>
                  <TableCell>
                    {record.requestedStatus === null ? <Text>{record.remarks}</Text> : null}
                  </TableCell>
                  <TableCell>
                    <Link
                      accessibilityLabel={`Edit Attendance for ${getFormattedDateValue({
                        date: record.date.date,
                        formatString: dateFormats.dateMonthCommaYear,
                      })}`}
                      variant="button"
                      icon={EditIcon}
                      size="medium"
                      onClick={() => {
                        setIsEditOpen(true);
                        setEditRecord(record);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </Table>
      <Modal isOpen={isEditOpen} onDismiss={() => setIsEditOpen(false)} size="small">
        <ModalHeader
          title="Edit Attendance"
          subtitle={
            editRecord
              ? `for ${getFormattedDateValue({
                  date: editRecord.date.date,
                  formatString: dateFormats.humanDateMonthYear,
                })}`
              : ''
          }
        />
        <ModalBody>
          {editRecord && (
            <EditAttendance record={editRecord} onClose={handleCloseEdit} userId={userId} />
          )}
        </ModalBody>
      </Modal>
    </Box>
  );
};
