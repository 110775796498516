import {
  Amount,
  ArrowRightIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  ProgressBar,
  Text,
  Heading,
} from '@razorpay/blade/components';
import api from 'api';
import { GetNetpayOnHoldListRequestsData } from 'api/fnf';
import { Table } from 'components/ApprovalWorkflows/MyRequests/components/Table';
import { Pagination } from 'components/ApprovalWorkflows/MyRequests/components/Table/Pagination';
import { Column } from 'components/ApprovalWorkflows/MyRequests/components/Table/types';
import { routePaths } from 'components/Routes/data';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';
import PageLayout from 'components/ui/Layout/PageLayout';
import { useFilters } from 'hooks/useFilters';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { GET_NET_PAY_LIST_DATA } from '../queries';
import { DetailsView } from './DetailsView/DetailsView';
import Filters from './Filters';
import NoResultsView from './NoResultsVIew';
import { NetpayOnHoldListFiltersMap } from './constants';
import { NetpayOnHoldListFilterTypes, ReleaseNetpayListItem } from './types';
import useOrgBalance from './useOrgBalance';
import useReleaseNetpayEventHandlers from './useReleaseNetpayEventHandlers';
import { useReleaseNetpayDetailsView } from './useReleseNetpayDetailsView';
import { transformNetPayListings } from './utils';
import { usePagination } from 'hooks/usePagination';

const columns: Column<ReleaseNetpayListItem>[] = [
  {
    title: 'Employee name',
    render: (item) => {
      return (
        (<Box display="flex" flexDirection="column">
          <Text color="surface.text.gray.muted">{item.employee_name}</Text>
          <Text variant="caption" color="surface.text.gray.muted" size='small'>
            {item.employee_id}
          </Text>
        </Box>)
      );
    },
  },
  {
    title: 'Last working day',
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Text>{item.last_working_date}</Text>
        </Box>
      );
    },
  },
  {
    title: 'Department',
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Text>{item.department}</Text>
        </Box>
      );
    },
  },
  {
    title: 'Location',
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Text>{item.locationName}</Text>
        </Box>
      );
    },
  },
  {
    title: 'Netpay Amount',
    render: (item) => {
      return (
        <Box display="flex" flexDirection="column">
          <Amount value={item.net_pay_amount || 0} />
        </Box>
      );
    },
  },
];

const ReleaseNetpay = () => {
  const [tableData, setTableData] = useState<ReleaseNetpayListItem[]>([]);
  const locations = useSelector(loggedInUserSelectors.locations);
  const history = useHistory();
  const { currentPage, itemsPerPage, setCurrentPage, setItemsPerPage } = usePagination({
    page: 1,
    limit: 10,
  });

  const orgBalanceQuery = useOrgBalance();

  const { openDetailsView, netpayRequestId } = useReleaseNetpayDetailsView();
  const { onRowChecked, selectedRowsCount, selectedRows } = useReleaseNetpayEventHandlers({
    tableData,
    setTableData,
  });

  const onRowClicked = (rowData: ReleaseNetpayListItem) => {
    const id = rowData.people_id ?? 0;

    openDetailsView(id);
  };

  const { currentFilters, urlParams } = useFilters<NetpayOnHoldListFilterTypes>({
    filtersMap: NetpayOnHoldListFiltersMap,
    multipleValueFilters: [
      NetpayOnHoldListFiltersMap.departments,
      NetpayOnHoldListFiltersMap.locations,
    ],
  });

  const queryParams: GetNetpayOnHoldListRequestsData = {
    ...currentFilters,
  };

  const { data, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: [GET_NET_PAY_LIST_DATA, queryParams],
    queryFn: () => {
      const params = urlParams;
      if (!params.has(NetpayOnHoldListFiltersMap.limit)) {
        params.set(NetpayOnHoldListFiltersMap.limit, itemsPerPage.toString());
      }
      return api.fnf.getNetpayOnHoldList(params.toString());
    },
    onSuccess: ({ data }) => {
      const tableData = transformNetPayListings(data ?? [], locations);
      setTableData(tableData);
    },
  });

  const isAnyRowSelected = selectedRowsCount > 0;

  const totalAmount = useMemo(
    () => selectedRows.reduce((prev, rowData) => prev + (rowData.net_pay_amount || 0), 0),
    [selectedRows],
  );
  const isInSufficientBalance = totalAmount > orgBalanceQuery.balance;
  const requiredBalance = totalAmount - orgBalanceQuery.balance;

  const selectedRowActions = (
    <Box display="flex" gap={'50px'} alignItems="center">
      <Box>
        <Box display="flex" alignItems="center" gap={'spacing.2'}>
          <Text color="surface.text.gray.subtle">Total net pay amount:</Text>
          <Amount value={totalAmount} />
        </Box>
        {isInSufficientBalance && (
          <Text color="feedback.text.notice.intense" variant="caption">
            Insufficient balance. Load ₹{requiredBalance} to release net pay
          </Text>
        )}
      </Box>
      <Button
        isDisabled={isInSufficientBalance}
        icon={ArrowRightIcon}
        iconPosition="right"
        size="small">
        Release Net Pay
      </Button>
    </Box>
  );

  const tableTopContent = tableData.length ? (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="68px"
      padding={['spacing.5', 'spacing.8']}
      borderBottomColor="surface.border.gray.normal"
      backgroundColor={
        selectedRowsCount > 0 ? 'surface.background.gray.intense' : 'transparent'
      }
      borderBottomWidth="thin">
      <Box display="flex" gap="spacing.5">
        {/* <Checkbox
          onChange={({ isChecked }) => {
            setTableData((data) =>
              data.map((rowData) => ({
                ...rowData,
                isChecked: rowData.allowAction ? isChecked : false,
              })),
            );
          }}
          isDisabled={tableData.reduce((prev, rowData) => prev && !rowData.allowAction, true)}>
          <></>
        </Checkbox> */}
        {isAnyRowSelected ? (
          <Text weight="semibold" color="surface.text.gray.muted">
            Selected {selectedRowsCount} of {data?.total} employees
          </Text>
        ) : (
          <Text weight="semibold" color="surface.text.gray.muted">
            Showing {data?.from}-{data?.to} of {data?.total} netpay pending releases
          </Text>
        )}
      </Box>
      {isAnyRowSelected && selectedRowActions}
    </Box>
  ) : null;

  const hoverTray = (rowData: ReleaseNetpayListItem) => {
    return (
      <Box display="flex" gap="spacing.6">
        <Button
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            // Redirect to manage fnf page
            history.push({
              pathname: routePaths.fnf.root,
              search: `userId=${rowData.people_id}`,
              state: {
                from: {
                  pathname: routePaths.fnf.releaseNetPay,
                },
              },
            });
          }}>
          Manage FNF
        </Button>
      </Box>
    );
  };

  return (
    (<PageLayout
      hideHeading
      withoutBackgroundImg
      boxProps={{
        width: 'initial',
        height: '100%',
        overflow: 'hidden',
      }}
      containerProps={{
        height: '100%',
      }}>
      <Box>
        <Box padding={'30px'}>
          <Heading size="xlarge">
            💸 Release Net Pay
          </Heading>
          <Text color="surface.text.gray.disabled">
            Release full and final settlement of employee who have resigned
          </Text>
        </Box>
        <Box paddingTop="10px" paddingBottom="spacing.7" paddingX="30px">
          <Filters balance={orgBalanceQuery.balance} />
        </Box>
        <Divider />
        <Box>
          {tableTopContent}
          {isFetching && <ProgressBar isIndeterminate />}
          <Box>
            <Table
              columns={columns}
              data={tableData.map((rowData) => ({
                ...rowData,
                isClicked: rowData.people_id === Number(netpayRequestId),
              }))}
              // onRowChecked={onRowChecked}
              onRowClick={onRowClicked}
              hoverTray={hoverTray}
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
              noResultsView={
                <Box paddingTop="spacing.9">
                  <NoResultsView
                    actions={
                      <Button onClick={() => refetch()} isLoading={isFetching}>
                        Refresh
                      </Button>
                    }
                  />
                </Box>
              }
              errorResultsView={
                <Box paddingTop="spacing.9">
                  <ErrorWithRetry onRetry={() => refetch()} />
                </Box>
              }
            />
          </Box>
          {tableData.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalItems={data?.total ?? 0}
              itemsPerPage={itemsPerPage}
              onCurrentPageChange={setCurrentPage}
              onItemsPerPageChange={setItemsPerPage}
              isDisabled={isLoading}
            />
          )}
        </Box>
      </Box>
      <DetailsView balance={orgBalanceQuery.balance} />
    </PageLayout>)
  );
};

export default ReleaseNetpay;
