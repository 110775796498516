import React from 'react';
import useActivationStatusQuery from './hooks/useActivationStatusQuery';
import Spinner from 'components/ui/Spinner';
import ErrorMessage from 'components/ui/ErrorMessage';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import Card from './components/Card';

interface WithZohoDataProps {
  zohoData: ZohoSettingsContract;
}

function withActivationQueryWrapper<P>(
  WrappedComponent: React.ComponentType<P & WithZohoDataProps>,
): React.FC<P & WithZohoDataProps> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'ActivationQueryWrapper';

  const ComponentWithActivationQueryWrapper = function (props: P) {
    const activationQuery = useActivationStatusQuery();

    if (activationQuery.status === 'loading' || activationQuery.status === 'idle') {
      return (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      );
    }

    if (activationQuery.status === 'error') {
      return (
        <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
          <ErrorMessage title="Sorry! An error has occured!">
            {activationQuery.error?.message || 'Something went wrong, please contact support'}
          </ErrorMessage>
        </Card>
      );
    }

    return <WrappedComponent {...(props as P)} zohoData={activationQuery.data} />;
  };

  ComponentWithActivationQueryWrapper.displayName = `withActivationQueryWrapper(${displayName})`;

  return ComponentWithActivationQueryWrapper;
}

export default withActivationQueryWrapper;
