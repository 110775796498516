import { routePaths } from 'components/Routes/data';
import React, { useEffect, useMemo } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { MainContent, OnboardingLayout, Sidebar } from 'components/ui/OnboardingLayout';
import { Box } from '@razorpay/blade/components';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import permissions from 'constants/permissions';
import { companyDetailsScreensData } from './constants';
import { BasicDetailsForm } from './BasicDetailsForm';
import { PanPrefillForm } from './PanPrefillForm';
import { useOnboardingContext } from '../OnboardingContext';
import { DelightScreen } from '../DelightScreen';
import { useL1KycData } from '../hooks/useL1KycData';
import { ProgressPillState } from 'components/Kyc/types';
import SidebarSection from 'components/Kyc/components/SidebarSection';
import { needHelpLinks } from 'components/Kyc/constants';

const CompanyDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  const sideBarTitle = companyDetailsScreensData[pathname]?.sidebar?.title;
  const sideBarDescription = companyDetailsScreensData[pathname]?.sidebar?.description;
  const mainContentTitle = companyDetailsScreensData[pathname]?.main?.title;
  const mainContentDescription = companyDetailsScreensData[pathname]?.main?.description;

  const { delightScreen } = useOnboardingContext();
  const { isVisible, content, onFinish } = delightScreen;

  const { isBasicDetailsComplete, isPanPrefillComplete } = useL1KycData();

  // this useEffect is for redirecting the user to right screen under company details section.
  useEffect(() => {
    if (!isBasicDetailsComplete) {
      history.replace(routePaths.onboarding.companyDetails.basicDetails);
      return;
    }

    if (!isPanPrefillComplete) {
      history.replace(routePaths.onboarding.companyDetails.panPrefill);
      return;
    }

    // If both basic-details and pan-prefill have been completed, redirecting the user to dashboard
    window.location.href = '/dashboard';
  }, [isBasicDetailsComplete, isPanPrefillComplete, history]);

  const progress = useMemo<Array<ProgressPillState>>(() => {
    if (isBasicDetailsComplete && !isPanPrefillComplete) {
      return ['COMPLETED', 'IN_PROGRESS'];
    }

    if (!isBasicDetailsComplete && isPanPrefillComplete) {
      return ['IN_PROGRESS', 'COMPLETED'];
    }

    if (isBasicDetailsComplete && isPanPrefillComplete) {
      return ['COMPLETED', 'COMPLETED'];
    }

    return ['IN_PROGRESS', 'INCOMPLETE'];
  }, [isBasicDetailsComplete, isPanPrefillComplete]);

  const hideBackButton = !isBasicDetailsComplete;

  return (
    <OnboardingLayout>
      <Sidebar
        title={sideBarTitle}
        description={sideBarDescription}
        progress={progress}
        hideBackButton={hideBackButton}
        onBackClick={() => {
          window.location.href = '/dashboard';
        }}
        footnoteText="☁️ Your progress till now is saved">
        <Switch>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.onboarding.companyDetails.panPrefill}
            exact>
            <Box display="flex" flexDirection="column" gap="spacing.10">
              <SidebarSection title="Need Help?" links={needHelpLinks} />
            </Box>
          </RestrictedRoute>
        </Switch>
      </Sidebar>
      <MainContent title={mainContentTitle} description={mainContentDescription}>
        <Switch>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.onboarding.companyDetails.basicDetails}
            exact>
            <BasicDetailsForm />
          </RestrictedRoute>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.onboarding.companyDetails.panPrefill}
            exact>
            <PanPrefillForm />
          </RestrictedRoute>
        </Switch>
      </MainContent>
      {isVisible ? <DelightScreen onFinish={onFinish}>{content}</DelightScreen> : <></>}
    </OnboardingLayout>
  );
};

export { CompanyDetails };
