import React, { createContext } from 'react';
import { FormContextValue } from './types';
import { useFormState } from './useFormState';

export const FormContext = createContext<FormContextValue<unknown> | null>(null);

export function FormProvider<T>({ children }: React.PropsWithChildren<T>) {
  const contextValue = useFormState<T>();

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}
