import React from 'react';
import { OnboardingLayout, MainContent, Sidebar } from 'components/ui/OnboardingLayout';
import { Box, Text, Heading } from '@razorpay/blade/components';
import useRxIntegration from './hooks/useRxIntegration';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const Activated = () => {
  const handleReturnToDashboard = () => {
    window.location.href = '/dashboard';
  };

  const { rxIntegrationQuery } = useRxIntegration();
  const loggedInOrgName = useSelector(loggedInUserSelectors.currentOrganization)?.name;

  if (rxIntegrationQuery.isLoading || rxIntegrationQuery.data === undefined) {
    return <></>;
  }

  if (!rxIntegrationQuery.data.merchant_details) {
    return <Redirect to={routePaths.settings.rxIntegration.preCheck} />;
  }

  return (
    (<OnboardingLayout>
      <Sidebar
        title="RazorpayX Account Integration"
        description="Integrate your RazorpayX account with XPayroll"
        onBackClick={handleReturnToDashboard}
        progress={['COMPLETED', 'COMPLETED', 'COMPLETED']}></Sidebar>
      <MainContent title="" description="">
        <Box display="flex" flexDirection="column" gap="spacing.10" width="376px">
          <Box display="flex" flexDirection="column" gap="spacing.9">
            <Box display="flex" flexDirection="column" gap="spacing.3">
              <Heading size="xlarge">✅ Activated</Heading>
              <Text>
                All payroll transactions for {loggedInOrgName} will now happen from your account
                ending in{' '}
                {rxIntegrationQuery.data.merchant_details.selected_account.account_number_ends_with}
              </Text>
            </Box>
          </Box>
        </Box>
      </MainContent>
    </OnboardingLayout>)
  );
};

export default Activated;
