import documentIcon from 'assets/icons/icon_document.svg';
import { PreviewTableProps } from 'components/BulkUploads/components/PreviewTable';
import fnfInitiateUploadConfig from 'components/BulkUploads/configs/fnfInitiateUploadConfig';
import fnfSettlementUploadConfig from 'components/BulkUploads/configs/fnfSettlementUploadConfig';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { FeatureFlags } from 'types';
import { getStaticMediaUrl } from 'utils/Urls';

export enum BulkUploadTypes {
  TYPE_BULK_ADDITION_DEDUCTION_LOP = 1,
  TYPE_BULK_SALARY_REVISION = 2,
  TYPE_BULK_FNF_INITIATION = 3,
  TYPE_BULK_FNF_SETTLEMENT = 4,
}
export const arrayGenericBulkUploadTypes = [
  BulkUploadTypes.TYPE_BULK_FNF_INITIATION,
  BulkUploadTypes.TYPE_BULK_FNF_SETTLEMENT,
];

export enum BulkUploadStatus {
  STATUS_VALIDATION_PENDING = 1,
  STATUS_VALIDATION_PROGRESS = 2,
  STATUS_VALIDATION_FAILED = 3,
  STATUS_VALIDATION_SUCCESS = 4,
  STATUS_EXECUTION_PENDING = 5,
  STATUS_EXECUTION_PROGRESS = 6,
  STATUS_EXECUTION_FAILED = 7,
  STATUS_EXECUTION_SUCCESS = 8,
  STATUS_EXECUTION_PARTIAL_SUCCESS = 9,
  STATUS_CANCELED = 10,
}

export const BulkUploadsValidationPollStatuses = [
  BulkUploadStatus.STATUS_VALIDATION_PENDING,
  BulkUploadStatus.STATUS_VALIDATION_PROGRESS,
];

export const BulkUploadsExecutionPollStatuses = [
  BulkUploadStatus.STATUS_EXECUTION_PENDING,
  BulkUploadStatus.STATUS_EXECUTION_PROGRESS,
];

export enum BulkUploadStep {
  DOWNLOAD_AND_UPLOAD_STEP = 1,
  PREVIEW_AND_EXECUTION = 2,
}

export const routeToTypeMap: { [key: string]: number } = {
  [routePaths.bulkUploads.bulkAdditionDeductionLOP.root]:
    BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP,
  [routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload]:
    BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP,
  [routePaths.bulkUploads.bulkSalaryRevision.root]: BulkUploadTypes.TYPE_BULK_SALARY_REVISION,
  [routePaths.bulkUploads.bulkSalaryRevision.fileUpload]: BulkUploadTypes.TYPE_BULK_SALARY_REVISION,
};

export const stepToRouteMap: { [key: number]: { [key: number]: string } } = {
  [BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP]: {
    [BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP]:
      routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload,
    [BulkUploadStep.PREVIEW_AND_EXECUTION]:
      routePaths.bulkUploads.bulkAdditionDeductionLOP.previewScreen,
  },
  [BulkUploadTypes.TYPE_BULK_SALARY_REVISION]: {
    [BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP]: routePaths.bulkUploads.bulkSalaryRevision.fileUpload,
    [BulkUploadStep.PREVIEW_AND_EXECUTION]: routePaths.bulkUploads.bulkSalaryRevision.previewScreen,
  },
};

export const routeToStepMap: { [key: number]: { [key: string]: number } } = {
  [BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP]: {
    [routePaths.bulkUploads.bulkAdditionDeductionLOP.root]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload]:
      BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.bulkAdditionDeductionLOP.previewScreen]:
      BulkUploadStep.PREVIEW_AND_EXECUTION,
  },
  [BulkUploadTypes.TYPE_BULK_SALARY_REVISION]: {
    [routePaths.bulkUploads.bulkSalaryRevision.root]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.bulkSalaryRevision.fileUpload]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.bulkSalaryRevision.previewScreen]: BulkUploadStep.PREVIEW_AND_EXECUTION,
  },
  [BulkUploadTypes.TYPE_BULK_FNF_INITIATION]: {
    [routePaths.bulkUploads.root]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.initiate]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.preview]: BulkUploadStep.PREVIEW_AND_EXECUTION,
  },
  [BulkUploadTypes.TYPE_BULK_FNF_SETTLEMENT]: {
    [routePaths.bulkUploads.root]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.initiate]: BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP,
    [routePaths.bulkUploads.preview]: BulkUploadStep.PREVIEW_AND_EXECUTION,
  },
};

export type UploadTypeConfig = {
  permissions?: string[];
  initiation: {
    feature?: FeatureFlags;
    permissions?: string[];
    pageTitle: string;
    step1Title: string;
    instructions: string[];
  };
  preview: {
    feature?: FeatureFlags;
    permissions?: string[];
    renderConfirmationText?: (details: BulkUploadDetailsSchemaContract[0]) => React.ReactNode;
    onSuccessModalCtaClick?: () => void;
    successModalCtaText?: string;
    successModalMainText?: string;
    successModalSubText?: string;
    onDownloadErrorReport?: (history?: ReturnType<typeof useHistory>) => void;
  } & Partial<Pick<PreviewTableProps, 'tableTopView' | 'columns'>>;
};

type UploadTypeConfigMap = {
  [key: number]: UploadTypeConfig;
};

export const PreviewMetric = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="border border-solid border-surfaceBorderGraySubtle bg-surfaceBackgroundGrayModerate px-[24px] py-[16px] rounded flex items-center gap-11">
      <div className="flex gap-3 items-center">
        <div className="border border-solid border-surfaceBorderGraySubtle rounded-full w-12 h-12 grid place-items-center">
          <img className="w-[12px] h-[12px]" src={getStaticMediaUrl(documentIcon)} alt={title} />
        </div>
        <span className="text-white text-[0.875rem]">{title}</span>
      </div>
      <span className="text-white-o-70 text-[1.25rem]">{value}</span>
    </div>
  );
};

export const uploadTypeConfigMap: UploadTypeConfigMap = {
  [BulkUploadTypes.TYPE_BULK_FNF_INITIATION]: fnfInitiateUploadConfig,
  [BulkUploadTypes.TYPE_BULK_FNF_SETTLEMENT]: fnfSettlementUploadConfig,
};
