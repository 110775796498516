import {
  Amount,
  BillIcon,
  Box,
  CashIcon,
  PettyCashBudgetIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterCell,
  TableFooterRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
} from '@razorpay/blade/components';
import { EmptyListView } from 'components/ui/EmptyListView/EmptyListView';
import React from 'react';
import { CtcBreakdownData } from '../types';
import ComponentContainer from './generics/ComponentContainer';
import ComponentHeader from './generics/ComponentHeader';
import ComponentSection from './generics/ComponentSection';
import ComponentSectionItem from './generics/ComponentSectionItem';
import { usePayDetails } from '../context/PayDetailsContext';

const CtcComponent = () => {
  const { compensationData } = usePayDetails();
  const ctcData: CtcBreakdownData = compensationData.ctcBreakdownData;

  return (
    <ComponentContainer>
      <ComponentSection>
        <ComponentHeader
          icon={<CashIcon size="medium" color="surface.icon.onSea.onSubtle" />}
          iconBackground={'surface.background.sea.subtle'}
          title={'Fixed'}
          description={'An earning component is any part of a salary that adds to your income'}
        />
        <ComponentSectionItem>
          <Box borderRadius={'large'} overflow={'hidden'}>
            <Table
              data={{
                nodes: ctcData.fixed.data,
              }}
              backgroundColor="transparent">
              {(data) => (
                <>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        <Text weight="semibold">Component</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Per Month</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Per Year</Text>
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} item={row}>
                        <TableCell>
                          <Text>{row.componentName}</Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          <Amount value={row.perMonthAmount} />
                        </TableCell>
                        <TableCell textAlign="right">
                          <Amount value={row.perYearAmount} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {data.length > 0 && (
                    <TableFooter>
                      <TableFooterRow>
                        <TableFooterCell>
                          <Text weight="semibold">Total</Text>
                        </TableFooterCell>
                        <TableFooterCell textAlign="right">
                          <Amount value={ctcData.fixed.totalMonthlyAmount} />
                        </TableFooterCell>
                        <TableFooterCell textAlign="right">
                          <Amount value={ctcData.fixed.totalYearlyAmount} />
                        </TableFooterCell>
                      </TableFooterRow>
                    </TableFooter>
                  )}
                </>
              )}
            </Table>
            {ctcData.fixed.data.length === 0 && (
              <EmptyListView
                title="No fixed components available"
                backgroundColor={'transparent'}
              />
            )}
          </Box>
        </ComponentSectionItem>
      </ComponentSection>
      <ComponentSection>
        <ComponentHeader
          icon={<PettyCashBudgetIcon size="medium" color="surface.icon.primary.normal" />}
          iconBackground={'surface.background.primary.subtle'}
          title={'Variable'}
          description={
            'Component which is based on performance, its not part of your monthly payment'
          }
        />
        <ComponentSectionItem>
          <Box borderRadius={'large'} overflow={'hidden'}>
            <Table
              data={{
                nodes: ctcData.variable.data,
              }}
              backgroundColor="transparent">
              {(data) => (
                <>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        <Text weight="semibold">Component</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Per Year</Text>
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} item={row}>
                        <TableCell>
                          <Text>{row.componentName}</Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          <Text>{row.perYearAmount}</Text>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {data.length > 0 && (
                    <TableFooter>
                      <TableFooterRow>
                        <TableFooterCell>
                          <Text weight="semibold">Total</Text>
                        </TableFooterCell>
                        <TableFooterCell textAlign="right">
                          <Amount value={ctcData.variable.totalYearlyAmount} />
                        </TableFooterCell>
                      </TableFooterRow>
                    </TableFooter>
                  )}
                </>
              )}
            </Table>
            {ctcData.variable.data.length === 0 && (
              <EmptyListView
                title="No variable components available"
                backgroundColor={'transparent'}
              />
            )}
          </Box>
        </ComponentSectionItem>
      </ComponentSection>

      <ComponentSection>
        <ComponentHeader
          icon={<BillIcon size="medium" color="surface.icon.gray.normal" />}
          iconBackground={'surface.background.gray.intense'}
          title={'Other CTC component'}
          description={'Component part of CTC but does not include fixed and variable'}
        />
        <ComponentSectionItem>
          <Box borderRadius={'large'} overflow={'hidden'}>
            <Table
              data={{
                nodes: ctcData.otherCtc.data,
              }}
              backgroundColor="transparent">
              {(data) => (
                <>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        <Text weight="semibold">Component</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Per Month</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Per Year</Text>
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} item={row}>
                        <TableCell>
                          <Text>{row.componentName}</Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          <Amount value={row.perMonthAmount} />
                        </TableCell>
                        <TableCell textAlign="right">
                          <Amount value={row.perYearAmount} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {data.length > 0 && (
                    <TableFooter>
                      <TableFooterRow>
                        <TableFooterCell>
                          <Text weight="semibold">Total</Text>
                        </TableFooterCell>
                        <TableFooterCell textAlign="right">
                          <Amount value={ctcData.otherCtc.totalMonthlyAmount} />
                        </TableFooterCell>
                        <TableFooterCell textAlign="right">
                          <Amount value={ctcData.otherCtc.totalYearlyAmount} />
                        </TableFooterCell>
                      </TableFooterRow>
                    </TableFooter>
                  )}
                </>
              )}
            </Table>
            {ctcData.otherCtc.data.length === 0 && (
              <EmptyListView
                title="No other ctc components available"
                backgroundColor={'transparent'}
              />
            )}
          </Box>
        </ComponentSectionItem>
      </ComponentSection>
    </ComponentContainer>
  );
};

export default CtcComponent;
