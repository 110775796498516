
export const enum PAYROLL_CYCLE {
    CALENDAR_CYCLE = 'CALENDAR_CYCLE',
    CUSTOM_CYCLE = 'CUSTOM_CYCLE',
}

export const enum CYCLE_TYPE {
    PAYROLL = 'payroll',
    ATTENDANCE = 'attendance',
}

export const PayrollCycleTypeMap: { [key: string]: PAYROLL_CYCLE } = {
    [PAYROLL_CYCLE.CALENDAR_CYCLE]: PAYROLL_CYCLE.CALENDAR_CYCLE,
    [PAYROLL_CYCLE.CUSTOM_CYCLE]: PAYROLL_CYCLE.CUSTOM_CYCLE
};

export interface CycleProps {
       from: number;
        to: number;
}