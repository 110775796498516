import React, { ReactElement, useState } from 'react';
import styles from './../index.module.scss';
import { PrimaryButton } from 'components/ui/Button';
import { useHistory } from 'react-router-dom';
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { UpdateOrgFlexiPartnerDetailsSchemaContract } from 'schemas/UpdateOrgFlexiPartnerDetailsSchema';
import { KYC_STATUS } from 'constants/flexibenefits';
import { ActionItems } from 'constants/actionItems';
import { routePaths } from 'components/Routes/data';
import api from 'api';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from '../../utils/queries';
import { AppError } from 'components/AppError';
import { getOrgFlexiDetails } from '../../utils/init';
import { getActionItemsCount } from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/utils/helpers';
import { ActionItemsCard } from './ActionItemsCard';
import { WarningAlert } from 'components/ui/Alert';

export interface ActionItemsCardDataType {
    id: number;
    title?: ReactElement | string;
    content?: contentArray ;
    buttonLabel?: ReactElement | string;
    redirectURL: any;
  }

export interface contentArray {
    default? : string;
    inProgress? : string;
    onHold? : string;
    rejected? : string;
    success? : string;
}

function getContent(id : ActionItems | undefined, content : contentArray | any, status? : KYC_STATUS){
    switch(id){
        case ActionItems.KYC_VERIFICATION: {
            if(status === KYC_STATUS.STATUS_NOT_INITIATED){
                return content.default;
            }

            else if(status === KYC_STATUS.STATUS_PENDING){
                return content.inProgress;
            }

            else if(status === KYC_STATUS.STATUS_ON_HOLD){
                return content.onHold;
            }

            else{
                return content.default;
            }
        }

        case ActionItems.ENABLE_FOR_ALL_EMPLOYEES: {
            return content.default;
        }

        default:{
            return 'Unable to get data';
        }
    }
}


export const ActionItemsCardList = ({ actionItemsCardList,flexiDetails } : {
    actionItemsCardList: ActionItemsCardDataType[];
    flexiDetails: GetOrgFlexiPartnerDetailsSchemaContract;
}) => {

    const history = useHistory();
    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const [flexiData, setFlexiData] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails())
    const [updateError, setUpdateError] = useState<string>("");


    const { isLoading, isRefetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      }, {
        onSuccess: (data) => data && setFlexiData(data),
        onError: (error: typeof AppError) => {
            console.log("Error on fetching Org's flexi details data : ", error);
        }
      });

    const queryClient = useQueryClient();
    const updateOrgFlexiDetails = useMutation(() => {
        let requestData: UpdateOrgFlexiPartnerDetailsSchemaContract = {
            enabledWallets: flexiData.enabledWallets,
            totalAmountAllocated: flexiData.totalAmountAllocated,
            deliveryType: flexiData.deliveryType,
            cardDeliveryDetails: flexiData.cardDeliveryDetails,
            isOpted: flexiData.isOpted,
            enableForAllEmployees: true
        }


        if(requestData && orgId) {
            return api.flexiPartnerDetails.updateOrgFlexiPartnerDetails(orgId, requestData);
        } else {
            throw "Something went wrong in updating this information."
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(FETCH_ORG_FLEXI_PARTNER_DETAILS).then(() => {
                flexiData.enableForAllEmployees = true;
                if(getActionItemsCount(flexiData) > 0){
                    history.push(routePaths.partnerFlexiBenefits.admin.actionItems);
                }
                else{
                    history.push(routePaths.partnerFlexiBenefits.admin.connectedStatus);
                }
                
            });
        },
        onError: (error) => {
            setUpdateError('There was an issue with performing this action: '.concat((error as Error).message));
        }
    });
    
    function handleClick(id : ActionItems | undefined){
            
        switch(id){
            case ActionItems.KYC_VERIFICATION: {
                history.push(routePaths.partnerFlexiBenefits.admin.kycVerification);
                break;                
            }
            
    
            case ActionItems.ENABLE_FOR_ALL_EMPLOYEES: {
                updateOrgFlexiDetails.mutate();
                break;
            }
            
            default:{
                return 'Unable to get data';
            }
            
        }
    }
    

    function getButton(actionItemsCard? : ActionItemsCardDataType, flexiDetails? : GetOrgFlexiPartnerDetailsSchemaContract){

        switch(actionItemsCard?.id){
            case ActionItems.KYC_VERIFICATION: {
                if(KYC_STATUS.STATUS_VERIFIED === flexiDetails?.kycData.status 
                    || KYC_STATUS.STATUS_PENDING === flexiDetails?.kycData.status){
                        return (<></>);
                    }
                else{
                    return (
                        <PrimaryButton size='sm' className={`${styles['action-item-card-right-block-button']}` }
                            onClick={() => handleClick(actionItemsCard?.id) } 
                            >
                                {((flexiDetails?.kycData.status && flexiDetails?.kycData.status === KYC_STATUS.STATUS_ON_HOLD) ? 'RE-UPLOAD PROOFS' : actionItemsCard?.buttonLabel)}
                        </PrimaryButton>
                    );
                }
            }
            case ActionItems.ENABLE_FOR_ALL_EMPLOYEES: {
                return (
                    <PrimaryButton size='sm' className={`${styles['action-item-card-right-block-button']}` }
                        onClick={() => handleClick(actionItemsCard?.id)} 
                        isPending={updateOrgFlexiDetails.isLoading || isRefetching}
                        isCompleted = {updateOrgFlexiDetails.isSuccess }
                        >
                            { updateOrgFlexiDetails.isSuccess ? '' : actionItemsCard.buttonLabel }
                    </PrimaryButton>
                )
            }
            default: {
                return (
                    <PrimaryButton size='sm' className={`${styles['action-item-card-right-block-button']}`}
                        disabled={true}
                        >
                        {'Data not found'}
                    </PrimaryButton>);
            }
        }
    }

    return (        
        <div className="py-5 grid grid-cols-1 gap-y-10 mb-12">
            { updateError && updateError !== "" && <WarningAlert>{updateError}</WarningAlert>}
            {
                actionItemsCardList?.map((card:ActionItemsCardDataType) => {
                    return <ActionItemsCard key = {card.id} actionItemsCard={card} flexiDetails={flexiDetails} getContent={getContent} getButton={getButton}/>
                })
            }
        </div>
    )
}

export default ActionItemsCardList;