import { routePaths } from 'components/Routes/data';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { BulkUploadTypes, PreviewMetric, UploadTypeConfig } from 'constants/bulkUpload';
import permissions from 'constants/permissions';
import React from 'react';

const config: UploadTypeConfig = {
  permissions: [permissions.EMPLOYEE_DISMISSAL],
  initiation: {
    feature: 'bulk-fnf',
    permissions: [],
    pageTitle: 'Bulk Full & Final Initiation',
    step1Title: '1. Download the Full & Final Initiation sheet to be updated',
    instructions: [
      'Enter Employee Details',
      'Provide Reason for Dismissal (Optional)',
      'Specify whether to release salary or hold net-pay. If holding net-pay, specify Hold Salary Start Month to an unfinalized payroll month.',
      'The maximum number of rows you can upload is 500',
    ],
  },
  preview: {
    permissions: [],
    feature: 'bulk-fnf',
    columns: [
      {
        title: 'ID',
        render: (item) => item.file_row?.commonProperties?.employeeId,
      },
      {
        title: 'Name',
        render: (item) => item.file_row?.commonProperties?.employeeName,
      },
      {
        title: 'Email ID',
        render: (item) => item.file_row?.commonProperties?.email,
      },
      {
        title: 'Last Working Day',
        render: (item) => item.file_row?.lastWorkingDate,
      },
      {
        title: 'Reason for dismissal',
        render: (item) => item.file_row?.reasonForDismissal,
      },
      {
        title: 'Salary Payout',
        render: (item) => {
          const value = (item.file_row?.salaryPayout || '') as string;
          return (
            <Pill
              content={value}
              type={
                value === 'Release Salary' ? pillClassTypeMap.success : pillClassTypeMap.warning
              }
            />
          );
        },
      },
      {
        title: 'Hold salary start month',
        render: (item) => item.file_row?.holdSalaryStartMonth,
      },
      {
        title: 'Personal Email',
        render: (item) => item.file_row?.personalEmail,
      },
    ],
    renderConfirmationText: (data) =>
      `Almost done! Please confirm to initiate the Full & final settlement for the ${data.total_number_of_rows} employees`,
    tableTopView: (bulkUploadRecord) => (
      <div className="flex gap-10 mb-[27px] mt-2">
        <PreviewMetric
          title="Employee net-pay on hold"
          value={bulkUploadRecord?.file_summary?.numberOfNetPayOnHold as string}
        />
        <PreviewMetric
          title="Employee salary to be released"
          value={bulkUploadRecord?.file_summary?.numberOfReleaseSalary as string}
        />
      </div>
    ),
    successModalCtaText: 'Go to People',
    successModalSubText:
      'The Full and Final settlement process has been initiated for the employees mentioned in the file',
    onSuccessModalCtaClick() {
      window.location.href = '/people';
    },
    onDownloadErrorReport(history) {
      history?.push({
        pathname: routePaths.bulkUploads.initiate,
        search: `type=${BulkUploadTypes.TYPE_BULK_FNF_INITIATION}`,
      });
    },
  },
};

export default config;
