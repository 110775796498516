import { CreateWorkflowSchemaContract } from 'schemas/CreateWorkflowSchema';
import { Workflow } from './types';
import { CreateWorkflowRequestData } from 'api/workflows';

export const parseWorkflowDataFromBE = (workflow: CreateWorkflowSchemaContract) => {
  return {
    type: workflow.action_type,
    config: workflow.approvers.levels
      .sort((levelA, levelB) => levelA.level - levelB.level)
      .map((level) => ({
        approvers: level.peopleIds.map((peopleId) => ({
          id: peopleId,
          name: workflow.people_metadata?.[peopleId]?.name ?? '',
        })),
        minApprovals: level.minApprovals,
        isSaved: true,
      })),
    id: workflow.id,
  } as Workflow;
};

export const prepareWorkflowDataForBE = (workflowData: Workflow): CreateWorkflowRequestData => {
  return {
    action_type: workflowData.type,
    approvers: workflowData.config.map((levelData, index) => ({
      level: index + 1,
      people_ids: levelData.approvers.map((approver) => approver.id),
      min_approvals: levelData.minApprovals,
    })),
  };
};
