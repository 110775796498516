import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useReduxSelector from 'utils/useReduxSelector';

/**
 * Custom hook to evaluate L1 KYC statuses whether user has completed basic-details and pan-prefill
 */
export const useL1KycData = () => {
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);

  // basic signup details
  const signupTitle = useReduxSelector((state) => state.loggedInUser.data?.signupTitle);
  const brandName = org?.brandName;
  const employeeCount = org?.signupNumEmployees;

  // pan details
  const pan = org?.pan;
  const orgType = org?.type;
  const orgName = org?.name;

  const isRazorpayMerchant = !!org?.razorpayMid;
  
  // NOTE - This does NOT reflect the current razorpay activation status, but only reflects the rzo activation status at the time of SSO
  const isActivatedOnRazorpay = !!org?.onboardingConfig?.isActivatedOnRazorpay;

  const isBasicDetailsComplete = !!signupTitle && !!brandName && !!employeeCount;

  const isPanPrefillComplete = !!pan && !!orgType && !!orgName;

  return {
    isBasicDetailsComplete,
    isPanPrefillComplete,
    signupTitle,
    brandName,
    employeeCount,
    pan,
    orgName,
    orgType,
    isRazorpayMerchant,
    isActivatedOnRazorpay
  };
};
