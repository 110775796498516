import { isAfter, isBefore } from 'date-fns';
import { useState } from 'react';
import { FlexiBenefitsSettings } from './types';
import { DeclarationErrors } from 'components/ui/DeclarationWindow/types';

interface UseFBPValidationsProps {
  settings: FlexiBenefitsSettings;
}

const useFBPValidations = ({ settings }: UseFBPValidationsProps) => {
  const [isValidateCalled, setIsValidateCalled] = useState(false);

  const getMonthRangeErrors = (startDate?: number, endDate?: number) => {
    let isValid = true;
    const errors: Record<'startDate' | 'endDate', string | null> = {
      startDate: null,
      endDate: null,
    };
    if (!startDate) {
      errors.startDate = 'This is required';
      isValid = false;
    } else if (!endDate) {
      errors.endDate = 'This is required';
      isValid = false;
    } else {
      if (startDate > endDate) {
        errors.startDate = 'Start date cannot be after end date';
        isValid = false;
      } else if (endDate < startDate) {
        errors.endDate = 'End date cannot be less than start date';
        isValid = false;
      }
    }
    return { errors, isValid };
  };

  const getCustomeRangeErrors = (startDate?: Date | null, endDate?: Date | null) => {
    let isValid = true;
    const errors: Record<'startDate' | 'endDate', string | null> = {
      startDate: null,
      endDate: null,
    };
    if (!startDate) {
      errors.startDate = 'This is required';
      isValid = false;
    } else if (!endDate) {
      errors.endDate = 'This is required';
      isValid = false;
    } else {
      if (isAfter(startDate, endDate)) {
        errors.startDate = 'Start date cannot be after end date';
        isValid = false;
      } else if (isBefore(endDate, startDate)) {
        errors.endDate = 'End date cannot be less than start date';
        isValid = false;
      }
    }
    return { errors, isValid };
  };

  const isWindowValid = (
    isDeclarationWindow = false,
  ): { errors: DeclarationErrors; isValid: boolean } => {
    let isValid = true;
    const selectedWindow = isDeclarationWindow
      ? settings.employeeDeclarationWindow
      : settings.employeeProofUploadWindow;
    let errors = {};
    if (selectedWindow.type === 'monthly') {
      const { startDate, endDate } = selectedWindow;
      const validationResult = getMonthRangeErrors(startDate, endDate);
      errors = validationResult.errors;
      isValid = validationResult.isValid;
    } else if (selectedWindow.type === 'custom') {
      const entries = Object.entries(selectedWindow.dates);
      for (const entry of entries) {
        const [key, value] = entry;
        const { startDate, endDate } = value;
        const validationResult = getCustomeRangeErrors(startDate, endDate);
        errors = {
          ...errors,
          [key]: validationResult.errors,
        };
        isValid = isValid && validationResult.isValid;
      }
    } else if (selectedWindow.type === 'always') {
      errors = {};
    }
    return { errors, isValid };
  };

  const validate = () => {
    const declarationWindowValidations = isWindowValid(true);
    const proofUploadWindowValidations = isWindowValid();
    const isValid = declarationWindowValidations.isValid && proofUploadWindowValidations.isValid;
    return {
      employeeDeclarationWindow: declarationWindowValidations.errors,
      employeeProofUploadWindow: proofUploadWindowValidations.errors,
      isValid,
    };
  };

  return {
    settings,
    validate: () => {
      setIsValidateCalled(true);
      return validate();
    },
    errors: (isValidateCalled ? validate() : { isValid: true }) as {
      employeeDeclarationWindow?: DeclarationErrors;
      employeeProofUploadWindow?: DeclarationErrors;
      isValid: boolean;
    },
    resetValidations: () => setIsValidateCalled(false),
  };
};

export default useFBPValidations;
