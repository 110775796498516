import React from 'react';
import { Box, Text } from '@razorpay/blade/components';
import successTick from 'assets/bulkUploads/file--download--success--tick.svg';

interface PanNameViewProps {
  title: string;
  value: string;
}

const PanNameView = ({ title, value }: PanNameViewProps) => {
  return (
    <Box
      backgroundColor="surface.background.gray.moderate"
      padding={['spacing.5', 'spacing.4']}>
      <Box display="flex" gap="spacing.4">
        <img src={successTick} width={32} height={32} alt="" />
        <Box display="flex" flexDirection="column" gap="spacing.1">
          <Text variant="caption" size='small'>{title}</Text>
          <Text>{value}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export { PanNameView };
