import { Dispatch, SetStateAction, useMemo } from 'react';
import { RowCheckHandler, RowData } from './components/Table/types';
import { MyRequestsContextType, useMyRequestsContext } from './MyRequestsContext';

interface UseWithdrawRequests<T extends RowData> {
  tableData: T[];
  setTableData: Dispatch<SetStateAction<T[]>>;
}

const useWithdrawRequests = <T extends RowData>({
  tableData,
  setTableData,
}: UseWithdrawRequests<T>) => {
  const { openBulkWithdrawModal, openSingleWithdrawModal } =
    useMyRequestsContext() as MyRequestsContextType;

  const onRowChecked: RowCheckHandler<T> = ({ index, isChecked }) => {
    setTableData((data) =>
      data.map((rowData, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...rowData,
            isChecked,
          };
        }

        return rowData;
      }),
    );
  };

  const actionCtasDisabled = useMemo(
    () => tableData.filter((rowData) => rowData.isChecked).length === 0,
    [tableData],
  );

  const selectedRowsCount = tableData.reduce(
    (totalSoFar, row) => (row.isChecked ? totalSoFar + 1 : totalSoFar),
    0,
  );

  const handleMultiWithdrawCtaClicked = (cb?: () => void) => {
    const selectedRowIds = tableData.filter((row) => row.isChecked).map((row) => row.id);

    openBulkWithdrawModal(selectedRowIds, cb);
  };

  const handleSingleWithdrawCtaClicked = (rowData: T, cb?: () => void) => {
    openSingleWithdrawModal(rowData.id, cb);
  };

  return {
    onRowChecked,
    actionCtasDisabled,
    selectedRowsCount,
    handleMultiWithdrawCtaClicked,
    handleSingleWithdrawCtaClicked,
  };
};

export { useWithdrawRequests };
