import React from "react";
import { Box } from '@razorpay/blade/components';
import CustomWindowSettings from '../../ui/CustomWindow/CustomWindowSettings';
import { TaxDeclarationSettingsSchemaContract } from 'schemas/TaxDeclarationSettingsSchema';
import { CustomWindowType, CUSTOM_WINDOW_TYPE } from "./types";
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import { startOfDay } from "date-fns";

interface CustomWindowConfigSettingsProps {
    hasProPlan?: boolean;
    settings: TaxDeclarationSettingsSchemaContract;
    proofUploadWindow: GenericDeclaration;
}

const CustomWindowConfigSettings = (
    { hasProPlan, settings, proofUploadWindow }: CustomWindowConfigSettingsProps
) => {

    const getCustomWindowModalErrorText = (startDate: Date | null, endDate: Date | null, windowType?: CustomWindowType) => {

        let currentDate = new Date();
        currentDate = startOfDay(currentDate);
        if (startDate && endDate && (startDate < currentDate || endDate < currentDate)) {
            return 'Window cannot include past dates';
        }
        else if (settings.letXpayrollVerifyDocs && proofUploadWindow.type === 'custom') {

            const customWindow = Object.entries(proofUploadWindow.dates)[0][1];
            const xpayrollStartDate = new Date(customWindow.startDate ?? '');
            const xpayrollEndDate = new Date(customWindow.endDate ?? '');

            if (startDate && endDate && xpayrollStartDate && xpayrollEndDate &&
                windowType === CUSTOM_WINDOW_TYPE.PROOF &&
                !(startDate >= xpayrollStartDate && endDate <= xpayrollEndDate)
            ) {
                return `Below Proof upload window is outside XPayroll’s window (
                    ${xpayrollStartDate.toLocaleDateString('en-us', { day: 'numeric' })} ${xpayrollStartDate.toLocaleDateString('en-us', { month: 'short' })} 
                    - 
                    ${xpayrollEndDate.toLocaleDateString('en-us', { day: 'numeric' })} ${xpayrollEndDate.toLocaleDateString('en-us', { month: 'short' })}
                    , ${xpayrollEndDate.getFullYear()}). XPayroll will not perform the proof verification.`;
            }
        }
        else if (startDate && endDate && startDate > endDate) {
            return 'End date cannot be before start date';
        }

        return '';
    }


    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            marginX={{ base: 'spacing.8', l: 'auto' }}
            width="560px"
            paddingY="spacing.11"
        >

            <CustomWindowSettings
                heading={'Custom window'}
                noWindowText={'No custom window created'}
                description={'Custom windows opened for employees which are currently active'}
                hasProPlan={hasProPlan}
                customWindowModalCondition={getCustomWindowModalErrorText}
            />

        </Box>
    );
}

export default CustomWindowConfigSettings;