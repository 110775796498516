import React from "react";
import {InputElement} from "../../../../../ui/Form/Input";
import DatePicker from "../../../../../ui/DatePicker";
import styles from "../index.module.scss";
import {StandardButton} from "../../../../../ui/Button";
import syncIcon from "assets/sync-icon-blue.svg";
import {getStaticMediaUrl} from "../../../../../../utils/Urls";
import {SuccessAlert} from "../../../../../ui/Alert";
import {addDays} from "date-fns";

interface DateRangeFormPros {
    fromDate: Date | null;
    toDate: Date | null;
    lastFromDate: Date | null;
    lastToDate: Date | null;
    lastSyncDate: Date | null;
    isDateSelectionDisabled: boolean;
    isAttendanceSyncDisabled: boolean;
    isFormSubmitDisabled: boolean;
    onChangeFromDate: (newFromDate?: Date | null) => void;
    onChangeToDate: (newToDate?: Date | null) => void;
    onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const DateRangeForm = ({
    fromDate,
    toDate,
    lastFromDate,
    lastToDate,
    lastSyncDate,
    isDateSelectionDisabled,
    isAttendanceSyncDisabled,
    isFormSubmitDisabled,
    onChangeFromDate,
    onChangeToDate,
    onFormSubmit,
}: DateRangeFormPros) => {

    if (isAttendanceSyncDisabled && lastFromDate && lastToDate && lastSyncDate) {
        return (
            <SuccessAlert className="flex w-full items-center my-12">
                <img src={getStaticMediaUrl(syncIcon)} alt=""  className="h-6 mr-4" />
                <p className="text-1.7xl max-w-none">
                    Attendance was synced on {lastSyncDate.toLocaleString()} for date {' '} range
                    {' '} <strong>{lastFromDate.toLocaleDateString()}</strong> to {' '}
                    <strong>{lastToDate.toLocaleDateString()}</strong>
                </p>
            </SuccessAlert>
        )
    }

    return (
        <form
            onSubmit={(event) => onFormSubmit(event)}
            className={styles['dateRangeForm']}
        >
            <h1 className={styles['formHeading']}>Enter the start date & end date of attendance 👇 </h1>
            <div className="flex">
                <InputElement label="Attendance From Date" className={styles['inputElement']}>
                    <DatePicker
                        className={styles['datePicker']}
                        id={"datepicker-start-date"}
                        onChange={(newFromDate) => onChangeFromDate(newFromDate)}
                        pickerType="past"
                        value={fromDate}
                        key={fromDate?.toLocaleDateString()}
                        isDisabled={isDateSelectionDisabled}
                        extraProps={{
                            // min: new Date(2023, 4, 1),
                            max: addDays(new Date(), -1),
                        }}
                    />
                </InputElement>
                <InputElement label="Attendance To Date" className={styles['inputElement']}>
                    <DatePicker
                        id={"datepicker-end-date"}
                        className={styles['datePicker']}
                        onChange={(newToDate) => onChangeToDate(newToDate)}
                        pickerType="past"
                        value={toDate}
                        key={toDate?.toLocaleDateString()}
                        isDisabled={isDateSelectionDisabled}
                        extraProps={{
                            // min: new Date(2023, 4, 1),
                            max: addDays(new Date(), -1),
                        }}
                    />
                </InputElement>
                <StandardButton
                    type="submit"
                    className={styles['formSubmitButton']}
                    disabled={isFormSubmitDisabled}
                >
                    Sync Loss Of Pay Report
                </StandardButton>
            </div>
            {lastSyncDate && lastFromDate && lastToDate &&
                <div className="flex w-full items-center mt-12">
                    <img src={getStaticMediaUrl(syncIcon)} alt=""  className="h-6 mr-1" />
                    <p className="text-1.5xl opacity-75">
                        Attendance was last synced on {lastSyncDate.toLocaleString()} for date {' '}
                        range {lastFromDate.toLocaleDateString()} to {lastToDate.toLocaleDateString()}
                    </p>
                </div>
            }
        </form>
    )
}

export default DateRangeForm;