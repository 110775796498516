import { routePaths } from "components/Routes/data";
import { SecondaryButton } from "components/ui/Button";
import React from "react";
import { Route } from "react-router-dom";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";

interface Props {
    RenewalDetails: GetInsuranceRenewalOrganizationAndEmployeeContract
}
const RenewalBanner:React.FC<Props> = ({RenewalDetails}) =>
{
    return (
        (RenewalDetails?.employeeDetails?.total  ? 
            ( RenewalDetails?.employeeDetails?.total > 0 ?
                <div className="bg-rhino flex p-10">
                    <div className="text-9xl font-heading text-secondary my-auto">{RenewalDetails?.employeeDetails?.total}</div>
                    <div className="flex-1 px-5">
                        <div className="p-2 font-bold text-white">
                            Employee insurance are expired for your organisation
                        </div>
                        <div className="p-2 text-secondary">
                            Secure the future of your employees now. Cover then on your insurance plan
                        </div>
                    </div>
                    <div className="text-5xl my-auto">
                        <Route
                            render={({ history }) => (
                                <SecondaryButton
                                    onClick={() => {
                                        history.push(routePaths.insurance.admin.organizationAndEmployeeRenewal);
                                    }}
                                >
                                    Renew insurance
                                </SecondaryButton>
                            )}
                        />
                    </div>
                </div>
                :
                <div></div>) 
        : <div></div>
    ))
} 

export default RenewalBanner;