import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useSelector, useDispatch } from 'react-redux';
import { Input, InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import { Select, Option } from 'components/ui/Form/Select';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import { statesOfIndia } from 'utils/StatesOfIndia';
import { orgTypes } from 'utils/OrganizationTypes';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { useMutation } from 'react-query';
import KYC from 'constants/kyc';
import INFO from 'constants/companyNameInfo';
import api from 'api';
import { AppError } from 'utils/AppError';
import { InfoAlertBlade, WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import RequiredFieldsIndicator from 'components/ui/Form/RequiredFieldsIndicator';
import { CompanyDetailsUpdateRequest } from 'api/companyOnboarding';
import { trackSegment } from 'utils/segment';
import { GSTIN_REGEX, PAN_REGEX } from 'utils/regexes';
import ExternalLink from 'components/ui/ExternalLink';
import { trackGtag, trackRazorpayAnalytics } from 'utils/razorpayAnalytics';
import NonEditableField from 'components/CompanyOnboarding/components/NonEditableField';
import { AddressConfirmationPayload, AddressField, PrefillViewType } from '../types';
import StickyFooterModal from 'components/BulkUploads/components/StickyFooterModal';
import infoIcon from '../../../../assets/icons/icon_info.svg';
import { Box } from '@razorpay/blade/components';
import { getStaticMediaUrl } from 'utils/Urls';
import { trackXPayrollEvent } from 'utils/analytics';
import { LockIcon } from '../styles';

type Props = {
  onSuccess?: () => void;
  onEditClick?: (prefillView: PrefillViewType) => void;
  fieldsFromPrefill?: AddressConfirmationPayload;
};

function getPanLabel(orgType: number) {
  return orgType === 2 ? 'Personal PAN' : 'Company PAN';
}

function getNameLabel(orgType: number) {
  return orgType === 2 ? 'Proprietor Name' : 'Company Name';
}

function getNamePlaceholder(orgType: number) {
  return orgType === 2 ? "Enter the Proprietor's name" : "Enter your company's name";
}

function getCompanyNameInfo(orgType: number) {
  return orgType === 2 ? INFO.PERSONAL_PAN_INFO : INFO.COMPANY_PAN_INFO;
}

const triggerOnboardingPixels = (orgType: number) => {
  trackRazorpayAnalytics('fb', 'Payroll_Company_Details');
  trackRazorpayAnalytics('linkedin_onboarding_tax_details', { conversion_id: 7377292 });
  trackRazorpayAnalytics('twitter_onboarding_tax_details', {
    tw_sale_amount: 0,
    tw_order_quantity: 0,
  });
  trackGtag('event', 'conversion', { send_to: 'AW-928471290/3YacCLyypZsDEPqx3boD' });
  trackGtag('event', 'PRL_PAN_VERIFIED', { organization_type: orgType });
};

const CompanyAddress = ({
  onSuccess,
  onEditClick,
  fieldsFromPrefill = {} as AddressConfirmationPayload,
}: Props) => {
  const history = useHistory();
  const location = useLocation<{ previousView: PrefillViewType }>();
  const { previousView } = location.state || {};
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const isAddressSetupComplete =
    currentOrganization?.onboardingConfig?.isCompanyAdressSetupComplete || false;
  const [name, setName] = useState(
    fieldsFromPrefill?.companyName || currentOrganization?.name || '',
  );
  const [brandName, setBrandName] = useState(currentOrganization?.brandName || '');
  const [addressLineOne, setAddresLineOne] = useState(
    fieldsFromPrefill?.addressLine1 || currentOrganization?.data?.address1 || '',
  );
  const [addressLineTwo, setAddressLineTwo] = useState(
    fieldsFromPrefill?.addressLine2 || currentOrganization?.data?.address2 || '',
  );
  const [state, setState] = useState(fieldsFromPrefill?.state || currentOrganization?.state || 17);
  const [orgType, setOrgType] = useState(
    fieldsFromPrefill?.orgType || currentOrganization?.type || 1,
  );
  const [pincode, setPincode] = useState(
    fieldsFromPrefill?.pincode || currentOrganization?.pincode || '',
  );
  const dispatch = useDispatch();
  const isKycVerified = currentOrganization?.kycStatus === KYC.STATUS_VERIFIED;
  const [pan, setPan] = useState(fieldsFromPrefill?.pan || currentOrganization?.pan || '');
  const [gstin, setGSTIN] = useState(fieldsFromPrefill?.gstin || currentOrganization?.gstin || '');
  const links = useSelector(loggedInUserSelectors.referenceLinks);
  const [isConfirmViewVisible, setIsConfirmViewVisible] = useState(false);

  const getConfirmationFields = () => {
    let { isAddressConfirmedFromGstin = false, isNameConfirmedFromPan = false } =
      currentOrganization?.onboardingConfig || {};

    if (previousView === 'gstin') {
      isAddressConfirmedFromGstin = true;
      isNameConfirmedFromPan = false;
    } else if (previousView === 'pan') {
      isNameConfirmedFromPan = true;
      isAddressConfirmedFromGstin = false;
    } else {
      // No previous view
    }
    return { isAddressConfirmedFromGstin, isNameConfirmedFromPan };
  };
  const { isAddressConfirmedFromGstin, isNameConfirmedFromPan } = getConfirmationFields();

  const updateAddressMutation = useMutation(
    (payload: CompanyDetailsUpdateRequest) => api.companyOnboarding.updateCompanyDetails(payload),
    {
      onSuccess: (data) => {
        dispatch(fetchLoggedInUser());
        trackSegment('onboarding.company_details.address.continue_click');
        trackSegment('onboarding.company_details.tax_setup.save_and_next_click');
        triggerOnboardingPixels(orgType);
        history.push(routePaths.companyOnboarding.payrollDate);
        onSuccess && onSuccess();
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleConfirm = (e: FormEvent) => {
    trackXPayrollEvent('onboarding.confirmed_details', 'interaction', 'company-onboarding');
    e.preventDefault();
    if (isKycVerified) {
      onSuccess && onSuccess();
    } else {
      setIsConfirmViewVisible(true);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    trackXPayrollEvent('onboarding.confirmed_l1kyc', 'interaction', 'company-onboarding');
    e.preventDefault();
    if (isKycVerified) {
      onSuccess && onSuccess();
    } else {
      updateAddressMutation.mutate({
        companyName: name,
        brandName,
        addressLineOne,
        addressLineTwo,
        state,
        pincode,
        orgType,
        gstin,
        pan,
        isAddressConfirmedFromGstin,
        isNameConfirmedFromPan,
      });
    }
  };

  const isFormBeingSubmitted = updateAddressMutation.status === 'loading';

  const panLabel = getPanLabel(orgType);
  const nameLabel = getNameLabel(orgType);
  const companyNameInfo = getCompanyNameInfo(orgType);
  const namePlaceholder = getNamePlaceholder(orgType);

  const isFieldValueFromPrefill = (fieldName: string) => {
    return fieldsFromPrefill[fieldName as keyof AddressConfirmationPayload] || false;
  };

  const shouldShowField = (fieldName: AddressField, savedValue?: string | null) => {
    const defaultValueFields = ['state', 'orgType'];
    // If kyc is verified, no need to show the fields.
    if (isKycVerified) {
      // Show fields that have not been filled as disabled
      if (!savedValue) return true;
      return false;
    }
    const isFieldValueAvailableFromPrefill = isFieldValueFromPrefill(fieldName);
    // If previouseView is not there, or the user is directly landing on this page
    if (!previousView) {
      // If the field is state or org type
      if (defaultValueFields.includes(fieldName) && !isAddressSetupComplete) return true;
      // If the field doesn't have a saved value then show the field
      if (!savedValue) return true;
    } else {
      // Show these fields with locked icon
      if (fieldName === 'gstin' && isAddressConfirmedFromGstin) return true;
      if (fieldName === 'pan' && isNameConfirmedFromPan) return true;
      if (fieldName === 'orgType' && isNameConfirmedFromPan) return true;

      // If user is coming from eith gstin or pan prefill views
      if (isFieldValueAvailableFromPrefill) {
        // If the field value is avilable from prefill, hide the field
        return false;
      }
      // Show the field in case field value is not avialble from prefill
      return true;
    }
  };

  const fields: Array<{
    isVisible?: boolean;
    isHidden?: boolean;
    field: ReactNode;
    nonEditableField?: ReactNode;
  }> = [
    {
      isVisible: shouldShowField('brandName', currentOrganization?.brandName),
      field: (
        <InputElement label="Brand Name" className="my-auto mt-4 mb-14">
          <Input
            type="text"
            maxLength={255}
            value={brandName}
            name="brand-name"
            onChange={(event) => setBrandName(event.target.value)}
            readOnly={isKycVerified}
            disabled={isFormBeingSubmitted}
          />
          <InputInfo>
            If your company is publicly known by a different brand name, then please enter that
            here.
          </InputInfo>
        </InputElement>
      ),
      nonEditableField: (
        <NonEditableField className="mb-8" label={'Brand Name'} value={brandName} />
      ),
    },
    {
      isVisible: shouldShowField(
        'orgType',
        orgTypes.find((e) => e.code === currentOrganization?.type)?.name,
      ),
      field: (
        <InputElement required label="Organization Type" className="my-auto mt-10 mb-14">
          <Box position={'relative'}>
            <Select
              className="mb-0"
              required
              onChange={(event) => {
                setOrgType(parseInt(event.target.value));
              }}
              value={orgType}
              disabled={isFormBeingSubmitted || isKycVerified || previousView === 'pan'}>
              {orgTypes.map((orgType) => (
                <Option key={orgType.code} value={orgType.code}>
                  {orgType.name}
                </Option>
              ))}
            </Select>
            {previousView === 'pan' ? <LockIcon right={40} /> : null}
          </Box>
          {previousView === 'gstin' ? null : (
            <InputInfo>
              In case of incorrect company type, please go back to edit PAN details
            </InputInfo>
          )}
        </InputElement>
      ),
      nonEditableField: (
        <NonEditableField
          className="mb-8"
          label={'Organization Type'}
          value={orgTypes.find((e) => e.code === orgType)?.name || ''}
        />
      ),
    },
    {
      isVisible: shouldShowField('pan', currentOrganization?.pan),
      field: (
        <div className="my-auto mt-4 mb-14">
          <InputElement required label={panLabel}>
            <Box position={'relative'}>
              <Input
                type="text"
                maxLength={10}
                value={pan}
                required
                name="company-pan"
                onChange={(event) => setPan(event.target.value)}
                placeholder="Enter PAN"
                customErrorMessage="Invalid PAN"
                pattern={PAN_REGEX}
                readOnly={isKycVerified || previousView === 'pan'}
                disabled={isFormBeingSubmitted || previousView === 'pan'}
              />
              {previousView === 'pan' ? <LockIcon /> : null}
            </Box>
            <InputInfo>
              In case of incorrect company name, please go back to edit PAN details
            </InputInfo>
          </InputElement>
          {previousView === 'pan' ? (
            <NonEditableField className="mt-5" label={nameLabel} value={name} />
          ) : null}
        </div>
      ),
      nonEditableField: <NonEditableField className="mb-8" label={panLabel} value={pan} />,
    },
    {
      isVisible: shouldShowField('companyName', currentOrganization?.name),
      isHidden: previousView === 'pan',
      field: (
        <InputElement required label={nameLabel} className="my-auto mt-4 mb-14">
          <Input
            type="text"
            maxLength={255}
            value={name}
            required
            name="company-name"
            onChange={(event) => setName(event.target.value)}
            placeholder={namePlaceholder}
            readOnly={isKycVerified}
            disabled={isFormBeingSubmitted}
          />
          <InputInfo>{companyNameInfo}</InputInfo>
        </InputElement>
      ),
      nonEditableField: <NonEditableField className="mb-8" label={nameLabel} value={name} />,
    },
    {
      isVisible: shouldShowField('gstin', currentOrganization?.gstin),
      isHidden: !!isNameConfirmedFromPan,
      field: (
        <InputElement label="Company GSTIN" className="my-auto mt-4 mb-14 relative">
          <Box position={'relative'}>
            <Input
              type="text"
              maxLength={15}
              value={gstin}
              name="company-gstin"
              onChange={(event) => setGSTIN(event.target.value)}
              placeholder="Enter your company GSTIN number"
              customErrorMessage="Invalid GSTIN"
              pattern={GSTIN_REGEX}
              readOnly={isKycVerified || previousView === 'gstin'}
              disabled={isFormBeingSubmitted || previousView === 'gstin'}
            />
            {previousView === 'gstin' ? <LockIcon /> : null}
          </Box>
          <InputInfo>
            In case of incorrect company details, please go back to edit GSTIN details
          </InputInfo>
        </InputElement>
      ),
      nonEditableField: <NonEditableField className="mb-8" label={'Company GSTIN'} value={gstin} />,
    },
    {
      isVisible: shouldShowField(
        'addressLine1',
        currentOrganization?.data?.address1
          ? `${currentOrganization?.data?.address1} ${currentOrganization?.data?.address2}`
          : '',
      ),
      field: (
        <div className="my-auto mt-4 mb-14">
          <InputElement required label="Registered Address" className="mb-5">
            <Input
              type="text"
              maxLength={255}
              value={addressLineOne}
              required
              name="address-line-one"
              onChange={(event) => setAddresLineOne(event.target.value)}
              placeholder="Enter your work address"
              readOnly={isKycVerified}
              disabled={isFormBeingSubmitted}
            />
          </InputElement>

          <Input
            type="text"
            maxLength={255}
            value={addressLineTwo}
            name="address-line-two"
            onChange={(event) => setAddressLineTwo(event.target.value)}
            placeholder="Address line 2"
            readOnly={isKycVerified}
            disabled={isFormBeingSubmitted}
          />
        </div>
      ),
      nonEditableField: (
        <div className="mb-8">
          <NonEditableField
            label={'Registered Address'}
            value={addressLineOne ? `${addressLineOne} ${addressLineTwo}` : ''}
          />

          {previousView === 'gstin' ? (
            <InfoAlertBlade icon={getStaticMediaUrl(infoIcon)} className="mt-3">
              Note: We need your address as mentioned in GSTIN to help you avail E-invoices.
            </InfoAlertBlade>
          ) : null}
        </div>
      ),
    },
    {
      isVisible: shouldShowField('pincode', currentOrganization?.pincode),
      field: (
        <InputElement required label="PIN Code" className="my-auto mt-4 mb-14">
          <Input
            type="text"
            maxLength={6}
            value={pincode}
            required
            name="pincode"
            onChange={(event) => setPincode(event.target.value)}
            placeholder="Enter your PIN code"
            readOnly={isKycVerified}
            disabled={isFormBeingSubmitted}
          />
        </InputElement>
      ),
      nonEditableField: <NonEditableField className="mb-8" label={'Pincode'} value={pincode} />,
    },
    {
      isVisible: shouldShowField(
        'state',
        statesOfIndia.find((e) => e.code === currentOrganization?.state)?.name,
      ),
      field: (
        <InputElement required label="State" className="my-auto">
          <Select
            required
            className="mb-0"
            onChange={(event) => {
              setState(parseInt(event.target.value));
            }}
            value={state}
            disabled={isFormBeingSubmitted || isKycVerified}>
            {statesOfIndia.map((state) => (
              <Option key={state.code} value={state.code}>
                {state.name}
              </Option>
            ))}
          </Select>
          <InputInfo>
            Choosing the state affects Professional tax compliance, it is suggested to select the
            state where your company is based in.
          </InputInfo>
        </InputElement>
      ),
      nonEditableField: (
        <NonEditableField
          className="mb-8"
          label={'State'}
          value={statesOfIndia.find((e) => e.code === state)?.name || ''}
        />
      ),
    },
  ];

  useEffect(() => {
    if (updateAddressMutation.isError) {
      setIsConfirmViewVisible(false);
    }
  }, [updateAddressMutation.isError]);

  return (
    <form onSubmit={handleConfirm}>
      {updateAddressMutation.status === 'error' && (
        <WarningAlert>
          <Errors errorResponse={updateAddressMutation.error} />
        </WarningAlert>
      )}
      {isKycVerified && (
        <div className="max-w-4xl mx-auto mb-8">
          <WarningAlert className="mt-8">
            You cannot update your company details as your KYC is verified.
          </WarningAlert>
        </div>
      )}

      {fields
        .filter((field) => !field.isHidden)
        .sort((e1, e2) => (e1.isVisible === e2.isVisible ? 0 : e1.isVisible ? -1 : 1))
        .map((item) => {
          if (item.isVisible) return item.field;
          return item.nonEditableField;
        })}

      <RequiredFieldsIndicator className="mb-4 pt-10" />
      <div className="flex justify-between align-center">
        {!isKycVerified && (
          <SecondaryButton
            className="normal-case"
            type="button"
            onClick={() =>
              onEditClick?.(
                isAddressConfirmedFromGstin ? 'gstin' : isNameConfirmedFromPan ? 'pan' : 'gstin',
              )
            }>
            {isNameConfirmedFromPan
              ? 'Back: Edit PAN'
              : isAddressConfirmedFromGstin || !previousView
              ? 'Back: Edit GSTIN'
              : ''}
          </SecondaryButton>
        )}
        <StandardButton type="submit" isPending={isFormBeingSubmitted} className="mr-0 normal-case">
          Continue
        </StandardButton>
      </div>
      {isConfirmViewVisible && (
        <StickyFooterModal
          onCancel={() => setIsConfirmViewVisible(false)}
          onConfirmClick={(e) => handleSubmit(e)}
          showModal
          confirmText="Confirm & Proceed →"
          showAlertWithWarningText={false}
          isConfirmPending={isFormBeingSubmitted}
          warningText={
            <div>
              <div className="font-bold text-white-o-80 text-1.92xl">✅ Confirm details</div>
              <div className="text-white-o-80 text-1.5xl mt-1">
                You will be unable to edit these details once you click on confirm.
              </div>
            </div>
          }
        />
      )}
    </form>
  );
};

export default CompanyAddress;
