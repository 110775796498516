import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton } from 'components/ui/Button';
import { trackSegment } from 'utils/segment';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import { MessageTypes, SetMessageType } from '../contexts/RunPayrollMessageContext';

import styles from './index.module.scss';
import useIsSuperAdmin from 'hooks/useIsSuperAdmin';
import { RunPayrollExecuteWithOtpSchemaContract } from 'schemas/RunPayrollExecuteWithOtpSchema';
import useTwoFactorAuth from 'hooks/useTwoFactorAuth';
import OtpModal, { OtpModalProps } from 'components/Otp/OtpModal';
import { OTP_ACTION_EXECUTE_PAYROLL } from 'components/Otp/constants';
import { OTP_EXPIRY_TIME, OTP_LIMIT_EXCEEDED, OTP_VERIFICATION_ERROR } from 'constants/otp';
import { getAppErrorDetails } from 'utils/AppError';
import { useSelector } from 'react-redux';
import { selectIsSkippedCountConsentGranted } from '../contexts/runPayrollSlice';

const ExecutePayroll = ({
  payrollMonth,
  isFirstPayroll,
  onError,
  showMessage,
  onSuccess,
}: {
  payrollMonth: string;
  isFirstPayroll: boolean;
  onSuccess?: () => void;
} & SetErrorMessageType &
  SetMessageType) => {
  const { isPhase2ExperimentActive, email, isAuthenticatorAppEnabled, splitzQuery } =
    useTwoFactorAuth();

  const isSkippedCountConsentGranted = useSelector(selectIsSkippedCountConsentGranted);

  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { isSuperAdmin } = useIsSuperAdmin();
  const [isDryRun, setIsDryRun] = useState(false);
  const [shouldShowOtpModal, setShouldShowOtpModal] = useState(false);
  const [otpData, setOtpData] = useState<OtpModalProps['data']>({
    is_authenticator_enabled: isAuthenticatorAppEnabled,
    email: (email as string) || '',
    remainingTime: OTP_EXPIRY_TIME,
  });

  const execute = useMutation(
    (payrollMonth: string) => {
      return api.runPayroll.executePayroll(payrollMonth, isDryRun, isSkippedCountConsentGranted);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        showMessage && showMessage({ type: MessageTypes.EXECUTION_REQUESTED });
        closeModal();
        onSuccess && onSuccess();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const executePayrollV2WithOtp = useMutation(
    (props: RunPayrollExecuteWithOtpSchemaContract) => {
      return api.runPayroll.executePayrollV2WithOtp(
        props.payroll_month,
        isDryRun,
        props.two_factor_auth_otp,
        isSkippedCountConsentGranted,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        showMessage && showMessage({ type: MessageTypes.EXECUTION_REQUESTED });
        setShouldShowOtpModal(false);
        onError && onError(null);
        closeModal();
        onSuccess && onSuccess();
      },
      onError: (error) => {
        const errorCode = error ? getAppErrorDetails(error).code : '';
        if (errorCode !== OTP_VERIFICATION_ERROR && errorCode !== OTP_LIMIT_EXCEEDED) {
          onError && onError({ message: (error as Error).message });
        }
        setOtpData({ ...otpData, error: (error as Error).message });
      },
    },
  );

  const executePayrollV2 = useMutation(
    (payrollMonth: string) => {
      return api.runPayroll.executePayrollV2(payrollMonth, isDryRun, isSkippedCountConsentGranted);
    },
    {
      onSuccess: () => {
        onError && onError(null);
        setShouldShowOtpModal(true);
        executePayrollV2WithOtp.reset();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const onExecutePayroll = () => {
    if (isFirstPayroll) {
      trackSegment('onboarding.first_payroll_executed', { queue: true });
    }

    if (isPhase2ExperimentActive) {
      executePayrollV2.mutate(payrollMonth);
    } else {
      execute.mutate(payrollMonth);
    }
  };

  const onSubmitOtpModal = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const otp = formData.get('otp') as string;

    if (otp.length === 6) {
      executePayrollV2WithOtp.mutate({
        payroll_month: payrollMonth,
        two_factor_auth_otp: otp,
        two_factor_auth_action: OTP_ACTION_EXECUTE_PAYROLL,
      });
    }
  };

  const isRequestInProgress =
    execute.isLoading || executePayrollV2WithOtp.isLoading || executePayrollV2.isLoading;

  const handleResendClick = async (e: React.MouseEvent, resetTimerCallback: Function) => {
    await executePayrollV2.mutateAsync(payrollMonth);
    setOtpData((prev) => ({ ...prev, isResendAttempt: true }));
    resetTimerCallback();
  };

  return (
    <Modal>
      {shouldShowOtpModal ? (
        <Modal disableClose>
          <form onSubmit={onSubmitOtpModal}>
            <OtpModal
              data={otpData}
              onClose={() => {
                setShouldShowOtpModal(false);
                closeModal();
              }}
              onResend={handleResendClick}
              isLoading={executePayrollV2WithOtp.isLoading}
              isDisabled={executePayrollV2.isLoading}
              className="!w-auto"
              actionName="Execute_Payroll"
            />
          </form>
        </Modal>
      ) : (
        <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
          <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
            Request Payroll Execution
          </div>
          <div className={`${styles['run-payroll-modal-content']}`}>
            If you would like to execute this payroll now, then please confirm below.
            <br />
            <br />
            It can take a few hours after the request has been received for the payroll to actually
            execute. You will receive an email notification once it happens.
            <br />
            <br />
            {isSuperAdmin && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="dryRunCheckbox"
                  checked={isDryRun}
                  onChange={() => setIsDryRun((prev) => !prev)}
                />
                <label htmlFor="dryRunCheckbox" className="ml-2">
                  Is this a dry run?
                </label>
              </div>
            )}
          </div>
          <div className={`flex justify-end ${styles['run-payroll-modal-footer']} gap-6`}>
            <StandardButton
              onClick={onExecutePayroll}
              isPending={isRequestInProgress}
              disabled={splitzQuery.isLoading}>
              EXECUTE PAYROLL
            </StandardButton>
            <StandardButton onClick={() => closeModal()} disabled={isRequestInProgress}>
              CANCEL
            </StandardButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ExecutePayroll;
