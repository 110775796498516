import { useSearchParams } from 'hooks/useSearchParams';
import { useEffect, useRef } from 'react';

type UseFilterProps<T extends string> = {
  filtersMap: Record<string, T>;
  multipleValueFilters?: T[];
  onParamsChange?: (params: Record<string, any>) => void;
  exemptFilters?: string[];
};

const useFilters = <T extends string>({
  filtersMap,
  multipleValueFilters = [],
  onParamsChange,
  exemptFilters = ['page', 'limit'],
}: UseFilterProps<T>) => {
  const { addSearchParams, removeSearchParams, urlParams } = useSearchParams();
  const previousParams = useRef<string>('');

  const getRelevantParamsString = (params: URLSearchParams) => {
    const entries = Array.from(params.entries());
    const relevantEntries = entries.filter(([key]) => !exemptFilters.includes(key as T));
    return new URLSearchParams(relevantEntries).toString();
  };

  useEffect(() => {
    const currentParamsString = getRelevantParamsString(urlParams);
    if (onParamsChange && currentParamsString !== previousParams.current) {
      onParamsChange(Object.fromEntries(urlParams.entries()));
      previousParams.current = currentParamsString;
    }
  }, [urlParams, onParamsChange, exemptFilters]);

  const applyFilter = (filter: T, value: string | string[]) => {
    addSearchParams([{ key: filter, value }]);
  };

  const applyFilters = (filters: { filter: T; value: string | string[] }[]) => {
    addSearchParams(filters.map(({ filter, value }) => ({ key: filter, value })));
  };

  const removeFilter = (filter: T) => {
    removeSearchParams([filter]);
  };

  const currentFilters: {
    [key in T]?: any;
  } = {};

  Object.entries(filtersMap).forEach(([_, filter]) => {
    const filterValue = multipleValueFilters.includes(filter as T)
      ? urlParams.getAll(filter as T)
      : urlParams.get(filter as T);
    if (Array.isArray(filterValue) && filterValue.length > 0) {
      currentFilters[filter as T] = filterValue;
    } else if (filterValue) {
      currentFilters[filter as T] = filterValue;
    }
  });

  const isAnyFilterApplied = Object.entries(currentFilters).some(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  });

  return {
    applyFilter,
    applyFilters,
    removeFilter,
    isAnyFilterApplied,
    urlParams,
    currentFilters,
  };
};

export { useFilters };
