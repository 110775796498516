import React from 'react';
import { Alert, Box } from '@razorpay/blade/components';

export const TDSFilingWarning = ({ payrollMonth }: { payrollMonth?: string }) => {
  return (
    <Box marginBottom="spacing.8">
      <Alert
        color="notice"
        isDismissible={false}
        isFullWidth={true}
        title="Warning: Payroll Execution Blocked!"
        description="Payroll execution is on hold due to missing/unverified TDS Deductor details. Please update and verify the required information to proceed with payroll for this month."
        actions={{
          primary: {
            text: 'Update Details',
            onClick: () => {
              window.location.href = `/companyOnboarding?step=799&redirect=%2Frun-payroll?payroll_month=${payrollMonth}`;
            },
          },
        }}
      />
    </Box>
  );
};
