import { Box, Tooltip } from '@razorpay/blade/components';
import React from 'react';
import { SidebarLink, SpanWrapper } from '../styles';

export interface LinkProps {
  icon: string;
  label: string;
  href: string;
  tooltip?: string;
  target?: string;
}

export interface LinksGroupProps {
  links: LinkProps[];
}

const LinksGroup = ({ links }: LinksGroupProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.3'} width="max-content">
      {links.map(({ href, label, icon, tooltip, target }) => {
        if (tooltip) {
          return (
            <Tooltip content={tooltip} placement="right">
              <SidebarLink href={href} key={label} target={target}>
                <SpanWrapper>{icon}</SpanWrapper>
                <SpanWrapper>{label}</SpanWrapper>
              </SidebarLink>
            </Tooltip>
          );
        }
        return (
          <SidebarLink href={href} key={label} target={target}>
            <SpanWrapper>{icon}</SpanWrapper>
            <SpanWrapper>{label}</SpanWrapper>
          </SidebarLink>
        );
      })}
    </Box>
  );
};

export { LinksGroup };
