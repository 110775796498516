import { routePaths } from '../Routes/data';

export const pagesWithoutSideBar: String[] = [
  routePaths.unknown,
  routePaths.bulkUploads.root,
  routePaths.bulkUploads.bulkAdditionDeductionLOP.root,
  routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload,
  routePaths.bulkUploads.bulkAdditionDeductionLOP.previewScreen,
  routePaths.settings.jibble.onboarding.activation,
  routePaths.settings.jibble.onboarding.settingsConfiguration,
  routePaths.settings.jibble.onboarding.syncEmployees,
  routePaths.bulkUploads.bulkSalaryRevision.root,
  routePaths.bulkUploads.bulkSalaryRevision.fileUpload,
  routePaths.bulkUploads.bulkSalaryRevision.previewScreen,
  routePaths.kyc.root,
  routePaths.kyc.bankDetails,
  routePaths.kyc.bankDetailsManual,
  routePaths.kyc.userSelection,
  routePaths.kyc.documentsUpload,
  routePaths.kyc.success,
  routePaths.login.otp,
  routePaths.onboarding.root,
  routePaths.onboarding.companyDetails.root,
  routePaths.onboarding.companyDetails.basicDetails,
  routePaths.onboarding.companyDetails.panPrefill,
  routePaths.kyc.companyDetailsVerification.root,
  routePaths.kyc.companyDetailsVerification.gstin,
  routePaths.kyc.companyDetailsVerification.cinOrLlpin,
  routePaths.kyc.companyDetailsVerification.manual,
  routePaths.kyc.companyDetailsVerification.addAddress,
  routePaths.kyc.companyDetailsVerification.pan,
  routePaths.approvalWorkflows.workflow.root,
  routePaths.approvalWorkflows.workflow.editPayroll,
  routePaths.approvalWorkflows.workflow.finalizePayroll,
  routePaths.approvalWorkflows.workflow.salaryRevision,
  routePaths.approvalWorkflows.myRequests.root,
  routePaths.approvalWorkflows.myRequests.editPayroll.root,
  routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.editPayroll.completed,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.root,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.completed,
  routePaths.approvalWorkflows.myRequests.salaryRevision.root,
  routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.salaryRevision.completed,
  routePaths.flexibleBenefits.root,
  routePaths.flexibleBenefits.declaration,
  routePaths.bonus.view,
  routePaths.bonus.create,
  routePaths.bonus.edit,
  routePaths.settings.darwinbox.integration,
  routePaths.settings.darwinbox.settings.root,
  routePaths.settings.darwinbox.settings.fields,
  routePaths.settings.darwinbox.settings.employeeContractorMapping,
  routePaths.settings.darwinbox.settings.syncConfirmation,
  routePaths.settings.darwinbox.settings.syncInProgress,
  routePaths.settings.darwinbox.settings.disableIntegration,
  routePaths.settings.hibob.integration,
  routePaths.settings.hibob.settings.root,
  routePaths.settings.hibob.settings.fields,
  routePaths.settings.hibob.settings.employeeContractorMapping,
  routePaths.settings.hibob.settings.syncConfirmation,
  routePaths.settings.hibob.settings.syncInProgress,
  routePaths.settings.hibob.settings.disableIntegration,
  routePaths.settings.worq.integration,
  routePaths.settings.worq.settings.root,
  routePaths.settings.worq.settings.fields,
  routePaths.settings.worq.settings.employeeContractorMapping,
  routePaths.settings.worq.settings.syncConfirmation,
  routePaths.settings.worq.settings.syncInProgress,
  routePaths.settings.worq.settings.disableIntegration,
  routePaths.settings.workline.integration,
  routePaths.settings.workline.settings.root,
  routePaths.settings.workline.settings.fields,
  routePaths.settings.workline.settings.employeeContractorMapping,
  routePaths.settings.workline.settings.syncConfirmation,
  routePaths.settings.workline.settings.syncInProgress,
  routePaths.settings.workline.settings.disableIntegration,
  routePaths.nps.declaration,
  routePaths.settings.rxIntegration.root,
  routePaths.settings.rxIntegration.preCheck,
  routePaths.settings.rxIntegration.activate,
  routePaths.payrollAiAssistant,
  routePaths.integrations.v2,
  routePaths.bulkUploads.root,
  routePaths.bulkUploads.initiate,
  routePaths.bulkUploads.preview,
  routePaths.salaryExplainer,
  routePaths.salaryComponents.create.earnings.root,
  routePaths.salaryComponents.create.earnings.generalDetails,
  routePaths.salaryComponents.create.earnings.taxationDetails,
  routePaths.salaryComponents.create.earnings.review,
  routePaths.salaryComponents.create.deductions.root,
  routePaths.salaryComponents.create.deductions.generalDetails,
  routePaths.salaryComponents.create.deductions.payAndTaxDetails,
  routePaths.salaryComponents.create.deductions.review,
  routePaths.salaryComponents.edit.earnings.root,
  routePaths.salaryComponents.edit.earnings.generalDetails,
  routePaths.salaryComponents.edit.earnings.taxationDetails,
  routePaths.salaryComponents.edit.earnings.review,
  routePaths.salaryComponents.edit.deductions.root,
  routePaths.salaryComponents.edit.deductions.generalDetails,
  routePaths.salaryComponents.edit.deductions.payAndTaxDetails,
  routePaths.salaryComponents.edit.deductions.review,
  routePaths.salaryComponents.edit.predefinedComponent,
];

export const pagesWithoutHeader: String[] = [
  routePaths.unknown,
  routePaths.kyc.root,
  routePaths.kyc.bankDetails,
  routePaths.kyc.bankDetailsManual,
  routePaths.kyc.userSelection,
  routePaths.kyc.documentsUpload,
  routePaths.kyc.success,
  routePaths.onboarding.root,
  routePaths.onboarding.companyDetails.root,
  routePaths.onboarding.companyDetails.basicDetails,
  routePaths.onboarding.companyDetails.panPrefill,
  routePaths.kyc.companyDetailsVerification.root,
  routePaths.kyc.companyDetailsVerification.gstin,
  routePaths.kyc.companyDetailsVerification.cinOrLlpin,
  routePaths.kyc.companyDetailsVerification.manual,
  routePaths.kyc.companyDetailsVerification.addAddress,
  routePaths.kyc.companyDetailsVerification.pan,
  routePaths.approvalWorkflows.workflow.root,
  routePaths.approvalWorkflows.workflow.editPayroll,
  routePaths.approvalWorkflows.workflow.finalizePayroll,
  routePaths.approvalWorkflows.workflow.salaryRevision,
  routePaths.approvalWorkflows.myRequests.root,
  routePaths.approvalWorkflows.myRequests.editPayroll.root,
  routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.editPayroll.completed,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.root,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.finalizePayroll.completed,
  routePaths.approvalWorkflows.myRequests.salaryRevision.root,
  routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
  routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe,
  routePaths.approvalWorkflows.myRequests.salaryRevision.completed,
  routePaths.flexibleBenefits.root,
  routePaths.flexibleBenefits.declaration,
  routePaths.bonus.view,
  routePaths.bonus.create,
  routePaths.bonus.edit,
  routePaths.settings.darwinbox.integration,
  routePaths.settings.darwinbox.settings.root,
  routePaths.settings.darwinbox.settings.fields,
  routePaths.settings.darwinbox.settings.employeeContractorMapping,
  routePaths.settings.darwinbox.settings.syncConfirmation,
  routePaths.settings.darwinbox.settings.syncInProgress,
  routePaths.settings.darwinbox.settings.disableIntegration,
  routePaths.settings.hibob.integration,
  routePaths.settings.hibob.settings.root,
  routePaths.settings.hibob.settings.fields,
  routePaths.settings.hibob.settings.employeeContractorMapping,
  routePaths.settings.hibob.settings.syncConfirmation,
  routePaths.settings.hibob.settings.syncInProgress,
  routePaths.settings.hibob.settings.disableIntegration,
  routePaths.settings.worq.integration,
  routePaths.settings.worq.settings.root,
  routePaths.settings.worq.settings.fields,
  routePaths.settings.worq.settings.employeeContractorMapping,
  routePaths.settings.worq.settings.syncConfirmation,
  routePaths.settings.worq.settings.syncInProgress,
  routePaths.settings.worq.settings.disableIntegration,
  routePaths.settings.workline.integration,
  routePaths.settings.workline.settings.root,
  routePaths.settings.workline.settings.fields,
  routePaths.settings.workline.settings.employeeContractorMapping,
  routePaths.settings.workline.settings.syncConfirmation,
  routePaths.settings.workline.settings.syncInProgress,
  routePaths.settings.workline.settings.disableIntegration,
  routePaths.nps.declaration,
  routePaths.settings.rxIntegration.root,
  routePaths.settings.rxIntegration.preCheck,
  routePaths.settings.rxIntegration.activate,
  routePaths.payrollAiAssistant,
  routePaths.integrations.v2,
  routePaths.salaryComponents.create.earnings.root,
  routePaths.salaryComponents.create.earnings.generalDetails,
  routePaths.salaryComponents.create.earnings.taxationDetails,
  routePaths.salaryComponents.create.earnings.review,
  routePaths.salaryComponents.create.deductions.root,
  routePaths.salaryComponents.create.deductions.generalDetails,
  routePaths.salaryComponents.create.deductions.payAndTaxDetails,
  routePaths.salaryComponents.create.deductions.review,
  routePaths.salaryComponents.edit.earnings.root,
  routePaths.salaryComponents.edit.earnings.generalDetails,
  routePaths.salaryComponents.edit.earnings.taxationDetails,
  routePaths.salaryComponents.edit.earnings.review,
  routePaths.salaryComponents.edit.deductions.root,
  routePaths.salaryComponents.edit.deductions.generalDetails,
  routePaths.salaryComponents.edit.deductions.payAndTaxDetails,
  routePaths.salaryComponents.edit.deductions.review,
  routePaths.salaryComponents.edit.predefinedComponent,
];

export const pagesWithoutLoggedInData: String[] = [routePaths.login.otp];
