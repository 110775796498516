import styled from 'styled-components';

export const LogoContainer = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing[8]}px;
    background-color: ${theme.colors.surface.text.gray.normal};
    border-radius: ${theme.border.radius.medium}px;
    display: flex;
    align-items: center;
    justify-content: center;
`,
);
