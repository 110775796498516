import permissions from 'constants/permissions';
import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import styles from '../../index.module.scss';
import { SecondaryButton } from 'components/ui/Button';
import ProPlanPill from 'components/ui/ProPlanPill';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useModal } from 'components/ui/Modals';
import { getStaticMediaUrl } from 'utils/Urls';
import rocketIcon from 'assets/icon--rocket.svg';

const PayrollBulkActions = () => {
  const permission = useSelector(loggedInUserSelectors.permissions);
  const hasBulkUploadPermission = permission.includes(permissions.BULK_ADDITION_DEDUCTION);
  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
  const history = useHistory();
  const { closeModal } = useModal();

  function onBulkUploadsClick() {
    history.push(routePaths.bulkUploads.bulkAdditionDeductionLOP.root);
    closeModal();
  }

  if (!hasBulkUploadPermission) return null;

  return (
    <>
      <div className={styles['gap']}></div>
      <div className={styles['bulk-upload-modal']}>
        <div className={styles['bulk-upload-modal-icon']}>
          <img src={getStaticMediaUrl(rocketIcon)} className="max-w-none" />
        </div>
        {isOnProPlan ? (
          <div className={styles['bulk-upload-modal-content']}>
            <div className={styles['main-text']}>Too many employees to update?</div>
            <div className={styles['sub-text']}>
              Perform actions like additions and deductions in bulk!
            </div>
          </div>
        ) : (
          <div className={styles['bulk-upload-modal-content']}>
            <div className={styles['main-text']}>
              Introducing bulk employee additions, deductions and more!
            </div>
            <div className={styles['sub-text']}>Contact support to upgrade now!</div>
          </div>
        )}
        {isOnProPlan ? (
          <SecondaryButton
            onClick={onBulkUploadsClick}
            className={styles['bulk-upload-modal-button']}>
            Bulk Upload
          </SecondaryButton>
        ) : (
          <ProPlanPill className={`${styles['bulk-upload-modal-pro-plan-pill']}`} />
        )}
      </div>
    </>
  );
};

export default PayrollBulkActions;
