import { format, parse } from 'date-fns';
import { GetNetpayOnHoldListSchemaContract } from 'schemas/GetNetpayOnHoldListSchema';
import { dateFormats } from 'utils/Dates';
import { Location, ReleaseNetpayListItem } from './types';

export const getLocationNameFromKey = (key: number, locations: Location[]) => {
  const location = locations.find((e) => e.code === key);
  const name = location?.name ?? key;
  return name.toString();
};

export const formatLastWorkingDate = (hireDate: string) => {
  const date = parse(hireDate, dateFormats.yearMonthDate, new Date());
  return format(date, dateFormats.dateShortMonthCommaYear);
};

export const transformNetPayListings = (
  items: GetNetpayOnHoldListSchemaContract['data'],
  locations: Location[],
): Array<ReleaseNetpayListItem> => {
  return items.map((item) => ({
    ...item,
    allowAction: true,
    last_working_date: item.last_working_date ? formatLastWorkingDate(item.last_working_date) : '',
    locationName: getLocationNameFromKey(item.location || 0, locations),
  }));
};

export const getLocationNamesFromKeys = (keys: string[], locations: Location[]) => {
  return keys.map((key) => {
    const location = locations.find((e) => e.code?.toString() === key);
    return location?.name ?? key;
  });
};
