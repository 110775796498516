import React, {useState} from "react";
import PageWrapper from "../../../../ui/PageWrapper";
import MainCase from "../../../../ui/MainCase";
import Breadcrumb from "../../../../ui/Breadcrumb";
import jibbleCommonStyles from "../../index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleSignature from "assets/jibble/signature--jibble.svg";
import {RadioButton} from "../../../../ui/RadioButton";
import styles from "./index.module.scss";
import {Card} from "../../../../ui/Card";
import {StandardButton, StandardSecondaryButton} from "../../../../ui/Button";
import {Link, useHistory} from "react-router-dom";
import {routePaths} from "../../../../Routes/data";
import ExternalLink from "../../../../ui/ExternalLink";
import {useQuery} from "react-query";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "../../utils/queries";
import api from "../../../../../api";
import APIError from "../../../../../types/apiError";
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../../reducers/loggedInUser/selectors";
import cx from "classnames";

const ProceedButton = ({orgHasJibbleAccount}: { orgHasJibbleAccount: string }) => {
    const { jibbleSignupLink } = useSelector(loggedInUserSelectors.referenceLinks);
    if (orgHasJibbleAccount === 'n') {
        return (
            <div className="w-full flex flex-col items-end">
                <ExternalLink href={jibbleSignupLink}>
                    <StandardButton className={styles['proceedButton']}>
                        Create A Jibble Account
                    </StandardButton>
                </ExternalLink>
                <p className="text-1.5xl italic opacity-75">You will be redirected to Jibble's signup page</p>
                <p className="text-1.5xl italic opacity-75">Please create an account and come back to this page</p>
            </div>
        );
    }
    return (
        <div className="w-full">
            <Link target="_self" to={routePaths.settings.jibble.onboarding.activation}>
                <StandardButton className={styles['proceedButton']} disabled={orgHasJibbleAccount===''}>
                    Proceed With Integration
                </StandardButton>
            </Link>
        </div>
    );
}

const OrgJibbleAccountConfirmation = () => {
    const [orgHasJibbleAccount, setOrgHasJibbleAccount] = useState('');
    const history = useHistory();

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    if (integrationStatusQuery?.data?.is_enabled) {
        if (integrationStatusQuery.data.is_onboarding_completed){
            history.push(routePaths.settings.jibble.manage);
        }
        else {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    return (
        <div className={cx(jibbleCommonStyles['container'], "mt-12")}>
            <img src={getStaticMediaUrl(jibbleSignature)} alt="" className="mb-16"/>
            <h1 className="text-2.14xl font-bold opacity-90 mb-16">Does your organisation have a Jibble account?</h1>
            <Card className={styles['jibbleAccountConfirmation']}>
                <RadioButton
                    onSelect={() => setOrgHasJibbleAccount('y')}
                    isSelected={orgHasJibbleAccount === 'y'}
                >
                    Yes, we have a Jibble account for our organization
                </RadioButton>
            </Card>
            <Card className={styles['jibbleAccountConfirmation']}>
                <RadioButton
                    onSelect={() => setOrgHasJibbleAccount('n')}
                    isSelected={orgHasJibbleAccount === 'n'}
                >
                    No, we don't have a Jibble account
                </RadioButton>
            </Card>
            <h2 className="text-1.5xl font-bold mt-20 mb-4 opacity-90">👋 Why does your organization need a Jibble account?</h2>
            <p className="text-1.5xl opacity-75">All employees from RazorpayX Payroll will sync to your organization’s Jibble account.
               {' '}Attendance data of employees will flow from Jibble to RazorpayX Payroll.</p>
            <div className={"flex"}>
                <Link target={"_self"} to={routePaths.settings.jibble.introduction}>
                    <StandardSecondaryButton className={styles['backButton']}>Back</StandardSecondaryButton>
                </Link>
                <ProceedButton orgHasJibbleAccount={orgHasJibbleAccount}/>
            </div>
        </div>
    )
}

const OrgJibbleAccountConfirmationWrapper = () => (
    <PageWrapper>
        <Breadcrumb
            name="Jibble"
            urlMaps={{
                Integrations: '/integrations',
            }}
        />
        <MainCase center>
            <OrgJibbleAccountConfirmation/>
        </MainCase>
    </PageWrapper>
)

export default OrgJibbleAccountConfirmationWrapper;