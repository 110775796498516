import React from 'react';
import { IGenericErrorState } from '../../../reducers/genericError';
function Bar({ text, className }: { text: string; className: string }) {
  return <div className={className + ' text-center'}>{text}</div>;
}

export function GlobalNotification({
  genericError,
  networkStatus,
}: {
  genericError: IGenericErrorState;
  networkStatus: boolean;
}) {
  let errorText = genericError.errors.length > 0 ? genericError.errors[0].messageForUser : null;
  if (!networkStatus) {
    return (
      <Bar
        text="Looks like there is no internet connection. Please try again later..."
        className="bg-yellow-300 text-black"
      ></Bar>
    );
  }

  if (errorText) {
    return <Bar text={errorText} className="error-component bg-red-900 text-white"></Bar>;
  }

  return <></>;
}
