import React, { useState } from 'react';
import { Box, ExternalLinkIcon, Link, Alert } from '@razorpay/blade/components';
import GeneralSettings from './GeneralSettings';
import { NpsSettings } from './types';
import Breadcrumb from 'components/ui/Breadcrumb';
import { LeftImg, RightImg } from 'components/ui/Layout/styles';
import leftImg from 'assets/layouts/circle_left.svg';
import rightImg from 'assets/layouts/right_circle.svg';
import { GET_ORG_NPS_SETTINGS } from './queries';
import api from 'api';
import { useQuery } from 'react-query';
import { AppError } from 'utils/AppError';
import { parseSettings } from './utils';
import { useSelector } from "react-redux";
import Errors from 'components/ui/Form/Errors';
import loggedInUserSelectors from "../../../reducers/loggedInUser/selectors";
import { useRef } from 'react';



const NpsSettingsPage = () => {
    const org = useSelector(loggedInUserSelectors.currentOrganization);
    const containerRef = useRef<HTMLDivElement>(null);

    const [settings, setSettings] = useState<NpsSettings>({
        isNpsEnabled: false,
        employeeCanDeclareNpsLimit: false,
        NpsDeclarationWindow: {
            type: 'always'
        }
    });

    const handleGeneralSettingsChange = (
        key: keyof NpsSettings,
        value: boolean
    ) => {
        setSettings((p) => ({ ...p, [key]: value }));
    };

    const fetchOrgNpsConfig = useQuery({
        queryKey: GET_ORG_NPS_SETTINGS,
        queryFn: api.nps.getOrgNpsConfig,
        onSuccess: (response) => {
            setSettings(parseSettings(response));
        },
        onError: (error: typeof AppError) => { },
    });

    return (
        (<Box
            display="flex"
            backgroundColor={'surface.background.gray.intense'}
            flexDirection="column"
            minHeight="100%"
            position="relative"
            paddingX={'25px'}
        >
            <LeftImg height="300px" src={leftImg} />
            <RightImg src={rightImg} />
            <Box display="flex" >
                <Box marginRight={'auto'}>
                    <Breadcrumb
                        name="NPS for Employer Setup"
                        urlMaps={{
                            Settings: '/settings',
                        }}
                    />
                </Box>
                <Link
                    variant="button"
                    icon={ExternalLinkIcon}
                    iconPosition="right"
                    marginLeft={'auto'}
                    onClick={() => { window.open('https://razorpay.com/docs/x/xpayroll/employers/salary/nps/', '_blank'); }}>
                    How to setup NPS
                </Link>
            </Box>
            <Box width="100%" height="100%">
                {
                    fetchOrgNpsConfig.error &&
                    <Box
                        ref={containerRef}
                        marginX={{ base: 'spacing.8', l: 'auto' }}
                        width="500px"
                        paddingTop="spacing.8"
                    >
                        <Alert description={<Errors errorResponse={fetchOrgNpsConfig.error} />} color="negative" />
                    </Box>
                }
                <GeneralSettings
                    isLoading={fetchOrgNpsConfig.isLoading}
                    settings={settings}
                    setSettings={setSettings}
                    onChange={handleGeneralSettingsChange}
                />
            </Box>
        </Box>)
    );
}

export default NpsSettingsPage;