import React from 'react';
import { Box, Text } from '@razorpay/blade/components';
import PayableTaxPercent from './PayableTaxPercent';
import RegimeTaxBreakup from './RegimeTaxBreakup';
import { PayrollAiAssistantPollSchemaContract } from 'schemas/PayrollAiAssistantPollSchema';
import { toIndianCurrencyString } from 'utils/Numbers';
import styled from 'styled-components';

export const GreenText = styled.div(
  ({ theme }) => `
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #8BFFEB;
      padding-top: 2px;
    `,
);

export const BarGraphWrapper = styled.div(
  ({ theme }) => `
    display: grid;
    padding: 12px 0px;
    margin-top: 12px;
    width: 100%;
    max-width: 842px;
    gap: 12px;
    border-left: 1px dashed rgb(157 159 178 / 28%);
  `,
);

const RegimeBreakup = (data: PayrollAiAssistantPollSchemaContract['response']) => {
  if (!data || !data.regime_breakup) {
    return null;
  }

  const regimeData = data.regime_breakup;
  const oldRegimeTax = regimeData.old_regime.total_tax_amount;
  const newRegimeTax = regimeData.new_regime.total_tax_amount;
  const taxDiff = Math.ceil(Math.abs(oldRegimeTax - newRegimeTax));

  let firstPercentDisplay = regimeData.new_regime;
  let secondPercentDisplay = regimeData.old_regime;
  let isNewShownFirst = !(newRegimeTax > oldRegimeTax);

  if (!isNewShownFirst) {
    firstPercentDisplay = regimeData.old_regime;
    secondPercentDisplay = regimeData.new_regime;
  }

  const isUnknownCurrentRegime =
    !regimeData.current_regime || regimeData.current_regime.toLocaleLowerCase() === 'na';

  const currentTax = isUnknownCurrentRegime
    ? null
    : regimeData.current_regime.toLocaleLowerCase() === 'new'
    ? regimeData.new_regime
    : regimeData.old_regime;

  const suggestedRegime =
    regimeData.suggested_regime.toLocaleLowerCase() === 'new'
      ? regimeData.new_regime
      : regimeData.old_regime;
  const otherRegime =
    regimeData.suggested_regime.toLocaleLowerCase() === 'new'
      ? regimeData.old_regime
      : regimeData.new_regime;

  const isUserOnSuggestedRegime = regimeData.suggested_regime
    .toLocaleLowerCase()
    .includes(regimeData.current_regime.toLocaleLowerCase());

  return (
    (<Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      width="100%"
      backgroundColor="transparent">
      <Text color="surface.text.gray.subtle" size="large">
        🥁 Drumrolls...
      </Text>
      {secondPercentDisplay.total_tax_amount > 0 && (
        <Box marginTop={'32px'} width={'100%'}>
          {isUserOnSuggestedRegime && !isUnknownCurrentRegime ? (
            <Text size="large" color="surface.text.gray.subtle" weight="semibold">
              🤩 Looks like we have a tax expert here! Your chosen regime is the perfect match.
            </Text>
          ) : (
            <Box display={'inline-flex'} gap={'3px'} flexWrap={'wrap'}>
              <Text color="surface.text.gray.subtle" size="large" display={'inline-flex'}>
                🤯 You can potentially save up to{' '}
              </Text>
              <GreenText>
                {toIndianCurrencyString(taxDiff)} more tax in {regimeData.suggested_regime} regime!
              </GreenText>
            </Box>
          )}

          {currentTax && (
            <Text color="surface.text.gray.subtle" size="large">
              👉🏻 Currently you pay {toIndianCurrencyString(currentTax.total_tax_amount)} as tax in{' '}
              {regimeData.current_regime.toLocaleLowerCase() === 'old' ? 'Old' : 'New'} regime.
            </Text>
          )}
          <Text size="large" color="surface.text.gray.subtle" marginTop={"spacing.5"}>Minimum payable tax in both:</Text>
          <BarGraphWrapper>
            <PayableTaxPercent
              regime={isNewShownFirst ? 'New Regime' : 'Old Regime'}
              tax={firstPercentDisplay.total_tax_amount}
              percent={Math.ceil(
                (firstPercentDisplay.total_tax_amount / secondPercentDisplay.total_tax_amount) *
                  100,
              )}
              variant="green"
            />
            <PayableTaxPercent
              regime={isNewShownFirst ? 'Old Regime' : 'New Regime'}
              tax={secondPercentDisplay.total_tax_amount}
              percent={100}
            />
          </BarGraphWrapper>
        </Box>
      )}
      <Box marginY={'32px'} width={'100%'}>
        <Text color="surface.text.gray.subtle" size="large">
          Here's the breakdown, including the maximum possible investments, in both the regimes:
        </Text>
        <Box display="flex" gap="spacing.5" flexWrap="wrap" width="100%" marginY={'spacing.5'}>
          <RegimeTaxBreakup
            regime={
              regimeData.suggested_regime.toLocaleLowerCase() === 'old'
                ? 'Old Regime'
                : 'New Regime'
            }
            variant={'green'}
            showSuggested={true}
            inHand={suggestedRegime.in_hand}
            currentDeductions={suggestedRegime.current_deductions}
            maxDeductions={suggestedRegime.max_deductions_available}
            isCurrentRegime={isUserOnSuggestedRegime && !isUnknownCurrentRegime}
          />
          <RegimeTaxBreakup
            regime={
              regimeData.suggested_regime.toLocaleLowerCase() === 'new'
                ? 'Old Regime'
                : 'New Regime'
            }
            variant={'grey'}
            showSuggested={false}
            inHand={otherRegime.in_hand}
            currentDeductions={otherRegime.current_deductions}
            maxDeductions={otherRegime.max_deductions_available}
            isCurrentRegime={!isUserOnSuggestedRegime && !isUnknownCurrentRegime}
          />
        </Box>
        <Text color="surface.text.gray.subtle" size="medium">
          * The maximum deductions are an approximate value to give a guesstimate figure.
        </Text>
      </Box>
      <Box>
        {!isUnknownCurrentRegime && (
          <Text
            color="surface.text.gray.normal"
            size="large"
            weight="semibold"
            marginBottom={'spacing.4'}>
            {isUserOnSuggestedRegime
              ? '✨ Since it’s already a match— Let’s see how you can save more tax!'
              : '🤯 Woohoo! I found a way to optimise your tax just by switching regimes.'}
          </Text>
        )}
        <Box>
          <Text color="surface.text.gray.subtle" size="large">
            I need to know more about your personal finances to gauge where I can help save more
            money 📈
          </Text>
          <Text color="surface.text.gray.subtle" size="large">
            Do you want to get started?
          </Text>
        </Box>
      </Box>
    </Box>)
  );
};

export default RegimeBreakup;
