import React, {useState} from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import styles from "./index.module.scss";
import jibbleConnectionImage from "assets/jibble/jibble-connection.png";
import {getStaticMediaUrl} from "../../../../../../utils/Urls";
import {format} from "date-fns";
import {StandardButton} from "../../../../../ui/Button";
import {useMutation} from "react-query";
import api from "../../../../../../api";
import LopAdditionSuccessModal from "../LopAdditionSuccessModal";
import {useModal} from "../../../../../ui/Modals";
import {usePollAddLopCompletion} from "../../../hooks/usePollAddLopCompletion";
import {AppError} from "../../../../../../utils/AppError";
import {WarningAlert} from "../../../../../ui/Alert";

const AddDeductionsConfirmation = ({
    payrollMonth,
    fromDate,
    toDate,
    onClose,
    onCloseAndRefetch,
    selectedIds
}: {
    payrollMonth: string,
    fromDate: Date | null,
    toDate: Date | null,
    onClose: () => void,
    onCloseAndRefetch: () => void,
    selectedIds: number[]
}) => {
    const payrollMonthString = format(new Date(payrollMonth + "T00:00:00"), 'MMMM yyyy');
    const { openModal, closeModal } = useModal();
    const pollLopAdditionStatus = usePollAddLopCompletion({onComplete: () => {
        closeModal();
        openModal(<LopAdditionSuccessModal payrollMonth={payrollMonth} onCloseModal={onCloseAndRefetch}/>)
    }});
    const [error, setError] = useState('');

    const addDeductionsMutation = useMutation({
        mutationFn: api.jibbleSettings.addLopDeductions,
        onSuccess: () => pollLopAdditionStatus.beginPolling(),
        onError: (error: AppError) => setError(error.message),
        onMutate: () => setError('')
    })

    const addDeductions = () => {
        addDeductionsMutation.mutate({ attendanceIds: selectedIds });
    }

    const isLopAdditionInProgress = addDeductionsMutation.isLoading || pollLopAdditionStatus.isPolling;

    return (
        <Modal>
            <div className={styles['confirmationModal']}>
                { error !== '' && <WarningAlert className={"mb-8"}>{error}</WarningAlert>}
                <img src={getStaticMediaUrl(jibbleConnectionImage)} alt="Jibble" className={styles['modalImage']}/>
                <h1 className={styles['confirmationModalHeader']}>
                    Add Loss of pay deductions to {payrollMonthString} Payroll?
                </h1>
                {fromDate && toDate &&
                    <p>
                        Attendance loss of pay for {format(fromDate, 'dd MMMM yyyy')} to {' '}
                        {format(toDate, 'dd MMMM yyyy')} will be added to {payrollMonthString} payroll
                    </p>
                }
                <div className={styles['actions']}>
                    <StandardButton
                        className={styles['confirm']}
                        onClick={addDeductions}
                        isPending={isLopAdditionInProgress}
                    >
                        {isLopAdditionInProgress ? "Adding Deductions To Payroll" : "Add Deductions Now"}
                    </StandardButton>
                    <StandardButton
                        className={styles['cancel']}
                        onClick={onClose}
                        disabled={isLopAdditionInProgress}
                    >
                        Cancel
                    </StandardButton>
                </div>
            </div>
        </Modal>
    )
}

export default AddDeductionsConfirmation;