import React from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import { DocumentStatus } from '../../constants';
import { Loader } from './styles';

import fileUploadFailure from 'assets/file--upload--failure.svg';
import fileUploadNotInitiated from 'assets/file--upload--not--initiated.svg';
import fileUploadSuccess from 'assets/file--upload--success.svg';
import fileUploadUploading from 'assets/file--upload--uploading.svg';
import fileUploadVerifying from 'assets/file--upload--verifying.svg';
import iconCheckCircle from 'assets/icons/icon_check_circle.svg';

export const UploadIcon = ({ status }: { status: DocumentStatus }) => {
  switch (status) {
    case DocumentStatus.NOT_INITIATED:
      return <img src={getStaticMediaUrl(fileUploadNotInitiated)} alt="" />;

    case DocumentStatus.UPLOADING:
      return (
        <>
          <img src={getStaticMediaUrl(fileUploadUploading)} alt="" />
          <Loader />
        </>
      );

    case DocumentStatus.VERIFYING:
      return <img src={getStaticMediaUrl(fileUploadVerifying)} alt="" />;

    case DocumentStatus.SUCCESS:
      return <img src={getStaticMediaUrl(fileUploadSuccess)} alt="" />;
    case DocumentStatus.VERIFIED:
      return <img src={getStaticMediaUrl(iconCheckCircle)} alt="" />;

    case DocumentStatus.ERROR:
      return <img src={getStaticMediaUrl(fileUploadFailure)} alt="" />;
    default:
      return <img src={getStaticMediaUrl(fileUploadNotInitiated)} alt="" />;
  }
};
