import { Box } from '@razorpay/blade/components';
import React, { PropsWithChildren, ReactNode } from 'react';

const ComponentSection = ({ children }: PropsWithChildren<ReactNode>) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.7'}>
      {children}
    </Box>
  );
};

export default ComponentSection;