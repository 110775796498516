import React, { ReactElement } from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import AddToSlack from 'components/Settings/SlackSettings/components/AddToSlack';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import { useQuery } from 'react-query';
import { SLACK_OAUTH_MAPPING_QUERY } from 'components/Settings/SlackSettings/queries';
import SlackUninstall from 'components/Settings/SlackSettings/components/SlackUninstall';
import ErrorMessage from 'components/ui/ErrorMessage';
import APIError from 'types/apiError';

interface Props {}

function SlackSettings({}: Props): ReactElement {
  const oauthMappingQuery = useQuery(
    SLACK_OAUTH_MAPPING_QUERY,
    () => {
      return api.slackSettings.getOauthMapping();
    },
    {
      onError: (error: APIError) => {},
    },
  );

  if (oauthMappingQuery.status === 'loading' || oauthMappingQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (oauthMappingQuery.status === 'error') {
    return (
      <ErrorMessage title="Something went wrong!">
        <div>{oauthMappingQuery.error?.message || 'Please contact support.'}</div>
      </ErrorMessage>
    );
  }

  if (oauthMappingQuery.data?.slack_team_id) {
    return <SlackUninstall />;
  }

  return <AddToSlack />;
}

function SlackSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase>
        <SlackSettings />
      </MainCase>
      <RightSidebar />
    </PageWrapper>
  );
}

export default SlackSettingsWithPageWrapper;
