import React from 'react';
import { LinkButtonV2, SecondaryButton, StandardButton } from 'components/ui/Button';
import { Link, useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import Card from 'components/Settings/ZohoSettings/components/Card';
import { WarningAlertYellow } from 'components/ui/Alert';
import { getMissingFields, getTransformedAvailableFields } from './utils';
import Field from '../components/Field';
import { AvailableField, SyncedField } from '../types';
import PencilIcon from 'assets/icons/pencil_icon.svg';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface ZohoPreviewFieldsProps {
  onEdit: () => void;
  availableFields: AvailableField[];
  allFields: { field: string; name: string }[];
  syncedFields: SyncedField[];
}

function ZohoPreviewFields({
  onEdit,
  availableFields,
  allFields,
  syncedFields,
}: ZohoPreviewFieldsProps) {
  const history = useHistory();

  const unavailableFields = getMissingFields(allFields, availableFields);
  const transformedAvailableFields = getTransformedAvailableFields(syncedFields, availableFields);
  const unselectedFields = Object.keys(transformedAvailableFields)
    .filter((field) => !transformedAvailableFields[field].isSelectedForSync)
    .map((field) => transformedAvailableFields[field]);

  return (
    <div className="mt-12">
      <div className="flex justify-between">
        <h3 className={styles['title']}>Preview employee details</h3>
        <LinkButtonV2 onClick={onEdit}>
          <img src={PencilIcon} alt="" className={styles['edit-icon']} />
          Edit Fields
        </LinkButtonV2>
      </div>
      <Card className="mt-12">
        <h3 className="font-bold text-white-o-70">
          The below {syncedFields.length} employee data fields will be synced from Zoho People to
          RazorpayX Payroll
        </h3>
        <div className="grid grid-cols-1 gap-x-3 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-4 mt-10">
          {syncedFields.map((field) => {
            return (
              <Field isEnabled key={field.field}>
                {field.name}
              </Field>
            );
          })}
        </div>
        {(unavailableFields.length > 0 || unselectedFields.length > 0) && (
          <>
            <div className="font-bold text-white-o-70 mt-12">Employee data that will not sync</div>
            <div className="grid grid-cols-1 gap-x-3 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-4 mt-10">
              {unselectedFields.map((field) => {
                return (
                  <Field isEnabled={false} key={field.type}>
                    {field.name}
                  </Field>
                );
              })}
              {unavailableFields.map((field) => {
                return (
                  <Field isEnabled={false} key={field.field}>
                    {field.name}
                  </Field>
                );
              })}
            </div>
          </>
        )}
      </Card>
      {unavailableFields.length > 0 && (
        <WarningAlertYellow className="mt-10">
          <span className="text-white-o-50">
            The following fields are disabled in Zoho People and won't be synced with RazorpayX
            Payroll:{' '}
          </span>
          <span className="text-white-o-70 font-bold">
            {unavailableFields.map((field) => field.name).join(', ')}.
          </span>
        </WarningAlertYellow>
      )}
      <div className="flex justify-between mt-24">
        <SecondaryButton
          onClick={() => {
            history.goBack();
          }}>
          Back
        </SecondaryButton>
        <Link to={routePaths.settings.zoho.config.employeeTypeSetup}>
          <StandardButton
            className="mr-0"
            onClick={() => trackSegment('zoho.field_mapping.next.click')}>
            Next
          </StandardButton>
        </Link>
      </div>
    </div>
  );
}

export default ZohoPreviewFields;
