import React, { FormEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { InputElement, CheckBox } from 'components/ui/Form/Input';
import { Select, Option } from 'components/ui/Form/Select';
import Note from 'components/ui/Note';
import { StandardButton } from 'components/ui/Button';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { useMutation } from 'react-query';
import api from 'api';
import RequiredFieldsIndicator from 'components/ui/Form/RequiredFieldsIndicator';
import { PayrollInfoRequest } from 'api/companyOnboarding';
import { trackSegment } from 'utils/segment';
import { trackGtag, trackRazorpayAnalytics } from 'utils/razorpayAnalytics';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

const payrollDates: { key: number; value: number }[] = [];
for (var i = 1; i <= 31; i++) {
  payrollDates.push({ key: i, value: i });
}

const PayrollDate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isPayrollAutoPilotDisabled } = useIsFeatureDisabledForRearch();
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const [payrollDate, setPayrollDate] = useState(currentOrganization?.payrollDate || 1);
  const [isPayrollEnabled, setIsPayrollEnabled] = useState(!!currentOrganization?.isPayrollEnabled);
  const [isAutoPayrollEnabled, setIsAutoPayrollEnabled] = useState(
    !!currentOrganization?.autoExecutePayroll,
  );
  const [isAdvanceSalaryEnabled, setIsAdvanceSalaryEnabled] = useState(
    !!currentOrganization?.advanceSalaryEnabled,
  );

  const updatePayrollInfoMutation = useMutation(
    ({
      isPayrollEnabled,
      payrollDate,
      isAutoPayrollEnabled,
      isAdvanceSalaryEnabled,
    }: PayrollInfoRequest) =>
      api.companyOnboarding.updatePayrollInfo({
        isPayrollEnabled,
        payrollDate,
        isAutoPayrollEnabled,
        isAdvanceSalaryEnabled,
      }),
    {
      onSuccess: () => {
        trackSegment('onboarding.company_details.set_payroll_date.continue_click');
        triggerOnboardingPixels();
        dispatch(fetchLoggedInUser());
        history.push(routePaths.companyOnboarding.salaryStructure);
      },
    },
  );

  const triggerOnboardingPixels = () => {
    trackRazorpayAnalytics('fb', 'Set_Payroll_Date');
    trackRazorpayAnalytics('linkedin_onboarding_payroll_date', { conversion_id: 7377300 });
    trackRazorpayAnalytics('twitter_onboarding_payroll_date', {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    trackGtag('event', 'conversion', { send_to: 'AW-928471290/TimvCOzhx7IDEPqx3boD' });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updatePayrollInfoMutation.mutate({
      isPayrollEnabled,
      payrollDate,
      isAutoPayrollEnabled,
      isAdvanceSalaryEnabled,
    });
  };

  const isFormBeingSubmitted = updatePayrollInfoMutation.status === 'loading';
  const isPayrollAutopilotEnabled = isPayrollAutoPilotDisabled
    ? false
    : (currentOrganization?.noOfEmployees || 0) < 500;

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit}>
        <CheckBox
          id="payroll-enabled"
          label="Payroll Enabled"
          className="mb-24"
          value="is-payroll-enabled"
          checked={isPayrollEnabled}
          onChange={(event) => {
            setIsPayrollEnabled(event.target.checked);
          }}
          disabled={isFormBeingSubmitted}
        />
        {isPayrollEnabled && (
          <>
            <Note>
              Choose when you would like to pay your employees.
              <br />
              Select a date after the 15th to pay your employees for the same month eg. if you pick
              31, then your employees will get paid for January on 31st January. Or, if you pick 1,
              then your employees will get paid for January on 1st February.
            </Note>

            <InputElement required label="Payroll Date" className="my-auto mt-4">
              <Select
                required
                onChange={(event) => {
                  setPayrollDate(parseInt(event.target.value));
                }}
                value={payrollDate}
                disabled={isFormBeingSubmitted}>
                {payrollDates.map((payrollDate) => (
                  <Option key={payrollDate.key} value={payrollDate.key}>
                    {payrollDate.value}
                  </Option>
                ))}
              </Select>
            </InputElement>
            {isPayrollAutopilotEnabled && (
              <>
                <Note>
                  You can choose to either manually request execution each month before the payroll
                  is executed, or XPayroll can automatically execute your payroll on your chosen
                  date.
                </Note>

                <CheckBox
                  id="payroll-automatic"
                  label="Automatically run payroll on selected date"
                  className="mb-8"
                  value="is-auto-payroll-enabled"
                  checked={isAutoPayrollEnabled}
                  onChange={(event) => {
                    setIsAutoPayrollEnabled(event.target.checked);
                  }}
                  disabled={isFormBeingSubmitted}
                />
              </>
            )}

            <IsFeatureDisabledForRearch feature="isAdvanceSalaryDisabled">
              <Note>
                Employees can request salary advances through XPayroll. If approved, the advance
                amount will be paid immediately and automatically recovered from future payments to
                the employees.
              </Note>

              <CheckBox
                id="salary-advance-request"
                label="Let employees request salary advances"
                className="mb-8"
                value="is-advance-salary-enabled"
                checked={isAdvanceSalaryEnabled}
                onChange={(event) => {
                  setIsAdvanceSalaryEnabled(event.target.checked);
                }}
                disabled={isFormBeingSubmitted}
              />
            </IsFeatureDisabledForRearch>
          </>
        )}
        <br />
        <RequiredFieldsIndicator className="mb-4" />
        <StandardButton type="submit" isPending={isFormBeingSubmitted}>
          Save & Next
        </StandardButton>
      </form>
    </div>
  );
};

export default PayrollDate;
