export const ATTENDANCE_STATUS = {
  STATUS_PRESENT: 10,
  STATUS_LEAVE: 20,
  STATUS_HALF_DAY: 25,
  STATUS_HOLIDAY_WEEKEND: 30,
  STATUS_HOLIDAY: 40,
  STATUS_UNPAID_LEAVE: 50,
  STATUS_UNPAID_HALF_DAY: 55,
  STATUS_DELETION_REQUESTED: 60,
};
