import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import {getStaticMediaUrl} from "../../../utils/Urls";
import zaggleIntegrated from "../../../assets/zaggleIntegrated.svg";
import { PrimaryButtonV2 as PrimaryButton } from "components/ui/Button";
import { routePaths } from "components/Routes/data";
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "./utils/queries";
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import api from '../../../api';

export const ZaggleConnected = () => {
    const history = useHistory();

    const redirectToManage = () => {
        history.push(routePaths.partnerFlexiBenefits.admin.manage);
    }
    
    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const { data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      });

    const feature = useSelector(loggedInUserSelectors.features)
    const isZaggleOrgAdminEnabled = feature?.enableZaggle ;
    

    return (<div className='md:w-260 mt-20 mx-auto space-y-10'>
        <img className="mx-auto" src={getStaticMediaUrl(zaggleIntegrated)}/>
        <div className='text-center font-bold text-4.5xl text-white-o-80'>
            You’ve successfully integrated with Zaggle flexible benefits
        </div>
        <div className='text-center text-2xl'>
            Employees can opt-in for Zaggle from their XPayroll dashboard.  
        </div>
        {
        data?.isOpted && isZaggleOrgAdminEnabled && (
            <div className="w-full flex justify-center">
                <PrimaryButton 
                    onClick={redirectToManage}
                    className='mt-20'
                    style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}}
                >
                    Manage configuration
                </PrimaryButton>
            </div>)
        }
    </div>)
}