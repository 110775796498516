import React, { ReactNode } from 'react';

import { getStaticMediaUrl } from 'utils/Urls';
import arrowIcon from 'assets/icon--down-arrow.svg';

import styles from './index.module.scss';
import { Reveal } from 'components/ui/Reveal/Reveal';

export interface ExpansionPanelProps {
  panelId: number;
  isOpen: boolean;
  onHeaderClick: (panelId: number) => void;
  header: JSX.Element | string;
  className?: string;
  children: ReactNode;
  scrollable?: boolean;
  disabled?: boolean;
  childrenClassName?: string;
}

const ExpansionPanel = ({
  panelId,
  isOpen,
  onHeaderClick,
  header,
  className,
  children,
  scrollable,
  disabled,
  childrenClassName,
}: ExpansionPanelProps) => {
  const onInteract = () => {
    !disabled && onHeaderClick(panelId);
  };

  return (
    <div className={`${styles['expansion-panel']} ${className}`}>
      <div className="flex justify-between align-center cursor-pointer" onClick={onInteract}>
        <h3 className={styles['expansion-panel-header']}>{header}</h3>
        <img
          alt="Toggle Expansion Panel"
          className={`p-5 transition duration-300 ${isOpen ? 'transform rotate-180 ' : ''}`}
          src={getStaticMediaUrl(arrowIcon)}
          onClick={onInteract}
        />
      </div>
      <Reveal isOpen={isOpen}>
        <div
          className={`${styles['expansion-panel-content']} transition duration-300 ${
            scrollable ? 'overflow-y-auto' : ''
          } 
              ${childrenClassName ? childrenClassName : ''}`}>
          {children}
        </div>
      </Reveal>
    </div>
  );
};

export default ExpansionPanel;
