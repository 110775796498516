import React from 'react';
import api from 'api';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import APIError from 'types/apiError';
import { routePaths } from 'components/Routes/data';
import ErrorMessage from 'components/ui/ErrorMessage';
import Card from '../components/Card';
import Spinner from 'components/ui/Spinner';
import { StandardButton } from 'components/ui/Button';

const ZohoAuthorize = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code') || '';
  const country = urlParams.get('location') || '';
  const state = urlParams.get('state') || '';
  const history = useHistory();

  const authorizeMutation = useMutation(
    ({ code, country, state }: { code: string; country: string; state: string }) =>
      api.zoho.activate(country, code, state),
    {
      onError: (error: APIError) => {},
      onSuccess: () => {
        history.push(routePaths.settings.zoho.config.root);
      },
    },
  );

  React.useEffect(() => {
    if (code) {
      authorizeMutation.mutate({ code, country, state });
    }
  }, [code]);

  if (authorizeMutation.status === 'error') {
    return (
      <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
        <ErrorMessage title="Something is not right!">
          {authorizeMutation.error?.message || 'Something went wrong, please contact support'}
        </ErrorMessage>
        <Link to={routePaths.settings.zoho.introduction}>
          <StandardButton className="mt-12">Go Back</StandardButton>
        </Link>
      </Card>
    );
  }

  return (
    <div className="flex justify-center items-center h-full">
      <Spinner />
    </div>
  );
};

export default ZohoAuthorize;
