import React from 'react';
import { StandardSecondaryButton } from '../../../ui/Button/index';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import downloadIcon from 'assets/bulkUploads/white--download--icon.svg';
import successTick from 'assets/bulkUploads/file--download--success--tick.svg';
import styles from './../../index.module.scss';

const FileDownloadButton = ({
  fileDownloaded,
  setFileDownloaded,
  disabled,
  href,
  buttonText,
  onClick
}: {
  fileDownloaded?: boolean;
  setFileDownloaded?: (fileDownloaded: boolean) => void;
  disabled?: boolean;
  href: string;
  buttonText?: string;
  onClick?: () => void;
}) => {

  function onClickFunction() {

    setFileDownloaded && setFileDownloaded(true);
    onClick && onClick();

  }

  return (
    <>
      <a
        href={href}
        target="_blank"
        onClick={onClickFunction}
        className='w-min'
        style={disabled ? { pointerEvents: 'none' } : {}} rel="noreferrer">
        <StandardSecondaryButton size="sm" disabled={disabled}>
          <div className="flex flex-row justify-center items-center">
            <img className="mr-4" src={getStaticMediaUrl(downloadIcon)} />
            <span className="font-bold capitalize">{buttonText ? buttonText : 'Download Now'}</span>
          </div>
        </StandardSecondaryButton>
      </a>
      {fileDownloaded && (
        <span className={styles['success-text']}>
          <img src={getStaticMediaUrl(successTick)} className={styles['img']} />
          {'File downloaded successfully!'}
        </span>
      )}
    </>
  );
};

export default FileDownloadButton;
