import React, { useEffect } from 'react';
import { Box, StepGroup, StepItem, Text } from '@razorpay/blade/components';
import { useCanViewComplianceActions } from 'components/RunPayroll/ComplianceActions/hooks/useCanViewComplianceActions';
import {
  ComplianceAction,
  useComplianceActionsContext,
} from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';
import {
  actionStatusesMap,
  complianceActionsMap,
  initiatedComplianceActionStatuses,
  stepItemMarkerMap,
} from 'components/RunPayroll/ComplianceActions/data';
import { useGetComplianceActionDetails } from 'components/RunPayroll/ComplianceActions/hooks/useGetComplianceActionDetails';
import { SummaryComponent } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/SummaryComponent';
import { ActionDetailsView } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ActionDetailsView';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import {
  ResetLocalOverridingStyles,
  StyledActionSummaryWrapper,
} from 'components/RunPayroll/ComplianceActions/styles';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { ActionsListLoadingState } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/ActionsListLoadingState';
import { ActionsListErrorState } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/ActionsListErrorState';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';
import { useIsTdsFilingConfigRequired } from 'components/RunPayroll/hooks/useIsTdsFilingConfigRequired';

export const ComplianceActionsList = () => {
  const complianceActionsContext = useComplianceActionsContext();
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth;
  const canViewComplianceActions = useCanViewComplianceActions();
  const userName = useReduxSelector(loggedInUserSelectors.name);

  const {
    actionsApiResponse,
    isRunPayrollActionCompleted,
    status,
    complianceActionsQueryResponse,
  } = useGetComplianceActionDetails();

  const isTdsFilingConfigRequired = useIsTdsFilingConfigRequired();

  const selectedActionName = complianceActionsContext.selectedComplianceAction.actionName;

  const payrollActionItem = actionsApiResponse?.[complianceActionsMap.runPayroll];
  const kaPtActionItem = actionsApiResponse?.[complianceActionsMap.payPt]['Karnataka'];

  const isKaPtActionDisabled = kaPtActionItem
    ? !(
        isRunPayrollActionCompleted ||
        initiatedComplianceActionStatuses.includes(kaPtActionItem.status)
      )
    : true;

  const getPendingActions = () => {
    if (!actionsApiResponse) {
      return 0;
    }

    const actionNames = Object.keys(actionsApiResponse) as ComplianceAction[];

    return actionNames.reduce((pendingActionsCount, actionName) => {
      if (actionName === complianceActionsMap.payPt) {
        const action = actionsApiResponse[actionName];
        const subActionNames = Object.keys(action);
        const pendingSubActions = subActionNames.filter((subActionName) => {
          return (
            action[subActionName].status === actionStatusesMap.notInitiated ||
            action[subActionName].status === actionStatusesMap.failed ||
            action[subActionName].status === actionStatusesMap.returnFailed
          );
        }, 0);
        return pendingActionsCount + pendingSubActions.length;
      }

      const action = actionsApiResponse[actionName];
      if (
        action.status === actionStatusesMap.finalized ||
        action.status === actionStatusesMap.pending ||
        action.status === actionStatusesMap.notInitiated
      ) {
        return pendingActionsCount + 1;
      }

      return pendingActionsCount;
    }, 0);
  };

  const pendingActionsCount = getPendingActions();

  const isPayrollActionBlocked =
    payrollActionItem?.status === actionStatusesMap.finalized && isTdsFilingConfigRequired;

  useEffect(() => {
    if (payrollMonth && selectedActionName !== 'run-payroll') {
      complianceActionsContext.setSelectedComplianceAction({
        actionName: 'run-payroll',
      });
    }
  }, [payrollMonth]);

  //x_tax_TODO: Add a check for payroll paid
  if (!canViewComplianceActions) {
    return null;
  }

  return (
    <ResetLocalOverridingStyles>
      <Box display="flex" flexDirection="column" gap="spacing.3" marginBottom="spacing.8">
        <Text weight="semibold">
          👋 Hi {userName}!{' '}
          {pendingActionsCount ? (
            <Text as="span" color="surface.text.gray.muted" weight="regular">
              Your action items ({pendingActionsCount})
            </Text>
          ) : null}
        </Text>

        <Box display="flex" borderRadius="large" overflow="hidden">
          {status === 'loading' || status === 'idle' ? <ActionsListLoadingState /> : null}

          {status === 'error' ? (
            <ActionsListErrorState onRetry={complianceActionsQueryResponse.refetch} />
          ) : null}

          {status === 'success' && payrollActionItem && kaPtActionItem ? (
            <>
              <Box backgroundColor="surface.background.gray.moderate" paddingX="spacing.6">
                <StepGroup orientation="vertical" size="medium" minWidth="200px">
                  <StepItem
                    stepProgress="full"
                    title="Run Payroll"
                    marker={
                      isPayrollActionBlocked
                        ? stepItemMarkerMap.blocked
                        : stepItemMarkerMap[payrollActionItem.status]
                    }
                    isDisabled={false}
                    isSelected={selectedActionName === complianceActionsMap.runPayroll}
                    onClick={() => {
                      complianceActionsContext.setSelectedComplianceAction({
                        actionName: complianceActionsMap.runPayroll,
                      });
                    }}
                  />

                  <StepItem
                    stepProgress="full"
                    title={kaPtActionItem.meta.display_name}
                    description={
                      kaPtActionItem.meta.due_date &&
                      kaPtActionItem.status !== 'success' &&
                      kaPtActionItem.status !== 'paid_manually'
                        ? `Deadline: ${getFormattedDateValue({
                            date: kaPtActionItem.meta.due_date,
                            formatString: dateFormats.humanDateMonthYear,
                          })}`
                        : undefined
                    }
                    marker={
                      kaPtActionItem.meta.is_over_due
                        ? stepItemMarkerMap.overdue
                        : stepItemMarkerMap[kaPtActionItem.status]
                    }
                    onClick={() => {
                      complianceActionsContext.setSelectedComplianceAction({
                        actionName: complianceActionsMap.payPt,
                      });
                    }}
                    isDisabled={isKaPtActionDisabled}
                    isSelected={selectedActionName === complianceActionsMap.payPt}
                  />
                </StepGroup>
              </Box>
              <StyledActionSummaryWrapper>
                <SummaryComponent />
              </StyledActionSummaryWrapper>
            </>
          ) : null}
        </Box>
      </Box>
      <ActionDetailsView />
    </ResetLocalOverridingStyles>
  );
};
