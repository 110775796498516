import React, { ReactElement } from 'react';
import { Box, BoxProps, Heading, Text } from '@razorpay/blade/components';

const FnfInputBoxWrapper = ({
  children,
  heading,
  ...boxProps
}: { children: ReactElement; heading: string } & BoxProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="spacing.6"
      padding={['28px', 'spacing.8']}
      borderWidth="thin"
      borderRadius={'medium'}
      borderColor='surface.border.gray.subtle'
      backgroundColor="surface.background.gray.moderate"
      zIndex="2"
      {...boxProps}>
      <Box display="flex" flexDirection="column" gap="spacing.4">
        <Text size="large">{heading}</Text>
      </Box>
      <Box display="flex" flexDirection="column" gap="36px">
        {children}
      </Box>
    </Box>
  );
};

export default FnfInputBoxWrapper;
