import { Box, Counter, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface NavItemProps {
  text: ReactNode;
  href: string;
  isActive?: boolean;
  actionableCount?: number;
  isFeatureEnabled?: boolean;
}

const NavItemBox = styled.div<{ isActive?: boolean }>(
  ({ theme, isActive }) => `
    padding: ${theme.spacing[4]}px;
    display: flex;
    align-items: center;
    gap: ${theme.spacing[3]}px;
    flex: 1;
    border-radius: ${theme.border.radius.medium}px;
    position: relative;
    overflow: hidden;
    background-color: ${
      isActive
        ? theme.colors.surface.background.gray.subtle
        : theme.colors.surface.background.gray.subtle
    };
    
    &:hover {
        background-color: ${
          isActive
            ? theme.colors.surface.background.gray.subtle
            : theme.colors.surface.background.gray.intense
        };
    }
`,
);

const NavItem = ({ text, href, isActive, actionableCount }: NavItemProps) => {
  return (
    (<Link to={href}>
      <NavItemBox isActive={isActive}>
        <Text>{text}</Text>

        {actionableCount && <Counter value={actionableCount} color="notice" />}
        {isActive && (
          <Box
            height="28px"
            width="4px"
            backgroundColor="surface.background.primary.intense"
            position="absolute"
            left="-2px"
            top="8px"
            borderRadius="small"
          />
        )}
      </NavItemBox>
    </Link>)
  );
};

export interface SideNavProps {
  navItems: NavItemProps[];
}

const SideNav = ({ navItems }: SideNavProps) => {
  return (
    <Box as="nav" display="flex" flexDirection="column" gap="spacing.1">
      {navItems.map((navItem, index) => (
        navItem.isFeatureEnabled && <NavItem {...navItem} key={index} />
      ))}
    </Box>
  );
};

export { SideNav };
