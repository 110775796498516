import React, { ReactElement, useState } from 'react';
import VarianceReportSchema, { VarianceReportSchemaContract } from 'schemas/VarianceReportSchema';
import { SimpleColorTable, Table, TableLayout } from 'components/ui/Table';

function TableView({ data }: { data: VarianceReportSchemaContract }): ReactElement {
  let months: string[] = [];
  let groupLabels: string[] = [];
  let groupedData: { [key: string]: number[] } = {};

  data.map((item) => {
    months.push(item.payroll_month);

    let itemdata = item.data;
    if (itemdata.length > 0) {
      itemdata.forEach((it) => {
        if (it['label'] && it['value'] !== undefined) {
          if (!groupLabels.includes(it['label'])) {
            groupLabels.push(it['label']);
            groupedData[it['label']] = [];
          }
          groupedData[it['label']].push(it['value']);
        }
      });
    }
  });

  return (
    <SimpleColorTable className="clear-both mt-8 export">
      <tbody>
        <tr>
          <th></th>
          {months.map((month) => (
            <th key={month.toLocaleUpperCase()}>{month}</th>
          ))}
        </tr>
        {Object.keys(groupedData).map((key) => (
          <tr key={key}>
            <td>
              {Object.keys(groupedData).length > 1
                ? key.toLocaleUpperCase()
                : key.charAt(0).toLocaleUpperCase() + key.substr(1).toLocaleLowerCase()}
            </td>
            {groupedData[key].map((item, index) => (
              <td key={index + key + item}>{item.toLocaleString('en-IN')}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </SimpleColorTable>
  );
}

export default TableView;
