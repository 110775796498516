import {
  Box,
  Button,
  CheckCircle2Icon,
  Divider,
  Drawer,
  DrawerBody,
  RefreshIcon,
  StampIcon,
  Text,
} from '@razorpay/blade/components';
import api from 'api';
import { EmptyView } from 'components/SalaryComponents/components/EmptyView';
import { ModalActionButtons } from 'components/SalaryComponents/components/ModalActionButtons';
import {
  DetailsContainer,
  DetailsFooter,
  DetailsHeader,
  DetailsSection,
  DetailsSectionHeading,
  DetailsSectionItem,
  DetailsSectionItemsContainer,
  DetailsViewSkeleton,
} from 'components/SalaryComponents/components/details/DetailsViewHelpers';
import {
  salaryComponentParams,
  salaryComponentsQueries,
} from 'components/SalaryComponents/constants';
import { useDetailsView, useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { ActionModals } from 'components/SalaryComponents/modals';
import { DeductionsComponentType } from 'components/SalaryComponents/types';
import { deductFrom, deductionType } from 'components/WizardViews/SalaryComponents/constants';
import {
  checkIsDeductionTypeAdhoc,
  checkIsDeductionTypeRecurring,
  deductionsExemptionDisplayText,
  isTaxExempt,
} from 'components/WizardViews/SalaryComponents/utils';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export const DeductionsDetailView = () => {
  const { paramId: salaryComponentId, closeDetailsView } = useDetailsView(
    salaryComponentParams.componentId,
  );
  const { openDeductionsEditPage } = useSalaryComponentHelpers();
  const [currentActiveModal, setCurrentActiveModal] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const isOpen = !!salaryComponentId;

  const openModal = (modal: string) => {
    setCurrentActiveModal(modal);
  };

  const closeModal = () => {
    setCurrentActiveModal(null);
  };

  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery],
    queryFn: () =>
      api.salaryComponents.queries.getSalaryComponent<DeductionsComponentType>({
        id: salaryComponentId!,
        category: 'DEDUCTIONS_CATEGORY',
      }),
    enabled: isOpen,
    select(data) {
      return data.payrollGetSalaryComponent.data.salaryComponent;
    },
    onError(error) {
      console.log(error);
    },
    cacheTime: 0,
  });

  const onDismiss = () => {
    queryClient.invalidateQueries({
      queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery, salaryComponentId],
    });
    closeModal();
    closeDetailsView();
  };

  const componentData = data as DeductionsComponentType;

  const { taxBehaviour, arrearBehaviour, prorationBehaviour } =
    componentData?.settings?.behaviour || {};

  const deductionDetails = componentData?.settings.deductionDetails;
  const isAdhoc = checkIsDeductionTypeAdhoc(deductionDetails?.deductionType);
  const isRecurring = checkIsDeductionTypeRecurring(deductionDetails?.deductionType);

  const taxExempt = isTaxExempt(
    taxBehaviour?.taxExemptInNewRegime,
    taxBehaviour?.taxExemptInOldRegime,
  );

  return (
    <>
      <Drawer isOpen={isOpen} onDismiss={onDismiss} zIndex={900}>
        <DrawerBody>
          <DetailsContainer onDismiss={onDismiss}>
            {isLoading && <DetailsViewSkeleton onDismiss={onDismiss} />}
            {isError && (
              <EmptyView
                description={
                  <Text color={'surface.text.gray.muted'} weight={'medium'} textAlign={'center'}>
                    Due to an unknown error, we could not load the component details page.
                  </Text>
                }>
                <Box display={'flex'} justifyContent={'center'}>
                  <Box width={'104px'}>
                    <Button icon={RefreshIcon} size={'small'} onClick={() => refetch()}>
                      Retry
                    </Button>
                  </Box>
                </Box>
              </EmptyView>
            )}
            {componentData ? (
              <>
                <DetailsHeader
                  heading={componentData.name}
                  description={componentData.description || ''}
                  isActive={Boolean(componentData.isActive)}
                  isPredefined={Boolean(componentData.isPredefined)}
                />

                <Divider marginY={'spacing.7'} />

                {/* Basic and Pay section */}
                <DetailsSection>
                  <DetailsSectionHeading
                    icon={<CheckCircle2Icon size={'large'} color={'surface.icon.gray.subtle'} />}
                    label="Basic and Pay"
                  />
                  <DetailsSectionItemsContainer>
                    <DetailsSectionItem label="Component Name" value={componentData.name || ''} />
                    <DetailsSectionItem
                      label="Display Name"
                      value={componentData.settings.behaviour.displayBehaviour?.displayName || ''}
                    />
                    <DetailsSectionItem
                      label="Deduction Type"
                      value={
                        deductionDetails ? deductionType[deductionDetails.deductionType].label : '-'
                      }
                    />
                  </DetailsSectionItemsContainer>
                </DetailsSection>

                <Divider marginY={'spacing.7'} />

                {/* Taxation section */}
                <DetailsSection>
                  <DetailsSectionHeading
                    icon={<StampIcon size={'large'} color={'surface.icon.gray.subtle'} />}
                    label="Pay and Taxability"
                  />
                  <DetailsSectionItemsContainer>
                    {isAdhoc && (
                      <DetailsSectionItem
                        label="Deduct From"
                        value={deductFrom[deductionDetails?.deductFrom].label}
                      />
                    )}
                    {isRecurring && (
                      <>
                        <DetailsSectionItem
                          label="Proration"
                          value={
                            prorationBehaviour
                              ? prorationBehaviour?.supportProration
                                ? 'Enabled'
                                : 'Disabled'
                              : '-'
                          }
                        />
                        {/* commenting this out till we enable arrears */}
                        {/* <DetailsSectionItem
                          label="Arrear Calculation"
                          value={
                            arrearBehaviour
                              ? arrearBehaviour?.considerInArrear
                                ? 'Enabled'
                                : 'Disabled'
                              : '-'
                          }
                        /> */}
                      </>
                    )}
                    <DetailsSectionItem
                      label="Tax Exempt - Old Regime"
                      value={Boolean(taxBehaviour?.taxExemptInOldRegime) ? 'Yes' : 'No'}
                    />
                    <DetailsSectionItem
                      label="Tax Exempt - New Regime"
                      value={Boolean(taxBehaviour?.taxExemptInNewRegime) ? 'Yes' : 'No'}
                    />
                    {taxExempt && (
                      <DetailsSectionItem
                        label="Exemption under"
                        value={
                          deductionsExemptionDisplayText(taxBehaviour?.taxExemptionSection) ?? 'N/A'
                        }
                      />
                    )}
                  </DetailsSectionItemsContainer>
                </DetailsSection>

                <Divider marginY={'spacing.7'} />

                {/* Actions */}
                <DetailsFooter isSticky={true}>
                  <ModalActionButtons
                    isActive={Boolean(componentData.isActive)}
                    showEnableDisableButton={!Boolean(componentData.isPredefined)}
                    openModal={openModal}
                    onEditClick={() => openDeductionsEditPage(salaryComponentId!)}
                  />
                </DetailsFooter>
              </>
            ) : null}
          </DetailsContainer>
        </DrawerBody>
      </Drawer>
      <ActionModals
        salaryComponent={componentData}
        closeModal={closeModal}
        currentActiveModal={currentActiveModal}
      />
    </>
  );
};

export default DeductionsDetailView;
