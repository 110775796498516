import React, { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import reportConstants from '../../constants';
import { Option, Select } from 'components/ui/Form/Select';
import { StandardButton } from 'components/ui/Button';
import DownloadButton from '../Download';
import ViewOptions from '../ViewOptions';

interface Props {}

function FormElements({}: Props): ReactElement {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [attribute, setAttribute] = useState(
    params.get('attribute') || reportConstants.ATTRIBUTE_SALARY,
  );
  const [timeline, setTimeline] = useState(
    params.get('timeline') || reportConstants.TIMELINE_CURRENT_YEAR,
  );
  const [view, setView] = useState(params.get('view') || 'chart');

  const updateURLParams = (params: { view?: string }) => {
    const paramsToUpdate = new URLSearchParams();
    paramsToUpdate.set('attribute', attribute);
    paramsToUpdate.set('timeline', timeline);
    paramsToUpdate.set('view', params.view || view);
    history.replace({
      pathname: location.pathname,
      search: '?' + paramsToUpdate.toString(),
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    updateURLParams({});
  };

  const handleViewChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    // add timeline to url
    let selectedView = e.target.value;
    setView(selectedView);
    updateURLParams({ view: selectedView });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-16 w-3/5">
        <div className="flex items-end">
          <label htmlFor="attribute" className="w-4/12 flex-auto pr-10 m-0">
            Select Attribute
            <Select
              className="m-0"
              id="attribute"
              name="attribute"
              value={attribute}
              onChange={(e) => {
                setAttribute(e.target.value);
              }}
            >
              <Option value={reportConstants.ATTRIBUTE_SALARY}>Salary Disbursed</Option>
              <Option value={reportConstants.ATTRIBUTE_COUNT}>Employee Count</Option>
              <Option value={reportConstants.ATTRIBUTE_COMPLIANCE}>TDS/PF/PT/ESI</Option>
            </Select>
          </label>
          <label htmlFor="timeline " className="w-4/12 flex-auto pr-10 m-0">
            Select Timeline
            <Select
              className="m-0"
              id="timeline"
              name="timeline"
              value={timeline}
              onChange={(e) => {
                setTimeline(e.target.value);
              }}
            >
              <Option value={reportConstants.TIMELINE_CURRENT_YEAR}>Current Financial Year</Option>
              <Option value={reportConstants.TIMELINE_CURRENT_QUARTER}>Current Quarter</Option>
              <Option value={reportConstants.TIMELINE_LAST_QUARTER}>Last Quarter</Option>
              <Option value={reportConstants.TIMELINE_3_MONTHS}>Last 3 months</Option>
              <Option value={reportConstants.TIMELINE_6_MONTHS}>Last 6 months</Option>
              <Option value={reportConstants.TIMELINE_12_MONTHS}>Last 12 months</Option>
              <Option value={reportConstants.TIMELINE_FY_2023}>FY-2023</Option>
              <Option value={reportConstants.TIMELINE_FY_2022}>FY-2022</Option>
              <Option value={reportConstants.TIMELINE_FY_2021}>FY-2021</Option>
              <Option value={reportConstants.TIMELINE_FY_2020}>FY-2020</Option>
              <Option value={reportConstants.TIMELINE_FY_2019}>FY-2019</Option>
            </Select>
          </label>
          <StandardButton type="submit">
            Generate Report
          </StandardButton>
        </div>
      </form>

      <div className="flex float-right mb-10">
        {view === 'table' && <DownloadButton attributeName={attribute} />}
        <ViewOptions handleViewChange={handleViewChange} selectedView={view} />
      </div>
    </>
  );
}

export default FormElements;
