import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CalendarIcon,
  EditComposeIcon,
  IconButton,
  LockIcon,
  Heading,
} from '@razorpay/blade/components';
import { Box, Text, Button, Alert, Link } from '@razorpay/blade/components';
import FnfDismissalInformation from './FnfDismissalInformation';
import FnfLeaveEncashment from './FnfLeaveEncashment';
import FnfAdditions from './FnfAdditions';
import FnfLossOfPay from './FnfLossOfPay';
import FnfEmailAddress from './FnfEmailAddress';
import FnfDeductions from './FnfDeductions';
import FnfGratuity from './FnfGratuity';
import format from 'date-fns/format';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { routePaths } from 'components/Routes/data';
import { dateFormats } from 'utils/Dates';
import { isValidLop, isNotEmptyElement } from '../utils';
import { FnfUpdateResponseSchemaContract } from 'schemas/FnfUpdateResponseSchema';
import { FnfMessage } from './types';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

type FnfInputProps = {
  setFnfData: (data: GetFnfResponseSchemaContract) => void;
  fnfData: GetFnfResponseSchemaContract | null;
  setDismissalDateInput: (date: Date) => void;
  dismissalDateInput: Date;
  peopleId: string;
  handleProcessFnf: () => void;
  updateErrorString: string | null;
  showSuccessBanner: boolean;
  isFnfDataLoading: boolean;
  isFnfUpdating: boolean;
  fnfUpdateResponse: FnfUpdateResponseSchemaContract | null;
  isHoldSalaryPayComplianceEnabled: boolean;
  isFnfProcessed: boolean;
};
const FnfInputs = ({
  fnfData,
  setFnfData,
  setDismissalDateInput,
  dismissalDateInput,
  peopleId,
  handleProcessFnf,
  updateErrorString,
  showSuccessBanner,
  isFnfDataLoading,
  isFnfUpdating,
  fnfUpdateResponse,
  isHoldSalaryPayComplianceEnabled,
  isFnfProcessed,
}: FnfInputProps) => {
  const formattedDismissalDate = format(new Date(dismissalDateInput), dateFormats.dateMonthYear);
  const history = useHistory();

  const onChangeDismissalDate = (dismissalDate: Date | null) => {
    setDismissalDateInput(dismissalDate ?? new Date());
  };

  const onChangeReason = (reason: string) => {
    if (fnfData) {
      setFnfData({
        ...fnfData,
        reason_for_leaving: reason,
      });
    }
  };

  const onChangeGratuity = (gratuityAmount: number) => {
    if (fnfData && fnfData.gratuity) {
      setFnfData({
        ...fnfData,
        gratuity: {
          ...fnfData.gratuity,
          gratuity_amount: gratuityAmount,
        },
      });
    }
  };

  const onChangeLeaveEncashment = (numberOfLeaves: string | null, amount: number | null) => {
    if (fnfData && fnfData.leave_encashments) {
      setFnfData({
        ...fnfData,
        leave_encashments: {
          ...fnfData.leave_encashments,
          leave_days_encashed: numberOfLeaves,
          total_encashment: amount,
        },
      });
    }
  };

  const onChangeEmail = (email: string) => {
    if (fnfData) {
      setFnfData({
        ...fnfData,
        personal_email_address: email,
      });
    }
  };

  const onChangeAdditions = (additionValue: GetFnfResponseSchemaContract['additions']) => {
    if (fnfData) {
      setFnfData({
        ...fnfData,
        additions: additionValue,
      });
    }
  };

  const onChangeLossOfPayDays = (lossOfPayDays: string | undefined) => {
    if (!isValidLop(lossOfPayDays, dismissalDateInput)) {
      return;
    }

    if (fnfData && fnfData.deductions && fnfData.deductions.lop_deduction) {
      setFnfData({
        ...fnfData,
        deductions: {
          ...fnfData.deductions,
          lop_deduction: {
            ...fnfData.deductions.lop_deduction,
            lop_days: lossOfPayDays ?? '0',
          },
        },
      });
    }
  };

  const onChangeNonLopDeductions = (
    deductionValue: GetFnfResponseSchemaContract['deductions']['non_lop_deductions'],
  ) => {
    if (fnfData) {
      setFnfData({
        ...fnfData,
        deductions: {
          ...fnfData.deductions,
          non_lop_deductions: deductionValue,
        },
      });
    }
  };

  const isPayrollFinalized = fnfData?.payroll_status.is_finalized ?? false;
  const isPayrollBlocked = fnfData?.payroll_status.is_paid ?? false;
  const isFnfProcessingAllowed = !isPayrollFinalized || (isPayrollFinalized && isPayrollBlocked);

  let successBannerDescription = <></>;
  if (fnfUpdateResponse?.message_type === FnfMessage.UNFINAL_AND_UNBLOCKED) {
    const payslipLink = fnfUpdateResponse.payslip_link;
    const monthToShow = format(new Date(fnfUpdateResponse.payroll_month), dateFormats.monthYear);
    successBannerDescription = (
      <Text>
        Payroll updated for {monthToShow}. Please click{' '}
        <Link href={payslipLink} target="_blank">
          here
        </Link>{' '}
        to see the payslip preview.
      </Text>
    );
  } else if (fnfUpdateResponse?.message_type === FnfMessage.FINAL_AND_BLOCKED) {
    const payslipLink = fnfUpdateResponse.payslip_link;
    const runPayrollLink = fnfUpdateResponse.runpayroll_link;
    successBannerDescription = (
      <Text>
        User has been dismissed. As{' '}
        <Link href={runPayrollLink} target="_blank">
          payroll
        </Link>{' '}
        for this month was already either finalised or executed, hence old payslip has been
        converted into full and final settlement{' '}
        <Link href={payslipLink} target="_blank">
          payslip.
        </Link>
      </Text>
    );
  }

  let bonusBanner = <></>;
  if (fnfData && fnfData.show_bonus_banner) {
    const bonusLink = `${routePaths.bonus.view}?userId=${peopleId}`;
    const bonusMessage = (
      <Text>
        Bonus Recovery is added as deduction since clawback rule is applicable.{' '}
        <Link href={bonusLink} target="_blank">
          View bonus details
        </Link>
      </Text>
    );

    bonusBanner = <Alert color="information" isDismissible={false} description={bonusMessage} />;
  }

  const handleEditLastWorkingDayClick = () => {
    history.push({ pathname: routePaths.fnf.initiate, search: `userId=${peopleId}` });
  };

  const showLastWorkingDayInfo = !!dismissalDateInput && isHoldSalaryPayComplianceEnabled;
  const isNetPayOnHold = Boolean(fnfData?.net_pay_on_hold_from);

  const isAlertContainerVisisble =
    (showSuccessBanner && isNotEmptyElement(successBannerDescription)) || updateErrorString;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding="auto"
        width="600px"
        marginBottom="100px"
        height="100%">
        <Heading marginTop="80px" size="large">
          ✍ Full & final settlement for {fnfData?.employee_name ?? 'Unknown'}
        </Heading>

        {showLastWorkingDayInfo && (
          <Box display="flex" alignItems="center" marginTop={'36px'}>
            <Box display="flex" gap="spacing.3" alignItems="flex-end">
              <Box display="flex" gap="spacing.2" alignItems="center">
                <CalendarIcon size="small" color="interactive.icon.gray.normal" />
                <Text size="small" color="surface.text.gray.muted">
                  Last working day:
                </Text>
              </Box>
              <Text weight="semibold" size="small">
                {formattedDismissalDate}
              </Text>
            </Box>
            {isHoldSalaryPayComplianceEnabled && (
              <Box marginX="spacing.4" display="flex" gap="spacing.3" alignItems="flex-end">
                <Box display="flex" gap="spacing.2" alignItems="center">
                  <LockIcon size="small" color="interactive.icon.gray.normal" />
                  <Text size="small" color="surface.text.gray.muted">
                    Net pay on Hold:
                  </Text>
                </Box>
                <Text weight="semibold" size="small">
                  {isNetPayOnHold ? 'Yes' : 'No'}
                </Text>
              </Box>
            )}
            <IconButton
              icon={EditComposeIcon}
              accessibilityLabel="Edit Last Working Day"
              onClick={handleEditLastWorkingDayClick}
              size="medium"
            />
          </Box>
        )}

        {isAlertContainerVisisble && (
          <Box display="flex" flexDirection="column" marginTop="36px" gap="20px">
            {showSuccessBanner && isNotEmptyElement(successBannerDescription) && (
              <Alert
                color="positive"
                title="Fnf added successfully"
                description={successBannerDescription}
                isDismissible={false}
              />
            )}
            {updateErrorString && (
              <Alert
                color="negative"
                title="Fnf settlement failed"
                description={updateErrorString}
                isDismissible={false}
              />
            )}
          </Box>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleProcessFnf();
          }}>
          <Box display="flex" flexDirection="column" gap="36px" marginTop="36px">
            {!isHoldSalaryPayComplianceEnabled && (
              <FnfDismissalInformation
                dismissalDateInput={dismissalDateInput}
                reason={fnfData?.reason_for_leaving ?? ''}
                onChangeDismissalDate={onChangeDismissalDate}
                onChangeReason={onChangeReason}
                payrollStatus={fnfData?.payroll_status ?? null}
                isDismissalSyncedByHRMS={fnfData?.is_dismissal_synced_by_hrms ?? false}
              />
            )}

            {(!isPayrollFinalized || (isHoldSalaryPayComplianceEnabled && isFnfProcessed)) && (
              <>
                {fnfData?.gratuity?.gratuity_applicable && (
                  <IsFeatureDisabledForRearch feature="isGratuityDisabled">
                    <FnfGratuity
                      onChangeGratuity={onChangeGratuity}
                      gratuityAmount={fnfData?.gratuity?.gratuity_amount}
                      isFnfProcessed={isFnfProcessed}
                      isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                    />
                  </IsFeatureDisabledForRearch>
                )}

                <IsFeatureDisabledForRearch feature="isLeaveEncashmentDisabled">
                  <FnfLeaveEncashment
                    onChangeLeaveEncashment={onChangeLeaveEncashment}
                    numberOfLeaves={fnfData?.leave_encashments?.leave_days_encashed ?? null}
                    leaveEncashmentAmount={fnfData?.leave_encashments?.total_encashment ?? null}
                    isFnfProcessed={isFnfProcessed}
                    isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                  />
                </IsFeatureDisabledForRearch>

                <IsFeatureDisabledForRearch feature="isAdditionsDisabled">
                  <FnfAdditions
                    additions={fnfData?.additions ?? null}
                    onChangeAdditions={onChangeAdditions}
                    isFnfProcessed={isFnfProcessed}
                    isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                  />
                </IsFeatureDisabledForRearch>

                <IsFeatureDisabledForRearch feature="isLOPDisabled">
                  <FnfLossOfPay
                    onChangeLossOfPayDays={onChangeLossOfPayDays}
                    lopDeduction={fnfData?.deductions?.lop_deduction ?? null}
                    payrollId={fnfData?.payroll_id ?? null}
                    isFnfProcessed={isFnfProcessed}
                    isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                  />
                </IsFeatureDisabledForRearch>

                <IsFeatureDisabledForRearch feature="isDeductionsDisabled">
                  <FnfDeductions
                    nonLopDeductions={fnfData?.deductions?.non_lop_deductions ?? null}
                    onChangeNonLopDeductions={onChangeNonLopDeductions}
                    isFnfProcessed={isFnfProcessed}
                    isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                  />
                </IsFeatureDisabledForRearch>

                {bonusBanner}
              </>
            )}

            {isFnfProcessingAllowed && (
              <FnfEmailAddress
                onChangeEmail={onChangeEmail}
                email={fnfData?.personal_email_address ?? ''}
              />
            )}
          </Box>

          {isFnfProcessingAllowed && (
            <Box marginTop="60px">
              <Button
                variant="primary"
                type="submit"
                isDisabled={isFnfDataLoading}
                isLoading={isFnfUpdating}>
                {isHoldSalaryPayComplianceEnabled ? 'Add to payment' : 'Process settlement'}
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </>
  );
};

export default FnfInputs;
