import React from "react";

export const HeadingsAndSubHeadingsSection = () => {
    return <div className="space-y-5">
        <div className="text-center font-bold text-4.5xl text-white">
            Digitize Employee Benefits with <span className="font-bold text-zaggle-orange">Zaggle</span>
        </div>
        <div className="text-center text-2xl">
            Help employees save tax with Zaggle’s flexible employee benefit plans. Digitize your employee tax benefits program with a single card.
        </div>
    </div>;
}