import { useLocation } from 'react-router-dom';
import { useKnitHrmsApp } from './useKnitHrmsApp';

const useIntegrationProgress = () => {
  const { hrmsAppRoutePaths } = useKnitHrmsApp();
  const location = useLocation<{
    totalSteps: number;
    currentStep: number;
  }>();
  const pathname = location.pathname;
  const locationState = location.state;
  const totalSteps = locationState?.totalSteps ?? 6;
  let currentStep = locationState?.currentStep;

  if (!currentStep) {
    switch (pathname) {
      case hrmsAppRoutePaths.integration:
        currentStep = 1;
        break;
      case hrmsAppRoutePaths.settings.entitySelection:
        currentStep = 2;
        break;
      case hrmsAppRoutePaths.settings.fields:
        currentStep = 3;
        break;
      case hrmsAppRoutePaths.settings.employeeContractorMapping:
        currentStep = 4;
        break;
      case hrmsAppRoutePaths.settings.syncConfirmation:
        currentStep = 5;
        break;
      case hrmsAppRoutePaths.settings.syncInProgress:
        currentStep = 6;
        break;
    }
  }

  const integrationProgress = (currentStep / totalSteps) * 100;

  return {
    integrationProgress,
    totalSteps,
    currentStep,
  };
};

export { useIntegrationProgress };
