import api from 'api';
import { useQuery, useQueryClient } from 'react-query';
import { GetKnitHrmsIntegrationSchemaContract } from 'schemas/GetKnitHrmsIntegrationSchema';
import { GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, KNIT_HRMS_QUERY_RETRY_LIMIT } from './constants';
import { HrmsSyncStatus } from './types';
import { useState } from 'react';

interface UseGetKnitHrmsIntegrationQueryOpts {
  onSuccess: (data: GetKnitHrmsIntegrationSchemaContract) => Promise<unknown> | void;
}

const useGetKnitHrmsIntegrationQuery = (
  knitHrmsApp: string,
  opts?: UseGetKnitHrmsIntegrationQueryOpts,
) => {
  const queryClient = useQueryClient();
  const [retryAttempts, setRetryAttempts] = useState(0);
  const knitHrmsIntegrationQuery = useQuery({
    queryFn: () => api.knit.getHrmsIntegration(knitHrmsApp),
    queryKey: [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
    staleTime: 300000, // setting 5 minute stale time as this data won't change frequently
    onSuccess: (data) => {
      opts?.onSuccess(data);
    },
    onError: () => {
      setRetryAttempts((prev) => prev + 1);
    },
  });

  const isAuthenticationComplete = knitHrmsIntegrationQuery.data?.enabled;
  const isIntegrationActive =
    knitHrmsIntegrationQuery.data?.enabled &&
    knitHrmsIntegrationQuery.data.is_user_data_sync_enabled;
  const isSyncInProgress =
    (knitHrmsIntegrationQuery.data?.user_data_sync_status as HrmsSyncStatus) ===
      'STATUS_SYNC_IN_PROGRESS' ||
    (knitHrmsIntegrationQuery.data?.user_data_sync_status as HrmsSyncStatus) ===
      'STATUS_SYNC_INITIATED';
  const isSyncFailed =
    (knitHrmsIntegrationQuery.data?.user_data_sync_status as HrmsSyncStatus) ===
    'STATUS_SYNC_FAILURE';

  const retryLimitExhausted = retryAttempts > KNIT_HRMS_QUERY_RETRY_LIMIT;

  const updateEmployeeTypes = (
    employeeTypes: GetKnitHrmsIntegrationSchemaContract['employee_types_full_time'],
  ) => {
    const oldData = queryClient.getQueryData<GetKnitHrmsIntegrationSchemaContract>([
      GET_HRMS_INTEGRATION_KNIT_QUERY_KEY,
      knitHrmsApp,
    ]);
    if (!oldData) {
      return;
    }

    const newData: GetKnitHrmsIntegrationSchemaContract = {
      ...oldData,
      employee_types_full_time: employeeTypes,
    };

    queryClient.setQueryData<GetKnitHrmsIntegrationSchemaContract>(
      [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
      newData,
    );
  };

  const updateContractorTypes = (
    contractorTypes: GetKnitHrmsIntegrationSchemaContract['employee_types_contractor'],
  ) => {
    const oldData = queryClient.getQueryData<GetKnitHrmsIntegrationSchemaContract>([
      GET_HRMS_INTEGRATION_KNIT_QUERY_KEY,
      knitHrmsApp,
    ]);
    if (!oldData) {
      return;
    }

    const newData: GetKnitHrmsIntegrationSchemaContract = {
      ...oldData,
      employee_types_contractor: contractorTypes,
    };

    queryClient.setQueryData<GetKnitHrmsIntegrationSchemaContract>(
      [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
      newData,
    );
  };

  const updateSyncedFields = (
    syncedFields: GetKnitHrmsIntegrationSchemaContract['synced_fields'],
  ) => {
    const oldData = queryClient.getQueryData<GetKnitHrmsIntegrationSchemaContract>([
      GET_HRMS_INTEGRATION_KNIT_QUERY_KEY,
      knitHrmsApp,
    ]);
    if (!oldData) {
      return;
    }

    const newData: GetKnitHrmsIntegrationSchemaContract = {
      ...oldData,
      synced_fields: syncedFields,
    };

    queryClient.setQueryData<GetKnitHrmsIntegrationSchemaContract>(
      [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
      newData,
    );
  };

  const updateEntityName = (entityName: GetKnitHrmsIntegrationSchemaContract['entity_name']) => {
    const oldData = queryClient.getQueryData<GetKnitHrmsIntegrationSchemaContract>([
      GET_HRMS_INTEGRATION_KNIT_QUERY_KEY,
      knitHrmsApp,
    ]);
    if (!oldData) {
      return;
    }

    const newData: GetKnitHrmsIntegrationSchemaContract = {
      ...oldData,
      entity_name: entityName,
    };

    queryClient.setQueryData<GetKnitHrmsIntegrationSchemaContract>(
      [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
      newData,
    );
  };

  const invalidateKnitHrmsIntegrationQueryCache = async () => {
    await queryClient.invalidateQueries({
      queryKey: [GET_HRMS_INTEGRATION_KNIT_QUERY_KEY, knitHrmsApp],
    });
  };

  return {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    isSyncFailed,
    retryLimitExhausted,
    updateEmployeeTypes,
    updateContractorTypes,
    updateSyncedFields,
    updateEntityName,
    invalidateKnitHrmsIntegrationQueryCache,
  };
};

export { useGetKnitHrmsIntegrationQuery };
