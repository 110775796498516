import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import AccessDenied from 'components/AccessDenied';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useReduxSelector from '../../utils/useReduxSelector';
import { fetchLoggedInUser } from '../../reducers/loggedInUser';
import { fetchSidebar } from '../../reducers/sidebar';
import Spinner from '../ui/Spinner';
import { RearchFeatureNames } from 'access/types';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { FeatureFlags } from 'types';

interface RestrictedRouteProps extends RouteProps {
  allowedPermissions?: string[];
  allowedRoles?: number[];
  allowedFeatures?: FeatureFlags[];
  allowedSpecialPlatformPermissions?: string[];
  disabledFeaturesForRearch?: RearchFeatureNames[];
}

export const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  component,
  allowedPermissions = [],
  allowedRoles = [],
  allowedFeatures = [],
  allowedSpecialPlatformPermissions = [],
  disabledFeaturesForRearch = [],
  ...rest
}) => {
  const dispatch = useDispatch();
  let loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const sidebar = useReduxSelector((state) => state.sidebar);

  useEffect(() => {
    if (!loggedInUser.isFetching && loggedInUser.data === null) {
      dispatch(fetchLoggedInUser());
    }

    if (
      !sidebar.isFetching &&
      (sidebar.data === null || (Array.isArray(sidebar.data) && sidebar.data.length === 0))
    ) {
      dispatch(fetchSidebar());
    }
  }, []);

  const permissions = useSelector(loggedInUserSelectors.permissions);
  const userRole = useSelector(loggedInUserSelectors.role);
  const features = useSelector(loggedInUserSelectors.features);
  const specialPlatformPermissions = useSelector(loggedInUserSelectors.specialPlatformPermissions);
  const isFeatureDisabledForRearch = useIsFeatureDisabledForRearch();

  let isAllowed = true;

  if (allowedRoles.length) {
    // Custom role is not yet supported here
    isAllowed = isAllowed && allowedRoles.some((role) => role === userRole);
  }

  if (allowedPermissions.length) {
    isAllowed =
      isAllowed && allowedPermissions.some((permission) => permissions.includes(permission));
  }

  if (allowedFeatures.length) {
    isAllowed = isAllowed && allowedFeatures.some((feature) => features?.[feature]);
  }

  allowedSpecialPlatformPermissions = allowedSpecialPlatformPermissions.concat([
    'PLATFORM_PERMISSION_SWITCH_USER',
  ]);
  isAllowed =
    isAllowed ||
    allowedSpecialPlatformPermissions.some((specialPermission: string) =>
      specialPlatformPermissions.includes(specialPermission),
    );

  // Moving this check to the end to block super admin from accessing the page
  if (disabledFeaturesForRearch.length) {
    isAllowed =
      isAllowed &&
      !disabledFeaturesForRearch.some((feature) => isFeatureDisabledForRearch[feature]);
  }

  if (loggedInUser.data === null) {
    return (
      <Route
        {...rest}
        render={() => (
          <div className="flex justify-center items-center h-screen">
            <Spinner />
          </div>
        )}
      />
    );
  }

  if (isAllowed) {
    return <Route {...rest} component={component} />;
  } else {
    return <Route {...rest} component={AccessDenied} />;
  }
};

export default RestrictedRoute;
