import userRoles from 'constants/userRoles';
import { useEffect } from 'react';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import _refiner from 'refiner-js';
import { isProduction } from 'utils/getEnvironment';
import useReduxSelector from 'utils/useReduxSelector';

const useRefiner = () => {
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const user = useReduxSelector(loggedInUserSelectors.employeeDetails);
  const isOrgActive = Boolean(org?.isActive);

  useEffect(() => {
    if (!org || !isOrgActive || !user || !isProduction()) return;

    const isAdmin = user?.userRoleId === userRoles.ADMIN;

    if (!isAdmin) return;

    _refiner('setProject', '0e9e32d0-4e60-11ef-a4b1-61176804fd1c');
    const orgId = org?.id;
    const userOrgId = user?.id;

    _refiner('identifyUser', {
      id: userOrgId,
      userOrgId: userOrgId,
      orgId: orgId,
      isAdmin: isAdmin,
    });
  }, [org, isOrgActive, user]);

  return null;
};

export default useRefiner;
