import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameDay,
} from 'date-fns';
import {
  Box,
  Text,
  Button,
  ArrowLeftIcon,
  ArrowRightIcon,
  Dropdown,
  DropdownOverlay,
  SelectInput,
  ActionList,
  ActionListItem,
  Tooltip,
  Spinner,
  Alert,
} from '@razorpay/blade/components';
import { useAttendanceData } from 'components/Attendance/hooks/useAttendanceData';
import {
  preprocessAttendanceRecords,
  getAttendanceStatus,
  calendarStatusToColor,
  CALENDAR_STATUS,
  monthNameToNumber,
  getMonthNames,
  getYearRange,
  DAYS_OF_THE_WEEK,
} from './utils';
import { makeBorderSize, makeSpace } from '@razorpay/blade/utils';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

interface CalendarProps {
  highlightedDates?: Date[];
  onDateClick?: (date: Date) => void;
}

const Day = styled.div<{ status: number; isCurrentDate: boolean }>(
  ({ theme, status, isCurrentDate }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing[4]}px;
  outline: ${
    isCurrentDate
      ? `${makeBorderSize(theme.border.width.thinner)} solid 
    ${theme.colors.interactive.border.staticWhite.highlighted}`
      : 'none'
  }
`,
);

export const AttendanceCalendar: React.FC<CalendarProps> = () => {
  const history = useHistory();
  const location = useLocation();

  const updateMonth = (newDate: Date) => {
    const newMonthParam = getFormattedDateValue({
      date: newDate,
      formatString: dateFormats.yearMonth,
    });
    const currentParams = new URLSearchParams(location.search);
    currentParams.set('month', newMonthParam);
    history.push({
      pathname: location.pathname,
      search: `?${currentParams.toString()}`,
    });
  };

  const { data, isLoading, isRefetching, error, currentDate, refetch } = useAttendanceData({
    select: preprocessAttendanceRecords,
  });

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const calendarDays: Date[] = [];
  let day = startDate;

  while (day <= endDate) {
    calendarDays.push(day);
    day = addDays(day, 1);
  }

  const handlePrevMonth = () => updateMonth(subMonths(currentDate, 1));
  const handleNextMonth = () => updateMonth(addMonths(currentDate, 1));
  const handleJumpToToday = () => updateMonth(new Date());

  return (
    <Box>
      {error && (
        <Box marginBottom="spacing.4">
          <Alert
            isFullWidth
            isDismissible={false}
            color="negative"
            title="An error occured while fetching your attendance data"
            description={errorParser(getAppErrorDetails(error))[0]}
            actions={{
              primary: {
                text: 'Retry',
                onClick: () => {
                  refetch();
                },
              },
            }}
          />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', m: 'center' }}
        marginBottom="spacing.4"
        gap={{ base: 'spacing.4', m: 'spacing.0' }}
        flexDirection={{ base: 'column', m: 'row' }}>
        <Box display="flex" gap="spacing.4">
          <Button
            accessibilityLabel="Previous Month"
            variant="tertiary"
            onClick={handlePrevMonth}
            icon={ArrowLeftIcon}
            isDisabled={isRefetching}
          />
          <Button
            accessibilityLabel="Next Month"
            variant="tertiary"
            onClick={handleNextMonth}
            icon={ArrowRightIcon}
            isDisabled={isRefetching}></Button>
          <Box display="flex" gap="spacing.4" alignItems="center">
            <Box minWidth={makeSpace(125)}>
              <Dropdown selectionType="single">
                <SelectInput
                  label=""
                  labelPosition="inside-input"
                  accessibilityLabel="Month"
                  placeholder="Month"
                  name="month"
                  value={getFormattedDateValue({
                    date: currentDate,
                    formatString: dateFormats.longMonth,
                  })}
                  onChange={({ name, values }) => {
                    const newDate = new Date(currentDate);
                    newDate.setMonth(monthNameToNumber(values[0]));
                    updateMonth(newDate);
                  }}
                />
                <DropdownOverlay>
                  <ActionList>
                    {getMonthNames().map((month) => (
                      <ActionListItem key={month} title={month} value={month} />
                    ))}
                  </ActionList>
                </DropdownOverlay>
              </Dropdown>
            </Box>
            <Dropdown selectionType="single">
              <SelectInput
                label=""
                accessibilityLabel="Year"
                labelPosition="inside-input"
                placeholder="Year"
                name="year"
                value={getFormattedDateValue({
                  date: currentDate,
                  formatString: dateFormats.fullYear,
                })}
                onChange={({ name, values }) => {
                  const newDate = new Date(currentDate);
                  newDate.setFullYear(parseInt(values[0]));
                  updateMonth(newDate);
                }}
              />
              <DropdownOverlay>
                <ActionList>
                  {getYearRange().map((year) => (
                    <ActionListItem key={year} title={year} value={year} />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
            <Box
              width="24px"
              height="24px"
              display="flex"
              justifyContent="center"
              alignItems="center">
              {(isLoading || isRefetching) && (
                <Spinner accessibilityLabel="loading attendance data" size="medium" />
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <Button variant="tertiary" onClick={handleJumpToToday}>
            Today
          </Button>
        </Box>
      </Box>
      <Box width="100%" backgroundColor="surface.background.gray.moderate">
        <Box display="grid" gridTemplateColumns="repeat(7, 1fr)">
          {DAYS_OF_THE_WEEK.map((day) => (
            <Box key={day} textAlign="center" padding="spacing.4">
              <Text size="small" weight="semibold" color="interactive.text.gray.muted">
                {day}
              </Text>
            </Box>
          ))}
          {calendarDays.map((day) => {
            const attendanceStatus = getAttendanceStatus(day, currentDate, data);
            const isCurrentDate = isSameDay(day, new Date());

            return (
              <React.Fragment key={day.toISOString()}>
                {attendanceStatus.status === CALENDAR_STATUS.NULL ? (
                  <Day
                    status={attendanceStatus.status}
                    isCurrentDate={isCurrentDate && isSameMonth(day, currentDate)}>
                    <Text
                      size="small"
                      weight="semibold"
                      color={calendarStatusToColor(attendanceStatus.status)}>
                      {isSameMonth(day, currentDate)
                        ? getFormattedDateValue({ date: day, formatString: dateFormats.shortDay })
                        : ''}
                    </Text>
                  </Day>
                ) : (
                  <Tooltip content={attendanceStatus.description}>
                    <Day
                      status={attendanceStatus.status}
                      isCurrentDate={isCurrentDate && isSameMonth(day, currentDate)}>
                      <Text
                        size="small"
                        weight="semibold"
                        color={calendarStatusToColor(attendanceStatus.status)}>
                        {isSameMonth(day, currentDate)
                          ? getFormattedDateValue({ date: day, formatString: dateFormats.shortDay })
                          : ''}
                      </Text>
                    </Day>
                  </Tooltip>
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
