import React from 'react';
import { Alert, ArrowRightIcon, Link, Text } from '@razorpay/blade/components';
import {
  actionPaymentNameMap,
  actionStatusesMap,
  karnatakaManualPTPaymentDocsLink,
} from 'components/RunPayroll/ComplianceActions/data';
import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';
import { ComplianceAction } from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';

export const PaymentAlerts = ({
  action,
  actionName,
}: {
  action: GetComplianceActionsSchemaContract['actions']['pay-pt'][string];
  actionName: ComplianceAction;
}) => {
  const isOverdue = action.meta.is_over_due;

  const paymentAmount = Number(action.meta.amount?.[0]?.value || 0);

  if (action.meta.manual_payment_outside_payroll) {
    return (
      <Alert
        testID="payment-alert-pay-externally"
        marginTop="spacing.3"
        color="notice"
        isDismissible={false}
        description={`You have executed the payroll after making the ${actionPaymentNameMap[actionName]} Payment. Please complete the compliance payment externally for the remaining eligible employees.`}
      />
    );
  }

  if (action.status === actionStatusesMap.paidManually) {
    return (
      <Alert
        testID="payment-alert-receipt-unavailable"
        marginTop="spacing.3"
        color="information"
        isDismissible={false}
        description="Receipt is unavailable for this payment, since it was paid outside XPayroll"
      />
    );
  }

  if (isOverdue) {
    return (
      <Alert
        testID="payment-alert-overdue"
        marginTop="spacing.3"
        color="notice"
        isDismissible={false}
        description={
          <Text size="small" color="surface.text.gray.subtle" as="span">
            Please complete the {actionPaymentNameMap[actionName]} Payment externally.
            <Link
              marginLeft="spacing.2"
              size="small"
              href={karnatakaManualPTPaymentDocsLink}
              target="_blank"
              icon={ArrowRightIcon}
              iconPosition="right">
              Learn more
            </Link>
          </Text>
        }
      />
    );
  }

  if (paymentAmount === 0) {
    return (
      <Alert
        testID="payment-alert-no-employees-eligible"
        marginTop="spacing.3"
        color="information"
        isDismissible={false}
        description="No employees in your organization are eligible for Karnataka Professional Tax."
      />
    );
  }

  return null;
};
