import React from 'react';
import iconCheckCircle from '../../../assets/icons/icon_check_circle.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import styles from './NonEditableField.module.scss';
import cx from 'classnames';

interface Props {
  label: string;
  value: string;
  className?: string;
}

const NonEditableField = ({ label, value, className }: Props) => {
  return (
    <div
      className={cx(
        'border border-solid border-greyOne px-5 py-6 flex gap-4 items-center',
        styles['container'],
        className,
      )}>
      <img src={getStaticMediaUrl(iconCheckCircle)} alt="" className={styles['icon']} />
      <div className={cx('flex flex-col', styles['textContainer'])}>
        <span className={cx('italic text-white-o-38', styles['label'])}>{label}</span>
        <span className={cx('text-white-o-74 text-3xl', styles['value'])}>{value}</span>
      </div>
    </div>
  );
};

export default NonEditableField;
