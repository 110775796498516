import React from "react";
import { Box, Spinner, Text, Alert } from '@razorpay/blade/components';
import DeclarationWindow from "components/ui/DeclarationWindow/DeclarationWindow";
import { GenericDeclaration, DeclarationErrors } from "components/ui/DeclarationWindow/types";
import { TaxDeclarationSettingsSchemaContract } from 'schemas/TaxDeclarationSettingsSchema';


const DeclarationWindowSettings = (
    {
        declarationWindow,
        onChange,
        settings,
        isLoading,
        hasProPlan,
        errors
    }:
        {
            declarationWindow: GenericDeclaration;
            onChange: (declarationSettings: GenericDeclaration) => void;
            settings: TaxDeclarationSettingsSchemaContract;
            isLoading?: boolean;
            hasProPlan?: boolean;
            errors?: DeclarationErrors;
        }
) => {

    const getComponent = () => {
        if (isLoading) {
            return <Spinner accessibilityLabel="Loading" marginLeft={'35%'} />
        }

        else if (!settings.allowEmployeeToUpdateTaxDeductions) {
            return (
                <Alert
                    isDismissible={false}
                    title="Employee IT declaration is currently disabled for employees"
                    description="Please go to General > Allow employees to update their tax deductions  and enable declaration."
                    marginTop={'spacing.11'}
                    color="information"
                />
            );
        }

        else {
            return <DeclarationWindow
                description={'Define the period during which employees can make their IT declarations.'}
                declarationWindow={hasProPlan ? declarationWindow : { type: 'always' }}
                onChange={(window) => onChange(window)}
                hasProPlan={hasProPlan}
                errors={errors}
            />
        }
    }




    return (
        <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" paddingBottom="spacing.11">
            {getComponent()}
        </Box>
    );
}

export default DeclarationWindowSettings;