import { Box, BoxProps, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  title: string;
  iconBackground: BoxProps['backgroundColor']
  description: string;
  button?: ReactNode;
}

const ComponentHeader = ({ icon, title, description, button, iconBackground }: Props) => {
  return (
    <Box display={'flex'} flexDirection={'row'} gap={'spacing.4'}>
      <Box
        width="36px"
        height="36px"
        borderRadius={'round'}
        backgroundColor={iconBackground}
        display="flex"
        alignItems="center"
        justifyContent="center">
        {icon}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
        <Text size="medium" weight="semibold">
          {title}
        </Text>
        <Text size="small" weight="regular" color="surface.text.gray.muted">
          {description}
        </Text>
      </Box>
      {button}
    </Box>
  );
};

export default ComponentHeader;
