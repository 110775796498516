import {
  ActionList,
  ActionListItem,
  Alert,
  ArrowLeftIcon,
  Button,
  Divider,
  Dropdown,
  DropdownOverlay,
  Link,
  Radio,
  RadioGroup,
  SelectInput,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import { salaryComponentParams } from 'components/SalaryComponents/constants';
import { useComponentParams, useDetailsView } from 'components/SalaryComponents/hooks';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
  SwitchDescription,
  SwitchField,
} from 'components/WizardViews/components';
import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import { ValidationSchema, useFormWithProvider } from 'forms';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  deductFrom,
  deductionsSteps,
  deductionsTaxSections,
  knowMoreItems,
  links,
} from '../constants';
import { DeductionsFormData } from '../types';
import {
  checkIsDeductionTypeAdhoc,
  checkIsDeductionTypeRecurring,
  isTaxExempt,
  showDeductionsTaxabilitySection,
} from '../utils';

// Validation schema
const validationSchema = (): ValidationSchema<DeductionsFormData> => {
  return {
    deductFrom: (formData) => {
      if (checkIsDeductionTypeAdhoc(formData.deductionType) && !formData.deductFrom) {
        return 'Deduct from section is required';
      }
      return null;
    },
    taxExemptionSection: (formData) => {
      if (
        isTaxExempt(formData.taxExemptInOldRegime, formData.taxExemptInNewRegime) &&
        !formData.taxExemptionSection
      ) {
        return 'Exempted from section is required';
      }
      return null;
    },
  };
};

const PayAndTaxStep: React.FC = () => {
  const history = useHistory();
  const { formData, validate, register } = useFormWithProvider<DeductionsFormData>();
  const { updateStepStatus } = useWizardInfo();
  const { searchString } = useComponentParams();
  const { openDetailsView } = useDetailsView(salaryComponentParams.knowMore);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (validate(validationSchema())) {
      updateStepStatus(deductionsSteps.step2, 'success');
      history.push({
        pathname: routePaths.salaryComponents.edit.deductions.review,
        search: searchString,
      });
    }
  };

  const isRecurring = checkIsDeductionTypeRecurring(formData.deductionType);
  const isAdhoc = checkIsDeductionTypeAdhoc(formData.deductionType);
  const showTaxabilitySection = showDeductionsTaxabilitySection(formData);
  const isTaxExempted = isTaxExempt(formData.taxExemptInOldRegime, formData.taxExemptInNewRegime);

  const alertDescription = (
    <>
      1.{' '}
      {isRecurring
        ? 'Proration and arrears changes will only apply to future payroll months.'
        : 'If display name is updated, past salary registers and payslips will reflect the new Display Name.'}
      <br />
      2.{' '}
      {isRecurring
        ? 'If taxability is updated, the change will apply retrospectively to all previous payrolls within the same financial year (FY) for the component.'
        : 'Proration and arrears changes will only apply to future payroll months.'}
    </>
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        {isRecurring && (
          <>
            <StepHeader title={'Pay & Taxability'} subtitle={'Configuration'} />

            <Alert
              marginBottom="spacing.7"
              isFullWidth
              title="Modify details"
              isDismissible={false}
              description={alertDescription}
            />

            <FieldGroup title={'Proration & Arrears'}>
              <SwitchField
                label="Prorate the component"
                accessibilityLabel="prorate the component"
                {...register('supportProration', 'switch')}>
                <SwitchDescription content="Amount will be adjusted based on the employee's working days within the pay cycle." />
                <Link
                  variant={'button'}
                  marginLeft="spacing.2"
                  size="small"
                  onClick={() => openDetailsView(knowMoreItems.proration)}
                  accessibilityLabel={'know more - proration'}>
                  Know more✨
                </Link>
              </SwitchField>
              <SwitchField
                label="Calculate arrears"
                accessibilityLabel="Exempt in the new tax regime"
                {...register('considerInArrear', 'switch')}
                isComingSoon
                isDisabled>
                <SwitchDescription content="Choose whether to calculate arrears for this component in case of missed payments or salary revisions." />
                <Link
                  variant={'button'}
                  marginLeft="spacing.2"
                  size="small"
                  onClick={() => openDetailsView(knowMoreItems.arrearCalculation)}
                  accessibilityLabel={'know more - arrears'}>
                  Know more✨
                </Link>
              </SwitchField>
            </FieldGroup>
          </>
        )}

        {isAdhoc && (
          <>
            <StepHeader title={'Pay & Taxability'} subtitle={'Configuration'} />

            <FieldGroup
              title={'Deduct from?'}
              subtitle={'Choose whether the deduction applies to Gross Pay or Net Pay.'}>
              <RadioGroup {...register('deductFrom', 'radiogroup')} isDisabled>
                <Radio value={'GROSS_PAY'} helpText={deductFrom.GROSS_PAY.description}>
                  {deductFrom.GROSS_PAY.label}
                </Radio>
                <Radio value={'NET_PAY'} helpText={deductFrom.NET_PAY.description}>
                  {deductFrom.NET_PAY.label}
                </Radio>
              </RadioGroup>
            </FieldGroup>
          </>
        )}

        {showTaxabilitySection && (
          <>
            {' '}
            <Divider marginY="spacing.8" />
            <FieldGroup
              title={'Taxability'}
              subtitle={
                'If enabled, the component will be exempt from tax under the selected regime.'
              }
              infoLabel={'Know more✨'}
              onInfoClick={() => history.push(links.knowMoreDeductionsTaxability)}>
              <SwitchField
                label="Exempt in the Old Tax Regime"
                accessibilityLabel="Exempt in the old tax regime"
                {...register('taxExemptInOldRegime', 'switch')}
              />
              <SwitchField
                label="Exempt in the New Tax Regime"
                accessibilityLabel="Exempt in the new tax regime"
                {...register('taxExemptInNewRegime', 'switch')}
              />
              {isTaxExempted && (
                <Dropdown>
                  <SelectInput
                    label="Exempt under which section"
                    placeholder="Exempt under which section"
                    {...register('taxExemptionSection', 'select')}
                  />
                  <DropdownOverlay>
                    <ActionList>
                      {deductionsTaxSections.map((section) => (
                        <ActionListItem
                          key={section.key}
                          title={section.label}
                          value={section.key}
                          description={section.description}
                        />
                      ))}
                    </ActionList>
                  </DropdownOverlay>
                </Dropdown>
              )}
            </FieldGroup>
          </>
        )}
      </StepContentWrapper>
      <StepActionsWrapper>
        <Button variant="secondary" icon={ArrowLeftIcon} onClick={() => history.goBack()}>
          Back: General & Type
        </Button>
        <Button type="submit">Next: Review</Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default PayAndTaxStep;
