import React from 'react';
import { Box } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import GeneralDetailsStep from 'components/WizardViews/SalaryComponents/CreateEarnings/GeneralDetailsStep';
import ReviewStep from 'components/WizardViews/SalaryComponents/CreateEarnings/ReviewStep';
import TaxInfoStep from 'components/WizardViews/SalaryComponents/CreateEarnings/TaxInfoStep';
import {
  createEarningsRouteToStepMap,
  earningSteps,
} from 'components/WizardViews/SalaryComponents/constants';
import { WizardHeader, WizardProgressView, WizardWrapper } from 'components/WizardViews/components';
import { WizardInfoProvider } from 'components/WizardViews/providers';
import { Wizard, WizardStep } from 'forms/Wizard';
import { useHistory, useLocation } from 'react-router-dom';

const CreateEarningsForm = () => {
  const location = useLocation();
  const currentStep =
    createEarningsRouteToStepMap[location.pathname as keyof typeof createEarningsRouteToStepMap] ||
    earningSteps.step1;

  const history = useHistory();

  const handleCloseClick = () => {
    history.push(routePaths.salaryComponents.root);
  };

  return (
    <WizardInfoProvider currentStep={currentStep} totalSteps={3}>
      <WizardWrapper>
        <WizardHeader heading="New" subHeading="Earning Component" onCloseClick={handleCloseClick}>
          <WizardProgressView
            steps={[
              { label: 'General & pay', position: earningSteps.step1 },
              { label: 'Taxation', position: earningSteps.step2 },
              { label: 'Review', position: earningSteps.step3 },
            ]}
          />
        </WizardHeader>
        <Box flex={1} overflowY="auto" height="100%">
          <Wizard>
            <WizardStep step={earningSteps.step1} component={GeneralDetailsStep} />
            <WizardStep step={earningSteps.step2} component={TaxInfoStep} />
            <WizardStep step={earningSteps.step3} component={ReviewStep} />
          </Wizard>
        </Box>
      </WizardWrapper>
    </WizardInfoProvider>
  );
};

export default CreateEarningsForm;
