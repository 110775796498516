import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextArea,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import { MyRequestsContextType, useMyRequestsContext } from '../MyRequestsContext';
import { useMutation } from 'react-query';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';

const BulkRejectModal = () => {
  const { bulkApproveRejectWithdrawRequests, closeBulkRejectionModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { requestIds, isRejectionModalOpen, callback } = bulkApproveRejectWithdrawRequests;
  const [remark, setRemark] = useState('');
  const requestsCount = requestIds.length;
  const title = `Reject ${requestsCount} requests`;
  const isRejectCtaDisabled = !remark;

  const bulkRejectionMutation = useMutation({
    mutationFn: api.workflowApis.rejectRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: `${success.length} requests successfully rejected`,
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: `${failure.length} requests could not be rejected`,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeBulkRejectionModal();
      callback?.();
    },
  });

  const handleRejectButtonClick = () => {
    bulkRejectionMutation.mutate({
      ids: requestIds,
      remarks: remark,
    });
  };

  return (
    <Modal isOpen={isRejectionModalOpen} onDismiss={closeBulkRejectionModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <TextArea
          value={remark}
          label="Add Remarks"
          placeholder="Eg. Bulk rejecting as discussed on mail."
          onChange={({ value }) => {
            setRemark(value ?? '');
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={bulkRejectionMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {bulkRejectionMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(bulkRejectionMutation.error))[0]}
            </Text>
          )}
          <Button
            isLoading={bulkRejectionMutation.isLoading}
            onClick={handleRejectButtonClick}
            isDisabled={isRejectCtaDisabled}>
            Reject
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

const SingleRejectModal = () => {
  const { singleApproveRejectWithdrawRequests, closeSingleRejectionModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { isRejectionModalOpen, requestId, callback } = singleApproveRejectWithdrawRequests;
  const [remark, setRemark] = useState('');
  const title = 'Reject request';
  const isRejectCtaDisabled = !remark;

  const singleRejectionMutation = useMutation({
    mutationFn: api.workflowApis.rejectRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: 'Request successfully rejected',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: 'Request could not be rejected',
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeSingleRejectionModal();
      callback?.();
    },
  });

  const handleRejectButtonClick = () => {
    singleRejectionMutation.mutate({
      ids: [requestId],
      remarks: remark,
    });
  };

  return (
    <Modal isOpen={isRejectionModalOpen} onDismiss={closeSingleRejectionModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <TextArea
          value={remark}
          label="Add Remarks"
          placeholder="Eg. Rejecting as discussed on mail."
          onChange={({ value }) => {
            setRemark(value ?? '');
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={singleRejectionMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {singleRejectionMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(singleRejectionMutation.error))[0]}
            </Text>
          )}
          <Button
            isLoading={singleRejectionMutation.isLoading}
            onClick={handleRejectButtonClick}
            isDisabled={isRejectCtaDisabled}>
            Reject
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export { SingleRejectModal, BulkRejectModal };
