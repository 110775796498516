import React from 'react';
import { Divider, List, ListItem, ListItemText, Text } from '@razorpay/blade/components';
import HeaderSection from './HeaderSection';
import InfoSection from './InfoSection';

const TaxDeductionsInfo = () => {
  return (
    <>
      <HeaderSection
        title={'Tax Deductions'}
      />

      <Divider marginY={'spacing.7'} />

      <InfoSection title={' ✅ If tax deduction is prorated'}>
        <Text color={'surface.text.gray.muted'} size='small'>Tax is spread across the remaining months of the financial year.</Text>
        <Text color={'surface.text.gray.muted'} size='small'>Useful when you want to avaoud a large one-time tax cut.</Text>
        <Text color={'surface.text.gray.muted'} size='small'>Example:</Text>

        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Bonus of ₹1,00,000 paid in October
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Tax @30% = ₹30,000
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Remaining months in FY = 6
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Tax per month = ₹30,000 / 6 = ₹5,000 (Oct-Mar)
            </ListItemText>
          </ListItem>
          
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={' ✅ If tax deduction is instant'}>
        <Text color={'surface.text.gray.muted'} size='small'>Full tax is deducted in the same month the component is paid.</Text>
        <Text color={'surface.text.gray.muted'} size='small'>Useful for one-time payments needing immediate tax deduction.</Text>
        <Text color={'surface.text.gray.muted'} size='small'>Example:</Text>

        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Bonus of ₹1,00,000 paid in October
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Tax @30% = ₹30,000
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Entire ₹30,000 is deducted in October payroll
            </ListItemText>
          </ListItem>
          
        </List>
      </InfoSection>
    </>
  );
};

export default TaxDeductionsInfo;