import {
  Alert,
  Box,
  Button,
  ExternalLinkIcon,
  Link,
  TabItemProps,
} from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import Errors from 'components/ui/Form/Errors';
import TabLayout from 'components/ui/Layout/TabLayout';
import { links } from 'constants/flexibenefits';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';
import DeclarationSettings from './DeclarationSettings/DeclarationSettings';
import GeneralSettings from './GeneralSettings';
import { GET_FLEXIBLE_BENEFITS_SETTINGS, UPDATE_FLEXIBLE_BENEFITS_SETTINGS } from './queries';
import { type FlexiBenefitsSettings } from './types';
import useFBPValidations from './useFBPValidations';
import { createPayload, parseSettings } from './utils';
import AdvancedWindowConfiguration from './AdvancedWindowConfiguration/AdvancedWindowConfiguration';

const FlexibleBenefitsSettings = () => {
  const location = useLocation();
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const pathname = location.pathname;
  const containerRef = useRef<HTMLDivElement>(null);
  const [settings, setSettings] = useState<FlexiBenefitsSettings>({
    shouldHoldFlexiAmount: false,
    employeeCanDeclareFlexiBenefits: false,
    employeeCanUpdateAmountInDeclaredFlexiBenefits: false,
    employeeDeclarationWindow: { type: 'always' },
    employeeProofUploadWindow: { type: 'always' },
    FbpDefaultTaxable: false,
    isAutoReleaseEnabledForUnclaimedBenefits: false,
    isTaxableAfterMonthEnabled: false,
    isAutoOpenWindowForNewEmployeesEnabled: false,
    isAutoOpenWindowForSalaryRevisedEmployeesEnabled: false,
  });

  const history = useHistory();

  const { isLoading, isRefetching, refetch, error } = useQuery({
    queryKey: GET_FLEXIBLE_BENEFITS_SETTINGS,
    queryFn: () =>
      org?.id ? api.flexibleBenefits.getSettings(org.id) : Promise.reject('User id is required'),
    onSuccess: (data) => setSettings(parseSettings(data)),
    onError: (e: typeof AppError) => {},
  });

  const updateSettings = useMutation({
    mutationKey: UPDATE_FLEXIBLE_BENEFITS_SETTINGS,
    mutationFn: api.flexibleBenefits.updateSettings,
    onSuccess: (data) => {
      setSettings(parseSettings(data));
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
    },
    onError: (e: typeof AppError) => {
      window.requestAnimationFrame(() => {
        containerRef.current?.scrollIntoView();
      });
    },
  });

  const getTabValue = () => {
    switch (pathname) {
      case routePaths.flexibleBenefits.settings:
      case routePaths.flexibleBenefits.settingsGeneral:
        return 'general';
      case routePaths.flexibleBenefits.settingsDeclaration:
        return 'declaration_window';
      case routePaths.flexibleBenefits.settingsProofUpload:
        return 'proof_upload_window';
      case routePaths.flexibleBenefits.settingsWindowAdavanced:
        return 'advanced_window_config';
    }
  };

  const [currentTab, setCurrentTab] = useState(getTabValue());

  const resetUpdate = updateSettings.reset;

  const handleGeneralSettingsChange = (
    key: keyof FlexiBenefitsSettings,
    value: string | boolean,
  ) => {
    setSettings((p) => ({ ...p, [key]: value }));
  };

  const { errors, validate, resetValidations } = useFBPValidations({ settings });

  const handleSave = () => {
    const { isValid } = validate();
    if (isValid) {
      if (org?.id) updateSettings.mutate({ org: org?.id, settings: createPayload(settings) });
    }
  };

  const tabItems: TabItemProps[] = [
    {
      children: 'General',
      value: 'general',
      onClick: () => history.push(routePaths.flexibleBenefits.settingsGeneral),
    },
    {
      children: 'Declaration Window',
      value: 'declaration_window',
      onClick: () => history.push(routePaths.flexibleBenefits.settingsDeclaration),
    },
    {
      children: 'Proof Upload Window',
      value: 'proof_upload_window',
      onClick: () => history.push(routePaths.flexibleBenefits.settingsProofUpload),
    },
    {
      children: 'Advance Window Configuration',
      value: 'advanced_window_config',
      onClick: () => history.push(routePaths.flexibleBenefits.settingsWindowAdavanced),
    },
  ];

  const handleDeclarationSettingsChange = (
    value: GenericDeclaration,
    isProofUploadWindowSettings: boolean,
  ) => {
    setSettings((p) => ({
      ...p,
      [isProofUploadWindowSettings ? 'employeeProofUploadWindow' : 'employeeDeclarationWindow']:
        value,
    }));
  };

  const handleWindowPeriodChange = () => resetValidations();

  const isUpdating = updateSettings.isLoading;

  const errorDescription = updateSettings.error || error;

  const isDeclarationSettingsView = pathname === routePaths.flexibleBenefits.settingsDeclaration;

  const isDeclrataionDisabled = settings.employeeCanDeclareFlexiBenefits === false;

  const isSaveHidden = isDeclarationSettingsView && isDeclrataionDisabled;

  const handleClick = () => window.open(links.learnMoreAboutFbp, '_blank');

  const handleTabChange = (value: string) => {
    resetUpdate();
    setCurrentTab(value);
    refetch();
  };

  const handleAdvancedSettingsChange = (
    key: keyof FlexiBenefitsSettings,
    value: string | boolean,
  ) => {
    setSettings((p) => ({ ...p, [key]: value }));
  };

  return (
    (<TabLayout
      tabs={tabItems}
      isLoading={isLoading || isRefetching}
      navbarProps={{
        tabsProps: { onChange: handleTabChange, value: currentTab, variant: 'borderless' },
      }}
      TabRightSideLayout={
        <Link variant="button" icon={ExternalLinkIcon} iconPosition="right" onClick={handleClick}>
          How to setup FBP
        </Link>
      }>
      <Box width="100%" height="100%">
        {errorDescription ? (
          <Box
            ref={containerRef}
            marginX={{ base: 'spacing.8', l: 'auto' }}
            width="500px"
            paddingTop="spacing.8"
            backgroundColor="surface.background.gray.moderate">
            <Alert description={<Errors errorResponse={errorDescription} />} color="negative" />
          </Box>
        ) : null}
        <Switch>
          <Route path={routePaths.flexibleBenefits.settingsWindowAdavanced}>
            <AdvancedWindowConfiguration
              orgId={org?.id}
              isLoading={updateSettings.isLoading}
              settings={settings}
              onChange={handleAdvancedSettingsChange}
            />
          </Route>
          <Route path={routePaths.flexibleBenefits.settingsDeclaration}>
            <DeclarationSettings
              isLoading={updateSettings.isLoading}
              settings={settings}
              errors={errors.employeeDeclarationWindow || {}}
              title="🪟 Declaration window"
              onChange={(val) => handleDeclarationSettingsChange(val, false)}
              description="Define the period during which employees can make changes to their flexible benefit selections."
              onWindowPeriodChange={handleWindowPeriodChange}
            />
          </Route>
          <Route path={routePaths.flexibleBenefits.settingsProofUpload}>
            <DeclarationSettings
              isLoading={isUpdating}
              settings={settings}
              errors={errors.employeeProofUploadWindow || {}}
              title="📄 Proof Upload Window"
              onChange={(val) => handleDeclarationSettingsChange(val, true)}
              description="Define the period during which employees upload their proof for their flexible benefits declarations"
              isProofUploadWindowSettings
              onWindowPeriodChange={handleWindowPeriodChange}
            />
          </Route>
          <Route
            path={[
              routePaths.flexibleBenefits.settings,
              routePaths.flexibleBenefits.settingsGeneral,
            ]}>
            <GeneralSettings
              isLoading={isUpdating}
              settings={settings}
              onChange={handleGeneralSettingsChange}
            />
          </Route>
          <Route>
            <Redirect to={routePaths.unknown} />
          </Route>
        </Switch>

        {isSaveHidden ? null : (
          <Box paddingBottom="spacing.11" marginX={{ base: 'spacing.8', l: 'auto' }} width="500px">
            <Button
              isDisabled={isLoading || isRefetching || !errors.isValid}
              isLoading={isUpdating}
              onClick={handleSave}>
              Save & Confirm
            </Button>
          </Box>
        )}
      </Box>
    </TabLayout>)
  );
};

export default FlexibleBenefitsSettings;
