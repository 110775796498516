import React from 'react';
import styles from './index.module.scss';
import { ChangeEventHandler, ReactElement } from 'react';
import { FileUploadStatus, MimeType, MimeTypeExtensionMapping } from './constants';
import { truncateFileNameKeepingExtension } from '../../../utils/FileName';
import { useRef } from 'react';
import fileUploadIcon from 'assets/file--upload--icon.svg';
import fileUploadNotInitiated from 'assets/file--upload--not--initiated.svg';
import fileUploadSuccess from 'assets/file--upload--success.svg';
import fileUploadFailure from 'assets/file--upload--failure.svg';
import crossIcon from 'assets/icons/icon_cross_blue.svg';
import { getStaticMediaUrl } from '../../../utils/Urls';

function getFileConstraintsText(allowedMimeTypes: MimeType[], maxSize: number) {

    let allowedExtensions: String[] = [];

    allowedMimeTypes.map(
        mimeType => allowedExtensions.push(
            ...MimeTypeExtensionMapping(mimeType)
        )
    );

    let retVal = allowedExtensions.join(', ') + ' file formats allowed';

    retVal = retVal + ' | Max Size ' + maxSize + ' MB';

    return retVal;
}



const FileUpload = ({ 
    id, 
    onChange, 
    onError,
    onCancel, 
    status, 
    className, 
    allowedMimeTypes, 
    maxSize = 5, 
    value, 
    error, 
    disableFileReuploadButton,
    disableCancelButton }:
    {
        id: string;
        onChange: ChangeEventHandler<HTMLInputElement>;
        onError?: () => {};
        onCancel?: () => void;
        status: number;
        className?: string;
        allowedMimeTypes: MimeType[];
        maxSize: number; //in MB,
        value?: string;
        error?: string;
        disableFileReuploadButton?: boolean;
        disableCancelButton?: boolean;
    }
): ReactElement => {

    const inputFileRef = useRef<HTMLInputElement>(null);

    function onClick(){

        if(status !== FileUploadStatus.UPLOADING){
            inputFileRef.current?.click();
        }

    }


    function getIconBasedOnStatus(status: FileUploadStatus) {
        switch (status) {
            case FileUploadStatus.NOT_INITIATED:
                return <img src={getStaticMediaUrl(fileUploadNotInitiated)}></img>;

            case FileUploadStatus.UPLOADING:
                return (<div className={styles['file-loader-wrapper']}>
                    <div className={styles['file-loader']}>
                    </div>
                    <img style={{ position: 'absolute', left: '12px', top: '10px' }} src={getStaticMediaUrl(fileUploadIcon)} />
                </div>);

            case FileUploadStatus.SUCCESS:
                return <img src={getStaticMediaUrl(fileUploadSuccess)}></img>;

            case FileUploadStatus.FAILURE:
                return <img src={getStaticMediaUrl(fileUploadFailure)}></img>;
        }
    }

    function getFooterTextBasedOnStatus(status: FileUploadStatus) {
        switch (status) {
            case FileUploadStatus.NOT_INITIATED:
                return <p className={styles['FileUpload-footer-text-normal']}>{getFileConstraintsText(allowedMimeTypes, maxSize)}</p>;

            case FileUploadStatus.UPLOADING:
                return <p className={styles['FileUpload-footer-text-normal']}>{'Uploading...'}</p>;

            case FileUploadStatus.SUCCESS:
                return <p className={styles['FileUpload-footer-text-normal']}>{'Uploaded successfully'}</p>;

            case FileUploadStatus.FAILURE:
                return <span className={styles['FileUpload-footer-text-red']}>{error === '' ? 'Processing failed, please re-upload valid file.' : error}</span>;
        }
    }


    return (
        <div className={`${styles['FileUpload-area']} ${className ? className : ''}`} onClick={onClick}>
            {status !== FileUploadStatus.UPLOADING && <input aria-label='Select File' type="file" id={id} name={id} onChange={(event) => {onChange(event);event.target.value='';}} className="inputfile invisible" ref={inputFileRef}/>}
            <div className={styles['FileUpload-icon']}>
                {getIconBasedOnStatus(status)}
            </div>
            <div>
                <div className={styles['FileUpload-main-text']}>
                    {status === FileUploadStatus.NOT_INITIATED && <label htmlFor={id} onClick={(event) => {event.preventDefault()}} className='cursor-pointer'>Browse files to upload</label>}
                    {status !== FileUploadStatus.NOT_INITIATED && value && <span>{truncateFileNameKeepingExtension(value, 25)}</span>}
                </div>
                <div className={styles['FileUpload-div-text-wrap']}>
                    {getFooterTextBasedOnStatus(status)}
                </div>
            </div>
            <div className={'ml-auto mt-4'}>
                {![FileUploadStatus.NOT_INITIATED,FileUploadStatus.UPLOADING].includes(status) && !disableFileReuploadButton && <label htmlFor={id} className={styles['FileUpload-button']} onClick={(event) => {event.preventDefault()}}>{'Replace file'}</label>}
                {status === FileUploadStatus.UPLOADING && !disableCancelButton && onCancel && <div className={styles['cancel-button']} onClick={onCancel}><img src={getStaticMediaUrl(crossIcon)}></img></div> }
            </div>
        </div>
    )
};

export default FileUpload;