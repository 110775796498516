import {
  ArrowRightIcon,
  Box,
  Button,
  ExternalLinkIcon,
  InfoIcon,
  Link,
  RefreshIcon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TablePagination,
  TableRow,
  TableToolbar,
  TableToolbarActions,
  Text,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import api from 'api';
import { getErrorsAsString } from 'components/Kyc/utils';
import { routePaths } from 'components/Routes/data';
import { StatusBadge } from 'components/SalaryComponents/components/StatusBadge';
import { TextWithDescription } from 'components/SalaryComponents/components/TextWithDescription';
import {
  earningFiltersMap,
  helpArticles,
  salaryComponentParams,
  salaryComponentsQueries,
} from 'components/SalaryComponents/constants';
import { EarningsComponentType } from 'components/SalaryComponents/types';
import {
  getQueryVariables,
  getTableItemCountLimits,
  getTaxRegimeText,
} from 'components/SalaryComponents/utils';
import { earningPayTypes } from 'components/WizardViews/SalaryComponents/constants';
import { earningsExemptionDisplayText } from 'components/WizardViews/SalaryComponents/utils';
import { EmptyListView } from 'components/ui/EmptyListView/EmptyListView';
import { TableSkeletonBody } from 'components/ui/TableSkeletonBody/TableSkeletonBody';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppError } from 'utils/AppError';
import { useDetailsView } from '../../hooks/useDetailsView';
import { capitalizeAllWordsInString } from 'utils/Strings';

const EarningsListView = () => {
  const history = useHistory();
  const { currentPage, itemsPerPage, setCurrentPage, setItemsPerPage } = usePagination({
    page: 0,
    limit: 10,
  });

  const { currentFilters, isAnyFilterApplied } = useFilters({
    filtersMap: earningFiltersMap,
    multipleValueFilters: [earningFiltersMap.taxability],
    onParamsChange(params) {
      setCurrentPage(0);
    },
  });

  const queryVariables = getQueryVariables('EARNINGS_CATEGORY', currentFilters);

  const { data, isFetching, refetch, error, isError } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentsList, queryVariables],
    queryFn: () =>
      api.salaryComponents.queries.listSalaryComponents<EarningsComponentType>(queryVariables),
    select(data) {
      return data.payrollListSalaryComponents.data.salaryComponents;
    },
    onError(err) {
      console.log('Error', err);
    },
  });

  const nodes = data ?? [];

  const { openDetailsView } = useDetailsView(salaryComponentParams.componentId);

  const isTableEmpty = nodes.length === 0;

  return (
    <>
      <Table
        data={{ nodes }}
        pagination={
          !isTableEmpty ? (
            <TablePagination
              currentPage={currentPage}
              showPageSizePicker={true}
              onPageChange={({ page }) => {
                setCurrentPage(page);
              }}
              onPageSizeChange={({ pageSize }) => {
                setItemsPerPage(pageSize);
              }}
              totalItemCount={nodes.length}
            />
          ) : undefined
        }
        toolbar={
          <TableToolbar title={getTableItemCountLimits(nodes.length, currentPage, itemsPerPage)}>
            <TableToolbarActions>
              <Button
                icon={RefreshIcon}
                variant={'tertiary'}
                onClick={() => {
                  refetch();
                }}
              />
            </TableToolbarActions>
          </TableToolbar>
        }
        marginTop={'spacing.5'}
        showBorderedCells={false}>
        {(salaryComponents) => (
          <>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>
                  <Text weight="semibold">Component Name</Text>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Box display={'flex'} flexDirection={'row'} gap={'spacing.3'}>
                    <Text weight="semibold">Display Name</Text>
                    <Tooltip
                      placement="top"
                      content={`The name that will appear in employee’s payslip and Salary Register`}>
                      <TooltipInteractiveWrapper>
                        <InfoIcon marginTop={'spacing.1'} />
                      </TooltipInteractiveWrapper>
                    </Tooltip>
                  </Box>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Text weight="semibold">Pay Type</Text>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Text weight="semibold">Taxability</Text>
                </TableHeaderCell>
                <TableHeaderCell>
                  <Text weight="semibold" marginX={'auto'}>
                    Status
                  </Text>
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            {isFetching ? (
              <TableSkeletonBody rowCount={10} columnCount={5} />
            ) : (
              <TableBody>
                {salaryComponents.map((salaryComponent) => {
                  const exemptionText = earningsExemptionDisplayText(
                    salaryComponent.settings.behaviour.taxBehaviour?.taxExemptionSection,
                  );
                  return (
                    <TableRow
                      key={salaryComponent.id}
                      item={salaryComponent}
                      onClick={() =>
                        openDetailsView(
                          salaryComponent.id,
                          salaryComponent.settings.earningDetails.payType,
                        )
                      }
                      hoverActions={
                        <Button
                          variant="tertiary"
                          color="primary"
                          size="medium"
                          icon={ArrowRightIcon}
                          iconPosition="right"
                          onClick={() =>
                            openDetailsView(
                              salaryComponent.id,
                              salaryComponent.settings.earningDetails.payType,
                            )
                          }
                          testID={`view-details-button-${salaryComponent.id}`}>
                          View Details
                        </Button>
                      }>
                      <TableCell>
                        <TextWithDescription
                          componentName={capitalizeAllWordsInString(salaryComponent.name)}
                          description={salaryComponent.isPredefined ? 'Predefined' : 'Custom'}
                        />
                      </TableCell>
                      <TableCell>
                        {capitalizeAllWordsInString(
                          salaryComponent.settings?.behaviour.displayBehaviour?.displayName ?? '',
                        )}
                      </TableCell>
                      <TableCell>
                        {salaryComponent.settings?.earningDetails?.payType
                          ? earningPayTypes[
                              salaryComponent.settings?.earningDetails
                                ?.payType as keyof typeof earningPayTypes
                            ].label
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <TextWithDescription
                          componentName={`${
                            Boolean(
                              salaryComponent.settings.behaviour.taxBehaviour
                                ?.taxExemptInOldRegime ||
                                salaryComponent.settings.behaviour.taxBehaviour
                                  ?.taxExemptInNewRegime,
                            )
                              ? 'Exempted'
                              : 'Taxable'
                          }${
                            salaryComponent.settings.behaviour.taxBehaviour?.taxExemptionSection
                              ? ` | ${exemptionText}`
                              : ''
                          }`}
                          description={getTaxRegimeText(
                            salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInOldRegime,
                            salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInNewRegime,
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Box marginX={'auto'}>
                          <StatusBadge isActive={salaryComponent.isActive} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </>
        )}
      </Table>
      {isTableEmpty && !isFetching && !isError && (
        <EmptyListView
          title={`No Earnings component ${
            isAnyFilterApplied ? 'found for the selected filters' : 'created yet'
          } 🙈`}
          description={
            <Text color={'surface.text.gray.muted'} weight={'medium'} textAlign={'center'}>
              Click on Create Component button to create your first component
            </Text>
          }>
          <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'} marginX={'auto'}>
            <Link
              size="medium"
              variant="anchor"
              href={helpArticles.learnMore} // link to be added
              icon={ExternalLinkIcon}
              iconPosition="right"
              target="_blank">
              Learn more about salary component
            </Link>
            <Box marginX={'auto'}>
              <Button
                size="medium"
                onClick={() => {
                  history.push(routePaths.salaryComponents.create.earnings.root);
                }}
                isFullWidth>
                Create Earnings Component
              </Button>
            </Box>
          </Box>
        </EmptyListView>
      )}
      {isError && (
        <EmptyListView
          title={`There was an error while fetching the data for ${
            currentFilters.status ?? 'all'
          } earning components. `}
          description={`Error: ${getErrorsAsString(error as AppError)}`}>
          <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'} marginX={'auto'}>
            <Link
              size="medium"
              variant="anchor"
              href={helpArticles.learnMore} // link to be added
              icon={ExternalLinkIcon}
              iconPosition="right"
              target="_blank">
              Learn more about salary component
            </Link>
            <Box marginX={'auto'}>
              <Button
                size="medium"
                onClick={() => {
                  refetch();
                }}
                isFullWidth
                iconPosition="left"
                icon={RefreshIcon}>
                Retry
              </Button>
            </Box>
          </Box>
        </EmptyListView>
      )}
    </>
  );
};

export default EarningsListView;
