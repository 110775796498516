import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { MyRequestsContextType, useMyRequestsContext } from '../MyRequestsContext';
import { useMutation } from 'react-query';
import api from 'api';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';

const SingleWithdrawModal = () => {
  const { singleApproveRejectWithdrawRequests, closeSingleWithdrawModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { isWithdrawModalOpen, requestId, callback } = singleApproveRejectWithdrawRequests;
  const title = 'Withdraw request';

  const singleWithdrawalMutation = useMutation({
    mutationFn: api.workflowApis.withdrawRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: 'Request successfully withdrawn',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: 'Request could not be withdrawn',
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeSingleWithdrawModal();
      callback?.();
    },
  });

  const handleConfirmWithdrawCtaClicked = () => {
    singleWithdrawalMutation.mutate({ ids: [requestId] });
  };

  return (
    (<Modal isOpen={isWithdrawModalOpen} onDismiss={closeSingleWithdrawModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <Text weight="semibold" size="large">
          Are you sure you want to withdraw this request?
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={singleWithdrawalMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {singleWithdrawalMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(singleWithdrawalMutation.error))[0]}
            </Text>
          )}
          <Button
            onClick={handleConfirmWithdrawCtaClicked}
            isLoading={singleWithdrawalMutation.isLoading}>
            Withdraw
          </Button>
        </Box>
      </ModalFooter>
    </Modal>)
  );
};

const BulkWithdrawModal = () => {
  const { bulkApproveRejectWithdrawRequests, closeBulkWithdrawModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { isWithdrawModalOpen, requestIds, callback } = bulkApproveRejectWithdrawRequests;
  const title = `Withdraw ${requestIds.length} ${requestIds.length > 1 ? 'requests' : 'request'}`;

  const bulkWithdrawalMutation = useMutation({
    mutationFn: api.workflowApis.withdrawRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: `${success.length} requests successfully withdrawn`,
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: `${failure.length} requests could not be withdrawn`,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeBulkWithdrawModal();
      callback?.();
    },
  });

  const handleConfirmWithdrawCtaClicked = () => {
    bulkWithdrawalMutation.mutate({ ids: requestIds });
  };

  return (
    (<Modal isOpen={isWithdrawModalOpen} onDismiss={closeBulkWithdrawModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <Text weight="semibold" size="large">
          Are you sure you want to withdraw {requestIds.length}{' '}
          {requestIds.length > 1 ? 'requests' : 'request'}?
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={bulkWithdrawalMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {bulkWithdrawalMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(bulkWithdrawalMutation.error))[0]}
            </Text>
          )}
          <Button
            onClick={handleConfirmWithdrawCtaClicked}
            isLoading={bulkWithdrawalMutation.isLoading}>
            Withdraw
          </Button>
        </Box>
      </ModalFooter>
    </Modal>)
  );
};

export { SingleWithdrawModal, BulkWithdrawModal };
