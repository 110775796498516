import { FormProvider } from 'forms';
import React from 'react';
import EditEarningsForm from './EditEarningsForm';
import KnowMoreView from '../KnowMoreView/KnowMoreView';

const EditEarningsView = () => {
  return (
    <FormProvider>
      <EditEarningsForm />
      <KnowMoreView />
    </FormProvider>
  );
};

export default EditEarningsView;
