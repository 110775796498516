import React, { useState, FormEvent } from 'react';
import { Input } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import { WarningAlert } from 'components/ui/Alert';
import { StandardButton, LinkButtonV2 } from 'components/ui/Button';
import { fieldsToBeSynced } from 'components/Settings/FreshteamsSettings/utils';

const FreshTeamActivationConfirmation = ({ onConfirm }: { onConfirm: () => void }) => {
  const [confirmationText, setConfirmationText] = useState('');
  const [confirmationError, setConfirmationError] = useState('');

  const handleActivation = (event: FormEvent) => {
    event.preventDefault();
    if (confirmationText.toLowerCase() === 'confirm') {
      onConfirm();
    } else {
      setConfirmationError(
        'Please read the above disclaimer and type "confirm" if you want to enable Freshteam integration',
      );
    }
  };

  return (
    <form className="p-10" onSubmit={handleActivation}>
      <div className="flex flex-col">
        <div className={'text-4xl font-bold text-white mb-8 mt-4'}>Disclaimer</div>
        <div className="mb-8">
          <div>
            Please note that enabling this integration will overwrite your current data on RazorpayX
            Payroll with the data on Freshteam.
          </div>
          <div className="mt-8">
            The following data will be synced. Once the integration is done, the previous data
            cannot be restored.
          </div>
          <div className="font-bold text-left mt-8">
            Fields synced from Freshteam: {fieldsToBeSynced.join(', ')}
          </div>
          <div className="mt-8">
            In case you want a backup please download the HR Register from{' '}
            <a href="/reports/hrRegister">
              <LinkButtonV2>here.</LinkButtonV2>
            </a>
          </div>
          <div className="mt-8">
            Please type “Confirm” in the text box and click “Integrate” to enable the integration.
          </div>
        </div>
        <Input
          type="text"
          required
          value={confirmationText}
          name="confirm-text"
          onChange={(event) => setConfirmationText(event.target.value)}
          placeholder="Confirm"
        />
        <InputInfo>Please type "Confirm" to proceed</InputInfo>
        {confirmationError && <WarningAlert className="mt-8">{confirmationError}</WarningAlert>}
        <div className="text-right">
          <StandardButton className="mt-8">Integrate</StandardButton>
        </div>
      </div>
    </form>
  );
};

export default FreshTeamActivationConfirmation;
