import React from 'react';
import { Box, Modal, ModalBody, ModalHeader } from '@razorpay/blade/components';

export type VideoModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  src: string;
};

const VideoModal = ({ isOpen, onDismiss, src }: VideoModalProps) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} size={'medium'}>
      <ModalHeader />
      <ModalBody>
        <Box padding={'spacing.6'}>
          <video playsInline controls src={src} data-testid="video-modal" />
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
