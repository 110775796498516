import React from 'react';
import { ClickableCard } from 'components/ui/SidebarCard';
import { Switch, Route } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

// TODO: get the help URLS from aditya
const ComplianceSideBarElement = () => {
  return (
    // Show nothing till product decides what to show here
    <></>
    // <Switch>
    //   <Route
    //     exact
    //     path={[
    //       routePaths.companyOnboarding.compliance.tds,
    //       routePaths.companyOnboarding.compliance.root,
    //     ]}
    //   >
    //     <ClickableCard heading="Get Help" imageSymbolType={'requests'}>
    //       Know more about TDS compliances
    //     </ClickableCard>
    //   </Route>
    //   <Route exact path={routePaths.companyOnboarding.compliance.pt}>
    //     <ClickableCard heading="Get Help" imageSymbolType={'requests'}>
    //       Know more about PT compliances
    //     </ClickableCard>
    //   </Route>
    //   <Route exact path={routePaths.companyOnboarding.compliance.pf}>
    //     <ClickableCard heading="Get Help" imageSymbolType={'requests'}>
    //       Know more about PF compliances
    //     </ClickableCard>
    //   </Route>
    //   <Route exact path={routePaths.companyOnboarding.compliance.esic}>
    //     <ClickableCard heading="Get Help" imageSymbolType={'requests'}>
    //       Know more about ESIC compliances
    //     </ClickableCard>
    //   </Route>
    // </Switch>
  );
};

export default ComplianceSideBarElement;
