import api from 'api';
import { EMP_ANNUAL_COMPENSATION_DETAILS } from 'components/EmployeeOnboarding/queries';
import { WarningAlert } from 'components/ui/Alert';
import { LinkButtonV2, PrimaryButton, SecondaryButton } from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { pageStateActionEnum, VIEW_UPDATE_COMPENSATION } from '..';
import {
  EmployeeOnboardingAnnualCompensationDataType,
  employeeOnboardingAnnualCompensationPageStateActionType,
} from '../types';
import payrollIcon from 'assets/employeeOnboarding/payroll_icon.svg';
import styles from '../index.module.css';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { format } from 'date-fns';
import { SalaryRevisionValidationResponse } from 'api/employeeOnboarding';
import { toIndianCurrencyString } from 'utils/Numbers';
import { Table } from 'components/ui/Table';
import arrowIcon from 'assets/icon--down-arrow.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import useIsRlopEnabled from 'components/RunPayroll/utils/useIsRlopEnabled';
import useArrearOverrideConfirmations from './useArrearOverrideConfirmations';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

interface Props {
  employeeId: number;
  annualCompensationData: EmployeeOnboardingAnnualCompensationDataType;
  pageStateDispatch: React.Dispatch<employeeOnboardingAnnualCompensationPageStateActionType>;
  effectiveDate: Date;
  validationResponse: SalaryRevisionValidationResponse | null;
}

const ConfirmAnnualCompensationModifiedDetails: React.FC<Props> = ({
  employeeId,
  annualCompensationData,
  pageStateDispatch,
  effectiveDate,
  validationResponse,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const {isRlopFeatureEnabled} = useIsRlopEnabled()

  const [showBreakdown, setShowBreakdown] = useState<boolean>(false);
  
  const mutateUpdateEmployeeAnnualyCompensationMutation = () => {
    updateEmployeeAnnualyCompensationMutation.mutate();
  };

  const updateEmployeeAnnualyCompensationMutation = useMutation(
    () =>
      api.employeeOnboarding.updateEmployeeAnnualyCompensation(employeeId, {
        isCustomSalaryStructure: !!annualCompensationData?.customSalaryStructure,
        basicSalary: annualCompensationData?.salaryModel?.basic ?? 0,
        da: annualCompensationData?.salaryModel?.da ?? 0,
        hra: annualCompensationData?.salaryModel?.hra.actualHRA ?? 0,
        specialAllowance: annualCompensationData?.salaryModel?.specialAllowance ?? 0,
        lta: annualCompensationData?.salaryModel?.lta ?? 0,
        npsEmployerContributionLimit: annualCompensationData?.salaryModel?.npsEmployerContributionLimit ?? 0,
        vpf: annualCompensationData?.salaryModel?.vpf ?? 0,
        annualSalary: annualCompensationData?.annualSalary ?? 0,
        variablePay: annualCompensationData?.variablePay ?? 0,
        isEligibleForEmployerESIContribution:
          annualCompensationData?.isEmployeeESIContributionEnabled ?? false,
        employerESIContribution: annualCompensationData?.salaryModel?.esiContribution ?? 0,
        isEligibleForEmployerPFContribution:
          annualCompensationData?.isEligibleForEmployerPFContribution ?? false,
        employerPFContribution: annualCompensationData?.salaryModel?.pfContribution ?? 0,
        flexiPartnerAllowanceAmount:
          annualCompensationData?.flexiPartnerDetails.flexiPartnerAllowanceAmount ?? 0,
        allowance: annualCompensationData?.customAllowancesDetails?.allowances ?? [],
        deductions: annualCompensationData?.salaryModel.deductions ?? [],
        perquisites: annualCompensationData?.perquisitesDetails?.perquisite ?? null,
        effectiveFrom: format(effectiveDate, 'yyyy-MM-dd'),
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(EMP_ANNUAL_COMPENSATION_DETAILS);
        pageStateDispatch({
          type: pageStateActionEnum.annualCompensationDetailsUpdateState,
          data: true,
        });
      },
      onError: (error: AppError) => {
        const response = getAppErrorDetails(error);
        pageStateDispatch({ type: pageStateActionEnum.errorOnPage, data: response.messageForUser });
        if (response.type === 403) {
          history.replace(routePaths.accessDenied);
        }
        window.scrollTo(0, 0);
      },
    },
  );

 const { openAndConfirmOverride } = useArrearOverrideConfirmations({
   arrearsDistribution: validationResponse?.existingArrearsDistribution,
   onConfirm: () => mutateUpdateEmployeeAnnualyCompensationMutation(),
   arrearMonth: validationResponse?.arrearMonth,
 });

  if (!validationResponse) {
    return null;
  }

  const getArrearsSum = () => {
    if (validationResponse.arrears) {
        // Pf and Esi contribution is not present here
      return validationResponse.arrears.map((item) => item.amount).reduce((a, b) => a + b, 0);
    }
    return 0;
  };

  const handleConfirmClick = () => {
    if (isRlopFeatureEnabled) {
      openAndConfirmOverride();
    } else {
      mutateUpdateEmployeeAnnualyCompensationMutation();
    }
  };

  return (
    <>
      {updateEmployeeAnnualyCompensationMutation.status === 'error' && (
        <WarningAlert
          children={
            updateEmployeeAnnualyCompensationMutation.error?.message ||
            'Something went wrong, please contact support'
          }
        />
      )}
      {validationResponse.salaryModelValidation.map((error: string) => (
        <WarningAlert className="mt-4" children={error} />
      ))}
      <h1 className="font-heading font-bold text-left text-4xl mb-1 mt-2">You are almost done! Verify and confirm </h1>
      <div
        className={`${styles['radiant-border-top']} ${styles['container-bg']} flex flex-col gap-10 mt-4 mb-6`} style={{padding:'24px'}}>
        <div className="flex gap-4 font-black">
          <img loading="lazy" src={payrollIcon} alt="" />
          <h1 className="font-heading font-bold text-left text-3xl mb-1">Salary revision</h1>
        </div>
        <p>
          Revised compensation of{' '}
          <strong>{toIndianCurrencyString(validationResponse.annualSalary, true)}</strong> will be
          be effective {' '}
          <span className="font-bold">
            {effectiveDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short',day:'2-digit' })}
          </span>{' '}
        </p>
      </div>
      <IsFeatureDisabledForRearch feature='isArrearsDisabled'>
      {validationResponse.arrears ? (
        <>
          <div
            className={`${styles['radiant-border-top']} ${styles['container-bg']} flex flex-col gap-6 mt-4 mb-6`} style={{padding:'24px'}}>
            <div className="flex gap-4 font-black">
              <img loading="lazy" src={payrollIcon} alt="" />
              <h1 className="font-heading font-bold text-left text-3xl mb-1">Arrear calculated in seconds 🚀 </h1>
            </div>
            <p>
              Arrear amount of <strong>{toIndianCurrencyString(getArrearsSum(), true)}</strong> will
              be added to{' '}
              <span className="font-bold">
                {(new Date(validationResponse.arrearMonth+"T00:00:00")).toLocaleDateString('en-us', { year: 'numeric', month: 'short',day:'2-digit'})}
              </span>{' '}
              payroll
            </p>
            <div>
              <LinkButtonV2
                className='text-left inline'
                onClick={() => setShowBreakdown(!showBreakdown)}
                id="amount-breakdown">
                View component wise arrears {'  '}
                <img
                  className={`pt-0 pb-0 transition duration-300 ${showBreakdown ? 'transform rotate-180 ' : ''} inline`}
                  src={getStaticMediaUrl(arrowIcon)}
                  onClick={() => setShowBreakdown(!showBreakdown)}
                />
              </LinkButtonV2>
              {
                showBreakdown && (
                  <Table showAlternateColors={false} className="w-full m-0 mt-8">
                    {
                      validationResponse.arrears.map((item) => {
                        return <tr className={`${styles['table-border']}`} >
                          <td className={`${styles['table-border']}`} style={{width:'50%'}} >{item.name}</td>
                          <td className={`${styles['table-border']}`} >{item.amount}</td>
                        </tr>
                      }) 
                    }
                  </Table>
                )
              }
            </div>
          </div>
        </>
      ) : null}
      </IsFeatureDisabledForRearch>
      <div className="flex flex-row justify-between mt-20 mb-20">
        <SecondaryButton
          onClick={() => {
            pageStateDispatch({
              type: pageStateActionEnum.pageView,
              data: VIEW_UPDATE_COMPENSATION,
            });
          }}>
          Back
        </SecondaryButton>
        <PrimaryButton
          style={{ marginTop: '0', paddingLeft: '20px', paddingRight: '20px', fontSize: '0.9375rem' }}
          onClick={handleConfirmClick}
          disabled={updateEmployeeAnnualyCompensationMutation.status === 'loading' || annualCompensationData.pendingWorkflowRequestExists }>
          {' '}
          { annualCompensationData.pendingWorkflowRequestExists ? 'Existing Request Pending' : ( annualCompensationData.workflowEnabled ? 'Request For Approval' : 'Confirm' ) }
          {updateEmployeeAnnualyCompensationMutation.status === 'loading' ? (
            <span style={{ padding: '0.7em 1em' }}>
              <Spinner />
            </span>
          ) : (
            ''
          )}
        </PrimaryButton>
      </div>
    </>
  );
};

export default ConfirmAnnualCompensationModifiedDetails;
