import api from 'api';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { GetCustomReportSchemaContract } from 'schemas/GetCustomReportSchema';
import { customReportStatus } from './constants';
import { GET_CUSTOM_REPORT } from './queries';

type Props = {
  type: string | null;
  limit?: number;
  page?: number;
  sortByKey: null | string;
  sortByOrder: null | string;
  isConfigFetched: boolean;
  customReportId: number | null;
  filters?: { key: string; value: string }[];
  onError: (err: any) => void;
  onSuccess: (data: GetCustomReportSchemaContract) => void;
  onTick: (data?: GetCustomReportSchemaContract) => void;
  isRealTime?: boolean;
};

const useCustomReportData = ({
  type,
  limit = 10,
  page = 1,
  sortByKey,
  sortByOrder,
  customReportId,
  isConfigFetched,
  onError,
  isRealTime = false,
  onSuccess,
  filters = [],
  onTick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const isQueryEnabled = Boolean(type) && isConfigFetched;

  const { isFetching, data, refetch, isError } = useQuery({
    queryFn: () =>
      api.customReport.getCustomReport({
        type,
        limit,
        page,
        sortByKey,
        sortByOrder,
        filters,
      }),
    queryKey: [
      GET_CUSTOM_REPORT,
      type,
      customReportId,
      limit,
      page,
      sortByKey,
      sortByOrder,
      filters,
    ],
    onSuccess: (response) => {
      setIsLoading(false);
      onSuccess(response);
    },
    refetchInterval(data) {
      onTick(data);
      if (data?.status === customReportStatus.STATUS_STARTED) {
        return 3000;
      }
      return false;
    },
    onError,
    enabled: isQueryEnabled,
  });

  useEffect(() => {
    if (isQueryEnabled) {
      setIsLoading(true);
    }
  }, [isQueryEnabled, customReportId, isRealTime]);

  useEffect(() => {
    if (data && isFetching) {
      setIsLoading(true);
    }
  }, [data, isFetching]);

  return { data, isLoading: isFetching || isLoading, refetch, isError };
};

export default useCustomReportData;
