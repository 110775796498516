import React from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import Note from 'components/ui/Note';
import RightSidebar from 'components/ui/RightSidebar';
import { AnchorCard } from 'components/ui/SidebarCard';

const AccessDenied = () => {
  return (
    <PageWrapper>
      <MainCase>
        <h1 className="text-5xl mb-12">403 :(</h1>
        <Note>
          Sorry, it looks like you are not authorized to view this page. If you think this is in
          error, please contact your company administrator, or email us at{' '}
          <a className="text-primary" href="mailto:xpayroll@razorpay.com">
            xpayroll@razorpay.com
          </a>{' '}
          along with steps to replicate the issue.
        </Note>
      </MainCase>
      <RightSidebar>
        <AnchorCard
          href="mailto:xpayroll@razorpay.com"
          imageSymbolType="help"
          heading="Got a Question?"
          newTab
        >
          Please email us at xpayroll@razorpay.com
        </AnchorCard>
      </RightSidebar>
    </PageWrapper>
  );
};

export default AccessDenied;
