import React, { useState } from 'react';
import { CustomWindow, NewCustomWindow } from './types';
import {
    Alert,
    Box,
    Button,
    Divider,
    Heading,
    IconButton,
    IconButtonProps,
    PlusIcon,
    Text,
    TrashIcon,
} from '@razorpay/blade/components';
import { windowTypeNames } from './constants';
import { format, parse } from 'date-fns';
import { dateFormats } from '../../../utils/Dates';
import { LinearGradientBox, Table } from './styles';
import DeleteConfiration from './DeleteConfirmation';
import EmployeeListing from './EmployeeListing';
import CreateCustomWindowModal from './CreateCustomWindowModal';
import NoContactImg from '../../../assets/no_team_members.svg';
import useCustomWindowConfigQuery from '../../Settings/TaxDeclarationAndProofSettings/useCustomWindowConfigQuery';
import { CustomWindowTypeMap, WINDOW_TARGET } from 'components/Settings/TaxDeclarationAndProofSettings/types';
import { BladeProPill } from '../BladeProPill/index';
import Errors from 'components/ui/Form/Errors';



interface TableRowData {
    startDate: string;
    endDate: string;
    type: string;
    employees: Array<CustomWindow>;
}

interface TableRowProps extends TableRowData {
    isDeleteButtonVisible: boolean;
    onDelete: IconButtonProps['onClick'];
    onEdit: IconButtonProps['onClick'];
    onEmployeeCountClick: () => void;
    id: string;
}

const TableRow = ({
    id,
    type,
    startDate,
    endDate,
    employees,
    isDeleteButtonVisible,
    onDelete,
    onEdit,
    onEmployeeCountClick,
}: TableRowProps) => {
    return (
        (<tr>
            <td>
                <Text color="surface.text.gray.muted">{windowTypeNames[type as keyof typeof windowTypeNames]}</Text>
            </td>
            <td>
                <Text color="surface.text.gray.muted">
                    {format(parse(startDate, dateFormats.yearMonthDate, new Date()), dateFormats.dateShortMonthCommaYear)}
                </Text>
            </td>
            <td>
                <Text color="surface.text.gray.muted">
                    {format(parse(endDate, dateFormats.yearMonthDate, new Date()), dateFormats.dateShortMonthCommaYear)}
                </Text>
            </td>
            <td style={{ cursor: 'pointer' }} onClick={onEmployeeCountClick}>
                <Text color="surface.text.primary.normal">
                    {employees.length || 0}
                </Text>
            </td>
            <td>
                {
                    isDeleteButtonVisible &&
                    <Box display="flex" gap="spacing.5" justifyContent="flex-end">
                        <IconButton icon={TrashIcon} accessibilityLabel="Delete" onClick={onDelete} />
                    </Box>
                }
            </td>
        </tr>)
    );
};


const CustomWindowSettings = (
    {
        heading,
        noWindowText,
        description,
        hasProPlan,
        customWindowModalCondition
    }:
        {
            heading?: string;
            noWindowText?: string;
            description?: string;
            hasProPlan?: boolean;
            customWindowModalCondition?: (startDate: Date | null, endDate: Date | null) => string;
        }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);

    const {
        customWindowItems,
        deleteCustomWindowConfig,
        addCustomWindowConfig,
        isLoading
    } = useCustomWindowConfigQuery({
        onAddSuccess: () => {
            setIsOpen(false);
        },
        onDeleteSuccess: () => {
            setIsDeleteConfirmationOpen(false);
        },
        onDeleteError: () => {
            setIsDeleteConfirmationOpen(false);
        },
        onUpdateSuccess: () => { }
    })

    const groupedItems = customWindowItems.reduce((a, c) => {
        const key = c.startDate + c.endDate + c.type;
        const arr = a[key] && a[key].employees ? [...a[key].employees, c] : [c];
        return {
            ...a,
            [key]: {
                startDate: c.startDate,
                endDate: c.endDate,
                type: c.type,
                employees: arr,
            },
        };
    }, {} as Record<string, { startDate: string; endDate: string; type: string; employees: Array<CustomWindow> }>);

    const showDeleteButton = (endDate: Date) => {
        if ((new Date()) > endDate) {
            return false;
        }
        else {
            return true;
        }
    }

    const onDismiss = () => {
        setIsOpen(false);
    };

    const onDeleteConfirmationDismiss = () => {
        setIsDeleteConfirmationOpen(false);
    };

    const onEmployeeListingDismiss = () => {
        setIsEmployeeListOpen(false);
    };

    const handleCreateNewWindowClick = () => {
        setIsOpen(true);
    };

    const onDeleteConfirmation = () => {
        if (selectedRow) {
            deleteCustomWindowConfig.mutate(
                selectedRow.employees.map((e) => e.id as number),
            );
        }
    };

    const handleEmployeeCountClick = (id: string) => {
        setSelectedRow(groupedItems[id]);
        setIsEmployeeListOpen(true);
    };

    const handleDeleteClick = (id: string) => {
        setSelectedRow(groupedItems[id]);
        setIsDeleteConfirmationOpen(true);
    };

    const isTableVisible = customWindowItems.length > 0;

    const onCreateNewWindow = (window: NewCustomWindow) => {
        addCustomWindowConfig.mutate({
            windowType: CustomWindowTypeMap[window.type],
            windowTarget: WINDOW_TARGET.WINDOW_TARGET_CUSTOM_EMPLOYEE,
            customWindows: {
                employeeEmails: window.employees.split(',').filter((e) => e.trim().length > 0),
                endDate: format(window.endDate, dateFormats.yearMonthDate),
                startDate: format(window.startDate, dateFormats.yearMonthDate),
                type: window.type,
            }
        });
    };

    return (
        (<Box marginTop="42px" marginBottom="42px">
            {
                deleteCustomWindowConfig.error &&
                <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" marginY="spacing.5">
                    <Alert
                        description={<Errors errorResponse={deleteCustomWindowConfig.error} />}
                        color={'negative'}
                        isDismissible={false}
                    />
                </Box>
            }
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="column" gap="spacing.2" maxWidth="320px">
                    <Box display="flex" flexDirection="row" gap="spacing.2">
                        <Heading size="small">{heading ?? 'Advance Window'}</Heading>
                        {!hasProPlan ? BladeProPill : undefined}
                    </Box>
                    <Text size="small" color="surface.text.gray.disabled">
                        {description ?? 'Custom windows opened for employees which are currently active'}
                    </Text>
                </Box>
                {
                    isTableVisible &&
                    hasProPlan &&
                    <Button
                        size="small"
                        variant="secondary"
                        icon={PlusIcon}
                        onClick={handleCreateNewWindowClick}
                        marginTop={'spacing.3'}
                        marginBottom={'auto'}>
                        Create new window
                    </Button>
                }
            </Box>
            {
                !isTableVisible &&
                <LinearGradientBox>
                    <Box display={'flex'} flexDirection={'row'} gap={'40px'}>
                        <img src={NoContactImg} width={105} height={105} alt="" />
                        <Box display={'flex'} flexDirection={'column'} gap={'18px'}>
                            <Text
                                weight="semibold"
                            >
                                {noWindowText ? noWindowText : 'No window created'}
                            </Text>
                            {
                                hasProPlan &&
                                <Button
                                    size="small"
                                    variant="secondary"
                                    icon={PlusIcon}
                                    onClick={handleCreateNewWindowClick}>
                                    Create new window
                                </Button>
                            }
                        </Box>
                    </Box>
                </LinearGradientBox>
            }
            {isTableVisible ? (
                <Box
                    marginTop={'spacing.7'}
                    borderColor={'surface.border.gray.subtle'}
                    borderWidth="thin"
                    borderRadius={'medium'}
                    padding={'spacing.7'}
                    zIndex="1"
                    backgroundColor="surface.background.gray.moderate">
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <Text weight="semibold">Window</Text>
                                </th>
                                <th align="left">
                                    <Text weight="semibold">From</Text>
                                </th>

                                <th align="left">
                                    <Text weight="semibold">To</Text>
                                </th>
                                <th>
                                    <Text weight="semibold">Employee(s)</Text>
                                </th>
                                <th>
                                    <Box />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <Divider />
                                </td>
                            </tr>
                            {Object.entries(groupedItems).map(([key, row]) => (
                                <TableRow
                                    key={key}
                                    id={key}
                                    type={row.type}
                                    startDate={row.startDate}
                                    endDate={row.endDate}
                                    employees={row.employees ?? []}
                                    isDeleteButtonVisible={showDeleteButton(new Date(row.endDate))}
                                    onDelete={() => handleDeleteClick(key)}
                                    onEdit={() => null}
                                    onEmployeeCountClick={() => handleEmployeeCountClick(key)}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Box>
            ) : null}
            <CreateCustomWindowModal
                isOpen={isOpen}
                onDismiss={onDismiss}
                onSubmit={onCreateNewWindow}
                isLoading={addCustomWindowConfig.isLoading}
                customWindowModalCondition={customWindowModalCondition}
                creationErrors={addCustomWindowConfig.error}
            />
            <DeleteConfiration
                isOpen={isDeleteConfirmationOpen}
                onDismiss={onDeleteConfirmationDismiss}
                onConfirm={onDeleteConfirmation}
                isLoading={deleteCustomWindowConfig.isLoading}
            />
            <EmployeeListing
                employees={selectedRow?.employees || []}
                isOpen={isEmployeeListOpen}
                onDismiss={onEmployeeListingDismiss}
            />
        </Box>)
    );

}

export default CustomWindowSettings;