import api from 'api';
import { useMutation } from 'react-query';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { showErrorToastNotification } from 'utils/ToastEvents';

const SEND_CHAT_FEEDBACK = 'SEND_CHAT_FEEDBACK';

const useChatFeedback = (onSuccess: () => void) => {
  const sendFeedback = useMutation({
    mutationKey: SEND_CHAT_FEEDBACK,
    mutationFn: (payload: { chatId: string; feedback: string }) =>
      api.payrollAiAssistant.sendFeedback(payload.chatId, payload.feedback),
    onSuccess: (data) => {
        onSuccess();
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 5000,
      });
    },
  });

  return {
    sendFeedback,
  };
};

export { useChatFeedback };
