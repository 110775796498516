import React, { ReactElement } from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ErrorMessage from 'components/ui/ErrorMessage';
import { AppError } from 'utils/AppError';
import { ZOHO_ACTIVATION_STATUS_QUERY } from './queries';
import permissions from 'constants/permissions';
import useHasPermission from 'utils/useHasPermission';
import { WarningAlert } from 'components/ui/Alert';
import Breadcrumb from 'components/ui/Breadcrumb';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { useModal } from 'components/ui/Modals';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import ZohoSyncing from './ZohoSyncing';
import ZohoDashboard from './ZohoDashboard';
import { trackSegment } from 'utils/segment';
import Card from './components/Card';

import styles from './index.module.scss';

interface Props {}

function ZohoSettings({}: Props): ReactElement {
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const hasManageHRMSPermission = useHasPermission([permissions.MANAGE_HRMS_INTEGRATION]);
  const activationQuery = useQuery(
    ZOHO_ACTIVATION_STATUS_QUERY,
    () => {
      return api.zoho.fetchActivationStatus();
    },
    {
      onError: (error: AppError) => {},
      // refetch status every 50 seconds
      refetchInterval: 50 * 1000,
      staleTime: 3000,
    },
  );

  const deactivationMutation = useMutation(() => api.zoho.disable(), {
    onSuccess: (data) => {
      trackSegment('zoho.disable.confirm.click');
      queryClient.invalidateQueries(ZOHO_ACTIVATION_STATUS_QUERY);
      showDeactivationSuccess();
    },
    onError: (error: AppError) => {
      window.scrollTo(0, 0);
    },
  });

  const syncMutation = useMutation(() => api.zoho.sync(), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(ZOHO_ACTIVATION_STATUS_QUERY);
    },
    onError: (error: AppError) => {},
  });

  const handleDeactivation = () => {
    deactivationMutation.mutate();
  };

  const showDeactivationSuccess = () => {
    openModal(
      <ConfirmModal confirmOnly confirmText="Ok">
        <div className="flex flex-col max-w-xl">
          <div className={'text-4xl font-bold text-white mb-8 mt-4'}>Zoho disabled</div>
          <div className="mb-8">
            Your Zoho integration has been disabled. You can reconnect to Zoho anytime from this
            page.
            <br />
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  const syncStatus = activationQuery?.data?.user_data_sync_status;

  const handleSync = () => {
    trackSegment('zoho.sync.click');
    syncMutation.mutate();
  };

  const isUserDataSyncInProgress =
    syncStatus === 'STATUS_SYNC_IN_PROGRESS' || syncStatus === 'STATUS_SYNC_INITIATED';

  if (activationQuery.status === 'loading' || activationQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (activationQuery.status === 'error') {
    return (
      <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
        <ErrorMessage title="Sorry! An error has occured!">
          {activationQuery.error?.message || 'Something went wrong, please contact support'}
        </ErrorMessage>
      </Card>
    );
  }

  if (!activationQuery?.data?.enabled) {
    if (hasManageHRMSPermission) {
      return <Redirect to={routePaths.settings.zoho.introduction} />;
    } else {
      return <Redirect to={routePaths.accessDenied} />;
    }
  } else if (!activationQuery.data.is_user_data_sync_enabled) {
    return <Redirect to={routePaths.settings.zoho.introduction} />;
  }

  return (
    <>
      {(deactivationMutation.status === 'error' || syncMutation.status === 'error') && (
        <WarningAlert className="my-8">
          {deactivationMutation.error?.message ||
            syncMutation.error?.message ||
            'Something went wrong, please contact support'}
        </WarningAlert>
      )}
      {syncStatus === 'STATUS_SYNC_FAILURE' && (
        <WarningAlert className="my-8">
          The data sync process has failed, please try syncing again, or reach out to support for
          help
        </WarningAlert>
      )}
      {isUserDataSyncInProgress ? (
        <ZohoSyncing />
      ) : (
        <ZohoDashboard
          lastFetchedAt={activationQuery?.data?.last_fetched_at}
          handleDisable={handleDeactivation}
          handleSync={handleSync}
          isPendingDeactivation={deactivationMutation.status === 'loading'}
          isPendingSync={syncMutation.status === 'loading'}
        />
      )}
    </>
  );
}

function ZohoSetttingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Zoho People"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase type="fullWidth" className={styles['wrapper']}>
        <ZohoSettings />
      </MainCase>
    </PageWrapper>
  );
}

export default ZohoSetttingsWithPageWrapper;
