import { useSearchParams } from 'hooks/useSearchParams';

const workflowDetailsViewParamKey = 'workflowRequestId';

const useWorkflowDetailsView = () => {
  const { addSearchParams, removeSearchParams, urlParams } = useSearchParams();

  const workflowRequestId = urlParams.get(workflowDetailsViewParamKey);

  const openDetailsView = (requestId: number) => {
    addSearchParams([{ key: workflowDetailsViewParamKey, value: requestId.toString() }]);
  };

  const closeDetailsView = () => {
    removeSearchParams([workflowDetailsViewParamKey]);
  };

  return {
    workflowRequestId,
    openDetailsView,
    closeDetailsView,
  };
};

export { useWorkflowDetailsView };
