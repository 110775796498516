import React, { ReactElement } from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import { PartnerFlexiBenefitsFAQ } from '../PartnerFlexiBenefitsFAQ';
import { PartnerFlexiBenefitsEmployee } from './PartnerFlexiBenefitsEmployee';

function PartnerFlexiBenefitsEmployeeWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase>
        <PartnerFlexiBenefitsEmployee />
      </MainCase>
      <RightSidebar>
        <PartnerFlexiBenefitsFAQ />
      </RightSidebar>
    </PageWrapper>
  );
}

export default PartnerFlexiBenefitsEmployeeWithPageWrapper;
