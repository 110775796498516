import React from 'react';
import { format } from 'date-fns';

import { SuccessAlert, WarningAlertYellow } from 'components/ui/Alert';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import { MessageType, MessageTypes, useMessageContext } from '../contexts/RunPayrollMessageContext';
import { LinkButtonV2 } from 'components/ui/Button';
import PFWarning from '../PFWarning';
import { shouldShowPfWarning } from '../utils/utils';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import { useIsTdsFilingConfigRequired } from '../hooks/useIsTdsFilingConfigRequired';
import { TDSFilingWarning } from './TDSFilingWarning';

const MessageDisplay = () => {
  const data = useRunPayrollDataContext();
  const appConfig = useSelector(loggedInUserSelectors.appConfig);
  const features = useSelector(loggedInUserSelectors.features);
  const isTdsFilingConfigRequired = useIsTdsFilingConfigRequired();

  const isRXIntegrated = useSelector(loggedInUserSelectors.currentOrganization)?.isRXIntegrated;
  const isPayPtOnRunPayrollEnabled = !!features?.enablePayPtOnRunPayroll;
  const enablePfEcRBannerOnRunPayroll = Boolean(appConfig.enablePfEcRBannerOnRunPayroll);

  const messageContext = useMessageContext();
  const message = messageContext?.message;

  if (!data && !message) {
    return null;
  }
  const isPayrollExecuted = (data?.numPaid || 0) > 0;
  const payrollMonth = data?.payrollMonth;
  const isPfWarningVisible = payrollMonth
    ? shouldShowPfWarning(payrollMonth) && isPayrollExecuted && enablePfEcRBannerOnRunPayroll
    : false;

  const getMessage = (message: MessageType) => {
    switch (message.type) {
      case MessageTypes.FINALIZE_IN_PROGRESS: {
        return (
          <WarningAlertYellow className="mb-5">
            Your Payroll is being finalized and will be completed in a few minutes
          </WarningAlertYellow>
        );
      }
      case MessageTypes.FINALIZED:
        const payrollMonth: string = message.data;
        const payrollMonthDate = new Date(payrollMonth + 'T00:00:00');
        return (
          <SuccessAlert className="mb-5">
            Payroll finalized for {format(payrollMonthDate, 'MMMM yyyy')}. You can verify the salary
            register{' '}
            <a
              target="_blank"
              href={`/reports/salaryRegister?pd=${format(payrollMonthDate, 'dd-MM-yy')}`}>
              <LinkButtonV2>here</LinkButtonV2>
            </a>
            .
          </SuccessAlert>
        );

      case MessageTypes.EXECUTION_REQUESTED:
        return <SuccessAlert className="mb-5">Your request has been received.</SuccessAlert>;
    }
    return null;
  };

  if (isPayPtOnRunPayrollEnabled) {
    return null;
  }

  return (
    <div>
      {isTdsFilingConfigRequired && !!data?.isPayrollFinalized && (
        <TDSFilingWarning payrollMonth={payrollMonth} />
      )}

      {isPfWarningVisible ? <PFWarning className="mb-5" payrollMonth={payrollMonth!} /> : null}

      {message ? (
        getMessage(message)
      ) : data?.isExecutionRequested ? (
        <SuccessAlert className="mb-5">
          {isRXIntegrated
            ? 'You have requested execution of this payroll. Your request will be completed within 12 hours.'
            : 'Your payroll execution request has been received. Salary transactions will be processed within 24 hours.'}
        </SuccessAlert>
      ) : null}
    </div>
  );
};

export default MessageDisplay;
