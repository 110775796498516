import React from "react";
import ExternalLink from "../../../ui/ExternalLink";
import {getStaticMediaUrl} from "../../../../utils/Urls";
// @ts-ignore
import zaggleBrochure from "../../../../assets/partnerFlexiBenefits/ZaggleEmployeeBenefitsDeck.pdf";
import iconDownload from "../../../../assets/icon--download.svg";

export function DownloadBrochureSection() {
    return <div className={"text-center"}>
        <ExternalLink target={"_blank"} href={getStaticMediaUrl(zaggleBrochure)}>
            <img className="inline-block" src={getStaticMediaUrl(iconDownload)}/> Want to know more? Download our
            brochure (.pdf)
        </ExternalLink>
    </div>;
}