import { Box, Divider, InfoIcon, Text, UserCheckIcon } from '@razorpay/blade/components';
import React from 'react';

type InformationCardType = 'info' | 'notice';

interface InformationCardProps {
  type?: InformationCardType;
  title: string;
  children: JSX.Element;
}

const InformationCard = ({ type = 'info', title, children }: InformationCardProps) => {
  return (
    (<Box
      display="flex"
      flexDirection="column"
      borderRadius="medium"
      borderWidth="thin"
      borderColor="surface.border.gray.normal">
      <Box display="flex" alignItems="center" gap="spacing.4" padding={['spacing.5', 'spacing.7']}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="spacing.9"
          height="spacing.9"
          backgroundColor="surface.background.gray.moderate"
          borderRadius="medium">
          {type === 'notice' ? (
            <InfoIcon size="large" color="feedback.icon.notice.intense" />
          ) : (
            <UserCheckIcon size="large" color="feedback.icon.information.intense" />
          )}
        </Box>
        <Text weight="semibold">{title}</Text>
      </Box>
      <Divider />
      <Box padding="spacing.7">{children}</Box>
    </Box>)
  );
};

export { InformationCard };
