import React, {ReactElement} from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';

import {PartnerFlexiBenefitsFAQ} from "../PartnerFlexiBenefitsFAQ";
import {ZaggleConnected} from "./ZaggleConnected";

function PartnerFlexiBenefitsAdmin(){
    return ZaggleConnected();
}

function PartnerFlexiBenefitsAdminWithPageWrapper(): ReactElement {
    return (
        <PageWrapper>
            <MainCase>
                <PartnerFlexiBenefitsAdmin/>
            </MainCase>
            <RightSidebar>
                <PartnerFlexiBenefitsFAQ />
            </RightSidebar>
        </PageWrapper>
    );
}

export default PartnerFlexiBenefitsAdminWithPageWrapper;
