import ExpansionPanel from 'components/ui/Accordion/ExpansionPanel';
import { PrimaryButton, SecondaryButton } from 'components/ui/Button';
import DatePicker from 'components/ui/DatePicker';
import { Input } from 'components/ui/Form/Input';
import Label from 'components/ui/Label';
import React from 'react';
import { getNumberFromIndianCurrencyString, toIndianCurrencyString } from 'utils/Numbers';
import {
  pageStateActionEnum,
  VIEW_COMPENSATION_DETAILS,
  VIEW_CONFIRM_UPDATED_COMPENSATION,
} from '..';
import {
  AnnualEmployeeCompensationPerquisitesDataEntityType,
  EmployeeOnboardingAnnualCompensationDataType,
  employeeOnboardingAnnualCompensationPageStateActionType,
  employeeOnboardingAnnualCompensationPageStateType,
} from '../types';
import EmployeeAnnualCompensationCustomSalaryStructure from './EmployeeAnnualCompensationCustomSalaryStructure';
import styles from './index.module.css';
import dateIcon from 'assets/employeeOnboarding/date_icon.svg';
import calendarIcon from 'assets/employeeOnboarding/calendar.svg';
import { WarningAlert } from 'components/ui/Alert';
import { useQuery } from 'react-query';
import { VALIDATE_ANNUAL_COMPENSATION_DETAILS } from 'components/EmployeeOnboarding/queries';
import { AppError } from 'utils/AppError';
import { format } from 'date-fns';
import api from 'api';
import { SalaryRevisionValidationResponse } from 'api/employeeOnboarding';
import Spinner from 'components/ui/Spinner';
import Errors from 'components/ui/Form/Errors';
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../reducers/loggedInUser/selectors";
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { Table } from 'components/ui/Table';
import Pill from 'components/ui/Pill';

// deduction type
const DEDUCTION_TYPE_MANUAL = 0;
const DEDUCTION_TYPE_AUTO_ATTENDANCE_LOP = 1;
const DEDUCTION_TYPE_MANUAL_ATTENDANCE_LOP = 2;
const DEDUCTION_TYPE_FLEXI_PARTNER_WALLET_DEDUCTION = 3;

function structureDeduction(
  deductibleName: string,
  deductibleAmount: number,
  deductibleTaxable: boolean,
  deductibleShowInEarnings: boolean,
  deductibleIsProRated: boolean,
) {
  return {
    name: deductibleName,
    amount: deductibleAmount,
    taxable: !!deductibleTaxable,
    type: DEDUCTION_TYPE_MANUAL,
    showInEarnings: !!deductibleShowInEarnings,
    lopDays: null,
    isProRated: !!deductibleIsProRated,
  };
}

function structurePerquisite(
  perquisiteType: number,
  perquisiteValue: number,
  perquisitePaidByEmployee: number,
) {
  let annualEmployeeCompensationPerquisitesDataEntityType: AnnualEmployeeCompensationPerquisitesDataEntityType =
  {
    type: perquisiteType,
    value: perquisiteValue,
    paidByEmployee: perquisitePaidByEmployee,
  };
  return annualEmployeeCompensationPerquisitesDataEntityType;
}

interface Props {
  employeeId: number;
  annualCompensationData: EmployeeOnboardingAnnualCompensationDataType;
  setAnnualCompensationData: React.Dispatch<
    React.SetStateAction<EmployeeOnboardingAnnualCompensationDataType>
  >;
  pageState: employeeOnboardingAnnualCompensationPageStateType;
  pageStateDispatch: React.Dispatch<employeeOnboardingAnnualCompensationPageStateActionType>;
  effectiveDate: Date;
  setEffectiveDate: React.Dispatch<React.SetStateAction<Date>>;
  setValidationResponse: (response: SalaryRevisionValidationResponse | null) => void;
}

const AnnualCompensationModifyDetails: React.FC<Props> = ({
  employeeId,
  annualCompensationData,
  setAnnualCompensationData,
  pageState,
  pageStateDispatch,
  effectiveDate,
  setEffectiveDate,
  setValidationResponse,
}) => {
  const {isCustomSalaryStructureDisabled} = useIsFeatureDisabledForRearch();
  function insertAndUpdatePerquisites(
    perquisiteType: number,
    perquisiteValue: number,
    perquisitePaidByEmployee: number,
  ) {
    let annualEmployeeCompensationPerquisitesData = structurePerquisite(
      perquisiteType,
      perquisiteValue,
      perquisitePaidByEmployee,
    );
    let perquisiteData = annualCompensationData.perquisitesDetails.perquisite;
    if (perquisiteData == null) {
      perquisiteData = {};
    }
    Object.assign(perquisiteData, { [perquisiteType]: annualEmployeeCompensationPerquisitesData });
    setAnnualCompensationData({
      ...annualCompensationData,
      perquisitesDetails: {
        ...annualCompensationData.perquisitesDetails,
        perquisite: perquisiteData,
      },
    });
  }

  function insertAndUpdateDeduction(
    index: number,
    deductibleName: string,
    deductibleAmount: number,
    deductibleTaxable: boolean,
    deductibleShowInEarnings: boolean,
    deductibleIsProRated : boolean ,
  ) {
    let annualEmployeeCompensationDeductionData = structureDeduction(
      deductibleName,
      deductibleAmount,
      deductibleTaxable,
      deductibleShowInEarnings,
      deductibleIsProRated,
    );
    let deductionData = annualCompensationData.salaryModel.deductions;
    deductionData[index] = annualEmployeeCompensationDeductionData;
    setAnnualCompensationData({
      ...annualCompensationData,
      salaryModel: {
        ...annualCompensationData.salaryModel,
        deductions: deductionData,
      },
    });
  }

  const updateNumber = (event: React.ChangeEvent<HTMLInputElement>, element: string) => {
    const numberValue = getNumberFromIndianCurrencyString(event.target.value);
    if (!isNaN(numberValue)) {
      setAnnualCompensationData({ ...annualCompensationData, [element]: numberValue });
    }
  };

  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const features = currentOrganization?.features||{}

  const salaryRevisionValidation = useQuery(
    VALIDATE_ANNUAL_COMPENSATION_DETAILS,
    () => {
      return api.employeeOnboarding.validateEmployeeAnnualyCompensation(employeeId, {
        isCustomSalaryStructure: !!annualCompensationData?.customSalaryStructure,
        basicSalary: annualCompensationData?.salaryModel?.basic ?? 0,
        da: annualCompensationData?.salaryModel?.da ?? 0,
        hra: annualCompensationData?.salaryModel?.hra.actualHRA ?? 0,
        specialAllowance: annualCompensationData?.salaryModel?.specialAllowance ?? 0,
        lta: annualCompensationData?.salaryModel?.lta ?? 0,
        npsEmployerContributionLimit: annualCompensationData?.salaryModel?.npsEmployerContributionLimit ?? 0,
        vpf: annualCompensationData?.salaryModel?.vpf ?? 0,
        annualSalary: annualCompensationData?.annualSalary ?? 0,
        variablePay: annualCompensationData?.variablePay ?? 0,
        isEligibleForEmployerESIContribution:
          annualCompensationData?.isEmployeeESIContributionEnabled ?? false,
        employerESIContribution: annualCompensationData?.salaryModel?.esiContribution ?? 0,
        isEligibleForEmployerPFContribution:
          annualCompensationData?.isEligibleForEmployerPFContribution ?? false,
        employerPFContribution: annualCompensationData?.salaryModel?.pfContribution ?? 0,
        flexiPartnerAllowanceAmount:
          annualCompensationData?.flexiPartnerDetails.flexiPartnerAllowanceAmount ?? 0,
        allowance: annualCompensationData?.customAllowancesDetails?.allowances ?? [],
        deductions: annualCompensationData?.salaryModel.deductions ?? [],
        perquisites: annualCompensationData?.perquisitesDetails?.perquisite ?? null,
        effectiveFrom: format(effectiveDate, 'yyyy-MM-dd'),
      });
    },
    {
      onSuccess: (apiResponse: SalaryRevisionValidationResponse) => {
        setValidationResponse(apiResponse);
        pageStateDispatch({
          type: pageStateActionEnum.pageView,
          data: VIEW_CONFIRM_UPDATED_COMPENSATION,
        });
      },
      onError: (error: typeof AppError) => { },
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const validateAndRedirect = () => {
    salaryRevisionValidation.refetch();
  };

  return (
    <>
      <div>
        {annualCompensationData.validationErrors.map((error: string) => (
          <WarningAlert className="mt-2" children={error} />
        ))}
      </div>
      {pageState?.errorOnPage &&
        pageState.errorOnPage.map((error: string) => (
          <WarningAlert className="mt-2" children={error} />
        ))}
      <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>
        Revise compensation for {annualCompensationData.name} ⚡️
      </h2>

      <div className="flex gap-10 flex-col">
      <IsFeatureDisabledForRearch feature='isCustomSalaryStructureDisabled'>
        <div className="flex flex-row gap-2">
          <Input
            type="checkbox"
            name="custom-salary"
            id="custom-salary"
            checked={annualCompensationData?.customSalaryStructure}
            onChange={() => {
              setAnnualCompensationData({
                ...annualCompensationData,
                customSalaryStructure: !annualCompensationData?.customSalaryStructure,
              });
            }}
          />
          <Label htmlFor="custom-salary">Create a custom salary structure</Label>
        </div>
        </IsFeatureDisabledForRearch>

        {(annualCompensationData?.customSalaryStructure && !isCustomSalaryStructureDisabled) ? (
          <EmployeeAnnualCompensationCustomSalaryStructure
            annualCompensationData={annualCompensationData}
            setAnnualCompensationData={setAnnualCompensationData}
          />
        ) : (
          <div className="prompt--text">
            <p className="text-2xl mb-3">Annual Salary (without variable pay)</p>
            <Input
              type="text"
              placeholder=" Enter Annual Salary"
              className="pl-5 pt-4 pb-4"
              onChange={(event) => {
                updateNumber(event, 'annualSalary');
              }}
              value={toIndianCurrencyString(annualCompensationData?.annualSalary ?? 0, true)}
            />
            <small className="omnipresent">
              Annual salary is the current CTC (cost to company) for this employee, not including
              variable pay. If you have a monthly CTC, please multiply by 12. XPayroll will
              automatically create an ideal salary breakup.
            </small>
          </div>
        )}
        <IsFeatureDisabledForRearch feature='isVariablePayDisabled'>
        <div className="prompt--text">
          <p className="text-2xl mb-3">Variable Pay</p>
          <Input
            type="text"
            placeholder=" Enter Variable Pay"
            className="pl-5 pt-4 pb-4"
            onChange={(event) => {
              updateNumber(event, 'variablePay');
            }}
            value={toIndianCurrencyString(annualCompensationData?.variablePay ?? 0, true)}
          />
          <small className="omnipresent">
            Annual bonus, incentive etc. that is not included in the monthly salary by default. This
            is in addition to the amount specified above.
          </small>
        </div>
        </IsFeatureDisabledForRearch>

        <IsFeatureDisabledForRearch feature='isVPFDisabled'>
        <ExpansionPanel
          panelId={4}
          isOpen={pageState.showVpf}
          onHeaderClick={() =>
            pageStateDispatch({ type: pageStateActionEnum.showVpf, data: !pageState.showVpf })
          }
          header={'Add Voluntary PF (VPF)'}>
          <div className="prompt--text">
            <Input
              type="text"
              placeholder=" Enter Voluntary PF"
              className="pl-5 pt-4 pb-4"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                if (!isNaN(numberValue)) {
                  setAnnualCompensationData({
                    ...annualCompensationData,
                    salaryModel: { ...annualCompensationData.salaryModel, vpf: numberValue },
                  });
                }
              }}
              value={toIndianCurrencyString(annualCompensationData?.salaryModel?.vpf ?? 0, true)}
            />
            <small className="omnipresent">
              Monthly voluntary PF contribution above and beyond the regular EPF contribution.
            </small>
          </div>
        </ExpansionPanel>
        </IsFeatureDisabledForRearch>

        { annualCompensationData.loanPerquisiteDetails.length > 0 &&
        <ExpansionPanel
          panelId={5}
          isOpen={pageState.showLoanPerquisites}
          onHeaderClick={() =>
            pageStateDispatch({
              type: pageStateActionEnum.showLoanPerquisites,
              data: !pageState.showLoanPerquisites,
            })
          }
          header={'Loan Perquisites'}>
          <div id="div-perks" className="prompt--text">
            <table className="border border-solid border-table-border">
              <thead>
                <tr>
                  <th className="align-middle mx-0">Loan Name</th>
                  <th className="align-middle mx-0">Total Loan Amount</th>
                  <th className="align-middle mx-0">Total Perquisite Amount</th>
                  <th className="align-middle mx-0">Perquisite Rate</th>
                </tr>
              </thead>
              <tbody>
              {
                  annualCompensationData.loanPerquisiteDetails.map( item => {
                    return (
                  <tr key={item.loanName}>
                    <td className="align-middle mx-0">{item.loanName}</td>
                    <td className="align-middle mx-0">{toIndianCurrencyString(item.loanAmount, true)}</td>
                    <td className="align-middle mx-0">{toIndianCurrencyString(item.totalPerquisiteAmount ?? 0, true)}</td>
                    <td className="align-middle mx-0">{`${item.perquisiteRate ?? 0.0}%`}</td>
                  </tr>)
                })
                }
              </tbody>
            </table>
          </div>
        </ExpansionPanel>
}

        <IsFeatureDisabledForRearch feature='isPerquisiteDisabled'>
        <ExpansionPanel
          panelId={6}
          isOpen={pageState.showPerquisites}
          onHeaderClick={() =>
            pageStateDispatch({
              type: pageStateActionEnum.showPerquisites,
              data: !pageState.showPerquisites,
            })
          }
          header={'Perquisites'}>
          <div id="div-perks" className="prompt--text">
            <Table className="border border-solid border-table-border" showAlternateColors={false}>
              <thead>
                <tr>
                  <th className="align-middle">Type</th>
                  <th className="align-middle">Total Value (annual)</th>
                  <th className="align-middle">Amount Paid by Employee</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(
                  annualCompensationData.perquisitesDetails.perquisitesWithTypeAndDescription,
                ).map((item: string, index: number) => (
                  <tr key={index}>
                    <td>
                      {
                        annualCompensationData.perquisitesDetails.perquisitesWithTypeAndDescription[
                        item
                        ]
                      }
                    </td>
                    <td>
                      <Input
                        type="text"
                        name={`perk-${item}-value`}
                        className="pl-5 pt-4 pb-4"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                          if (!isNaN(numberValue)) {
                            insertAndUpdatePerquisites(
                              Number(item),
                              numberValue,
                              annualCompensationData?.perquisitesDetails?.perquisite
                                ? Object.keys(
                                  annualCompensationData?.perquisitesDetails?.perquisite ?? [],
                                ).includes(item)
                                  ? annualCompensationData?.perquisitesDetails?.perquisite[
                                    Number(item)
                                  ].paidByEmployee
                                  : 0
                                : 0,
                            );
                          }
                        }}
                        value={
                          annualCompensationData?.perquisitesDetails?.perquisite
                            ? Object.keys(
                              annualCompensationData?.perquisitesDetails?.perquisite ?? [],
                            ).includes(item)
                              ? toIndianCurrencyString(
                                annualCompensationData?.perquisitesDetails?.perquisite[
                                  Number(item)
                                ].value ?? 0,
                                true,
                              )
                              : 0
                            : 0
                        }
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        name={`perk-${item}-employee-amount`}
                        className="pl-5 pt-4 pb-4"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                          if (!isNaN(numberValue)) {
                            insertAndUpdatePerquisites(
                              Number(item),
                              annualCompensationData?.perquisitesDetails?.perquisite
                                ? Object.keys(
                                  annualCompensationData?.perquisitesDetails?.perquisite ?? [],
                                ).includes(item)
                                  ? annualCompensationData?.perquisitesDetails?.perquisite[
                                    Number(item)
                                  ].value
                                  : 0
                                : 0,
                              numberValue,
                            );
                          }
                        }}
                        value={
                          annualCompensationData?.perquisitesDetails?.perquisite
                            ? Object.keys(
                              annualCompensationData?.perquisitesDetails?.perquisite ?? [],
                            ).includes(item)
                              ? toIndianCurrencyString(
                                annualCompensationData?.perquisitesDetails?.perquisite[
                                  Number(item)
                                ].paidByEmployee ?? 0,
                                true,
                              )
                              : 0
                            : 0
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ExpansionPanel>
        </IsFeatureDisabledForRearch>

        <IsFeatureDisabledForRearch feature='isDeductibleBenefitsDisabled'>
        <ExpansionPanel
          panelId={7}
          isOpen={pageState.showDeductibleBenefits}
          onHeaderClick={() =>
            pageStateDispatch({
              type: pageStateActionEnum.showDeductibleBenefits,
              data: !pageState.showDeductibleBenefits,
            })
          }
          header={'Deductible Benefits'}>
          <div id="div-benefits">
            <p className="note">
              This section is only for adding deductions that should be included in an employee's
              CTC (like medical insurance). For any loss of pay or reduction in salary, please add a
              deduction on the <strong>Run Payroll</strong> page. <br /> <br />
              <strong>
                Do not add any compliance related deductions (PT, PF, TDS, PT etc) here either.
              </strong>
            </p>
            <Table className="border border-solid border-table-border" showAlternateColors={false} >
              <thead>
                <tr>
                  <th className="align-middle">Label</th>
                  <th className="align-middle">Monthly Amount</th>
                  <th className="align-middle">Taxable</th>
                  <th className="align-middle">Separate Head</th>
                  <th className="align-middle">ProRated?</th>
                </tr>
              </thead>
              <tbody>
                {new Array(
                  annualCompensationData.deductibleBenefitsDetails.countOfDeductibleBenefits,
                )
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          type="text"
                          placeholder="enter deduction label"
                          className="pl-5 pt-4 pb-4"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            insertAndUpdateDeduction(
                              index,
                              event.target.value,
                              annualCompensationData?.salaryModel?.deductions[index]?.amount,
                              annualCompensationData?.salaryModel?.deductions[index]?.taxable,
                              annualCompensationData?.salaryModel?.deductions[index]
                                ?.showInEarnings,
                              annualCompensationData?.salaryModel?.deductions[index]
                                  ?.isProRated ?? false,
                            );
                          }}
                          value={annualCompensationData?.salaryModel?.deductions[index]?.name}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          placeholder=" Enter Amount"
                          className="pl-5 pt-4 pb-4"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const numberValue = getNumberFromIndianCurrencyString(
                              event.target.value,
                            );
                            if (!isNaN(numberValue)) {
                              insertAndUpdateDeduction(
                                index,
                                annualCompensationData?.salaryModel?.deductions[index]?.name,
                                numberValue,
                                annualCompensationData?.salaryModel?.deductions[index]?.taxable,
                                annualCompensationData?.salaryModel?.deductions[index]
                                  ?.showInEarnings,
                                  annualCompensationData?.salaryModel?.deductions[index]
                                      ?.isProRated ?? false,
                              );
                            }
                          }}
                          value={
                            annualCompensationData?.salaryModel?.deductions[index]?.amount
                              ? toIndianCurrencyString(
                                annualCompensationData?.salaryModel?.deductions[index]?.amount ??
                                0,
                                true,
                              )
                              : 0
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="checkbox"
                          className="pl-5 pt-4 pb-4"
                          onChange={(event) => {
                            insertAndUpdateDeduction(
                              index,
                              annualCompensationData?.salaryModel?.deductions[index]?.name ?? '',
                              annualCompensationData?.salaryModel?.deductions[index]?.amount ?? 0,
                              !annualCompensationData?.salaryModel?.deductions[index]?.taxable,
                              annualCompensationData?.salaryModel?.deductions[index]
                                ?.showInEarnings,
                                annualCompensationData?.salaryModel?.deductions[index]
                                    ?.isProRated ?? false,

                            );
                          }}
                          checked={annualCompensationData?.salaryModel?.deductions[index]?.taxable}
                        />
                      </td>
                      <td>
                        <Input
                          type="checkbox"
                          className="pl-5 pt-4 pb-4"
                          onChange={(event) => {
                            insertAndUpdateDeduction(
                              index,
                              annualCompensationData?.salaryModel?.deductions[index]?.name ?? '',
                              annualCompensationData?.salaryModel?.deductions[index]?.amount ?? 0,
                              annualCompensationData?.salaryModel?.deductions[index]?.taxable,
                              !annualCompensationData?.salaryModel?.deductions[index]
                                ?.showInEarnings,
                                annualCompensationData?.salaryModel?.deductions[index]
                                    ?.isProRated ?? false,
                            );
                          }}
                          checked={
                            annualCompensationData?.salaryModel?.deductions[index]?.showInEarnings
                          }
                        />
                      </td>
                      <td>
                        <Input
                            type="checkbox"
                            className="pl-5 pt-4 pb-4"
                            onChange={(event) => {
                              insertAndUpdateDeduction(
                                  index,
                                  annualCompensationData?.salaryModel?.deductions[index]?.name ?? '',
                                  annualCompensationData?.salaryModel?.deductions[index]?.amount ?? 0,
                                  annualCompensationData?.salaryModel?.deductions[index]?.taxable,
                                  annualCompensationData?.salaryModel?.deductions[index]
                                      ?.showInEarnings,
                                  !annualCompensationData?.salaryModel?.deductions[index]
                                      ?.isProRated ?? false,
                              );
                            }}
                            checked={
                              annualCompensationData?.salaryModel?.deductions[index]?.isProRated
                            }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ExpansionPanel>
        </IsFeatureDisabledForRearch>

        <div className="flex flex-col gap-8">
          <div className="flex justify-between gap-4">
            <div className="flex flex-row justify-between gap-2 mt-4 mb-8">
              <h1 className="font-heading font-bold text-left text-4xl">
                Add Salary effective date
              </h1>
              <Pill
                type='success'
                content='NEW'
              >
                NEW
              </Pill>
            </div>
            <h5 className="to-gray-500 mt-4">Optional</h5>
          </div>
          <div className={`flex flex-col p-6 pl-8 pr-8 ${styles['container-bg']}`}>
            <ul className='list-disc ml-10'>
              <li className="mt-2">
                By default, salary is effective from start of the current month.
              </li>
              <li className="mt-2">
                Add a salary effective date if the above revised salary is effective from a previous or future date. ⚡️
              </li>
              <IsFeatureDisabledForRearch feature='isArrearsDisabled'>
              <li className="mt-2">
                We will auto compute arrears (if applicable) for earnings and deductible benefits.
              </li>
              <li className="mt-2">
                To edit arrear amounts, go to <i>Run Payroll {'>'} Edit </i>              </li>
                </IsFeatureDisabledForRearch>
            </ul>
          </div>
          {pageState.showEffectiveDate ? (
            <div>
              <p className="mb-4">Add salary effective date </p>
              <div className="flex relative cursor-pointer">
                <DatePicker
                  id={`salary-effective-date`}
                  className={`${styles['date-picker']} rounded-md p-4 cursor-pointer`}
                  value={effectiveDate}
                  onChange={(value) => {
                    setEffectiveDate(value ?? new Date());
                  }}
                />
                <img
                  className="absolute cursor-pointer"
                  style={{ top: '12px', right: '12px', height: '16px', width: '16px' }}
                  loading="lazy"
                  src={dateIcon}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div
              className="flex cursor-pointer gap-2 items-center"
              onClick={() =>
                pageStateDispatch({ type: pageStateActionEnum.showEffectiveDate, data: true })
              }>
              <img loading="lazy" src={calendarIcon} alt="" style={{ height: '16px', width: '16px' }} />{' '}
              <p className='text-interactiveTextPrimaryNormal text-200'>Add salary effective date</p>
            </div>
          )}
        </div>

        {salaryRevisionValidation.error ? (
          <WarningAlert>
            <Errors errorResponse={salaryRevisionValidation.error} />
          </WarningAlert>
        ) : null}
        <div className="flex flex-row justify-between mt-20 mb-20">
          <SecondaryButton
            onClick={() => {
              pageStateDispatch({
                type: pageStateActionEnum.pageView,
                data: VIEW_COMPENSATION_DETAILS,
              });
            }}>
            Back
          </SecondaryButton>
          <PrimaryButton
            className={`${styles['employee-annual-compensation-continue-button']}`}
            onClick={validateAndRedirect}
            disabled={salaryRevisionValidation.isLoading || salaryRevisionValidation.isRefetching}>
            {' '}
            Next
            {salaryRevisionValidation.isLoading || salaryRevisionValidation.isRefetching ? (
              <span style={{ padding: '0.7em 1em' }}>
                <Spinner />
              </span>
            ) : (
              ''
            )}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default AnnualCompensationModifyDetails;
