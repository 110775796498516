import React, { useMemo } from 'react';
import { Approver, WorkflowLevelConfig } from '../types';
import {
  ActionList,
  ActionListItem,
  Box,
  Button,
  CloseIcon,
  Dropdown,
  DropdownOverlay,
  Heading,
  IconButton,
  SelectInput,
  Text,
  UserCheckIcon,
  UserIcon,
} from '@razorpay/blade/components';
import { NamePill } from './NamePill';
import { BladeTypeAhead } from './BladeTypeAhead';
import api from 'api';
import useReduxSelector from 'utils/useReduxSelector';
import userRoles from 'constants/userRoles';

interface WorkflowCardProps {
  workflowConfig?: WorkflowLevelConfig;
  updateWorkflowConfig: (config: WorkflowLevelConfig) => void;
  level: number;
  showCloseIcon?: boolean;
  showEditButton?: boolean;
  onCloseIconClick?: () => void;
  onSaveButtonClick?: () => void;
  onEditButtonClick?: () => void;
}

interface PeopleSearchResultItem {
  name: string;
  id: number;
  role: string;
}

const WorkflowLevelCard = ({
  workflowConfig = {
    approvers: [],
    minApprovals: 1,
    isSaved: false,
  },
  updateWorkflowConfig,
  level,
  showCloseIcon,
  showEditButton,
  onCloseIconClick,
  onSaveButtonClick,
  onEditButtonClick,
}: WorkflowCardProps) => {
  const isEditMode = !workflowConfig.isSaved;
  const orgId = useReduxSelector(
    (rootState) => rootState.loggedInUser.data?.currentOrganization?.id as number,
  );

  const minApprovalsRequired = workflowConfig.minApprovals;
  const approvers = workflowConfig.approvers;
  const canShowCloseIcon = showCloseIcon && isEditMode;
  const canShowEditButton = !isEditMode && showEditButton;
  const isSaveCtaDisabled = !minApprovalsRequired || !approvers?.length;

  const summaryText = useMemo(() => {
    const names = approvers.map(({ name }) => name.split('(')[0].trim());
    let summaryText = '';
    if (names.length === 1) {
      summaryText = `⚡️ ${names[0]} has to approve at Level ${level}`;
    }

    if (names.length === 2) {
      if (minApprovalsRequired === 1) {
        summaryText = `⚡️ ${names[0]} or ${names[1]} has to approve at Level ${level}`;
      }

      if (minApprovalsRequired === 2) {
        summaryText = `⚡️ Both ${names[0]} and ${names[1]} have to approve at Level ${level}`;
      }
    }

    if (names.length > 2) {
      if (minApprovalsRequired === names.length) {
        summaryText = `⚡️ All ${minApprovalsRequired} of `;
      } else {
        summaryText = `⚡️ Either ${minApprovalsRequired} of `;
      }
      for (let i = 0; i < names.length; i++) {
        const firstName = names[i].split(' ')[0];
        if (i === 0) {
          summaryText += firstName;
          continue;
        }

        if (i === names.length - 1) {
          summaryText += ` & ${firstName}`;
          continue;
        }

        summaryText += `, ${firstName}`;
      }

      summaryText += ` have to approve at Level ${level}`;
    }

    return summaryText;
  }, [approvers, level, minApprovalsRequired]);

  const handleCloseIconClick = () => {
    onCloseIconClick && onCloseIconClick();
  };

  const handleEditButtonClick = () => {
    onEditButtonClick && onEditButtonClick();
  };

  const handleNamePillCloseIconClick = (pillData: Approver) => {
    const reducedApprovers = approvers?.filter((approver) => approver.id !== pillData.id) ?? [];
    const newMinApprovals = Math.min(workflowConfig.minApprovals, reducedApprovers.length);
    const updatedWorkflowConfig: WorkflowLevelConfig = {
      ...workflowConfig,
      approvers: approvers?.filter((approver) => approver.id !== pillData.id) ?? [],
      minApprovals: newMinApprovals,
    };
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const handleMinApprovalsSelection = (minApprovals: string) => {
    const updatedWorkflowConfig = {
      ...workflowConfig,
      minApprovals: Number(minApprovals),
    };
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const handleSaveWorkflowCtaClicked = () => {
    onSaveButtonClick && onSaveButtonClick();
  };

  const handleApproverSelection = ({ name, id }: PeopleSearchResultItem) => {
    const updatedWorkflowLevelConfig: WorkflowLevelConfig = {
      ...workflowConfig,
      approvers: [
        ...((approvers?.filter((approver) => approver.id !== id) as Approver[]) ?? []),
        {
          name,
          id,
        },
      ],
      minApprovals: workflowConfig.minApprovals > 0 ? workflowConfig.minApprovals : 1,
    };
    updateWorkflowConfig(updatedWorkflowLevelConfig);
  };

  const typeAheadSearchFn = (searchText: string): Promise<PeopleSearchResultItem[]> => {
    return api.people
      .search({
        search: searchText,
        orgId,
        onlyActive: true,
        userRoleBlacklist: [userRoles.EMPLOYEE]
      })
      .then((searchResults) =>
        searchResults.map((user) => ({
          name: user.label,
          id: user.key,
          role: user.role || '',
        })),
      );
  };

  const cardHeaderContent = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      padding="spacing.7"
      borderBottomWidth="thin"
      borderBottomColor="surface.border.gray.normal">
      <Box display="flex" gap="spacing.4">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="spacing.9"
          height="spacing.9"
          backgroundColor="surface.background.gray.moderate"
          borderRadius="medium">
          <UserCheckIcon
            size="large"
            color={
              isEditMode
                ? 'feedback.icon.information.intense'
                : 'interactive.icon.gray.normal'
            }
          />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Text size="xsmall" color="surface.text.gray.muted">
            {isEditMode ? 'Add approvers' : 'Added approvers'}
          </Text>
          <Text size="large">Level {level}</Text>
        </Box>
      </Box>
      {canShowCloseIcon && (
        <Box height="100%">
          <IconButton
            icon={CloseIcon}
            size="medium"
            onClick={handleCloseIconClick}
            accessibilityLabel="close icon"
          />
        </Box>
      )}
      {canShowEditButton && (
        <Button size="small" variant="tertiary" onClick={handleEditButtonClick}>
          Edit
        </Button>
      )}
    </Box>
  );

  const savedModeCardContent = (
    <Box
      display="flex"
      flexDirection="column"
      gap="spacing.4"
      flexGrow={1}
      padding={['spacing.7', 'spacing.7', 'spacing.8']}>
      <Text variant="caption" size='small'>{summaryText}</Text>
      <Box display="flex" flexWrap="wrap" gap="spacing.3">
        {approvers?.map(({ name, id }) => {
          // parsing the name to avoid adding the employee id in NamePill to keep it aligned with the people_metadata received from workflow APIs
          const parsedName = name.split('(')[0].trim();
          return <NamePill key={id} name={parsedName} showCloseIcon={false} />;
        })}
      </Box>
    </Box>
  );

  const resultItemView = ({ data }: { data: PeopleSearchResultItem }) => {
    return (
      <Box display="flex" flexDirection="column">
        <Text>{data.name}</Text>
        <Text size="small">{data.role}</Text>
      </Box>
    );
  };

  const editModeCardContent = (
    <Box
      display="flex"
      flexDirection="column"
      gap="spacing.8"
      flexGrow={1}
      padding={['spacing.7', 'spacing.7', 'spacing.8']}>
      <Box display="flex" flexDirection="column" gap="spacing.4" flexGrow={1}>
        <BladeTypeAhead
          label=""
          placeholder="Search and add approvers"
          onSearchItemClick={handleApproverSelection}
          searchFn={typeAheadSearchFn}
          resultItemView={resultItemView}
          focusOnMount
        />
        {approvers?.length ? (
          <Box display="flex" flexWrap="wrap" gap="spacing.3">
            {approvers?.map(({ name, id }) => {
              // parsing the name to avoid adding the employee id in NamePill to keep it aligned with the people_metadata received from workflow APIs
              const parsedName = name.split('(')[0].trim();
              return (
                <NamePill
                  key={id}
                  name={parsedName}
                  showCloseIcon={true}
                  onClose={() => {
                    handleNamePillCloseIconClick({ name, id });
                  }}
                />
              );
            })}
          </Box>
        ) : null}
      </Box>
      <Dropdown>
        <SelectInput
          label="Min. Approver (s)"
          helpText={summaryText}
          icon={UserIcon}
          value={minApprovalsRequired?.toString()}
          onChange={({ values }) => {
            handleMinApprovalsSelection(values[0]);
          }}
          isDisabled={approvers.length === 0}
        />
        <DropdownOverlay>
          <ActionList>
            {approvers.map((_, index) => (
              <ActionListItem key={index} title={`${index + 1}`} value={`${index + 1}`} />
            ))}
          </ActionList>
        </DropdownOverlay>
      </Dropdown>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleSaveWorkflowCtaClicked} isDisabled={isSaveCtaDisabled}>
          Done
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderColor="surface.border.gray.normal"
      borderWidth="thin"
      borderRadius="large"
      backgroundColor="surface.background.gray.intense"
      minWidth="556px"
      maxWidth="556px">
      {cardHeaderContent}
      {isEditMode ? editModeCardContent : savedModeCardContent}
    </Box>
  );
};

export { WorkflowLevelCard };
