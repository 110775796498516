import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';

import styles from './index.module.scss';

const FNFPayroll = ({
  payrollId,
  onError,
  payrollMonth,
}: { payrollId: number; payrollMonth: string } & SetErrorMessageType) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const skip = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runPayroll.bulkSkipPayroll(payrollMonth, [payrollId]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const skipAllExceptThis = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runPayroll.skipAllExceptThisPayroll(payrollId, payrollMonth);
    },
    {
      onSuccess: () => {
        closeModal();
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Edit Salary
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <p className="mb-16">
            This is a full and final settlement. To make changes, please cancel dismissal of the
            employee, and then dismiss them again to go through the F&F inputs.
          </p>
          <div className='flex items-center gap-8'>
            <StandardSecondaryButton
              onClick={() => skip.mutate({ payrollId })}
              isPending={skip.isLoading}
              disabled={skip.isLoading || skipAllExceptThis.isLoading}>
              Do Not Pay
            </StandardSecondaryButton>
            <StandardSecondaryButton
              onClick={() => skipAllExceptThis.mutate({ payrollId })}
              isPending={skipAllExceptThis.isLoading}
              disabled={skip.isLoading || skipAllExceptThis.isLoading}>
              Skip All Except This
            </StandardSecondaryButton>
            <StandardButton 
              onClick={() => closeModal()}
              disabled={skip.isLoading || skipAllExceptThis.isLoading}
            >Cancel</StandardButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FNFPayroll;
