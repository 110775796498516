import { ArrowUpRightIcon, Box, Button, RefreshIcon, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import noTaskFound from 'assets/noTaskFound.png';
import styled from 'styled-components';
import { getStaticMediaUrl } from 'utils/Urls';
import { WorkflowTypesMap } from '../../constants';
import { useHistory } from 'react-router-dom';
import { WorkflowPathRouteMap } from 'components/ApprovalWorkflows/CreateWorkflow/constants';
import { Values } from 'types/utils';
import useHasPermission from 'utils/useHasPermission';
import permissions from 'constants/permissions';

interface NoResultsTableViewProps {
  title?: ReactNode;
  description?: ReactNode;
  onRefresh?: () => void;
  workflowExists?: boolean;
  workflowType: Values<typeof WorkflowTypesMap>;
}

const NoTaskFoundImage = styled.img`
  width: 180px;
  height: 180px;
`;

const NoResultsTableView = ({
  title = 'You’re all caught up!',
  description = 'No pending requests to show',
  onRefresh,
  workflowExists,
  workflowType
}: NoResultsTableViewProps) => {
  const showRefreshCta = !!onRefresh;
  const history = useHistory();
  const hasManageWorkflowPermission = useHasPermission([permissions.MANAGE_WORKFLOW]);

  return (workflowExists ? <Box
    width="100%"
    height="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap="spacing.7"
    backgroundColor="surface.background.gray.moderate">
    <NoTaskFoundImage src={getStaticMediaUrl(noTaskFound)} />
    <Box display="flex" flexDirection="column" alignItems="center" gap="spacing.2">
      <Text weight="semibold" size="large">
        {title}
      </Text>
      <Text>{description}</Text>
    </Box>
    {showRefreshCta && (
      <Button size="large" icon={RefreshIcon} iconPosition="right" onClick={onRefresh}>
        Refresh
      </Button>
    )}
  </Box> : <Box
    width="100%"
    height="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap="spacing.7"
    backgroundColor="surface.background.gray.moderate">
    <NoTaskFoundImage src={getStaticMediaUrl(noTaskFound)} />
    <Box display="flex" flexDirection="column" alignItems="center" gap="spacing.2">
      <Text weight="semibold" size="large">
        No Workflow Found
      </Text>
      <Text>Please create a workflow from the settings page</Text>
    </Box>
    {showRefreshCta && hasManageWorkflowPermission && (
      <Button size="large" icon={ArrowUpRightIcon} iconPosition="right" onClick={() => { history.push(WorkflowPathRouteMap[workflowType]) }}>
        Create Workflow
      </Button>
    )}
  </Box>);
};

export { NoResultsTableView };
