import {
  Alert,
  Box,
  Button,
  Checkbox,
  Heading,
  InfoIcon,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import styled from 'styled-components';
import { pastPayrollExecutionHelpLink } from '../constants';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import { getYear } from 'date-fns';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.feedback.background.information.subtle};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: auto;
`;

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onProceed: () => void;
  onCancel: () => void;
};

const SkipConfirmationModal = ({
  isOpen,
  onOpenChange,
  onCancel,
  onProceed,
}: Props): React.ReactElement => {
  const data = useRunPayrollDataContext();
  const skippedEmployees = data.skippedEmployeeCountForFY;
  const [isChecked, setIsChecked] = React.useState(false);
  const payrollMonth = data.payrollMonth;
  const yearFromPayrollMonth = payrollMonth.split('-')[0];

  return (
    <Modal isOpen={isOpen} onDismiss={() => onOpenChange(false)} size="small">
      <ModalHeader />
      <ModalBody>
        <Box display="flex" flexDirection="column" gap="spacing.4" alignItems="center">
          <Wrapper>
            <InfoIcon size="xlarge" color="surface.icon.primary.normal" />
          </Wrapper>
          <Heading size="medium" weight="semibold" textAlign="center">
            Skip {skippedEmployees} pending payroll?
          </Heading>

          <Text color="surface.text.gray.muted" textAlign="center">
            There are {skippedEmployees} pending payroll in this financial year.
          </Text>
          <Button
            href={`${routePaths.customReport.root}?type=15&payrollMonth=${payrollMonth}`}
            target="_blank"
            variant="tertiary"
            size="xsmall">
            View pending report
          </Button>
        </Box>
        <Alert
          icon={InfoIcon}
          isDismissible={false}
          description={
            <>
              Once March {yearFromPayrollMonth} payroll is run, previous pending payroll cannot be
              executed. <Link href={pastPayrollExecutionHelpLink}>Learn more</Link>
            </>
          }
          color="notice"
          emphasis="subtle"
          marginY="spacing.6"
        />
        <Checkbox isChecked={isChecked} onChange={() => setIsChecked(!isChecked)}>
          I understand the pending payroll restrictions.{' '}
        </Checkbox>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="space-between" gap="spacing.7">
          <Button size="small" variant="tertiary" isFullWidth onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="primary"
            isFullWidth
            isDisabled={!isChecked}
            onClick={onProceed}>
            Proceed
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export default SkipConfirmationModal;
