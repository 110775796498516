import { GetMeApiSchemaContract } from 'schemas/GetMeApiSchema';
import { ATTENDANCE_STATUS } from '../constants';

export interface LeaveOption {
  title: string;
  value: string;
  status: number;
  leaveType: number | null;
}

export const generateStatusOptions = (
  leaveAttendanceConfiguration: NonNullable<
    GetMeApiSchemaContract['currentOrganization']
  >['leaveAttendanceConfig'],
): LeaveOption[] => {
  const options: LeaveOption[] = [
    {
      title: 'Present',
      value: 'request-attendance',
      status: ATTENDANCE_STATUS.STATUS_PRESENT,
      leaveType: null,
    },
  ];

  if (
    !leaveAttendanceConfiguration ||
    !leaveAttendanceConfiguration.typesOfLeaves ||
    leaveAttendanceConfiguration.typesOfLeaves.length === 0
  ) {
    options.push({
      title: 'Leave',
      value: 'request-leave',
      status: ATTENDANCE_STATUS.STATUS_LEAVE,
      leaveType: null,
    });
    if (leaveAttendanceConfiguration && leaveAttendanceConfiguration.allowHalfdays) {
      options.push({
        title: 'Half Day',
        value: 'request-half-leave',
        status: ATTENDANCE_STATUS.STATUS_HALF_DAY,
        leaveType: null,
      });
    }
  } else {
    leaveAttendanceConfiguration.typesOfLeaves.forEach((leaveType, index) => {
      options.push({
        title: leaveType,
        value: `request-leave|${index}`,
        status: ATTENDANCE_STATUS.STATUS_LEAVE,
        leaveType: index,
      });
      if (leaveAttendanceConfiguration.allowHalfdays) {
        options.push({
          title: `Half ${leaveType}`,
          value: `request-half-leave|${index}`,
          status: ATTENDANCE_STATUS.STATUS_HALF_DAY,
          leaveType: index,
        });
      }
    });
  }

  options.push({
    title: 'Unpaid Leave',
    value: 'request-unpaid-leave',
    status: ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE,
    leaveType: null,
  });
  if (leaveAttendanceConfiguration && leaveAttendanceConfiguration.allowHalfdays) {
    options.push({
      title: 'Unpaid Half Day',
      value: 'request-unpaid-half-leave',
      status: ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY,
      leaveType: null,
    });
  }

  return options;
};

export const isStatusLeave = (status: number): boolean => {
  return [
    ATTENDANCE_STATUS.STATUS_LEAVE,
    ATTENDANCE_STATUS.STATUS_HALF_DAY,
    ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY,
    ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY,
  ].includes(status);
};
