import React, { useState } from 'react';

import { Text, Box, List, ListItem } from '@razorpay/blade/components';
import TaxSavingItems from './TaxSavingItems';
import { PayrollAiAssistantPollSchemaContract } from 'schemas/PayrollAiAssistantPollSchema';
import PayableTaxPercent from '../RegimeBreakup/PayableTaxPercent';
import { BarGraphWrapper, GreenText } from '../RegimeBreakup';
import { toIndianCurrencyString } from 'utils/Numbers';
import { useRandomPick } from 'components/PayrollAIAssistant/useRandomPick';

const TaxSavingBreakup = ({
  data,
  showSuggested,
}: {
  data: PayrollAiAssistantPollSchemaContract['response'];
  showSuggested: boolean;
}) => {
  const [regimeToShow, setRegimeToShow] = useState(
    showSuggested
      ? data?.tax_saving_breakup?.suggested_regime ?? 'old'
      : data?.tax_saving_breakup?.suggested_regime === 'old'
      ? 'new'
      : 'old',
  );

  const moneyAlertText = useRandomPick([
    "Update alert! 🚨 I've tweaked my recommendation based on your declarations.",
    "Adjustment made! 💸 I've updated my recommendation following your declarations.",
    "Quick update! 💼 I've fine-tuned my recommendation after considering your declarations.",
    "Heads up! 💵 I've adjusted my recommendation following your declarations.",
    "Attention! 💲 I've modified my recommendation after assessing your declarations.",
  ]);

  if (!data || !data.tax_saving_breakup) {
    return null;
  }

  const taxSavingData = data.tax_saving_breakup;

  const firstDisplayAmount =
    taxSavingData.suggested_regime === 'old'
      ? taxSavingData.old_regime_tax_amount
      : taxSavingData.new_regime_tax_amount;
  const secondDisplayAmount =
    taxSavingData.suggested_regime === 'old'
      ? taxSavingData.new_regime_tax_amount
      : taxSavingData.old_regime_tax_amount;

  const miscellaneousAdvice =
    regimeToShow === 'old'
      ? taxSavingData.old_miscellaneous_advice
      : taxSavingData.new_miscellaneous_advice;
  const sectionsToShow = taxSavingData.sections.filter((item) => item.regime === regimeToShow);

  return (
    (<Box width="100%">
      <Text size="large" color="surface.text.gray.subtle">
        Wait for it, wait for it...
      </Text>
      <Text size="large" color="surface.text.gray.subtle" marginTop={'32px'}>
        🔔 {moneyAlertText}
      </Text>
      <Box display={'inline-flex'} marginBottom={'spacing.5'} flexWrap={'wrap'}>
        <Text size="large" color="surface.text.gray.subtle">
          🤯 You can potentially save up to{' '}
        </Text>
        <GreenText>
          {toIndianCurrencyString(Math.abs(firstDisplayAmount - secondDisplayAmount))} more tax in{' '}
          {taxSavingData.suggested_regime} regime!
        </GreenText>
      </Box>
      <Text size="large" color="surface.text.gray.subtle">
        Minimum payable tax in both:
      </Text>
      <BarGraphWrapper>
        <PayableTaxPercent
          regime={taxSavingData.suggested_regime === 'old' ? 'Old Regime' : 'New Regime'}
          tax={firstDisplayAmount}
          percent={Math.ceil((firstDisplayAmount / secondDisplayAmount) * 100)}
          variant="green"
          onClick={() => setRegimeToShow(taxSavingData.suggested_regime === 'old' ? 'old' : 'new')}
        />
        <PayableTaxPercent
          regime={taxSavingData.suggested_regime === 'old' ? 'New Regime' : 'Old Regime'}
          tax={secondDisplayAmount}
          percent={100}
          onClick={() => setRegimeToShow(taxSavingData.suggested_regime === 'old' ? 'new' : 'old')}
        />
      </BarGraphWrapper>
      {sectionsToShow.length > 0 && (<Box marginY={'32px'}>
        <Box display={'inline-flex'} marginBottom={'spacing.5'} flexWrap={'wrap'}>
          <Text size="large" color="surface.text.gray.subtle">
            ✨ Here are the top saving options in {regimeToShow} regime,{' '}
          </Text>
          <GreenText>supported by insights from 1M+ employees:</GreenText>
        </Box>
        <TaxSavingItems sections={taxSavingData.sections} regime={regimeToShow} />
      </Box>)}
      {miscellaneousAdvice && miscellaneousAdvice.length > 0 && (
        <Box marginTop={'spacing.9'}>
          <Text weight="semibold" size="large">
            {' '}
            Few more things to share:{' '}
          </Text>
          <List variant="unordered" size="large">
            {miscellaneousAdvice?.map((info, key) => {
              return <ListItem key={key}>{info}</ListItem>;
            })}
          </List>
        </Box>
      )}
      <Text marginTop={'spacing.9'} size="large" color="surface.text.gray.subtle">
        Do you want to add or remove any investment options?
      </Text>
    </Box>)
  );
};

export default TaxSavingBreakup;
