import React, { useState, useEffect } from 'react';
import { Input } from 'components/ui/Form/Input';
import { StandardButton } from 'components/ui/Button';
import closeIcon from 'assets/icon--close-blue.svg';
import searchIcon from 'assets/icon--search-white.svg';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from '../../../RunPayroll/Grid/index.module.scss';

const EsicFilters = ({
  isLoading,
  searchFilter,
  setSearchFilter,
  onClearSearch
}: {
  isLoading: boolean;
  searchFilter: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  onClearSearch: () => void;
}) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(searchFilter); // This will allow the input to stay after clicking on the submit button
  }, [searchFilter]);
  const handleClearSearch = () => {
    setInputValue(''); // Clear inputValue
    onClearSearch();
  };

  return (
    <div>
      <form
        className={styles['run-payroll-search']}
        onSubmit={(e) => {
          e.preventDefault();
          setSearchFilter(inputValue);
        }}>
        <Input
          type="search"
          name="search"
          placeholder="Search by name, email or employee id"
          value={inputValue}
          disabled={isLoading}
          onChange={(event) => setInputValue(event.target.value)}
        />
        {!!searchFilter && (
          <img
            className={styles['runpayroll-filter-search-clear']}
            src={getStaticMediaUrl(closeIcon)}
            alt=""
            onClick={handleClearSearch}
          />
        )}
        <StandardButton
          type="submit"
          disabled={isLoading}
          className={styles['run-payroll-data-search']}>
          <img width="30px" src={getStaticMediaUrl(searchIcon)} alt="Search" />
        </StandardButton>
      </form>
    </div>
  );
};

export default EsicFilters;