import React, { ReactElement, useCallback } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { TabLayout, Tab, TabPanel, TabList } from 'components/ui/TabLayout';
import OnboardingStatus, { OnboardingItem } from 'components/ui/OnboardingStatus';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import ComplianceSideBarElement from './ComplianceSideBarElement';
import { routeToStepMap, stepToRouteMap, complianceSteps } from './utils';
import TdsSetup from './TdsSetup';
import { routePaths } from 'components/Routes/data';
import PtSetup from './PtSetup';
import PfSetup from './PfSetup';
import EsicSetup from './EsicSetup';
import { LinkButtonV2 } from 'components/ui/Button';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { trackSegment } from 'utils/segment';

const STEP_COMPLIANCE = 1;
const STEP_PEOPLE = 2;

const Compliance = () => {
  const location = useLocation();
  const history = useHistory();
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);

  const currentStep = routeToStepMap[location.pathname];
  const handleTabChange = useCallback((value: string) => {
    history.push(stepToRouteMap[value]);
  }, []);

  const handleStepChange = (value: number) => {
    if (value === STEP_PEOPLE) {
      window.location.href = routePaths.people;
    }
  };

  if (!(location.pathname in routeToStepMap)) {
    return <Redirect to={routePaths.unknown} />;
  }

  return (
    <div className="max-w-screen-xl mx-auto">
      <OnboardingStatus
        className="mb-16 mx-auto"
        value={STEP_COMPLIANCE}
        title="Setup compliance"
        onChange={handleStepChange}
      >
        <OnboardingItem
          title="Setup compliance"
          value={STEP_COMPLIANCE}
          status={
            currentOrganization?.onboardingConfig?.isTDSSetupComplete &&
            currentOrganization.onboardingConfig.isPTSetupComplete &&
            currentOrganization.onboardingConfig.isPFSetupComplete &&
            currentOrganization.onboardingConfig.isESICSetupComplete
              ? 'completed'
              : undefined
          }
        />
        <OnboardingItem
          title="Add employees and contractors"
          value={STEP_PEOPLE}
          status={(currentOrganization?.noOfEmployees || 0) >= 2 ? 'completed' : undefined}
        />
      </OnboardingStatus>
      <TabLayout value={currentStep} onChange={handleTabChange}>
        <TabList>
          <Tab value={complianceSteps.STEP_TDS} label="TDS" />
          <Tab value={complianceSteps.STEP_PT} label="Professional Tax" />
          <Tab value={complianceSteps.STEP_PF} label="PF" />
          <Tab value={complianceSteps.STEP_ESIC} label="ESIC" />
        </TabList>
        <TabPanel value={complianceSteps.STEP_TDS}>
          <TdsSetup />
        </TabPanel>
        <TabPanel value={complianceSteps.STEP_PT}>
          <PtSetup />
        </TabPanel>
        <TabPanel value={complianceSteps.STEP_PF}>
          <PfSetup />
        </TabPanel>
        <TabPanel value={complianceSteps.STEP_ESIC}>
          <EsicSetup />
        </TabPanel>
      </TabLayout>
      <div className="max-w-4xl mx-auto mt-16">
        <a href="/">
          <LinkButtonV2 onClick={() => trackSegment('onboarding.setup_compliance.back_to_setup_guide_click', { queue: true})} >{'<'} Back to setup guide</LinkButtonV2>
        </a>
      </div>
    </div>
  );
};

function ComplianceWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase>
        <Compliance />
      </MainCase>
      <RightSidebar>
        <ComplianceSideBarElement />
      </RightSidebar>
    </PageWrapper>
  );
}

export default ComplianceWithPageWrapper;
