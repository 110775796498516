import React from 'react';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';

const useIsFinalizationRestrictionEnabled = () => {
  const { payrollMonth } = useRunPayrollDataContext();
  const isFinalizationRestrictionEnabled = React.useMemo(() => {
    const payrollDate = new Date(payrollMonth);
    return payrollDate.getMonth() === 2; 
  }, [payrollMonth]);

  return { isFinalizationRestrictionEnabled };
};

export default useIsFinalizationRestrictionEnabled;
