import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  useToast,
} from '@razorpay/blade/components';
import { useQueryClient } from 'react-query';
import attendance from 'api/attendance';
import { ErrorsAlert } from 'components/ui/Form/Errors';
import { useMutationV2 } from 'hooks/useMutationV2';

interface ApproveRequestsButtonProps {
  selectedRequests: number[];
  onSuccess: () => void;
  userId: string;
}

export const ApproveRequestsButton: React.FC<ApproveRequestsButtonProps> = ({
  selectedRequests,
  onSuccess,
  userId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();

  const approveMutation = useMutationV2(
    (data: { ids: number[]; 'user-id': number }) => attendance.approveLeave(data),
    {
      onSuccess: (data) => {
        toast.show({
          content: data.message,
          color: 'positive',
        });
        queryClient.invalidateQueries(['attendance']);
        queryClient.invalidateQueries(['attendance-today']);
        setIsModalOpen(false);
        onSuccess();
      },
    },
  );

  const handleApprove = () => {
    approveMutation.mutate({ ids: selectedRequests, 'user-id': parseInt(userId, 10) });
  };

  return (
    <>
      <Button
        variant="primary"
        isDisabled={selectedRequests.length === 0}
        onClick={() => setIsModalOpen(true)}>
        Approve
      </Button>
      <Modal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} size="small">
        <ModalHeader title="Confirm Approval" />
        <ModalBody>
          <Text>Are you sure you want to approve the selected requests?</Text>
          {approveMutation.error && (
            <Box marginTop="spacing.4">
              <ErrorsAlert errorResponse={approveMutation.error} />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
            <Button
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
              isDisabled={approveMutation.isLoading}>
              Cancel
            </Button>
            <Button onClick={handleApprove} isLoading={approveMutation.isLoading}>
              Approve
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  );
};
