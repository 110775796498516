export const statesOfIndia = [
  { code: 1, name: 'Andaman and Nicobar Islands' },
  { code: 2, name: 'Andhra Pradesh' },
  { code: 3, name: 'Arunachal Pradesh' },
  { code: 4, name: 'Assam' },
  { code: 5, name: 'Bihar' },
  { code: 6, name: 'Chandigarh' },
  { code: 7, name: 'Chhattisgarh' },
  { code: 8, name: 'Dadra and Nagar Haveli' },
  { code: 9, name: 'Daman and Diu' },
  { code: 10, name: 'Delhi' },
  { code: 11, name: 'Goa' },
  { code: 12, name: 'Gujarat' },
  { code: 13, name: 'Haryana' },
  { code: 14, name: 'Himachal Pradesh' },
  { code: 15, name: 'Jammu and Kashmir' },
  { code: 16, name: 'Jharkhand' },
  { code: 17, name: 'Karnataka' },
  { code: 18, name: 'Kerala' },
  { code: 19, name: 'Lakshadweep' },
  { code: 20, name: 'Madhya Pradesh' },
  { code: 21, name: 'Maharashtra' },
  { code: 22, name: 'Manipur' },
  { code: 23, name: 'Meghalaya' },
  { code: 24, name: 'Mizoram' },
  { code: 25, name: 'Nagaland' },
  { code: 26, name: 'Odisha' },
  { code: 27, name: 'Puducherry' },
  { code: 28, name: 'Punjab' },
  { code: 29, name: 'Rajasthan' },
  { code: 30, name: 'Sikkim' },
  { code: 31, name: 'Tamil Nadu' },
  { code: 37, name: 'Tamil Nadu (Tiruchirappalli)' },
  { code: 32, name: 'Telangana' },
  { code: 33, name: 'Tripura' },
  { code: 34, name: 'Uttar Pradesh' },
  { code: 35, name: 'Uttarakhand' },
  { code: 36, name: 'West Bengal' },
];
