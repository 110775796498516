import { Alert, ArrowLeftIcon, Button, useToast } from '@razorpay/blade/components';
import api from 'api';
import { getErrorsAsString } from 'components/Kyc/utils';
import { routePaths } from 'components/Routes/data';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import {
  earningPayTypes,
  earningsValidationSchema,
  taxDeductionBehavior,
} from 'components/WizardViews/SalaryComponents/constants';
import { EarningsFormData } from 'components/WizardViews/SalaryComponents/types';
import {
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
} from 'components/WizardViews/components';
import {
  ReviewSection,
  ReviewSectionContainer,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
} from 'components/WizardViews/components/review';
import { useFormWithProvider } from 'forms';
import React, { FormEvent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppError } from 'utils/AppError';
import {
  checkIsPayFrequencyMonthly,
  convertFormDataToEarningSalaryComponentUpdatePayload,
  earningsExemptionDisplayText,
  isTaxExempt,
} from '../utils';

const ReviewStep: React.FC = () => {
  const { formData, validate, setIsSubmitting } = useFormWithProvider<EarningsFormData>();
  const history = useHistory();
  const toast = useToast();
  const { searchString, componentId } = useComponentParams();

  const updateEarnings = useMutation({
    mutationFn: api.salaryComponents.mutations.updateSalaryComponent,
    onError: (e: AppError) => {
      toast.show({
        content: 'There was an error in updating salary component.',
        color: 'negative',
      });
    },
    onMutate() {
      setIsSubmitting(true);
    },
    onSettled() {
      setIsSubmitting(false);
    },
    onSuccess(data) {
      toast.show({
        content: 'Salary component updated successfully!',
        color: 'positive',
        duration: 3000,
      });
      history.push(routePaths.salaryComponents.root);
    },
  });

  const isFormValid = useMemo(
    () => validate(earningsValidationSchema, { sideEffects: false }),
    [validate],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (componentId) {
      updateEarnings.mutate(
        convertFormDataToEarningSalaryComponentUpdatePayload(formData, componentId),
      );
    }
  };

  const editBasicDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.edit.earnings.generalDetails,
      search: searchString,
    });
  const editTaxationDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.edit.earnings.taxationDetails,
      search: searchString,
    });

  const selectedPayFrequency = formData.payType;
  const isPayTypeFixed = checkIsPayFrequencyMonthly(selectedPayFrequency);
  const isExemptionVisible = isTaxExempt(
    formData.taxExemptInNewRegime,
    formData.taxExemptInOldRegime,
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Review" subtitle="Component" />
        {updateEarnings.isError && (
          <Alert
            marginBottom="spacing.7"
            title="The component cannot be created because of the below errors:"
            description={getErrorsAsString(updateEarnings.error)}
            color="negative"
            isFullWidth
            isDismissible={false}
          />
        )}
        <ReviewSectionContainer>
          <ReviewSection>
            <ReviewSectionHeader
              title="Basic"
              subtitle="details"
              onEditClick={editBasicDetailsClick}
            />
            <ReviewSectionItemContainer>
              <ReviewSectionItem
                label="Component Name"
                value={formData.name}
                status={formData.name ? 'success' : 'error'}
              />
              <ReviewSectionItem
                label="Payslip & Salary Register name"
                value={formData.displayName}
                status={formData.displayName ? 'success' : 'error'}
              />
              {formData.description ? (
                <ReviewSectionItem label="Component Description" value={formData.description} />
              ) : null}
            </ReviewSectionItemContainer>
          </ReviewSection>
          <ReviewSection>
            <ReviewSectionHeader
              title="Pay"
              subtitle="details"
              onEditClick={editBasicDetailsClick}
            />
            <ReviewSectionItemContainer>
              <ReviewSectionItem
                label="Pay Frequency"
                value={selectedPayFrequency ? earningPayTypes[selectedPayFrequency].label : ''}
                status={selectedPayFrequency ? 'success' : 'error'}
              />
              <ReviewSectionItem
                label="Proration"
                value={formData.supportProration ? 'Enabled' : 'Disabled'}
              />
              <ReviewSectionItem
                label="Arrear calculation"
                value={formData.considerInArrear ? 'Enabled' : 'Disabled'}
              />
            </ReviewSectionItemContainer>
          </ReviewSection>
          <ReviewSection>
            <ReviewSectionHeader
              title="Taxation"
              subtitle="details"
              onEditClick={editTaxationDetailsClick}
            />
            <ReviewSectionItemContainer>
              <ReviewSectionItem
                label="Tax exempted in Old Regime"
                value={formData.taxExemptInOldRegime ? 'Yes' : 'No'}
              />
              <ReviewSectionItem
                label="Tax exempted in New Regime"
                value={formData.taxExemptInNewRegime ? 'Yes' : 'No'}
              />
              {isExemptionVisible && (
                <ReviewSectionItem
                  label="Exempt under section"
                  value={
                    formData.taxExemptionSection?.[0]
                      ? earningsExemptionDisplayText(formData.taxExemptionSection[0])
                      : 'Not selected'
                  }
                  status={formData.taxExemptionSection?.[0] ? 'success' : 'error'}
                />
              )}
              {!isPayTypeFixed && (
                <ReviewSectionItem
                  label="TDS deduction"
                  value={
                    formData.taxDeductionBehaviour
                      ? taxDeductionBehavior[
                          formData.taxDeductionBehaviour as keyof typeof taxDeductionBehavior
                        ].label
                      : ''
                  }
                  status={formData.taxDeductionBehaviour ? 'success' : 'error'}
                />
              )}
              <ReviewSectionItem
                label="Part of PF wage"
                value={formData.includeInPfWages ? 'Yes' : 'No'}
              />
              <ReviewSectionItem
                label="Part of ESI wage"
                value={formData.includeInEsiWages ? 'Yes' : 'No'}
              />
              <ReviewSectionItem
                label="Part of PT wage"
                value={formData.includeInPtWages ? 'Yes' : 'No'}
              />
              <ReviewSectionItem
                label="Part of LWF wage"
                value={formData.includeInLwfWages ? 'Yes' : 'No'}
              />
            </ReviewSectionItemContainer>
          </ReviewSection>
        </ReviewSectionContainer>
      </StepContentWrapper>
      <StepActionsWrapper>
        <Button variant="secondary" icon={ArrowLeftIcon} onClick={() => history.goBack()}>
          Taxation
        </Button>
        <Button type="submit" isLoading={updateEarnings.isLoading} isDisabled={!isFormValid}>
          Update Component
        </Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default ReviewStep;
