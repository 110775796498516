import React, { useState } from 'react';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import { useMutation } from 'react-query';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useQueryClient } from 'react-query';
import { ZOHO_ACTIVATION_STATUS_QUERY } from '../../queries';
import ZohoLogo from 'assets/zoho/zoho_text_logo.png';
import Card from '../../components/Card';
import cx from 'classnames';
import { getAvailableEmployeeTypesOnZoho } from './utils';
import { useHistory } from 'react-router-dom';
import { CheckBox } from 'components/ui/Form/Input';
import withActivationQueryWrapper from '../../withActivationQueryWrapper';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { routePaths } from 'components/Routes/data';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoEmployeeTypeMappingSetup({ zohoData }: Props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const allEmployeeTypeFields = getAvailableEmployeeTypesOnZoho(zohoData.available_fields || []);
  const [selectedFields, setSelectedFields] = useState(zohoData.employee_types_full_time);

  const handleToggleField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;

    if (allEmployeeTypeFields.includes(selectedValue)) {
      const updatedFields = [...selectedFields];
      if (!updatedFields.includes(selectedValue)) {
        updatedFields.push(selectedValue);
      } else {
        updatedFields.splice(updatedFields.indexOf(selectedValue), 1);
      }
      setSelectedFields(updatedFields);
    }
  };

  const updateTypesMutation = useMutation(
    (types: string[]) => api.zoho.updateEmployeeTypeConfig(types),
    {
      onError: (error: typeof AppError) => {},
      onSuccess: () => {
        queryClient.invalidateQueries(ZOHO_ACTIVATION_STATUS_QUERY);
        history.push(routePaths.settings.zoho.config.employeeDismissalTypeSetup);
      },
    },
  );

  const handleConfirm = () => {
    trackSegment('zoho.type_mapping.next.click');
    updateTypesMutation.mutate(selectedFields);
  };

  return (
    <div className={styles['wrapper']}>
      {updateTypesMutation.status === 'error' && (
        <WarningAlert className="my-6">
          <Errors errorResponse={updateTypesMutation.error} />
        </WarningAlert>
      )}
      <img src={ZohoLogo} alt="Zoho Logo" className={styles['logo']} />
      <h3 className={cx(styles['title'], 'mt-12')}>Employee type mapping</h3>
      <Card className="mt-10">
        <h3 className="font-bold text-white-o-70">
          Select only those employee type(s) from Zoho People which you want to map as{' '}
          <span className="text-white-o-80 italic">Employees</span> in RazorpayX Payroll.
        </h3>
        <h3 className="font-bold text-white-o-70 mt-5">
          Unselected Zoho employee type(s) will be mapped as{' '}
          <span className="italic text-white-o-80">contractors</span>
        </h3>
        <div className="grid grid-cols-1 gap-y-6 mt-10">
          {allEmployeeTypeFields.map((type) => (
            <CheckBox
              id={`type-${type}`}
              value={type}
              key={type}
              label={type}
              checked={selectedFields.includes(type)}
              onChange={handleToggleField}
              disabled={updateTypesMutation.status === 'loading'}
            />
          ))}
        </div>
      </Card>
      <div className="flex justify-between mt-24">
        <SecondaryButton
          disabled={updateTypesMutation.status === 'loading'}
          onClick={() => {
            history.goBack();
          }}>
          Back
        </SecondaryButton>
        <StandardButton
          className="mr-0"
          disabled={selectedFields.length === 0}
          isPending={updateTypesMutation.status === 'loading'}
          onClick={handleConfirm}>
          Next
        </StandardButton>
      </div>
    </div>
  );
}

export default withActivationQueryWrapper(ZohoEmployeeTypeMappingSetup);
