import { DeliveryType, KYC_STATUS } from "constants/flexibenefits";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";

export const getOrgFlexiDetails = (): GetOrgFlexiPartnerDetailsSchemaContract => {
    return {
        isOpted: false,
        optedOn: null,
        walletBudgets: [],
        enabledWallets: [],
        deliveryType: DeliveryType.DISPATCH_TO_EMPLOYEE,
        cardDeliveryDetails: {
            address: '',
            pinCode: '',
            city: '',
            state: '',
            fullName: '',
            phoneNumber: '',
        },
        totalAmountAllocated: 0,
        kycData:{
            status: KYC_STATUS.STATUS_NOT_INITIATED,
            documents: {
                gstin: {
                    file: '',
                    comment: '',
                    gstinNumber: '',
                    isApproved: false
                },
                cancelledCheque: {
                    file: '',
                    comment: '',
                    isApproved: false
                }
            }
        },
        requiredKYCFields : {
            requireGSTIN : true,
            requireCancelledCheque : true
        },
        enableForAllEmployees : false,
        restrictions: []
    };
};

export interface FlexiDetailsProps {
    flexiDetails: GetOrgFlexiPartnerDetailsSchemaContract;
    setFlexiDetails: (flexiDetails: GetOrgFlexiPartnerDetailsSchemaContract) => void;
    isUpdateLoading?: boolean;
    onSaveFlexiDetails?: (saveDetails?: GetOrgFlexiPartnerDetailsSchemaContract) => void;
    goBack?: () => void; 
}

export const getDisabledTabs = (
    currentTab: string
    , tabOptions: {
        WALLETS: string;
        COMPONENT: string;
        ADDRESS: string;
        CONFIRM: string;
    } | {
        WALLETS: string;
        COMPONENT: string;
        ADDRESS: string;
    }
    ) => {
    const disabledTabs = Object.values(tabOptions);
    const currentIndex = disabledTabs.indexOf(currentTab);
    return disabledTabs
        .filter((val, idx) => idx > currentIndex)
        .map((val) => (val as keyof typeof tabOptions));
}

export interface DispatchAddress {
    address: string | null;
    city: string | null;
    state: string | null;
    pinCode: string | null;
    fullName: string | null;
    phoneNumber: string | null;
    [k: string]: unknown;
}