import {useState} from "react";
import {useQuery} from "react-query";
import {JIBBLE_GET_LOP_ADDITION_STATUS} from "../utils/queries";
import api from "../../../../api";

export const usePollAddLopCompletion = ({onComplete}: {onComplete: () => void}) => {
    const [isPolling, setIsPolling] = useState(false);
    const { refetch } = useQuery(
        JIBBLE_GET_LOP_ADDITION_STATUS,
        () => {
            return api.jibbleSettings.getLopAdditionStatus();
        },
        {
            enabled: isPolling,
            refetchInterval: (data) => {
                const isLopAdditionInProgress = data?.is_lop_addition_in_progress;
                if (isLopAdditionInProgress === true) {
                    setIsPolling(true);
                    return 3000;
                } else {
                    isLopAdditionInProgress === false && setIsPolling(false);
                    return false;
                }
            },
            onSuccess: (data) => isPolling && !data.is_lop_addition_in_progress && onComplete()
        }
    );
    return {
        isPolling,
        beginPolling: () => {
            setIsPolling(true);
            refetch();
        }
    };
}