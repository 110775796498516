import React from 'react';
import freshTeamInstallImage from 'assets/freshteam/freshteam_install.png';
import { LinkButtonV2, StandardButton } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import { getStaticMediaUrl } from 'utils/Urls';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { routePaths } from 'components/Routes/data';
import { Card } from 'components/ui/Card';

import styles from './index.module.scss';
import { WarningAlertYellow } from 'components/ui/Alert';

interface Props {
  currentlyActiveIntegration: { type: number; name: string } | null;
}

const FreshTeamsIntroduction = ({ currentlyActiveIntegration }: Props) => {
  return (
    <>
      {currentlyActiveIntegration && (
        <WarningAlertYellow className="mt-8 mb-8">
          You already have an HRMS integration active via {currentlyActiveIntegration.name}. Please
          disable that if you want to use the Freshteam integration
        </WarningAlertYellow>
      )}
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(freshTeamInstallImage)} />
      <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
        Now integrate XPayroll with Freshteam
      </div>
      <div className="text-white-o-70 text-center">
        Manage your hiring, onboarding, time-off, employee data, and HR workflows now from
        Freshteam.
      </div>
      <Card className={`${styles['Card']} mt-12 px-10 py-10`} withTopBorder>
        <div className="font-bold text-white-o-80 mb-8 text-100">Features and benefits</div>
        <NumberedList className="pl-6">
          <NumberedListItem>
            Employee Data Sync - Update all employee information on Freshteam and it will
            automatically sync with XPayroll
          </NumberedListItem>
          <NumberedListItem>
            New Hire Onboarding - Add new hires on Freshteam, and they get added to XPayroll
            directly
          </NumberedListItem>
          <NumberedListItem>
            Mobile App - Give your team on-the-go access with Freshteam’s mobile app
          </NumberedListItem>
        </NumberedList>
      </Card>
      <Card className={`${styles['Card']} mt-12 px-10 py-10`} withTopBorder>
        <div className="font-bold text-white-o-80 mb-8 text-100">How to get started</div>
        <NumberedList className="pl-6">
          <NumberedListItem>
            Create and account on{' '}
            <a target="_blank" rel="noreferrer" href="https://www.freshworks.com/hrms/">
              <LinkButtonV2>Freshteam</LinkButtonV2>
            </a>{' '}
            and add your employee information. If you do not have an existing Freshteam account,
            please reach out to us on xpayroll@razorpay.com
          </NumberedListItem>
          <NumberedListItem>
            Once done, integrate XPayroll with Freshteam by clicking on the “Start Integration” button
            below
          </NumberedListItem>
        </NumberedList>
      </Card>
      <Link to={routePaths.settings.freshteamsActivation}>
        <StandardButton disabled={!!currentlyActiveIntegration} className="mt-12">
          Start Integration
        </StandardButton>
      </Link>
    </>
  );
};

export default FreshTeamsIntroduction;
