import React from 'react';

type RemarksProps = {
  onRemarkChange: (value: string) => void;
  remarks: string;
};

const Remarks: React.FC<RemarksProps> = ({ onRemarkChange, remarks }) => {
  return (
    <textarea
      name="remarks"
      placeholder="Would you like to send a personal message to this employee?"
      id="form-remarks"
      style={{ minHeight: '60px' }}
      value={remarks}
      onChange={(event) => onRemarkChange(event.target.value)}
    />
  );
};

export default Remarks;
