import React from 'react';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { ModalTitle } from 'components/ui/Modals/components/Modal';
import Card from 'components/Settings/ZohoSettings/components/Card';
import styles from '../index.module.scss';
import { useMutation } from 'react-query';
import { LoanEditRequestSchemaContract } from '../../../schemas/LoanEditRequestSchema';
import api from '../../../api/index';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { AppError } from 'utils/AppError';

const LoanConfirmChanges = function ({
  content,
  requestData,
  loanId,
  handleErrorOnConfirm,
}: {
  content: string;
  requestData: LoanEditRequestSchemaContract;
  loanId: number;
  handleErrorOnConfirm: (error: typeof AppError) => void;
}) {
  const history = useHistory();
  const editLoan = useMutation(
    () => {
      return api.loans.editLoan(requestData, loanId);
    },
    {
      onSuccess: () => {
        history.push(`${routePaths.loans.manageLoan}?loanId=${loanId}`);
      },
      onError: (error: typeof AppError) => {
        handleErrorOnConfirm(error);
      },
    },
  );

  const handleConfirm = () => {
    editLoan.mutate();
  };

  return (
    <ConfirmModal confirmText="Confirm" cancelText="cancel" onConfirm={handleConfirm} className={styles['confirm-modal']}>
      <ModalTitle>Confirm Changes</ModalTitle>
      <Card className={styles['confirm-modal-text-card']}>{content}</Card>
    </ConfirmModal>
  );
};

export default LoanConfirmChanges;
