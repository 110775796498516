import React from 'react';
import styles from './index.module.scss';

interface NumberedListProps {
  children?: React.ReactNode;
  className?: string;
}

interface NumberedListItemProps {
  children?: React.ReactNode;
}

const NumberedList = ({ className, children }: NumberedListProps) => {
  return <ol className={`${styles['NumberedList']} ${className ? className : ''}`}>{children}</ol>;
};

export const NumberedListItem = ({ children }: NumberedListItemProps) => (
  <li className={styles['NumberedListItem']}>{children}</li>
);

export default NumberedList;
