import React from 'react';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import ZohoConnected from 'assets/zoho/zoho_connected.png';
import { formatDistance } from 'date-fns';
import Card from '../components/Card';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import BulletList, { BulletListItem } from 'components/ui/BulletList';
import ZohoDisconnectImage from 'assets/zoho/zoho_disconnect.png';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface Props {
  lastFetchedAt: string | null;
  handleDisable: () => void;
  handleSync: () => void;
  isPendingDeactivation?: boolean;
  isPendingSync?: boolean;
}

function ZohoDashboard({
  lastFetchedAt,
  handleDisable,
  handleSync,
  isPendingDeactivation = false,
  isPendingSync = false,
}: Props) {
  const { openModal } = useModal();

  const handleDeactivationConfirmation = () => {
    trackSegment('zoho.disable.click');
    openModal(
      <ConfirmModal onConfirm={handleDisable} confirmText="Disable">
        <div className="flex flex-col max-w-3xl items-center mb-20">
          <img src={ZohoDisconnectImage} alt="" className={styles['disconnect-logo']} />
          <div className={'text-4xl font-bold text-white-o-80 mb-8 mt-4'}>
            We are sad to see you go 😔
          </div>
          <div className="mb-8 text-center">
            Once disabled employee details will not sync from Zoho People RazorpayX Payroll.
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  return (
    <div className="flex flex-col items-center pb-8">
      <img src={ZohoConnected} alt="" className={styles['connected-logo']} />
      <div className="text-white-o-80 font-bold mt-12 text-3xl">
        Zoho People integration is active
      </div>
      {lastFetchedAt && (
        <div className="mt-4 text-white-o-50 text-2xl">
          Last synced{' '}
          {formatDistance(new Date(lastFetchedAt), new Date(), {
            addSuffix: true,
          })}
          . Auto syncs every 24 hours.
        </div>
      )}
      <Card className="mt-12">
        <h3 className="text-white-o-70 font-bold">Want to do a manual sync?</h3>
        <div className="flex justify-between mt-5">
          <div className="text-white-o-50 text-2xl">
            You can do a manual sync if employees added in Zoho People are not reflecting in
            RazorpayX Payroll.
          </div>
          <StandardButton
            className="ml-20"
            onClick={handleSync}
            disabled={isPendingDeactivation}
            isPending={isPendingSync}>
            Sync Now
          </StandardButton>
        </div>
      </Card>
      <Card className="mt-10">
        <h3 className="text-white-o-70 font-bold mb-6">
          Magic of RazorpayX Payroll and Zoho People 🚀
        </h3>
        <BulletList>
          <BulletListItem className="text-white-o-50">
            Automatic onboarding to RazorpayX Payroll - A new employee onboarded in Zoho People gets
            automatically onboarded in RazorpayX Payroll
          </BulletListItem>
          <BulletListItem className="text-white-o-50">
            Attendance sync for accurate payroll calculation - Sync employee unpaid leaves
            information directly to RazorpayX Payroll{' '}
            <Pill type={pillClassTypeMap.success} content="Coming Soon" />
          </BulletListItem>
        </BulletList>
      </Card>
      <StandardSecondaryButton
        onClick={handleDeactivationConfirmation}
        className="mt-16"
        disabled={isPendingSync}
        isPending={isPendingDeactivation}>
        Disable
      </StandardSecondaryButton>
    </div>
  );
}

export default ZohoDashboard;
