export const fieldsToBeSynced = [
  'Name',
  'Age',
  'Email',
  'Role',
  'Employee Type',
  'Manager',
  'Personal Email',
  'Bank account number, IFSC',
  'Employee ID',
  'Title',
  'Hire Date',
  'Gender',
  'Dismissal',
];
