import React from 'react';
import errorImage from 'assets/error.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import styles from './index.module.scss';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const ErrorMessage = ({ title, children }: Props) => {
  return (
    <div className="flex flex-col items-center">
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(errorImage)} />
      <div className={`${styles.Headline} text-4xl font-bold text-white mb-8 mt-4 text-center`}>
        {title}
      </div>
      {children && <div className={styles.ErrorDescription}>{children}</div>}
    </div>
  );
};

export default ErrorMessage;
