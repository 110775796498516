import { routePaths } from "components/Routes/data";

export const WorkflowRequestsStatusLabelsMap = {
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected',
  deleted: 'deleted',
  approved_by_me: 'approved_by_me',
  rejected_by_me: 'rejected_by_me',
} as const;

export const WorkflowRequestsStatusMap = {
  [WorkflowRequestsStatusLabelsMap.approved]: 1,
  [WorkflowRequestsStatusLabelsMap.pending]: 2,
  [WorkflowRequestsStatusLabelsMap.rejected]: 3,
  [WorkflowRequestsStatusLabelsMap.deleted]: 4,
} as const;

export const WorkflowRequestsFiltersMap = {
  fromDate: 'from_date',
  toDate: 'to_date',
  status: 'status[]',
  page: 'page',
  limit: 'limit',
} as const;

export const WorkflowRequestsCacheKeys = {
  summaryForUser: 'summaryForUser',
  editPayroll: {
    pending: 'editPayrollPending',
    initiatedByMe: 'editPayrollInitiatedByMe',
    completed: 'editPayrollCompleted',
  },
  finalizePayroll: {
    pending: 'finalizePayrollPending',
    initiatedByMe: 'finalizePayrollInitiatedByMe',
    completed: 'finalizePayrollCompleted',
  },
  salaryRevision: {
    pending: 'salaryRevisionPending',
    initiatedByMe: 'salaryRevisionInitiatedByMe',
    completed: 'salaryRevisionCompleted',
  },
};

export const getTitle = (pathname: string) => {
  switch(pathname){
    case routePaths.approvalWorkflows.myRequests.editPayroll.root:
    case routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe:
    case routePaths.approvalWorkflows.myRequests.editPayroll.completed:
    case routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals:
      return 'Edit Payroll';
    
    case routePaths.approvalWorkflows.myRequests.finalizePayroll.root:
    case routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe:
    case routePaths.approvalWorkflows.myRequests.finalizePayroll.completed:
    case routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals:
      return 'Finalize Payroll';

    case routePaths.approvalWorkflows.myRequests.salaryRevision.root:
    case routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe:
    case routePaths.approvalWorkflows.myRequests.salaryRevision.completed:
    case routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals:
      return 'Salary Revision';
  }
}