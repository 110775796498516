import { m } from 'framer-motion';
import React from 'react';

// Donut Slice Component
const DonutSlice = ({
  percentage,
  color,
  radius,
  strokeWidth,
  startAngle,
}: {
  percentage: number;
  color: string;
  radius: number;
  strokeWidth: number;
  startAngle: number;
}) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${(percentage / 100) * circumference} ${circumference}`;

  // Calculate rotation to position the slice correctly
  const rotation = startAngle - 90; // -90 to start from the top

  return (
    <m.circle
      cx="50"
      cy="50"
      r={radius}
      fill="transparent"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
      transform={`rotate(${rotation} 50 50)`}
      initial={{ strokeDashoffset: circumference }}
      animate={{ strokeDashoffset: 0 }}
      transition={{ duration: 2, ease: 'easeInOut' }}
    />
  );
};

//TODO: Use Blade component in future
// Donut Chart Component
export const DonutChart = ({
  data,
  size = 200,
  strokeWidth = 15,
}: {
  data: Array<{ percentage: number; color: string }>;
  size?: number;
  strokeWidth?: number;
}) => {
  const radius = (100 - strokeWidth) / 2;
  let currentAngle = 0;

  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      {data.map((slice, index) => {
        // Calculate the starting angle for this slice
        const startAngle = currentAngle;

        // Update the current angle for the next slice
        currentAngle += (slice.percentage / 100) * 360;

        return (
          <DonutSlice
            key={index}
            percentage={slice.percentage}
            color={slice.color}
            radius={radius}
            strokeWidth={strokeWidth}
            startAngle={startAngle}
          />
        );
      })}
    </svg>
  );
};
