import { Box } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { salaryComponentsQueries } from 'components/SalaryComponents/constants';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import { EarningsComponentType } from 'components/SalaryComponents/types';
import {
  WizardError,
  WizardHeader,
  WizardLoader,
  WizardProgressView,
  WizardWrapper,
} from 'components/WizardViews/components';
import { WizardInfoProvider } from 'components/WizardViews/providers';
import {
  earningSteps,
  editEarningsRouteToStepMap,
} from 'components/WizardViews/SalaryComponents/constants';
import GeneralDetailsStep from 'components/WizardViews/SalaryComponents/EditEarnings/GeneralDetailsStep';
import ReviewStep from 'components/WizardViews/SalaryComponents/EditEarnings/ReviewStep';
import TaxInfoStep from 'components/WizardViews/SalaryComponents/EditEarnings/TaxInfoStep';
import { EarningsFormData } from 'components/WizardViews/SalaryComponents/types';
import { useFormWithProvider } from 'forms';
import { Wizard, WizardStep } from 'forms/Wizard';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { convertSalaryComponentToEarningFormData } from '../utils';

const EditEarningsForm = () => {
  const location = useLocation();
  const currentStep =
    editEarningsRouteToStepMap[location.pathname as keyof typeof editEarningsRouteToStepMap] ||
    earningSteps.step1;

  const history = useHistory();
  const { setFormData } = useFormWithProvider<EarningsFormData>();

  const { componentId, payType } = useComponentParams();
  const isExistingComponent = Boolean(componentId);
  const { isFetching, refetch, error } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentById, componentId],
    queryFn: () =>
      api.salaryComponents.queries.getSalaryComponent({
        id: componentId!,
        category: 'EARNINGS_CATEGORY',
        payType,
      }),
    enabled: isExistingComponent,
    onSuccess(data) {
      setFormData(
        convertSalaryComponentToEarningFormData(
          data.payrollGetSalaryComponent.data.salaryComponent as EarningsComponentType,
        ),
      );
    },
  });

  const handleCloseClick = () => history.push(routePaths.salaryComponents.root);

  if (isFetching) {
    return <WizardLoader />;
  }

  return (
    <WizardInfoProvider currentStep={currentStep} totalSteps={3}>
      <WizardWrapper>
        <WizardHeader
          heading="Modify"
          subHeading="Earning Component"
          onCloseClick={handleCloseClick}>
          <WizardProgressView
            steps={[
              { label: 'General & pay', position: earningSteps.step1 },
              { label: 'Taxation', position: earningSteps.step2 },
              { label: 'Review', position: earningSteps.step3 },
            ]}
          />
        </WizardHeader>
        <Box flex={1} overflowY="auto" height="100%">
          <WizardError onRetry={refetch} error={error}>
            <Wizard>
              <WizardStep step={earningSteps.step1} component={GeneralDetailsStep} />
              <WizardStep step={earningSteps.step2} component={TaxInfoStep} />
              <WizardStep step={earningSteps.step3} component={ReviewStep} />
            </Wizard>
          </WizardError>
        </Box>
      </WizardWrapper>
    </WizardInfoProvider>
  );
};

export default EditEarningsForm;
