import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import api from '../../../../api';
import { FETCH_EMPLOYEE_FLEXI_PARTNER_DETAILS } from '../queries';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import zaggleIndusCard from '../../../../assets/zaggleIndusCard.svg';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';

export function ConfirmYourDeclarations({
                                          peopleOrgId,
                                          detailsToSync,
                                          setInConfirmation,
                                          totalAmount,
                                          setErrorMessage,
                                          changeDeclarationSavedAlertDisplayStatus,
                                          employeeFlexiBenefitsDetails,
                                        }: {
  detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract,
  setInConfirmation: (newState: boolean) => void,
  totalAmount: number,
  employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract,
  peopleOrgId: number,
  setErrorMessage: (message: string) => void,
  changeDeclarationSavedAlertDisplayStatus: (arg: boolean) => void
}) {
  const queryClient = useQueryClient();
  const [isConfirmed, updateConfirmationStatus] = useState((!!employeeFlexiBenefitsDetails.optedOn));
  const syncPartnerFlexiEmployeeDetails = useMutation(() => {
    return api.flexiPartnerDetails.syncEmployeeFlexiPartnerDetails(peopleOrgId, detailsToSync);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_EMPLOYEE_FLEXI_PARTNER_DETAILS).then(() => {
        changeDeclarationSavedAlertDisplayStatus(true);
        setInConfirmation(false);
      });
    },
  });
  if (syncPartnerFlexiEmployeeDetails.isError) {
    const err = syncPartnerFlexiEmployeeDetails.error as Error;
    setErrorMessage(err.message);
    setInConfirmation(false);
  }
  return <div className={'space-y-10 max-w-576'}>
    <div className={'font-bold text-5xl text-white'}>Confirm your declarations</div>
    <div className={'bg-persian-blue p-10'}>
      <div className={'flex ml-5'}>
        <div>
          <ul className={'list-disc'}>
            <li>The declared amount of {toIndianCurrencyString(totalAmount)} will be transferred to your Zaggle card on
              the date of payroll execution.
            </li>
            <li>The amount transferred to your Zaggle card cannot be transferred to your bank account or cashed.</li>
            <li>You can edit your declaration anytime.😌</li>
          </ul>
        </div>
        <div className={'min-w-68'}>
          <img src={getStaticMediaUrl(zaggleIndusCard)} />
        </div>
      </div>
      {(!employeeFlexiBenefitsDetails.optedOn) && <div className={'mt-10'}>
        <label>
          <input
            type={'checkbox'}
            className={'mr-2'}
            name={'is-confirmed'}
            defaultChecked={isConfirmed}
            onChange={(event) => {
              updateConfirmationStatus(event.currentTarget.checked);
            }}
          />
          I understand the above conditions.
        </label>
      </div>}
      <div>
        <button
          className={'btn ' + ((syncPartnerFlexiEmployeeDetails.isLoading || !isConfirmed) ? 'btn--disabled' : '')}
          disabled={syncPartnerFlexiEmployeeDetails.isLoading || !isConfirmed} onClick={() => {
          isConfirmed && syncPartnerFlexiEmployeeDetails.mutate();
        }}>
          Submit Declarations
        </button>
        <button className={'btn--secondary-red'} onClick={() => {
          setInConfirmation(false);
        }}>
          Back
        </button>
      </div>
    </div>
  </div>;
}