import React from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PrimaryButton } from 'components/ui/Button';
import api from 'api';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  SLACK_ACTIVATION_STATUS_QUERY,
  SLACK_OAUTH_MAPPING_QUERY,
  SLACK_ACTIVATION_VALIDATION_QUERY,
} from './queries';
import slackUserConnectImage from 'assets/slack/slack_user_connect.png';
import slackUserConnectedImage from 'assets/slack/slack_user_connected.png';
import APIError from 'types/apiError';
import ErrorMessage from 'components/ui/ErrorMessage';
import { getStaticMediaUrl } from 'utils/Urls';
import Spinner from 'components/ui/Spinner';

import styles from './Activation.module.scss';

type PropsType = {
  token: string;
  onSuccess: Function;
};

const Activation = ({ token, onSuccess }: PropsType) => {
  const queryClient = useQueryClient();

  const activationQuery = useQuery(
    SLACK_ACTIVATION_STATUS_QUERY,
    () => {
      return api.slackSettings.fetchActivationStatus();
    },
    {
      onError: (error: APIError) => {},
    },
  );

  const activationValidationQuery = useQuery(
    SLACK_ACTIVATION_VALIDATION_QUERY,
    () => {
      return api.slackSettings.validateActivationToken(token);
    },
    {
      onError: (error: APIError) => {},
    },
  );

  const oauthMappingQuery = useQuery(
    SLACK_OAUTH_MAPPING_QUERY,
    () => {
      return api.slackSettings.getOauthMapping();
    },
    {
      onError: (error: APIError) => {},
    },
  );

  const activationMutation = useMutation((token: string) => api.slackSettings.activate(token), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(SLACK_ACTIVATION_STATUS_QUERY);
      onSuccess({
        appId: oauthMappingQuery.data?.app_id,
        botId: oauthMappingQuery.data?.bot_id,
        teamId: data.slack_team_id,
      });
    },
    onError: (error: APIError) => {},
  });

  const handleActivation = () => {
    activationMutation.mutate(token);
  };

  if (
    activationQuery.status === 'loading' ||
    activationQuery.status === 'idle' ||
    oauthMappingQuery.status === 'loading' ||
    oauthMappingQuery.status === 'idle' ||
    activationValidationQuery.status === 'loading' ||
    activationValidationQuery.status === 'idle'
  ) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (
    activationQuery.status === 'error' ||
    activationMutation.status === 'error' ||
    oauthMappingQuery.status === 'error' ||
    activationValidationQuery.status === 'error'
  ) {
    return (
      <ErrorMessage title="Sorry! Something went wrong!">
        {activationMutation.error?.message ||
          activationQuery.error?.message ||
          oauthMappingQuery.error?.message ||
          activationValidationQuery.error?.message ||
          'Could not fetch activation details, please contact support'}
      </ErrorMessage>
    );
  }

  if (!oauthMappingQuery.data?.slack_team_id) {
    return (
      <ErrorMessage title="Application is not installed!">
        Looks like our Slack app is not installed on your workspace, please reach out to your admins
        to get it installed.
      </ErrorMessage>
    );
  }

  if (activationQuery?.data?.people_organization_id) {
    return (
      <div className="flex flex-col items-center">
        <img className={styles['HeadlineImage']} src={getStaticMediaUrl(slackUserConnectedImage)} />
        <div className={`${styles.Headline}`}>Your XPayroll to Slack connection is active!</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(slackUserConnectImage)} />
      <div className={`${styles.Headline}`}>Connect your XPayroll account to Slack!</div>
      {!activationQuery.data?.people_organization_id && (
        <PrimaryButton
          onClick={handleActivation}
          isPending={activationMutation.status === 'loading'}
        >
          CONNECT
        </PrimaryButton>
      )}
    </div>
  );
};

const ActivationWrapper = (props: RouteComponentProps) => {
  const token = new URLSearchParams(props.location.search).get('token') || '';
  const openSlack = ({
    appId,
    botId,
    teamId,
  }: {
    appId: string;
    botId: string;
    teamId: string;
  }) => {
    const redirectParams = `app=${appId}&team=${teamId}&channel=${botId}`;
    window.location.replace(`slack://open?${redirectParams}`);
  };

  return (
    <PageWrapper>
      <MainCase>
        <Activation token={token} onSuccess={openSlack} />
      </MainCase>
      <RightSidebar />
    </PageWrapper>
  );
};

export default withRouter(ActivationWrapper);
