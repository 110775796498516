import React, { ReactNode, useState } from 'react';
import {
  AuthorisedSignatoryDetailsDocuments,
  BankDocumentTypes,
  BvsDocumentTypes,
  FileFields,
  KycDocumentsContextType,
  KycDocumentsContextValues,
  ManualDocumentTypes,
} from './types';

export const initialKycContextValue: KycDocumentsContextValues = {
  bankVerificationDetails: {
    proofType: {},
  },
  authorizedSignatoryDetails: {
    addressProof: {},
  },
  companyDetails: {},
};

export const KycDocumentsContext = React.createContext<KycDocumentsContextType>(
  {} as KycDocumentsContextType,
);

export const KycDocumentsContextProvider = ({ children }: { children: ReactNode }) => {
  const [kycValues, setKycValues] = useState(initialKycContextValue);

  const updateAuthorizedSignatoryDetailsDocument = (
    id: keyof AuthorisedSignatoryDetailsDocuments,
    fields: FileFields,
  ) => {
    setKycValues((p) => ({
      ...p,
      authorizedSignatoryDetails: {
        ...p.authorizedSignatoryDetails,
        [id]: {
          ...p.authorizedSignatoryDetails[id],
          ...fields,
        },
      },
    }));
  };

  const updateCompanyDetailsDocument = (
    id: BvsDocumentTypes | ManualDocumentTypes,
    fields: FileFields,
  ) => {
    setKycValues((p) => ({
      ...p,
      companyDetails: {
        ...p.companyDetails,
        [id]: {
          ...p.companyDetails[id],
          ...fields,
        },
      },
    }));
  };

  const updateBankDetails = (id: BankDocumentTypes, fields: FileFields) => {
    setKycValues((p) => ({
      ...p,
      bankVerificationDetails: {
        ...p.bankVerificationDetails,
        [id]: {
          ...p.bankVerificationDetails[id],
          ...fields,
        },
      },
    }));
  };

  return (
    <KycDocumentsContext.Provider
      value={{
        kycValues,
        setKycValues,
        updateAuthorizedSignatoryDetailsDocument,
        updateCompanyDetailsDocument,
        updateBankDetails,
      }}>
      {children}
    </KycDocumentsContext.Provider>
  );
};
