import { Amount, Box, Text } from '@razorpay/blade/components';
import React from 'react';
import { toIndianCurrencyString } from 'utils/Numbers';

export interface NewToOldAmountProps {
  oldAmount?: number;
  newAmount?: number;
}

const NewToOldAmount = ({ newAmount, oldAmount }: NewToOldAmountProps) => {
  return newAmount ? (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Amount value={newAmount} />
      <Box paddingRight="spacing.2">
        {(oldAmount || oldAmount == 0) ? (
          <Text variant="caption" color="surface.text.gray.muted" size='small'>
            Prev: { toIndianCurrencyString(oldAmount,true) }
          </Text>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Text>-</Text>
  );
};

export { NewToOldAmount };
