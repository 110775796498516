export const holdSalaryOptions = [
  {
    title: 'Release Salary',
    helpText: 'Net pay of the employee & compliance payment will be taken care of as usual',
  },
  {
    title: 'Hold net pay till the last working month',
    helpText:
      'Net pay of the employee is on hold, compliance payments will be taken care of as usual.',
  },
];

export const exitLearnMoreLink =
  'https://razorpay.com/docs/x/xpayroll/employers/salary/run-payroll/fnf/';
