import { Heading } from '@razorpay/blade/components';
import { MainContent, OnboardingLayout, Sidebar } from 'components/ui/OnboardingLayout';
import React from 'react';

const IntegrationsV2 = () => {
  return (
    (<OnboardingLayout>
      <Sidebar title="" backButtonTitle="BACK TO DASHBOARD"></Sidebar>
      <MainContent
        title="Integrations"
        description="Integrate with world class product to double your efficiency">
        <Heading size="large">IntegrationsV2 coming soon!!</Heading>
      </MainContent>
    </OnboardingLayout>)
  );
};

export { IntegrationsV2 };
