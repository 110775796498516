import React from 'react';
import { Badge, Box, Card, CardBody, Text, UserIcon } from '@razorpay/blade/components';

const Merchant = ({
  role,
  mid,
  companyName,
}: {
  role: string;
  mid: string;
  companyName: string;
}) => {
  return (
    (<Card backgroundColor="surface.background.gray.intense">
      <CardBody>
        <Box display="flex" gap="spacing.4">
          <Box display="flex" flexDirection="column" gap="spacing.3">
            <Box
              display="flex"
              gap="spacing.3"
              alignItems="center"
              padding={['spacing.1', 'spacing.0']}>
              <Text weight="semibold">{companyName}</Text>
              <Badge color="information" size="small">
                {role}
              </Badge>
            </Box>
            <Box display="flex" gap="spacing.4" alignItems="center">
              <Box display="flex" gap="spacing.2" alignItems="center">
                <UserIcon size="medium" color="interactive.icon.gray.normal" />
                <Text size="small">{mid}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardBody>
    </Card>)
  );
};

export default Merchant;
