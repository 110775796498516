import React from 'react';
import { Box, Button, Modal, Text } from '@razorpay/blade/components';
import ActionModal from '../components/ActionModal';

type Props = Pick<React.ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  isLoading?: boolean;
  componentName: string;
  onSubmit: () => void;
};

const EnableModal = ({ componentName, isOpen, onDismiss, onSubmit, isLoading }: Props) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      headingText={`Enable ${componentName} ?`}
      submitButton={
        <Button
          testID={`enable-component-button`}
          size={'medium'}
          onClick={onSubmit}
          color={'positive'}
          isLoading={isLoading}>
          Enable Now
        </Button>
      }>
      <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
          <Text size="small" color={'surface.text.gray.muted'} weight={'medium'}>
            What will happen?
          </Text>
          <Text color={'surface.text.gray.subtle'}>
            Once enabled, it can be used in an employee's salary structure.{' '}
          </Text>
        </Box>
      </Box>
    </ActionModal>
  );
};

export default EnableModal;
