import React from 'react';
import PageWrapper from "../../../../../ui/PageWrapper";
import MainCase from "../../../../../ui/MainCase";
import { useQuery } from "react-query";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "../../../utils/queries";
import api from "../../../../../../api";
import APIError from "../../../../../../types/apiError";
import {routePaths} from "../../../../../Routes/data";
import ErrorMessage from "../../../../../ui/ErrorMessage";
import jibbleCommonStyles from "../../../index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleSignature from "../../../../../../assets/jibble/signature--jibble.svg";
import AttendanceSettingsForm from "../../AttendanceSettingsForm";
import {useHistory} from "react-router-dom";
import {HeaderWithProgressBar} from "../../../../../BulkUploads/components/HeaderWithProgressBar";

const JibbleSettingsConfiguration= () => {
    const history = useHistory();

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occurred!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery?.data && !integrationStatusQuery.data.is_enabled) {
        history.push(routePaths.settings.jibble.onboarding.activation);
    }

    if (integrationStatusQuery?.data?.is_enabled && integrationStatusQuery.data.is_onboarding_completed) {
        history.push(routePaths.settings.jibble.manage);
    }

    return (
        <div className={jibbleCommonStyles['container']}>
            <img src={getStaticMediaUrl(jibbleSignature)} alt="" className="mb-16"/>
            <h1 className="text-2.14xl font-bold mb-16">Attendance configuration</h1>
            <AttendanceSettingsForm isOnboardingComplete={false} />
        </div>
    );
}

const JibbleSettingsConfigurationPageWrapper = () => (
    <PageWrapper>
        <div className={"flex flex-col w-full"}>
            <HeaderWithProgressBar
                headerText="Jibble Integration"
                currentStep={2}
                totalSteps={3}
            />
            <MainCase center>
                <JibbleSettingsConfiguration/>
            </MainCase>
        </div>
    </PageWrapper>
)

export default JibbleSettingsConfigurationPageWrapper;