import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { store } from './reducers';
import { BrowserRouter } from 'react-router-dom';
import { BladeProvider } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import GlobalStyles from 'GlobalStyles';

export default ({ children }: PropsWithChildren<{}>) => (
  <Provider store={store}>
    <BrowserRouter>
      <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
        <GlobalStyles />
        {children}
      </BladeProvider>
    </BrowserRouter>
  </Provider>
);
