import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import React from 'react';

type Props = Pick<React.ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  onConfirm: () => void;
  isLoading: boolean;
};

const DeleteConfiration = ({ isOpen, onDismiss, onConfirm, isLoading }: Props) => {
  return (
    (<Modal isOpen={isOpen} onDismiss={onDismiss} size="small">
      <ModalHeader title="Delete custom window" />
      <ModalBody>
        <Text color="surface.text.gray.muted">
          Once deleted declaration/proof upload window will be closed for the selected employee.
          Employee won't be able to declare or upload proof after that.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
          <Button variant="secondary" onClick={() => onDismiss()} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button color="negative" onClick={() => onConfirm()} isLoading={isLoading}>
            Delete now
          </Button>
        </Box>
      </ModalFooter>
    </Modal>)
  );
};

export default DeleteConfiration;
