import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tab } from "components/ui/Tab";
import FlexiWallets from "./FlexiWallets";
import IntegrationConfirmation from "./IntegrationConfimation";
import SalaryComponent from "./SalaryStructureSelection";
import DeliveryAddress from "./DeliveryAddress";
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import api from '../../../../api';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "../utils/queries";
import { FlexiDetailsProps, getDisabledTabs, getOrgFlexiDetails } from "../utils/init";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { AppError } from "utils/AppError";
import Spinner from "components/ui/Spinner";
import { WarningAlert, WarningAlertYellow} from "components/ui/Alert";
import Errors from "components/ui/Form/Errors";
import { getActionItemsCount, getNextPage } from "../utils/helpers";
import { routePaths } from "components/Routes/data";
import { PrimaryButtonV2 as PrimaryButton } from "components/ui/Button";
import { UpdateOrgFlexiPartnerDetailsSchemaContract } from "schemas/UpdateOrgFlexiPartnerDetailsSchema";
import ExternalLink from "components/ui/ExternalLink";
import { DeliveryType, ZAGGLE_MANAGE_TAB_OPTIONS } from "constants/flexibenefits";
import { LinkButtonV2 } from "components/ui/Button";

const ZaggleManagement = () : ReactElement => {
    const [selectedTab, setSelectedTab] = useState<string>(ZAGGLE_MANAGE_TAB_OPTIONS.WALLETS);
    const [flexiDetails, setFlexiDetails] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails())
    const [updateError, setUpdateError] = useState<string>("");
    const addressFormRef = useRef<HTMLFormElement>(null);

    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const history = useHistory();
    
    const { isLoading, isRefetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      }, {
        onSuccess: (data) => data && setFlexiDetails(data),
        onError: (error: typeof AppError) => {
            console.log("Error on fetching Org's flexi details data : ", error);
        }
      });
    const queryClient = useQueryClient();
    const updateOrgFlexiDetails = useMutation((flexiDetails: GetOrgFlexiPartnerDetailsSchemaContract) => {
        let requestData: UpdateOrgFlexiPartnerDetailsSchemaContract = {
            enabledWallets: flexiDetails.enabledWallets,
            totalAmountAllocated: flexiDetails.totalAmountAllocated,
            deliveryType: flexiDetails.deliveryType,
            cardDeliveryDetails: flexiDetails.cardDeliveryDetails
        }

        if(selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM) {
            requestData.isOpted = true;
        }

        if(requestData && orgId) {
            return api.flexiPartnerDetails.updateOrgFlexiPartnerDetails(orgId, requestData);
        } else {
            throw "Something went wrong in updateOrgFlexiDetails"
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(FETCH_ORG_FLEXI_PARTNER_DETAILS).then(() => {
                const nextPage = getNextPage(selectedTab);
                if(nextPage){
                    setSelectedTab(nextPage);
                } else {
                    if(getActionItemsCount(data) > 0){
                        history.push(routePaths.partnerFlexiBenefits.admin.actionItems);
                    }
                    else{
                        history.push(routePaths.partnerFlexiBenefits.admin.connectedStatus);
                    }
                }
            });
        },
        onError: (error) => {
            setUpdateError((error as Error).message);
        }
    });
    
    const onSaveFlexiDetails = (saveDetails?: GetOrgFlexiPartnerDetailsSchemaContract) => {
        const details = saveDetails ?? flexiDetails;
        let validationError = null;
        switch(selectedTab) {
            case ZAGGLE_MANAGE_TAB_OPTIONS.WALLETS: 
                if(!details.enabledWallets?.length) {
                    validationError = "Please select atleast one wallet to continue"
                }
                break;
            case ZAGGLE_MANAGE_TAB_OPTIONS.COMPONENT: 
                if(!details.totalAmountAllocated) {
                    validationError = "Please select monthly amount for the flexible benefit component"
                }
                break;
            case ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS: 
                if (details.deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION) {
                    const form = addressFormRef.current;
                    if (form && form.checkValidity() === false){
                        form.reportValidity();   
                        return; 
                    }
                }
                break
        }
        if(!validationError) {
            updateOrgFlexiDetails.mutate(details);
        } else {
            setUpdateError(validationError);
        }
    }

    const goBack = () => {
        setSelectedTab(ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS);
    }

    const redirectPage = () => {

        if(getActionItemsCount(data) > 0){
            history.push(routePaths.partnerFlexiBenefits.admin.actionItems);
        }
        else{
            history.push(routePaths.partnerFlexiBenefits.admin.connectedStatus);
        }
    } 

    useEffect(() => {
        setUpdateError("");
    }, [selectedTab])

    const flexiProps: FlexiDetailsProps = {
        flexiDetails, 
        setFlexiDetails, 
        isUpdateLoading: updateOrgFlexiDetails.isLoading || isRefetching,
        onSaveFlexiDetails,
        goBack
    };

    if (isLoading ||
        (selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM && data?.isOpted && !updateError)) {
        return (<div className={'flex justify-center'}>
            <Spinner size={40}/>
        </div>);
    }
       
    if (error) return <WarningAlert><Errors errorResponse={error}/></WarningAlert>;

    const tabOptions = data?.isOpted? {
        WALLETS : 'WALLETS',
        COMPONENT: 'COMPONENT',
        ADDRESS: 'ADDRESS'
    } : ZAGGLE_MANAGE_TAB_OPTIONS;


    return (
        <>
            { flexiDetails?.restrictions.length > 1 && <WarningAlert className="mb-5">{'Looks like there are few issues that need fixing before setting up your Zaggle. Please find them listed below:'}{flexiDetails?.restrictions.map(txt => <p className="max-w-full mb-1">{txt}</p>)}</WarningAlert> }
            { flexiDetails?.restrictions.length == 1 && <WarningAlert className="mb-5">{flexiDetails?.restrictions[0]}</WarningAlert> }

            <Tab 
                selections={tabOptions} 
                activeElement={selectedTab as keyof typeof tabOptions} 
                className="mb-20"  
                keysToDisable={!data?.isOpted ?getDisabledTabs(selectedTab, tabOptions) : undefined}
                setActiveElement={(selectedTab: string) => setSelectedTab(selectedTab)}
            />
            {data?.isOpted && selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS 
                && <WarningAlertYellow className="mb-5">
                    You cannot change your delivery details as you have already opted in.  Contact <ExternalLink
                href={'mailto:xpayroll@razorpay.com'}>xpayroll@razorpay.com</ExternalLink> for queries or modification in your
                existing configurations.
                    </WarningAlertYellow>}

            <div className={`flex flex-col ${data?.isOpted 
                && selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS ? "pointer-events-none cursor-not-allowed":""}`}>
                {updateError && <WarningAlert className="mb-5">{updateError}</WarningAlert>}
                
                {selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.WALLETS && <FlexiWallets {...flexiProps} />} 
                {selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.COMPONENT && <SalaryComponent {...flexiProps}/>} 
                {selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS && <DeliveryAddress {...flexiProps} addressFormRef={addressFormRef}/>} 
                {selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM 
                    && !data?.isOpted 
                    && <IntegrationConfirmation {...flexiProps}/> 
                } 
            </div>
            {
                ((selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.WALLETS && flexiDetails?.walletBudgets?.length)
                || (selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.COMPONENT)
                || (selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS && !data?.isOpted)) && 
                    <PrimaryButton 
                        onClick={() => onSaveFlexiDetails()} 
                        isPending={updateOrgFlexiDetails.isLoading || isRefetching}
                    >
                        {!data?.isOpted ? 'SAVE AND NEXT' : 'SAVE'}
                    </PrimaryButton>
            }
            {
               ( selectedTab === ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS && data?.isOpted) && <LinkButtonV2 onClick={ redirectPage }>{'<'} { (getActionItemsCount(data) > 0) ? ' Back to Action Items' : ' Back to Status Screen'}</LinkButtonV2>
            }
        </>
    )
}

export default ZaggleManagement;