import React, { useEffect, useState } from 'react';
import { NoWorkflowView } from './components/NoWorkflowView';
import { Workflow, WorkflowLevelConfig } from './types';
import {
  Box,
  Button,
  CheckCircleIcon,
  FilePlusIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { WorkflowLevelsChain } from './components/WorkflowLevelsChain';
import { useLocation } from 'react-router-dom';
import { WorkflowPathnamesMetadata, WorkflowQueryKeys } from './constants';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import api from 'api';
import { prepareWorkflowDataForBE } from './helpers';
import { CreateWorkflowRequestData } from 'api/workflows';
import { UnsavedWorkflowNavigation } from './UnsavedWorkflowNavigation';
import { isEqual } from 'utils/Comparator';
import { showToastViaEvent } from 'utils/ToastEvents';
import { WorkflowTypesMap } from '../constants';
import { errorParser } from 'utils/Api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { Values } from 'types/utils';

interface WorkflowBuilderProps {
  existingWorkflows: Workflow[];
  actionType: Values<typeof WorkflowTypesMap>;
}

const WorkflowBuilder = ({ existingWorkflows, actionType }: WorkflowBuilderProps) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const pathname = location.pathname;
  const workflowLabel = WorkflowPathnamesMetadata[pathname].label;

  const { mutate: createWorkflowMutation, isLoading: isCreateWorkflowMutationLoading } =
    useMutation({
      mutationFn: (mutationData: CreateWorkflowRequestData) =>
        api.workflowApis.createWorkflow(mutationData),
      onSuccess: () => {
        queryClient.invalidateQueries(WorkflowQueryKeys.getAllWorkflows);
        showToastViaEvent({
          text: 'Workflow created successfully!',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      },
      onError: (error: typeof AppError) => {
        showToastViaEvent({
          text: errorParser(getAppErrorDetails(error))[0],
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      },
    });

  const { mutate: updateWorkflowMutation, isLoading: isUpdateWorkflowMutationLoading } =
    useMutation({
      mutationFn: ({
        workflowId,
        requestData,
      }: {
        workflowId: number;
        requestData: CreateWorkflowRequestData;
      }) => api.workflowApis.updateWorkflow({ workflowId, requestData }),
      onSuccess: () => {
        queryClient.invalidateQueries(WorkflowQueryKeys.getAllWorkflows);
        showToastViaEvent({
          text: 'Workflow updated successfully!',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      },
      onError: (error: typeof AppError) => {
        showToastViaEvent({
          text: errorParser(getAppErrorDetails(error))[0],
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      },
    });

  const { mutate: deleteWorkflowMutation, isLoading: isDeleteWorkflowMutationLoading } =
    useMutation({
      mutationFn: ({ workflowId }: { workflowId: number }) =>
        api.workflowApis.deleteWorkflow({ workflowId }),
      onSuccess: () => {
        queryClient.invalidateQueries(WorkflowQueryKeys.getAllWorkflows);
        showToastViaEvent({
          text: 'Workflow deleted successfully!',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      },
      onError: (error: typeof AppError) => {
        showToastViaEvent({
          text: errorParser(getAppErrorDetails(error))[0],
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      },
    });

  const existingWorkflow = existingWorkflows.filter(
    (workflowConfig) => workflowConfig.type === actionType,
  )[0];

  const [workflow, setWorkflow] = useState<Workflow | undefined>(existingWorkflow);
  const [showWorkflowBuilder, setShowWorkflowBuilder] = useState(!!workflow);
  const [isEditing, setIsEditing] = useState(!existingWorkflow);
  const [isDeleteWorkflowModalOpen, setIsDeleteWorkflowModalOpen] = useState(false);
  const [isEditWorkflowModalOpen, setIsEditWorkflowModalOpen] = useState(false);
  const [isEditWorkflowConfirmModalOpen, setIsEditWorkflowConfirmModalOpen] = useState(false);

  const {
    data: pendingWorkflowRequestsData,
    isLoading: isPendingWorkflowRequestsLoading,
    isFetching: isPendingWorkflowRequestsFetching,
    isError: isPendingWorkflowRequestsError,
  } = useQuery({
    queryFn: () => api.workflowApis.getPendingWorkflowRequests(workflow?.id as number),
    queryKey: [WorkflowQueryKeys.pendingWorkflowRequests, `${workflow?.id}`],
    enabled: !!workflow?.id,
  });

  const pendingWorkflowRequestsCount = pendingWorkflowRequestsData?.total_pending ?? 0;

  // Resetting all the local states when the existingWorkflow changes because of cache-key invalidation
  useEffect(() => {
    setWorkflow(existingWorkflow);
    setIsEditing(!existingWorkflow);
    setShowWorkflowBuilder(!!existingWorkflow);
    setIsDeleteWorkflowModalOpen(false);
  }, [existingWorkflow]);

  const showViewModeCtas = existingWorkflow && showWorkflowBuilder;
  const showEditModeCtas = isEditing && showWorkflowBuilder;
  const showNewWorkflowCtas = !existingWorkflow && showWorkflowBuilder;

  const showCtaTab = showViewModeCtas || showEditModeCtas || showNewWorkflowCtas;

  const isSaveWorkflowCtaDisabled = workflow
    ? workflow?.config.filter((levelConfig) => !levelConfig.isSaved).length > 0
    : true;

  const isDeleteWorkflowCtaLoading =
    isPendingWorkflowRequestsLoading ||
    isPendingWorkflowRequestsFetching ||
    isDeleteWorkflowMutationLoading;
  const isEditWorkflowCtaLoading =
    isPendingWorkflowRequestsLoading ||
    isPendingWorkflowRequestsFetching ||
    isUpdateWorkflowMutationLoading;

  const isLocalAndServerWorkflowIdentical = isEqual(existingWorkflow, workflow);

  const handleOnSetUpWorkflowClick = () => {
    setShowWorkflowBuilder(true);
  };

  const handleExistingWorkflowSelect = (workflow: Workflow) => {
    setWorkflow({
      ...workflow,
      type: actionType,
      id: undefined,
    });
    setShowWorkflowBuilder(true);
  };

  const handleWorkflowChange = (workflowConfig: WorkflowLevelConfig[]) => {
    setWorkflow({
      ...workflow,
      type: actionType,
      config: workflowConfig,
    });
  };

  const handleCreateNewWorkflowCtaClicked = () => {
    if (workflow) {
      const mutationData = prepareWorkflowDataForBE(workflow);
      createWorkflowMutation(mutationData);
    }
  };

  const handleEditWorkflowCtaClicked = () => {
    if (pendingWorkflowRequestsCount > 0) {
      setIsEditWorkflowModalOpen(true);
      return;
    }

    handleConfirmEditCtaClicked();
  };

  const handleSaveWorkflowCtaClicked = () => {
    setIsEditWorkflowConfirmModalOpen(true);
  };

  const handleEditRejectAndSaveClicked = () => {
    if (workflow) {
      const mutationData = prepareWorkflowDataForBE(workflow);
      updateWorkflowMutation({
        workflowId: workflow.id as number,
        requestData: mutationData,
      });
    }
  };

  const handleDeleteWorkflowCtaClicked = () => {
    setIsDeleteWorkflowModalOpen(true);
  };

  const handleConfirmDeleteCtaClicked = () => {
    if (workflow && workflow.id) {
      deleteWorkflowMutation({
        workflowId: workflow.id,
      });
    }
  };

  const handleConfirmEditCtaClicked = () => {
    setIsEditWorkflowModalOpen(false);
    setIsEditing(true);
    setWorkflow((workflow) => {
      if (!workflow) {
        return;
      }

      const workflowCopy = { ...workflow };

      if (workflowCopy.config?.[0]) {
        workflowCopy.config[0].isSaved = false;
      }

      return workflowCopy;
    });
  };

  const firstStepContent = (
    <Box display="flex" gap="spacing.3" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="spacing.7"
        height="spacing.7"
        borderRadius="round"
        backgroundColor="surface.background.primary.subtle">
        <FilePlusIcon size="medium" color="interactive.icon.primary.normal" />
      </Box>
      <Text color="surface.text.gray.muted">{workflowLabel} request initiated</Text>
    </Box>
  );

  const lastStepContent = (
    <Box display="flex" gap="spacing.3" alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="spacing.7"
        height="spacing.7"
        borderRadius="round"
        backgroundColor="surface.background.primary.subtle">
        <CheckCircleIcon size="medium" color="feedback.icon.positive.intense" />
      </Box>
      <Text color="surface.text.gray.muted">{workflowLabel} request approved</Text>
    </Box>
  );

  const deleteWorkflowCtaContent = (
    <Button
      variant="secondary"
      onClick={handleDeleteWorkflowCtaClicked}
      isDisabled={isPendingWorkflowRequestsError}
      isLoading={isDeleteWorkflowCtaLoading}>
      Delete
    </Button>
  );

  const viewModeCtaContent = (
    <Box display="flex" gap="spacing.5">
      {deleteWorkflowCtaContent}
      <Button
        onClick={handleEditWorkflowCtaClicked}
        isLoading={isEditWorkflowCtaLoading}
        isDisabled={isPendingWorkflowRequestsError}>
        Edit Workflow
      </Button>
    </Box>
  );

  const editModeCtaContent = (
    <Box display="flex" gap="spacing.5">
      {deleteWorkflowCtaContent}
      <Button
        onClick={handleSaveWorkflowCtaClicked}
        isDisabled={isSaveWorkflowCtaDisabled || isPendingWorkflowRequestsError}>
        Save Workflow
      </Button>
    </Box>
  );

  const newWorkflowCtaContent = (
    <Box display="flex" gap="spacing.5">
      <Button
        onClick={handleCreateNewWorkflowCtaClicked}
        isLoading={isCreateWorkflowMutationLoading}
        isDisabled={isSaveWorkflowCtaDisabled}>
        End Workflow & Save
      </Button>
    </Box>
  );

  const renderCtaTabContent = () => {
    if (showNewWorkflowCtas) {
      return newWorkflowCtaContent;
    }

    if (showEditModeCtas) {
      return editModeCtaContent;
    }

    if (showViewModeCtas) {
      return viewModeCtaContent;
    }

    return null;
  };

  const ctaTabContent = (
    <Box
      width="100%"
      padding={['spacing.8', 'spacing.8', 'spacing.7', 'spacing.8']}
      backgroundColor="surface.background.gray.moderate"
      borderTopWidth="thin"
      borderTopColor="surface.border.gray.normal"
      display="flex"
      justifyContent="flex-end">
      {renderCtaTabContent()}
    </Box>
  );

  const deleteWorkflowModalContent = (
    <Modal
      isOpen={isDeleteWorkflowModalOpen}
      onDismiss={() => {
        setIsDeleteWorkflowModalOpen(false);
      }}>
      <ModalHeader title="Delete Workflow" />
      <ModalBody>
        {pendingWorkflowRequestsCount > 0 ? (
          <Box display="flex" flexDirection="column" gap="spacing.7">
            <Text weight="semibold" size="large">
              You will be rejecting {pendingWorkflowRequestsCount} pending{' '}
              {workflowLabel.toLowerCase()}{' '}
              {pendingWorkflowRequestsCount > 1 ? 'approvals' : 'approval'} by removing this
              workflow.
            </Text>
            <Text>
              All {workflowLabel.toLowerCase()} requests will be processed without approvals. Are
              you sure you want to proceed to deleting workflow?
            </Text>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap="spacing.7">
            <Text weight="semibold" size="large">
              Are you sure you want to delete the workflow?
            </Text>
          </Box>
        )}
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="large"
            onClick={handleConfirmDeleteCtaClicked}
            isLoading={isDeleteWorkflowMutationLoading}>
            {pendingWorkflowRequestsCount > 0 ? 'Yes, reject and delete' : 'Yes, delete'}
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );

  const editWorkflowModalContent = (
    <Modal
      isOpen={isEditWorkflowModalOpen}
      onDismiss={() => {
        setIsEditWorkflowModalOpen(false);
      }}>
      <ModalHeader title="Edit Workflow" />
      <ModalBody>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <Text weight="semibold" size="large">
            You will be rejecting {pendingWorkflowRequestsCount} pending{' '}
            {workflowLabel.toLowerCase()}{' '}
            {pendingWorkflowRequestsCount > 1 ? 'approvals' : 'approval'} on saving the changes to
            your workflow.
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="flex-end">
          <Button size="large" onClick={handleConfirmEditCtaClicked}>
            Got it, proceed
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );

  const editWorkflowConfirmationModal = (
    <Modal
      isOpen={isEditWorkflowConfirmModalOpen}
      onDismiss={() => {
        setIsEditWorkflowConfirmModalOpen(false);
      }}>
      <ModalHeader title="Edit Workflow" />
      <ModalBody>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <Text>
            Upon saving changes, all new {workflowLabel.toLocaleLowerCase()} approvals created going
            forward will follow the new workflow.
          </Text>
          <Text>
            Are you sure you want to reject all pending {workflowLabel.toLocaleLowerCase()}{' '}
            approvals and proceed to saving changes to workflow?
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="large"
            onClick={handleEditRejectAndSaveClicked}
            isLoading={isEditWorkflowCtaLoading}>
            Yes, reject and save
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        flexGrow="1"
        overflowY="scroll"
        display={showWorkflowBuilder ? 'block' : 'flex'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <Box
          flexGrow="1"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          {!showWorkflowBuilder && (
            <NoWorkflowView
              onClickSetUp={handleOnSetUpWorkflowClick}
              existingWorkflows={existingWorkflows}
              onExistingWorkflowSelect={handleExistingWorkflowSelect}
              pathname={pathname}
            />
          )}
          {showWorkflowBuilder && (
            <WorkflowLevelsChain
              levels={2}
              workflowType={actionType}
              workflowConfig={workflow?.config}
              updateWorkflowConfig={handleWorkflowChange}
              firstStepContent={firstStepContent}
              lastStepContent={lastStepContent}
              showEditControls={isEditing}
            />
          )}
        </Box>
      </Box>
      {showCtaTab && ctaTabContent}
      {deleteWorkflowModalContent}
      {editWorkflowModalContent}
      {editWorkflowConfirmationModal}
      <UnsavedWorkflowNavigation when={!isLocalAndServerWorkflowIdentical} />
    </Box>
  );
};

export { WorkflowBuilder };
