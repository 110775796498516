import React from 'react';
import { ArrowRightIcon, Badge, Box, Button, Text } from '@razorpay/blade/components';
import { ComplianceActionStatus } from 'components/RunPayroll/ComplianceActions/types';
import { EventTimestampWrapper } from 'components/RunPayroll/ComplianceActions/styles';
import {
  actionStatusesMap,
  ptSummaryDescriptionMap,
  badgeColorLabelMap,
  complianceActionsMap,
} from 'components/RunPayroll/ComplianceActions/data';
import { useGetComplianceActionDetails } from 'components/RunPayroll/ComplianceActions/hooks/useGetComplianceActionDetails';
import { useComplianceActionsContext } from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

const PtEventLog = ({
  status,
  paymentMadeOn,
  message,
  isOverDue,
}: {
  status: ComplianceActionStatus;
  paymentMadeOn?: string | null;
  message?: string;
  isOverDue?: boolean;
}) => {
  const paymentDate = getFormattedDateValue({
    date: paymentMadeOn,
    formatString: dateFormats.monthDateCommaYear,
    filler: '',
  });

  if (status === actionStatusesMap.success || status === actionStatusesMap.paidManually) {
    return (
      <EventTimestampWrapper borderColor="positive">
        <Text color="interactive.text.gray.muted">Payment made on</Text>
        {paymentDate ? <Text weight="semibold">{paymentDate}</Text> : null}
      </EventTimestampWrapper>
    );
  }

  if (status === actionStatusesMap.inProgress) {
    return (
      <EventTimestampWrapper borderColor="primary">
        <Text color="interactive.text.gray.muted">
          Your payment has been initiated and you will receive the status update on the email
          shortly.
        </Text>
      </EventTimestampWrapper>
    );
  }

  if (
    message &&
    ((status === actionStatusesMap.failed && !isOverDue) ||
      status === actionStatusesMap.returnFailed)
  ) {
    return (
      <EventTimestampWrapper borderColor="negative">
        <Text color="surface.text.gray.muted">{message}</Text>
      </EventTimestampWrapper>
    );
  }

  if (
    status === actionStatusesMap.notInitiated ||
    isOverDue ||
    status === actionStatusesMap.failed ||
    status === actionStatusesMap.returnFailed
  ) {
    return (
      <EventTimestampWrapper borderColor="primary">
        <Text color="surface.text.gray.muted">Average time taken to complete this step</Text>
        <Text weight="semibold">15-20 mins</Text>
      </EventTimestampWrapper>
    );
  }

  return null;
};

export const ProfessionalTaxActionSummary = ({ state }: { state: string }) => {
  const { actionsApiResponse } = useGetComplianceActionDetails();
  const complianceActionsContext = useComplianceActionsContext();

  const openActionDetails = () => {
    complianceActionsContext.setShowComplianceActionDetails({
      actionName: complianceActionsMap.payPt,
    });
  };

  const action = actionsApiResponse?.[complianceActionsMap.payPt]?.[state];

  if (!action) {
    return null;
  }

  const isOverdue = action.meta.is_over_due;

  const badgeProps = isOverdue ? badgeColorLabelMap.overdue : badgeColorLabelMap[action?.status];

  const description = isOverdue
    ? ptSummaryDescriptionMap.overdue
    : ptSummaryDescriptionMap[action.status];

  const showInitiateButton =
    !isOverdue &&
    (action.status === actionStatusesMap.notInitiated ||
      action.status === actionStatusesMap.failed ||
      action.status === actionStatusesMap.returnFailed);

  return (
    <Box>
      {badgeProps ? (
        <Badge
          testID={`badge-test-${badgeProps.label}`}
          color={badgeProps.color}
          marginBottom="spacing.2">
          {badgeProps.label}
        </Badge>
      ) : null}
      <Text marginBottom="spacing.2" size="large" weight="semibold">
        {state} Professional Tax
      </Text>
      <Text marginBottom="spacing.5" color="surface.text.gray.muted">
        {description}
      </Text>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <PtEventLog
            status={action.status}
            paymentMadeOn={action.meta.payment_made_on}
            message={action.meta.message}
            isOverDue={isOverdue}
          />
        </Box>
        <Box>
          {showInitiateButton ? (
            <Button onClick={openActionDetails}>Initiate</Button>
          ) : (
            <Button onClick={openActionDetails} variant="tertiary">
              View Details
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
