import React, { PropsWithChildren } from 'react';
import styles from './index.module.css';

function CircleIcon({ isSelected }: { isSelected: boolean }) {
  if (isSelected) {
    return (
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1.5"
          width="8"
          height="8"
          rx="4"
          fill="#5E7CF7"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    );
  }
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="1"
        width="9"
        height="9"
        rx="4.5"
        fill="#3B4571"
        stroke="#555555"
        strokeWidth="1"
      />
    </svg>
  );
}

export function RadioButton({
  isSelected,
  onSelect,
  children,
  className,
  isDisabled,
}: PropsWithChildren<{
  isSelected: boolean;
  isDisabled?: boolean;
  onSelect?: CallableFunction;
  className?: string;
}>) {
  return (
    <div
      onClick={() => onSelect && isDisabled !== true && onSelect()}
      className={`inline-block ${styles['radio-button']} ${
        isDisabled ? styles['radio-button--disabled'] : ''
      } ${className ? className : ''} ${isSelected ? styles['radio-button--selected'] : ''}`}
    >
      <div className={`w-5 my-auto inline-block`}>
        <CircleIcon isSelected={isSelected} />
      </div>
      <div
        className={`mx-3 inline-block ${isSelected ? 'text-white font-bold' : 'secondary-text'}`}
      >
        {children}
      </div>
    </div>
  );
}
