import React, { ReactElement } from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import styles from './index.module.css';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import success_icon from 'assets/bulkUploads/success_modal_icon.svg';
import { PrimaryButton } from 'components/ui/Button';

export function SuccessfullStatusModal({
  mainText,
  subText,
  callBackOnClick,
  buttonText
}: {
  mainText: string;
  subText?: string;
  callBackOnClick: Function;
  buttonText?:string;
}): ReactElement {
  return (
    <div className={styles['Modal-Parent']}>
      <Modal
        disableClose={true}>
        <div className={`flex flex-col ${styles['bulk-success-modal-container']}`}>
          <img src={getStaticMediaUrl(success_icon)} loading="lazy" style={{ width: '300px', margin: '10px' }} />
          <h3 className={styles['bulk-success-modal-main-text']}>{mainText}</h3>
          <p className={styles['bulk-success-modal-sub-text']}>{subText}</p>
          <PrimaryButton
            style={{ fontSize: '14px', width: 'fit-content' }}
            onClick={() => {
              callBackOnClick();
            }}>
            {buttonText ?? 'Go to Run payroll'}
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
}
