import { Box, CalendarIcon, CloseIcon, IconButton, Text } from '@razorpay/blade/components';
import { format, isSameDay } from 'date-fns';
import React from 'react';
import { CustomDeclarationType, DeclarationErrors } from '../types';
import { dateFormats } from 'utils/Dates';
import DatePickerBlade from '../../../ui/DatePickerBlade';

interface DateRangeDeclarationSelectorProps
  extends Partial<CustomDeclarationType['dates'][string]> {
  onChange: (value: Date | null, isStart: boolean) => void;
  index: number;
  onDelete: () => void;
  isProofUploadWindowSettings?: boolean;
  isLoading: boolean;
  errors?: DeclarationErrors[string];
  disableMonthSelector?: boolean;
  disableYearSelector?: boolean;
  monthOffset?: number;
  windowNameForMessage?: string;
}

export const DateRangeDeclarationSelector = ({
  onChange,
  startDate,
  endDate,
  index,
  onDelete,
  isProofUploadWindowSettings,
  isLoading,
  errors = {},
  disableYearSelector = false,
  disableMonthSelector = false,
  monthOffset = 0,
}: DateRangeDeclarationSelectorProps) => {
  const handleDateChange = (date: Date | null, isStart: boolean = true) => {
    onChange(date, isStart);
  };

  const getMessage = () => {
    if (!startDate || !endDate) {
      return null;
    }

    return `⚡️ ${
      isProofUploadWindowSettings ? 'Proof upload' : 'Declaration'
    } window will be open ${
      isSameDay(startDate, endDate)
        ? 'on ' + format(startDate, dateFormats.dayMonthShort)
        : 'from ' +
          format(startDate, dateFormats.dayMonthShort) +
          ' to ' +
          format(endDate, dateFormats.dayMonthShort)
    }`;
  };

  return (
    <Box
      borderWidth="thin"
      borderRadius="medium"
      borderColor='surface.border.gray.muted'
      display="flex"
      flexDirection="column"
      gap="spacing.6"
      padding={['spacing.5', 'spacing.5', 'spacing.6']}
      backgroundColor="surface.background.gray.moderate">
      <Box
        borderBottomColor="surface.border.gray.subtle"
        borderBottomWidth="thin"
        paddingBottom={'spacing.4'}
        gap="spacing.3"
        display="flex"
        alignItems="center">
        <Box
          backgroundColor="surface.background.gray.intense"
          display="grid"
          borderRadius="medium"
          placeItems="center"
          width="30px"
          height="30px">
          <CalendarIcon margin="auto" color="interactive.icon.information.subtle" size="medium" />
        </Box>
        <Box flex="1">
          <Text weight="semibold">Custom date range {index + 1}</Text>
        </Box>
        {index > 0 && (
          <IconButton
            isDisabled={isLoading}
            icon={CloseIcon}
            accessibilityLabel="Delete entry"
            onClick={onDelete}
          />
        )}
      </Box>
      <Box maxWidth="340px">
        <DatePickerBlade
          isDisabled={isLoading}
          labelPosition="left"
          dateFormat={dateFormats.dayMonthShort}
          placeholder="Select date"
          value={startDate}
          isRequired
          label="Start date"
          onChange={(date) => handleDateChange(date)}
          validationState={errors.startDate ? 'error' : 'none'}
          disableYearSelector={disableYearSelector}
          disableMonthSelector={disableMonthSelector}
          monthOffset={monthOffset}
          errorText={errors.startDate || ''}
        />
        <DatePickerBlade
          isDisabled={isLoading}
          marginTop="spacing.5"
          labelPosition="left"
          dateFormat={dateFormats.dayMonthShort}
          placeholder="Select date"
          value={endDate}
          isRequired
          label="End date"
          onChange={(date) => handleDateChange(date, false)}
          validationState={errors.endDate ? 'error' : 'none'}
          errorText={errors.endDate || ''}
          disableYearSelector={disableYearSelector}
          disableMonthSelector={disableMonthSelector}
          monthOffset={monthOffset}
        />
      </Box>
      {startDate && endDate && (
        <Text variant="caption" color="interactive.text.information.subtle" size="small">
          {getMessage()}
        </Text>
      )}
    </Box>
  );
};
