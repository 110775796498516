import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import api from 'api';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { ModalTitle } from 'components/ui/Modals/components/Modal';
import { FETCH_OTP_DATA } from '.';
import { useModal } from 'components/ui/Modals';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';

const ResetOtpMethod = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const resetToEmail = useMutation(
    () => {
      return api.login.resetMethod();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_OTP_DATA);
        closeModal();
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        showToastViaEvent({
          text: response.messageForUser,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      },
    },
  );

  return (
    <>
      <div className={`p-10`}>
        <div>
          <ModalTitle>Get OTP via Email</ModalTitle>
          <p className="my-10">
            On choosing this, your authenticator app code will no longer work. You will need to setup
            authenticator again if you wish to use the authenticator app later.
          </p>
        </div>
        <div className="flex justify-end">
          <StandardSecondaryButton
            className="mr-6"
            onClick={closeModal}
            disabled={resetToEmail.isLoading}>
            Cancel
          </StandardSecondaryButton>
          <StandardButton
            onClick={() => {
              resetToEmail.mutate();
            }}
            isPending={resetToEmail.isLoading}
            className="mr-0">
            Confirm & Send
          </StandardButton>
        </div>
      </div>
    </>
  );
};

export default ResetOtpMethod;