import React from "react";
import { Box, Text } from "@razorpay/blade/components";

interface TextWithDescriptionProps {
  componentName: string;
  description?: string;
}

export const TextWithDescription = ({ componentName, description }: TextWithDescriptionProps) => {
  return (
    <Box display="flex" alignItems="center" paddingY={'spacing.5'}>
      <Box display="flex" flexDirection="column">
        <Text>{componentName}</Text>
        <Text size="small" color={'surface.text.gray.muted'}>
          {description}
        </Text>
      </Box>
    </Box>
  );
};