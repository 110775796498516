import React from 'react';

export function ProgressBar({
  count,
  total,
  className
}: {
  count: number;
  total: number;
  className?: string;
}) {
  let width = 0;
  if (count >= 0 && total > 0) {
    width = Math.round((count * 100) / total);
  }
  if (width > 100) {
    width = 100;
  }
  if (width < 0) {
    width = 0;
  }
  return (
    <div className={`${className ? className : ''} transparent w-full h-2 rounder-full my-2`}>
      <div
        className={`bg-primary h-2 rounded-full`}
        style={{
          width: `${width}%`,
          transition: `all .4s ease-in` ,
        }}
      ></div>
    </div>
  );
}
