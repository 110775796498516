import React from 'react';

export function ConfirmationOption({
                                     setInConfirmation
                                   }: {
  setInConfirmation: (newState: boolean) => void
}) {
  return <button className={'flex justify-center btn btn--primary'}
                 onClick={() => setInConfirmation(true)}>
    Confirm & Next
  </button>;
}