import React from 'react';
import { Input } from 'components/ui/Form/Input';
import { SimpleColorTable } from 'components/ui/Table';
import {
  InitialEmployeeOnboardingAnnualCompensationData,
  TAXABLE_FLEXIBLE_BENEFIT,
  TAXABLE_NO,
  TAXABLE_YES,
} from '..';
import { EmployeeOnboardingAnnualCompensationDataType } from '../types';
import { getNumberFromIndianCurrencyString, toIndianCurrencyString } from 'utils/Numbers';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

// allowance type
const ALLOWANCE_TYPE_DEFAULT = 0;
const ALLOWANCE_TYPE_GRATUITY = 1;
const ALLOWANCE_TYPE_LEAVE_ENCASHMENT_TAXABLE = 2;
const ALLOWANCE_TYPE_LEAVE_ENCASHMENT_NONTAXABLE = 3;
const ALLOWANCE_TYPE_ADHOC_BONUS = 4;
const ALLOWANCE_TYPE_FLEXI_PARTNER_WALLET = 5;

interface Props {
  annualCompensationData: EmployeeOnboardingAnnualCompensationDataType;
  setAnnualCompensationData: React.Dispatch<
    React.SetStateAction<EmployeeOnboardingAnnualCompensationDataType>
  >;
}

const EmployeeAnnualCompensationCustomSalaryStructure: React.FC<Props> = ({
  annualCompensationData,
  setAnnualCompensationData,
}) => {
  function structureAllowance(
    allowanceName: string,
    allowanceAmount: number,
    allowanceTaxable: number,
    allowanceTercentage: number | null = 0,
  ) {
    let taxableEntity: number;
    if (allowanceTaxable === 1) {
      taxableEntity = TAXABLE_YES;
    } else if (allowanceTaxable === 0) {
      taxableEntity = TAXABLE_NO;
    }

    if (typeof allowanceTercentage !== 'undefined' && allowanceAmount <= 0) {
      allowanceAmount = 0;
    } else {
      allowanceTercentage = null;
      allowanceAmount = Number(allowanceAmount);
    }

    taxableEntity = Number(allowanceTaxable);
    let type = ALLOWANCE_TYPE_DEFAULT;

    return {
      name: allowanceName,
      amount: allowanceAmount,
      taxable: taxableEntity,
      type: type,
      percentage: allowanceTercentage,
    };
  }
  function insertAndUpdateAllowances(
    index: number,
    allowanceName: string = '',
    allowanceAmount: number = 0,
    allowanceTaxable: number,
  ) {
    let annualEmployeeCompensationAllowanceData = structureAllowance(
      allowanceName,
      allowanceAmount,
      allowanceTaxable,
    );
    let allowanceData = annualCompensationData.customAllowancesDetails.allowances;
    allowanceData[index] = annualEmployeeCompensationAllowanceData;
    setAnnualCompensationData({
      ...annualCompensationData,
      customAllowancesDetails: {
        ...annualCompensationData.customAllowancesDetails,
        allowances: allowanceData,
      },
    });
  }

  function updateCustomSalaryStructure(
    event: React.ChangeEvent<HTMLInputElement>,
    property: string,
  ) {
    const numberValue = getNumberFromIndianCurrencyString(event.target.value);
    if (!isNaN(numberValue)) {
      setAnnualCompensationData({
        ...annualCompensationData,
        salaryModel:
          annualCompensationData?.salaryModel !== null
            ? {
                ...annualCompensationData?.salaryModel,
                [property]: numberValue,
              }
            : {
                ...InitialEmployeeOnboardingAnnualCompensationData?.salaryModel,
                [property]: numberValue,
              },
      });
    }
  }
  return (
    <div>
      <p>
        We do not recommend setting custom structures. Please read our{' '}
        <a
          style={{ color: 'rgb(85 152 255)' }}
          href="https://intercom.help/XPayroll/en/articles/5160686-how-do-i-set-up-a-custom-salary-structure"
          target="_blank"
          rel="noreferrer">
          FAQ
        </a>{' '}
        carefully regarding custom salary structures before editing.
      </p>

      <SimpleColorTable className="clear-both mt-8 export">
        <thead>
          <tr>
            <th className="align-middle">Salary Head</th>
            <th className="align-middle">Monthly Amount</th>
            <th className="align-middle">Taxable?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic Salary</td>
            <td>
              <Input
                type="text"
                name="custom-basic-salary"
                placeholder=" Enter Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateCustomSalaryStructure(event, 'basic');
                }}
                value={
                  annualCompensationData.salaryModel
                    ? toIndianCurrencyString(annualCompensationData.salaryModel.basic ?? 0, true)
                    : 0
                }
              />
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Dearness Allowances</td>
            <td>
              <Input
                type="text"
                name="custom-da"
                placeholder=" Enter Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateCustomSalaryStructure(event, 'da');
                }}
                value={
                  annualCompensationData.salaryModel
                    ? toIndianCurrencyString(annualCompensationData.salaryModel.da ?? 0, true)
                    : 0
                }
              />
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>HRA</td>
            <td>
              <Input
                type="text"
                name="custom-hra"
                placeholder=" Enter Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                  if (!isNaN(numberValue)) {
                    setAnnualCompensationData({
                      ...annualCompensationData,
                      salaryModel: {
                        ...annualCompensationData?.salaryModel,
                        hra: {
                          ...annualCompensationData?.salaryModel.hra,
                          actualHRA: numberValue,
                        },
                      },
                    });
                  }
                }}
                value={
                  annualCompensationData.salaryModel
                    ? toIndianCurrencyString(
                        annualCompensationData?.salaryModel?.hra?.actualHRA ?? 0,
                        true,
                      )
                    : 0
                }
              />
            </td>
            <td>
              <a
                style={{ color: 'rgb(85 152 255)' }}
                href="https://razorpay.com/learn/income-tax-allowances-salaried-individuals-india/#House-Rent-Allowance-HRA"
                target="_blank"
                rel="noreferrer">
                Partially
              </a>
            </td>
          </tr>
          <tr>
            <td>Special Allowance</td>
            <td>
              <Input
                type="text"
                name="custom-special"
                placeholder=" Enter Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateCustomSalaryStructure(event, 'specialAllowance');
                }}
                value={
                  annualCompensationData?.salaryModel?.specialAllowance
                    ? toIndianCurrencyString(
                        annualCompensationData.salaryModel.specialAllowance ?? 0,
                        true,
                      )
                    : 0
                }
              />
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Leave & Travel Allowance</td>
            <td>
              <Input
                type="text"
                name="custom-lta"
                placeholder=" Enter Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateCustomSalaryStructure(event, 'lta');
                }}
                value={
                  annualCompensationData?.salaryModel?.lta
                    ? toIndianCurrencyString(annualCompensationData.salaryModel.lta ?? 0, true)
                    : 0
                }
              />
            </td>
            <td>Yes</td>
          </tr>

          <IsFeatureDisabledForRearch feature='isEmployerNPSDisabled'>
          {(annualCompensationData.isNpsEnabled ||
            annualCompensationData.salaryModel.npsEmployerContributionLimit > 0) && (
            <tr>
              <td>Employer NPS Contribution</td>
              <td>
                <Input
                  type="text"
                  name="custom-nps-employer-contribution"
                  placeholder=" Enter Amount"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateCustomSalaryStructure(event, 'npsEmployerContributionLimit');
                  }}
                  value={
                    annualCompensationData.salaryModel
                      ? toIndianCurrencyString(
                          annualCompensationData.salaryModel.npsEmployerContributionLimit ?? 0,
                          true,
                        )
                      : 0
                  }
                />
              </td>
              <td>No</td>
            </tr>
          )}
          </IsFeatureDisabledForRearch>

          {annualCompensationData.isEligibleForEmployerESIContribution && (
            <tr>
              <td>Employer ESI Contribution</td>
              <td>
                <Input
                  type="text"
                  name="employer-esi-contribution"
                  placeholder=" Enter Amount"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateCustomSalaryStructure(event, 'esiContribution');
                  }}
                  value={
                    annualCompensationData?.salaryModel?.esiContribution
                      ? toIndianCurrencyString(
                          annualCompensationData.salaryModel.esiContribution ?? 0,
                          true,
                        )
                      : 0
                  }
                />
              </td>
              <td>No</td>
            </tr>
          )}

          {annualCompensationData.isPf && (
            <tr>
              <td>Employer PF Contribution</td>
              <td>
                <Input
                  type="text"
                  name="employer-pf-contribution"
                  placeholder=" Enter Amount"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateCustomSalaryStructure(event, 'pfContribution');
                  }}
                  value={
                    annualCompensationData?.salaryModel?.pfContribution
                      ? toIndianCurrencyString(
                          annualCompensationData.salaryModel.pfContribution ?? 0,
                          true,
                        )
                      : 0
                  }
                />
              </td>
              <td>No</td>
            </tr>
          )}

          {annualCompensationData.flexiPartnerDetails.hasOrgOptedForPartnerFlexiBenefit && (
            <tr>
              <td>
                {annualCompensationData.flexiPartnerDetails.flexiPartnerName} flexible benefits
              </td>
              <td>
                <Input
                  type="text"
                  name="flexi-partner-allowance-amount"
                  placeholder=" Enter Amount"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                    if (!isNaN(numberValue)) {
                      setAnnualCompensationData({
                        ...annualCompensationData,
                        flexiPartnerDetails: {
                          ...annualCompensationData?.flexiPartnerDetails,
                          flexiPartnerAllowanceAmount: numberValue,
                        },
                      });
                    }
                  }}
                  value={
                    annualCompensationData.flexiPartnerDetails.flexiPartnerAllowanceAmount
                      ? toIndianCurrencyString(
                          annualCompensationData.flexiPartnerDetails.flexiPartnerAllowanceAmount ??
                            0,
                          true,
                        )
                      : 0
                  }
                />
              </td>
              <td>Flexi ({annualCompensationData.flexiPartnerDetails.flexiPartnerName})</td>
            </tr>
          )}

          {new Array(annualCompensationData.customAllowancesDetails.countOfCustomAllowancesAllowed)
            .fill(0)
            .map((value: number, index: number) => (
              <tr key={index}>
                <td>
                  <Input
                    type="text"
                    name="allowance-<?=$i?>"
                    placeholder=" Enter Custom Allowance"
                    onChange={(event) => {
                      insertAndUpdateAllowances(
                        index,
                        event.target.value,
                        annualCompensationData?.customAllowancesDetails.allowances[index]?.amount ??
                          0,
                        annualCompensationData?.customAllowancesDetails.allowances[index]
                          ?.taxable ?? TAXABLE_YES,
                      );
                    }}
                    value={
                      annualCompensationData.customAllowancesDetails.allowances[index]
                        ? annualCompensationData.customAllowancesDetails.allowances[index]?.name
                        : ''
                    }
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    name="allowance-<?=$i?>-amount"
                    placeholder=" Enter Amount"
                    onChange={(event) => {
                      const numberValue = getNumberFromIndianCurrencyString(event.target.value);
                      if (!isNaN(numberValue)) {
                        insertAndUpdateAllowances(
                          index,
                          annualCompensationData?.customAllowancesDetails.allowances[index]?.name,
                          numberValue,
                          annualCompensationData?.customAllowancesDetails.allowances[index]
                            ?.taxable ?? TAXABLE_YES,
                        );
                      }
                    }}
                    value={
                      annualCompensationData.customAllowancesDetails.allowances[index]
                        ? toIndianCurrencyString(
                            annualCompensationData.customAllowancesDetails.allowances[index]
                              ?.amount ?? 0,
                            true,
                          )
                        : 0
                    }
                  />
                </td>
                <td>
                  <select
                    style={{ width: '90px', padding: '7px 10px' }}
                    onChange={(event) => {
                      insertAndUpdateAllowances(
                        index,
                        annualCompensationData?.customAllowancesDetails.allowances[index]?.name,
                        annualCompensationData?.customAllowancesDetails.allowances[index]?.amount ??
                          0,
                        Number(event.target.value),
                      );
                    }}>
                    <option
                      value={TAXABLE_YES}
                      selected={
                        TAXABLE_YES ===
                        annualCompensationData.customAllowancesDetails.allowances[index]?.taxable
                      }>
                      Yes
                    </option>
                    <option
                      value={TAXABLE_NO}
                      selected={
                        TAXABLE_NO ===
                        annualCompensationData.customAllowancesDetails.allowances[index]?.taxable
                      }>
                      No
                    </option>
                    <option
                      value={TAXABLE_FLEXIBLE_BENEFIT}
                      selected={
                        TAXABLE_FLEXIBLE_BENEFIT ===
                        annualCompensationData.customAllowancesDetails.allowances[index]?.taxable
                      }>
                      Flexi
                    </option>
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </SimpleColorTable>
    </div>
  );
};

export default EmployeeAnnualCompensationCustomSalaryStructure;
