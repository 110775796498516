import api from 'api';
import { PrimaryButton } from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import { BulkUploadStatus, BulkUploadTypes } from 'constants/bulkUpload';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { AppError } from 'utils/AppError';
import ErrorCard from '../components/ErrorCard';
import FileUploadSection from '../components/FileUploadSection';
import styles from '../index.module.scss';
import { GET_BULK_UPLOAD_RECORD } from '../queries/queries';

export type FileUploadStepProps = {
  bulkUploadType: BulkUploadTypes;
  onNext: () => void;
  pollingRequestId: string;
};

const FileUploadStep = ({ bulkUploadType, onNext, pollingRequestId }: FileUploadStepProps) => {
  const [bulkUploadRecord, setBulkUploadRecord] = useState<
    BulkUploadDetailsSchemaContract[0] | null
  >(null);

  const { isLoading, error } = useQuery(
    GET_BULK_UPLOAD_RECORD,
    () => {
      return api.bulkUploads.fetchBulkUploadRecordByType(bulkUploadType);
    },
    {
      onSuccess: (data) => data && setBulkUploadRecord(data.length > 0 ? data[0] : null),
      onError: (error: typeof AppError) => {},
    },
  );

  if (isLoading) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorCard
        error={error}
        buttonText={'Back to Dashboard'}
        onClick={() => {
          window.location.href = '/dashboard';
        }}
      />
    );
  }

  return (
    <>
      <FileUploadSection
        title="2. Upload the updated sheet below👇"
        bulkUploadRecord={bulkUploadRecord}
        setBulkUploadRecord={setBulkUploadRecord}
        pollingRequestId={pollingRequestId}
        bulkUpoadType={bulkUploadType}
      />
      <div className={styles['section']}>
        <PrimaryButton
          className={styles['button']}
          disabled={
            bulkUploadRecord
              ? bulkUploadRecord.status !== BulkUploadStatus.STATUS_VALIDATION_SUCCESS
              : true
          }
          onClick={(event) => {
            onNext();
          }}>
          Upload & preview
        </PrimaryButton>
      </div>
    </>
  );
};

export default FileUploadStep;
