import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import React, { ReactElement } from 'react';
import CamsActivation from './components/CamsActivation';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { AnchorCard } from 'components/ui/SidebarCard';
import { Redirect, useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

function CamsSettings(): ReactElement {
  const currentLoggedInUserIsOnFreePlan = useSelector(loggedInUserSelectors.isOnFreePlan);

  if (currentLoggedInUserIsOnFreePlan) {
    return <Redirect to={routePaths.settings.camsIntro} />;
  }

  return (
    <PageWrapper>
      {
        <MainCase>
          <CamsActivation />
        </MainCase>
      }
      <RightSidebar>
        <AnchorCard
          href="https://intercom.help/XPayroll/en/articles/4754555-how-does-biometric-integration-for-attendance-work4"
          imageSymbolType={'help'}
          heading="Learn more about CAMS Integration"
          newTab></AnchorCard>
      </RightSidebar>
    </PageWrapper>
  );
}

export default CamsSettings;
