import React from 'react';
import callInsuranceAgent from 'assets/callexpert.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import api from 'api';

async function sendCalendlyInstrumentationEvent() {
  try {
    const response = await api.analytics.push({
      eventName: 'insurance.clicked',
      eventType: 'viewed',
    });
  } catch (error) {
    console.log(error);
  }
}

export default function CalendlyCard() {
  return (
    <div
      className="bg-rhino px-5 py-5 m-4 ml-3 rounded"
      onClick={() => sendCalendlyInstrumentationEvent()}
    >
      <a
        className="flex flex-row items-center "
        href="https://bit.ly/TalktoInsuranceExperts"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-4xl font-semibold ">
          <p>Book a call with</p>
          <p>our insurance expert</p>
        </div>
        <div className="p-4 ml-4 bg-primary rounded">
          <img
            src={getStaticMediaUrl(callInsuranceAgent)}
            alt="call insurance agent"
            className="w-full"
          />
        </div>
      </a>
    </div>
  );
}
