import {
  ActionList,
  ActionListItem,
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  SelectInput,
} from '@razorpay/blade/components';
import React from 'react';

export interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  onCurrentPageChange?: (nextPage: number) => void;
  isDisabled?: boolean;
  pageSizeOptions?: number[];
  leftContainer?: React.ReactNode;
}

const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onItemsPerPageChange,
  onCurrentPageChange,
  isDisabled,
  pageSizeOptions = [10, 20, 30, 40],
  leftContainer,
}: PaginationProps) => {
  const totalPages = totalItems / itemsPerPage;
  const showNextButton = totalPages > 1;
  const showPrevButton = currentPage > 1;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      padding={['spacing.5', 'spacing.8']}
      width="100%"
      borderTopColor="surface.border.gray.subtle"
      borderTopWidth="thin">
      <Box>{leftContainer ? leftContainer : null}</Box>
      <Box display="flex" gap="spacing.7">
        <Dropdown>
          <SelectInput
            label=""
            value={itemsPerPage.toString()}
            onChange={({ values }) => {
              onItemsPerPageChange && onItemsPerPageChange(Number(values[0]));
            }}
            isDisabled={isDisabled}
          />
          <DropdownOverlay>
            <ActionList>
              {pageSizeOptions.map((option) => (
                <ActionListItem key={option} title={option.toString()} value={option.toString()} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        {showPrevButton && (
          <Button
            variant="tertiary"
            icon={ArrowLeftIcon}
            iconPosition="left"
            onClick={() => {
              onCurrentPageChange && onCurrentPageChange(currentPage - 1);
            }}
            isDisabled={isDisabled}>
            Previous
          </Button>
        )}
        {showNextButton && (
          <Button
            variant="tertiary"
            icon={ArrowRightIcon}
            iconPosition="right"
            onClick={() => {
              onCurrentPageChange && onCurrentPageChange(currentPage + 1);
            }}
            isDisabled={isDisabled}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export { Pagination };
