import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import styles from './index.module.scss';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const ResumePayroll = ({
  payrollId,
  payrollMonth,
  onError,
}: { payrollId: number; payrollMonth: string } & SetErrorMessageType) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();

  const resume = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return isM2S1Enabled
        ? api.runpayrollMutations.bulkResumePayroll({
            payroll_month: payrollMonth,
            ids: [payrollId],
          })
        : api.runPayroll.bulkResumePayroll(payrollMonth, [payrollId]);
    },
    {
      onSuccess: () => {
        closeModal();
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const resumeAll = useMutation(
    ({ payrollMonth }: { payrollMonth: string }) => {
      return api.runPayroll.resumeAllPayroll(payrollMonth);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Resume Pay
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <p className="mb-16">
            Do you want resume this employee's pay? You can also resume pay for everyone who is
            currently skipped.
          </p>
          <div className="flex gap-6">
            <StandardButton
              onClick={() => resume.mutate({ payrollId })}
              isPending={resume.isLoading}
              disabled={resume.isLoading || resumeAll.isLoading}>
              RESUME PAY
            </StandardButton>
            <StandardButton
              onClick={() => resumeAll.mutate({ payrollMonth })}
              isPending={resumeAll.isLoading}
              disabled={resume.isLoading || resumeAll.isLoading}>
              RESUME ALL SKIPPED
            </StandardButton>
            <StandardSecondaryButton
              onClick={() => closeModal()}
              disabled={resume.isLoading || resumeAll.isLoading}>
              CANCEL
            </StandardSecondaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResumePayroll;
