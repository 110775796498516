import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import QuickLinks from './components/QuickLinks';

import React, { useState } from 'react';
import {
  adminArticleLinks,
  employeeArticleLinks,
  videoLinks,
  adminArticleLinksM1,
  employeeArticleLinksM1,
  videoLinksM1,
  apiLinks
} from './data';
import { AnchorCard, ClickableCard } from 'components/ui/SidebarCard';
import useReduxSelector from 'utils/useReduxSelector';
import { InfoMessage } from 'components/ui/Message';
import { getStaticMediaUrl } from 'utils/Urls';
import linkArrow from 'assets/link-arrow.svg';
import ExternalLink from 'components/ui/ExternalLink';
import userRoles from 'constants/userRoles';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import quickLinksIcon from 'assets/quick-links-icon.svg';
import videoLinksIcon from 'assets/video-links-icon.svg';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

export default function HelpPage() {
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  let loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const userRoleId = loggedInUser.data?.currentOrganization?.employeeDetails?.userRoleId;
  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
  const permissions = useSelector(loggedInUserSelectors.permissions) ?? [];
  const hasEditCompanyPermission = permissions.includes('EDIT_COMPANY_DETAILS');

  function checkPlan() {
    if (!isOnProPlan) {
      setShowInfoMessage(true);
    }
  }

  const { isMileStone1Enabled } = useIsFeatureDisabledForRearch();

  const adminLinks = isMileStone1Enabled ? adminArticleLinksM1 : adminArticleLinks;

  const employeeLinks = isMileStone1Enabled ? employeeArticleLinksM1 : employeeArticleLinks;

  return (
    <>
      <PageWrapper>
        <MainCase>
          <h1 className="font-heading font-bold text-left text-5xl mb-12">Get Help</h1>
          {showInfoMessage && (
            <InfoMessage className="mb-8">
              <span>Live chat support is available on Pro plan. </span>
              <ExternalLink
                href="https://razorpay.com/docs/payroll/plans/"
                className="text-primary">
                Learn more
              </ExternalLink>
              <span> about our plans.</span>
            </InfoMessage>
          )}
          <div className="pt-12 pb-12 pl-12 bg-persian-blue">
            <QuickLinks
              sectionHeading="Quick Links"
              quickLinks={userRoleId === userRoles.ADMIN ? adminLinks : employeeLinks}
              icon={quickLinksIcon}
            />
            <ExternalLink
              className="pt-8 flex items-center text-primary"
              href="https://razorpay.com/docs/payroll/faqs/">
              Search more help articles on the documentation {' '}
              <img src={getStaticMediaUrl(linkArrow)} className="pl-4" />
            </ExternalLink>
            {hasEditCompanyPermission && (
              <div className="mt-16">
                <QuickLinks
                  sectionHeading="XPayroll APIs"
                  quickLinks={apiLinks}
                  icon={quickLinksIcon}
                />
              </div>
            )}
            {(userRoleId === userRoles.ADMIN || userRoleId === userRoles.HUMAN_RESOURCES) && (
              <div className="mt-16">
                <QuickLinks
                  sectionHeading="Help Videos"
                  quickLinks={isMileStone1Enabled ? videoLinksM1 : videoLinks}
                  icon={videoLinksIcon}
                />
              </div>
            )}

          </div>
        </MainCase>
        <RightSidebar>
          <h1 className="font-heading font-bold text-left text-4xl mb-12">Get in touch</h1>
          {isOnProPlan && (
            <>
              {(userRoleId === userRoles.ADMIN || userRoleId === userRoles.HUMAN_RESOURCES) && (
                <ClickableCard
                  imageSymbolType={'chat'}
                  heading="Chat Support"
                  onClick={() => checkPlan()}
                  className="open-intercom-chat">
                  Chat with us live from 10 AM to 5:30 PM
                </ClickableCard>
              )}

              <AnchorCard
                href="mailto:xpayroll@razorpay.com"
                imageSymbolType={'help'}
                heading="Mail Support"
                newTab>
                Please email us at xpayroll@razorpay.com
              </AnchorCard>
            </>
          )}
          {!isOnProPlan && (
            <>
              <AnchorCard
                href="mailto:xpayroll@razorpay.com"
                imageSymbolType={'help'}
                heading="Mail Support"
                newTab>
                Please email us at xpayroll@razorpay.com
              </AnchorCard>
              {(userRoleId === userRoles.ADMIN || userRoleId === userRoles.HUMAN_RESOURCES) && (
                <ClickableCard
                  imageSymbolType={'chat'}
                  heading="Chat Support"
                  onClick={() => checkPlan()}
                  className="open-intercom-chat">
                  Chat with us live from 10 AM to 5:30 PM
                </ClickableCard>
              )}
            </>
          )}
        </RightSidebar>
      </PageWrapper>
    </>
  );
}
