import { Text } from '@razorpay/blade/components';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTextSize } from '../useTextSize';

interface StreamingTextProps {
  children: string;
}

const StreamingTextContainer = styled.div(
  () => `
    position: relative;
    flex-grow: 1;
    overflow: hidden;
`,
);

const StreamingText = ({ children }: StreamingTextProps) => {
  const [text, setText] = useState('');
  const textSize = useTextSize();

  useEffect(() => {
    const textArray = children.split(' ');

    setText('');
    const printText = (textArray: string[], index: number) => {
      if (index < textArray.length) {
        setText((prevText) => prevText + ' ' + textArray[index]);
        setTimeout(() => {
          printText(textArray, index + 1);
        }, 50);
      }
    };

    setTimeout(() => {
      printText(textArray, 0);
    }, 1000);
  }, [children]);

  return (
    (<StreamingTextContainer>
      <Text display="flex" size={textSize} color="surface.text.gray.subtle">
        {text}
      </Text>
    </StreamingTextContainer>)
  );
};

export { StreamingText };
