import React from 'react';
import { useDispatch } from 'react-redux';
import { IInsurance } from '../../../../reducers/insurance';
import {
  addInsuranceEmployeeDependent,
  deleteInsuranceEmployeeDependent,
  IInsuranceEmployee,
  submitInsuranceEmployee,
  updateInsuranceEmployee,
  updateInsuranceEmployeeDependent,
} from '../../../../reducers/insuranceEmployee';
import {
  areAllBasicEmployeeAndDependentFieldsCorrect,
  validateDependentFields,
  validateEmployeeFields,
  validateOrderOfAges,
} from '../../../../reducers/insuranceEmployee/validators';
import { GetOrgEmployeeForInsuranceSchemaContract } from '../../../../schemas/GetOrgEmployeeForInsuranceSchema';
import { WarningAlert } from '../../../ui/Alert';
import { PrimaryButton, SecondaryButton } from '../../../ui/Button';
import { ContentContainer } from '../../../ui/ContentContainer';
import { Input, InputElement } from '../../../ui/Form/Input';
import { RadioButton } from '../../../ui/RadioButton';
import { useHistory } from 'react-router-dom';
import DependentBeneficiaryDetails from './components/DependentBeneficiaryDetails';

const updateDependentName =
  (dispatch: ReturnType<typeof useDispatch>, dependentId: number, employeeId?: number | null) =>
  (name: string) =>
    employeeId &&
    dispatch(
      updateInsuranceEmployeeDependent(employeeId, dependentId, {
        id: dependentId,
        name,
      }),
    );
const updateDependentDob =
  (dispatch: ReturnType<typeof useDispatch>, dependentId: number, employeeId?: number | null) =>
  (dateOfBirth: string) =>
    employeeId &&
    dispatch(
      updateInsuranceEmployeeDependent(employeeId, dependentId, {
        id: dependentId,
        dateOfBirth,
      }),
    );
const updateDependentGender =
  (dispatch: ReturnType<typeof useDispatch>, dependentId: number, employeeId?: number | null) =>
  (gender: 'm' | 'f') =>
    employeeId &&
    dispatch(
      updateInsuranceEmployeeDependent(employeeId, dependentId, {
        id: dependentId,
        gender: gender === 'm' ? 'male' : 'female',
      }),
    );

const updateChildGender =
  (dispatch: ReturnType<typeof useDispatch>, dependentId: number, employeeId?: number | null) =>
  (gender: 'm' | 'f') =>
    employeeId &&
    dispatch(
      updateInsuranceEmployeeDependent(employeeId, dependentId, {
        id: dependentId,
        gender: gender === 'm' ? 'male' : 'female',
        relation: gender === 'm' ? 'son' : 'daughter',
      }),
    );

function BeneficiaryDetails({
  label,
  id,
  name,
  phone,
  dob,
  gender,
  isEligible,
  compulsoryError,
  shouldShowGender,
  onDelete,
  showDelete,
  updateName,
  updateDob,
  updateGender,
  isEmployee,
  updatePhone,
  hireDate,
  updateHireDate,
  isAdmin,
}: {
  id: number;
  phone: null | string;
  isEmployee: boolean;
  label: React.ReactNode;
  name?: string | null;
  dob?: string | null;
  hireDate?: string | null;
  gender?: 'm' | 'f' | 'male' | 'female' | 'o' | null;
  isEligible: boolean;
  compulsoryError?: string | null;
  shouldShowGender?: boolean;
  onDelete: CallableFunction;
  showDelete?: boolean;
  updateName: CallableFunction;
  updatePhone?: CallableFunction;
  updateDob: CallableFunction;
  updateHireDate?: CallableFunction;
  updateGender: CallableFunction;
  isAdmin: boolean;
}) {
  const error =
    compulsoryError && compulsoryError.length > 0
      ? compulsoryError
      : !isEligible
      ? 'Not eligible'
      : null;
  const deleteButton = (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9C8.55229 9 9 9.44771 9 10V16C9 16.5523 8.55229 17 8 17C7.44772 17 7 16.5523 7 16V10C7 9.44771 7.44772 9 8 9Z"
        fill="#C4C4C4"
      />
      <path
        d="M13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V10Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4V3C5 1.34315 6.34315 0 8 0H12C13.6569 0 15 1.34315 15 3V4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H18V19C18 20.6569 16.6569 22 15 22H5C3.34315 22 2 20.6569 2 19V6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H5ZM7 3C7 2.44772 7.44772 2 8 2H12C12.5523 2 13 2.44772 13 3V4H7V3ZM16 6V19C16 19.5523 15.5523 20 15 20H5C4.44772 20 4 19.5523 4 19V6H16Z"
        fill="#C4C4C4"
      />
    </svg>
  );

  return (
    <div className="text-3xl flex flex-col lg:space-y-4">
      <div className="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0 w-full">
        <div
          className={`flex w-full lg:w-40 font-bold lg:text-right mr-16 text-2xl my-auto pt-10 `}
        >
          &nbsp;
          <div className={`flex-1 font-bold lg:text-right text-2xl ${error ? 'text-red-700' : ''}`}>
            {label} {error ? `(${error})` : ''}
          </div>{' '}
          <div className=" inline-block lg:hidden" onClick={() => onDelete()}>
            {deleteButton}
          </div>
        </div>
        <InputElement label="Full name" className="lg:w-96 my-auto">
          <Input
            type="text"
            value={name ? name : undefined}
            onChange={(event) => updateName(event.target.value)}
          />
        </InputElement>
        <InputElement label="Date of birth" className="lg:w-64 my-auto">
          <Input
            type="date"
            value={dob ? dob : ''}
            onChange={(event) => {
              updateDob(event.currentTarget.valueAsDate?.toISOString().split('T')[0]);
            }}
          />
        </InputElement>
        <InputElement
          label="Gender"
          className={`lg:w-96 my-auto ${
            shouldShowGender === false ? 'hidden lg:block lg:opacity-0' : ''
          }`}
        >
          <div className="space-x-2">
            <RadioButton
              isDisabled={shouldShowGender === false}
              onSelect={() => updateGender('m')}
              isSelected={gender === 'm' || gender === 'male'}
            >
              Male
            </RadioButton>
            <RadioButton
              isDisabled={shouldShowGender === false}
              onSelect={() => updateGender('f')}
              isSelected={gender === 'f' || gender === 'female'}
            >
              Female
            </RadioButton>
          </div>
        </InputElement>
        {showDelete !== false && (
          <div
            className="lg:my-auto hidden lg:block lg:pt-10 cursor-pointer"
            onClick={() => onDelete()}
          >
            {deleteButton}
          </div>
        )}
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0 w-full">
        <div
          className={`flex w-full lg:w-40 font-bold lg:text-right mr-16 text-2xl my-auto pt-10 `}
        >
          <div
            className={`flex-1 font-bold lg:text-right text-2xl ${error ? 'text-red-700' : ''}`}
          ></div>
        </div>
        {isAdmin && isEmployee && (
          <InputElement label="Hire date" className="lg:w-64 my-auto">
            <Input
              required={isAdmin}
              type="date"
              value={hireDate ? hireDate : ''}
              onChange={(event) =>
                updateHireDate &&
                updateHireDate(event.currentTarget.valueAsDate?.toISOString().split('T')[0])
              }
            />
          </InputElement>
        )}
        {isEmployee && (
          <InputElement label="Phone" className="lg:w-96 my-auto">
            <Input
              type="text"
              maxLength={14}
              value={phone ? phone : undefined}
              onChange={(event) => updatePhone && updatePhone(event.target.value)}
            />
          </InputElement>
        )}
      </div>
    </div>
  );
}

function FamilyDetails({
  insuranceEmployee,
  isAdmin,
  deleteInsuranceEmployeeDependentAction,
  addInsuranceEmployeeDependentAction,
}: {
  insuranceEmployee: IInsuranceEmployee;
  deleteInsuranceEmployeeDependentAction: typeof deleteInsuranceEmployeeDependent;
  addInsuranceEmployeeDependentAction: typeof addInsuranceEmployeeDependent;
  updateInsuranceEmployeeDependentAction: typeof updateInsuranceEmployeeDependent;
  isAdmin: boolean;
}) {
  const dispatch = useDispatch();
  if (insuranceEmployee.data === null) {
    return <></>;
  }

  const spouses = insuranceEmployee.data.dependents.filter((dependent) =>
    ['spouse'].includes(dependent.relation),
  );
  const children = insuranceEmployee.data.dependents.filter((dependent) =>
    ['son', 'daughter'].includes(dependent.relation),
  );
  const totalEligibleSpouses = spouses.length;
  const totalEligibleChildren = children.length;
  return (
    <>
      <div className="text-3xl flex flex-col lg:flex-row lg:flex-wrap lg:space-x-3 my-auto lg:py-10">
        <div className="w-40 font-bold lg:text-right mr-16 text-3xl my-4 lg:my-auto">Family</div>
        {totalEligibleSpouses === 0 && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'spouse',
                    gender: insuranceEmployee.data?.gender === 'f' ? 'male' : 'female',
                  }),
                );
            }}
          >
            Add spouse
          </SecondaryButton>
        )}
        {totalEligibleChildren < 4 && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'son',
                    gender: 'male',
                  }),
                );
            }}
          >
            Add Child (Up to {4 - totalEligibleChildren})
          </SecondaryButton>
        )}
      </div>

      {spouses.map((spouse, index) => {
        return (
          <DependentBeneficiaryDetails
            key={spouse.id}
            label="Spouse"
            isAdmin={isAdmin}
            name={spouse.name}
            dob={spouse.dateOfBirth}
            shouldShowGender={false}
            gender={spouse.gender}
            onDelete={() =>
              insuranceEmployee.data &&
              dispatch(deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, spouse.id))
            }
            showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
            isEligible={spouse.isEligible || !isAdmin}
            updateName={updateDependentName(dispatch, spouse.id, insuranceEmployee.data?.id)}
            updateDob={updateDependentDob(dispatch, spouse.id, insuranceEmployee.data?.id)}
            updateGender={updateDependentGender(dispatch, spouse.id, insuranceEmployee.data?.id)}
            compulsoryError={
              validateDependentFields(spouse) ||
              (index > 0 ? 'Cannot have more than one spouse' : '')
            }
          />
        );
      })}
      {children.map((child, index) => {
        return (
          <DependentBeneficiaryDetails
            label={`Child ${index + 1}`}
            isAdmin={isAdmin}
            key={child.id}
            name={child.name}
            dob={child.dateOfBirth}
            gender={child.gender}
            updateName={updateDependentName(dispatch, child.id, insuranceEmployee.data?.id)}
            updateDob={updateDependentDob(dispatch, child.id, insuranceEmployee.data?.id)}
            updateGender={updateChildGender(dispatch, child.id, insuranceEmployee.data?.id)}
            onDelete={() =>
              insuranceEmployee.data &&
              dispatch(deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, child.id))
            }
            showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
            compulsoryError={
              validateDependentFields(child) ||
              (index > 3 ? 'Cannot have more than 4 eligible children' : '')
            }
            isEligible={child.isEligible || !isAdmin}
          />
        );
      })}
    </>
  );
}

function DependentDetails({
  insuranceEmployee,
  deleteInsuranceEmployeeDependentAction,
  addInsuranceEmployeeDependentAction,
  isAdmin,
}: {
  insuranceEmployee: IInsuranceEmployee;
  deleteInsuranceEmployeeDependentAction: typeof deleteInsuranceEmployeeDependent;
  addInsuranceEmployeeDependentAction: typeof addInsuranceEmployeeDependent;
  updateInsuranceEmployeeDependentAction: typeof updateInsuranceEmployeeDependent;
  isAdmin: boolean;
}) {
  const dispatch = useDispatch();
  if (!insuranceEmployee.data) {
    return <></>;
  }

  const { father, fatherInLaw, mother, motherInLaw } = insuranceEmployee.data.dependents.reduce(
    (hash, dependent) => {
      hash[dependent.relation] = dependent;
      return hash;
    },
    {} as Record<
      GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]['relation'],
      GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]
    >,
  );

  return (
    <>
      <div className="text-3xl flex flex-col lg:flex-row lg:flex-wrap lg:space-x-3 my-auto py-10">
        <div className="w-40 font-bold text-right mr-16 text-3xl my-auto">Dependents</div>
        {!father && !fatherInLaw && !motherInLaw && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'father',
                    gender: 'male',
                  }),
                );
            }}
          >
            Add father{' '}
          </SecondaryButton>
        )}
        {!mother && !fatherInLaw && !motherInLaw && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'mother',
                    gender: 'female',
                  }),
                );
            }}
          >
            Add mother
          </SecondaryButton>
        )}
        {!fatherInLaw && !father && !mother && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'fatherInLaw',
                    gender: 'male',
                  }),
                );
            }}
          >
            Add father in law
          </SecondaryButton>
        )}
        {!motherInLaw && !father && !mother && (
          <SecondaryButton
            size="sm"
            className="mt-4 lg:mt-0"
            onClick={() => {
              if (!insuranceEmployee.data) {
                return;
              }
              insuranceEmployee.data &&
                dispatch(
                  addInsuranceEmployeeDependentAction(insuranceEmployee.data.id, {
                    relation: 'motherInLaw',
                    gender: 'female',
                  }),
                );
            }}
          >
            Add mother in law
          </SecondaryButton>
        )}
      </div>

      {father && (
        <DependentBeneficiaryDetails
          label={`Father`}
          name={father.name}
          isAdmin={isAdmin}
          dob={father.dateOfBirth}
          gender={father.gender}
          shouldShowGender={false}
          isEligible={father.isEligible || !isAdmin}
          updateName={updateDependentName(dispatch, father.id, insuranceEmployee.data?.id)}
          updateDob={updateDependentDob(dispatch, father.id, insuranceEmployee.data?.id)}
          updateGender={updateDependentGender(dispatch, father.id, insuranceEmployee.data?.id)}
          onDelete={() =>
            insuranceEmployee.data &&
            dispatch(deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, father.id))
          }
          showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
          compulsoryError={validateDependentFields(father)}
        />
      )}
      {mother && (
        <DependentBeneficiaryDetails
          label={`Mother`}
          name={mother.name}
          isAdmin={isAdmin}
          dob={mother.dateOfBirth}
          gender={mother.gender}
          shouldShowGender={false}
          isEligible={mother.isEligible || !isAdmin}
          updateName={updateDependentName(dispatch, mother.id, insuranceEmployee.data?.id)}
          updateDob={updateDependentDob(dispatch, mother.id, insuranceEmployee.data?.id)}
          updateGender={updateDependentGender(dispatch, mother.id, insuranceEmployee.data?.id)}
          onDelete={() =>
            insuranceEmployee.data &&
            dispatch(deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, mother.id))
          }
          showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
          compulsoryError={validateDependentFields(mother)}
        />
      )}
      {fatherInLaw && (
        <DependentBeneficiaryDetails
          label={`Father in law`}
          name={fatherInLaw.name}
          isAdmin={isAdmin}
          dob={fatherInLaw.dateOfBirth}
          gender={fatherInLaw.gender}
          shouldShowGender={false}
          updateName={updateDependentName(dispatch, fatherInLaw.id, insuranceEmployee.data?.id)}
          updateDob={updateDependentDob(dispatch, fatherInLaw.id, insuranceEmployee.data?.id)}
          updateGender={updateDependentGender(dispatch, fatherInLaw.id, insuranceEmployee.data?.id)}
          isEligible={fatherInLaw.isEligible || !isAdmin}
          onDelete={() =>
            insuranceEmployee.data &&
            dispatch(
              deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, fatherInLaw.id),
            )
          }
          showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
          compulsoryError={
            validateDependentFields(fatherInLaw) ||
            ((father && father.isEligible) || (mother && mother.isEligible)
              ? 'Cannot cover as parents are already included'
              : '')
          }
        />
      )}
      {motherInLaw && (
        <DependentBeneficiaryDetails
          label={`Mother in law`}
          name={motherInLaw.name}
          isAdmin={isAdmin}
          dob={motherInLaw.dateOfBirth}
          gender={motherInLaw.gender}
          shouldShowGender={false}
          isEligible={motherInLaw.isEligible || !isAdmin}
          updateName={updateDependentName(dispatch, motherInLaw.id, insuranceEmployee.data?.id)}
          updateDob={updateDependentDob(dispatch, motherInLaw.id, insuranceEmployee.data?.id)}
          updateGender={updateDependentGender(dispatch, motherInLaw.id, insuranceEmployee.data?.id)}
          onDelete={() =>
            insuranceEmployee.data &&
            dispatch(
              deleteInsuranceEmployeeDependentAction(insuranceEmployee.data.id, motherInLaw.id),
            )
          }
          showDelete = {!(insuranceEmployee.data?.status === 'PURCHASE_IN_PROGRESS' ||
            insuranceEmployee.data?.status === 'PURCHASED')}
          compulsoryError={
            validateDependentFields(motherInLaw) ||
            ((father && father.isEligible) || (mother && mother.isEligible)
              ? 'Cannot cover as parents are already included'
              : '')
          }
        />
      )}
    </>
  );
}

export function EmployeeDetails({
  insuranceEmployee,
  deleteInsuranceEmployeeDependentAction,
  addInsuranceEmployeeDependentAction,
  updateInsuranceEmployeeDependentAction,
  updateInsuranceEmployeeAction,
  submitInsuranceEmployeeAction,
  isAdmin,
  insurance,
}: {
  insuranceEmployee: IInsuranceEmployee;
  deleteInsuranceEmployeeDependentAction: typeof deleteInsuranceEmployeeDependent;
  addInsuranceEmployeeDependentAction: typeof addInsuranceEmployeeDependent;
  updateInsuranceEmployeeDependentAction: typeof updateInsuranceEmployeeDependent;
  updateInsuranceEmployeeAction: typeof updateInsuranceEmployee;
  submitInsuranceEmployeeAction: typeof submitInsuranceEmployee;
  isAdmin: boolean;
  insurance: IInsurance;
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  if (insuranceEmployee.isFetching) {
    return <div></div>;
  }

  if(insuranceEmployee.hasError){
    return <WarningAlert> {insuranceEmployee.errorData?.messageForUser} </WarningAlert>
  }

  if (!insuranceEmployee.data) {
    return <div>No insurance</div>;
  }

  function onSuccess() {
    if (isAdmin) {
      history.goBack();
    }
  }

  function getInsuranceDetailsMessage() {
    var included = '';
    if (insurance.data?.withChildrenAndSpouse && insurance.data?.withParentsOrInLaws) {
      included = 'family and dependents like children, parents or in-laws.';
    } else if (insurance.data?.withChildrenAndSpouse) {
      included = 'family like your spouse and children.';
    } else if (insurance.data?.withParentsOrInLaws) {
      included = 'dependents like parents or in-laws.';
    }
    if (included)
      return (
        'Your company has opted insurance for your ' +
        included +
        ' Please do not forget to add their details.'
      );
    return '';
  }

  const agesConstraint = validateOrderOfAges(insuranceEmployee.data);
  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="flex-auto max-w-screen-sm mx-auto lg:ml-0 lg:max-w-screen-lg">
        <div className="py-10">
          {insuranceEmployee.hasError && (
            <WarningAlert>{insuranceEmployee.errorData?.messageForUser}</WarningAlert>
          )}
          <div className="text-5xl font-heading text-white">Details for Insurance</div>
          <div className="secondary-text mt-2">
            {getInsuranceDetailsMessage()} <br />
            Final coverage inclusion will depend on the policy your organization selects.
          </div>
          <div className="mx-auto mt-5 lg:mt-10 space-y-2 lg:space-y-4">
            {insuranceEmployee.data && (
              <BeneficiaryDetails
                gender={insuranceEmployee.data?.gender}
                name={insuranceEmployee.data?.name}
                dob={insuranceEmployee.data?.dob}
                id={insuranceEmployee.data?.id}
                isAdmin={isAdmin}
                label={<span className="text-3xl font-bold">{isAdmin ? 'Employee' : 'You'}</span>}
                onDelete={() => {}}
                isEmployee={true}
                phone={insuranceEmployee.data.phoneNumber}
                showDelete={false}
                hireDate={insuranceEmployee.data?.hireDate}
                updatePhone={(phoneNumber: string) => {
                  insuranceEmployee.data &&
                    dispatch(
                      updateInsuranceEmployeeAction(insuranceEmployee.data.id, {
                        ...insuranceEmployee.data,
                        phoneNumber: phoneNumber,
                      }),
                    );
                }}
                updateName={(name: string) => {
                  insuranceEmployee.data &&
                    dispatch(
                      updateInsuranceEmployeeAction(insuranceEmployee.data.id, {
                        ...insuranceEmployee.data,
                        name: name,
                      }),
                    );
                }}
                updateDob={(dob: string) => {
                  insuranceEmployee.data &&
                    dispatch(
                      updateInsuranceEmployeeAction(insuranceEmployee.data.id, {
                        ...insuranceEmployee.data,
                        dob: dob,
                      }),
                    );
                }}
                updateHireDate={(hireDate: string) => {
                  insuranceEmployee.data &&
                    dispatch(
                      updateInsuranceEmployeeAction(insuranceEmployee.data.id, {
                        ...insuranceEmployee.data,
                        hireDate: hireDate,
                      }),
                    );
                }}
                updateGender={(gender: 'm' | 'f') => {
                  insuranceEmployee.data &&
                    dispatch(
                      updateInsuranceEmployeeAction(insuranceEmployee.data.id, {
                        ...insuranceEmployee.data,
                        gender: gender,
                      }),
                    );
                }}
                compulsoryError={validateEmployeeFields({
                  ...insuranceEmployee.data,
                  isAdmin,
                })}
                isEligible={insuranceEmployee.data?.status !== 'NOT_ELIGIBLE' || !isAdmin}
              />
            )}

            <FamilyDetails
              insuranceEmployee={insuranceEmployee}
              isAdmin={isAdmin}
              deleteInsuranceEmployeeDependentAction={deleteInsuranceEmployeeDependentAction}
              addInsuranceEmployeeDependentAction={addInsuranceEmployeeDependentAction}
              updateInsuranceEmployeeDependentAction={updateInsuranceEmployeeDependentAction}
            />
            <DependentDetails
              insuranceEmployee={insuranceEmployee}
              isAdmin={isAdmin}
              deleteInsuranceEmployeeDependentAction={deleteInsuranceEmployeeDependentAction}
              addInsuranceEmployeeDependentAction={addInsuranceEmployeeDependentAction}
              updateInsuranceEmployeeDependentAction={updateInsuranceEmployeeDependentAction}
            />
            <div className="w-full justify-center flex">
              <div className="space-x-2 mt-8">
                {agesConstraint ? (
                  <div className="text-red-700"> {agesConstraint} </div>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      id="confirmByEmployee"
                      name="confirm"
                      disabled={
                        insuranceEmployee.data.detailsConfirmedAt !== null &&
                        insuranceEmployee.isSynced
                      }
                      value={
                        insuranceEmployee.data.detailsConfirmedAt !== null ? 'checked' : 'unchecked'
                      }
                      checked={insuranceEmployee.data.detailsConfirmedAt !== null ? true : false}
                      onChange={(event) => {
                        insuranceEmployee.data &&
                          !(
                            insuranceEmployee.data.detailsConfirmedAt !== null &&
                            insuranceEmployee.isSynced
                          ) &&
                          dispatch(
                            updateInsuranceEmployee(insuranceEmployee.data.id, {
                              ...insuranceEmployee.data,
                              detailsConfirmedAt:
                                event.currentTarget.value === 'unchecked'
                                  ? new Date().toISOString()
                                  : null,
                            }),
                          );
                      }}
                    />
                    <label htmlFor="confirmByEmployee">
                      I hereby declare that all information provided by me is correct according to
                      govt. records.
                    </label>{' '}
                  </>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center">
              {
                <PrimaryButton
                  className="mx-auto"
                  disabled={
                    ((isAdmin
                      ? insurance.data?.status === 'PURCHASE_IN_PROGRESS' ||
                        insurance.data?.status === 'PURCHASED'
                      : insuranceEmployee.data.detailsConfirmedAt !== null) &&
                      insuranceEmployee.isSynced) ||
                    insuranceEmployee.isSynced ||
                    insuranceEmployee.isFetching ||
                    !insuranceEmployee.data ||
                    !areAllBasicEmployeeAndDependentFieldsCorrect(insuranceEmployee, isAdmin)
                  }
                  onClick={() => {
                    insuranceEmployee.data &&
                      dispatch(
                        submitInsuranceEmployeeAction(
                          insuranceEmployee.data.id,
                          isAdmin,
                          onSuccess,
                        ),
                      );
                  }}
                >
                  {' '}
                  {insuranceEmployee.isFetching ? 'Submitting' : 'Submit'}{' '}
                </PrimaryButton>
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-96 relative opacity-0 lg:opacity-100">
                    <div className="w-full h-screen bg-gray-100 absolute top-0 right-0 bottom-0"></div>
                </div> */}
    </div>
  );
}

export default function EmployeeDetailsContainer({
  insuranceEmployee,
  isAdmin,
  insurance,
}: {
  insuranceEmployee: IInsuranceEmployee;
  isAdmin: boolean;
  insurance: IInsurance;
}) {
  // return <div className="bg-green border border-green w-screen h-screen">hello</div>
  return (
    <ContentContainer showSidebar={false}>
      <EmployeeDetails
        insuranceEmployee={insuranceEmployee}
        deleteInsuranceEmployeeDependentAction={deleteInsuranceEmployeeDependent}
        addInsuranceEmployeeDependentAction={addInsuranceEmployeeDependent}
        updateInsuranceEmployeeAction={updateInsuranceEmployee}
        updateInsuranceEmployeeDependentAction={updateInsuranceEmployeeDependent}
        submitInsuranceEmployeeAction={submitInsuranceEmployee}
        insurance={insurance}
        isAdmin={isAdmin}
      />
    </ContentContainer>
  );
}
