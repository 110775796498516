import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  TextArea,
  TextInput,
} from '@razorpay/blade/components';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { routePaths } from 'components/Routes/data';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import { DeductionType } from 'components/SalaryComponents/types';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
} from 'components/WizardViews/components';
import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import { ValidationSchema, useFormWithProvider } from 'forms';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { deductionType, deductionsSteps } from '../constants';
import { DeductionsFormData } from '../types';
import { checkIsDeductionTypeRecurring } from '../utils';

// Validation schema
const validationSchema: ValidationSchema<DeductionsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  deductionType: (formData) => {
    if (!formData.deductionType) return 'Deduction type is required';
    return null;
  },
};

const GeneralDetailsStep: React.FC = () => {
  const history = useHistory();
  const { validate, formData, register } = useFormWithProvider<DeductionsFormData>();
  const { updateStepStatus } = useWizardInfo();
  const { searchString } = useComponentParams();
  const { isM2S2Enabled } = useIsFeatureDisabledForRearch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validate(validationSchema)) {
      updateStepStatus(deductionsSteps.step1, 'success');
      history.push({
        pathname: routePaths.salaryComponents.edit.deductions.payAndTaxDetails,
        search: searchString,
      });
    }
  };

  const isRecurring = checkIsDeductionTypeRecurring(formData.deductionType);

  const alertDescription = (
    <>
      1. If display name is updated, past salary registers and payslips will reflect the new Display
      Name.
      <br />
      2.{' '}
      {isRecurring
        ? `Recurring deduction type cannot be modified as they are part of an employee's salary structure or compensation.`
        : 'Proration and arrears changes will only apply to future payroll months.'}
    </>
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title={'General & Type'} subtitle={'Configuration'} />

        <Alert
          marginBottom="spacing.7"
          isFullWidth
          title="Modify details"
          isDismissible={false}
          description={alertDescription}
        />

        <FieldGroup title="General Details">
          <TextInput
            label={'Component Name'}
            placeholder={'Enter component name'}
            {...register('name')}
            maxCharacters={50}
            isDisabled
          />
          <TextInput
            label={'Display Name in Payslip & Salary Register'}
            placeholder={'Enter display name'}
            {...register('displayName')}
            maxCharacters={50}
          />
          <TextArea
            label={'Component Description'}
            placeholder={
              'e.g. Component provided to help employees cover their housing or rental expenses.'
            }
            {...register('description')}
            maxCharacters={255}
          />
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup
          title={'Deduction Type'}
          subtitle={'Choose how often employees receive this component as their pay.'}>
          <RadioGroup {...register('deductionType', 'radiogroup')} isDisabled>
            <Box display="flex">
              <Radio
                value={'RECURRING' as DeductionType}
                helpText={deductionType.RECURRING.description}
                isDisabled>
                {deductionType.RECURRING.label}
              </Radio>
              {!isM2S2Enabled && <Badge color="notice">Coming soon</Badge>}
            </Box>
            <Radio value={'ADHOC'} helpText={deductionType.ADHOC.description}>
              {deductionType.ADHOC.label}
            </Radio>
          </RadioGroup>
        </FieldGroup>
      </StepContentWrapper>
      <StepActionsWrapper>
        <span />
        <Button type="submit">Next: Pay & Taxability</Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default GeneralDetailsStep;
