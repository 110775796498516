import { useSearchParams } from 'hooks/useSearchParams';

const netpayDetailsViewParamKey = 'userId';

const useReleaseNetpayDetailsView = () => {
  const { addSearchParams, removeSearchParams, urlParams } = useSearchParams();

  const netpayRequestId = urlParams.get(netpayDetailsViewParamKey);

  const openDetailsView = (requestId: number) => {
    addSearchParams([{ key: netpayDetailsViewParamKey, value: requestId.toString() }]);
  };

  const closeDetailsView = () => {
    removeSearchParams([netpayDetailsViewParamKey]);
  };

  return {
    netpayRequestId,
    openDetailsView,
    closeDetailsView,
  };
};

export { useReleaseNetpayDetailsView };
