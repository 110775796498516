import { AttendanceCalendar } from './AttendanceCalendar';
import React from 'react';
import { CheckIn } from './CheckIn';
import { Box, Breadcrumb, BreadcrumbItem, Spinner } from '@razorpay/blade/components';
import { AttendanceHistory } from './AttendanceHistory';
import { OpenRequests } from './OpenRequests';
import { ApplyLeaveComponent } from './ApplyLeaveComponent';
import { useAttendanceData } from './hooks/useAttendanceData';
import { getAttendancePage, getEmployeeDetailsLink, getPeoplePage } from 'utils/links';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { EEmployeeType } from 'enums';
import { useTodaysAttendanceData } from './hooks/useTodaysAttendanceData';
import useSplitz from 'hooks/useSplitz';
import { isSplitzExperimentActive } from 'utils/splitz';
import { splitzExperiments } from 'constants/splitz';
import { AttendanceSidebar } from './AttendanceSidebar';
import { prepareUrlV2 } from 'utils/Urls';
import { useSearchParams } from 'hooks/useSearchParams';
import { parse } from 'date-fns';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

const Attendance = () => {
  const splitzQuery = useSplitz();
  const isBladeMigrationPhase1Enabled = isSplitzExperimentActive(
    splitzQuery.data,
    splitzExperiments.bladeMigrationPhase1,
  );
  const { urlParams } = useSearchParams();
  const userIdFromUrl = urlParams.get('user_id');
  const attendanceMonthFromUrl = urlParams.get('month');

  const todaysData = useTodaysAttendanceData({ enabled: isBladeMigrationPhase1Enabled });
  const attendanceData = useAttendanceData({ enabled: todaysData.isSuccess });
  const currentOrganization = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const currentEmployee = currentOrganization?.employeeDetails;

  if (splitzQuery.status === 'loading' || todaysData.status === 'loading') {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%">
        <Spinner size="medium" accessibilityLabel="Fetching attendance data" />
      </Box>
    );
  }

  if (!isBladeMigrationPhase1Enabled) {
    window.location.href = prepareUrlV2('/attendance', {
      userId: userIdFromUrl,
      date: attendanceMonthFromUrl
        ? getFormattedDateValue({
            date: parse(attendanceMonthFromUrl, 'yyyy-MM', new Date()),
            formatString: dateFormats.dateMonthShortYear,
          })
        : undefined,
    });
    return null;
  }

  if (
    !currentOrganization?.isAttendance ||
    (currentEmployee?.employeeType === EEmployeeType.CONTRACTOR &&
      !currentOrganization.leaveAttendanceConfig?.enableContractorsAttendance) ||
    todaysData.error?.getDetails().type === 403 ||
    todaysData.error?.getDetails().type === 404 ||
    attendanceData.error?.getDetails().type === 403 ||
    attendanceData.error?.getDetails().type === 404
  ) {
    return <Redirect to={routePaths.accessDenied} />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={{ base: 'column', l: 'row' }}>
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing.8"
        padding="spacing.8"
        paddingTop="spacing.8"
        flexGrow={1}>
        {!attendanceData.isForLoggedInUser && (
          <Breadcrumb>
            <BreadcrumbItem href={getPeoplePage()}>People</BreadcrumbItem>
            <BreadcrumbItem
              href={getEmployeeDetailsLink(
                attendanceData.userId ? attendanceData.userId : undefined,
              )}>
              {(todaysData.data && todaysData.data.employee_name) || '...'}
            </BreadcrumbItem>
            <BreadcrumbItem
              href={getAttendancePage(attendanceData.userId ? attendanceData.userId : undefined)}
              isCurrentPage>
              Leave and attendance
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <CheckIn />
        <AttendanceCalendar />
        <ApplyLeaveComponent />
        <OpenRequests />
        <AttendanceHistory />
      </Box>
      <AttendanceSidebar />
    </Box>
  );
};

export default Attendance;
