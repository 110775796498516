import React from 'react';
import ErrorMessage from 'components/ui/ErrorMessage';
import { StandardSecondaryButton } from 'components/ui/Button';
import { Link } from 'react-router-dom';

const FallbackUI = ({ title }: { title: string }) => {
  return (
    <div className="h-full flex justify-center items-center">
      <div className="p-16 bg-white-o-05">
        <ErrorMessage title={title}>
          <p className="mb-12">Something went wrong, please contact support.</p>
          <a href="/dashboard">
            <StandardSecondaryButton>Go to dashboard</StandardSecondaryButton>
          </a>
        </ErrorMessage>
      </div>
    </div>
  );
};

export default FallbackUI;
