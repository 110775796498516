import React, { useState } from 'react';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import { WarningAlert } from '../../../ui/Alert';
import { validate } from '../utils';
import { DeclareYourMonthlyExpense } from './DeclareYourMonthlyExpense';
import { Stats } from './Stats';
import { WalletWiseDeclaration } from './WalletWiseDeclaration';
import { ZaggleCardDeliveryAddress } from './ZaggleCardDeliveryAddress';
import { ConfirmationOption } from './ConfirmationOption';
import { ConfirmYourDeclarations } from './ConfirmYourDeclarations';
import { EmployeePersonalDetails } from './EmployeePersonalDetails';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import infoIcon from '../../../../assets/employeeOnboarding/info.svg';

function DeclarationSavedAlert() {
  return <p className='alert--success'>Your declarations have been successfully saved.</p>;
}

export default function EmployeeDeclarationForm({
                                                  employeeFlexiBenefitsDetails,
                                                  detailsToSync,
                                                  setDetailsToSync,
                                                  peopleOrgId,
                                                  partnerName,
                                                }: {
  employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract,
  detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract,
  setDetailsToSync: (detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract) => void,
  peopleOrgId: number,
  partnerName: string
}) {
  const [inConfirmationState, askForConfirmation] = useState(false);
  const [showDeclarationSavedAlert, changeDeclarationSavedAlertDisplayStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetAlerts = () => {
    changeDeclarationSavedAlertDisplayStatus(false);
    setErrorMessage('');
  };

  let totalAmount = 0;

  for (let wallet of detailsToSync.walletBudgets) {
    totalAmount += wallet.amount;
  }

  if (inConfirmationState) {
    return <ConfirmYourDeclarations
      setInConfirmation={(newState: boolean) => {
        askForConfirmation(newState);
      }}
      employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails}
      peopleOrgId={peopleOrgId}
      detailsToSync={detailsToSync}
      totalAmount={totalAmount}
      setErrorMessage={setErrorMessage}
      changeDeclarationSavedAlertDisplayStatus={changeDeclarationSavedAlertDisplayStatus} />;
  }

  return <div className={'space-y-20 -mt-20'}>
    {errorMessage.length > 0 ? <WarningAlert>{errorMessage}</WarningAlert> : <></>}
    {showDeclarationSavedAlert ? <DeclarationSavedAlert /> : <></>}
    <DeclareYourMonthlyExpense />
     <div style={{display: "flex", marginBottom: 12, padding:8, backgroundColor: '#323A5F'}}>
          <img src={infoIcon}  alt={''} style={{paddingRight:6}}/>
         Specific wallets such as Meal wallet, Books and Periodicals, and Attire flexible benefits are not available in the New Tax Regime
     </div>
    <Stats employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails} />
    <WalletWiseDeclaration employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails} detailsToSync={detailsToSync}
                           setDetailsToSync={setDetailsToSync} setErrorMessage={setErrorMessage} />
    <EmployeePersonalDetails
      setErrorMessage={setErrorMessage}
      employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails}
      detailsToSync={detailsToSync}
      setDetailsToSync={setDetailsToSync}
      partnerName={partnerName}
    />
    <ZaggleCardDeliveryAddress
      setErrorMessage={setErrorMessage}
      employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails}
      detailsToSync={detailsToSync}
      setDetailsToSync={setDetailsToSync} />
    <ConfirmationOption
      setInConfirmation={(newState: boolean) => {
        const errors = validate(detailsToSync, employeeFlexiBenefitsDetails);
        if (errors.length > 0 && newState) {
          resetAlerts();
          setErrorMessage(errors[0]);
        } else {
          resetAlerts();
          askForConfirmation(newState);
        }
      }}/>
  </div>;
}
