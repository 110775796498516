import React from 'react';
import { Alert, Box, ProgressBar, Text } from '@razorpay/blade/components';
import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';
import { actionStatusesMap } from 'components/RunPayroll/ComplianceActions/data';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

export const PayrollProgress = ({
  action,
  hideAlert,
}: {
  action: GetComplianceActionsSchemaContract['actions']['pay-pt'][string];
  hideAlert?: boolean;
}) => {
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth;
  const payrollExecutedEmployees = action.meta.payroll_executed_employees || 0;
  const payrollExecutedEmployeesPending = action.meta.payroll_executed_employees_pending || 0;
  const payrollExecutedEmployeesTotal = action.meta.payroll_executed_employees_total || 0;

  const progress =
    (Number(payrollExecutedEmployees) / Number(payrollExecutedEmployeesTotal)) * 100 || 0;

  const alertMessage =
    action.status === actionStatusesMap.failed || action.status === actionStatusesMap.notInitiated
      ? `Please process payroll for all employees due this month before making the PT payment. Please note that after the first Karnataka PT payment for ${getFormattedDateValue(
          {
            date: payrollMonth,
            formatString: dateFormats.shortMonthYear,
            filler: 'this month',
          },
        )}, XPayroll will not process any subsequent KA PT payments.`
      : undefined;

  return (
    <>
      <Box paddingX="spacing.5" paddingBottom="spacing.5">
        <ProgressBar variant="linear" value={progress} showPercentage label="Payroll Processed" />
        <Box
          marginTop="spacing.3"
          display="flex"
          gap="spacing.3"
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" flexDirection="column" gap="spacing.1">
            <Text size="small" color="surface.text.gray.muted">
              Employees Paid
            </Text>
            <Text size="large" weight="semibold">
              {payrollExecutedEmployees}
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="spacing.1"
            flexGrow={1}
            justifyContent="flex-end"
            textAlign="right">
            <Text size="small" color="interactive.text.notice.normal">
              Employees Left
            </Text>
            <Text size="large" weight="semibold" color="interactive.text.notice.normal">
              {payrollExecutedEmployeesPending}
            </Text>
          </Box>
        </Box>
      </Box>
      {!hideAlert && alertMessage ? (
        <Alert
          testID="payroll-progress-alert"
          color="notice"
          isDismissible={false}
          isFullWidth={true}
          description={alertMessage}
        />
      ) : null}
    </>
  );
};
