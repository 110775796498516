import React from 'react';

import { Card } from 'components/ui/Card';
import { DeliveryType } from '../../../../constants/flexibenefits';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from 'schemas/GetUserOrgFlexiPartnerDetailsSchema';

import styles from './index.module.scss';

export const ZaggleCardDeliveryAddressReadonly = ({
  employeeFlexiBenefitsDetails,
}: {
  employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract;
}) => {
  const isOffice = employeeFlexiBenefitsDetails.deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION;
  const {address, city, state, pinCode} = employeeFlexiBenefitsDetails.cardDeliveryDetails;

  return (
    <div className="space-y-5">
      <div className="text-5xl text-white">Zaggle card delivery detail 📦</div>
      <Card className={`shadow-none pl-8 pr-10 ${styles['zaggle-delivery-address-readonly']}`}>
        <p>
          Your Zaggle card will be delivered to your
          {isOffice ? ' office' : ''} address{!isOffice ? `(${address}, ${city}, ${state} - ${pinCode})` : ''}, usually within 10–12 days post
          your declaration submission.
        </p>
        <p className="pt-10">Please contact your administrator for further information.</p>
      </Card>
    </div>
  );
};
