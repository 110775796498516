import React from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Box, Text, Button } from '@razorpay/blade/components';
import api from 'api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { showErrorToastNotification } from 'utils/ToastEvents';
import { routePaths } from 'components/Routes/data';
import { ChatContextType, useChatContext } from 'components/PayrollAIAssistant/ChatContext';

type ErrorDisplayProps = {
  error: 'timeout' | 'unknown';
};

export const RESTART_AI_TAX_OPTIMIZER = 'RESTART_AI_TAX_OPTIMIZER';

const ErrorDisplay = ({ error }: ErrorDisplayProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { userName } = useChatContext() as ChatContextType;
 
  const history = useHistory();

  const restartForPayrollUser = useMutation({
    mutationKey: RESTART_AI_TAX_OPTIMIZER,
    mutationFn: () => api.payrollAiAssistant.initiateForPayrollUsers(),
    onSuccess: (data) => {
      window.location.href = `/tax-optimizer?id=${data.conversation_key}&conversation_count=${data.conversation_count}&user_name=${data.user_name}`;
    },
    onError: (error: typeof AppError) => {
      setIsLoading(false);
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 5000,
      });
    },
  });

  const onRetry = () => {
    if(userName) { // for now using userName to deduct public vs payroll user 
        setIsLoading(true);
        restartForPayrollUser.mutate();
    } else {
        restartForNonPayrollUser();
    }
  };

  const restartForNonPayrollUser  = () => {
    window.location.href = routePaths.payrollAiAssistant;
  }

  return (
    <Box>
      <Text>It appears there was a minor hiccup on my end. </Text>
      <Text>Could you try again, please?</Text>
      <Button marginTop={"spacing.7"} size="medium" onClick={onRetry} isLoading={isLoading}>
        Retry
      </Button>
    </Box>
  );
};

export default ErrorDisplay;
