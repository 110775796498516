import { KycInfoApiResponse } from 'api/kyc';
import KYC from 'constants/kyc';
import { errorParser } from 'utils/Api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import {
  DocumentStatus,
  OrgFields,
  addressProofGroups,
  addressProofOptions,
  bvsDocumentTypes,
  bvsStatuses,
  documentsBankAccountVerificationManualProof,
  documentsBankAccountVerificationProof,
} from './constants';
import {
  BankDocumentTypes,
  KycDocumentsContextValues,
  ManualBankDocuments,
  OrgType,
  ProofAddress,
} from './types';

export const getMessageFromStatus = ({
  status,
  error,
}: {
  status: DocumentStatus;
  error?: string;
}) => {
  if (status === DocumentStatus.ERROR) {
    return error || 'Some error occurred';
  } else if (status === DocumentStatus.SUCCESS) {
    return 'Uploaded successfully';
  } else if (status === DocumentStatus.VERIFIED) {
    return 'Verified successfully';
  } else if (status === DocumentStatus.VERIFYING) {
    return 'Verification in progress';
  } else if (status === DocumentStatus.UPLOADING) {
    return 'Uploading...';
  } else if (status === DocumentStatus.WAITING) {
    return "Verification didn't start because another document's verification failed";
  } else {
    return '';
  }
};

export const getErrorsAsString = (errorResponse: AppError) => {
  return errorParser(getAppErrorDetails(errorResponse)).join(' ');
};

/**
 * Converts a BVS verification status to a document status.
 *
 * @param bvsStatus - The BVS verification status.
 * @returns The corresponding document status.
 *
 * Document statuses:
 * - SUCCESS: Verification not initiated yet
 * - VERIFYING: Verification in progress or on hold
 * - VERIFIED: Verification completed successfully
 * - ERROR: Verification rejected or failed
 * - NOT_INITIATED: Unknown status
 */
export const convertBvsStatusToDocumentStatus = (
  bvsStatus = 0,
  isKycFlowManual = false,
  kycStatus = KYC.STATUS_NOT_INITIATED,
) => {
  if (kycStatus === KYC.STATUS_PENDING && bvsStatus !== bvsStatuses.Verified) {
    return DocumentStatus.VERIFYING;
  }
  switch (bvsStatus) {
    case bvsStatuses.NotInitiated:
      return DocumentStatus.SUCCESS;
    case bvsStatuses.Pending:
      return DocumentStatus.VERIFYING;
    case bvsStatuses.OnHold:
      return DocumentStatus.WAITING;
    case bvsStatuses.Verified:
      return DocumentStatus.VERIFIED;
    case bvsStatuses.Rejected:
      return DocumentStatus.ERROR;
    default:
      return DocumentStatus.NOT_INITIATED;
  }
};

export const getAddressProofSelectedDocumentType = (
  proofType?: string | null,
): keyof typeof addressProofGroups | null => {
  if (proofType === bvsDocumentTypes.AADHAAR_BACK || proofType === bvsDocumentTypes.AADHAAR_FRONT) {
    return 'AADHAAR_FRONT_BACK';
  } else if (
    proofType === bvsDocumentTypes.VOTERS_ID ||
    proofType === bvsDocumentTypes.VOTERS_ID_BACK
  ) {
    return 'VOTER_ID_FRONT_BACK';
  } else if (
    proofType === bvsDocumentTypes.PASSPORT ||
    proofType === bvsDocumentTypes.PASSPORT_BACK
  ) {
    return 'PASSPORT_FRONT_BACK';
  } else if (proofType === bvsDocumentTypes.AADHAAR) {
    return 'E_AADHAAR';
  }
  return null;
};

export const parseKycInfoResponse = ({
  response,
  orgType,
}: {
  response: KycInfoApiResponse;
  orgType: OrgType;
}) => {
  const { kycDocumentInfo, kycInfo } = response;
  const authorizedSignatoryDetails: Record<string, string | object> = {
    addressProof: {},
  };
  const companyDetails: Record<string, string | object> = {};
  const bankVerificationDetails: Record<string, string | object> = { proofType: {} };

  const isKycFlowManual = response.kycInfo.autoKyc?.verificationType === 'manual';
  let bankVerificationMode = 'auto';

  for (let doc of kycDocumentInfo) {
    const { documentType, verificationStatus, comments, documentKey, fileS3Url, isOcrPossible } =
      doc;

    const isAddressProof = [
      bvsDocumentTypes.AADHAAR_BACK,
      bvsDocumentTypes.VOTERS_ID_BACK,
      bvsDocumentTypes.PASSPORT_BACK,
      ...addressProofOptions.map((e) => e.value),
    ].includes(documentType as ProofAddress);

    const isBankAccountVerificationProof = documentsBankAccountVerificationProof.includes(
      documentType as BankDocumentTypes,
    );
    const kycStatus = kycInfo.status;
    const isPersonalPan = documentType === bvsDocumentTypes.PERSONAL_PAN;
    const status = convertBvsStatusToDocumentStatus(verificationStatus, isKycFlowManual, kycStatus);
    const isIdentityProofSelectionRequired = OrgFields[orgType].isIdentityProofSelectionRequired;
    const isIdentityProof = isIdentityProofSelectionRequired
      ? OrgFields[orgType].identityProofOptions.map((e) => e.value as string).includes(documentType)
      : false;

    if (isAddressProof) {
      const addressProofDocumentType = getAddressProofSelectedDocumentType(documentType);

      authorizedSignatoryDetails['addressProof'] = {
        name: addressProofDocumentType
          ? addressProofGroups[addressProofDocumentType].id
          : documentType,
        // message: comments,
      };
      authorizedSignatoryDetails[documentType] = {
        saved_filename: documentKey,
        url: fileS3Url,
        message: getMessageFromStatus({ status, error: comments || '' }),
        status,
        isOcrPossible,
      };
    } else if (isPersonalPan) {
      authorizedSignatoryDetails[bvsDocumentTypes.PERSONAL_PAN] = {
        saved_filename: documentKey,
        url: fileS3Url,
        message: getMessageFromStatus({ status, error: comments || '' }),
        status,
        isOcrPossible,
      };
    } else if (isBankAccountVerificationProof) {
      const isProofManual = documentsBankAccountVerificationManualProof.includes(
        documentType as ManualBankDocuments,
      );
      if (isProofManual) {
        bankVerificationMode = 'manual';
        bankVerificationDetails['proofType'] = {
          name: documentType,
          message: comments,
        };
        bankVerificationDetails['mode'] = 'manual';
        bankVerificationDetails[documentType] = {
          saved_filename: documentKey,
          url: fileS3Url,
          message: getMessageFromStatus({ status, error: comments || '' }),
          status,
          isOcrPossible,
        };
      } else if (documentType === bvsDocumentTypes.BANK_ACCOUNT) {
        bankVerificationDetails['mode'] = 'auto';
        bankVerificationDetails[documentType] = {
          saved_filename: documentKey,
          message: getMessageFromStatus({ status, error: comments || '' }),
          status,
          isOcrPossible,
        };
      }
    } else if (isIdentityProof) {
      companyDetails['identityProof'] = {
        name: documentType,
        message: comments,
      };
      companyDetails[documentType] = {
        saved_filename: documentKey,
        url: fileS3Url,
        message: getMessageFromStatus({ status, error: comments || '' }),
        status,
        isOcrPossible,
      };
    } else {
      companyDetails[documentType] = {
        saved_filename: documentKey,
        url: fileS3Url,
        message: getMessageFromStatus({ status, error: comments || '' }),
        status,
        isOcrPossible,
      };
    }
  }

  bankVerificationDetails['mode'] = bankVerificationMode;

  return {
    authorizedSignatoryDetails,
    companyDetails,
    bankVerificationDetails,
  } as KycDocumentsContextValues;
};

export const isBankAccountVerified = ({ response }: { response: KycInfoApiResponse }) => {
  const { kycDocumentInfo } = response;
  for (let doc of kycDocumentInfo) {
    const { documentType, verificationStatus } = doc;
    if (documentsBankAccountVerificationProof.includes(documentType as BankDocumentTypes)) {
      if (verificationStatus === bvsStatuses.Verified) return true;
    }
  }
  return false;
};
