import React from 'react';
import BulletList, { BulletListItem } from '../../../ui/BulletList';

export function DeclareYourMonthlyExpense() {
  return <div className={'space-y-3'}>
    <div className={'text-5xl text-white'}>Declare your monthly expenses</div>
    <BulletList>
      <BulletListItem>Set your flexible benefit plan for the available wallets. The declared amount will be transferred
        to your Zaggle card on your payroll date. The amount transferred to your card is tax exempted! 😀
      </BulletListItem>
      <BulletListItem>The same declaration amounts will be used for the all the upcoming months. You can modify your
        selection anytime as per your need.
      </BulletListItem>
    </BulletList>
  </div>;
}