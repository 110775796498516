import { Box, Button, Spinner } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import KYC from 'constants/kyc';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useKycValidations } from './hooks/useKycValidations';
import { useIsSelfServeP3M1Enabled } from './hooks/useIsSelfServeP3M1Enabled';

interface DocumentUploadCtasProps {
  kycStatus?: number;
  isVerifying: boolean;
  isMaxAttemptExceeded: boolean;
  isKycFlowManual: boolean;
}

const ManualBankVerificationCtas = ({
  kycStatus = KYC.STATUS_NOT_INITIATED,
  isVerifying,
  isMaxAttemptExceeded,
  isKycFlowManual,
}: DocumentUploadCtasProps) => {
  const history = useHistory();
  const { isSelfServeP3M1Enabled, isLoading: isFetchingSelfServeP3M1Experiment } = useIsSelfServeP3M1Enabled();
  const {
    isVerifyAvailable,
    isErrorInBankDetails,
    isErrorInKycDocuments,
    isKycDocumentNotAdded,
    isBankDocumentNotAdded,
    isManualBankProofAdded,
  } = useKycValidations({
    kycStatus,
    isSelfServeP3M1Enabled
  });

  if (isFetchingSelfServeP3M1Experiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  const handleNextCtaClick = () => {
    const nextRoute = isSelfServeP3M1Enabled
      ? routePaths.kyc.documentsUpload
      : routePaths.kyc.userSelection;

    history.push(nextRoute);
  };

  if (kycStatus === KYC.STATUS_NOT_INITIATED) {
    return (
      <Box display={'flex'} gap={'spacing.5'}>
        {isMaxAttemptExceeded || isKycFlowManual ? null : (
          <Button variant="secondary" onClick={() => history.push(routePaths.kyc.bankDetails)}>
            Back: Verify via Account Number
          </Button>
        )}
        {isKycDocumentNotAdded && !isVerifying && !isBankDocumentNotAdded ? (
          <Button isDisabled={!isManualBankProofAdded} onClick={handleNextCtaClick}>
            Next
          </Button>
        ) : isVerifyAvailable || isBankDocumentNotAdded ? (
          <Button
            type="submit"
            isDisabled={isErrorInBankDetails || !isVerifyAvailable || isBankDocumentNotAdded}
            isLoading={isVerifying}>
            Verify
          </Button>
        ) : null}
      </Box>
    );
  } else if (kycStatus === KYC.STATUS_PENDING) {
    return null;
  } else if (kycStatus === KYC.STATUS_ON_HOLD) {
    if (isErrorInBankDetails) {
      return (
        <Button
          type="submit"
          isDisabled={isErrorInBankDetails || !isVerifyAvailable}
          isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else if (isErrorInKycDocuments) {
      return (
        <Button
          type="submit"
          isLoading={isVerifying}
          onClick={() => history.push(routePaths.kyc.documentsUpload)}>
          Next
        </Button>
      );
    } else if (isVerifyAvailable) {
      return (
        <Button
          type="submit"
          isDisabled={isErrorInKycDocuments || !isVerifyAvailable || isErrorInBankDetails}
          isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else {
      return null;
    }
  }

  return (
    <Button type="submit" isDisabled={!isVerifyAvailable} isLoading={isVerifying}>
      Verify
    </Button>
  );
};

export default ManualBankVerificationCtas;
