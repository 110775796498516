import React, { ReactElement } from 'react';
import MainCase from '../ui/MainCase/index';
import styles from './index.module.scss';
import { SecondaryButton, PrimaryButtonV2 } from '../ui/Button/index';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import Breadcrumb from 'components/ui/Breadcrumb';
import LoansTable from './components/LoansTable';
import PageWrapper from 'components/ui/PageWrapper';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import permissions from 'constants/permissions';

export default function ViewLoans(): ReactElement {

    const history = useHistory();
    let permission = useSelector(loggedInUserSelectors.permissions);
    let peopleId = useSelector(loggedInUserSelectors.peopleId);
    const hasManagePermission = permission.includes(permissions.PAYROLL_PAY_ADVANCE_SALARY);

    return (
        <PageWrapper>
            <Breadcrumb
                name="Loans"
                urlMaps={{}}
            />
            <MainCase type={"fullWidth"}>
                <div className={styles['create-loan-title-bar']}>
                    <div>
                        <div className={styles['title']}>
                            Loans
                        </div>
                        <div className={styles['title-description']}>
                            Create, disburse and manage employee loans.
                        </div>
                    </div>
                    { hasManagePermission &&
                    <div className={styles['view-loans-buttons']}>
                        <SecondaryButton onClick={() => { window.location.href = '/loanPayment'; }}>Pending Loans</SecondaryButton>
                        <PrimaryButtonV2 onClick={() => { history.push(routePaths.loans.createLoan); }}>+ Add New Loan</PrimaryButtonV2>
                    </div>
                    }

                </div>

                <LoansTable hasManagePermission = {hasManagePermission} userId={peopleId}/>

            </MainCase>
        </PageWrapper>
    );

}