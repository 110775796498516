import { Amount, Box, Checkbox, TextInput } from '@razorpay/blade/components';
import React from 'react';
import { NUMERIC_REGEX_WITH_SIGN } from 'utils/regexes';
import { InputContainer } from './styles';

interface DeclarationItemProps {
  name: string;
  id: string;
  maxLimit: number;
  declaredAmount: string;
  proratedAmount?: number;
  isDisabled?: boolean;
  isSelected: boolean;
  onValueChange?: (value: string, id: string) => void;
  employeeCanUpdateAmountInDeclaredFlexiBenefits?: boolean;
  onCheckedChange: (isChecked: boolean, id: string) => void;
}

const DeclarationItem = ({
  id,
  name,
  maxLimit,
  declaredAmount,
  proratedAmount = 0,
  isDisabled = false,
  onValueChange,
  employeeCanUpdateAmountInDeclaredFlexiBenefits = true,
  isSelected,
  onCheckedChange,
}: DeclarationItemProps) => {
  const isValid = declaredAmount
    ? declaredAmount.match(NUMERIC_REGEX_WITH_SIGN) && parseFloat(declaredAmount) <= maxLimit
    : true;

  const handleChange = (val?: string) => {
    if (parseFloat(val || '0') === 0) {
      onCheckedChange(false, id);
    } else {
      onCheckedChange(true, id);
    }
    onValueChange?.(val || '', id);
  };

  const handleCheckboxChange = (isChecked: boolean) => {
    onCheckedChange(isChecked, id);
    if (!isChecked) {
      onValueChange?.('', id);
    }
  };

  return (
    (<tr>
      <td>
        <Checkbox
          isChecked={isSelected}
          isDisabled={isDisabled}
          onChange={(e) => handleCheckboxChange(e.isChecked)}>
          {name}
        </Checkbox>
      </td>
      <td>
        <Amount
          suffix="none"
          value={maxLimit}
          type="body"
          size="medium"
          weight="semibold" />
      </td>
      {employeeCanUpdateAmountInDeclaredFlexiBenefits === true ? (
        <td>
          <InputContainer>
            <Box width={'154px'} display="flex" justifyContent="flex-end">
              <TextInput
                type="number"
                isDisabled={isDisabled}
                accessibilityLabel="Enter value"
                labelPosition="top"
                value={declaredAmount}
                onChange={(e) => handleChange(e.value)}
                placeholder="0"
                prefix=""
                suffix=""
                validationState={isValid ? 'none' : 'error'}
              />
            </Box>
          </InputContainer>
        </td>
      ) : null}
    </tr>)
  );
};

export default DeclarationItem;
