import { BoxProps } from '@razorpay/blade/components';
import api from 'api';
import { UploadKycDocumentApiPayload } from 'api/kyc';
import React from 'react';
import { useMutation } from 'react-query';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { AppError } from 'utils/AppError';
import { UPLOAD_KYC_DOCUMENT } from '../queries';
import { FileUploadWrapper } from '../styles';
import FileUpload, { FileUploadProps } from './FileUpload/FileUpload';

type DocumentUploadProps = Omit<FileUploadProps, 'onChange'> &
  BoxProps & {
    wrapperlabel?: string;
    onChange: (
      files: File[] | null,
      upload: (payload: UploadKycDocumentApiPayload) => void,
    ) => void;
    onSuccess?: (data: UploadKycDocumentSchemaContract) => void;
    onError?: (error: AppError) => void;
  };

const DocumentUpload = ({
  wrapperlabel,
  label,
  value,
  labelJustifyContent,
  allowedMimeTypes,
  onChange,
  status,
  onSuccess,
  onError,
  subText,
  onFilenameClick,
  ...boxProps
}: DocumentUploadProps) => {
  const uploadDocument = useMutation({
    mutationKey: [UPLOAD_KYC_DOCUMENT],
    mutationFn: api.kyc.uploadKycDocument,
    onSuccess,
    onError,
  });

  const uploadFunction = (data: UploadKycDocumentApiPayload) => uploadDocument.mutate(data);

  return (
    <FileUploadWrapper label={wrapperlabel} {...boxProps}>
      <FileUpload
        label={label}
        value={value}
        labelJustifyContent={labelJustifyContent}
        allowedMimeTypes={allowedMimeTypes}
        onChange={(files) => onChange(files, uploadFunction)}
        status={status}
        subText={subText}
        onFilenameClick={onFilenameClick}
      />
    </FileUploadWrapper>
  );
};

export default DocumentUpload;
