import React, { ReactElement, useState } from 'react';
import { BulkUploadStatus, routeToStepMap, BulkUploadStep, BulkUploadTypes, routeToTypeMap } from 'constants/bulkUpload';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import { useLocation, useHistory } from 'react-router-dom';
import styles from '../index.module.scss';
import { PrimaryButton } from 'components/ui/Button';
import { stepToRouteMap } from '../../../constants/bulkUpload';
import { startOfMonth } from 'date-fns';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from '../queries/queries';
import { AppError } from 'utils/AppError';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import SalaryRevisionDownloadSection from './SalaryRevisionDownloadSection';
import FileUploadSection from '../components/FileUploadSection';
import ErrorCard from '../components/ErrorCard';
import { trackXPayrollEvent } from 'utils/analytics';

export default function SalaryRevisionDownloadAndUploadPage(): ReactElement {
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [bulkUploadRecord, setBulkUploadRecord] = useState<
    BulkUploadDetailsSchemaContract[0] | null
  >(null);
  const location = useLocation();

  const currentStep = routeToStepMap[BulkUploadTypes.TYPE_BULK_SALARY_REVISION][location.pathname] || BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP;
  const history = useHistory();

  function nextStep() {
    if (currentStep + 1 <= 2) {
      history.push(stepToRouteMap[BulkUploadTypes.TYPE_BULK_SALARY_REVISION][currentStep + 1]);
    }
  }

  const { isLoading, isRefetching, error, data } = useQuery(GET_BULK_UPLOAD_RECORD, () => {

    return api.bulkUploads.fetchBulkUploadRecordByType(BulkUploadTypes.TYPE_BULK_SALARY_REVISION);

  }, {
    onSuccess: (data) => data && setBulkUploadRecord((data.length > 0) ? data[0] : null),
    onError: (error: typeof AppError) => {
      
    }
  });

  if (isLoading) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>);
  }

  if (error) {
    return <ErrorCard error={error} buttonText= {'Back to Dashboard'} onClick={() => {
        window.location.href = '/dashboard';
      }} />
  }

  return (
    <>
      <div className={styles['centered-body-50']}>
        <SalaryRevisionDownloadSection effectiveDate={effectiveDate} setEffectiveDate={setEffectiveDate} />
        <FileUploadSection
          title='3. Upload the updated sheet below'
          bulkUploadRecord={bulkUploadRecord}
          setBulkUploadRecord={setBulkUploadRecord}
          onChangeFile={()=>{
            trackXPayrollEvent('salary_revision.file_upload', 'interaction', 'bulk-action');
          }}
          onIncorrectFileTypeSelection={()=>{
            trackXPayrollEvent(
              'salary_revision.file_upload.incorrect_filetype',
              'interaction',
              'bulk-action',
            );
          }}
          onExceededSizeSelection={()=>{
            trackXPayrollEvent('salary_revision.file_upload.exceeded_size', 'interaction', 'bulk-action');
          }}
          onDownloadErrorReport={()=> trackXPayrollEvent(
            'salary_revision.error_file_download',
            'interaction',
            'bulk-action',
          )}
          pollingRequestId='bulkUploadPollId'
          bulkUpoadType={routeToTypeMap[location.pathname]}
        />
        <div className={styles['section']}>
          <PrimaryButton
            className={styles['button']}
            disabled={
              bulkUploadRecord
                ? bulkUploadRecord.status !== BulkUploadStatus.STATUS_VALIDATION_SUCCESS
                : true
            }
            onClick={(event)=>{
              nextStep();
            }}>
            {'Continue'}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}
