import { Box, CloseIcon, IconButton, Text, useTheme } from '@razorpay/blade/components';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

interface NamePillProps {
  name: string;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

const getInitialsFromName = (name: string) => {
  const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  const allInitials = [...name.matchAll(regex)] || [];

  const initials = (
    (allInitials.shift()?.[1] || '') + (allInitials.pop()?.[1] || '')
  ).toUpperCase();

  return initials.slice(0, 2);
};

const TagContainer = styled.div<{ backgroundColor: string }>(
  ({ theme, backgroundColor }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing[2]}px;
    border-radius: ${theme.border.radius.round};
    height: ${theme.spacing[7]}px;
    width: ${theme.spacing[7]}px;
    background-color: ${backgroundColor};
`,
);

const NamePill = ({ name, showCloseIcon, onClose }: NamePillProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const {
    theme: { colors },
  } = useTheme();

  const tag = getInitialsFromName(name).toUpperCase();

  const tagColor = useMemo(() => {
    const tagColors = [
      colors.surface.background.primary.intense,
      colors.feedback.background.positive.intense,
      colors.surface.text.onSea.onSubtle,
    ];

    const charCodesSum = tag.split('').reduce((prev, current) => prev + current.charCodeAt(0), 0);

    const colorIndex = charCodesSum % tagColors.length;

    return tagColors[colorIndex];
  }, [tag]);

  const handleOnCloseClick = () => {
    setIsVisible(false);

    onClose && onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    (<Box
      display="flex"
      alignItems="center"
      gap="spacing.2"
      borderRadius="max"
      padding={['6px', 'spacing.4', '6px', 'spacing.3']}
      backgroundColor={showCloseIcon ? 'surface.background.primary.subtle' : 'surface.background.gray.moderate'}>
      {tag && (
        <TagContainer backgroundColor={tagColor}>
          <Text weight="semibold" size="small">
            {tag}
          </Text>
        </TagContainer>
      )}
      <Text>{name}</Text>
      {showCloseIcon && (
        <IconButton
          icon={CloseIcon}
          emphasis="subtle"
          accessibilityLabel="close pill"
          onClick={handleOnCloseClick}
        />
      )}
    </Box>)
  );
};

export { NamePill };
