import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import React, { ReactElement } from 'react';
import KlaarIntro from './components/KlaarIntro';

function KlaarSettings(): ReactElement {
  return <KlaarIntro />;
}

function KlaarSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase center>
        <KlaarSettings />
      </MainCase>
    </PageWrapper>
  );
}

export default KlaarSettingsWithPageWrapper;
