import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab } from '../../../ui/Tab';
import { routePaths } from 'components/Routes/data';
import CalendlyCard from '../components/CalendlyCard';

export function InsuranceSelectionTab({
  routesNotAllowed,
  className,
}: {
  routesNotAllowed?: string[];
  className?: string;
}) {
  const location = useLocation();
  let notAllowedRoutesMap = (routesNotAllowed || []).reduce((hash, route) => {
    hash[route] = route;
    return hash;
  }, {} as Record<string, string>);
  let map = {
    [routePaths.insurance.admin.plan]: notAllowedRoutesMap[routePaths.insurance.admin.plan] ? (
      <span> Plan </span>
    ) : (
      <Link to={routePaths.insurance.admin.plan}> Plan</Link>
    ),
    [routePaths.insurance.admin.estimate]: notAllowedRoutesMap[
      routePaths.insurance.admin.estimate
    ] ? (
      <span>Estimate</span>
    ) : (
      <Link to={routePaths.insurance.admin.estimate}> Estimate</Link>
    ),
    [routePaths.insurance.admin.finalize]: notAllowedRoutesMap[
      routePaths.insurance.admin.finalize
    ] ? (
      <span>Finalize</span>
    ) : (
      <Link to={routePaths.insurance.admin.finalize}> Finalize</Link>
    ),
  };

  const activeElement = (location.pathname in map ? location.pathname : null) as
    | keyof typeof map
    | null;
  const routesNotAllowedFiltered = (routesNotAllowed ? routesNotAllowed : []).filter(
    (key) => key in map,
  ) as Array<keyof typeof map>;
  return (
    <>
      <div className="flex flex-col items-center xl:w-4/5 w-full self-center">
        <div className="flex xl:flex-row xl:justify-between flex-col-reverse justify-start w-3/5 items-center  sm:self-center md:self-center lg:self-center xl:self-end ml-12 mr-20">
          <Tab
            selections={map}
            activeElement={activeElement}
            keysToDisable={routesNotAllowedFiltered}
            className={className}
          />
          <CalendlyCard />
        </div>
      </div>
    </>
  );
}
