let debouncertime: number | undefined;

export const debounce = (callback: any, time: number) => {
  window.clearTimeout(debouncertime);
  debouncertime = Number(setTimeout(callback, time) ?? 0);
};

type Procedure = (...args: any[]) => void;

interface DebouncedFunction<T extends Procedure> {
  (...args: Parameters<T>): void;
  cancel(): void;
}

export function debounceV2<T extends Procedure>(func: T, delay: number): DebouncedFunction<T> {
  let timerId: ReturnType<typeof setTimeout>;

  const debounced: DebouncedFunction<T> = (...args: Parameters<T>) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debounced.cancel = () => {
    clearTimeout(timerId);
  };

  return debounced;
}
