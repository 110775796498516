import React, { useState } from 'react';
import {
  Badge,
  Box,
  Button,
  ClockIcon,
  Heading,
  Text,
  Tooltip,
  useToast,
} from '@razorpay/blade/components';
import { getFormattedDateValue, dateFormats } from 'utils/Dates';
import { useMutation, useQueryClient } from 'react-query';
import api from 'api';
import { getLocation, LocationError } from './utils/getLocation';
import { AppError } from 'utils/AppError';
import { parseError } from 'utils/Api';
import { useTodaysAttendanceData } from './hooks/useTodaysAttendanceData';

export const CheckIn = () => {
  const queryClient = useQueryClient();
  const [isAcquiringLocation, setIsAcquiringLocation] = useState<{
    for: 'checkin' | 'checkout' | null;
  }>({ for: null });
  const toast = useToast();

  const currentMonth = getFormattedDateValue({
    date: new Date(),
    formatString: dateFormats.yearMonth,
  });
  const { data: todayData, isLoading, userId, isForLoggedInUser } = useTodaysAttendanceData();

  const checkInMutation = useMutation(api.attendance.checkIn, {
    onSuccess: (data) => {
      toast.show({
        content: `You have successfully checked in at ${data['checkin-time']}. Don't forget to check out at the end of the day!`,
        color: 'positive',
      });
      queryClient.invalidateQueries(['attendance', userId, currentMonth]);
      queryClient.invalidateQueries(['attendance-today']);
    },
    onError: (e: typeof AppError) => {
      toast.show({
        content: parseError(e),
        color: 'negative',
      });
    },
  });

  const checkOutMutation = useMutation(api.attendance.checkOut, {
    onSuccess: (data) => {
      toast.show({
        content: `You have successfully checked out at ${data['checkout-time']}.`,
        color: 'positive',
      });
      queryClient.invalidateQueries(['attendance', userId, currentMonth]);
      queryClient.invalidateQueries(['attendance-today']);
    },
    onError: (e: typeof AppError) => {
      toast.show({
        content: parseError(e),
        color: 'negative',
      });
    },
  });

  const handleCheckIn = () => {
    setIsAcquiringLocation({ for: 'checkin' });
    getLocation()
      .then((location) => {
        checkInMutation.mutate({ location });
      })
      .catch((error: LocationError) => {
        toast.show({
          content: `Could not update your location.${
            error === LocationError.PERMISSION_DENIED ? ' Please enable location access.' : ''
          }`,
          color: 'negative',
        });
        checkInMutation.mutate({ location: error });
      })
      .finally(() => {
        setIsAcquiringLocation({ for: null });
      });
  };

  const handleCheckOut = () => {
    setIsAcquiringLocation({ for: 'checkout' });
    getLocation()
      .then((location) => {
        checkOutMutation.mutate({ location });
      })
      .catch((error: LocationError) => {
        toast.show({
          content: `Could not update your location.${
            error === LocationError.PERMISSION_DENIED ? ' Please enable location access.' : ''
          }`,
          color: 'negative',
        });
        checkOutMutation.mutate({ location: error });
      })
      .finally(() => {
        setIsAcquiringLocation({ for: null });
      });
  };

  if (userId !== null && !isForLoggedInUser) {
    return null;
  }

  if (!todayData?.show_web_checkin) {
    return null;
  }

  const getTodaysCheckInMessage = () => {
    if (todayData.checkin_time && !todayData.checkout_time) {
      return "You have checked in today. Please don't forget to checkout at the end of the day.";
    } else if (todayData.checkout_time) {
      return 'Your attendance has been marked for today.';
    } else {
      return 'You can mark your attendance for today.';
    }
  };

  return (
    <Box>
      <Box
        padding="spacing.4"
        display="flex"
        flexDirection={{ m: 'row', base: 'column' }}
        gap="spacing.4"
        justifyContent="space-between"
        backgroundColor="surface.background.gray.moderate">
        <Box>
          <Heading as="h2">
            Mark attendance for today (
            {getFormattedDateValue({
              date: new Date(),
              formatString: dateFormats.humanDateMonthYear,
            })}
            )
          </Heading>
          <Text>{getTodaysCheckInMessage()}</Text>
        </Box>
        <Box display="flex" gap="spacing.4">
          {todayData.is_attendance_marked_today ? (
            <>
              <Tooltip content="Your attendance is already marked today">
                <Button isDisabled>Check In</Button>
              </Tooltip>
              <Tooltip content="Your attendance is already marked today">
                <Button isDisabled>Check Out</Button>
              </Tooltip>
            </>
          ) : (
            <>
              {!todayData.is_checkin_done && (
                <Button
                  icon={ClockIcon}
                  onClick={handleCheckIn}
                  isLoading={isAcquiringLocation.for === 'checkin' || checkInMutation.isLoading}
                  isDisabled={
                    isAcquiringLocation.for === 'checkout' ||
                    isLoading ||
                    checkOutMutation.isLoading
                  }>
                  Check In
                </Button>
              )}
              {todayData.is_checkin_done && (
                <Button
                  icon={ClockIcon}
                  onClick={handleCheckOut}
                  isLoading={isAcquiringLocation.for === 'checkout' || checkOutMutation.isLoading}
                  isDisabled={
                    isAcquiringLocation.for === 'checkin' || isLoading || checkInMutation.isLoading
                  }>
                  Check Out
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box paddingTop="spacing.4" display="flex" gap="spacing.4">
        {todayData.checkin_time && (
          <Badge icon={ClockIcon}>Checked in at: {todayData.checkin_time.substring(0, 5)}</Badge>
        )}
        {todayData.checkout_time && (
          <Badge icon={ClockIcon}>Checked out at: {todayData.checkout_time.substring(0, 5)}</Badge>
        )}
      </Box>
    </Box>
  );
};
