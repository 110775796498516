import { Box, Button, Heading, Text } from '@razorpay/blade/components';
import { SideSheet } from 'components/ApprovalWorkflows/MyRequests/components/SideSheet';
import React from 'react';

type ConfirmationSheetProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  isLoading: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

const ConfirmationSheet = ({
  isOpen,
  onConfirm,
  onDismiss,
  title,
  description,
  isLoading,
  cancelButtonText = 'No',
  confirmButtonText = 'Yes',
}: ConfirmationSheetProps) => {
  return (
    (<SideSheet isOpen={isOpen} onDismiss={onDismiss} position="bottom" hideCloseIcon>
      <Box
        paddingX="94px"
        paddingY="spacing.7"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box>
          <Heading size="small">{title}</Heading>
          <Text
            weight="regular"
            marginTop="spacing.3"
            size="large"
            color="surface.text.gray.muted">
            {description}
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="spacing.5"
          justifyContent="flex-end">
          <Button variant="secondary" isDisabled={isLoading} onClick={onDismiss}>
            {cancelButtonText}
          </Button>
          <Button onClick={onConfirm} isLoading={isLoading}>
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </SideSheet>)
  );
};

export default ConfirmationSheet;
