import React from 'react';
import styles from './index.module.css';
export function Tab<T extends Record<string, React.ReactNode>, K extends keyof T>({
  selections,
  activeElement,
  setActiveElement,
  keysToDisable,
  className,
}: {
  selections: T;
  activeElement: K | null;
  setActiveElement?: Function;
  keysToDisable?: K[];
  className?: string;
}) {
  let items = [];
  const hashOfKeysToDisable =
    keysToDisable?.reduce((hash, key) => {
      hash[key] = key;
      return hash;
    }, {} as Record<K, K>) || ({} as Record<K, K>);

  for (let [index, [key, element]] of Object.entries(Object.entries(selections))) {
    items.push(
      <li
        key={key}
        onClick={() => {
          setActiveElement && !hashOfKeysToDisable[key as K] && setActiveElement(key);
        }}
        className={`uppercase ${
          hashOfKeysToDisable[key as K] ? styles['tab-item--disabled'] : 'cursor-pointer'
        } ${styles['tab-item']} ${key === activeElement ? styles['tab-item--active'] : ''}`}
      >
        {Number(index) + 1}. {element}
      </li>,
    );
  }
  return (
    <div className={className ? className : ''}>
      <ul className={styles['tab']}>{items}</ul>
    </div>
  );
}
