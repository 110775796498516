import api from 'api';
import KYC from 'constants/kyc';
import { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';
import { KycDocumentsContext } from '../KycDocumentsContext';
import { GET_KYC_INFO, INITIATE_KYC } from '../queries';
import { OrgType } from '../types';
import { parseKycInfoResponse } from '../utils';

const useKycInfo = () => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const orgType = org?.type as OrgType;
  const { setKycValues } = useContext(KycDocumentsContext);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isValidationCreated, setIsValidationCreated] = useState(false);

  const kycInfo = useQuery({
    queryKey: GET_KYC_INFO,
    queryFn: () => api.kyc.getKycInfo(),
    refetchInterval: (data) => {
      if (
        data?.kycInfo.status === KYC.STATUS_VERIFIED ||
        data?.kycInfo.status === KYC.STATUS_ON_HOLD ||
        data?.kycInfo.autoKyc?.verificationType === 'manual'
      ) {
        setIsVerifying(false);
        return false;
      } else if (isValidationCreated) {
        return 5000;
      }
      setIsVerifying(false);
      return false;
    },
    onSuccess: (response) => {
      setKycValues(parseKycInfoResponse({ response, orgType }));
    },
  });

  const initateKycVerification = useMutation({
    mutationKey: INITIATE_KYC,
    mutationFn: () => api.kyc.initiateKycVerification(),
    onSuccess: (response) => {
      setIsValidationCreated(response.success === true);
      kycInfo.refetch();
    },
    onMutate: () => {
      setIsVerifying(true);
    },
    onError: (error: typeof AppError) => {
      setIsVerifying(false);
    },
  });
  return { kycInfo, initateKycVerification, setIsVerifying, isVerifying };
};

export default useKycInfo;
