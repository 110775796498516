import { Alert, ArrowLeftIcon, Button, useToast } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import {
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
} from 'components/WizardViews/components';
import {
  ReviewSection,
  ReviewSectionContainer,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
} from 'components/WizardViews/components/review';
import { useFormPersistence, useFormWithProvider } from 'forms';
import React, { FormEvent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppError } from 'utils/AppError';
import { deductFrom, deductionsValidationSchema } from '../constants';
import { DeductionsFormData } from '../types';
import {
  checkIsDeductionTypeAdhoc,
  checkIsDeductionTypeRecurring,
  convertFormDataToDeductionSalaryComponentPayload,
  deductionsExemptionDisplayText,
  showDeductionsTaxabilitySection,
} from '../utils';

const ReviewStep: React.FC = () => {
  const { formData, setIsSubmitting, validate } = useFormWithProvider<DeductionsFormData>();
  const history = useHistory();
  const { searchString } = useComponentParams();
  const toast = useToast();

  const createDeduction = useMutation({
    mutationFn: api.salaryComponents.mutations.createSalaryComponent,
    onMutate() {
      setIsSubmitting(true);
    },
    onSettled() {
      setIsSubmitting(false);
    },
    onSuccess(data) {
      history.push(routePaths.salaryComponents.deductionsListView);
    },
    onError: (e: AppError) => {
      toast.show({
        content: e.message,
        color: 'negative',
        duration: 3000,
      });
    },
  });

  const isFormValid = useMemo(
    () => validate(deductionsValidationSchema, { sideEffects: false }),
    [validate],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    createDeduction.mutate(convertFormDataToDeductionSalaryComponentPayload(formData));
  };

  const editGeneralDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.create.deductions.generalDetails,
      search: searchString,
    });

  const editPayAndTaxationDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.create.deductions.payAndTaxDetails,
      search: searchString,
    });

  const isRecurring = checkIsDeductionTypeRecurring(formData?.deductionType);
  const isAdhoc = checkIsDeductionTypeAdhoc(formData?.deductionType);

  const showTaxabilitySection = showDeductionsTaxabilitySection(formData);

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Review" subtitle="Component" />

        {createDeduction.error && (
          <Alert
            marginBottom="spacing.7"
            title="The component cannot be created because of the below errors:"
            description={createDeduction.error.message}
            color="negative"
            isFullWidth
            isDismissible={false}
          />
        )}

        <ReviewSectionContainer>
          <ReviewSection>
            <ReviewSectionHeader
              title={'General & Type'}
              subtitle={'details'}
              onEditClick={editGeneralDetailsClick}
            />
            <ReviewSectionItemContainer>
              <ReviewSectionItem label={'Component Name'} value={formData.name} />
              <ReviewSectionItem
                label="Payslip & Salary Register name"
                value={formData.displayName}
              />
              <ReviewSectionItem label="Component Description" value={formData.description} />
              <ReviewSectionItem
                label="Deduction Type"
                value={isRecurring ? 'Recurring(Monthly)' : 'Adhoc'}
              />
            </ReviewSectionItemContainer>
          </ReviewSection>
          <ReviewSection>
            <ReviewSectionHeader
              title={'Pay & Taxability'}
              subtitle={'details'}
              onEditClick={editPayAndTaxationDetailsClick}
            />
            <ReviewSectionItemContainer>
              {isAdhoc && (
                <ReviewSectionItem
                  label={'Deduct From'}
                  value={
                    formData.deductFrom
                      ? deductFrom[formData.deductFrom as keyof typeof deductFrom].label
                      : 'N/A'
                  }
                />
              )}
              {isRecurring && (
                <>
                  <ReviewSectionItem
                    label="Prorate the component"
                    value={formData.supportProration ? 'Yes' : 'No'}
                  />
                  <ReviewSectionItem
                    label="Calculate arrears"
                    value={formData.considerInArrear ? 'Yes' : 'No'}
                  />
                </>
              )}
              {showTaxabilitySection && (
                <>
                  <ReviewSectionItem
                    label="Tax exempted in Old Regime"
                    value={formData.taxExemptInOldRegime ? 'Yes' : 'No'}
                  />
                  <ReviewSectionItem
                    label="Tax exempted in New Regime"
                    value={formData.taxExemptInNewRegime ? 'Yes' : 'No'}
                  />
                  <ReviewSectionItem
                    label="Exemption under"
                    value={
                      formData.taxExemptionSection?.[0]
                        ? deductionsExemptionDisplayText(formData.taxExemptionSection[0])
                        : 'Not Selected'
                    }
                  />
                </>
              )}
            </ReviewSectionItemContainer>
          </ReviewSection>
        </ReviewSectionContainer>
      </StepContentWrapper>
      <StepActionsWrapper>
        <Button
          variant="secondary"
          icon={ArrowLeftIcon}
          onClick={() => history.goBack()}
          isDisabled={createDeduction.isLoading}>
          Back: Pay & Taxability
        </Button>
        <Button type="submit" isDisabled={!isFormValid} isLoading={createDeduction.isLoading}>
          Create Component
        </Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default ReviewStep;
