import React, { useState, ReactElement, FormEvent } from 'react';
import { Table } from 'components/ui/Table';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../../../api';
import deleteIcon from 'assets/icon--delete.svg';
import editIcon from 'assets/icon--edit.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { Input, InputElement } from 'components/ui/Form/Input';
import { CAMS_ACTIVATION_STATUS_QUERY } from '../../queries';
import Spinner from 'components/ui/Spinner';
import syncIcon from 'assets/sync-icon-blue.svg';
import { SuccessAlert } from 'components/ui/Alert';

function ConnectedDevices(): ReactElement {
  const { openModal } = useModal();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const connectedDeviceQuery = useQuery(CAMS_ACTIVATION_STATUS_QUERY, () => {
    return api.camsSettings.getConnectedDevices();
  });

  const deleteDeviceMutation = useMutation(
    (deviceId: string) => {
      return api.camsSettings.deleteDevice(deviceId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CAMS_ACTIVATION_STATUS_QUERY);
      },
    },
  );

  const editDeviceNameMutation = useMutation(
    ({ deviceId, name }: { deviceId: string; name: string }) => {
      return api.camsSettings.updateDeviceName({
        deviceId: deviceId,
        name: name,
      });
    },
    {
      onSuccess: (data) => {
        closeModal();
        queryClient.invalidateQueries(CAMS_ACTIVATION_STATUS_QUERY);
      },
    },
  );

  const refreshDeviceMutation = useMutation((deviceId: string) => {
    return api.camsSettings.refreshDevice({ deviceId: deviceId });
  });

  const handleDelete = (device: string) => {
    deleteDeviceMutation.mutate(device);
  };

  const handleEdit = (deviceId: string, name: string) => {
    if (name.length === 0) {
      return;
    }
    editDeviceNameMutation.mutate({ deviceId: deviceId, name: name });
  };

  const handleRefresh = (deviceId: string) => {
    refreshDeviceMutation.mutate(deviceId);
  };

  const handleDeleteConfirmation = (deviceId: string) => {
    openModal(
      <ConfirmModal onConfirm={() => handleDelete(deviceId)}>
        <div className="flex flex-col items-start">
          <div className={'text-4xl font-bold text-white mb-8 mt-4'}>Delete device?</div>
          <div className="mb-8">
            Please note that this will delete any fingerprint/face data that is already stored on
            this device. Do you still want to go ahead?
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  const handleRefreshConfirmation = (deviceId: string) => {
    openModal(
      <ConfirmModal onConfirm={() => handleRefresh(deviceId)}>
        <div className="flex flex-col items-start">
          <div className={'text-4xl font-bold text-white mb-8 mt-4 text-left'}>Re-sync device?</div>
          <p className="mb-8">
            Please note that this will delete any fingerprint/face data that is already stored on
            this device. Do you still want to go ahead?
          </p>
        </div>
      </ConfirmModal>,
    );
  };
  const EditDeviceNameModal = ({
    deviceId,
    deviceName,
  }: {
    deviceId: string;
    deviceName: string;
  }) => {
    const [name, setName] = useState(deviceName);

    function handleSubmit(e: React.FormEvent) {
      e.preventDefault();
      handleEdit(deviceId, name);
    }

    return (
      <ConfirmModal confirmText="Save" onConfirm={() => handleEdit(deviceId, name)}>
        <form
          className="pr-4"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="text-white-o-80 text-4xl mb-10 font-bold">Edit device name</div>
          <InputElement required label="Device name" className="mt-4 mb-10">
            <Input
              id={deviceId}
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={deleteDeviceMutation.status === 'loading'}
            />
          </InputElement>
        </form>
      </ConfirmModal>
    );
  };

  const handleEditConfirmation = (deviceId: string) => {
    const filteredName = connectedDevices
      ?.filter((item) => item.device_id === deviceId)
      .map((item) => item.name);
    const deviceName = filteredName?.toString();
    openModal(<EditDeviceNameModal deviceId={deviceId} deviceName={deviceName!} />);
  };

  const connectedDevices = connectedDeviceQuery.data;
  if (connectedDevices?.length === 0) {
    return <></>;
  }

  if (connectedDeviceQuery.status === 'loading') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <h1 className="font-heading font-bold text-5xl my-16">Your devices</h1>
      {refreshDeviceMutation.status === 'success' && (
        <SuccessAlert className="mt-8">
          All employees will be re-synced to the device. It can take up to 30 minutes for the
          biometric device to update.
        </SuccessAlert>
      )}
      <Table className="break-all md:break-normal w-full mt-8">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Service Id</th>
            <th>Auth Token</th>
            <th>Last Active</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Re-sync</th>
          </tr>
          {connectedDevices?.map((device) => (
            <tr key={device.id}>
              <td>{device.name?.length === 0 ? 'N/A' : device.name}</td>
              <td>{device.device_id}</td>
              <td>{device.auth_token}</td>
              <td>
                {device.attendance_timestamp?.length === 0 ? 'N/A' : device.attendance_timestamp}
              </td>
              <td>
                <div
                  className="w-6 h-6 ml-4 cursor-pointer"
                  onClick={() => handleEditConfirmation(device.device_id)}
                >
                  <img src={getStaticMediaUrl(editIcon)} />
                </div>
              </td>
              <td>
                <div
                  className="w-6 h-6 ml-4 cursor-pointer"
                  onClick={() => handleDeleteConfirmation(device.device_id)}
                >
                  <img src={getStaticMediaUrl(deleteIcon)} />
                </div>
              </td>
              <td>
                <div
                  className="w-6 h-6 ml-4 cursor-pointer"
                  onClick={() => handleRefreshConfirmation(device.device_id)}
                >
                  <img src={getStaticMediaUrl(syncIcon)} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default ConnectedDevices;
