import { getRandomPick } from 'components/PayrollAIAssistant/useRandomPick';
import { PayrollAiAssistantPollSchemaContract } from 'schemas/PayrollAiAssistantPollSchema';

export const getRegimeBreakupCalculation = (
  pollData: PayrollAiAssistantPollSchemaContract | null,
) => {
  const messagePrefix = getRandomPick([
    'Absolutely! 🙈 Let me break down how I got to those results:',
    "Of course! 🙈 Here's the breakdown of how I reached those results:",
    'Definitely! 🙈 Let me walk you through how I reached those results:',
    "Certainly! 🙈 Here's the breakdown of the process behind those results",
  ]);

  const messageSuffix = getRandomPick([
    "Ready to help you save more money! 📈 Before we begin, I'll need some details about your personal finances. Ready to dive in?",
    "Let's maximize your savings! 📈 But first, I'll need some details about your personal finances. Ready to get started?",
    "Time to boost your savings! 📈 Before we start, I'll need some details about your personal finances. Ready to dive in?",
    "Let's work on growing your savings! 📈 But first, I'll need some details about your personal finances. Ready to get started?",
  ]);

  const calculation =
    pollData?.response?.regime_breakup?.regime_calculation_explanation ??
    "I'm Sorry, I don't have the calculation with me right now.";

  return `${messagePrefix} \n\n${calculation}\n\n${messageSuffix}`;
};
