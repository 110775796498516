import React, { PropsWithChildren } from 'react';

export function Card({
  className,
  children,
  withTopBorder,
}: PropsWithChildren<{ className: string; withTopBorder?: boolean }>) {
  return (
    <div
      className={`${className ? className : ''} ${
        withTopBorder ? ` border-primary border-t-2` : ''
      } w-full  bg-rhino rounded shadow-lg py-10 px-6`}
    >
      {children}
    </div>
  );
}
