import { Box, Button, PlusIcon } from '@razorpay/blade/components';
import React from 'react';
import { CustomDeclarationType, DeclarationErrors } from '../types';
import { DateRangeDeclarationSelector } from './DateRangeDeclarationSelector';

interface CustomDeclarationSelectorProps extends Partial<CustomDeclarationType> {
  onChange: (id: string, value: Date | null, isStart: boolean) => void;
  onAdd: () => void;
  onDelete: (id: string) => void;
  isProofUploadWindowSettings?: boolean;
  isLoading: boolean;
  errors?: DeclarationErrors;
  disableMonthSelector?: boolean;
  disableYearSelector?: boolean;
  disableAddNewButton?: boolean;
  monthOffset?: number;
}

export const CustomDeclarationSelector = ({
  dates = {},
  onChange,
  onAdd,
  onDelete,
  isProofUploadWindowSettings,
  isLoading,
  errors = {},
  disableYearSelector = false,
  disableMonthSelector = false,
  monthOffset = 0,
}: CustomDeclarationSelectorProps) => {
  const handleDateChange = (id: string, value: Date | null, isStart: boolean = true) => {
    onChange(id, value, isStart);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" gap="spacing.7" marginTop="spacing.7">
        {Object.entries(dates)?.map(([id, item], index) => (
          <DateRangeDeclarationSelector
            isLoading={isLoading}
            isProofUploadWindowSettings={isProofUploadWindowSettings}
            key={id}
            index={index}
            startDate={item.startDate}
            endDate={item.endDate}
            errors={errors[id]}
            disableYearSelector={disableYearSelector}
            disableMonthSelector={disableMonthSelector}
            monthOffset={monthOffset}
            onChange={(val, isStart) => handleDateChange(id, val, isStart)}
            onDelete={() => onDelete(id)}
          />
        ))}
      </Box>
      <Button
        isDisabled={isLoading}
        marginTop="spacing.4"
        alignSelf="flex-end"
        icon={PlusIcon}
        size="small"
        variant="secondary"
        accessibilityLabel="Add range"
        onClick={onAdd}>
        Add new range
      </Button>
    </Box>
  );
};
