import React, { useState, ChangeEventHandler, PropsWithChildren, MouseEventHandler } from 'react';
import { LinkButton, PrimaryButton } from '../../../ui/Button';
import { Input } from '../../../ui/Form/Input';
import { Select, Option } from '../../../ui/Form/Select';
import { Modal } from '../../../ui/Modal';
import pluralize from 'pluralize';

function ConfirmSendReminder({
  totalMissingEmployees,
  onConfirmSendReminder,
  children,
  showModal,
  setShowModal,
}: PropsWithChildren<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmSendReminder: MouseEventHandler<HTMLButtonElement>;
  totalMissingEmployees: number;
}>) {
  const YesButton = (
    <div>
      <PrimaryButton onClick={onConfirmSendReminder}>CONFIRM</PrimaryButton>
    </div>
  );
  return (
    <>
      <span className="cursor-pointer underline" onClick={() => setShowModal(true)}>
        {children}
      </span>
      <Modal
        showModal={showModal}
        heading=""
        footerChildren={YesButton}
        closeModal={() => setShowModal(false)}
      >
        Are you sure you want to send reminders to all the employees ({totalMissingEmployees}) who
        did not submit the insurance details?
      </Modal>
    </>
  );
}

export function EmployeesListBox({
  isFetching,
  totalEmployees,
  search,
  onSearch,
  currentFilters,
  onChangeFilter,
  filters,
  onConfirmSendReminder,
  totalMissingEmployees,
  children,
}: PropsWithChildren<{
  isFetching: boolean;
  totalEmployees: number;
  search: string | null;
  onSearch: ChangeEventHandler<HTMLInputElement>;
  onChangeFilter: ChangeEventHandler<HTMLSelectElement>;
  currentFilters: string[];
  filters: Record<string, string>;
  onConfirmSendReminder: (
    setShowModal: (show: boolean) => any,
  ) => MouseEventHandler<HTMLButtonElement>;
  totalMissingEmployees: number;
}>) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="space-y-6">
      <div className="text-4xl font-bold font-heading flex">
        <div className="flex-auto text-4xl font-bold font-heading">Details collection status</div>
        {!isFetching && (
          <div className="text-primary font-bold hidden sm:block">
            {totalEmployees} {pluralize('Employee', totalEmployees)}
          </div>
        )}
      </div>
      <div className="bg-rhino px-6 py-8 flex flex-wrap space-y-6 sm:space-y-0">
        <Input
          className="sm:flex-1 sm:px-4 w-1/2 sm:mr-4"
          type="text"
          placeholder="Search by employee name or email"
          value={search ? search : ''}
          onChange={onSearch}
        />
        <div className="flex flex-col sm:flex-row sm:inline-flex sm:space-x-4 w-full sm:w-1/4">
          <div className="my-auto w-full sm:w-auto">Filter:</div>
          <Select
            className="flex-auto w-full sm:w-auto"
            value={currentFilters ? currentFilters[0] : ''}
            onChange={onChangeFilter}
          >
            <Option value="">All</Option>
            {Object.entries(filters).map(([key, value]) => (
              <Option value={key}>{value}</Option>
            ))}
          </Select>
        </div>
        <ConfirmSendReminder
          showModal={showModal}
          setShowModal={setShowModal}
          onConfirmSendReminder={onConfirmSendReminder(setShowModal)}
          totalMissingEmployees={totalMissingEmployees}
        />
        {totalMissingEmployees ? (
          <LinkButton onClick={() => setShowModal(true)} className="sm:mx-4 w-full md:w-auto">
            Send Reminder
          </LinkButton>
        ) : (
          <></>
        )}
      </div>
      {children}
    </div>
  );
}
