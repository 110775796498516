import { PrimaryButton } from "components/ui/Button";
import Spinner from "components/ui/Spinner";
import { TableWithLoader } from "components/ui/Table";
import React from "react";
import { IInsurance } from "reducers/insurance";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";
import { renewalPageStateActionType, renewalPageStateType } from ".";
import styles from './index.module.css';
import RenewalEstimationData from "./RenewalEstimationData";
import {WarningAlert} from "../../../ui/Alert";


interface Props {
    insurance: IInsurance,
    organizationAndEmployeeRenewalData:GetInsuranceRenewalOrganizationAndEmployeeContract,
    generateVendorEstimate:Function,
    pageStateDispatch:React.Dispatch<renewalPageStateActionType>,
    pageState:renewalPageStateType
  }
const RenewalEstimationContainer:React.FC<Props> = ({
    insurance,
    organizationAndEmployeeRenewalData,
    generateVendorEstimate,
    pageStateDispatch,
    pageState
  })=>{
    
    return (
        <div className={`${styles['renewal-page-estimate-details-container']}`}>
        { (organizationAndEmployeeRenewalData?.canShowEstimateAndProcess && !(Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total) > 0 && !!organizationAndEmployeeRenewalData?.localEstimate?.total && organizationAndEmployeeRenewalData?.localEstimate?.total > 0
                && organizationAndEmployeeRenewalData?.isVendorEstimateValid && (Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total)?.toFixed(2) === organizationAndEmployeeRenewalData?.localEstimate?.total?.toFixed(2))))
            &&
            <>
                <TableWithLoader
                 className=" xl:w-1/2 min-w-full">
                    <tbody>
                    <tr className="flex flex-col">
                        <h2 className="m-3 mt-4 text-3xl font-semibold"> Click below to know the insurance premium</h2>

                        { (
                            Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total?.toFixed(2)) > 0 &&
                            Number(organizationAndEmployeeRenewalData?.localEstimate?.total?.toFixed(2)) > 0 &&
                            Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total?.toFixed(2)) !== Number(organizationAndEmployeeRenewalData?.localEstimate?.total?.toFixed(2))
                          ) &&
                            <WarningAlert
                                className='mt-10'
                                children={`Looks like there is some issue while generating estimates. Please contact support to renew insurance.`}/>
                        }
                        <PrimaryButton style={{margin: '20px auto 10px',fontSize:'0.9em',
                            opacity:(pageState.estimateStateLoader ? '0.5' : '1')}} 
                            onClick={()=>generateVendorEstimate(insurance.data?.organizationId)} 

                            disabled={pageState.estimateStateLoader}>
                               GET ESTIMATE
                                {pageState.estimateStateLoader ? <span style={{padding: '0.7em 1em'}}><Spinner /></span> : ''}
                        </PrimaryButton>
                    </tr>
                    </tbody>
                </TableWithLoader>
            </>}
        { (organizationAndEmployeeRenewalData?.canShowEstimateAndProcess && Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total) > 0 && !!organizationAndEmployeeRenewalData?.localEstimate?.total && organizationAndEmployeeRenewalData?.localEstimate?.total > 0
                && organizationAndEmployeeRenewalData?.isVendorEstimateValid && (Number(organizationAndEmployeeRenewalData?.vendorEstimate?.total)?.toFixed(2) === organizationAndEmployeeRenewalData?.localEstimate?.total?.toFixed(2)))
            && <RenewalEstimationData organizationAndEmployeeRenewalData={organizationAndEmployeeRenewalData} pageStateDispatch={pageStateDispatch} />}
        {
            (!organizationAndEmployeeRenewalData?.canShowEstimateAndProcess &&
                <TableWithLoader isLoading={pageState.estimateStateLoader} className=" xl:w-1/2 min-w-full">
                    <h2 className="m-3 mt-4 text-3xl font-semibold">No Estimate to process!</h2>
                </TableWithLoader>)
        }
    </div>
    )
}
 
export default RenewalEstimationContainer;