import { StatusAction, StatusState } from './types';

export const wizardReducer = (state: StatusState, action: StatusAction): StatusState => {
  switch (action.type) {
    case 'UPDATE_STATUS':
      return { ...state, [action.stepIndex]: action.status };
    case 'RESET_STATUS':
      return {};
    case 'INITIALIZE_STATUS':
      return action.statuses;
    default:
      return state;
  }
};
