import React, {ReactNode} from "react";
import styles from "../index.module.scss";
import {getStaticMediaUrl} from "../../../../../../utils/Urls";
import cx from "classnames";
import syncIcon from "assets/sync-icon-indigo.svg";

export interface PreSyncDisplayProps {
    display: boolean
    imageLink?: string
    header?: ReactNode,
    description?: ReactNode,
    isLoading?: boolean
}

const PreSyncDisplay = ({props}: {props: PreSyncDisplayProps}) => {
    if (!props.display) return (<></>);

    return (
        <div
            className={cx({ 'flex': props.display, 'hidden': !props.display },
                "mt-24 flex-col w-full items-center")}
        >
            {props.imageLink &&
                <div className={styles['syncStateImage']}>
                    <img src={getStaticMediaUrl(props.imageLink)} alt={"sync-pending"}/>
                    {props.isLoading &&
                        <div className={styles['spinner']}>
                            <img
                                className={`${styles['syncImage']} animate-spin`}
                                src={getStaticMediaUrl(syncIcon)}
                                alt={''}
                            />
                        </div>
                    }
                </div>
            }
            <h1 className="font-bold text-1.7xl opacity-90">{props.header}</h1>
            <p className="text-1.5xl opacity-75 mt-5">{props.description}</p>
        </div>
    )
}

export default PreSyncDisplay