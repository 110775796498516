import { Box, Heading } from '@razorpay/blade/components';
import React from 'react';
import useFetchUserDetails from '../hooks/useFetchUserDetails';
import { useSearchParams } from 'hooks/useSearchParams';

export const Header = () => {
  const { urlParams } = useSearchParams();
  const peopleId = urlParams.get('userId');
  const { firstName, isLoading } = useFetchUserDetails(peopleId);

  return (
    <Box display={'flex'} flexDirection={'row'} gap={'spacing.1'}>
      <Heading size="large" weight="semibold" color="surface.text.gray.subtle" textAlign="center">
        {firstName ? `${firstName}'s Pay` : 'Pay'}
      </Heading>
      <Heading size="large" weight="regular" color="surface.text.gray.muted" textAlign="center">
        Details
      </Heading>
    </Box>
  );
};
