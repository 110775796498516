import React, { ReactElement, FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { StandardButton, LinkButtonV2 } from 'components/ui/Button';
import { InputElement, Input } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import api from '../../../../../api';
import ConnectedDevices from '../ConnectedDevices';
import { CAMS_ACTIVATION_STATUS_QUERY } from '../../queries';
import { SuccessAlert, WarningAlert } from 'components/ui/Alert';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';

interface Props {}
function CamsActivation({}: Props): ReactElement {
  const [name, setName] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [authToken, setAuthToken] = useState('');
  const queryClient = useQueryClient();

  const knowMoreUrl =
    'https://intercom.help/XPayroll/en/articles/5487368-we-have-purchased-a-biometric-device-from-cams-how-do-we-integrate-it-with-xpayroll';

  const formSubmitMutation = useMutation(
    ({ name, serviceId, authToken }: { name: string; serviceId: string; authToken: string }) => {
      return api.camsSettings.addDevice({
        name: name,
        serviceId: serviceId,
        authToken: authToken,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(CAMS_ACTIVATION_STATUS_QUERY);
        setName('');
        setAuthToken('');
        setServiceId('');
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    formSubmitMutation.mutate({ name, serviceId, authToken });
  };

  return (
    <>
      {formSubmitMutation.status === 'success' && (
        <SuccessAlert className="mt-8">
          All employees will be added to the new device. It can take up to 30 minutes for the
          biometric device to update.
        </SuccessAlert>
      )}
      <h1 className="font-heading font-bold text-5xl my-16">Add a new device</h1>
      <div className="w-3/5">
        <form className="mt-12" onSubmit={handleFormSubmit}>
          <InputElement required label="Device Name" className="my-8">
            <Input
              type="text"
              value={name}
              required
              disabled={formSubmitMutation.status === 'loading'}
              name="domain"
              onChange={(event) => setName(event.target.value)}
              placeholder=""
              className="mt-16"
            />
          </InputElement>
          <InputElement required label="Service Id" className="my-8">
            <Input
              type="text"
              value={serviceId}
              required
              disabled={formSubmitMutation.status === 'loading'}
              name="domain"
              onChange={(event) => setServiceId(event.target.value)}
              placeholder=""
              className="mt-16"
            />
            <InputInfo>
              What is my Service id?{' '}
              <a target="_blank" rel="noreferrer" href={knowMoreUrl}>
                <LinkButtonV2>Know here</LinkButtonV2>
              </a>
            </InputInfo>
          </InputElement>
          <InputElement required label="Auth token" className="my-auto my-4">
            <Input
              type="text"
              value={authToken}
              required
              disabled={formSubmitMutation.status === 'loading'}
              name="domain"
              onChange={(event) => setAuthToken(event.target.value)}
              placeholder=""
              maxLength={32}
            />
            <InputInfo>
              What is my Auth Token?{' '}
              <a target="_blank" rel="noreferrer" href={knowMoreUrl}>
                <LinkButtonV2>Know here</LinkButtonV2>
              </a>
            </InputInfo>
          </InputElement>

          <StandardButton
            className="mt-20"
            type="submit"
            isPending={formSubmitMutation.status === 'loading'}>
            Add Device
          </StandardButton>
        </form>
      </div>
      {formSubmitMutation.status === 'error' && (
        <WarningAlert className="mt-8">
          <Errors errorResponse={formSubmitMutation.error} />
        </WarningAlert>
      )}
      <ConnectedDevices />
    </>
  );
}

export default CamsActivation;
