import React, { useEffect } from 'react';
import { ModalActions, salaryComponentsQueries } from 'components/SalaryComponents/constants';
import { useModalActions } from 'components/SalaryComponents/hooks/useModalActions';
import { EnableModal, DisableModal, DeleteModal } from 'components/SalaryComponents/modals/index';
import { DeductionsComponentType, EarningsComponentType } from '../types';
import { useQueryClient } from 'react-query';
import { useToast } from '@razorpay/blade/components';
import { AppError } from 'utils/AppError';

type ActionModalsProps = {
  salaryComponent: EarningsComponentType | DeductionsComponentType;
  currentActiveModal: string | null;
  closeModal: () => void;
};

const ActionModals = ({ salaryComponent, currentActiveModal, closeModal }: ActionModalsProps) => {
  const toast = useToast();

  const onSuccess = () => {
    switch (currentActiveModal) {
      case ModalActions.enable:
        toast.show({
          id: salaryComponentId,
          content: 'Component enabled successfully',
          color: 'positive',
        });
        break;
      case ModalActions.disable:
        toast.show({
          id: salaryComponentId,
          content: 'Component disabled successfully',
          color: 'information',
        });
        break;
      case ModalActions.delete:
        toast.show({
          id: salaryComponentId,
          content: 'Component deleted successfully',
          color: 'negative',
        });
        break;
    }

    closeModal();
    queryClient.invalidateQueries({
      queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery],
      exact: false,
    });
    // Force an immediate refetch
    queryClient.refetchQueries({
      queryKey: [salaryComponentsQueries.getSalaryComponentsList],
      exact: false,
    });
  };

  const onError = (error: AppError) => {
    toast.show({
      id: salaryComponentId,
      content: `There was an error changing status: ${error.message}`,
      color: 'negative',
    });
  };

  const { salaryComponentId, actionMutation } = useModalActions({
    modalAction: currentActiveModal,
    onSuccess: onSuccess,
    onError: onError,
  });
  const queryClient = useQueryClient();
  const isOpen = !!salaryComponentId && !!currentActiveModal;
  const isLoading = actionMutation.isLoading;
  const onSubmit = () => {
    let payType = undefined;
    if (salaryComponent.category === 'EARNINGS_CATEGORY') {
      payType = (salaryComponent as EarningsComponentType).settings.earningDetails.payType;
    }
    actionMutation.mutate({
      id: salaryComponentId,
      category: salaryComponent.category,
      status:
        currentActiveModal === ModalActions.enable ? 'SET_STATUS_ACTIVE' : 'SET_STATUS_INACTIVE',
      earningsPayType: payType,
    });
  };

  return (
    <>
      <EnableModal
        componentName={salaryComponent ? salaryComponent.name : ''}
        isOpen={isOpen && currentActiveModal === ModalActions.enable}
        isLoading={isLoading}
        onDismiss={closeModal}
        onSubmit={onSubmit}
      />
      <DisableModal
        componentName={salaryComponent ? salaryComponent.name : ''}
        isOpen={isOpen && currentActiveModal === ModalActions.disable}
        isLoading={isLoading}
        onDismiss={closeModal}
        onSubmit={onSubmit}
      />
      {/*Temporarily commenting it out till we solutionise salary component deletion*/}
      {/* <DeleteModal
        componentName={componentName}
        isOpen={isOpen && currentActiveModal === ModalActions.delete}
        isLoading={isLoading}
        onDismiss={closeModal}
        onSubmit={onSubmit}
      /> */}
    </>
  );
};

export default ActionModals;
