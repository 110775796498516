import React, { ReactElement, Suspense } from 'react';
import EmployeeInsurance from 'components/insurance/EmployeeInsurance';
import InsuranceAdmin from 'components/insurance/InsuranceAdmin';
import { UIDemo } from 'components/ui';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import UnknownPage from 'components/UnknownPage';
import permissions from 'constants/permissions';
import SlackSettings from 'components/Settings/SlackSettings';
import SlackActivation from 'components/Settings/SlackSettings/Activation';
import SlackAuthorize from 'components/Settings/SlackSettings/SlackAuthorize';
import VarianceReport from 'components/Reports/VarianceReport';
import EsicRegistrationDashboard from 'components/Reports/EsicRegistrationDashboard';
import PfRegistrationDashboard from 'components/Reports/PfRegistrationDashboard';
import FreshteamsSettings from 'components/Settings/FreshteamsSettings';
import FreshTeamsActivation from 'components/Settings/FreshteamsSettings/components/FreshTeamsActivation';
import CompanyOnboarding from 'components/CompanyOnboarding';
import { Integrations } from 'components/Integrations';
import HelpPage from 'components/HelpPage';
import Compliance from 'components/CompanyOnboarding/Compliance';
import JupiterSettings from 'components/Settings/JupiterSettings';
import CamsSettings from 'components/Settings/CamsSettings';
import CamsIntroduction from 'components/Settings/CamsSettings/components/CamsIntroduction';
import { routePaths } from './data';
import { trackSegmentPage } from 'utils/segment';
import PartnerFlexiBenefitsEmployee from '../PartnerFlexiBenefits/PartnerFlexiBenefitsEmployee';
import KlaarSettings from 'components/Settings/KlaarSettings';
import KlaarActivation from 'components/Settings/KlaarSettings/components/KlaarActivation';
import WhatsappSettings from 'components/Settings/WhatsAppSettings';
import ZaggleAdminInfo from '../PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/ZaggleAdminInfo';
import PartnerFlexiBenefitsAdmin from '../PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin';
import ZohoSettings from 'components/Settings/ZohoSettings';
import ZohoAuthorize from 'components/Settings/ZohoSettings/ZohoAuthorize';
import ZaggleAdminPage from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/ZaggleAdminManage';
import { ZaggleAdminScheduleACall } from '../PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/ZaggleAdminScheduleACall';
import TaxReportWithPageWrapper from 'components/Reports/TaxReport';
import SpringVerifySettings from 'components/Settings/SpringVerifySettings';
import SpringVerifyActivation from 'components/Settings/SpringVerifySettings/components/SpringVerifyActivation';
import SpringVerifyDeactivation from 'components/Settings/SpringVerifySettings/components/SpringVerifyDeactivation';
import PazcareSettings from 'components/Settings/PazcareSettings';
import PazcareActivation from 'components/Settings/PazcareSettings/components/PazcareActivation';
import PazcareDeactivation from 'components/Settings/PazcareSettings/components/PazcareDeactivation';
import ZaggleAdminActionItemsPage from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/ZaggleAdminInfo/ZaggleAdminActionItems/index';
import ZaggleAdminKYCVerificationPage from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/ZaggleAdminInfo/ZaggleAdminKYCVerification/index';
import ZohoIntro from 'components/Settings/ZohoSettings/ZohoIntro';
import ZohoIntroPreconditions from 'components/Settings/ZohoSettings/ZohoIntroPreconditions';
import ZohoConfig from 'components/Settings/ZohoSettings/ZohoConfig';
import AccessDenied from 'components/AccessDenied';
import RunPayroll from 'components/RunPayroll';
import PaymentBankMapping from 'components/Compliance/PaymentBankMapping';
import BulkAdditionsDeductionsLOP from '../BulkUploads/BulkAdditionsDeductionsLOP';
import BulkSalaryRevision from 'components/BulkUploads/BulkSalaryRevision';
import EmployeeOnboarding from 'components/EmployeeOnboarding';
import EmployeeAdvanceSalary from 'components/EmployeeOnboarding/EmployeeAdvanceSalary';
import JibbleSettings from 'components/Settings/JibbleSettings';
import OrgJibbleAccountConfirmation from '../Settings/JibbleSettings/components/OrgJibbleAccountConfirmation';
import JibbleActivation from 'components/Settings/JibbleSettings/components/onboarding/JibbleActivation';
import JibbleAttendanceSettingsOnboarding from 'components/Settings/JibbleSettings/components/onboarding/JibbleAttendanceSettings';
import JibbleSyncEmployees from '../Settings/JibbleSettings/components/onboarding/JibbleSyncEmployees';
import JibbleManageIntegration from 'components/Settings/JibbleSettings/components/ManageIntegration';
import JibbleAttendanceSettings from '../Settings/JibbleSettings/components/JibbleAttendanceSettings';
import JibbleManageEmployees from '../Settings/JibbleSettings/components/JibbleManageEmployees';
import JibbleAttendanceReports from '../Settings/JibbleSettings/components/JibbleAttendanceReports';
import KycView from 'components/Kyc/KycView';
import CreateLoan from 'components/Loans/CreateLoan';
import ViewLoans from 'components/Loans/ViewLoans';
import ManageLoan from '../Loans/ManageLoan';
import SkipEmi from 'components/Loans/SkipEmi';
import LoanRecovery from '../Loans/LoanRecovery';
import Onboarding from 'components/Onboarding';
import LoanChangeTenure from 'components/Loans/LoanChangeTenure';
import VerifyOtp from 'components/Otp/Login';
import ViewBonus from '../Bonus/ViewBonus';
import { CreateWorkflow } from 'components/ApprovalWorkflows/CreateWorkflow';
import { MyRequests } from 'components/ApprovalWorkflows/MyRequests';
import DeclarationView from 'components/FlexibleBenefits/DeclarationView';
import FlexibleBenefitsSettings from 'components/Settings/FlexibleBenefitsSettings/FlexibleBenefitsSettings';
import CreateBonus from '../Bonus/CreateBonus';
import EditBonus from '../Bonus/EditBonus';
import FnfPage from 'components/Fnf';

import bladeRoutes from './bladeRoutes';
import { CustomReport } from 'components/CustomReports/CustomReport';
import EmployeeDeclaration from '../Settings/NPS/EmployeeDeclaration';
import NpsSettingsPage from '../Settings/NPS/NpsSettingsPage';
import TwoFactorOrgSetting from 'components/Otp/TwoFactorOrgSetting';
import { KnitHrms } from 'components/Knit/KnitHrms';
import { KnitApps, KnitHrmsAppsFeatureFlags } from 'components/Knit/constants';
import RXIntegration from 'components/RXIntegration';
import InitiateExit from 'components/Fnf/InitiateExit/InitiateExit';
import ReleaseNetpay from 'components/Fnf/ReleaseNetpay/ReleaseNetpay';
import userRoles from 'constants/userRoles';
import TaxDeclarationAndProofSettings from 'components/Settings/TaxDeclarationAndProofSettings/TaxDeclarationAndProofSettings';
import { PayrollAIAssistant } from 'components/PayrollAIAssistant';
import CustomPayrollAndAttendanceCycle from '../Settings/CustomPayrollAndAttendanceCycle/CustomPayrollAndAttendanceCycle';
import BulkUploadsInitiate from 'components/BulkUploads/BulkUploadsInitiate';
import ApiAccessView from 'components/ApiAccessPage/ApiAccessView';
import SalaryComponentsView from 'components/SalaryComponents/views/SalaryComponentsView';
import CreateEarningsView from 'components/WizardViews/SalaryComponents/CreateEarnings/CreateEarningsView';
import EditEarningsView from 'components/WizardViews/SalaryComponents/EditEarnings/EditEarningsView';
import EditPredefined from 'components/WizardViews/SalaryComponents/EditPredefined/EditPredefined';
import CreateDeductionsView from 'components/WizardViews/SalaryComponents/CreateDeductions/CreateDeductionsView';
import EditDeductionsView from 'components/WizardViews/SalaryComponents/EditDeductions/EditDeductionsView';
import Attendance from 'components/Attendance';
import SalaryStructure from 'components/SalaryStructure/SalaryStructure';
// import { PendingReimbursements } from 'components/Reimbursements/PendingReimbursements';
import { SalaryExplainerWrapper } from './routeExports';
import { LazyComponentLoader } from './LazyComponentLoader';
import { PayDetailsView } from 'components/PayDetails/views/PayDetailsView';

function Routes(): ReactElement {
  const location = useLocation();
  const previousUrl = React.useRef<string>(document.referrer || '');

  // not relying on document.referrer for this hack
  // we are force refreshing the page if you are navigating between blade and non blade routes
  // when location.href is set, document.referrer wil still point to the older route
  // and it wil go into an infinte loop
  const previousPathFromReactRouter = React.useRef<string>('');

  const previousPath = previousPathFromReactRouter.current;
  const prevRouteType = bladeRoutes.includes(previousPath) ? 'blade' : 'non-blade';
  const currentRouteType = bladeRoutes.includes(location.pathname) ? 'blade' : 'non-blade';

  React.useEffect(() => {
    trackSegmentPage({ referrer: previousUrl.current });

    if (previousPathFromReactRouter.current) {
      if (prevRouteType !== currentRouteType) {
        window.location.href = window.location.origin + location.pathname + location.search;
      }
    }

    previousPathFromReactRouter.current = location.pathname;

    previousUrl.current = window.location.origin + location.pathname;
  }, [location.pathname]);

  // dont render any routes unless the previous and current type is same
  // if they are not same, the above effect wil take care of reloading the page to get the approriate CSS files
  if (previousPath !== '' && prevRouteType !== currentRouteType) {
    return <></>;
  }

  const currentPath = window.location.pathname + window.location.search;

  return (
    <Suspense fallback={<LazyComponentLoader />}>
      <Switch>
        <RestrictedRoute path={routePaths.insurance.user.root} component={EmployeeInsurance} />
        <Route path={routePaths.login.otp} component={VerifyOtp} exact />
        <RestrictedRoute
          allowedPermissions={[permissions.MANAGE_INSURANCE]}
          path={routePaths.insurance.admin.root}
          component={InsuranceAdmin}
        />
        <RestrictedRoute path={routePaths.uiDemo} component={UIDemo} />
        <RestrictedRoute
          path={routePaths.settings.slackSettings}
          component={SlackSettings}
          allowedPermissions={[permissions.MANAGE_SLACK]}
          exact
        />
        <RestrictedRoute
          path={routePaths.settings.slackActivation}
          exact
          component={SlackActivation}
        />
        <RestrictedRoute
          path={routePaths.settings.slackAuthorize}
          exact
          component={SlackAuthorize}
          allowedPermissions={[permissions.MANAGE_SLACK]}
        />
        <RestrictedRoute
          path={routePaths.reports.varianceReport}
          exact
          component={VarianceReport}
          allowedPermissions={[permissions.REPORTS_SALARY_REGISTER]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.reports.esicRegistrationDashboard}
          exact
          component={EsicRegistrationDashboard}
          allowedPermissions={[permissions.REPORTS_ESIC_REGISTRATION_DASHBOARD]}
        />
        <RestrictedRoute
          path={routePaths.reports.pfRegistrationDashboard}
          exact
          component={PfRegistrationDashboard}
          allowedPermissions={[permissions.REPORTS_PF_REGISTRATION_DASHBOARD]}
        />
        <RestrictedRoute
          path={routePaths.settings.freshteamsSettings}
          exact
          component={FreshteamsSettings}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION, permissions.TRIGGER_HRMS_SYNC]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.freshteamsActivation}
          exact
          component={FreshTeamsActivation}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.settings.whatsappSettings}
          component={WhatsappSettings}
          allowedPermissions={[permissions.MANAGE_WHATSAPP]}
        />
        <RestrictedRoute
          path={routePaths.integrations.root}
          component={Integrations}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        />
        <RestrictedRoute exact path={routePaths.help.root} component={HelpPage} />
        <RestrictedRoute
          path={routePaths.companyOnboarding.compliance.root}
          component={Compliance}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.companyOnboarding.root}
          component={CompanyOnboarding}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.onboarding.root}
          component={Onboarding}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          exact
          path={routePaths.settings.jupiterSettings}
          component={JupiterSettings}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        />

        <RestrictedRoute
          exact
          path={routePaths.settings.camsSettings}
          component={CamsSettings}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        />
        <RestrictedRoute
          exact
          path={routePaths.settings.camsIntro}
          component={CamsIntroduction}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        />
        <RestrictedRoute
          path={routePaths.partnerFlexiBenefits.user.employeeDeclarationForm}
          component={PartnerFlexiBenefitsEmployee}
        />
        <RestrictedRoute
          path={routePaths.settings.klaar.settings}
          exact
          component={KlaarSettings}
          allowedPermissions={[permissions.MANAGE_KLAAR]}
        />
        <RestrictedRoute
          path={routePaths.settings.klaar.activation}
          exact
          component={KlaarActivation}
          allowedPermissions={[permissions.MANAGE_KLAAR]}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.info}
          component={ZaggleAdminInfo}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['enableZaggle']}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.manage}
          component={ZaggleAdminPage}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['enableZaggle']}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.connectedStatus}
          component={PartnerFlexiBenefitsAdmin}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.scheduleACall}
          component={ZaggleAdminScheduleACall}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.zoho.home}
          exact
          component={ZohoSettings}
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION, permissions.TRIGGER_HRMS_SYNC]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.zoho.introduction}
          exact
          component={ZohoIntro}
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION, permissions.TRIGGER_HRMS_SYNC]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.zoho.preconditions}
          exact
          component={ZohoIntroPreconditions}
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.zoho.authorize}
          exact
          component={ZohoAuthorize}
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.zoho.config.root}
          component={ZohoConfig}
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.springVerify.settings}
          exact
          component={SpringVerifySettings}
          allowedPermissions={[permissions.MANAGE_SPRING_VERIFY]}
        />
        <RestrictedRoute
          path={routePaths.settings.springVerify.activation}
          exact
          component={SpringVerifyActivation}
          allowedPermissions={[permissions.MANAGE_SPRING_VERIFY]}
        />
        <RestrictedRoute
          path={routePaths.settings.springVerify.deactivation}
          exact
          component={SpringVerifyDeactivation}
          allowedPermissions={[permissions.MANAGE_SPRING_VERIFY]}
        />
        <RestrictedRoute
          path={routePaths.settings.pazcare.settings}
          exact
          component={PazcareSettings}
          allowedFeatures={['pazcare-integration']}
          allowedPermissions={[permissions.MANAGE_PAZCARE]}
        />
        <RestrictedRoute
          path={routePaths.settings.pazcare.activation}
          exact
          component={PazcareActivation}
          allowedFeatures={['pazcare-integration']}
          allowedPermissions={[permissions.MANAGE_PAZCARE]}
        />
        <RestrictedRoute
          path={routePaths.settings.pazcare.deactivation}
          exact
          component={PazcareDeactivation}
          allowedFeatures={['pazcare-integration']}
          allowedPermissions={[permissions.MANAGE_PAZCARE]}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.scheduleACall}
          component={ZaggleAdminScheduleACall}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.actionItems}
          component={ZaggleAdminActionItemsPage}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['enableZaggle']}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          exact
          path={routePaths.partnerFlexiBenefits.admin.kycVerification}
          component={ZaggleAdminKYCVerificationPage}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['enableZaggle']}
          disabledFeaturesForRearch={['isZaggleDisabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.introduction}
          exact
          component={JibbleSettings}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.accountConfirmation}
          exact
          component={OrgJibbleAccountConfirmation}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.onboarding.activation}
          exact
          component={JibbleActivation}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.onboarding.settingsConfiguration}
          exact
          component={JibbleAttendanceSettingsOnboarding}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.onboarding.syncEmployees}
          exact
          component={JibbleSyncEmployees}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.manage}
          exact
          component={JibbleManageIntegration}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.settings}
          exact
          component={JibbleAttendanceSettings}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.employees}
          exact
          component={JibbleManageEmployees}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.jibble.attendanceReport}
          exact
          component={JibbleAttendanceReports}
          allowedPermissions={[
            permissions.MANAGE_INTEGRATIONS,
            permissions.REPORTS_VIEW,
            permissions.PAYROLL_VIEW,
            permissions.PAYROLL_EDIT,
          ]}
          allowedFeatures={['jibble-integration']}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.darwinbox.root}
          component={KnitHrms}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.darwinbox]]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.hibob.root}
          component={KnitHrms}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.hibob]]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.worq.root}
          component={KnitHrms}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.worq]]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.settings.workline.root}
          component={KnitHrms}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
          allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.workline]]}
          disabledFeaturesForRearch={['isMileStone1Enabled']}
        />
        <RestrictedRoute
          path={routePaths.runpayroll}
          exact
          component={RunPayroll}
          allowedPermissions={[
            permissions.PAYROLL_VIEW,
            permissions.PAYROLL_EDIT,
            permissions.PAYROLL_EXECUTION,
            permissions.PAYROLL_FINALIZE,
          ]}
        />
        <RestrictedRoute
          path={routePaths.bulkUploads.bulkAdditionDeductionLOP.root}
          component={BulkAdditionsDeductionsLOP}
          allowedPermissions={[permissions.BULK_ADDITION_DEDUCTION]}
          disabledFeaturesForRearch={[
            'isAdditionsDisabled',
            'isDeductionsDisabled',
            'isLOPDisabled',
          ]}
        />
        <RestrictedRoute
          path={routePaths.bulkUploads.bulkSalaryRevision.root}
          component={BulkSalaryRevision}
          allowedPermissions={[
            permissions.EMPLOYEE_CHANGE_SALARY,
            permissions.BULK_SALARY_REVISION,
          ]}
        />
        <RestrictedRoute
          path={[routePaths.bulkUploads.root, routePaths.bulkUploads.initiate]}
          component={BulkUploadsInitiate}
        />
        <RestrictedRoute
          path={[routePaths.employeeOnboarding.employeeAnnualCompensation]}
          component={EmployeeOnboarding}
          allowedPermissions={[
            permissions.EMPLOYEE_VIEW_PROFILE,
            permissions.EMPLOYEE_CHANGE_SALARY,
          ]}
        />
        <RestrictedRoute
          path={[routePaths.employeeOnboarding.advanceSalary]}
          component={EmployeeAdvanceSalary}
          allowedPermissions={[
            permissions.EMPLOYEE_VIEW_PROFILE,
            permissions.EMPLOYEE_CHANGE_SALARY,
          ]}
          disabledFeaturesForRearch={['isAdvanceSalaryDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.admin.bankSelection]}
          component={PaymentBankMapping}
          allowedSpecialPlatformPermissions={['PLATFORM_PERMISSION_COMPLIANCE_SERVICE_ADMIN']}
          exact
        />
        <RestrictedRoute
          path={routePaths.kyc.root}
          component={KycView}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={[routePaths.loans.createLoan]}
          component={CreateLoan}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.loans.root]}
          component={ViewLoans}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.loans.manageLoan]}
          component={ManageLoan}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.loans.skipEmi]}
          component={SkipEmi}
          allowedPermissions={[permissions.PAYROLL_PAY_ADVANCE_SALARY]}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.loans.loanRecovery]}
          component={LoanRecovery}
          allowedPermissions={[permissions.PAYROLL_PAY_ADVANCE_SALARY]}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.loans.loanTenureChange]}
          component={LoanChangeTenure}
          exact
          disabledFeaturesForRearch={['isLoansDisabled']}
        />
        <RestrictedRoute
          path={routePaths.approvalWorkflows.workflow.root}
          component={CreateWorkflow}
        />
        <RestrictedRoute
          path={routePaths.approvalWorkflows.myRequests.root}
          component={MyRequests}
        />
        <RestrictedRoute
          path={[routePaths.bonus.create]}
          component={CreateBonus}
          allowedPermissions={[permissions.BONUS_EDIT]}
          disabledFeaturesForRearch={['isBonusDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.bonus.view]}
          component={ViewBonus}
          disabledFeaturesForRearch={['isBonusDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.bonus.edit]}
          component={EditBonus}
          allowedPermissions={[permissions.BONUS_EDIT]}
          disabledFeaturesForRearch={['isBonusDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.flexibleBenefits.root, routePaths.flexibleBenefits.declaration]}
          component={DeclarationView}
          exact
          disabledFeaturesForRearch={['isFBPDisabled']}
        />
        <RestrictedRoute
          path={routePaths.flexibleBenefits.settings}
          component={FlexibleBenefitsSettings}
          disabledFeaturesForRearch={['isFBPDisabled']}
        />
        <RestrictedRoute
          path={routePaths.customReport.root}
          allowedPermissions={[permissions.REPORTS_VIEW]}
          exact
          component={CustomReport}
        />
        <RestrictedRoute
          path={[routePaths.nps.root, routePaths.nps.settings]}
          allowedFeatures={['npsFlag']}
          allowedRoles={[userRoles.ADMIN]}
          component={NpsSettingsPage}
          exact
          disabledFeaturesForRearch={['isEmployerNPSDisabled']}
        />
        <RestrictedRoute
          path={[routePaths.nps.declaration]}
          allowedFeatures={['npsFlag']}
          component={EmployeeDeclaration}
          exact
          disabledFeaturesForRearch={['isEmployerNPSDisabled']}
        />
        <RestrictedRoute
          path={routePaths.fnf.root}
          allowedPermissions={[permissions.EMPLOYEE_DISMISSAL]}
          component={FnfPage}
          exact
        />
        <RestrictedRoute
          path={routePaths.fnf.initiate}
          allowedFeatures={['holdSalaryPayCompliance']}
          allowedPermissions={[permissions.EMPLOYEE_DISMISSAL]}
          component={InitiateExit}
          exact
        />
        <RestrictedRoute
          path={routePaths.fnf.releaseNetPay}
          allowedFeatures={['holdSalaryPayCompliance']}
          allowedPermissions={[permissions.EMPLOYEE_DISMISSAL]}
          component={ReleaseNetpay}
          exact
          disabledFeaturesForRearch={['isHoldSalaryPayComplianceDisabled']}
        />
        <RestrictedRoute
          path={routePaths.login.twoFactorOrgSetting}
          allowedPermissions={[permissions.MANAGE_2FA]}
          component={TwoFactorOrgSetting}
        />
        <RestrictedRoute
          path={routePaths.settings.rxIntegration.root}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
          allowedFeatures={['rxSelfServe']}
          component={RXIntegration}
        />
        <RestrictedRoute
          path={[routePaths.taxDeclarationAndProofSettings.settings]}
          component={TaxDeclarationAndProofSettings}
        />
        <RestrictedRoute
          path={[routePaths.payrollSetupSettings.root]}
          allowedFeatures={['customPayrollCycleFlag']}
          component={CustomPayrollAndAttendanceCycle}
        />
        <RestrictedRoute
          path={[routePaths.orgSettings.apiAccess]}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
          component={ApiAccessView}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.edit.predefinedComponent]}
          allowedPermissions={[permissions.MANAGE_SALARY_COMPONENTS]}
          allowedFeatures={['reArchM2S1']}
          component={EditPredefined}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.edit.earnings.root]}
          allowedPermissions={[permissions.MANAGE_SALARY_COMPONENTS]}
          allowedFeatures={['reArchM2S1']}
          component={EditEarningsView}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.edit.deductions.root]}
          allowedFeatures={['reArchM2S1']}
          component={EditDeductionsView}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.create.earnings.root]}
          allowedPermissions={[permissions.MANAGE_SALARY_COMPONENTS]}
          allowedFeatures={['reArchM2S1']}
          component={CreateEarningsView}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.create.deductions.root]}
          allowedFeatures={['reArchM2S1']}
          component={CreateDeductionsView}
        />
        <RestrictedRoute
          path={[routePaths.salaryComponents.root]}
          allowedPermissions={[permissions.MANAGE_SALARY_COMPONENTS]}
          allowedFeatures={['reArchM2S1']}
          component={SalaryComponentsView}
        />
        <RestrictedRoute
          path={[routePaths.salaryStructure.root]}
          allowedFeatures={['reArchM2S2']}
          component={SalaryStructure}
        />
        <RestrictedRoute
          path={[routePaths.payDetails.root]}
          allowedFeatures={['reArchM2S2']}
          component={PayDetailsView}
        />
        <RestrictedRoute path={routePaths.attendance.root} component={Attendance} />
        {/*
          DONT UNCOMMENT THIS LINE
        */}
        {/* <RestrictedRoute
          path={[routePaths.reimbursements.pending.root]}
          component={PendingReimbursements}
        /> */}
        <RestrictedRoute
          path={routePaths.salaryExplainer}
          component={SalaryExplainerWrapper}
          exact
        />
        <RestrictedRoute path={routePaths.accessDenied} exact component={AccessDenied} />
        <Route path="/404" component={UnknownPage} />
        <Route path={routePaths.payrollAiAssistant} component={PayrollAIAssistant} exact />
        <Route path="*">
          <Redirect to={`/404?path=${currentPath}`} />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;
