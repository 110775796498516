import { Box, Button, CheckIcon, Heading } from '@razorpay/blade/components';
import React from 'react';
import checkIcon from '../../assets/bulkUploads/file--download--success--tick.svg';
import {
  AnimatedContainer,
  SuccessViewModalContainer,
  SuccessViewModalContentWrapper,
  TitleContainer,
} from 'components/ui/FullscreenSuccessModal/styles';

const SuccessViewModal = ({ isOpen = false, orgName }: { isOpen?: boolean; orgName: string }) => {
  if (!isOpen) return null;

  const handleSetupClick = () => {
    window.location.href = '/dashboard';
  };

  return (
    (<SuccessViewModalContainer>
      <SuccessViewModalContentWrapper>
        <AnimatedContainer>
          <TitleContainer>
            <img src={checkIcon} width={80} height={80} alt="" />
            <Heading
              marginTop={'spacing.5'}
              weight="regular"
              color="surface.text.gray.subtle"
              size="medium">
              KYC Verification Successful!
            </Heading>
            <Heading marginTop={'spacing.7'} textAlign="center" size="xlarge">
              Welcome to RazorpayX Payroll, {orgName}! 🎉
            </Heading>
          </TitleContainer>
        </AnimatedContainer>
        <AnimatedContainer delay={1200}>
          <Box
            marginY={'64px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'spacing.7'}
            paddingX={'spacing.5'}>
            <Box display={'flex'} alignItems={'center'}>
              <CheckIcon
                size="medium"
                color="interactive.icon.positive.subtle"
              />
              <Heading
                marginLeft={'spacing.7'}
                weight="regular"
                color="interactive.text.staticWhite.normal"
                size="small">
                Generate salary slips for your employees
              </Heading>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <CheckIcon
                size="medium"
                color="interactive.icon.positive.subtle"
              />
              <Heading
                marginLeft={'spacing.7'}
                weight="regular"
                color="interactive.text.staticWhite.normal"
                size="small">
                Process hassle-free salary transfers in an hour
              </Heading>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button onClick={handleSetupClick}>Go to Set-Up</Button>
          </Box>
        </AnimatedContainer>
      </SuccessViewModalContentWrapper>
    </SuccessViewModalContainer>)
  );
};

export default SuccessViewModal;
