import React from 'react';

import { Select, Option } from 'components/ui/Form/Select';

const BankSelector = ({
  options,
  value,
  onChange,
}: {
  options: {
    bank: string;
    name: string;
  }[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <Select name="bankSelector" onChange={onChange} value={value ?? ''}>
      {options.map((option) => (
        <Option key={option.bank} value={option.bank}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
};

export default BankSelector;
