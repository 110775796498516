import { Box, Button, EditIcon, Link, TextInput } from '@razorpay/blade/components';
import { FormContainer } from 'components/Onboarding/CompanyDetails/styles';
import { routePaths } from 'components/Routes/data';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useVerifyCompanyDetailsContext } from './VerifyCompanyDetailsContext';
import { useCompanyVerificationData } from '../hooks/useCompanyVerificationDetails';
import api from 'api';
import { useMutation, useQuery } from 'react-query';
import { CIN_REGEX, LLPIN_REGEX, PAN_REGEX } from 'utils/regexes';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { PanNameView } from 'components/Onboarding/components/PanNameView';
import { BvsDataLoadingView } from 'components/Onboarding/components/BvsDataLoadingView';
import { useDispatch } from 'react-redux';
import { fetchLoggedInUser } from 'reducers/loggedInUser';

const CinOrLlpinWithPan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setAlertBannerPropsContext } = useVerifyCompanyDetailsContext();
  const { isCompanyVerified, authorizedSignatory } = useCompanyVerificationData();
  const [formData, setFormData] = useState({
    cinOrLlpin: '',
    signatoryPan: authorizedSignatory?.pan ?? '',
  });
  const [validationErrors, setValidationErrors] = useState({
    cinOrLlpin: '',
    signatoryPan: '',
  });
  const [signatoryNameFromPan, setSignatoryNameFromPan] = useState('');
  const panFieldRef = useRef<HTMLInputElement>(null);

  const isCin = CIN_REGEX.test(formData.cinOrLlpin);
  const isLlpin = LLPIN_REGEX.test(formData.cinOrLlpin);

  const signatoryPanQuery = useQuery({
    queryFn: () => api.companyOnboarding.getCompanyNameFromPan(formData.signatoryPan),
    onSuccess: (data) => {
      setSignatoryNameFromPan(data.name ?? '');
    },
    onError: (error) => {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        signatoryPan: errorParser(getAppErrorDetails(error))[0],
      }));
    },
    enabled: false,
  });

  useEffect(() => {
    const { signatoryPan } = formData;
    if (
      signatoryPan &&
      signatoryPan.length >= 10 &&
      new RegExp(PAN_REGEX).test(signatoryPan) &&
      !isCompanyVerified
    ) {
      setSignatoryNameFromPan('');
      signatoryPanQuery.refetch();
    }
  }, [formData.signatoryPan]);

  const companyVerificationByCinMutation = useMutation({
    mutationFn: api.companyOnboarding.verifyCompanyDetailsWithCin,
    onSuccess: (data) => {
      if (data.success) {
        setAlertBannerPropsContext(undefined);
        dispatch(fetchLoggedInUser());
        history.push({
          pathname: routePaths.kyc.companyDetailsVerification.manual,
          state: {
            cinOrLlpin: formData.cinOrLlpin,
            signatoryPan: formData.signatoryPan,
          },
        });
      }
    },
    onError: (error) => {
      setAlertBannerPropsContext({
        title: 'Verification failed',
        color: 'negative',
        description: errorParser(getAppErrorDetails(error))[0],
        isDismissible: false,
      });
    },
  });

  const companyVerificationByLlpinMutation = useMutation({
    mutationFn: api.companyOnboarding.verifyCompanyDetailsWithLlpin,
    onSuccess: (data) => {
      if (data.success) {
        setAlertBannerPropsContext(undefined);
        dispatch(fetchLoggedInUser());
        history.push({
          pathname: routePaths.kyc.companyDetailsVerification.manual,
          state: {
            cinOrLlpin: formData.cinOrLlpin,
            signatoryPan: formData.signatoryPan,
          },
        });
      }
    },
    onError: (error) => {
      setAlertBannerPropsContext({
        title: 'Verification failed',
        color: 'negative',
        description: errorParser(getAppErrorDetails(error))[0],
        isDismissible: false,
      });
    },
  });

  const redirectToDashboard = () => {
    window.location.href = '/dashboard';
  };

  // if the company verification is already completed, redirect back to dashboard
  if (isCompanyVerified) {
    redirectToDashboard();
    return null;
  }

  const handleChange = (e: { name?: string; value?: string }) => {
    if (!e.name) return;

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      [e.name as string]: '',
    }));

    setFormData((formData) => ({
      ...formData,
      [e.name as string]: e.value,
    }));
  };

  const handleHaveGstinClick = (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault();

    history.push(routePaths.kyc.companyDetailsVerification.gstin);
  };

  const validateFields = () => {
    let result = true;
    if (!isCin && !isLlpin) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        cinOrLlpin: 'Please enter a valid CIN or LLPIN',
      }));
      result = false;
    }

    if (!new RegExp(PAN_REGEX).test(formData.signatoryPan)) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        signatoryPan: 'Please enter a valid personal PAN',
      }));
      result = false;
    }

    return result;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    if (isCin) {
      companyVerificationByCinMutation.mutate({
        cin: formData.cinOrLlpin,
        signatoryPan: formData.signatoryPan,
        signatoryName: signatoryNameFromPan,
      });
    }

    if (isLlpin) {
      companyVerificationByLlpinMutation.mutate({
        llpin: formData.cinOrLlpin,
        signatoryPan: formData.signatoryPan,
        signatoryName: signatoryNameFromPan,
      });
    }
  };

  const handleNotCorrectSignatoryNameClick = () => {
    panFieldRef.current?.focus();
  };

  const isSignatoryNameFetched = signatoryNameFromPan.length > 0;

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <TextInput
          name="cinOrLlpin"
          label="CIN/LLPIN"
          placeholder="Eg. U67190TN2014PTC096971 or AAA-0001"
          value={formData.cinOrLlpin}
          onChange={handleChange}
          isRequired
          autoFocus
          validationState={validationErrors.cinOrLlpin ? 'error' : 'none'}
          errorText={validationErrors.cinOrLlpin}
        />
        <TextInput
          name="signatoryPan"
          label="Authorised Signatory PAN"
          placeholder="Eg. BZBIJ7819G"
          value={formData.signatoryPan}
          onChange={handleChange}
          isRequired
          ref={panFieldRef}
          validationState={validationErrors.signatoryPan ? 'error' : 'none'}
          errorText={validationErrors.signatoryPan}
        />
        {isSignatoryNameFetched && (
          <PanNameView title="Authorised Signatory Name" value={signatoryNameFromPan} />
        )}
        {!signatoryPanQuery.isFetching && (
          <Link onClick={handleHaveGstinClick}>Have GSTIN? Verify here→</Link>
        )}
        {signatoryPanQuery.isFetching && (
          <BvsDataLoadingView description="Fetching your details..." />
        )}
      </FormContainer>
      {isSignatoryNameFetched && (
        <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.5">
          <Box>
            <Button
              type="submit"
              isLoading={
                companyVerificationByCinMutation.isLoading ||
                companyVerificationByLlpinMutation.isLoading
              }>
              Confirm & Proceed
            </Button>
          </Box>
          <Box>
            <Button
              variant="secondary"
              iconPosition="right"
              icon={EditIcon}
              onClick={handleNotCorrectSignatoryNameClick}
              isDisabled={
                companyVerificationByCinMutation.isLoading ||
                companyVerificationByLlpinMutation.isLoading
              }>
              Not the correct signatory name? Edit PAN
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
};

export { CinOrLlpinWithPan };
