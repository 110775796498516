import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import React, { ReactElement } from 'react';
import PazcareIntro from './components/PazcareIntro';
import styles from './index.module.scss';
import Breadcrumb from 'components/ui/Breadcrumb';

function Pazcare(): ReactElement {
  return (
    <div className={`${styles['sectionWidth']}`}>
      <PazcareIntro />
    </div>
  );
}

function PazcareSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Pazcare"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase center>
        <Pazcare/>
      </MainCase>
    </PageWrapper>
  );
}

export default PazcareSettingsWithPageWrapper;
