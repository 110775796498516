import React from 'react';
import { useQuery } from 'react-query';
import { ZOHO_ACTIVATION_STATUS_QUERY } from '../queries';
import api from 'api';
import { AppError } from 'utils/AppError';

export default function useActivationStatusQuery() {
  const activationQuery = useQuery(
    ZOHO_ACTIVATION_STATUS_QUERY,
    () => {
      return api.zoho.fetchActivationStatus();
    },
    {
      onError: (error: AppError) => {},
      staleTime: 3000 * 60,
    },
  );

  return activationQuery;
}
