import React from 'react';
import { routePaths } from 'components/Routes/data';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PendingApproval } from './PendingApproval';
import { InitiatedByMe } from './InitiatedByMe';
import { Completed } from './Completed';

const EditPayroll = () => {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname === routePaths.approvalWorkflows.myRequests.editPayroll.root) {
    return <Redirect to={routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals} />;
  }

  return (
    <Switch>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals} exact>
        <PendingApproval />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe} exact>
        <InitiatedByMe />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.completed} exact>
        <Completed />
      </Route>
    </Switch>
  );
};

export { EditPayroll };
