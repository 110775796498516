import React, { ReactElement } from 'react';
import PageWrapper from '../../../../ui/PageWrapper';
import Breadcrumb from '../../../../ui/Breadcrumb';
import MainCase from '../../../../ui/MainCase';
import styles from '../index.module.scss';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FileTextIcon,
  Link,
  List,
  ListItem,
  ListItemText,
  MessageSquareIcon,
  Spinner,
  Text,
  Heading,
} from '@razorpay/blade/components';
import { useGetKnitHrmsIntegrationQuery } from 'components/Knit/KnitHrms/useGetKnitHrmsIntegrationQuery';
import { getNamesInSentence } from 'components/Knit/KnitHrms/utils';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';
import { hrmsAppIntroductionContent } from '../constants';
import { routePaths } from 'components/Routes/data';
import { KnitApps, KnitAppsMetadata } from 'components/Knit/constants';
import { LogoContainer } from '../styles';
import { FeaturePill } from '../components/FeaturePill';

const DarwinboxIntroduction = (): ReactElement => {
  const history = useHistory();
  const knitHrmsApp = KnitApps.darwinbox;
  const hrmsAppMetadata = KnitAppsMetadata[knitHrmsApp];
  const hrmsAppRoutePaths = routePaths.settings[knitHrmsApp];

  const {
    knitHrmsIntegrationQuery,
    isIntegrationActive,
    isAuthenticationComplete,
    retryLimitExhausted,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const hrmsAppIntroContent = hrmsAppIntroductionContent[knitHrmsApp];

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    // @TODO - Replace this with the skeleton loader once designs are ready
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner accessibilityLabel="loading" />
      </Box>
    );
  }

  if (knitHrmsIntegrationQuery.isSuccess && isIntegrationActive) {
    return <Redirect to={hrmsAppRoutePaths.manage} />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <ErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  const handleStartIntegrationClick = () => {
    history.push(hrmsAppRoutePaths.integration, {
      totalSteps: 5,
      currentStep: 1,
    });
  };

  const handleContinueIntegrationClick = () => {
    history.push(hrmsAppRoutePaths.settings.root, {
      totalSteps: 5,
      currentStep: 2,
    });
  };

  const primaryCtaText = isAuthenticationComplete ? 'Continue Integration' : 'Start Integration';

  const syncableFieldsInSentence = getNamesInSentence(
    knitHrmsIntegrationQuery.data?.available_fields.map(({ name }) => name) ?? [],
  );

  return (
    <PageWrapper>
      <Breadcrumb
        name={hrmsAppMetadata.name}
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase className={styles['knit-page-wrapper']} type="fullWidth">
        <Box maxWidth="1024px" display="flex" flexDirection="column" gap="spacing.10">
          <Box
            display="flex"
            gap="spacing.9"
            backgroundColor="surface.background.gray.moderate"
            borderRadius="medium"
            padding="spacing.8"
            width="100%">
            <LogoContainer>
              <img
                src={hrmsAppIntroContent.logo}
                width={97}
                height={97}
                alt={`${hrmsAppMetadata.name} Logo`}
              />
            </LogoContainer>
            <Box display="flex" flexDirection="column" gap="spacing.7">
              <Box display="flex" flexDirection="column" gap="spacing.3">
                <Heading size="large">{hrmsAppMetadata.name}</Heading>
                <Text color="surface.text.gray.muted">
                  Simplify your HR operations, retain talent, and build a high-performing workforce
                  while putting employee experience first.
                </Text>
              </Box>
              <Box display="flex" gap="spacing.5">
                {hrmsAppIntroContent.features.map((text) => (
                  <FeaturePill text={text} key={text} />
                ))}
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" maxWidth="675px">
              <Box display="flex" flexDirection="column" gap="spacing.5" paddingBottom="spacing.8">
                <Heading size="large">
                  Magic of RazorpayX Payroll and {hrmsAppMetadata.name} 🚀
                </Heading>
                <List>
                  <ListItem>
                    <ListItemText weight="semibold" color="surface.text.gray.subtle">
                      Automatic onboarding to XPayroll
                    </ListItemText>
                    <List variant="ordered">
                      <ListItem>
                        <ListItemText color="surface.text.gray.muted">
                          A new employee onboarded on {hrmsAppMetadata.name} gets automatically
                          onboarded in RazorpayX Payroll
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText color="surface.text.gray.muted">
                          Any changes to employee data get updated on XPayroll automatically
                        </ListItemText>
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem>
                    <ListItemText weight="semibold" color="surface.text.gray.subtle">
                      Attendance sync for accurate payroll calculation
                    </ListItemText>
                    <List variant="ordered">
                      <ListItem>
                        <ListItemText color="surface.text.gray.muted">
                          Sync employee unpaid leaves information directly to XPayroll
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText color="surface.text.gray.muted">
                          Sync employee leaves at the time of exit for leave encashments
                        </ListItemText>
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDirection="column"
                gap="spacing.5"
                padding={['spacing.8', '0px']}>
                <Heading size="large" color="surface.text.gray.subtle">
                  Data sync & sharing
                </Heading>
                <List>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Employee data will automatically sync at 1:30 AM IST everyday. You can do
                      manual sync as well.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      The following employee data can be synced from {hrmsAppMetadata.name}{' '}
                      {syncableFieldsInSentence}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Only active employees will sync from {hrmsAppMetadata.name} to XPayroll
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      All employees and contractors will have default employee and contractor role
                      types respectively in RazorpayX Payroll. You can assign different role once
                      integration is done.
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDirection="column"
                gap="spacing.5"
                padding={['spacing.8', '0px']}>
                <Heading size="large" color="surface.text.gray.subtle">
                  4 simple steps to integrate
                </Heading>
                <List>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Authorize {hrmsAppMetadata.name}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Preview or Edit employee fields to sync
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Complete employee type mapping.{' '}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText color="surface.text.gray.muted">
                      Complete employee status type mapping and you are done!!
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="spacing.11"
              padding={['0px', 'spacing.11']}>
              <Button
                variant="primary"
                onClick={
                  isAuthenticationComplete
                    ? handleContinueIntegrationClick
                    : handleStartIntegrationClick
                }>
                {primaryCtaText}
              </Button>
              <Box display="flex" flexDirection="column" gap="spacing.4">
                <Link icon={FileTextIcon} iconPosition="left" href="#">
                  FAQs
                </Link>
                <Link icon={MessageSquareIcon} iconPosition="left" href="#">
                  Contact support
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainCase>
    </PageWrapper>
  );
};

export { DarwinboxIntroduction };
