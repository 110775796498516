import { Alert, Box, Button, ProgressBar, Spinner, Text } from '@razorpay/blade/components';
import api from 'api';
import { CreateCustomReportPayload, GetCustomReportPayload } from 'api/customReports';
import { Table } from 'components/ApprovalWorkflows/MyRequests/components/Table';
import { Pagination } from 'components/ApprovalWorkflows/MyRequests/components/Table/Pagination';
import { Column } from 'components/ApprovalWorkflows/MyRequests/components/Table/types';
import NoResultsView from 'components/Fnf/ReleaseNetpay/NoResultsVIew';
import Breadcrumb from 'components/ui/Breadcrumb';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';
import PageLayout from 'components/ui/Layout/PageLayout';
import { useFilters } from 'hooks/useFilters';
import { useSearchParams } from 'hooks/useSearchParams';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GetCustomReportSchemaContract } from '../../schemas/GetCustomReportSchema';
import FilterTags from './FilterTags';
import ReportFilters from './ReportFilters';
import ReportInputs from './ReportInputs';
import { customReportStatus } from './constants';
import { GET_CUSTOM_REPORT_CONFIG } from './queries';
import { ReportFilter, ReportInput } from './types';
import useCustomReportData from './useCustomReportData';
import useFiltersAndInputs from './useFiltersAndInputs';
import { useTimeThreshold } from './useTimeThreshold';

const placeholderColumns: Column<ListItem>[] = [
  { title: 'Loading', render: () => <></> },
  { title: 'Loading', render: () => <></> },
];

type ListItem = {
  id: number;
  data: GetCustomReportSchemaContract['response']['rows'][0];
  isChecked?: boolean;
  allowAction?: boolean;
};

const PageWrapper = ({
  reportName,
  errorMessage,
  children,
  ...props
}: React.ComponentProps<typeof PageLayout> & {
  errorMessage?: string;
  reportName?: string;
}) => {
  return (
    <PageLayout
      hideHeading
      withoutBackgroundImg
      boxProps={{
        position: 'relative',
        width: 'initial',
        height: '100%',
        overflow: 'auto',
      }}
      containerProps={{
        height: 'auto',
      }}
      {...(reportName
        ? {
            breadcrumb: (
              <Box padding="spacing.7" backgroundColor="surface.background.gray.subtle">
                <Breadcrumb
                  listPadding="0px"
                  listPosition="relative"
                  name={reportName}
                  urlMaps={{
                    Reports: `/reports`,
                  }}
                  className="pt-0 pb-0"
                />
              </Box>
            ),
          }
        : {})}
      {...props}>
      {errorMessage ? (
        <ErrorWithRetry
          title={'Uh Oh, Some error occurred :('}
          description={<Box>{errorMessage}</Box>}
        />
      ) : null}
      {children}
    </PageLayout>
  );
};

export const CustomReport = () => {
  const [limit, onLimitChange] = useState(10);
  const [sortedColumns, setSortedColumns] = useState<Record<string, 'asc' | 'desc'>>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [page, onPageChange] = useState(1);
  const { urlParams } = useSearchParams();
  const type = urlParams.get('type');
  const [oldData, setOldData] = useState<GetCustomReportSchemaContract | null>(null);

  const { start, tick, hasBreachedThreshold, startTime } = useTimeThreshold({
    thresholdMs: 1000 * 60 * 5, // 5 minutes in milliseconds
  });

  const customReportConfigQuery = useQuery({
    queryFn: () => api.customReport.getCustomReportConfig(type),
    queryKey: [GET_CUSTOM_REPORT_CONFIG, type],
    onError: (err) => {
      console.log('Getting error while fetching the config', err);
    },
    onSuccess(data) {
      if (!data.isRealTime) {
        start();
      }
      const filters = (data?.filters ?? []) as ReportFilter[];
      applyFilters(filters.map((filter) => ({ filter: filter.key, value: filter.defaultValue })));
    },
    enabled: !!type,
  });

  const sortedKeys = Object.keys(sortedColumns);
  const sortByKey = sortedKeys.length ? sortedKeys[0] : null;
  const sortByOrder = sortByKey ? sortedColumns[sortByKey] : null;

  const isRealTime = customReportConfigQuery.data?.isRealTime ?? false;
  const inputs = (customReportConfigQuery.data?.inputs ?? []) as ReportInput[];
  const filters = (customReportConfigQuery.data?.filters ?? []) as ReportFilter[];

  const { filtersLabelMap, filtersFieldsMap, inputsFieldsMap } = useFiltersAndInputs({
    inputs,
    filters,
  });

  const {
    applyFilter: applyInput,
    removeFilter: removeInput,
    currentFilters: currentInputs,
  } = useFilters({
    filtersMap: inputsFieldsMap,
  });

  const { applyFilter, removeFilter, applyFilters, currentFilters } = useFilters({
    filtersMap: filtersFieldsMap,
  });

  const createReportMutation = useMutation({
    mutationFn: () => {
      onPageChange(1);
      return api.customReport.createCustomReport({
        type,
        inputs: inputs.reduce(
          (acc, input) => ({ ...acc, [input.key]: currentInputs[input.key] || input.defaultValue }),
          {},
        ) as CreateCustomReportPayload['inputs'],
      });
    },
    onMutate: () => start(),
    onError: (err) => {
      console.log('Getting error while creating report', err);
      setErrorMessage(
        'Cannot generate report. Please try again. If issue persists, contact customer support.',
      );
    },
  });

  const customReportId = createReportMutation.data?.customReportId ?? null;

  const customReportQuery = useCustomReportData({
    customReportId,
    isRealTime,
    isConfigFetched: customReportConfigQuery.isFetched,
    type,
    filters: filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.key]: currentFilters[filter.key],
      }),
      {},
    ) as GetCustomReportPayload['filters'],
    onError() {
      setErrorMessage(
        'Cannot generate the report. Please try again. If issue persists, contact customer support.',
      );
    },
    onTick(data) {
      if (data?.status !== customReportStatus.STATUS_SUCCEEDED && !isRealTime) {
        tick();
      }
    },
    onSuccess(data) {
      setOldData(data);
    },
    sortByKey,
    sortByOrder,
    limit,
    page,
  });

  const isCreatingReport = createReportMutation.isLoading;
  const isReportStatusStarted =
    customReportQuery.data?.status === customReportStatus.STATUS_STARTED;

  if (
    isCreatingReport ||
    isReportStatusStarted ||
    (customReportId && oldData?.reportId !== customReportId)
  ) {
    return (
      <PageWrapper reportName={customReportConfigQuery.data?.name}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="spacing.7"
          paddingY="spacing.10">
          <Spinner accessibilityLabel="Generating Report" size="large" />
          <Box display="flex" flexDirection="column" gap="spacing.7" alignItems="center">
            <Box width="200px">
              <ProgressBar
                label="Generating Report"
                value={customReportQuery.data?.progress ?? 0}
                size="medium"
                color="information"
              />
            </Box>
            {hasBreachedThreshold && (
              <Alert
                isDismissible={false}
                color="information"
                description="Your report is in the queue and will begin processing shortly. Due to high demand,
                this may take a few minutes"
              />
            )}
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  const data = oldData;

  const columns: Column<ListItem>[] =
    oldData?.response?.columns?.map((column, index) => {
      return {
        title: column.name,
        key: column.key,
        render: (item: any, index: any) => {
          return (
            <Text color="interactive.text.gray.normal">{item.data?.[index]?.value || ''}</Text>
          );
        },
        isSortable: true,
        styles: {
          width: '150px',
          minWidth: '150px',
          textAlign: index > 0 ? 'right' : 'left',
        },
      };
    }) || [];

  const tableData = oldData?.response?.rows?.map((row, index) => {
    return { id: index, data: row, isChecked: false, allowAction: true };
  });

  const tableTopContent = data?.response?.rows?.length ? (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      minHeight="68px"
      padding={['spacing.5', 'spacing.8']}>
      <Box display="flex" gap="spacing.5">
        <Text weight="semibold" color="surface.text.gray.muted">
          Showing {(page - 1) * limit + 1}-{Math.min(page * limit, data.response.totalRows)} of{' '}
          {data.response.totalRows}
        </Text>
      </Box>
      {!isRealTime && data && data.preparedAt && (
        <Box>
          <Text variant="caption" size="small">
            Last generated at:{' '}
            {data.preparedAt.split(' ')[0] + ' | ' + data.preparedAt.split(' ')[1]}
          </Text>
        </Box>
      )}
    </Box>
  ) : null;

  const errorView = (
    <Box display="grid" placeItems="center" height="100%">
      <NoResultsView
        title="Uh Oh, some error occurred :("
        description="Could not generate the report, Please try again. If issue persists, please contact support"
        actions={<Button onClick={() => createReportMutation.mutate()}>Retry</Button>}
      />
    </Box>
  );

  const applyInputValue = (key: string, value: string | string[] | null) => {
    if (!value) {
      removeInput(key);
    } else {
      applyInput(key, value);
    }
  };

  const applyFilterValue = (key: string, value: string | string[] | null) => {
    if (!value) {
      removeFilter(key);
    } else {
      applyFilter(key, value);
    }
  };

  return (
    <PageWrapper
      isLoading={customReportConfigQuery.isLoading}
      isError={
        customReportConfigQuery.isError ||
        customReportQuery.data?.status === customReportStatus.STATUS_FAILED
      }
      errorView={errorView}
      errorMessage={errorMessage}
      reportName={customReportConfigQuery.data?.name}>
      <ReportInputs
        isLoading={customReportConfigQuery.isLoading || customReportQuery.isLoading}
        inputs={inputs as ReportInput[]}
        onChange={applyInputValue}
        currentInputs={currentInputs}
        centerLayout={
          !isRealTime ? (
            <Button
              variant="secondary"
              isDisabled={customReportQuery.isLoading || isCreatingReport}
              onClick={() => createReportMutation.mutate()}>
              Generate New
            </Button>
          ) : null
        }
        rightSideContent={
          <ReportFilters
            key={JSON.stringify(currentFilters)}
            isLoading={customReportConfigQuery.isLoading || customReportQuery.isLoading}
            currentFilters={currentFilters}
            onApplyFilters={applyFilters}
            onChange={applyFilterValue}
            filters={filters as ReportFilter[]}
          />
        }
      />
      <FilterTags filtersMap={filtersFieldsMap} filtersLabelMap={filtersLabelMap} />
      <Box
        marginTop="spacing.7"
        borderBottomWidth="thin"
        borderTopWidth="thin"
        borderBottomColor="surface.border.gray.subtle"
        borderTopColor="surface.border.gray.subtle">
        <Box>{tableTopContent}</Box>
        {customReportQuery.isLoading && (
          <Box>
            <ProgressBar isIndeterminate />
          </Box>
        )}
      </Box>
      <Box>
        <Table
          id="table-custom-report"
          layout="auto"
          columns={columns.length ? columns : placeholderColumns}
          data={tableData || []}
          sortedColumns={sortedColumns}
          onSortChange={(sortKey, operation) =>
            setSortedColumns({
              [sortKey]: operation,
            })
          }
          isError={
            customReportQuery.isError ||
            customReportQuery.data?.status === customReportStatus.STATUS_FAILED
          }
          isLoading={customReportConfigQuery.isLoading || !oldData}
          isFetching={customReportQuery.isLoading}
          noResultsView={
            !Boolean(customReportQuery.data?.preparedAt) ? (
              <Box paddingY="spacing.10">
                <NoResultsView
                  boxProps={{
                    maxWidth: '400px',
                    margin: 'auto',
                  }}
                  title="No report generated yet"
                  description={
                    "To generate a report, please select the required options from the dropdown and click on the 'Generate New' button"
                  }
                />
              </Box>
            ) : (
              <Box paddingY="spacing.10">
                <NoResultsView
                  title="No results found"
                  description={isRealTime ? 'Automatically refreshing report for new data' : ''}
                  actions={
                    isRealTime ? null : (
                      <Button
                        onClick={() => customReportQuery.refetch()}
                        isLoading={customReportQuery.isLoading}>
                        Refresh
                      </Button>
                    )
                  }
                />
              </Box>
            )
          }
          errorResultsView={
            <Box paddingY="spacing.10">
              <NoResultsView
                title="Uh Oh, some error occurred :("
                description={
                  'Could not generate the report, Please try again. If issue persists, please contact support'
                }
                actions={
                  <Button
                    onClick={() => customReportQuery.refetch()}
                    isLoading={customReportQuery.isLoading}>
                    Retry
                  </Button>
                }
              />
            </Box>
          }
        />
      </Box>

      <Pagination
        currentPage={page}
        pageSizeOptions={data?.response?.limitOptions ?? [10, 20, 50]}
        totalItems={data?.response?.totalRows ?? 1}
        itemsPerPage={limit ?? 10}
        onCurrentPageChange={onPageChange}
        onItemsPerPageChange={(value) => {
          onLimitChange(value);
          onPageChange(1);
        }}
        isDisabled={!oldData}
        leftContainer={
          <Box>
            <Button
              isDisabled={customReportQuery.isLoading || !data?.reportId}
              onClick={() => {
                window.open('/v2/custom-report/download?type=' + type, '_blank');
              }}>
              Download CSV
            </Button>
          </Box>
        }
      />
    </PageWrapper>
  );
};
