import React from 'react';
import { ProfessionalTaxDetailsView } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/ProfessionalTaxDetailsView';
import { useComplianceActionsContext } from '../contexts/ComplianceActionsContext';
import { complianceActionsMap } from '../data';

export const ActionDetailsView = () => {
  const complianceActionContextData = useComplianceActionsContext();
  const selectedAction = complianceActionContextData.selectedComplianceAction.actionName;

  if (selectedAction === complianceActionsMap.payPt) {
    return <ProfessionalTaxDetailsView state="Karnataka" />;
  }

  return null;
};
