import { useToast } from '@razorpay/blade/components';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import api from 'api';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import ExpansionPanel from 'components/ui/Accordion/ExpansionPanel';
import { ExpansionList } from 'components/ui/Accordion/ExpansionPanel/ExpansionList';
import useExpansionPanel from 'components/ui/Accordion/ExpansionPanel/useExpansionPanel';
import { WarningAlert } from 'components/ui/Alert';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import Modal from 'components/ui/Modals/components/Modal';
import { getDaysInMonth } from 'date-fns';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { trackXPayrollEvent } from 'utils/analytics';
import { DECIMAL_REGEX } from 'utils/regexes';
import loggedInUserSelectors from '../../../reducers/loggedInUser/selectors';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { DeductionComponentV2, RunPayrollData } from '../utils/types';
import useNewJoinerArrearEnabled from '../utils/useNewJoinerArrearEnabled';
import { PayrollBulkActions, Remarks } from './EditSalary/components';
import AdditionsV2 from './EditSalary/components/AdditionsV2';
import DeductionsV2 from './EditSalary/components/DeductionsV2';
import useAdditionsDeductionsLop from './EditSalary/hooks/useAdditionsDeductionsLop';
import { convertDataToUpdateAdditionsDeductionsPayload } from './EditSalary/utils';
import styles from './index.module.scss';
import queryKeys from './EditSalary/components/queries';

// this is the Edit Salary component made for Rearch M2, it won't contain arrears for now
const EditSalaryV2 = ({
  payrollEntry,
  onError,
  payrollMonth,
}: { payrollEntry: RunPayrollData; payrollMonth: string } & SetErrorMessageType) => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { newJoinerArrearPaymentMonthExists, newJoinerArrearExists } =
    useNewJoinerArrearEnabled(payrollEntry);
  const expansionPanel = useExpansionPanel(newJoinerArrearPaymentMonthExists ? 2 : 3);

  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const toast = useToast();
  const doesEditNeedApproval = actionsWithActiveWorkflows.includes(WorkflowTypesMap.EDIT_PAYROLL);

  const {
    allowances,
    onAllowanceChange,
    onAllowanceAdd,
    onAllowanceDelete,
    isAdditionsDeductionsListLoading,
    deductions,
    setDeductions,
    errorMessage,
    updateAdditionsDeductionsLop,
  } = useAdditionsDeductionsLop({
    payrollEntry,
    payrollMonth,
    onUpdateSuccess: () => {
      queryClient.invalidateQueries([FETCH_RUNPAYROLL_DATA]);
      closeModal();
      toast.show({
        id: String(payrollEntry.id),
        content: 'Payroll data updated successfully',
        color: 'positive',
      });
    },
  });

  const setNonLOPDeductions = (nonLOPDeductions: DeductionComponentV2['non_lop_deductions']) => {
    setDeductions({
      ...deductions,
      non_lop_deductions: nonLOPDeductions,
    });
  };

  const [remarks, setRemarks] = useState(payrollEntry.remarks);

  const skip = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runpayrollMutations.bulkSkipPayroll({
        payroll_month: payrollMonth,
        ids: [payrollId],
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const skipAllExceptThis = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runpayrollMutations.skipAllExceptThis({
        payroll_month: payrollMonth,
        payroll_id: payrollId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const onLOPDaysChange = (value: string) => {
    if (!value.match(DECIMAL_REGEX)) {
      return;
    }

    const daysInCurrentMonth = getDaysInMonth(new Date(payrollMonth));

    if (parseFloat(value) > daysInCurrentMonth) {
      return;
    }

    setDeductions({
      ...deductions,
      lop_deduction: {
        ...deductions.lop_deduction,
        lop_days: value ?? '0',
      },
    });
  };

  const isActionLoading =
    skip.isLoading || skipAllExceptThis.isLoading || isAdditionsDeductionsListLoading;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    trackXPayrollEvent(`edit_salary.DONE`);

    updateAdditionsDeductionsLop.mutate(
      convertDataToUpdateAdditionsDeductionsPayload({
        allowances,
        deductions,
        userId: String(payrollEntry.peopleId),
        payrollMonth,
      }),
    );
  };

  const onClose = () => {
    queryClient.invalidateQueries([queryKeys.QUERY_LIST_ADDITIONS_DEDUCTIONS_LOP]);
  };

  return (
    <Modal onClose={onClose}>
      <div className="max-h-screen overflow-y-auto">
        <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
          <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
            Edit Salary
          </div>
          {errorMessage && (
            <WarningAlert>
              <span>{errorMessage}</span>
            </WarningAlert>
          )}
          <form className={`${styles['run-payroll-modal-content']}`} onSubmit={onSubmit}>
            <ExpansionList className="mb-16">
              {!newJoinerArrearPaymentMonthExists && (
                <ExpansionPanel
                  {...expansionPanel.panelProps[0]}
                  onHeaderClick={expansionPanel.onHeaderClick}
                  header={'Addition'}
                  scrollable={true}
                  childrenClassName={styles['run-payroll-addition-expansion-child']}>
                  <AdditionsV2
                    allowances={allowances}
                    onAllowanceChange={onAllowanceChange}
                    payrollEntry={payrollEntry}
                    payrollMonth={payrollMonth}
                    onAllowanceAdd={onAllowanceAdd}
                    onAllowanceDelete={onAllowanceDelete}
                  />
                </ExpansionPanel>
              )}
              <IsFeatureDisabledForRearch feature={['isDeductionsDisabled']}>
                <ExpansionPanel
                  {...expansionPanel.panelProps[newJoinerArrearPaymentMonthExists ? 0 : 1]}
                  onHeaderClick={expansionPanel.onHeaderClick}
                  header={'Deduction'}
                  scrollable={true}
                  className="pt-0"
                  childrenClassName={styles['run-payroll-deduction-expansion-child']}>
                  <DeductionsV2
                    deductions={deductions}
                    onLOPDaysChange={onLOPDaysChange}
                    payrollEntry={payrollEntry}
                    setNonLOPDeductions={setNonLOPDeductions}
                    payrollMonth={payrollMonth}
                  />
                </ExpansionPanel>
              </IsFeatureDisabledForRearch>
              <IsFeatureDisabledForRearch feature={['isMileStone1Enabled', 'isM2S1Enabled']}>
                <ExpansionPanel
                  {...expansionPanel.panelProps[newJoinerArrearPaymentMonthExists ? 1 : 2]}
                  onHeaderClick={expansionPanel.onHeaderClick}
                  header={'Remark'}>
                  <Remarks remarks={remarks} onRemarkChange={(value) => setRemarks(value)} />
                </ExpansionPanel>
              </IsFeatureDisabledForRearch>
            </ExpansionList>
            <div className="flex gap-[8px]">
              <StandardButton
                isPending={updateAdditionsDeductionsLop.isLoading}
                disabled={isActionLoading}
                type={'submit'}
                className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                {doesEditNeedApproval ? 'Request For Approval' : 'Done'}
              </StandardButton>
              {!newJoinerArrearPaymentMonthExists && (
                <>
                  <StandardSecondaryButton
                    onClick={() => {
                      trackXPayrollEvent(`edit_salary.skip_this`);
                      skip.mutate({ payrollId: payrollEntry.id });
                    }}
                    isPending={skip.isLoading}
                    disabled={isActionLoading}
                    className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                    Skip
                  </StandardSecondaryButton>
                  <StandardSecondaryButton
                    onClick={() => {
                      trackXPayrollEvent(`edit_salary.skip_all_except_this`);
                      skipAllExceptThis.mutate({ payrollId: payrollEntry.id });
                    }}
                    isPending={skipAllExceptThis.isLoading}
                    disabled={isActionLoading}
                    className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                    Skip All Except This
                  </StandardSecondaryButton>
                </>
              )}
            </div>
          </form>
        </div>
        <PayrollBulkActions />
      </div>
    </Modal>
  );
};

export default EditSalaryV2;
