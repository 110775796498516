import ExternalLink from "components/ui/ExternalLink";
import React, { useEffect, useState } from "react";
import { getStaticMediaUrl } from "utils/Urls";
import insuranceRenewalIntro from "assets/insurance/insuranceRenewalIntro.png";
import { Route } from "react-router-dom";
import { SecondaryButton } from "components/ui/Button";
import { routePaths } from "components/Routes/data";
import { IInsurance } from "reducers/insurance";
import api from "api";

export function InsuranceRenewal({
  insurance
}: {
  insurance: IInsurance
}){
  return (
    <>
      <div className="font-heading text-5xl">Insurance</div>
      <div className="flex flex-col gap-4 mt-20">
        <img className="mx-auto" src={getStaticMediaUrl(insuranceRenewalIntro)} alt="Insurance Renewal"/>
        <div className="text-4xl p-15 mt-5 font-bold text-center">Insurance is expired for your organization.</div>
        { insurance?.data?.isInsuranceRenewalEndorsementAllowed 
          && <Route
            render={({ history }) => (
                <SecondaryButton
                    style={{width: 'fit-content',
                      margin: '1em auto 5em'
                    }}
                    onClick={() => {
                        history.push(routePaths.insurance.admin.organizationAndEmployeeRenewal);
                    }}
                >
                    Renew insurance
                </SecondaryButton>
            )}
          />
        }

        <div className="text-center">Please contact {' '}
          <ExternalLink className="text-primary" href="mailto:xpayroll@razorpay.com">
            xpayroll@razorpay.com
          </ExternalLink>{' '} or chat with us to renew expired insurances.
        </div>
      </div>
    </>
  );
}