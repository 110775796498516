function isDocumentElement(el: HTMLElement | typeof window): el is typeof window {
  return [document.documentElement, document.body, window].indexOf(el) > -1;
}

function scrollTo(el: HTMLElement | typeof window, top: number): void {
  // with a scroll distance, we perform scroll on the element
  if (isDocumentElement(el)) {
    window.scrollTo(0, top);
    return;
  }

  el.scrollTop = top;
}

export function scrollIntoView(wrapper: HTMLElement, item: HTMLElement): void {
  const wrapperRect = wrapper.getBoundingClientRect();
  const itemRect = item.getBoundingClientRect();
  const overScroll = item.offsetHeight / 3;

  if (itemRect.bottom + overScroll > wrapperRect.bottom) {
    scrollTo(
      wrapper,
      Math.min(
        item.offsetTop + item.clientHeight - wrapper.offsetHeight + overScroll,
        wrapper.scrollHeight,
      ),
    );
  } else if (itemRect.top - overScroll < wrapperRect.top) {
    scrollTo(wrapper, Math.max(item.offsetTop - overScroll, 0));
  }
}
