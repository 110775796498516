import React from 'react';
import { Box, Text } from '@razorpay/blade/components';

export const LabelValuePair = ({ label, value }: { label: string; value: React.ReactNode }) => {
  return (
    <Box display="flex" flexDirection="column" gap="spacing.1">
      <Text size="small" color="surface.text.gray.muted">
        {label}
      </Text>
      {typeof value === 'string' ? <Text color="surface.text.gray.subtle">{value}</Text> : value}
    </Box>
  );
};
