import React, { ReactElement } from 'react';

import { Table } from 'components/ui/Table';
import { FlexiDetailsProps } from '../../utils/init';
import { toIndianCurrencyString } from 'utils/Numbers';
import WalletsInfo from './WalletsInfo';

import styles from "./../index.module.scss";
import Spinner from 'components/ui/Spinner';

const FlexiWallets = ({
    flexiDetails, 
    setFlexiDetails,
}: FlexiDetailsProps): ReactElement => {
    const wallets = flexiDetails.walletBudgets;
    const allWalletIds = [...flexiDetails.walletBudgets.map(wallet => wallet.id.toString())];

    const updateWallets = (enabledWallets: string[]) => {
        setFlexiDetails({...flexiDetails, enabledWallets: enabledWallets})
    }
    const enabledWallets = flexiDetails.enabledWallets;

    const handleWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedWallet = String(event.target.getAttribute("data-wallet-id"));
        if(enabledWallets.includes(selectedWallet)) {
            updateWallets(enabledWallets.filter(wallet => wallet != selectedWallet));
        } else {
            updateWallets([...enabledWallets, selectedWallet]);
        }
    }

    const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateWallets(event.target.checked ? allWalletIds: []);
    }

    const amountAggregate = wallets
        .filter(wallet => flexiDetails.enabledWallets.includes(wallet.id.toString()))
        .map((wallet => wallet.maxCompliance.max ?? 0))
        .reduce((prev, next) => prev+next , 0);

    if(!wallets?.length) {
       return (<div className={'flex justify-center'}>
            <Spinner size={40}/>
        </div>)
    }

    return (
        <>
            <WalletsInfo />
            <Table className="w-full mt-16 mb-20">
                <tbody>
                    <tr>
                        <th>
                            <input onChange={selectAll} 
                                type="checkbox" 
                                id="flexi-wallets-select"
                                checked={[...allWalletIds].sort().join(',') 
                                    === [...enabledWallets].sort().join(',')}/>
                        </th>
                        <th className={styles['wallet-name-header']}>Flexible wallet</th>
                        <th className={styles["wallet-amount-header"]}>Min. limit per month</th>
                        <th className={styles["wallet-amount-header"]}>Max. limit per month</th>
                    </tr>
                    {
                        wallets?.map((wallet) => {
                            const isEnabled = enabledWallets?.includes(wallet?.id.toString())
                            return (
                                <tr key={wallet.id}>
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            data-wallet-id={wallet.id}
                                            onChange={handleWalletChange}
                                            id={`flexi-wallets-select-${wallet.id}`} 
                                            checked={isEnabled}/>
                                    </td>
                                    <td className={styles[`wallet-name${isEnabled ? "":"-unchecked"}`]}>
                                        {wallet?.wallet?.name || ""}
                                    </td>
                                    <td className={styles[`wallet-amount${isEnabled ? "":"-unchecked"}`]}>
                                        {toIndianCurrencyString(wallet?.maxCompliance?.min ?? 0, true)}
                                    </td>
                                    <td className={styles[`wallet-amount${isEnabled ? "":"-unchecked"}`]}>
                                        {toIndianCurrencyString(wallet?.maxCompliance?.max ?? 0, true)}
                                    </td>
                                </tr>)

                        })
                    }
                    <tr className={styles["wallet-footer"]}>
                        <td></td>
                        <td className={styles["wallet-name"]}>Monthly Total</td>
                        <td></td>
                        <td className={styles["wallet-amount"]}>
                            {toIndianCurrencyString(amountAggregate, true)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default FlexiWallets;