import { useMemo, useReducer } from 'react';
import { formReducer } from './formReducer';
import { FormContextValue, ValidationFunction, ValidationSchema } from './types';

export function useFormState<T>(initialState?: T) {
  const [state, dispatch] = useReducer(formReducer<T>, {
    formData: initialState ?? {} as T,
    errors: {},
    isSubmitting: false,
    isValidating: false,
  });

  const actions = useMemo(
    () => ({
      setFormData: (data: Partial<T>) => {
        dispatch({ type: 'SET_FORM_DATA', payload: data });
      },
      setFieldValue: (field: keyof T, value: any) => {
        dispatch({ type: 'SET_FIELD_VALUE', payload: { field, value } });
      },
      clearFieldValue: (field: keyof T) => {
        dispatch({ type: 'CLEAR_FIELD_VALUE', payload: field });
      },
      setFieldError: (field: keyof T, error: string) => {
        dispatch({ type: 'SET_FIELD_ERROR', payload: { field, error } });
      },
      clearFieldError: (field: keyof T) => {
        dispatch({ type: 'CLEAR_FIELD_ERROR', payload: field });
      },
      clearAllErrors: () => {
        dispatch({ type: 'CLEAR_ALL_ERRORS' });
      },
      clearFormData: () => {
        dispatch({ type: 'CLEAR_FORM_DATA' });
      },
      setIsSubmitting: (payload: boolean) => {
        dispatch({ type: 'SET_IS_SUBMITTING', payload });
      },
      setIsValidating: (payload: boolean) => {
        dispatch({ type: 'SET_IS_VALIDATING', payload });
      },
      validate: (validationSchema: ValidationSchema<T>, options?: { sideEffects: boolean }) => {
        if (!validationSchema) return true;

        let isStepValid = true;
        const isSideEffectsDisabled = options?.sideEffects === false;
        (Object.entries(validationSchema) as Array<[keyof T, ValidationFunction<T>]>).forEach(
          ([field, validator]) => {
            if (validator) {
              const validationResult = validator(state.formData);
              if (validationResult) {
                if (!isSideEffectsDisabled) {
                  dispatch({
                    type: 'SET_FIELD_ERROR',
                    payload: { field, error: validationResult },
                  });
                }
                isStepValid = false;
              } else {
                if (!isSideEffectsDisabled) {
                  dispatch({ type: 'CLEAR_FIELD_ERROR', payload: field });
                }
              }
            }
          },
        );
        return isStepValid;
      },
    }),
    [state.formData],
  );

  return { ...state, ...actions } as FormContextValue<T>;
}