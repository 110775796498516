import React from 'react';
import {
  AlertTriangleIcon,
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { compliancePaymentModalsZIndex } from 'components/RunPayroll/ComplianceActions/components/data';
import {
  bankAccountUnavailableModalDetails,
  genericErrorModalDetails,
  initiatePtErrorCodes,
  missingCredentialsModalDetails,
} from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/data';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { ResetLocalOverridingStyles } from 'components/RunPayroll/ComplianceActions/styles';

type PTPaymentErrorModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  reInitiatePayment: () => void;
  errorCode: string;
};

export const PTPaymentErrorModal = (props: PTPaymentErrorModalProps) => {
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth || '';
  const { isOpen, onDismiss, errorCode, reInitiatePayment } = props;

  const getErrorDetails = () => {
    if (errorCode === initiatePtErrorCodes.bankUnavailable) {
      return bankAccountUnavailableModalDetails({ onDismiss });
    }
    if (errorCode === initiatePtErrorCodes.missingCreds) {
      return missingCredentialsModalDetails({ onDismiss, payrollMonth });
    }
    return genericErrorModalDetails({ onDismiss, reInitiatePayment });
  };

  const { heading, description, footerActions } = getErrorDetails();

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} zIndex={compliancePaymentModalsZIndex}>
      <ModalHeader title="" />
      <ModalBody>
        <ResetLocalOverridingStyles>
          <Box
            display="flex"
            flexDirection="column"
            gap="spacing.3"
            alignItems="center"
            paddingTop="spacing.6"
            paddingBottom="spacing.7">
            <AlertTriangleIcon color="feedback.icon.notice.intense" size="xlarge" />
            <Box display="flex" flexDirection="column" gap="spacing.1" alignItems="center">
              <Heading size="medium" color="surface.text.gray.normal" weight="semibold">
                {heading.title}
              </Heading>
              {heading.subTitle ? (
                <Text size="small" color="surface.text.gray.subtle">
                  {heading.subTitle}
                </Text>
              ) : null}
            </Box>
          </Box>
          <Box
            borderRadius="large"
            padding="spacing.5"
            display="flex"
            flexDirection="column"
            backgroundColor="surface.background.gray.subtle"
            gap="spacing.6">
            <Box display="flex" flexDirection="column" gap="spacing.2">
              <Heading color="surface.text.gray.normal" size="small" weight="semibold">
                {description.title}
              </Heading>
              {description.subtitle ? (
                <Text color="surface.text.gray.muted">{description.subtitle}</Text>
              ) : null}
            </Box>
            {description.actions}
          </Box>
        </ResetLocalOverridingStyles>
      </ModalBody>
      <ModalFooter>
        <ResetLocalOverridingStyles>
          <Box display="flex" justifyContent="flex-end" gap="spacing.5">
            {footerActions}
          </Box>
        </ResetLocalOverridingStyles>
      </ModalFooter>
    </Modal>
  );
};
