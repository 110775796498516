import { routePaths } from 'components/Routes/data';
import { WorkflowTypesMap } from '../constants';

export const WorkflowPathnamesMetadata = {
  [routePaths.approvalWorkflows.workflow.editPayroll]: {
    type: WorkflowTypesMap.EDIT_PAYROLL,
    title: 'Workflows | Edit Payroll',
    label: 'Edit Payroll',
  },
  [routePaths.approvalWorkflows.workflow.finalizePayroll]: {
    type: WorkflowTypesMap.FINALIZE_PAYROLL,
    title: 'Workflows | Finalize Payroll',
    label: 'Finalize Payroll',
  },
  [routePaths.approvalWorkflows.workflow.salaryRevision]: {
    type: WorkflowTypesMap.SALARY_REVISION,
    title: 'Workflows | Salary Revision',
    label: 'Salary Revision',
  },
} as const;

export const WorkflowPathRouteMap = {
  [WorkflowTypesMap.EDIT_PAYROLL]: routePaths.approvalWorkflows.workflow.editPayroll,
  [WorkflowTypesMap.FINALIZE_PAYROLL]: routePaths.approvalWorkflows.workflow.finalizePayroll,
  [WorkflowTypesMap.SALARY_REVISION]: routePaths.approvalWorkflows.workflow.salaryRevision
} as const;

export const WorkflowQueryKeys = {
  getAllWorkflows: 'getAllWorkflows',
  pendingWorkflowRequests: 'pendingWorkflowRequests',
};
