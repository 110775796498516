import {
    ActionList,
    ActionListItem,
    Box,
    CalendarIcon,
    Dropdown,
    DropdownOverlay,
    SelectInput,
    Text,
    Tooltip,
    TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import React from 'react';
import { DeclarationErrors } from './types';
import { daysKeyValueList } from './constants';

interface CustomRangeSelectorProp {
    fieldName: string;
    titleText?: string;
    startText?: string;
    endText?: string;
    startDate: number;
    endDate: number;
    startDateList?: {
        key: string;
        title: string;
        value: string;
    }[];
    endDateList?: {
        key: string;
        title: string;
        value: string;
    }[];
    isDisabled?: boolean;
    disableStartDate?: boolean;
    disableEndDate?: boolean;
    descriptionText?: string;
    showTooltip?: boolean;
    toolTipText?: string;
    onChange: (
        value: {
            startDate: number,
            endDate: number
        },
        name: string,
        isStart?: boolean
    ) => void;
    isLoading: boolean;
    errors?: DeclarationErrors;
}

export const CustomRangeSelectorCard = ({
    fieldName,
    titleText,
    startText,
    endText,
    endDate = 0,
    startDate = 0,
    onChange,
    errors = {},
    startDateList = daysKeyValueList,
    endDateList = daysKeyValueList,
    descriptionText,
    isDisabled,
    disableStartDate,
    disableEndDate,
    showTooltip,
    toolTipText
}: CustomRangeSelectorProp) => {


    const component =
        <Box
            borderColor="surface.border.gray.subtle"
            borderWidth="thin"
            borderRadius="medium"
            display="flex"
            flexDirection="column"
            gap="spacing.6"
            padding={['spacing.5', 'spacing.5', 'spacing.6']}
            backgroundColor="surface.background.gray.moderate">
            <Box
                borderBottomColor="surface.border.gray.subtle"
                borderBottomWidth="thin"
                paddingBottom={'spacing.4'}
                gap="spacing.3"
                display="flex"
                alignItems="center">
                <Box
                    backgroundColor="surface.background.gray.intense"
                    display="grid"
                    borderRadius="medium"
                    placeItems="center"
                    width="30px"
                    height="30px">
                    <CalendarIcon
                        margin="auto"
                        color="interactive.icon.information.subtle"
                        size="medium"
                    />
                </Box>
                <Box flex="1">
                    <Text>
                        {titleText ?? 'Select the date range for the month'}
                    </Text>
                </Box>
            </Box>
            <Box maxWidth="340px">
                <Dropdown selectionType="single">
                    <SelectInput
                        isDisabled={isDisabled || disableStartDate}
                        labelPosition="left"
                        isRequired
                        value={startDate.toString().padStart(2, '0')}
                        label={startText ?? "Month start date"}
                        placeholder="Select date"
                        onChange={({ name, values }) => onChange(
                            { startDate: parseInt(values[0]), endDate: endDate },
                            fieldName
                        )}
                        validationState={errors.startDate ? 'error' : 'none'}
                        errorText={errors.startDate || ''}
                    />
                    <DropdownOverlay>
                        <ActionList>
                            {startDateList.map(({ key, title, value }) => (
                                <ActionListItem
                                    key={key}
                                    title={title}
                                    value={value}
                                />
                            ))}
                        </ActionList>
                    </DropdownOverlay>
                </Dropdown>
                <Dropdown selectionType="single" marginTop="spacing.5">
                    <SelectInput
                        isDisabled={isDisabled || disableEndDate}
                        labelPosition="left"
                        isRequired
                        value={endDate.toString().padStart(2, '0')}
                        label={endText ?? "Month end date"}
                        placeholder="Select date"
                        onChange={({ name, values }) => onChange(
                            { startDate: startDate, endDate: parseInt(values[0]) },
                            fieldName
                        )}
                        validationState={errors.endDate ? 'error' : 'none'}
                        errorText={errors.endDate || ''}
                    />
                    <DropdownOverlay>
                        <ActionList>
                            {
                                endDateList.map(({ key, title, value }) => (
                                    <ActionListItem
                                        key={key}
                                        title={title}
                                        value={value}
                                    />
                                ))}
                        </ActionList>
                    </DropdownOverlay>
                </Dropdown>
            </Box>
            {(
                <Text variant={'caption'} color={'interactive.text.positive.subtle'}>
                    {descriptionText}
                </Text>
            )}
        </Box>;

    if (showTooltip && toolTipText) {
        return (
            <Tooltip content={toolTipText}>
                <TooltipInteractiveWrapper>
                    {component}
                </TooltipInteractiveWrapper>
            </Tooltip>
        );
    }
    else {
        return component
    }

};
