import api from 'api';
import { useQuery, UseBaseQueryOptions } from 'react-query';
import { FETCH_RX_MERCHANTS } from '../queries';
import { AppError } from 'utils/AppError';
import { CACHE_TTL } from 'utils/cache';

const useRxMerchants = (options: Pick<UseBaseQueryOptions, 'enabled'>) => {
  const rxMerchantsQuery = useQuery({
    queryFn: api.rxIntegration.getMerchantDetails,
    queryKey: FETCH_RX_MERCHANTS,
    onError: (error: typeof AppError) => {},
    staleTime: CACHE_TTL.LARGE, // 5 mins, merchant data of X isn't going to change
    enabled: options.enabled,
  });

  return {
    rxMerchantsQuery,
  };
};

export default useRxMerchants;
