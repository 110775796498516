import { routePaths } from 'components/Routes/data';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import { AnchorCard } from 'components/ui/SidebarCard';
import permissions from 'constants/permissions';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import EmployeeAdvanceSalary from './EmployeeAdvanceSalary';
import EmployeeAnnualCompensation from './EmployeeAnnualCompensation';

const EmployeeOnboarding = () => {
  const urlParams = useParams<{ employeeId? : string ,pageView?: string}>();
  const employeeId = urlParams?.employeeId ? +urlParams?.employeeId : null;
  
  const permission = useSelector(loggedInUserSelectors.permissions);
  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
  const hasBulkUploadPermission = permission.includes(permissions.BULK_SALARY_REVISION);
  const isBulkEnabled = isOnProPlan && hasBulkUploadPermission;

  return (
    <PageWrapper>
      <MainCase>
      <div>
        <Switch>
            <RestrictedRoute
            path={[routePaths.employeeOnboarding.employeeAnnualCompensation]}
            component={EmployeeAnnualCompensation}
            allowedPermissions={[
                permissions.EMPLOYEE_VIEW_PROFILE,
                permissions.EMPLOYEE_CHANGE_SALARY,
            ]}
            />
            <RestrictedRoute
            path={[routePaths.employeeOnboarding.advanceSalary]}
            component={EmployeeAdvanceSalary}
            allowedPermissions={[
                permissions.EMPLOYEE_VIEW_PROFILE,
                permissions.EMPLOYEE_CHANGE_SALARY,
            ]}
            />
            <Route>
            <Redirect to={routePaths.unknown} />
            </Route>
        </Switch>
        </div>
      </MainCase>
      <RightSidebar> 
        <AnchorCard
          imageSymbolType="requests"
          newTab={true}
          href={`/generateLetter?userId=${employeeId}&documentType=3`}
          heading="Generate Appraisal Letter"
        >
          Generate appraisal letter
        </AnchorCard>
        {
          isBulkEnabled ?
          (
            <AnchorCard
              imageSymbolType="requests"
              newTab={true}
              href={`/bulk-uploads/salary-revision`}
              heading="Bulk Salary Revision"
            >
              Revise salaries with an effective date in bulk through an Excel template
            </AnchorCard>
          ) : (
              <AnchorCard
                  imageSymbolType="requests"
                  newTab={true}
                  heading="Bulk Salary Revision"
              >
                  Revise salaries with an effective date in bulk. Contact support to upgrade now!
              </AnchorCard>
              )

        }
      </RightSidebar>
    </PageWrapper>
  )
}

export default EmployeeOnboarding;
