import React from "react";
import {addMonths, format} from "date-fns";
import {useIsFetching} from "react-query";
import {FETCH_RUNPAYROLL_DATA} from "../../../../../RunPayroll/utils/queries";
import {Option, Select} from "../../../../../ui/Form/Select";

const getReportMonthList = (month: string | null) => {
    if (!month) {
        return [];
    }

    let monthList: Date[] = [];

    // Covering 12 months with 4 past and 7 future months. Same as the run-payroll page month selector
    for (let delta = -4; delta < 8; delta++) {
        const newDate = addMonths(new Date(month+ "T00:00:00"), delta);
        monthList.push(newDate);
    }
    return monthList;
};

const ReportMonthSelector = ({
    reportMonth,
    setReportMonth,
    className
}: {
    reportMonth: string | null;
    setReportMonth: (reportMonth: string| null) => void;
    className?: string
}) => {

    const isFetchingData = useIsFetching(FETCH_RUNPAYROLL_DATA);

    const monthList = getReportMonthList(reportMonth);

    const onSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedValue = event.target.value;
        setReportMonth(selectedValue);
    };

    if (!reportMonth) {
        return null;
    }

    return (
        <>
            <Select
                name="report-month"
                onChange={onSelectionChange}
                value={reportMonth}
                disabled={!!isFetchingData}
                className={className ? className : ''}
                required
            >
                {monthList.map((month, index) => (
                    <Option key={month.toDateString()} value={format(month, 'yyyy-MM-dd')} data-date={month}>
                        {format(month, 'MMMM yyyy')}
                    </Option>
                ))}
            </Select>
        </>
    );
};

export default ReportMonthSelector;
