import { externalCredentials } from '../types';
import { CredentialsType } from './types';

export const transformPTCredentials = ({
  credentials,
  maximumCredentialsAllowed,
}: {
  credentials?: externalCredentials;
  maximumCredentialsAllowed: number;
}) => {
  const transformedCredentials: CredentialsType[] = [];

  if (credentials && credentials.ptInfo) {
    for (const credentialState in credentials.ptInfo) {
      const credential = credentials.ptInfo[credentialState];
      transformedCredentials.push({
        state: parseInt(credentialState),
        username: credential.ptUser || '',
        password: credential.ptPass || '',
        info: credential.ptAdditionalInfo || '',
      });
    }
  }

  for (let i = transformedCredentials.length; i < maximumCredentialsAllowed; i++) {
    transformedCredentials.push({
      state: null,
      username: '',
      password: '',
      info: '',
    });
  }

  return transformedCredentials;
};
