import { Box } from '@razorpay/blade/components';
import React from 'react';
import styled from 'styled-components';

interface ChatMessageBox {
  thumbNail: JSX.Element;
  children: React.ReactNode;
  withBackground?: boolean;
}

const ChatMessageBoxContainer = styled.div<{ withBackground?: boolean }>(
  ({ theme, withBackground }) => `
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing[4]}px;
    padding: ${
      withBackground ? `0px ${theme.spacing[3]}px` : `${theme.spacing[7]}px ${theme.spacing[3]}px`
    };
    
    animation: fadeIn 500ms;
    animation-delay: 200ms;
    animation-fill-mode: both;

    @keyframes fadeIn {
        0% { opacity: 0; visibility: hidden; }
        1% { opacity: 0; visibility: visible;}
        100% { opacity: 1; visibility: visible;}
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${
        withBackground ? `0px ${theme.spacing[6]}px` : `${theme.spacing[7]}px ${theme.spacing[6]}px`
      };
    }
`,
);

const ChatMessageBox = React.forwardRef<HTMLDivElement, ChatMessageBox>(
  ({ thumbNail, children, withBackground }, ref) => {
    return (
      <ChatMessageBoxContainer ref={ref} withBackground={withBackground}>
        {thumbNail}
        <Box
          flexGrow={withBackground ? '0' : '1'}
          padding={withBackground ? 'spacing.5' : '0px'}
          backgroundColor={withBackground ? 'surface.background.gray.moderate' : 'transparent'}
          borderRadius="large"
          borderTopLeftRadius="medium"
          whiteSpace="pre-wrap">
          {children}
        </Box>
      </ChatMessageBoxContainer>
    );
  },
);

export { ChatMessageBox };
