import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainContent } from './components/MainContent';
import { Box, Button, Heading, Link, ArrowRightIcon, Spinner, Alert, Text } from '@razorpay/blade/components';
import { OnboardingLayout, Sidebar } from '../../components/ui/OnboardingLayout';
import { routePaths } from '../Routes/data';
import { getDefaultFormProps, FormProps } from './types';
import CreateBonusForm from './components/CreateBonusForm';
import api from '../../api/index';
import { useQuery, useMutation } from 'react-query';
import { getAppErrorDetails,AppError } from '../../utils/AppError';
import { useHistory } from 'react-router-dom';
import { CreateBonusRequestSchemaContract } from '../../schemas/CreateBonusRequestSchema';
import Errors from 'components/ui/Form/Errors';
import { ValidatePage, headerContent, useShouldFetchUserData, validateFormVal } from './utils';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import permissions from 'constants/permissions';
import { showToastViaEvent } from 'utils/ToastEvents';

export default function CreateBonus(): ReactElement {

    const pathname = useLocation().pathname;
    const loggedInUserName = useSelector(loggedInUserSelectors.name);
    const permission = useSelector(loggedInUserSelectors.permissions);
    const [stepCount, setStepCount] = useState<number>(1);
    const [formProps, setFormProps] = useState<FormProps>(() => getDefaultFormProps());
    const [userName, setUserName] = useState<string | null>(loggedInUserName)
    const history = useHistory();
    const queryParam = new URLSearchParams(useLocation().search);
    const userId = queryParam.get('userId');

    let isPageValid = ValidatePage();
    let shouldFetchUserData = useShouldFetchUserData(userId);
    let canEdit = permission.includes(permissions.BONUS_EDIT);

    if (!isPageValid || !canEdit) {
        history.push(routePaths.unknown);
    }

    const getBonusTypes = useQuery('GET_ENABLED_BONUS_TYPES', () => {
      return api.bonus.enabledBonusTypes();
    });

    const userDataQuery = useQuery('GET_USER_DATA', () => {

        if (userId) {
            return api.people.fetchUserData(parseInt(userId));
        }

    },
        {
            onSuccess: (data) => {
                setUserName(data?.name ?? null);
            },
            onError: (error: typeof AppError) => {
                console.error("Error on fetching user's data : ", error);
            },
            enabled: shouldFetchUserData
        });

    const createBonusMutation = useMutation(
        api.bonus.createBonus,
        {
            onSuccess: (data) => {
                history.push(`${routePaths.bonus.view}?userId=${userId}`);
                showToastViaEvent({
                    text: `${data.data[0].bonus_name} created successfully!`,
                    timeout: 5000,
                    type: 'success',
                    icon: 'success',
                });
            },
            onError: (error: typeof AppError) => { },
        },
    );

    const CreateBonusMutation = () => {
        if (userId &&
            formProps.payout_month &&
            formProps.organization_bonus_setting_id &&
            formProps.source &&
            formProps.amount &&
            formProps.tds_recovery_option
        ) {
            let requestData: CreateBonusRequestSchemaContract = {
                people_id: parseInt(userId),
                organization_bonus_setting_id: formProps.organization_bonus_setting_id,
                amount: formProps.amount,
                source: formProps.source,
                payout_month: (new Date(formProps.payout_month)).toISOString().slice(0, 10),
                tds_recovery_option: formProps.tds_recovery_option,
                part_of_ctc: formProps.part_of_ctc,
                clawback_applicable: formProps.clawback_applicable,
                clawback_period: formProps.clawback_period ?? 0,
                remarks: formProps.remarks
            };

            createBonusMutation.mutate(requestData);

        }
    }

    if (userDataQuery.isLoading || getBonusTypes.isLoading) {
        return <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
        </Box>
    }

    return (
        (<OnboardingLayout>
            <Sidebar
                title={`Bonus Creation For ${userName}`}
                hideBackButton={false}
                backButtonTitle='BACK TO VIEW BOUNSES'
                onBackClick={() => {
                    history.push(`${routePaths.bonus.view}?userId=${userId}`);
                }}
                progress={headerContent(pathname, stepCount).progress}
            >

                <Box display="flex" flexDirection="column" gap="spacing.6" marginTop={'spacing.3'}>
                    <Text size="large">Helpful Links</Text>
                    <Link size="medium" variant="anchor" href={'https://intercom.help/XPayroll/en/articles/8320127-how-do-i-create-an-employee-bonus'} target='_blank'>
                        How to create a bonus for an employee?
                    </Link>
                </Box>

            </Sidebar>
            <MainContent
                title={<Box display={'flex'} flexDirection={'row'} alignItems='center'>{headerContent(pathname, stepCount).titleIcon}{headerContent(pathname, stepCount).title}</Box>}
                hideButton={true}
                description={headerContent(pathname, stepCount).description}
            >

                <Box display={'flex'} flexDirection={'column'} gap={'spacing.8'}>
                    {shouldFetchUserData && userDataQuery.isError &&
                        <Alert title={getAppErrorDetails(createBonusMutation.error).code} description={<Errors errorResponse={userDataQuery.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                    }
                    {createBonusMutation.isError &&
                        <Alert title={getAppErrorDetails(createBonusMutation.error).code} description={<Errors errorResponse={createBonusMutation.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                    }

                    <CreateBonusForm stepCount={stepCount} formProps={formProps} setFormProps={setFormProps} bonusTypes={getBonusTypes.data?.types || []}/>
                    <Box alignContent={'left'} marginBottom={'spacing.7'}>
                        {stepCount === 1 &&
                            <Box width={'212px'}>
                                <Button isDisabled={!validateFormVal('organization_bonus_setting_id',formProps) || !validateFormVal('amount',formProps)}
                                    variant='primary'
                                    icon={ArrowRightIcon}
                                    iconPosition='right'
                                    isFullWidth={true}
                                    onClick={() => { setStepCount(2); }}
                                >Next
                                </Button>
                            </Box>
                        }

                        {
                            stepCount === 2 &&
                            <Box display={'flex'} flexDirection={'row'} gap={'spacing.4'}>
                                <Box width={'212px'}>
                                    <Button variant='secondary' isFullWidth={true} onClick={() => { setStepCount(1); }}>Back: Define Bonus</Button>
                                </Box>
                                <Box width={'212px'}>
                                    <Button isDisabled={!validateFormVal('organization_bonus_setting_id',formProps) || !validateFormVal('amount',formProps) || ( formProps.clawback_applicable ? !validateFormVal('clawback_period',formProps) : false)} variant='primary' isFullWidth={true} onClick={CreateBonusMutation}>Create Bonus</Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </MainContent>
        </OnboardingLayout>)
    );
}