import FileDownloadButton from 'components/BulkUploads/components/DownloadButton';
import React, { useState } from 'react';

type UploadTemplateViewProps = {
  organizationId: number;
  reportType: number;
};

const UploadTemplateView = ({ organizationId, reportType }: UploadTemplateViewProps) => {
  const [fileDownloaded, setFileDownloaded] = useState(false);
  return (
    <FileDownloadButton
      href={`/v2/api/bulk-fnf/download-template?organization_id=${organizationId}&type=${reportType}`}
      fileDownloaded={fileDownloaded}
      setFileDownloaded={setFileDownloaded}
    />
  );
};

export default UploadTemplateView;
