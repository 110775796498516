import { Box, Button, RefreshIcon, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import failedToLoad from 'assets/failedToLoad.png';
import styled from 'styled-components';
import { getStaticMediaUrl } from 'utils/Urls';

interface ErrorResultsTableViewProps {
  title?: ReactNode;
  description?: ReactNode;
  onRetry?: () => void;
  isRetrying?: boolean;
}

const NoTaskFoundImage = styled.img`
  width: 180px;
  height: 180px;
`;

/**
 * @deprecated use this instead - ui/src/components/ui/ErrorWithRetry/index.tsx
 */
const ErrorResultsTableView = ({
  title = 'Uh Oh, Failed to load :(',
  description = (
    <Box textAlign="center">
      Couldn’t fetch your requests.
      <br /> Please retry or contact support
    </Box>
  ),
  onRetry,
  isRetrying,
}: ErrorResultsTableViewProps) => {
  const showRefreshCta = !!onRetry;

  return (
    (<Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="spacing.7"
      backgroundColor="surface.background.gray.moderate">
      <NoTaskFoundImage src={getStaticMediaUrl(failedToLoad)} />
      <Box display="flex" flexDirection="column" alignItems="center" gap="spacing.2">
        <Text weight="semibold" size="large">
          {title}
        </Text>
        <Text>{description}</Text>
      </Box>
      {showRefreshCta && (
        <Button
          size="large"
          icon={RefreshIcon}
          iconPosition="right"
          onClick={onRetry}
          isLoading={isRetrying}>
          Retry
        </Button>
      )}
    </Box>)
  );
};

export { ErrorResultsTableView };
