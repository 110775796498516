import { Modal } from "components/ui/Modal";
import React from "react";
import styles from './index.module.css';
import successCheckIcon from 'assets/icons/success_check_icon.svg';
import { PrimaryButton } from "components/ui/Button";
import { useHistory } from "react-router-dom";
import { routePaths } from "components/Routes/data";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";
import { renewalPageStateType, RenewalPurchaseState } from ".";
import { useDispatch } from "react-redux";
import useReduxSelector from "utils/useReduxSelector";
import { fetchInsurance, resetInsurance } from "reducers/insurance";

interface Props {
    pageState:renewalPageStateType,
    organizationAndEmployeeRenewalData:GetInsuranceRenewalOrganizationAndEmployeeContract
}

const RenewalPurchaseSuccessModal:React.FC<Props> = ({pageState,organizationAndEmployeeRenewalData}) =>{
    const history = useHistory();
    const dispatch = useDispatch();
    const loggedInUser = useReduxSelector((state) => state.loggedInUser);
    const orgId = loggedInUser.data?.currentOrganization?.id ?? null;

    return (<> 
            <Modal
                showModal={pageState?.renewalPurchaseState === RenewalPurchaseState.Success }
                heading=""
                className={`${styles['renewal-page-purchase-success']}`}
                contentClass="flex flex-col h-auto"
                closeModal={()=>{}}
                disableClose={true}
            >
                <>
                    <div className={`w-full justify-center flex pb-10 flex-col ${styles['renewal-page-purchase-success']}`}>
                        <img
                            loading='lazy'
                            src={successCheckIcon}
                            alt=""
                        />
                        <h2 className="m-3 mt-4 text-2xl font-semibold">  Insurance has been successfully renewed for {organizationAndEmployeeRenewalData?.employeeDetails?.total ?? 0} employees</h2>
                        <PrimaryButton
                            onClick={() => {
                                dispatch(resetInsurance());
                                dispatch(fetchInsurance(orgId ? orgId : null));
                                history.push(routePaths.insurance.admin.root);
                            }}
                        >
                            Continue
                        </PrimaryButton>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default RenewalPurchaseSuccessModal;