import React from 'react';
import { Box, Button, EditIcon } from '@razorpay/blade/components';
import { ModalActions } from '../constants';

const EnableDisableComponentButton = ({
  isActive,
  openModal,
}: {
  isActive: boolean;
  openModal: (modalAction: string) => void;
}) => {
  return isActive ? (
    <Button
      variant={'tertiary'}
      isFullWidth={true}
      onClick={() => openModal(ModalActions.disable)}
      accessibilityLabel="details view disable button">
      Disable
    </Button>
  ) : (
    <Button
      variant={'secondary'}
      color={'positive'}
      isFullWidth={true}
      onClick={() => openModal(ModalActions.enable)}
      accessibilityLabel="details view enable button">
      Enable
    </Button>
  );
};

export const ModalActionButtons = ({
  isActive,
  showEnableDisableButton,
  openModal,
  onEditClick
}: {
  isActive: boolean;
  showEnableDisableButton: boolean;
  openModal: (modalAction: string) => void;
  onEditClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <>
      <Box
        display={'grid'}
        gridTemplateColumns={showEnableDisableButton ? '0.60fr 0.40fr' : '1fr'}
        columnGap={'spacing.4'}>
        <Button variant={'primary'} icon={EditIcon} iconPosition={'left'} isFullWidth={true} onClick={onEditClick}>
          Modify
        </Button>
        {showEnableDisableButton && (
          <EnableDisableComponentButton isActive={isActive} openModal={openModal} />
        )}
      </Box>
      {/* Temporarily commenting it out till we solutionise salary component deletion */}
      {/* <Box display={'flex'} justifyContent={'center'}>
        <Link
          marginTop={'spacing.7'}
          marginBottom={'spacing.8'}
          variant={'button'}
          icon={TrashIcon}
          iconPosition={'left'}
          color={'negative'}
          onClick={() => openModal(ModalActions.delete)}
          accessibilityLabel="details view delete button">
          Delete component
        </Link>
      </Box> */}
    </>
  );
};
