import React from 'react';
import InputBoxWrapper from './FnfInputBoxWrapper';
import { Box, TextInput } from '@razorpay/blade/components';

type FnfEmailAddressProps = {
  email: string;
  onChangeEmail: (emailAddress: string) => void;
};

const FnfEmailAddress = ({ email, onChangeEmail }: FnfEmailAddressProps) => {
  return (
    <InputBoxWrapper heading="Personal Email Address">
      <Box>
        <TextInput
          label="Email"
          placeholder="Enter employee's personal email here"
          value={email}
          onChange={(e) => {
            onChangeEmail(e.value ?? '');
          }}
        />
      </Box>
    </InputBoxWrapper>
  );
};

export default FnfEmailAddress;
