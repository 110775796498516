import React from 'react';
import { useSelector } from 'react-redux';

import { SuccessAlert, WarningAlert } from 'components/ui/Alert';
import { useErrorMessageContext } from '../contexts/RunPayrollErrorContext';
import { getError, getPageError } from './ErrorMaps';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import format from 'date-fns/format';
import { LinkButtonV2 } from 'components/ui/Button';
import { routePaths } from 'components/Routes/data';

const ErrorDisplay = ({
  setPayrollMonth,
}: {
  setPayrollMonth: (payrollMonth: string | null) => void;
}) => {
  const errorMessageContext = useErrorMessageContext();
  const data = useRunPayrollDataContext();

  const isOrgKycRestricted = useSelector(loggedInUserSelectors.isOrgKycRestricted);
  const referenceLinks = useSelector(loggedInUserSelectors.referenceLinks);

  const error = errorMessageContext?.error;

  const links = { ...data?.links, ...referenceLinks };
  const pageError = getPageError(setPayrollMonth, data, isOrgKycRestricted, links);
  const actionError = error ? getError(error, data ?? null, setPayrollMonth, links) : null;
  const numberOfEmployeesHavingBonus = data?.numberOfEmployeesHavingBonus;

  let bonusMessage = '';
  if (numberOfEmployeesHavingBonus && numberOfEmployeesHavingBonus > 0) {
    const payrollMonthForMessage = format(new Date(data.payrollMonth), 'MMMM yyyy');
    if (numberOfEmployeesHavingBonus === 1) {
      bonusMessage = `Bonus payment for 1 employee has been added to ${payrollMonthForMessage} payroll. View all bonus details ${' '}`;
    } else {
      bonusMessage = `Bonus payments for ${numberOfEmployeesHavingBonus} employees have been added to ${payrollMonthForMessage} payroll. View all bonus details ${' '}`;
    }
  }

  return (
    <>
      {pageError && <WarningAlert className="mb-5">{pageError}</WarningAlert>}
      {actionError && <WarningAlert className="mb-5">{actionError}</WarningAlert>}
      {!actionError && bonusMessage && data && (
        <SuccessAlert className="mb-5">
          {bonusMessage}
          <a
            target="_blank"
            href={`${routePaths.reports.bonusReport}?payout-month=${data.payrollMonth}`}>
            <LinkButtonV2>here.</LinkButtonV2>
          </a>
        </SuccessAlert>
      )}
    </>
  );
};

export default ErrorDisplay;
