import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { OTPInput } from '@razorpay/blade/components';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { PrimaryButton } from 'components/ui/Button';
import { OTP_REGEX } from 'utils/regexes';
import { OTP_ACTION_DISABLE_2FA } from '../constants';

import styles from './../Login/index.module.scss';

const DisableVerification = ({
  email,
  isAuthenticationAppEnabled,
}: {
  email: string;
  isAuthenticationAppEnabled: boolean;
}) => {
  const [otp, setOtp] = useState<string>('');

  useEffect(() => {
    sendOtp.mutate();
  }, []);

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;

    if (OTP_REGEX.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  const verifyAndDisable = useMutation(
    (otp: string) => {
      return api.companyOnboarding.disable2FASetting(otp);
    },
    {
      onSuccess: () => {
        showToastViaEvent({
            text: 'Two Factor Authentication Disabled Successfully',
            timeout: 5000,
            type: 'success',
            icon: 'success',
        });
        window.location.href = '/settings';
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);

        if (response.code == 'OTP_LIMIT_EXCEEDED') {
          showToastViaEvent({
            text: response.messageForUser,
            timeout: 5000,
            type: 'danger',
            icon: 'info',
          });
        }
      },
    },
  );

  const sendOtp = useMutation(
    () => {
      return api.login.resendOtp(OTP_ACTION_DISABLE_2FA);
    },
    {
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        showToastViaEvent({
          text: response.messageForUser,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      },
    },
  );

  return (
    <>
      <div className={styles['otp-component']} style={{ height: '350px' }}>
        <div className={styles['otp-title']}>
          {isAuthenticationAppEnabled
            ? 'Enter the authentication code to disable 2FA'
            : 'Enter OTP to disable 2FA'}
        </div>
        <div className={styles['otp-sub-title']}>
          {isAuthenticationAppEnabled
            ? 'Open the two-factor authenticator app on your mobile device to view the authentication code'
            : `One Time Password sent successfully to ${email}`}
        </div>
        <OTPInput
          label=""
          errorText={`The ${
            !isAuthenticationAppEnabled ? 'OTP' : 'authentication code'
          } entered is incorrect. Please try again.`}
          name="otp"
          value={otp}
          onChange={onOtpChange}
          otpLength={6}
          placeholder=""
          validationState={
            getAppErrorDetails(verifyAndDisable.error).code == 'OTP_VERIFICATION_ERROR'
              ? 'error'
              : 'none'
          }
          isDisabled={verifyAndDisable.isLoading}
        />
        <div className="flex-1"></div>
        <PrimaryButton
          className={'w-full'}
          disabled={!otp}
          onClick={() => verifyAndDisable.mutate(otp)}
          isPending={verifyAndDisable.isLoading || verifyAndDisable.isSuccess}>
          Verify & Disable
        </PrimaryButton>
      </div>
    </>
  );
};

export default DisableVerification;
