import React, { useState } from 'react';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import { useMutation } from 'react-query';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useQueryClient } from 'react-query';
import { ZOHO_ACTIVATION_STATUS_QUERY } from '../../queries';
import ZohoLogo from 'assets/zoho/zoho_text_logo.png';
import Card from '../../components/Card';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { CheckBox } from 'components/ui/Form/Input';
import withActivationQueryWrapper from '../../withActivationQueryWrapper';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import { WarningAlert, WarningAlertYellow } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { getAvailableEmployeeStatuses } from './utils';
import ActivationConfirmation from './ActivationConfirmation';
import { useModal } from 'components/ui/Modals';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoDismissalStatusMappingSetup({ zohoData }: Props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { openModal, closeModal } = useModal();
  const availableStatuses = getAvailableEmployeeStatuses(zohoData.available_fields || []);
  const [selectedStatuses, setSelectedStatues] = useState(zohoData.employee_active_statuses);

  const enableMutation = useMutation(() => api.zoho.enableUserDataSync(), {
    onError: (error: typeof AppError) => {},
    onSuccess: () => {
      queryClient.invalidateQueries(ZOHO_ACTIVATION_STATUS_QUERY);
    },
  });

  const handleToggleField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;

    if (availableStatuses.includes(selectedValue)) {
      const updatedStatuses = [...selectedStatuses];
      if (!updatedStatuses.includes(selectedValue)) {
        updatedStatuses.push(selectedValue);
      } else {
        updatedStatuses.splice(updatedStatuses.indexOf(selectedValue), 1);
      }
      setSelectedStatues(updatedStatuses);
    }
  };

  const updateStatusesMutation = useMutation(
    (statuses: string[]) => api.zoho.updateEmployeeActiveStatuses(statuses),
    {
      onError: (error: typeof AppError) => {},
      onSuccess: () => {
        enableMutation.mutate();
      },
    },
  );

  const handleConfirm = () => {
    trackSegment('zoho.integration.confirm.click');
    updateStatusesMutation.mutate(selectedStatuses);
    closeModal();
  };

  const handleActivationConfirmation = () => {
    const fieldsToBeSynced = zohoData.synced_fields.map((field) => field.name);
    trackSegment('zoho.dismissal_mapping.save.click');
    openModal(
      <ActivationConfirmation onConfirm={handleConfirm} fieldsToBeSynced={fieldsToBeSynced} />,
    );
  };

  return (
    <div className={styles['wrapper']}>
      {enableMutation.status === 'error' && (
        <WarningAlert className="my-6">
          <Errors errorResponse={enableMutation.error} />
        </WarningAlert>
      )}
      {updateStatusesMutation.status === 'error' && (
        <WarningAlert className="my-6">
          <Errors errorResponse={updateStatusesMutation.error} />
        </WarningAlert>
      )}
      <img src={ZohoLogo} alt="Zoho Logo" className={styles['logo']} />
      <h3 className={cx(styles['title'], 'mt-12')}>Employee status mapping</h3>
      <Card className="mt-10">
        <h3 className="font-bold text-white-o-70">
          Choose all employee statuses from your Zoho People account which need to be be marked as{' '}
          <span className="text-white-o-80 italic">active</span> Employees/Contractors on RazorpayX
          Payroll. <br />
          <br />
          If an employee has any status other than the selected ones, they would be considered as
          dismissed.
        </h3>
        <div className="grid grid-cols-1 gap-y-6 mt-10">
          {availableStatuses.map((status) => (
            <CheckBox
              id={`zoho-status-${status}`}
              value={status}
              key={status}
              label={status}
              checked={selectedStatuses.includes(status)}
              onChange={handleToggleField}
              disabled={
                updateStatusesMutation.status === 'loading' || enableMutation.status === 'loading'
              }
            />
          ))}
        </div>
      </Card>
      <WarningAlertYellow className="mt-10">
        An employee will only be considered as dismissed in RazorpayX Payroll only if the following
        conditions are met in Zoho People:
        <ol className="mt-2 mb-4 ml-8">
          <li className="text-white">a. Employee status is marked as dismissed</li>
          <li className="text-white">b. Date of exit is set in Zoho People </li>
        </ol>
        If the date of exit is not set the employee will be considered as active in RazorpayX
        Payroll.
      </WarningAlertYellow>
      <div className="flex justify-between mt-24">
        <SecondaryButton
          disabled={
            updateStatusesMutation.status === 'loading' || enableMutation.status === 'loading'
          }
          onClick={() => {
            history.goBack();
          }}>
          Back
        </SecondaryButton>
        <StandardButton
          className="mr-0"
          isPending={
            updateStatusesMutation.status === 'loading' || enableMutation.status === 'loading'
          }
          disabled={selectedStatuses.length === 0}
          onClick={handleActivationConfirmation}>
          Confirm and sync
        </StandardButton>
      </div>
    </div>
  );
}

export default withActivationQueryWrapper(ZohoDismissalStatusMappingSetup);
