import { FormProvider } from 'forms';
import React from 'react';
import EditPredefinedForm from './EditPredefinedForm';

const EditPredefined = () => {
  return (
    <FormProvider>
      <EditPredefinedForm />
    </FormProvider>
  );
};

export default EditPredefined;
