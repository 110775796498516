import {
  Badge,
  Box,
  Button,
  CheckCircleIcon,
  ClockIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  ThumbsUpIcon,
} from '@razorpay/blade/components';
import api from 'api';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateUiUserPreferencesAction } from 'reducers/loggedInUser';
import useReduxSelector from 'utils/useReduxSelector';

const WorkflowIntroModalPreferenceKey = 'hasSeenWorkflowsIntroModal';

const WorkflowIntroModal = () => {
  const dispatch = useDispatch();
  const uiPreferences = useReduxSelector(
    (state) => state.loggedInUser.data?.currentOrganization?.employeeDetails?.uiPreferences,
  );
  const isModalAlreadyShown = !!uiPreferences?.[WorkflowIntroModalPreferenceKey] ?? false;
  const [isOpen, setIsOpen] = useState(!isModalAlreadyShown);
  const { mutate } = useMutation({
    mutationFn: api.people.saveUserUiPreference,
  });

  const featuresList = [
    {
      icon: <ClockIcon size="medium" color="feedback.icon.positive.intense" />,
      description: 'The maker creates an approval request',
    },
    {
      icon: <ThumbsUpIcon size="medium" color="feedback.icon.positive.intense" />,
      description: 'Checkers approve or reject the pending requests',
    },
    {
      icon: <CheckCircleIcon size="medium" color="feedback.icon.positive.intense" />,
      description: 'Post approval, the requests are ready to be processed',
    },
  ];

  const savePreferenceAndDismiss = () => {
    setIsOpen(false);

    dispatch(
      updateUiUserPreferencesAction({
        [WorkflowIntroModalPreferenceKey]: true,
      }),
    );

    mutate({
      [WorkflowIntroModalPreferenceKey]: true,
    });
  };

  return (
    (<Modal isOpen={isOpen} onDismiss={savePreferenceAndDismiss}>
      <ModalHeader title="Introducing Workflows" titleSuffix={<Badge color="primary">New</Badge>} />
      <ModalBody>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <Text weight="semibold" size="large">
            Here’s what happens once approval workflow is set up:
          </Text>
          <Box display="flex" flexDirection="column" gap="spacing.6" marginBottom="spacing.5">
            {featuresList.map(({ icon, description }, index) => (
              <Box key={index} display="flex" gap="spacing.3">
                {icon}
                <Text size="small">{description}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button isFullWidth onClick={savePreferenceAndDismiss}>
          Set-up workflows
        </Button>
      </ModalFooter>
    </Modal>)
  );
};

export { WorkflowIntroModal };
