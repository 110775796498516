import React, { ReactElement, useState } from 'react';
import DownloadSection from './DownloadSection';
import FileUploadSection from './FileUploadSection';
import { BulkUploadStatus, routeToStepMap, BulkUploadStep } from 'constants/bulkUpload';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import { useLocation, useHistory } from 'react-router-dom';
import styles from '../index.module.scss';
import { PrimaryButton } from 'components/ui/Button';
import { stepToRouteMap } from '../../../constants/bulkUpload';
import { BulkUploadTypes } from 'constants/bulkUpload';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from '../queries/queries';
import api from '../../../api/index';
import Spinner from '../../ui/Spinner/index';
import ErrorCard from '../components/ErrorCard';
import { AppError } from 'utils/AppError';
import { getCurrentPayrollMonth } from 'components/RunPayroll/utils/utils';
import { format } from 'date-fns';


export default function DownloadAndUploadPage(): ReactElement {

  const [payrollMonth, setPayrollMonth] = useState<string | null>(format(getCurrentPayrollMonth(),'yyyy-MM-dd'));
  const [bulkUploadRecord, setBulkUploadRecord] = useState<BulkUploadDetailsSchemaContract[0] | null>(null);
  const location = useLocation();

  const currentStep = routeToStepMap[BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP][location.pathname] || BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP;
  const history = useHistory();

  function nextStep() {
    if ((currentStep + 1) <= 2) {
      history.push(stepToRouteMap[BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP][currentStep + 1]);
    }
  }

  const { isLoading, isRefetching, error, data } = useQuery(GET_BULK_UPLOAD_RECORD, () => {

    return api.bulkUploads.fetchBulkUploadRecordByType(BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP);

  }, {
    onSuccess: (data) => {
      if (data) {
        setBulkUploadRecord((data.length > 0) ? data[0] : null);
      }
    },
    onError: (error: typeof AppError) => {
      console.error("Error on fetching org's bulk upload data : ", error);
    }
  });

  if (isLoading) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>);
  }

  if (error) {
    return <ErrorCard error={error} buttonText= {'Back to Dashboard'} onClick={() => {
        window.location.href = '/dashboard';
      }} />
}

  return (
    <>
      <div className={styles['centered-body-50']}>
        <DownloadSection 
        payrollMonth={payrollMonth} 
        setPayrollMonth={setPayrollMonth} 
        bulkUploadRecord={bulkUploadRecord}
        setBulkUploadRecord={setBulkUploadRecord}
        />

        <FileUploadSection
          bulkUploadRecord={bulkUploadRecord}
          setBulkUploadRecord={setBulkUploadRecord}
        />
        <div className={styles['section']}>
          <PrimaryButton
            className={styles['button']}
            disabled={bulkUploadRecord ? (bulkUploadRecord.status !== BulkUploadStatus.STATUS_VALIDATION_SUCCESS) : true}
            onClick={nextStep}>{'Continue'}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}
