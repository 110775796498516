import React from 'react';
import { TabProps, TabPanelProps, TabLayoutProps } from './types';
import { TabLayoutProvider, useTabLayoutValue } from './context';
import cx from 'classnames';

import styles from './index.module.scss';

export const Tab = ({ label, value }: TabProps) => {
  const tabLayoutValue = useTabLayoutValue();

  const handleOnClick = () => {
    tabLayoutValue.onChange && tabLayoutValue.onChange(value);
  };

  return (
    <span
      className={cx(
        'text-inactive-tab',
        'cursor-pointer font-semibold',
        'inline-block px-10 py-2',
        styles['Tab'],
        {
          [styles['is-active']]: tabLayoutValue.value === value,
        },
      )}
      onClick={handleOnClick}
    >
      {label}
    </span>
  );
};

export const TabList: React.FC = ({ children }) => {
  return (
    <div className="border border-solid border-white-o-10 flex gap-4 mb-16 p-[4px] rounded-sm">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === Tab) {
          return child;
        } else {
          throw new Error('Only Tab can be passed');
        }
      })}
    </div>
  );
};

export const TabPanel: React.FC<TabPanelProps> = ({ value, children }) => {
  const tabLayoutValue = useTabLayoutValue();

  if (tabLayoutValue.value !== value) {
    return null;
  }

  return <>{children}</>;
};

export const TabLayout: React.FC<TabLayoutProps> = ({ value, children, onChange }) => {
  const context = React.useMemo(() => {
    return { value, onChange };
  }, [value]);

  return (
    <TabLayoutProvider value={context}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && (child.type === TabList || child.type === TabPanel)) {
          return child;
        } else {
          throw new Error('Only TabList / TabPanel can be passed');
        }
      })}
    </TabLayoutProvider>
  );
};
