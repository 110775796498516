import React, { useState } from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import {
  ArrowRightIcon,
  Box,
  Button,
  CheckCircleIcon,
  ClockIcon,
  Counter,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextInput,
  Heading,
  UserCheckIcon,
} from '@razorpay/blade/components';
import { InformationCard } from './components/InformationCard';
import { Redirect, useHistory } from 'react-router-dom';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { useMutation } from 'react-query';
import api from 'api';
import { showApiErrorNotification } from 'utils/Api';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';
import { useGetKnitHrmsEmployeeTypesLovQuery } from '../useGetKnitHrmsEmployeeTypesLovQuery';
import { showSuccessToastNotification } from 'utils/ToastEvents';

const SyncConfirmation = () => {
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const history = useHistory();
  const [showSyncedFieldsModal, setShowSyncedFieldsModal] = useState(false);
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    retryLimitExhausted: hrmsIntegrationQueryRetryLimitExhausted,
    invalidateKnitHrmsIntegrationQueryCache,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const updateHrmsIntegrationConfigMutation = useMutation({
    mutationFn: api.knit.updateHrmsIntegration,
    onSuccess: (data) => {
      if (data.success) {
        invalidateKnitHrmsIntegrationQueryCache();
        showSuccessToastNotification({
          text: `Integration with ${hrmsAppMetadata.name} updated successfully and data sync is triggered`,
          timeout: 2000,
        });
      }
    },
    onError: (error) => {
      showApiErrorNotification(error);
    },
  });
  const {
    knitHrmsEmployeeTypesLovQuery,
    retryLimitExhausted: employeeTypesLovQueryRetryLimitExhausted,
  } = useGetKnitHrmsEmployeeTypesLovQuery(knitHrmsApp);
  const { integrationProgress } = useIntegrationProgress();
  const [confirmText, setConfirmText] = useState('');
  const canProceed = confirmText === 'confirm';

  const isLoading =
    knitHrmsIntegrationQuery.isLoading ||
    knitHrmsIntegrationQuery.isFetching ||
    knitHrmsEmployeeTypesLovQuery.isLoading ||
    knitHrmsEmployeeTypesLovQuery.isFetching;

  // redirecting to HRMS app home if integration is not active
  if (knitHrmsIntegrationQuery.isSuccess && !isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  // redirecting to sync-in-progress screen if employee data sync is in progress
  if (knitHrmsIntegrationQuery.isSuccess && isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.settings.syncInProgress} />;
  }

  const handleConfirmTextChange = ({ value }: { name?: string; value?: string }) => {
    setConfirmText(value ?? '');
  };

  const handleBackCtaClick = () => {
    history.goBack();
  };

  const handleNextCtaClick = () => {
    if (knitHrmsIntegrationQuery.data) {
      updateHrmsIntegrationConfigMutation.mutate({
        fields_to_be_synced: knitHrmsIntegrationQuery.data?.synced_fields.map(({ field }) => field),
        employee_types_full_time: knitHrmsIntegrationQuery.data.employee_types_full_time,
        employee_types_contractor: knitHrmsIntegrationQuery.data.employee_types_contractor,
        hrms_provider: knitHrmsApp,
        entity_name: knitHrmsIntegrationQuery.data.entity_name ?? undefined,
      });
    }
  };

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  if (isLoading) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={hrmsIntegrationQueryRetryLimitExhausted}
      />
    );
  }

  if (knitHrmsEmployeeTypesLovQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsEmployeeTypesLovQuery.refetch}
        isRetrying={knitHrmsEmployeeTypesLovQuery.isFetching}
        retryLimitExhausted={employeeTypesLovQueryRetryLimitExhausted}
      />
    );
  }

  const employeeTypeMappingContent = knitHrmsIntegrationQuery.data?.employee_types_full_time
    .map(
      (employeeTypeId) =>
        knitHrmsEmployeeTypesLovQuery.data?.data.fields.find(({ id }) => employeeTypeId === id)
          ?.label,
    )
    .join(' | ');
  const contractorTypeMappingContent = knitHrmsIntegrationQuery.data?.employee_types_contractor
    .map(
      (contractorTypeId) =>
        knitHrmsEmployeeTypesLovQuery.data?.data.fields.find(({ id }) => contractorTypeId === id)
          ?.label,
    )
    .join(' | ');

  const handleViewFieldsClick = () => {
    openFieldsSyncModal();
  };

  const openFieldsSyncModal = () => {
    setShowSyncedFieldsModal(true);
  };

  const closeFieldsSyncModal = () => {
    setShowSyncedFieldsModal(false);
  };

  return (
    (<IntegrationWindow
      progress={integrationProgress}
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={org?.name ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}>
      <Box display="flex" flexDirection="column" gap="spacing.9" maxWidth="592px">
        <Heading as="h2" size="xlarge" color="surface.text.gray.muted">
          👍 You are almost done!
        </Heading>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <InformationCard type="info" title="Configuration Summary" key={1}>
            <Box display="flex" flexDirection="column" gap="spacing.7">
              {knitHrmsIntegrationQuery.data?.entity_name && (
                <Box display="flex" justifyContent="space-between" alignItems="start">
                  <Box display="flex" gap="spacing.3" width="244px">
                    <CheckCircleIcon
                      size="medium"
                      color="interactive.icon.gray.normal"
                    />
                    <Text color="surface.text.gray.disabled">{hrmsAppMetadata.name} entity</Text>
                  </Box>
                  <Box display="flex" width="183px">
                    <Text>{knitHrmsIntegrationQuery.data.entity_name}</Text>
                  </Box>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="spacing.3" width="244px">
                  <UserCheckIcon size="medium" color="interactive.icon.gray.normal" />
                  <Text color="surface.text.gray.disabled">Fields to be synced</Text>
                </Box>
                <Box display="flex" flexDirection="column" gap="spacing.4" width="183px">
                  <Box display="flex" flexDirection="column">
                    <Text>Employee personal details</Text>
                    <Text>Employee organization details</Text>
                  </Box>
                  <Link
                    variant="button"
                    icon={ArrowRightIcon}
                    iconPosition="right"
                    onClick={handleViewFieldsClick}>
                    View fields
                  </Link>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="spacing.3" width="244px">
                  <ClockIcon size="medium" color="interactive.icon.gray.normal" />
                  <Text color="surface.text.gray.disabled">Sync duration</Text>
                </Box>
                <Box display="flex" width="183px">
                  <Text>Every 6 hours</Text>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="spacing.3" width="244px">
                  <UserCheckIcon size="medium" color="interactive.icon.gray.normal" />
                  <Text color="surface.text.gray.disabled">
                    Fields from {hrmsAppMetadata.name} mapped as employee
                  </Text>
                </Box>
                <Box display="flex" width="183px">
                  <Text>{employeeTypeMappingContent}</Text>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="spacing.3" width="244px">
                  <UserCheckIcon size="medium" color="interactive.icon.gray.normal" />
                  <Text color="surface.text.gray.disabled">
                    Fields from {hrmsAppMetadata.name} mapped as contractor
                  </Text>
                </Box>
                <Box display="flex" width="183px">
                  <Text>{contractorTypeMappingContent}</Text>
                </Box>
              </Box>
            </Box>
          </InformationCard>
          <InformationCard type="notice" title="Some things to note" key={2}>
            <List variant="unordered">
              <ListItem key={1}>
                <ListItemText color="surface.text.gray.disabled">
                  Please note that enabling this integration will overwrite your current data on
                  RazorpayX Payroll with the data on {hrmsAppMetadata.name}
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText color="surface.text.gray.disabled">
                  Employee information that is flowing from {hrmsAppMetadata.name} cannot be updated
                  in RazorapayX Payroll.
                </ListItemText>
              </ListItem>
            </List>
          </InformationCard>
          <TextInput
            label="Type “confirm” to proceed with the integration"
            value={confirmText}
            onChange={handleConfirmTextChange}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          <Button
            onClick={handleNextCtaClick}
            isDisabled={!canProceed}
            isLoading={updateHrmsIntegrationConfigMutation.isLoading}>
            {isIntegrationActive
              ? `Update and sync ${hrmsAppMetadata.name}`
              : `Integrate with ${hrmsAppMetadata.name}`}
          </Button>
        </Box>
        <Modal isOpen={showSyncedFieldsModal} onDismiss={closeFieldsSyncModal} size="small">
          <ModalHeader
            title="Fields to be synced"
            titleSuffix={
              <Counter value={knitHrmsIntegrationQuery.data?.synced_fields.length ?? 0} />
            }
          />
          <ModalBody>
            {knitHrmsIntegrationQuery.data?.synced_fields.map(({ name }, index) => (
              <Box display="flex" flexDirection="column" key={index}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding={['spacing.4', '0px']}>
                  <Text>{name}</Text>
                </Box>
                {index < knitHrmsIntegrationQuery.data?.synced_fields.length - 1 && <Divider />}
              </Box>
            ))}
          </ModalBody>
          <ModalFooter>
            <Box display="flex" justifyContent="flex-end" gap="spacing.5">
              <Button onClick={closeFieldsSyncModal}>Close</Button>
            </Box>
          </ModalFooter>
        </Modal>
      </Box>
    </IntegrationWindow>)
  );
};

export { SyncConfirmation };
