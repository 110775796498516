import React from 'react';
import { Box } from '@razorpay/blade/components';
import FilterTag from 'components/ui/FilterTag/FilterTag';
import { useFilters } from 'hooks/useFilters';

interface FilterTagsProps {
  filtersMap: Record<string, string>; // map with all filters
  multipleValueFilters: string[]; // Filters which can have multiple values
  filterValueLabelMap: Record<string, Record<string, string>>; // map of the filters to a map of options with their readable labels
  filterLabelMap: Record<string, string>; // map of the filters to their readable labels
}

const FilterTags = ({
  filtersMap,
  filterValueLabelMap,
  multipleValueFilters = [],
  filterLabelMap,
}: FilterTagsProps) => {
  const { currentFilters, removeFilter } = useFilters({ filtersMap, multipleValueFilters });

  if (Object.keys(currentFilters).length === 0) {
    return <></>;
  }

  return (
    <Box display="flex" gap="spacing.5" overflowX="auto" flexWrap="wrap">
      {Object.entries(currentFilters).map(([filterKey, filterValues]) => {
        //if the filter isn't to be excluded and it's value exists
        if (filterValues.length > 0) {
          let labelValue;

          // if the filter is a multi-value filter, i.e, it's an array,
          // we take all the option labels and make it a comma separated string
          // if not we just assign it the respective option label
          if (Array.isArray(filterValues)) {
            labelValue = filterValues
              .map((option) => {
                return filterValueLabelMap[filterKey][option];
              })
              .join(', ');
          } else {
            labelValue = filterValueLabelMap[filterKey][filterValues];
          }

          return (
            <FilterTag
              key={filterKey}
              name={filterLabelMap[filterKey]}
              value={labelValue}
              onDismiss={() => removeFilter(filterKey)}
            />
          );
        }
      })}
    </Box>
  );
};

export default FilterTags;
