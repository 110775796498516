import React, { ReactElement } from 'react';

import styles from './../index.module.scss';

export interface InfoCard {
  id: number;
  img?: ReactElement;
  title?: ReactElement | string;
  content?: ReactElement | string
}

export const InfoCard = ({
  className,
  infoCard,
  isSelected,
  onSelect
}: {
  className?: string;
  infoCard?: InfoCard;
  isSelected: boolean;
  onSelect: (id?: number) => void;
}) => {
  return (
    <div
      className={`${styles['info-card']} ${className ? className : ''} ${isSelected ? styles['selected']: ""}`}
      id={infoCard?.id ? `infoCard-${infoCard?.id}` : undefined}
      onClick={() => onSelect(infoCard?.id)}
    >
      <div className='info-card-img'>
        {infoCard?.img}
      </div>
      <div className={styles['info-card-title']}>
        {infoCard?.title}
      </div>
      <div className={styles['info-card-content']}>
        {infoCard?.content}
      </div>
    </div>
  );
}

const InfoCardList = ({ infoCardList, selectedCardId, onSelect }: {
  infoCardList: InfoCard[];
  selectedCardId?: number;
  onSelect: (id?: number) => void;
}) => {
  return (<div className="py-5 grid grid-cols-1 gap-x-10 md:grid-cols-2 mb-12">
    {
      infoCardList?.map((card: InfoCard) => {
        return <InfoCard key={card.id} infoCard={card} onSelect={onSelect} isSelected={selectedCardId === card.id} />
      })
    }
  </div>)
}


export default InfoCardList;
