import { makeMotionTime } from '@razorpay/blade/utils';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const RevealWrapper = styled.div<{ isOpen?: boolean; maxHeight: string }>(
  ({ isOpen, maxHeight, theme }) => {
    const easing = isOpen
      ? theme.motion.easing.overshoot
      : theme.motion.easing.exit;
    const duration = makeMotionTime(theme.motion.duration.xmoderate);
    return css`
      max-height: ${isOpen ? maxHeight : '0'};
      opacity: ${isOpen ? '1' : '0'};
      overflow: ${isOpen ? 'visible' : 'hidden'};
      transition: all ${duration} ${easing};
    `;
  },
);

export const Reveal = ({ children, isOpen }: React.PropsWithChildren<{ isOpen: boolean }>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  useEffect(() => {
    if (!ref.current) return;
    setMaxHeight(`${ref.current?.scrollHeight || 0}px`);
  }, [isOpen, children]);

  return (
    <RevealWrapper ref={ref} maxHeight={maxHeight} isOpen={isOpen}>
      {children}
    </RevealWrapper>
  );
};
