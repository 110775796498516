import React, { ReactNode, useState, PropsWithChildren } from 'react';
import {
  Badge,
  Box,
  CheckIcon,
  CloseIcon,
  Divider,
  Heading,
  IconButton,
  Link,
  Skeleton,
  Text,
} from '@razorpay/blade/components';
import styled from 'styled-components';
import { DetailsSkeleton } from 'components/ui/Skeletons/DetailsSkeleton';
import { MIN_COLLAPSIBLE_LENGTH } from 'components/SalaryComponents/constants';

export const IconContainer = styled.div(
  ({ theme }) => `
  display: flex;
  background-color: ${theme.colors.interactive.background.gray.faded};
  border-radius: ${theme.border.radius.medium}px;
  padding: ${theme.spacing['2']}px;
`,
);

export const BooleanIconContainer = styled.div<{ success: boolean }>(
  ({ theme, success }) => `
  display: flex;
  background-color: ${
    success
      ? theme.colors.surface.background.sea.subtle
      : theme.colors.feedback.background.negative.subtle
  };
  height: ${theme.spacing['5']}px;
  width: ${theme.spacing['5']}px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`,
);

export const DetailsSectionItem = ({
  label,
  value,
}: {
  label: string;
  value?: string | boolean;
}) => (
  <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} alignItems={'baseline'}>
    <Text size={'small'} weight="medium" color={'surface.text.gray.muted'}>
      {label}
    </Text>
    {value !== undefined ? (
      typeof value === 'string' ? (
        <Text size={'medium'} weight="regular">
          {value}
        </Text>
      ) : (
        <BooleanIconContainer success={value}>
          {value ? (
            <CheckIcon color={'surface.icon.onSea.onSubtle'} size={'small'} />
          ) : (
            <CloseIcon color={'feedback.icon.negative.intense'} size={'small'} />
          )}
        </BooleanIconContainer>
      )
    ) : (
      '-'
    )}
  </Box>
);

export const DetailsContainer = ({
  children,
  onDismiss,
}: PropsWithChildren<{ onDismiss: () => void }>) => (
  <Box>
    <Box paddingBottom={'spacing.5'}>
      <IconButton
        size={'medium'}
        emphasis={'subtle'}
        icon={CloseIcon}
        onClick={onDismiss}
        accessibilityLabel="Close Details View"
      />
    </Box>
    <Box paddingX={'spacing.4'}>{children}</Box>
  </Box>
);

export const DetailsSection = ({ children }: PropsWithChildren<{}>) => (
  <Box display={'flex'} flexDirection={'column'} gap={'spacing.6'}>
    {children}
  </Box>
);

export const DetailsSectionItemsContainer = ({ children }: PropsWithChildren<{}>) => (
  <Box display={'flex'} gap={'spacing.3'} flexDirection={'column'}>
    {children}
  </Box>
);

export const DetailsFooter = ({
  isSticky = false,
  children,
}: PropsWithChildren<{ isSticky: boolean }>) => (
  <Box position={isSticky ? 'sticky' : 'fixed'} bottom={isSticky ? 'spacing.0' : undefined}>
    {children}
  </Box>
);

export const DetailsHeader = ({
  heading,
  description,
  isActive,
  isPredefined,
}: {
  heading: string;
  description: string;
  isActive: boolean;
  isPredefined: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
      <Heading size={'large'}>{heading}</Heading>
      {description && (
        <Box display={'flex'}>
          {isExpanded ? (
            <Text size="small" color="surface.text.gray.muted">
              {description}
              {isExpanded && (
                <Link onClick={() => setIsExpanded(false)} marginLeft={'spacing.5'}>
                  less
                </Link>
              )}
            </Text>
          ) : (
            <>
              <Text size="small" color="surface.text.gray.muted" truncateAfterLines={1}>
                {description}
              </Text>
              {description.length > MIN_COLLAPSIBLE_LENGTH && (
                <Link onClick={() => setIsExpanded(true)}>more</Link>
              )}
            </>
          )}
        </Box>
      )}
      <Box display={'flex'} gap={'spacing.3'} paddingTop={'spacing.3'}>
        <ActivityBadge isActive={isActive} />
        {isPredefined && <Badge color={'information'}>Predefined</Badge>}
      </Box>
    </Box>
  );
};

export const DetailsSectionHeading = ({ icon, label }: { icon: ReactNode; label: string }) => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={'spacing.2'}>
      <IconContainer>{icon}</IconContainer>
      <Text weight="semibold" size={'large'}>
        {label}
      </Text>
      <Text size={'large'} weight={'medium'} color={'surface.text.gray.muted'}>
        details
      </Text>
    </Box>
  );
};

export const ActivityBadge = ({ isActive }: { isActive: boolean }) => {
  return (
    <Badge color={isActive ? 'positive' : 'information'}>{isActive ? 'Active' : 'Disabled'}</Badge>
  );
};

export const DetailsViewSkeleton = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <>
      <Box paddingX={'spacing.4'}>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
          <Skeleton height={'spacing.7'} width={'50%'} />
          <Skeleton height={'spacing.7'} />
          <Box display={'flex'} gap={'spacing.3'} paddingTop={'spacing.3'}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Skeleton height={'spacing.7'} width={'spacing.10'} key={index} />
            ))}
          </Box>
        </Box>
        <Divider marginY={'spacing.7'} />
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.6'}>
          <Skeleton height={'spacing.7'} width={'40%'} />
          <DetailsSkeleton rowCount={8} />
        </Box>
        <Divider marginY={'spacing.7'} />
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.6'}>
          <Skeleton height={'spacing.7'} width={'40%'} />
          <DetailsSkeleton rowCount={8} />
        </Box>
        <Divider marginY={'spacing.7'} />
        <Box display={'grid'} gridTemplateColumns={'0.60fr 0.40fr'} columnGap={'spacing.4'}>
          {Array.from({ length: 2 }).map((_, index) => (
            <Skeleton height={'spacing.7'} key={index} />
          ))}
        </Box>
      </Box>
    </>
  );
};
