import {
  ActionList,
  ActionListItem,
  Box,
  Dropdown,
  DropdownOverlay,
  Heading,
  InfoIcon,
  SelectInput,
  Switch,
  Text,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import React from 'react';
import { FlexiBenefitsSettings } from './types';
import { format, parse } from 'date-fns';
import { Reveal } from '../../ui/Reveal/Reveal';
import { dateFormats, monthsList } from 'utils/Dates';

interface GeneralSettingsProps {
  settings: FlexiBenefitsSettings;
  onChange: (key: keyof FlexiBenefitsSettings, value: string | boolean) => void;
  isLoading: boolean;
}

const GeneralSettings = ({ settings, onChange, isLoading }: GeneralSettingsProps) => {
  const getParsedFBPTaxableMonth = (value?: string) => {
    const date = parse(value || '', 'MM', new Date());
    return format(date, dateFormats.humanDate);
  };

  const getParsedHoldFBPReleaseMonth = (value?: string) => {
    const date = parse(value || '', 'MM', new Date());
    return format(date, dateFormats.longMonth);
  };

  return (
    <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="500px" paddingY="spacing.11">
      <Heading size="small">⚙️ General</Heading>
      <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.7">
        <Box
          borderWidth="thin"
          borderColor="surface.border.gray.muted"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Consider FBP allowances to be taxable by default
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                By default the flexible component will not be tax exempted and will be added to the
                employee's taxable salary
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.FbpDefaultTaxable}
              size="small"
              accessibilityLabel="FBP Allowance Toggle"
              alignSelf="flex-start"
              onChange={({ isChecked }) => onChange('FbpDefaultTaxable', isChecked)}
            />
          </Box>
          <Reveal isOpen={settings.FbpDefaultTaxable === false}>
            <Box paddingTop="spacing.7">
              <Box
                paddingTop="spacing.7"
                borderTopWidth="thin"
                borderColor="surface.border.gray.subtle">
                <Box display="flex" gap="spacing.8" justifyContent="space-between">
                  <Box>
                    <Text weight="semibold" color="surface.text.gray.muted">
                      FBP allowance will be taxable from this month onwards
                    </Text>
                    <Text
                      variant="caption"
                      marginTop="spacing.2"
                      color="surface.text.gray.disabled"
                      size="small">
                      If no month is selected below, it will automatically become taxable for March payroll
                    </Text>
                  </Box>
                  <Switch
                    isDisabled={isLoading}
                    isChecked={settings.isTaxableAfterMonthEnabled}
                    size="small"
                    accessibilityLabel="FBP allowance will be taxable after this month"
                    alignSelf="flex-start"
                    onChange={({ isChecked }) => onChange('isTaxableAfterMonthEnabled', isChecked)}
                  />
                </Box>
                <Reveal isOpen={settings.isTaxableAfterMonthEnabled}>
                  <Box paddingTop={'spacing.4'}>
                    <Box width="50%">
                      <Dropdown selectionType="single">
                        <SelectInput
                          isDisabled={isLoading}
                          isRequired
                          value={settings.fbpDefaultTaxableSettingAutoTurnOn || ''}
                          label={'Select month'}
                          placeholder="Select month"
                          onChange={({ values }) =>
                            onChange('fbpDefaultTaxableSettingAutoTurnOn', values[0])
                          }
                        />
                        <DropdownOverlay>
                          <ActionList>
                            {monthsList.map((e) => (
                              <ActionListItem key={e.name} title={e.name} value={e.index} />
                            ))}
                          </ActionList>
                        </DropdownOverlay>
                      </Dropdown>
                    </Box>
                    {settings.fbpDefaultTaxableSettingAutoTurnOn ? (
                      <Text
                        marginTop="spacing.4"
                        variant="caption"
                        color="interactive.text.information.subtle"
                        size="small">
                        {`⚡️ FBP allowance will be taxable from ${getParsedFBPTaxableMonth(
                          settings.fbpDefaultTaxableSettingAutoTurnOn,
                        )} onwards`}
                      </Text>
                    ) : null}
                  </Box>
                </Reveal>
              </Box>
            </Box>
          </Reveal>
        </Box>

        <Box
          borderColor="surface.border.gray.muted"
          borderWidth="thin"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Employees can choose the flexible benefits
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                Employees can select their flexible benefits and declare their custom amounts for
                flexible benefits
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.employeeCanDeclareFlexiBenefits}
              size="small"
              accessibilityLabel="Employees can choose the flexible benefits"
              alignSelf="flex-start"
              onChange={({ isChecked }) => onChange('employeeCanDeclareFlexiBenefits', isChecked)}
            />
          </Box>
          <Reveal isOpen={settings.employeeCanDeclareFlexiBenefits}>
            <Box paddingTop="spacing.7">
              <Box
                paddingTop="spacing.7"
                borderTopWidth="thin"
                borderColor="surface.border.gray.subtle"
                display="flex"
                gap="spacing.8"
                justifyContent="space-between">
                <Text weight="semibold" color="surface.text.gray.muted">
                  Only allow employees to select wallets and not declare flexible benefit amounts
                </Text>
                <Switch
                  isDisabled={isLoading}
                  isChecked={settings.employeeCanUpdateAmountInDeclaredFlexiBenefits === false}
                  size="small"
                  accessibilityLabel=" Only allow employee to select wallets and not declare amount"
                  alignSelf="flex-start"
                  onChange={({ isChecked }) =>
                    onChange('employeeCanUpdateAmountInDeclaredFlexiBenefits', !isChecked)
                  }
                />
              </Box>
            </Box>
          </Reveal>
        </Box>

        <Box
          borderWidth="thin"
          borderColor="surface.border.gray.muted"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Hold the unclaimed flexible benefits amount
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                Hold paying unclaimed flexible benefit payout till specified FY month. Without
                proofs, it won't show on employee's payslip till then
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.shouldHoldFlexiAmount}
              size="small"
              accessibilityLabel="Hold the unclaimed flexible benefits amount"
              alignSelf="flex-start"
              onChange={({ isChecked }) => onChange('shouldHoldFlexiAmount', isChecked)}
            />
          </Box>
          <Reveal isOpen={settings.shouldHoldFlexiAmount}>
            <Box paddingTop="spacing.7">
              <Box
                paddingTop="spacing.7"
                borderTopWidth="thin"
                borderColor="surface.border.gray.subtle">
                <Box display="flex" gap="spacing.8" justifyContent="space-between">
                  <Box>
                    <Text weight="semibold" color="surface.text.gray.muted">
                      Auto release unclaimed benefit at a certain month
                    </Text>
                    <Text
                      variant="caption"
                      marginTop="spacing.2"
                      color="surface.text.gray.disabled"
                      size="small">
                      If no month is selected below, it will automatically be released in March payroll
                    </Text>
                  </Box>
                  <Switch
                    isDisabled={isLoading}
                    isChecked={settings.isAutoReleaseEnabledForUnclaimedBenefits}
                    size="small"
                    accessibilityLabel="Auto release unclaimed benefit at a certain month"
                    alignSelf="flex-start"
                    onChange={({ isChecked }) =>
                      onChange('isAutoReleaseEnabledForUnclaimedBenefits', isChecked)
                    }
                  />
                </Box>
                <Reveal isOpen={settings.isAutoReleaseEnabledForUnclaimedBenefits}>
                  <Box paddingTop="spacing.4">
                    <Box width="50%">
                      <Dropdown selectionType="single">
                        <SelectInput
                          isDisabled={isLoading}
                          isRequired
                          value={settings.shouldHoldFlexiAmountSettingAutoTurnOff || ''}
                          label={'Select payroll month'}
                          placeholder="Select month"
                          onChange={({ values }) =>
                            onChange('shouldHoldFlexiAmountSettingAutoTurnOff', values[0])
                          }
                        />
                        <DropdownOverlay>
                          <ActionList>
                            {monthsList.map((e) => (
                              <ActionListItem key={e.name} title={e.name} value={e.index} />
                            ))}
                          </ActionList>
                        </DropdownOverlay>
                      </Dropdown>
                    </Box>
                    {settings.shouldHoldFlexiAmountSettingAutoTurnOff ? (
                      <Text
                        marginTop="spacing.4"
                        variant="caption"
                        color="interactive.text.information.subtle"
                        size="small">
                        {`⚡️ All Unclaimed flexible benefits will be released to the employee in ${getParsedHoldFBPReleaseMonth(
                          settings.shouldHoldFlexiAmountSettingAutoTurnOff,
                        )} Payroll. The income tax would be applicable basis the proof submission.`}
                      </Text>
                    ) : null}
                  </Box>
                </Reveal>
              </Box>
            </Box>
          </Reveal>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
