import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import MainCase from '../../ui/MainCase/index';
import styles from '../index.module.scss';
import BreadcrumbStyleHeader from '../components/BreadcrumbStyleHeader';
import { ExecutionStatusModal, STATUS_FAILED, STATUS_IN_PROGRESS } from '../components/ExecutionModals/index';
import ErrorBlock from '../components/ErrorBlock';
import StickyFooterModal from '../components/StickyFooterModal/index';
import { SecondaryButton } from 'components/ui/Button';
import { PrimaryButtonV2 } from '../../ui/Button/index';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from '../queries/queries';
import api from '../../../api/index';
import { AppError } from '../../AppError/index';
import { BulkUploadTypes, BulkUploadStatus } from 'constants/bulkUpload';
import BulkUploadsPolling from '../../BulkUploadsPolling/index';
import { BulkUploadsExecutionPollStatuses } from '../../../constants/bulkUpload';
import SalaryRevisionPreviewTable from './SalaryRevisionPreviewTable';
import { SuccessfullStatusModal } from '../components/SuccessfullModal';
import FileDownloadButton from '../components/DownloadButton';
import { getAppErrorDetails } from 'utils/AppError';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import useIsRlopEnabled from 'components/RunPayroll/utils/useIsRlopEnabled';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';


export enum BulkSalaryRevisionModals {
    SHOW_NO_MODALS = 0,
    SHOW_IN_PROGRESS_MODAL = 1,
    SHOW_PARTIAL_UPLOAD_MODAL = 2,
    SHOW_SUCCESS_MODAL = 3
}


export default function SalaryRevisionPreviewPage(): ReactElement {

    const [showConfirmModal, setConfirmModal] = useState(false);
    const history = useHistory();
    const [bulkUploadRecord, setBulkUploadRecord] = useState<BulkUploadDetailsSchemaContract[0] | null>(null);
    const [modalToDisplay, setModalToDisplay] = useState<BulkSalaryRevisionModals>(BulkSalaryRevisionModals.SHOW_NO_MODALS);
    const callPauseRef = useRef(true);
    const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
    const { isRlopFeatureEnabled } = useIsRlopEnabled();

    const needsApproval = actionsWithActiveWorkflows.includes(WorkflowTypesMap.SALARY_REVISION);

    const getBulkUploadRecord = useQuery(GET_BULK_UPLOAD_RECORD, () => {

        return api.bulkUploads.fetchBulkUploadRecordByType(BulkUploadTypes.TYPE_BULK_SALARY_REVISION);

    }, {
        onSuccess: (data) => {
            setBulkUploadRecord((data.length > 0) ? data[0] : null);
            if (localStorage.getItem('bulkAdditionDeductionId') === null) {
                const temp = data ? data[0].id : null;
                if (temp) {
                    localStorage.setItem('bulkAdditionDeductionId', temp.toString());
                }
                else {
                    history.push(routePaths.bulkUploads.bulkSalaryRevision.fileUpload);
                }
            }
        },
        onError: (error: typeof AppError) => {
            window.scrollTo(0, 0);
            const response = getAppErrorDetails(error);
            if (response.type === 403) {
                history.replace(routePaths.accessDenied);
            }
        }
    });

    const onConfirmClick = () => {
        if (bulkUploadRecord) {

            sessionStorage.removeItem('bulkUploadPollId');

            api.bulkUploads.sendExecuteRequest(bulkUploadRecord.id)
                .then((response) => {
                    setBulkUploadRecord(response[0] ?? bulkUploadRecord);
                    sessionStorage.setItem('bulkUploadPollId', String(response[0].id));
                })
                .catch((error: Error) => {
                });

        }
    }

    const onCancel = () => {
        setConfirmModal(false);
    }

    const onBack = () => {
        history.push(routePaths.bulkUploads.bulkSalaryRevision.fileUpload);
    }
    const onClick = () => {
        setModalToDisplay(BulkSalaryRevisionModals.SHOW_NO_MODALS);
        history.push(routePaths.bulkUploads.bulkSalaryRevision.fileUpload);
    }

    useEffect(() => {
        switch (bulkUploadRecord?.status) {

            case BulkUploadStatus.STATUS_VALIDATION_PENDING:
            case BulkUploadStatus.STATUS_VALIDATION_PROGRESS:
            case BulkUploadStatus.STATUS_VALIDATION_FAILED:
                history.push(routePaths.bulkUploads.bulkSalaryRevision.fileUpload);
                break;
            case BulkUploadStatus.STATUS_EXECUTION_PENDING:
            case BulkUploadStatus.STATUS_EXECUTION_PROGRESS:
                setModalToDisplay(BulkSalaryRevisionModals.SHOW_IN_PROGRESS_MODAL);
                break;
            case BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS:
            case BulkUploadStatus.STATUS_EXECUTION_FAILED:
                setModalToDisplay(BulkSalaryRevisionModals.SHOW_PARTIAL_UPLOAD_MODAL);
                break;
            case BulkUploadStatus.STATUS_EXECUTION_SUCCESS:
                (() => {
                    if (callPauseRef.current) {
                        callPauseRef.current = false;

                        setModalToDisplay(BulkSalaryRevisionModals.SHOW_SUCCESS_MODAL);

                    }
                })();
                break;
        }
    }, [bulkUploadRecord]);

    function renderModal(bulkUploadRecord: BulkUploadDetailsSchemaContract[0], modalToDisplay: BulkSalaryRevisionModals) {
        switch (modalToDisplay) {

            case BulkSalaryRevisionModals.SHOW_IN_PROGRESS_MODAL:
                return <ExecutionStatusModal
                    mainText={'Processing your uploads'}
                    subText={`Processing...${(bulkUploadRecord.percentage_completed !== null && bulkUploadRecord.percentage_completed !== undefined) ? bulkUploadRecord.percentage_completed + '%' : ''}`}
                    status={STATUS_IN_PROGRESS} />;

            case BulkSalaryRevisionModals.SHOW_PARTIAL_UPLOAD_MODAL:
                return <ExecutionStatusModal
                    mainText={'Uploading failed'}
                    subText={`${bulkUploadRecord?.number_of_rows_with_error}/${bulkUploadRecord?.total_number_of_rows} employees failed to upload due to error in employee data. Please retry uploading after fixing the errors. `}
                    status={STATUS_FAILED}
                    errorBlock={
                        bulkUploadRecord &&
                            [BulkUploadStatus.STATUS_EXECUTION_FAILED, BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS].includes(bulkUploadRecord.status) &&
                            bulkUploadRecord.error_file_url ?
                            <ErrorBlock mainText={`⚠️ ${bulkUploadRecord?.number_of_rows_with_error} errors found`}
                                subText={'Please download error report > fix ALL errors > re-upload file '}
                                buttonText={'Get error report'}
                                downloadURL={bulkUploadRecord?.error_file_url}
                                onClick={onClick}
                            /> :
                            <ErrorBlock mainText={`⚠️ There was an unexpected error .`}
                                subText={'Please contact support or try again later'}
                                buttonText={'Go Back'}
                                onClick={onClick}
                            />} />;

            case BulkSalaryRevisionModals.SHOW_SUCCESS_MODAL:
                return (needsApproval ?
                    <SuccessfullStatusModal
                        mainText={`Request to update employee salaries & arrears has been sent successfully 🎉 `}
                        subText={`Track the request(s) from the Approvals page`}
                        buttonText='Go to Approvals'
                        callBackOnClick={() => {
                            setModalToDisplay(BulkSalaryRevisionModals.SHOW_NO_MODALS);
                            history.push(routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe);
                        }} /> :
                    <SuccessfullStatusModal
                        mainText={`Employee salaries & arrears are updated successfully 🎉 `}
                        subText={`View updated information on the Run Payroll page`}
                        callBackOnClick={() => {
                            setModalToDisplay(BulkSalaryRevisionModals.SHOW_NO_MODALS);
                            window.location.href = `/run-payroll?payroll_month=${bulkUploadRecord.input_config?.effectiveMonth}`;
                        }} />);

            default:
                return <></>;

        }
    }

    return (
        <>

            {bulkUploadRecord && renderModal(bulkUploadRecord, modalToDisplay)}

            {bulkUploadRecord?.id &&
                BulkUploadsExecutionPollStatuses.includes(bulkUploadRecord?.status ?? BulkUploadStatus.STATUS_VALIDATION_SUCCESS) &&
                <BulkUploadsPolling
                    id={bulkUploadRecord?.id.toString()}
                    action={'file-execution'}
                    setBulkUploadRecordData={setBulkUploadRecord}
                />}

            {bulkUploadRecord &&
                <>
                    <MainCase type="fullWidth" >
                        <div className={styles['centered-body-80']}>
                            <BreadcrumbStyleHeader bulkUploadRecord={bulkUploadRecord} bulkUploadTypeRequest={BulkUploadTypes.TYPE_BULK_SALARY_REVISION} />
                            <div className='flex justify-between'>
                                <div className={'text-4xl text-white font-semibold mt-20 mb-8'}>Confirm and verify</div>
                                <IsFeatureDisabledForRearch feature={['isArrearsDisabled']}>
                                <div className='mt-20'>
                                    {bulkUploadRecord?.id && (<FileDownloadButton
                                        href={`/v2/api/bulk-uploads/${bulkUploadRecord?.id}/salary-revision-arrears-report`}
                                        buttonText={"Download Arrear report"}
                                    />)}
                                </div>
                                </IsFeatureDisabledForRearch>
                            </div>
                            <SalaryRevisionPreviewTable
                                bulkUploadRecord={bulkUploadRecord}
                            />

                            <div className='flex flex-row mt-10'>
                                <SecondaryButton className={styles['button-left']} onClick={onBack}>Back</SecondaryButton>
                                <PrimaryButtonV2 className={styles['button-right']} onClick={() => setConfirmModal(true)}>Proceed to Confirm</PrimaryButtonV2>
                            </div>
                        </div>
                    </MainCase>
                    <StickyFooterModal warningText={
                        <div className='ml-4'>Employee salary will be updated from {
                            bulkUploadRecord?.input_config?.effectiveMonth
                                ? new Date(bulkUploadRecord?.input_config?.effectiveMonth).toLocaleDateString('en-us', { year: 'numeric', month: 'short' })
                                : new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short' })} Payroll.
                            <br />
                            Salary arrears will be added and paid when next month's payroll is executed.
                            {isRlopFeatureEnabled && <>
                            <br/>
                            <div>
                                Please note that once you add salary revision arrears, the existing arrears will be overridden.
                            </div>
                            </>}
                            
                            </div>
                    }
                        showModal={showConfirmModal}
                        onCancel={onCancel}
                        onConfirmClick={onConfirmClick}
                        confirmText={ needsApproval ? 'Request Approval →' : undefined}
                        showAlertWithWarningText={false}
                    />
                </>}

        </>

    );
}