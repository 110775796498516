import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';

import Breadcrumb from 'components/ui/Breadcrumb';
import { PrimaryButton } from 'components/ui/Button';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { CheckBox } from 'components/ui/Form/Input';
import { AppError } from 'utils/AppError';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import { useModal } from 'components/ui/Modals';
import DisableVerification from './DisableVerification';
import { FETCH_OTP_DATA } from '../Login';

import styles from './../index.module.scss';

const TwoFactorOrgSetting = () => {
  const is2FASettingEnabled = useSelector(loggedInUserSelectors.isTwoFactorAuthenticationEnabled);

  const [isEnabled, setIsEnabled] = useState<boolean>(is2FASettingEnabled);

  const { openModal } = useModal();

  const enable = useMutation(
    () => {
      return api.companyOnboarding.enable2FASetting();
    },
    {
      onSuccess: () => {
        showToastViaEvent({
            text: 'Two Factor Authentication Enabled Successfully',
            timeout: 5000,
            type: 'success',
            icon: 'success',
        });
        goToSetting();
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const { isLoading, data } = useQuery(
    FETCH_OTP_DATA,
    () => {
      return api.login.getUserData();
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );

  const goToSetting = () => {
    window.location.href = '/settings';
  };
  
  const onSumbit = () => {
    if (is2FASettingEnabled == isEnabled) {
      goToSetting();
    } else if (isEnabled) {
      enable.mutate();
    } else {
        openModal(<DisableVerification email={data?.email ?? ''} isAuthenticationAppEnabled={!!data?.is_authenticator_enabled}/>)
    }
  };

  return (
    <>
      <Breadcrumb
        name="Two Factor Authentication"
        urlMaps={{
          Settings: `/settings`,
        }}
        className="pt-0 pb-0"
      />
      <div id="two-factor-authentication" className={styles['tfa-setting-form']}>
        <span>Two Factor Authentication</span>
        <div className="note mt-3 text-100">
          After enabling Two-Factor Authentication (2FA), users will have an additional login
          process involving either <strong>Email OTP</strong> or <strong>Authenticator app</strong>.
          This will help keep the account secure.
          <br />
          <br />
          The default 2FA authentication method will be Email. Users can also configure an authenticator app via their preferences.
     
          <br />
          <br /> 
          Two-Factor Authentication is compulsory for all user roles, except for employees.
 
        </div>
        <div>
          <CheckBox
            label="Enable Two Factor Authentication for employees"
            id="enable-two-factor"
            className={`mb-8 mt-2`}
            checked={isEnabled}
            disabled={enable.isLoading}
            onChange={(event) => {
              setIsEnabled(event.target.checked);
            }}
          />
          {
            <CheckBox
              label="Enable Two Factor Authentication for admin and other user roles"
              id="enable-two-factor-other"
              className="mb-8 mt-2"
              checked={true}
              disabled={true}
              title='System mandated'
            />
          }
        </div>
        <PrimaryButton
          onClick={onSumbit}
          isPending={enable.isLoading}
          size={'sm'}
          className={'mt-5'}>
          Continue
        </PrimaryButton>
      </div>
    </>
  );
};

export default TwoFactorOrgSetting;
