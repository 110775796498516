import React from 'react';
import {
    Badge,
    ActionListItemBadge,
    StarIcon,
    TooltipInteractiveWrapper,
    Tooltip
} from '@razorpay/blade/components';


export const BladeProPill =
    <Tooltip content='This feature is available in PRO plan' placement='bottom'>
        <TooltipInteractiveWrapper>
            <Badge color='notice' icon={StarIcon}>
                PRO
            </Badge>
        </TooltipInteractiveWrapper>
    </Tooltip>

export const BladeProPillActionListItem =
    <ActionListItemBadge color={'notice'} icon={StarIcon}>
        PRO
    </ActionListItemBadge>