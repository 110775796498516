import Allowance, { amountTypes, taxableTypes } from 'utils/Allowance';

export const concatenateAllowances = (allowances: Allowance[], customAllowancesLimit: number) => {
  const customAllowances = [...allowances];
  const remainingAllowanceCount = customAllowancesLimit - customAllowances.length;
  for (let i = 0; i < remainingAllowanceCount; i++) {
    customAllowances.push(
      new Allowance({
        amountType: amountTypes.ALLOWANCE_AMOUNT_TYPE_PERCENTAGE,
        taxableType: taxableTypes.TYPE_YES,
        name: '',
      }),
    );
  }
  return customAllowances;
};
