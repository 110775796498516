import {
  Box,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Button,
} from '@razorpay/blade/components';
import React from 'react';
import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';
import { PayrollProgress } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/PayrollProgress';
import {
  compliancePaymentModalsZIndex,
  paymentNamesMap,
} from 'components/RunPayroll/ComplianceActions/components/data';
import { PaymentType } from 'components/RunPayroll/ComplianceActions/components/types';
import { ResetLocalOverridingStyles } from 'components/RunPayroll/ComplianceActions/styles';

export const PayrollPartiallyProcessedModal = ({
  isOpen,
  onDismiss,
  onContinue,
  action,
  paymentType,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onContinue: () => void;
  action: GetComplianceActionsSchemaContract['actions']['pay-pt'][string];
  paymentType: PaymentType;
}) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} zIndex={compliancePaymentModalsZIndex}>
      <ModalHeader title={`Initiate ${paymentNamesMap[paymentType]}?`} />
      <ModalBody>
        <Box
          backgroundColor="surface.background.gray.moderate"
          borderRadius="medium"
          paddingTop="spacing.5"
          overflow="hidden"
          marginBottom="spacing.6">
          <PayrollProgress action={action} hideAlert />
        </Box>
        <Text size="small" color="surface.text.gray.muted">
          Employees whose payroll has not been processed will not have their compliance completed by
          us.
        </Text>
        <Text size="small" color="surface.text.gray.muted" marginTop="spacing.4">
          Please ensure all pending payrolls are processed to avoid manual compliance payments.
        </Text>
      </ModalBody>
      <ModalFooter>
        <ResetLocalOverridingStyles>
          <Box display="flex" gap="spacing.5" justifyContent="flex-end">
            <Button variant="tertiary" onClick={onDismiss}>
              Cancel
            </Button>
            <Button onClick={onContinue}>Initiate</Button>
          </Box>
        </ResetLocalOverridingStyles>
      </ModalFooter>
    </Modal>
  );
};
