const KYC = {
  STATUS_NOT_INITIATED: 0,
  STATUS_PENDING: 1,
  STATUS_ON_HOLD: 2,
  STATUS_VERIFIED: 3,
  STATUS_REJECTED: 4,
};

Object.freeze(KYC);

export default KYC;
