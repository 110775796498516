import React from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import { Box, Button, Text, Heading } from '@razorpay/blade/components';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { SyncInProgressLazy } from '../components/SyncInProgressLazy';
import { Redirect, useHistory } from 'react-router-dom';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';

const SyncInProgress = () => {
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const history = useHistory();
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);

  const { knitHrmsIntegrationQuery, isSyncInProgress, isIntegrationActive, retryLimitExhausted } =
    useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const { integrationProgress } = useIntegrationProgress();

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  const handleBackToDashboardClick = () => {
    window.location.href = '/dashboard';
  };

  if (knitHrmsIntegrationQuery.isSuccess && !isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  return (
    (<IntegrationWindow
      progress={integrationProgress}
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={org?.name ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="spacing.10"
        maxWidth="592px">
        <SyncInProgressLazy knitHrmsApp={knitHrmsApp} />
        <Box display="flex" flexDirection="column" gap="spacing.5">
          <Heading textAlign="center" size="large" color="surface.text.gray.muted">
            Syncing data from {hrmsAppMetadata.name} to RazorpayX Payroll
          </Heading>
          <Text textAlign="center" margin={['0px', 'spacing.6']} color="surface.text.gray.disabled">
            It will be done in few minutes. You can navigate away from this screen. We will notify
            you once the integration is complete
          </Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="tertiary" onClick={handleBackToDashboardClick}>
            Back to dashboard
          </Button>
        </Box>
      </Box>
    </IntegrationWindow>)
  );
};

export { SyncInProgress };
