import React, { useEffect } from 'react';
import { ChatContextType, useChatContext } from './ChatContext';
import { Box, Text } from '@razorpay/blade/components';
import { ChatMessageBox } from './components/ChatMessageBox';
import { BotThumbnail } from './components/BotThumbnail';
import { UserThumbnail } from './components/UserThumbnail';
import RegimeBreakup from './components/RegimeBreakup';
import TaxSavingBreakup from './components/TaxSavingBreakup';
import { ChatMessageTypes } from './constants';
import { useChatHandler } from './useChatHandler';
import { useChatPolling } from './useChatPolling';
import { StreamingText } from './components/StreamingText';
import { ProcessingAnimation, ProcessingAnimationMessage } from './components/ProcessingAnimation';
import ErrorDisplay from './components/ErrorDisplay';
import { getTextStreamDuration } from './utils';
import { useTextSize } from './useTextSize';

const ChatRenderer = () => {
  const { messages, isBotProcessing, userName, isPolling, lastMessageRef } =
    useChatContext() as ChatContextType;
  useChatPolling({ enabled: isPolling });
  useChatHandler();
  const textSize = useTextSize();

  useEffect(() => {
    setTimeout(() => {
      lastMessageRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: isBotProcessing ? 'end' : 'nearest',
      });
    }, 100);
  }, [messages, lastMessageRef, isBotProcessing]);

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.6" marginTop="spacing.6">
      {messages.map(({ initiator, type, messageData }, index) => {
        const shouldAddRef = index === messages.length - 1 && !isBotProcessing;
        return (
          (<ChatMessageBox
            key={index}
            thumbNail={
              initiator === 'bot' ? (
                <BotThumbnail
                  isStreaming={
                    (type === ChatMessageTypes.text ||
                      type === ChatMessageTypes.calculation ||
                      type === ChatMessageTypes.knowMore) &&
                    initiator === 'bot'
                  }
                  streamDuration={
                    type === ChatMessageTypes.text && initiator === 'bot'
                      ? getTextStreamDuration(messageData)
                      : undefined
                  }
                  height="auto"
                />
              ) : (
                <UserThumbnail name={userName} />
              )
            }
            withBackground={initiator === 'user'}
            ref={shouldAddRef ? lastMessageRef : null}>
            {(() => {
              // @TODO payroll-ai-assistant - replace the cases with the correct UI component
              switch (type) {
                case ChatMessageTypes.text:
                  return initiator === 'bot' ? (
                    <StreamingText>{messageData}</StreamingText>
                  ) : (
                    <Text size={textSize} color="surface.text.gray.subtle">
                      {messageData}
                    </Text>
                  );
                case ChatMessageTypes.regimeBreakup:
                  return <RegimeBreakup {...messageData} />;
                case ChatMessageTypes.taxBreakup:
                  return <TaxSavingBreakup {...messageData} />;
                case ChatMessageTypes.processing:
                  return <ProcessingAnimation {...messageData} />;
                case ChatMessageTypes.knowMore:
                case ChatMessageTypes.calculation:
                  return <StreamingText>{messageData}</StreamingText>;
                case ChatMessageTypes.errorDisplay:
                  return <ErrorDisplay {...messageData} />;
                default:
                  return null;
              }
            })()}
          </ChatMessageBox>)
        );
      })}
      {isBotProcessing && <ProcessingAnimationMessage ref={lastMessageRef} />}
    </Box>)
  );
};

export { ChatRenderer };
