import { AttendanceRecord, AttendanceResponse } from 'schemas/Attendance';
import { Text } from '@razorpay/blade/components';
import {
  addYears,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfYear,
  isSameMonth,
  parse,
  startOfYear,
  subYears,
} from 'date-fns';
import { ATTENDANCE_STATUS } from '../constants';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

type TextColorProp = React.ComponentProps<typeof Text>['color'];

export const CALENDAR_STATUS = {
  NULL: 0,
  LEAVE: 1,
  PRESENT: 2,
  HOLIDAY: 3,
  REQUESTED: 4,
};

type AttendanceRecordMap = Map<string, AttendanceRecord>;

export function preprocessAttendanceRecords(data: AttendanceResponse): AttendanceRecordMap {
  const recordMap = new Map<string, AttendanceRecord>();
  data.forEach((record) => {
    if (record.date) {
      const dateKey = getFormattedDateValue({
        date: record.date.date,
        formatString: dateFormats.yearMonthDate,
      });
      recordMap.set(dateKey, record);
    }
  });

  return recordMap;
}

export function getAttendanceStatus(
  date: Date,
  currentDate: Date,
  attendanceMap: Map<string, AttendanceRecord> | undefined,
): { status: number; description: string } {
  if (!attendanceMap) {
    return { status: CALENDAR_STATUS.NULL, description: '' };
  }
  const dateKey = getFormattedDateValue({ date, formatString: dateFormats.yearMonthDate });
  const record = attendanceMap.get(dateKey);

  if (!record || !isSameMonth(date, currentDate)) {
    return { status: CALENDAR_STATUS.NULL, description: '' };
  }

  // Check for requested status first
  if (record.requestedStatus !== null) {
    switch (record.requestedStatus) {
      case ATTENDANCE_STATUS.STATUS_PRESENT:
        return { status: CALENDAR_STATUS.REQUESTED, description: 'Present Requested' };
      case ATTENDANCE_STATUS.STATUS_LEAVE:
      case ATTENDANCE_STATUS.STATUS_HALF_DAY:
      case ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE:
      case ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY:
        const requestedLeaveDescription = record.requestedStatusDescription || 'Unknown';
        return {
          status: CALENDAR_STATUS.REQUESTED,
          description: `${requestedLeaveDescription} Requested`,
        };
    }
  }

  // If no requested status, check the current status
  const leaveDescription = record.statusDescription || 'Unknown';
  switch (record.status) {
    case ATTENDANCE_STATUS.STATUS_PRESENT:
      return { status: CALENDAR_STATUS.PRESENT, description: leaveDescription };
    case ATTENDANCE_STATUS.STATUS_LEAVE:
    case ATTENDANCE_STATUS.STATUS_HALF_DAY:
    case ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE:
    case ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY:
      return { status: CALENDAR_STATUS.LEAVE, description: leaveDescription };
    case ATTENDANCE_STATUS.STATUS_HOLIDAY:
      return { status: CALENDAR_STATUS.HOLIDAY, description: `Holiday (${record.remarks})` };
    case ATTENDANCE_STATUS.STATUS_HOLIDAY_WEEKEND:
      return { status: CALENDAR_STATUS.HOLIDAY, description: leaveDescription };
    default:
      return { status: CALENDAR_STATUS.NULL, description: '' };
  }
}

export const calendarStatusToColor = (status: number): TextColorProp => {
  switch (status) {
    case CALENDAR_STATUS.LEAVE:
      return 'feedback.text.negative.intense';
    case CALENDAR_STATUS.PRESENT:
      return 'feedback.text.positive.intense';
    case CALENDAR_STATUS.HOLIDAY:
      return 'feedback.text.neutral.subtle';
    case CALENDAR_STATUS.REQUESTED:
      return 'feedback.text.notice.intense';
    default:
      return 'feedback.text.neutral.intense';
  }
};

// output: ['January', 'February', 'March', 'April', 'May', 'June',...]
export function getMonthNames(): string[] {
  const year = new Date(); // Could be any year since we just want month names
  const interval = {
    start: startOfYear(year),
    end: endOfYear(year),
  };

  return eachMonthOfInterval(interval).map((date) =>
    getFormattedDateValue({ date, formatString: dateFormats.longMonth }),
  );
}

// output: ['2021', '2022', '2023', '2024', '2025', '2026',...]
export function getYearRange(lookBack: number = 10, lookForward: number = 1): string[] {
  const now = new Date();
  const interval = {
    start: subYears(now, lookBack),
    end: addYears(now, lookForward),
  };

  return eachYearOfInterval(interval).map((date) => date.getFullYear().toString());
}

export function monthNameToNumber(monthName: string): number {
  const date = parse(monthName, 'MMMM', new Date());
  return date.getMonth();
}

export const DAYS_OF_THE_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
