import React, {FormEvent, useState} from "react";
import {Card} from "../../../../ui/Card";
import styles from "./index.module.scss";
import {StandardButton} from "../../../../ui/Button";
import {useMutation, useQuery} from "react-query";
import api from "../../../../../api";
import {AppError} from "../../../../../utils/AppError";
import { useHistory } from "react-router-dom";
import {routePaths} from "../../../../Routes/data";
import {JibbleAttendanceSettingsSchemaContract} from "../../../../../schemas/JibbleAttendanceSettingsSchema";
import {JIBBLE_GET_ATTENDANCE_SETTINGS} from "../../utils/queries";
import APIError from "../../../../../types/apiError";
import Spinner from "../../../../ui/Spinner";
import {timeThresholdOptions} from "../../constants";
import Checkbox from "components/ui/Checkbox";
import TimeThresholdInput from "./TimeThresholdInput";
import cx from "classnames";
import {WarningAlert} from "../../../../ui/Alert";

const AttendanceSettingsForm = ({isOnboardingComplete}: {isOnboardingComplete: boolean}) => {
    const history = useHistory();
    const [fullDayThreshold, setFullDayThreshold] = useState('1.0');
    const [halfDayThreshold, setHalfDayThreshold] = useState('1.0');
    const [calculateLopOnWorkingDays, setCalculateLopOnWorkingDays] = useState(false);
    const [showAttendanceInPayslip, setShowAttendanceInPayslip] = useState(true);

    const updateAttendanceSettingsMutation = useMutation(
        (requestData: JibbleAttendanceSettingsSchemaContract['integrationConfig']) =>
            api.jibbleSettings.updateAttendanceSettings(requestData), {
            onSuccess: () => {
                if (isOnboardingComplete) {
                    window.location.href = '/settings';
                } else {
                    history.push(routePaths.settings.jibble.onboarding.syncEmployees);
                }
            },
            onError: (error: typeof AppError) => {},
        }
    );

    const isFormBeingSubmitted = updateAttendanceSettingsMutation.isLoading;

    const { isLoading } = useQuery(
        JIBBLE_GET_ATTENDANCE_SETTINGS,
        () => { return api.jibbleSettings.getAttendanceSettings(); },
        {
            onError: (error: APIError) => {},
            onSuccess: data => {
                setFullDayThreshold(data.integrationConfig.fullDayThreshold.toFixed(1));
                setHalfDayThreshold(data.integrationConfig.halfDayThreshold.toFixed(1));
                setCalculateLopOnWorkingDays(data.integrationConfig.calculateLopOnWorkingDays);
                setShowAttendanceInPayslip(data.integrationConfig.showAttendanceInPayslip);
            }
        },
    )

    if (isLoading || updateAttendanceSettingsMutation.isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    const isInputValid = parseFloat(fullDayThreshold) >= parseFloat(halfDayThreshold);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        updateAttendanceSettingsMutation.mutate({
            halfDayThreshold: parseFloat(halfDayThreshold),
            fullDayThreshold: parseFloat(fullDayThreshold),
            calculateLopOnWorkingDays: calculateLopOnWorkingDays,
            showAttendanceInPayslip: showAttendanceInPayslip
        });
    }

    return(
        <form className="mt-6 w-full" onSubmit={handleSubmit}>
            <div className={cx({ 'mb-12': isOnboardingComplete, 'mb-16': !isOnboardingComplete })}>
                <h2 className="font-bold mb-3">Minimum shift durations configuration</h2>
                <p className="text-1.5xl opacity-75 text-left">
                    Setup shift durations to calculate employee’s attendance and leaves
                </p>
            </div>
            <TimeThresholdInput
                id="full-day-threshold"
                name="full-day-threshold"
                value={fullDayThreshold}
                options={timeThresholdOptions}
                onChange={(value) => {setFullDayThreshold(value)}}
                title="Minimum shift duration for a full day"
                description="Minimum number of hours an employee needs to clock in to complete a full day"
                isOnboardingComplete={isOnboardingComplete}
            />
            <TimeThresholdInput
                id="half-day-threshold"
                name="half-day-threshold"
                value={halfDayThreshold}
                options={timeThresholdOptions}
                onChange={(value) => {setHalfDayThreshold(value)}}
                title="Minimum shift duration for a half day"
                description="Minimum number of hours an employee needs to clock in to complete a half day"
                isOnboardingComplete={isOnboardingComplete}
            />
            {
                isInputValid && (
                    <Card className={cx(
                        "w-full p-6 shadow-none",
                        {"mt-8 mb-16": isOnboardingComplete, "mt-20": !isOnboardingComplete}
                    )}>
                        <h1 className="text-1.5xl opacity-75 font-bold mb-2">⚡ Organisation configuration summary</h1>
                        <ul className={styles['settingDescriptionList']}>
                            <li>A leave will be deducted if an employee clocks in {' '}
                                <strong>less than {halfDayThreshold} hours.</strong>
                            </li>
                            <li>
                                A half day will be deducted if an employee clocks in {' '}
                                <strong>more than {halfDayThreshold} hours but less than {fullDayThreshold} hours. </strong>
                            </li>
                        </ul>
                    </Card>
                )
            }
            {
                !isInputValid && (
                    <WarningAlert className={cx({ 'mb-10': isOnboardingComplete}, "text-left" )}>
                        Please select a half day shift duration less than or equal to the full day shift duration
                    </WarningAlert>
                )
            }
            {
                isOnboardingComplete && (
                    <>
                        {/*<Checkbox*/}
                        {/*    id="attendance-on-payslip"*/}
                        {/*    label={*/}
                        {/*        <span className="font-bold">Show attendance on payslips</span>*/}
                        {/*    }*/}
                        {/*    checked={showAttendanceInPayslip}*/}
                        {/*    onChange={(event) => {*/}
                        {/*        setShowAttendanceInPayslip(event.target.checked)*/}
                        {/*    }}*/}
                        {/*    disabled={isFormBeingSubmitted}*/}
                        {/*/>*/}
                        <Checkbox
                            id="calculate-lop-on-working-days"
                            label={
                                <span className="font-bold">Calculate loss-of-pay on working days instead of total days in a month</span>
                            }
                            checked={calculateLopOnWorkingDays}
                            onChange={(event) => {
                                setCalculateLopOnWorkingDays(event.target.checked);
                            }}
                            disabled={isFormBeingSubmitted}
                        />
                    </>
                )
            }
            <StandardButton
                className={`mt-24 mr-0 ${!isOnboardingComplete ? "float-right" : ""}`}
                type="submit"
                disabled={!isInputValid}
            >
                Continue
            </StandardButton>
        </form>
    );
}

export default AttendanceSettingsForm;