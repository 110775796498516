import { routePaths } from 'components/Routes/data';

export const verifyCompanyDetailsScreensData = {
  [routePaths.kyc.companyDetailsVerification.root]: {
    main: {
      title: '🏢 Verify Company Details',
      description: 'Automatically verify your company with GSTIN ⚡️',
    },
  },
  [routePaths.kyc.companyDetailsVerification.gstin]: {
    main: {
      title: '🏢 Verify Company Details',
      description: 'Automatically verify your company with GSTIN ⚡️',
    },
  },
  [routePaths.kyc.companyDetailsVerification.cinOrLlpin]: {
    main: {
      title: '🏢 Verify Company Details',
      description: 'Automatically verify your company with CIN/LLPIN ⚡️',
    },
  },
  [routePaths.kyc.companyDetailsVerification.manual]: {
    main: {
      title: '🏢 Verify Company Details',
    },
  },
  [routePaths.kyc.companyDetailsVerification.addAddress]: {
    main: {
      title: '🏢 Add address',
    },
  },
  [routePaths.kyc.companyDetailsVerification.pan]: {
    main: {
      title: '🏢 Verify Company Details',
      description: 'Automatically verify company details with PAN ⚡️',
    },
  },
};
