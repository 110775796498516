import React, { ReactElement } from 'react';
import {
  EsicRegistrationSchemaContract,
} from 'schemas/EsicRegistrationSchema';
import { TableWithLoader } from 'components/ui/Table';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import MailIcon from '../../PfRegistrationDashboard/MailIcon';
import { ActionType, INITIATE, UPDATE, RETRY, UPDATE_CRED, FAILED, NOT_INITIATED } from '../constants/constants';
import classes from '../index.module.scss';
import ButtonWrapper from '../../PfRegistrationDashboard/TableView/ButtonWrapper';

const statusComponentMapping = {
  DISABLED: () => <Pill type={pillClassTypeMap.warning} content="Disabled" />,
  REGISTERED: () => <Pill type={pillClassTypeMap.success} content="Success" />,
  IN_PROGRESS: () => <Pill type={pillClassTypeMap.neutral} content="In Progress" />,
  FAILED: () => <Pill type={pillClassTypeMap.danger} content="Failed" />,
  NOT_INITIATED: () => <Pill type={pillClassTypeMap.default} content="Not Initiated" />,
};

function createMailtoLink(row: any) {
  let subject = encodeURIComponent(
    `ESIC Registration Failure | ${row.organization_id} | ${row.employee_id_prefix}`,
  );
  return `mailto:xpayroll@razorpay.com?subject=${subject}`;
}

function TableView({
  data,
  isLoading,
  handleButtonClick,
}: {
  data: EsicRegistrationSchemaContract['data'];
  isLoading: boolean;
  handleButtonClick: (people_id: number, action: ActionType) => void;
}): ReactElement {
  return (
    <TableWithLoader
      isLoading={isLoading}
      showAlternateColors={true}
      className={`clear-both mt-8 export w-full ${classes['esic_registration']}`}>
      <thead>
        <tr>
          <th>Employee ID</th>
          <th>Name</th>
          <th>Registration Status</th>
          <th>Remarks</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>
              <a href={`/employeeDetail?userId=${row.people_id}`}>{row.employee_id_prefix}</a>
            </td>
            <td>
              <a href={`/employeeDetail?userId=${row.people_id}`}>{row.name}</a>
            </td>
            <td>
              {statusComponentMapping[row.registration_status]
                ? statusComponentMapping[row.registration_status]()
                : null}
            </td>
            <td>{row.registration_status === FAILED && row.friendly_error}</td>
            <td>
              {row.registration_status === NOT_INITIATED && row.esic_registration_status_id &&(
                <ButtonWrapper
                  onClick={() => handleButtonClick(row.people_id, UPDATE)}
                  disabled={false}
                >
                  UPDATE REGISTRATION
                </ButtonWrapper>
              )}
              {row.registration_status === NOT_INITIATED  && !row.esic_registration_status_id &&(
                  <ButtonWrapper
                      onClick={() => handleButtonClick(row.people_id, UPDATE)}
                      disabled={false}
                  >
                    START REGISTRATION
                  </ButtonWrapper>
              )}
              {row.registration_status === FAILED && (
                <>
                  {(() => {
                    switch (row.error_category) {
                      case 'update_retry_registration':
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, UPDATE)}
                            disabled={false}
                          >
                            UPDATE INFORMATION
                          </ButtonWrapper>
                        );
                        case 'invalid_cred':
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, UPDATE_CRED)}
                            disabled={false}
                          >
                            UPDATE
                          </ButtonWrapper>
                        );
                      case 'contact_xpayroll_support_or_retry':
                        const mailtoLink = createMailtoLink(row);
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <ButtonWrapper onClick={() => window.open(mailtoLink, '_blank')} disabled={false}>
                              <MailIcon className={`${classes['mail-icon']} ${classes['blue-fill']}`} />
                            </ButtonWrapper>
                            <div style={{ width: '10px' }}></div>
                            <ButtonWrapper onClick={() => handleButtonClick(row.people_id, RETRY)} disabled={false}>
                              RETRY REGISTRATION
                            </ButtonWrapper>
                          </div>
                        );
                      case 'contact_xpayroll_support':
                        let mailtoLinkSupport = createMailtoLink(row);
                        return (
                          <ButtonWrapper
                            onClick={() => window.open(mailtoLinkSupport, '_blank')}
                            disabled={false}
                          >
                            <MailIcon className={`${classes['mail-icon']} ${classes['blue-fill']}`} />
                          </ButtonWrapper>
                        );
                      default:
                        return (
                          <ButtonWrapper
                            onClick={() => handleButtonClick(row.people_id, RETRY)}
                            disabled={false}
                          >
                            RETRY REGISTRATION
                          </ButtonWrapper>
                        );
                    }
                  })()}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </TableWithLoader>
  );
}

export default TableView;
