import {
  ActionList,
  ActionListItem,
  ActionListItemIcon,
  Box,
  ChevronDownIcon,
  Dropdown,
  DropdownButton,
  DropdownOverlay,
  Heading,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

const Header = (): ReactElement => {
  const history = useHistory();
  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
      <Heading size="large">Salary Component Library</Heading>
      <Dropdown>
        <DropdownButton icon={ChevronDownIcon} iconPosition={'right'}>
          Create Component
        </DropdownButton>
        <DropdownOverlay>
          <ActionList>
            <ActionListItem
              leading={<ActionListItemIcon icon={PlusCircleIcon} />}
              onClick={({ name, value }) => {
                history.push(routePaths.salaryComponents.create.earnings.root);
              }}
              title="Earnings"
              value="earnings"
            />
            <ActionListItem
              leading={<ActionListItemIcon icon={MinusCircleIcon} />}
              onClick={({ name, value }) => {
                history.push(routePaths.salaryComponents.create.deductions.root);
              }}
              title="Deductions"
              value="deductions"
            />
          </ActionList>
        </DropdownOverlay>
      </Dropdown>
    </Box>
  );
};

export default Header;
