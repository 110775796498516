import React, { useState } from 'react';

import { StandardButton } from 'components/ui/Button';
import { getStaticMediaUrl } from 'utils/Urls';
import arrowIcon from 'assets/icon--down-arrow-white.png';
import { PayrollMetaData, SkipTypes } from '../utils/types';
import { useModal } from 'components/ui/Modals';
import SkipPayroll from '../Popups/SkipPayroll';
import { useErrorMessageContext } from '../contexts/RunPayrollErrorContext';
import { UseIdsType } from '../utils/useIds';
import { trackXPayrollEvent } from 'utils/analytics';

import styles from './index.module.scss';

const SkipButton = ({
  selectedIds,
  activePayrollMeta,
  payrollMonth,
}: {
  selectedIds: UseIdsType | null;
  activePayrollMeta: PayrollMetaData[];
  payrollMonth: string;
}) => {
  const [isOptionsOpened, setIsOptionsOpened] = useState<boolean>(false);
  const { openModal } = useModal();
  const errCtx = useErrorMessageContext();

  if (!selectedIds?.value.length) {
    return null;
  }

  const toggleDropdown = () => {
    setIsOptionsOpened((prev) => !prev);
  };

  const selectedList = activePayrollMeta.filter((meta) => selectedIds.value.includes(meta.id));

  const selectedIsCancelledList = selectedList.map((meta) => meta.isCancelled);

  const unselectedList = activePayrollMeta.filter((meta) => !selectedIds.value.includes(meta.id));

  const unselectedIsCancelledList = unselectedList.map((meta) => meta.isCancelled);

  const isAllSelectedSkipped = !selectedIsCancelledList.includes(false);
  const isAllSelectedUnskipped = !selectedIsCancelledList.includes(true);
  const isAllUnselectedSkipped = !unselectedIsCancelledList.includes(false);
  const isAllUnselectedUnskipped = !unselectedIsCancelledList.includes(true);

  const onSkipOrResume = () => {
    selectedIds.setValue([]);
  }

  const showConfirm = (type: string, exceptCase: boolean = false) => {
    trackXPayrollEvent(`runpayroll.${type === SkipTypes.SKIPPED ? 'skip_button_clicked' : 'resume_button_clicked'}`);
    const selectedIds = selectedList.map((meta) => meta.id);
    const unselectedIds = unselectedList.map((meta) => meta.id);
    openModal(
      <SkipPayroll
        payrollMonth={payrollMonth}
        type={type}
        skipIds={
          !exceptCase
            ? selectedIds
            : unselectedIds
        }
        exceptSelected={exceptCase ? selectedIds.length : null}
        onError={errCtx?.setError}
        onSuccess={onSkipOrResume}
      />,
    );
  };

  return (
    <>
      <div className={styles['runpayroll-skip-button']}>
        <StandardButton
          onClick={() => showConfirm(isAllSelectedSkipped ? SkipTypes.RESUME : SkipTypes.SKIPPED)}>
          {`${isAllSelectedSkipped ? 'RESUME' : 'SKIP'} SELECTED (${selectedIds.value.length})`}
        </StandardButton>
        {((!isAllSelectedSkipped && !isAllSelectedUnskipped) ||
          !isAllUnselectedSkipped ||
          !isAllUnselectedUnskipped) && (
          <StandardButton onClick={toggleDropdown}>
            <img
              className={`inline-block transition duration-300 ${
                isOptionsOpened ? 'transform rotate-180 ' : ''
              }`}
              src={getStaticMediaUrl(arrowIcon)}
              width="12px"
            />
          </StandardButton>
        )}
        {isOptionsOpened && (
          <div className={styles['runpayroll-skip-options']}>
            {!isAllSelectedSkipped && !isAllSelectedUnskipped && (
              <div className={styles['runpayroll-skip-option']}  onClick={() => showConfirm(SkipTypes.RESUME)}>
                {`Resume Selected (${selectedIds.value.length})`}
              </div>
            )}
            {!isAllUnselectedSkipped && (
              <div className={styles['runpayroll-skip-option']} onClick={() => showConfirm(SkipTypes.SKIPPED, true)}>
                {`Skip All Except Selected (${activePayrollMeta.length - selectedIds.value.length})`}
              </div>
            )}
            {!isAllUnselectedUnskipped && (
              <div className={styles['runpayroll-skip-option']} onClick={() => showConfirm(SkipTypes.RESUME, true)}>
                {`Resume All Except Selected (${activePayrollMeta.length - selectedIds.value.length})`}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SkipButton;
