import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  Text,
  CloseIcon,
  VideoIcon,
  PaymentPagesIcon,
  CardHeader,
  CardHeaderLeading,
  CardHeaderTrailing,
  CardHeaderIconButton,
} from '@razorpay/blade/components';
import TileButton from './TileButton';
import VideoModal from 'components/ui/VideoModal';
import { helpArticles } from '../constants';

const WelcomeCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('viewed-scl-welcome-banner') === 'true') {
      setShowWelcomeBanner(false);
    }
  }, []);

  const onClose = () => {
    setShowWelcomeBanner(false);
    localStorage.setItem('viewed-scl-welcome-banner', 'true');
  };

  if (!showWelcomeBanner) {
    return null;
  }

  return (
    <Box marginBottom={'spacing.8'}>
      <Card>
        <CardHeader showDivider={false}>
          <CardHeaderLeading title="👋 Welcome to Salary Component Library" />
          <CardHeaderTrailing
            visual={
              <CardHeaderIconButton
                icon={CloseIcon}
                onClick={onClose}
                accessibilityLabel="Close welcome banner"
              />
            }
          />
        </CardHeader>
        <CardBody>
          <Box
            display={'flex'}
            paddingLeft={'spacing.3'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Box maxWidth={'556px'}>
              <Text color="surface.text.gray.muted">
                Now Effortlessly Create & Customise Salary Components To Fit Your Organisation's
                Needs. Get Started By Watching A Quick Video.{' '}
              </Text>
            </Box>
            <Box display={'flex'} flexDirection={'row'} gap={'spacing.5'}>
              <TileButton
                icon={<VideoIcon size={'xlarge'} color={'surface.icon.onSea.onIntense'} />}
                title="Watch a video"
                onClick={() => setIsModalOpen(true)}
                backgroundColor={'surface.background.sea.subtle'}
              />
              <TileButton
                icon={<PaymentPagesIcon size={'xlarge'} color={'surface.icon.onCloud.onSubtle'} />}
                title="Learn more"
                href={helpArticles.learnMore}
              />
            </Box>
          </Box>
        </CardBody>
      </Card>
      <VideoModal
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        src={'https://payroll-assets.razorpay.com/assets/freshteam/freshteam_api_key_tutorial.mp4'}
        //TODO: Add correct video link
      />
    </Box>
  );
};

export default WelcomeCard;
