import React, {ReactElement} from 'react';
import {BannerImageSection} from "./BannerImageSection";
import {HeadingsAndSubHeadingsSection} from "./HeadingsAndSubHeadingsSection";
import {BenefitsSection} from "./BenefitsSection";
import {DownloadBrochureSection} from "./DownloadBrochureSection";
import {WhyXPayrollSection} from "./WhyXPayrollSection";
import {ScheduleCallSection} from "./ScheduleCallSection";
import PageWrapper from "../../../ui/PageWrapper";
import MainCase from "../../../ui/MainCase";
import RightSidebar from "../../../ui/RightSidebar";
import {PartnerFlexiBenefitsFAQ} from "../../PartnerFlexiBenefitsFAQ";
import ExternalLink from 'components/ui/ExternalLink';


export function ZaggleAdminScheduleACall() {
  return <div className='space-y-16 w-full md:px-0 px-10 md:w-260 mx-auto'>
      <BannerImageSection/>
      <HeadingsAndSubHeadingsSection/>
      <div className="text-center">
        {/* bit.ly link - https://calendly.com/xpayroll-zaggle/30min?month=2022-12&utm_medium=cta_top&utm_campaign=dashboard */}
        <ExternalLink
            className="mx-auto btn "
            href="http://bit.ly/3FTm7TW"
            target="_blank"
            rel="noopener noreferrer">
            Schedule A Call
        </ExternalLink>
        </div>
      <BenefitsSection/>
      <DownloadBrochureSection/>
      {/* <WhyXPayrollSection/> */}
      <ScheduleCallSection/>
      <br/>
      <br/>
  </div>;
}

function ZaggleAdminScheduleACallWithPageWrapper(): ReactElement {
    return (
        <PageWrapper>
            <MainCase>
                <ZaggleAdminScheduleACall/>
            </MainCase>
            <RightSidebar>
                <PartnerFlexiBenefitsFAQ />
            </RightSidebar>
        </PageWrapper>
    );
}

export default ZaggleAdminScheduleACallWithPageWrapper;