import React, { PropsWithChildren, ReactElement } from 'react';
import ZohoLogo from 'assets/zoho/zoho_logo.png';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import { StandardButton, LinkButtonV2, SecondaryButton } from 'components/ui/Button';
import Breadcrumb from 'components/ui/Breadcrumb';
import { Link, Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import BulletList, { BulletListItem } from 'components/ui/BulletList';
import HorizontalLine from 'components/ui/HorizontalLine';
import cx from 'classnames';
import { WarningAlertYellow } from 'components/ui/Alert';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import withActivationQueryWrapper from '../withActivationQueryWrapper';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import ExternalLink from 'components/ui/ExternalLink';
import useHasPermission from 'utils/useHasPermission';
import permissions from 'constants/permissions';
import SupportIcon from 'assets/icons/icon_support.svg';
import DocumentIcon from 'assets/icons/icon_document.svg';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

function Tag({ children, className = '' }: PropsWithChildren<{ className?: string }>) {
  return <span className={cx(styles['zoho-tag'], className)}>{children}</span>;
}

function InfoTitle({ children }: PropsWithChildren<{}>) {
  return <h3 className={cx(styles['zoho-intro-info-title'], 'mb-6')}>{children}</h3>;
}

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoIntro({ zohoData }: Props): ReactElement {
  const fieldsToBeSynced = zohoData.synced_fields
    .map((field) => {
      return field.name;
    })
    .join(', ');
  const hasManageHRMSPermission = useHasPermission([permissions.MANAGE_HRMS_INTEGRATION]);
  const isDataSyncActive = zohoData.is_user_data_sync_enabled;
  const isZohoConnected = zohoData.enabled;

  if (isDataSyncActive) {
    return <Redirect to={routePaths.settings.zoho.home} />;
  }

  if (!hasManageHRMSPermission) {
    return <Redirect to={routePaths.accessDenied} />;
  }

  return (
    <div>
      {zohoData.active_integration && (
        <WarningAlertYellow className="mt-8 mb-8">
          You already have an HRMS integration active via {zohoData.active_integration.name}. Please
          disable that if you want to use the Zoho People integration
        </WarningAlertYellow>
      )}
      <div className={cx(styles['zoho-intro-banner-wrapper'], 'flex flex-col lg:flex-row')}>
        <div>
          <img
            className={cx(styles['logo'], 'mx-auto mb-10 lg:mb-0')}
            src={ZohoLogo}
            alt="Zoho Logo"
          />
        </div>
        <div className="ml-0 lg:ml-16">
          <h1 className={cx(styles['title'], 'text-white-o-80 text-center lg:text-left')}>
            Zoho People
          </h1>
          <div className="text-white-o-70 mt-4 text-center lg:text-left">
            Simplify your HR operations, retain talent, and build a high-performing workforce while
            putting employee experience first.
          </div>
          <div className="mt-12 flex flex-col lg:block gap-4">
            <Tag>Core HR Simplified</Tag>
            <Tag className="ml-0 lg:ml-6">Time & Attendance</Tag>
            <Tag className="ml-0 lg:ml-6">Performance management</Tag>
            <Tag className="ml-0 lg:ml-6">Learning and development</Tag>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-24 gap-24">
        <div>
          <div className="mb-12">
            <InfoTitle>Magic of RazorpayX Payroll and Zoho People 🚀</InfoTitle>
            <BulletList>
              <BulletListItem className="text-white-o-50">
                <span className="text-white-o-70 font-bold">
                  Automatic onboarding to RazorpayX Payroll
                </span>
                <br />
                <span className="text-white-o-50">
                  A new employee onboarded in Zoho people gets automatically onboarded in RazorpayX
                  Payroll
                </span>
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                <span className="text-white-o-70 font-bold">
                  Attendance sync to accurate payroll calculation{' '}
                  <Pill type={pillClassTypeMap.success} content="Coming Soon" />
                </span>
                <br />
                <span className="text-white-o-50">
                  Sync employee unpaid leaves information directly to RazorpayX Payroll
                </span>
              </BulletListItem>
            </BulletList>
          </div>
          <HorizontalLine />
          <div className="my-12">
            <InfoTitle>Data sync & sharing</InfoTitle>
            <BulletList>
              <BulletListItem className="text-white-o-50">
                Employee data will automatically sync at 3:30 AM IST everyday. You can sync manually
                as well
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                The following information can be synced from Zoho People:{' '}
                <span className="italic text-white-o-50">{fieldsToBeSynced}</span>
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                Only active employees will sync from Zoho People to RazorpayX Payroll
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                All employees and contractors will have default employee and contractor role types
                respectively in RazorpayX Payroll. You can assign different role once the
                intergration is done.
              </BulletListItem>
            </BulletList>
          </div>
          <HorizontalLine />
          <div className="mt-12">
            <InfoTitle>4 simple steps to integrate</InfoTitle>
            <BulletList>
              <BulletListItem className="text-white-o-50">Authorize Zoho People</BulletListItem>
              <BulletListItem className="text-white-o-50">
                Preview or Edit employee fields to sync
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                Complete employee type mapping
              </BulletListItem>
              <BulletListItem className="text-white-o-50">
                Complete employee status type mapping and you are done!
              </BulletListItem>
            </BulletList>
          </div>
        </div>
        <div>
          {isZohoConnected ? (
            <Link to={routePaths.settings.zoho.config.root}>
              <StandardButton
                disabled={!!zohoData.active_integration}
                onClick={() => trackSegment('zoho.continue_integration.click')}>
                Continue Integration
              </StandardButton>
            </Link>
          ) : (
            <Link to={routePaths.settings.zoho.preconditions}>
              <StandardButton
                disabled={!!zohoData.active_integration}
                onClick={() => trackSegment('zoho.start_integration.click')}>
                Start Integration
              </StandardButton>
            </Link>
          )}
          <div className="mt-10 text-white-o-50 mb-4">Want to explore Zoho?</div>
          <ExternalLink href="https://calendly.com/xpayroll-zoho/30min">
            <SecondaryButton onClick={() => trackSegment('zoho.book_demo.click')}>
              Book a demo
            </SecondaryButton>
          </ExternalLink>
          <br />
          <ExternalLink href="https://intercom.help/XPayroll/en/articles/6948606-razorpayx-payroll-zoho-people-integration">
            <LinkButtonV2 className="mt-32" onClick={() => trackSegment('zoho.faqs.click')}>
              <img src={DocumentIcon} alt="" className={styles['document-icon']} />
              FAQs
            </LinkButtonV2>
          </ExternalLink>
          <br />
          <ExternalLink href="https://intercom.help/XPayroll/en/articles/6948606-razorpayx-payroll-zoho-people-integration">
            <LinkButtonV2 className="mt-5" onClick={() => trackSegment('zoho.support.click')}>
              <img src={SupportIcon} alt="" className={styles['support-icon']} />
              Contact support
            </LinkButtonV2>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}

function ZohoIntroWithPageWrapper({ zohoData }: Props): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Zoho People"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase className={styles['zoho-page-wrapper']} type="fullWidth">
        <ZohoIntro zohoData={zohoData} />
      </MainCase>
    </PageWrapper>
  );
}

export default withActivationQueryWrapper(ZohoIntroWithPageWrapper);
