import { RestrictedRoute } from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import MainCase from 'components/ui/MainCase';
import {
  BulkUploadStep,
  BulkUploadTypes,
  arrayGenericBulkUploadTypes,
  routeToStepMap,
  uploadTypeConfigMap,
} from 'constants/bulkUpload';
import { useSearchParams } from 'hooks/useSearchParams';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useReduxSelector from 'utils/useReduxSelector';
import BulkUploadsPreview from './BulkUploadsPreview';
import PageHeader from './components/PageHeader';
import BulkUploadView from './components/BulkUploadView';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const BulkUploadsInitiate = () => {
  const location = useLocation();
  const { urlParams } = useSearchParams<'type'>();
  const history = useHistory();
  const { isAdditionsDisabled, isDeductionsDisabled, isLOPDisabled } = useIsFeatureDisabledForRearch();

  const bulkUploadType = parseInt(
    urlParams.get('type') || '-1',
  ) as keyof typeof uploadTypeConfigMap;

  const isOnProPlan = useReduxSelector(loggedInUserSelectors.isOnProPlan);
  const isValidUploadType = arrayGenericBulkUploadTypes.includes(bulkUploadType);
  const organizationId = useReduxSelector(loggedInUserSelectors.organizationID);

  if (isAdditionsDisabled || isDeductionsDisabled || isLOPDisabled) {
    if (
      [BulkUploadTypes.TYPE_BULK_FNF_INITIATION, BulkUploadTypes.TYPE_BULK_FNF_SETTLEMENT].includes(
        bulkUploadType,
      )
    ) {
      return <Redirect to={routePaths.unknown} />;
    }
  }

  if (!isOnProPlan || !organizationId || !isValidUploadType) {
    return <Redirect to={routePaths.unknown} />;
  }

  const uploadTypeConfig = uploadTypeConfigMap[bulkUploadType];
  const currentStep =
    routeToStepMap[bulkUploadType][location.pathname] || BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP;

  return (
    <div>
      <MainCase type="fullWidth" className="!pr-0">
        <Switch>
          <RestrictedRoute
            allowedPermissions={uploadTypeConfig.permissions ?? []}
            render={() => (
              <>
                <PageHeader
                  currentStep={currentStep}
                  headerText={uploadTypeConfig.initiation.pageTitle}
                />
                <BulkUploadView
                  viewTitle={uploadTypeConfig.initiation.step1Title}
                  instructions={uploadTypeConfig.initiation.instructions}
                  organizationId={organizationId}
                  bulkUploadType={bulkUploadType}
                  pollingRequestId={`upload_${bulkUploadType}_polling_request_id`}
                />
              </>
            )}
            path={[routePaths.bulkUploads.root, routePaths.bulkUploads.initiate]}
            {...(uploadTypeConfig.initiation.feature
              ? { allowedFeatures: [uploadTypeConfig.initiation.feature] }
              : {})}
            exact
          />
          <RestrictedRoute
            allowedPermissions={uploadTypeConfig.permissions ?? []}
            render={() => (
              <>
                <PageHeader
                  currentStep={currentStep}
                  headerText={uploadTypeConfig.initiation.pageTitle}
                />
                <BulkUploadsPreview
                  bulkUploadType={bulkUploadType}
                  pollingRequestId={`preview_${bulkUploadType}_polling_request_id`}
                  renderConfirmationText={uploadTypeConfig.preview.renderConfirmationText}
                  onBackClick={() =>
                    history.push({
                      pathname: routePaths.bulkUploads.root,
                      search: `?type=${bulkUploadType}`,
                    })
                  }
                  onSucessClick={uploadTypeConfig.preview.onSuccessModalCtaClick}
                />
              </>
            )}
            path={[routePaths.bulkUploads.preview]}
            {...(uploadTypeConfig.preview.feature
              ? { allowedFeatures: [uploadTypeConfig.preview.feature] }
              : {})}
            exact
          />
          <Route>
            <Redirect to={routePaths.unknown} />
          </Route>
        </Switch>
      </MainCase>
    </div>
  );
};

export default BulkUploadsInitiate;
