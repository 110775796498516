import { Box, TabItem, TabList, Tabs, ToastContainer } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import PageLayout from 'components/ui/Layout/PageLayout';
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import { tabItems } from '../constants';
import DeductionsView from './Deductions/DeductionsView';
import EarningsView from './Earnings/EarningsView';
import WelcomeCard from '../components/WelcomeCard';

const SalaryComponentsView = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const currentTab = location.pathname.split('/')[2];

  return (
    <PageLayout
      hideHeading
      withoutBackgroundImg
      boxProps={{
        marginY: 'spacing.7',
        width: 'initial',
      }}>
      <Box paddingX="spacing.8">
        <WelcomeCard />
        <Header />
        <Tabs
          onChange={(value) => history.push(`/salary-components/${value}`)}
          defaultValue={tabItems.earnings}
          value={currentTab}>
          <TabList>
            <TabItem value={tabItems.earnings}>Earnings</TabItem>
            <TabItem value={tabItems.deductions}>Deduction</TabItem>
          </TabList>
        </Tabs>
      </Box>
      <Switch>
        <Route path={routePaths.salaryComponents.root} exact>
          <Redirect to={routePaths.salaryComponents.earningsListView} />
        </Route>
        <Route path={routePaths.salaryComponents.earningsListView} component={EarningsView} exact />
        <Route
          path={routePaths.salaryComponents.deductionsListView}
          component={DeductionsView}
          exact
        />
        <Route>
          <Redirect to={routePaths.unknown} />
        </Route>
      </Switch>
      <ToastContainer />
    </PageLayout>
  );
};

export default SalaryComponentsView;
