import React from 'react';
import { GetPayrollSetupSchemaContract } from '../../../schemas/GetPayrollSetupSchema';
import { CycleProps, CYCLE_TYPE, PAYROLL_CYCLE } from './types';
import { convertToOrdinal, dateFormats } from 'utils/Dates';
import { capitalizeFirstLetter } from 'utils/Strings';
import { format, startOfMonth } from 'date-fns';
import { Text } from '@razorpay/blade/components';
import { deepEqual } from 'utils/Objects';

interface CycleDescriptionFunctionProps {
    cycleType: CYCLE_TYPE;
    startDate: number;
    endDate: number;
    payrollCycleType?: PAYROLL_CYCLE;
}

const ordinalTextWithLastDayCheck = (day: number) => {
    return ((day === 31 || day === 0) ? 'last day' : convertToOrdinal(day))
}

export const getInitialData: GetPayrollSetupSchemaContract =
{
    payrollEnabled: true,
    payoutDate: 5,
    autoExecutePayroll: false,
    hasFinalizedAPayroll: false,
    nextPayrollMonthToFinalize: format(startOfMonth(new Date()), dateFormats.yearMonthDate),
    payrollCycle: {
        startDate: 1,
        endDate: 0
    },
    payrollInputCycle: {
        startDate: 1,
        endDate: 0
    },
    effectiveFromMonth: format(startOfMonth(new Date()), dateFormats.yearMonthDate),
    upcomingCycles: null,
    newJoinerArrearEnabled: false,
    newJoinerCutoffDate: null
}

export const getEffectiveFromMonthList = (startMonth: string | null) => {
    let initMonth = startMonth !== null ? new Date(startMonth) : new Date();
    const monthArray = [];

    for (let i = 0; i < 6; i++) {
        monthArray.push(
            {
                key: initMonth.toISOString().slice(0, 10),
                title: `${initMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}`,
                value: initMonth.toISOString().slice(0, 10)
            }
        );
        initMonth = new Date(initMonth.setMonth(initMonth.getMonth() + 1));
    }

    return monthArray;

};

export const getCycleText = (cycle: CycleProps) => {

    return `${ordinalTextWithLastDayCheck(cycle.from)} to the ${ordinalTextWithLastDayCheck(cycle.to)} of the month`

}

export const getCycleTextWithMonthSuffix = (startDate: number, endDate: number) => {
    return `${ordinalTextWithLastDayCheck(startDate)} of ${startDate > 15 ? 'previous' : 'payroll'} month to 
    the ${ordinalTextWithLastDayCheck(endDate)} of ${endDate >= 15 ? 'payroll' : 'next'} month`
}

export const getSummaryText = (cycle: CycleProps) => {
    if (cycle.from === 1) {
        return getCycleText(cycle);
    }
    else {
        return `${convertToOrdinal(cycle.from)} to ${convertToOrdinal(cycle.to)}`;
    }
}

export const checkIsCycleChanged = (oldSettings: GetPayrollSetupSchemaContract, newSettings: GetPayrollSetupSchemaContract, setIsCycleChanged: (value: boolean) => void) => {

    if (
        !deepEqual(oldSettings.payrollCycle, newSettings.payrollCycle) ||
        !deepEqual(oldSettings.payrollInputCycle, newSettings.payrollInputCycle) ||
        !(oldSettings.newJoinerCutoffDate === newSettings.newJoinerCutoffDate)
    ) {
        setIsCycleChanged(true);
    }
    else {
        setIsCycleChanged(false);
    }
}

export const checkIsSettingsChanged = (oldSettings: GetPayrollSetupSchemaContract, newSettings: GetPayrollSetupSchemaContract, setIsSettingChanged: (value: boolean) => void) => {
    if (!deepEqual(oldSettings, newSettings)) {
        setIsSettingChanged(true);
    }
    else {
        setIsSettingChanged(false);
    }
}

export const getHelpText = (settings: GetPayrollSetupSchemaContract) => {

    const payrollCycleText = `The organization's payroll cycle is ${getCycleText(
        {
            from: settings.payrollCycle.startDate,
            to: settings.payrollCycle.endDate
        }
    )}`;

    const executionText = `The salary will be executed on ${settings.payoutDate ? convertToOrdinal(settings.payoutDate) : 'N/A'} of the next month`;

    const attendanceText = (
        settings.payrollCycle.startDate === settings.payrollInputCycle.startDate &&
        settings.payrollCycle.endDate === settings.payrollInputCycle.endDate
    ) ?
        `Attendance cycle is same as the payroll cycle` :
        `The organization's attendance cycle is from the ${getCycleTextWithMonthSuffix(settings.payrollInputCycle.startDate, settings.payrollInputCycle.endDate)}`;

    if (settings.payrollEnabled) {
        return [payrollCycleText, executionText, attendanceText].join('. ');
    }
    else {
        return [payrollCycleText, attendanceText].join('. ');
    }

}

export const getCycleDescriptionText = (props: CycleDescriptionFunctionProps) => {
    const ordinalStartDate = ordinalTextWithLastDayCheck(props.startDate);
    const ordinalEndDate = ordinalTextWithLastDayCheck(props.endDate);
    if (props.payrollCycleType === PAYROLL_CYCLE.CALENDAR_CYCLE) {
        return `💡 The organization's ${props.cycleType} cycle runs from the ${ordinalStartDate} to the ${ordinalEndDate} of the month`;
    }
    else {
        let retText = `💡 ${capitalizeFirstLetter(props.cycleType)} cycle is from the ${getCycleTextWithMonthSuffix(props.startDate, props.endDate)}.`;

        if (props.startDate === 1) {
            retText += `For eg.  ${ordinalStartDate} of March to the last day of March.`
        }
        else {
            retText += `For eg.  ${ordinalStartDate} of March to the ${ordinalEndDate} of April.`
        }

        retText += 'Number of days in the cycle may vary as per the month involved.';

        return retText;
    }


}

export function parseDropdownDate(endDate: number) {
    return ((endDate === 0 || endDate === 31) ? 31 : (endDate % 31));
}

export const getFutureCyclesText = (
    upcomingCycles: GetPayrollSetupSchemaContract['upcomingCycles'],
    payoutDate: GetPayrollSetupSchemaContract['payoutDate'],
    isNewJoinerArrearFeatureEnabled: boolean,
)
    : React.ReactChild => {

    let payrollCycleText = 'N/A';
    let attendanceCycleText = 'N/A';

    if (upcomingCycles) {
        payrollCycleText = upcomingCycles.payrollCycle ? getCycleText(
            {
                from: upcomingCycles.payrollCycle.startDate,
                to: upcomingCycles.payrollCycle.endDate
            }
        ) : 'N/A';
        attendanceCycleText = upcomingCycles.payrollInputCycle ? getCycleText(
            {
                from: upcomingCycles.payrollInputCycle?.startDate,
                to: upcomingCycles.payrollInputCycle?.endDate
            }
        ) : 'N/A';
    }

    if (isNewJoinerArrearFeatureEnabled && upcomingCycles) {
        if (upcomingCycles.newJoinerArrearEnabled && upcomingCycles.newJoinerCutoffDate) {
            return <Text>The configuration for the new cycle is as follows:
                Payroll cycle dates are from the <b>{payrollCycleText}</b>,
                Attendance cycle is from the <b>{attendanceCycleText}</b>,
                the payroll execution date is the <b>{payoutDate ? convertToOrdinal(payoutDate) : 'N/A'}</b> and
                new joiner arrear is enabled with the new joiner cut-off from <b>{convertToOrdinal(upcomingCycles.newJoinerCutoffDate)}</b>.
            </Text>
        }
        else {
            return <Text>The configuration for the new cycle is as follows:
                Payroll cycle dates are from the <b>{payrollCycleText}</b>,
                Attendance cycle is from the <b>{attendanceCycleText}</b>,
                the payroll execution date is the <b>{payoutDate ? convertToOrdinal(payoutDate) : 'N/A'}</b> and
                new joiner arrear is disabled.
            </Text>
        }
    }
    else {

        return <Text>The configuration for the new cycle is as follows:
            Payroll cycle dates are from the <b>{payrollCycleText}</b>,
            Attendance cycle is from the <b>{attendanceCycleText}</b>,
            and the payroll execution date is the <b>{payoutDate ? convertToOrdinal(payoutDate) : 'N/A'}</b>.
        </Text>
    }
}