import React from "react";

export function HeadingsAndSubHeadingsSection() {
    return <div className="space-y-5">
        <div className="text-center font-bold text-4.5xl text-white">
            Digitize Employee Benefits with <span style={{color: "rgba(243, 105, 105, 1)"}}
                                                  className={"font-bold"}>Zaggle</span>
        </div>
        <div className="text-center text-3xl">
            Digitize your employee tax benefits program with a single card
        </div>
    </div>;
}