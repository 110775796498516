import { stateWiseCities } from '../constants/address';

export function isValidPinCode(pinCode?: string | null) {
  if (!pinCode) {
    return false;
  }
  const pattern = /^[1-9][0-9]{5}$/;

  return pattern.test(pinCode);
}

export function getCity(state?: string | null, currentCity?: string | null) {
  if (!state) {
    return '';
  }

  if (!currentCity) {
    return '';
  }

  if (stateWiseCities[state].includes(currentCity)) {
    return currentCity;
  }
  return '';
}

export function getStateCities(state?: string | null) {
  if (!state) {
    return [];
  }

  if (!stateWiseCities[state]) {
    return [];
  }

  return stateWiseCities[state];
}
