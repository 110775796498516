import React, { ReactElement } from 'react';
import { getStaticMediaUrl } from '../../../utils/Urls';
import greenTick from '../../../assets/icon--green--circle--tick.svg';
import documentIcon from 'assets/icons/icon_document_white.svg';
import union from 'assets/bulkUploads/Union.svg';
import dateIcon from 'assets/bulkUploads/calendarIcon.svg';
import styles from '../index.module.scss';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { BulkUploadTypes } from 'constants/bulkUpload';



export default function BreadcrumbStyleHeader({bulkUploadRecord,bulkUploadTypeRequest, isInputDataHidden=false} : {bulkUploadRecord:BulkUploadDetailsSchemaContract[0] | null,bulkUploadTypeRequest? : Number, isInputDataHidden?: boolean}):ReactElement {

    let selectedDate = bulkUploadRecord?.input_config?.payrollMonth ? new Date(bulkUploadRecord?.input_config?.payrollMonth) : null;
    if(bulkUploadTypeRequest && bulkUploadTypeRequest === BulkUploadTypes.TYPE_BULK_SALARY_REVISION){
        selectedDate = bulkUploadRecord?.input_config?.effectiveMonth ? new Date(bulkUploadRecord?.input_config?.effectiveMonth) : null;
    }
    const payrollMonth = selectedDate?.toLocaleString('default', { month: 'long'});

    return (
        <>
            <div className='flex flex-row'>
                <div className={`${styles['breadcrumb-header']}`}>
                    <div className={styles['icon']}><img src={getStaticMediaUrl(greenTick)} /></div>
                    <div className={styles['file-details']}>{'File details'}</div>
                </div>
                <div className={`${styles['breadcrumb-header']} ml-48`}>
                    <div className={`${styles['filename-rows']} flex items-center gap-2`}>
                        <div><img src={getStaticMediaUrl(documentIcon)} style={{width:'14px',height:'14px'}}/></div>
                        <span className={'mr-3 font-bold'}>{bulkUploadRecord?.file_name ?? 'File Not Found'}</span>
                        {bulkUploadTypeRequest && bulkUploadTypeRequest === BulkUploadTypes.TYPE_BULK_SALARY_REVISION  ?
                             (<div className={`ml-4 flex items-center`}> 
                                <img src={getStaticMediaUrl(union)} style={{width:'15px',height:'15px'}}/>
                                <span className={'ml-2'}><strong>{`${bulkUploadRecord?.total_number_of_rows ?? 0}  `}</strong>salary revisions</span>
                                </div>)
                             : (<>{'|'}
                                <span className={'ml-2'}><strong>{`${bulkUploadRecord?.total_number_of_rows ?? 0} `}</strong> row(s)</span>
                                </>
                            )
                        }
                    </div> 
                    {!isInputDataHidden &&(<div className={`ml-8 flex items-center ${styles['filename-rows']}`}>
                        <img src={getStaticMediaUrl(dateIcon)} style={{width:'15px',height:'15px'}}/>
                        {
                            bulkUploadTypeRequest && bulkUploadTypeRequest === BulkUploadTypes.TYPE_BULK_SALARY_REVISION 
                                ? <span className={'ml-2'}>{` Effective Month: `} <strong>{`${selectedDate ? selectedDate.toLocaleDateString('en-us', { year: 'numeric', month: 'short'}) : ''}`}</strong></span>
                                : <span className={'ml-2'}>{` Payroll Month: `} <strong>{`${selectedDate ? payrollMonth : ''}`}</strong></span>
                        }
                    </div>)}   
                </div>
            </div>
        </>
    )

}