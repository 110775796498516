import { Amount, Box, Divider, Text } from '@razorpay/blade/components';
import React from 'react';
import { transformSalaryRevisionRequestData } from '../SalaryRevision/utils';
import { NewToOldAmountWithDelta } from './NewToOldAmountWithDelta';
import { GetWorkflowRequestDetailsContract } from '../../../../schemas/GetWorkflowRequestDetails';
import { format } from 'date-fns';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { DetailsSkeleton } from 'components/ui/Skeletons/DetailsSkeleton';

export interface SalaryRevisionDetailsProps {
  workflowRequestDetails?: GetWorkflowRequestDetailsContract;
  isLoading?: boolean;
}

const SalaryRevisionDetails = ({ workflowRequestDetails, isLoading }: SalaryRevisionDetailsProps) => {
  const {isVariablePayDisabled, isEmployerNPSDisabled} = useIsFeatureDisabledForRearch();
  if (isLoading || !workflowRequestDetails) {
    return <DetailsSkeleton rowCount={8} />;
  }

  const requestData = transformSalaryRevisionRequestData(
    workflowRequestDetails.workflow_requests_data,
    workflowRequestDetails.people_metadata,
  );

  const showData = (data : { new:number, old : number}) => {
    return (requestData.isCustomSalaryStructure && ( data.new || data.old ));
  }

  const details = [
    {
      label: 'Employee Details',
      content: (
        <Box display="flex" flexDirection="column">
          <Text color="surface.text.gray.muted">{requestData.employeeDetails.name}</Text>
          <Text variant="caption" color="surface.text.gray.muted" size='small'>
            {requestData.employeeDetails.employeeId}
          </Text>
        </Box>
      ),
    },
    {
      label: 'Effective Date',
      content: <Text color="surface.text.gray.muted">{format(new Date(requestData.effectiveFrom), 'dd LLL yyyy')}</Text>,
    },
    {
      label: 'Salary Structure',
      content: <Text color="surface.text.gray.muted">{requestData.isCustomSalaryStructure ? 'Custom' : 'Default'}</Text>,
    },
    {
      label: 'CTC',
      content:
        (requestData.ctc.old || requestData.ctc.new) ? <NewToOldAmountWithDelta newAmount={requestData.ctc.new} oldAmount={requestData.ctc.old} /> : undefined
    },
    {
      label: 'Basic Salary',
      content:
        showData(requestData.basicSalary) ? <NewToOldAmountWithDelta newAmount={requestData.basicSalary.new} oldAmount={requestData.basicSalary.old} /> : undefined
    },
    {
      label: 'Dearness Allowance',
      content:
        showData(requestData.da) ? <NewToOldAmountWithDelta newAmount={requestData.da.new} oldAmount={requestData.da.old} /> : undefined
    },
    {
      label: 'LTA',
      content:
        showData(requestData.lta) ? <NewToOldAmountWithDelta newAmount={requestData.lta.new} oldAmount={requestData.lta.old} /> : undefined
    },
    {
      label: 'HRA',
      content:
        showData(requestData.hra) ? <NewToOldAmountWithDelta newAmount={requestData.hra.new} oldAmount={requestData.hra.old} /> : undefined
    },
    {
      label: 'Special Allowance',
      content:
        showData(requestData.specialAllowance) ? <NewToOldAmountWithDelta newAmount={requestData.specialAllowance.new} oldAmount={requestData.specialAllowance.old} /> : undefined
    },
    {
      label: 'Employer PF Contribution',
      content:
        showData(requestData.pfContribution) ? <NewToOldAmountWithDelta newAmount={requestData.pfContribution.new} oldAmount={requestData.pfContribution.old} /> : undefined
    },
    {
      label: 'Employer ESI Contribution',
      content:
        showData(requestData.esiContribution) ? <NewToOldAmountWithDelta newAmount={requestData.esiContribution.new} oldAmount={requestData.esiContribution.old} /> : undefined
    },
    {
      label: 'Employer NPS Contribution',
      content:
        showData(requestData.npsEmployerContributionLimit) ? <NewToOldAmountWithDelta newAmount={requestData.npsEmployerContributionLimit.new} oldAmount={requestData.npsEmployerContributionLimit.old} /> : undefined
    },
    // {
    //   label: 'VPF',
    //   content:
    //     <NewToOldAmountWithDelta newAmount={requestData.vpf.new} oldAmount={requestData.vpf.old} />
    // },
    {
      label: 'Total Arrears',
      content:
        requestData.arrears ? 
        <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={'spacing.6'} justifyItems={'right'}>
        <Amount value={requestData.arrears} /> 
        <Box />
        </Box>
        : undefined
    },
    {
      label: 'Variable Pay',
      content:
        requestData.vp ? 
        <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={'spacing.6'} justifyItems={'right'}>
        <Amount value={requestData.vp} /> 
        <Box />
        </Box>
        : undefined
    },
    // {
    //   label: 'Total Perquisite',
    //   content:
    //     requestData.perquisites ? <Amount value={requestData.perquisites} /> : undefined
    // }
  ].filter((detail) => {
    if (isVariablePayDisabled || isEmployerNPSDisabled) {
      return  ['Variable Pay','Employer NPS Contribution'].includes(detail.label) ? false : true;
    }
    return true;
  });

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.5">
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text weight="semibold" size="large" color="surface.text.gray.muted">
          Salary Revision
        </Text>
        <Text size="large" color="surface.text.gray.disabled">
          Details
        </Text>
      </Box>
      {details.map(({ label, content }, index) => (
        <>
          {label === 'CTC' &&
            <Box display="flex" width="100%" justifyContent="center" marginBottom={'spacing.2'}>
              <Divider orientation="horizontal" dividerStyle="dashed" alignSelf="center" />
            </Box>}

          {content && <Box display="grid" gridTemplateColumns={'120px 1fr'} gap={'spacing.5'} alignItems="baseline" key={index}>
            <Box>
              <Box width={'100%'}>
                <Text color="surface.text.gray.muted">{label}</Text>
              </Box>
            </Box>
            <Box width={'100%'}>{content}</Box>
          </Box>}

          {label === 'CTC' &&
            <Box display="flex" width="100%" justifyContent="center" marginTop={'spacing.2'}>
              <Divider orientation="horizontal" dividerStyle="dashed" alignSelf="center" />
            </Box>}
        </>
      ))}
      {requestData.allowances && Object.keys(requestData.allowances).length > 0 &&
        <>
          <Box display="flex" width="100%" justifyContent="center" marginTop={'spacing.2'}>
            <Divider orientation="horizontal" dividerStyle="dashed" alignSelf="center" />
          </Box>
          <Box display="grid" gridTemplateColumns={'120px 1fr'} gap={'spacing.5'} alignItems="baseline">
            <Box>
              <Box width={'100%'}>
                <Text color="surface.text.gray.muted">Allowances</Text>
              </Box>
            </Box>
            <Box>
            </Box>
          </Box>
          {Object.entries(requestData.allowances).map((item) => {
            return (
              (<Box display="grid" gridTemplateColumns={'120px 1fr 1fr'} gap={'spacing.5'} alignItems="baseline">
                <Box>
                  <Box width={'100%'}>
                    <Text size='small' color="surface.text.gray.muted">{item[1].name}</Text>
                  </Box>
                </Box>
                <Box marginLeft={'auto'}>
                  <Amount value={item[1].amount as any as number} />
                </Box>
                <Box></Box>
              </Box>)
            );
          })}


          <Box display="flex" width="100%" justifyContent="center" marginTop={'spacing.2'}>
            <Divider orientation="horizontal" dividerStyle="dashed" alignSelf="center" />
          </Box>
        </>
      }
    </Box>)
  );
};

export { SalaryRevisionDetails };
