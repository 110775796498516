import React from 'react';
import { capturePrometheusMetrics, Metrics } from './Lumberjack';
import { getRedactedPath } from './Urls';

const retryImport = async (
  importFunction: () => Promise<{ default: React.ComponentType<any> }>,
  retryCount = 3,
  interval = 1000,
): Promise<{ default: React.ComponentType<any> }> => {
  try {
    const res = await importFunction();
    return res;
  } catch (error) {
    setTimeout(() => {
      capturePrometheusMetrics(Metrics.ERROR_COUNT, {
        pathname: getRedactedPath(window.location.pathname),
        source: 'chunk-load',
      });
      if (retryCount === 0) {
        throw error;
      }
      return retryImport(importFunction, retryCount - 1, interval * 1.25);
    }, interval);
  }
  return { default: () => null };
};

const lazyLoadWithRetry = (
  importFunction: () => Promise<{ default: React.ComponentType<any> }>,
) => {
  const Component = React.lazy(() => retryImport(importFunction));
  //@ts-expect-error - preload is a standard property of Promise
  Component.preload = importFunction;
  return Component;
};

export default lazyLoadWithRetry;
