import {
  ActionList,
  ActionListItem,
  Button,
  Dropdown,
  DropdownOverlay,
  PlusIcon,
  SelectInput,
} from '@razorpay/blade/components';
import closeIcon from 'assets/icon--close-grey.svg';
import {
  DeductFrom,
  DeductFromLabelToNumberMap,
  DeductionComponentV2,
  DeductionType,
} from 'components/RunPayroll/utils/types';
import { useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { DeductionsComponentType } from 'components/SalaryComponents/types';
import AutoCompleteV2 from 'components/ui/AutoCompleteV2/AutoCompleteV2';
import { SecondaryButton } from 'components/ui/Button';
import { Input } from 'components/ui/Form/Input';
import { Table } from 'components/ui/Table';
import React from 'react';
import { capitalizeAllWordsInString } from 'utils/Strings';
import { getStaticMediaUrl } from 'utils/Urls';
import crossIcon from './../../../assets/icons/red_cross.png';
import useSalaryComponentsList from './EditSalary/hooks/useSalaryComponentsList';
import { getSearchDropdownDescription } from './EditSalary/utils';
import styles from './index.module.scss';

const EditDeductionsV2 = ({
  deductions,
  setNonLOPDeductions,
}: {
  deductions: DeductionComponentV2;
  setNonLOPDeductions: (nonLOPDeductions: DeductionComponentV2['non_lop_deductions']) => void;
}) => {
  const { openDeductionsCreation } = useSalaryComponentHelpers();

  const { searchSalaryComponents, isFetchingSalaryComponents, getSalaryComponentById } =
    useSalaryComponentsList<DeductionsComponentType>({
      requestParams: {
        category: 'DEDUCTIONS_CATEGORY',
        payType: 'LIST_ADHOC_TYPE',
        status: 'COMPONENT_STATUS_ACTIVE',
      },
    });

  const onDeductionsChange = (
    updateIndex: number,
    updates: Partial<DeductionComponentV2['non_lop_deductions'][0]>,
  ) => {
    const nonLopDeductions = [...deductions.non_lop_deductions];
    const componentId = updates.id ? updates.id : nonLopDeductions[updateIndex].id;
    const selectedComponent = getSalaryComponentById(componentId);

    nonLopDeductions[updateIndex] = {
      ...nonLopDeductions[updateIndex],
      ...updates,
      deduct_from:
        DeductFromLabelToNumberMap[
          selectedComponent?.settings.deductionDetails.deductFrom ?? 'GROSS_PAY'
        ],
    };

    setNonLOPDeductions(nonLopDeductions);
  };

  const onAdd = () => {
    const nonLOPDeductions = [...deductions.non_lop_deductions];
    nonLOPDeductions.push({
      id: '',
      name: '',
      amount: 0,
      deduct_from: DeductFrom.GROSS_PAY,
      lop_days: '0',
      type: DeductionType.TYPE_MANUAL,
    });
    setNonLOPDeductions(nonLOPDeductions);
  };

  const onDelete = (idx: number) => {
    const nonLOPDeductions = [...deductions.non_lop_deductions];
    nonLOPDeductions.splice(idx, 1);
    setNonLOPDeductions(nonLOPDeductions);
  };

  const onHoverCross = (idx: number) => {
    const row = document.getElementById(`deduction-row-${idx}`);
    row?.classList.add(styles['run-payroll-deduction-row-hover']);
  };

  const onMouseoutOfCross = (idx: number) => {
    const row = document.getElementById(`deduction-row-${idx}`);
    row?.classList.remove(styles['run-payroll-deduction-row-hover']);
  };

  const getDeductionsSearchSkipList = (
    deductions: DeductionComponentV2['non_lop_deductions'],
    currentId: string,
  ): string[] => {
    return deductions
      .filter((deduction) => deduction.id !== '' && deduction.id !== currentId)
      .map((deduction) => deduction.id);
  };

  return (
    <>
      <Table
        className={
          styles['run-payroll-edit-deduction-table'] +
          ' border border-solid border-surfaceBorderGrayMuted'
        }
        showAlternateColors={false}>
        <thead>
          <tr>
            <th className={styles['run-payroll-edit-model-deduction-label-column']}>Label</th>
            <th>
              <a
                href="https://intercom.help/XPayroll/en/articles/8512408-deductions-loss-of-pay-days"
                target="_blank"
                rel="noreferrer">
                Deduct From?
              </a>
            </th>
            <th className={styles['run-payroll-edit-model-deduction-amount-column']}>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {deductions.non_lop_deductions.map((deductionItem, idx) => {
            return (
              <tr key={idx} id={`deduction-row-${idx}`}>
                <td>
                  <AutoCompleteV2
                    options={searchSalaryComponents(
                      getDeductionsSearchSkipList(deductions.non_lop_deductions, deductionItem.id),
                    )}
                    itemToKey={(item) => item.id}
                    itemToLabel={(item) => capitalizeAllWordsInString(item.name)}
                    itemToDescription={(item) => getSearchDropdownDescription(item) ?? ''}
                    data-testid={`deduction-${deductionItem.name}-name`}
                    value={deductionItem.id}
                    placeholder="Eg. Laptop recovery"
                    isDisabled={isFetchingSalaryComponents}
                    onChange={(event) => {
                      onDeductionsChange(idx, {
                        name: event.name,
                        id: event.id,
                      });
                    }}
                    onClearSelection={() => {
                      onDeductionsChange(idx, {
                        name: '',
                        id: '',
                      });
                    }}
                    footer={
                      <Button
                        variant="tertiary"
                        size="small"
                        icon={PlusIcon}
                        onClick={openDeductionsCreation}
                        isFullWidth>
                        Create deduction
                      </Button>
                    }
                  />
                </td>
                <td>
                  <Dropdown>
                    <SelectInput
                      name="deduct-from"
                      value={deductionItem?.deduct_from?.toString() ?? ''}
                      label=""
                      isDisabled
                    />
                    <DropdownOverlay zIndex={9999999}>
                      <ActionList>
                        <ActionListItem
                          key={'gross-pay'}
                          title={'Gross Pay'}
                          value={DeductFrom.GROSS_PAY.toString()}
                        />
                        <ActionListItem
                          key={'net-pay'}
                          title={'Net Pay'}
                          value={DeductFrom.NET_PAY.toString()}
                        />
                      </ActionList>
                    </DropdownOverlay>
                  </Dropdown>
                </td>
                <td>
                  <Input
                    type="number"
                    data-testid={`deduction-${deductionItem.name}-value`}
                    className={styles['run-payroll-edit-number']}
                    id={`deduction-amount-${idx}`}
                    value={deductionItem?.amount > 0 ? deductionItem?.amount : ''}
                    onChange={(event) => {
                      onDeductionsChange(idx, {
                        amount: Number(event.target.value),
                      });
                    }}
                  />
                </td>
                <td
                  onMouseOver={() => {
                    onHoverCross(idx);
                  }}
                  onMouseOut={() => {
                    onMouseoutOfCross(idx);
                  }}>
                  {
                    <>
                      <img
                        className={styles['run-payroll-deduction-red-cross']}
                        src={getStaticMediaUrl(crossIcon)}
                        alt=""
                        onClick={() => onDelete(idx)}
                      />
                      <img
                        className={styles['run-payroll-deduction-grey-close']}
                        src={getStaticMediaUrl(closeIcon)}
                        alt=""
                        onClick={() => onDelete(idx)}
                      />
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {deductions.non_lop_deductions.length < 10 && (
        <SecondaryButton
          onClick={onAdd}
          disabled={false}
          className={styles['run-payroll-new-deduction-button']}
          type="button">
          + New deduction
        </SecondaryButton>
      )}
    </>
  );
};

export default EditDeductionsV2;
