import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CompanyAddress from './CompanyAddress';
import TaxDetails from './TaxDetails';
import { Tab } from 'components/ui/Tab';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import KYC from 'constants/kyc';
import { WarningAlert } from 'components/ui/Alert';
import PayrollDate from '../PayrollDate';
import CompanyDetailsWrapper from './CompanyDetailsWrapper';

const TAB_COMPANY_ADDRESS = '1';
const TAB_TAX_INFO = '2';

const CompanyDetails = () => {
  const [tabState, setTabState] = useState(TAB_COMPANY_ADDRESS);
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const kycStatus = currentOrganization?.kycStatus;

  const onAddressUpdateSuccess = () => {
    setTabState(TAB_TAX_INFO);
  };

  // TODO: refactor ui/tabs components
  return (
    <>
      <div className="flex justify-center">
        {tabState === TAB_COMPANY_ADDRESS ? (
          <CompanyDetailsWrapper onSuccess={onAddressUpdateSuccess} />
        ) : (
          <PayrollDate />
        )}
      </div>
    </>
  );
};

export default CompanyDetails;
