import React from 'react';

export function ZaggleHeading() {
  return <div className='space-y-5'>
    <div className='text-center font-bold text-4.5xl text-white'>
      Flexible benefit plan with Zaggle
    </div>
    <div className='text-center text-1.4xl'>
      Personalize your salary structure as per your monthly expenses to avail tax
      benefits with Zaggle prepaid card
    </div>
  </div>;
}