import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';
import {
  actionStatusesMap,
  complianceActionsMap,
} from 'components/RunPayroll/ComplianceActions/data';
import { ComplianceAction } from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';

export const isCompliancePaymentInProgress = ({
  actionsApiResponse,
}: {
  actionsApiResponse?: GetComplianceActionsSchemaContract['actions'];
}) => {
  let isAnyActionInProgress = false;
  if (actionsApiResponse) {
    const actionNames = Object.keys(actionsApiResponse) as ComplianceAction[];

    for (const actionName of actionNames) {
      if (actionName === complianceActionsMap.payPt) {
        const action = actionsApiResponse[actionName];
        const subActionNames = Object.keys(action);
        const inProgressSubActions = subActionNames.find(
          (subActionName) => action[subActionName].status === actionStatusesMap.inProgress,
        );
        if (inProgressSubActions) {
          isAnyActionInProgress = true;
          break;
        }
      }
      const action = actionsApiResponse[actionName];
      if (action.status === actionStatusesMap.processing) {
        isAnyActionInProgress = true;
        break;
      }
    }
  }
  return isAnyActionInProgress;
};
