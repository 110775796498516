import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  TextInput,
  Box,
  ModalFooter,
  Button,
  Text,
} from '@razorpay/blade/components';
import { useMutation } from 'react-query';
import api from 'api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { showErrorToastNotification, showToastViaEvent } from 'utils/ToastEvents';

type EmployerShareProps = {
  isOpen: boolean;
  onClose: () => void;
  chatId: string;
};

const SEND_CHAT_EMPLOYER_EMAIL = 'SEND_CHAT_EMPLOYER_EMAIL';

export default function EmployerShare({ isOpen, onClose, chatId }: EmployerShareProps) {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const onSubmit = () => {
    //validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    if (!isEmailValid) {
      setIsValid(false);
    } else {
      sendEmail.mutate({ chatId, email });
    }
  };

  const sendEmail = useMutation({
    mutationKey: SEND_CHAT_EMPLOYER_EMAIL,
    mutationFn: (payload: { chatId: string; email: string }) =>
      api.payrollAiAssistant.sendEmail(payload.chatId, payload.email),
    onSuccess: (data) => {
        showToastViaEvent({
            text: 'We will reach out to you shortly!',
            timeout: 2000,
            type: 'success',
            icon: 'success',
          });
        onClose();
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 5000,
      });
    },
  });

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} size="small">
      <ModalHeader title="Share with your team" />
      <ModalBody>
        <Box display="flex" flexDirection={'column'} alignItems={'flex-start'} gap="24px">
          <Text size="medium" color="surface.text.gray.disabled">
            Drop in your email and we'll share the steps to securely share Ray :)
          </Text>
          <Box width={'100%'} marginBottom={'spacing.4'}>
            <TextInput
              label=""
              placeholder="Your email"
              onChange={(e) => setEmail(e.value ?? '')}
              validationState={!isValid ? 'error' : 'none'}
              errorText="Please enter a valid email"
            />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="flex-end">
          <Button size="large" onClick={onSubmit}>
            Let's go
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
}
