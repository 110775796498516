import { createContext, useContext } from 'react';

export interface ErrorMessageType {
  message?: string;
  type?: string;
  data?: any;
}

export interface RunPayrollErrorContextType {
  error: ErrorMessageType | null;
  setError: (error: ErrorMessageType | null) => void;
}

export const RunPayrollErrorContext = createContext<RunPayrollErrorContextType | null>(null);

export const useErrorMessageContext = () => {
  return useContext(RunPayrollErrorContext);
};

export interface SetErrorMessageType {
  onError?: (error: ErrorMessageType | null) => void;
}
