import React, { ReactElement, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import { useMutation } from 'react-query';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import slackInstallSuccess from 'assets/slack/slack_install_success.png';
import APIError from 'types/apiError';
import ErrorMessage from 'components/ui/ErrorMessage';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from './SlackAuthorize.module.scss';
import { routePaths } from 'components/Routes/data';
import { StandardButton } from 'components/ui/Button';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';

const instructionText =
  'Hi @channel - XPayroll has launched a Slack integration to make things easy and we have added that to our workspace. ' +
  'Enter /xpayroll as a command to get started.';

function SlackAuthorize(): ReactElement {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code') || '';
  const state = urlParams.get('state') || '';
  const feature = useSelector(loggedInUserSelectors.features);

  const authorizeMutation = useMutation(
    ({ code, state }: { code: string; state: string }) =>
      api.slackSettings.authorize({ code, state }),
    {
      onError: (error: APIError) => {},
    },
  );

  useEffect(() => {
    if (code && state) {
      authorizeMutation.mutate({ code, state });
    }
  }, [code, state]);

  if (authorizeMutation.status === 'error' || !code || !state) {
    const errorMessage =
      authorizeMutation.error?.message ||
      'Could not install the application. Please contact support.';

    return (
      <ErrorMessage title="Sorry! Something went wrong!">
        <div>{errorMessage}</div>
      </ErrorMessage>
    );
  }

  if (authorizeMutation.status === 'loading' || authorizeMutation.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(slackInstallSuccess)} />
      <div className={`${styles.Headline} text-4xl font-bold text-white mb-16 mt-4 text-center`}>
        The XPayroll Slack app has been successfully installed on your workspace 🎉
      </div>
      <div className={styles.InstallInstructions}>
        Notify your team to start using our Slack App. You can post the following message on your{' '}
        <span className="font-bold text-white">#general</span> or{' '}
        <span className="font-bold text-white">#office</span> channel to inform your team that the
        app is active.
      </div>
      <div className={styles.InstructionsText}>{instructionText}</div>
      <Link to={routePaths.integrations.root}>
        <StandardButton>BACK TO INTEGRATIONS</StandardButton>
      </Link>
    </div>
  );
}

function SlackAuthorizeWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase>
        <SlackAuthorize />
      </MainCase>
      <RightSidebar />
    </PageWrapper>
  );
}

export default SlackAuthorizeWithPageWrapper;
