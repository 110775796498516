import { createContext, useContext } from 'react';

import { Filters } from '../utils/types';

export interface RunPayrollFilterContextType {
  filters: Filters;
  setSearchPhraseFilter: (searchPhrase: string | null) => void;
  setDepartmentsFilter: (departments: string[]) => void;
  setLocationsFilter: (locations: string[]) => void;
  setStatusFilter: (statusFilter: string | null) => void;
  hasFilters: () => boolean;
}

export const RunPayrollFilterContext = createContext<RunPayrollFilterContextType | null>(null);

export const useRunPayrollFilterContext = () => {
  return useContext(RunPayrollFilterContext);
};