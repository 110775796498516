import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { PendingApproval } from './PendingApproval';
import { routePaths } from '../../../Routes/data';
import { InitiatedByMe } from './InitiatedByMe';
import { Completed } from './Completed';

const SalaryRevision = () => {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname === routePaths.approvalWorkflows.myRequests.salaryRevision.root) {
    return <Redirect to={routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals} />;
  }

  return (
    <Switch>
      <Route path={routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals} exact>
        <PendingApproval />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe} exact>
        <InitiatedByMe />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.salaryRevision.completed} exact>
        <Completed />
      </Route>
    </Switch>
  );
};

export { SalaryRevision };
