import React from 'react';

interface MailIconProps {
  className?: string;
}

const MailIcon: React.FC<MailIconProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1667 3.11539V13.1006C19.1667 14.4776 18.0436 15.6006 16.6667 15.6006H3.33337C1.95647 15.6006 0.833374 14.4776 0.833374 13.1006V3.10065C0.833374 1.72374 1.95647 0.600647 3.33337 0.600647H16.6667C18.037 0.600647 19.1559 1.71292 19.1666 3.08072C19.1669 3.09228 19.1669 3.10384 19.1667 3.11539ZM2.58887 2.72892C2.72669 2.45641 3.01008 2.26731 3.33337 2.26731H16.6667C16.99 2.26731 17.2734 2.45644 17.4113 2.72899L10.0001 7.91679L2.58887 2.72892ZM2.50004 4.70116V13.1006C2.50004 13.5571 2.87694 13.934 3.33337 13.934H16.6667C17.1231 13.934 17.5 13.5571 17.5 13.1006V4.70126L10.478 9.61669C10.1911 9.81755 9.80916 9.81755 9.52223 9.61669L2.50004 4.70116Z"/>
  </svg>
);

export default MailIcon;