import styled, { css, keyframes } from 'styled-components';
import { makeMotionTime } from '@razorpay/blade/utils';

const entry = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
`;

export const SuccessViewModalContainer = styled('div')`
  display: grid;
  z-index: 2;
  place-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.5);
`;

export const SuccessViewModalContentWrapper = styled('div')`
  max-width: 476px;
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const AnimatedContainer = styled.div<{ delay?: number }>(({ theme, delay = 0 }) => {
  return css`
    animation: ${entry} ${makeMotionTime(theme.motion.duration['2xgentle'])}
      ${theme.motion.easing.entrance} forwards;
    animation-delay: ${delay}ms;
    opacity: 0;
  `;
});
