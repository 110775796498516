import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { GetLOPAmountSchemaContract } from 'schemas/GetLOPAmountSchema';
import { toIndianCurrencyString } from 'utils/Numbers';
import { DeductionType } from '../utils/types';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const useLopTexts = ({
  lopAmountData,
  lopAmountStatus,
  lopDeduction,
  payrollId,
}: {
  lopAmountData?: GetLOPAmountSchemaContract | null;
  lopAmountStatus: string;
  lopDeduction: GetFnfResponseSchemaContract['deductions']['lop_deduction'];
  payrollId?: number | null;
}): [boolean, string, string, string] => {
  const { isM2S1Enabled, isM2S2Enabled } = useIsFeatureDisabledForRearch();
  const leaveAttendanceConfig = useSelector(loggedInUserSelectors.leaveAttendanceConfig);

  let isLOPEditable = true;
  let lopText =
    'Enter the number of days of salary to deduct. The amount will be reduced from employee’s gross pay.';

  if (!lopDeduction) {
    return [isLOPEditable, lopText, '', ''];
  }
  const lopAmount = lopAmountData?.lop_amount ?? lopDeduction.amount;

  let lopAmountText = `⚡️Loss of Pay for ${
    lopDeduction.lop_days.toString() ?? '0'
  } days is ₹${toIndianCurrencyString(lopAmount, true)}`;

  if(isM2S1Enabled || isM2S2Enabled){
    lopAmountText = '';
  }
  else if (lopDeduction.lop_days === '0') {
    lopAmountText = '';
  } else if (lopAmountStatus === 'loading') {
    lopAmountText = 'Calculating LOP amount...';
  } else if (lopAmountStatus === 'error') {
    lopAmountText = 'Error calculating LOP amount';
  }

  if (payrollId === null) {
    lopAmountText = '';
  }

  if (Boolean(leaveAttendanceConfig?.autoAttendanceLop) || lopDeduction.type === DeductionType.TYPE_AUTO_ATTENDANCE_LOP) {
    isLOPEditable = false;
    lopText =
      'Loss of pay days synced basis Unpaid leaves applied by the employee. Regularise employee’s attendance if this needs to change.';
  }

  if (lopDeduction.type === DeductionType.TYPE_JIBBLE_ATTENDANCE_LOP) {
    isLOPEditable = false;
    lopText =
      'Loss of pay days synced from Jibble. Regularise employee’s attendance on Jibble if this needs to change.';
  }

  let lopValue = lopDeduction.lop_days.toString();
  if (lopDeduction.lop_days === '0' && isLOPEditable) {
    lopValue = '';
  }

  return [isLOPEditable, lopText, lopAmountText, lopValue];
};

export default useLopTexts;
