import React, { PropsWithChildren } from 'react';

function Tag({
  value,
  onChange,
  isSelected,
}: PropsWithChildren<{ value: string; onChange: (value: string) => void; isSelected: boolean }>) {
  const tagStyle = `px-4 py-2 mt-2 mr-3 mb-2 rounded-md font-semibold cursor-pointer text-md ${
    isSelected ? 'bg-white-o-80 text-surfaceTextStaticBlackNormal' : 'bg-persian-blue'
  }`;
  return (
    <div className={tagStyle} onClick={() => onChange(value)}>
      {value}
    </div>
  );
}

export function Tags({
  onChange,
  value,
}: PropsWithChildren<{
  onChange: (item: string) => void;
  value: string;
}>) {
  const tags = [
    'All Integrations',
    'Banking',
    'HRMS',
    'Communication',
    'Insurance',
    'Attendance',
    'ITR Filing',
    'Performance',
    'Background Verification'
  ];
  return (
    <div className="flex items-center flex-wrap mb-4 mt-12">
      {tags.map((item, index) => (
        <Tag key={index} value={item} onChange={onChange} isSelected={item === value} />
      ))}
    </div>
  );
}
