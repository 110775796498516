import api from "api";
import { InsurancePurchaseTermsAndCondition } from "components/insurance/ShowBalanceConfirmationModal";
import { PrimaryButton } from "components/ui/Button";
import { Input } from "components/ui/Form/Input";
import { Modal } from "components/ui/Modal";
import Spinner from "components/ui/Spinner";
import { StandardTable, TableLayout, TableWithLoader } from "components/ui/Table";
import Pagination, { paginationStateActionType, paginationStateType } from "components/ui/Table/pagination";
import React, { useRef, useState } from "react";
import { IInsurance } from "reducers/insurance";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";
import { formatStringToYMD } from "utils/Dates";
import { debounce } from "utils/Debounce";
import { toIndianCurrencyString } from "utils/Numbers";
import { renewalPageStateActionType, renewalPageStateType, RenewalPurchaseState } from ".";
import styles from './index.module.css';

interface Props {
    insurance: IInsurance,
    organizationAndEmployeeRenewalData:GetInsuranceRenewalOrganizationAndEmployeeContract,
    pageStateDispatch:React.Dispatch<renewalPageStateActionType>,
    pageState:renewalPageStateType,
    paginationState:paginationStateType,
    paginationDispatch:React.Dispatch<paginationStateActionType>,
    fetchOrganizationAndEmployeeRenewalData:Function
}

type employeeDetailsType = {
    name : string,
    id : number,
    label : string,
    startDate : string,
    endDate : string,
    amount : number,
    gst : number
}


const RenewalMemberDetails:React.FC<Props> = ({
    insurance,
    organizationAndEmployeeRenewalData,
    pageStateDispatch,
    pageState,
    paginationState,
    paginationDispatch,
    fetchOrganizationAndEmployeeRenewalData}) =>
{
    const [showEmployeeDetailsModal, setshowEmployeeDetailsModal] = useState(false);
    const [renewalEmployeeData, setrenewalEmployeeData] = useState<[employeeDetailsType]>();
    const renewalPurchaseCallPauseRef = useRef(false);

    const purchaseInsuranceRenewalEndorsement  = async (id:number) =>{
        try{
            if (renewalPurchaseCallPauseRef.current) return;
            renewalPurchaseCallPauseRef.current = true;
            pageStateDispatch({type:'renewalPurchaseState',data: RenewalPurchaseState.InProcess});
            const apiResponse = await api.insurance.purchaseInsuranceRenewalEndorsement(id);
            pageStateDispatch({type:'showRenewalPaymentConfirmationModel',data:false})
            if(apiResponse.success){
                pageStateDispatch({type:'renewalPurchaseState',data: RenewalPurchaseState.Success});
            }
            else{
                pageStateDispatch({type:'renewalPurchaseState',data: RenewalPurchaseState.Failure});
            }
        }
        catch(error){
            pageStateDispatch({type:'showRenewalPaymentConfirmationModel',data:false})
            console.log('Failed to purchase Insurace',error); //adding for our convenince
            pageStateDispatch({type:'renewalPurchaseState',data: RenewalPurchaseState.Failure});
        }
        renewalPurchaseCallPauseRef.current = false;
    }

    return (   
          <div className={`${styles['renewal-page-employee-details-container']}`}>
            <Input
                className=" sm:px-4 w-1/2 sm:mr-4 sm:mb-4"
                type="text"
                value={pageState.searchEmployeeInputData}
                onChange={(event)=>{ 
                    pageStateDispatch({type:'searchEmployeeInputData',data:event.target.value});
                    debounce(()=>fetchOrganizationAndEmployeeRenewalData(insurance.data?.organizationId,event.target.value),1000)
                }}
                placeholder="Search by Employee name"
            />
            <TableWithLoader isLoading={pageState.employeeRenewalLoader} className=" flex-none w-full py-2 mt-4">
                <tbody>
                <tr>
                    <th>S. No.</th>
                    <th>Employee Name</th>
                    <th>Emp ID</th>
                    <th>Date Of Expiry</th>
                    <th>Total Premium</th>
                    <th>Status</th>
                </tr>
                { !!organizationAndEmployeeRenewalData?.employeeDetails?.data  && organizationAndEmployeeRenewalData?.employeeDetails?.data?.length > 0 &&
                    organizationAndEmployeeRenewalData?.employeeDetails?.data?.map((employee: any, index: number) => (

                        <tr key={index}>
                            <td>{(paginationState?.perPage * (paginationState?.currentPage - 1)) + index+1}</td>
                            <td>{employee?.name ?? 'N/A'}</td>
                            <td>{'EID-' + (employee?.employeeId ?? 0)}</td>
                            <td>{formatStringToYMD(employee?.endDate) ?? 'N/A'}</td>
                            <td>{toIndianCurrencyString(Number(employee?.amount) + (employee?.gst),false,2) ?? 0} </td>
                            <td><h3 className="text-primary font-semibold" onClick={(e)=>
                            {
                                setshowEmployeeDetailsModal(true);
                                setrenewalEmployeeData(employee?.details);
                            }}>View details</h3></td>
                        </tr>

                    ))
                }
                </tbody>
            </TableWithLoader>
            { 
                !!organizationAndEmployeeRenewalData?.employeeDetails?.data && !(organizationAndEmployeeRenewalData?.employeeDetails?.data?.length > 0) 
                    && <div className="p-10 text-center" style={{background:'#2C3251'}}> No Employees available</div>
            }
            <Pagination paginationState={paginationState} paginationDispatch={paginationDispatch} />
            <Modal
                showModal={showEmployeeDetailsModal}
                heading="Insurance details"
                className= {`${styles['renewal-page-employee-details-modal']}`}
                contentClass="flex flex-col"
                closeModal={() => setshowEmployeeDetailsModal(false)}
            >
                <>
                    <hr className="my-6" />
                    <StandardTable className="w-full flex-1 py-2 mt-1 mb-1">
                        <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Relation</th>
                            <th>Start date</th>
                            <th>Date Of Expiry</th>
                            <th>Amount</th>
                        </tr>
                        {
                            renewalEmployeeData && renewalEmployeeData?.map((members: employeeDetailsType, index: number) => (

                                <tr key={index}>
                                    <td>{members?.name ?? 'N/A'}</td>
                                    <td>{members?.label ?? 'N/A'}</td>
                                    <td>{formatStringToYMD(members?.startDate) ?? 'N/A'}</td>
                                    <td>{formatStringToYMD(members?.endDate) ?? 'N/A'}</td>
                                    <td>{toIndianCurrencyString(Number(members?.amount) + (members?.gst),false,2) ?? 0} </td>
                                </tr>

                            ))
                        }
                        </tbody>
                    </StandardTable>
                </>
            </Modal>
            
            <Modal
                showModal={pageState?.showRenewalPaymentConfirmationModel}
                heading="Confirm payment"
                className=""
                contentClass="flex flex-col h-full"
                closeModal={() => pageStateDispatch({type:'showRenewalPaymentConfirmationModel',data:false})}
            >
                <>
                    <InsurancePurchaseTermsAndCondition className="overflow-y-auto flex-grow-1" />
                    <hr className="my-6" />
                    <TableLayout className="-mx-4 w-full">
                        <tr>
                            <td>Insurance Renewal premium total</td>
                            <td className="font-semibold text-right text-primary text-3xl">
                                {toIndianCurrencyString(organizationAndEmployeeRenewalData?.localEstimate?.total ?? 0,false,2)}
                            </td>
                        </tr>
                        <tr>
                            <td>XPayroll wallet balance</td>
                            <td className="font-semibold text-right text-primary text-3xl">
                                {organizationAndEmployeeRenewalData?.currentBalance !== undefined ? toIndianCurrencyString(organizationAndEmployeeRenewalData?.currentBalance ?? 0 ,false,2) : 'NA'}
                            </td>
                        </tr>
                    </TableLayout>
                    <div className="w-full justify-center flex pb-10">
                        <div className="space-x-2 mt-8">
                            <input
                                type="checkbox"
                                id="agreeTermsAndCondition"
                                name="confirm"
                                value={pageState?.readTermsAndCondition ? 'checked' : 'unchecked'}
                                checked={pageState?.readTermsAndCondition ? true : false}
                                onChange={(event) => {
                                    pageStateDispatch({type:'readTermsAndCondition',data:(event.currentTarget.value === 'unchecked')})
                                    
                                }}
                            />
                            <label htmlFor="agreeTermsAndCondition">
                                I agree with above Terms and Conditions.
                            </label>
                        </div>
                    </div>

                    <PrimaryButton
                        onClick={() => {
                            if(insurance.data?.organizationId){
                                purchaseInsuranceRenewalEndorsement(insurance.data?.organizationId);
                            }
                        }}
                        // className= {`${styles['renewal-page-btn']}`}
                        style={{opacity:((!pageState?.readTermsAndCondition || pageState.renewalPurchaseState !== 0) ? '0.5' : '1')}}
                        disabled={!pageState?.readTermsAndCondition || pageState.renewalPurchaseState !== 0}
                    >
                        
                        PAY {toIndianCurrencyString(organizationAndEmployeeRenewalData?.localEstimate?.total ?? 0,false,2)}  
                        {pageState.renewalPurchaseState === RenewalPurchaseState.InProcess ?<span style={{padding: '0.7em 1em'}}><Spinner /></span> : ''}
                    </PrimaryButton>
                </>
            </Modal>
        </div>)
}

export default RenewalMemberDetails;