import React from 'react';
import IntegrationsV1 from './IntegrationsV1';
import { IntegrationsV2 } from './v2';
import { Redirect, Route, Switch } from 'react-router-dom';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';
import { IntegrationsV1RestrictedFlag } from './constants';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const Integrations = () => {
  const restrictedFeatures = useSelector(loggedInUserSelectors.features);
  const isIntegrationsV2Enabled = !!restrictedFeatures?.[IntegrationsV1RestrictedFlag];

  return (
    <Switch>
      <RestrictedRoute
        exact
        path={routePaths.integrations.root}
        component={IntegrationsV1}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
      />
      {isIntegrationsV2Enabled && (
        <RestrictedRoute
          exact
          path={routePaths.integrations.v2}
          component={IntegrationsV2}
          allowedFeatures={[IntegrationsV1RestrictedFlag]}
          allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        />
      )}
      <Route>
        <Redirect to={routePaths.unknown} />
      </Route>
    </Switch>
  );
};

export { Integrations };
