import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import permissions from 'constants/permissions';
import { KnitHrmsSettings } from './Settings';
import { Introduction } from './Introduction';
import { Manage } from './Manage';
import { Integration } from './Integration';
import { KnitHrmsAppsFeatureFlags } from '../constants';

const KnitHrms = () => {
  const knitHrmsAppsFeatureFlags = useMemo(() => Object.values(KnitHrmsAppsFeatureFlags), []);

  return (
    <Switch>
      <RestrictedRoute
        path={[routePaths.settings.knitHrms.root, routePaths.settings.knitHrms.introduction]}
        exact
        component={Introduction}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.manage}
        exact
        component={Manage}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.integration}
        exact
        component={Integration}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.root}
        component={KnitHrmsSettings}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
    </Switch>
  );
};

export { KnitHrms };
