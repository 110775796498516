import React from 'react';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';
import { KnitApps, KnitHrmsAppsFeatureFlags } from 'components/Knit/constants';
import { DarwinboxIntroduction } from './Darwinbox';
import { HiBobIntroduction } from './HiBob';
import { Switch } from 'react-router-dom';
import { WorqIntroduction } from './Worq';
import { WorklineIntroduction } from './Workline';

const Introduction = () => {
  return (
    <Switch>
      <RestrictedRoute
        path={[routePaths.settings.darwinbox.root, routePaths.settings.darwinbox.introduction]}
        exact
        component={DarwinboxIntroduction}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.darwinbox]]}
      />
      <RestrictedRoute
        path={[routePaths.settings.hibob.root, routePaths.settings.hibob.introduction]}
        exact
        component={HiBobIntroduction}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.hibob]]}
      />
      <RestrictedRoute
        path={[routePaths.settings.worq.root, routePaths.settings.worq.introduction]}
        exact
        component={WorqIntroduction}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.worq]]}
      />
      <RestrictedRoute
        path={[routePaths.settings.workline.root, routePaths.settings.workline.introduction]}
        exact
        component={WorklineIntroduction}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={[KnitHrmsAppsFeatureFlags[KnitApps.workline]]}
      />
    </Switch>
  );
};

export { Introduction };
