import React, { ReactElement } from 'react';
import styles from './index.module.scss';

interface Props {
  children: React.ReactNode;
  type?: 'regular' | 'fullWidth' | 'none';
  center?: boolean;
  className?: string;
}

const classMap = {
  regular: 'main__case--sidebar',
  fullWidth: 'main__case--fullWidth',
  none: '',
};

function MainCase({
  children,
  type = 'regular',
  center = false,
  className = '',
}: Props): ReactElement {
  return (
    <div className={`${styles['MainCase']} ${classMap[type]} ${center ? styles['Centered'] : ''} ${className}`}>
      {children}
    </div>
  );
}

export default MainCase;
