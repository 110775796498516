import React from 'react';
import styles from './index.module.scss';
import { getStaticMediaUrl } from 'utils/Urls';
import jupiterInstallImage from 'assets/jupiter/jupiter_intro.png';
import { Card } from 'components/ui/Card';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import ExternalLink from 'components/ui/ExternalLink';
import { trackSegment } from 'utils/segment';

function JupiterIntroduction() {
  return (
    <>
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(jupiterInstallImage)} />
      <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
        Now integrate XPayroll with Jupiter
      </div>
      <div className="text-white-o-70 text-center">
        Open a fully free digital bank account and revamp the way you bank.
      </div>
      <Card className={`${styles['Card']} mt-12 px-10 py-10`} withTopBorder>
        <div className="font-bold text-white-o-80 mb-8 text-100">Features and benefits</div>
        <NumberedList className="pl-6">
          <NumberedListItem>
            100% Digital - Create a bank account while making a playlist or posting a picture.
            That’s 100% digital and zero percent painful.
          </NumberedListItem>
          <NumberedListItem>
            Master your money - Get real-time spend breakdowns with Insights.
          </NumberedListItem>
          <NumberedListItem>
            Earn rewards in real-time - Instant rewards like nowhere else. Get 1% rewards on Debit
            card and UPI purchases.
          </NumberedListItem>
        </NumberedList>
      </Card>
      <Card className={`${styles['Card']} mt-12 px-10 py-10`} withTopBorder>
        <div className="font-bold text-white-o-80 mb-8 text-100">How to get started</div>
        <NumberedList className="pl-6">
          <NumberedListItem>
            Book a call with our banking experts at Jupiter to know about the benefits of the
            employee salary accounts
          </NumberedListItem>
          <NumberedListItem>
            Get your employees onboard Jupiter. Their bank accounts sync with XPayroll and they
            receive salaries in this new account
          </NumberedListItem>
        </NumberedList>
      </Card>
      <ExternalLink
        onClick={() => trackSegment('Booking a call for Jupiter')}
        href="https://calendly.com/xpayroll-jupiter/30min"
        className="mt-8 bg-primary py-4 px-8 text-white rounded hover:text-white"
      >
        Book A Call
      </ExternalLink>
    </>
  );
}

export default JupiterIntroduction;
