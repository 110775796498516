import React from 'react';
import { Divider, List, ListItem, ListItemText, Text } from '@razorpay/blade/components';
import HeaderSection from './HeaderSection';
import InfoSection from './InfoSection';

const TaxabilityInfo = () => {
  return (
    <>
      <HeaderSection
        title={'Taxability'}
        description={
          'Choose if the salary component is taxable or tax-exempt in both the income tax regimes.'
        }
      />

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Tax Exempted in Old Regime'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              This means the component is not taxable under the old income tax regime (which allows
              various deductions like HRA, Section 80C, LTA, etc.).
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              If this is selected, the system will not calculate tax on this component under the old
              regime.{' '}
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={' ✅ Tax Exempted in New Regime'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              This means the component is not taxable under the new income tax regime (introduced in
              FY 2020-21), which offers lower tax rates but no deductions or exemptions.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              Selecting this means the component will remain tax-free in the new regime.{' '}
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>

      <Divider marginY={'spacing.7'} />

      <InfoSection title={'✅ Exemption Section'}>
        <List size={'small'} variant={'unordered'}>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              If a component is tax-exempt in either regime, this setting specifies the section of
              the Income Tax Act that provides the exemption.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText color={'surface.text.gray.muted'}>
              This ensures proper tax treatment and compliance with legal provisions.{' '}
            </ListItemText>
          </ListItem>
        </List>
      </InfoSection>
    </>
  );
};

export default TaxabilityInfo;
