import React, { ReactElement } from "react";

import PageWrapper from "components/ui/PageWrapper";
import MainCase from "components/ui/MainCase";
import RightSidebar from "components/ui/RightSidebar";
import { PartnerFlexiBenefitsFAQ } from "components/PartnerFlexiBenefits/PartnerFlexiBenefitsFAQ";
import ZaggleManagement from "./ZaggleManagement";
import BookDemoFAQ from "../../PartnerFlexiBenefitsFAQ/BookDemoFAQ";

const ZaggleAdminPage = () : ReactElement => {
    return (
        <PageWrapper>
            <MainCase>
                <ZaggleManagement />
            </MainCase>
            <RightSidebar>
                <BookDemoFAQ/>
                <PartnerFlexiBenefitsFAQ />
            </RightSidebar>
        </PageWrapper>
    );
}
export default ZaggleAdminPage;