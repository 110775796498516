import React, { FormEvent, useState } from 'react';
import { useModal } from 'components/ui/Modals';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { Input, InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import { useDispatch, useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useMutation } from 'react-query';
import { InviteCARequest } from 'api/companyOnboarding';
import api from 'api';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { EMAIL_REGEX } from 'utils/regexes';
import styles from './index.module.scss';
import { trackSegment } from 'utils/segment';
import successBg from 'assets/image--success-tick-with-bg.png';
import {getStaticMediaUrl} from "../../../../../utils/Urls";

const InviteCAModal = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isInviteSuccess, setIsInviteSuccess] = useState(false);
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const inviteCAMutation = useMutation(
    ({ name, email, phone }: InviteCARequest) =>
      api.companyOnboarding.inviteCA({
        name,
        email,
        phone,
      }),
    {
      onSuccess: (data) => {
        dispatch(fetchLoggedInUser());
        setIsInviteSuccess(true);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    trackSegment("onboarding.setup_compliance.tds.invite_your_ca_click");
    inviteCAMutation.mutate({
      name,
      email,
      phone,
    });
  };

  const isFormBeingSubmitted = inviteCAMutation.status === 'loading';

  if (isInviteSuccess) {
    return (
      <div className={styles['InviteSuccessContainer']}>
        <img
          className={styles['Illustration']}
          src={getStaticMediaUrl(successBg)}
          alt="invite-success"
        />
        <div className={styles['Title']}>
          Invitation email is sent successfully to your Chartered Accountant
        </div>
        <div className={styles['Description']}>
          Your Chartered Accountant can login to XPayroll via the invitation email and help in
          account setup
        </div>
        <StandardButton type="button" aria-label="okay" onClick={closeModal}>
          Okay
        </StandardButton>
      </div>
    );
  }

  return (
    <form className="p-10" onSubmit={handleSubmit}>
      <div className="text-white-o-80 text-4xl mb-10 font-bold">
        Invite your Chartered Accountant
      </div>
      {inviteCAMutation.status === 'error' && (
        <WarningAlert>
          <Errors errorResponse={inviteCAMutation.error} />
        </WarningAlert>
      )}
      <InputElement required label="Name" className="mt-4 mb-10">
        <Input
          required
          type="text"
          maxLength={255}
          name="ca-name"
          placeholder="John Doe"
          disabled={isFormBeingSubmitted}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputElement>
      <InputElement required label="Email address" className="mt-4 mb-10">
        <Input
          required
          type="email"
          maxLength={255}
          name="ca-email"
          pattern={EMAIL_REGEX}
          placeholder="john.doe@example.com"
          disabled={isFormBeingSubmitted}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputInfo>
          Invitee will have admin access. You can always change the user role later!
        </InputInfo>
      </InputElement>
      <InputElement label="Phone number" className="mt-4 mb-10">
        <Input
          type="number"
          name="ca-phone"
          placeholder="eg. 9810012345"
          disabled={isFormBeingSubmitted}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </InputElement>
      <StandardButton>Send invite</StandardButton>
    </form>
  );
};

const InviteCA = () => {
  const { openModal } = useModal();

  const handleOpenInvite = () => {
    openModal(<InviteCAModal />);
  };

  return (
    <div className="flex items-center bg-persian-blue px-10 py-12 gap-8">
      <div>
        <div className="text-white-o-80 font-bold">
          Don't have compliance details? Invite your Chartered Accountant
        </div>
        <div className="text-white-o-70 text-xl mt-3">
          Invite your Chartered Accountant to setup your tax and get TRACES.
        </div>
      </div>
      <div>
        <StandardSecondaryButton type="button" onClick={handleOpenInvite}>
          Invite Now
        </StandardSecondaryButton>
      </div>
    </div>
  );
};

export default InviteCA;
