import { Box } from '@razorpay/blade/components';
import React, { PropsWithChildren, ReactNode } from 'react';

const ComponentSectionItem = ({ children }: PropsWithChildren<ReactNode>) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {children}
    </Box>
  );
};

export default ComponentSectionItem;
