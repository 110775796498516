import React, { ReactElement, useState } from "react";
import ZaggleActionItemsAlert from "./ZaggleActionItemsAlert";
import { useSelector } from "react-redux";
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import { useQuery } from "react-query";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { getOrgFlexiDetails } from "../../utils/init";
import api from '../../../../../api';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "../../utils/queries";
import { AppError } from "utils/AppError";
import { WarningAlert } from "components/ui/Alert";
import Errors from "components/ui/Form/Errors";
import Spinner from "components/ui/Spinner";
import { getActionItemsCount } from "../../utils/helpers";

const ZaggleActionItemsHeading = (): ReactElement => {

    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const [flexiDetails, setFlexiDetails] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails())
    const { isLoading, isRefetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      }, {
        onSuccess: (data) => data && setFlexiDetails(data),
        onError: (error: typeof AppError) => {
            console.log("Error on fetching Org's flexi details data : ", error);
        }
      });

    if (isLoading ||( data?.isOpted && isRefetching)) {
        return (
        <div className={'flex justify-center'}>
            <Spinner size={40}/>
        </div>); 
    }

    if(error){
        return (<WarningAlert><Errors errorResponse={error}/></WarningAlert>);
    }

    const actionItemCount = getActionItemsCount(data);

    return (
        <>
            <ZaggleActionItemsAlert success={null}/>
            <div className='space-y-8 mb-4 centered font-bold text-4xl'>
                Pending Action Items ({actionItemCount})
            </div>
        </>
    )
    
}

export default ZaggleActionItemsHeading;