import { Card } from 'components/ui/Card';
import React, { ReactElement } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import pazcareIntro from 'assets/pazcare/pazcare-intro.svg';
import addition from 'assets/user-addition.png';
import assignment from 'assets/user.png';
import providing from 'assets/umbrella.png';
import { TickList, TickListItem } from 'components/ui/TickList';
import { routePaths } from 'components/Routes/data';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/ui/ExternalLink';
import styles from './index.module.scss';

function PazcareIntro(): ReactElement {
  return (
    <>
      <img src={getStaticMediaUrl(pazcareIntro)} alt='Pazcare Intro Logo' />
      <div className={styles['page']}>
        Now integrate RazorpayX Payroll with{' '}
        <span className={styles['heading']}>Pazcare</span>
      </div>
      <p className={styles['content']}>
        Securely automate insurance benefit assignment for all employees with Pazcare.
      </p>
      <Card className={styles['card']}>
        <div className={styles['cardHeading']}>Now with Pazcare, get</div>
        <div className={styles['cardContent']}>
          <div className={styles['benefit']}>
            <img src={getStaticMediaUrl(addition)} alt='addition icon'></img>
            <p className={styles['text']}>
              Automatic employee addition and deletion to the Pazcare dashboard
            </p>
          </div>
          <div className={styles['benefit']}>
            <img src={getStaticMediaUrl(assignment)} alt= 'onboarding icon'></img>
            <p className={styles['text']}>
              Automate benefits assignment for all employees
            </p>
          </div>
          <div className={styles['benefit']}>
            <img src={getStaticMediaUrl(providing)} alt='bulk hiring icon'></img>
            <p className={styles['text']}>
              Never miss out on providing benefits to any employee
            </p>
          </div>
        </div>
        <div className={styles['demo']}>
          <ExternalLink href="https://calendly.com/xpayroll-pazcare">
            <StandardSecondaryButton className="capitalize">Book a demo</StandardSecondaryButton>
          </ExternalLink>
        </div>
      </Card>
      <Card className={styles['card']}>
        <div className={styles['cardHeading']}>Why integrate with RazorpayX Payroll ?</div>
        <TickList className="pl-6">
          <TickListItem>
            <span className={styles['item']}>
              Automated addition, and deletion of employees from the Pazcare platform, and hence, your group insurance policies.
            </span>
          </TickListItem>
          <TickListItem>
            <span className={styles['item']}>
              Employees receive an email with their insurance details with the ability to add/modify their dependents.
            </span>
          </TickListItem>
          <TickListItem>
            <span className={styles['item']}>
              No need to manually manage insurance coverage for each new joinee and employee exit.
            </span>
          </TickListItem>
        </TickList>
      </Card>
      <Link to={routePaths.settings.pazcare.activation}>
        <StandardButton className="mt-12">Connect Now</StandardButton>
      </Link>
    </>
  );
}

export default PazcareIntro;
