import React, { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import Note from 'components/ui/Note';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { CheckBox, Input } from 'components/ui/Form/Input';
import { LinkButtonV2, StandardButton } from 'components/ui/Button';
import { Table } from 'components/ui/Table';
import { useDispatch, useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { Select, Option } from 'components/ui/Form/Select';
import { concatenateAllowances } from './utils';
import Allowance, { amountTypes, taxableTypes } from 'utils/Allowance';
import api from 'api';
import { SalaryStructureRequest } from 'api/companyOnboarding';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { AppError } from 'utils/AppError';
import Spinner from 'components/ui/Spinner';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { trackSegment } from 'utils/segment';
import { trackGtag, trackRazorpayAnalytics } from 'utils/razorpayAnalytics';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const PercentageOptions = [
  { value: amountTypes.ALLOWANCE_AMOUNT_TYPE_PERCENTAGE, name: 'Percentage' },
  { value: amountTypes.ALLOWANCE_AMOUNT_TYPE_FIXED, name: 'Fixed' },
];

const TaxableOptions = [
  { value: taxableTypes.TYPE_YES, name: 'Yes' },
  { value: taxableTypes.TYPE_NO, name: 'No' },
  { value: taxableTypes.TYPE_FLEXI, name: 'Flexi' },
];

const SalaryStructure = () => {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const {isCustomSalaryStructureDisabled} = useIsFeatureDisabledForRearch()
  const features = currentOrganization?.features||{}
  const salaryStructureConfig = currentOrganization?.salaryStructureConfig;
  const defaultSalaryCustomAllowancesAllowed =
    useSelector(loggedInUserSelectors.appConfig).defaultSalaryCustomAllowancesAllowed || 0;
  const organizationCustomAllowances = useSelector(
    loggedInUserSelectors.organizationCustomAllowances,
  );
  const organizationDefaultSalaryStructure = useSelector(
    loggedInUserSelectors.organizationDefaultSalaryStructure,
  );
  const links = useSelector(loggedInUserSelectors.referenceLinks);
  const [shouldUseDefaultSalaryStructure, setShouldUseDefaultSalaryStructure] = useState(
    !!salaryStructureConfig?.useDefault,
  );
  const [shouldFlexibleBenefitsBeTaxableByDefault, setShouldFlexibleBenefitsBeTaxableByDefault] =
    useState(!!salaryStructureConfig?.fbpDefaultTaxable);
  const [customAllowances] = useState(
    concatenateAllowances(organizationCustomAllowances, defaultSalaryCustomAllowancesAllowed),
  );
  const [isRedirectingHome, setIsRedirectingHome] = useState(false);

  const updateSalaryStructureMutation = useMutation(
    ({
      customBasic,
      customHra,
      customLta,
      customDa,
      customSpecialAllowance,
      customNpsLimit,
      customAllowances,
      shouldFlexibleBenefitsBeTaxableByDefault,
      shouldUseDefaultSalaryStructure,
    }: SalaryStructureRequest) =>
      api.companyOnboarding.updateSalaryStructure({
        customBasic,
        customHra,
        customLta,
        customDa,
        customSpecialAllowance,
        customNpsLimit,
        customAllowances,
        shouldFlexibleBenefitsBeTaxableByDefault,
        shouldUseDefaultSalaryStructure,
      }),
    {
      onError: (error: typeof AppError) => {},
      onSuccess: (data) => {
        trackSegment('onboarding.company_details.set_employee_salary_structure.continue_click', { queue: true });
        triggerOnboardingPixels();
        setIsRedirectingHome(true);
        window.location.href = '/';
      },
    },
  );

  const triggerOnboardingPixels = () => {
    trackRazorpayAnalytics('fb', 'Emp_Salary_Structure-Payroll');
    trackRazorpayAnalytics('linkedin_onboarding_salary_structure', { conversion_id: 7377308 });
    trackRazorpayAnalytics('twitter_onboarding_salary_structure', {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    trackGtag('event', 'conversion', { send_to: 'AW-928471290/zzc7CLSC_rIDEPqx3boD' });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const formObject: { [k: string]: FormDataEntryValue } = {};
    for (var pair of formData.entries()) {
      const name = pair[0];
      const value = pair[1];
      formObject[name] = value;
    }
    let customBasic = 0;
    let customHra = 0;
    let customSpecialAllowance = 0;
    let customLta = 0;
    let customDa = 0;
    let customNpsLimit = 0;

    const allowances: Allowance[] = [];

    if (!shouldUseDefaultSalaryStructure) {
      customBasic = parseInt(formObject['custom-basic'].toString());
      customHra = parseInt(formObject['custom-hra'].toString());
      customSpecialAllowance = parseInt(formObject['custom-special-allowance'].toString());
      customLta = parseInt(formObject['custom-lta'].toString());
      customDa = parseInt(formObject['custom-da'].toString());
      if (formObject['custom-nps-limit']) {
        customNpsLimit = parseInt(formObject['custom-nps-limit'].toString());
      }

      for (const key in formObject) {
        if (key.startsWith('custom-allowance-name-')) {
          if (formObject[key]) {
            const idx = key.split('-')[3];
            allowances.push(
              new Allowance({
                name: formObject[key].toString(),
                value: parseInt(formObject[`custom-allowance-value-${idx}`].toString()),
                amountType: parseInt(formObject[`custom-allowance-type-${idx}`].toString()),
                taxableType: parseInt(formObject[`custom-allowance-taxable-${idx}`].toString()),
              }),
            );
          }
        }
      }
    }

    const requestObject = {
      customBasic,
      customHra,
      customSpecialAllowance,
      customLta,
      customDa,
      customNpsLimit,
      customAllowances: allowances,
      shouldFlexibleBenefitsBeTaxableByDefault,
      shouldUseDefaultSalaryStructure,
    };

    updateSalaryStructureMutation.mutate(requestObject);
  };

  const isFormBeingSubmitted = updateSalaryStructureMutation.status === 'loading';

  if (isRedirectingHome) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit}>
        {updateSalaryStructureMutation.status === 'error' && (
          <WarningAlert>
            <Errors errorResponse={updateSalaryStructureMutation.error} />
          </WarningAlert>
        )}
        <div className="mb-3">Default Salary Structure</div>
        <Note>
          <NumberedList>
            <NumberedListItem>
              You can {isCustomSalaryStructureDisabled?'view':'customize'} the default salary structure that XPayroll uses for your employees.
            </NumberedListItem>
            <NumberedListItem>
              Employer contributions to PF and ESI (if applicable) are applied after the salary has
              been bifurcated into the components in the ratio defined. Hence, the final salary
              structure can have a slightly different ratio.
            </NumberedListItem>
          </NumberedList>
        </Note>

        <IsFeatureDisabledForRearch feature='isCustomSalaryStructureDisabled'>
        <CheckBox
          label="Use XPayroll's default salary structure (recommended)"
          id="use-default-structure"
          className="mb-10"
          value="use-default-stucture"
          name="test"
          checked={shouldUseDefaultSalaryStructure}
          disabled={isFormBeingSubmitted}
          onChange={(event) => setShouldUseDefaultSalaryStructure(!!event.target.checked)}
        />
        </IsFeatureDisabledForRearch>

        {shouldUseDefaultSalaryStructure ? (
          <Table className="w-full mb-10">
            <thead>
              <tr>
                <th>Salary Component</th>
                <th>%age of CTC</th>
                <th>Percentage or Fixed?</th>
                <th>Taxable?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Salary</td>
                <td>{organizationDefaultSalaryStructure.basicPercentage}</td>
                <td>Percentage</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>HRA</td>
                <td>{organizationDefaultSalaryStructure.hraPercentage}</td>
                <td>Percentage</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Special Allowance</td>
                <td>{organizationDefaultSalaryStructure.specialAllowancePercentage}</td>
                <td>Percentage</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>LTA</td>
                <td>{organizationDefaultSalaryStructure.ltaPercentage}</td>
                <td>Percentage</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <>
            <Note>
              <NumberedList>
                <NumberedListItem>
                  To remove any predefined component, enter "0" as the percentage/amount.
                </NumberedListItem>
                <NumberedListItem>
                  Fixed amounts should be entered as per monthly salary.
                </NumberedListItem>
                <NumberedListItem>
                  If the sum of components doesn't match with monthly pay, the difference amount
                  will be adjusted from these components - LTA, HRA, Special Allowances, Basic
                  (Where LTA has the highest priority and Basic has the lowest).
                </NumberedListItem>
              </NumberedList>
            </Note>
            <Table className="w-full mb-10">
              <thead>
                <tr>
                  <th>Salary Component</th>
                  <th>%age of CTC</th>
                  <th>Percentage or Fixed?</th>
                  <th>Taxable?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>
                    <Input
                      type="number"
                      defaultValue={salaryStructureConfig?.percBasic || 0}
                      required
                      name="custom-basic"
                      disabled={isFormBeingSubmitted}
                    />
                  </td>
                  <td>Percentage</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>DA</td>
                  <td>
                    <Input
                      type="number"
                      defaultValue={salaryStructureConfig?.percDa || 0}
                      required
                      name="custom-da"
                      disabled={isFormBeingSubmitted}
                    />
                  </td>
                  <td>Percentage</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>HRA</td>
                  <td>
                    <Input
                      type="number"
                      defaultValue={salaryStructureConfig?.percHra || 0}
                      required
                      name="custom-hra"
                      disabled={isFormBeingSubmitted}
                    />
                  </td>
                  <td>Percentage</td>
                  <td>
                    <a href={links.linkHra} target="_blank" rel="noreferrer">
                      <LinkButtonV2>Partially</LinkButtonV2>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Special Allowance</td>
                  <td>
                    <Input
                      type="number"
                      defaultValue={salaryStructureConfig?.percSpecialAllowance || 0}
                      required
                      name="custom-special-allowance"
                      disabled={isFormBeingSubmitted}
                    />
                  </td>
                  <td>Percentage</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>LTA</td>
                  <td>
                    <Input
                      type="number"
                      defaultValue={salaryStructureConfig?.percLta || 0}
                      required
                      name="custom-lta"
                      disabled={isFormBeingSubmitted}
                    />
                  </td>
                  <td>Percentage</td>
                  <td>Yes</td>
                </tr>
                <IsFeatureDisabledForRearch feature='isEmployerNPSDisabled'>
                {currentOrganization?.npsConfig?.isNpsEnabled && (
                  <tr>
                    <td>Employer NPS Contribution</td>
                    <td>
                      <Input
                        type="number"
                        defaultValue={salaryStructureConfig?.percNpsLimit || 0}
                        required
                        name="custom-nps-limit"
                        disabled={isFormBeingSubmitted}
                      />
                    </td>
                    <td>Percentage</td>
                    <td>No</td>
                  </tr>
                )}
                </IsFeatureDisabledForRearch>
                {customAllowances.map((allowance, idx) => {
                  return (
                    // there is no unique ID for this
                    <tr key={idx}>
                      <td>
                        <Input
                          type="text"
                          placeholder="Custom allowance"
                          defaultValue={allowance.name}
                          name={`custom-allowance-name-${idx}`}
                          disabled={isFormBeingSubmitted}
                        />
                      </td>
                      <td>
                        <Input
                          type="number"
                          defaultValue={allowance.value}
                          name={`custom-allowance-value-${idx}`}
                          disabled={isFormBeingSubmitted}
                        />
                      </td>
                      <td>
                        <Select
                          defaultValue={allowance.amountType}
                          name={`custom-allowance-type-${idx}`}
                          disabled={isFormBeingSubmitted}>
                          {PercentageOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <Select
                          defaultValue={allowance.taxableType}
                          name={`custom-allowance-taxable-${idx}`}
                          disabled={isFormBeingSubmitted}>
                          {TaxableOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}

        <StandardButton type="submit" isPending={isFormBeingSubmitted}>
          Continue
        </StandardButton>
      </form>
    </div>
  );
};

export default SalaryStructure;
