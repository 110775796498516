import { useLoadAsyncScript } from 'hooks/useLoadAsyncScript';
import { useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FETCH_AUTH_SESSION_TOKEN } from './queries';
import api from 'api';
import APIError from 'types/apiError';
import { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';

const knitUiScriptUrl = 'https://af1.getknit.dev/knit-ui-comp.js';

interface UseKnitIntegration {
  appId: string;
  categoryId: string;
  onIntegrationSuccess?: (data: SuccessResponseSchemaContract) => Promise<unknown> | void;
  onIntegrationFailure?: (error: APIError) => Promise<unknown> | void;
}

const useKnitIntegration = ({
  appId,
  categoryId,
  onIntegrationSuccess,
  onIntegrationFailure,
}: UseKnitIntegration) => {
  const knitRef = useRef<HTMLElement>(null);
  const knitScriptAsyncLoader = useLoadAsyncScript({
    url: knitUiScriptUrl,
    type: 'module',
  });

  const authSessionTokenQuery = useQuery(FETCH_AUTH_SESSION_TOKEN, () => {
    return api.knit.getAuthSessionToken(appId, categoryId);
  });

  const createIntegrationMutation = useMutation(api.knit.createIntegration, {
    onSuccess: (data) => {
      onIntegrationSuccess?.(data);
    },
    onError: (error: APIError) => {
      onIntegrationFailure?.(error);
    },
  });

  // Backend call to generate & get the authSessionToken
  const newSessionFn = (e?: CustomEvent) => {
    e?.preventDefault();
    knitRef?.current?.setAttribute('skipIntro', '');
    knitRef?.current?.setAttribute('skipAdminCheck', '');
  };

  // Upon finishing the integration flow
  const onFinishFn = (e: CustomEvent) => {
    e?.preventDefault();
    if (e['detail']['integrationDetails']['success'] === true) {
      createIntegrationMutation.mutate({
        integrationId: e['detail']['integrationDetails']['integrationId'],
        hrmsProvider: appId,
      });
    }
  };

  // Upon deletion of integration flow
  const onDeleteFn = (e: CustomEvent) => {
    e?.preventDefault();
  };

  useEffect(() => {
    // Assign functions to event listeners for onNewSession and onFinish events.
    knitRef.current?.addEventListener('onNewSession', ((event?: CustomEvent) => {
      newSessionFn(event);
    }) as EventListener);

    knitRef.current?.addEventListener('onFinish', ((event: CustomEvent) => {
      onFinishFn(event);
    }) as EventListener);

    knitRef.current?.addEventListener('onDelete', ((event: CustomEvent) => {
      onDeleteFn(event);
    }) as EventListener);
    // Set the token once the component has mounted
    newSessionFn();

    if (authSessionTokenQuery.isSuccess && knitRef.current) {
      knitRef?.current?.setAttribute('authSessionToken', authSessionTokenQuery.data.sessionToken);
    }

    // we should use copy of ref in cleanup functions - https://github.com/facebook/react/issues/14920
    const prevRefCurrent = knitRef.current;

    return () => {
      // Remove events listeners on unmount
      prevRefCurrent?.removeEventListener('onNewSession', ((event?: CustomEvent) => {
        newSessionFn(event);
      }) as EventListener);
      prevRefCurrent?.removeEventListener('onFinish', ((event: CustomEvent) => {
        onFinishFn(event);
      }) as EventListener);
      prevRefCurrent?.removeEventListener('onDelete', ((event: CustomEvent) => {
        onDeleteFn(event);
      }) as EventListener);
    };
  }, [knitRef.current]);

  return {
    knitRef,
    knitScriptAsyncLoader,
    authSessionTokenQuery,
    createIntegrationMutation,
  };
};

export { useKnitIntegration };
