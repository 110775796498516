import { Box, Heading, HomeIcon, Link } from '@razorpay/blade/components';
import React from 'react';
import { ProgressPill } from '../styles';
import { ProgressPillState } from '../types';

export const SidebarHeader = ({
  progress = ['COMPLETED', 'IN_PROGRESS'],
  onBackClick,
}: {
  progress?: ProgressPillState[];
  onBackClick?: (e: React.SyntheticEvent<Element, Event>) => void;
}) => {
  return (<>
    <Link variant="button" size="xsmall" icon={HomeIcon} onClick={onBackClick}>
      BACK TO DASHBOARD
    </Link>
    <Box display={'flex'} marginTop={'spacing.11'} gap={'spacing.2'} marginBottom={'spacing.6'}>
      {progress.map((item, i) => (
        <ProgressPill progressState={item} key={i} />
      ))}
    </Box>
    <Heading size="medium" color="surface.text.gray.muted">
      KYC Verification
    </Heading>
  </>);
};

export default SidebarHeader;
