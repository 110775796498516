import React from 'react';
import { PayslipComponent } from './types';

export const getFinancialYearList = () => {
  const currentDate = new Date();
  let currentFY = currentDate.getFullYear();
  if(currentDate.getMonth() < 3) {
    currentFY = currentFY - 1;
  }
  let yearList = [];
  while(currentFY >= 2019) {
    yearList.push(currentFY)
    currentFY--;
  }
  return yearList;
}

export const getMonthKeyList = (fy: string) => {
  const financialYear = +fy.slice(-2);
  return [
    `Apr ${financialYear}`,
    `May ${financialYear}`,
    `Jun ${financialYear}`,
    `Jul ${financialYear}`,
    `Aug ${financialYear}`,
    `Sep ${financialYear}`,
    `Oct ${financialYear}`,
    `Nov ${financialYear}`,
    `Dec ${financialYear}`,
    `Jan ${financialYear + 1}`,
    `Feb ${financialYear + 1}`,
    `Mar ${financialYear + 1}`,
  ];
};

export const Record = ({ size }: { size: number }) => {
  return (
    <tr>
      <td colSpan={size} style={{ height: '50px', backgroundColor: 'transparent' }}></td>
    </tr>
  );
};

export const KeyValueRecord = ({
  text,
  value,
  highlight,
}: {
  text: string;
  value: string | number;
  highlight?: boolean;
}) => {
  return (
    <tr>
      <td colSpan={4}>{highlight ? <strong>{text}</strong> : text}</td>
      <td colSpan={3}>{value}</td>
    </tr>
  );
};

export const HeaderRecord = ({ value, size }: { value: string | number; size: number }) => {
  return (
    <tr>
      <td colSpan={size}>
        <strong>{value}</strong>
      </td>
    </tr>
  );
};

export function getComponentNamesMap(totalComponents: { [key: string]: PayslipComponent }) {
  const namesMap: { [key: string]: string } = {};

  for (const month in totalComponents) {
    const monthlyComponents = totalComponents[month];
    for (const component in monthlyComponents) {
      const label = monthlyComponents[component].label;
      namesMap[monthlyComponents[component].name] = label;
    }
  }

  return namesMap;
}
