import styled from 'styled-components';

export const Day = styled.div<{
  isCurrentMonth?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fonts.family.text};
  font-size: ${({ theme }) => theme.typography.fonts.size[75]}px;
  font-weight: ${({ theme, isSelected }) =>
    isSelected ? theme.typography.fonts.weight.bold : theme.typography.fonts.weight.regular};
  padding: ${({ theme }) => theme.spacing[4]}px;
  cursor: pointer;
  color: ${(props) =>
    props.isCurrentMonth
      ? props.isDisabled
        ? props.theme.colors.surface.text.gray.disabled
        : props.theme.colors.surface.text.gray.normal
      : props.theme.colors.surface.text.gray.disabled};
  opacity: ${(props) => (props.isCurrentMonth ? (props.isDisabled ? 0.5 : 1) : 0.5)};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.surface.background.primary.intense : 'transparent'};
  color: ${(props) =>
    props.isSelected ? '#fff' : props.theme.colors.surface.text.gray.normal};

  :hover {
    background-color: ${(props) =>
      !props.isSelected && !props.isDisabled
        ? props.theme.colors.surface.background.gray.subtle
        : props.isSelected
        ? props.theme.colors.surface.background.primary.intense
        : 'transparent'};
  }
`;

export const Weekday = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.fonts.family.text};
  color: ${({ theme }) => theme.colors.surface.text.gray.normal};
  font-weight: ${({ theme }) => theme.typography.fonts.weight.bold};
`;

export const DropdownContainers = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[5]}px;
  flex: 1;
  & > * {
    flex: 1;
  }
`;
