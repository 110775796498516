import React from 'react';
import { useSelector } from 'react-redux';

import { getStaticMediaUrl } from 'utils/Urls';
import { OrgLocation } from '../utils/types';
import closeIcon from 'assets/icon--close-blue.svg';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useRunPayrollFilterContext } from '../contexts/RunPayrollFiltersContext';
import { LinkButtonV2 } from 'components/ui/Button';

import styles from './index.module.scss';

const StatusFilterMap = {
  finalized: 'Finalized',
  paid: 'Paid',
  skipped: 'Skipped',
  'net-pay-on-hold': 'NetPay on hold',
  'gross-pay-on-hold': 'Gross Pay on Hold',

};

const FilterStats = () => {
  const locations = useSelector(loggedInUserSelectors.locations);
  const filters = useRunPayrollFilterContext();
  const appliedFilters = filters?.filters;

  const onClearDepartment = (value: string) => {
    filters?.setDepartmentsFilter(
      appliedFilters?.departments.filter((department) => department !== value) ?? [],
    );
  };

  const onClearLocation = (value: string) => {
    filters?.setLocationsFilter(
      appliedFilters?.locations.filter((location) => location !== value) ?? [],
    );
  };

  const handleClearStatusFilter = () => {
    filters?.setStatusFilter(null);
  };

  const clearAll = () => {
    filters?.setDepartmentsFilter([]);
    filters?.setLocationsFilter([]);
    filters?.setStatusFilter(null);
  };

  if (
    !(
      appliedFilters?.locations.length ||
      appliedFilters?.departments.length ||
      appliedFilters?.statusFilter
    )
  ) {
    return null;
  }

  const departmentList = appliedFilters?.departments;
  const codeList = appliedFilters?.locations?.map((code) => +code);
  const locationList = (locations as OrgLocation[])?.filter((location) =>
    codeList.includes(location.code),
  );
  const statusFilter = appliedFilters.statusFilter;

  return (
    <div className={'flex flex-wrap content-center mb-10 gap-y-4'}>
      {!!departmentList.length &&
        departmentList.map((entry) => {
          return (
            <StatsDisplay key={entry} value={entry} text={entry} onClose={onClearDepartment} />
          );
        })}
      {!!locationList.length &&
        locationList?.map((entry) => {
          return (
            <StatsDisplay
              key={entry.code}
              value={entry.code.toString()}
              text={entry.name}
              onClose={onClearLocation}
            />
          );
        })}
      {statusFilter && (
        <StatsDisplay
          value={statusFilter}
          text={StatusFilterMap[statusFilter as keyof typeof StatusFilterMap]}
          onClose={handleClearStatusFilter}
        />
      )}
      <LinkButtonV2 className={styles['runpayroll-filter-stats-clear']} onClick={clearAll}>
        CLEAR ALL
      </LinkButtonV2>
    </div>
  );
};

export const StatsDisplay = ({
  header,
  value,
  text,
  onClose,
}: {
  header?: string;
  value: string;
  text: string;
  onClose: (value: string) => void;
}) => {
  const onClick = () => {
    onClose(value);
  };

  return (
    <div className={styles['runpayroll-filter-stats']}>
      {!!header && <span className={styles['runpayroll-filter-stats-header']}>{header}:</span>}
      <span title={value} className={styles['runpayroll-filter-stats-content']}>
        {text}
      </span>
      <img
        className={styles['runpayroll-filter-stats-action']}
        src={getStaticMediaUrl(closeIcon)}
        alt=""
        onClick={onClick}
      />
    </div>
  );
};

export default FilterStats;
