import {
  ActionList,
  ActionListItem,
  Box,
  DropdownOverlay,
  InfoIcon,
  SelectInput,
  TableCell,
  TableEditableDropdownCell,
  Text,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import { SalaryComponent } from 'components/SalaryComponents/types';
import React from 'react';
import { capitalizeAllWordsInString } from 'utils/Strings';

interface ComponentCellProps {
  name: string;
  info?: string;
  isComponentEditDisabled: boolean;
  options: SalaryComponent[];
  onComponentChange: (value?: string) => void;
}

const areEqual = (prevProps: ComponentCellProps, nextProps: ComponentCellProps) => {
  return (
    prevProps.name === nextProps.name &&
    prevProps.isComponentEditDisabled === nextProps.isComponentEditDisabled
  );
};

const ComponentCell = ({
  name,
  info,
  isComponentEditDisabled,
  options,
  onComponentChange,
}: ComponentCellProps) => {
  if (isComponentEditDisabled) {
    return (
      <TableCell>
        <Box display="flex" alignItems="center">
          <Text textAlign="center">{name} </Text>
          {info ? (
            <Tooltip content={info}>
              <TooltipInteractiveWrapper>
                <InfoIcon marginLeft="spacing.2" size="small" />
              </TooltipInteractiveWrapper>
            </Tooltip>
          ) : null}
        </Box>
      </TableCell>
    );
  }

  return (
    <TableEditableDropdownCell>
      <SelectInput
        label=""
        placeholder="Eg. Earned Leave"
        value={name}
        onChange={(e) => onComponentChange(e.values[0])}
      />
      <DropdownOverlay>
        <ActionList>
          {options.map((option) => (
            <ActionListItem
              key={option.id}
              title={option.name}
              description={capitalizeAllWordsInString(
                option.settings.behaviour.displayBehaviour?.displayName ?? '',
              )}
              value={option.name}
            />
          ))}
        </ActionList>
      </DropdownOverlay>
    </TableEditableDropdownCell>
  );
};

ComponentCell.displayName = 'ComponentCell';

export default React.memo(ComponentCell, areEqual);
