
import React, { ReactElement, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { OnboardingLayout, Sidebar } from 'components/ui/OnboardingLayout';
import { MainContent } from './components/MainContent';
import { BonusCard } from './components/Card';
import {
    Alert,
    Box,
    Heading,
    Link,
    Spinner,
    Text,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from '@razorpay/blade/components';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import api from '../../api/index';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { BonusStatus, BonusSource, DeleteModalProps, bonusStatus, bonusSource } from './types';
import { MONTHS_LIST } from 'utils/Dates';
import { ValidatePage, useShouldFetchUserData, canEdit, canDelete, getClawbackMonthStringFromDate } from './utils';
import { routePaths } from 'components/Routes/data';
import Errors from 'components/ui/Form/Errors';
import rupeeImage from '../../assets/advance-salary-request.png';
import { getStaticMediaUrl } from 'utils/Urls';
import permissions from 'constants/permissions';
import { toIndianCurrencyString } from 'utils/Numbers';
import { showToastViaEvent } from 'utils/ToastEvents';

export default function ViewBonus(): ReactElement {

    const permission = useSelector(loggedInUserSelectors.permissions);
    const loggedInUserName = useSelector(loggedInUserSelectors.name);
    const queryParam = new URLSearchParams(useLocation().search);
    const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>({ isOpen: false, bonusData: null });
    const [userName, setUserName] = useState<string | null>(loggedInUserName)
    const history = useHistory();
    const userId = queryParam.get('userId');
    const helpArticleLinkForCreateBonus = "https://intercom.help/XPayroll/en/articles/8320127-how-do-i-create-an-employee-bonus";

    let isPageValid = ValidatePage();
    let shouldFetchUserData = useShouldFetchUserData(userId);
    let bonusEditAndDeletePermission = permission.includes(permissions.BONUS_EDIT);

    if (!isPageValid) {
        history.push(routePaths.unknown);
    }

    const userDataQuery = useQuery('GET_USER_DATA', () => {

        if (userId) {
            return api.people.fetchUserData(parseInt(userId));
        }

    }, {
        onSuccess: (data) => {
            setUserName(data?.name ?? null);
        },
        onError: (error: typeof AppError) => {
            console.error("Error on fetching user's data : ", error);
        },
        enabled: shouldFetchUserData
    });

    const fetchBonusesQuery = useQuery('FETCH_BONUS_DATA', () => {

        if (userId) {
            return api.bonus.fetchBonuses({ people_id: parseInt(userId) });
        }
        else {
            return null;
        }

    }, {
        onError: (error: typeof AppError) => {
            console.error("Error on fetching bonus data : ", error);
        }
    });

    const deleteBonusMutation = useMutation(
        api.bonus.deleteBonus,
        {
            onSuccess: (data) => {

                if (data.data[0].id) {
                    fetchBonusesQuery.refetch();
                    showToastViaEvent({
                        text: `${data.data[0].bonus_name} deleted successfully!`,
                        timeout: 5000,
                        type: 'success',
                        icon: 'success',
                    });
                }
            },
            onError: (error: typeof AppError) => { },
        },
    );

    if (userDataQuery.isLoading) {
        return <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
        </Box>
    }

    return (<>
        <OnboardingLayout>

            <Sidebar
                title={userDataQuery.isLoading ? 'Bonuses For Employee' : `Bonus For ${userName}`}
                hideBackButton={false}
                backButtonTitle='BACK TO EMPLOYEE PROFILE'
                onBackClick={() => {
                    window.location.href = `/employeeDetail?userId=${userId}`;
                }}
            >
                {bonusEditAndDeletePermission ?
                    <Box display="flex" flexDirection="column" gap="spacing.6" marginTop={'spacing.3'}>
                        <Text size="large">Helpful Links</Text>
                        <Link size="medium" variant="anchor" href={helpArticleLinkForCreateBonus} target='_blank'>
                            How to create a bonus for an employee?
                        </Link>
                    </Box>
                    :
                    <></>

                }

            </Sidebar>

            <MainContent
                title={`Bonuses For ${userName}`}
                buttonOnClick={() => { history.push(`${routePaths.bonus.create}?userId=${userId}`); }}
                hideButton={!bonusEditAndDeletePermission}
                description={["Create and manage employee bonuses"]}
            >
                {
                    (deleteBonusMutation.isLoading || fetchBonusesQuery.isFetching) ?

                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            height={'100%'}
                            width={'100%'}>
                            <Spinner accessibilityLabel="loading" />
                        </Box>

                        :
                        <>
                            {(shouldFetchUserData && userDataQuery.isError) &&
                                <Alert title={getAppErrorDetails(userDataQuery.error).code} description={<Errors errorResponse={userDataQuery.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                            }
                            {fetchBonusesQuery.isError &&
                                <Alert title={getAppErrorDetails(fetchBonusesQuery.error).code} description={<Errors errorResponse={fetchBonusesQuery.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                            }
                            {deleteBonusMutation.isError &&
                                <Alert title={getAppErrorDetails(deleteBonusMutation.error).code} description={<Errors errorResponse={deleteBonusMutation.error} />} marginTop="spacing.4" isDismissible={true} color="negative" />
                            }
                            {
                                fetchBonusesQuery.data?.data && fetchBonusesQuery.data.data.length > 0 &&
                                fetchBonusesQuery.data?.data.map((bonus) => {
                                    let payoutMonthDate = bonus.payout_month ? (new Date(bonus.payout_month)) : undefined;
                                    return <BonusCard key={bonus.id?.toString() ?? Math.random()}
                                        title={bonus.bonus_name ?? ''}
                                        amount={bonus.amount ?? 0}
                                        payoutMonth={payoutMonthDate ? `${MONTHS_LIST[payoutMonthDate.getMonth()]}, ${payoutMonthDate.getFullYear()}` : undefined}
                                        status={bonus.status?.toString() as BonusStatus ?? undefined}
                                        source={bonus.source as BonusSource ?? undefined}
                                        clawbackRulesText={(bonus.clawback_applicable && bonus.clawback_month && bonus.clawback_period) ? `${getClawbackMonthStringFromDate(bonus.clawback_month)} (${bonus.clawback_period} ${bonus.clawback_period > 1 ? 'months' : 'month'})` : 'Not Applicable'}
                                        remark={bonus.remarks ?? undefined}
                                        canEdit={ canEdit(bonusEditAndDeletePermission, bonus) }
                                        canDelete={ canDelete(bonusEditAndDeletePermission, bonus) }
                                        onEditClick={() => {
                                            if (canEdit(bonusEditAndDeletePermission, bonus)) {
                                                history.push(`${routePaths.bonus.edit}?userId=${userId}&id=${bonus.id}`);
                                            }
                                        }
                                        }
                                        onDeleteClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteModalProps(({ isOpen: true, bonusData: bonus }));
                                        }}
                                    />
                                })
                            }
                            {
                                (!fetchBonusesQuery.data?.data || fetchBonusesQuery.data.data.length === 0) &&
                                <Box marginX={'auto'} marginTop={'spacing.8'}>
                                    <Box marginLeft={'40%'}>
                                        <img src={getStaticMediaUrl(rupeeImage)} alt="" />
                                    </Box>
                                    <Text marginTop="spacing.3" color="surface.text.gray.muted">
                                        No existing bonus found for this employee. Please create a bonus first.
                                    </Text>
                                </Box>
                            }
                        </>
                }
            </MainContent>
        </OnboardingLayout>
        <Modal
            onDismiss={() => setDeleteModalProps(((prev) => ({ ...prev, isOpen: false })))}
            size="medium"
            isOpen={deleteModalProps.isOpen}
        >
            <ModalHeader
                title={`Delete ${deleteModalProps.bonusData?.bonus_name}`}
            />
            <ModalBody>

                <Box
                    display="flex"
                    flexDirection="column"
                    gap="spacing.6"
                    justifyContent="left"
                    alignItems="left"
                    padding="spacing.6"
                >
                    <Box display="flex" flexDirection="row" gap={'spacing.2'}>
                        <Text
                            size='large'
                            weight='regular'
                            color="surface.text.gray.normal">Bonus with name </Text>
                        <Text
                            size='large'
                            weight="semibold"
                            color="surface.text.gray.normal">"{deleteModalProps.bonusData?.bonus_name}"</Text>
                        <Text
                            size='large'
                            weight='regular'
                            color="surface.text.gray.normal"> of Rs. </Text>
                        <Text
                            size='large'
                            weight="semibold"
                            color="surface.text.gray.normal">{deleteModalProps.bonusData?.amount ? toIndianCurrencyString(deleteModalProps.bonusData?.amount, true) : 'N/A'}</Text>
                    </Box>
                    <Box display="flex" flexDirection="row" gap={'spacing.2'}>
                        <Text
                            size='large'
                            weight='regular'
                            color="surface.text.gray.normal"> will be deleted and will be removed from </Text>
                        <Text
                            size='large'
                            weight="semibold"
                            color="surface.text.gray.normal">{userName}</Text>
                        <Text
                            size='large'
                            weight='regular'
                            color="surface.text.gray.normal">'s profile.</Text>
                    </Box>
                </Box>

            </ModalBody>
            <ModalFooter>
                <Box
                    display="flex"
                    gap="spacing.3"
                    justifyContent="flex-start"
                    marginLeft={'spacing.4'}
                    width="100%"
                >
                    <Button variant="tertiary" onClick={() => setDeleteModalProps(((prev) => ({ ...prev, isOpen: false })))}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={() => {
                        if (deleteModalProps.bonusData && deleteModalProps.bonusData.id) {
                            deleteBonusMutation.mutate(deleteModalProps.bonusData.id);
                            setDeleteModalProps(((prev) => ({ ...prev, isOpen: false })));
                        }
                    }
                    }>
                        Delete Now
                    </Button>
                </Box>
            </ModalFooter>
        </Modal>
    </>);
}