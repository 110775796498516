import React, { ReactNode } from 'react';
import { Box, Text, Heading, Button } from '@razorpay/blade/components';
import { SidebarTopImg } from 'components/Kyc/styles';
import topRightImg from '../../../assets/kyc/sidebar_top_right.svg';


interface MainContentProps {
    title: ReactNode;
    description?: string[];
    buttonOnClick?: () => void;
    hideButton?: boolean;
    children: JSX.Element[] | JSX.Element;
}

const MainContent = ({ title, description, buttonOnClick, hideButton, children }: MainContentProps) => {
    return (
        (<Box
            backgroundColor="surface.background.gray.intense"
            position="relative"
            overflowY="auto">
            <Box
                height="100vh"
                padding={['spacing.10', '96px']}
                paddingRight={'156px'}
                display="grid"
                gridTemplateRows="1fr auto">
                <Box display="flex" flexDirection="column" gap="spacing.10">
                    <Box display='flex' flexDirection={{base:'column', m:'row'}} justifyContent={'space-between'} gap={{m:'70px', base:'24px'}} alignItems={{m:'center', base:'flex-start'}}>
                        <Box display="flex" flexDirection="column" gap="spacing.4" width={'400px'}>
                            <Heading size={'large'}>{title}</Heading>
                            {description && description.map((text) => (
                                <Text marginTop="spacing.1" color="surface.text.gray.muted">
                                    {text}
                                </Text>
                            ))}
                        </Box>
                        {!hideButton && <Box>
                            <Button variant='primary' size='medium' onClick={buttonOnClick}>
                                Create a new bonus
                            </Button>
                        </Box>}
                    </Box>
                    <Box display="flex" flexDirection="column" gap="spacing.7">
                        {children}
                    </Box>
                </Box>
            </Box>
            <SidebarTopImg src={topRightImg} height={'270px'} />
        </Box>)
    );
};

export { MainContent };