import React, { useState, useEffect, ReactElement } from 'react';
import { useMutation } from 'react-query';
import MainCase from '../ui/MainCase/index';
import styles from './index.module.scss';
import { InputElement } from 'components/ui/Form/Input';
import LoanPreviewBlock from './components/LoanPreviewBlock';
import { LoanCalculatorResponseSchemaContract } from '../../schemas/LoanCalculatorResponseSchema';
import api from '../../api/index';
import { LoanCalculatorRequestSchemaContract } from '../../schemas/LoanCalculatorRequestSchema';
import { BasicUserLoanDetailsSchemaContract } from '../../schemas/BasicUserLoanDetailsSchema';
import { WarningAlert, WarningAlertYellow } from '../ui/Alert/index';
import Errors from '../ui/Form/Errors/index';
import { AppError } from 'utils/AppError';
import CreateLoanFormBlock from './components/CreateLoanFormBlock';
import { CreateLoanRequestSchemaContract } from '../../schemas/CreateLoanRequestSchema';
import { routePaths } from '../Routes/data';
import Breadcrumb from 'components/ui/Breadcrumb';
import TypeAhead, { TypeItem } from '../ui/TypeAhead/index';
import useReduxSelector from '../../utils/useReduxSelector';
import { CreateLoanFormData, getInitialData } from './props';
import PageWrapper from 'components/ui/PageWrapper';
import { getStaticMediaUrl } from '../../utils/Urls';
import addUserIcon from 'assets/icon--add--user.svg';

export interface CreateLoanFormProps {
    peopleId: string,
    createLoanFormData: CreateLoanFormData,
    loanPreview: LoanCalculatorResponseSchemaContract | null,
    basicUserLoanDetails: BasicUserLoanDetailsSchemaContract | null,
    updateCreateLoanFormState: (propName: string, value: any) => void,
    setLoanPreview: (loanPreview: LoanCalculatorResponseSchemaContract | null) => void,
    setBasicUserLoanDetails: (basicUserLoanDetails: BasicUserLoanDetailsSchemaContract | null) => void,
    mutateLoanData: () => void,
    error?: typeof AppError,
    setError: (error?: typeof AppError) => void
}


export default function CreateLoan(): ReactElement {

    const [typeAheadResponse, setTypeAheadResponse] = useState<TypeItem | null>(null);
    const [peopleId, setPeopleId] = useState('');
    const [createLoanFormData, setCreateLoanFormData] = useState<CreateLoanFormData>(() => getInitialData());
    const [loanPreview, setLoanPreview] = useState<LoanCalculatorResponseSchemaContract | null>(null);
    const [basicUserLoanDetails, setBasicUserLoanDetails] = useState<BasicUserLoanDetailsSchemaContract | null>(null);
    const [error, setError] = useState<typeof AppError>();
    const loggedInUser = useReduxSelector((state) => state.loggedInUser);


    const updateCreateLoanFormState = (propName: string, value: any) => {
        setCreateLoanFormData((prevData) => ({
            ...prevData,
            [propName]: value,
        }));
    };



    useEffect(() => {
        getBasicUserLoanDetails(parseInt(peopleId));
    }, [peopleId])

    const updateLoanPreview = useMutation(
        (requestData: LoanCalculatorRequestSchemaContract) =>
            api.loans.calculateLoan(requestData),
        {
            onSuccess: (data: LoanCalculatorResponseSchemaContract) => {
                setLoanPreview(data);
            },
            onError: (error: typeof AppError) => {
                setLoanPreview(null);
            },
        },
    );

    const getBasicUserLoanDetails = (peopleId: number) => {
        if (peopleId) {
            api.loans.basicUserLoanData(peopleId)
                .then((data: BasicUserLoanDetailsSchemaContract) => {
                    setBasicUserLoanDetails(data);
                    setLoanPreview(null);
                    error && setError(undefined);
                    if (data.last_payroll_month) {
                        updateCreateLoanFormState('emiStartFrom', data.last_payroll_month);
                    }
                })
                .catch((error: typeof AppError) => {
                    setBasicUserLoanDetails(null);
                    setError(error);
                    document.querySelector('#react')?.scrollTo(0, 0);
                });
        }
    }


    const searchFn = (search: string) => {
        return new Promise<{ name: string; label: string }[]>((resolve, reject) => {
            if (loggedInUser.data?.currentOrganization?.id) {
                api.people
                    .search({ orgId: loggedInUser.data?.currentOrganization?.id, search, onlyActive: true, type: 'employee' })
                    .then((data) => {
                        const result = data.map((item) => {
                            return {
                                name: item.key.toString(),
                                label: item.label,
                            };
                        });

                        resolve(result);
                    })
                    .catch(() => {
                        reject('No results found');
                    });
            } else {
                resolve([]);
            }
        });
    }

    const mutateLoanData = () => {
        if (createLoanFormData.principal &&
            createLoanFormData.rateOfInterest &&
            createLoanFormData.tenure &&
            createLoanFormData.interestType &&
            createLoanFormData.perquisites) {
            updateLoanPreview.mutate(
                {
                    principal: parseInt(createLoanFormData.principal.replaceAll(',', '')),
                    interest_rate: parseFloat(createLoanFormData.rateOfInterest),
                    tenure: parseInt(createLoanFormData.tenure),
                    interest_type: createLoanFormData.interestType,
                    perk_rate: parseInt(createLoanFormData.perquisites)
                })
        }
        else if (
            !createLoanFormData.principal ||
            !createLoanFormData.rateOfInterest ||
            !createLoanFormData.tenure ||
            !createLoanFormData.interestType ||
            !createLoanFormData.perquisites) {
            setLoanPreview(null);
        }

    }

    return (
        <PageWrapper>

            <Breadcrumb
                name="Create Loan"
                urlMaps={{
                    Loans: routePaths.loans.viewLoans,
                }}
            />
            <MainCase>
                <div className={styles['title']}>
                    Create Loan
                </div>
                <span className={styles['title-description']}>Create and disburse employee loans in seconds</span> ⚡️
                
                <div className={styles['form']}>

                    <div className={styles['form-section-header']}>
                        <img src={getStaticMediaUrl(addUserIcon)} alt="" className={styles['icon']} />

                        <div className={styles['sub-title']}>
                            {`Basic Loan Details`}
                        </div>
                    </div>

                    <InputElement required label={'Search by Employee Name'} className={` mt-8 mb-10 ${styles['label']}`}>
                        <div className='mt-4'>
                            <TypeAhead
                                placeholder='Search for Employees'
                                searchFunction={searchFn}
                                value={typeAheadResponse}
                                onChange={(value) => { setTypeAheadResponse(value); setPeopleId(value?.name ? value?.name : '') }}
                            />
                        </div>
                    </InputElement>

                    {error &&
                        <WarningAlert>
                            <Errors errorResponse={error} />
                        </WarningAlert>}

                    {basicUserLoanDetails && basicUserLoanDetails.existing_loan_count > 0 &&
                        <WarningAlertYellow>
                            {`This user already has ${basicUserLoanDetails.existing_loan_count} loan(s) active`}
                        </WarningAlertYellow>}

                    <CreateLoanFormBlock
                        peopleId={peopleId}
                        createLoanFormData={createLoanFormData}
                        loanPreview={loanPreview}
                        basicUserLoanDetails={basicUserLoanDetails}
                        updateCreateLoanFormState={updateCreateLoanFormState}
                        setLoanPreview={setLoanPreview}
                        setBasicUserLoanDetails={setBasicUserLoanDetails}
                        mutateLoanData={mutateLoanData}
                        error={error}
                        setError={setError}
                    />

                </div>

            </MainCase>
            <LoanPreviewBlock loanPreview={loanPreview} isLoading={updateLoanPreview.isLoading} />
        </PageWrapper>
    );
}