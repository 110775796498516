import React from "react";
import styles from "./index.module.scss";
import {getStaticMediaUrl} from "../../../../utils/Urls";
import jibbleSignature from "../../../../assets/jibble/signature--jibble.svg";
import {LinkButtonV2} from "../../../ui/Button";
import linkArrow from "../../../../assets/link-arrow.svg";
import {routePaths} from "../../../Routes/data";

const ImportJibbleAttendancePrompt = ({reportMonth}: {reportMonth: string | null}) => {
    let queryParams = '';
    queryParams += reportMonth ? '?report_month=' + reportMonth : '';
    return (
        <div className={styles['promptContainer']}>
            <div className={styles['prompt']}>
                <div className="flex items-center">
                    <span className="text-1.29xl italic font-bold ">Attendance managed by</span>
                    <img className="h-4 ml-3" src={getStaticMediaUrl(jibbleSignature)} alt={"Jibble"}/>
                </div>
                <p className={styles['promptText']}>Fetch employees’ loss of pay information from Jibble</p>
                <a className="flex items-center text-1.5xl" href={routePaths.settings.jibble.attendanceReport + queryParams}>
                    <LinkButtonV2 className={styles['actionButton']}>Get loss of pay report now</LinkButtonV2>
                    <img src={getStaticMediaUrl(linkArrow)} alt="" className="pl-4 h-5" />
                </a>
            </div>
        </div>
    )
}

export default ImportJibbleAttendancePrompt;