import React, { ReactElement } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import greenTick from '../../../assets/icon--green--circle--tick.svg';
import Note from 'components/ui/Note';
import styles from '../index.module.scss';
import { RecoveryFormProps } from '../LoanRecovery';
import { toIndianCurrencyString } from '../../../utils/Numbers';
import LoanRecoveryPreviewTable from './LoanRecoveryPreviewTable';
import { LoanRecoveryOption } from '../constants';


export default function LoanRecoveryConfirmFormBlock(
    {
        formProps,
        updateFormProps,
        fullRecoveryAmount,
        loanId
    }:
        {
            formProps: RecoveryFormProps;
            updateFormProps: (field: string, type: LoanRecoveryOption | string) => void;
            fullRecoveryAmount?: number | null;
            loanId: number;
        }
): ReactElement {
    const value = (formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY && fullRecoveryAmount) ?
        fullRecoveryAmount : parseInt(formProps.recoveryAmount);

    return (
        <>
            <div className={`${styles['breadcrumb-style-header']}`}>
                <div className={styles['icon']}><img src={getStaticMediaUrl(greenTick)} /></div>
                <div className={styles['loan-amount']}>
                    {
                        formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ? 'Full Outstanding Amount: ' : 'Amount Being Recovered: '
                    }<strong>{
                        toIndianCurrencyString(value)
                    }</strong></div>
            </div>
            <div>
                <span>Mode of Loan Recovery: <strong>External</strong></span>
                <Note className="mt-3">
                    The amount needs to be recovered externally and the loan status will be updated accordingly.
                </Note>
            </div>
            {formProps.recoveryType === LoanRecoveryOption.PARTIAL_RECOVERY && loanId &&
                <>
                    <span className={styles['preview-table-title']}>Loan Preview</span>
                    <LoanRecoveryPreviewTable loanId={loanId} requestData={{ action: LoanRecoveryOption.PARTIAL_RECOVERY, amount: parseInt(formProps.recoveryAmount) }} />
                </>
            }
        </>
    );
}