import {
  ArrowRightIcon,
  Box,
  Button,
  Counter,
  Divider,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  Text,
  Tooltip,
  UserCheckIcon,
} from '@razorpay/blade/components';
import React, { useState } from 'react';

export type FieldsToSync = {
  field: string;
  name: string;
  isMandatory?: boolean;
  isSyncEnabled?: boolean;
};

export interface FieldsSyncCardProps {
  title: string;
  description: string;
  fields: FieldsToSync[];
  onFieldSwitch: (isChecked: boolean, fieldToSync: FieldsToSync) => void;
}

const FieldsSyncCard = ({ title, description, fields, onFieldSwitch }: FieldsSyncCardProps) => {
  const [isFieldsModalOpen, setIsFieldsModalOpen] = useState(false);

  const selectedFieldsCount = fields.reduce(
    (total, { isSyncEnabled }) => (isSyncEnabled ? total + 1 : total),
    0,
  );

  const openFieldsModal = () => {
    setIsFieldsModalOpen(true);
  };

  const closeFieldsModal = () => {
    setIsFieldsModalOpen(false);
  };

  const handleOnFieldSwitchStateChange = ({
    isChecked,
    value,
  }: {
    isChecked: boolean;
    event?: React.ChangeEvent;
    value?: string;
  }) => {
    onFieldSwitch(isChecked, fields.find(({ field }) => field === value) as FieldsToSync);
  };

  const fieldsToSyncSummary =
    selectedFieldsCount > 0 ? (
      <Text variant="caption" color="interactive.text.positive.subtle" size='small'>
        {selectedFieldsCount}/{fields.length} {title} will sync
      </Text>
    ) : (
      <Text variant="caption" color="interactive.text.staticWhite.normal" size='small'>
        {title} will not sync
      </Text>
    );

  return (
    (<Box
      display="flex"
      flexDirection="column"
      borderRadius="medium"
      borderWidth="thin"
      borderColor="surface.border.gray.normal">
      <Box padding="spacing.7">
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap="spacing.4">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="spacing.9"
                height="spacing.9"
                backgroundColor="surface.background.gray.moderate"
                borderRadius="medium">
                <UserCheckIcon size="large" color="feedback.icon.information.intense" />
              </Box>
              <Heading as="h3" size="large">{title}</Heading>
            </Box>
          </Box>
          <Text color="surface.text.gray.muted">{description}</Text>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" padding="spacing.7">
        {fieldsToSyncSummary}
        <Link variant="button" icon={ArrowRightIcon} iconPosition="right" onClick={openFieldsModal}>
          Manage data to be synced
        </Link>
      </Box>
      <Modal isOpen={isFieldsModalOpen} onDismiss={closeFieldsModal} size="medium">
        <ModalHeader title={title} titleSuffix={<Counter value={fields.length} />} />
        <ModalBody>
          {fields.map(({ field, name, isMandatory, isSyncEnabled }, index) => (
            <Box display="flex" flexDirection="column" key={index}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={['spacing.4', '0px']}>
                <Text
                  color={isMandatory ? 'surface.text.gray.disabled' : 'surface.text.gray.muted'}>{name}</Text>
                {isMandatory ? (
                  <Tooltip
                    content={`${name} field is required to be synced with RazorpayX-Payroll`}
                    placement="right">
                    <Box>
                      <Switch
                        accessibilityLabel=""
                        value={field}
                        onChange={handleOnFieldSwitchStateChange}
                        isChecked={true}
                        isDisabled={true}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Switch
                    accessibilityLabel=""
                    value={field}
                    onChange={handleOnFieldSwitchStateChange}
                    isChecked={isSyncEnabled}
                  />
                )}
              </Box>
              {index < fields.length - 1 && <Divider />}
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" justifyContent="flex-end" gap="spacing.5">
            <Button onClick={closeFieldsModal}>Confirm & Save</Button>
          </Box>
        </ModalFooter>
      </Modal>
    </Box>)
  );
};

export { FieldsSyncCard };
