import React from 'react';
import { ZaggleIntroSection } from './ZaggleIntroSection';
import { TickList, TickListItem } from '../../../ui/TickList';

export function ZaggleBenefitsSection() {
  return <ZaggleIntroSection heading={'Benefits 🚀 '}>
    <TickList>
      <TickListItem>
        Instant tax exemption on the declared amount.
      </TickListItem>
      <TickListItem>
        Get & use lifetime free Zaggle Card at any offline or online store. Learn more.
      </TickListItem>
      <TickListItem>
        Access to Zaggle app to track all expenses.
      </TickListItem>
    </TickList>
  </ZaggleIntroSection>;
}