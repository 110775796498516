import React from 'react';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import { DeliveryType } from '../../../../constants/flexibenefits';
import { getCity, getStateCities } from '../../../../utils/Address';
import { states } from '../../../../constants/address';
import { ZaggleCardDeliveryAddressReadonly } from './ZaggleCardDeliveryAddressReadonly';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';

export function ZaggleCardDeliveryAddress({
                                            employeeFlexiBenefitsDetails,
                                            detailsToSync,
                                            setDetailsToSync,
                                            setErrorMessage,
                                          }: { employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract, detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract, setDetailsToSync: (detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract) => void, setErrorMessage: (message: string) => void }) {
  if (employeeFlexiBenefitsDetails.deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION || employeeFlexiBenefitsDetails.isCardDetailsSent) {
    return <ZaggleCardDeliveryAddressReadonly 
      employeeFlexiBenefitsDetails={employeeFlexiBenefitsDetails}
      />;
  }
  const updateCardDeliveryField = (cardDeliveryDetails: UpdateUserOrgFlexiPartnerDetailsSchemaContract['cardDeliveryDetails']) => {
    setErrorMessage('');
    setDetailsToSync({ ...detailsToSync, cardDeliveryDetails });
  };
  return <div className={'space-y-5'}>
    <div className={'text-5xl text-white'}>Zaggle card delivery address</div>
    <div>Confirm your current address where you want your Zaggle card to be delivered.</div>
    <form>
      <label htmlFor='address'>Address*
        <input type='text' name='address'
               defaultValue={detailsToSync.cardDeliveryDetails.address ?? ''}
               aria-describedby='address'
               onChange={(event) => {
                 updateCardDeliveryField({
                   ...detailsToSync.cardDeliveryDetails,
                   address: event.currentTarget.value,
                 });
               }}
               placeholder='Address' />
      </label>
      <label htmlFor='pinCode'>Pin code*
        <input type='text' name='pinCode'
               defaultValue={detailsToSync.cardDeliveryDetails.pinCode ?? ''}
               aria-describedby='pinCode'
               onChange={(event) => {
                 updateCardDeliveryField({
                   ...detailsToSync.cardDeliveryDetails,
                   pinCode: event.currentTarget.value,
                 });
               }}
               placeholder='Pin code' />
      </label>

      <label htmlFor='state'>State*
        <select
          name='state'
          aria-describedby='state'
          placeholder='State'
          required={true}
          defaultValue={detailsToSync.cardDeliveryDetails.state ?? ''}
          onChange={(event) => {
            updateCardDeliveryField({
              ...detailsToSync.cardDeliveryDetails,
              state: event.currentTarget.value,
              city: getCity(event.currentTarget.value, detailsToSync.cardDeliveryDetails.city),
            });
          }}>
          <option value=''>State</option>
          {states.map((state) =>
            (<option value={state.name}>
              {state.name}
            </option>),
          )}
        </select>
      </label>

      <label htmlFor='city'>City*
        <select
          name='city'
          aria-describedby='city'
          placeholder='City'
          required={true}
          defaultValue={getCity(detailsToSync.cardDeliveryDetails.state, detailsToSync.cardDeliveryDetails.city)}
          onChange={(event) => {
            updateCardDeliveryField({
              ...detailsToSync.cardDeliveryDetails,
              city: getCity(detailsToSync.cardDeliveryDetails.state, event.currentTarget.value),
            });
          }}>
          <option value=''>City</option>
          {getStateCities(detailsToSync.cardDeliveryDetails.state).map((city) =>
            (<option value={city} selected={city === detailsToSync.cardDeliveryDetails.city}>
              {city}
            </option>),
          )}
        </select>
      </label>
    </form>
  </div>;
}
