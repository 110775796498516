import React from 'react';

import { WarningAlertYellow } from 'components/ui/Alert';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import pluralize from 'pluralize';
import { routePaths } from 'components/Routes/data';
import { useHistory } from 'react-router-dom';
import { LinkButtonV2 } from 'components/ui/Button';
import useApprovalLink from '../utils/useApprovalLink';
import Spinner from 'components/ui/Spinner';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';

import styles from './index.module.scss';

const PendingDisplay = () => {
  const data = useRunPayrollDataContext();
  const history = useHistory();

  const approvalLinks = useApprovalLink();

  if (!data) {
    return null;
  }

  const { 
    numPendingEditRequest, 
    isChangeRequestPendingOnFinalize,
    numPendingSalaryRevisionRequests 
  } = data;

  const onRedirect = (to: string) => {
    history.push(to);
  }

  const getApprovalLink = (type: number) => {
    if(approvalLinks.isLoading) {
        return <Spinner size={14} />
    }

    if(!approvalLinks.links[type]) {
        return null;
    }

    return (
        <LinkButtonV2 onClick={() => onRedirect(approvalLinks.links[type])} className={styles['approval-link']}>
            Go to Approvals
        </LinkButtonV2>
    )
  }

  return (
    <>
      {numPendingEditRequest === 0 && !isChangeRequestPendingOnFinalize && numPendingSalaryRevisionRequests > 0 &&
      <WarningAlertYellow className='mb-10'>
      <p>
        <strong>{numPendingSalaryRevisionRequests} {pluralize('request', numPendingSalaryRevisionRequests)}</strong> pending for salary revision
      </p>
      {getApprovalLink(WorkflowTypesMap.SALARY_REVISION)}
    </WarningAlertYellow>
      }
      {numPendingEditRequest > 0 ? (
          <WarningAlertYellow className='mb-10'>
            <p>
              <strong>{numPendingEditRequest} {pluralize('request', numPendingEditRequest)}</strong> pending before finalizing payroll
            </p>
            {numPendingSalaryRevisionRequests > 0 && <p>
              and <strong>{numPendingSalaryRevisionRequests} {pluralize('request',numPendingSalaryRevisionRequests)}</strong> pending for salary revision
            </p>
            }
            {getApprovalLink(WorkflowTypesMap.EDIT_PAYROLL)}
          </WarningAlertYellow>
        ) : (isChangeRequestPendingOnFinalize) ? (
          <WarningAlertYellow className='mb-10'>
            <p>
              <strong>Approval pending</strong> to finalize payroll for this month
            </p>
            {numPendingSalaryRevisionRequests > 0 && <p>
              and <strong>{numPendingSalaryRevisionRequests} {pluralize('request',numPendingSalaryRevisionRequests)}</strong> pending for salary revision
            </p>
            }
            {getApprovalLink(WorkflowTypesMap.FINALIZE_PAYROLL)}
          </WarningAlertYellow>
        ) : null
      }
    </>
  );
};

export default PendingDisplay;
