import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { compliancePaymentModalsZIndex } from 'components/RunPayroll/ComplianceActions/components/data';
import { PaymentType } from 'components/RunPayroll/ComplianceActions/components/types';
import { OtpScreen } from 'components/RunPayroll/ComplianceActions/components/TwoFaModal/OtpScreen';
import { SuccessScreen } from 'components/RunPayroll/ComplianceActions/components/TwoFaModal/SuccessScreen';
import { ResetLocalOverridingStyles } from 'components/RunPayroll/ComplianceActions/styles';

export const TwoFaModal = ({
  isOpen,
  onDismiss,
  complianceId,
  paymentType,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  complianceId?: string | number;
  paymentType: PaymentType;
}) => {
  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);
  const timerRef: React.MutableRefObject<null | NodeJS.Timeout> = useRef(null);

  const clearStates = () => {
    setOtp('');
    setError('');
    setShowSuccessScreen(false);
  };

  const handleDismiss = () => {
    onDismiss();
    //added this as blade modal has animation which takes time to hide modal
    setTimeout(() => {
      clearStates();
    }, 300);
  };

  useEffect(() => {
    if (showSuccessScreen) {
      timerRef.current = setTimeout(() => {
        handleDismiss();
      }, 5000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [showSuccessScreen]);

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} zIndex={compliancePaymentModalsZIndex}>
      <ModalHeader />
      <ModalBody padding="spacing.0">
        <ResetLocalOverridingStyles>
          {showSuccessScreen ? (
            <SuccessScreen paymentType={paymentType} />
          ) : (
            <OtpScreen
              otp={otp}
              setOtp={setOtp}
              error={error}
              setError={setError}
              setShowSuccessScreen={setShowSuccessScreen}
              complianceId={complianceId}
              paymentType={paymentType}
            />
          )}
        </ResetLocalOverridingStyles>
      </ModalBody>

      {showSuccessScreen ? (
        <ModalFooter>
          <ResetLocalOverridingStyles>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Text size="small" color="surface.text.gray.muted">
                Window will automatically close in 5s
              </Text>
              <Box>
                <Button onClick={handleDismiss}>Okay, Got it</Button>
              </Box>
            </Box>
          </ResetLocalOverridingStyles>
        </ModalFooter>
      ) : null}
    </Modal>
  );
};
