/**
 *
 * @param step
 * @returns Chat suggestions for the given step
 */
export const getChatSuggestionByStep = (step: number) => {
  switch (step) {
    case 1:
      return ['Yes, continue to save tax', 'Show calculation'];
    case 2:
      return ['Show options in other regime', 'Show calculation (suggested)'];
    default:
      return [];
  }
};

/**
 *
 * @param text
 * @returns Give stream duration based on the text size
 */
export const getTextStreamDuration = (text: string) => {
  const textSize = text.split(' ').length;
  const streamDuration = (textSize * 50) / 1000;

  return streamDuration;
};

export const getProcessingAnimationMessages = (step: number) => {
  switch (step) {
    case 1:
      return [
        'Analyzing salary components..',
        'Evaluating deductions..',
        'Evaluating regimes..',
        'Finding the best tax regime..',
        'Complying with Indian tax laws..',
        'Almost there..',
      ];
    case 2:
    default:
      return [
        'Assessing salary in your demographics..',
        'Evaluating declarations..',
        'Comparing industry benchmarks..',
        'Finding best tax strategies..',
        'Complying with Indian tax laws..',
        'Almost there..',
      ];
  }
};
