import {
  Amount,
  Box,
  Button,
  Card,
  CardBody,
  DotIcon,
  MinusCircleIcon,
  SparklesIcon,
  Text,
  UserIcon,
} from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import { DonutChart } from 'components/ui/DonutChart';
import React from 'react';
import { usePayDetails } from '../context/PayDetailsContext';
import { SummaryCard } from './SummaryCard';

export const SummarySection = () => {
  const { compensationData } = usePayDetails();
  const summaryData = compensationData.summaryCardData;

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
      <Card padding="spacing.7" borderRadius="large">
        <CardBody>
          <Box
            display={'flex'}
            flexDirection={{ base: 'column', m: 'row' }}
            justifyContent={'space-between'}
            gap={'48px'}>
            <Box
              alignItems={'center'}
              width={'100px'}
              height={'170px'}
              marginLeft={{ base: '60px', m: 'spacing.8' }}>
              <DonutChart
                data={[
                  {
                    percentage: summaryData.fixedPercentage,
                    color: bladeTheme.colors.onDark.interactive.icon.positive.subtle,
                  }, // Green - Fixed component
                  {
                    percentage: summaryData.variablePercentage,
                    color: bladeTheme.colors.onDark.interactive.icon.information.normal,
                  }, // Blue - Variable component
                  {
                    percentage: summaryData.otherCtcPercentage,
                    color: bladeTheme.colors.onDark.interactive.icon.notice.normal,
                  }, // Orange - Other CTC component
                ]}
                size={170}
                strokeWidth={20}
              />
            </Box>
            <Box
              minWidth={{ base: 'auto', m: '458px' }}
              display={'flex'}
              flexDirection={'column'}
              gap={'spacing.7'}>
              <Box
                display={'flex'}
                flexDirection={{ base: 'column', m: 'row', xs: 'initial' }}
                gap={{ base: 'spacing.4' }}
                justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Text weight="medium" color={'interactive.text.gray.muted'}>
                    Annual CTC
                  </Text>
                  <Amount
                    type={'heading'}
                    size="2xlarge"
                    weight="semibold"
                    value={summaryData.annualCtc}
                  />
                </Box>
                <Box width={'118px'}>
                  <Button variant={'primary'} isFullWidth>
                    Revise CTC
                  </Button>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={'space-between'}
                gap={'spacing.3'}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.positive.subtle'} />
                    <Text color={'surface.text.gray.muted'}>Fixed</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    value={summaryData.totalFixedValue}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.information.normal'} />
                    <Text color={'surface.text.gray.muted'}>Variable</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    value={summaryData.totalVariableValue}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.notice.normal'} />
                    <Text color={'surface.text.gray.muted'}>Other CTC component</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    value={summaryData.totalOtherCtcValue}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardBody>
      </Card>
      <Box display="flex" flexDirection={{ base: 'column', m: 'row' }} gap={'spacing.5'}>
        <SummaryCard
          icon={<MinusCircleIcon size="medium" />}
          title="Recurring deduction"
          amount={summaryData.totalRecurringDeductionAmount}
        />
        <SummaryCard icon={<SparklesIcon size="medium" />} title="Perquisites" amount={1065000} />
        <SummaryCard icon={<UserIcon size="medium" />} title="FBP allocated" amount={3000} />
      </Box>
    </Box>
  );
};
