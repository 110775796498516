import React from 'react';
import { IInsurance } from '../../../../reducers/insurance';
import { IInsuranceEmployee } from '../../../../reducers/insuranceEmployee';
import EmployeeInsurancePostPurchase from '../EmployeeInsurancePostPurchase';
import { GetOrgEmployeeForInsuranceSchemaContract } from '../../../../schemas/GetOrgEmployeeForInsuranceSchema';

function Message({
  insuranceEmployee,
  insurance,
}: {
  insuranceEmployee: IInsuranceEmployee;
  insurance: IInsurance;
}) {
  if (!insuranceEmployee.data) {
    return <></>;
  }
  switch (insuranceEmployee.data.status) {
    case 'PURCHASE_PENDING':
      const spouses = insuranceEmployee.data.dependents.filter((dependent) =>
        ['spouse'].includes(dependent.relation),
      );
      const children = insuranceEmployee.data.dependents.filter((dependent) =>
        ['son', 'daughter'].includes(dependent.relation),
      );
      const { father, fatherInLaw, mother, motherInLaw } = insuranceEmployee.data.dependents.reduce(
        (hash, dependent) => {
          hash[dependent.relation] = dependent;
          return hash;
        },
        {} as Record<
          GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]['relation'],
          GetOrgEmployeeForInsuranceSchemaContract['dependents'][0]
        >,
      );
      return (
        <div>
          <div className="flex justify-center w-full">
            <div className="pt-8 bg-rhino mt-8 w-3/4">
              <div className="text-5xl font-bold px-8 pb-2">Insurance Details</div>
              <div className="text-3xl mt-4 px-8 pb-8">
                Thank you for submitting your details. Once your organization completes the
                purchase, you will be notified with the details.
                <br />
                Below is the information that you have submitted. If you want to make changes to
                this, please contact your administrator.
                <br />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center w-full mt-20">
            <table className="w-3/4">
              <tr>
                <th>Full name</th>
                <th>Date of birth</th>
                <th>Gender</th>
                <th>Hire date</th>
                <th>Phone</th>
              </tr>
              <tr>
                <td>{insuranceEmployee.data?.name}</td>
                <td>{insuranceEmployee.data?.dob}</td>
                <td>{insuranceEmployee.data?.gender === 'm' ? 'Male' : 'Female'}</td>
                <td>{insuranceEmployee.data?.hireDate}</td>
                <td>{insuranceEmployee.data?.phoneNumber}</td>
              </tr>
            </table>
          </div>
          {(spouses && spouses.length > 0) || (children && children.length > 0) ? (
            <div>
              <div className="text-center text-3xl pt-16 font-bold">
                Family <br />
                <br />
              </div>
              <div className="flex flex-wrap justify-center w-full">
                <table className="w-3/4">
                  <tr>
                    <th className="w-2/5">Full name</th>
                    <th>Relation</th>
                    <th>Date of birth</th>
                    <th>Gender</th>
                  </tr>
                  {spouses.map((spouse, index) => {
                    return (
                      <tr>
                        <td>{spouse.name}</td>
                        <td>Spouse</td>
                        <td>{new Date(spouse.dateOfBirth)?.toISOString().split('T')[0]}</td>
                        <td>{spouse.gender.charAt(0).toUpperCase() + spouse.gender.slice(1)}</td>
                      </tr>
                    );
                  })}
                  {children.map((child, index) => {
                    return (
                      <tr>
                        <td>{child.name}</td>
                        <td>Child</td>
                        <td>{new Date(child.dateOfBirth)?.toISOString().split('T')[0]}</td>
                        <td>{child.gender.charAt(0).toUpperCase() + child.gender.slice(1)}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          ) : null}
          {father || mother || fatherInLaw || motherInLaw ? (
            <div>
              <div className="text-center text-3xl pt-16 font-bold">
                Dependents <br />
                <br />
              </div>
              <div className="flex flex-wrap justify-center w-full">
                <table className="w-3/4">
                  <tr>
                    <th className="w-2/5">Full name</th>
                    <th>Relation</th>
                    <th>Date of birth</th>
                    <th>Gender</th>
                  </tr>
                  {father && (
                    <tr>
                      <td>{father.name}</td>
                      <td>Father</td>
                      <td>{new Date(father.dateOfBirth)?.toISOString().split('T')[0]}</td>
                      <td>{father.gender.charAt(0).toUpperCase() + father.gender.slice(1)}</td>
                    </tr>
                  )}
                  {mother && (
                    <tr>
                      <td>{mother.name}</td>
                      <td>Mother</td>
                      <td>{new Date(mother.dateOfBirth)?.toISOString().split('T')[0]}</td>
                      <td>{mother.gender.charAt(0).toUpperCase() + mother.gender.slice(1)}</td>
                    </tr>
                  )}
                  {fatherInLaw && (
                    <tr>
                      <td>{fatherInLaw.name}</td>
                      <td>Father in law</td>
                      <td>{new Date(fatherInLaw.dateOfBirth)?.toISOString().split('T')[0]}</td>
                      <td>
                        {fatherInLaw.gender.charAt(0).toUpperCase() + fatherInLaw.gender.slice(1)}
                      </td>
                    </tr>
                  )}
                  {motherInLaw && (
                    <tr>
                      <td>{motherInLaw.name}</td>
                      <td>Mother in law</td>
                      <td>{new Date(motherInLaw.dateOfBirth)?.toISOString().split('T')[0]}</td>
                      <td>
                        {motherInLaw.gender.charAt(0).toUpperCase() + motherInLaw.gender.slice(1)}
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          ) : null}
        </div>
      );
    case 'PURCHASE_IN_PROGRESS':
    // return <div className="text-center text-3xl pt-16 font-bold"> Your insurance purchase is in progress, please wait for some days to get health IDs and other details.</div>;
    case 'PURCHASED':
      return (
        <EmployeeInsurancePostPurchase
          insurance={insurance}
          insuranceEmployee={insuranceEmployee}
        />
      );
  }
  return (
    <div className="text-center text-3xl pt-16 font-bold">
      {' '}
      Looks like there is some issue. Please refresh the page or contact administrator
    </div>
  );
}

export function EmployeeInsuranceDetails({
  insuranceEmployee,
  insurance,
}: {
  insuranceEmployee: IInsuranceEmployee;
  insurance: IInsurance;
}) {
  if (!insuranceEmployee.data) {
    return <></>;
  }
  return (
    <div className="flex-col flex justify-center h-full">
      <Message insuranceEmployee={insuranceEmployee} insurance={insurance} />
    </div>
  );
}
