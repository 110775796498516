import React, { useState } from 'react';
import { Box, Button, Heading } from '@razorpay/blade/components';
import { QuestionCard } from './components/QuestionCard';
import { useKnitIntegration } from 'components/Knit/useKnitIntegration';
import { KnitCategories } from 'components/Knit/constants';
import { Redirect, useHistory } from 'react-router-dom';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { showApiErrorNotification } from 'utils/Api';
import { useGetKnitHrmsIntegrationQuery } from 'components/Knit/KnitHrms/useGetKnitHrmsIntegrationQuery';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from 'components/Knit/KnitHrms/components/IntegrationWindow';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { hrmsAppIntegrationQuestions } from './constants';

const Integration = () => {
  const history = useHistory();
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const questionCardsContent = hrmsAppIntegrationQuestions[knitHrmsApp];
  const [answerSelections, setAnswerSelections] = useState<(number | undefined)[]>(
    questionCardsContent.map((_) => undefined),
  );
  const { knitRef, authSessionTokenQuery, knitScriptAsyncLoader } = useKnitIntegration({
    appId: knitHrmsApp,
    categoryId: KnitCategories.hris,
    onIntegrationSuccess: async (data) => {
      if (data.success) {
        await invalidateKnitHrmsIntegrationQueryCache();

        history.push(hrmsAppRoutePaths.settings.root, {
          totalSteps: 6,
          currentStep: 1,
        });
      }
    },
    onIntegrationFailure: (error) => {
      showApiErrorNotification(error);
    },
  });

  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    retryLimitExhausted,
    invalidateKnitHrmsIntegrationQueryCache,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const { integrationProgress } = useIntegrationProgress();

  if (knitHrmsIntegrationQuery.isSuccess && isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  const isStartIntegrationDisabled =
    authSessionTokenQuery.isFetching ||
    authSessionTokenQuery.isError ||
    knitScriptAsyncLoader.isError;

  const canProceed =
    answerSelections.reduce(
      (prev, selectionIndex, index) =>
        prev && selectionIndex === questionCardsContent[index].validSelectionIndex,
      true,
    ) && !isStartIntegrationDisabled;

  const handleAnswerSelection = (questionIndex: number, answerIndex: number) => {
    setAnswerSelections((prevSelections) => {
      const selectionsCopy = [...prevSelections];

      selectionsCopy[questionIndex] = answerIndex;

      return selectionsCopy;
    });
  };

  const handleBackCtaClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  const proceedCtaContent = knitScriptAsyncLoader.isLoading ? (
    <Button isLoading={knitScriptAsyncLoader.isLoading}>Connect to {hrmsAppMetadata.name}</Button>
  ) : (
    <knit-auth ref={knitRef}>
      {/* Adding a span to capture the click event because blade button would not accept slot="trigger" attribute */}
      <span
        slot="trigger"
        onClickCapture={(e) => {
          if (!canProceed) {
            e.stopPropagation();
          }
        }}>
        <Button isDisabled={!canProceed}>Connect to {hrmsAppMetadata.name}</Button>
      </span>
    </knit-auth>
  );

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  return (
    <IntegrationWindow
      progress={integrationProgress}
      title={<IntegrationWindowTitle appName={hrmsAppMetadata.name} />}
      onClose={handleCloseViewClick}>
      <Box display="flex" flexDirection="column" gap="spacing.7" maxWidth="592px">
        <Heading as="h2" size="xlarge" color="surface.text.gray.subtle">
          Before integrating, make sure
        </Heading>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          {questionCardsContent.map(
            ({ title, description, selectionOptions, noticeText, validSelectionIndex }, index) => {
              const canShowNotice =
                answerSelections[index] !== undefined &&
                answerSelections[index] !== validSelectionIndex;

              return (
                <QuestionCard
                  title={title}
                  description={description}
                  answerOptions={selectionOptions}
                  key={index}
                  onSelect={(selectionIndex) => handleAnswerSelection(index, selectionIndex)}
                  noticeText={canShowNotice ? noticeText : undefined}
                />
              );
            },
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          {proceedCtaContent}
        </Box>
      </Box>
    </IntegrationWindow>
  );
};

export { Integration };
