import { Box, Spinner } from '@razorpay/blade/components';
import React from 'react';

export const LazyComponentLoader = () => {
  return (
    <Box height='100%' width='100%' display='flex' justifyContent='center' alignItems='center' >
      <Spinner accessibilityLabel="loading page" />
    </Box>
  );
};
