import React  from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import styles from "./index.module.scss";
import {Card} from "../../../../../ui/Card";
import {
    JibbleInititalEmployeeSyncResponseSchemaContract
} from "../../../../../../schemas/JibbleInititalEmployeeSyncResponseSchema";
import {JibbleAccountCreationRequestBody} from "../../../../../../api/jibbleSettings";

export type ModalElements = {
    header: string,
    employeeData: JibbleInititalEmployeeSyncResponseSchemaContract['mapped_employees'] |
        JibbleAccountCreationRequestBody[];
}

const EmployeeListModal = ({header, employeeData}: ModalElements) => {
    return (
        <Modal>
            <div className={styles['employeeListModal']}>
                <div className={styles['employeeListModalHeader']}>{header}</div>
                <div className={styles['employeeList']}>
                    {employeeData.map((employeeDatum) => {
                        return (
                            <Card className={styles['employeeCard']} >
                                <p className="font-bold">{employeeDatum.name}</p>
                                <p className="text-1.5xl opacity-80">{employeeDatum.email}</p>
                            </Card>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default EmployeeListModal;