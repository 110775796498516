import React from "react";
import {Option, Select} from "../Form/Select";
import styles from "./index.module.scss";
import Label from "../Label";
import {StandardButton} from '../Button';

interface PaginationProps {
    className?: string;
    totalRows: number;
    limitOptions: number[];
    limit: number;
    onLimitChange: (limit: number) => void;
    totalPages: number;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
}

const Pagination = ({
    className,
    totalRows,
    limitOptions,
    limit,
    onLimitChange,
    totalPages,
    currentPage,
    onPageChange
}: PaginationProps) => {
    const pageNumberList = Array(totalPages).fill(0).map((_, i) => i+1);

    return (
        <div className={`flex justify-between mt-10 ${className}`}>
            { totalRows>limitOptions[0] && (
                <div className="flex items-center">
                    <Select
                        className={styles['select']}
                        id="rows-limit"
                        name="rows-limit"
                        value={limit}
                        onChange={(e) => {onLimitChange(parseInt(e.target.value))}}
                    >
                        <Option key={`limit-${limitOptions[0]}`} value={limitOptions[0]}>{limitOptions[0]}</Option>
                        {limitOptions.map((value, index) => {
                            return (
                                index>0 &&
                                totalRows > limitOptions[index-1] &&
                                <Option key={`limit-${value}`} value={value}>{value}</Option>
                            )
                        })}
                    </Select>
                    <Label htmlFor="rows-limit">rows/page</Label>
                </div>
            )}
            {totalPages>1 && (
                <div className="flex items-center">
                    <span>Page</span>
                    <Select
                        className={`${styles['select']} ml-3 mr-3`}
                        id="page-select"
                        name="page-select"
                        value={currentPage}
                        onChange={(e) => {onPageChange(parseInt(e.target.value))}}
                    >
                        { pageNumberList.map((pageNumber) => {
                            return <Option key={`page-${pageNumber}`} value={pageNumber.toString()}>{pageNumber}</Option>
                        })}
                    </Select>
                    <span> of {totalPages}</span>
                    {currentPage>1 && (
                        <StandardButton
                            className={styles['turnPageButton']}
                            onClick={() => {onPageChange(currentPage - 1)}}
                        >
                            &larr;
                        </StandardButton>
                    )}
                    {currentPage<totalPages && (
                        <StandardButton
                            className={styles['turnPageButton']}
                            onClick={() => {onPageChange(currentPage + 1)}}
                        >
                            &rarr;
                        </StandardButton>
                    )}
                </div>
            )}
        </div>
    )
}

export default Pagination;