import { Card } from 'components/ui/Card';
import React, { ReactElement } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import springVerifyIntro from 'assets/springVerify/springVerify-intro.svg';
import verification from 'assets/springVerify/verification.png';
import onboarding from 'assets/springVerify/onboarding.png';
import bulkHiring from 'assets/springVerify/bulk-hiring.png';
import { TickList, TickListItem } from 'components/ui/TickList';
import { routePaths } from 'components/Routes/data';
import { LinkButtonV2, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/ui/ExternalLink';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { StandardTable } from 'components/ui/Table';

function SpringVerifyIntro(): ReactElement {
  return (
    <>
      <img src={getStaticMediaUrl(springVerifyIntro)} alt='SpringVerify Intro Logo' />
      <div className={`text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center`}>
        Now integrate RazorpayX Payroll with{' '}
        <span className="text-spring-verify-green font-bold">SpringVerify</span>
      </div>
      <p className="text-center text-1.7xl text-white-o-70">
        Make smart hiring decisions with prompt, reliable, and accurate background checks.
      </p>
      <Card className={`mt-12 px-10 py-10 border-spring-verify-green border-t-2`}>
        <div className="font-bold text-white-o-80 mb-8 text-100">Now with SpringVerify, you can</div>
        <div className="flex justify-between">
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(verification)} alt='verification icon'></img>
            <p className="text-1.5xl text-white-o-70">
              Initiate a candidate background verification instantly
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(onboarding)} alt= 'onboarding icon'></img>
            <p className="text-1.5xl text-white-o-70">
              Create a delightful candidate onboarding experience
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={getStaticMediaUrl(bulkHiring)} alt='bulk hiring icon'></img>
            <p className="text-1.5xl text-white-o-70">
              Complete bulk and seasonal hiring with AI-based solutions
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center mt-16">
          {/* TODO: TO change demo call link */}
          <ExternalLink href="https://calendly.com/xpayroll-springverify">
            <StandardSecondaryButton className="capitalize">Book a demo</StandardSecondaryButton>
          </ExternalLink>
          {/* TODO : Update learn more when LP is ready */}
          {/* <ExternalLink>Learn More</ExternalLink> */}
        </div>
      </Card>
      <Card className={`mt-12 px-10 py-10 border-spring-verify-green border-t-2`}>
        <div className="font-bold text-white-o-80 mb-8 text-100">Why integrate with RazorpayX Payroll ?</div>
        <TickList className="pl-6">
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Document collection, consent, background checks, follow-ups, detailed verification
              reports, and everything else is managed for you.
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Complete candidate verifications within just a few hours!
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              A detailed verification report that includes document copies, verification results,
              and email conversations to keep your audit records clean.
            </span>
          </TickListItem>
        </TickList>
      </Card>
      <Link to={routePaths.settings.springVerify.activation}>
        <StandardButton className="mt-12">Connect Now</StandardButton>
      </Link>
    </>
  );
}

export default SpringVerifyIntro;
