import {
  Box,
  Checkbox,
  ChevronDownIcon,
  ChevronUpIcon,
  Skeleton,
  Text,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import {
  HoverTrayContainer,
  TableCheckboxCell,
  TableContainer,
  TableDataCell,
  TableHeadCell,
  TableHeadHoverTrayCell,
  TableRow,
} from './styles';
import {
  RowData,
  TableDataRowProps,
  TableHeadProps,
  TableProps,
  TableSkeletonProps,
} from './types';
import useStickyColumns from './useStickyColumns';

const TableHeadContent = <T extends RowData>({
  columns,
  isActionable,
  hoverTray,
  sortedColumns = {},
  onSortChange,
}: TableHeadProps<T>) => {
  const headerCells = [];
  if (isActionable) {
    headerCells.push(<TableHeadCell key="empty" />);
  }

  const handleSortClick = (key?: string) => {
    if (!key) return;
    const previuosOperation = sortedColumns[key];
    if (!previuosOperation) {
      onSortChange?.(key, 'asc');
    } else {
      onSortChange?.(key, previuosOperation === 'asc' ? 'desc' : 'asc');
    }
  };

  columns.forEach(({ title, styles, isSortable, key }, index) => {
    headerCells.push(
      <TableHeadCell
        key={title as string}
        style={{ textAlign: styles?.textAlign }}
        isLastCell={index === columns.length - 1}
        isFirstCell={index === 0 && !isActionable}
        isSticky={styles?.position === 'sticky'}
        onClick={() => handleSortClick(key)}
        className={styles?.position === 'sticky' ? 'sticky-col' : ''}
        backgroundColor={styles?.backgroundColor}>
        <Box display="inline-flex" gap="spacing.3" alignItems="center">
          <Text size='medium' weight="medium" color="interactive.text.gray.normal">
            {title}
          </Text>
          {isSortable && key && sortedColumns[key] ? (
            <span>
              {key && sortedColumns[key] === 'asc' ? (
                <ChevronUpIcon size="medium" color={'interactive.icon.primary.normal'} />
              ) : (
                <ChevronDownIcon size="medium" color={'interactive.icon.primary.normal'} />
              )}
            </span>
          ) : null}
        </Box>
      </TableHeadCell>,
    );
  });

  if (hoverTray) {
    headerCells.push(<TableHeadHoverTrayCell key="hoverTray" />);
  }

  return <tr>{headerCells}</tr>;
};

const TableColumnGroup = <T extends RowData>({ columns, isActionable }: TableHeadProps<T>) => {
  const tableColumns = [];
  if (isActionable) {
    tableColumns.push(<col width="56px" />);
  }

  columns.forEach((column, index) => {
    tableColumns.push(
      <col
        key={index}
        width={column.styles?.width}
        style={{ minWidth: column.styles?.minWidth }}
      />,
    );
  });

  return <colgroup>{tableColumns}</colgroup>;
};

const TableDataRow = <T extends RowData>({
  columns,
  rowData,
  index,
  onRowChecked,
  onRowClick,
  hoverTray,
}: TableDataRowProps<T>) => {
  const [isHovering, setIsHovering] = useState(false);
  const showCheckboxCell = !!onRowChecked;
  const isChecked = !!rowData.isChecked;
  const isClicked = !!rowData.isClicked;
  const allowAction = rowData.allowAction;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleRowClicked = () => {
    onRowClick && onRowClick(rowData);
  };

  const showHoverTray = isHovering && !!hoverTray && allowAction;

  return (
    <TableRow
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleRowClicked}
      isClickable={!!onRowClick}
      isChecked={isChecked}
      isClicked={isClicked}>
      {showCheckboxCell && (
        <TableCheckboxCell>
          <Box display="flex">
            {allowAction && (
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  onChange={({ isChecked }) => {
                    onRowChecked({
                      rowData,
                      isChecked,
                      index,
                    });
                  }}
                  isChecked={isChecked}>
                  <></>
                </Checkbox>
              </div>
            )}
          </Box>
        </TableCheckboxCell>
      )}
      {columns.map((column, index) => {
        const { render, title, styles } = column;
        const isSticky = styles?.position === 'sticky';
        return (
          <TableDataCell
            isSticky={isSticky}
            className={isSticky ? 'sticky-col' : ''}
            backgroundColor={styles?.backgroundColor}
            key={title}
            style={{ textAlign: styles?.textAlign }}
            isLastCell={index === columns.length - 1}
            isFirstCell={index === 0 && !showCheckboxCell}>
            {render(rowData, index)}
          </TableDataCell>
        );
      })}
      <HoverTrayContainer isVisible={showHoverTray} isChecked={isChecked}>
        <Box display="flex" alignItems="center" padding={['spacing.0', 'spacing.8']} height="100%">
          {hoverTray && hoverTray(rowData)}
        </Box>
      </HoverTrayContainer>
    </TableRow>
  );
};

const TableSkeleton = <T extends RowData>({
  columns,
  showCheckboxSkeleton,
}: TableSkeletonProps<T>) => {
  return (
    <Box width="100%" height="500px" display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        {showCheckboxSkeleton && (
          <Box key="checkbox" width="spacing.5" height="spacing.4" marginLeft="spacing.8" />
        )}
        <Box
          width="100%"
          display="flex"
          gap="spacing.9"
          justifyContent="space-between"
          alignItems="center"
          padding={
            showCheckboxSkeleton
              ? ['spacing.4', 'spacing.8', 'spacing.4', 'spacing.5']
              : ['spacing.4', 'spacing.8']
          }>
          {columns.map((_, index) => (
            <Skeleton key={index} height="spacing.4" width="100%" borderRadius="medium" />
          ))}
        </Box>
      </Box>
      {((rowCount) => {
        const skeletonContent: JSX.Element[] = [];
        for (let i = 0; i < rowCount; i++) {
          skeletonContent.push(
            <Box display="flex" alignItems="center">
              {showCheckboxSkeleton && (
                <Box key="checkbox" padding={['spacing.4', '0px', 'spacing.4', 'spacing.8']}>
                  <Skeleton height="spacing.5" width="spacing.5" borderRadius="medium" />
                </Box>
              )}
              <Box
                width="100%"
                display="flex"
                gap="spacing.9"
                justifyContent="space-between"
                alignItems="center"
                padding={
                  showCheckboxSkeleton
                    ? ['spacing.4', 'spacing.8', 'spacing.4', 'spacing.5']
                    : ['spacing.4', 'spacing.8']
                }>
                {columns.map((_, index) => (
                  <Skeleton key={index} height="spacing.5" width="100%" borderRadius="medium" />
                ))}
              </Box>
            </Box>,
          );
        }

        return skeletonContent;
      })(11)}
    </Box>
  );
};

const Table = <T extends RowData>({
  columns,
  data,
  onRowChecked,
  onRowClick,
  hoverTray,
  isLoading,
  isFetching,
  isError,
  noResultsView,
  errorResultsView,
  layout = 'fixed',
  id,
  onSortChange,
  sortedColumns = {},
}: TableProps<T>) => {
  const isActionable = !!onRowChecked;

  useStickyColumns();

  if (isLoading) {
    return <TableSkeleton columns={columns} showCheckboxSkeleton={!!onRowChecked} />;
  }

  if (isError && errorResultsView) {
    return errorResultsView;
  }

  if (data.length === 0 && noResultsView) {
    return noResultsView;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
      overflowX={layout === 'auto' ? 'auto' : 'hidden'}>
      <TableContainer id={id} layout={layout}>
        <TableColumnGroup columns={columns} isActionable={isActionable} />
        <thead>
          <TableHeadContent
            columns={columns}
            isActionable={isActionable}
            hoverTray={hoverTray}
            onSortChange={onSortChange}
            sortedColumns={sortedColumns}
          />
        </thead>
        <tbody>
          {data.map((rowData, index) => (
            <TableDataRow
              key={rowData.id}
              columns={columns}
              rowData={rowData}
              onRowChecked={onRowChecked}
              onRowClick={onRowClick}
              index={index}
              hoverTray={hoverTray}
            />
          ))}
        </tbody>
      </TableContainer>
    </Box>
  );
};

export { Table, TableDataRow, TableHeadContent };
