import { useQuery } from "react-query";
import { queryKeys } from "../constants";
import api from "api";
import { AppError } from "utils/AppError";

const useFetchUserDetails = (userId: string | null) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [queryKeys.FETCH_USER_DETAILS, userId],
    queryFn: () =>
      api.userQueries.getUserbyId({userId: userId!}),
    select: (data) => data?.payrollGetUser.data,
    onError: (error: AppError) => { },
  });

  const firstName = data && data.name ? data?.name.split(' ')[0] : null ;

  return { data, isLoading, error, firstName, refetch };
};

export default useFetchUserDetails;