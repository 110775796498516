import React, { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { CheckBox, Input } from 'components/ui/Form/Input';
import Note from 'components/ui/Note';
import Label from 'components/ui/Label';
import ExternalLink from 'components/ui/ExternalLink';
import { Table } from 'components/ui/Table';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { TDSUpdateRequest } from 'api/companyOnboarding';
import api from 'api';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';
import { WarningAlert } from 'components/ui/Alert';
import { routePaths } from 'components/Routes/data';
import { Select, Option } from 'components/ui/Form/Select';
import { getNextFourQuarters } from './utils';
import InviteCA from './InviteCA';
import { trackSegment } from 'utils/segment';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { TAN_REGEX } from "../../../../utils/regexes";
import InputInfo from 'components/ui/Form/Input/InputInfo';
import KYC from 'constants/kyc';
import { InputElement } from 'components/ui/Form/Input';

const START_FROM_IMMEDEATELY = 'immedeately';

const TdsSetup = () => {
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tan, setTan] = useState(currentOrganization?.tan || '');
  const [isSalaryTDSEnabled, setIsSalaryTDSEnabled] = useState(
    !!currentOrganization?.paymentOptions?.payTds,
  );
  const [isNonSalaryTDSEnabled, setIsNonSalaryTDSEnabled] = useState(
    !!currentOrganization?.paymentOptions?.enableTdsPaymentForContractors,
  );
  const [tracesUsername, setTracesUsername] = useState(
    currentOrganization?.externalCredentials?.tracesUser || '',
  );
  const [tracesPassword, setTracesPassword] = useState(
    currentOrganization?.externalCredentials?.tracesPass || '',
  );
  const [itPortalUsername, setItPortalUsername] = useState(
      currentOrganization?.externalCredentials?.itUser || '',
  );
  const [itPortalPassword, setItPortalPassword] = useState(
      currentOrganization?.externalCredentials?.itPass || '',
  );
  const [showPassword, setShowPassword] = useState(false);
  const links = useSelector(loggedInUserSelectors.referenceLinks);
  const [shouldAutoFile24Q, setShouldAutoFile24Q] = useState(!!currentOrganization?.auto24q);
  const [shouldAutoFile26Q, setShouldAutoFile26Q] = useState(!!currentOrganization?.auto26q);
  const [start24QFrom, setStart24QFrom] = useState(
    currentOrganization?.paymentOptions?.start24QFrom || START_FROM_IMMEDEATELY,
  );
  const [start26QFrom, setStart26QFrom] = useState(
    currentOrganization?.paymentOptions?.start26QFrom || START_FROM_IMMEDEATELY,
  );
  const nextFourQuarters = getNextFourQuarters();
  const hasInvitedCA = !!currentOrganization?.onboardingConfig?.hasInvitedCA;

  const updateTDSMutation = useMutation(
    ({
      isSalaryTDSEnabled,
      isNonSalaryTDSEnabled,
      itPortalUsername,
      itPortalPassword,
      tracesUsername,
      tracesPassword,
      shouldAutoFile24Q,
      shouldAutoFile26Q,
      start24QFrom,
      start26QFrom,
      tan,
    }: TDSUpdateRequest) =>
      api.companyOnboarding.updateTDSSetup({
        isSalaryTDSEnabled,
        isNonSalaryTDSEnabled,
        itPortalUsername,
        itPortalPassword,
        tracesUsername,
        tracesPassword,
        shouldAutoFile24Q,
        shouldAutoFile26Q,
        start24QFrom,
        start26QFrom,
        tan,
      }),
    {
      onSuccess: (data) => {
        trackSegment("onboarding.setup_compliance.tds.save_and_next_click");
        dispatch(fetchLoggedInUser());
        history.push(routePaths.companyOnboarding.compliance.pt);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!isSalaryTDSEnabled) {
        setShouldAutoFile24Q(false);
    }

    if (!isNonSalaryTDSEnabled) {
        setShouldAutoFile26Q(false);
    }

    let start24QFromRequest: string | null = start24QFrom;
    let start26QFromRequest: string | null = start26QFrom;

    if (start24QFromRequest === START_FROM_IMMEDEATELY) {
      start24QFromRequest = null;
    }

    if (start26QFromRequest === START_FROM_IMMEDEATELY) {
      start26QFromRequest = null;
    }

    let payLoad = {
      isSalaryTDSEnabled,
      isNonSalaryTDSEnabled,
      shouldAutoFile24Q,
      shouldAutoFile26Q,
      start24QFrom: start24QFromRequest,
      start26QFrom: start26QFromRequest,
    };

    if (isNonSalaryTDSEnabled || isSalaryTDSEnabled ) {
      const extraKeys = {
        itPortalUsername,
        itPortalPassword,
        tracesUsername,
        tracesPassword,
        tan,
      }
      payLoad = {...payLoad,...extraKeys};
    }

    updateTDSMutation.mutate(payLoad);
    
  };

  const isFormBeingSubmitted = updateTDSMutation.status === 'loading';

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit}>
        {!hasInvitedCA && (
          <div className="mb-16">
            <InviteCA />
          </div>
        )}
        {updateTDSMutation.status === 'error' && (
          <WarningAlert className="mb-4">
            <Errors errorResponse={updateTDSMutation.error} />
          </WarningAlert>
        )}
        <Label>TDS Payments</Label>
        <Note>
          Please note that disabling the payment of TDS does not disable the deduction of the same
          in your employees' payslips.
        </Note>
        <CheckBox
          id="salary-tds-payment-enabled"
          label="Salary TDS payments (if applicable)"
          className="mb-8"
          value="is-salary-tds-enabled"
          checked={isSalaryTDSEnabled}
          onChange={(event) => {
            setIsSalaryTDSEnabled(event.target.checked);
          }}
          disabled={isFormBeingSubmitted}
        />
        <CheckBox
          id="non-salary-tds-payment-enabled"
          label="Non-salary TDS payments (if applicable)"
          className="mb-8"
          value="is-non-salary-tds-enabled"
          checked={isNonSalaryTDSEnabled}
          onChange={(event) => {
            setIsNonSalaryTDSEnabled(event.target.checked);
          }}
          disabled={isFormBeingSubmitted}
        />

        {(isSalaryTDSEnabled || isNonSalaryTDSEnabled) && (
            <>
            <InputElement required label="Company TAN" className="my-auto mt-4 mb-10">
              <Input
                type="text"
                maxLength={10}
                value={tan}
                required
                name="company-tan"
                onChange={(event) => setTan(event.target.value)}
                placeholder="Enter your company TAN number"
                customErrorMessage="Invalid or Empty TAN"
                pattern={TAN_REGEX}
                disabled={isFormBeingSubmitted}
              />
              <InputInfo>
                You can verify your TAN <ExternalLink href={links.verifyTanLink} onClick={() => trackSegment('onboarding.company_details.tax_setup.verify_your_tan_here_click')} >here</ExternalLink>.
              </InputInfo>
            </InputElement>
            </>
        )}  

        {(isSalaryTDSEnabled || isNonSalaryTDSEnabled) && (
          <>
            <Label className="mt-16">TDS Filing Setup</Label>
            <Note>
              <p className='mb-16'>
                XPayroll can automatically file your TDS returns at the end of every quarter! Here
                are a few things to note:
              </p>
              <NumberedList>
                <NumberedListItem>
                  If your payroll is being run by us for an entire quarter, you should definitely
                  let us file your <ExternalLink href={links.link24Q}>24Q</ExternalLink> returns.
                  <br />
                  However, if there have been salary TDS deductions in this quarter from outside of
                  XPayroll, we recommend that you file your returns manually this one time.
                </NumberedListItem>
                <NumberedListItem>
                  For <ExternalLink href={links.link26Q}>26Q</ExternalLink>,have XPayroll file your
                  returns if you are making <strong className="font-bold">all</strong> your
                  non-salary payments (to contractors, vendors, etc.) through us.
                </NumberedListItem>
                <NumberedListItem>
                  XPayroll will not be able to generate Form 16s for your employees unless the TDS
                  returns are being filed by us.
                </NumberedListItem>
              </NumberedList>
            </Note>
          </>
        )}
        {isSalaryTDSEnabled && (
          <>
            <CheckBox
              id="auto-24q"
              label="Automatically file 24Q"
              className="mb-8"
              disabled={isFormBeingSubmitted}
              value="auto-24q"
              checked={shouldAutoFile24Q}
              onChange={(e) => setShouldAutoFile24Q(e.target.checked)}
            />
            <br />
            {shouldAutoFile24Q && (
              <>
                <Label>Start 24Q filing from</Label>
                <Select
                  disabled={isFormBeingSubmitted}
                  className="mb-8"
                  value={start24QFrom}
                  onChange={(e) => setStart24QFrom(e.target.value)}
                >
                  <Option value={START_FROM_IMMEDEATELY}>Immediately</Option>
                  {nextFourQuarters.map((quarter) => (
                    <Option
                      key={quarter.quarter}
                      value={`Q${quarter.quarter}-${quarter.financialYear}`}
                    >
                      Q{quarter.quarter}, FY {quarter.financialYear} - {quarter.financialYear + 1}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </>
        )}
        {isNonSalaryTDSEnabled && (
          <>
            <CheckBox
              id="auto-26q"
              label="Automatically file 26Q"
              className="mb-8"
              disabled={isFormBeingSubmitted}
              value="auto-26q"
              checked={shouldAutoFile26Q}
              onChange={(e) => setShouldAutoFile26Q(e.target.checked)}
            />
            <br />
            {shouldAutoFile26Q && (
              <>
                <Label>Start 26Q filing from</Label>
                <Select
                  disabled={isFormBeingSubmitted}
                  value={start26QFrom}
                  onChange={(e) => setStart26QFrom(e.target.value)}
                >
                  <Option value={START_FROM_IMMEDEATELY}>Immediately</Option>
                  {nextFourQuarters.map((quarter) => (
                    <Option
                      key={quarter.quarter}
                      value={`Q${quarter.quarter}-${quarter.financialYear}`}
                    >
                      Q{quarter.quarter}, FY {quarter.financialYear} - {quarter.financialYear + 1}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </>
        )}

        {(isSalaryTDSEnabled || isNonSalaryTDSEnabled) && (
          <>
            <Label className="mt-16"><b>IT Portal credentials (Required for TDS filing)</b></Label>
            <Note>
              <p className='mb-12'>
                For RazorpayX Payroll to automate your TDS filings, you must provide us with your
                Income Tax Portal login credentials at the earliest.
              </p>
              <p>
                <div className='mb-4'>🚨 <b>Important</b></div>
                <ul className="list-disc ml-8">
                  <li>The UserID entered needs to be your TAN.</li>
                  <li>
                    Please ensure you have registered under the "Tax Deductor and Collector" category
                    correctly using the <ExternalLink href={links.itRegistrationStepsLink}>linked steps</ExternalLink>
                  </li>
                </ul>
              </p>
            </Note>
            <Table className="w-full mb-6">
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Input
                      type="text"
                      name="it-username"
                      disabled={isFormBeingSubmitted}
                      maxLength={10}
                      value={itPortalUsername}
                      placeholder={"Enter your company TAN number"}
                      pattern={TAN_REGEX}
                      autoComplete='off'
                      title={"User ID must be TAN Number."}
                      onChange={(e) => setItPortalUsername(e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name="it-password"
                      disabled={isFormBeingSubmitted}
                      maxLength={255}
                      autoComplete='off'
                      value={itPortalPassword}
                      onChange={(e) => setItPortalPassword(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="italic">You can update the IT Portal credentials later as well!</div>
          </>
        )}

        {(isSalaryTDSEnabled || isNonSalaryTDSEnabled) && (
          <>
            <Label className="mt-16">TRACES</Label>
            <Note>
              Your company's <ExternalLink onClick={() => trackSegment('onboarding.setup_compliance.tds.traces_click')} 
              href={links.linkTraces}>TRACES</ExternalLink> login is
              required to file TDS returns, corrections, and to download Form 16/16A data.
            </Note>

            <Table className="w-full mb-10">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Input
                      type="text"
                      name="traces-username"
                      disabled={isFormBeingSubmitted}
                      maxLength={255}
                      value={tracesUsername}
                      onChange={(e) => setTracesUsername(e.target.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name="traces-password"
                      disabled={isFormBeingSubmitted}
                      maxLength={255}
                      value={tracesPassword}
                      onChange={(e) => setTracesPassword(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <StandardSecondaryButton type="button" onClick={() => setShowPassword(!showPassword)}>
              Click here to {showPassword ? 'hide' : 'show'} password
            </StandardSecondaryButton>
          </>
        )}
        <br />
        <StandardButton className="mt-16" type="submit" isPending={isFormBeingSubmitted}>
          Save & Next
        </StandardButton>
      </form>
    </div>
  );
};

export default TdsSetup;
