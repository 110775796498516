import {
  ActionList,
  ActionListItem,
  Alert,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  ExternalLinkIcon,
  Heading,
  Link,
  Radio,
  RadioGroup,
  SelectInput,
  Text,
} from '@razorpay/blade/components';
import PageLayout from 'components/ui/Layout/PageLayout';
import { Reveal } from 'components/ui/Reveal/Reveal';
import { useSearchParams } from 'hooks/useSearchParams';
import React, { FormEvent, useState } from 'react';
import ConfirmationSheet from '../ReleaseNetpay/ConfirmationSheet';
import FnfDismissalInformation from '../components/FnfDismissalInformation';
import {
  formatDOJ,
  formatTenure,
  getNetPayOnHoldMessage,
  getPayrollMonthsAvailableForHold,
} from '../utils';
import { exitLearnMoreLink, holdSalaryOptions } from './constants';
import { NameCircularWrapper, RadioWrapper } from './styles';
import useFnfApi from './useFnfApi';

const InitiateExit = () => {
  const { urlParams } = useSearchParams();
  const peopleId = urlParams.get('userId');
  const [selectedHoldOption, setSelectedHoldOption] = useState('0');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [dismissalDateInput, setDismissalDateInput] = useState<null | Date>(null);

  const { fnfData, isError, isFetching, lastFinalizedPayrollMonth, setFnfData, processFnf } =
    useFnfApi({
      dismissalDateInput,
      selectedHoldOption,
      setDismissalDateInput,
      setSelectedHoldOption,
      peopleId,
    });

  const clearNetPayHoldFrom = () => {
    if (fnfData) {
      setFnfData({ ...fnfData, net_pay_on_hold_from: null });
    }
  };

  const onChangeDismissalDate = (dismissalDate: Date | null) => {
    setSelectedHoldOption('0');
    clearNetPayHoldFrom();
    setDismissalDateInput(dismissalDate ?? new Date());
  };

  const onChangeReason = (reason: string) => {
    if (fnfData) {
      setFnfData({
        ...fnfData,
        reason_for_leaving: reason,
      });
    }
  };

  const { isPayrollSettlementMonth, payrollMonthsAvailableForHold } =
    getPayrollMonthsAvailableForHold(lastFinalizedPayrollMonth, dismissalDateInput);

  const payrollStatus = fnfData?.payroll_status ?? { is_finalized: false, is_paid: false };

  const isPayrollFinalizedAndNotPaid = payrollStatus.is_finalized && !payrollStatus.is_paid;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsConfirmationOpen(true);
  };

  const handleDismiss = () => {
    processFnf.mutate({ fnfData, dismissalDateInput, peopleId });
  };

  const handleOnSalaryOnHoldTypeChange = (selectedHoldOption: string) => {
    setSelectedHoldOption(selectedHoldOption);
    clearNetPayHoldFrom();
  };

  const handleOnStartingMonthChange = (startingMonth: string) => {
    if (fnfData) {
      setFnfData({ ...fnfData, net_pay_on_hold_from: startingMonth });
    }
  };

  const netPayOnHoldFrom = fnfData?.net_pay_on_hold_from ?? null;

  const messageForNetPayOnHoldFrom = getNetPayOnHoldMessage(
    payrollMonthsAvailableForHold,
    netPayOnHoldFrom,
  );

  const isValid = !!dismissalDateInput && (selectedHoldOption === '1' ? !!netPayOnHoldFrom : true);

  if (isError) {
    return (
      <PageLayout
        hideHeading
        boxProps={{
          height: '100%',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={{ l: '630px', base: 'auto' }}
          marginY={'spacing.11'}
          marginX={{ base: 'spacing.8', l: 'auto' }}>
          <Alert
            color="negative"
            description={'Error in fetching fnf information. Please try again in some time.'}
          />
        </Box>
      </PageLayout>
    );
  }

  const initials = fnfData?.employee_name
    ?.split(' ')
    .filter((part) => part.length)
    .map((x) => x.charAt(0));

  return (
    <PageLayout
      hideHeading
      boxProps={{
        width: 'auto',
        height: '100%',
      }}
      isLoading={isFetching}>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={{ l: '570px', base: 'auto' }}
          marginY={'spacing.11'}
          marginX={{ base: 'spacing.8', l: 'auto' }}
          gap="spacing.10"
          overflow="visible">
          <Box>
            <Heading size="xlarge">Initiate exit process</Heading>
            <Box
              marginTop="spacing.7"
              borderWidth="thin"
              borderColor="surface.border.gray.subtle"
              borderRadius="large"
              padding="spacing.7">
              <Box display="flex" gap="spacing.4">
                <NameCircularWrapper>
                  <Heading size="small" color="surface.text.gray.subtle">
                    {initials}
                  </Heading>
                </NameCircularWrapper>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Heading testID="employee_name" size="small" color="surface.text.gray.subtle">
                    {fnfData?.employee_name}
                  </Heading>
                  <Text color="surface.text.gray.muted">{fnfData?.employee_id}</Text>
                </Box>
              </Box>
              <Box marginTop="spacing.5" display="flex" justifyContent="space-evenly">
                <Box flex="1" display="flex" flexDirection="column">
                  <Text size="xsmall" color="surface.text.gray.muted">
                    POSITION
                  </Text>
                  <Text weight="semibold">{fnfData?.employee_title}</Text>
                </Box>
                <Box flex="1" display="flex" flexDirection="column">
                  <Text size="xsmall" color="surface.text.gray.muted">
                    DEPARTMENT
                  </Text>
                  <Text weight="semibold">{fnfData?.employee_department}</Text>
                </Box>
                <Box flex="1" display="flex" flexDirection="column">
                  <Text size="xsmall" color="surface.text.gray.muted">
                    DATE OF JOINING
                  </Text>
                  <Text weight="semibold">{formatDOJ(fnfData?.date_of_joining)}</Text>
                </Box>
                <Box flex="1" display="flex" flexDirection="column">
                  <Text size="xsmall" color="surface.text.gray.muted">
                    TENURE
                  </Text>
                  <Text weight="semibold">{formatTenure(fnfData?.date_of_joining)}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <FnfDismissalInformation
              padding={'spacing.0'}
              backgroundColor={'transparent'}
              borderWidth="none"
              dismissalDateInput={dismissalDateInput || new Date()}
              reason={fnfData?.reason_for_leaving ?? ''}
              onChangeDismissalDate={onChangeDismissalDate}
              onChangeReason={onChangeReason}
              payrollStatus={fnfData?.payroll_status ?? null}
              isDismissalSyncedByHRMS={fnfData?.is_dismissal_synced_by_hrms ?? false}
            />
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Text size="large">Salary during resignation months</Text>
              <Link href={exitLearnMoreLink} icon={ExternalLinkIcon} iconPosition="right">
                Learn more
              </Link>
            </Box>

            <RadioGroup
              value={selectedHoldOption}
              marginTop="spacing.5"
              label=""
              name="answer"
              onChange={(e) => handleOnSalaryOnHoldTypeChange(e.value)}>
              <Box display="flex" flexDirection="column" gap="spacing.3">
                {holdSalaryOptions.map((option, index) => (
                  <RadioWrapper isSelected={selectedHoldOption === index.toString()} key={index}>
                    <Radio
                      size="small"
                      value={index.toString()}
                      key={index}
                      helpText={option.helpText}>
                      {option.title}
                    </Radio>
                  </RadioWrapper>
                ))}
              </Box>
            </RadioGroup>
            <Reveal isOpen={selectedHoldOption === '1'}>
              <Box paddingTop="spacing.8">
                <Dropdown selectionType="single">
                  <SelectInput
                    label="Hold net pay starting from"
                    placeholder="Select month"
                    value={netPayOnHoldFrom || ''}
                    onChange={({ name, values }) => {
                      handleOnStartingMonthChange(values[0]);
                    }}
                  />
                  <DropdownOverlay>
                    <ActionList>
                      {payrollMonthsAvailableForHold.map((month) => (
                        <ActionListItem
                          key={month.formattedValue}
                          title={month.displayFormat}
                          value={month.formattedValue}
                        />
                      ))}
                    </ActionList>
                  </DropdownOverlay>
                </Dropdown>

                {netPayOnHoldFrom ? (
                  <Text marginTop="spacing.6" color="interactive.text.staticWhite.normal">
                    {messageForNetPayOnHoldFrom}
                  </Text>
                ) : null}
              </Box>
            </Reveal>
          </Box>
          <Box>
            <Button
              isDisabled={isPayrollFinalizedAndNotPaid || !isValid}
              type="submit"
              isLoading={processFnf.isLoading}>
              Dismiss Employee
            </Button>
          </Box>
        </Box>
      </form>
      <ConfirmationSheet
        isLoading={processFnf.isLoading}
        title={fnfData?.employee_name || ''}
        description={`${fnfData?.employee_name || ''} will be dismissed now from the organisation`}
        isOpen={isConfirmationOpen}
        onDismiss={() => setIsConfirmationOpen(false)}
        onConfirm={handleDismiss}
        confirmButtonText="Dismiss"
        cancelButtonText="Back"
      />
    </PageLayout>
  );
};

export default InitiateExit;
