import React, { ReactElement, useState } from "react";
import ZaggleActionItemsHeading from './ZaggleActionItemsHeading';
import PageWrapper from 'components/ui/PageWrapper';
import ZaggleActionItemsList from './ZaggleActionItemsList';
import ZaggleAdminCenteredMainCase from './ZaggleAdminCenteredMainCase';
import { useSelector } from "react-redux";
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import { useQuery } from "react-query";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { getOrgFlexiDetails } from "../../utils/init";
import api from '../../../../../api';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "../../utils/queries";
import { AppError } from "utils/AppError";
import { WarningAlert } from "components/ui/Alert";
import Errors from "components/ui/Form/Errors";
import Spinner from "components/ui/Spinner";
import ZaggleActionItemsFooter from './ZaggleActionItemsFooter';

const ZaggleAdminActionItemsPage = () : ReactElement => {

    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const [flexiDetails, setFlexiDetails] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails())
    const { isLoading, isFetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      }, {
        onSuccess: (data) => data && setFlexiDetails(data),
        onError: (error: typeof AppError) => {
            console.log("Error on fetching Org's flexi details data : ", error);
        }
      });

    if (isLoading) {
        return (
        <div className={'flex justify-center'}>
            <Spinner size={40}/>
        </div>); 
    }

    if(error){
        return (<WarningAlert><Errors errorResponse={error}/></WarningAlert>);
    }


    return (
        <PageWrapper>
            <ZaggleAdminCenteredMainCase>
                <ZaggleActionItemsHeading />
                <ZaggleActionItemsList data={flexiDetails}/>
                <ZaggleActionItemsFooter />
            </ZaggleAdminCenteredMainCase>
        </PageWrapper>
    );
}

export default ZaggleAdminActionItemsPage;