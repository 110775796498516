import React, {useState, useEffect} from 'react';
import api from 'api';
import { useQuery } from 'react-query';
import { ChatContextType, useChatContext } from './ChatContext';
import { ChatMessageTypes, ChatPollingStatusTypes, ChatResponseTypes } from './constants';
import { getChatSuggestionByStep } from './utils';

type UseChatPolling = {
  enabled?: boolean;
  pollingInterval?: number;
};

const CHAT_POLLING_QUERY_KEY = 'CHAT_POLLING_QUERY_KEY';
const CHAT_POLLING_MAX_ATTEMPT = 36;

const useChatPolling = ({ pollingInterval = 5000, enabled }: UseChatPolling) => {
  const {
    setIsBotProcessing,
    chatId,
    conversationCount,
    setPollData,
    pushMessage,
    setPolling,
    setInputSuggestions,
  } = useChatContext() as ChatContextType;
  const [pollingAttempt, setPollingAttempt] = useState<number>(0);

  const validateAndSetPollingAttempt = () => {
    const attempt = pollingAttempt + 1;
    if (attempt > CHAT_POLLING_MAX_ATTEMPT) {
      setPollingAttempt(0);
      setPolling(false);
      setIsBotProcessing(false);
      pushMessage({
        initiator: "bot",
        type: "errorDisplay",
        messageData: {
          error: "timeout"
        }
      })
      return false;
    }
    setPollingAttempt(attempt);
    return true;
  };

  useEffect(() => {
    if (!enabled) {
      setPollingAttempt(0);
    }
  }, [enabled]);

  const chatPollingQuery = useQuery({
    queryFn: () =>
      api.payrollAiAssistant.getLatestChatResponse({ conversationKey: chatId, conversationCount }),
    queryKey: [CHAT_POLLING_QUERY_KEY, chatId],
    enabled,
    refetchInterval: pollingInterval,
    onSuccess: (data) => {
      if(!validateAndSetPollingAttempt()) {
        return;
      }

      if (data.status === ChatPollingStatusTypes.IN_PROGRESS) {
        setIsBotProcessing(true);
        setInputSuggestions([]);
        return;
      }

      if (data.status === ChatPollingStatusTypes.DONE) {
        setPollData(data);
        setPolling(false);
        setInputSuggestions(getChatSuggestionByStep(conversationCount >= 2 ? 2 : 1));
        pushMessage({
          initiator: 'bot',
          messageData: data.response_type === ChatResponseTypes.TAX_BREAKUP 
            ? {
                data: data.response,
                showSuggested: true
              }
            : data.response,
          type:
            data.response_type === ChatResponseTypes.REGIME_BREAKUP
              ? ChatMessageTypes.regimeBreakup
              : data.response_type === ChatResponseTypes.TAX_BREAKUP
              ? ChatMessageTypes.taxBreakup
              : ChatMessageTypes.errorDisplay,
        });
      }
      setIsBotProcessing(false);
    },
    onError: () => {
      validateAndSetPollingAttempt();
      setInputSuggestions([]);
    },
  });

  return {
    chatPollingQuery,
  };
};

export { useChatPolling };
