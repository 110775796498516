import api from 'api';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { dateFormats } from 'utils/Dates';
import { showErrorToastNotification, showToastViaEvent } from 'utils/ToastEvents';
import { GET_FNF_DATA, GET_FNF_DISMISSAL_DATE, UPDATE_FNF_DATA } from '../queries';
import {
  makeAdditionOfMinimumLenth,
  makeNonLopDeductionsOfMinimumLength,
  prepareFnfDataForUpdate,
} from '../utils';
import { DeductFrom } from 'components/RunPayroll/utils/types';

const useFnfApi = ({
  dismissalDateInput,
  setDismissalDateInput,
  selectedHoldOption,
  setSelectedHoldOption,
  peopleId,
}: {
  dismissalDateInput: Date | null;
  setDismissalDateInput: React.Dispatch<React.SetStateAction<Date | null>>;
  setSelectedHoldOption: React.Dispatch<React.SetStateAction<string>>;
  selectedHoldOption: string;
  peopleId: string | null;
}) => {
  const [fnfData, setFnfData] = useState<null | GetFnfResponseSchemaContract>(null);

  const isFnfProcessed = fnfData?.is_fnf_processed ?? false;

  const dismissalDateQuery = useQuery({
    queryKey: [GET_FNF_DISMISSAL_DATE, peopleId],
    enabled: !!peopleId,
    queryFn: () => api.fnf.getDismissalDate(parseInt(peopleId as string)),
    onSuccess: (data) => {
      let dismissalDateForInput = new Date();
      if (data.deactivate_date) {
        dismissalDateForInput = new Date(data.deactivate_date);
      } else if (data.resignation_date) {
        dismissalDateForInput = new Date(data.resignation_date);
      }
      setDismissalDateInput(dismissalDateForInput);
    },
  });

  const fnfDataQuery = useQuery({
    enabled: !!peopleId && !!dismissalDateInput,
    queryKey: [GET_FNF_DATA, peopleId, dismissalDateInput],
    queryFn: () => {
      const dismissalDateParam = format(
        new Date(dismissalDateInput as Date),
        dateFormats.yearMonthDate,
      );
      return api.fnf.getFnfData(parseInt(peopleId as string), dismissalDateParam);
    },
    onSuccess: (data) => {
      data.additions = makeAdditionOfMinimumLenth(data.additions);
      data.deductions.non_lop_deductions = makeNonLopDeductionsOfMinimumLength(
        data.deductions.non_lop_deductions,
        isFnfProcessed ? DeductFrom.NET_PAY : DeductFrom.GROSS_PAY,
        isFnfProcessed,
      );
      setFnfData(data);
      if (data.net_pay_on_hold_from) {
        setSelectedHoldOption('1');
      }
    },
  });

  const finalizedPayrollMonthQuery = useQuery({
    enabled: !!peopleId,
    queryFn: () => api.runPayroll.getLastFinalizalizedPayrollMonth(peopleId as string),
  });

  const lastFinalizedPayrollMonth = finalizedPayrollMonthQuery.data?.last_finalized_payroll_month;

  const isFetchingDismissalDate = dismissalDateQuery.isLoading;
  const isErrorInFetchingDismissalDate = dismissalDateQuery.isError;

  const isFetchingFnfData = fnfDataQuery.isFetching;
  const isErrorInFetchingFnfData = fnfDataQuery.isError;

  const isFetchingLastPayrollMonth = finalizedPayrollMonthQuery.isFetching;
  const isErrorInFetchingLastFinalizedPayrollMonth = finalizedPayrollMonthQuery.isError;

  const isFetching = isFetchingDismissalDate || isFetchingFnfData || isFetchingLastPayrollMonth;
  const isError =
    isErrorInFetchingDismissalDate ||
    isErrorInFetchingFnfData ||
    isErrorInFetchingLastFinalizedPayrollMonth ||
    !peopleId;

  const processFnf = useMutation({
    mutationKey: UPDATE_FNF_DATA,
    mutationFn: ({
      fnfData,
      peopleId,
      dismissalDateInput,
    }: {
      fnfData: GetFnfResponseSchemaContract | null;
      peopleId: string | null;
      dismissalDateInput: Date | null;
    }) => {
      if (fnfData && peopleId && dismissalDateInput) {
        const startingMonth = selectedHoldOption === '1' ? fnfData.net_pay_on_hold_from : null;
        return api.fnf.updateFnfData(
          prepareFnfDataForUpdate(fnfData, dismissalDateInput, parseInt(peopleId), startingMonth),
        );
      } else {
        return Promise.reject('Fnf data is required');
      }
    },
    onSuccess: () => {
      showToastViaEvent({
        text: 'Dismissal successfull!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
      window.location.href = `/employeeDetail?userId=${peopleId}`;
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 3000,
      });
    },
  });
  return {
    isFetching,
    isError,
    processFnf,
    fnfData,
    setFnfData,
    lastFinalizedPayrollMonth,
  };
};

export default useFnfApi;
