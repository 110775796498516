import {
  CalendarIcon,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  TextInput,
  TextInputProps,
} from '@razorpay/blade/components';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DatePicker, { DatePickerProps } from './DatePicker';

export default function DatePickerBlade({
  value,
  onChange,
  dateFormat = 'dd / MM / yyyy',
  onClose,
  disableFuture = false,
  disablePast = false,
  isOpen = false,
  disableDates = [],
  disableYearSelector = false,
  disableMonthSelector = false,
  monthOffset = 0,
  boxProps = {},
  variant = 'modal',
  ...textInputProps
}: {
  isOpen?: boolean;
  label: string;
  dateFormat?: string;
  variant?: 'modal' | 'inline';
  onChange?: (date: Date | null) => void;
} & DatePickerProps &
  Omit<TextInputProps, 'onChange' | 'value'>) {
  const isDisabled = textInputProps.isDisabled;
  const [isOpenInternal, setIsPickerOpenInternal] = useState(isDisabled ? false : isOpen);

  const isPickerOpen = isDisabled ? false : isOpenInternal;

  const handleOnFocus = () => {
    setIsPickerOpenInternal((isPreviouslyOpen) => !isPreviouslyOpen);
  };

  const handleChange: DatePickerProps['onChange'] = (e) => {
    onChange?.(e);
    setIsPickerOpenInternal(false);
  };

  if (variant === 'inline') {
    return (
      <Popover
        isOpen={isPickerOpen}
        title="Select date"
        placement="bottom"
        zIndex={1000} // added z-index larger than modal
        onOpenChange={({ isOpen }) => setIsPickerOpenInternal(isOpen)}
        content={
          <DatePicker
            value={value}
            onChange={handleChange}
            disableDates={disableDates}
            disableFuture={disableFuture}
            disablePast={disablePast}
            boxProps={boxProps}
          />
        }>
        <div onClick={handleOnFocus}>
          <TextInput
            value={value ? format(value, dateFormat) : ''}
            icon={CalendarIcon}
            {...textInputProps}
          />
        </div>
      </Popover>
    );
  }

  return (
    <>
      <div onClick={handleOnFocus}>
        <TextInput
          value={value ? format(value, dateFormat) : ''}
          icon={CalendarIcon}
          {...textInputProps}
        />
      </div>
      <Modal isOpen={isPickerOpen} onDismiss={() => setIsPickerOpenInternal(false)}>
        <ModalHeader title="Select date" />
        <ModalBody padding="spacing.6">
          <DatePicker
            value={value}
            onChange={handleChange}
            disableDates={disableDates}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disableYearSelector={disableYearSelector}
            disableMonthSelector={disableMonthSelector}
            monthOffset={monthOffset}
            boxProps={boxProps}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
