import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from './Button';
import { ListLoader } from './Loaders';
import { Modal } from './Modal/Modal';
import { ProgressBar } from './ProgressBar';
import { RadioButton } from './RadioButton';
import { Tab } from './Tab';
import { Table } from './Table';

function ModalAndModalTrigger() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const footerChildren = (
    <div>
      <SecondaryButton onClick={toggleModal}>Click here for some action</SecondaryButton>
    </div>
  );
  return (
    <div>
      <SecondaryButton onClick={() => setShowModal(true)}> Open Modal </SecondaryButton>
      <Modal showModal={showModal} heading="Sample Modal" closeModal={() => setShowModal(false)}>
        <div>
          <p>Some modal content</p>
        </div>
      </Modal>
    </div>
  );
}
function Tabs() {
  let [selectedTab, setTab] = useState('tabA');
  const selections = {
    tabA: <span>Tab A</span>,
    tabB: <span>Tab B</span>,
    tabC: <span>Tab C</span>,
  };
  return (
    <Tab
      selections={selections}
      activeElement={selectedTab as keyof typeof selections}
      setActiveElement={(selectedTab: string) => setTab(selectedTab)}
    ></Tab>
  );
}

function RadioButtons() {
  return (
    <div className="flex flex-col sm:flex-row py-2 space-y-1 sm:space-y-0">
      <RadioButton className="sm:mx-1" isSelected={false}>
        Starter
      </RadioButton>
      <RadioButton className="sm:mx-1" isSelected={true}>
        Vigilant
      </RadioButton>
      <RadioButton className="sm:mx-1" isSelected={false}>
        Maternity
      </RadioButton>
    </div>
  );
}

export function UIDemo() {
  return (
    <main className="app__main">
      <div>
        <div>
          <SecondaryButton size="sm"> Demo </SecondaryButton>
        </div>
        <div>
          <SecondaryButton> Demo </SecondaryButton>
        </div>
        <div>
          <SecondaryButton size="lg"> Demo </SecondaryButton>
        </div>
      </div>
      <div>
        <div>
          <PrimaryButton size="sm"> Demo </PrimaryButton>
        </div>
        <div>
          <PrimaryButton> Demo </PrimaryButton>
        </div>
        <div>
          <PrimaryButton size="lg"> Demo </PrimaryButton>
        </div>
      </div>
      <Table>
        <tr>
          <td>Name</td>
          <td>Employee ID</td>
        </tr>
        <tr>
          <td>sunny</td>
          <td>rzp957</td>
        </tr>
      </Table>
      <ModalAndModalTrigger />
      <Tabs />
      <RadioButtons />
      <ProgressBar count={100} total={200} />
      <ListLoader isLoading={true}>Fetching employees</ListLoader>
    </main>
  );
}
