import { Box, CloseIcon, IconButton, useTheme } from '@razorpay/blade/components';
import React, { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface SideSheetProps {
  children?: JSX.Element | JSX.Element[];
  isOpen: boolean;
  onDismiss: () => void;
  position?: 'right' | 'bottom';
  hideCloseIcon?: boolean;
}

const SideSheetContainer = styled.div<
  Pick<SideSheetProps, 'isOpen' | 'position'> & { height: string }
>(
  ({ theme, isOpen, position, height }) => `
    display: flex;
    overflow-y: scroll;
    overscroll-behavior: contain;
    flex-direction: column;
    background-color: ${theme.colors.surface.background.gray.moderate};
    z-index: 9999;
    position: fixed;
    
    ${
      position === 'right'
        ? `
        right: ${isOpen ? '0' : '-400px'};
        height: 100%;
        width: 400px;
        top: 0;
        border-left: ${theme.border.width.thin}px solid ${
            theme.colors.surface.border.gray.normal
          };
        `
        : `
        bottom: ${isOpen ? '0' : `-${height}`};
        height: ${height};
        width: 100%;
        left: 0;
        border-top: ${theme.border.width.thin}px solid ${
            theme.colors.surface.border.gray.normal
          };
        `
    }
    transition: all ${theme.motion.duration.gentle}ms ${theme.motion.easing.emphasized};
`,
);

const ScreenBlocker = styled.div<Pick<SideSheetProps, 'isOpen'>>(
  ({ theme, isOpen }) => `
    visibility: ${isOpen ? 'visible' : 'hidden'};
    opacity: ${isOpen ? '1' : '0'};
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all ${theme.motion.duration.gentle}ms ${theme.motion.easing.emphasized};
`,
);

const SideSheet = ({
  children,
  isOpen,
  onDismiss,
  hideCloseIcon = false,
  position = 'right',
}: SideSheetProps) => {
  const closeCtaRef = useRef<HTMLButtonElement>(null);
  const { theme } = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState('auto');

  useEffect(() => {
    if (ref.current && position === 'bottom') {
      // +1 is added to remove overflow due to border top
      const height = ref.current.clientHeight + 1;
      setHeight(height + 'px');
    }
  }, [ref, position]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        closeCtaRef.current?.focus();
      }, theme.motion.duration.gentle);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, theme]);

  const handleOnKeypress: KeyboardEventHandler = (e) => {
    if (e.key === 'Escape') {
      onDismiss();
    }
  };

  const handleScreenBlockerClick = () => {
    onDismiss();
  };

  return (
    <>
      <ScreenBlocker isOpen={isOpen} onClick={handleScreenBlockerClick} />
      <SideSheetContainer
        tabIndex={isOpen ? 0 : -1}
        isOpen={isOpen}
        onKeyDown={handleOnKeypress}
        position={position}
        height={height}
        ref={ref}>
        <Box>
          {!hideCloseIcon && (
            <Box
              padding="spacing.6"
              position="sticky"
              zIndex={1}
              top={'0px'}
              backgroundColor="surface.background.gray.moderate">
              <IconButton
                icon={CloseIcon}
                accessibilityLabel="Close side sheet"
                onClick={onDismiss}
                ref={closeCtaRef}
              />
            </Box>
          )}
          <Box flexGrow="1">
            <Box
              padding={
                position === 'right' ? ['0px', 'spacing.8', 'spacing.8', 'spacing.8'] : 'spacing.0'
              }>
              {children}
            </Box>
          </Box>
        </Box>
      </SideSheetContainer>
    </>
  );
};

export { SideSheet };
