import { Box, Divider, Text } from '@razorpay/blade/components';
import React from 'react';
import Timeline from '../Timeline';
import { useWorkflowRequestDetails } from '../useWorkflowRequestDetails';
import { SalaryRevisionDetails } from '../../SalaryRevisionDetails';
import { ErrorResultsTableView } from '../../ErrorResultsTableView';
import { useWorkflowDetailsView } from 'components/ApprovalWorkflows/MyRequests/useWorkflowDetailsView';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const Completed = () => {
  const { workflowRequestId } = useWorkflowDetailsView();
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];
  const { data, isLoading, isFetching, refetch, isError } = useWorkflowRequestDetails({
    requestId: Number(workflowRequestId),
    isGraphQLEnabled,
  });
  const isTimelineLoading = isLoading || isFetching;

  if (isError) {
    return (
      <ErrorResultsTableView
        onRetry={refetch}
        description={<Box textAlign="center">Couldn’t fetch request details.</Box>}
        isRetrying={isFetching}
      />
    );
  }

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.7">
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text weight="semibold" color="surface.text.gray.muted">
          Completed
        </Text>
        <Text variant="caption" color="surface.text.gray.disabled" size='small'>
          Salary Revision
        </Text>
      </Box>
      <Timeline workflowRequestDetails={data} isLoading={isTimelineLoading} />
      <Divider width="100%" />
      <SalaryRevisionDetails workflowRequestDetails={data} isLoading={isTimelineLoading} />
    </Box>)
  );
};

export { Completed };
