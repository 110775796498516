import closeIcon from 'assets/icon--close-grey.svg';
import { SecondaryButton } from 'components/ui/Button';
import { Input } from 'components/ui/Form/Input';
import { Option, Select } from 'components/ui/Form/Select';
import { Table } from 'components/ui/Table';
import React from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import {
  DeductFrom,
  DeductionComponent,
  DeductionType
} from '../utils/types';
import crossIcon from './../../../assets/icons/red_cross.png';
import styles from './index.module.scss';

const EditDeductions = ({
  deductions,
  setNonLOPDeductions,
}: {
  deductions: DeductionComponent;
  setNonLOPDeductions: (nonLOPDeductions: DeductionComponent['non_lop_deductions']) => void;
}) => {
  const onDeductionsChange = (idx: number, key: string, value: string) => {
    const nonLOPDeductions = [...deductions.non_lop_deductions];
    if (key === 'amount') {
      nonLOPDeductions[idx][key] = parseInt(value);
    } else {
      nonLOPDeductions[idx][key] = value;
    }
    setNonLOPDeductions(nonLOPDeductions);
  };

  const onAdd = () => {
    const nonLOPDeductions = [...deductions.non_lop_deductions];
    nonLOPDeductions.push({
      name: '',
      amount: 0,
      deduct_from: DeductFrom.GROSS_PAY,
      lop_days: '0',
      type: DeductionType.TYPE_MANUAL,
    });
    setNonLOPDeductions(nonLOPDeductions);
  };

  const onDelete = (idx: number) => {
    const nonLOPDeductions = [...deductions.non_lop_deductions];
    nonLOPDeductions.splice(idx, 1);
    setNonLOPDeductions(nonLOPDeductions);
  };

  const onHoverCross = (idx: number) => {
    if (idx >= 2) {
      const row = document.getElementById(`deduction-row-${idx}`);
      row?.classList.add(styles['run-payroll-deduction-row-hover']);
    }
  };

  const onMouseoutOfCross = (idx: number) => {
    if (idx >= 2) {
      const row = document.getElementById(`deduction-row-${idx}`);
      row?.classList.remove(styles['run-payroll-deduction-row-hover']);
    }
  };

  return (
    <>
      <Table
        className={
          styles['run-payroll-edit-deduction-table'] +
          ' border border-solid border-surfaceBorderGrayMuted'
        }
        showAlternateColors={false}>
        <thead>
          <tr>
            <th className={styles['run-payroll-edit-model-deduction-label-column']}>Label</th>
            <th className={styles['run-payroll-edit-model-deduction-amount-column']}>Amount</th>
            <th>
              <a
                href="https://intercom.help/XPayroll/en/articles/8512408-deductions-loss-of-pay-days"
                target="_blank"
                rel="noreferrer">
                Deduct From?
              </a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {deductions.non_lop_deductions.map((deductionItem, idx) => {
            return (
              <tr key={idx} id={`deduction-row-${idx}`}>
                <td>
                  {
                    <Input
                      type="text"
                      id={`deduction-name-${idx}`}
                      data-testid={`deduction-${deductionItem.name}-name`}
                      value={deductionItem?.name ?? ''}
                      placeholder="Eg. Laptop recovery"
                      onChange={(event) => {
                        onDeductionsChange(idx, 'name', event.target.value);
                      }}
                    />
                  }
                </td>
                <td>
                  <Input
                    type="number"
                    data-testid={`deduction-${deductionItem.name}-value`}
                    className={styles['run-payroll-edit-number']}
                    id={`deduction-amount-${idx}`}
                    value={deductionItem?.amount > 0 ? deductionItem?.amount : ''}
                    onChange={(event) => {
                      onDeductionsChange(idx, 'amount', event.target.value);
                    }}
                  />
                </td>
                <td>
                  <Select
                    name="deduct-from"
                    data-testid={`deduction-${deductionItem.name}-deduct-from`}
                    value={deductionItem?.deduct_from ?? ''}
                    disabled={false}
                    id={`deduct-from-${idx}`}
                    onChange={(event) => {
                      onDeductionsChange(idx, 'deduct_from', event.target.value);
                    }}>
                    <Option key={'gross-pay'} value={DeductFrom.GROSS_PAY}>
                      Gross Pay
                    </Option>
                    <Option key={'net-pay'} value={DeductFrom.NET_PAY}>
                      Net Pay
                    </Option>
                  </Select>
                </td>
                <td
                  onMouseOver={() => {
                    onHoverCross(idx);
                  }}
                  onMouseOut={() => {
                    onMouseoutOfCross(idx);
                  }}>
                  {idx >= 2 && (
                    <>
                      <img
                        className={styles['run-payroll-deduction-red-cross']}
                        src={getStaticMediaUrl(crossIcon)}
                        alt=""
                        onClick={() => onDelete(idx)}
                      />
                      <img
                        className={styles['run-payroll-deduction-grey-close']}
                        src={getStaticMediaUrl(closeIcon)}
                        alt=""
                        onClick={() => onDelete(idx)}
                      />
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {deductions.non_lop_deductions.length < 10 && (
        <SecondaryButton
          onClick={onAdd}
          disabled={false}
          className={styles['run-payroll-new-deduction-button']}
          type="button">
          + New deduction
        </SecondaryButton>
      )}
    </>
  );
};

export default EditDeductions;
