import React from 'react';
import { ZaggleIntroSection } from './ZaggleIntroSection';
import { ZaggleIntroHowDoesItWorkItem } from './ZaggleIntroHowDoesItWorkItem';
import zaggleWallet from '../../../../assets/zaggleWallet.svg';
import zaggleCard from '../../../../assets/zaggleCard.svg';
import zaggleShopping from '../../../../assets/zaggleShopping.svg';

export function ZaggleHowDoesItWorkSection() {
  return <ZaggleIntroSection heading={'How does it work?'}>
    <ul className={'flex flex-col md:flex-row'}>
      <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleWallet}
                                    description={'Declare your monthly expenses into each wallets like fuel, meal etc.'} />
      <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleCard}
                                    description={'Get the declared amount in your Zaggle card on your payroll date.'} />
      <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleShopping}
                                    description={'Get the declared amount in your Zaggle card on your payroll date.'} />
    </ul>
  </ZaggleIntroSection>;
}