import React, { ReactElement, RefObject } from 'react';

import { DispatchAddress } from './../../utils/init';
import { states } from 'constants/address';
import { getCity, getStateCities } from 'utils/Address';
import { Input, InputElement } from 'components/ui/Form/Input';
import { Select, Option } from 'components/ui/Form/Select';

const AddressForm = ({ cardDeliveryDetails, setDispatchAddress, isPending, addressFormRef }: {
    cardDeliveryDetails: DispatchAddress;
    setDispatchAddress: (address: DispatchAddress) => void;
    isPending: boolean;
    addressFormRef: RefObject<HTMLFormElement>
}): ReactElement => {
    const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let value = event.currentTarget.value;
        const propName = event.target.name;
        if(propName=="city") {
            value = getCity(cardDeliveryDetails.state, value)
        } else if(propName=="pinCode") {
            value = !isNaN(+value) ? value : "";
        }
        setDispatchAddress({
            ...cardDeliveryDetails,
            [propName]: value,
            ...(propName==="state" && {city: ""})
        });
    }

    const {address, state, city, pinCode, fullName, phoneNumber} = cardDeliveryDetails;
    return (
        <div className='py-8 pb-16'>
            <form id="zaggle-delivery-address-form" ref={addressFormRef}>
                <div className='font-bold text-4xl pb-8'>Confirm Organization Address</div>
                <InputElement required label="Address" className="my-auto mt-4 mb-10">
                    <Input
                    type="text"
                    maxLength={255}
                    value={address ?? ""}
                    required
                    name="address"
                    onChange={setDetails}
                    disabled={isPending}
                    placeholder="Address"
                    
                    />
                </InputElement>
                <InputElement required label="State" className="my-auto mt-10">
                    <Select
                    required
                    name='state'
                    onChange={setDetails}
                    value={state ?? ""}
                    disabled={isPending}
                    >
                    <Option value=''>Select State</Option>
                    {states.map((state) => (
                        <Option key={state.value} value={state.name}>
                            {state.name}
                        </Option>
                    ))}
                    </Select>
                </InputElement>
                <InputElement required label="City" className="my-auto">
                    <Select
                    required
                    name='city'
                    onChange={setDetails}
                    value={city ?? ""}
                    disabled={isPending}
                    >
                    <Option value=''>Select City</Option>
                    {getStateCities(state).map((cty, index) =>(
                        <Option key={index} value={cty}>
                        {cty}   
                        </Option>),
                    )}
                    </Select>
                </InputElement>
                <InputElement required label="Pincode" className="my-auto">
                    <Input
                    type="text"
                    maxLength={6}
                    value={pinCode ?? ""}
                    required
                    name="pinCode"
                    onChange={setDetails}
                    placeholder="Pin Code"
                    disabled={isPending}
                    />
                </InputElement>
                <div className='pb-8 pt-20'>
                    <div className='font-bold text-4xl'>Confirm SPOC Details</div>
                    <div className='font-normal text-2xl pt-5'>
                        The SPOC will be contacted for assistance during card delivery 
                    </div>
                </div>
                <InputElement required label="Full Name" className="my-auto mt-4 mb-10">
                    <Input
                    type="text"
                    maxLength={255}
                    value={fullName ?? ""}
                    required
                    name="fullName"
                    onChange={setDetails}
                    disabled={isPending}
                    placeholder="Full Name"
                    />
                </InputElement>
                <InputElement required label="Phone Number" className="my-auto mb-10">
                    <Input
                    type="text"
                    value={phoneNumber ?? ""}
                    required
                    name="phoneNumber"
                    onChange={setDetails}
                    disabled={isPending}
                    placeholder="eg. 9810012345" 
                    pattern="^\d{10}$"
                    title="Phone number should be of 10 digits and numbers only."
                    />
                </InputElement>
            </form>
        </div>
    )
}

export default AddressForm;