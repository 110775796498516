import React from 'react';
import { ActionItemsCardDataType } from './ActionItemsCardList';
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import ActionItemsCardStatus from './ActionItemsCardStatus';
import styles from './../index.module.scss';
import { ActionItems } from '../../../../../constants/actionItems';
import { ReactElement } from 'react';
import { contentArray } from './ActionItemsCardList';
import { KYC_STATUS } from '../../../../../constants/flexibenefits';

export const ActionItemsCard = ({
    className,
    actionItemsCard,
    flexiDetails,
    getContent,
    getButton
}: {
    className?: string;
    actionItemsCard?: ActionItemsCardDataType;
    flexiDetails?: GetOrgFlexiPartnerDetailsSchemaContract;
    getContent: (id : ActionItems | undefined, content : contentArray | any, status? : KYC_STATUS) => ReactElement;
    getButton: (actionItemsCard? : ActionItemsCardDataType, flexiDetails? : GetOrgFlexiPartnerDetailsSchemaContract) => ReactElement;
}) => {

    return (
        <div className={`${styles['action-item-card']} ${className ? className : ''}`}
        id = {actionItemsCard?.id ? `action-items-card-${actionItemsCard?.id}` : undefined}  >
            <div className={`${styles['action-item-card-left-block']}`}>
                <div className={`${styles['action-item-card-left-block-title']}`}>
                    {actionItemsCard?.title}
                    <ActionItemsCardStatus card={actionItemsCard} status={flexiDetails?.kycData.status} />
                </div>
                <div className={`${styles['action-item-card-left-block-content']}`} >
                    { getContent(
                        actionItemsCard?.id ,
                        actionItemsCard?.content, 
                        actionItemsCard?.id == ActionItems.KYC_VERIFICATION ? flexiDetails?.kycData.status : undefined
                        )
                    }
                </div>
            </div>
            <div className={`${styles['action-item-card-right-block']}`}>
                { getButton(actionItemsCard,flexiDetails) }
            </div>
        </div>
    );
}

export default ActionItemsCard;