import React from 'react';
import {
  Box,
  Heading,
  Text,
  Dropdown,
  SelectInput,
  DropdownOverlay,
  ActionList,
  ActionListItem,
} from '@razorpay/blade/components';
import { BladeProPillActionListItem } from '../BladeProPill/index';

export interface DropdownSettingsProps {
  label: string;
  name?: string;
  value?: string | string[];
  onChange?: ({ name, values }: { name?: string | undefined; values: string[] }) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  listItemArray: { title: string; value: string; isProOption?: boolean; hasProPlan?: boolean }[];
  heading?: string;
  description?: string;
  alertText?: string;
}

const DropdownSettingsCard = ({
  label,
  name,
  value,
  onChange,
  isRequired,
  isDisabled,
  placeholder,
  heading,
  description,
  alertText,
  listItemArray,
}: DropdownSettingsProps) => {
  return (
    <Box
      gap={'spacing.6'}
      display={'flex'}
      flexDirection={'column'}
      borderWidth="thin"
      borderRadius="medium"
      padding="spacing.7"
      borderColor='surface.border.gray.subtle'
      backgroundColor="surface.background.gray.moderate">
      <Box gap={'spacing.2'} display={'flex'} flexDirection={'column'}>
        <Text weight="semibold" color="surface.text.gray.normal">
          {heading}
        </Text>
        <Text size="small" color="surface.text.gray.muted">
          {description}
        </Text>
      </Box>
      <Box width={'294px'}>
        <Dropdown selectionType="single">
          <SelectInput
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            isRequired={isRequired}
            isDisabled={isDisabled}
          />
          <DropdownOverlay>
            <ActionList>
              {listItemArray.map(({ title, value, isProOption, hasProPlan }) => (
                <ActionListItem
                  title={title}
                  value={value}
                  isDisabled={isProOption && !hasProPlan}
                  titleSuffix={isProOption && !hasProPlan ? BladeProPillActionListItem : undefined}
                />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
      </Box>
      {alertText && (
        <Text variant={'caption'} size="small" color={'interactive.text.positive.subtle'}>
          {alertText}
        </Text>
      )}
    </Box>
  );
};

export default DropdownSettingsCard;
