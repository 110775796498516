import React, { ReactElement } from "react";
import styles from './../index.module.scss';
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { WarningAlertYellow } from "components/ui/Alert";
import { KYC_STATUS } from 'constants/flexibenefits';


const KYCVerificationHeader = ({data} : {data:GetOrgFlexiPartnerDetailsSchemaContract} ): ReactElement => {

    return (
        <>
            {data?.kycData.status === KYC_STATUS.STATUS_ON_HOLD && 
            (<WarningAlertYellow className="mb-4">{'There were some issues with the document(s) uploaded.'}</WarningAlertYellow>)}

            <div className='space-y-8 mb-4 centered font-bold text-4xl'>
                KYC Verification
            </div>
            <div className={`${styles['header-content']}` + ' mb-8'}>
                Upload your KYC documents. It will be sent to Zaggle and the associated bank partner for verification.
            </div>
        </>
    )
    
}

export default KYCVerificationHeader;