import { createContext, useContext } from 'react';
import { OrderConfig } from '../utils/types';

export interface RunPayrollPaginationContextType {
  page: number;
  limit: number;
  setPage: (page: number) => void;
  goNextPage: () => void;
  goPrevPage: () => void;
  order: OrderConfig|null;
  orderBy: (key: 'ORDER_BY_NAME' | 'ORDER_BY_CANCELLED') => void;
}

export const RunPayrollPaginationContext = createContext<RunPayrollPaginationContextType | null>(null);

export const useRunPayrollPaginationContext = () => {
  return useContext(RunPayrollPaginationContext);
};