import styled from 'styled-components';

export const RightImg = styled.img`
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
`;

export const LeftImg = styled.img`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  mix-blend-mode: color-burn;
`;
