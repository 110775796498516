import { InterestType, LoanRecoveryOption } from './constants'
import { RecoveryFormProps } from './LoanRecovery';

export interface CreateLoanFormData {
    principal: string,
    interestType?: InterestType,
    rateOfInterest: string,
    perquisites: string,
    tenure: string,
    emiStartFrom: string,
    remarks: string,
}

export const getInitialData = (initialData?: CreateLoanFormData): CreateLoanFormData => ({
    principal: initialData?.principal ?? '',
    interestType: initialData?.interestType ?? InterestType.FLAT_INTEREST,
    rateOfInterest: initialData?.rateOfInterest ?? '',
    perquisites: initialData?.perquisites ?? '',
    tenure: initialData?.tenure ?? '',
    emiStartFrom: initialData?.emiStartFrom ?? '',
    remarks: initialData?.remarks ?? '',
});

export const getInitialRecoveryFormData = (initialData?: RecoveryFormProps): RecoveryFormProps => (
    {
        recoveryType: initialData?.recoveryType ?? LoanRecoveryOption.FULL_RECOVERY,
        recoveryAmount: initialData?.recoveryAmount ?? ''
    }
)