import {
  ActionList,
  ActionListItem,
  Box,
  Divider,
  Dropdown,
  DropdownOverlay,
  SelectInput,
  Text,
  UserCheckIcon,
} from '@razorpay/blade/components';
import React from 'react';
import RazorpayXLogo from '../../../../../assets/razorpayXMiniLogo.svg';
import { capitalizeFirstLetter } from 'utils/Strings';
import styled from 'styled-components';
import { NamePill } from 'components/ApprovalWorkflows/CreateWorkflow/components/NamePill';
import { getNamesInSentence } from '../../utils';

export type EmployeeContractorUnion = 'employee' | 'contractor';

export interface EmployeeType {
  id: string;
  label: string;
  selectedAs?: EmployeeContractorUnion;
}

export interface EmployeeTypesMappingCardProps {
  knitHrmsAppName: string;
  mapTo: EmployeeContractorUnion;
  peopleTypes: EmployeeType[];
  onChange: (selectedTypes: string[]) => void;
}

const MappingTypeTag = styled.div(
  ({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing[2]}px;
    padding: ${theme.spacing[4]}px ${theme.spacing[5]}px;
    border-radius: ${theme.border.radius.medium}px;
    border-width: ${theme.border.width.thin}px;
    border-color: ${theme.colors.surface.border.gray.normal};
    border-style: dashed;
    margin-top: 14px;
`,
);

const EmployeeTypesMappingCard = ({
  mapTo,
  peopleTypes,
  onChange,
  knitHrmsAppName,
}: EmployeeTypesMappingCardProps) => {
  const selectedTypes = peopleTypes.filter(({ selectedAs }) => selectedAs === mapTo);
  const selectedTypesLabels = selectedTypes.map(({ label }) => label);
  const selectedTypesIds = selectedTypes.map(({ id }) => id);
  const showFootnote = selectedTypes.length > 0;

  const handleChangeInMultiSelect = ({ values }: { name?: string; values: string[] }) => {
    onChange(values);
  };

  const handleNamePillClose = (id: string) => {
    onChange(selectedTypesIds.filter((selectedId) => selectedId !== id));
  };

  return (
    (<Box
      display="flex"
      flexDirection="column"
      borderRadius="medium"
      borderWidth="thin"
      borderColor="surface.border.gray.normal">
      <Box display="flex" alignItems="center" gap="spacing.4" padding={['spacing.5', 'spacing.7']}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="spacing.9"
          height="spacing.9"
          backgroundColor="surface.background.gray.moderate"
          borderRadius="medium">
          <UserCheckIcon size="large" color="feedback.icon.information.intense" />
        </Box>
        <Text weight="semibold">
          Select type from {knitHrmsAppName} which you want to map as {capitalizeFirstLetter(mapTo)}{' '}
          in XPayroll
        </Text>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing.5"
        padding={['spacing.8', 'spacing.7']}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} maxWidth="344px">
            <Dropdown selectionType="multiple">
              <SelectInput
                label={`Select Type from ${knitHrmsAppName}`}
                placeholder="Select types from dropdown"
                onChange={handleChangeInMultiSelect}
                value={selectedTypesIds}
              />
              <DropdownOverlay>
                <ActionList>
                  {peopleTypes.map(({ label, id, selectedAs }, index) => (
                    <ActionListItem
                      key={label}
                      title={label}
                      value={id}
                      isDisabled={selectedAs !== undefined && selectedAs !== mapTo}
                    />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          </Box>
          <Divider dividerStyle="dashed" marginTop="spacing.5" />
          <MappingTypeTag>
            <img src={RazorpayXLogo} alt="razoprpayx logo" />
            <Text size="medium" weight="semibold">
              {capitalizeFirstLetter(mapTo)}
            </Text>
          </MappingTypeTag>
        </Box>
        <Box display="flex" gap="spacing.3" flexWrap="wrap">
          {selectedTypes.map(({ label, id }, index) => (
            <NamePill
              name={label}
              onClose={() => {
                handleNamePillClose(id);
              }}
              key={id}
              showCloseIcon
            />
          ))}
        </Box>
      </Box>
      {showFootnote && (
        <>
          <Divider />
          <Box padding={['spacing.3', 'spacing.7']}>
            <Text variant="caption" color="surface.text.gray.subtle" size='small'>
              ⚡️ {getNamesInSentence(selectedTypesLabels)} will be treated as{' '}
              {capitalizeFirstLetter(mapTo)} in RazorpayX Payroll
            </Text>
          </Box>
        </>
      )}
    </Box>)
  );
};

export { EmployeeTypesMappingCard };
