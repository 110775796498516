import KYC from 'constants/kyc';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { KycDocumentsContext } from '../KycDocumentsContext';
import {
  DocumentStatus,
  OrgFields,
  addressProofGroups,
  bvsDocumentTypes,
  documentsBankAccountVerificationManualProof,
  orgTypes,
} from '../constants';
import {
  AuthorisedSignatoryDetailsDocuments,
  BvsDocumentTypes,
  KycDocumentsContextValues,
  ManualBankDocuments,
  OrgType,
} from '../types';
import { getAddressProofSelectedDocumentType } from '../utils';

interface UseKycValidationsProps {
  kycStatus?: number | null;
  isSelfServeP3M1Enabled?: boolean;
}

export const useKycValidations = ({
  kycStatus = KYC.STATUS_NOT_INITIATED,
  isSelfServeP3M1Enabled,
}: UseKycValidationsProps) => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const orgType = (org?.type || orgTypes.PRIVATE_LIMITED) as OrgType;
  const { kycValues } = useContext(KycDocumentsContext);

  const getCompanyDetailsDocsToCheck = () => {
    let docs = [];
    if (orgType === orgTypes.PRIVATE_LIMITED) {
      docs.push(...OrgFields[orgType].companyDetailsFields);
    } else if (orgType === orgTypes.SOLE_PROP) {
      docs.push(...OrgFields[orgType].companyDetailsFields);
    } else if (orgType === orgTypes.PARTNERSHIPS) {
      docs.push(...OrgFields[orgType].companyDetailsFields);
    } else if (orgType === orgTypes.TRUST) {
      docs.push(...OrgFields[orgType].companyDetailsFields);
    }
    const identityProofName = OrgFields[orgType].isIdentityProofSelectionRequired
      ? kycValues.companyDetails.identityProof?.name
      : '';
    if (identityProofName) {
      docs.push(identityProofName);
    }

    return docs as BvsDocumentTypes[];
  };

  const getAuthorisedSignatoryDetailsDocsToCheck = () => {
    type Docs = keyof AuthorisedSignatoryDetailsDocuments;
    const docs: Docs[] = [];
    const addressProofName = kycValues.authorizedSignatoryDetails.addressProof?.name;
    const selectedAddressProofGroup = getAddressProofSelectedDocumentType(addressProofName);
    if (selectedAddressProofGroup) {
      docs.push(...addressProofGroups[selectedAddressProofGroup].items);
    }

    // Not asking to submit auth signatory personal PAN as this is verified in company verification in self-serve phase 3
    if(!isSelfServeP3M1Enabled){
      docs.push(bvsDocumentTypes.PERSONAL_PAN);
    }
    return docs;
  };

  const getBankVerificationDetailsAdded = (kycValues: KycDocumentsContextValues) => {
    let isBankAccountDetailsAdded = false;
    let isManualBankProofAdded = false;
    let isBankAccountVerified = false;
    let isErrorInPennyTesting = false;

    const { bankVerificationDetails } = kycValues;
    const bankAccountProofName = bankVerificationDetails.proofType?.name;
    const isProofManual = bankAccountProofName
      ? documentsBankAccountVerificationManualProof.includes(
          bankAccountProofName as ManualBankDocuments,
        )
      : false;
    let isMethodPennyTesting = bankVerificationDetails.mode === 'auto';
    if (isProofManual) {
      if (bankAccountProofName) {
        const { saved_filename, file, status } =
          bankVerificationDetails[bankAccountProofName] || {};
        const isFileSavedOrAdded = saved_filename || file?.name;
        isBankAccountDetailsAdded = !!isFileSavedOrAdded;
        isManualBankProofAdded = !!isFileSavedOrAdded;
        if (isFileSavedOrAdded) {
          isBankAccountVerified = status === DocumentStatus.VERIFIED;
        }
      }
    } else if (isMethodPennyTesting) {
      isBankAccountDetailsAdded =
        bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status === DocumentStatus.VERIFIED;
      isBankAccountVerified =
        bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status === DocumentStatus.VERIFIED;
      isErrorInPennyTesting =
        bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status !== DocumentStatus.VERIFIED;
    } else {
      isMethodPennyTesting = true;
    }
    return {
      isBankAccountDetailsAdded,
      isMethodPennyTesting,
      isBankAccountVerified,
      isErrorInPennyTesting,
      isManualBankProofAdded,
    };
  };

  const validate = () => {
    let isVerifyAvailable = true;
    let isErrorInBankDetails = false;
    let isErrorInKycDocuments = false;
    let isKycDocumentNotAdded = false;
    let isBankDocumentNotAdded = false;
    let isAnyDocumentReuploaded = false;
    const { authorizedSignatoryDetails, companyDetails } = kycValues;
    const addressProofName = authorizedSignatoryDetails.addressProof?.name;

    if (!addressProofName) {
      isVerifyAvailable = false;
      isKycDocumentNotAdded = true;
    }

    const authorisedSignatoryDocs = getAuthorisedSignatoryDetailsDocsToCheck();

    for (let fieldName of authorisedSignatoryDocs) {
      const { saved_filename, file, status } = authorizedSignatoryDetails[fieldName] || {};
      const isFileSavedOrAdded = saved_filename || file?.name;
      const isFileSaved = saved_filename;
      if (kycStatus === KYC.STATUS_NOT_INITIATED) {
        isKycDocumentNotAdded = isKycDocumentNotAdded || !isFileSavedOrAdded;
        if (isFileSavedOrAdded) {
          if (status !== DocumentStatus.SUCCESS) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isKycDocumentNotAdded = true;
          }
        }
      } else if (kycStatus === KYC.STATUS_ON_HOLD) {
        isKycDocumentNotAdded = isKycDocumentNotAdded || !isFileSaved;
        if (isFileSaved) {
          isAnyDocumentReuploaded = isAnyDocumentReuploaded || status === DocumentStatus.SUCCESS;
          if (status === DocumentStatus.ERROR) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isErrorInKycDocuments = true;
          }
        }
      } else {
        isVerifyAvailable = false;
      }
    }

    let isProofTypeSelectionRequired = OrgFields[orgType].isIdentityProofSelectionRequired;

    const identityProofName = companyDetails.identityProof?.name;
    if (isProofTypeSelectionRequired && !identityProofName) {
      isVerifyAvailable = false;
      isKycDocumentNotAdded = true;
    }
    const companyDetailsDocs = getCompanyDetailsDocsToCheck();
    for (let fieldName of companyDetailsDocs) {
      const { saved_filename, file, status } = companyDetails[fieldName] || {};

      const isFileSavedOrAdded = saved_filename || file?.name;
      const isFileSaved = saved_filename;
      if (kycStatus === KYC.STATUS_NOT_INITIATED) {
        isKycDocumentNotAdded = isKycDocumentNotAdded || !isFileSavedOrAdded;
        if (isFileSavedOrAdded) {
          if (status !== DocumentStatus.SUCCESS) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isKycDocumentNotAdded = true;
          }
        }
      } else if (kycStatus === KYC.STATUS_ON_HOLD) {
        isKycDocumentNotAdded = isKycDocumentNotAdded || !isFileSaved;
        if (isFileSaved) {
          isAnyDocumentReuploaded = isAnyDocumentReuploaded || status === DocumentStatus.SUCCESS;
          if (status === DocumentStatus.ERROR) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isErrorInKycDocuments = true;
          }
        }
      } else {
        isVerifyAvailable = false;
      }
    }

    const { bankVerificationDetails } = kycValues;
    const bankAccountProofName = bankVerificationDetails.proofType?.name;
    const isProofManual = bankAccountProofName
      ? documentsBankAccountVerificationManualProof.includes(
          bankAccountProofName as ManualBankDocuments,
        )
      : false;
    const isMethodPennyTesting = bankVerificationDetails.mode === 'auto';
    if (isProofManual && bankAccountProofName) {
      const { saved_filename, file, status } = bankVerificationDetails[bankAccountProofName] || {};
      const isFileSavedOrAdded = saved_filename || file?.name;
      const isFileSaved = saved_filename;
      if (kycStatus === KYC.STATUS_NOT_INITIATED) {
        isBankDocumentNotAdded = isBankDocumentNotAdded || !isFileSavedOrAdded;
        if (isFileSavedOrAdded) {
          if (status !== DocumentStatus.SUCCESS) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isBankDocumentNotAdded = true;
          }
        }
      } else if (kycStatus === KYC.STATUS_ON_HOLD) {
        isBankDocumentNotAdded = isBankDocumentNotAdded || !isFileSaved;
        if (isFileSaved) {
          isAnyDocumentReuploaded = isAnyDocumentReuploaded || status === DocumentStatus.SUCCESS;
          if (status === DocumentStatus.ERROR) {
            // If the user has saved a file or selected a file
            // And the document status is not uploaded, dont allow to verify
            isVerifyAvailable = false;
            isErrorInBankDetails = true;
          }
        }
      } else {
        isVerifyAvailable = false;
      }
    } else if (isMethodPennyTesting) {
      if (kycStatus === KYC.STATUS_NOT_INITIATED) {
        isVerifyAvailable =
          isVerifyAvailable &&
          bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status ===
            DocumentStatus.VERIFIED;
        isErrorInBankDetails =
          isErrorInBankDetails ||
          bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status !==
            DocumentStatus.VERIFIED;
      } else if (kycStatus === KYC.STATUS_ON_HOLD) {
        isVerifyAvailable =
          isVerifyAvailable &&
          bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status ===
            DocumentStatus.VERIFIED;
        isErrorInBankDetails =
          isErrorInBankDetails ||
          bankVerificationDetails[bvsDocumentTypes.BANK_ACCOUNT]?.status !==
            DocumentStatus.VERIFIED;
      } else {
        isVerifyAvailable = false;
      }
    } else {
      isVerifyAvailable = false;
      isBankDocumentNotAdded = true;
    }
    isVerifyAvailable =
      isVerifyAvailable &&
      !isBankDocumentNotAdded &&
      !isKycDocumentNotAdded &&
      !isErrorInBankDetails &&
      !isErrorInKycDocuments;

    if (kycStatus === KYC.STATUS_PENDING) {
      isVerifyAvailable = false;
    }
    if (kycStatus === KYC.STATUS_ON_HOLD) {
      isVerifyAvailable = isAnyDocumentReuploaded && isVerifyAvailable;
    }

    return {
      isVerifyAvailable,
      isErrorInBankDetails,
      isErrorInKycDocuments,
      isKycDocumentNotAdded,
      isBankDocumentNotAdded,
      isAnyDocumentReuploaded,
    };
  };

  const values = {
    ...validate(),
    ...getBankVerificationDetailsAdded(kycValues),
  };

  return values;
};
