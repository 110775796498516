import React from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { useModal } from 'components/ui/Modals';
import { StandardSecondaryButton } from 'components/ui/Button';
import { toIndianCurrencyString } from 'utils/Numbers';
import { Additions, ADHOC_PAYMENTS_TYPE, ApplicableBonus, ArrearComponent } from '../utils/types';

import styles from './index.module.scss';
import {replaceHTMLSpecialChars} from "../../../utils/Strings";

const AdditionsDisplay = ({
  adhocAllowances,
  allowances,
  arrears,
  applicableBonus,
}: {
  adhocAllowances: Additions[];
  allowances: Additions[];
  arrears: ArrearComponent[];
  applicableBonus: ApplicableBonus[];
}) => {
  const { closeModal } = useModal();

  let additions = [...allowances, ...adhocAllowances, ...arrears, ...applicableBonus];

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Addition details
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <table className="w-full">
            <thead>
              <tr>
                <th>Label</th>
                <th>Amount</th>
              </tr>
            </thead>
            {additions?.length &&
              additions.map((addition) => {
                return (
                  <tr key={addition.name}>
                    <td>{replaceHTMLSpecialChars(`${addition.name} ${
                        addition.type === ADHOC_PAYMENTS_TYPE ? '*' : ''
                    }`)}</td>
                    <td>{toIndianCurrencyString(addition.amount ?? 0, true)}</td>
                  </tr>
                );
              })}
          </table>
          <br />
          {adhocAllowances?.length > 0 && <>* indicates one-time payment paid to employee.</>}
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardSecondaryButton onClick={() => closeModal()}>CLOSE</StandardSecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default AdditionsDisplay;
