import React, { ReactElement } from "react";

const AddressInfo = (): ReactElement => (
    <div className='space-y-8 mb-10'>
        <div className='font-bold text-5xl'>Physical card delivery address</div>
        <div className="mt-10">
        Please specify the address where you want the Zaggle SAVE card to be delivered. <strong>For faster deliveries, please choose Organization Address</strong>
        </div>
    </div>
)

export default AddressInfo;