import { FileUploadStatus } from 'components/ui/FileUpload/constants';
import { BulkUploadStatus } from 'constants/bulkUpload';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';

export const getStatusBasedOnBulkRecord = (
  bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null,
) => {
  let $status = FileUploadStatus.NOT_INITIATED;
  if (bulkUploadRecord) {
    switch (bulkUploadRecord.status) {
      case BulkUploadStatus.STATUS_VALIDATION_FAILED:
        $status = FileUploadStatus.FAILURE;
        break;

      case BulkUploadStatus.STATUS_VALIDATION_PENDING:
      case BulkUploadStatus.STATUS_VALIDATION_PROGRESS:
        $status = FileUploadStatus.UPLOADING;
        break;

      case BulkUploadStatus.STATUS_VALIDATION_SUCCESS:
        $status = FileUploadStatus.SUCCESS;
        break;
      default:
        $status = FileUploadStatus.NOT_INITIATED;
        break;
    }
  }
  return $status;
};
