import React from 'react';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';

import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmOnly?: boolean;
  className?: string;
  footerContainerClassName?: string;
};

const ConfirmModal = ({
  children,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  confirmOnly = false,
  className = 'p-10',
  footerContainerClassName=''
}: Props) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();

    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    closeModal();

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className={`${styles.Body} ${className}`}>
      <div>{children}</div>
      <div className={`flex justify-end ${footerContainerClassName}`}>
        {!confirmOnly && (
          <StandardSecondaryButton className="mr-6" onClick={handleCancel}>
            {cancelText}
          </StandardSecondaryButton>
        )}
        <StandardButton onClick={handleConfirm}>{confirmText}</StandardButton>
      </div>
    </div>
  );
};

export default ConfirmModal;
