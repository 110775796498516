import React, {useState,ReactElement} from 'react';
import styles from './../index.module.scss'
import { PrimaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import { getStaticMediaUrl } from 'utils/Urls';
import zaggleModalKYCInitiated from '../../../../../assets/zaggle/zaggleModalKYCInitiated.svg';
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import FileInputBlock from './FileInputBlock';
import { Input,InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import { useSelector } from 'react-redux';
import api from '../../../../../api';
import FileSubmitAlert from './FileSubmitAlert';
import ExternalLink from 'components/ui/ExternalLink';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import sampleCancelledCheque from 'assets/partnerFlexiBenefits/sampleCancelledCheque.pdf';
import sampleGstin from 'assets/partnerFlexiBenefits/sampleGstin.pdf'
import { KYC_STATUS } from 'constants/flexibenefits';
import { GSTIN_REGEX } from 'utils/regexes';

const KYCVerificationDocumentBody = ({data} : {data:GetOrgFlexiPartnerDetailsSchemaContract}): ReactElement => {
    
    const [selectedGSTINFile, setSelectedGSTINFile] = useState<File | null>(null);
    const [selectedCancelledChequeFile, setSelectedCancelledChequeFile] = useState<File | null>(null);
    const [isGSTINFilePicked, setIsGSTINFilePicked] = useState(false);
    const [isCancelledChequeFilePicked, setIsCancelledChequeFilePicked] = useState(false);
    const [gstinNumber, setGstinNumber] = useState<string>('');
	const [error, setError] = useState<string | null>(null);
    const { openModal, closeModal } = useModal();
	const [isWarning, setIsWarning] = useState<boolean>(false);
	const history = useHistory();

    function gstinChangeHandler(event : React.ChangeEvent<HTMLInputElement>){
        setSelectedGSTINFile( event.target.files? event.target.files[0] : null);
        setIsGSTINFilePicked(true);
    }

    function cancelledChequeChangeHandler(event : React.ChangeEvent<HTMLInputElement>){
        setSelectedCancelledChequeFile(event.target.files? event.target.files[0] : null);
        setIsCancelledChequeFilePicked(true);
    };

    const orgId = useSelector(loggedInUserSelectors.organizationID);

    const handleSubmission = () => {

        let formData = new FormData();

		if(data?.requiredKYCFields.requireGSTIN){
			if(isGSTINFilePicked && gstinNumber !== ''){

                formData.append('gstinNumber',isGSTINFilePicked? gstinNumber : '');
                if(isGSTINFilePicked && selectedGSTINFile) {
                    formData.append('gstinCertificateFile', selectedGSTINFile);
                }
	
			}
			else{

				setError(gstinNumber !== '' ? 'Please make sure you fill all the fields' : 'Incorrect GSTIN number entered');
				setIsWarning(true);
                return;
			}
		}

		if(data?.requiredKYCFields.requireCancelledCheque){
			if(isCancelledChequeFilePicked && selectedCancelledChequeFile){

				formData.append('cancelledChequeFile', selectedCancelledChequeFile);
	
			}
			else{
				setError('Please make sure you fill all the fields');
				setIsWarning(true);
                return;
			}
		}

        if(formData && orgId && 
            (data?.requiredKYCFields.requireGSTIN ? (formData.has('gstinNumber') && formData.has('gstinCertificateFile')) : true) &&
            (data?.requiredKYCFields.requireCancelledCheque ? formData.has('cancelledChequeFile') : true )) {
            
            openInitiatedKYCModal();
            return api.flexiPartnerDetails.updateOrgFlexiPartnerKYC(orgId,formData);
            
        } else {
            setError('Please make sure you fill all the fields');
			setIsWarning(true);
            return;
        }

    };

    function openInitiatedKYCModal() {

        openModal(
          <>
            <div
              className={`flex flex-col items-center flex-grow pt-8 mb-12 ${styles['KYCInitModal']}`}>
              <div className="flex flex-col items-center px-16 py-10">
                <img src={getStaticMediaUrl(zaggleModalKYCInitiated)} />
                <div
                  className={`text-4xl text-white-o-80 font-bold text-white mb-8 mt-4 text-center ${styles['KYCInitModalHeading']}`}>
                  Your KYC verification process is initiated
                </div>
                <p className="text-center px-8 mb-11 text-1.7xl text-white-o-70">
                Any communication regarding failed KYC verification will happen by email to the point of contact.
                </p>
              </div>
              <PrimaryButton onClick={ () => 
					{
						closeModal();
						history.push(routePaths.partnerFlexiBenefits.admin.actionItems);
					}
				} className="text-1.5xl">
                GOT IT
              </PrimaryButton>
            </div>
          </>,
        );
    }

    return(
        <>
            <div> 
				
				<FileSubmitAlert message= {error} isWarning={isWarning} />
                { data?.requiredKYCFields.requireGSTIN &&  
                <div className="w-2/3"> {/* CSS Hack, to be fixed when file upload component is built */}

                <InputElement required className={`my-auto mt-4 mb-10 ${data?.requiredKYCFields.requireGSTIN ? '' : 'hidden'} `}>
                    <label htmlFor="gstin-number" className='mb-6'>GSTIN Number *</label>
                    <Input
                        type="text"
                        maxLength={15}
                        name="gstin-number"
                        id="gstin-number"
                        onChange={(event) => setGstinNumber((event.target.value).match(GSTIN_REGEX) == null ? '' : event.target.value)}
                        placeholder="Enter your company's GSTIN number"
                        customErrorMessage="Invalid GSTIN"
                        required
                    />
                    <InputInfo >
                        Enter a valid GSTIN to claim GST credit on our invoices. 
                        <ExternalLink href='https://razorpay.com/gst-number-search' target="_blank" className="text-primary font-bold"> Details.</ExternalLink>
                    </InputInfo>
                </InputElement>
                </div>
                }

                <FileInputBlock
                    label='GSTIN Certificate'
                    changeHandler={gstinChangeHandler} 
                    condition={data?.requiredKYCFields.requireGSTIN}
                    id='gstin-certificate'
                    value={(isGSTINFilePicked && selectedGSTINFile)? selectedGSTINFile.name : null}
					sampleFile = {getStaticMediaUrl(sampleGstin)}
                    warningMessage= {(data?.kycData.status === KYC_STATUS.STATUS_ON_HOLD && 
                        data.kycData.documents.gstin.isApproved == false) ? (data.kycData.documents.gstin.comment) : null}
                />
                
                <FileInputBlock
                    label='Cancelled Cheque'
                    changeHandler={cancelledChequeChangeHandler} 
                    condition={data?.requiredKYCFields.requireCancelledCheque} 
                    id='cancelled-cheque'
                    value={(isCancelledChequeFilePicked && selectedCancelledChequeFile)? selectedCancelledChequeFile.name : null}
					sampleFile={getStaticMediaUrl(sampleCancelledCheque)}
                    warningMessage= {(data?.kycData.status === KYC_STATUS.STATUS_ON_HOLD && 
                        data.kycData.documents.cancelledCheque.isApproved == false) ? (data.kycData.documents.cancelledCheque.comment) : null}
                />


            </div>
            <div className={`${styles['note-card']}`}>
                <b>⚡ Proof of Business Identity</b> and <b>Proof of Business Work</b> uploaded during RazorpayX company onboarding will also be sent to Zaggle and the associated bank partner for verification.
            </div>
            <div>
                <PrimaryButton onClick={handleSubmission} className='text-1.7xl'>Request Verification</PrimaryButton>
            </div>
        </>
    )
    
}

export default KYCVerificationDocumentBody;