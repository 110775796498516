import React from 'react';
import styles from "./index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleSignatureDark from "../../../../../assets/jibble/signature--jibble-dark.svg";
import {Card} from "../../../../ui/Card";
import {jibbleIntroductionInformationData} from "../../data";
import {StandardButton, StandardSecondaryButton} from "../../../../ui/Button";
import iconFAQs from "assets/icons/icon_document.svg";
import iconSupport from "assets/icons/icon_support.svg"
import {routePaths} from "../../../../Routes/data";
import {useHistory} from "react-router-dom";

const OfferComponent = () => (
    <div className={styles['actionSection']}>
        <h1 className={styles['originalPrice']}>₹158/employee/month</h1>
        <p className={styles['xpayrollPrice']}>FREE for RazorpayX Payroll Pro customers 🤗</p>
    </div>
)

const JibbleIntroduction = () => {
    const history = useHistory();

    return (
        <div className={styles['container']}>
            <Card className={styles['posterCard']}>
                <span className={styles['iconBox']}>
                    <img src={getStaticMediaUrl(jibbleSignatureDark)} alt="Jibble"/>
                </span>
                <div>
                    <h1 className={styles['posterHeading']}>Jibble</h1>
                    <p className={styles['posterParagraph']}>Make attendance tracking effortless with Jibble - a cloud-based time and
                        {' '} attendance software that empowers users to clock in and out from anywhere</p>
                    <span className={styles['featurePill']}>Shift management</span>
                    <span className={styles['featurePill']}>Geofencing</span>
                    <span className={styles['featurePill']}>Performance management</span>
                    <span className={styles['featurePill']}>Learning and development</span>
                </div>
            </Card>
            <div className="flex">
                <div className={styles['infoSection']}>
                    {jibbleIntroductionInformationData.map(({heading, information}) => {
                        return (
                            <div className="mb-16">
                                <h1 className={styles['sectionHeading']}>{heading}</h1>
                                {information}
                            </div>
                        )
                    })}
                </div>
                <div className={styles['actionSection']}>
                    <OfferComponent/>
                    <StandardButton className={styles['integrateButton']} onClick={() => {
                        history.push(routePaths.settings.jibble.accountConfirmation)
                    }}>Start Integration</StandardButton>
                    <a href={"https://calendly.com/xpayroll-jibble/30min"} target={"_blank"} rel="noreferrer">
                        <StandardSecondaryButton className={styles['demoButton']}>Book a demo</StandardSecondaryButton>
                    </a>
                    <div className={styles['altAction']}>
                        <img className="mr-3" src={getStaticMediaUrl(iconFAQs)} alt=""/>
                        <span className={styles['imageLabel']}>FAQs</span>
                    </div>
                    <div className={styles['altAction']}>
                        <img className="mr-3" src={getStaticMediaUrl(iconSupport)} alt=""/>
                        <span className={styles['imageLabel']}>Contact support</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JibbleIntroduction;