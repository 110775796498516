import { Box, BoxProps, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';

type TileButtonProps = {
  title: string;
  icon: ReactNode;
  href?: string;
  backgroundColor?: BoxProps['backgroundColor'];
  onClick?: () => void;
};

const TileButton = ({
  title,
  icon,
  onClick,
  backgroundColor = 'surface.background.primary.subtle',
  href,
}: TileButtonProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!href && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <a href={href} onClick={handleClick} target={'_blank'} style={{ cursor: 'pointer' }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        backgroundColor={backgroundColor}
        minWidth={'150px'}
        borderRadius={'large'}
        paddingX={'spacing.5'}
        paddingY={'spacing.4'}
        gap={'spacing.3'}>
        {icon}
        <Text size="medium">{title}</Text>
      </Box>
    </a>
  );
};

export default TileButton;
