import React, { PropsWithChildren } from 'react';
import { StandardButton } from 'components/ui/Button';
import ExternalLink from 'components/ui/ExternalLink';
import { trackSegment } from 'utils/segment';

const ConnectToZoho = ({
  children,
  disabled,
  isPending,
  onClick,
}: PropsWithChildren<{ disabled?: boolean; onClick: () => void; isPending: boolean }>) => {
  return (
    <ExternalLink target="_self" href="/v2/api/zoho/connect">
      <StandardButton
        disabled={disabled}
        isPending={isPending}
        className="mr-0"
        onClick={() => {
          trackSegment('zoho.connect.click', { queue: true });
          onClick();
        }}>
        {children}
      </StandardButton>
    </ExternalLink>
  );
};

export default ConnectToZoho;
