import { FormProvider } from 'forms';
import React from 'react';
import EditDeductionsForm from './EditDeductionsForm';
import KnowMoreView from '../KnowMoreView/KnowMoreView';

const EditDeductionsView = () => {

  return (
    <FormProvider>
      <EditDeductionsForm />
      <KnowMoreView />
    </FormProvider>
  );
};

export default EditDeductionsView;
