import React, { FormEvent, useState } from 'react';
import {
  Box,
  Button,
  TextInput,
  TextArea,
  Dropdown,
  SelectInput,
  DropdownOverlay,
  ActionList,
  ActionListItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
} from '@razorpay/blade/components';
import { useSelector } from 'react-redux';
import { leaveAttendanceConfig } from 'reducers/loggedInUser/selectors';
import { generateStatusOptions } from './utils/leaveOptions';
import { TransformedAttendanceRecord } from './AttendanceHistory';
import { ATTENDANCE_STATUS } from './constants';
import { useMutation, useQueryClient } from 'react-query';
import attendance from 'api/attendance';
import { AppError } from 'utils/AppError';
import { ErrorsAlert } from 'components/ui/Form/Errors';
import { useForm, ValidationSchema } from 'forms';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

interface EditAttendanceProps {
  record: TransformedAttendanceRecord;
  onClose: () => void;
  userId: string | null;
}

type FormFields = {
  status: string[];
  checkIn: string;
  checkOut: string;
  remarks: string;
};

export const EditAttendance: React.FC<EditAttendanceProps> = ({ record, onClose, userId }) => {
  const leaveAttendanceConfiguration = useSelector(leaveAttendanceConfig);
  const statusOptions = generateStatusOptions(leaveAttendanceConfiguration);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const getInitialStatus = (record: TransformedAttendanceRecord): string => {
    switch (record.status) {
      case ATTENDANCE_STATUS.STATUS_PRESENT:
        return 'request-attendance';
      case ATTENDANCE_STATUS.STATUS_LEAVE:
        return `request-leave|${record.leaveType}`;
      case ATTENDANCE_STATUS.STATUS_HALF_DAY:
        return `request-half-leave|${record.leaveType}`;
      case ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE:
        return 'request-unpaid-leave';
      case ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY:
        return 'request-unpaid-half-leave';
      default:
        return 'request-attendance';
    }
  };
  const initialData = {
    status: [getInitialStatus(record)],
    checkIn: record.checkIn?.substring(0, 5) || '',
    checkOut: record.checkOut?.substring(0, 5) || '',
    remarks: record.remarks || '',
  };
  const { register, validate, formData, setIsSubmitting, isSubmitting } =
    useForm<FormFields>(initialData);
  const validationSchema: ValidationSchema<FormFields> = {
    status: (formData) => (formData.status ? null : 'Status is required'),
  };

  const updateMutation = useMutation(attendance.updateAttendance, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['attendance']);
      queryClient.invalidateQueries(['attendance-today']);
      toast.show({
        content: data.message,
        color: 'positive',
      });
      onClose();
    },
    onError: (error: typeof AppError) => {},
    onSettled: () => {
      setIsSubmitting(false);
    },
  });

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate(validationSchema)) {
      return;
    }

    if (!formData.status) {
      return;
    }

    const [statusType, leaveType] = formData.status[0].split('|');
    let status;

    switch (statusType) {
      case 'request-attendance':
        status = ATTENDANCE_STATUS.STATUS_PRESENT;
        break;
      case 'request-leave':
        status = ATTENDANCE_STATUS.STATUS_LEAVE;
        break;
      case 'request-half-leave':
        status = ATTENDANCE_STATUS.STATUS_HALF_DAY;
        break;
      case 'request-unpaid-leave':
        status = ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE;
        break;
      case 'request-unpaid-half-leave':
        status = ATTENDANCE_STATUS.STATUS_UNPAID_HALF_DAY;
        break;
    }

    if (!status) {
      toast.show({
        content: 'Invalid status',
        color: 'negative',
      });
      return;
    }

    const payload = {
      date: getFormattedDateValue({
        date: record.date.date,
        formatString: dateFormats.yearMonthDate,
      }),
      status,
      leave_type: leaveType ? parseInt(leaveType) : undefined,
      checkin: formData.checkIn || undefined,
      checkout: formData.checkOut || undefined,
      remarks: formData.remarks || undefined,
      user_id: userId ? parseInt(userId) : undefined,
    };

    setIsSubmitting(true);
    updateMutation.mutate(payload);
  };

  const deleteMutation = useMutation(attendance.deleteAttendance, {
    onSuccess: (data) => {
      setIsSubmitting(false);
      queryClient.invalidateQueries(['attendance']);
      queryClient.invalidateQueries(['attendance-today']);
      toast.show({
        content: data.message,
        color: 'positive',
      });
      onClose();
    },
    onError: (error: typeof AppError) => {},
  });

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (record.actualId) {
      setIsSubmitting(true);
      deleteMutation.mutate(record.actualId);
    }
    setShowDeleteModal(false);
  };

  return (
    <>
      <form onSubmit={handleUpdate}>
        {updateMutation.error && (
          <Box marginBottom="spacing.4">
            <ErrorsAlert errorResponse={updateMutation.error} />
          </Box>
        )}
        {deleteMutation.error && (
          <Box marginBottom="spacing.4">
            <ErrorsAlert errorResponse={deleteMutation.error} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap="spacing.4">
          <Dropdown selectionType="single">
            <SelectInput
              {...register('status', 'select')}
              label="Status"
              placeholder="Status"
              isRequired
            />
            <DropdownOverlay>
              <ActionList>
                {statusOptions.map((option) => (
                  <ActionListItem key={option.value} title={option.title} value={option.value} />
                ))}
              </ActionList>
            </DropdownOverlay>
          </Dropdown>

          <TextInput {...register('checkIn')} label="Check In" placeholder="hh:mm" />
          <TextInput {...register('checkOut')} label="Check Out" placeholder="hh:mm" />

          <TextArea {...register('remarks')} label="Remarks" placeholder="(optional)" />
        </Box>

        <Box display="flex" justifyContent="end" marginTop="spacing.4" gap="spacing.4">
          {!!record.actualId && !!record.peopleId && (
            <Button
              variant="primary"
              color="negative"
              onClick={handleDelete}
              isDisabled={isSubmitting}
              isLoading={deleteMutation.isLoading}>
              Delete
            </Button>
          )}
          <Button
            variant="tertiary"
            onClick={onClose}
            isDisabled={isSubmitting || deleteMutation.isLoading}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            isLoading={updateMutation.isLoading}
            isDisabled={deleteMutation.isLoading}>
            Update
          </Button>
        </Box>
      </form>

      <Modal isOpen={showDeleteModal} onDismiss={() => setShowDeleteModal(false)} size="small">
        <ModalHeader title="Delete Attendance Record" />
        <ModalBody>Are you sure you want to delete this attendance record?</ModalBody>
        <ModalFooter>
          <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              isLoading={deleteMutation.isLoading}>
              No
            </Button>
            <Button
              variant="primary"
              color="negative"
              onClick={confirmDelete}
              isLoading={deleteMutation.isLoading}>
              Yes
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  );
};
