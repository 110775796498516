import React from "react";
import {Card} from "../../../ui/Card";
import ExternalLink from "../../../ui/ExternalLink";

export function ScheduleCallSection() {
    return (<Card withTopBorder={true} className={'border-accent-negative'}>
        <div
            className={`text-1.92xl font-bold text-white text-center`}>Interested in setting up Zaggle for your organisation? 🙌
        </div>
        <div className={"text-center"}>Schedule a call with us! Our team of experts will help you setup Zaggle.</div>
        <br/>
        <br/>
        <div className={"text-center"}>
            {/* bit.ly link - https://calendly.com/xpayroll-zaggle/30min?month=2022-12&utm_medium=cta_bottom&utm_campaign=dashboard */}
            <ExternalLink
                className="mx-auto btn "
                href="http://bit.ly/3hA3if4"
                target="_blank"
                rel="noopener noreferrer">
                Schedule A Call
            </ExternalLink>
        </div>
    </Card>);
}