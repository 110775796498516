import React, { useState } from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import { Redirect, useHistory } from 'react-router-dom';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';
import { useMutation } from 'react-query';
import api from 'api';
import { showApiErrorNotification } from 'utils/Api';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextInput,
  Heading,
} from '@razorpay/blade/components';
import { InformationCard } from './components/InformationCard';
import { showSuccessToastNotification } from 'utils/ToastEvents';

const DisableIntegration = () => {
  const history = useHistory();
  const [confirmText, setConfirmText] = useState('');
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    retryLimitExhausted,
    invalidateKnitHrmsIntegrationQueryCache,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const disableHrmsIntegrationMutation = useMutation({
    mutationFn: api.knit.disableIntegration,
    onSuccess: (data) => {
      if (data.success) {
        invalidateKnitHrmsIntegrationQueryCache();
        showSuccessToastNotification({
          text: `Integration with ${hrmsAppMetadata.name} disabled successfully`,
          timeout: 2000,
        });
      }
    },
    onError: (error) => {
      showApiErrorNotification(error);
    },
  });
  const canProceed = confirmText === 'confirm';

  // redirecting to HRMS app home if integration is not active
  if (knitHrmsIntegrationQuery.isSuccess && !isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  // redirecting to sync-in-progress screen if employee data sync is in progress
  if (knitHrmsIntegrationQuery.isSuccess && isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.settings.syncInProgress} />;
  }

  const handleConfirmTextChange = ({ value }: { name?: string; value?: string }) => {
    setConfirmText(value ?? '');
  };

  const handleBackCtaClick = () => {
    history.goBack();
  };

  const handleNextCtaClick = () => {
    if (knitHrmsIntegrationQuery.data) {
      disableHrmsIntegrationMutation.mutate({
        hrms_provider: knitHrmsApp,
      });
    }
  };

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  return (
    (<IntegrationWindow
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={org?.name ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}>
      <Box display="flex" flexDirection="column" gap="spacing.9" maxWidth="592px">
        <Heading as="h2" size="xlarge" color="surface.text.gray.muted">
          Disable integration
        </Heading>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          <InformationCard type="notice" title="What will happen after integration is disabled?">
            <List variant="unordered">
              <ListItem key={1}>
                <ListItemText color="surface.text.gray.disabled">
                  Please note after disabling, employee information will not sync from{' '}
                  {hrmsAppMetadata.name} to RazorpayX Payroll.
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText color="surface.text.gray.disabled">
                  Employee information can be updated in RazorpayX Payroll after disabling.
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText color="surface.text.gray.disabled">
                  All information previously synced from {hrmsAppMetadata.name} will remain in
                  RazorpayX Payroll.
                </ListItemText>
              </ListItem>
            </List>
          </InformationCard>
          <TextInput
            label="Type “confirm” to disable integration"
            value={confirmText}
            onChange={handleConfirmTextChange}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          <Button
            onClick={handleNextCtaClick}
            isDisabled={!canProceed}
            isLoading={disableHrmsIntegrationMutation.isLoading}>
            Disable integration
          </Button>
        </Box>
      </Box>
    </IntegrationWindow>)
  );
};

export { DisableIntegration };
