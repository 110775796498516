import React from "react";
import {ReactNode} from "react";
import {LinkButtonV2} from "../../ui/Button";
import styles from "./index.module.scss";
import jibbleAttendanceSyncInProgressBackground from "assets/image-spinner-bg-1.png";
import jibbleAttendanceSyncPending from "assets/image-reports-pre-sync.png";
import {PreSyncDisplayProps} from "./components/JibbleAttendanceReports/PreSyncDisplay";
import {jibbleLandingPageUrl} from "./constants";

interface jibbleIntroductionInformation {
    heading: string;
    information: ReactNode;
}

export const jibbleIntroductionInformationData: jibbleIntroductionInformation[] = [
    {
        heading: 'The new standard of time tracking software!',
        information: (<div>
            <ul className={styles['infoList']}>
                <li className={styles['info']}>Manage multiple shifts, rotational shifts, nights shifts for employees, departments or locations</li>
                <li className={styles['info']}>Geofencing and face recogniation based attendance for accurate attendance</li>
                <li className={styles['info']}>Customized working week schedule for different employees</li>
                <li className={styles['info']}>Create customized rules around attendance and leaves for all your employees</li>
            </ul>
            <a href={jibbleLandingPageUrl} target="_blank" rel="noopener noreferrer">
                <LinkButtonV2 className="ml-10">and many more +</LinkButtonV2>
            </a>
        </div>)
    },
    {
        heading: 'Integration magic - RazorpayX Payroll and Jibble 🎁',
        information: (
            <ul className={styles['infoList']}>
                <li className={styles['info']}>RazorpayX Payroll will be creating Jibble accounts for all the employees</li>
                <li className={styles['info']}>Fetch attendance from Jibble and RazorpayX Payroll will calculate loss of pay for all employees</li>
                <li className={styles['info']}>Verify and add loss of pay as deduction to employees' monthly salaries</li>
            </ul>
        )
    },
    {
        heading: '3 easy steps to intergrate with Jibble',
        information: (
            <ul className={styles['infoList']}>
                <li className={styles['info']}>Step 1: Input new API credentials from Jibble to connect your Jibble account to your RazorpayX Payroll account.</li>
                <li className={styles['info']}>Step 2: Configure minimum half day and full day shift durations to calculate loss of pay for the employees</li>
                <li className={styles['info']}>Step 3: Sync all the employees from RazorpayX Payroll to Jibble and your integration is done!!</li>
            </ul>
        )
    },
    {
        heading: '🚨 Before you integrate...',
        information: (
            <ul className={styles['infoList']}>
                <li className={styles['info']}>If you have already added employees to your organization's Jibble account, make sure to add their email IDs to their Jibble accounts to simplify the integration.</li>
                <li className={styles['info']}>If you haven't already onboarded your employees to Jibble, worry not! RazorpayX Payroll will do it for you!</li>
            </ul>
        )
    }
];


export const FetchingNewAttendanceProps: PreSyncDisplayProps = {
    display: true,
    imageLink: jibbleAttendanceSyncInProgressBackground,
    header: "Generating LOP report from Jibble",
    description: "This may take up to 5 minutes. You may wait or come back after some time.",
    isLoading: true
};

export const FetchingExistingAttendanceProps: PreSyncDisplayProps = {
    display: true,
    imageLink: jibbleAttendanceSyncInProgressBackground,
    header: "Fetching Attendance LOP report",
    isLoading: true
}

export const AttendanceSyncPendingProps: PreSyncDisplayProps = {
    display: true,
    imageLink: jibbleAttendanceSyncPending,
    header: "No report generated for this month",
    description: "Enter the date range to fetch loss of pay report from Jibble",
    isLoading: false
}

export const HidePreSyncStateProps: PreSyncDisplayProps = {
    display: false
}