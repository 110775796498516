import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { mockPredefinedComponents } from '../__tests__/mock';
import { SalaryStructureComponent } from '../types';
import { SalaryStructureContextType } from './types';

const SalaryStructureContext = createContext<SalaryStructureContextType | undefined>(undefined);

const getInitialData = () => {
  return [
    ...mockPredefinedComponents,
    ...Array.from({ length: 6 }).map((item, id) => ({
      id: (Date.now() + id).toString(),
      name: '',
      amount: '',
      type: '',
      taxable: 'Yes',
      cellType: 'dropdown',
    })),
  ];
};

type Action =
  | { type: 'UPDATE_COMPONENT'; id: string; value?: string }
  | { type: 'UPDATE_AMOUNT'; id: string; value?: string }
  | { type: 'UPDATE_TYPE'; id: string; value: string }
  | { type: 'UPDATE_TAXABLE'; id: string; value: string };

function salaryStructureReducer(
  state: SalaryStructureComponent[],
  action: Action,
): SalaryStructureComponent[] {
  switch (action.type) {
    case 'UPDATE_COMPONENT':
      return state.map((item) =>
        item.id === action.id ? { ...item, name: action.value || '' } : item,
      );
    case 'UPDATE_AMOUNT':
      return state.map((item) =>
        item.id === action.id ? { ...item, amount: action.value || '' } : item,
      );
    case 'UPDATE_TYPE':
      return state.map((item) => (item.id === action.id ? { ...item, type: action.value } : item));
    case 'UPDATE_TAXABLE':
      return state.map((item) =>
        item.id === action.id ? { ...item, taxable: action.value } : item,
      );
    default:
      return state;
  }
}

export const SalaryStructureProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tableData, dispatch] = useReducer(salaryStructureReducer, getInitialData());

  const updateComponent = useCallback((id: string, value?: string) => {
    dispatch({ type: 'UPDATE_COMPONENT', id, value });
  }, []);

  const updateAmount = useCallback((id: string, value?: string) => {
    dispatch({ type: 'UPDATE_AMOUNT', id, value });
  }, []);

  const updateType = useCallback((id: string, value: string) => {
    dispatch({ type: 'UPDATE_TYPE', id, value });
  }, []);

  const updateTaxable = useCallback((id: string, value: string) => {
    dispatch({ type: 'UPDATE_TAXABLE', id, value });
  }, []);

  return (
    <SalaryStructureContext.Provider
      value={{ tableData, updateComponent, updateAmount, updateType, updateTaxable }}>
      {children}
    </SalaryStructureContext.Provider>
  );
};

export const useSalaryStructure = () => {
  const context = useContext(SalaryStructureContext);
  if (!context) {
    throw new Error('useSalaryStructure must be used within SalaryStructureProvider');
  }
  return context;
};
