export const INITIATE = 'INITIATE';
export const UPDATE = 'UPDATE';
export const CONTACT = 'CONTACT';
export const RETRY = 'RETRY';
export const UPDATE_CRED = 'UPDATE_CRED';
export const NOT_INITIATED = 'NOT_INITIATED';
export const DISABLED = 'DISABLED';
export const FAILED = 'FAILED';

export type ActionType = 'INITIATE' | 'UPDATE' | 'RETRY' | 'DISABLED' | 'UPDATE_CRED';
export type OrgType = string | null | undefined;