import React from 'react';

export type InstructionsViewProps = {
  instructions: Array<string>;
};

const InstructionsView = ({ instructions }: InstructionsViewProps) => {
  return (
    <div>
      <span className="leading-5 text-white-o-80 text-[14px]">💡 Instruction to fill</span>
      <ol className="list-decimal mt-3 mw-[492px] ml-6">
        {instructions.map((item, index) => (
          <li className="text-medium-grey leading-5 text-[14px]" key={index}>
            {item}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default InstructionsView;
