import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ fontSizeValue?: string }>`
  ${({ fontSizeValue }) => (fontSizeValue ? `& > * { font-size:${fontSizeValue};}` : ``)}
`;

const List = styled.ul<{ listPosition: string; listPadding?: string }>`
  display: flex;
  align-items: center;
  position: ${({ listPosition }) => listPosition};
  ${({ listPadding }) => (listPadding ? `padding: ${listPadding};` : '')}
`;

const CrumbPath = styled.li(
  ({ theme }) => `
  color: ${theme.colors.surface.text.gray.muted};
  font-size: 12px;
  &:after {
    color: ${theme.colors.surface.text.gray.muted};
    content: " | ";
    white-space: pre;
  }
`)

const CurrentPath = styled.li(
  ({ theme }) => `
  color: ${theme.colors.surface.text.gray.subtle};
  font-size: 12px;
`)

function Breadcrumb({
  name,
  urlMaps,
  className,
  listPosition = 'absolute',
  listPadding,
}: PropsWithChildren<{
  urlMaps: Record<string, string>;
  name: string | React.ReactNode | null;
  className?: string;
  listPosition?: string;
  listPadding?: string;
}>) {
  let hrefs: React.ReactNode[] = [];

  for (const [referenceName, url] of Object.entries(urlMaps)) {
    hrefs.push(
      <a key={url} href={url}>
        <CrumbPath>{referenceName}</CrumbPath>
      </a>,
    );
  }

  return (
    <Container className={`py-10 ${className ? className : ''}`}>
      <List
        listPosition={listPosition}
        listPadding={listPadding}>
        {hrefs}
        {name ? <CurrentPath>{name}</CurrentPath> : <></>}
      </List>
    </Container>
  );
}

export default Breadcrumb;