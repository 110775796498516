import { AppError } from 'components/AppError';
import BulkUploadsPolling from 'components/BulkUploadsPolling';
import FileUpload from 'components/ui/FileUpload';
import {
  BulkUploadStatus,
  BulkUploadTypes,
  BulkUploadsValidationPollStatuses,
} from 'constants/bulkUpload';
import React, { ReactElement, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import api from '../../../api/index';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import { FileUploadStatus, MimeType } from '../../ui/FileUpload/constants';
import ErrorBlock from '../components/ErrorBlock/index';
import { getStatusBasedOnBulkRecord } from './../Utils/utils';
import styles from './../index.module.scss';
import { truncateString } from 'utils/Strings';

type FileUploadSectionProps = {
  bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
  setBulkUploadRecord: (bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null) => void;
  onChangeFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIncorrectFileTypeSelection?: () => void;
  onExceededSizeSelection?: () => void;
  onDownloadErrorReport?: () => void;
  title: string;
  pollingRequestId: string;
  bulkUpoadType: BulkUploadTypes;
};

export default function FileUploadSection({
  bulkUploadRecord,
  setBulkUploadRecord,
  onChangeFile,
  onIncorrectFileTypeSelection,
  onExceededSizeSelection,
  onDownloadErrorReport,
  title,
  pollingRequestId,
  bulkUpoadType,
}: FileUploadSectionProps): ReactElement {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUploadCancelled, setFileUploadCancelled] = useState<Boolean>(false);
  const [status, setStatus] = useState<FileUploadStatus>(FileUploadStatus.NOT_INITIATED);
  const [error, setError] = useState<string>('');

  const sendFile = useMutation(
    (requestData: FormData) => {
      if (requestData && requestData.has('type') && requestData.has('file')) {
        return api.bulkUploads.sendFile(requestData);
      } else {
        throw 'Could not upload your file.';
      }
    },
    {
      onSuccess: (data) => {
        setBulkUploadRecord(data.length > 0 ? data[0] : null);

        sessionStorage.setItem(pollingRequestId, data[0].id.toString());

        if (data.length > 0 && data[0].status === BulkUploadStatus.STATUS_VALIDATION_FAILED) {
          setStatus(FileUploadStatus.FAILURE);
        } else if (
          data.length > 0 &&
          data[0].status === BulkUploadStatus.STATUS_VALIDATION_SUCCESS
        ) {
          setStatus(FileUploadStatus.SUCCESS);
        }
      },
      onError: (error: typeof AppError) => {
        setError(error.toString());
        setStatus(FileUploadStatus.FAILURE);
      },
    },
  );

  function onCancel() {
    setFileUploadCancelled(true);
    sessionStorage.removeItem(pollingRequestId);

    if (bulkUploadRecord) {
      api.bulkUploads
        .cancelBulkUpload(bulkUploadRecord.id)
        .then((response) => {
          setBulkUploadRecord(null);
        })
        .catch((error: Error) => {
          console.error("Error on cancelling org's bulk upload : ", error);
        });
    }
  }

  function shouldPoll() {
    if (
      bulkUploadRecord?.id &&
      sessionStorage.getItem(pollingRequestId) &&
      !fileUploadCancelled &&
      BulkUploadsValidationPollStatuses.includes(bulkUploadRecord.status)
    ) {
      return true;
    }
    return false;
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedFile(event.target.files ? event.target.files[0] : null);
    setFileUploadCancelled(false);
    setStatus(FileUploadStatus.UPLOADING);

    onChangeFile?.(event);

    if (event.target.files && event.target.files[0].type !== MimeType.XLSX) {
      setStatus(FileUploadStatus.FAILURE);
      setError('File uploaded is not of type .xlsx');
      onIncorrectFileTypeSelection?.();

      return;
    }

    if (event.target.files && event.target.files[0].size / 1048576 > 5) {
      setStatus(FileUploadStatus.FAILURE);
      setError('File uploaded is greater than 5 MB');
      onExceededSizeSelection?.();

      return;
    }

    let requestData = new FormData();
    requestData.append('type', bulkUpoadType.toString());
    requestData.append('file', event.target.files ? event.target.files[0] : '');
    sendFile.mutate(requestData);
  }

  useEffect(() => {
    setStatus(getStatusBasedOnBulkRecord(bulkUploadRecord));

    if (
      !bulkUploadRecord?.error_file_url &&
      bulkUploadRecord?.errors &&
      bulkUploadRecord.errors.length > 0
    ) {
      setError(bulkUploadRecord?.errors[0]);
    }
  }, [bulkUploadRecord]);

  return (
    <>
      {shouldPoll() && (
        <BulkUploadsPolling
          id={String(bulkUploadRecord?.id)}
          action={'file-upload'}
          setBulkUploadRecordData={setBulkUploadRecord}
        />
      )}
      <div className={styles['section']}>
        <span className={styles['section-main-text']}>{title}</span>
        <FileUpload
          id={'bulk-upload-input-file'}
          onChange={onChange}
          onCancel={onCancel}
          status={status}
          value={truncateString(
            selectedFile
              ? selectedFile.name
              : bulkUploadRecord?.file_name
              ? bulkUploadRecord?.file_name
              : 'File Not Found',
            25,
          )}
          allowedMimeTypes={[MimeType.XLSX]}
          maxSize={5}
          className="mt-8"
          error={bulkUploadRecord && bulkUploadRecord.error_file_url ? '' : error}
          disableFileReuploadButton={[
            BulkUploadStatus.STATUS_VALIDATION_PENDING,
            BulkUploadStatus.STATUS_VALIDATION_PROGRESS,
          ].includes(bulkUploadRecord?.status ?? 0)}
          disableCancelButton={!shouldPoll()}
        />
        {bulkUploadRecord &&
          bulkUploadRecord.status === BulkUploadStatus.STATUS_VALIDATION_FAILED &&
          bulkUploadRecord.error_file_url && (
            <div className={styles['error-block']}>
              <ErrorBlock
                mainText={
                  '⚠️ ' + bulkUploadRecord.number_of_rows_with_error?.toString() + ' errors found'
                }
                subText={'Please download error report > fix errors > re-upload file '}
                buttonText={'Get error report'}
                downloadURL={bulkUploadRecord.error_file_url ?? ''}
                onClick={onDownloadErrorReport}
              />
            </div>
          )}
      </div>
    </>
  );
}
