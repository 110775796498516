import React, { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { CheckBox, Input } from 'components/ui/Form/Input';
import Note from 'components/ui/Note';
import Label from 'components/ui/Label';
import ExternalLink from 'components/ui/ExternalLink';
import { Table } from 'components/ui/Table';
import { Select, Option } from 'components/ui/Form/Select';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { PFUpdateRequest } from 'api/companyOnboarding';
import api from 'api';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { routePaths } from 'components/Routes/data';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { trackSegment } from 'utils/segment';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

const PF_ENABLED = 1;
const PF_DISABLED = 0;

const PfSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const disablePfPaymentsFlag = useSelector(loggedInUserSelectors.disablePfPaymentsFlag);

  const [isPFPaymentEnabled, setIsPFPaymentEnabled] = useState(
    !!currentOrganization?.paymentOptions?.payPf,
  );
  const [pfStatus, setPfStatus] = useState(currentOrganization?.isPf ? PF_ENABLED : PF_DISABLED);
  const [shouldIncludeEmployerContributionInCtc, setShouldIncludeEmployerContributionInCtc] =
    useState(!!currentOrganization?.paymentOptions?.includeEmployerPfContributionInCtc);
  const [shouldIncludeAdminCharges, setShouldIncludeAdminCharges] = useState(
    !!currentOrganization?.paymentOptions?.includePfAdminChargeInCtc,
  );
  const [shouldUseOnlyBasicForPf, setShouldUseOnlyBasicForPr] = useState(
    !!currentOrganization?.paymentOptions?.useBasicOnlyForPf,
  );
  const [shouldUsePfLimit, setShouldUsePfLimit] = useState(
    !!currentOrganization?.paymentOptions?.restrictPfToLimit,
  );
  const [pfUsername, setPfUsername] = useState(
    currentOrganization?.externalCredentials?.pfUser || '',
  );
  const [pfPassword, setPfPassword] = useState(
    currentOrganization?.externalCredentials?.pfPass || '',
  );
  const [showPassword, setShowPassword] = useState(false);
  const links = useSelector(loggedInUserSelectors.referenceLinks);

  const updatePFMutation = useMutation(
    ({
      isPFEnabled,
      isPFPaymentEnabled,
      shouldIncludeEmployerContributionInCtc,
      shouldIncludeAdminCharges,
      shouldUseOnlyBasicForPf,
      shouldUsePfLimit,
      pfUsername,
      pfPassword,
    }: PFUpdateRequest) =>
      api.companyOnboarding.updatePFSetup({
        isPFEnabled,
        isPFPaymentEnabled,
        shouldIncludeEmployerContributionInCtc,
        shouldIncludeAdminCharges,
        shouldUseOnlyBasicForPf,
        shouldUsePfLimit,
        pfUsername,
        pfPassword,
      }),
    {
      onSuccess: (data) => {
        trackSegment('onboarding.setup_compliance.pf_click');
        dispatch(fetchLoggedInUser());
        history.push(routePaths.companyOnboarding.compliance.esic);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const isPFEnabled = pfStatus === PF_ENABLED;
    updatePFMutation.mutate({
      isPFEnabled,
      isPFPaymentEnabled: !isPFEnabled ? false : isPFPaymentEnabled,
      shouldIncludeEmployerContributionInCtc,
      shouldIncludeAdminCharges,
      shouldUseOnlyBasicForPf,
      shouldUsePfLimit,
      pfUsername,
      pfPassword,
    });
  };

  const isFormBeingSubmitted = updatePFMutation.status === 'loading';

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className='flex flex-col items-start'>
        {updatePFMutation.status === 'error' && (
          <WarningAlert className="mb-4">
            <Errors errorResponse={updatePFMutation.error} />
          </WarningAlert>
        )}
        <Note>PF is mandatory if the organization size is more than 20 employees</Note>
        <Label>PF Status</Label>
        <Select
          value={pfStatus}
          onChange={(e) => {
            setPfStatus(parseInt(e.target.value));
          }}
          disabled={isFormBeingSubmitted}
          className="mb-20"
        >
          <Option value={PF_ENABLED}>Enabled</Option>
          <Option value={PF_DISABLED}>Disabled</Option>
        </Select>

        {pfStatus === PF_ENABLED && (
          <>
            <Label>PF payments</Label>
            <Note>
              Please note that disabling the payment of PF does not disable the deduction of the
              same in your employees' payslips.
            </Note>
            <CheckBox
              id="pf-payment-enabled"
              label="PF payments and filing (if applicable)"
              className="mb-8"
              disabled={isFormBeingSubmitted || disablePfPaymentsFlag}
              value="is-pf-payment-enabled"
              checked={isPFPaymentEnabled}
              onChange={(event) => {
                setIsPFPaymentEnabled(event.target.checked);
              }}
            />

            {isPFPaymentEnabled && (
              <>
                <Label className="mt-16">PF</Label>
                <Note>
                  Your company's <ExternalLink href={links.linkPf}>PF</ExternalLink> login is
                  required to pay provident fund dues and file returns.
                </Note>

                <Table className="w-full mb-10">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          type="text"
                          name="pf-username"
                          disabled={isFormBeingSubmitted}
                          maxLength={255}
                          value={pfUsername}
                          onChange={(e) => setPfUsername(e.target.value)}
                        />
                      </td>
                      <td>
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          name="pf-password"
                          disabled={isFormBeingSubmitted}
                          maxLength={255}
                          value={pfPassword}
                          onChange={(e) => setPfPassword(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <StandardSecondaryButton
                  type="button"
                  className="mb-16"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  Click here to {showPassword ? 'hide' : 'show'} password
                </StandardSecondaryButton>
                <br />
              </>
            )}

            <Label className="mt-16">PF compliance setup</Label>
            <Note>Please choose your PF settings.</Note>

            <CheckBox
              id="employer-contribution-pf"
              label="Include employer contribution to PF in employee CTC"
              className="mb-8"
              value="is-employer-contribution-pf-ctc"
              checked={shouldIncludeEmployerContributionInCtc}
              onChange={(event) => {
                setShouldIncludeEmployerContributionInCtc(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />
            <CheckBox
              id="include-pf-admin-charges"
              label="Include PF edli + admin charges in employee CTC"
              className="mb-8"
              value="include-pf-admin-charges"
              checked={shouldIncludeAdminCharges}
              onChange={(event) => {
                setShouldIncludeAdminCharges(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />
            <IsFeatureDisabledForRearch feature='isUseOnlyBasicForPfSettingDisabled'>
            <CheckBox
              id="use-only-basic-pf"
              label={
                <>
                  Use only basic salary for calculating PF (
                  <ExternalLink href={links.pfContributionCalculation} target="_blank">
                    warning!
                  </ExternalLink>
                  )
                </>
              }
              className="mb-8"
              value="use-only-basic-pf"
              checked={shouldUseOnlyBasicForPf}
              onChange={(event) => {
                setShouldUseOnlyBasicForPr(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />
            </IsFeatureDisabledForRearch>
            <CheckBox
              id="use-pf-limit-15k"
              label="Use PF limit of ₹15,000 while calculating contributions"
              className="mb-16"
              value="use-pf-limit-15k"
              checked={shouldUsePfLimit}
              onChange={(event) => {
                setShouldUsePfLimit(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />
          </>
        )}
        <StandardButton type="submit" isPending={isFormBeingSubmitted}>
          Save & Next
        </StandardButton>
      </form>
    </div>
  );
};

export default PfSetup;
