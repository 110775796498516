import React from 'react';
import { ZaggleIntroSection } from './ZaggleIntroSection';
import { TickList, TickListItem } from '../../../ui/TickList';

export function ZagglePleaseNoteSection() {
  return <ZaggleIntroSection heading={'Please note 📝'}>
    <TickList>
      <TickListItem>
        The declared amount will be transferred to your Zaggle SAVE card on the date of payroll execution.
      </TickListItem>
      <TickListItem>
        Once the amount is transferred to your Zaggle SAVE card it cannot be transferred to your bank account or cashed.
      </TickListItem>
    </TickList>
  </ZaggleIntroSection>;
}