import React from 'react';
import { useIsFetching } from 'react-query';

import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import AmountDisplay from './AmountDisplay';
import InfoDisplay from './InfoDisplay';
import PayrollActions from './PayrollActions';
import PayrollMonthSelector from './PayrollMonthSelector';
import { useRunPayrollFilterContext } from '../contexts/RunPayrollFiltersContext';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import ImportJibbleAttendancePrompt from "./ImportJibbleAttendancePrompt";
import PendingDisplay from './PendingDisplay';

const SideDisplay = ({
  payrollMonth,
  setPayrollMonth,
  isSelected,
  isJibbleEnabled
}: {
  payrollMonth: string | null;
  setPayrollMonth: (payrollMonth: string | null) => void;
  isSelected: boolean;
  isJibbleEnabled: boolean;
}) => {
  const isFetchingData = useIsFetching(FETCH_RUNPAYROLL_DATA);
  
  const { hasFilters } = useRunPayrollFilterContext() ?? {};
  const isDisabled = isFetchingData || isSelected;

  const data = useRunPayrollDataContext();
  // hide everthing except payrollmonth selector when there are any filters applied or 
  // when the org doesn't have any employees 
  const shouldShowDetails = hasFilters && !hasFilters() && !!data?.payrollData?.length;

  return (
    <>
      <PayrollMonthSelector payrollMonth={payrollMonth} setPayrollMonth={setPayrollMonth} />
      {!isFetchingData && isJibbleEnabled && <ImportJibbleAttendancePrompt reportMonth={payrollMonth}/>}
      <PendingDisplay />
      {shouldShowDetails && (
        <div className={isDisabled ? 'cursor-not-allowed' : ''}>
          <div className={isDisabled ? 'opacity-25 pointer-events-none' : ''}>
            <AmountDisplay/>
            <InfoDisplay />
            <PayrollActions />
          </div>
        </div>
      )}
    </>
  );
};

export default SideDisplay;
