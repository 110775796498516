import React, { useState } from 'react';
import useRxIntegration from './hooks/useRxIntegration';
import {
  Alert,
  Badge,
  Box,
  Button,
  CheckCircleIcon,
  Heading,
  Link,
  Text,
} from '@razorpay/blade/components';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { OnboardingLayout, MainContent, Sidebar } from 'components/ui/OnboardingLayout';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { toIndianCurrencyString } from 'utils/Numbers';
import { useMutation } from 'react-query';
import { AppError } from 'utils/AppError';
import api from 'api';
import Errors from 'components/ui/Form/Errors';

const PreCheck = () => {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const { rxIntegrationQuery } = useRxIntegration();
  const history = useHistory();
  const initiateLoginMutation = useMutation({
    mutationFn: api.rxIntegration.initiateLogin,
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: (error: typeof AppError) => {
      setIsSigningIn(false);
    },
  });

  const handleReturnToDashboard = () => {
    window.location.href = '/dashboard';
  };

  const handleProceed = () => {
    history.push(routePaths.settings.rxIntegration.activate);
  };

  const handleLoginToX = () => {
    setIsSigningIn(true);
    initiateLoginMutation.mutate();
  };

  if (rxIntegrationQuery.isLoading || rxIntegrationQuery.data === undefined) {
    return <></>;
  }

  if (rxIntegrationQuery.data.merchant_details) {
    return <Redirect to={routePaths.settings.rxIntegration.activate} />;
  }

  const isLoggedIntoX =
    rxIntegrationQuery.data.is_logged_in_x && !!rxIntegrationQuery.data.merchant_id;

  const isPreCheckInComplete =
    rxIntegrationQuery.data.transactions_in_count_progress > 0 ||
    rxIntegrationQuery.data.balance > 0 ||
    !isLoggedIntoX;

  if (
    isLoggedIntoX &&
    rxIntegrationQuery.data.balance === 0 &&
    rxIntegrationQuery.data.transactions_in_count_progress === 0
  ) {
    return <Redirect to={routePaths.settings.rxIntegration.activate} />;
  }

  return (
    (<OnboardingLayout>
      <Sidebar
        title="RazorpayX Account Integration"
        description="Integrate your RazorpayX account with XPayroll"
        onBackClick={handleReturnToDashboard}
        progress={['IN_PROGRESS', 'INCOMPLETE', 'INCOMPLETE']}></Sidebar>
      <MainContent title="" description="">
        <Box display="flex" flexDirection="column" gap="spacing.3">
          <Heading size="small">Please complete the below actions</Heading>
          <Text>
            These are pre-requisites before you can link your RazorpayX account with XPayroll
          </Text>
          <Link
            size="small"
            rel="noopener noreferrer"
            target="_blank"
            href="https://intercom.help/XPayroll/en/articles/5479921-what-are-the-requirements-to-do-the-current-account-integration">
            Learn more
          </Link>
        </Box>
        {!!rxIntegrationQuery.error ? (
          <Alert
            color="negative"
            title="An error occured"
            description={<Errors errorResponse={rxIntegrationQuery.error} />}
            marginTop="spacing.4"
          />
        ) : (
          <></>
        )}
        {!!initiateLoginMutation.error ? (
          <Alert
            color="negative"
            title="An error occured"
            description={<Errors errorResponse={initiateLoginMutation.error} />}
            marginTop="spacing.4"
          />
        ) : (
          <></>
        )}
        <Box display="flex" flexDirection="column" gap="spacing.5">
          <Box
            display="flex"
            gap="spacing.5"
            alignItems="center"
            padding="spacing.7"
            borderRadius="large"
            backgroundColor="surface.background.gray.subtle">
            <Box display="flex" gap="spacing.5" flex="1">
              <CheckCircleIcon
                size="medium"
                color={
                  isLoggedIntoX
                    ? 'feedback.icon.positive.intense'
                    : 'interactive.icon.gray.disabled'
                }
              />
              <Box display="flex" gap="spacing.4" flex="1">
                <Box display="flex" flexDirection="column" gap="spacing.2" justifyContent="center">
                  <Box display="flex" gap="spacing.3" alignItems="center">
                    <Text weight="semibold">Sign in to your RazorpayX account</Text>
                    {!isLoggedIntoX && (
                      <Badge color="notice" size="small">
                        Not signed in
                      </Badge>
                    )}
                  </Box>
                  <Text size="small" color="surface.text.gray.muted">
                    {isLoggedIntoX
                      ? 'Done!'
                      : 'Please sign in to your RazorpayX Account to verify your identity on RazorpayX'}
                  </Text>
                </Box>
              </Box>
            </Box>
            {!isLoggedIntoX && (
              <Button size="xsmall" isLoading={isSigningIn} onClick={handleLoginToX}>
                Sign in
              </Button>
            )}
          </Box>
          <Box
            display="flex"
            gap="spacing.5"
            alignItems="center"
            padding="spacing.7"
            borderRadius="large"
            backgroundColor="surface.background.gray.subtle">
            <Box display="flex" gap="spacing.5" flex="1">
              <CheckCircleIcon
                size="medium"
                color={
                  rxIntegrationQuery.data.balance > 0
                    ? 'interactive.icon.gray.disabled'
                    : 'feedback.icon.positive.intense'
                }
              />
              <Box display="flex" gap="spacing.4" flex="1">
                <Box display="flex" flexDirection="column" gap="spacing.2" justifyContent="center">
                  <Box display="flex" gap="spacing.3" alignItems="center">
                    <Text weight="semibold">Have 0 balance</Text>
                    {rxIntegrationQuery.data.balance > 0 && (
                      <Badge color="notice" size="small">
                        Current bal:{' '}
                        {toIndianCurrencyString(rxIntegrationQuery.data.balance, false, 2)}
                      </Badge>
                    )}
                  </Box>
                  <Text size="small" color="surface.text.gray.muted">
                    {rxIntegrationQuery.data.balance > 0
                      ? 'Go to reimbursements → add yourself as contractor → make payment'
                      : 'Done!'}
                  </Text>
                </Box>
              </Box>
            </Box>
            {rxIntegrationQuery.data.balance > 0 && (
              <a href="/reimbursements" target="_self">
                <Button size="xsmall">Reimbursements</Button>
              </a>
            )}
          </Box>
          <Box
            display="flex"
            gap="spacing.5"
            alignItems="center"
            padding="spacing.7"
            borderRadius="large"
            backgroundColor="surface.background.gray.subtle">
            <Box display="flex" gap="spacing.5" flex="1">
              <CheckCircleIcon
                size="medium"
                color={
                  rxIntegrationQuery.data.transactions_in_count_progress > 0
                    ? 'interactive.icon.gray.disabled'
                    : 'feedback.icon.positive.intense'
                }
              />
              <Box display="flex" gap="spacing.4" flex="1">
                <Box display="flex" flexDirection="column" gap="spacing.2" justifyContent="center">
                  <Box display="flex" gap="spacing.3" alignItems="center">
                    <Text weight="semibold">Have 0 pending transactions</Text>
                    {rxIntegrationQuery.data.transactions_in_count_progress > 0 && (
                      <Badge color="notice" size="small">
                        {rxIntegrationQuery.data.transactions_in_count_progress} pending
                      </Badge>
                    )}
                  </Box>
                  <Text size="small" color="surface.text.gray.muted">
                    {rxIntegrationQuery.data.transactions_in_count_progress > 0
                      ? 'No action is required for employee transfers. Please contact support for pending compliance payments'
                      : 'Done!'}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Button size="xsmall" onClick={handleProceed} isDisabled={isPreCheckInComplete}>
            Proceed
          </Button>
        </Box>
      </MainContent>
    </OnboardingLayout>)
  );
};

export default PreCheck;
