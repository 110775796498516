import React, { useRef, useState } from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import { Alert, Box, Button, Text, Heading } from '@razorpay/blade/components';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { Redirect, useHistory } from 'react-router-dom';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { AppError } from 'utils/AppError';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';
import { useGetKnitHrmsEmployeeTypesLovQuery } from '../useGetKnitHrmsEmployeeTypesLovQuery';
import { EmployeeType, EmployeeTypesMappingCard } from './components/EmployeeTypesMappingCard';

const EmployeeContractorMapping = () => {
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const mainContentRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const [showIncompleteMappingError, setShowIncompleteMappingError] = useState(false);
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    retryLimitExhausted: integrationQueryRetryLimitExhausted,
    updateEmployeeTypes,
    updateContractorTypes,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);
  const { totalSteps, currentStep, integrationProgress } = useIntegrationProgress();

  const {
    knitHrmsEmployeeTypesLovQuery,
    retryLimitExhausted: employeeTypesLovQueryRetryLimitExhausted,
  } = useGetKnitHrmsEmployeeTypesLovQuery(knitHrmsApp);

  const isLoading =
    knitHrmsIntegrationQuery.isLoading ||
    knitHrmsIntegrationQuery.isFetching ||
    knitHrmsEmployeeTypesLovQuery.isLoading ||
    knitHrmsEmployeeTypesLovQuery.isFetching;

  const peopleTypes = knitHrmsEmployeeTypesLovQuery.data?.data.fields;
  const mappedEmployeeTypes = knitHrmsIntegrationQuery.data?.employee_types_full_time;
  const mappedContractorTypes = knitHrmsIntegrationQuery.data?.employee_types_contractor;
  const mappedPeopleTypes = peopleTypes?.map((peopleType) => {
    const mappedEmployeeType = mappedEmployeeTypes?.find((id) => id === peopleType.id);
    const mappedContractorType = mappedContractorTypes?.find((id) => id === peopleType.id);

    if (mappedEmployeeType) {
      return {
        ...peopleType,
        selectedAs: 'employee',
      } as EmployeeType;
    }

    if (mappedContractorType) {
      return {
        ...peopleType,
        selectedAs: 'contractor',
      } as EmployeeType;
    }

    return peopleType as EmployeeType;
  });

  const canContinueToNextStep =
    mappedEmployeeTypes &&
    mappedEmployeeTypes?.length > 0 &&
    mappedContractorTypes &&
    mappedContractorTypes?.length > 0;

  if (knitHrmsEmployeeTypesLovQuery.isError) {
    const errorCode = (knitHrmsEmployeeTypesLovQuery.error as AppError).getDetails().code;

    // If the employee types LOV is not present for the app, it automatically skips the employee types mapping step
    if (errorCode === 'KNIT_EMPLOYEE_TYPES_LOV_UNAVAILABLE_FOR_APP') {
      return (
        <Redirect
          to={{
            pathname: hrmsAppRoutePaths.settings.syncConfirmation,
            state: {
              totalSteps: totalSteps - 1,
              currentStep: currentStep,
            },
          }}
        />
      );
    }
  }

  // redirecting to HRMS app home if integration is not active
  if (knitHrmsIntegrationQuery.isSuccess && !isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  // redirecting to sync-in-progress screen if employee data sync is in progress
  if (knitHrmsIntegrationQuery.isSuccess && isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.settings.syncInProgress} />;
  }

  const handleBackCtaClick = () => {
    history.goBack();
  };

  const showUnmappedFieldsError = () => {
    setShowIncompleteMappingError(true);
    mainContentRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleNextCtaClick = () => {
    if (
      !mappedEmployeeTypes ||
      !mappedContractorTypes ||
      !peopleTypes ||
      mappedEmployeeTypes.length + mappedContractorTypes.length < peopleTypes.length
    ) {
      showUnmappedFieldsError();
      return;
    }

    history.push(hrmsAppRoutePaths.settings.syncConfirmation, {
      totalSteps,
      currentStep: currentStep + 1,
    });
  };

  const handleEmployeeSelectionChange = (selectedIds: string[]) => {
    if (!peopleTypes) {
      return;
    }

    setShowIncompleteMappingError(false);
    updateEmployeeTypes(selectedIds);
  };

  const handleContractorSelectionChange = (selectedIds: string[]) => {
    if (!peopleTypes) {
      return;
    }

    setShowIncompleteMappingError(false);
    updateContractorTypes(selectedIds);
  };

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  if (isLoading) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={integrationQueryRetryLimitExhausted}
      />
    );
  }

  if (knitHrmsEmployeeTypesLovQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsEmployeeTypesLovQuery.refetch}
        isRetrying={knitHrmsEmployeeTypesLovQuery.isFetching}
        retryLimitExhausted={employeeTypesLovQueryRetryLimitExhausted}
      />
    );
  }

  return (
    (<IntegrationWindow
      progress={integrationProgress}
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={org?.name ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}
      ref={mainContentRef}>
      <Box display="flex" flexDirection="column" gap="spacing.9" maxWidth="592px">
        <Box display="flex" flexDirection="column" gap="spacing.2">
          <Heading as="h2" size="xlarge" color="surface.text.gray.muted">
            🧑‍💼Employee & contractor mapping
          </Heading>
          <Text color="surface.text.gray.disabled">
            RazorpayX payroll have only employee and contractors as entities. All types from
            {hrmsAppMetadata.name} has to be mapped from {hrmsAppMetadata.name} to employee and
            contractor type for sync
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" gap="spacing.7">
          {showIncompleteMappingError && (
            <Box marginBottom="spacing.3">
              <Alert
                isDismissible={false}
                description={`All types from ${hrmsAppMetadata.name} are not assigned Employee/Contractor in RazorpayX Payroll. Please assign them to proceed forward.`}
                color="negative"
              />
            </Box>
          )}
          <EmployeeTypesMappingCard
            knitHrmsAppName={hrmsAppMetadata.name}
            mapTo="employee"
            peopleTypes={mappedPeopleTypes ?? []}
            onChange={handleEmployeeSelectionChange}
          />
          <EmployeeTypesMappingCard
            knitHrmsAppName={hrmsAppMetadata.name}
            mapTo="contractor"
            peopleTypes={mappedPeopleTypes ?? []}
            onChange={handleContractorSelectionChange}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          <Button onClick={handleNextCtaClick} isDisabled={!canContinueToNextStep}>
            Continue
          </Button>
        </Box>
      </Box>
    </IntegrationWindow>)
  );
};

export { EmployeeContractorMapping };
