import React from 'react';
import { Drawer, DrawerBody } from '@razorpay/blade/components';
import { useDetailsView } from 'components/SalaryComponents/hooks';
import { salaryComponentParams } from 'components/SalaryComponents/constants';
import { DetailsContainer } from 'components/SalaryComponents/components/details/DetailsViewHelpers';
import { knowMoreItems } from '../constants';
import { ArrearsInfo, ProrationInfo, TaxabilityInfo, WageCalculationInfo } from './components';
import TaxDeductionsInfo from './components/TaxDeductionsInfo';

const KnowMoreView = () => {
  const { paramId: knowMoreItem, closeDetailsView } = useDetailsView(
    salaryComponentParams.knowMore,
  );
  const isOpen = !!knowMoreItem;

  return (
    <>
      <Drawer isOpen={isOpen} onDismiss={() => closeDetailsView()} showOverlay={false}>
        <DrawerBody>
          <DetailsContainer onDismiss={() => closeDetailsView()}>
            {knowMoreItem === knowMoreItems.proration && <ProrationInfo />}
            {knowMoreItem === knowMoreItems.arrearCalculation && <ArrearsInfo />}
            {knowMoreItem === knowMoreItems.wageCalculation && <WageCalculationInfo />}
            {knowMoreItem === knowMoreItems.taxability && <TaxabilityInfo />}
            {knowMoreItem === knowMoreItems.taxDeductions && <TaxDeductionsInfo />}
          </DetailsContainer>
        </DrawerBody>
      </Drawer>
    </>
  );
};

export default KnowMoreView;
