import React, { useState } from 'react';
import { OnboardingLayout, MainContent, Sidebar } from 'components/ui/OnboardingLayout';
import {
  Box,
  Button,
  Heading,
  Text,
  ActionList,
  ActionListItem,
  ActionListSection,
  SelectInput,
  DropdownOverlay,
  Dropdown,
  Spinner,
  Alert,
} from '@razorpay/blade/components';
import Errors from 'components/ui/Form/Errors';
import Merchant from './components/Merchant';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FETCH_RX_BANKING_ACCOUNTS, FETCH_RX_INTEGRATION_DETAILS } from './queries';
import useRxMerchants from './hooks/useRxMerchants';
import useRxIntegration from './hooks/useRxIntegration';
import { Redirect, useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { ROLE, PRODUCT, ACCOUNT_TYPE } from './constants';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import { getAccountType, getBankName } from './utils';
import { CACHE_TTL } from 'utils/cache';
import { BankingAccount } from './types';
import SuccessModal from './SuccessModal';

// sort accounts to show current accounts first
function sortBankAccounts(accountOne: BankingAccount, accountTwo: BankingAccount) {
  if (accountOne.account_type === ACCOUNT_TYPE.CURRENT) {
    return -1;
  }

  return 1;
}

const Activation = () => {
  const [selectedBankAccount, setSelectedBankAccount] = useState('');
  const history = useHistory();
  const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false);
  const handleReturnToDashboard = () => {
    window.location.href = '/dashboard';
  };
  const queryClient = useQueryClient();
  const loggedInOrgName = useSelector(loggedInUserSelectors.currentOrganization)?.name;

  const { rxIntegrationQuery } = useRxIntegration();

  const { rxMerchantsQuery } = useRxMerchants({
    enabled: rxIntegrationQuery.isSuccess && !!rxIntegrationQuery.data.merchant_id,
  });

  const rxMerchant = rxMerchantsQuery.data?.find(
    (merchant) => merchant.product === PRODUCT.BANKING,
  );

  const hasAdminPrivilegesOnX =
    !!rxMerchant && [ROLE.ADMIN, ROLE.OWNER].includes(rxMerchant.role || '');

  const isNameNotMatchingOnX = !loggedInOrgName || loggedInOrgName !== rxMerchant?.name;

  const bankingAccountsQuery = useQuery({
    queryFn: api.rxIntegration.fetchBankingAccounts,
    queryKey: FETCH_RX_BANKING_ACCOUNTS,
    enabled: rxMerchantsQuery.isSuccess && hasAdminPrivilegesOnX && !isNameNotMatchingOnX,
    onError: (error: typeof AppError) => {},
    onSuccess: (data) => {
      const sortedData = data.sort(sortBankAccounts);
      setSelectedBankAccount(sortedData[0]?.id || '');
    },
    staleTime: CACHE_TTL.MEDIUM,
  });

  const activateIntegrationMutation = useMutation({
    mutationFn: api.rxIntegration.activateIntegration,
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_RX_INTEGRATION_DETAILS);
      setShouldShowSuccessModal(true);
    },
    onError: (error: typeof AppError) => {},
  });

  const handleSubmit = () => {
    activateIntegrationMutation.mutate(selectedBankAccount);
  };

  if (rxIntegrationQuery.isLoading || rxIntegrationQuery.data === undefined) {
    return <></>;
  }

  if (
    !rxIntegrationQuery.data.merchant_id ||
    rxIntegrationQuery.data.balance > 0 ||
    rxIntegrationQuery.data.transactions_in_count_progress > 0
  ) {
    return <Redirect to={routePaths.settings.rxIntegration.preCheck} />;
  }

  if (rxIntegrationQuery.data.merchant_details?.is_active && !shouldShowSuccessModal) {
    return <Redirect to={routePaths.settings.rxIntegration.root} />;
  }

  // TODO: enable when the blade dropdown issue is fixed
  // const hasCurrentAccounts =
  //   !!bankingAccountsQuery.data &&
  //   bankingAccountsQuery.data.filter((account) => account.account_type === ACCOUNT_TYPE.CURRENT).length >
  //     0;

  // const hasVirtualAccounts =
  //   !!bankingAccountsQuery.data &&
  //   bankingAccountsQuery.data.filter((account) => account.account_type === ACCOUNT_TYPE.NODAL).length >
  //     0;

  const handleCloseSuccessModal = () => {
    setShouldShowSuccessModal(false);
    history.push(routePaths.settings.rxIntegration.root);
  };

  return (
    (<OnboardingLayout>
      <SuccessModal onClose={handleCloseSuccessModal} isOpen={shouldShowSuccessModal} />
      <Sidebar
        title="RazorpayX Account Integration"
        description="Integrate your RazorpayX account with XPayroll"
        onBackClick={handleReturnToDashboard}
        progress={['COMPLETED', 'IN_PROGRESS', 'INCOMPLETE']}></Sidebar>
      <MainContent title="" description="">
        <Box display="flex" flexDirection="column" gap="spacing.10" width="376px">
          <Box display="flex" flexDirection="column" gap="spacing.9">
            <Box display="flex" flexDirection="column" gap="spacing.3">
              <Heading size="xlarge">✅ Select Account</Heading>
              <Text>All transactions in Payroll will be made via this account.</Text>
            </Box>
          </Box>
          {rxMerchantsQuery.error && (
            <Alert
              color="negative"
              title="An error occured"
              description={<Errors errorResponse={rxMerchantsQuery.error} />}
              marginTop="spacing.4"
            />
          )}

          {bankingAccountsQuery.error && (
            <Alert
              color="negative"
              title="An error occured"
              description={<Errors errorResponse={bankingAccountsQuery.error} />}
              marginTop="spacing.4"
            />
          )}

          {activateIntegrationMutation.error && (
            <Alert
              color="negative"
              title="An error occured"
              description={<Errors errorResponse={activateIntegrationMutation.error} />}
              marginTop="spacing.4"
            />
          )}

          {rxMerchantsQuery.isFetching || bankingAccountsQuery.isFetching ? (
            <Spinner accessibilityLabel="loading" />
          ) : (
            <Box display="flex" flexDirection="column" gap="spacing.10">
              {!!rxMerchant && (
                <Box display="flex" flexDirection="column" gap="spacing.5">
                  <Text size="large">Your organization details on RazorpayX</Text>
                  {rxMerchant && (
                    <Merchant
                      role={rxMerchant.role || ''}
                      mid={rxMerchant.id}
                      companyName={rxMerchant.name || ''}
                    />
                  )}
                  {!hasAdminPrivilegesOnX && (
                    <Alert
                      color="negative"
                      title="Insufficient privileges"
                      description="You need to be an Owner / Admin on a RazorpayX account to proceed."
                      marginTop="spacing.4"
                    />
                  )}{' '}
                  {isNameNotMatchingOnX && (
                    <Alert
                      color="negative"
                      title="Name mismatch"
                      description={`Your company name on RazorpayX (${rxMerchant.name}) does not match the name on XPayroll (${loggedInOrgName}). Please contact support.`}
                      marginTop="spacing.4"
                    />
                  )}
                </Box>
              )}

              {!rxMerchant && (rxMerchantsQuery.data?.length || 0) > 0 && (
                <Alert
                  color="negative"
                  title="RazorpayX account not found"
                  description="You have an account with Razorpay, but are not yet using RazorpayX. Please activate your RazorpayX account first."
                  marginTop="spacing.4"
                />
              )}

              {bankingAccountsQuery.isSuccess && bankingAccountsQuery.data?.length > 0 && (
                <>
                  <Box display="flex" flexDirection="column" gap="spacing.4">
                    {rxMerchant && (
                      <Text weight="semibold">Select bank account for {rxMerchant?.name}</Text>
                    )}
                    <Dropdown>
                      <SelectInput
                        isDisabled={
                          activateIntegrationMutation.isLoading || rxIntegrationQuery.isFetching
                        }
                        label="Select account"
                        placeholder="Select account"
                        name="account-select"
                        value={selectedBankAccount}
                        onChange={({ name, values }) => {
                          setSelectedBankAccount(values[0]);
                        }}
                      />
                      {bankingAccountsQuery.data && (
                        <DropdownOverlay>
                          <ActionList>
                            {bankingAccountsQuery.data.sort(sortBankAccounts).map((bankAccount) => (
                              <ActionListItem
                                key={bankAccount.id}
                                title={getBankName(bankAccount.channel)}
                                value={bankAccount.id}
                                description={`${bankAccount.account_number || ''} (${getAccountType(
                                  bankAccount.account_type || '',
                                )})`}
                              />
                            ))}

                            {/* {hasCurrentAccounts && (
                              <ActionListSection title="Current Accounts">
                                {bankingAccountsQuery.data
                                  .filter((account) => account.account_type === ACCOUNT_TYPE.CURRENT)
                                  .map((bankAccount) => (
                                    <ActionListItem
                                      key={bankAccount.id}
                                      title={getBankName(bankAccount.channel)}
                                      value={bankAccount.id}
                                      description={bankAccount.account_number || ''}
                                    />
                                  ))}
                              </ActionListSection>
                            )}
                            {hasVirtualAccounts && (
                              <ActionListSection title="Virtual Accounts">
                                {bankingAccountsQuery.data
                                  .filter((account) => account.account_type === ACCOUNT_TYPE.NODAL)
                                  .map((bankAccount) => (
                                    <ActionListItem
                                      key={bankAccount.id}
                                      title={getBankName(bankAccount.channel)}
                                      value={bankAccount.id}
                                      description={bankAccount.account_number || ''}
                                    />
                                  ))}
                              </ActionListSection>
                            )} */}
                          </ActionList>
                        </DropdownOverlay>
                      )}
                    </Dropdown>
                  </Box>
                  <Box display="flex" gap="spacing.5" alignItems="center" flex="1">
                    <Button
                      isDisabled={rxMerchantsQuery.isFetching || selectedBankAccount === ''}
                      isLoading={
                        activateIntegrationMutation.isLoading || rxIntegrationQuery.isFetching
                      }
                      onClick={handleSubmit}>
                      Confirm
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </MainContent>
    </OnboardingLayout>)
  );
};

export default Activation;
