import closeIcon from 'assets/icon--close-grey.svg';
import clsx from 'clsx';
import { SecondaryButton, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import ExternalLink from 'components/ui/ExternalLink';
import { Input } from 'components/ui/Form/Input';
import { Option, Select } from 'components/ui/Form/Select';
import { useModal } from 'components/ui/Modals';
import Modal from 'components/ui/Modals/components/Modal';
import { format, parse, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import { GetComputedRlopArrearBreakdownContract } from 'schemas/GetComputedRlopArrearBreakdown';
import { dateFormats } from 'utils/Dates';
import { getStaticMediaUrl } from 'utils/Urls';
import { DECIMAL_REGEX } from 'utils/regexes';
import { reverseLopDocumentationLink } from '../constants';
import { getRlopMonthList } from '../utils';
import { DefinedRunPayrollData, RunPayrollData } from '../utils/types';
import useRlopInfo from '../utils/useRlopInfo';
import RlopArrearBreakdown from './RlopArrearBreakdown';
import styles from './index.module.scss';

const AddReverseLopModal = ({
  payrollEntry,
  payrollMonth,
  breakdown,
  salaryRevisionBreakdown,
  onSave,
  existingRlopInfo,
  isEditing = false,
}: {
  payrollEntry: RunPayrollData;
  payrollMonth: string;
  isEditing?: boolean;
  breakdown: GetArrearsSchemaContract['rlop_breakdown'];
  salaryRevisionBreakdown: GetArrearsSchemaContract['salary_revision_breakdown'];
  existingRlopInfo: DefinedRunPayrollData;
  onSave: (data: DefinedRunPayrollData, response: GetComputedRlopArrearBreakdownContract) => void;
}) => {
  const [rlopInfo, setRlopInfo] = useState<DefinedRunPayrollData>(existingRlopInfo);
  const { closeModal, openModal } = useModal();
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number>(-1);

  const openBreakdown = (response: GetComputedRlopArrearBreakdownContract) => {
    openModal(
      <RlopArrearBreakdown
        onSubmission={(breakdown) => {
          closeModal();
          onSave(rlopInfo, { ...response, rlop_arrears_breakdown: breakdown });
        }}
        onEdit={() => closeModal()}
        payrollEntry={payrollEntry}
        breakdown={response.rlop_arrears_breakdown}
      />,
    );
  };

  useEffect(() => {
    if (existingRlopInfo.length !== 0 && isEditing && breakdown) {
      openBreakdown({
        rlop_arrears_breakdown: breakdown,
        salary_revision_breakdown: salaryRevisionBreakdown,
        is_salary_revision_arrears_modified: false,
      });
    }
  }, [isEditing]);

  const { computeRlopArrears } = useRlopInfo({
    onComputeRlopArrearsSuccess: (res) => {
      openBreakdown(res);
    },
  });

  const isCalculatingArrears = computeRlopArrears.isLoading;

  const isDataValid =
    rlopInfo.length === 0
      ? existingRlopInfo.length !== 0
      : rlopInfo.every((item) => item.rlopDays > 0);

  const computeArrears = () => {
    computeRlopArrears.mutate({
      userOrg: payrollEntry.peopleOrgId.toString(),
      requestData: {
        payroll_month: payrollMonth,
        source_details: rlopInfo.map((item) => ({
          rlop_days: item.rlopDays,
          source_month: item.month,
        })),
      },
    });
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isDataValid) {
      return;
    }
    computeArrears();
  };

  const addAnotherLopMonthRow = () => {
    setRlopInfo((prev) => {
      if (prev.length === 0) {
        const parsedMonth = parse(
          payrollMonth || format(new Date(), dateFormats.yearMonthDate),
          dateFormats.yearMonthDate,
          new Date(),
        );

        return [
          {
            month: format(subMonths(parsedMonth, 1), dateFormats.yearMonthDate),
            rlopDays: 0,
          },
        ];
      } else {
        const lastMonth = parse(prev[prev.length - 1].month, dateFormats.yearMonthDate, new Date());
        return [
          ...prev,
          {
            month: format(subMonths(lastMonth, 1), dateFormats.yearMonthDate),
            rlopDays: 0,
          },
        ];
      }
    });
  };

  const onSelectionChange = (idx: number, e: React.ChangeEvent<HTMLSelectElement>) => {
    setRlopInfo((prev) => {
      const newData = [...prev];
      newData[idx].month = e.target.value;
      return newData;
    });
  };

  const onLopDaysChange = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value.match(DECIMAL_REGEX)) {
      return;
    }
    setRlopInfo((prev) => {
      const newData = [...prev];
      newData[idx].rlopDays = parseFloat(e.target.value);
      return newData;
    });
  };

  const getMonthsList = (idx: number, payrollMonth: string | null) => {
    const monthList = getRlopMonthList(idx, payrollMonth, rlopInfo);
    return monthList;
  };

  const onDelete = (idx: number) => {
    setRlopInfo((prev) => prev.filter((_, index) => index !== idx));
  };

  return (
    <Modal>
      <div className="max-h-screen overflow-auto h-260">
        <form
          onSubmit={onSubmit}
          className={`flex flex-col h-full max-w-xl ${styles['run-payroll-modal']}`}>
          <div className="flex flex-col flex-1">
            <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
              Add Reverse LOP
            </div>
            <div className="text-xl italic leading-6 text-white-o-70 px-10 pt-8">
              Reverse Loss-of-pay(LOP) added in any previous month for the employee.{' '}
              <ExternalLink className="text-primary text-xl" href={reverseLopDocumentationLink}>
                Know More
              </ExternalLink>
            </div>
            <div className={`${styles['run-payroll-modal-content']}`}>
              <table className="w-full">
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>LOP days to be reversed </th>
                    <th></th>
                  </tr>
                </thead>

                {rlopInfo.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={clsx(
                        idx === hoveredItemIndex && styles['run-payroll-deduction-row-hover'],
                      )}>
                      <td>
                        <Select
                          onChange={(e) => onSelectionChange(idx, e)}
                          value={item.month}
                          disabled={isCalculatingArrears}>
                          {getMonthsList(idx, item.month).map((month, index) => (
                            <Option
                              key={month.toDateString()}
                              value={format(month, dateFormats.yearMonthDate)}
                              data-date={month}>
                              {format(month, dateFormats.monthYear)}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <Input
                          type="number"
                          className={styles['run-payroll-edit-number']}
                          name={`amount-${idx}`}
                          value={item.rlopDays}
                          disabled={isCalculatingArrears}
                          onChange={(event) => {
                            onLopDaysChange(idx, event);
                          }}
                        />
                      </td>
                      <td>
                        <img
                          onMouseOver={() => setHoveredItemIndex(idx)}
                          onMouseOut={() => setHoveredItemIndex(-1)}
                          src={getStaticMediaUrl(closeIcon)}
                          alt=""
                          onClick={() => onDelete(idx)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="px-10 pb-8">
              <SecondaryButton
                type="button"
                size="sm"
                onClick={addAnotherLopMonthRow}
                disabled={isCalculatingArrears}>
                + For another month
              </SecondaryButton>
            </div>
          </div>
          <div className="px-8 flex gap-4 py-8 items-center">
            <StandardButton type="submit" disabled={isCalculatingArrears || !isDataValid}>
              {isCalculatingArrears ? 'Calculating Arrears...' : 'Next'}
            </StandardButton>
            <StandardSecondaryButton
              type="button"
              onClick={closeModal}
              disabled={isCalculatingArrears}>
              CLOSE
            </StandardSecondaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddReverseLopModal;
