import {
  ActionList,
  ActionListItem,
  Alert,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  SelectInput,
  Text,
  TextInput,
  Heading,
} from '@razorpay/blade/components';
import { employeeCountCohorts, titleCohorts } from 'constants/onboarding';
import React, { useState } from 'react';
import { FormContainer } from './styles';
import { useMutation } from 'react-query';
import { SsoOnboardingRequestContract } from 'api/onboarding';
import api from 'api';
import { useL1KycData } from '../hooks/useL1KycData';
import { useOnboardingContext } from '../OnboardingContext';
import { useDispatch } from 'react-redux';
import { fetchLoggedInUser } from 'reducers/loggedInUser';

const BasicDetailsForm = () => {
  const dispatch = useDispatch();
  const { signupTitle, brandName, employeeCount, isPanPrefillComplete } = useL1KycData();
  const { setDelightScreen } = useOnboardingContext();

  const [formData, setFormData] = useState({
    brandName: brandName ?? '',
    employeeCount: employeeCount ?? '',
    title: signupTitle || '',
  });

  const { isLoading, isError, mutate } = useMutation({
    mutationFn: (onboardingData: SsoOnboardingRequestContract) =>
      api.onboarding.updateSsoOnboardingData(onboardingData),
    onSuccess: (data) => {
      if (data && data.success) {
        if (isPanPrefillComplete) {
          setDelightScreen({
            isVisible: true,
            content: delightScreenContent,
            onFinish: handleDelightFinish,
          });
          return;
        }

        dispatch(fetchLoggedInUser());
      }
    },
  });

  const handleBrandNameChange = (e: { name?: string; value?: string }) => {
    if (!e.name) return;

    setFormData({
      ...formData,
      [e.name]: e.value,
    });
  };

  const handleEmployeeCountOrTitleChange = (e: { name?: string; values: string[] }) => {
    if (!e.name) return;

    setFormData({
      ...formData,
      [e.name]: e.values[0],
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { brandName, employeeCount, title } = formData;

    mutate({
      employee_count: employeeCount,
      brand_name: brandName,
      job_title: title,
    });
  };

  const delightScreenContent = (
    <Box display="flex" flexDirection="column" gap="spacing.10" alignItems="center">
      <Heading textAlign="center" size="xlarge">
        Welcome to RazorpayX Payroll,
        <br />
        {formData.brandName}! 🎉
      </Heading>
      <Text size="large" weight="semibold">
        Taking you to your dashboard...
      </Text>
    </Box>
  );

  const handleDelightFinish = () => {
    window.location.href = '/dashboard';
  };

  return (
    (<form onSubmit={handleSubmit}>
      <FormContainer>
        <TextInput
          name="brandName"
          value={formData.brandName}
          isRequired
          label="Brand Name"
          placeholder="Eg. Razorpay"
          onChange={handleBrandNameChange}
          isDisabled={isLoading}
        />
        <Dropdown>
          <SelectInput
            name="employeeCount"
            value={formData.employeeCount}
            isRequired
            label="No. of Employees"
            onChange={handleEmployeeCountOrTitleChange}
            isDisabled={isLoading}
          />
          <DropdownOverlay>
            <ActionList>
              {employeeCountCohorts.map((item) => (
                <ActionListItem title={item} value={item} key={item} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        <Dropdown>
          <SelectInput
            name="title"
            value={formData.title}
            isRequired
            label="Your Title"
            onChange={handleEmployeeCountOrTitleChange}
            isDisabled={isLoading}
          />
          <DropdownOverlay>
            <ActionList>
              {titleCohorts.map((item) => (
                <ActionListItem title={item} value={item} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        {isError && (
          <Alert
            description="Sorry some error occurred. Please try again later"
            isDismissible
            color="negative"
          />
        )}
      </FormContainer>
      <Button type="submit" marginTop="spacing.10" isLoading={isLoading}>
        Confirm & Proceed
      </Button>
    </form>)
  );
};

export { BasicDetailsForm };
