import { ArrowLeftIcon, Box, ExternalLinkIcon, Heading, Link } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';


interface WorkflowLayoutProps {
  title: ReactNode;
  onBackButtonClick?: () => void;
  children: JSX.Element | JSX.Element[];
}

const SideNavContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <Box
      minWidth="224px"
      padding="spacing.5"
      backgroundColor="surface.background.gray.subtle">
      {children}
    </Box>
  );
};

const MainContentContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <Box
      as="section"
      flexGrow={1}
      height="100%"
      display="flex"
      flexDirection="column"
      backgroundColor="surface.background.gray.moderate">
      {children}
    </Box>
  );
};

const WorkflowLayout = ({ title, children, onBackButtonClick }: WorkflowLayoutProps) => {
  const showBackButton = !!onBackButtonClick;
  const history = useHistory();

  return (
    (<Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      position="relative"
      overflow="hidden">
      <Box
        padding={['spacing.5', 'spacing.8']}
        display="flex"
        alignItems="center"
        gap="spacing.8"
        backgroundColor="surface.background.gray.subtle"
        borderBottomWidth="thin"
        borderBottomColor="surface.border.gray.normal">
        {showBackButton && (
          <Link variant="button" icon={ArrowLeftIcon} onClick={onBackButtonClick} size="large" />
        )}
        <Heading as="h1" display={'flex'} size="medium">
          {title}
        </Heading>
        <Link href={'https://razorpay.com/docs/x/xpayroll/employers/approval-workflow/'} icon={ExternalLinkIcon} target='_blank' iconPosition='right' marginLeft={'auto'}>Workflow Setup Guide</Link>
      </Box>
      <Box flexGrow={1} display="flex" overflowY="hidden">
        {children}
      </Box>
    </Box>)
  );
};

export { WorkflowLayout, SideNavContainer, MainContentContainer };
