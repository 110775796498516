import React, { useState } from 'react';
import { TablLayoutProviderStateType, TablLayoutProviderProps } from './types';

const TabLayoutContext = React.createContext<TablLayoutProviderStateType | undefined>(undefined);

export const TabLayoutProvider: React.FC<TablLayoutProviderProps> = ({ value, children }) => {
  return <TabLayoutContext.Provider value={value}>{children}</TabLayoutContext.Provider>;
};

export const useTabLayoutValue = () => {
  const context = React.useContext(TabLayoutContext);
  if (context === undefined) {
    throw new Error('useTabLayoutValue must be used within a TabLayoutContext');
  }
  return context;
};
