import {
  ActionList,
  ActionListItem,
  Alert,
  Button,
  Divider,
  Dropdown,
  DropdownOverlay,
  Link,
  SelectInput,
  TextArea,
  TextInput,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import { salaryComponentParams } from 'components/SalaryComponents/constants';
import { useComponentParams, useDetailsView } from 'components/SalaryComponents/hooks';
import { EarningPayType } from 'components/SalaryComponents/types';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
  SwitchDescription,
  SwitchField,
} from 'components/WizardViews/components';
import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import {
  earningsGeneralDetailsStepValidationSchema,
  earningSteps,
  getEarningPayTypes,
  knowMoreItems,
} from 'components/WizardViews/SalaryComponents/constants';
import { EarningsFormData } from 'components/WizardViews/SalaryComponents/types';
import {
  checkIsPayFrequencyAdhoc,
  checkIsPayFrequencyMonthly,
} from 'components/WizardViews/SalaryComponents/utils';
import { useFormWithProvider } from 'forms';
import useFeatures from 'hooks/useFeatures';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

const GeneralDetailsStep: React.FC = () => {
  const { validate, formData, setFieldValue, register } = useFormWithProvider<EarningsFormData>();
  const { features } = useFeatures();
  const { updateStepStatus } = useWizardInfo();
  const history = useHistory();
  const { searchString } = useComponentParams();
  const { openDetailsView } = useDetailsView(salaryComponentParams.knowMore);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validate(earningsGeneralDetailsStepValidationSchema)) {
      updateStepStatus(earningSteps.step1, 'success');
      history.push({
        pathname: routePaths.salaryComponents.edit.earnings.taxationDetails,
        search: searchString,
      });
    }
  };

  const isPayFrequencyAdhoc = checkIsPayFrequencyAdhoc(formData.payType);
  const isPayFrequencyMonthly = checkIsPayFrequencyMonthly(formData.payType);

  const earningPayTypes = getEarningPayTypes(features);

  const handlePayFrequencyChange = (value: EarningPayType) => {
    setFieldValue('payType', value);
    if (value === 'adhoc') {
      setFieldValue('supportProration', false);
      setFieldValue('considerInArrear', false);
    }
  };

  //   TODO add validation for component name from server

  const alertDescription = (
    <>
      1. If display name is updated, past salary registers and payslips will reflect the new Display
      Name.
      <br />
      2.{' '}
      {isPayFrequencyMonthly
        ? 'Proration and arrears changes will only apply to future payroll months.'
        : 'Pay Frequency changes will only apply to future payroll months.'}
    </>
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Basic & pay" subtitle="Configuration" />

        <Alert
          marginBottom="spacing.7"
          isFullWidth
          title="Modify details"
          isDismissible={false}
          description={alertDescription}
        />

        <FieldGroup
          title="Basic Details"
          subtitle="Modify the component name, display name and description for this component. Changes apply to your next payslip">
          <TextInput
            label="Component Name"
            placeholder="Component Name"
            {...register('name')}
            maxCharacters={50}
            isDisabled
          />
          <TextInput
            label="Display Name"
            placeholder="Display Name"
            helpText="Customise how you'd like the components' names to appear on your employees' payslips."
            {...register('displayName')}
            maxCharacters={50}
          />
          <TextArea
            label="Description"
            placeholder="Description"
            {...register('description')}
            maxCharacters={255}
          />
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup
          title="Pay Frequency"
          subtitle="Choose how often employees receive this component as their pay.">
          <Dropdown>
            <SelectInput
              label="Pay Frequency"
              helpText="Pay frequency cannot be updated from monthly as it is currently in use in employees' salary structures"
              placeholder="Pay Frequency"
              {...register('payType', 'select')}
              isDisabled
              onChange={({ values }) => handlePayFrequencyChange(values[0] as EarningPayType)}
            />
            <DropdownOverlay>
              <ActionList>
                {Object.entries(earningPayTypes).map(([key, item]) => (
                  <ActionListItem
                    key={key}
                    title={item.label}
                    value={key}
                    description={item.description}
                  />
                ))}
              </ActionList>
            </DropdownOverlay>
          </Dropdown>
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup title="Proration & Arrears">
          <SwitchField
            label="Prorate the component"
            accessibilityLabel="Proration"
            size="small"
            {...register('supportProration', 'switch')}
            isDisabled={isPayFrequencyAdhoc}
            tooltip={isPayFrequencyAdhoc ? 'Ad hoc components cannot be prorated' : ''}>
            <SwitchDescription content="The pay will be adjusted based on the number of working days, such as for loss of pay, joining date." />
            <Link
              variant={'button'}
              marginLeft="spacing.2"
              size="small"
              onClick={() => openDetailsView(knowMoreItems.proration)}>
              Know more✨
            </Link>
          </SwitchField>
          <SwitchField
            label="Calculate arrears"
            accessibilityLabel="Calculate Arrears"
            size="small"
            {...register('considerInArrear', 'switch')}
            isComingSoon
            isDisabled={true}
            // isDisabled={isPayFrequencyAdhoc}
            // tooltip={isPayFrequencyAdhoc ? 'Arrears are not applicable to ad hoc component' : ''}
          >
            <SwitchDescription content="Choose whether to calculate arrears for this component in case of missed payments or salary revisions." />
            <Link
              variant={'button'}
              marginLeft="spacing.2"
              size="small"
              onClick={() => openDetailsView(knowMoreItems.arrearCalculation)}>
              Know more✨
            </Link>
          </SwitchField>
        </FieldGroup>
      </StepContentWrapper>
      <StepActionsWrapper>
        <span />
        <Button type="submit">Next: Taxation</Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default GeneralDetailsStep;
