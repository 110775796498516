import { Box, Skeleton } from '@razorpay/blade/components';
import React, { Suspense } from 'react';
import { StreamingText } from './StreamingText';
import { MessageFlashes } from './MessageFlashes';
import { ChatContextType, useChatContext } from '../ChatContext';
import { getProcessingAnimationMessages, getTextStreamDuration } from '../utils';
import { getStaticMediaUrl } from 'utils/Urls';
import BotProcessingPlaceholder from '../../../assets/payrollAiAssistant/BotProcessing.png';
import { ChatMessageBox } from './ChatMessageBox';
import { BotThumbnail } from './BotThumbnail';
import { useRandomPick } from '../useRandomPick';

const BotProcessingAnimation = React.lazy(() => import('./BotAnimationVideo'));

export interface ProcessingAnimationProps {
  text: string;
}

const ProcessingAnimation = ({ text }: ProcessingAnimationProps) => {
  const { conversationCount } = useChatContext() as ChatContextType;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <StreamingText>{text}</StreamingText>
      <Box margin={['spacing.0', 'auto', 'spacing.0', 'auto']} position="relative">
        <Suspense
          fallback={
            <img
              src={getStaticMediaUrl(BotProcessingPlaceholder)}
              width={320}
              height={320}
              style={{
                opacity: '0.43',
              }}
            />
          }>
          <BotProcessingAnimation />
        </Suspense>
        <MessageFlashes messages={getProcessingAnimationMessages(conversationCount)} />
      </Box>
    </Box>
  );
};

const ProcessingAnimationMessage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const botProcessingMessage = useRandomPick([
    "💪🏻 Get set for impact! We're powering through some hefty calculations right now...",
    "🚀 Hold tight! We're maneuvering through some intricate calculations...",
    '🚀 Brace for impact! Navigating through complex number crunching...',
  ]);

  return (
    <ChatMessageBox
      ref={ref}
      key="processing"
      thumbNail={
        <BotThumbnail
          streamDuration={getTextStreamDuration(botProcessingMessage)}
          isStreaming
          height="auto"
        />
      }>
      <ProcessingAnimation text={botProcessingMessage} />
    </ChatMessageBox>
  );
});

export { ProcessingAnimation, ProcessingAnimationMessage };
