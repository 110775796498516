import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { GetMeApiSchemaContract } from 'schemas/GetMeApiSchema';

type TdsFilingConfig = {
  email: string;
  deductorType: null | string;
  personResponsibleForSalary: string;
  personResponsibleForSalaryPan: string;
  personResponsibleForSalaryPin: string;
  personResponsibleForSalaryEmail: string;
  personResponsibleForSalaryState: string;
  personResponsibleForSalaryMobile: string;
  personResponsibleForSalaryAddress: string;
  personResponsibleForSalaryDesignation: string;
  personResponsibleForSalaryPanVerified: boolean;
};

const requiredFields: Array<Partial<keyof TdsFilingConfig>> = [
  'email',
  'personResponsibleForSalary',
  'personResponsibleForSalaryDesignation',
  'personResponsibleForSalaryAddress',
  'personResponsibleForSalaryState',
  'personResponsibleForSalaryPin',
  'personResponsibleForSalaryEmail',
  'personResponsibleForSalaryMobile',
  'personResponsibleForSalaryPan',
  'personResponsibleForSalaryPanVerified',
];

const isTdsFilingConfigEmpty = (
  tdsFilingConfig: NonNullable<GetMeApiSchemaContract['currentOrganization']>['tdsFilingConfig'],
) => {
  if (!tdsFilingConfig) {
    return true;
  }
  const parsedConfig = JSON.parse(tdsFilingConfig) as TdsFilingConfig;
  return requiredFields.some((field) => !parsedConfig[field]);
};

export const useIsTdsFilingConfigRequired = () => {
  const { auto24q, auto26q, tdsFilingConfig } =
    useSelector(loggedInUserSelectors.currentOrganization) || {};

  const isAutoTdsEnabled = auto24q || auto26q;

  const isTdsFilingConfigRequired = isAutoTdsEnabled && isTdsFilingConfigEmpty(tdsFilingConfig);

  return !!isTdsFilingConfigRequired;
};
