import React, { useState, ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import styles from '../index.module.scss';
import { LoanStatus } from '../constants';
import { Option, Select } from '../../ui/Form/Select/index';
import { toIndianCurrencyString } from '../../../utils/Numbers';
import api from '../../../api/index';
import { LoanDataArraySchemaContract } from '../../../schemas/LoanDataArraySchema';
import { TableWithLoader } from '../../ui/Table';
import { AppError } from '../../../utils/AppError';
import rupeeImage from '../../../assets/advance-salary-request.png';
import { getStaticMediaUrl } from 'utils/Urls';
import Pagination from 'components/ui/Pagination';
import { FETCH_LOAN_DATA } from '../queries';
import { LinkButtonV2 } from 'components/ui/Button';
import { routePaths } from 'components/Routes/data';


export default function LoansTable({hasManagePermission, userId} : {hasManagePermission?:boolean; userId?:number;}): ReactElement {

    const history = useHistory();
    const queryParam = new URLSearchParams(useLocation().search);
    const userIdParam = parseInt(queryParam.get('userId') ?? '');
    const [loanStatus, setLoanStatus] = useState<LoanStatus>(LoanStatus.ACTIVE);
    const [loanDataArray, setLoanDataArray] = useState<LoanDataArraySchemaContract | null>(null);
    const [error, setError] = useState<typeof AppError>();
    const [rowLimit, setRowLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(1);


    const { isLoading, refetch, isRefetching, data } = useQuery(FETCH_LOAN_DATA, () => {

        return api.loans.fetchLoanData({ status: loanStatus, page: page, limit: rowLimit, user_id : userIdParam ? userIdParam : hasManagePermission ? undefined : userId});

    }, {
        onSuccess: (data: LoanDataArraySchemaContract) => {
            setLoanDataArray(data);
            setTotalRows(data.total)
            setTotalPages(Math.ceil(data.total / rowLimit));
        },
        onError: (error: typeof AppError) => {
            setLoanDataArray(null);
            setError(error);
        }
    });

    useEffect(() => {
        refetch();
    }, [loanStatus, page, rowLimit]);

    return (
        <div className='mt-16'>
            <Select
                name="loanStatus"
                onChange={
                    (event: React.ChangeEvent<HTMLSelectElement>) => { setLoanStatus(parseInt(event.target.value)); }
                }
                value={loanStatus}
                className={styles['loan-status-dropdown']}
                disabled={isLoading || isRefetching}>
                <Option value={LoanStatus.ACTIVE}>Active Loans</Option>
                <Option value={LoanStatus.CLOSED}>Closed Loans</Option>
            </Select>
            <TableWithLoader isLoading={isLoading || isRefetching} showAlternateColors={true} className={`w-full ${styles['preview-table']} `}>
                <tbody>
                    <tr className={styles['dynamic-row']}>
                        <th className="text-left font-bold text-xl">EMPLOYEE ID</th>
                        <th className="text-left font-bold text-xl">NAME</th>
                        <th className="text-right font-bold text-xl">LOAN AMOUNT (₹)</th>
                        <th className="text-right font-bold text-xl">OUTSTANDING AMOUNT (₹)</th>
                        <th className="text-right font-bold text-xl">EMI AMOUNT (₹)</th>
                        <th className="text-center font-bold text-xl">REMAINING EMI TERMS</th>
                        <th className="text-left font-bold text-xl">LOAN NAME</th>
                        <th className="text-center font-bold text-xl">ACTION</th>
                    </tr>
                    {loanDataArray && loanDataArray.data.length > 0 && loanDataArray.data?.map((loanDataRecord) => (
                        <tr key={loanDataRecord.id}>
                            <td className='text-left'>{loanDataRecord.employee_id}</td>
                            <td className='text-left'><LinkButtonV2 onClick={() => { window.location.href = `/employeeDetail?userId=${loanDataRecord.people_id}`; }}>{loanDataRecord.name}</LinkButtonV2></td>
                            <td className='text-right'>{loanDataRecord.principal ? toIndianCurrencyString(loanDataRecord.principal, true) : '0'}</td>
                            <td className='text-right'>{loanDataRecord.remaining_amount_to_be_paid ? toIndianCurrencyString(loanDataRecord.remaining_amount_to_be_paid, true) : '-'}</td>
                            <td className='text-right'>{loanDataRecord.monthly_total_emi ? toIndianCurrencyString(loanDataRecord.monthly_total_emi, true) : '0'}</td>
                            <td className='text-center'>{`${loanDataRecord.remaining_duration} of ${loanDataRecord.tenure}`}</td>
                            <td className='text-left'>{loanDataRecord.remarks}</td>
                            <td className='text-center'><LinkButtonV2 onClick={() => { history.push(`${routePaths.loans.manageLoan}?loanId=${loanDataRecord.id}`) }}>{ hasManagePermission ? 'Manage' : 'View'}</LinkButtonV2></td>
                        </tr>
                    ))}
                </tbody>
            </TableWithLoader>
            {loanDataArray && loanDataArray.data.length > 0 &&
                <Pagination
                    className='mt-4 mr-4 ml-4'
                    totalRows={totalRows}
                    limitOptions={[5, 10, 15, 20]}
                    limit={rowLimit}
                    onLimitChange={setRowLimit}
                    totalPages={totalPages}
                    currentPage={page}
                    onPageChange={setPage}
                />}

            {(!loanDataArray || loanDataArray.data.length === 0) && !(isLoading || isRefetching) &&
                <div className={styles['no-data-note']}>
                    <img src={getStaticMediaUrl(rupeeImage)} alt="" className={styles['no-data-note-img']} />
                    <div className={styles['no-data-note-text']}>No {loanStatus === LoanStatus.ACTIVE ? 'active' : 'closed'} loans found. Create or approve a loan request first .</div>
                </div>
            }
        </div>

    );
}