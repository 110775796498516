import {
  ActionList,
  ActionListItem,
  DropdownOverlay,
  SelectInput,
  TableCell,
  TableEditableDropdownCell,
} from '@razorpay/blade/components';
import React from 'react';

interface TypeCellProps {
  type: string;
  isTypeEditDisabled: boolean;
  onTypeChange: (value: string) => void;
}

const areEqual = (prevProps: TypeCellProps, nextProps: TypeCellProps) => {
  return (
    prevProps.type === nextProps.type &&
    prevProps.isTypeEditDisabled === nextProps.isTypeEditDisabled
  );
};

const TypeCell = ({ type, isTypeEditDisabled, onTypeChange }: TypeCellProps) => {
  if (isTypeEditDisabled) {
    return <TableCell>{type}</TableCell>;
  }

  return (
    <TableEditableDropdownCell>
      <SelectInput
        label=""
        placeholder="Type"
        value={type}
        onChange={(e) => onTypeChange(e.values[0])}
      />
      <DropdownOverlay>
        <ActionList>
          <ActionListItem title="Percentage" value="percentage" />
          <ActionListItem title="Fixed" value="fixed" />
        </ActionList>
      </DropdownOverlay>
    </TableEditableDropdownCell>
  );
};

TypeCell.displayName = 'TypeCell';

export default React.memo(TypeCell, areEqual);
