import { Amount, Box, Text } from '@razorpay/blade/components';
import React from 'react';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';
import { transformEditPayrollRequestData } from '../EditPayroll/utils';
import { NewToOldAmount } from './NewToOldAmount';
import { NewToOldAmountWithDelta } from './NewToOldAmountWithDelta';
import { Divider } from '@razorpay/blade/components';
import { DetailsSkeleton } from 'components/ui/Skeletons/DetailsSkeleton';

export interface EditPayrollDetailsProps {
  workflowRequestDetails?: GetWorkflowRequestDetailsContract;
  isLoading?: boolean;
}

const EditPayrollDetails = ({ workflowRequestDetails, isLoading }: EditPayrollDetailsProps) => {
  if (isLoading || !workflowRequestDetails) {
    return <DetailsSkeleton rowCount={8} />;
  }

  const requestData = transformEditPayrollRequestData(
    workflowRequestDetails.workflow_requests_data,
    workflowRequestDetails.people_metadata,
  );

  const details = [
    {
      label: 'Employee Details',
      content: (
        <Box display="flex" flexDirection="column">
          <Text color="surface.text.gray.muted">{requestData.employeeDetails.name}</Text>
          <Text variant="caption" color="surface.text.gray.muted" size="small">
            {requestData.employeeDetails.employeeId}
          </Text>
        </Box>
      ),
    },
    {
      label: 'Payroll Month',
      content: (
        <Box>
          <Text color="surface.text.gray.muted">{requestData.payrollMonth}</Text>
        </Box>
      ),
    },
    {
      label: 'Total Additions',
      content: (
        <Box flexGrow="1">
          <NewToOldAmountWithDelta
            newAmount={requestData.additions.newSum ?? 0}
            oldAmount={requestData.additions.oldSum ?? 0}
          />
        </Box>
      ),
      breakupComponents: Object.values(requestData.additions.new ?? {}).map((item) => ({
        componentName: item.name,
        content: <Amount value={item.amount as any as number} type="body" size="small" />,
      })),
    },
    {
      label: 'Total Deductions',
      content: (
        <Box flexGrow="1">
          <NewToOldAmountWithDelta
            newAmount={requestData.deductions.newSum ?? 0}
            oldAmount={requestData.deductions.oldSum ?? 0}
          />
        </Box>
      ),
      breakupComponents: (requestData.deductions.new ?? []).map((item) => ({
        componentName: item.name,
        content: <Amount value={item.amount as any as number} type="body" size="small" />,
      })),
    },
    {
      label: 'Arrears',
      content: (
        <NewToOldAmount newAmount={requestData.arrears.new} oldAmount={requestData.arrears.old} />
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="spacing.5">
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text weight="semibold" size="large" color="surface.text.gray.muted">
          Edit Payroll
        </Text>
        <Text size="large" color="surface.text.gray.disabled">
          Details
        </Text>
      </Box>
      {details.map(({ label, content, breakupComponents }, index) => (
        <>
          <Box display="flex" flexDirection="column" gap="spacing.5">
            <Box display="flex" gap="spacing.5" alignItems="baseline" key={index}>
              <Box width="120px">
                <Text color="surface.text.gray.muted">{label}</Text>
              </Box>
              {content}
            </Box>
            {breakupComponents && breakupComponents?.length > 0 && (
              <Box>
                {breakupComponents?.map(({ componentName, content }) => (
                  <Box>
                    <Box display="flex" gap="spacing.5" alignItems="baseline">
                      <Box width="120px">
                        <Text size="small" color="surface.text.gray.muted">
                          {componentName}
                        </Text>
                      </Box>
                      <Box>{content}</Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>

          <Box display="flex" width="100%" justifyContent="center" marginTop={'spacing.2'}>
            <Divider
              orientation="horizontal"
              dividerStyle="solid"
              alignSelf="center"
              variant="subtle"
              thickness="thick"
            />
          </Box>
        </>
      ))}
    </Box>
  );
};

export { EditPayrollDetails };
