import { routePaths } from 'components/Routes/data';
import { WarningAlert } from 'components/ui/Alert';
import { PrimaryButton, SecondaryButton, StandardButton } from 'components/ui/Button';
import { Card } from 'components/ui/Card';
import ErrorMessage from 'components/ui/ErrorMessage';
import { Input, InputElement } from 'components/ui/Form/Input';
import { useModal } from 'components/ui/Modals';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { LoanDataArraySchemaContract } from 'schemas/LoanDataArraySchema';
import { LoanEditRequestSchemaContract } from 'schemas/LoanEditRequestSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import api from '../../api/index';
import LoanConfirmChanges from './components/LoanConfirmChanges';
import LoanRecoveryPreviewTable from './components/LoanRecoveryPreviewTable';
import { LoanRecoveryOption, LoanStatus } from './constants';
import styles from './index.module.scss';
import { FETCH_SINGLE_LOAN_RECORD } from './queries';
import { useSearchParams } from 'hooks/useSearchParams';

const LoanChangeTenure = () => {
  const queryParam = useSearchParams().urlParams;
  const loanId = queryParam.get('loanId');
  const { openModal, closeModal } = useModal();
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const [requestData, setRequestData] = useState<LoanEditRequestSchemaContract | null>(null);
  const [previewError, setPreviewError] = useState<typeof AppError | null>(null);

  const errorsToShowBelowTable = [
    'LOAN_TENURE_CANNOT_BE_EQUAL_TO_THE_DURATION_COMPLETED',
    'LOAN_SKIP_EMI_OPTION_NOT_POSSIBLE_KEEPING_TENURE_SAME',
    'INVALID_DATA',
  ];

  let showPreviewErrorOnTop = false;
  let previewErrorMessage = '';
  if (previewError) {
    const previewErrorDetails = getAppErrorDetails(previewError);
    showPreviewErrorOnTop = !errorsToShowBelowTable.includes(previewErrorDetails.code);
    previewErrorMessage = previewErrorDetails.messageForUser;
  }

  const handleErrorOnConfirm = (error: typeof AppError) => {
    const response = getAppErrorDetails(error);
    setError(response.messageForUser);
    document.querySelector('#react')?.scrollTo(0, 0);
  };

  const fetchLoan = useQuery(
    FETCH_SINGLE_LOAN_RECORD,
    () => {
      if (!loanId) {
        return null;
      }
      return api.loans.fetchLoanData({ loan_id: parseInt(loanId), page: 1 });
    },
    {
      onSuccess: (data) => {
        redirectIfLoanIsClosed(data);
        setRequestData({
          action: LoanRecoveryOption.CHANGE_TENURE,
          tenure: data?.data[0].tenure,
        });
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        setError(response.messageForUser);
      },
    },
  );

  const redirectIfLoanIsClosed = (fetchLoanData: LoanDataArraySchemaContract | null) => {
    if (fetchLoanData?.data[0].status === LoanStatus.CLOSED) {
      history.push(`${routePaths.loans.manageLoan}?loanId=${loanId}`);
    }
  };

  let emiRemainingContentLeft = 'Loading...';
  let emiRemainingContentRight = '';
  if (fetchLoan.data) {
    const remainingEMIs = fetchLoan.data.data[0].remaining_duration;
    const tenure = fetchLoan.data.data[0].tenure;
    emiRemainingContentLeft = `⚡️ Current remaining EMI terms: `;
    emiRemainingContentRight = `${remainingEMIs} of ${tenure}`;
  }

  return (
    <>
      <div className={styles['change-tenure-parent']}>
        {error && <WarningAlert className="my-6">{error}</WarningAlert>}
        {showPreviewErrorOnTop && (
          <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
            <ErrorMessage title={''}>{previewErrorMessage}</ErrorMessage>
            <Link to={`${routePaths.loans.manageLoan}?loanId=${loanId}`}>
              <StandardButton className="mt-12">Go Back</StandardButton>
            </Link>
          </Card>
        )}
        {!showPreviewErrorOnTop && (
          <>
            <div>
              <div className={styles['change-tenure-title']}>Modify Loan Duration</div>
              <span className={styles['change-tenure-info']}>
                Increase or decrease loan tenure.
              </span>
            </div>
            <Card className={styles['change-tenure-text-box']}>
              <p>{emiRemainingContentLeft}</p>
              <p className={styles['right-text']}>{emiRemainingContentRight} </p>
            </Card>

            {loanId && requestData && (
              <LoanRecoveryPreviewTable
                requestData={requestData}
                loanId={parseInt(loanId)}
                setPreviewError={setPreviewError}>
                {({ isLoading }) => {
                  return (
                    <>
                      <div className={styles['new-installment-parent']}>
                        <InputElement
                          required
                          label="New loan duration"
                          className={styles['installment-input-element']}>
                          <div className={styles['installment-input-plus-preview-button']}>
                            <Input
                              className={styles['installment-input-item']}
                              type="text"
                              maxLength={3}
                              required
                              disabled={isLoading}
                              name="modifiedInstallmentTerms"
                              placeholder="Enter the new duration"
                              customErrorMessage="Invalid amount"
                              id="new-tenure"
                            />
                            <SecondaryButton
                              className={styles['preview-button']}
                              disabled={isLoading}
                              onClick={() => {
                                setRequestData({
                                  action: LoanRecoveryOption.CHANGE_TENURE,
                                  tenure: parseInt(
                                    (document.getElementById('new-tenure') as HTMLInputElement)
                                      .value,
                                  ),
                                });
                              }}>
                              Preview New Tenure
                            </SecondaryButton>
                          </div>
                        </InputElement>
                      </div>
                      <p className={styles['recovery-preview-text']}>
                        Preview changes before confirming
                      </p>
                    </>
                  );
                }}
              </LoanRecoveryPreviewTable>
            )}

            {loanId && requestData && !previewError && (
              <PrimaryButton
                className={styles['confirm-button']}
                onClick={function () {
                  openModal(
                    <LoanConfirmChanges
                      content={`Loan duration will change from ${
                        fetchLoan.data?.data[0].tenure
                      } to ${(document.getElementById('new-tenure') as HTMLInputElement).value}.`}
                      requestData={{
                        action: LoanRecoveryOption.CHANGE_TENURE,
                        tenure: parseInt(
                          (document.getElementById('new-tenure') as HTMLInputElement).value,
                        ),
                      }}
                      loanId={parseInt(loanId)}
                      handleErrorOnConfirm={handleErrorOnConfirm}
                    />,
                  );
                }}>
                Confirm
              </PrimaryButton>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LoanChangeTenure;
