import React from 'react';
import InputBoxWrapper from './FnfInputBoxWrapper';
import { Box, TextInput } from '@razorpay/blade/components';
import { isNumeric } from 'utils/Numbers';
import {
  isValidLeaveEncashMentDays,
  isValidLeaveEncashmentDaysFeatureFlag
} from '../utils';
import useFeature from "../../../hooks/useFeature";

type FnfLeaveEncashmentProps = {
  numberOfLeaves: string | null;
  leaveEncashmentAmount: number | null;
  onChangeLeaveEncashment: (numberOfLeaves: string | null, amount: number | null) => void;
  isFnfProcessed: boolean;
  isHoldSalaryPayComplianceEnabled: boolean;
};
const FnfLeaveEncashment = ({
  numberOfLeaves,
  leaveEncashmentAmount,
  onChangeLeaveEncashment,
  isFnfProcessed,
  isHoldSalaryPayComplianceEnabled
}: FnfLeaveEncashmentProps) => {
  const {isLeaveEncashmentDaysFilterEnabled}  = useFeature('leaveEncashmentDaysFilter');
  const onChangingNumberOfLeaves = (numberOfLeaves: string | undefined) => {
    if (numberOfLeaves === '') {
      onChangeLeaveEncashment(null, leaveEncashmentAmount);
    } else {
      if (!isLeaveEncashmentDaysFilterEnabled) {
        if (isValidLeaveEncashMentDays(numberOfLeaves)) {
          onChangeLeaveEncashment(numberOfLeaves ?? null, leaveEncashmentAmount);
        }
      } else {
        if (isValidLeaveEncashmentDaysFeatureFlag(numberOfLeaves)) {
          onChangeLeaveEncashment(numberOfLeaves ?? null, leaveEncashmentAmount);
        }
      }
    }
  };

  const onChangingLeaveEncashmentAmount = (leaveEncashmentAmount: string | undefined) => {
    if (leaveEncashmentAmount === '') {
      onChangeLeaveEncashment(numberOfLeaves, null);
    } else if (isNumeric(leaveEncashmentAmount)) {
      onChangeLeaveEncashment(numberOfLeaves, Number(leaveEncashmentAmount));
    }
  };
  return (
    <InputBoxWrapper heading="Leave Encashment">
      <Box display="flex" flexDirection="column" gap="36px">
        <TextInput
          label="Number of leaves to encash"
          placeholder="0"
          type="number"
          isDisabled={isHoldSalaryPayComplianceEnabled && isFnfProcessed}
          helpText="You can see the current leave balance(s) on the right sidebar, if any."
          value={numberOfLeaves?.toString() ?? ''}
          onChange={(e) => {
            onChangingNumberOfLeaves(e.value);
          }}
        />
        <TextInput
          label="Leave encashment amount"
          placeholder="0"
          type="number"
          isDisabled={isHoldSalaryPayComplianceEnabled && isFnfProcessed}
          value={leaveEncashmentAmount?.toString() ?? ''}
          helpText="Please leave this blank for XPayroll to auto-calculate encashment based on the number of leaves entered above. If entering an amount here, then please ensure the number of leaves encashed above is also entered."
          prefix="₹"
          onChange={(e) => {
            onChangingLeaveEncashmentAmount(e.value);
          }}
        />
      </Box>
    </InputBoxWrapper>
  );
};

export default FnfLeaveEncashment;
