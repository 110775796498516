import React from 'react';
import { useSelector } from 'react-redux';

import { SuccessAlert, WarningAlert } from 'components/ui/Alert';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const InfoDisplay = () => {
  const data = useRunPayrollDataContext();
  const isRXIntegrated = useSelector(loggedInUserSelectors.isOrgRXIntegrated);
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const autoExecutePayroll = currentOrganization?.autoExecutePayroll;

  if (!data) {
    return null;
  }

  const { isLocalOrRXBalanceSufficient, isPayrollFinalized } = data;

  return (
    <>
      {!isLocalOrRXBalanceSufficient ? (
        isRXIntegrated ? (
          <WarningAlert className="mb-10">
            <span>
              XPayroll cannot process this payroll till you have sufficient balance. Please load
              funds to your RazorpayX account asap.
            </span>
          </WarningAlert>
        ) : (
          <WarningAlert className="mb-10">
            <span>
              XPayroll cannot process this payroll till you have sufficient balance. Please{' '}
              <a href="/moneyTransfer" target="_blank">
                transfer funds
              </a>{' '}
              asap.
            </span>
          </WarningAlert>
        )
      ) : (
        autoExecutePayroll && (
          <SuccessAlert className="mb-10">
            Your payroll is on auto-pilot and will be processed automatically.
          </SuccessAlert>
        )
      )}

      {isPayrollFinalized && (
        <SuccessAlert className="mb-10">Your payroll has been finalized. </SuccessAlert>
      )}
    </>
  );
};

export default InfoDisplay;
