import React from 'react';
import {
  AlertCircleIcon,
  BadgeProps,
  CheckIcon,
  ClockIcon,
  LoaderIcon,
  StepItemIcon,
  StepItemProps,
} from '@razorpay/blade/components';
import {
  ActionStatus,
  ComplianceActionStatus,
  PayrollActionStatus,
} from 'components/RunPayroll/ComplianceActions/types';

export const complianceActionsMap = {
  runPayroll: 'run-payroll',
  payPt: 'pay-pt',
} as const;

export const actionPaymentNameMap = {
  [complianceActionsMap.runPayroll]: 'Payroll',
  [complianceActionsMap.payPt]: 'Professional Tax',
};

export const actionStatusesMap = {
  notInitiated: 'not_initiated',
  inProgress: 'in_progress',
  failed: 'failed',
  returnFailed: 'return_failed',
  success: 'success',
  paidManually: 'paid_manually',

  pending: 'pending',
  finalized: 'finalized',
  processing: 'processing',
  partiallyExecuted: 'partially_executed',
  completelyExecuted: 'completely_executed',
} as const;

export const initiatedComplianceActionStatuses: Array<Partial<ComplianceActionStatus>> = [
  actionStatusesMap.inProgress,
  actionStatusesMap.failed,
  actionStatusesMap.success,
  actionStatusesMap.paidManually,
  actionStatusesMap.returnFailed,
];

export const runPayrollActionCompletedStatuses: Array<Partial<PayrollActionStatus>> = [
  actionStatusesMap.completelyExecuted,
  actionStatusesMap.partiallyExecuted,
];

export const stepItemMarkerMap: Record<ActionStatus | 'overdue' | 'blocked', StepItemProps['marker']> = {
  [actionStatusesMap.pending]: <StepItemIcon icon={ClockIcon} color="neutral" />,
  [actionStatusesMap.processing]: <StepItemIcon icon={LoaderIcon} color="primary" />,
  [actionStatusesMap.finalized]: <StepItemIcon icon={ClockIcon} color="neutral" />,
  [actionStatusesMap.partiallyExecuted]: <StepItemIcon icon={CheckIcon} color="positive" />,
  [actionStatusesMap.completelyExecuted]: <StepItemIcon icon={CheckIcon} color="positive" />,

  [actionStatusesMap.notInitiated]: <StepItemIcon icon={ClockIcon} color="neutral" />,
  [actionStatusesMap.inProgress]: <StepItemIcon icon={LoaderIcon} color="primary" />,
  [actionStatusesMap.failed]: <StepItemIcon icon={AlertCircleIcon} color="negative" />,
  [actionStatusesMap.returnFailed]: <StepItemIcon icon={AlertCircleIcon} color="negative" />,
  [actionStatusesMap.success]: <StepItemIcon icon={CheckIcon} color="positive" />,
  [actionStatusesMap.paidManually]: <StepItemIcon icon={CheckIcon} color="positive" />,
  overdue: <StepItemIcon icon={AlertCircleIcon} color="notice" />,
  blocked: <StepItemIcon icon={ClockIcon} color="notice" />,
};

export const badgeColorLabelMap: Partial<
  Record<ActionStatus | 'overdue', { label: string; color: BadgeProps['color'] }>
> = {
  [actionStatusesMap.pending]: { label: 'Approval Pending', color: 'information' },
  [actionStatusesMap.processing]: { label: 'Processing', color: 'primary' },
  [actionStatusesMap.partiallyExecuted]: { label: 'Completed', color: 'positive' },
  [actionStatusesMap.completelyExecuted]: { label: 'Completed', color: 'positive' },

  [actionStatusesMap.inProgress]: { label: 'Processing', color: 'primary' },
  [actionStatusesMap.failed]: { label: 'Failed', color: 'negative' },
  [actionStatusesMap.returnFailed]: { label: 'Failed', color: 'negative' },
  [actionStatusesMap.success]: { label: 'Paid', color: 'positive' },
  [actionStatusesMap.paidManually]: { label: 'Paid Manually', color: 'positive' },
  overdue: { label: 'Overdue', color: 'notice' },
};

export const statusColorLabelMap: Record<
  ComplianceActionStatus,
  { label: string; color: BadgeProps['color'] }
> = {
  [actionStatusesMap.notInitiated]: { label: 'Not Initiated', color: 'neutral' },
  [actionStatusesMap.inProgress]: { label: 'Processing', color: 'primary' },
  [actionStatusesMap.failed]: { label: 'Failed', color: 'negative' },
  [actionStatusesMap.returnFailed]: { label: 'Failed', color: 'negative' },
  [actionStatusesMap.success]: { label: 'Paid', color: 'positive' },
  [actionStatusesMap.paidManually]: { label: 'Paid Manually', color: 'positive' },
};

export const ptSummaryDescriptionMap: Record<ComplianceActionStatus | 'overdue', string> = {
  [actionStatusesMap.notInitiated]:
    'Please proceed to initiate your Professional Tax payment through our platform or complete the payment manually.',
  [actionStatusesMap.inProgress]:
    'Your payment has been initiated. We’ll update you on the payment status.',
  [actionStatusesMap.failed]: 'Please try again, or you may complete the payment manually.',
  [actionStatusesMap.returnFailed]: 'Please try again',
  [actionStatusesMap.paidManually]:
    'Your payment was successful. No further action is needed. Thank you!',
  [actionStatusesMap.success]:
    'Your payment was successful. No further action is needed. Thank you!',
  overdue:
    'Your Professional Tax payment is past the deadline. Please complete the payments externally.',
};

export const payrollSummaryDescriptionMap: Record<PayrollActionStatus, string> = {
  [actionStatusesMap.pending]:
    'Payroll finalization has been sent for approval and will be executed once approved. You will receive an email notification upon completion.',
  [actionStatusesMap.notInitiated]:
    'If you would like to execute this payroll now, then please confirm below. You will receive an email notification once it happens.',
  [actionStatusesMap.finalized]:
    'If you would like to execute this payroll now, then please confirm below. You will receive an email notification once it happens.',
  [actionStatusesMap.processing]:
    'Payroll has been executed and is now being processed. You will receive an email notification once the process is complete.',
  [actionStatusesMap.partiallyExecuted]: 'Payroll has been successfully executed.',
  [actionStatusesMap.completelyExecuted]: 'Payroll has been successfully executed.',
};

export const karnatakaManualPTPaymentDocsLink =
  'https://razorpay.com/docs/payroll/professional-tax/#pt-payments-via-pt-portal';

export const karnatakaPTPaymentDocsLink = 'https://razorpay.com/docs/payroll/professional-tax';
