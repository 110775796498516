import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-evenly;
  & > * {
    width: 100%;
    flex: 1;
  }
`;

export const TableRow = styled.tr`
  & td {
    width: 100%;
  }
`;

export const TableHeaderText = styled.div<{ width?: string }>`
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: ${({ theme }) => theme.typography.fonts.family.text};
  color: ${({ theme }) => theme.colors.surface.text.gray.disabled};
  min-width: ${({ width }) => (width ? width : 'auto')};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Table = styled.table`
  width: 100%;
  & tr th {
    padding-bottom: 10px;
    width: 20%;
  }
  & tr td {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 20%;
    text-align: end;
  }

  & tr th:first-of-type,
  tr td:first-of-type {
    text-align: start;
  }
`;
