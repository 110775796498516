import { AmountProps } from '@razorpay/blade/components';
import { actionStatusesMap } from 'components/RunPayroll/ComplianceActions/data';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';
import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';

export const getDataForPtDetailsView = (
  action: GetComplianceActionsSchemaContract['actions']['pay-pt'][string],
  payrollMonth?: string,
) => {
  const isPaymentOverdue = action.meta.is_over_due;

  const isPaymentDone =
    action.status === actionStatusesMap.inProgress ||
    action.status === actionStatusesMap.success ||
    action.status === actionStatusesMap.paidManually;

  const payrollMonthFormatted = getFormattedDateValue({
    date: payrollMonth,
    formatString: dateFormats.monthYear,
    filler: '',
  });

  const amountCurrency = (
    action.meta.amount?.[0]?.currency || 'inr'
  ).toUpperCase() as AmountProps['currency'];

  const paymentAmount = Number(action.meta.amount?.[0]?.value || 0);

  const isFailedPayment =
    action.status === actionStatusesMap.failed || action.status === actionStatusesMap.returnFailed;

  const shouldShowPayNowButton =
    action.status === actionStatusesMap.returnFailed || (!isPaymentOverdue && !isPaymentDone);

  const shouldShowMarkAsPaidButton = action.meta.mark_as_paid;

  const shouldShowPaymentButtons =
    paymentAmount > 0 && (shouldShowPayNowButton || shouldShowMarkAsPaidButton);

  const pendingPayrollEmployees = action.meta.payroll_executed_employees_pending;
  const isPayrollProcessedForAllEmployees =
    pendingPayrollEmployees === null || pendingPayrollEmployees === undefined
      ? false
      : pendingPayrollEmployees === 0;

  const shouldHidePayrollProgress =
    !action.meta.payroll_executed_employees_total ||
    action.meta.is_over_due ||
    isPayrollProcessedForAllEmployees ||
    action.meta.manual_payment_outside_payroll ||
    !(action.status === 'not_initiated' || action.status === 'failed');

  return {
    isPaymentOverdue,
    payrollMonthFormatted,
    amountCurrency,
    paymentAmount,
    isFailedPayment,
    shouldShowPayNowButton,
    shouldShowMarkAsPaidButton,
    shouldShowPaymentButtons,
    shouldHidePayrollProgress,
    isPayrollProcessedForAllEmployees,
  };
};
