import React, { useState, ReactElement } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from '../ui/MainCase/index';
import { routePaths } from '../Routes/data';
import RightSidebar from '../ui/RightSidebar/index';
import styles from './index.module.scss';
import PageWrapper from 'components/ui/PageWrapper';
import { PlainCard } from '../ui/SidebarCard/index';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { TableWithLoader } from 'components/ui/Table';
import { SummaryTiles } from './components/SummaryTiles';
import { useLocation } from 'react-router-dom';
import api from 'api';
import { AppError } from 'utils/AppError';
import { LoanDataArraySchemaContract } from '../../schemas/LoanDataArraySchema';
import { useQuery } from 'react-query';
import { FETCH_SINGLE_LOAN_RECORD } from './queries';
import { LoanStatus, InterestTypeStringMap } from './constants';
import { toIndianCurrencyString } from 'utils/Numbers';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import LoanRecoveryPreviewTable from './components/LoanRecoveryPreviewTable';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { LinkButtonV2 } from 'components/ui/Button';
import permissions from 'constants/permissions';
import { prepareUrl } from 'utils/Urls';

export interface SummaryObject {
    id: string;
    title: string;
    value: string;
}

export default function ManageLoan(): ReactElement {

    const queryParam = new URLSearchParams(useLocation().search);
    const features = useSelector(loggedInUserSelectors.features);
    const permission = useSelector(loggedInUserSelectors.permissions);
    const hasManagePermission = permission.includes(permissions.PAYROLL_PAY_ADVANCE_SALARY);
    const peopleId = useSelector(loggedInUserSelectors.peopleId);
    const loanId = queryParam.get('loanId');
    const [loanDataArray, setLoanDataArray] = useState<LoanDataArraySchemaContract | null>(null);
    const [summary,setSummary] = useState<SummaryObject[]>();
    let disableLoanFeatures = loanDataArray?.data[0].status === LoanStatus.CLOSED || loanDataArray?.data[0].is_next_emi_month_finalized ;

    const { isLoading, isRefetching, data, error } = useQuery(FETCH_SINGLE_LOAN_RECORD, () => {
        if (!loanId) {
            return null;
        }
        return api.loans.fetchLoanData({ loan_id: parseInt(loanId), page: 1, user_id: hasManagePermission ? undefined : peopleId });
    }, {
        onSuccess: (data) =>{ 
            setLoanDataArray(data);
            const outstandingAmount : SummaryObject = { id: 'outstanding-amount', title: 'Outstanding Amount', value: data?.data[0].remaining_amount_to_be_paid ? toIndianCurrencyString(data?.data[0].remaining_amount_to_be_paid) : toIndianCurrencyString(0) };
            const repaidAmount : SummaryObject = { id: 'repaid-amount', title: 'Repaid Amount', value: data?.data[0].amount_paid ? toIndianCurrencyString(data?.data[0].amount_paid) : toIndianCurrencyString(0) };
            const remainigEmiTerms : SummaryObject = { id: 'remaining-emi-terms', title: 'Remaining EMI Terms', value: data?.data ? `${data?.data[0].remaining_duration} of ${data?.data[0].tenure}` : `0 of ${data?.data[0].tenure}` };

            setSummary([outstandingAmount,repaidAmount,remainigEmiTerms]);
        },
        onError: (error : typeof AppError) => {
            return error;
        }
    });

    var loanData = loanDataArray?.data[0];

    return (
        <PageWrapper>
            <Breadcrumb
                name="Loan Details"
                urlMaps={{
                    Loans: routePaths.loans.viewLoans
                }}
            />
            <MainCase type={"fullWidth"}>
                <div className={styles['title']}>
                    Loan Details
                    {loanData?.status === LoanStatus.ACTIVE && <Pill content='ACTIVE LOAN' className={styles['title-pill']} />}
                    {loanData?.status === LoanStatus.CLOSED && <Pill content='CLOSED LOAN' className={styles['title-pill']} type={pillClassTypeMap.success} />}

                </div>

                {error &&
                    <WarningAlert>
                        <Errors errorResponse={error} />
                    </WarningAlert>}

                <TableWithLoader showAlternateColors={true} className={` w-3/4 mt-12 ${styles['table']}`} isLoading={isLoading}>
                    <tbody>
                        <tr>
                            <th className={styles['loan-detail-column-text']}>LABEL</th>
                            <th className={styles['loan-detail-column-text']}>DETAILS</th>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Employee Name</td>
                            <td className={styles['loan-detail-column-text']}>{<LinkButtonV2 onClick={() => { window.location.href = `/employeeDetail?userId=${loanData?.people_id}`; }}>{loanData?.name}</LinkButtonV2> ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Loan Name</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.remarks ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Loan Amount</td>
                            <td className={styles['loan-detail-column-text']}>{toIndianCurrencyString(loanData?.principal ?? 0)}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Total Amount (Principal + Interest)</td>
                            <td className={styles['loan-detail-column-text']}>{toIndianCurrencyString(loanData?.total_amount ?? 0)}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Interest Type</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.interest_type ? InterestTypeStringMap[loanData?.interest_type] : '-'}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Rate of Interest (per annum)</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.interest_rate == null ? '-' : `${loanData?.interest_rate}%`}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Perquisite Rate</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.perk_rate == null ? '-' : `${loanData?.perk_rate}%` }</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>Total Perquisite Amount</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.total_perk == null ? '-' : toIndianCurrencyString(loanData?.total_perk)}</td>
                        </tr>
                        <tr>
                            <td className={styles['loan-detail-column-text']}>EMI Deduction Start Month</td>
                            <td className={styles['loan-detail-column-text']}>{loanData?.recovery_start_from ? new Date(loanData?.recovery_start_from).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }) : '-'}</td>
                        </tr>
                    </tbody>

                </TableWithLoader>

                <div className={styles['sub-title']}>
                    {`Repayment Details`}
                </div>

                <SummaryTiles summaryKeyValueArray={summary} />

                <div className='mt-8'>
                    <LoanRecoveryPreviewTable loanId={parseInt(loanId ?? '')} requestData={{}} />
                </div>

            </MainCase>

{ hasManagePermission &&
            <RightSidebar>
                { loanDataArray && loanDataArray.data[0].is_next_emi_month_finalized && 
                <WarningAlert className='mb-10'>
                    <span className={'text-2xl'}>
                        {`Payroll for the month of ${new Date(loanDataArray.data[0].next_emi_month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })} is finalized`}
                    </span>
                </WarningAlert> 
                }
                { loanDataArray?.data[0].status === LoanStatus.CLOSED && 
                <WarningAlert className='mb-10'>
                    <span className={'text-2xl'}>
                        {`Loan has been closed and cannot be edited`}
                    </span>
                </WarningAlert> 
                }
                <PlainCard
                    href={disableLoanFeatures ? undefined : prepareUrl(`${window.location.origin}/loans/recovery`, {loanId: loanId})}
                    heading={'Loan Recovery'}
                >
                    Record a recovery of the full or partial loan amount
                </PlainCard>

                <PlainCard
                    href={disableLoanFeatures ? undefined : `${routePaths.loans.skipEmi}?loanId=${loanId}`}
                    heading={'Skip EMI'}
                >
                    Skip the upcoming EMI for an employee
                </PlainCard>

                <PlainCard
                    href={disableLoanFeatures ? undefined : prepareUrl(`${window.location.origin}/loans/change-tenure`, {loanId: loanId})}
                    heading={'Modify Instalment Terms'}
                >
                    Modify the terms of a loan's instalment
                </PlainCard>

                <PlainCard
                    href={`${window.location.origin}/reports/loanSummary`}
                    heading={'Report'}
                >
                    A report of all the loans for your organization
                </PlainCard>

            </RightSidebar>
}
        </PageWrapper>
    );

}