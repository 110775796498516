import { useParams } from 'react-router-dom';
import { KnitHrmsApp } from '../types';
import { routePaths } from 'components/Routes/data';
import { KnitAppsMetadata } from '../constants';

const useKnitHrmsApp = () => {
  const { knitHrmsApp } = useParams<{ knitHrmsApp: KnitHrmsApp }>();
  const hrmsAppRoutePaths = routePaths.settings[knitHrmsApp];
  const hrmsAppMetadata = KnitAppsMetadata[knitHrmsApp];

  return {
    knitHrmsApp,
    hrmsAppMetadata,
    hrmsAppRoutePaths,
  };
};

export { useKnitHrmsApp };
