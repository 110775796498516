import { Box } from '@razorpay/blade/components';
import React from 'react';
import { DeductionsDetailView, DeductionsFilters, DeductionsListView } from './index';

const DeductionsView = () => {
  return (
    <>
      <DeductionsFilters />
      <Box paddingX="spacing.8">
        <DeductionsListView />
        <DeductionsDetailView />
      </Box>
    </>
  );
};

export default DeductionsView;
