import React, { ReactElement, useState, useEffect } from 'react';
import { TableWithLoader } from '../../ui/Table/index';
import styles from '../index.module.scss';
import { toIndianCurrencyString } from 'utils/Numbers';
import { BulkUploadStagingDetailsSchemaContract } from 'schemas/BulkUploadStagingDetailsSchema';
import Pagination from 'components/ui/Pagination/index';
import Spinner from '../../ui/Spinner/index';
import { GET_BULK_UPLOAD_STAGING_RECORD } from '../queries/queries';
import { useQuery } from 'react-query';
import api from 'api';
import { LinkButtonV2 } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import { SalaryRevisionArrearBreakdownModal } from './SalaryRevisionArrearBreakdownModal';
import ErrorCard from '../components/ErrorCard';
import { AppError } from 'utils/AppError';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { SalaryRevisionCustomSalaryInputModal, SalaryRevisionFileRowType } from './SalaryRevisionCustomSalaryInputModal';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

export type arrearsBreakdownType = {
  name?: string | null;
  amount?: number | null;
};

export default function SalaryRevisionPreviewTable({
  bulkUploadRecord
}: {
  bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
}): ReactElement {
  const [rowLimit, setRowLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);
  const [bulkUploadStagingRecord, setBulkUploadStagingRecord] = useState<BulkUploadStagingDetailsSchemaContract | null>(null);

  const { openModal, closeModal } = useModal();

  const { isLoading, refetch, isRefetching, error, data } = useQuery(
    GET_BULK_UPLOAD_STAGING_RECORD,
    () => {
      if (!bulkUploadRecord) {
        return null;
      }

      return api.bulkUploads.fetchBulkUploadStagingRecord(
        bulkUploadRecord ? bulkUploadRecord.id : 0,
        page,
        rowLimit,
      );
    },
    {
      onSuccess: (data: BulkUploadStagingDetailsSchemaContract) => {
        setBulkUploadStagingRecord(data);
        setTotalRows(data.bulk_uploads_staging?.total);
        setTotalPages(Math.ceil(data.bulk_uploads_staging?.total / rowLimit));
      },
      onError: (error: typeof AppError) => {
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [rowLimit, page, bulkUploadRecord?.id]);

  if (isLoading || isRefetching) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>
    );
  }

  if (error) {
    return <ErrorCard error={error} buttonText= {'Back to Dashboard'} onClick={() => {
        window.location.href = '/dashboard';
      }} />
  }

  const hasCustom = bulkUploadStagingRecord &&
    bulkUploadStagingRecord?.bulk_uploads_staging.data?.filter((stagingRecord) => stagingRecord.file_row?.isCustomRevision);
  
  return (
    <>
      <TableWithLoader
        isLoading={isLoading}
        showAlternateColors={true}
        className={`w-full mt-0 ${styles['preview-table']} `}
        style={{marginTop:0}}>
        <tbody>
          <tr className={styles['dynamic-row']}>
            <th className="text-left">Name</th>
            <th className="text-left">Emp ID</th>
            <th className="text-left">Old gross CTC</th>
            <th className="text-left">Revised gross CTC</th>
            <th className="text-left">Salary Type</th>
            <IsFeatureDisabledForRearch feature={['isArrearsDisabled']}>
            <th className="text-left">Total arrear</th>
            <th className="text-left">Arrear Month</th>
            </IsFeatureDisabledForRearch>
          </tr>
          {bulkUploadStagingRecord &&
            bulkUploadStagingRecord?.bulk_uploads_staging.data?.map((stagingRecord) => (
              <tr key={stagingRecord.id}>
                <td>{stagingRecord.file_row?.commonProperties?.employeeName}</td>
                <td>{stagingRecord.file_row?.commonProperties?.employeeId}</td>
                <td>
                  {stagingRecord.file_row?.oldCtc
                    ? toIndianCurrencyString(parseInt(stagingRecord.file_row?.oldCtc), true)
                    : '-'}
                </td>
                <td>
                  {stagingRecord.file_row?.newCtc
                    ? (stagingRecord.file_row?.isCustomRevision 
                        ? <LinkButtonV2
                            onClick={()=>{  
                              openModal(
                                <SalaryRevisionCustomSalaryInputModal fileRow={stagingRecord.file_row ? (stagingRecord.file_row as SalaryRevisionFileRowType) : null}/>
                              );
                            }}
                            >{toIndianCurrencyString(parseInt(stagingRecord.file_row?.newCtc), true)}</LinkButtonV2> 
                        : toIndianCurrencyString(parseInt(stagingRecord.file_row?.newCtc), true))
                    : '-'}
                </td>
                <td>
                  {stagingRecord.file_row?.isCustomRevision
                    ? (<Pill content='Custom' type={pillClassTypeMap.warning} />)
                    : (<Pill content='Default' type={pillClassTypeMap.success} />)
                    }
                </td>
                <IsFeatureDisabledForRearch feature={['isArrearsDisabled']}>
                <td>{(stagingRecord.file_row?.arrearApplicable && stagingRecord.file_row?.arrearAmount && stagingRecord.file_row?.arrearAmount > 0 && stagingRecord.file_row?.arrearsBreakdown) 
                    ? <LinkButtonV2
                      onClick={()=>{  
                        openModal(
                          <SalaryRevisionArrearBreakdownModal arrearsDetails={stagingRecord.file_row?.arrearsBreakdown ? stagingRecord.file_row?.arrearsBreakdown : []}/>
                        );
                      }}
                      >{stagingRecord.file_row?.arrearAmount}</LinkButtonV2> 
                      : 0
                }</td>
                 <td>
                  {stagingRecord.file_row?.arrearApplicable && stagingRecord.file_row?.arrearMonth ? new Date(stagingRecord.file_row?.arrearMonth ?? null).toLocaleDateString('en-us', { year: 'numeric', month: 'short'})
                    : '-'}
                </td>
                </IsFeatureDisabledForRearch>
              </tr>
              
            ))}
          {!bulkUploadStagingRecord && (
            <tr>
              <td className="text-center" colSpan={7}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </TableWithLoader>

      <Pagination
        className="mt-4"
        totalRows={totalRows}
        limitOptions={[5, 10, 15, 20]}
        limit={rowLimit}
        onLimitChange={setRowLimit}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
      {
        hasCustom && hasCustom?.length > 0 && <p style={{maxWidth: "100%", fontSize: "12px", fontStyle:"italic"}} >* Any compliance contribution entered explicitly might not be in accordance with the employees' salary structure. Please visit the employee's individual profile to view XPayroll's suggestion.</p>
      }
    </>
  );
}
