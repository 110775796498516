import React from 'react';
import { OnboardingLayout, MainContent, Sidebar } from 'components/ui/OnboardingLayout';
import { Box } from '@razorpay/blade/components';

const Error = ({ children }: { children: React.ReactNode }) => {
  const handleReturnToDashboard = () => {
    window.location.href = '/dashboard';
  };

  return (
    <OnboardingLayout>
      <Sidebar
        title="RazorpayX Account Integration"
        description="Integrate your RazorpayX account with XPayroll"
        onBackClick={handleReturnToDashboard}
        progress={['INCOMPLETE', 'INCOMPLETE', 'INCOMPLETE']}></Sidebar>
      <MainContent title="" description="">
        <Box>{children}</Box>
      </MainContent>
    </OnboardingLayout>
  );
};

export default Error;
