import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import { toIndianCurrencyString } from '../../../utils/Numbers';
import { isValidPinCode } from '../../../utils/Address';
import { DeliveryType, FlexiPartners } from '../../../constants/flexibenefits';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import { PAN_REGEX, PHONE_NUMBER_REGEX, YMD_DATE_REGEX } from '../../../utils/regexes';

export function isDateValid(dateStr: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(new RegExp(YMD_DATE_REGEX)) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return true;
}

export function isValidPhoneNumber(phoneStr: string) {
  phoneStr = phoneStr.replace(/^\+91-/, '');
  phoneStr = phoneStr.replace(/^\+91/, '');
  phoneStr = phoneStr.replace(/^91-/, '');
  return phoneStr.match(new RegExp(PHONE_NUMBER_REGEX)) !== null;
}

export function isValidPanNumber(panStr: string) {
  return panStr.match(new RegExp(PAN_REGEX)) !== null;
}

export function validate(detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract, employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract) {

  let errors: string[] = [];
  let walletsHash: Record<number, GetUserOrgFlexiPartnerDetailsSchemaContract['walletBudgets'][0]> = {};

  for (const wallet of employeeFlexiBenefitsDetails.walletBudgets) {
    walletsHash[wallet.id] = wallet;
  }
  let total = 0;
  let minimumMin = null;
  for (const wallet of detailsToSync.walletBudgets) {
    if (!wallet.id || !wallet.amount) {
      continue;
    }

    if (!walletsHash[wallet.id]) {
      continue;
    }
    total = total + wallet.amount;
    let min = 0;
    let max = 0;
    for (const rule of walletsHash[wallet.id].wallet.compliance.caseWiseRules) {
      if (wallet.compliance && rule.case === wallet.compliance.case) {
        min = rule.min;
        max = rule.max;
        if (minimumMin === null) {
          minimumMin = min;
        }
        if (minimumMin > min) {
          minimumMin = min;
        }
      }
    }
    if (min > wallet.amount && wallet.amount > 0) {
      errors.push(` Minimum allocation for ${walletsHash[wallet.id].wallet.name} is ${wallet.amount}`);
    }
    if (max < wallet.amount && wallet.amount > 0) {
      errors.push(`Maximum allocation for ${walletsHash[wallet.id].wallet.name} is ${wallet.amount}`);
    }
  }

  if(employeeFlexiBenefitsDetails.isAlreadyEnabledInOtherOrganization){
    errors.push(`Sorry, you are already registered to partner from other organization, please reach out to support to deregister the old account.`);
  }

  if(employeeFlexiBenefitsDetails.email?.includes('mailinator.com')){
    errors.push(`Invalid email domain.`);
  }

  if (!detailsToSync.name || detailsToSync.name.length === 0) {
    errors.push(`Employee Full name field is mandatory`);
  }

  if (!detailsToSync.dateOfBirth || detailsToSync.dateOfBirth.length === 0) {
    errors.push(`Employee Date of birth field is mandatory`);
  }

  if (detailsToSync.dateOfBirth && !isDateValid(detailsToSync.dateOfBirth)) {
    errors.push(`Invalid Date of birth.`);
  }

  if (!detailsToSync.phoneNumber || detailsToSync.phoneNumber.length === 0) {
    errors.push(`Employee phone number field is mandatory`);
  }

  if (detailsToSync.phoneNumber && !isValidPhoneNumber(detailsToSync.phoneNumber)) {
    errors.push(`Employee phone number is not valid.`);
  }

  if (!detailsToSync.pan || detailsToSync.pan.length === 0) {
    errors.push(`Employee pan field is mandatory`);
  }

  if (detailsToSync.pan && !isValidPanNumber(detailsToSync.pan)) {
    errors.push(`Employee pan is not valid`);
  }

  if (detailsToSync.deliveryType === DeliveryType.DISPATCH_TO_EMPLOYEE) {

    if (!detailsToSync.cardDeliveryDetails.address) {
      errors.push(`Address in address field is mandatory`);
    }

    if (!detailsToSync.cardDeliveryDetails.pinCode) {
      errors.push(`Pin code in address field is mandatory`);
    }

    if (!isValidPinCode(detailsToSync.cardDeliveryDetails.pinCode)) {
      errors.push('Invalid pin code.');
    }

    if (!detailsToSync.cardDeliveryDetails.state) {
      errors.push(`State in address field is mandatory`);
    }

    if (!detailsToSync.cardDeliveryDetails.city) {
      errors.push(`City in address field is mandatory`);
    }

  }

  if (total > employeeFlexiBenefitsDetails.totalAmountAllocated) {
    errors.push(`Maximum allocation allowed is ${employeeFlexiBenefitsDetails.totalAmountAllocated}`);
  }
  minimumMin = minimumMin ?? 0;
  if (total < minimumMin) {
    errors.unshift(`Minimum allocation allowed is ${toIndianCurrencyString(minimumMin)}.`);
  }


  return errors;

}

export function getFlexiPartnerName(partner: number) {
  switch (partner) {
    case FlexiPartners.ZAGGLE:
      return 'Zaggle';
    default:
      return 'Unknown';
  }
}

export function getCurrentFinancialYear() {

  let today = new Date();
  if ((today.getMonth() + 1) <= 3) {
    return today.getFullYear();
  } else {
    return today.getFullYear() + 1;
  }
}