import { ZAGGLE_MANAGE_TAB_OPTIONS, KYC_STATUS } from "constants/flexibenefits";
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";

export const getNextPage = (currentPage: string) => {
    let nextPage: string | null = ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM;
    switch(currentPage) {
        case ZAGGLE_MANAGE_TAB_OPTIONS.WALLETS: 
            nextPage = ZAGGLE_MANAGE_TAB_OPTIONS.COMPONENT;
            break;
        case ZAGGLE_MANAGE_TAB_OPTIONS.COMPONENT: 
            nextPage = ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS;
            break;
        case ZAGGLE_MANAGE_TAB_OPTIONS.ADDRESS: 
            nextPage = ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM;
            break;
        case ZAGGLE_MANAGE_TAB_OPTIONS.CONFIRM: 
            nextPage = null;
            break;
    }
    return nextPage;
}

export const hasStartedFillingTheDetailsAlready = (data?: GetOrgFlexiPartnerDetailsSchemaContract|null): boolean => {
    let hasData = false;
    // only considering amount and wallets as the address is being prepopulated
    if(data && (data.totalAmountAllocated || data.enabledWallets?.length)) {
        hasData = true;
    }
    return hasData;
}


export const hasInitiatedKYC = (data?: GetOrgFlexiPartnerDetailsSchemaContract|null): boolean => {
    let initiatedKYC = false;
    
    if(data && data.kycData.status && (data.kycData.status === KYC_STATUS.STATUS_ON_HOLD || data.kycData.status === KYC_STATUS.STATUS_PENDING || data.kycData.status === KYC_STATUS.STATUS_VERIFIED)) {
        initiatedKYC = true;
    }
    
    return initiatedKYC;
}

export const getActionItemsCount = (data?: GetOrgFlexiPartnerDetailsSchemaContract|null): number => {
    let count = 0;

    if(data && [KYC_STATUS.STATUS_NOT_INITIATED,KYC_STATUS.STATUS_ON_HOLD,KYC_STATUS.STATUS_PENDING].includes(data.kycData.status)){
        count ++;
    }

    if(data && !data?.enableForAllEmployees){
        count ++;
    }

    return count;
}