import { Box, Heading } from '@razorpay/blade/components';
import React from 'react';
import styled from 'styled-components';
import { ChatContextType, useChatContext } from '../ChatContext';
import { useRandomPick } from '../useRandomPick';

export const Title = styled.h1(
  ({ theme }) => `
    font-family: ${theme.typography.fonts.family.text};
    font-size: 24px;
    font-weight: ${theme.typography.fonts.weight.bold};
    color: #fff;
    line-height: 1.25;
    padding: 0 ${theme.spacing[3]}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      font-size: 32px;
      padding: 0 ${theme.spacing[6]}px;
    }
  `,
);

export const GradientTitle = styled.span<{ background: string }>(
  ({ theme, background }) => `
    font-family: ${theme.typography.fonts.family.text};
    background: ${background};
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 24px;
    font-weight: ${theme.typography.fonts.weight.bold};
    padding-left: ${theme.spacing[4]}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      font-size: 32px;
    }
  `,
);

export const SubTitle = styled.span(
  ({ theme }) => `
    font-size: ${theme.typography.fonts.size[300]}px;
    color: #fff;
    padding: ${theme.spacing[3]}px ${theme.spacing[3]}px 0 ${theme.spacing[3]}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing[3]}px ${theme.spacing[6]}px 0 ${theme.spacing[6]}px;
    }
  `,
);

const WelcomeTitle = () => {
  const { userName } = useChatContext() as ChatContextType;
  const titlePrefix = useRandomPick([
    'Terrific to see you',
    'Good to see you',
    'Pleasure seeing you',
    'Delighted to have you here',
  ]);
  const titleSuffix = useRandomPick([
    "Let's kick off with some tax-saving strategies",
    "Let's work on some tax-saving strategies",
    "Let's discuss ways to save you some taxes",
    "Let's dive into some savvy tax-saving tactics",
  ]);

  return (
    (<Box display="flex" flexDirection="column">
      <Heading size="large">
        {titlePrefix}
        {userName && (
          <>
            ,
            <GradientTitle background="linear-gradient(91deg, #8BFFF1 73.37%, #C1ECFD 84.68%, #36DAC6 102.08%);">
              {userName}
            </GradientTitle>
          </>
        )}
        !
      </Heading>
      <SubTitle>I'm Ray. Your Payroll co-pilot. {titleSuffix} 😊</SubTitle>
    </Box>)
  );
};

export { WelcomeTitle };
