import { useContext } from 'react';
import { FormContext } from './FormContext';
import { FormContextValue } from './types';
import { useRegister } from './useRegister';

export function useFormWithProvider<T>() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useFormWithProvider must be used within a FormProvider');
  }

  const internalContext = context as FormContextValue<T>;
  const { register } = useRegister<T>(internalContext);

  return {
    ...internalContext,
    register,
  };
}
