import { useRef } from 'react';

export const useRandomPick = <T = string>(bag: T[]) => {
  const pickRef = useRef(getRandomPick(bag));

  return pickRef.current;
};

export const getRandomPick = <T = string>(bag: T[]) => {
  return bag[Math.floor(Math.random() * bag.length)];
};
