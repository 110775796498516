import { PayrollListSalaryComponentsQueryVariables } from 'api/gql-utils/salaryComponents/types';
import { deductionTypeFiltersMap, payTypeFiltersMap, statusFiltersMap } from './constants';

export const getTaxRegimeText = (
  underOldRegime: boolean = false,
  underNewRegime: boolean = false,
) => {
  if (underOldRegime && underNewRegime) {
    return 'Under Old & New Regime';
  } else if (underOldRegime) {
    return 'Under Old Regime';
  } else if (underNewRegime) {
    return 'Under New Regime';
  } else {
    return '';
  }
};

export const getTableItemCountLimits = (
  totalNumberOfItems: number,
  currentPage: number,
  itemsPerPage: number,
) => {
  if (totalNumberOfItems === 0) {
    return 'No items to display';
    // this case is unlikely to be shown as no data has a separate view
  }

  const startItem = currentPage * itemsPerPage + 1;
  const endItem = Math.min((currentPage + 1) * itemsPerPage, totalNumberOfItems);

  return `Showing ${startItem}-${endItem} components`;
};

export const getQueryVariables = (
  category: 'EARNINGS_CATEGORY' | 'DEDUCTIONS_CATEGORY',
  currentFilters: Record<string, string>,
): PayrollListSalaryComponentsQueryVariables => {
  const taxabilityFilter =
    currentFilters['taxability[]'] && currentFilters['taxability[]'].length > 0
      ? {
          taxExemptInOldRegime: currentFilters['taxability[]'].includes('old-regime'),
          taxExemptInNewRegime: currentFilters['taxability[]'].includes('new-regime'),
        }
      : undefined;

  return {
    category,
    status: statusFiltersMap[currentFilters.status] || statusFiltersMap.all,
    payType:
      category === 'EARNINGS_CATEGORY'
        ? currentFilters.payType
          ? payTypeFiltersMap[currentFilters.payType]
          : undefined
        : currentFilters.deduction_type
        ? deductionTypeFiltersMap[currentFilters.deduction_type]
        : undefined,
    taxability: taxabilityFilter,
  };
};
