// CSV Downloads
function downloadCSV(csv: string, filename: string) {
  let csvFile;
  let downloadLink;

  // CSV file
  csvFile = new Blob([csv], { type: 'text/csv' });

  // Download link
  downloadLink = document.createElement('a');

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = 'none';

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();
}

export function exportTableToCSV(filename: string) {
  let csv = [];
  let rows = document.querySelectorAll('table.export tr');

  for (let i = 0; i < rows.length; i++) {
    let row = [],
      cols = rows[i].querySelectorAll('td, th');

    for (let j = 0; j < cols.length; j++) {
      let text = cols[j].textContent;
      if (text === null) {
        text = '';
      }
      row.push('"' + text.replace(/\n/g, ' ').replace(/,/g, '') + '"');
    }

    csv.push(row.join(','));
  }

  // Download CSV file
  downloadCSV(csv.join('\n'), filename);
}
