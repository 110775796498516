import { PaymentType } from './types';

export const compliancePaymentModalsZIndex = 1003;

export const paymentTypes = {
  KA_PT_PAYMENT: 'KA_PT_PAYMENT',
} as const;

export const paymentNamesMap: Record<PaymentType, string> = {
  KA_PT_PAYMENT: 'PT Payment',
};
