import React, { ReactElement } from "react";

const ComponentInfo = ({flexiBenefitComponentName} : {flexiBenefitComponentName: string}): ReactElement => (
    <div className={'space-y-8'}>
        <div className={'font-bold text-5xl'}>Define Flexible benefit component in the salary structure</div>
        <ul className="list-disc mt-10 pl-6">
            <li>
                A new component named "{flexiBenefitComponentName}" will be created in the employee salary structure. This will be of the value that you enter here. 
            </li>
            <li>
                Specify the monthly amount for the component.
            </li>
        </ul>
    </div>
)

export default ComponentInfo;