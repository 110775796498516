import React from "react";

const WalletsInfo = () => (
    <div className='space-y-8'>
        <div className='font-bold text-5xl'>Select the flexible benefit wallets for your organization</div>
        <div className="mt-10">
            Select the wallets that you want to enable for your employees. Do note that is an organization level setting and the selected wallets will be available for your employees to choose from. Employees can select wallets and the limits they want for each wallet individually.
        </div>
    </div>
)

export default WalletsInfo;