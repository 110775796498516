import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface DelightScreenProps {
  children: JSX.Element[] | JSX.Element;
  onFinish?: () => void;
}

const DelightScreenContainer = styled.div<{ isVisible: boolean }>(
  ({ isVisible }) => `
    position: fixed;
    left: 0;
    top: 0;
    ${isVisible ? 'visibility: visible;' : 'visibility: hidden;'}
    ${isVisible ? 'opacity: 1;' : 'opacity: 0;'}
    transition: visibility 0s, opacity 1s linear;
    z-index: 99999;
    backdrop-filter: blur(17.5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`,
);

const DelightScreen = ({ children, onFinish }: DelightScreenProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    if (onFinish) {
      setTimeout(() => {
        onFinish();
      }, 3000);
    }
  }, []);

  return <DelightScreenContainer isVisible={isVisible}>{children}</DelightScreenContainer>;
};

export { DelightScreen };
