import React from 'react';
import { Box, Button, Heading, LockIcon, OTPInput, Text } from '@razorpay/blade/components';
import otpIcon from 'assets/verify-otp.png';
import { getStaticMediaUrl } from 'utils/Urls';
import { useMutation, useQueryClient } from 'react-query';
import {
  GET_COMPLIANCE_ACTIONS,
  SUBMIT_COMPLIANCE_ACTION_OTP,
} from 'components/RunPayroll/ComplianceActions/queries';
import api from 'api';
import { AppError } from 'utils/AppError';
import { parseError } from 'utils/Api';
import { PaymentType } from 'components/RunPayroll/ComplianceActions/components/types';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { OTP_REGEX } from 'utils/regexes';

const portalName: Record<PaymentType, string> = {
  KA_PT_PAYMENT: 'Karnataka PT Portal',
};

export const OtpScreen = ({
  otp,
  setOtp,
  error,
  setError,
  setShowSuccessScreen,
  complianceId,
  paymentType,
}: {
  otp: string;
  setOtp: (otp: string) => void;
  error: string;
  setError: (error: string) => void;
  setShowSuccessScreen: (show: boolean) => void;
  complianceId?: string | number;
  paymentType: PaymentType;
}) => {
  const queryClient = useQueryClient();
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth || '';
  const { mutate: submitComplianceOtp, isLoading } = useMutation({
    mutationKey: SUBMIT_COMPLIANCE_ACTION_OTP,
    mutationFn: api.complianceActions.submitComplianceActionOtp,
    onSuccess: () => {
      setShowSuccessScreen(true);
      queryClient.invalidateQueries({
        queryKey: [GET_COMPLIANCE_ACTIONS, payrollMonth],
      });
    },
    onError: (error: typeof AppError) => {
      const message = parseError(error);
      setError(message);
    },
  });

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;

    if (OTP_REGEX.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  const handleVerify = () => {
    if (otp.length === 6 && complianceId) {
      submitComplianceOtp({ complianceId: complianceId, otp: otp, payrollMonth });
    }
  };
  return (
    <Box
      padding="spacing.6"
      margin="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={getStaticMediaUrl(otpIcon)} alt="" width="120px" />
        <Heading size="medium">Second factor authentication</Heading>
        <Text marginTop="spacing.3" color="surface.text.gray.subtle" textAlign="center">
          One time password has been sent to your registered mobile number on the{' '}
          {portalName[paymentType]}
        </Text>
        <Box
          marginY="spacing.6"
          display="flex"
          flexDirection="column"
          paddingX="spacing.7"
          paddingTop="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <OTPInput
            label=""
            errorText={error}
            value={otp}
            name="otp-field"
            onChange={onOtpChange}
            otpLength={6}
            placeholder=""
            validationState={error ? 'error' : 'none'}
          />

          <input type="hidden" name="otp" value={otp} />

          <Button
            isLoading={isLoading}
            marginY="20px"
            isDisabled={!otp}
            onClick={handleVerify}
            isFullWidth>
            Verify
          </Button>
        </Box>

        <Box display="flex" flexDirection="column" gap="spacing.3">
          <Box display="flex" gap="spacing.2" alignItems="center">
            <LockIcon color="feedback.icon.positive.intense" size="small" />
            <Text weight="semibold" color="feedback.text.positive.intense">
              Enhanced Security
            </Text>
          </Box>
          <Text color="surface.text.gray.muted">
            {portalName[paymentType]} has introduced 2FA for enhanced security. Please enter the OTP
            to proceed with payment. Our system will handle the rest.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
