import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardHeaderLeading,
  CardHeaderLink,
  CardHeaderTrailing,
  ExternalLinkIcon,
} from '@razorpay/blade/components';
import React from 'react';

type ConfigurationCardProps = React.PropsWithChildren<{
  href: string;
}>;

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({ children, href }) => {
  return (
    <Card elevation="none" marginBottom="spacing.9">
      <CardHeader>
        <CardHeaderLeading
          title="🔒 Predefined configuration"
          subtitle="The configuration defined by the government in accordance with the regulations cannot be changed."
        />
        <CardHeaderTrailing
          visual={
            <CardHeaderLink href={href} icon={ExternalLinkIcon}>
              Income Tax Info
            </CardHeaderLink>
          }
        />
      </CardHeader>
      <CardBody>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          rowGap="spacing.7"
          columnGap="spacing.8">
          {children}
        </Box>
      </CardBody>
    </Card>
  );
};

export default ConfigurationCard;
