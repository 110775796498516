import React, { ReactElement } from 'react';
import { ActionItemsCardDataType } from './ActionItemsCardList';
import {ActionItems} from '../../../../../constants/actionItems';
import { KYC_STATUS } from '../../../../../constants/flexibenefits';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';

const ActionItemsCardStatus = ({card,status = KYC_STATUS.STATUS_NOT_INITIATED } : {card?: ActionItemsCardDataType, status?: number}):ReactElement => {

    switch(card?.id){
        case ActionItems.KYC_VERIFICATION:
            if(status === KYC_STATUS.STATUS_NOT_INITIATED ){
                return <></>;
            }
            else if(status === KYC_STATUS.STATUS_PENDING){
                return <Pill content='IN PROGRESS' type = { pillClassTypeMap.default } />

            }
            else if(status === KYC_STATUS.STATUS_ON_HOLD){
                return <Pill content='ON HOLD' type = { pillClassTypeMap.warning } />
            }
            else if(status === KYC_STATUS.STATUS_REJECTED){
                return <Pill content='REJECTED' type = { pillClassTypeMap.danger } />

            }
        break;
    }
    return <></>;
}

export default ActionItemsCardStatus;