import { Box, Skeleton } from '@razorpay/blade/components';
import React from 'react';

interface AllRowsCheckSkeletonProps {
  withCheckbox?: boolean;
}

const AllRowsCheckSkeleton = ({ withCheckbox }: AllRowsCheckSkeletonProps) => {
  return (
    <Box display="flex" gap="spacing.5">
      {withCheckbox && <Skeleton width="spacing.6" height="spacing.6" borderRadius="medium" />}
      <Skeleton width="240px" height="spacing.6" borderRadius="medium" />
    </Box>
  );
};

export { AllRowsCheckSkeleton };
