import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import EditIcon from 'assets/icon--edit.svg';
import { getArrearsTotalAmount } from 'components/RunPayroll/utils';
import { ArrearComponent } from 'components/RunPayroll/utils/types';
import useIsRlopEnabled from 'components/RunPayroll/utils/useIsRlopEnabled';
import useNewJoinerArrearEnabled from 'components/RunPayroll/utils/useNewJoinerArrearEnabled';
import { getNextMonth } from 'components/RunPayroll/utils/utils';
import { rlopButton } from 'components/RunPayroll/utils/viewHelpers';
import { LinkButtonV2, SecondaryButton } from 'components/ui/Button';
import { Input, InputElement } from 'components/ui/Form/Input';
import { TableWithLoader as Table } from 'components/ui/Table';
import pluralize from 'pluralize';
import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import { getStaticMediaUrl } from 'utils/Urls';
import styles from '../../index.module.scss';
import { EditPayrollProps } from '../types';

type ArrearsProps = EditPayrollProps & {
  isArrearBreakdownPopupEnabled: boolean;
  onArrearChange: (componentToUpdate: string, value: string) => void;
  onArrearEdit: () => void;
  arrearsData?: GetArrearsSchemaContract;
  rlopArrears: ArrearComponent[];
  salaryRevisionArrears: ArrearComponent[];
  combinedArrears: ArrearComponent[];
  isPlatformArrears: boolean;
  lastFinalizedPayrollMonth: string | null;
  onRlopArrearEdit: () => void;
  onAddReverseLOP: () => void;
  isFetchingComplianceArrears: boolean;
};

const Arrears = ({
  payrollEntry,
  payrollMonth,
  onArrearChange,
  isArrearBreakdownPopupEnabled,
  onArrearEdit,
  arrearsData,
  isPlatformArrears,
  lastFinalizedPayrollMonth,
  onRlopArrearEdit,
  onAddReverseLOP,
  rlopArrears,
  salaryRevisionArrears,
  combinedArrears,
  isFetchingComplianceArrears,
}: ArrearsProps) => {
  const { newJoinerArrearExists } = useNewJoinerArrearEnabled(payrollEntry);
  const { isRlopFeatureEnabled } = useIsRlopEnabled();
  const features = useSelector(loggedInUserSelectors.features);
  const isPFArrearsEnabled = !!features?.['pf-arrears'];

  const arrearEarnings = combinedArrears.filter((component) => component.type === 'Earnings');
  const arrearDeductions = combinedArrears.filter((component) => component.type === 'Deductions');
  const arrearMonths = arrearsData?.arrear_breakdown
    ? Object.keys(arrearsData?.arrear_breakdown).length
    : 0;

  const isRlopVisible = isRlopFeatureEnabled
    ? (lastFinalizedPayrollMonth
        ? getNextMonth(lastFinalizedPayrollMonth) === payrollMonth
        : false) || rlopArrears.length > 0
    : false;

  const rlopArrearsAmount = getArrearsTotalAmount(rlopArrears);
  const salaryRevisionArrearsAount = getArrearsTotalAmount(salaryRevisionArrears);
  const isArrearFieldsDisabled =
    (isRlopFeatureEnabled && !isPlatformArrears) || isArrearBreakdownPopupEnabled;

  const isRlopArrearsAdded = rlopArrearsAmount > 0 || rlopArrears.length > 0;
  const isSalaryRevisionArrearsAdded =
    salaryRevisionArrearsAount > 0 || salaryRevisionArrears.length > 0;

  const isFieldVisibleAsNonEditable =
    (isRlopFeatureEnabled && (isRlopArrearsAdded || isSalaryRevisionArrearsAdded)) ||
    newJoinerArrearExists;

  const getMessageForArrears = () => {
    const isSalaryRevisionArrearsEnabledAndAdded = isSalaryRevisionArrearsAdded;
    if (isRlopFeatureEnabled) {
      if (isSalaryRevisionArrearsEnabledAndAdded && isRlopArrearsAdded) {
        return 'Below arrear is sum of Salary revision & reverse LOP arrears';
      } else if (isSalaryRevisionArrearsEnabledAndAdded) {
        return 'The breakdown of salary revision arrears is below';
      } else if (isRlopArrearsAdded) {
        return 'The breakdown of reverse LOP arrears is below';
      } else if (isPlatformArrears) {
        return 'Add the arrears separately for each component';
      }
    } else {
      if (!isPFArrearsEnabled) {
        return 'We do not handle PF and ESIC arrear payments. Please manage them separately.';
      }
    }
  };

  const messageForArrears = getMessageForArrears();

  return (
    <div>
      {!isPFArrearsEnabled && (
        <div className="italic text-xl">
          We do not handle PF and ESIC arrear payments. Please manage them separately.
        </div>
      )}
      <>
        {arrearEarnings.length > 0 && (
          <>
            <div className="py-3">
              <div className="flex flex-col gap-6">
                <div className={`${styles['arrears-cta-container']} flex gap-3`}>
                  {isArrearBreakdownPopupEnabled &&
                    isRlopFeatureEnabled &&
                    salaryRevisionArrears.length > 0 && (
                      <SecondaryButton
                        size="sm"
                        type="button"
                        onClick={onArrearEdit}
                        id="add-reverse-lop">
                        Manage salary revision arrears
                      </SecondaryButton>
                    )}
                  <IsFeatureDisabledForRearch feature="isRLOPDisabled">
                    {rlopButton({
                      text: `${isRlopArrearsAdded ? 'Manage' : 'Add'} Reverse LOP`,
                      onClick: isRlopArrearsAdded ? onRlopArrearEdit : onAddReverseLOP,
                      isRlopVisible: isRlopVisible,
                      newJoinerArrearExists: newJoinerArrearExists,
                    })}
                  </IsFeatureDisabledForRearch>
                </div>

                <div className="w-full flex justify-between">
                  {isRlopFeatureEnabled ? (
                    messageForArrears
                  ) : (
                    <>
                      {isArrearBreakdownPopupEnabled ? (
                        payrollEntry.newJoinerInfo && newJoinerArrearExists ? (
                          <span>{`Earning arrears for ${
                            payrollEntry.newJoinerInfo.arrearDays +
                            pluralize(' day', payrollEntry.newJoinerInfo.arrearDays)
                          }`}</span>
                        ) : (
                          <span>{`Earning arrears for ${arrearMonths} months`}</span>
                        )
                      ) : (
                        'Earnings'
                      )}
                    </>
                  )}

                  {isArrearBreakdownPopupEnabled &&
                    !isRlopFeatureEnabled &&
                    !newJoinerArrearExists && (
                      <LinkButtonV2 onClick={onArrearEdit} id="amount-components" className="flex">
                        <img width="17" className="mr-2" src={getStaticMediaUrl(EditIcon)} />
                        Edit month-wise arrears
                      </LinkButtonV2>
                    )}
                </div>
              </div>
            </div>
            <Table
              isLoading={isFetchingComplianceArrears}
              className="w-full border border-solid border-surfaceBorderGraySubtle"
              showAlternateColors={false}>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {arrearEarnings.map((component) => {
                  return (
                    <tr key={component.name}>
                      <td>
                        {isFieldVisibleAsNonEditable ? (
                          component.name
                        ) : (
                          <Input
                            type="text"
                            name={`name-${component.name}`}
                            value={component.name ?? ''}
                          />
                        )}
                      </td>
                      <td>
                        {isFieldVisibleAsNonEditable ? (
                          component.amount
                        ) : (
                          <InputElement>
                            <Input
                              type="number"
                              disabled={isArrearFieldsDisabled}
                              className={styles['run-payroll-edit-number']}
                              name={`amount-${component.amount}`}
                              value={component.amount}
                              onChange={(event) => {
                                onArrearChange(component.name, event.target.value);
                              }}
                            />
                          </InputElement>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
        {arrearDeductions.length > 0 && (
          <>
            <div className="pt-8 pb-2">Deductions</div>
            <Table
              className="w-full border border-solid border-surfaceBorderGrayMuted"
              showAlternateColors={false}>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {arrearDeductions.map((component) => {
                  return (
                    <tr key={component.name}>
                      <td>
                        {isFieldVisibleAsNonEditable ? (
                          component.name
                        ) : (
                          <Input
                            type="text"
                            name={`name-${component.name}`}
                            value={component.name ?? ''}
                          />
                        )}
                      </td>
                      <td>
                        {isFieldVisibleAsNonEditable ? (
                          component.amount
                        ) : (
                          <InputElement>
                            <Input
                              type="number"
                              className={styles['run-payroll-edit-number']}
                              name={`amount-${component.amount}`}
                              value={component.amount}
                              disabled={isArrearFieldsDisabled}
                              onChange={(event) => {
                                onArrearChange(component.name, event.target.value);
                              }}
                            />
                          </InputElement>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </>
    </div>
  );
};

export default Arrears;
