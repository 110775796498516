import { splitzExperiments } from 'constants/splitz';
import useSplitz from 'hooks/useSplitz';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { isSplitzExperimentActive } from 'utils/splitz';
import useReduxSelector from 'utils/useReduxSelector';
import { kycVersions } from '../constants';

const useIsSelfServeP3M1Enabled = () => {
  const splitzQuery = useSplitz();
  const currentOrganization = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const kycFlowType = currentOrganization?.onboardingConfig?.kycFlowType;
  const isKycFlowV2 = kycFlowType === kycVersions.V2;

  const isSelfServeP3M1Enabled =
    isKycFlowV2 && isSplitzExperimentActive(splitzQuery.data, splitzExperiments.selfServeP3M1);

  return {
    isSelfServeP3M1Enabled,
    isLoading: splitzQuery.isFetching,
  };
};

export { useIsSelfServeP3M1Enabled };
