import React, { ReactElement } from "react";
import { LinkButtonV2 } from '../../../../ui/Button/index';
import { useHistory } from "react-router-dom";
import { routePaths } from "components/Routes/data";

const ZaggleActionItemsFooter = (): ReactElement => {

    const history = useHistory();

    const redirectToManage = () => {
        history.push(routePaths.partnerFlexiBenefits.admin.manage);
    }
    
    return (
        <>
            <div className='space-y-8 mb-4 justify-end text-2xl'>
            To modify Zaggle flexible benefit plan configurations
            <LinkButtonV2 className='ml-1' onClick={redirectToManage}>click here</LinkButtonV2>.
            </div>
        </>
    )
    
}

export default ZaggleActionItemsFooter;