import { routePaths } from 'components/Routes/data';
import { arrearTypes } from 'components/RunPayroll/constants';
import { RunPayrollData } from 'components/RunPayroll/utils/types';
import useIsRlopEnabled from 'components/RunPayroll/utils/useIsRlopEnabled';
import { getArrearWithNameFromDistribution } from 'components/RunPayroll/utils/utils';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { ModalTitle } from 'components/ui/Modals/components/Modal';
import { format, startOfMonth } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormats } from 'utils/Dates';

type UseArrearOverrideConfirmationsProps = {
  arrearsDistribution?: RunPayrollData['arrearsDistribution'];
  onConfirm: () => void;
  arrearMonth?: string;
};

const useArrearOverrideConfirmations = ({
  arrearsDistribution,
  onConfirm,
  arrearMonth,
}: UseArrearOverrideConfirmationsProps) => {
  const { isRlopFeatureEnabled } = useIsRlopEnabled();
  const { openModal } = useModal();

  const monthAsDate = new Date(arrearMonth + 'T00:00:00');
  const monthValue = monthAsDate.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const monthAsParam = format(startOfMonth(monthAsDate), dateFormats.yearMonthDate);

  const openAndConfirmOverride = () => {
    const platformArrears = getArrearWithNameFromDistribution(
      arrearTypes.platform,
      arrearsDistribution,
    );

    const rlopArrears = getArrearWithNameFromDistribution(arrearTypes.rlop, arrearsDistribution);

    if (isRlopFeatureEnabled) {
      if (platformArrears && platformArrears.value > 0) {
        openModal(
          <ConfirmModal
            confirmText="Continue"
            onConfirm={onConfirm}
            className="p-0"
            footerContainerClassName="pt-4 pb-10 px-7">
            <div>
              <ModalTitle className="border-b border-modal-header-border py-6 px-10 border-solid">
                Arrears will be overriden?
              </ModalTitle>
              <div className="p-10">
                Please note that existing arrears for this employee have been added in{' '}
                <Link
                  className="text-primary"
                  to={routePaths.runpayroll + `?payroll_month=${monthAsParam}`}>
                  {monthValue} Payroll
                </Link>
                . Salary revision arrears will override these arrears.
              </div>
            </div>
          </ConfirmModal>,
        );
      } else if (rlopArrears && rlopArrears.value > 0) {
        openModal(
          <ConfirmModal
            confirmText="Continue"
            onConfirm={onConfirm}
            className="p-0"
            footerContainerClassName="pt-4 pb-10 px-7">
            <div>
              <ModalTitle className="border-b border-modal-header-border py-6 px-10 border-solid">
                Reverse LOP Arrears will be modified
              </ModalTitle>
              <div className="p-10">
                Please note that arrears which were already added for this employee would be
                modified. You can view the existing arrears{' '}
                <Link
                  className="text-primary"
                  to={routePaths.runpayroll + `?payroll_month=${monthAsParam}`}>
                  here
                </Link>
                . Salary revision arrears will override the previously entered arrears.
              </div>
            </div>
          </ConfirmModal>,
        );
      } else {
        onConfirm();
      }
    } else {
      onConfirm();
    }
  };

  return { openAndConfirmOverride };
};

export default useArrearOverrideConfirmations;
