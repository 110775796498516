import React from 'react';
import { Box } from '@razorpay/blade/components';
import styled, { css } from 'styled-components';

const MainContent = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    return (
        <Box
            backgroundColor={'surface.background.gray.intense'}
            overflowY={'auto'}
            position={'relative'}
            display={'flex'}
            >
            {children}
        </Box>
    );
};

export const ColoredDiv = styled.div<{ color?: string }>(({ theme, color }) => {
    return css`
      width: 36px;
      height: 36px;
      padding: 8px;
      border-radius: ${theme.border.radius.medium}px;
      background: ${color};
    `;
  });

export default MainContent;