import { UploadKycDocumentApiPayload } from 'api/kyc';
import { KycDocumentsContext } from 'components/Kyc/KycDocumentsContext';
import { DocumentStatus, bvsDocumentTypes } from 'components/Kyc/constants';
import { UploadDocumentSectionProps } from 'components/Kyc/types';
import { getErrorsAsString, getMessageFromStatus } from 'components/Kyc/utils';
import { MimeType } from 'components/ui/FileUpload/constants';
import React, { useContext } from 'react';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { AppError } from 'utils/AppError';
import DocumentUpload from '../DocumentUpload';

const Fields = [bvsDocumentTypes.BUSINESS_PAN, bvsDocumentTypes.CERTIFICATE_OF_INCORPORATION];

type FieldNames = typeof Fields[number];

export const PrivateLimitedFields = ({ orgType, gstin }: UploadDocumentSectionProps) => {
  const { kycValues, updateCompanyDetailsDocument } = useContext(KycDocumentsContext);
  const data = kycValues.companyDetails;

  const onFileSelect = (
    documentType: FieldNames,
    file: File,
    upload: (payload: UploadKycDocumentApiPayload) => void,
  ) => {
    updateCompanyDetailsDocument(documentType, {
      file,
      status: DocumentStatus.UPLOADING,
      saved_filename: '',
      message: getMessageFromStatus({ status: DocumentStatus.UPLOADING }),
    });
    upload({
      documentType,
      file,
      orgType,
    });
  };

  const onSuccess = (documentType: FieldNames, response: UploadKycDocumentSchemaContract) => {
    updateCompanyDetailsDocument(documentType, {
      status: DocumentStatus.SUCCESS,
      saved_filename: response.filename || '',
      url: response.fileS3Url || '',
      message: getMessageFromStatus({ status: DocumentStatus.SUCCESS }),
    });
  };

  const onError = (documentType: FieldNames, error: AppError) => {
    updateCompanyDetailsDocument(documentType, {
      status: DocumentStatus.ERROR,
      message: getMessageFromStatus({
        status: DocumentStatus.ERROR,
        error: getErrorsAsString(error),
      }),
    });
  };

  const onFileNameClick = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  return (
    <>
      <DocumentUpload
        wrapperlabel="Company PAN*"
        value={data.business_pan?.file?.name || data.business_pan?.saved_filename}
        onChange={(files, upload) => {
          const file = files?.[0] || null;
          if (!file) return;
          onFileSelect('business_pan', file, upload);
        }}
        onSuccess={(response) => onSuccess('business_pan', response)}
        onError={(error) => onError('business_pan', error)}
        allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
        status={data.business_pan?.status}
        subText={data.business_pan?.message}
        onFilenameClick={() => onFileNameClick(data.business_pan?.url)}
      />
      <DocumentUpload
        wrapperlabel="Certificate of Incorporation*"
        value={
          data.certificate_of_incorporation?.file?.name ||
          data.certificate_of_incorporation?.saved_filename
        }
        onChange={(files, upload) => {
          const file = files?.[0] || null;
          if (!file) return;
          onFileSelect('certificate_of_incorporation', file, upload);
        }}
        onSuccess={(response) => onSuccess('certificate_of_incorporation', response)}
        onError={(error) => onError('certificate_of_incorporation', error)}
        allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
        status={data.certificate_of_incorporation?.status}
        subText={data.certificate_of_incorporation?.message}
        onFilenameClick={() => onFileNameClick(data.certificate_of_incorporation?.url)}
      />
    </>
  );
};
