import { Box, BoxProps, Heading } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import noDataFound from 'assets/NoDataFound.svg';
import { getStaticMediaUrl } from 'utils/Urls';

const NoDataFoundImage = styled.img`
  width: 162px;
  height: 162px;
  margin-left: auto;
  margin-right: auto;
`;

export const EmptyListView = ({
  title,
  description,
  backgroundColor = 'surface.background.gray.intense',
  children,
}: React.PropsWithChildren<{
  title: string;
  description?: ReactNode;
  backgroundColor?: BoxProps['backgroundColor'];
}>) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      width={'100%'}
      paddingTop={'spacing.8'}
      paddingBottom={'spacing.11'}
      display={'flex'}
      justifyContent={'center'}>
      <Box display={'flex'} flexDirection={'column'} width={'372px'} alignContent={'center'}>
        <NoDataFoundImage src={getStaticMediaUrl(noDataFound)} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign={'center'}
          gap="spacing.2"
          marginBottom={'spacing.4'}>
          <Heading size="medium" testID={'empty-list-view'}>
            {title}
          </Heading>
          {description}
        </Box>
        {children}
      </Box>
    </Box>
  );
};
