import React, { useEffect } from 'react';
import { FormContainer } from 'components/Onboarding/CompanyDetails/styles';
import { AddAddress } from './AddAddress';
import { Box, TextInput } from '@razorpay/blade/components';
import { useVerifyCompanyDetailsContext } from './VerifyCompanyDetailsContext';
import { useLocation } from 'react-router-dom';

const Manual = () => {
  const { setAlertBannerPropsContext } = useVerifyCompanyDetailsContext();
  const {
    state: { gstin, cinOrLlpin, signatoryPan, errorFetchingAddressFromBvs },
  } = useLocation<{
    gstin?: string;
    cinOrLlpin?: string;
    signatoryPan?: string;
    errorFetchingAddressFromBvs?: boolean;
  }>();

  useEffect(() => {
    if (errorFetchingAddressFromBvs) {
      setAlertBannerPropsContext({
        color: 'negative',
        description:
          'Something went wrong on our end, please fill the rest of the details in order to proceed for verification.',
        isDismissible: false,
      });
    }
  }, [errorFetchingAddressFromBvs]);

  return (
    <Box display="flex" flexDirection="column" gap="spacing.8">
      <FormContainer>
        <TextInput
          label={gstin ? 'GSTIN' : 'CIN/LLPIN'}
          placeholder={gstin ? 'Eg. 22AAAAA0000A1Z5' : 'Eg. U67190TN2014PTC096971 or AAA-0001'}
          value={gstin ?? cinOrLlpin}
          isDisabled
        />
        <TextInput label="Authorised Signatory PAN" value={signatoryPan} isDisabled />
        <AddAddress />
      </FormContainer>
    </Box>
  );
};

export { Manual };
