// import { MockGeolocation } from './MockGeoLocation';

// const mockGeolocation = new MockGeolocation();

// Object.defineProperty(global.navigator, 'geolocation', {
//   value: mockGeolocation,
//   configurable: true,
// });

export enum LocationError {
  PERMISSION_DENIED = '-1',
  POSITION_UNAVAILABLE = '-2',
  TIMEOUT = '-3',
  UNKNOWN = '-4',
  GEOLOCATION_UNSUPPORTED = '-5',
}

export function isLocationError(code: string) {
  return Object.values(LocationError).includes(code as LocationError);
}

export function getLocation(): Promise<string> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = `${position.coords.latitude},${position.coords.longitude}`;
          resolve(location);
        },
        (error) => {
          let location: string;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              location = LocationError.PERMISSION_DENIED;
              break;
            case error.POSITION_UNAVAILABLE:
              location = LocationError.POSITION_UNAVAILABLE;
              break;
            case error.TIMEOUT:
              location = LocationError.TIMEOUT;
              break;
            default:
              location = LocationError.UNKNOWN;
              break;
          }
          reject(location);
        },
      );
    } else {
      reject(LocationError.GEOLOCATION_UNSUPPORTED);
    }
  });
}
