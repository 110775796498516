import { Box, Divider, Text } from '@razorpay/blade/components';
import React from 'react';

interface ActionCardProps {
  title: string;
  children: JSX.Element;
}

const ActionCard = ({ title, children }: ActionCardProps) => {
  return (
    (<Box
      display="flex"
      flexDirection="column"
      borderRadius="medium"
      borderWidth="thin"
      borderColor="surface.border.gray.normal">
      <Box display="flex" alignItems="center" gap="spacing.4" padding={['spacing.5', 'spacing.7']}>
        <Text weight="semibold" size="large">
          {title}
        </Text>
      </Box>
      <Divider />
      <Box padding="spacing.7">{children}</Box>
    </Box>)
  );
};

export { ActionCard };
