import React, { useState } from "react";
import { useQuery } from "react-query";

import { WorkflowTypesMap } from "components/ApprovalWorkflows/constants";
import { routePaths } from "components/Routes/data";
import api from "api";
import { GetWorkflowSummaryForUserContract } from "schemas/GetWorkflowSummaryForUser";

interface TypeLinkMap {
    [k: string]: string;
}

const RootTypeLinkMap: TypeLinkMap = {
    [WorkflowTypesMap.EDIT_PAYROLL]: routePaths.approvalWorkflows.myRequests.editPayroll.root,
    [WorkflowTypesMap.FINALIZE_PAYROLL]: routePaths.approvalWorkflows.myRequests.finalizePayroll.root,
    [WorkflowTypesMap.SALARY_REVISION]: routePaths.approvalWorkflows.myRequests.salaryRevision.root,
}

const PendingTypeLinkMap: TypeLinkMap = {
    [WorkflowTypesMap.EDIT_PAYROLL]: routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
    [WorkflowTypesMap.FINALIZE_PAYROLL]: routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
    [WorkflowTypesMap.SALARY_REVISION]: routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
}

const MyRequestsTypeLinkMap: TypeLinkMap = {
    [WorkflowTypesMap.EDIT_PAYROLL]: routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe,
    [WorkflowTypesMap.FINALIZE_PAYROLL]: routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe,
    [WorkflowTypesMap.SALARY_REVISION]: routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe,
}

export const APPROVAL_DATA_FETCH = 'APPROVAL_DATA_FETCH';

const transformDataToLink = (response?: GetWorkflowSummaryForUserContract) => {
    let data :TypeLinkMap = {};

    if(!response) {
        return data;
    }
    
    Object.values(WorkflowTypesMap).forEach((type: number) => {
        if(response.pending_approvals && response.pending_requests &&
            response.pending_approvals[type] && response.pending_requests[type]) {
            data[type] = RootTypeLinkMap[type];
        } else if (response.pending_approvals && response.pending_approvals[type]) {
            data[type] = PendingTypeLinkMap[type];
        } else if (response.pending_requests && response.pending_requests[type]) {
            data[type] = MyRequestsTypeLinkMap[type];
        }
    });

    return data;
}

const useApprovalLink = ( forUserId? : number ): { links: TypeLinkMap, isLoading: boolean} => {
    const dataFetch = useQuery(APPROVAL_DATA_FETCH, () => {
        return api.workflowApis.getWorkflowSummaryForUser( forUserId );
    });

    return {    
        links : transformDataToLink(dataFetch.data),
        isLoading: dataFetch.isLoading || dataFetch.isFetching
    };
}

export default useApprovalLink;