import { KnitApps } from 'components/Knit/constants';
import DarwinboxLogoNew from '../../../../assets/darwinbox/darwinbox_logo_new.png';
import HibobIntroLogo from '../../../../assets/hibob/hibob-intro-logo.png';
import WorqIntroLogo from '../../../../assets/worq/worq-intro-logo.png';
import WorklineIntroLogo from '../../../../assets/workline/workline-intro-logo.png';

export const hrmsAppIntroductionContent = {
  [KnitApps.darwinbox]: {
    features: [
      'Core HR simplified',
      'Time & Attendance',
      'Performance management',
      'Learning and development',
    ],
    logo: DarwinboxLogoNew,
  },
  [KnitApps.hibob]: {
    features: [
      'Core HR simplified',
      'Time & Attendance',
      'Performance management',
      'Learning and development',
    ],
    logo: HibobIntroLogo,
  },
  [KnitApps.worq]: {
    features: [
      'Core HR simplified',
      'Time & Attendance',
      'Performance management',
      'Learning and development',
    ],
    logo: WorqIntroLogo,
  },
  [KnitApps.workline]: {
    features: [
      'Core HR simplified',
      'Time & Attendance',
      'Performance management',
      'Learning and development',
    ],
    logo: WorklineIntroLogo,
  },
};
