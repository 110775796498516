import { Box, Button } from '@razorpay/blade/components';
import React from 'react';
import { MyRequestsContextType, useMyRequestsContext } from '../MyRequestsContext';
interface DetailsViewWithdrawProps {
  requestId: number;
  withdrawCallback?: () => void;
}

const DetailsViewWithdraw = ({ requestId, withdrawCallback }: DetailsViewWithdrawProps) => {
  const { openSingleWithdrawModal } = useMyRequestsContext() as MyRequestsContextType;

  const handleWithdrawClick = () => {
    openSingleWithdrawModal(requestId, withdrawCallback);
  };

  return (
    <Box display="flex" flexDirection="column" gap="spacing.7">
      <Box>
        <Button onClick={handleWithdrawClick}>Withdraw Request</Button>
      </Box>
    </Box>
  );
};

export { DetailsViewWithdraw };
