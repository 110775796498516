export const ROLE = {
  ADMIN: 'owner',
  OWNER: 'admin',
};

export const PRODUCT = {
  BANKING: 'banking',
};

export const CHANNEL = {
  YESBANK: 'yesbank',
  RBL: 'rbl',
  ICICI: 'icici',
};

export const ACCOUNT_TYPE = {
  CURRENT: 'current',
  NODAL: 'nodal',
};