import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Input } from 'components/ui/Form/Input';
import { StandardButton } from 'components/ui/Button';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import MultiSelect from 'components/ui/Dropdown/MultiSelect';
import { OrgLocation } from '../utils/types';
import { useRunPayrollFilterContext } from '../contexts/RunPayrollFiltersContext';
import closeIcon from 'assets/icon--close-blue.svg';
import searchIcon from 'assets/icon--search-white.svg';
import { trackXPayrollEvent } from 'utils/analytics';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from './index.module.scss';

const RunPayrollFilters = ({
  isLoading,
  isBulkActionsEnabled,
}: {
  isLoading: boolean;
  isBulkActionsEnabled: boolean;
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('');

  const departments = useSelector(loggedInUserSelectors.departments);
  const locations = useSelector(loggedInUserSelectors.locations);
  const filters = useRunPayrollFilterContext();

  const appliedFilters = filters?.filters;

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    filters?.setSearchPhraseFilter(searchFilter);
  };

  const setDepartments = (options: (string | number)[]) => {
    trackXPayrollEvent(`runpayroll.departments_filtered`);
    filters?.setDepartmentsFilter(options as string[]);
  };

  const setLocations = (options: (string | number)[]) => {
    trackXPayrollEvent(`runpayroll.locations_filtered`);
    filters?.setLocationsFilter(options as string[]);
  };

  const onClearSearch = () => {
    setSearchFilter('');
    if (!!appliedFilters?.searchPhrase) {
      filters?.setSearchPhraseFilter('');
    }
  };

  useEffect(() => {
    if (appliedFilters?.searchPhrase !== searchFilter) {
      setSearchFilter(appliedFilters?.searchPhrase ?? '');
    }
  }, [appliedFilters?.searchPhrase]);

  const departmentsOptions = departments?.map((department) => ({
    key: department,
    value: department,
  }));
  const locationOptions = (locations as OrgLocation[])?.map((location) => ({
    key: location.code.toString(),
    value: location.name,
  }));

  return (
    <div
      className={`grid grid-cols-10 gap-6 ${
        appliedFilters?.departments.length || appliedFilters?.locations.length ? 'mb-10' : 'mb-20'
      } w-full`}>
      <div className={`col-span-10 ${isBulkActionsEnabled ? 'lg:col-span-4' : ''}`}>
        <form className={styles['run-payroll-search']}>
          <Input
            type="search"
            name="search"
            placeholder="Search by name, email or employee id"
            value={searchFilter}
            disabled={isLoading}
            onChange={(event) => setSearchFilter(event.target.value)}
          />
          {!!searchFilter && (
            <img
              className={styles['runpayroll-filter-search-clear']}
              src={getStaticMediaUrl(closeIcon)}
              alt=""
              onClick={onClearSearch}
            />
          )}
          <StandardButton
            onClick={onSubmit}
            disabled={isLoading}
            className={styles['run-payroll-data-search']}>
            <img width="30px" src={getStaticMediaUrl(searchIcon)} alt="Search" />
          </StandardButton>
        </form>
      </div>
      {isBulkActionsEnabled && (
        <>
          <div className="col-span-10 md:col-span-5 lg:col-span-3">
            <MultiSelect
              id="department-filter"
              options={departmentsOptions ?? []}
              name={'Department'}
              value={appliedFilters?.departments}
              onChange={setDepartments}
              disableChipsDisplay={true}
              disableAllOption={true}
              disabled={isLoading}
            />
          </div>
          <div className="col-span-10 md:col-span-5 lg:col-span-3">
            <MultiSelect
              id="locations-filter"
              options={locationOptions ?? []}
              value={appliedFilters?.locations}
              name={'Location'}
              onChange={setLocations}
              disableChipsDisplay={true}
              disableAllOption={true}
              disabled={isLoading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RunPayrollFilters;
