import React from 'react';
import LineLoader from '../Loaders/LineLoader';
import style from './index.module.css';

export function TableWithBorder(props: React.HTMLAttributes<HTMLTableElement>) {
  return <table {...props} className={`${props.className}`}></table>;
}

// Todo: need to come up with proper tables
export function SimpleColorTable(props: React.HTMLAttributes<HTMLTableElement>) {
  return <table {...props} className={`${props.className} ${style.table}`}></table>;
}

export function TableLayout(props: React.HTMLAttributes<HTMLTableElement>) {
  return <table {...props} className={`${props.className} ${style['table--layout']}`}></table>;
}

export function StandardTable(props: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <table
      {...props}
      className={`${props.className} ${style.table} ${style['standard-table']}`}></table>
  );
}

export function Table({
  showAlternateColors,
  areRowsHoverable,
  ...props
}: React.HTMLAttributes<HTMLTableElement> & {
  showAlternateColors?: boolean;
  areRowsHoverable?: boolean;
}) {
  return (
    <table
      {...props}
      className={`${style.table} ${props.className} ${
        showAlternateColors === false ? style['table__no_stripes'] : style['table-alternate-colors']
      } ${areRowsHoverable === true ? style['table--hoverable'] : ''}`}></table>
  );
}


export function TableWithLoader({
  showAlternateColors,
  areRowsHoverable,
  isLoading,
  ...props
}: React.HTMLAttributes<HTMLTableElement> & {
  showAlternateColors?: boolean;
  areRowsHoverable?: boolean;
  isLoading?:boolean;
}) {
  return (
    <>
      <LineLoader isLoading={!!isLoading}/>
      <table
        {...props}
        className={`${style.table} ${props.className} ${
          showAlternateColors === false ? style['table__no_stripes'] : style['table-alternate-colors']
        } ${areRowsHoverable === true ? style['table--hoverable'] : ''}  
        ${isLoading ? ' opacity-50 pointer-events-none ' : ''}`}></table>
      </>
  );
}