import React from 'react';
import { ComplianceActionsList } from 'components/RunPayroll/ComplianceActions/ComplianceActionsList/ComplianceActionsList';
import { ComplianceActionsContextProvider } from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';

export const ComplianceActions = () => {
  const features = useSelector(loggedInUserSelectors.features);
  const isPayPtOnRunPayrollEnabled = !!features?.enablePayPtOnRunPayroll;

  if (!isPayPtOnRunPayrollEnabled) {
    return null;
  }

  return (
    <ComplianceActionsContextProvider>
      <ComplianceActionsList />
    </ComplianceActionsContextProvider>
  );
};
