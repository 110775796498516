import React from 'react';
import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import CompanyDetails from './CompanyDetails';
import permissions from 'constants/permissions';
import PayrollDate from './PayrollDate';
import SalaryStructure from './SalaryStructure';
import OnboardingStatus, { OnboardingItem } from 'components/ui/OnboardingStatus';
import { LinkButtonV2 } from 'components/ui/Button';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { trackSegment } from 'utils/segment';
import { useIsSelfServeP3M1Enabled } from 'components/Kyc/hooks/useIsSelfServeP3M1Enabled';
import Spinner from 'components/ui/Spinner';

const STEP_COMPANY_DETAILS = 1;
const STEP_PAYROLL_DATE = 2;
const STEP_SALARY_STRUCTURE = 3;

const stepMap: { [key: number]: { name: string; route: string } } = {
  1: { name: 'Enter company details', route: routePaths.companyOnboarding.companyDetails },
  2: { name: 'Set payroll date', route: routePaths.companyOnboarding.payrollDate },
  3: { name: 'Set employee salary structure', route: routePaths.companyOnboarding.salaryStructure },
};

const routeToStepMap: { [key: string]: number } = {
  [routePaths.companyOnboarding.root]: STEP_COMPANY_DETAILS,
  [routePaths.companyOnboarding.companyDetails]: STEP_COMPANY_DETAILS,
  [routePaths.companyOnboarding.payrollDate]: STEP_PAYROLL_DATE,
  [routePaths.companyOnboarding.salaryStructure]: STEP_SALARY_STRUCTURE,
};

const CompanyOnboarding = () => {
  const location = useLocation();
  const history = useHistory();
  const currentStep = routeToStepMap[location.pathname] || STEP_COMPANY_DETAILS;
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const { isSelfServeP3M1Enabled, isLoading } = useIsSelfServeP3M1Enabled();

  const onStepChange = (value: number) => {
    history.push(stepMap[value].route);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isSelfServeP3M1Enabled) {
    return (
      <div className="pt-10 pb-8 max-w-screen-xl mx-auto">
        <OnboardingStatus
          className="mb-16 mr-10"
          title={stepMap[currentStep].name}
          value={currentStep}
          onChange={onStepChange}>
          <OnboardingItem
            title={stepMap[STEP_PAYROLL_DATE].name}
            value={STEP_PAYROLL_DATE}
            status={
              currentOrganization?.onboardingConfig?.isPayrollDateSetupComplete
                ? 'completed'
                : undefined
            }
          />
          <OnboardingItem
            title={stepMap[STEP_SALARY_STRUCTURE].name}
            value={STEP_SALARY_STRUCTURE}
            status={
              currentOrganization?.onboardingConfig?.isSalaryStructureSetupComplete
                ? 'completed'
                : undefined
            }
          />
        </OnboardingStatus>
        <Switch>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.companyOnboarding.payrollDate}
            component={PayrollDate}
            exact
          />
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.companyOnboarding.salaryStructure}
            component={SalaryStructure}
            exact
          />
          <Route>
            <Redirect to={routePaths.unknown} />
          </Route>
        </Switch>
        <Switch>
          <Route
            path={[
              routePaths.companyOnboarding.payrollDate,
              routePaths.companyOnboarding.salaryStructure,
            ]}>
            <div className="max-w-4xl mx-auto mt-16">
              <a href="/">
                <LinkButtonV2
                  onClick={() =>
                    trackSegment('onboarding.company_details.back_to_setup_guide_click', {
                      queue: true,
                    })
                  }>
                  {'<'} Back to setup guide
                </LinkButtonV2>
              </a>
            </div>
          </Route>
        </Switch>
      </div>
    );
  }

  return (
    <div className="pt-10 pb-8 max-w-screen-xl mx-auto">
      <OnboardingStatus
        className="mb-16 mr-10"
        title={stepMap[currentStep].name}
        value={currentStep}
        onChange={onStepChange}>
        <OnboardingItem
          title={stepMap[STEP_COMPANY_DETAILS].name}
          value={STEP_COMPANY_DETAILS}
          status={
            currentOrganization?.onboardingConfig?.isCompanyAdressSetupComplete &&
            currentOrganization.onboardingConfig.isCompanyTaxSetupComplete
              ? 'completed'
              : undefined
          }
        />
        <OnboardingItem
          title={stepMap[STEP_PAYROLL_DATE].name}
          value={STEP_PAYROLL_DATE}
          status={
            currentOrganization?.onboardingConfig?.isPayrollDateSetupComplete
              ? 'completed'
              : undefined
          }
        />
        <OnboardingItem
          title={stepMap[STEP_SALARY_STRUCTURE].name}
          value={STEP_SALARY_STRUCTURE}
          status={
            currentOrganization?.onboardingConfig?.isSalaryStructureSetupComplete
              ? 'completed'
              : undefined
          }
        />
      </OnboardingStatus>
      <Switch>
        <RestrictedRoute
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
          path={routePaths.companyOnboarding.payrollDate}
          component={PayrollDate}
          exact
        />
        <RestrictedRoute
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
          path={routePaths.companyOnboarding.salaryStructure}
          component={SalaryStructure}
          exact
        />
        <RestrictedRoute
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
          path={[routePaths.companyOnboarding.companyDetails, routePaths.companyOnboarding.root]}
          component={CompanyDetails}
        />
        <Route>
          <Redirect to={routePaths.unknown} />
        </Route>
      </Switch>
      <Switch>
        <Route
          path={[
            routePaths.companyOnboarding.payrollDate,
            routePaths.companyOnboarding.salaryStructure,
          ]}>
          <div className="max-w-4xl mx-auto mt-16">
            <a href="/">
              <LinkButtonV2
                onClick={() =>
                  trackSegment('onboarding.company_details.back_to_setup_guide_click', {
                    queue: true,
                  })
                }>
                {'<'} Back to setup guide
              </LinkButtonV2>
            </a>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default CompanyOnboarding;
