
import { TaxDeclarationSettingsSchemaContract } from '../../../schemas/TaxDeclarationSettingsSchema';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import { DeclarationErrors } from 'components/ui/DeclarationWindow/types';
import { isAfter, isBefore } from 'date-fns';

export const initSettings = (): TaxDeclarationSettingsSchemaContract => {
    return {
        letXpayrollVerifyDocs: true,
        disable80g: false,
        taxCalculationsOnVerifiedProofs: false,
        financialYear: null,
        autoOpenProofWindowForFnf: false,
        autoOpenProofWindowForNewEmployees: false,
        windowDurationForNewEmployees: null,
        freezeXpayrollVerifyDocs: false,
        proofVerificationCompletedAt: null,
        allowEmployeeToUpdateTaxDeductions: true
    }
}

export const calculateTaxFieldAlertText = (settings: TaxDeclarationSettingsSchemaContract): string => {

    if (settings.letXpayrollVerifyDocs) {
        return '⚡ Since XPayroll is handling the verification, this setting is controlled by us. Once the proof verification is completed, the setting will change to "Declaration with verified proof”.';
    }
    else {
        if (settings.taxCalculationsOnVerifiedProofs) {
            return "⚡ Tax will be calculated based on the verified proofs uploaded by the employees. Change this setting when you have verified proofs uploaded by the employees.";
        }
    }
    return '';
}

const getMonthRangeErrors = (startDate?: number, endDate?: number) => {
    let isValid = true;
    const errors: Record<'startDate' | 'endDate', string | null> = {
        startDate: null,
        endDate: null,
    };
    if (!startDate) {
        errors.startDate = 'This is required';
        isValid = false;
    } else if (!endDate) {
        errors.endDate = 'This is required';
        isValid = false;
    } else {
        if (startDate > endDate) {
            errors.startDate = 'Start date cannot be after end date';
            isValid = false;
        } else if (endDate < startDate) {
            errors.endDate = 'End date cannot be less than start date';
            isValid = false;
        }
    }
    return { errors, isValid };
};

const getCustomRangeErrors = (startDate?: Date | null, endDate?: Date | null) => {
    let isValid = true;
    const errors: Record<'startDate' | 'endDate', string | null> = {
        startDate: null,
        endDate: null,
    };
    if (!startDate) {
        errors.startDate = 'This is required';
        isValid = false;
    } else if (!endDate) {
        errors.endDate = 'This is required';
        isValid = false;
    } else {
        if (isAfter(startDate, endDate)) {
            errors.startDate = 'Start date cannot be after end date';
            isValid = false;
        } else if (isBefore(endDate, startDate)) {
            errors.endDate = 'End date cannot be less than start date';
            isValid = false;
        }
    }
    return { errors, isValid };
};

export const getWindowErrors = (
    declarationWindow: GenericDeclaration
): { errors: DeclarationErrors, isValid: boolean } => {
    let isValid = true;
    let errors = {};
    if (declarationWindow.type === 'monthly') {
        const { startDate, endDate } = declarationWindow;
        const validationResult = getMonthRangeErrors(startDate, endDate);
        errors = validationResult.errors;
        isValid = validationResult.isValid;
    } else if (declarationWindow.type === 'custom') {
        const entries = Object.entries(declarationWindow.dates);
        for (const entry of entries) {
            const [key, value] = entry;
            const { startDate, endDate } = value;
            const validationResult = getCustomRangeErrors(startDate, endDate);
            errors = {
                ...errors,
                [key]: validationResult.errors,
            };
            isValid = validationResult.isValid;
        }
    } else if (declarationWindow.type === 'always') {
        errors = {};
    }
    return { errors, isValid };
};