import api from 'api';
import { InfoAlertBlade, WarningAlert } from 'components/ui/Alert';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import Errors from 'components/ui/Form/Errors';
import { Input, InputElement } from 'components/ui/Form/Input';
import React, { FormEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { AppError } from 'utils/AppError';
import { getStaticMediaUrl } from 'utils/Urls';
import { trackXPayrollEvent } from 'utils/analytics';
import { GSTIN_REGEX } from 'utils/regexes';
import zapIcon from '../../../assets/icons/icon_zap.svg';
import { FETCH_ADDRESS_FROM_GSTIN } from '../queries';
import { wordWrapText } from '../utils';
import { AddressConfirmationPayload } from './types';

interface Props {
  onConfirm: (payload: AddressConfirmationPayload) => void;
  onProceedManually: () => void;
  initialGstin?: string;
}

const AddressFromGSTIN = ({ onConfirm, onProceedManually, initialGstin = '' }: Props) => {
  const [gstin, setGstin] = useState(initialGstin);
  const { isLoading, isRefetching, refetch, error } = useQuery(
    FETCH_ADDRESS_FROM_GSTIN,
    () => api.companyOnboarding.getAddressFromGstin(gstin),
    {
      enabled: false,
      onError: (error: typeof AppError) => {},
      onSuccess: (data) => {
        const pan = gstin.substring(2, 12);
        const [addressLine1, addressLine2] = wordWrapText(data?.primary_address || '');
        trackXPayrollEvent('onboarding.fetched_details', 'interaction', 'company-onboarding');
        onConfirm({
          addressLine1,
          addressLine2,
          pincode: data?.pincode,
          gstin,
          pan,
          companyName: data?.legal_name,
          state: data?.state_code,
        });
      },
    },
  );

  const handleSubmit = (e: FormEvent) => {
    trackXPayrollEvent('onboarding.entered_gstin', 'interaction', 'company-onboarding');
    e.preventDefault();
    refetch();
  };

  const isGstinValid = new RegExp(GSTIN_REGEX).test(gstin.toUpperCase());
  const isRequesting = isRefetching || isLoading;

  return (
    <form onSubmit={handleSubmit}>
      {error ? (
        <WarningAlert className="mb-16">
          <Errors errorResponse={error} />
        </WarningAlert>
      ) : null}
      <InputElement required label="Company GSTIN" className="my-auto mt-4 mb-3">
        <Input
          type="text"
          maxLength={15}
          value={gstin}
          required
          disabled={isRequesting}
          name="company-gstin"
          onChange={(event) => setGstin(event.target.value)}
          placeholder="Enter GSTIN"
          customErrorMessage="Invalid GSTIN"
          pattern={GSTIN_REGEX}
        />
      </InputElement>
      <InfoAlertBlade icon={getStaticMediaUrl(zapIcon)} className="mb-12">
        Save time by entering GST and let us pre-fill your company info like address, pin, etc.
        Bonus: claim GST credit on invoices!
      </InfoAlertBlade>

      <div className="flex justify-between align-center pt-12">
        <SecondaryButton
          type="button"
          className="normal-case"
          disabled={isRequesting}
          onClick={onProceedManually}>
          Don't have GSTIN
        </SecondaryButton>
        <StandardButton
          type="submit"
          isPending={isRequesting}
          className="normal-case mr-0"
          disabled={!isGstinValid || isRequesting}>
          Continue
        </StandardButton>
      </div>
    </form>
  );
};

export default AddressFromGSTIN;
