import {
  Badge,
  Box,
  CheckCircleIcon,
  CloseIcon,
  IconButton,
  ProgressBar,
  Skeleton,
  Heading,
} from '@razorpay/blade/components';
import React from 'react';
import styled from 'styled-components';
import bottomLeftImg from '../../../../assets/darwinbox/HrmsIntegrationBgBottomLeft.png';
import topRightImg from '../../../../assets/darwinbox/HrmsIntegrationBgTopRight.png';
import { HrmsSettingsSkeleton } from '../Settings/components/HrmsSettingsSkeleton';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';

interface IntegrationWindowProps {
  progress?: number;
  title?: JSX.Element;
  children?: JSX.Element;
  onClose?: () => void;
}

interface IntegrationWindowTitleProps {
  appName: string;
  orgName?: string;
  mode?: 'integrate' | 'modify';
}

const MainContentContainer = styled.section(
  ({ theme }) => `
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing[11]}px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url('${bottomLeftImg}'), url('${topRightImg}');
  background-repeat: no-repeat, no-repeat;
  background-position: bottom left, top right;
  background-size: 593px auto, auto 376px;

  &::-webkit-scrollbar{
    display: none;
  }
`,
);

const MutedText = styled.span(
  ({ theme }) => `
  color: ${theme.colors.surface.text.gray.disabled};
`,
);

const IntegrationWindowTitle = ({ appName, orgName, mode }: IntegrationWindowTitleProps) => {
  const prefixText = mode === 'modify' ? 'Modify integration for' : 'Integrate';

  return (
    (<Box display="flex" gap="spacing.2">
      <Heading as="h1" size="large">
        <MutedText>{prefixText}</MutedText> {appName}
      </Heading>
      {orgName && (
        <Badge marginLeft="spacing.3" size="large" icon={CheckCircleIcon} color="positive">
          {orgName}
        </Badge>
      )}
    </Box>)
  );
};

const IntegrationWindowSkeleton = () => {
  return (
    <IntegrationWindow
      title={
        <Box display="flex" alignItems="center" gap="spacing.5">
          <Skeleton width="250px" height="spacing.8" />
          <Skeleton width="150px" height="spacing.7" />
        </Box>
      }>
      <HrmsSettingsSkeleton />
    </IntegrationWindow>
  );
};

const IntegrationWindowErrorWithRetry = (props: React.ComponentProps<typeof ErrorWithRetry>) => {
  return (
    <IntegrationWindow>
      <ErrorWithRetry {...props} />
    </IntegrationWindow>
  );
};

const IntegrationWindow = React.forwardRef<HTMLElement, IntegrationWindowProps>(
  ({ title, progress, children, onClose }, ref) => {
    return (
      (<Box
        height="100vh"
        width="100vw"
        display="flex"
        flexDirection="column"
        backgroundColor="surface.background.gray.moderate"
        >
        {title && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            width="100%"
            backgroundColor="surface.background.gray.intense"
            padding={['spacing.5', 'spacing.7']}>
            {title}
            {onClose && (
              <IconButton
                icon={CloseIcon}
                onClick={onClose}
                size="medium"
                accessibilityLabel="close view"
              />
            )}
            {progress !== undefined && (
              <Box position="absolute" bottom="0px" left="0px" width="100%">
                <ProgressBar size="medium" value={progress} color="information" showPercentage={false} />{' '}
              </Box>
            )}
          </Box>
        )}
        <MainContentContainer ref={ref}>{children}</MainContentContainer>
      </Box>)
    );
  },
);

export {
  IntegrationWindow,
  IntegrationWindowTitle,
  IntegrationWindowSkeleton,
  IntegrationWindowErrorWithRetry,
};
