import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ChatMessage } from './types';
import { useSearchParams } from 'hooks/useSearchParams';
import { PayrollAiAssistantPollSchemaContract } from 'schemas/PayrollAiAssistantPollSchema';
import { useRandomPick } from './useRandomPick';

export interface ChatContextType {
  chatId: string;
  setChatId: React.Dispatch<React.SetStateAction<string>>;
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  conversationCount: number;
  setConversationCount: React.Dispatch<React.SetStateAction<number>>;
  messages: ChatMessage[];
  isBotProcessing?: boolean;
  setIsBotProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  pushMessage: (message: ChatMessage, callback?: () => void) => void;
  pollData: PayrollAiAssistantPollSchemaContract | null;
  setPollData: React.Dispatch<React.SetStateAction<PayrollAiAssistantPollSchemaContract | null>>;
  isPolling: boolean;
  setPolling: React.Dispatch<React.SetStateAction<boolean>>;
  inputSuggestions: string[];
  setInputSuggestions: React.Dispatch<React.SetStateAction<string[]>>;
  lastMessageRef: React.MutableRefObject<HTMLDivElement | null>;
  captcha: string | null;
  setCaptcha: React.Dispatch<React.SetStateAction<string | null>>;
  isFeedbackPhase: boolean;
  setFeedbackPhase: React.Dispatch<React.SetStateAction<boolean>>;
  isEmployer: boolean;
}

const ChatContext = createContext<ChatContextType | null>(null);

interface ChatContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

const ChatContextProvider = ({ children }: ChatContextProviderProps) => {
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const { urlParams } = useSearchParams();
  const id = urlParams.get('id') ?? '';
  const chatCount = urlParams.get('conversation_count');
  const name = urlParams.get('user_name');
  const isEmployer = urlParams.get('employer') ? true : false;

  // @TODO payroll-ai-assistant - use setChatId for non payroll users
  const [chatId, setChatId] = useState<string>(id);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [conversationCount, setConversationCount] = useState<number>(chatCount ? +chatCount : 0);
  const [userName, setUserName] = useState<string>(name ?? '');

  const [isBotProcessing, setIsBotProcessing] = useState(chatId ? true : false);
  const [isPolling, setPolling] = useState(chatId ? true : false);
  const [isFeedbackPhase, setFeedbackPhase] = useState(false);

  const [pollData, setPollData] = useState<null | PayrollAiAssistantPollSchemaContract>(null);

  const ctcMessageData = useRandomPick([
    'Ready to roll! Just need your CTC to get started.',
    'Before we begin our analysis, could you share your CTC?',
    'Before we proceed, could you disclose your CTC?',
  ]);

  const ctcMessage: ChatMessage = {
    initiator: 'bot',
    type: 'text',
    messageData: ctcMessageData,
  };
  const [messages, setMessages] = useState<ChatMessage[]>(chatId ? [] : [ctcMessage]);

  const [inputSuggestions, setInputSuggestions] = useState<string[]>(
    [],
    //    getChatSuggestionByStep(conversationCount),
  );

  const pushMessage = (message: ChatMessage, callback?: () => void) => {
    setMessages((prev) => {
      return [...prev, { ...message, hasCallback: !!callback }];
    });

    if (callback) {
      setTimeout(() => {
        callback();
      }, 500);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        chatId,
        setChatId,
        conversationCount,
        setConversationCount,
        messages,
        isBotProcessing,
        setIsBotProcessing,
        pushMessage,
        pollData,
        setPollData,
        isPolling,
        setPolling,
        userName,
        setUserName,
        inputSuggestions,
        setInputSuggestions,
        lastMessageRef,
        captcha,
        setCaptcha,
        isFeedbackPhase,
        setFeedbackPhase,
        isEmployer,
      }}>
      {children}
    </ChatContext.Provider>
  );
};

const useChatContext = () => {
  const chatContext = useContext(ChatContext);

  return chatContext;
};

export { ChatContextProvider, useChatContext };
