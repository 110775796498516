import { Alert } from '@razorpay/blade/components';
import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

export type CompanyVerificationAlertBannerContext = React.ComponentProps<typeof Alert>;

export interface VerifyCompanyDetailsContextType {
  alertBannerPropsContext?: CompanyVerificationAlertBannerContext;
  setAlertBannerPropsContext: Dispatch<
    SetStateAction<CompanyVerificationAlertBannerContext | undefined>
  >;
}

const VerifyCompanyDetailsContext = createContext<VerifyCompanyDetailsContextType>({
  alertBannerPropsContext: undefined,
  setAlertBannerPropsContext: () => {
    //no-op
  },
});

const VerifyCompanyDetailsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [alertBannerPropsContext, setAlertBannerPropsContext] = useState<
    CompanyVerificationAlertBannerContext | undefined
  >(undefined);

  return (
    <VerifyCompanyDetailsContext.Provider
      value={{
        alertBannerPropsContext,
        setAlertBannerPropsContext,
      }}>
      {children}
    </VerifyCompanyDetailsContext.Provider>
  );
};

const useVerifyCompanyDetailsContext = () => {
  const { setAlertBannerPropsContext, alertBannerPropsContext } = useContext(
    VerifyCompanyDetailsContext,
  );

  return {
    alertBannerPropsContext,
    setAlertBannerPropsContext,
  };
};

export { VerifyCompanyDetailsContextProvider, useVerifyCompanyDetailsContext };
