import { TabItem, TabList, Tabs } from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { compensationTabItems } from '../constants';
import RecurringDeductionsView from '../views/RecurringDeductionsView';
import CtcComponent from './CtcComponent';
import { useSearchParams } from 'hooks/useSearchParams';

export const CompensationBreakdown = () => {
  const location = useLocation();
  const history = useHistory();
  const currentTab = location.pathname.split('/')[3];
  const { urlParams } = useSearchParams();
  const peopleId = urlParams.get('userId');

  return (
    <>
      <Tabs
        variant="bordered"
        orientation="horizontal"
        size={'medium'}
        onChange={(value) => history.push(`/pay-details/compensation/${value}?userId=${peopleId}`)}
        defaultValue={compensationTabItems.ctc}
        value={currentTab}>
        <TabList>
          <TabItem value={compensationTabItems.ctc}>CTC Component</TabItem>
          <TabItem value={compensationTabItems.deductions}>Recurring Deductions</TabItem>
        </TabList>
      </Tabs>

      <Switch>
        <Route path={routePaths.payDetails.compensation.root} exact>
          <Redirect
            to={{
              pathname: routePaths.payDetails.compensation.ctc,
              search: `?userId=${peopleId}`,
            }}
          />
        </Route>
        <Route path={routePaths.payDetails.compensation.ctc} component={CtcComponent} exact />
        <Route
          path={`${routePaths.payDetails.compensation.deductions}`}
          component={RecurringDeductionsView}
          exact
        />
      </Switch>
    </>
  );
};
