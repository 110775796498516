import React, { ReactElement, useState } from 'react';
import MainCase from 'components/ui/MainCase';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from 'api';
import TableView from './TableView';
import EsicFilters from './Filters';
import styles from './index.module.scss';
import StatusFilter from './Filters/StatusFilter';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import Pagination from 'components/ui/Pagination/index';
import DownloadCSVButton from '../PfRegistrationDashboard/DownloadCSVButton';
import { ActionType, INITIATE, UPDATE, RETRY, UPDATE_CRED } from './constants/constants';
import Spinner from 'components/ui/Spinner';
import { WarningAlert } from 'components/ui/Alert';
import ErrorMessage from 'components/ui/ErrorMessage';
import Breadcrumb from 'components/ui/Breadcrumb';

interface Props {}
const SHOW_DATA = 'SHOW_DATA';

function EsicRegistrationDashboard({}: Props): ReactElement {
  const [rowLimit, setRowLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string | ''>('');

  const queryClient = useQueryClient();

  const querydata = useQuery(
    [SHOW_DATA, { page, rowLimit, searchFilter, selectedStatus }],
    () => {
      return api.esicRegistrationData.fetchData(page, rowLimit, searchFilter, selectedStatus);
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );

  const updateStatus = useMutation(
    (people_id: number) => {
      return api.esicRegistrationData.retryRegistration(people_id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SHOW_DATA);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleButtonClick = (people_id: number, action: ActionType) => {
    if (action === INITIATE || action === UPDATE) {
      const redirectUrl = `/addEsi?userId=${people_id}`;
      window.location.href = redirectUrl;
    } else if (action === RETRY) {
      updateStatus.mutate(people_id);
    } else if (action === UPDATE_CRED) {
      const redirectUrl = `/companyOnboarding?step=999&redirect=%2FcompanyDetails`;
      window.location.href = redirectUrl;
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit: number) => {
    setRowLimit(newLimit);
    setPage(1);
  };

  const onClearSearch = () => {
    setSearchFilter('');
    querydata.refetch();
  };

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
  };

  if (querydata.status === 'loading' || querydata.status === 'idle') {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>
    );
  }

  if (querydata.status === 'error') {
    return (
      <ErrorMessage title="Something went wrong!">
        <div>Please contact support.</div>
      </ErrorMessage>
    );
  }

  return (
    <>
      <Breadcrumb
        name="ESIC Registration Report"
        urlMaps={{
          Reports: '/reports',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: '0 0 46%', marginRight: '3%' }}>
          <EsicFilters
            isLoading={querydata.isRefetching || querydata.isLoading}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            onClearSearch={onClearSearch}
          />
        </div>
        <div style={{ flex: '0 0 34%', marginRight: '-3%' }}>
          <StatusFilter onStatusChange={handleStatusChange} selectedStatus={selectedStatus} />
        </div>
        <div style={{ flex: '0 0 10%', marginRight: '0%' }}>
          <DownloadCSVButton link={'reports/esic-dashboard/csv'} selectedStatus={selectedStatus} />
        </div>
      </div>
      {updateStatus.isError && (
        <WarningAlert className={styles['warning']}>
          {getAppErrorDetails(updateStatus.error).messageForUser}
        </WarningAlert>
      )}
      {querydata.data?.data && querydata.data?.total > 0 ? (
        <TableView
          data={querydata.data.data}
          isLoading={querydata.isLoading}
          handleButtonClick={handleButtonClick}
        />
      ) : (
        <ErrorMessage title="No data found!">
          <div>Search did not return any results. Please try again.</div>
        </ErrorMessage>
      )}
      <Pagination
        className="mt-4"
        totalRows={querydata.data.total}
        limitOptions={[5, 10, 15, 20]}
        limit={rowLimit}
        onLimitChange={handleLimitChange}
        totalPages={Math.ceil(querydata.data.total / rowLimit)}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </>
  );
}

function EsicRegistrationDashboardWithPageWrapper(): ReactElement {
  return (
    <MainCase type={'fullWidth'}>
      <EsicRegistrationDashboard />
    </MainCase>
  );
}

export default EsicRegistrationDashboardWithPageWrapper;
