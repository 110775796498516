import { Box } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { salaryComponentsQueries } from 'components/SalaryComponents/constants';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import { EarningsComponentType } from 'components/SalaryComponents/types';
import {
  WizardError,
  WizardHeader,
  WizardLoader,
  WizardWrapper,
} from 'components/WizardViews/components';
import { WizardInfoProvider } from 'components/WizardViews/providers';
import GeneralDetailsStep from 'components/WizardViews/SalaryComponents/EditPredefined/GeneralDetailsStep';
import { useFormWithProvider } from 'forms';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { EarningsFormData } from '../types';
import { convertSalaryComponentToEarningFormData } from '../utils';

const EditPredefinedForm = () => {
  const history = useHistory();
  const { setFormData, formData } = useFormWithProvider<EarningsFormData>();

  const { componentId, payType } = useComponentParams();
  const isExistingComponent = Boolean(componentId);
  const { isFetching, error, refetch } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery, componentId],
    queryFn: () =>
      api.salaryComponents.queries.getSalaryComponent({
        id: componentId!,
        category: 'EARNINGS_CATEGORY',
        payType,
      }),
    enabled: isExistingComponent,
    onSuccess(data) {
      setFormData(
        convertSalaryComponentToEarningFormData(
          data.payrollGetSalaryComponent.data.salaryComponent as EarningsComponentType,
        ),
      );
    },
  });

  const handleCloseClick = () => history.push(routePaths.salaryComponents.root);

  if (isFetching) {
    return <WizardLoader />;
  }

  return (
    <WizardInfoProvider currentStep={0} totalSteps={1}>
      <WizardWrapper>
        <WizardHeader
          heading="Modify"
          subHeading={formData.name!}
          onCloseClick={handleCloseClick}
        />
        <Box flex={1} overflowY="auto" height="100%">
          <WizardError onRetry={refetch} error={error}>
            <GeneralDetailsStep />
          </WizardError>
        </Box>
      </WizardWrapper>
    </WizardInfoProvider>
  );
};

export default EditPredefinedForm;
