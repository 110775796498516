import {
  DeductFrom,
  DeductFromLabelToNumberMap,
  DeductionType,
} from 'components/RunPayroll/utils/types';
import React from 'react';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { isNumeric } from 'utils/Numbers';
import InputBoxWrapper from './FnfInputBoxWrapper';

import {
  ActionList,
  ActionListItem,
  Box,
  Button,
  CloseIcon,
  Divider,
  Dropdown,
  DropdownOverlay,
  IconButton,
  Link,
  PlusIcon,
  SelectInput,
  Text,
  TextInput,
} from '@razorpay/blade/components';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import useSalaryComponentsList from 'components/RunPayroll/Popups/EditSalary/hooks/useSalaryComponentsList';
import { getSearchDropdownDescription } from 'components/RunPayroll/Popups/EditSalary/utils';
import { useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { DeductionsComponentType } from 'components/SalaryComponents/types';
import AutoCompleteV2 from 'components/ui/AutoCompleteV2/AutoCompleteV2';
import { capitalizeAllWordsInString } from 'utils/Strings';

type FnfDeductionsProps = {
  nonLopDeductions: GetFnfResponseSchemaContract['deductions']['non_lop_deductions'] | null;
  onChangeNonLopDeductions: (
    nonLopDeductions: GetFnfResponseSchemaContract['deductions']['non_lop_deductions'],
  ) => void;
  isFnfProcessed: boolean;
  isHoldSalaryPayComplianceEnabled: boolean;
};

const FnfDeductions = ({
  nonLopDeductions,
  onChangeNonLopDeductions,
  isHoldSalaryPayComplianceEnabled,
  isFnfProcessed = false,
}: FnfDeductionsProps) => {
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();
  const { openDeductionsCreation } = useSalaryComponentHelpers();

  const { searchSalaryComponents, isFetchingSalaryComponents } =
    useSalaryComponentsList<DeductionsComponentType>({
      requestParams: {
        category: 'DEDUCTIONS_CATEGORY',
        payType: 'LIST_ADHOC_TYPE',
        status: 'COMPONENT_STATUS_ACTIVE',
      },
      enabled: isM2S1Enabled,
    });

  const onDeductionsChange = (idx: number, key: string, value: string) => {
    const newNonLOPDeductions = nonLopDeductions ? [...nonLopDeductions] : [];
    if (key === 'amount' || key === 'deduct_from') {
      value = value === '' ? '0' : value;
      if (!isNumeric(value)) {
        return;
      }
      newNonLOPDeductions[idx][key] = parseInt(value);
    } else {
      newNonLOPDeductions[idx][key] = value;
    }
    onChangeNonLopDeductions(newNonLOPDeductions);
  };

  const onSelectDeduction = (idx: number, item: DeductionsComponentType) => {
    const newNonLOPDeductions = nonLopDeductions ? [...nonLopDeductions] : [];

    newNonLOPDeductions[idx] = {
      ...newNonLOPDeductions[idx],
      name: item.name,
      id: item.id,
      deduct_from:
        DeductFromLabelToNumberMap[item.settings.deductionDetails.deductFrom ?? 'GROSS_PAY'],
    };

    onChangeNonLopDeductions(newNonLOPDeductions);
  };

  const onClearSelection = (idx: number) => {
    const newNonLOPDeductions = nonLopDeductions ? [...nonLopDeductions] : [];

    newNonLOPDeductions[idx] = { ...newNonLOPDeductions[idx], name: '', id: null };

    onChangeNonLopDeductions(newNonLOPDeductions);
  };

  const onDeleteItem = (idx: number) => {
    if (!nonLopDeductions) return;
    const newNonLOPDeductions = nonLopDeductions ? [...nonLopDeductions] : [];
    const filteredDeductions = newNonLOPDeductions.filter((_, index) => index !== idx);
    onChangeNonLopDeductions(filteredDeductions);
  };

  const onAdd = () => {
    const newNonLOPDeductions = nonLopDeductions ? [...nonLopDeductions] : [];
    newNonLOPDeductions.push({
      name: '',
      amount: 0,
      deduct_from:
        isHoldSalaryPayComplianceEnabled && isFnfProcessed
          ? DeductFrom.NET_PAY
          : DeductFrom.GROSS_PAY,
      lop_days: 0,
      type: DeductionType.TYPE_MANUAL,
    });
    if (newNonLOPDeductions.length <= 10) {
      onChangeNonLopDeductions(newNonLOPDeductions);
    }
  };

  const nonLopDeductionsInternal = Array.isArray(nonLopDeductions) ? nonLopDeductions : [];

  // Adding existing behaviour, where we do not render this section if there are no deductions
  if (!isM2S1Enabled && nonLopDeductionsInternal.length === 0) {
    return null;
  }

  const getDeductionsSearchSkipList = (
    deductions: NonNullable<FnfDeductionsProps['nonLopDeductions']>,
    currentId?: string | null,
  ): string[] => {
    return deductions
      .filter((deduction) => deduction.id !== '' && deduction.id !== currentId && !!deduction.id)
      .map((deduction) => deduction.id!);
  };

  return (
    <>
      <InputBoxWrapper heading="Deduction (excluding salary advance)">
        <Box display="flex" flexDirection="column" gap="28px">
          <Box display="flex" gap="12px" flexDirection="column">
            <Box display="flex" alignItems="center" gap="32px">
              <Box flex="1.5" textAlign="left">
                <Text weight="semibold" size="small" color="surface.text.gray.disabled">
                  Label
                </Text>
              </Box>
              <Box flex="1" textAlign="left">
                <Text weight="semibold" size="small" color="surface.text.gray.disabled">
                  Amount
                </Text>
              </Box>
              <Box flex="1" textAlign="left">
                <Link
                  size="small"
                  target="_blank"
                  href="https://intercom.help/XPayroll/en/articles/8512408-deductions-loss-of-pay-days">
                  Deduct From
                </Link>
              </Box>
              {isM2S1Enabled && <Box textAlign="left">&nbsp;</Box>}
            </Box>
            <Box marginTop="spacing.0">
              <Divider thickness="thin" variant="subtle" />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="20px">
            {nonLopDeductionsInternal.map((deductionValue, idx) => {
              return (
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="32px"
                  alignItems="center"
                  key={`fnf-deduction-${idx}`}>
                  <Box flex="1.5" textAlign="left">
                    {isM2S1Enabled ? (
                      <AutoCompleteV2
                        options={searchSalaryComponents(
                          getDeductionsSearchSkipList(nonLopDeductionsInternal, deductionValue.id),
                        )}
                        itemToKey={(item) => item.id}
                        itemToLabel={(item) => capitalizeAllWordsInString(item.name)}
                        itemToDescription={(item) => getSearchDropdownDescription(item) ?? ''}
                        isDisabled={isFetchingSalaryComponents}
                        placeholder="Eg. Laptop Recovery"
                        value={deductionValue.id}
                        label=""
                        onChange={(item) => onSelectDeduction(idx, item)}
                        onClearSelection={() => onClearSelection(idx)}
                        footer={
                          <Button
                            isFullWidth
                            size="small"
                            variant="tertiary"
                            icon={PlusIcon}
                            onClick={(e) => openDeductionsCreation()}>
                            Create deduction
                          </Button>
                        }
                      />
                    ) : (
                      <TextInput
                        placeholder="Eg. Laptop Recovery"
                        value={deductionValue.name}
                        label=""
                        onChange={(e) => {
                          onDeductionsChange(idx, 'name', e.value ?? '');
                        }}
                        isDisabled={
                          isHoldSalaryPayComplianceEnabled && isFnfProcessed
                            ? Boolean(deductionValue.isDisabled)
                            : false
                        }
                      />
                    )}
                  </Box>
                  <Box flex="1" textAlign="left">
                    <TextInput
                      placeholder="Enter amount here"
                      value={deductionValue.amount.toString()}
                      label=""
                      type="number"
                      prefix="₹"
                      onChange={(e) => {
                        onDeductionsChange(idx, 'amount', e.value ?? '');
                      }}
                      isDisabled={
                        isHoldSalaryPayComplianceEnabled && isFnfProcessed
                          ? Boolean(deductionValue.isDisabled)
                          : false
                      }
                    />
                  </Box>
                  <Box flex="1" textAlign="left">
                    <Dropdown>
                      <SelectInput
                        label=""
                        isDisabled={
                          (isHoldSalaryPayComplianceEnabled && isFnfProcessed) || isM2S1Enabled
                        }
                        defaultValue={DeductFrom.GROSS_PAY.toString()}
                        value={
                          deductionValue.deduct_from?.toString() ?? DeductFrom.GROSS_PAY.toString()
                        }
                        onChange={(e) => {
                          onDeductionsChange(idx, 'deduct_from', e.values[0]);
                        }}
                      />
                      <DropdownOverlay>
                        <ActionList>
                          <ActionListItem
                            title={'Gross Pay'}
                            value={DeductFrom.GROSS_PAY.toString()}
                          />
                          <ActionListItem title={'Net Pay'} value={DeductFrom.NET_PAY.toString()} />
                        </ActionList>
                      </DropdownOverlay>
                    </Dropdown>
                  </Box>
                  {isM2S1Enabled && (
                    <Box>
                      <IconButton
                        icon={CloseIcon}
                        emphasis="subtle"
                        accessibilityLabel="Delete item"
                        onClick={() => onDeleteItem(idx)}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          {nonLopDeductionsInternal.length < 10 && (
            <Box width="36px">
              <Button
                variant="secondary"
                icon={PlusIcon}
                color="white"
                isFullWidth={true}
                onClick={onAdd}
                accessibilityLabel="Add new deduction"
              />
            </Box>
          )}
        </Box>
      </InputBoxWrapper>
    </>
  );
};

export default FnfDeductions;
