import { Theme } from '@razorpay/blade/components';
import { DocumentStatus } from '../../constants';

export const getBorderColor = (status: DocumentStatus, theme: Theme) => {
  switch (status) {
    case DocumentStatus.NOT_INITIATED:
      return theme.colors.surface.border.gray.subtle;
    case DocumentStatus.VERIFYING:
    case DocumentStatus.UPLOADING:
      return theme.colors.surface.border.gray.muted;
    case DocumentStatus.SUCCESS:
    case DocumentStatus.VERIFIED:
      return theme.colors.feedback.border.positive.intense;
    case DocumentStatus.ERROR:
      return theme.colors.feedback.border.negative.intense;
    default:
      return theme.colors.surface.border.gray.subtle;
  }
};

export const getSubTextColor = (status: DocumentStatus, theme: Theme) => {
  switch (status) {
    case DocumentStatus.NOT_INITIATED:
      return theme.colors.surface.text.gray.disabled;
    case DocumentStatus.UPLOADING:
    case DocumentStatus.VERIFYING:
      return theme.colors.surface.text.gray.disabled;
    case DocumentStatus.SUCCESS:
    case DocumentStatus.VERIFIED:
      return theme.colors.feedback.text.positive.intense;
    case DocumentStatus.ERROR:
      return theme.colors.feedback.text.negative.intense;
    default:
      return theme.colors.surface.text.gray.disabled;
  }
};
