import React from 'react';
import { KycPageWrapper } from 'components/Kyc/styles';
import { Sidebar } from './Sidebar';
import { MainContent } from './MainContent';

interface OnboardingLayoutProps {
  children: JSX.Element[];
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return <KycPageWrapper>{children}</KycPageWrapper>;
};

export { OnboardingLayout, MainContent, Sidebar };
