import React from 'react';
import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Text,
} from '@razorpay/blade/components';
import HeaderSection from './HeaderSection';

const ProrationInfo = () => {
  return (
    <>
      <HeaderSection
        title={'Proration of a component'}
        description={
          'Proration adjusts a salary component based on the actual days an employee worked'
        }
      />

      <Divider marginY={'spacing.7'} />

      <Box display={'flex'} flexDirection={'column'}>
        <Text weight={'semibold'}>
          If Proration is{'  '}
          <Badge emphasis={'subtle'} color={'positive'}>
            Enabled
          </Badge>
        </Text>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'}>
          <Text size="small" color={'surface.text.gray.muted'}>
            Component is calculated proportionally based on the days worked during the pay period.
          </Text>
          <Text size="small" color={'surface.text.gray.muted'}>
            Example:
          </Text>
          <List size={'small'} variant={'unordered'}>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                An employee's monthly salary is 20,000, but they joined on the 15th of a 30-day
                month.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                If proration is enabled, they will receive only half the salary, i.e., 10,000,
                because they worked 15 out of 30 days.
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>

      <Divider marginY={'spacing.7'} />

      <Box display={'flex'} flexDirection={'column'}>
        <Text weight={'semibold'}>
          If Proration is{'  '}
          <Badge emphasis={'subtle'} color={'information'}>
            Disabled
          </Badge>
        </Text>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'}>
          <Text size="small" color={'surface.text.gray.muted'}>
            The employee will receive the full amount of the salary component, regardless of the
            number of days worked.
          </Text>
          <Text size="small" color={'surface.text.gray.muted'}>
            Example:
          </Text>
          <List size={'small'} variant={'unordered'}>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                Same situation: if proration is disabled, the employee would still receive the full
                20,000, even though they worked only 15 days.
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default ProrationInfo;
