import React, { ReactElement } from 'react';
import ActionItemsCardList from './ActionItemsCardList';
import { getActionItemCardData, filterActionItemCardData } from './actionItemsCardData';
import { ActionItems } from '../../../../../constants/actionItems';
import { GetOrgFlexiPartnerDetailsSchemaContract } from "schemas/GetOrgFlexiPartnerDetailsSchema";
import { KYC_STATUS } from "constants/flexibenefits";
import { ActionItemsCardDataType } from './ActionItemsCardList';

const ZaggleActionItemsList = ({data} : {data:GetOrgFlexiPartnerDetailsSchemaContract}):ReactElement => {
    
    const allowedActionItems = [];

    if(data  && (data.kycData.status === KYC_STATUS.STATUS_NOT_INITIATED || data.kycData.status === KYC_STATUS.STATUS_PENDING || data.kycData.status === KYC_STATUS.STATUS_ON_HOLD)) {
        allowedActionItems.push(ActionItems.KYC_VERIFICATION);
    }

    if(data && !data.enableForAllEmployees){
        allowedActionItems.push(ActionItems.ENABLE_FOR_ALL_EMPLOYEES);
    }

    const actionItemsCardList : ActionItemsCardDataType[] = filterActionItemCardData(allowedActionItems,getActionItemCardData());


    return (
        <>
            <ActionItemsCardList actionItemsCardList={actionItemsCardList} flexiDetails={data}/>
        </>
    )
}

export default ZaggleActionItemsList;