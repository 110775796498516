import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import React, { ReactElement } from 'react';
import WhatsAppIntroduction from './components/WhatsAppIntroduction';
import { useQuery } from 'react-query';
import { WHATSAPP_STATUS_QUERY } from './queries';
import api from '../../../api';
import Spinner from '../../ui/Spinner';
import ErrorMessage from '../../ui/ErrorMessage';
import WhatsAppUninstall from './components/WhatsAppUninstall';
import { AppError } from 'components/AppError';

function WhatsAppSettings() {
  const statusQuery = useQuery(
    WHATSAPP_STATUS_QUERY,
    () => {
      return api.whatsappSettings.fetchActivationStatus();
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );

  if (statusQuery.status === 'loading' || statusQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (statusQuery.status === 'error') {
    return (
      <ErrorMessage title="Something went wrong!">
        <div>{statusQuery.error || 'Please contact support.'}</div>
      </ErrorMessage>
    );
  }

  if (statusQuery.data?.enabled) {
    return <WhatsAppUninstall />;
  }

  return <WhatsAppIntroduction />;
}

function WhatsAppSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase>
        <WhatsAppSettings />
      </MainCase>
      <RightSidebar></RightSidebar>
    </PageWrapper>
  );
}

export default WhatsAppSettingsWithPageWrapper;
