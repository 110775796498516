import { useFilters } from './useFilters';


const PaginationFiltersMap = {
  page: 'page',
  limit: 'limit'
};

type PaginationProps = {
  page: number; 
  limit:number;
}

const usePagination = ( { page, limit } : PaginationProps) => {

  const { currentFilters, applyFilter } = useFilters({filtersMap: PaginationFiltersMap});

  const currentPage = Number(currentFilters.page ?? page);
  const itemsPerPage = Number(currentFilters.limit ?? limit);

  const setCurrentPage = (currentPage: number) => {
    applyFilter(PaginationFiltersMap.page, currentPage.toString());
  };

  const setItemsPerPage = (itemsPerPage: number) => {
    applyFilter(PaginationFiltersMap.limit, itemsPerPage.toString());
  };

  return {
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
  };

};

export { usePagination };
