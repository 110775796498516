import {
  Alert,
  Box,
  Button,
  Checkbox,
  Heading,
  Link,
  Spinner,
  Text,
  TextInput,
} from '@razorpay/blade/components';
import api from 'api';
import { TwoFaOtp, UpdateApiAccessSetPermissionsPayload } from 'api/apiAccess';
import OtpModalBlade from 'components/Otp/OtpModalBlade';
import Breadcrumb from 'components/ui/Breadcrumb';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';
import PageLayout from 'components/ui/Layout/PageLayout';
import useKycStatus from 'hooks/useKycStatus';
import useTwoFactorAuth from 'hooks/useTwoFactorAuth';
import React, { FormEvent, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { parseError } from 'utils/Api';
import { AppError } from 'utils/AppError';
import { showErrorToastNotification, showSuccessToastNotification } from 'utils/ToastEvents';
import { getOtpModalProps } from 'utils/otp';
import useReduxSelector from 'utils/useReduxSelector';

const actions = {
  resetKey: 'reset-api-key',
  disableKey: 'disable-api-key',
  setPermissions: 'set-api-permissions',
};

const ApiAccessView = () => {
  const [apiKey, setApiKey] = React.useState<null | string>(null);
  const [isKeyVisible, setIsKeyVisible] = React.useState(false);
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const orgId = useReduxSelector(loggedInUserSelectors.organizationID);
  const { isKycVerified } = useKycStatus();
  const { email, isAuthenticatorAppEnabled, isExperimentActive, splitzQuery } = useTwoFactorAuth();
  const [isResendAttempt, setIsResendAttempt] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleHideKey = () => {
    setIsKeyVisible((prev) => !prev);
  };

  const {
    isFetching,
    isError,
    refetch,
    data,
    error: getApiAccessDetailsError,
  } = useQuery({
    queryFn: () => api.apiAccess.getApiAccessDetails(),
    onSuccess(data) {
      setApiKey(data.apiKey);
      setIsOtpGenerated(false);
      setCurrentAction('');
      setIsResendAttempt(false);
    },
    enabled: isKycVerified,
    onError(e: typeof AppError) {
      showErrorToastNotification({
        text: parseError(e),
        timeout: 5000,
      });
    },
  });

  const apiPermissions = data?.apiPermissions || {};

  const mask = Array(apiKey?.length).fill('•').join('');

  const updatePermissions = useMutation({
    mutationFn: api.apiAccess.updatePermissions,
    onSuccess() {
      setCurrentAction(actions.setPermissions);
      setIsOtpGenerated(true);
      resetAllQueries();
    },
    onError(e: typeof AppError) {
      showErrorToastNotification({
        text: parseError(e),
        timeout: 5000,
      });
    },
  });

  const setKey = useMutation({
    mutationFn: api.apiAccess.setKey,
    onSuccess() {
      setCurrentAction(actions.resetKey);
      setIsOtpGenerated(true);
      resetAllQueries();
    },
    onError(e: typeof AppError) {
      showErrorToastNotification({
        text: parseError(e),
        timeout: 5000,
      });
    },
  });

  const disableKey = useMutation({
    mutationFn: api.apiAccess.disableKey,
    onSuccess() {
      setCurrentAction(actions.disableKey);
      setIsOtpGenerated(true);
      resetAllQueries();
    },
    onError(e: typeof AppError) {
      showErrorToastNotification({
        text: parseError(e),
        timeout: 5000,
      });
    },
  });

  const setKeyWithOtp = useMutation({
    mutationFn: api.apiAccess.setKeyWithOtp,
    onSuccess() {
      refetch();
      setIsOtpGenerated(false);
      showSuccessToastNotification({
        text: 'API access details updated successfully!',
        timeout: 5000,
      });
    },
    onError(e: typeof AppError) {},
  });

  const disableKeyWithOtp = useMutation({
    mutationFn: api.apiAccess.disableKeyWithOtp,
    onSuccess() {
      refetch();
      setIsOtpGenerated(false);
      showSuccessToastNotification({
        text: 'API access details updated successfully!',
        timeout: 5000,
      });
    },
    onError(e: typeof AppError) {},
  });

  const updatePermissionsWithOtp = useMutation({
    mutationFn: api.apiAccess.updatePermissionsWithOtp,
    onSuccess() {
      showSuccessToastNotification({
        text: 'API access details updated successfully!',
        timeout: 5000,
      });
      refetch();
    },
    onError(e: typeof AppError) {},
  });

  const resetAllQueries = () => {
    setKey.reset();
    setKeyWithOtp.reset();
    disableKey.reset();
    disableKeyWithOtp.reset();
    updatePermissions.reset();
    updatePermissionsWithOtp.reset();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // Get the updated permissions from form data
    const formData = new FormData(e.currentTarget);
    e.preventDefault();

    setCurrentAction(actions.setPermissions);
    const payload = Object.fromEntries(formData.entries());
    updatePermissions.mutate(payload as UpdateApiAccessSetPermissionsPayload);
  };

  const handleResetKeyClick = () => {
    setKey.mutate();
  };

  const handleDisableKeyClick = () => {
    disableKey.mutate();
  };

  const handleResendClick = async (
    e: React.SyntheticEvent<Element, Event>,
    resetTimerCallback: Function,
  ) => {
    setIsResendAttempt(false);
    if (currentAction === actions.setPermissions) {
      const formData = new FormData(formRef.current!);
      const payload = Object.fromEntries(formData.entries());
      await updatePermissions.mutateAsync(payload as UpdateApiAccessSetPermissionsPayload);
    } else if (currentAction === actions.resetKey) {
      await setKey.mutateAsync();
    } else if (currentAction === actions.disableKey) {
      await disableKey.mutateAsync();
    }
    setIsResendAttempt(true);
    resetTimerCallback();
  };

  const handleVerify = (e: React.SyntheticEvent<Element, Event>, otp: string) => {
    e.preventDefault();

    if (otp) {
      let formData = new FormData();
      formData.append('two_factor_auth_otp', otp);
      if (currentAction === actions.resetKey) {
        const payload = Object.fromEntries(formData.entries());
        setKeyWithOtp.mutate(payload as TwoFaOtp);
      } else if (currentAction === actions.disableKey) {
        const payload = Object.fromEntries(formData.entries());
        disableKeyWithOtp.mutate(payload as TwoFaOtp);
      } else if (currentAction === actions.setPermissions) {
        formData = new FormData(formRef.current!);
        formData.append('two_factor_auth_otp', otp);
        const payload = Object.fromEntries(
          formData.entries(),
        ) as UpdateApiAccessSetPermissionsPayload;
        updatePermissionsWithOtp.mutate(payload as UpdateApiAccessSetPermissionsPayload);
      }
    }
  };

  const isLoading =
    splitzQuery.isLoading ||
    setKey.isLoading ||
    disableKey.isLoading ||
    updatePermissions.isLoading ||
    isFetching;

  const error =
    setKey.error ||
    disableKey.error ||
    updatePermissions.error ||
    setKeyWithOtp.error ||
    disableKeyWithOtp.error ||
    updatePermissionsWithOtp.error;

  if (splitzQuery.isSuccess && !isExperimentActive) {
    window.location.href = '/companyOnboarding?step=899&redirect=%2Fsettings';
    return <></>;
  }

  if (splitzQuery.isError) {
    return <ErrorWithRetry onRetry={splitzQuery.refetch} />;
  }

  if (!isKycVerified) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width={{ l: '560px', base: 'auto' }}
        marginY={'spacing.11'}
        marginX={{ base: 'spacing.8', l: 'auto' }}>
        <Alert
          isDismissible={false}
          color="information"
          title="KYC Incomplete"
          description="Your KYC verification is incomplete. Please complete it to proceed."
          actions={{
            primary: {
              text: 'Continue to KYC Verification',
              onClick: () => {
                window.location.href = '/companyOnboarding?step=6';
              },
            },
          }}
        />
      </Box>
    );
  }

  const content = (
    <>
      <Box display="flex" flexDirection="column" gap="spacing.8">
        <Box display="flex" flexDirection="column" gap="spacing.3">
          <Heading size="medium">API Setup</Heading>
          <Text>
            Always keep the API Key private! Anyone with your key will be able to access and modify
            your data. For documentation, please{' '}
            <Link href="https://documenter.getpostman.com/view/11662503/Tzm5HckE"> click here</Link>
            .
          </Text>
        </Box>
        <TextInput label="API ID" value={orgId?.toString()} isDisabled />
        <TextInput
          label="API Key"
          placeholder="Not set (API disabled)"
          value={apiKey ? (isKeyVisible ? apiKey : mask) : ''}
          isDisabled
        />
        <Box display="flex" alignItems="center" gap="spacing.6">
          <Button
            isLoading={setKey.isLoading}
            isDisabled={isFetching || disableKey.isLoading || updatePermissions.isLoading}
            size="large"
            variant="tertiary"
            onClick={handleResetKeyClick}>
            {apiKey ? 'Reset' : 'Set'} Key
          </Button>
          {Boolean(apiKey) && (
            <>
              <Button
                isDisabled={
                  isFetching ||
                  disableKey.isLoading ||
                  updatePermissions.isLoading ||
                  setKey.isLoading
                }
                size="large"
                variant="tertiary"
                onClick={handleHideKey}>
                {isKeyVisible ? 'Hide' : 'Show'} Key
              </Button>
              <Button
                isLoading={disableKey.isLoading}
                isDisabled={isFetching || setKey.isLoading || updatePermissions.isLoading}
                size="large"
                variant="secondary"
                color="negative"
                onClick={handleDisableKeyClick}>
                Disable API
              </Button>
            </>
          )}
        </Box>
      </Box>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap="spacing.7" marginTop="52px">
          <Heading size="small">API Permissions</Heading>
          <Box display="flex" flexDirection="column" gap="spacing.6">
            {Object.entries(apiPermissions).map(([key, { label, disabled, value }]) => (
              <Checkbox key={key} isDisabled={disabled} defaultChecked={value} name={key}>
                {label}
              </Checkbox>
            ))}
          </Box>
        </Box>
        <Box marginTop="spacing.8">
          <Text marginY="spacing.8">*Indicated required fields</Text>
          <Button
            isLoading={updatePermissions.isLoading}
            isDisabled={isFetching || disableKey.isLoading || setKey.isLoading}
            type="submit">
            Continue
          </Button>
        </Box>

        {isOtpGenerated && (
          <OtpModalBlade
            actionName={currentAction}
            isLoading={
              setKeyWithOtp.isLoading ||
              disableKeyWithOtp.isLoading ||
              updatePermissionsWithOtp.isLoading
            }
            isDisabled={isLoading}
            isOpen={isOtpGenerated}
            data={{
              ...getOtpModalProps({
                error: error,
                isAuthenticatorAppEnabled,
                email: email,
              }),
              isResendAttempt: !isLoading && isResendAttempt,
            }}
            onDismiss={() => setIsOtpGenerated(false)}
            onVerify={handleVerify}
            onResend={handleResendClick}
          />
        )}
      </form>
    </>
  );

  const loader = (
    <Box display="grid" placeItems="center">
      <Spinner accessibilityLabel="loading" />
    </Box>
  );

  return (
    <PageLayout
      breadcrumb={
        <Box marginLeft="25px">
          <Breadcrumb
            name="API Setup"
            urlMaps={{
              Settings: '/settings',
            }}
          />
        </Box>
      }
      boxProps={{
        height: 'auto',
        width: 'auto',
      }}
      hideHeading>
      {isFetching ? (
        loader
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={{ l: '560px', base: 'auto' }}
          marginY={'spacing.11'}
          marginX={{ base: 'spacing.8', l: 'auto' }}>
          {isError ? (
            <Alert color="negative" description={parseError(getApiAccessDetailsError)} />
          ) : (
            content
          )}
        </Box>
      )}
    </PageLayout>
  );
};

export default ApiAccessView;
