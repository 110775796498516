import React from 'react';
import { NpsSettings } from './types';
import {
    Alert,
    Box,
    Heading,
    Switch,
    Text,
    Button,
    List,
    ListItem
} from '@razorpay/blade/components';
import DeclarationWindow from '../../ui/DeclarationWindow/DeclarationWindow';
import { Reveal } from '../../ui/Reveal/Reveal';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import { UPDATE_NPS_SETTINGS } from './queries';
import api from 'api';
import { useMutation } from 'react-query';
import { AppError } from 'utils/AppError';
import { createPayload } from './utils';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { showToastViaEvent } from '../../../utils/ToastEvents';
import { useRef } from 'react';
import Errors from 'components/ui/Form/Errors';

export interface GeneralSettingsProps {
    settings: NpsSettings;
    setSettings: (value: React.SetStateAction<NpsSettings>) => void;
    onChange: (key: keyof NpsSettings, value: boolean) => void;
    isLoading?: boolean;
}

const GeneralSettings = ({ settings, setSettings, onChange, isLoading }: GeneralSettingsProps) => {
    const org = useSelector(loggedInUserSelectors.currentOrganization);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleDeclarationSettingsChange = (
        value: GenericDeclaration,
    ) => {
        setSettings((p) => ({
            ...p,
            'NpsDeclarationWindow': value,
        }));
    };

    const handleSave = () => {
        org?.id && updateSettings.mutate(createPayload(settings));
    };

    const updateSettings = useMutation({
        mutationKey: UPDATE_NPS_SETTINGS,
        mutationFn: api.nps.updateOrgNpsSettings,
        onSuccess: (data) => {
            showToastViaEvent({
                text: 'Settings saved successfully!',
                timeout: 3000,
                type: 'success',
                icon: 'success',
            });
            window.location.href = '/settings';
        },
        onError: (e: typeof AppError) => {
            window.requestAnimationFrame(() => {
                containerRef.current?.scrollIntoView();
            });
        },
    });

    return (
        (<Box marginX={{ base: 'spacing.8', l: 'auto' }} width="500px" paddingY="spacing.6">
            <Heading size="small">⚙️ General</Heading>
            {updateSettings.error && (
                <Box
                    ref={containerRef}
                    marginX={{ base: 'spacing.8', l: 'auto' }}
                    width="500px"
                    paddingTop="spacing.8"
                >
                    <Alert description={<Errors errorResponse={updateSettings.error} />} color="negative" />
                </Box>
            )}
            <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.4">

                <Box
                    borderWidth="thin"
                    borderColor="surface.border.gray.subtle"
                    borderRadius="medium"
                    padding="spacing.7"
                    backgroundColor="surface.background.gray.moderate">
                    <Box display="flex" gap="spacing.8" justifyContent="space-between">
                        <Box>
                            <Text weight="semibold" color="surface.text.gray.muted">
                                Enable/Disable NPS
                            </Text>
                            <Text variant="caption" marginTop="spacing.2" color="surface.text.gray.subtle" size='small'>
                                Include employer contribution to NPS in employee CTC
                            </Text>
                        </Box>
                        <Switch
                            isDisabled={isLoading}
                            isChecked={settings.isNpsEnabled}
                            size="small"
                            accessibilityLabel="Enable/Disable NPS"
                            alignSelf="flex-start"
                            onChange={({ isChecked }) => onChange('isNpsEnabled', isChecked)}
                        />
                    </Box>

                </Box>
                <Reveal isOpen={settings.isNpsEnabled}>
                    <Box
                        borderWidth="thin"
                        borderColor="surface.border.gray.subtle"
                        borderRadius="medium"
                        padding="spacing.7"
                        backgroundColor="surface.background.gray.moderate">
                        <Box display="flex" gap="spacing.8" justifyContent="space-between">
                            <Box>
                                <Text weight="semibold" color="surface.text.gray.muted">
                                    Employees can declare NPS contribution
                                </Text>
                                <Text variant="caption" marginTop="spacing.2" color="surface.text.gray.subtle" size='small'>
                                    Employees can declare their NPS contribution, with maximum cap of 14% as per compliance guidelines.
                                </Text>
                            </Box>
                            <Switch
                                isDisabled={isLoading}
                                isChecked={settings.employeeCanDeclareNpsLimit}
                                size="small"
                                accessibilityLabel="Employee can Declare NPS limit"
                                alignSelf="flex-start"
                                onChange={({ isChecked }) => onChange('employeeCanDeclareNpsLimit', isChecked)}
                            />
                        </Box>
                        

                    </Box>
                    <Alert
                        isDismissible={false}
                        marginTop={"spacing.4"}
                        description={
                            <>
                                <Text size='small'>Points to note:</Text>
                                <List size="small">
                                    <ListItem>
                                        Only deductions will be handled on XPayroll, NPS payments need to be handled by the organization outside RazorpayX Payroll.
                                    </ListItem>
                                    <ListItem>
                                        The employee requires PRAN for NPS declaration. In case the employee lacks a PRAN, the organization is responsible for facilitating the PRAN creation process.
                                    </ListItem>
                                </List>
                            </>
                        }
                        color="information"
                    />
                    
                </Reveal>
                <Reveal isOpen={settings.isNpsEnabled && settings.employeeCanDeclareNpsLimit}>
                    <DeclarationWindow
                        heading={'🪟 Declaration Window for NPS'}
                        description={'Define the period during which employees can make changes to their NPS contribution.'}
                        declarationWindow={settings.NpsDeclarationWindow}
                        onChange={(val) => handleDeclarationSettingsChange(val)}
                        isLoading={isLoading}
                        disableCustomYearSelector
                        customMonthOffset={3}
                    />
                </Reveal>
                <Box paddingBottom="spacing.11" marginX={{ base: 'spacing.8', l: 'auto' }} marginTop={'60px'} width="500px">
                    <Button
                        isDisabled={isLoading}
                        isLoading={updateSettings.isLoading}
                        onClick={handleSave}
                    >
                        Save & Confirm
                    </Button>
                </Box>
            </Box>
        </Box>)
    );
}

export default GeneralSettings;