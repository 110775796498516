import api from 'api';
import { useQuery } from 'react-query';
import { GET_HRMS_ENTITY_TYPES_QUERY_KEY, KNIT_HRMS_QUERY_RETRY_LIMIT } from './constants';
import { useState } from 'react';

interface UseGetKnitHrmsEntityTypesLovQueryOpts {
  enabled: boolean;
}

const useGetKnitHrmsEntityTypesLovQuery = (
  knitHrmsApp: string,
  opts: UseGetKnitHrmsEntityTypesLovQueryOpts,
) => {
  const [retryAttempts, setRetryAttempts] = useState(0);
  const hrmsEntityTypesQuery = useQuery({
    queryFn: () => api.knit.getHrmsEntityTypes(knitHrmsApp),
    queryKey: [GET_HRMS_ENTITY_TYPES_QUERY_KEY, knitHrmsApp],
    staleTime: 300000, // setting 5 minute stale time as this data won't change frequently,
    enabled: opts.enabled,
    onError: () => {
      setRetryAttempts((prev) => prev + 1);
    },
  });

  const retryLimitExhausted = retryAttempts > KNIT_HRMS_QUERY_RETRY_LIMIT;

  return {
    hrmsEntityTypesQuery,
    retryLimitExhausted,
  };
};

export { useGetKnitHrmsEntityTypesLovQuery };
