import React, { ReactElement } from 'react';
import styles from '../index.module.scss';
import { Input, InputElement } from '../../ui/Form/Input/index';
import { InterestType, InterestTypeStringMap } from '../constants';
import { CreateLoanFormProps } from '../CreateLoan';
import { Option, Select } from '../../ui/Form/Select/index';
import InputInfo from '../../ui/Form/Input/InputInfo/index';
import ExternalLink from '../../ui/ExternalLink/index';
import { NUMERIC_REGEX, DECIMAL_REGEX, TWO_DIGIT_REGEX } from '../../../utils/regexes';
import { PrimaryButtonV2 } from '../../ui/Button/index';
import { toIndianCurrencyString } from 'utils/Numbers';
import { useMutation } from 'react-query';
import { CreateLoanRequestSchemaContract } from '../../../schemas/CreateLoanRequestSchema';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { getStaticMediaUrl } from '../../../utils/Urls';
import docWithBlueLines from 'assets/icon--document--blue--line.svg'
import calendarTick from 'assets/icon--calendar--tick.svg'



export default function CreateLoanFormBlock(props: CreateLoanFormProps): ReactElement {

    const features = useSelector(loggedInUserSelectors.features);
    const isReducingEMIEnabled = !!features?.['loan-reducing-emi-flag'];

    const updateFormStateWithEvent = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, regex?: string, parseAsInt?: boolean) => {
        var name = event.target.name;
        var value = event.target.value;
        if (regex) {
            value = (new RegExp(regex)).test(event.target.value.replaceAll(',', '')) ? event.target.value.replaceAll(',', '') : '';
        }
        props.updateCreateLoanFormState(name, parseAsInt ? parseInt(value) : value)
    }

    const createLoanMutation = useMutation(
        (requestData: CreateLoanRequestSchemaContract) =>
            api.loans.createLoan(requestData),
        {
            onSuccess: (data) => {
                props.setError(undefined);
                window.location.href = `/loanPayment`;
            },
            onError: (error: typeof AppError) => {
                props.setError(error);
            },
        },
    );

    const createLoan = () => {
        const requestData: CreateLoanRequestSchemaContract = {
            user_id: parseInt(props.peopleId),
            principal: parseInt(props.createLoanFormData.principal),
            interest_rate: parseFloat(props.createLoanFormData.rateOfInterest),
            perk_rate: parseFloat(props.createLoanFormData.perquisites),
            tenure: parseInt(props.createLoanFormData.tenure),
            remarks: props.createLoanFormData.remarks,
            interest_type: props.createLoanFormData.interestType ? props.createLoanFormData.interestType : 0,
            recovery_start_from: props.createLoanFormData.emiStartFrom
        }

        createLoanMutation.mutate(requestData);

    }

    return (
        <>
            <form onSubmit={(event) => { event.preventDefault(); createLoan();}}>

                <InputElement required label={'Loan Name'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        maxLength={100}
                        value={props.createLoanFormData.remarks}
                        required
                        name="remarks"
                        onChange={(event) => { updateFormStateWithEvent(event) }}
                        placeholder="Name for the Loan"
                        className={styles['input-element']}
                    />

                </InputElement>


                <div className={styles['form-section-header']}>
                        <img src={getStaticMediaUrl(docWithBlueLines)} alt="" className={styles['icon']}/>

                        <div className={styles['sub-title']}>
                            {`Loan Amount & Interest Details`}
                        </div>
                    </div>

                <InputElement required label={'Loan Amount'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        maxLength={10}
                        value={props.createLoanFormData.principal !== '' ? toIndianCurrencyString(parseInt(props.createLoanFormData.principal.replaceAll(',', '')), true) : props.createLoanFormData.principal}
                        required
                        name="principal"
                        onBlur={(event) => { updateFormStateWithEvent(event, NUMERIC_REGEX); props.mutateLoanData(); }}
                        onChange={(event) => { updateFormStateWithEvent(event) }}
                        placeholder="Enter Amount"
                        className={styles['input-element']}
                    />
                    <InputInfo>
                        Enter the amount to pay as a loan
                    </InputInfo>

                </InputElement>

                <InputElement required label={'Interest Type'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Select
                        name="interestType"
                        onChange={
                            (event: React.ChangeEvent<HTMLSelectElement>) =>
                                updateFormStateWithEvent(event, undefined, true)
                        }
                        onBlur={() => { props.mutateLoanData(); }}
                        value={props.createLoanFormData.interestType}
                        required
                        className='mb-0'
                    >
                        <Option value={InterestType.FLAT_INTEREST}>{InterestTypeStringMap[InterestType.FLAT_INTEREST]}</Option>
                        <Option value={InterestType.REDUCING_INTEREST}>{InterestTypeStringMap[InterestType.REDUCING_INTEREST]}</Option>
                        { isReducingEMIEnabled && <Option value={InterestType.REDUCING_EMI}>{InterestTypeStringMap[InterestType.REDUCING_EMI]}</Option>}
                    </Select>
                    <InputInfo>
                        { isReducingEMIEnabled ? 'We support three interest types. ' : 
                        'We support two interest types : Flat Interest and Reducing Interest. ' } Read <ExternalLink href={'https://intercom.help/XPayroll/en/articles/8298492-how-do-i-create-and-pay-an-employee-loan#h_8478ab031a'} >this</ExternalLink> article to understand the difference.
                    </InputInfo>

                </InputElement>

                <InputElement required label={'Rate of Interest (per annum)'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        value={props.createLoanFormData.rateOfInterest}
                        required
                        name="rateOfInterest"
                        onChange={(event) => { updateFormStateWithEvent(event) }}
                        onBlur={(event) => { updateFormStateWithEvent(event, DECIMAL_REGEX); props.mutateLoanData(); }}
                        placeholder=""
                        className={styles['input-element']}
                    />
                    <InputInfo>
                        Enter the rate of interest for the loan.
                    </InputInfo>

                </InputElement>

                <InputElement required label={'Perquisite %'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        value={props.createLoanFormData.perquisites}
                        required
                        name="perquisites"
                        onChange={(event) => { updateFormStateWithEvent(event) }}
                        onBlur={(event) => { updateFormStateWithEvent(event, DECIMAL_REGEX); props.mutateLoanData(); }}
                        placeholder="Enter the perquisite %"
                        className={styles['input-element']}
                    />
                    <InputInfo>
                        Perquisite % is calculated as the difference between the SBI interest rate and the interest rate your organisation will give. Please refer to the current SBI rates before calculating the difference.
                    </InputInfo>

                </InputElement>

                <div className={styles['form-section-header']}>
                        <img src={getStaticMediaUrl(calendarTick)} alt="" className={styles['icon']}/>

                        <div className={styles['sub-title']}>
                            {`Repayment Details`}
                        </div>
                    </div>

                <InputElement required label={'Instalment Terms (no. of months)'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        value={props.createLoanFormData.tenure}
                        required
                        name="tenure"
                        onChange={(event) => { updateFormStateWithEvent(event) }}
                        onBlur={(event) => { updateFormStateWithEvent(event, TWO_DIGIT_REGEX); props.mutateLoanData(); }}
                        placeholder="Number of months"
                        className={styles['input-element']}
                    />
                    <InputInfo>
                        Enter the duration of the loan
                    </InputInfo>

                </InputElement>

                <InputElement required label={'EMI Deduction Starts From (month)'} className={` mt-8 mb-10 ${styles['label']}`}>
                    <Input
                        type="text"
                        value={props.createLoanFormData.emiStartFrom ?
                            `${new Date(props.createLoanFormData.emiStartFrom).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}` : ''
                        }
                        required
                        name="emiStartsFrom"
                        placeholder="Month to start EMI recovery from"
                        readOnly={true}
                        className={styles['input-element']}
                        disabled
                    />
                    <InputInfo>
                        Payroll month from which the EMI deduction starts
                    </InputInfo>

                </InputElement>

                <PrimaryButtonV2 disabled={createLoanMutation.isLoading} type="submit">
                    Add to Pending Loans
                </PrimaryButtonV2>
            </form>
        </>
    );

}