export const ChatMessageTypes = {
  text: 'text',
  regimeBreakup: 'regimeBreakup',
  taxBreakup: 'taxBreakup',
  errorDisplay: 'errorDisplay',
  processing: 'processing',
  knowMore: 'knowMore',
  calculation: 'calculation'
} as const;

// @TODO payroll-ai-assistant - replace with correct enums once from received from BE
export const ChatPollingStatusTypes = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
} as const;

// @TODO payroll-ai-assistant - replace with correct enums once from received from BE
export const ChatResponseTypes = {
  REGIME_BREAKUP: 'REGIME_BREAKUP',
  TAX_BREAKUP: 'TAX_BREAKUP',
} as const;

export const BotProcessingMessage = 'Brace for impact! I’m crunching numbers...';
