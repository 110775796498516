import '../webpack-dynamic-config-change';
import React from 'react';
import ReactDOM from 'react-dom';
import AppProvider from '../AppProvider';
import BaseShell from 'components/BaseShell';
import Routes from 'components/Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ModalProvider from 'components/ui/Modals/ModalProvider';
import { initSegment } from 'utils/segment';
import { initRazorpayAnalytics } from 'utils/razorpayAnalytics';
import { isProduction } from 'utils/getEnvironment';
import V1ToastContainer from 'components/ui/Toast/ToastContainer';
import { ToastContainer } from '@razorpay/blade/components';
import BulkDownloadPolling from 'components/BulkDownloadPolling';
import { initSentry } from 'utils/sentry';
import { ErrorBoundary } from 'components/ErrorBoundary';
import FallbackUI from 'components/ErrorBoundary/FallbackUI';
import { LazyMotion } from 'framer-motion';
import '@razorpay/blade/fonts.css';

const loadFeatures = () => import('../components/Routes/features.js').then((res) => res.default);

if (isProduction()) {
  initSegment();
  initRazorpayAnalytics();
}

initSentry();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
});

const bulkDownloadPollId = sessionStorage.getItem('pollId');

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary fallbackRender={() => <FallbackUI title="An error has occured" />}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <LazyMotion strict features={loadFeatures}>
            <V1ToastContainer />
            <ToastContainer />
            {bulkDownloadPollId && <BulkDownloadPolling id={bulkDownloadPollId} />}
            <ModalProvider>
              <BaseShell>
                <Routes />
              </BaseShell>
              <ReactQueryDevtools initialIsOpen={false} />
            </ModalProvider>
            </LazyMotion>
          </AppProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('react'),
  );
});
