import { Box, Heading, Text } from '@razorpay/blade/components';
import React from 'react';
import { LinkProps, LinksGroup } from './LinksGroup';

interface SidebarSectionProps {
  title: string;
  links: LinkProps[];
}

export const SidebarSection = ({ title, links = [] }: SidebarSectionProps) => {
  return (
    (<Box display="flex" flexDirection="column" gap="spacing.4">
      <Text size="large" color="surface.text.gray.muted">{title}</Text>
      <LinksGroup links={links} />
    </Box>)
  );
};

export default SidebarSection;
