import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton } from 'components/ui/Button';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { trackGtag } from 'utils/razorpayAnalytics';
import { MessageTypes, SetMessageType } from '../contexts/RunPayrollMessageContext';
import { showToastViaEvent } from 'utils/ToastEvents';
import { GET_COMPLIANCE_ACTIONS } from 'components/RunPayroll/ComplianceActions/queries';
import { APPROVAL_DATA_FETCH } from '../utils/useApprovalLink';

import styles from './index.module.scss';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { formatPayrollMonth } from '../utils/utils';
import { dateFormats } from 'utils/Dates';

const FinalizePayroll = ({
  payrollMonth,
  onError,
  showMessage,
  doesFinalizeNeedApproval,
}: { payrollMonth: string; doesFinalizeNeedApproval: boolean } & SetErrorMessageType &
  SetMessageType) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { isAdditionsDisabled } = useIsFeatureDisabledForRearch();

  const finalize = useMutation(
    (payrollMonth: string) => {
      return api.runPayroll.finalizePayroll(payrollMonth);
    },
    {
      onSuccess: (data) => {
        trackGtag('event', 'conversion', { send_to: 'AW-928471290/lX8tCIT-1LsBEPqx3boD' });
        const gaTrack = (window as any).ga;
        gaTrack &&
          gaTrack(
            'send',
            'event',
            'Opfin Dashboard - Run Payroll',
            'Finalize Payroll Popup',
            'Finalize Payroll',
          );
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        queryClient.invalidateQueries(GET_COMPLIANCE_ACTIONS);
        if (data['hasChangeRequest']) {
          queryClient.invalidateQueries(APPROVAL_DATA_FETCH);
          showToastViaEvent({
            text: `Approval Request Sent!`,
            timeout: 5000,
            type: 'success',
            icon: 'success',
          });
        } else if (data.isPayrollBeingFinalized) {
          showMessage && showMessage({ type: MessageTypes.FINALIZE_IN_PROGRESS });
        } else if(data['hasErrors']) {
          const formattedPayrollMonth = formatPayrollMonth(payrollMonth, dateFormats.monthYear);
          showToastViaEvent({
            text: `${formattedPayrollMonth} payroll could not be finalized`,
            timeout: 5000,
            type: 'danger',
            icon: 'info',
          });
        } else {
          showMessage && showMessage({ type: MessageTypes.FINALIZED, data: payrollMonth });
        }
        closeModal();
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        onError &&
          onError({
            type: response.code,
            message: response.messageForUser,
            data: response?.otherDetails?.jsonResponse?.extraDetails,
          });
        closeModal();
      },
    },
  );

  const onFinalizePayroll = () => {
    finalize.mutate(payrollMonth);
  };

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Finalize Payroll
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          By finalizing the payroll, you are freezing your payroll data
          {isAdditionsDisabled
            ? '.'
            : 'with all the current additions, deductions and reimbursements.'}
          <br />
          <br />
          Please remember that your payroll still needs to be executed after finalization. To do so,
          please load the required funds, and then click on the "Request Execution" button.
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardButton
            onClick={onFinalizePayroll}
            isPending={finalize.isLoading}
            data-testid="finalize-payroll-modal-finalize-cta">
            {doesFinalizeNeedApproval ? 'Request Finalize Payroll' : 'Finalize Payroll'}
          </StandardButton>
        </div>
      </div>
    </Modal>
  );
};

export default FinalizePayroll;
