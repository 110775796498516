import api from 'api';
import mailIcon from 'assets/icon--email.svg';
import { WarningAlertYellow } from 'components/ui/Alert';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import { format, setDate } from 'date-fns';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { dateFormats } from 'utils/Dates';
import { showErrorToastNotification, showSuccessToastNotification } from 'utils/ToastEvents';
import { pfPaymentsDocumentationLink } from './constants';
import { formatPayrollMonth, getNextPayrollMonth } from './utils/utils';

const PFWarning = ({ className, payrollMonth }: { className?: string; payrollMonth: string }) => {
  const formattedPayrollMonth = formatPayrollMonth(payrollMonth, dateFormats.monthYear);
  let nextMonth = getNextPayrollMonth(payrollMonth);
  //   PF Payment Date is 15th of the next month
  const pfPaymentDate = format(setDate(nextMonth, 15), dateFormats.dateMonthCommaYear);

  const organization = useSelector(loggedInUserSelectors.currentOrganization);
  const orgId = String(organization?.id!);
  const userId = String(organization?.employeeDetails?.peopleId!);

  const emailEcrReport = useMutation({
    mutationFn: api.runPayroll.emailPfEcrReport,
    onSuccess: () => {
      showSuccessToastNotification({
        text: 'ECR (txt. file) will be sent on your mail within 20 minutes.',
        timeout: 5000,
      });
    },
    onError: () => {
      showErrorToastNotification({
        text: 'Failed to email ECR report',
        timeout: 5000,
      });
    },
  });

  const handleEmailReportClick = () => {
    const month = formatPayrollMonth(payrollMonth, dateFormats.monthYearShort);
    emailEcrReport.mutate({
      orgId,
      userId,
      payrollMonth: month,
    });
  };

  return (
    <WarningAlertYellow className={className}>
      <div className="text-[16px] font-bold text-white">
        PF Payment & Filing Automation is Back! 🚀 {' '}
      </div>
      <ol className="list-inside list-decimal mt-[8]" aria-label="PF Payments Warnings">
        <li className="text-[14px] text-white-o-80">
          Opt-in to PF automation before processing {formattedPayrollMonth} Payroll to take advantage of automated PF payments and filings.
          <br /><span className="italic w-full">Note: If you’ve already opted in, make sure to update the UANs for any newly registered employees on the XPayroll dashboard to avoid missing any employee!</span>
        </li>
        <li className="text-[14px] text-white-o-80">
          If you wish to make payments outside of RazorpayX Payroll, you can request the ECR.
        </li>
      </ol>
      <div className="mt-[16px] flex items-center gap-4">
        <a href={`companyOnboarding?step=4&redirect=%2Frun-payroll?payroll_month=${payrollMonth}`}>
          <StandardButton className="normal-case m-0">Opt-In to PF</StandardButton>
        </a>
        <StandardButton
          className="normal-case flex items-center m-0 gap-2"
          onClick={handleEmailReportClick}
          isPending={emailEcrReport.isLoading}>
          <img src={mailIcon} />
          Email ECR File
        </StandardButton>
        <a className="text-inherit" target="_blank" href={pfPaymentsDocumentationLink}>
          <SecondaryButton className="normal-case m-0">Learn more</SecondaryButton>
        </a>
      </div>
    </WarningAlertYellow>
  );
};

export default PFWarning;
