
import React, { useState, ReactElement, Dispatch, SetStateAction } from 'react';
import styles from './../index.module.scss';
import FileDownloadButton from '../components/DownloadButton/index';
import DatePicker from 'components/ui/DatePicker';
import dateIcon from 'assets/employeeOnboarding/date_icon.svg';
import { format } from 'date-fns';
import { InputElement } from 'components/ui/Form/Input';
import { trackXPayrollEvent } from 'utils/analytics';


const SalaryRevisionDownloadSection = ({
    effectiveDate,
    setEffectiveDate,
}: {
    effectiveDate: Date | null;
    setEffectiveDate: Dispatch<SetStateAction<any>>;
}): ReactElement => {

    const [fileDownloaded, setFileDownloaded] = useState(false);

    function onClick(){
        
      trackXPayrollEvent("salary_revision.template_download","interaction","bulk-action")

  }

    return (
        <div className={styles['section']} style={{marginTop:'48px'}}>
            <span className={styles['section-main-text']}>{'1. Select salary effective date'}</span>
            <div className='mt-6'>
              <p className="mb-4">Salary effective date </p>
              <div className="flex relative cursor-pointer">
                <DatePicker
                  id={`salary-effective-date`}
                  className="p-4 cursor-pointer"
                 
                  value={effectiveDate}
                  onChange={(value) => {
                    setEffectiveDate(value ?? new Date());
                  }}
                  placeholder={!effectiveDate ? 'Enter effective date' : ''}
                />
                <img
                  className="absolute cursor-pointer"
                  style={{ top: 'calc(50% - 8px)', right: '14px', color: 'rgba(255, 255, 255, 0.38)' }}
                  loading="lazy"
                  src={dateIcon}
                  alt=""
                />
              </div>
            </div>
            
              <div className='mt-6'>
                  <ul className={'list-disc ml-4'}>
                      <li className={styles['section-secondary-text']}>{'Select the month from which the revised salary is effective. We will auto compute arrears️ if any ⚡'}</li>
                      <li className={styles['section-secondary-text']}>Do note we do not pay PF arrears. PF arrears has to be paid by the organisation from the PF portal. <a href='https://intercom.help/XPayroll/en/articles/7913097-how-can-i-bulk-upload-salary-revisions-with-an-effective-date' style={{fontSize: '14px' , textDecoration: 'underline'}} target='_blank' rel="noreferrer">Learn more</a></li>
                      <li className={styles['section-secondary-text']}>The maximum number of rows you can upload is 500</li>
                      
                  </ul>
              </div>

            <div style={{marginTop:'48px'}}>
              <span className={`${styles['section-main-text']}`}>{'2. Download salary revision sheet'}</span>
              <div className={'mt-6'}>
                  <FileDownloadButton href={`/v2/api/bulk-salary-revision/download-template?payroll_month=${effectiveDate? format(effectiveDate, 'yyyy-MM-dd') : ""}`} fileDownloaded={fileDownloaded} setFileDownloaded={setFileDownloaded} onClick={onClick} disabled={(effectiveDate === null)}/>
              </div>
            </div>
        </div>
    )
}

export default SalaryRevisionDownloadSection;