import React, { useEffect, useState } from 'react';

export interface ExpansionPanelListProps {
  panelId: number;
  isOpen: boolean;
}

export interface UseExpansionPanelType {
  openedPanel: number;
  setOpenedPanel: React.Dispatch<React.SetStateAction<number>>;
  panelProps: ExpansionPanelListProps[];
  onHeaderClick: (panelId: number) => void;
}

const getExpansionPanelProps = (numberOfPanels: number, openedPanel: number = 1) => {
  return [...Array(numberOfPanels).keys()].map((_, i) => {
    const id = i + 1;
    return {
      panelId: id,
      isOpen: openedPanel == id ? true : false,
    };
  });
};

const useExpansionPanel = (numberOfPanels = 1): UseExpansionPanelType => {
  const [openedPanel, setOpenedPanel] = useState<number>(1);
  const [panelProps, setPanelProps] = useState<ExpansionPanelListProps[]>(
    getExpansionPanelProps(numberOfPanels),
  );

  useEffect(() => {
    setPanelProps(getExpansionPanelProps(numberOfPanels, openedPanel));
  }, [openedPanel]);

  const onHeaderClick = (panelId: number) => {
    if (panelId === openedPanel) {
      setOpenedPanel((panelId % numberOfPanels) + 1);
    } else {
      setOpenedPanel(panelId);
    }
  };

  return { openedPanel, setOpenedPanel, panelProps, onHeaderClick };
};

export default useExpansionPanel;
