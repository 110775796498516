import React, { useState, ReactElement } from 'react';
import { BulkUploadStep, BulkUploadTypes, routeToStepMap } from '../../constants/bulkUpload';
import { useLocation, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { RestrictedRoute } from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import MainCase from '../ui/MainCase/index';
import { BulkUploadDetailsSchemaContract } from '../../schemas/BulkUploadDetailsSchema';
import { HeaderWithProgressBar } from './components/HeaderWithProgressBar/index';
import DownloadAndUploadPage from './SalaryRevision/SalaryRevisionDownloadAndUploadPage';
import SalaryRevisionPreviewPage from './SalaryRevision/SalaryRevisionPreviewPage';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from './queries/queries';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import permissions from 'constants/permissions';
import { Modal } from 'components/ui/Modal';
import exitBulkUploadsExit from 'assets/bulkUploads/exit_bulk_uploads_exit.svg';
import styles from './index.module.scss';
import { PrimaryButton, StandardSecondaryButton } from 'components/ui/Button';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { Card } from 'components/ui/Card';
import ErrorMessage from 'components/ui/ErrorMessage';
import { useModal } from 'components/ui/Modals';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

export default function BulkSalaryRevision(): ReactElement {
  const location = useLocation();
  const currentStep =
    routeToStepMap[BulkUploadTypes.TYPE_BULK_SALARY_REVISION][location.pathname] ||
    BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP;

  const { openModal, closeModal } = useModal();
  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);

  if(!isOnProPlan){
    return <Redirect to={routePaths.unknown} />
  }


  return (
    <div className={styles['main-case-no-padding']}>

      <HeaderWithProgressBar
        headerText={'Salary revision & arrear calculation '}
        currentStep={currentStep}
        totalSteps={2}
        callbackOnCloseButtonClick={() => {
          openModal(
            <div
              className={`w-full justify-center flex pb-10 flex-col items-center p-8`}
              style={{gap:'32px'}} 
              >
              <img loading="lazy" src={exitBulkUploadsExit} alt="" style={{height:'88px',width:'88px'}} />
              <div className='text-center'>
                  <h2 className="m-3 mt-4 text-2xl font-semibold"> Exit bulk salary revision flow?
                  </h2>
                  <p style={{ color: 'rgba(255, 255, 255, 0.54)', fontSize: '16px' }}>
                  Please note any progress made till now will not be saved
                  </p>
              </div>
              <div className="flex gap-8">
                <PrimaryButton
                  style={{ fontSize: '14px',marginTop:'0',width: '150px' }}
                  onClick={() => {
                    window.location.href = '/dashboard';
                    closeModal();
                  }}>
                  Exit now
                </PrimaryButton>
                <StandardSecondaryButton
                  style={{ fontSize: '13px' }}
                  onClick={() => {
                    closeModal();
                  }}>
                  Back to setup
                </StandardSecondaryButton>
              </div>
            </div>);
        }}
      />

      <MainCase type="fullWidth">
        <Switch>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS,permissions.BULK_SALARY_REVISION]}
            path={[
              routePaths.bulkUploads.bulkSalaryRevision.fileUpload,
              routePaths.bulkUploads.bulkSalaryRevision.root,
            ]}
            component={DownloadAndUploadPage}
            exact
          />
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS,permissions.BULK_SALARY_REVISION]}
            path={[routePaths.bulkUploads.bulkSalaryRevision.previewScreen]}
            component={SalaryRevisionPreviewPage}
            exact
          />
          <Route>
            <Redirect to={routePaths.unknown} />
          </Route>
        </Switch>
      </MainCase>

    </div>
  );
}
