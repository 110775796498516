import React from "react";
import { Box, Skeleton } from "@razorpay/blade/components";
import { SpacingValueType } from "types/bladeTypes";

// this component is used to show skeleton loading for details view with multiple rows and columns
export const DetailsSkeleton = ({
  rowCount = 1,
  columnCount = 2,
  columnGap = 'spacing.5',
  rowGap = 'spacing.5',
}: {
  rowCount: number;
  columnCount?: number;
  rowGap?: SpacingValueType;
  columnGap?: SpacingValueType;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={rowGap}>
      {((rowCount) => {
        const skeletons = [];
        for (let i = 0; i < rowCount; i++) {
          skeletons.push(
            <Box display="flex" gap={columnGap} alignItems="baseline" key={i}>
              {Array.from({ length: columnCount }, (_,colIndex) => (
                <Skeleton width="100%" height="spacing.7" borderRadius="medium" key={`row-${i}-col-${colIndex}`}/>
              ))}
            </Box>,
          );
        }

        return skeletons;
      })(rowCount)}
    </Box>
  );
};