import {
  ActionList,
  ActionListItem,
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  BoxProps,
  Button,
  Dropdown,
  DropdownOverlay,
  IconButton,
  SelectInput,
} from '@razorpay/blade/components';
import {
  addDays,
  addMonths,
  addYears,
  format,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import React, { useState } from 'react';
import { Day, DropdownContainers, Weekday } from './styles';

export interface DatePickerProps {
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  onClose?: () => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableDates?: Date[];
  disableMonthSelector?: boolean;
  disableYearSelector?: boolean;
  monthOffset?: number; // the month list starts from 0. If you want the list to start from a certain month and loop around, please give (month - 1)
  boxProps?: BoxProps;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value = new Date(),
  onChange,
  onClose,
  disableFuture = false,
  disablePast = false,
  disableDates = [],
  disableMonthSelector = false,
  disableYearSelector = false,
  monthOffset = 0,
  boxProps = {},
}) => {
  const [currentMonth, setCurrentMonth] = useState(value ?? new Date());

  const handleMonthChange = (newMonth: Date) => {
    setCurrentMonth(newMonth);
  };

  const handlePrevMonth = () => {
    handleMonthChange(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    handleMonthChange(addMonths(currentMonth, 1));
  };

  const handleDateClick = (date: Date) => {
    if (!isSameMonth(date, currentMonth)) {
      setCurrentMonth(date); // Automatically change the month when a date from another month is selected
    }
    onChange?.(date);
    onClose?.();
  };

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const renderWeekdays = () => {
    return weekdays.map((weekday) => <Weekday key={weekday}>{weekday}</Weekday>);
  };

  const renderWeeks = () => {
    const startDate = startOfWeek(startOfMonth(currentMonth));
    const days = [];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        const currentDate = addDays(startDate, i * 7 + j);
        const isCurrentMonth = isSameMonth(currentDate, currentMonth);
        const isDisabled = isSameDay(new Date(), currentDate)
          ? false
          : (disableFuture && currentDate > new Date()) ||
          (disablePast && currentDate < new Date()) ||
          disableDates.some((date) => isSameDay(date, currentDate));

        days.push(
          <Day
            isCurrentMonth={isCurrentMonth}
            isDisabled={isDisabled}
            isSelected={isSameDay(value as Date, currentDate)}
            onClick={() =>
              isDisabled && (disableFuture || disablePast) ? null : handleDateClick(currentDate)
            }
            key={currentDate.toISOString()}>
            {format(currentDate, 'd')}
          </Day>,
        );
      }
    }

    return days;
  };

  const handleMonthSelect = (selectedMonth: number) => {
    setCurrentMonth(setMonth(currentMonth, selectedMonth));
  };

  const handleYearSelect = (selectedYear: number) => {
    setCurrentMonth(setYear(currentMonth, selectedYear));
  };

  const months = Array.from({ length: 12 }, (_, index) => ({
    value: (index + monthOffset) % 12 + '',
    label: format(setMonth(currentMonth, (index + monthOffset) % 12), 'MMMM'),
  }));

  const years = Array.from({ length: 10 }, (_, index) => ({
    value: getYear(addYears(currentMonth, index - 5)) + '',
    label: getYear(addYears(currentMonth, index - 5)).toString(),
  }));

  console.log('Current Month', currentMonth);
  const handleTodayClick = () => {
    const date = new Date();
    setCurrentMonth(date);
    handleDateClick(date);
  };
  const handleClearClick = () => {
    onChange?.(null);
    onClose?.();
  };
  const handleCloseClick = () => {
    onClose?.();
  };

  return (
    <Box minWidth={'300px'} padding={'spacing.5'} {...boxProps}>
      <Box display="flex" flexDirection={'row'} gap={'spacing.5'} paddingX={'spacing.4'}>
        <IconButton icon={ArrowLeftIcon} accessibilityLabel="Previous" onClick={handlePrevMonth} />
        <DropdownContainers>
          {
            !disableMonthSelector &&
            <Dropdown>
              <SelectInput
                labelPosition="top"
                label=""
                value={getMonth(currentMonth).toString()}
                placeholder="Month"
                name="month"
                onChange={({ values }) => handleMonthSelect(parseInt(values[0] || ''))}
              />
              <DropdownOverlay>
                <ActionList>
                  {months.map((item) => (
                    <ActionListItem key={item.label} title={item.label} value={item.value} />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          }
          {
            !disableYearSelector &&
            <Dropdown>
              <SelectInput
                labelPosition="top"
                label=""
                value={getYear(currentMonth).toString()}
                placeholder="Year"
                name="year"
                onChange={({ values }) => handleYearSelect(parseInt(values[0] || ''))}
              />
              <DropdownOverlay>
                <ActionList>
                  {years.map((item) => (
                    <ActionListItem key={item.label} title={item.label} value={item.value} />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          }
        </DropdownContainers>
        <IconButton icon={ArrowRightIcon} accessibilityLabel="Next" onClick={handleNextMonth} />
      </Box>
      <Box marginTop={'spacing.7'} display="grid" gridTemplateColumns={'repeat(7, 1fr)'}>
        {renderWeekdays()}
        {renderWeeks()}
      </Box>
      <Box display={'flex'} gap="spacing.4" marginTop={'spacing.7'}>
        <Button variant="secondary" size="small" isFullWidth onClick={handleTodayClick}>
          Today
        </Button>
        <Button variant="tertiary" size="small" isFullWidth onClick={handleClearClick}>
          Clear
        </Button>
        {onClose && (
          <Button size="small" isFullWidth onClick={handleCloseClick}>
            Close
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DatePicker;
