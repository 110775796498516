import React from "react";
import PageWrapper from "../../../../ui/PageWrapper";
import MainCase from "../../../../ui/MainCase";
import Breadcrumb from "../../../../ui/Breadcrumb";
import styles from "./index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleConnected from "assets/jibble/jibble-connected.png";
import bulletIcon from "assets/bullet--sync.png";
import settingsIcon from "assets/icon--settings.svg";
import userIcon from "assets/icon--user.svg";
import jibbleCommonStyles from "../../index.module.scss";
import {Card} from "../../../../ui/Card";
import {LinkButtonV2} from "../../../../ui/Button";
import ExternalLink from "../../../../ui/ExternalLink";
import linkArrow from "../../../../../assets/link-arrow.svg";
import {routePaths} from "../../../../Routes/data";
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "../../utils/queries";
import api from "../../../../../api";
import APIError from "../../../../../types/apiError";
import ErrorMessage from "../../../../ui/ErrorMessage";
import Spinner from "../../../../ui/Spinner";
import {useHistory} from "react-router-dom";


const ManageJibbleIntegration = () => {
    const { jibbleDashboardLink } = useSelector(loggedInUserSelectors.referenceLinks);
    const history = useHistory();

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occurred!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery.isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    if (integrationStatusQuery?.data) {
        if (!integrationStatusQuery.data.is_enabled) {
            history.push(routePaths.settings.jibble.onboarding.activation);
        }
        if (!integrationStatusQuery.data.is_onboarding_completed) {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    return (
        <div className={jibbleCommonStyles['container']}>
            <img
                className={styles['HeadlineImage']}
                src={getStaticMediaUrl(jibbleConnected)}
                alt="XPayroll-Jibble integrated"
            />
            <h1 className={styles['heading']}>Jibble integration is active</h1>
            <Card className={styles['infoCard']}>
                <p className={styles['cardHeading']}>Magic of RazorpayX Payroll and Jibble 🎉</p>
                <ul className={styles['infoList']}>
                    <li className={styles['info']}>
                        Now all your employees can mark thier attendance via {' '}
                        <ExternalLink
                            className="text-1.29xl text-primary"
                            href={jibbleDashboardLink}
                        >
                            Jibble platform
                        </ExternalLink>.
                        {' '} RazorpayX Payroll’s attendance module will be disabled.
                    </li>
                    <li className={styles['info']}>
                        Admin can fetch the loss of pay data from Jibble and add the respective
                        {' '} deduction amount to the current month’s payroll.
                    </li>
                </ul>
            </Card>
            <div className="flex">
                <div className="w-1/2 pr-4">
                    <Card className={styles['infoCard']}>
                        <div className="flex">
                            <img className="mr-3" src={getStaticMediaUrl(settingsIcon)} alt=""/>
                            <p className={styles['cardHading']}>Manage Jibble configuration</p>
                        </div>
                        <p className={styles['paragraph']}>Manage shift durations, LOP settings and more</p>
                        <a href={routePaths.settings.jibble.settings}>
                            <LinkButtonV2 className="flex text-1.5xl items-center mt-8">
                                Manage Now
                                <img src={getStaticMediaUrl(linkArrow)} alt="" className="pl-4 h-5" />
                            </LinkButtonV2>
                        </a>
                    </Card>
                </div>
                <div className="w-1/2 pl-4">
                    <Card className={styles['infoCard']}>
                        <div className="flex">
                            <img className="mr-3" src={getStaticMediaUrl(userIcon)} alt=""/>
                            <p className={styles['cardHading']}>Jibble Employee details</p>
                        </div>
                        <p className={styles['paragraph']}>Check details and sync status of employees with Jibble</p>
                        <a href={routePaths.settings.jibble.employees}>
                            <LinkButtonV2 className="flex text-1.5xl items-center mt-8">
                                View Employees
                                <img src={getStaticMediaUrl(linkArrow)} alt="" className="pl-4 h-5" />
                            </LinkButtonV2>
                        </a>
                    </Card>
                </div>
            </div>
            <div className='h-24' />
            <p className={styles['paragraph']}>
                If you want to disable Jibble integration or change the Jibble API credentials please {' '}
                <a className="text-primary text-1.29xl" href="mailto:xpayroll@razorpay.com">contact support</a>
            </p>
        </div>
    )
}

const JibbleManageIntegrationPageWrapper = () => (
    <PageWrapper>
        <Breadcrumb
            name="Jibble"
            urlMaps={{
                Settings: '/settings',
            }}
        />
        <MainCase center>
            <ManageJibbleIntegration/>
        </MainCase>
    </PageWrapper>
)

export default JibbleManageIntegrationPageWrapper;