import { ArrowLeftIcon, Box, Button, Heading, Text } from '@razorpay/blade/components';
import React from 'react';
import { SalaryStructureProvider } from './context/SalaryStructureProvider';
import { SalaryStructureTable } from './SalaryStructureTable';

const SalaryStructure: React.FC = () => {
  return (
    <SalaryStructureProvider>
      <Box display="grid" placeItems="center" padding="spacing.10">
        <Box padding="spacing.6" backgroundColor="surface.background.gray.subtle" maxWidth="768px">
          <Heading
            size="large"
            weight="semibold"
            color="surface.text.primary.normal"
            marginBottom="spacing.3">
            Default Salary Structure
          </Heading>
          <Text
            size="medium"
            weight="regular"
            color="surface.text.gray.subtle"
            marginBottom="spacing.6">
            Default salary structure is applied to all the employees of the organisation
          </Text>
          <SalaryStructureTable />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="spacing.10">
            <Button variant="secondary" icon={ArrowLeftIcon}>
              Back
            </Button>
            <Button>Save & continue</Button>
          </Box>
        </Box>
      </Box>
    </SalaryStructureProvider>
  );
};

export default SalaryStructure;
