import React from 'react';
import { useMutation, useQueryClient } from "react-query";
import pluralize from 'pluralize';

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { SkipTypes } from '../utils/types';
import { format } from 'date-fns';
import { showToastViaEvent } from 'utils/ToastEvents';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import { trackXPayrollEvent } from 'utils/analytics';

import styles from './index.module.scss';

const getSkipHeaderContent = (payrollMonth: string, skipLength: number, type: string, exceptLength?: number|null) => {
  let data = {
    header: "",
    content: <></>
  };

  const exceptCase = Number.isInteger(exceptLength);

  switch(true) {
    case type === SkipTypes.SKIPPED && !exceptCase :
      data = {
        header: "Skip Selected",
        content: <>Payroll for<strong>{` ${skipLength} ${pluralize('employee', skipLength)}`}</strong>{` will be skipped from ${payrollMonth}.`}</>,
      }
      break;
    case type === SkipTypes.SKIPPED && exceptCase :
      data = {
        header: "Skip all except selected",
        content: <>Payroll for<strong>{` ${skipLength} ${pluralize('employee', skipLength)} except selected (${exceptLength})`}</strong>{` will be skipped from ${payrollMonth}.`}</>,
      }
      break;
    case type === SkipTypes.RESUME && !exceptCase :
      data = {
        header: "Resume Selected",
        content: <>Payroll for<strong>{` ${skipLength} ${pluralize('employee', skipLength)}`}</strong>{` will be resumed from ${payrollMonth}.`}</>,
      }
      break;
    case type === SkipTypes.RESUME && exceptCase :
      data = {
        header: "Resume all except selected",
        content: <>Payroll for<strong>{` ${skipLength} ${pluralize('employee', skipLength)} except selected (${exceptLength})`}</strong>{` will be resumed from ${payrollMonth}.`}</>,
      }
      break;
  }

  return data;
}

const SkipPayroll = ({
  payrollMonth,
  skipIds,
  type,
  exceptSelected,
  onError,
  onSuccess
}: {
  payrollMonth: string;
  skipIds: number[];
  type: string;
  exceptSelected?: number|null;
  onSuccess: () => void;
} & SetErrorMessageType) => {

  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const skip = useMutation(
    (payrollMonth: string) => {
      if(type === SkipTypes.SKIPPED) {
        return api.runPayroll.bulkSkipPayroll(payrollMonth, skipIds);
      } else {
        return api.runPayroll.bulkResumePayroll(payrollMonth, skipIds);
      }
    },
    {
      onSuccess: () => {
        showToastViaEvent({
          text: `${skipIds.length} ${pluralize('employee', skipIds.length)} ${type == SkipTypes.RESUME ? "pay ":""}${pluralize('has', skipIds.length)} been ${type == SkipTypes.SKIPPED? "skipped":"resumed"} from ${format(new Date(payrollMonth+ "T00:00:00"), 'MMMM yyyy')} payroll`,
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        onSuccess();
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const onSkipPayroll = () => {
    trackXPayrollEvent(`runpayroll.${type === SkipTypes.SKIPPED ? 'skipped' : 'resumed'}`);
    skip.mutate(payrollMonth);
  };

  const displayData = getSkipHeaderContent(format(new Date(payrollMonth+ "T00:00:00"), 'MMMM yyyy'), skipIds.length, type, exceptSelected);

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          {displayData.header}
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          {displayData.content}
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']} gap-4`}>
          <StandardButton onClick={onSkipPayroll} isPending={skip.isLoading}>
            {type === SkipTypes.SKIPPED ? 'SKIP NOW' : 'RESUME PAY'}
          </StandardButton>
          <StandardSecondaryButton 
            onClick={() => closeModal()}
            disabled={skip.isLoading}>
            CANCEL
          </StandardSecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default SkipPayroll;
