import { GetWorkflowRequestsContract } from 'schemas/GetWorkflowRequests';
import { QueryClient } from 'react-query';
import { WorkflowRequestsCacheKeys, WorkflowRequestsStatusMap } from '../constants';
import { format } from 'date-fns';
import { EditPayrollListItem } from './types';
import { WorkflowRequestDetailsQueryKey } from '../components/DetailsView/constants';

export const transformEditPayrollRequestData = (
  data: GetWorkflowRequestsContract['data'][0],
  peopleMetadata: GetWorkflowRequestsContract['people_metadata'],
): EditPayrollListItem => {
  const forPeopleId = data['for_people_id'] as number;
  const oldData = data.old_data as any;
  const newData = data.new_data as any;
  const payload = data.payload as any;

  const forPersonData = peopleMetadata[forPeopleId];
  return {
    id: data.id,
    employeeDetails: {
      name: forPersonData.name,
      employeeId: `${forPersonData.id_prefix ?? ''}${forPersonData.employee_id}`,
    },
    payrollMonth: format(new Date(payload.payroll_month as string), 'MMMM'),
    approvalLevel: data.pending_on_level as number,
    status: data.status,
    deductions: {
      new: newData.deductions,
      oldSum: oldData.sum_of_deductions,
      newSum: newData.sum_of_deductions,
    },
    arrears: {
      old: oldData.sum_of_arrears,
      new: newData.sum_of_arrears,
    },
    additions: {
      new: newData.additions,
      oldSum: oldData.sum_of_allowances,
      newSum: newData.sum_of_allowances,
    },
    allowAction: data.status === WorkflowRequestsStatusMap.pending,
  };
};

export const invalidateEditPayrollCache = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.editPayroll.pending);
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.editPayroll.initiatedByMe);
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.editPayroll.completed);

  // invalidating workflow-summary cache
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.summaryForUser);

  // invalidating workflow-request-details cache
  queryClient.invalidateQueries(WorkflowRequestDetailsQueryKey);
};
