import { Box, Button, CheckIcon, CloseIcon } from '@razorpay/blade/components';
import React from 'react';
import { MyRequestsContextType, useMyRequestsContext } from '../MyRequestsContext';

interface DetailsViewApproveRejectProps {
  requestId: number;
  approveCallback?: () => void;
  rejectCallback?: () => void;
}

const DetailsViewApproveReject = ({
  requestId,
  approveCallback,
  rejectCallback,
}: DetailsViewApproveRejectProps) => {
  const { openSingleApprovalModal, openSingleRejectionModal } =
    useMyRequestsContext() as MyRequestsContextType;

  const handleApproveClick = () => {
    openSingleApprovalModal(requestId, approveCallback);
  };

  const handleRejectClick = () => {
    openSingleRejectionModal(requestId, rejectCallback);
  };

  return (
    <Box display="flex" flexDirection="column" gap="spacing.7">
      <Box display="flex" gap="spacing.7">
        <Button
          variant="tertiary"
          icon={CloseIcon}
          iconPosition="left"
          onClick={handleRejectClick}
          isFullWidth>
          Reject
        </Button>
        <Button icon={CheckIcon} iconPosition="left" onClick={handleApproveClick} isFullWidth>
          Approve
        </Button>
      </Box>
    </Box>
  );
};
export { DetailsViewApproveReject };
