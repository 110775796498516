import React, { ReactElement } from 'react';
import { SecondaryButton, StandardSecondaryButton } from 'components/ui/Button';
import { exportTableToCSV } from 'utils/downloadAsCsv';

function DownloadButton({ attributeName }: { attributeName: string }): ReactElement {
  function handleSubmit() {
    exportTableToCSV('Variance Report - ' + attributeName + '.csv');
  }

  return (
    <StandardSecondaryButton type="submit" className="mr-10" onClick={handleSubmit}>
      Download CSV
    </StandardSecondaryButton>
  );
}

export default DownloadButton;
