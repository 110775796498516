import React, { useState } from 'react';
import ZohoLogo from 'assets/zoho/zoho_text_logo.png';
import ZohoEditFields from './ZohoEditFields';
import ZohoPreviewFields from './ZohoPreviewFields';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import withActivationQueryWrapper from '../../withActivationQueryWrapper';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoEmployeeFieldsSetup({ zohoData }: Props) {
  const [isEditMode, setIsEditMode] = useState(false);

  const switchToEditMode = () => {
    trackSegment('zoho.edit_fields.click');
    setIsEditMode(true);
  };

  const switchToPreviewMode = () => {
    setIsEditMode(false);
  };

  return (
    <div className={styles['wrapper']}>
      <img src={ZohoLogo} alt="Zoho Logo" className={styles['logo']} />
      {isEditMode ? (
        <ZohoEditFields
          onPreview={switchToPreviewMode}
          availableFields={zohoData.available_fields || []}
          syncedFields={zohoData.synced_fields}
          allFields={zohoData.all_fields || []}
        />
      ) : (
        <ZohoPreviewFields
          onEdit={switchToEditMode}
          availableFields={zohoData.available_fields || []}
          allFields={zohoData.all_fields || []}
          syncedFields={zohoData.synced_fields}
        />
      )}
    </div>
  );
}

export default withActivationQueryWrapper(ZohoEmployeeFieldsSetup);
