import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { useCanViewComplianceActions } from 'components/RunPayroll/ComplianceActions/hooks/useCanViewComplianceActions';
import { useQuery } from 'react-query';
import { GET_COMPLIANCE_ACTIONS } from 'components/RunPayroll/ComplianceActions/queries';
import api from 'api';
import {
  complianceActionsMap,
  runPayrollActionCompletedStatuses,
} from 'components/RunPayroll/ComplianceActions/data';
import { AppError } from 'components/AppError';

export const useGetComplianceActionDetails = () => {
  const canViewComplianceActions = useCanViewComplianceActions();
  const runPayrollDataContext = useRunPayrollDataContext();

  const payrollMonth = runPayrollDataContext?.payrollMonth;
  const skipApiCall = !canViewComplianceActions || !payrollMonth;

  const complianceActionsQueryResponse = useQuery(
    [GET_COMPLIANCE_ACTIONS, payrollMonth],
    () => {
      return payrollMonth
        ? api.complianceActions.getComplianceActions(payrollMonth)
        : Promise.reject('Payroll Month is required');
    },
    {
      onError: (error: typeof AppError) => {
        //do nothing
      },
      enabled: !skipApiCall,
      refetchOnMount: false,
    },
  );

  const actionsApiResponse = complianceActionsQueryResponse?.data?.actions;

  const isRunPayrollActionCompleted = actionsApiResponse
    ? runPayrollActionCompletedStatuses.includes(
        actionsApiResponse[complianceActionsMap.runPayroll].status,
      )
    : false;

  return {
    complianceActionsQueryResponse,
    actionsApiResponse,
    status: complianceActionsQueryResponse.status,
    isRunPayrollActionCompleted,
  };
};
