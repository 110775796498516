import api from "api";
import { routePaths } from "components/Routes/data";
import Breadcrumb from "components/ui/Breadcrumb";
import { PrimaryButton, SecondaryButton, StandardSecondaryButton } from "components/ui/Button";
import { Card } from "components/ui/Card";
import ErrorMessage from "components/ui/ErrorMessage";
import { Input } from "components/ui/Form/Input";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AppError, getAppErrorDetails } from "utils/AppError";
import { getNumberFromIndianCurrencyString, toIndianCurrencyString } from "utils/Numbers";
import { EMP_ADVANCE_SALARY_DETAILS } from "../queries";
import { employeeAdvanceSalaryResponseType } from "./types";
import styles from './index.module.css';
import Spinner from "components/ui/Spinner";
import { showToastViaEvent } from "utils/ToastEvents";
import { errorParser } from "utils/Api";


export const InitialEmployeeOnboardingAdvanceCompensationData:employeeAdvanceSalaryResponseType = {
    name:'',
    advanceSalary:0,
    advanceSalaryEMI:0
}

const EmployeeAdvanceSalary = () => {
    const  [advanceSalaryData ,setAdvanceSalaryData] = useState(InitialEmployeeOnboardingAdvanceCompensationData);
    const urlParams = useParams<{ employeeId?: string }>();
    const employeeId = urlParams?.employeeId ? +urlParams?.employeeId : -1;
    const history = useHistory();
    const queryClient = useQueryClient();

    const employeeAdvanceSalaryQuery = useQuery(
        EMP_ADVANCE_SALARY_DETAILS,
        () => {
          if (employeeId) {
            return api.employeeOnboarding.fetchAdvanceSalary(employeeId);
          }
        },
        {
          onSuccess: (apiResponse: employeeAdvanceSalaryResponseType) => {
            setAdvanceSalaryData(apiResponse);
          },
          onError: (error: AppError) => {}
        },
      );

      // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        employeeAdvanceSalaryQuery.refetch();
    }, [employeeId]);

    const updateEmployeeAdvanceSalaryMutation = useMutation(
        () =>
        api.employeeOnboarding.updateAdvanceSalary(employeeId,{
          advanceSalaryEMI: advanceSalaryData?.advanceSalaryEMI ?? 0,
          advanceSalary: advanceSalaryData?.advanceSalary ?? 0
        }),
      {
        onSuccess: (data) => {
            showToastViaEvent({
                text: 'Advance salary updated successfuly!!',
                timeout: 5000,
                type: 'success',
                icon: 'success',
              });
          queryClient.invalidateQueries(EMP_ADVANCE_SALARY_DETAILS);
          window.location.href = '/employeeDetail?userId=' + employeeId;
        },
        onError: (error: AppError) => {  
          showToastViaEvent({
            text: errorParser(getAppErrorDetails(error)).join(' '),
            timeout: 5000,
            type: 'danger',
            icon: 'info',
          });             
        },
      },
    )
    

    const mutateUpdatAdvanceSalaryMutation = () => {
        updateEmployeeAdvanceSalaryMutation.mutate();
    };
    if (employeeAdvanceSalaryQuery.status === 'error') {
        return (
          <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
            <ErrorMessage title="Sorry! An error has occured!">
              <p className="m-4">
                {employeeAdvanceSalaryQuery.error?.message ||
                  'Something went wrong, please contact support'}
              </p>
              <StandardSecondaryButton
                children="Back to Employee Details"
                onClick={() => {
                  history.push(routePaths.emploteeDetails.root + employeeId);
                  window.location.reload(); //this change is only for revamp in phase 2 - I will redirect them to new react View page; currently chaning to route is having issue as it tries to search a react page of that route and gives error.
                }}
              />
            </ErrorMessage>
          </Card>
        );
      }
    

  const updateNumber = (event: React.ChangeEvent<HTMLInputElement>, element: string) => {
    const numberValue = getNumberFromIndianCurrencyString(event.target.value);
    if (!isNaN(numberValue)) {
        setAdvanceSalaryData({ ...advanceSalaryData, [element]: numberValue });
    }
  };
    return (<>
      <Breadcrumb
          name="edit"
          urlMaps={{
            People: `/people`,
            [advanceSalaryData.name]: `/employeeDetail?userId=${employeeId}`,
          }}
        />
        <div className={`${styles['employee-advance-salary-container']}`}>
        <h1 className="font-heading font-bold text-left text-5xl mb-12">Advance salary</h1>
        
        <div className="prompt--text">
          <p className="text-2xl mb-3">Current Advance Salary</p>
          <Input
            type="text"
            placeholder=" Enter Current Advance Salary"
            className="pl-5 pt-4 pb-4"
            onChange={(event) => {
              updateNumber(event, 'advanceSalary');
            }}
            value={toIndianCurrencyString(advanceSalaryData?.advanceSalary ?? 0, true)}
          />
          <small className="omnipresent">
            Any advance will get settled against the employee's dues. Change this only if the
            employee has been given an advance salary from outside of XPayroll. This value will
            automatically update if an advance is processed from within XPayroll.
          </small>
        </div>
        <div className="prompt--text">
          <p className="text-2xl mb-3">Advance Salary EMI</p>
          <Input
            type="text"
            placeholder=" Enter Advance Salary EMI"
            className="pl-5 pt-4 pb-4"
            onChange={(event) => {
              updateNumber(event, 'advanceSalaryEMI');
            }}
            value={toIndianCurrencyString(advanceSalaryData?.advanceSalaryEMI ?? 0, true)}
          />
          <small className="omnipresent">
            If there is an outstanding advance salary amount, you can enter how much of it should be
            recovered each month. To recover the entire amount as quickly as possible, enter 0.
          </small>
        </div>
        <div className="flex flex-row justify-between mt-20 mb-20">
        <SecondaryButton
          onClick={() => {
            history.goBack();
          }}>
          Back
        </SecondaryButton>
        <PrimaryButton
            style={{marginTop:'0',paddingLeft:'20px',paddingRight:'20px',fontSize:'0.9375rem'}}
          onClick={() => {
            mutateUpdatAdvanceSalaryMutation();
          }}>
          {' '}
          Confirm
          {updateEmployeeAdvanceSalaryMutation.status === 'loading' ? (
            <span style={{ padding: '0.7em 1em' }}>
              <Spinner />
            </span>
          ) : (
            ''
          )}
        </PrimaryButton>
      </div>
    </div>
    </>)
}

export default EmployeeAdvanceSalary;