import { Box, Text } from '@razorpay/blade/components';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Message = styled.div<{ isVisible: boolean }>(
  ({ isVisible }) => `
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.5s ease;
    margin: 0 auto;
    animation: ${isVisible ? 'typing 2s steps(40, end)' : 'none'};
    animation-delay: 0.5s;
    animation-fill-mode: both;

    @keyframes typing {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  `,
);

interface MessageFlashesProps {
  messages: string[];
}

const MessageFlashes = ({ messages }: MessageFlashesProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => {
        if (prevIndex === messages.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [messages]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap="spacing.3"
      position="absolute"
      bottom="50%"
      transform="translateY(-50%)"
      width="100%">
      {messages.map((message, index) => (
        <Message key={index} isVisible={index === currentMessageIndex}>
          <Text size="small" textAlign="center">
            {message}
          </Text>
        </Message>
      ))}
    </Box>
  );
};

export { MessageFlashes };
