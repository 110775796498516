import { WarningAlert } from "components/ui/Alert";
import { PrimaryButton } from "components/ui/Button";
import { TableLayout } from "components/ui/Table";
import React from "react";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";
import { toIndianCurrencyString } from "utils/Numbers";
import { renewalPageStateActionType } from ".";


interface Props {
    organizationAndEmployeeRenewalData:GetInsuranceRenewalOrganizationAndEmployeeContract,
    pageStateDispatch:React.Dispatch<renewalPageStateActionType>
  }
const RenewalEstimationData:React.FC<Props> =({organizationAndEmployeeRenewalData,pageStateDispatch})=> {
    let isPurchaseRestricted = (organizationAndEmployeeRenewalData?.currentBalance !== undefined  && organizationAndEmployeeRenewalData?.localEstimate?.total) ?
                                    organizationAndEmployeeRenewalData?.currentBalance === 0 
                                        ? true 
                                        : (organizationAndEmployeeRenewalData?.currentBalance < organizationAndEmployeeRenewalData?.localEstimate?.total)
                                            ? true 
                                            : false
                                    : false;
    let diffInBalance = (organizationAndEmployeeRenewalData?.localEstimate?.total && organizationAndEmployeeRenewalData?.currentBalance !== undefined ) 
                            ? organizationAndEmployeeRenewalData?.currentBalance === 0 
                                ? organizationAndEmployeeRenewalData?.localEstimate?.total 
                                : (organizationAndEmployeeRenewalData?.localEstimate?.total - organizationAndEmployeeRenewalData?.currentBalance)
                            : 0;
    return (<div className="flex flex-col">
        <h2 className="m-3 text-3xl font-semibold"> Final amount</h2>
        <TableLayout className=" xl:w-1/2 min-w-full">

            <tr>
                <td>Premium</td>
                <td className="text-right ">{toIndianCurrencyString(organizationAndEmployeeRenewalData?.localEstimate?.amount ?? 0 , false, 2) ?? 'NA'}</td>
            </tr>
            <tr>
                <td>GST(18%)</td>
                <td className="text-right ">{toIndianCurrencyString(organizationAndEmployeeRenewalData?.localEstimate?.gst ?? 0, false, 2) ?? 'NA'}</td>

            </tr>
            <br />
            <tr style={{ borderTop: '1px solid' }}>
                <td className="text-3xl font-semibold">Total</td>
                <td className="text-3xl font-semibold text-right"> {toIndianCurrencyString(organizationAndEmployeeRenewalData?.localEstimate?.total ?? 0, false, 2) ?? 'NA'} </td>
            </tr>

        </TableLayout>
        {isPurchaseRestricted &&
                    <WarningAlert className='mt-10' children={`⚠️ Low balance. Add ${toIndianCurrencyString(diffInBalance)} more 
                    to proceed with the purchase.`}/>
        }
        <PrimaryButton 
            className="mb-10 btn" 
            style={{ margin: '20px auto 10px',padding: '0 20px',fontSize:'0.9em', width:'100%',  maxWidth: '17.5rem', opacity:(isPurchaseRestricted ? '0.5' : '1') }}
            disabled={isPurchaseRestricted}
            onClick={() => pageStateDispatch({ type: 'showRenewalPaymentConfirmationModel', data: true })}
            >
               PROCEED TO PURCHASE
        </PrimaryButton>
    </div>);
}

export default RenewalEstimationData;