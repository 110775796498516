import api from 'api';
import { FETCH_BALANCE, FETCH_RX_BALANCE } from 'components/RunPayroll/utils/queries';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { showErrorToastNotification } from 'utils/ToastEvents';

const useOrgBalance = () => {
  const orgId = useSelector(loggedInUserSelectors.organizationID);
  const isRXIntegrated = useSelector(loggedInUserSelectors.isOrgRXIntegrated);

  const rxBalanceQuery = useQuery({
    queryKey: [FETCH_RX_BALANCE],
    queryFn: () => api.runPayroll.getRXBalance(orgId as number),
    enabled: !!isRXIntegrated && !!orgId,
    onError: () => {
      showErrorToastNotification({
        text: 'There was an error in fetching your account balance.',
        timeout: 3000,
      });
    },
  });

  const balanceQuery = useQuery({
    queryKey: [FETCH_BALANCE],
    queryFn: () => api.runPayroll.getBalance(orgId as number),
    enabled: !isRXIntegrated && !!orgId,
    onError: () => {
      showErrorToastNotification({
        text: 'There was an error in fetching your account balance.',
        timeout: 3000,
      });
    },
  });

  const isLoading = rxBalanceQuery.isFetching || balanceQuery.isFetching;
  const isError = isRXIntegrated ? rxBalanceQuery.isError : balanceQuery.isError;
  const error = isRXIntegrated ? rxBalanceQuery.error : balanceQuery.error;

  const refetch = isRXIntegrated ? rxBalanceQuery.refetch : balanceQuery.refetch;

  const balance = isRXIntegrated
    ? rxBalanceQuery.data?.balance || 0
    : balanceQuery.data?.balance || 0;

  return { balance, isLoading, isError, refetch, error };
};

export default useOrgBalance;
