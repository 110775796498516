import React, { useEffect, useState } from 'react';
import { IInsurance } from '../../../../reducers/insurance';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import { Table } from '../../../ui/Table';
import { planBenefits, planNames, standardBenefits } from '../../insuranceData';
import processStepDone from '../../../../assets/processStepDone.svg';
import processStepInProgress from '../../../../assets/processStepInProgress.svg';
import processStepPending from '../../../../assets/processStepPending.svg';
import line from '../../../../assets/line.svg';
import dottedLine from '../../../../assets/dottedLine.svg';
import { FAQ } from '../../FAQ';
import { PrimaryButton, SecondaryButton } from '../../../ui/Button';
import { useDispatch } from 'react-redux';
import {
  fetchInsuranceEndorsements,
  insuranceEndorsements,
  IInsuranceEndorsements,
} from '../../../../reducers/insuranceEndorsements';
import useReduxSelector from '../../../../utils/useReduxSelector';
import { Route } from 'react-router-dom';
import { ILoggedInUser, loggedInUser } from '../../../../reducers/loggedInUser';
import pluralize from 'pluralize';
import { ContentContainer } from '../../../ui/ContentContainer';
import { AnchorCard } from '../../../ui/SidebarCard';
import { routePaths } from 'components/Routes/data';
import { ETransactionStatus } from '../../../../enums';
import insurancePlanBlocked from 'constants/insurancePlanBlocked';
import RenewalBanner from '../InsuranceOrganizationAndEmployeeRenewal/RenewalBanner';
import api from 'api';
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from 'schemas/GetInsuranceRenewalOrganizationAndEmployee';
import { initialRenewalDataState } from '../InsuranceOrganizationAndEmployeeRenewal';

function isValidPlanId(planId: number): planId is keyof typeof planBenefits {
  return !!(planBenefits as Record<number, any>)[planId];
}

function getAddOns(insurance: IInsurance): string {
  if (insurance.data?.withParentsOrInLaws && insurance.data?.withChildrenAndSpouse) {
    return 'Spouse & children, Parents';
  }
  if (insurance.data?.withParentsOrInLaws) {
    return 'Parents';
  }
  if (insurance.data?.withChildrenAndSpouse) {
    return 'Spouse & children';
  }
  return 'No addons';
}

function Status({ insurance }: { insurance: IInsurance }) {
  let transactionStatus = insurance.data?.transactionStatus;

  return (
    <>
      <div className="font-heading text-5xl">
        Status<span className="xl:hidden font-semibold font-heading">: In Progress</span>
      </div>
      <div className="hidden xl:flex">
        <div className="flex justify-evenly bg-rhino p-16 h-64">
          <div className="-mr-5 ">
            <img className="mx-auto" src={getStaticMediaUrl(processStepDone)} />
            <div className="w-full font-semibold text-center">Payment done</div>
          </div>
          <img className="w-4/12 pb-6" src={getStaticMediaUrl(line)} />
          <div>
            {transactionStatus === ETransactionStatus.EXECUTED_SUCCESSFULLY ? (
              <img className="m-auto" src={getStaticMediaUrl(processStepDone)} />
            ) : (
              <img className="m-auto" src={getStaticMediaUrl(processStepPending)} />
            )}
            <div className="w-full font-semibold text-center">Processing</div>
          </div>
          <img
            className="w-4/12 pb-6"
            src={getStaticMediaUrl(
              insurance.data?.areHealthCardsAvailable === true ? line : dottedLine,
            )}
          />
          <div className=" -ml-5">
            {insurance.data?.areHealthCardsAvailable === true ? (
              <img className="mx-auto" src={getStaticMediaUrl(processStepDone)} />
            ) : (
              <img className="mx-auto" src={getStaticMediaUrl(processStepPending)} />
            )}
            <div className="w-full font-semibold text-center">Cards disbursal</div>
          </div>
        </div>
      </div>
    </>
  );
}

function PlanBenefits({ insurance }: { insurance: IInsurance }) {
  if (!insurance.data) {
    return <></>;
  }
  if (!insurance.data.planId || !isValidPlanId(insurance.data.planId)) {
    return <></>;
  }

  const benefits = planBenefits[insurance.data.planId];

  return (
    <>
      <div className="font-heading text-5xl">{planNames[insurance.data.planId]} plan benefits</div>
      <div className="flex flex-col xl:flex-row xl:space-x-5">
        <Table className="w-full xl:w-1/2">
          <tr>
            <td className="">Sum insured</td>
            <td className="font-bold">₹{insurance.data.sumInsured?.toLocaleString()}</td>
          </tr>
          {Object.entries(benefits).map(([benefit, benefitValue]) => (
            <tr>
              <td className="">{benefit}</td>
              <td className="font-bold">
                {typeof benefitValue === 'string' ? benefitValue : benefitValue['description']}
              </td>
            </tr>
          ))}
          {Object.entries(standardBenefits(insurance.data.sumInsured)).map(
            ([benefit, benefitValue]) => (
              <tr className="xl:hidden">
                <td className="">{benefit}</td>
                <td className="font-bold">{benefitValue}</td>
              </tr>
            ),
          )}
        </Table>
        <Table className="w-full xl:w-1/2">
          {Object.entries(standardBenefits(insurance.data.sumInsured)).map(
            ([benefit, benefitValue]) => (
              <tr className="hidden xl:table-row">
                <td className="">{benefit}</td>
                <td className="font-bold">
                  {benefit === 'Optional add-ons' ? getAddOns(insurance) : benefitValue}
                </td>
              </tr>
            ),
          )}
        </Table>
      </div>
    </>
  );
}

function NewEndorsementsBanner({
  insurance,
  insuranceEndorsements,
  loggedInUser,
}: {
  insurance: IInsurance;
  insuranceEndorsements: IInsuranceEndorsements;
  loggedInUser: ILoggedInUser;
}) {
  const totalEndorsements =
    insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length +
    insuranceEndorsements.data.summary.totalMissingEmployeeDetails;
  if (totalEndorsements === 0) {
    return <></>;
  }
  return (
    <div className="bg-rhino flex p-10">
      <div className="text-9xl font-heading text-secondary my-auto">{totalEndorsements}</div>
      <div className="flex-1 px-5">
        <div className="p-2 font-bold text-white">
          New {pluralize('member', totalEndorsements)} in{' '}
          {loggedInUser.data?.currentOrganization?.name
            ? loggedInUser.data?.currentOrganization?.name
            : 'organization'}
        </div>
        <div className="p-2 text-secondary">
          Secure the future of your new {pluralize('employee', totalEndorsements)} now. Cover them
          on your insurance plan
        </div>
        {insurance.data?.planId !== undefined &&
          insurance.data?.planId !== null &&
          insurancePlanBlocked[insurance.data?.planId] && (
            <div className="p-2 text-secondary">
              We are currently under maintenance for Insurance. Please contact support at
              xpayroll@razorpay.com for any queries.
            </div>
          )}
      </div>
      <div className="text-5xl my-auto">
        <Route
          render={({ history }) => (
            <SecondaryButton
              onClick={() => {
                history.push(routePaths.insurance.admin.newEndorsements);
              }}
              disabled={
                insurance.data?.planId !== undefined &&
                insurance.data?.planId !== null &&
                insurancePlanBlocked[insurance.data?.planId]
              }
            >
              LET’S GET STARTED
            </SecondaryButton>
          )}
        />
      </div>
    </div>
  );
}



export default function ({
  insurance,
  loggedInUser,
}: {
  insurance: IInsurance;
  loggedInUser: ILoggedInUser;
}) {
  const [renewalDetails,setRenewalData] = useState<GetInsuranceRenewalOrganizationAndEmployeeContract>(initialRenewalDataState);
  if (!insurance.data) {
    return <div> Looks like there is no insurance. Please contact administrator. </div>;
  }
  if (!insurance.data.planId || !isValidPlanId(insurance.data.planId)) {
    return (
      <div>
        {' '}
        Looks like there is no plan selected for your insurance. Please contact administrator.{' '}
      </div>
    );
  }
  const dispatch = useDispatch();

  useEffect(() => {
    try{
      if (insurance.data?.id) {
        dispatch(fetchInsuranceEndorsements({ insuranceId: insurance.data.id, fetchFresh: true }));
        (async()=>{
          if(insurance?.data?.organizationId){
            const apiResponse = await api.insurance.fetchInsuranceRenewalData(insurance?.data?.organizationId);
            setRenewalData(apiResponse.data);
          }
        })();
      
      }
    }catch(error){
      console.log('fetching banner Failer:',error); // do we have a better way to display error of banners
    }
  }, [insurance.data?.id]);

  const insuranceEndorsements = useReduxSelector((state) => state.insuranceEndorsements);

  return (
    <ContentContainer
      showSidebar={true}
      rightSidebar={
        <>
          <AnchorCard
            imageSymbolType="help"
            newTab={false}
            href="mailto:xpayroll@razorpay.com"
            heading="Got any Question?"
          >
            Email us at xpayroll@razorpay.com.
          </AnchorCard>
        </>
      }
    >
      <div className="w-full space-y-20 mt-10">
        <div className="text-7xl font-heading">Insurance</div>
        <NewEndorsementsBanner
          insurance={insurance}
          insuranceEndorsements={insuranceEndorsements}
          loggedInUser={loggedInUser}
        />
        { 
          insurance?.data?.isInsuranceRenewalEndorsementAllowed && <RenewalBanner RenewalDetails={renewalDetails} />
        }
        <div className="space-y-6">
          <Status insurance={insurance} />
        </div>
        <div className="space-y-6">
          <PlanBenefits insurance={insurance} />
        </div>
        <div className="space-y-6">
          <div className="font-heading text-5xl">FAQs</div>
          <div className="-mx-5">
            <FAQ />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}

export const InsurancePostPurchase = {
  PlanBenefits,
  isValidPlanId,
  getAddOns
}
