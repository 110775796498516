import React from 'react';
import InputBoxWrapper from './FnfInputBoxWrapper';
import { Box, TextInput } from '@razorpay/blade/components';
import { isNumeric } from 'utils/Numbers';

type FnfGratuityProps = {
  gratuityAmount: number | null;
  onChangeGratuity: (gratuity: number) => void;
  isFnfProcessed: boolean;
  isHoldSalaryPayComplianceEnabled: boolean;
};

const FnfGratuity = ({
  gratuityAmount,
  onChangeGratuity,
  isFnfProcessed,
  isHoldSalaryPayComplianceEnabled,
}: FnfGratuityProps) => {
  return (
    <InputBoxWrapper heading="Gratuity">
      <Box>
        <TextInput
          label="Gratuity"
          placeholder="0"
          type="number"
          isDisabled={isHoldSalaryPayComplianceEnabled && isFnfProcessed}
          helpText='Leave this field blank if you would like XPayroll to auto-calculate the applicable gratuity.
          Enter "0" to remove gratuity completely.'
          value={gratuityAmount?.toString() ?? ''}
          onChange={(e) => {
            if (isNumeric(e.value)) onChangeGratuity(Number(e.value));
          }}
        />
      </Box>
    </InputBoxWrapper>
  );
};

export default FnfGratuity;
