import React from 'react';
import { ZaggleAppSvg } from './ZaggleAppSvg';
import { ZaggleHeading } from './ZaggleHeading';
import { ZaggleHowDoesItWorkSection } from './ZaggleHowDoesItWorkSection';
import { ZaggleBenefitsSection } from './ZaggleBenefitsSection';
import { ZagglePleaseNoteSection } from './ZagglePleaseNoteSection';
import { ZaggleStartDeclaring } from './ZaggleStartDeclaring';

export function ZaggleIntro({ onStart }: { onStart: () => void }) {
  return <div className='space-y-16 w-full md:px-0 px-10 md:w-260 mx-auto'>
    <ZaggleAppSvg />
    <ZaggleHeading />
    <ZaggleHowDoesItWorkSection />
    <ZaggleBenefitsSection />
    <ZagglePleaseNoteSection />
    <ZaggleStartDeclaring onStart={onStart} />
  </div>;
}
