import { GetDeclarationsResponse, GetFlexiBenefitsSettingsResponse } from 'api/flexibleBenefits';
import { uniqueId } from 'utils/Numbers';
import { Declaration } from './types';

export const parseDeclarations = (
  declarations: Array<Omit<Declaration, 'id'> & { declaredAmount: string }> = [],
): Record<string, Declaration> => {
  return declarations.reduce(
    (a, c) => ({
      ...a,
      [uniqueId()]: c,
    }),
    {},
  );
};

export const calculateFbpAllocation = (data: Record<string, Declaration> = {}) => {
  const calculatedValues = Object.values(data).reduce(
    (a, c) => ({
      ...a,
      allocated: a.allocated + (c.declaredAmount ? parseFloat(c.declaredAmount) : 0),
      totalAllocation: a.totalAllocation + c.maxLimit,
    }),
    { allocated: 0, totalAllocation: 0 },
  );
  return {
    ...calculatedValues,
    unAllocated: calculatedValues.totalAllocation - calculatedValues.allocated,
  };
};

export const normalizeResponse = (response: GetDeclarationsResponse) => {
  const settings = response.settings;
  const isDeclarationAmountUpdateAllowed =
    settings.employeeCanDeclareFlexiBenefits === true &&
    settings.employeeCanUpdateAmountInDeclaredFlexiBenefits === true;
  return {
    ...response,
    allowances: response.allowances.map((e) => ({
      ...e,
      declaredAmount: String(e.declaredAmount || '0'),
      isSelected: isDeclarationAmountUpdateAllowed
        ? e.isSelected === true || e.declaredAmount > 0
        : e.isSelected === true,
    })),
  };
};

export const createPayload = (
  data: Record<string, Declaration> = {},
  settings: GetFlexiBenefitsSettingsResponse | undefined,
) => {
  const isDeclarationAmountUpdateAllowed =
    settings?.employeeCanDeclareFlexiBenefits === true &&
    settings?.employeeCanUpdateAmountInDeclaredFlexiBenefits === true;
  return Object.values(data).map((e) => ({
    name: e.name,
    declaredAmount: isDeclarationAmountUpdateAllowed
      ? parseFloat(e.declaredAmount || '0')
      : e.isSelected
      ? e.maxLimit
      : 0,
    isSelected: isDeclarationAmountUpdateAllowed
      ? e.isSelected || parseFloat(e.declaredAmount || '0') > 0
      : e.isSelected,
  }));
};
