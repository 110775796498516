import {
  Box,
  Spinner,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from '@razorpay/blade/components';
import api from 'api';
import { SalaryComponent } from 'components/SalaryComponents/types';
import React from 'react';
import { useQuery } from 'react-query';
import SalaryRow from './SalaryRow';
import { useSalaryStructure } from './context/SalaryStructureProvider';

export const SalaryStructureTable: React.FC = () => {
  const { tableData, updateComponent, updateAmount, updateType, updateTaxable } =
    useSalaryStructure();

  const { isFetching, data } = useQuery({
    queryFn: () =>
      api.salaryComponents.queries.listSalaryComponents<SalaryComponent>({
        category: 'EARNINGS_CATEGORY',
        status: 'COMPONENT_STATUS_ACTIVE',
      }),
  });

  const options = data?.payrollListSalaryComponents.data.salaryComponents || [];

  if (isFetching) {
    return (
      <Box display="grid" placeItems="center">
        <Spinner accessibilityLabel="Loading" size="large" />
      </Box>
    );
  }

  if (!tableData.length) {
    return null;
  }

  return (
    <Table data={{ nodes: tableData }} showBorderedCells>
      {() => (
        <>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Salary Component</TableHeaderCell>
              <TableHeaderCell>%age of CTC or Amount</TableHeaderCell>
              <TableHeaderCell>Percentage or Fixed?</TableHeaderCell>
              <TableHeaderCell>Taxable?</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {tableData.map((item) => (
              <SalaryRow
                key={item.id}
                item={item}
                options={options}
                onComponentChange={updateComponent}
                onAmountChange={updateAmount}
                onTypeChange={updateType}
                onTaxableChange={updateTaxable}
              />
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};
