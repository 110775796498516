import React from 'react';
import {
  MainContentContainer,
  SideNavContainer,
  WorkflowLayout,
} from '../components/WorkflowLayout';
import { NavItemProps, SideNav } from '../MyRequests/components/SideNav';
import { routePaths } from 'components/Routes/data';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { WorkflowBuilder } from './WorkflowBuilder';
import { useQuery } from 'react-query';
import api from 'api';
import { WorkflowPathnamesMetadata, WorkflowQueryKeys } from './constants';
import { parseWorkflowDataFromBE } from './helpers';
import { WorkflowTypesMap } from '../constants';
import { Box, Spinner } from '@razorpay/blade/components';
import { ErrorResultsTableView } from '../MyRequests/components/ErrorResultsTableView';
import { WorkflowIntroModal } from './components/WorkflowIntroModal';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const Container = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%3E%3Ccircle%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20fill%3D%22%231D2345%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
  background-color: ${theme.colors.surface.background.gray.intense};
`,
);

const CreateWorkflow = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { isApprovalWorkflowsDisabled } = useIsFeatureDisabledForRearch();
 
  const {
    data: allWorkflowsData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryFn: api.workflowApis.getAllWorkflows,
    queryKey: [WorkflowQueryKeys.getAllWorkflows],
    enabled: [
      routePaths.approvalWorkflows.workflow.editPayroll,
      routePaths.approvalWorkflows.workflow.finalizePayroll,
      routePaths.approvalWorkflows.workflow.salaryRevision,
    ].includes(pathname),
  });

  const isFetchingAllWorkflows = isLoading || isFetching;
  
  if(isApprovalWorkflowsDisabled) {
    return <Redirect to={routePaths.unknown} />;
  }

  if (isError && !allWorkflowsData) {
    return <ErrorResultsTableView onRetry={refetch} />;
  }

  const loaderContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%">
      <Spinner size="large" accessibilityLabel="loading" />
    </Box>
  );

  if (pathname === routePaths.approvalWorkflows.workflow.root) {
    return <Redirect to={routePaths.approvalWorkflows.workflow.editPayroll} />;
  }

  if (isFetchingAllWorkflows || !allWorkflowsData) {
    return loaderContent;
  }

  const navItems: NavItemProps[] = [
    {
      text: 'Edit Payroll',
      href: routePaths.approvalWorkflows.workflow.editPayroll,
      isActive: pathname.includes(routePaths.approvalWorkflows.workflow.editPayroll),
      isFeatureEnabled: true
    },
    {
      text: 'Finalize Payroll',
      href: routePaths.approvalWorkflows.workflow.finalizePayroll,
      isActive: pathname.includes(routePaths.approvalWorkflows.workflow.finalizePayroll),
      isFeatureEnabled: true
    },
    {
      text: 'Salary Revision',
      href: routePaths.approvalWorkflows.workflow.salaryRevision,
      isActive: pathname.includes(routePaths.approvalWorkflows.workflow.salaryRevision),
      isFeatureEnabled: true
    },
  ];

  const existingWorkflows =
    allWorkflowsData?.workflows?.map((workflow) =>
      parseWorkflowDataFromBE({
        ...workflow,
        people_metadata: allWorkflowsData.people_metadata,
      }),
    ) ?? [];

  const handleBackButtonClick = () => {
    window.location.href = '/dashboard';
  };

  const title = WorkflowPathnamesMetadata[pathname].title;

  return (
    <WorkflowLayout title={title} onBackButtonClick={handleBackButtonClick}>
      <SideNavContainer>
        <SideNav navItems={navItems} />
      </SideNavContainer>
      <MainContentContainer>
        <Container>
          <Switch>
            <Route path={routePaths.approvalWorkflows.workflow.editPayroll} exact>
              <WorkflowBuilder
                key={WorkflowTypesMap.EDIT_PAYROLL}
                existingWorkflows={existingWorkflows}
                actionType={WorkflowTypesMap.EDIT_PAYROLL}
              />
            </Route>
            <Route path={routePaths.approvalWorkflows.workflow.finalizePayroll} exact>
              <WorkflowBuilder
                key={WorkflowTypesMap.FINALIZE_PAYROLL}
                existingWorkflows={existingWorkflows}
                actionType={WorkflowTypesMap.FINALIZE_PAYROLL}
              />
            </Route>
            <Route path={routePaths.approvalWorkflows.workflow.salaryRevision} exact>
              <WorkflowBuilder
                key={WorkflowTypesMap.SALARY_REVISION}
                existingWorkflows={existingWorkflows}
                actionType={WorkflowTypesMap.SALARY_REVISION}
              />
            </Route>
          </Switch>
        </Container>
      </MainContentContainer>
      <WorkflowIntroModal />
    </WorkflowLayout>
  );
};

export { CreateWorkflow };
