import { FormContextValue } from './types';
import { useFormState } from './useFormState';
import { useRegister } from './useRegister';

export function useForm<T>(initialState?: T) {
  const localContext = useFormState<T>(initialState);

  const internalContext = localContext as FormContextValue<T>;
  const { register } = useRegister<T>(internalContext);

  return {
    ...internalContext,
    register,
  };
}
