import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import { TransformedAvailableField } from '../types';

export function updateSyncedFields(
  currentData: ZohoSettingsContract,
  checkedFields: { [k: string]: TransformedAvailableField },
): ZohoSettingsContract {
  if (currentData.available_fields) {
    return {
      ...currentData,
      synced_fields: Object.keys(checkedFields)
        .filter((field) => {
          return checkedFields[field].isSelectedForSync;
        })
        .map((field) => ({
          field: checkedFields[field].type,
          name: checkedFields[field].name,
        })),
    };
  } else {
    return currentData;
  }
}

export function getUncheckedFields(checkedFields: { [k: string]: TransformedAvailableField }) {
  return Object.keys(checkedFields)
    .filter((field) => {
      return !checkedFields[field].isSelectedForSync;
    })
    .map((field) => {
      return {
        field: checkedFields[field].type,
        name: checkedFields[field].name,
      };
    });
}
