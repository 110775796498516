import React, { useState } from 'react';
import {
  Amount,
  ArrowUpRightIcon,
  Box,
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  ExternalLinkIcon,
  Link,
  RefreshIcon,
  Text,
} from '@razorpay/blade/components';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import { PaymentAlerts } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/PaymentAlerts';
import { PaymentInformation } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/PaymentInformation';
import {
  complianceActionsMap,
  karnatakaPTPaymentDocsLink,
} from 'components/RunPayroll/ComplianceActions/data';
import {
  ResetLocalOverridingStyles,
  StyledPayrollProgressWrapper,
} from 'components/RunPayroll/ComplianceActions/styles';
import { PayrollProgress } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/PayrollProgress';
import { useGetComplianceActionDetails } from 'components/RunPayroll/ComplianceActions/hooks/useGetComplianceActionDetails';
import { useComplianceActionsContext } from 'components/RunPayroll/ComplianceActions/contexts/ComplianceActionsContext';
import { Receipts } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/Receipts';
import { TwoFaModal } from 'components/RunPayroll/ComplianceActions/components/TwoFaModal/TwoFaModal';
import { useMutation, useQueryClient } from 'react-query';
import {
  GET_COMPLIANCE_ACTIONS,
  INITIATE_COMPLIANCE_ACTION,
} from 'components/RunPayroll/ComplianceActions/queries';
import api from 'api';
import { AppError, getAppError } from 'utils/AppError';
import { MarkAsPaidModal } from 'components/RunPayroll/ComplianceActions/components/MarkAsPaidModal';
import { PayrollPartiallyProcessedModal } from 'components/RunPayroll/ComplianceActions/components/PayrollPartiallyProcessedModal';
import { paymentTypes } from 'components/RunPayroll/ComplianceActions/components/data';
import { PTPaymentErrorModal } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/PTPaymentErrorModal';
import {
  ptAmountLabelMap,
  ptModalKeys,
} from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/data';
import { getDataForPtDetailsView } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/utils';

export const ProfessionalTaxDetailsView = ({ state }: { state: string }) => {
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth;
  const [visibleModalKey, setVisibleModalKey] = useState<keyof typeof ptModalKeys>();

  const queryClient = useQueryClient();

  const complianceActionsContext = useComplianceActionsContext();
  const { actionsApiResponse } = useGetComplianceActionDetails();

  const {
    mutate: initiateCompliancePayment,
    isLoading,
    data: initiateApiResponse,
    error,
  } = useMutation({
    mutationKey: INITIATE_COMPLIANCE_ACTION,
    mutationFn: api.complianceActions.initiateComplianceAction,
    onSuccess: ({ data }) => {
      if (data.compliance_id) {
        setVisibleModalKey(ptModalKeys.twoFa);
        queryClient.invalidateQueries({
          queryKey: [GET_COMPLIANCE_ACTIONS, payrollMonth],
        });
      } else {
        setVisibleModalKey(ptModalKeys.error);
      }
    },
    onError: (e: typeof AppError) => {
      setVisibleModalKey(ptModalKeys.error);
    },
  });

  const onModalClose = () => {
    setVisibleModalKey(undefined);
  };

  const onDetailsViewClose = () => {
    complianceActionsContext.setShowComplianceActionDetails(undefined);
  };

  const isOpen =
    complianceActionsContext.showComplianceActionDetails?.actionName === complianceActionsMap.payPt;

  const action = actionsApiResponse?.[complianceActionsMap.payPt][state];
  const payrollActionDetails = actionsApiResponse?.[complianceActionsMap.runPayroll];

  if (!action) {
    return null;
  }

  const {
    isPaymentOverdue,
    payrollMonthFormatted,
    amountCurrency,
    paymentAmount,
    isFailedPayment,
    shouldShowPayNowButton,
    shouldShowMarkAsPaidButton,
    shouldShowPaymentButtons,
    shouldHidePayrollProgress,
    isPayrollProcessedForAllEmployees,
  } = getDataForPtDetailsView(action, payrollMonth);

  const initiatePayment = () => {
    if (payrollMonth) {
      initiateCompliancePayment({
        payrollMonth: payrollMonth,
        type: paymentTypes.KA_PT_PAYMENT,
        amount: paymentAmount,
      });
    }
  };

  const checkPayrollProgressAndInitiatePayment = () => {
    if (isPayrollProcessedForAllEmployees) {
      initiatePayment();
    } else {
      setVisibleModalKey(ptModalKeys.payrollPartiallyProcessed);
    }
  };

  return (
    <Box>
      <Drawer isOpen={isOpen} onDismiss={onDetailsViewClose}>
        <DrawerHeader
          title={`${state} Professional Tax`}
          titleSuffix="(PT)"
          subtitle={payrollMonthFormatted}
        />
        <DrawerBody>
          <ResetLocalOverridingStyles>
            {!shouldHidePayrollProgress ? (
              <StyledPayrollProgressWrapper>
                <PayrollProgress action={action} />
              </StyledPayrollProgressWrapper>
            ) : null}

            {/* Amount */}
            <Box display="flex" gap="spacing.2">
              <Text color="surface.text.gray.muted" weight="semibold">
                💰 Amount{' '}
                {isPaymentOverdue ? ptAmountLabelMap.overdue : ptAmountLabelMap[action.status]}
              </Text>
              <Amount
                suffix="decimals"
                weight="semibold"
                currency={amountCurrency}
                value={paymentAmount}
                size="medium"
              />
            </Box>
            <Receipts docs={action.meta.supporting_doc} />

            {/* Pay buttons */}
            {Boolean(shouldShowPaymentButtons) && (
              <Box display="flex" alignItems="center" gap="spacing.4" marginTop="spacing.3">
                {Boolean(shouldShowPayNowButton) && (
                  <Button
                    icon={isFailedPayment ? RefreshIcon : ArrowUpRightIcon}
                    onClick={checkPayrollProgressAndInitiatePayment}
                    isLoading={isLoading}>
                    {isFailedPayment ? 'Retry' : 'Pay now'}
                  </Button>
                )}

                {Boolean(shouldShowMarkAsPaidButton) && (
                  <Button
                    variant="tertiary"
                    isDisabled={isLoading}
                    onClick={() => {
                      setVisibleModalKey(ptModalKeys.markAsPaid);
                    }}>
                    Paid Externally
                  </Button>
                )}
              </Box>
            )}

            <PaymentAlerts action={action} actionName={complianceActionsMap.payPt} />

            {/* Payment Information */}
            <PaymentInformation
              action={action}
              payrollActionDetails={payrollActionDetails}
              state={state}
            />

            {/* Docs */}
            <Link
              icon={ExternalLinkIcon}
              iconPosition="right"
              marginTop="spacing.7"
              target="_blank"
              href={karnatakaPTPaymentDocsLink}>
              Make PT Payments for {state || ''} Employees
            </Link>
          </ResetLocalOverridingStyles>
        </DrawerBody>
      </Drawer>
      <TwoFaModal
        isOpen={visibleModalKey === ptModalKeys.twoFa}
        onDismiss={() => {
          onModalClose();
          queryClient.invalidateQueries(INITIATE_COMPLIANCE_ACTION);
        }}
        complianceId={initiateApiResponse?.data.compliance_id}
        paymentType={paymentTypes.KA_PT_PAYMENT}
      />
      <MarkAsPaidModal
        isOpen={visibleModalKey === ptModalKeys.markAsPaid}
        onDismiss={onModalClose}
        paymentType={paymentTypes.KA_PT_PAYMENT}
        amount={paymentAmount}
        currency={amountCurrency}
      />

      <PTPaymentErrorModal
        isOpen={visibleModalKey === ptModalKeys.error}
        onDismiss={onModalClose}
        reInitiatePayment={() => {
          onModalClose();
          initiatePayment();
        }}
        errorCode={getAppError(error).getDetails().code}
      />

      <PayrollPartiallyProcessedModal
        isOpen={visibleModalKey === ptModalKeys.payrollPartiallyProcessed}
        onDismiss={onModalClose}
        onContinue={() => {
          onModalClose();
          initiatePayment();
        }}
        action={action}
        paymentType={paymentTypes.KA_PT_PAYMENT}
      />
    </Box>
  );
};
