import { TableRow } from '@razorpay/blade/components';
import { SalaryComponent } from 'components/SalaryComponents/types';
import React from 'react';
import AmountCell from './components/AmountCell';
import ComponentCell from './components/ComponentCell';
import TaxabilityCell from './components/TaxabilityCell';
import TypeCell from './components/TypeCell';
import { SalaryStructureComponent } from './types';
import { capitalizeAllWordsInString } from 'utils/Strings';

interface SalaryRowProps {
  item: SalaryStructureComponent;
  options: SalaryComponent[];
  onComponentChange: (id: string, value?: string) => void;
  onAmountChange: (id: string, value?: string) => void;
  onTypeChange: (id: string, value: string) => void;
  onTaxableChange: (id: string, value: string) => void;
}

const areEqual = (prevProps: SalaryRowProps, nextProps: SalaryRowProps) => {
  return prevProps.item === nextProps.item;
};

const SalaryRow = ({
  item,
  options = [],
  onComponentChange,
  onAmountChange,
  onTypeChange,
  onTaxableChange,
}: SalaryRowProps) => {
  return (
    <TableRow key={item.id} item={item}>
      <ComponentCell
        name={capitalizeAllWordsInString(item.name)}
        info={item.info}
        isComponentEditDisabled={Boolean(item.isComponentEditDisabled)}
        options={options}
        onComponentChange={(value) => onComponentChange(item.id.toString(), value)}
      />
      <AmountCell
        amount={item.amount}
        isAmountEditDisabled={Boolean(item.isAmountEditDisabled)}
        onAmountChange={(value) => onAmountChange(item.id.toString(), value)}
      />
      <TypeCell
        type={item.type}
        isTypeEditDisabled={Boolean(item.isTypeEditDisabled)}
        onTypeChange={(value) => onTypeChange(item.id.toString(), value)}
      />
      <TaxabilityCell
        taxable={item.taxable}
        isTaxabilityEditDisabled={Boolean(item.isTaxabilityEditDisabled)}
        onTaxableChange={(value) => onTaxableChange(item.id.toString(), value)}
      />
    </TableRow>
  );
};

SalaryRow.displayName = 'SalaryRow';

export default React.memo(SalaryRow, areEqual);
