import React from 'react';
import { Box, Button, RefreshIcon, Text } from '@razorpay/blade/components';
import { StyledActionSummaryWrapper } from '../styles';

export const ActionsListErrorState = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <StyledActionSummaryWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" gap="spacing.2" flexDirection="column">
          <Text color="surface.text.gray.normal">Oops! Something went wrong. Please try again</Text>
          <Text size="small" color="surface.text.gray.muted">
            Failed to load your action items. Please try again by clicking the retry button.
          </Text>
        </Box>
        <Box>
          <Button icon={RefreshIcon} iconPosition="left" onClick={onRetry}>
            Retry
          </Button>
        </Box>
      </Box>
    </StyledActionSummaryWrapper>
  );
};
