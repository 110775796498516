import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

function Card({ children, className = '' }: PropsWithChildren<{ className?: string }>) {
  return <div className={cx(styles['card'], className)}>{children}</div>;
}

export default Card;
