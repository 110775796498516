import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import api from "api";
import { IInsurance } from '../../../../reducers/insurance';
import { paginationStateType, paginationStateTypeEnum, Paginator } from "components/ui/Table/pagination";
import { WarningAlert } from 'components/ui/Alert';
import RenewalInsurancePlanDetails from './RenewalInsurancePlanDetails';
import RenewalPurchaseSuccessModal from "./renewalPurchaseSuccessModal";
import { RenewalPurchaseFailureModal } from "./RenewalPurchaseFailureModal";
import RenewalMemberDetails from "./RenewalMemberDetails";
import RenewalEstimationContainer from "./RenewalEstimationContainer";
import { routePaths } from "components/Routes/data";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";

export enum RenewalPurchaseState {
    Default = 0,
    InProcess = 1,
    Success = 2,
    Failure = 3
}

type renewalPurchaseStateRangeType = number;// default 0, inprocess 1, success 2, failure 3

export type renewalPageStateType = {
    employeeRenewalLoader : boolean,
    estimateStateLoader : boolean,
    renewalDataError :boolean,
    renewalDataErrorMessage :string,
    renewalEstimateDataError :boolean,
    renewalEstimateDataErrorMessage :string,
    renewalPurchaseState : number, // default 0, inprocess 1, success 2, failure 3
    readTermsAndCondition : boolean,
    showRenewalPaymentConfirmationModel : boolean,
    searchEmployeeInputData : string
};

const initialPageState:renewalPageStateType = {
    'employeeRenewalLoader' : false,
    'estimateStateLoader' : false,
    'renewalDataError' :false,
    'renewalDataErrorMessage' :'',
    'renewalEstimateDataError' :false,
    'renewalEstimateDataErrorMessage' :'',
    'renewalPurchaseState' : 0, // default 0, inprocess 1, success 2, failure 3
    'readTermsAndCondition' : false,
    'showRenewalPaymentConfirmationModel' : false,
    'searchEmployeeInputData' : ''
}

type pageStateErrorType = {
    isError : boolean,
    errorMessage : string
}

export type renewalPageStateActionType = {
    type: 'employeeRenewalLoader',
    data: boolean
} | {
    type: 'estimateStateLoader',
    data: boolean
} | {
    type: 'renewalDataError',
    data: pageStateErrorType
} | {
    type: 'renewalEstimateDataError',
    data: pageStateErrorType
} | {
    type: 'renewalPurchaseState',
    data: number
} | {
    type: 'readTermsAndCondition',
    data: boolean
} | {
    type: 'showRenewalPaymentConfirmationModel',
    data: boolean
} | {
    type: 'searchEmployeeInputData',
    data: string
}

const errorRestTime = 10000; // 10 milli sec


function pageStateReducer(state : renewalPageStateType ,action : renewalPageStateActionType){
    switch (action.type) {
        case 'employeeRenewalLoader': {
            return { ...state, employeeRenewalLoader: action.data };
        }
        case 'estimateStateLoader': {
            return { ...state, estimateStateLoader: action.data };
        }
        case 'renewalDataError':{
            return { ...state, renewalDataError: action.data.isError ,renewalDataErrorMessage : action.data.errorMessage };
        }
        case 'renewalEstimateDataError':{
            return { ...state, renewalEstimateDataError: action.data.isError ,renewalEstimateDataErrorMessage : action.data.errorMessage };
        }
        case 'renewalPurchaseState' : {
            return { ...state, renewalPurchaseState: action.data };
        }
        case 'readTermsAndCondition' : {
            return { ...state, readTermsAndCondition: action.data };
        }
        case 'showRenewalPaymentConfirmationModel' : {
            return { ...state, showRenewalPaymentConfirmationModel: action.data };
        }
        case  'searchEmployeeInputData' : {
            return { ...state, searchEmployeeInputData: action.data };
        }
    }
    return {...state};
}


export const initialRenewalDataState = {
    'employeeDetails' : {
        'current_page':1,
        'first_page_url': "/?page=1",
        'from': 1,
        'last_page': 1,
        'last_page_url': "/?page=1",
        'next_page_url': "/?page=1",
        'path': '/',
        'per_page':10,
        'prev_page_url':"/?page=1",
        'to': 1,
        'total': 0,
        'allPageLinkCollection':[],
        'data':[]
      },
      'canShowEstimateAndProcess': false,
      'localEstimate': {
        'amount': 0,
        'gst': 0,
        'total': 0
      },
      'vendorEstimate': {
        'amount': 0,
        'gst': 0,
        'total': 0
      },
      'isVendorEstimateValid' : false,
      'allowRenewalEndorsement' : true,
      'referenceDate': '',
      'insuranceRenewalStagingDate': '',
      'currentBalance': 0,
      'isOrgLevelInsuranceRenewalPending': false
}



export interface IInsuranceOrganizationRenewal {
    success: boolean;
    data: GetInsuranceRenewalOrganizationAndEmployeeContract;
    errorMessage: string | null;
}


export function InsuranceOrganizationRenewal({insurance}: { insurance: IInsurance;})
{
    const [organizationAndEmployeeRenewalData,setOrganizationAndEmployeeRenewalData] = useState<GetInsuranceRenewalOrganizationAndEmployeeContract>(initialRenewalDataState);
    const [pageState,pageStateDispatch] = useReducer(pageStateReducer,initialPageState);
    const [paginationState, paginationDispatch] = useReducer(Paginator.paginationReducer, Paginator.initialStatePagination);
    const renewalEmpDataCallPauseRef = useRef(false);
    const history = useHistory();

   
    const fetchOrganizationAndEmployeeRenewalData = useCallback(async (id:number,search:string=pageState?.searchEmployeeInputData) =>{
        try{
            if (renewalEmpDataCallPauseRef.current) return;
            renewalEmpDataCallPauseRef.current = true;
            pageStateDispatch({type:'employeeRenewalLoader',data:true});
            pageStateDispatch({type:'searchEmployeeInputData',data:search});
            const apiResponse = await api.insurance.fetchInsuranceRenewalData(id,paginationState.currentPageUrl,paginationState.perPage,search);

            pageStateDispatch({type:'employeeRenewalLoader',data:false});
            if(apiResponse.success){
                let employeeDetails = apiResponse.data?.employeeDetails;
                let initialStatePagination:paginationStateType = {
                    allPageLinkCollection : employeeDetails?.allPageLinkCollection,
                    previousPageUrl       : employeeDetails?.prev_page_url,
                    nextPageUrl           : employeeDetails?.next_page_url,
                    totalPages            : employeeDetails?.last_page,
                    rowsPerPage           : paginationState.rowsPerPage,
                    currentPage           : paginationState.currentPage,
                    currentPageUrl        : paginationState.currentPageUrl,
                    perPage               : paginationState.perPage
                }
                paginationDispatch({ type: paginationStateTypeEnum.InitialStateUpdate, data: {...paginationState, ...initialStatePagination} });
                setOrganizationAndEmployeeRenewalData(apiResponse?.data);
                if(!apiResponse?.data?.allowRenewalEndorsement){
                    history.push(routePaths.insurance.admin.details);
                }

            }else{
                pageStateDispatch({type:'renewalDataError',data:{isError: true,errorMessage:'There seems to be an issue with the computing of data please refresh and try after some time!' }});
                setTimeout(()=>{
                    pageStateDispatch({type:'renewalDataError',data:{isError: false,errorMessage:'' }});
                },5000);
            }
            
            pageStateDispatch({type:'estimateStateLoader',data:false});
        }
        catch(error){
            pageStateDispatch({type:'renewalDataError',data:{isError: true,errorMessage:'There seems to be an issue with the computing of data please refresh and try after some time!' }});
            setTimeout(()=>{
                pageStateDispatch({type:'renewalDataError',data:{isError: false,errorMessage:'' }});
            },errorRestTime);
            pageStateDispatch({type:'employeeRenewalLoader',data:false});
        }
       
        renewalEmpDataCallPauseRef.current = false;
        
    },[pageState?.searchEmployeeInputData, paginationState.currentPageUrl, paginationState.perPage, history]);

    useEffect(()=>{
        if(insurance.data?.organizationId){
            fetchOrganizationAndEmployeeRenewalData(insurance.data?.organizationId);
        }
    },[insurance.data?.organizationId, paginationState.currentPageUrl, paginationState.perPage]);


    const generateVendorEstimate = async (id:number) =>{
        try{
            pageStateDispatch({type:'estimateStateLoader',data:true});
            const apiResponse = await api.insurance.generateInsuranceRenewalVendorEstimateData(id);

            pageStateDispatch({type:'estimateStateLoader',data:false});
            if(apiResponse.success){
                fetchOrganizationAndEmployeeRenewalData(id);
            }else{
                pageStateDispatch({type:'renewalEstimateDataError',data:{isError: true,errorMessage:'There seems to be an issue with the computing of estimate please refresh and try after some time!' }});
                setTimeout(()=>{
                    pageStateDispatch({type:'renewalEstimateDataError',data:{isError: false,errorMessage:'' }});
                },5000);
            }
        }
        catch(error){
            pageStateDispatch({type:'renewalEstimateDataError',data:{isError: true,errorMessage:'There seems to be an issue with the computing of estimate please refresh and try after some time!' }});
            setTimeout(()=>{
                pageStateDispatch({type:'renewalEstimateDataError',data:{isError: false,errorMessage:'' }});
            },errorRestTime);
            pageStateDispatch({type:'estimateStateLoader',data:false});
        }
    }

  

    return (
        <div className="mr-4">

            <h1 className="m-3 text-5xl">Renew expired insurance</h1>
            {pageState?.renewalDataError &&
                <WarningAlert className='mt-10' children={`⚠️ There seems to be issue with the processing of employees under review please refresh the page or try after sometime!`}/>
            }
            {pageState?.renewalEstimateDataError &&
                <WarningAlert className='mt-10' children={<span>⚠️ We are currently facing issue with the computing the estimates for your employees, please refresh the page or try after sometime !<br/> <span style={{padding:'1.5em'}}>If issue still persist please <a style={{color:'#5A99E8'}} href="mailto:xpayroll@razorpay.com">contact support</a></span></span>}/>
            }
            <p style={{padding:'1em',background:'rgba(47, 179, 120, 0.1)',maxWidth:'100%'}} className='mt-10' children={`⚠️ Insurance is expired for the following ${organizationAndEmployeeRenewalData?.employeeDetails?.total ? organizationAndEmployeeRenewalData?.employeeDetails?.total : ''} employees. Renew insurance asap to protect them and their family.`}/>
            <div className={`${styles['renewal-page-container']}`}>
                <div className={`${styles['renewal-page-plan-details-container']}`}>
                    <RenewalInsurancePlanDetails insurance={insurance} organizationAndEmployeeRenewalData={organizationAndEmployeeRenewalData}/>
                    <br/>
                   <RenewalEstimationContainer 
                        insurance={insurance} 
                        organizationAndEmployeeRenewalData={organizationAndEmployeeRenewalData}
                        generateVendorEstimate={generateVendorEstimate}
                        pageStateDispatch={pageStateDispatch}
                        pageState={pageState}
                    />
                    <p>If you have any queries <a style={{color:'#5A99E8'}} href="mailto:xpayroll@razorpay.com">contact support</a></p>
                </div>
                <RenewalMemberDetails
                    insurance={insurance} 
                    organizationAndEmployeeRenewalData={organizationAndEmployeeRenewalData}
                    pageStateDispatch={pageStateDispatch}
                    pageState={pageState}
                    paginationState={paginationState}
                    paginationDispatch={paginationDispatch}
                    fetchOrganizationAndEmployeeRenewalData={fetchOrganizationAndEmployeeRenewalData}
                />
            </div>

            <RenewalPurchaseSuccessModal pageState={pageState} organizationAndEmployeeRenewalData={organizationAndEmployeeRenewalData} />
            <RenewalPurchaseFailureModal pageState={pageState} />


        </div>
    )
}

