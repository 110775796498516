import {
  Badge,
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  TextArea,
  TextInput,
} from '@razorpay/blade/components';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { routePaths } from 'components/Routes/data';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import { DeductionType } from 'components/SalaryComponents/types';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
} from 'components/WizardViews/components';
import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import { useFormWithProvider } from 'forms';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  deductionType,
  deductionsCreationGeneralStepValidationSchema,
  deductionsSteps,
} from '../constants';
import { DeductionsFormData } from '../types';

const GeneralDetailsStep: React.FC = () => {
  const history = useHistory();
  const { validate, register } = useFormWithProvider<DeductionsFormData>();
  const { updateStepStatus } = useWizardInfo();
  const { searchString } = useComponentParams();
  const { isM2S2Enabled } = useIsFeatureDisabledForRearch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validate(deductionsCreationGeneralStepValidationSchema)) {
      updateStepStatus(deductionsSteps.step1, 'success');
      history.push({
        pathname: routePaths.salaryComponents.create.deductions.payAndTaxDetails,
        search: searchString,
      });
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title={'General & Type'} subtitle={'Configuration'} />
        <FieldGroup title="General Details">
          <TextInput
            label={'Component Name'}
            placeholder={'Enter component name'}
            {...register('name')}
            maxCharacters={50}
            necessityIndicator={'required'}
          />
          <TextInput
            label={'Display Name in Payslip & Salary Register'}
            placeholder={'Enter display name'}
            {...register('displayName')}
            maxCharacters={50}
            necessityIndicator={'required'}
          />
          <TextArea
            label={'Component Description'}
            placeholder={
              'e.g. Component provided to help employees cover their housing or rental expenses.'
            }
            {...register('description')}
            maxCharacters={255}
          />
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup
          title={'Deduction Type'}
          subtitle={'Choose how often employees receive this component as their pay.'}>
          <RadioGroup {...register('deductionType', 'radiogroup')}>
            <Box display="flex">
              <Radio
                value={'RECURRING' as DeductionType}
                helpText={deductionType.RECURRING.description}
                isDisabled>
                {deductionType.RECURRING.label}
              </Radio>
              {!isM2S2Enabled && <Badge color="notice">Coming soon</Badge>}
            </Box>
            <Radio value={'ADHOC' as DeductionType} helpText={deductionType.ADHOC.description}>
              {deductionType.ADHOC.label}
            </Radio>
          </RadioGroup>
        </FieldGroup>
      </StepContentWrapper>
      <StepActionsWrapper>
        <span />
        <Button type="submit">Next: Pay & Taxability</Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default GeneralDetailsStep;
