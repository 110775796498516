import {
  Alert,
  AlertProps,
  Amount,
  BottomSheet,
  BottomSheetFooter,
  BottomSheetHeader,
  Box,
  Button,
  Divider,
  EditIcon,
  ExternalLinkIcon,
  Heading,
  Link,
  Skeleton,
  Text,
} from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import Errors from 'components/ui/Form/Errors';
import PageLayout from 'components/ui/Layout/PageLayout';
import { links } from 'constants/flexibenefits';
import userRoles from 'constants/userRoles';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';
import DeclarationItem from './DeclarationItem';
import { GET_USER_DECLARATIONS, UPDATE_USER_DECLARATIONS } from './queries';
import { Table, TableHeaderText } from './styles';
import { Declaration } from './types';
import {
  calculateFbpAllocation,
  createPayload,
  normalizeResponse,
  parseDeclarations,
} from './utils';
import emptyViewImg from 'assets/empty_view_fbp.svg';

const DeclarationView = () => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const userRoleId = org?.employeeDetails?.userRoleId;
  const isAdmin = userRoleId === userRoles.ADMIN; //TODO: Add permissions
  const history = useHistory();
  const queryParam = new URLSearchParams(useLocation().search);
  const userOrgId = queryParam.get('userOrgId') || org?.employeeDetails?.id;
  const [declarations, setDeclarations] = useState<Record<string, Declaration>>({});
  const [isEditing, setIsEditing] = useState(false);
  const { totalAllocation, allocated, unAllocated } = calculateFbpAllocation(declarations);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: GET_USER_DECLARATIONS,
    queryFn: () =>
      userOrgId
        ? api.flexibleBenefits.getDeclarations(userOrgId)
        : Promise.reject('People Id is required'),
    onSuccess: (response) => {
      const data = normalizeResponse(response);
      setDeclarations(parseDeclarations(data.allowances || []));
    },
    onError: (error: typeof AppError) => {},
  });

  const updateDeclarations = useMutation({
    mutationKey: UPDATE_USER_DECLARATIONS,
    mutationFn: api.flexibleBenefits.updateDeclarations,
    onSuccess: () => {
      refetch();
      setIsConfirmationOpen(false);
      showToastViaEvent({
        text: 'Declaration saved successfuly!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
    },
    onError: (error: typeof AppError) => setIsConfirmationOpen(false),
  });

  const computeTaxSavings = useMutation({
    mutationKey: UPDATE_USER_DECLARATIONS,
    mutationFn: api.flexibleBenefits.computeTaxSavings,
    onError: (error: typeof AppError) => {},
  });

  const settings = data?.settings;

  const handleSave = () => {
    if (userOrgId)
      updateDeclarations.mutate({
        userOrg: userOrgId,
        allowances: createPayload(declarations, settings),
      });
  };

  const handleComputeTaxClick = () => {
    if (userOrgId)
      computeTaxSavings.mutate({
        userOrg: userOrgId,
        allowances: createPayload(declarations, settings),
      });
  };

  const isAlertVisible =
    data?.isDeclarationWindowOpen === false && !!data?.messageRegardingDeclarationWindow;
  const isEditDisabled = isAlertVisible || !isEditing;
  const estimatedSavings =
    computeTaxSavings.data?.estimatedTaxSavings || data?.estimatedTaxSaving || 0;
  const yearlySavings = estimatedSavings;
  const apiError = updateDeclarations.error || error || computeTaxSavings.error;

  const employeeCanDeclareFlexiBenefits = settings?.employeeCanDeclareFlexiBenefits;

  const employeeCanUpdateAmountInDeclaredFlexiBenefits =
    settings?.employeeCanDeclareFlexiBenefits &&
    settings?.employeeCanUpdateAmountInDeclaredFlexiBenefits;

  const actions: AlertProps['actions'] = isAdmin
    ? {
        primary: {
          text: 'Update Settings',
          onClick: () => history.push(routePaths.flexibleBenefits.settings),
        },
      }
    : undefined;

  const handleClick = () => window.open(links.learnMoreAboutFbp, '_blank');

  const declarationEntries = Object.entries(declarations);

  const emptyView = (
    <Box display="grid" placeItems="center" marginTop="76px">
      <img src={emptyViewImg} alt="" />
      <Heading marginTop="spacing.7" size="small">
        No flexible benefit component is found in your salary structure
      </Heading>
      <Button
        size="large"
        variant="secondary"
        marginTop="spacing.10"
        onClick={() => (window.location.href = '/dashboard')}>
        Back to dashboard
      </Button>
    </Box>
  );

  if (error) {
    return (
      <PageLayout
        isLoading={isLoading}
        title="Flexible benefit plan declaration"
        onBackClick={() => window.history.back()}
        RightSideLayout={
          <Link variant="button" icon={ExternalLinkIcon} iconPosition="right" onClick={handleClick}>
            Learn more about FBP
          </Link>
        }>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={{ l: '830px', base: 'auto' }}
          marginY={'spacing.11'}
          marginX={{ base: 'spacing.8', l: 'auto' }}>
          <Alert
            marginBottom={'spacing.9'}
            isDismissible={false}
            description={<Errors errorResponse={error} />}
            color="negative"
          />
        </Box>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      isLoading={isLoading}
      title="Flexible benefit plan declaration"
      onBackClick={() => window.history.back()}
      RightSideLayout={
        <Link variant="button" icon={ExternalLinkIcon} iconPosition="right" onClick={handleClick}>
          Learn more about FBP
        </Link>
      }
      emptyView={emptyView}
      isEmpty={declarationEntries.length === 0}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width={{ l: '830px', base: 'auto' }}
        marginY={'spacing.11'}
        marginX={{ base: 'spacing.8', l: 'auto' }}>
        {employeeCanDeclareFlexiBenefits ? (
          <>
            {apiError ? (
              <Alert
                marginBottom={'spacing.9'}
                isDismissible={false}
                description={<Errors errorResponse={apiError} />}
                color="negative"
              />
            ) : null}
            {isAlertVisible && !error ? (
              <Alert
                marginBottom={'spacing.9'}
                isDismissible={false}
                description={data?.messageRegardingDeclarationWindow || ''}
                color="notice"
              />
            ) : null}
            <Box display="flex" gap="spacing.7">
              <Box>
                <Text color="surface.text.gray.muted">Total allocated amount</Text>
                <Amount
                  suffix="none"
                  marginTop={'spacing.2'}
                  value={totalAllocation}
                  type="heading"
                  size="medium"
                  weight="semibold"
                />
              </Box>
              <Box>
                <Text color="surface.text.gray.muted">FBP allocation</Text>
                <Amount
                  suffix="none"
                  marginTop={'spacing.2'}
                  value={allocated}
                  type="heading"
                  size="medium"
                  weight="semibold"
                />
              </Box>
              <Box>
                <Text color="surface.text.gray.muted">Unallocated amount</Text>
                <Amount
                  suffix="none"
                  marginTop={'spacing.2'}
                  value={unAllocated}
                  type="heading"
                  size="medium"
                  weight="semibold"
                />
              </Box>
            </Box>

            <Box
              marginTop={'spacing.7'}
              borderColor={'surface.border.gray.subtle'}
              borderWidth="thin"
              borderRadius={'medium'}
              padding={['spacing.7', 'spacing.9']}
              zIndex="1"
              backgroundColor={'surface.background.gray.moderate'}>
              <Table>
                <tr>
                  <TableHeaderText>Flexible component</TableHeaderText>
                  <TableHeaderText>Max. limit per month</TableHeaderText>
                  {employeeCanUpdateAmountInDeclaredFlexiBenefits ? (
                    <TableHeaderText>Your Monthly declaration</TableHeaderText>
                  ) : null}
                  {/* <th>
                <TableHeaderText>Prorated yearly amount</TableHeaderText>
              </th> */}
                </tr>
                {declarationEntries.map(([id, e]) => (
                  <DeclarationItem
                    key={id}
                    id={id}
                    isSelected={e.isSelected}
                    isDisabled={isEditDisabled}
                    name={e.name}
                    maxLimit={e.maxLimit}
                    declaredAmount={e.declaredAmount}
                    proratedAmount={e.proratedAmount}
                    employeeCanUpdateAmountInDeclaredFlexiBenefits={
                      employeeCanUpdateAmountInDeclaredFlexiBenefits
                    }
                    onValueChange={(value, id) =>
                      setDeclarations((p) => ({
                        ...p,
                        [id]: { ...p[id], declaredAmount: value },
                      }))
                    }
                    onCheckedChange={(isSelected, id) =>
                      setDeclarations((p) => ({ ...p, [id]: { ...p[id], isSelected } }))
                    }
                  />
                ))}
              </Table>
              <Box paddingY="14px">
                <Divider />
              </Box>

              {computeTaxSavings.isLoading ? (
                <Box display="flex" gap="spacing.7" height="20px" alignItems="stretch">
                  <Skeleton flex="1" width="100%" />
                  <Skeleton width="80px" />
                </Box>
              ) : (
                <Box display="flex" justifyContent="space-between" gap={'spacing.11'}>
                  {computeTaxSavings.data ? (
                    <>
                      <Text weight="semibold" color={'interactive.text.information.subtle'}>
                        {`⚡️ With the above declaration you will save tax of approx ₹${yearlySavings}`}
                      </Text>

                      <Link
                        isDisabled={computeTaxSavings.isLoading}
                        variant="button"
                        onClick={handleComputeTaxClick}>
                        Recompute Tax
                      </Link>
                    </>
                  ) : (
                    <Link
                      isDisabled={computeTaxSavings.isLoading}
                      variant="button"
                      onClick={handleComputeTaxClick}>
                      ⚡️ View how much tax you’ll save
                    </Link>
                  )}
                </Box>
              )}
            </Box>
            {isAlertVisible ? null : (
              <Box display="flex" justifyContent="flex-end" marginTop="spacing.9">
                {isEditing ? (
                  <Button isDisabled={isEditDisabled} onClick={() => setIsConfirmationOpen(true)}>
                    Submit declaration
                  </Button>
                ) : (
                  <Button icon={EditIcon} onClick={() => setIsEditing(true)}>
                    Edit Declaration
                  </Button>
                )}
              </Box>
            )}
          </>
        ) : (
          <Alert
            marginBottom={'spacing.9'}
            isDismissible={false}
            title="Employee declaration is disabled"
            description={
              'Employee declarations is disabled for your org, please contact your admin to get it enabled.'
            }
            actions={actions}
            color="notice"
          />
        )}
      </Box>
      <BottomSheet isOpen={isConfirmationOpen} onDismiss={() => setIsConfirmationOpen(false)}>
        <BottomSheetHeader
          title="Save declarations"
          subtitle="Submit your FBP declaration? Please note you can edit till the declaration window is
            open."
        />
        <BottomSheetFooter>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="spacing.7"
            justifyContent="flex-end">
            <Button variant="secondary" isDisabled={updateDeclarations.isLoading}>
              Back
            </Button>
            <Button onClick={handleSave} isLoading={updateDeclarations.isLoading}>
              Continue
            </Button>
          </Box>
        </BottomSheetFooter>
      </BottomSheet>
    </PageLayout>
  );
};

export default DeclarationView;
