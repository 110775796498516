import { Box } from '@razorpay/blade/components';
import React, { PropsWithChildren, ReactNode } from 'react';

const ComponentContainer = ({ children }: PropsWithChildren<ReactNode>) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'44px'} marginBottom={'spacing.8'}>
      {children}
    </Box>
  );
};

export default ComponentContainer;
