import React, { PropsWithChildren, useEffect } from 'react';
import icicilombardlogo from '../../../../assets/lombard.svg';
import covidBenefitIcon from '../../../../assets/covidbenefit.svg';
import settlementbenefit from '../../../../assets/settlementbenefit.svg';
import reassignunusedbenefit from '../../../../assets/reassignunusedbenefit.svg';
import bestInClass from '../../../../assets/bestInClass.svg';
import hassleFreePurchase from '../../../../assets/hassleFreePurchase.svg';
import support from '../../../../assets/support.svg';
import plumLogo from '../../../../assets/plum.svg';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import { Route, useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../../ui/Button';
import { useDispatch } from 'react-redux';
import { IInsurance, startInsurance } from '../../../../reducers/insurance';
import { startRecording } from '../../../../utils/Hotjar';
import { ContentContainer } from '../../../ui/ContentContainer';
function Heading({ highlightedText, children }: PropsWithChildren<{ highlightedText: string }>) {
  return (
    <div className="font-heading font-bold text-center text-6xl pb-8">
      <span className="text-primary font-bold font-heading">{highlightedText}</span>
      {children}
    </div>
  );
}
function BenefitItem({
  icon,
  heading,
  description,
}: {
  icon?: string;
  heading: string;
  description: string;
}) {
  return (
    <li className="flex mt-2 sm:mt-0 w-128">
      {icon && <img className="p-3 h-24 my-auto" src={getStaticMediaUrl(icon)} />}
      <div className="flex-1 p-3">
        <div className="text-3xl font-bold font-heading">{heading}</div>
        <div className="secondary-text mt-2">{description}</div>
      </div>
    </li>
  );
}
function BenefitsList() {
  return (
    <ul className="flex flex-col sm:flex-row font-normal w-full justify-between flex-wrap mt-8">
      <BenefitItem
        key={1}
        icon={covidBenefitIcon}
        heading="Covers COVID 19"
        description="& other illnesses"
      />
      <BenefitItem
        key={2}
        icon={settlementbenefit}
        heading="99.8% Settlement rate"
        description="Best in industry"
      />
      <BenefitItem
        key={3}
        icon={reassignunusedbenefit}
        heading="Re-assign unused seats"
        description="across employee pool"
      />
    </ul>
  );
}

function OpfinBenefitsList() {
  return (
    <ul className="flex flex-col sm:flex-row font-normal w-full justify-between flex-wrap mt-8">
      <BenefitItem
        key={1}
        icon={bestInClass}
        heading="Best-in-class plans"
        description="starting from Rs. 1230/life"
      />
      <BenefitItem
        key={2}
        icon={hassleFreePurchase}
        heading="Group health insurance"
        description="Hassle free 3-click purchase"
      />
      <BenefitItem
        key={3}
        icon={support}
        heading="24/7 claims support"
        description="a Superior Employee Experience"
      />
    </ul>
  );
}

export function InsuranceIntro({
  insurance,
  startInsuranceAction,
}: {
  insurance: IInsurance;
  startInsuranceAction: typeof startInsurance;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isInsuranceActive = insurance?.data?.status !== 'NO_ACTIVE_INSURANCE' ? true : false;
  return (
    <ContentContainer showSidebar={false}>
      <div className="max-w-screen-lg py-10 mx-auto w-full space-y-24 sm:mt-10">
        <div className="w-full pb-8">
          <Heading highlightedText="Why"> Group Insurance?</Heading>
          <BenefitsList />
        </div>
        <div className="w-full">
          <Heading highlightedText="Why"> XPayroll?</Heading>
          <OpfinBenefitsList />
        </div>
        <div className="flex flex-col sm:flex-row space-y-12">
          <div className="flex flex-col justify-center sm:w-128 text-5xl text-center sm:text-left w-full font-bold font-heading">
            XPayroll insurance is powered by
          </div>
          <div className="flex flex-1 flex-col justify-evenly max-w-md mx-auto my-auto sm:flex-row sm:max-w-none sm:space-y-0 space-y-10">
            <div className="w-full sm:w-1/2 sm:px-4 flex flex-col justify-center">
              <img className="w-full" src={getStaticMediaUrl(icicilombardlogo)} />
            </div>
            <div className="w-full sm:w-1/2 sm:px-4 flex flex-col justify-center">
              <img className="w-full" src={getStaticMediaUrl(plumLogo)} />
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <Route
            render={() => (
              <PrimaryButton
                className="w-full sm:w-auto"
                onClick={() => {
                  insurance.data?.organizationId &&
                    dispatch(startInsuranceAction(insurance.data?.organizationId, history));
                  startRecording('payroll-insurance');
                }}
              >
                {isInsuranceActive ? 'CONTINUE' : "LET'S GET STARTED"}
              </PrimaryButton>
            )}
          />
        </div>
      </div>
    </ContentContainer>
  );
}
