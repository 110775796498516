import { Box, BoxProps, Link, PlusIcon } from '@razorpay/blade/components';
import { FileExtensions, MimeType, MimeTypeExtensions } from 'components/ui/FileUpload/constants';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import React, { useRef } from 'react';
import { DocumentStatus } from '../../constants';
import { UploadIcon } from './UploadIcon';
import { FileDropView, FileInput, FileUploadWrapper, LabelWrapper, SubText } from './styles';

export interface FileUploadProps {
  id?: string;
  status?: DocumentStatus;
  allowedMimeTypes: Array<MimeType>;
  value?: string;
  onChange?: (files: File[] | null) => void;
  label?: string;
  labelJustifyContent?: BoxProps['justifyContent'];
  subText?: string;
  onFilenameClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const FileUpload = ({
  id,
  status = DocumentStatus.NOT_INITIATED,
  allowedMimeTypes,
  onChange,
  value,
  label = 'Drop files here or',
  subText,
  labelJustifyContent,
  onFilenameClick,
}: FileUploadProps) => {
  const { isDragging, handleDragEnter, handleDragOver, handleDragLeave, handleDrop } =
    useDragAndDrop();

  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (
      ![DocumentStatus.UPLOADING, DocumentStatus.VERIFYING, DocumentStatus.VERIFIED].includes(
        status,
      )
    ) {
      inputRef.current?.click();
    }
  };

  const showReplaceButton =
    !!value &&
    ![DocumentStatus.VERIFYING, DocumentStatus.UPLOADING, DocumentStatus.VERIFIED].includes(status);

  const showDropEffect =
    isDragging &&
    ![DocumentStatus.VERIFYING, DocumentStatus.UPLOADING, DocumentStatus.VERIFIED].includes(status);

  const handleDroppedFiles = (files: File[]) => {
    const validFiles = files.filter((file) => allowedMimeTypes.includes(file.type as MimeType));
    onChange?.(validFiles);
  };

  const splittedValues = value?.split('.');
  const extension = splittedValues?.length ? splittedValues[splittedValues?.length - 1] : '';

  const isFile =
    FileExtensions.includes(`.${extension}` as MimeTypeExtensions) &&
    status !== DocumentStatus.UPLOADING;

  return (
    <FileUploadWrapper status={status} onDragEnter={(e) => handleDragEnter(e)}>
      <Box padding="spacing.5" display="flex" flex="1">
        <FileInput
          id={id}
          accept={allowedMimeTypes.join(',')}
          onChange={(e) => {
            const files = e.target.files;
            onChange?.(files ? Array.from(files) : []);
            e.target.value = '';
          }}
          ref={inputRef}
        />
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={'spacing.5'}
          alignItems={'center'}
          width={'100%'}>
          <Box width={'44px'} height={'44px'} position={'relative'}>
            <UploadIcon status={status} />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={showReplaceButton ? 'space-between' : labelJustifyContent}
            alignItems={'center'}
            gap={'spacing.2'}
            flex={1}>
            <Box display={'flex'} flexDirection={'column'} gap={'spacing.1'}>
              <LabelWrapper isLink={isFile} onClick={onFilenameClick}>
                {value ? value : label}
              </LabelWrapper>
              {subText ? <SubText status={status}>{subText}</SubText> : null}
            </Box>
            {value ? null : (
              <Link variant="button" onClick={onClick}>
                Browse
              </Link>
            )}
            {showReplaceButton ? (
              <Link variant="button" onClick={onClick}>
                Replace File
              </Link>
            ) : null}
          </Box>
        </Box>
      </Box>
      {showDropEffect ? (
        <FileDropView
          onDragOver={handleDragOver}
          onDragLeave={(e) => handleDragLeave(e)}
          onDrop={(e) => handleDrop(e, handleDroppedFiles)}>
          <PlusIcon color="interactive.icon.primary.normal" size="2xlarge" />
        </FileDropView>
      ) : null}
    </FileUploadWrapper>
  );
};

export default FileUpload;
