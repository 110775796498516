import React from 'react';
import { Box } from '@razorpay/blade/components';

interface props {
    descriptionComponent: React.ReactNode;
    rightComponent: React.ReactNode;
}
const DescriptionAndComponentCard = ({ descriptionComponent, rightComponent }: props) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            gap={'80px'}
        >
            <Box width="416px"
            >
                { descriptionComponent }
            </Box>
            <Box width="466px"
            >
                { rightComponent }
            </Box>
        </Box>
    );
}

export default DescriptionAndComponentCard;