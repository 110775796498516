import React from "react";
import { 
    ActionList, 
    ActionListItem, 
    ActionListItemBadge, 
    Box, 
    Dropdown, 
    DropdownOverlay, 
    SelectInput, 
    Switch, 
    Text, 
    Tooltip 
} from "@razorpay/blade/components";
import { GetPayrollSetupSchemaContract } from "schemas/GetPayrollSetupSchema";
import { parseDropdownDate } from "../utils";
import { Reveal } from "components/ui/Reveal/Reveal";
import { convertToOrdinal } from "utils/Dates";
import { BladeProPill } from "components/ui/BladeProPill";


// this component is new joiner cutoff specific, is extracted here just for readability and adding the tooltip component
interface NewJoinerCutoffDropdownProps {
    settings: GetPayrollSetupSchemaContract;
    isDisabled: boolean;
    showTooltip?: boolean;
    tooltipText?: string;
    hasProPlan: boolean;
    items: {
        key: string,
        title: string,
        value: string
    }[]
    onChangeDropdown: ({ name, values }: { name?: string | undefined; values: string[]; }) => void;
    onChangeSwitch?: ({ isChecked, value, event, }: { isChecked: boolean; event?: React.ChangeEvent; value?: string; }) => void | undefined;
}

export const NewJoinerCutoffDropdown = ({
    settings,
    isDisabled,
    items,
    hasProPlan,
    showTooltip,
    tooltipText,
    onChangeDropdown,
    onChangeSwitch
}: NewJoinerCutoffDropdownProps) => {

    const component = <Box display={'flex'} flexDirection={'column'} gap={'spacing.6'}>
        <Box
            display="flex"
            flexDirection="row"
            gap={'spacing.4'}
            as={'label'}
        >
            <Switch
                name={'newJoinerArrearEnabled'}
                value={'newJoinerArrearEnabled'}
                isChecked={settings.newJoinerArrearEnabled}
                isDisabled={!hasProPlan || isDisabled}
                size="small"
                accessibilityLabel="Enable Cut-Off Date"
                onChange={onChangeSwitch}
            />
            <Text color="surface.text.gray.subtle">
                Enable Cut-off Date
            </Text>
            {!hasProPlan && BladeProPill}
        </Box>

        <Reveal isOpen={settings.newJoinerArrearEnabled}>
            <Dropdown selectionType="single">
                <SelectInput
                    accessibilityLabel='New Joiner Cutoff Date'
                    name={'newJoinerCutoffDate'}
                    labelPosition="top"
                    isRequired
                    value={settings.newJoinerCutoffDate?.toString().padStart(2, "0")}
                    label={'Select Date'}
                    isDisabled={isDisabled}
                    placeholder="Select date"
                    defaultValue={parseDropdownDate(settings.payrollInputCycle.endDate).toString().padStart(2, "0")}
                    onChange={onChangeDropdown}
                />
                <DropdownOverlay>
                    <ActionList>
                        {
                            items.map(({ key, title, value }) => (
                                <ActionListItem
                                    key={key}
                                    title={title}
                                    value={value}
                                    titleSuffix={value === parseDropdownDate(settings.payrollInputCycle.endDate).toString() ?
                                        <ActionListItemBadge color={'information'}>Recommended basis Attendance Cut-Off Date</ActionListItemBadge> : undefined
                                    }
                                />
                            ))}
                    </ActionList>
                </DropdownOverlay>
            </Dropdown>
            <Text
                variant={'caption'}
                color={'interactive.text.positive.subtle'}
                marginTop={'spacing.3'}
            >
                {
                    `💡 Employees joining after ${settings.newJoinerCutoffDate ? convertToOrdinal(settings.newJoinerCutoffDate) : 'N/A'} will be included in the next payroll month. 
                For instance, if an employee starts on the ${settings.newJoinerCutoffDate ? convertToOrdinal(parseDropdownDate(settings.newJoinerCutoffDate + 1)) : 'N/A'} of January, 
                they won't be part of the January payroll but will be included in February's, 
                with prorated salary as arrears from the January month.`
                }
            </Text>
        </Reveal>
    </Box>

    if (showTooltip && tooltipText) {
        return (
            <Tooltip content={tooltipText} placement="top">
                {component}
            </Tooltip>
        )
    }

    return component;
}