import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetCustomWindowResponse } from '../../ui/CustomWindow/types';
import {
  CREATE_UPDATE_WINDOW,
  DELETE_CUSTOM_WINDOW,
  GET_CUSTOM_WINDOW,
} from './queries';
import { AppError } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';
import api from '../../../api/index';

interface Props {
  onAddSuccess: () => void;
  onDeleteSuccess: () => void;
  onDeleteError: () => void;
  onUpdateSuccess: () => void;
}

const useCustomWindowConfigQuery = ({
  onAddSuccess,
  onDeleteSuccess,
  onDeleteError,
  onUpdateSuccess
}: Props) => {
  const queryClient = useQueryClient();
  const [customWindowItems, setCustomWindowItems] = useState<GetCustomWindowResponse>([]);

  const fetchCustomWindowItems = useQuery({
    queryKey: GET_CUSTOM_WINDOW,
    queryFn: api.taxDeclarations.fetchCustomWindow,
    onSuccess: (data) => {
      setCustomWindowItems(data)
    },
    onError: (e: typeof AppError) => { },
  });

  const addCustomWindowConfig = useMutation({
    mutationKey: CREATE_UPDATE_WINDOW,
    mutationFn: api.taxDeclarations.createUpdateWindow,
    onSuccess: (data) => {
      queryClient.invalidateQueries(CREATE_UPDATE_WINDOW);
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      onAddSuccess();
      fetchCustomWindowItems.refetch();
    },
    onError: (e: typeof AppError) => { },
  });

  const deleteCustomWindowConfig = useMutation({
    mutationKey: DELETE_CUSTOM_WINDOW,
    mutationFn: api.taxDeclarations.deleteCustomWindow,
    onSuccess: (data) => {
      queryClient.invalidateQueries(DELETE_CUSTOM_WINDOW);
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      onDeleteSuccess();
      fetchCustomWindowItems.refetch();
    },
    onError: (e: typeof AppError) => {
      onDeleteError();
    },
  });

  return {
    deleteCustomWindowConfig,
    isLoading: fetchCustomWindowItems.isFetching,
    customWindowItems,
    addCustomWindowConfig,
    fetchCustomWindowItems
  };
};

export default useCustomWindowConfigQuery;
