export enum FileUploadStatus {
  NOT_INITIATED = 0,
  UPLOADING = 1,
  SUCCESS = 2,
  FAILURE = 3
}

export enum MimeType {
CSV = "text/csv",
DOC = "application/msword",
DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
JPEG = "image/jpeg",
PNG = "image/png",
XLS = "application/vnd.ms-excel",
XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
PDF = "application/pdf",
}

export enum MimeTypeExtensions{
CSV = ".csv",
DOC = ".doc",
DOCX = ".docx",
JPEG = ".jpeg",
PNG = ".png",
JPG = ".jpg",
XLS = ".xls",
XLSX = ".xlsx",
PDF = ".pdf",
}

export const FileExtensions = Object.values(MimeTypeExtensions);

export function MimeTypeExtensionMapping(mimeType:MimeType):String[] {
switch(mimeType){
  case MimeType.CSV: 
    return [MimeTypeExtensions.CSV];
  case MimeType.DOC: 
    return [MimeTypeExtensions.DOC];
  case MimeType.DOCX: 
    return [MimeTypeExtensions.DOCX];
  case MimeType.JPEG: 
    return [MimeTypeExtensions.JPEG,MimeTypeExtensions.JPG];
  case MimeType.PNG: 
    return [MimeTypeExtensions.PNG];
  case MimeType.XLS: 
    return [MimeTypeExtensions.XLS];
  case MimeType.XLSX: 
    return [MimeTypeExtensions.XLSX];
  case MimeType.PDF: 
    return [MimeTypeExtensions.PDF];
  
}
}