import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cx from 'classnames';
import Tooltip from '../Tooltip';
import { Placement } from '@floating-ui/react';

const enum pillClassTypeMap {
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  neutral = 'neutral',
  default = 'default',
}

export default function Pill({
  content,
  type = pillClassTypeMap.default,
  className = '',
  tooltipContent,
  tooltipPosition = 'right'
}: PropsWithChildren<{ 
  content: string; 
  type?: keyof typeof pillClassTypeMap;
  className?: string; 
  tooltipContent?: string;
  tooltipPosition?: Placement;
}>) {
  const pillComponent = <span className={cx(styles?.pill, styles[type], className)}>{content}</span>;

  if(tooltipContent){

    return <Tooltip content={tooltipContent} position={tooltipPosition}>
      {pillComponent}
    </Tooltip>
  }
  else{
    return pillComponent;
  }

}

export { pillClassTypeMap };
