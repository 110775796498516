import {
  ArrowRightIcon,
  Box,
  Button,
  InfoIcon,
  PasswordInput,
  Spinner,
  Text,
  TextInput,
  Heading,
  Tooltip,
  TooltipInteractiveWrapper,
  Alert,
} from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import Errors from 'components/ui/Form/Errors';
import KYC from 'constants/kyc';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';
import SuccessViewModal from './SuccessViewModal';
import SidebarHeader from './components/SidebarHeader';
import SidebarSection from './components/SidebarSection';
import {
  BANK_VERIFICATION_MAX_ATTEMPTS,
  bankDetailsHelpfulLinks,
  bvsStatuses,
  eventCurrentAccountEntered,
  needHelpLinks,
} from './constants';
import {
  GET_BANK_ACCOUNT_VALIDATION_STATUS,
  GET_KYC_INFO,
  VALIDATE_BANK_ACCOUNT_DETAILS,
} from './queries';
import {
  ErrorMessage,
  FooterActionsContainer,
  KycPageMainViewWrapper,
  KycPageSideViewWrapper,
  KycPageWrapper,
  KycViewContent,
  KycViewFooter,
  KycViewHeader,
  TextPair,
} from './styles';
import { trackXPayrollEvent } from '../../utils/analytics';
import { useIsSelfServeP3M1Enabled } from './hooks/useIsSelfServeP3M1Enabled';
import { useCompanyVerificationData } from './hooks/useCompanyVerificationDetails';
import { ProgressPillState } from './types';

const BankDetailsView = () => {
  const history = useHistory();
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const [status, setStatus] = useState(bvsStatuses.NotInitiated);
  const isL1KycCompleted = org?.levelOneKyc?.status === true;
  const [isValidationCreated, setIsValidationCreated] = useState(false);
  const [data, setData] = useState({
    ifsc: '',
    accountNumber: '',
    repeatedAccountNumber: '',
  });

  const kycInfo = useQuery({
    queryKey: GET_KYC_INFO,
    queryFn: () => api.kyc.getKycInfo(),
  });

  const isKycVerified =
    kycInfo.data?.kycInfo.status === KYC.STATUS_VERIFIED || org?.kycStatus === KYC.STATUS_VERIFIED;

  const validationStatusQuery = useQuery({
    queryKey: GET_BANK_ACCOUNT_VALIDATION_STATUS,
    queryFn: () => api.kyc.getBankAccountVerificationStatus(),
    refetchInterval: (data) => {
      if (!isValidationCreated) return false;
      if (data?.status === bvsStatuses.Rejected || data?.status === bvsStatuses.Verified) {
        return false;
      } else {
        // Is status is not verified or rejected, poll the status api
        return 5000;
      }
    },
    onSuccess: (data) => {
      setStatus((prev) => data.status || prev);
      setData({
        ifsc: data.bank_ifsc || '',
        accountNumber: data.bank_account_number || '',
        repeatedAccountNumber: data.bank_account_number || '',
      });
    },
    onError: (error: typeof AppError) => {
      // TODO what to show in case there is a server error
    },
  });

  const validateBankAccount = useMutation({
    mutationKey: VALIDATE_BANK_ACCOUNT_DETAILS,
    mutationFn: () =>
      api.kyc.validateBankAccount({
        bank_account_number: data.accountNumber,
        bank_ifsc: data.ifsc,
        bank_beneficiary_name: org?.name || '',
      }),
    onSuccess: () => {
      setIsValidationCreated(true);
      setStatus(bvsStatuses.Pending);
      validationStatusQuery.refetch();
    },
    onError: (error: typeof AppError) => {
      // TODO what to show in case there is a server error
    },
  });

  const { isSelfServeP3M1Enabled, isLoading: isFetchingSplitzExperiment } =
    useIsSelfServeP3M1Enabled();

  const { isCompanyVerified } = useCompanyVerificationData();

  const error = validationStatusQuery.error || validateBankAccount.error;
  const isKycFlowManual = validationStatusQuery.data?.verificationType === 'manual';

  const isVerifying =
    (status === bvsStatuses.Pending || status === bvsStatuses.OnHold) && !error && !isKycFlowManual;

  const isVerified = status === bvsStatuses.Verified;
  const isRejected = status === bvsStatuses.Rejected;

  const progress = useMemo<ProgressPillState[]>(() => {
    if (isSelfServeP3M1Enabled) {
      return [
        'COMPLETED',
        isVerified ? 'COMPLETED' : 'IN_PROGRESS',
        isKycVerified ? 'COMPLETED' : 'INCOMPLETE',
      ];
    } else {
      return [isVerified ? 'COMPLETED' : 'IN_PROGRESS', isKycVerified ? 'COMPLETED' : 'INCOMPLETE'];
    }
  }, [isSelfServeP3M1Enabled, isVerified, isKycVerified]);

  if (isFetchingSplitzExperiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  // Redirecting to company verification if company not yet verified
  if (isSelfServeP3M1Enabled && !isCompanyVerified) {
    return <Redirect to={routePaths.kyc.companyDetailsVerification.root} />;
  }

  const checkDetailsChanged = () => {
    const validationData = validationStatusQuery.data;
    return (
      data.ifsc !== validationData?.bank_ifsc ||
      data.accountNumber !== validationData?.bank_account_number
    );
  };

  const areDetailsChanged = checkDetailsChanged();

  const attemptsRemaining = validationStatusQuery.data?.attempts_remaining || 0;
  const isManualVerificationVisible = attemptsRemaining <= 0 || isKycFlowManual;

  const isLoading =
    validateBankAccount.isLoading || validationStatusQuery.isFetching || isVerifying;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (org?.kycStatus === KYC.STATUS_NOT_INITIATED) {
      trackXPayrollEvent(eventCurrentAccountEntered, 'interaction', 'company-onboarding');
    }
    setStatus(bvsStatuses.Pending);
    setIsValidationCreated(false);
    validateBankAccount.mutate();
  };

  const handleVerifyManuallyClick = () => {
    history.push(routePaths.kyc.bankDetailsManual);
  };

  const handleGoToCompanyDetails = () => {
    window.location.href = routePaths.companyOnboarding.root;
  };

  const handleNextClick = () => {
    const nextRoute = isSelfServeP3M1Enabled
      ? routePaths.kyc.documentsUpload
      : routePaths.kyc.userSelection;

    history.push(nextRoute);
  };

  const handleChange = (e: { name?: string; value?: string }) => {
    const { name, value } = e;
    setData((prev) => ({ ...prev, [name || '']: value }));
  };

  const isRepeatedCorrectly = data.repeatedAccountNumber
    ? data.accountNumber === data.repeatedAccountNumber
    : true;

  const FooterMessage = () => {
    if (isKycFlowManual) {
      return (
        <>
          <ErrorMessage message="Verification failed" />
          <TextPair mutedText={'Please verify your bank details manually'} />
        </>
      );
    } else if (validationStatusQuery.data?.error_description && attemptsRemaining) {
      return (
        <>
          <ErrorMessage message="Verification failed" />
          <TextPair mutedText={validationStatusQuery.data.error_description} />
        </>
      );
    } else if (error) {
      return (
        <>
          <ErrorMessage message="Some error occurred" />
          <TextPair mutedText={<Errors errorResponse={error} />} />
        </>
      );
    }
    if (!isL1KycCompleted) {
      return (
        <>
          <ErrorMessage message="Registered business name not verified" />
          <TextPair mutedText="Please fill" highlightedText="Company details" />
          <TextPair mutedText="in order to proceed with KYC." />
        </>
      );
    }

    if (isVerifying) {
      return (
        <TextPair
          mutedText="⏳ Verifying..."
          highlightedText="please don't close this window or refresh"
        />
      );
    } else if (isVerified) {
      return <TextPair mutedText="✅ Verification Successful!" />;
    } else if (isRejected) {
      return (
        <>
          <ErrorMessage
            message={`Verification failed (${
              BANK_VERIFICATION_MAX_ATTEMPTS - attemptsRemaining
            }/${BANK_VERIFICATION_MAX_ATTEMPTS} attempts)`}
          />
          <TextPair mutedText="This bank account" highlightedText="does not match" />
          <TextPair mutedText="with" highlightedText="business name." />
          {attemptsRemaining ? (
            <TextPair mutedText="Please re-check your account number." />
          ) : (
            <TextPair mutedText="Please try" highlightedText="Manual Verification" />
          )}
        </>
      );
    }
    return <TextPair mutedText="⚡ Verify" highlightedText="within 15 seconds!" />;
  };

  const FooterCtas = () => {
    if (!isL1KycCompleted) {
      return (
        <Button icon={ArrowRightIcon} iconPosition="right" onClick={handleGoToCompanyDetails}>
          Go to Company Details
        </Button>
      );
    }

    if (isVerifying) {
      return (
        <Button type="submit" isLoading={isVerifying}>
          Verify
        </Button>
      );
    } else if (isVerified) {
      if (areDetailsChanged && attemptsRemaining > 0) {
        return (
          <Button type="submit" isLoading={isLoading}>
            Verify
          </Button>
        );
      }
      return <Button onClick={handleNextClick}>Next</Button>;
    } else if (isRejected) {
      if (isManualVerificationVisible) {
        return (
          <Button onClick={handleVerifyManuallyClick} icon={ArrowRightIcon} iconPosition="right">
            Verify Manually
          </Button>
        );
      }
      return (
        <Button type="submit" isLoading={isLoading}>
          Verify
        </Button>
      );
    }
    return (
      <Button type="submit" isLoading={isLoading}>
        Verify
      </Button>
    );
  };

  return (
    (<KycPageWrapper>
      <KycPageSideViewWrapper>
        <Box
          overflowY={'auto'}
          display={'flex'}
          flexDirection={'column'}
          paddingY={'spacing.9'}
          paddingX={'spacing.10'}>
          <SidebarHeader
            onBackClick={() => (window.location.href = '/dashboard')}
            progress={progress}
          />
          <Box display={'flex'} gap={'spacing.2'} alignItems={'center'} marginTop={'spacing.4'}>
            <Text color="surface.text.gray.muted">Why do KYC?</Text>
            <Tooltip
              content={'Complete KYC to unlock salary slips and salary transfers'}
              placement="right">
              <TooltipInteractiveWrapper>
                <InfoIcon color="interactive.icon.gray.normal" size="small" />
              </TooltipInteractiveWrapper>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.10" marginTop="spacing.10">
            <SidebarSection title="Helpful Links" links={bankDetailsHelpfulLinks} />
            <SidebarSection title="Need Help?" links={needHelpLinks} />
          </Box>
        </Box>
      </KycPageSideViewWrapper>
      <KycPageMainViewWrapper as="form" onSubmit={handleSubmit}>
        <Box height="100vh" display={'grid'} gridTemplateRows={'1fr auto'}>
          <Box overflowY={'auto'}>
            <KycViewHeader marginBottom={'spacing.6'}>
              <Heading size="xlarge">🏦 Verify Bank Details</Heading>
              <Text marginTop={'spacing.3'} color="surface.text.gray.muted">
                💳 We will verify if your registered business name matches your bank account for
                salary transfers
              </Text>
              <Alert
                title="Note"
                description="You can load funds to your XPayroll account only via this bank account. You can contact support to add more bank accounts later if required."
                marginTop="spacing.4"
                color="information"
              />
            </KycViewHeader>

            <KycViewContent>
              <Box display={'flex'} flexDirection={'column'} gap="spacing.8" maxWidth={'376px'}>
                <TextInput
                  isRequired
                  isDisabled
                  value={org?.name || ''}
                  label="Account Name"
                  placeholder="Stark Industries"
                  validationState={isL1KycCompleted ? 'none' : 'error'}
                  errorText="Not verified"
                  onChange={handleChange}
                />
                <TextInput
                  name="ifsc"
                  value={data.ifsc}
                  isRequired
                  isDisabled={isVerifying}
                  label="IFSC Code"
                  placeholder="Eg. HDFC0000001"
                  onChange={handleChange}
                />
                <PasswordInput
                  isRequired
                  name="accountNumber"
                  isDisabled={isVerifying}
                  value={data.accountNumber}
                  label="Bank Account Number"
                  placeholder="Eg. 1234567890112"
                  autoCompleteSuggestionType="none"
                  onChange={handleChange}
                />
                <TextInput
                  isRequired
                  name="repeatedAccountNumber"
                  isDisabled={isVerifying}
                  value={data.repeatedAccountNumber}
                  label="Re-enter Bank Account Number"
                  placeholder="Eg. 1234567890112"
                  validationState={isRepeatedCorrectly ? 'none' : 'error'}
                  errorText={
                    isRepeatedCorrectly ? '' : 'Bank account number does not match the above'
                  }
                  onChange={handleChange}
                />
              </Box>
            </KycViewContent>
          </Box>
          <KycViewFooter>
            <Box>
              <FooterMessage />
            </Box>
            <FooterActionsContainer>
              <FooterCtas />
            </FooterActionsContainer>
          </KycViewFooter>
        </Box>
      </KycPageMainViewWrapper>
      <SuccessViewModal orgName={org?.name || ''} isOpen={isKycVerified} />
    </KycPageWrapper>)
  );
};

export default BankDetailsView;
