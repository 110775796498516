import { useQuery, UseQueryOptions } from 'react-query';
import { parse } from 'date-fns';
import attendance from 'api/attendance';
import { AttendanceResponse } from 'schemas/Attendance';
import { CACHE_TTL } from 'utils/cache';
import { useSearchParams } from 'hooks/useSearchParams';
import { useSelector } from 'react-redux';
import { peopleId } from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

type AttendanceQueryKey = ['attendance', userId: string | null, month: string];

interface UseAttendanceDataOptions<TData = AttendanceResponse>
  extends Omit<
    UseQueryOptions<AttendanceResponse, AppError, TData, AttendanceQueryKey>,
    'queryKey' | 'queryFn'
  > {}

export function useAttendanceData<TData = AttendanceResponse>(
  options: UseAttendanceDataOptions<TData> = {},
) {
  const { urlParams } = useSearchParams();
  const loggedInUserId = useSelector(peopleId);
  const monthParam = urlParams.get('month');
  const userId = urlParams.get('user_id') || (loggedInUserId ? loggedInUserId.toString() : null);

  const currentDate = monthParam ? parse(monthParam, 'yyyy-MM', new Date()) : new Date();
  const attendanceMonth = getFormattedDateValue({
    date: currentDate,
    formatString: dateFormats.yearMonthDate,
  });

  const queryKey: AttendanceQueryKey = [
    'attendance',
    userId,
    getFormattedDateValue({ date: currentDate, formatString: dateFormats.yearMonth }),
  ];

  const queryResult = useQuery<AttendanceResponse, AppError, TData, AttendanceQueryKey>(
    queryKey,
    () => attendance.fetchAttendance(attendanceMonth, userId),
    {
      staleTime: CACHE_TTL.MEDIUM,
      ...options,
      onError: (error: AppError) => {
        options.onError?.(error);
      },
    },
  );

  return {
    ...queryResult,
    currentDate,
    userId,
    isForLoggedInUser: userId === loggedInUserId?.toString(),
  };
}
