import React from "react";
import { IInsurance } from "reducers/insurance";
import styles from './index.module.css';
import { toIndianCurrencyString } from "utils/Numbers";
import { TableLayout } from "components/ui/Table";
import { GetInsuranceRenewalOrganizationAndEmployeeContract } from "schemas/GetInsuranceRenewalOrganizationAndEmployee";

export default function RenewalInsurancePlanDetails({insurance,organizationAndEmployeeRenewalData}: {
    insurance: IInsurance,
    organizationAndEmployeeRenewalData: GetInsuranceRenewalOrganizationAndEmployeeContract;
}){
    if (!insurance.data) {
        return <></>;
    }
    return (
        <>
            <div className={`${styles['renewal-page-plan-details-content']}`}>
                <h2 className="m-3 text-3xl font-semibold"> Current plan details</h2>
                <TableLayout  className=" xl:w-1/2 min-w-full">
                    <tbody>
                    <tr>
                            <td className="">Plan Type</td>
                            <td className="font-bold">
                                {
                                    insurance.data.planId  === 1  ? 'Starter'
                                    : insurance.data.planId === 2  ? 'Essential'
                                    : insurance.data.planId  === 3 ? 'Premium' 
                                    : 'N/A'
                                }
                            </td>
                    </tr>
                    <tr>
                        <td className="">Sum insured</td>
                        <td className="font-bold">{insurance?.data?.sumInsured ? toIndianCurrencyString(insurance?.data?.sumInsured) : 'N/A'}</td>
                    </tr>
                    <tr>
                        <td className="">With Children And Spouse</td>
                        <td className="font-bold">{insurance?.data?.withChildrenAndSpouse ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr>
                        <td className="">With Parents Or In Laws</td>
                        <td className="font-bold">{insurance?.data?.withParentsOrInLaws ? 'Yes' : 'No'}</td>
                    </tr>
                    </tbody>
                </TableLayout>
            </div>
        </>
    );
}