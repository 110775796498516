import React from 'react';

import permissions from 'constants/permissions';
import { toIndianCurrencyString } from 'utils/Numbers';
import { StandardSecondaryButton } from 'components/ui/Button';
import { Additions, ApplicableBonus, ArrearComponent, DeductionComponent, RunPayrollData } from './../utils/types';
import { useModal } from 'components/ui/Modals';
import AdditionsDisplay from '../Popups/AdditionsDisplay';
import SkipButton from './SkipButton';
import FNFPayroll from '../Popups/FNFPayroll';
import ResumePayroll from '../Popups/ResumePayroll';
import EditSalary from '../Popups/EditSalary';
import CheckBox from 'components/ui/Checkbox';
import useHasPermission from 'utils/useHasPermission';
import { useRunPayrollPaginationContext } from '../contexts/RunPayrollPaginationContext';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import { getStaticMediaUrl } from 'utils/Urls';
import sortBlue from 'assets/icon--sort-blue.svg';
import sortWhite from 'assets/icon--sort-white.svg';
import { useErrorMessageContext } from '../contexts/RunPayrollErrorContext';
import { getArrearsToDisplay, getStatusPillTooltipContent, getUserName, newJoinerArrearExistsInEntry } from '../utils/utils';
import { useRunPayrollDataSelectionContext } from '../contexts/RunPayrollDataSelectionContext';
import EditIcon from 'assets/icon--edit.svg';
import EditIconDisabled from 'assets/icon--edit-grey.svg';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import DeductionsDisplay from '../Popups/DeductionsDisplay';

import { TableWithLoader as Table } from 'components/ui/Table';

import styles from './index.module.scss';
import useIsHoldSalaryPayComplianceEnabled from 'components/Fnf/useIsHoldSalaryPayComplianceEnabled';
import useIsRlopEnabled from '../utils/useIsRlopEnabled';
import useNewJoinerArrearEnabled from '../utils/useNewJoinerArrearEnabled';
import { GrossPayComponent } from '../utils/viewHelpers';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import EditSalaryV2 from '../Popups/EditSalaryV2';


const DataDisplay = ({
  isLoading,
  isBulkActionsEnabled,
}: {
  isLoading: boolean;
  isBulkActionsEnabled: boolean;
}) => {
  const { openModal } = useModal();
  const { isRlopFeatureEnabled } = useIsRlopEnabled();
  const { isNewJoinerArrearFeatureEnabled } = useNewJoinerArrearEnabled();
  const hasEditPermission = useHasPermission([permissions.PAYROLL_EDIT]);
  const pagination = useRunPayrollPaginationContext();
  const data = useRunPayrollDataContext();
  const errCtx = useErrorMessageContext();
  const selectedIds = useRunPayrollDataSelectionContext();
  const { isHoldSalaryPayComplianceEnabled } = useIsHoldSalaryPayComplianceEnabled();
  const { isAdditionsDisabled, isM2S1Enabled, isDeductionsDisabled } = useIsFeatureDisabledForRearch();

  const isEditCtaVisible = !isAdditionsDisabled;

  if (!data || !pagination) {
    return null;
  }
  const { hasAdhocPayments, isPayrollBeingFinalized } = data;
  const dataSize = data.numTotal;
  const payrollMeta = data.payrollIds ?? [];
  const payrollMonth = data.payrollMonth;
  const { page, limit, order, orderBy, goNextPage, goPrevPage } = pagination;

  const showAdditions = (adhocAllowances: Additions[], allowances: Additions[], arrears: ArrearComponent[], applicableBonus: ApplicableBonus[]) => {
    openModal(<AdditionsDisplay adhocAllowances={adhocAllowances} allowances={allowances} arrears={arrears} applicableBonus={applicableBonus} />);
  };

  const showDeductions = (deductions: DeductionComponent) => {
    openModal(<DeductionsDisplay deductions={deductions} />);
  }

  const activePayrollMeta = payrollMeta.filter((meta) => !meta.isBlocked && !meta.isFinal);
  const newJoinerIds = ((data.payrollData ?? []) as RunPayrollData[]).filter((entry) => entry.newJoinerArrearPaymentMonth ).map((entry) => entry.id);
  const canSkipOrUnskipIds = (isNewJoinerArrearFeatureEnabled && newJoinerIds.length > 0) ? activePayrollMeta.filter((meta) => !(newJoinerIds.includes(meta.id))).map((meta) => meta.id) : activePayrollMeta.map((meta) => meta.id);

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;
    selectedIds?.setValue(isSelected ? canSkipOrUnskipIds : []);
  };

  const onEdit = (entry: RunPayrollData) => {
    if ( isNewJoinerArrearFeatureEnabled ? (entry.isCancelled && !entry.newJoinerArrearPaymentMonth) : entry.isCancelled) {
      openModal(
        <ResumePayroll
          payrollId={entry.id}
          payrollMonth={payrollMonth}
          onError={errCtx?.setError}
        />,
      );
    } else if (entry.hasFnf) {
      openModal(
        <FNFPayroll payrollId={entry.id} payrollMonth={payrollMonth} onError={errCtx?.setError} />,
      );
    } else {
      if(isM2S1Enabled){
        openModal(
          <EditSalaryV2 payrollEntry={entry} payrollMonth={payrollMonth} onError={errCtx?.setError} />,
        );
      }
      else{
        openModal(
          <EditSalary payrollEntry={entry} payrollMonth={payrollMonth} onError={errCtx?.setError} />,
        );
      }
      
    }
  };

  const isBulkSelectionEnabled = canSkipOrUnskipIds.length > 0 && isBulkActionsEnabled && !data.isChangeRequestPendingOnFinalize;

  return (
    <>
      <Table aria-label='run-payroll-table' isLoading={isLoading} className={`w-full overflow-x-auto ${styles['run-payroll-data-grid']} `}>
        <tbody>
          <tr className={styles['pagination-header']}>
            {isBulkSelectionEnabled && (
              <th colSpan={1} className={styles['pagination-checkbox']}>
                <CheckBox
                  name="select-all-run-payroll"
                  className={styles['skip-checkbox']}
                  title={'Select All'}
                  onChange={onSelectAll}
                  checked={selectedIds?.value.length === canSkipOrUnskipIds.length}
                  isIntermediate={
                    !!selectedIds?.value.length && selectedIds?.value.length !== dataSize
                  }
                />
              </th>
            )}
            <th colSpan={!isEditCtaVisible?3:4} className={styles['pagination-info-display']}>
              {!!selectedIds?.value.length
                ? `Selected ${selectedIds?.value.length} of ${dataSize} employees`
                : `Showing ${(page - 1) * limit + 1}-${Math.min(
                  page * limit,
                  dataSize,
                )} of ${dataSize} employees`}
            </th>
            <th colSpan={4} style={{ padding: '12px' }}>
              {isBulkSelectionEnabled && (
                <SkipButton
                  selectedIds={selectedIds}
                  activePayrollMeta={activePayrollMeta}
                  payrollMonth={payrollMonth}
                />
              )}
            </th>
          </tr>
          <tr className={styles['dynamic-row']}>
            {isBulkSelectionEnabled && <th></th>}
            <th
              className={styles['sort-header']}
              title="Sort by Name"
              style={{ minWidth: '115px' }}
              onClick={() => orderBy('ORDER_BY_NAME')}>
              Name
              <img
                src={getStaticMediaUrl(order && order.ORDER_BY_NAME ? sortBlue : sortWhite)}
                alt=""
              />
            </th>
            <th className="text-right" style={{ minWidth: '115px' }}>
              Monthly CTC
            </th>
            <IsFeatureDisabledForRearch feature='isAdditionsDisabled'>
            <th id="table-bonus" className="text-right">
              Addition
            </th>
            </IsFeatureDisabledForRearch>
            <IsFeatureDisabledForRearch feature='isDeductionsDisabled'>
            <th id="table-deduction" className="text-right">
              Deduction
            </th>
            </IsFeatureDisabledForRearch>
            <IsFeatureDisabledForRearch feature='isReimbursementsDisabled'>
            <IsFeatureDisabledForRearch feature='isMileStone1Enabled'>
            <th className="text-right">Reimbursements</th>
            
            <th id="table-remarks">Remarks</th>
            </IsFeatureDisabledForRearch>
            </IsFeatureDisabledForRearch>
            <th
              className={`${styles['sort-header']} text-right`}
              style={{ minWidth: '110px' }}
              title="Sort by Skipped"
              onClick={() => orderBy('ORDER_BY_CANCELLED')}>
              Gross Pay
              <img
                src={getStaticMediaUrl(order && order.ORDER_BY_CANCELLED ? sortBlue : sortWhite)}
                alt=""
              />
            </th>
            <th className="text-center">{ !isEditCtaVisible ? 'Status' : 'Edit' }</th>
          </tr>
          <tr></tr>
          {((data.payrollData ?? []) as RunPayrollData[])?.map((entry) => {
            const entryId = entry.id.toString();
            const isSelected = selectedIds?.hasId(entry.id);
            const newJoinerArrearPaymentMonthExists = !!entry.newJoinerArrearPaymentMonth;

            return (
              <tr id={entryId} key={entry.id} className={styles['data-record']}>
                {isBulkSelectionEnabled && (
                  <td className={isSelected ? '' : styles['data-checkbox']}>
                    {canSkipOrUnskipIds.includes(entry.id) && !entry.changesStagingId ? (
                      <CheckBox
                        name={`select-run-payroll-${entryId}`}
                        checked={isSelected}
                        className={styles['skip-checkbox']}
                        onChange={(event) => {
                          if (event.target.checked) {
                            selectedIds?.push(entry.id);
                          } else {
                            selectedIds?.remove(entry.id);
                          }
                        }}
                        data-testid={`run-payroll-skip-checkbox-${entry.email}`}
                      />
                    ) : (
                      <span></span>
                    )}
                  </td>
                )}
                <td data-title="Employee name" className="text-right lg:text-left" title={entry.employeeId ? `Emp ID ${entry.employeeId}` : ""}>
                  <a href={entry.nameLink} style={{ wordBreak: 'break-word' }}>
                    {getUserName(entry)}
                  </a> {!entry.isPeopleActive ? (<span className='italic'>(Dismissed)</span>) : ""}
                </td>
                <td data-title="Monthly Salary" className="text-right">
                  {toIndianCurrencyString(entry.salary, true)}
                </td>
                <IsFeatureDisabledForRearch feature='isAdditionsDisabled'>
                <td data-title="Bonus" className="text-right">
                  {(entry.adhocPaymentSum > 0 || entry.applicableBonusSum > 0 || (entry.arrearsSum && entry.arrearsSum.amount > 0)) ? (
                    <>
                      <a
                        onClick={() =>
                          showAdditions(
                            entry.adhocAllowances ?? [],
                            entry.allowances ?? [],
                            getArrearsToDisplay(entry, isRlopFeatureEnabled, isNewJoinerArrearFeatureEnabled),
                            entry.applicableBonus ?? []
                          )
                        }
                        data-testid={`runpayroll-additions-link-${entry.email}`}>
                        {toIndianCurrencyString(entry.bonus ?? 0, true)}
                      </a>
                      <sup> #</sup>
                    </>
                  ) : entry.bonus ? (
                    toIndianCurrencyString(entry.bonus, true)
                  ) : (
                    ' '
                  )}
                </td>
                </IsFeatureDisabledForRearch>
                <IsFeatureDisabledForRearch feature='isDeductionsDisabled'>
                <td data-title="Deduction" className="text-right">
                  <a
                    href="/"
                    onClick={(e) => {

                      e.preventDefault();
                      if (entry.deductions && entry.deduction && entry.deduction > 0) {
                        showDeductions(entry.deductions)
                      }
                    }}
                  >
                    {entry.deduction ? toIndianCurrencyString(entry.deduction, true) : ' '}
                  </a>
                </td>
                <IsFeatureDisabledForRearch feature='isMileStone1Enabled'>
                <td data-title="Reimbursements" className="text-right">
                  {entry.reimbursement ? (
                    <a href={entry.reimbursementLink}>
                      {toIndianCurrencyString(entry.reimbursement, true)}
                    </a>
                  ) : (
                    ' '
                  )}
                </td>
                <td
                  data-title="Remarks"
                  className={`text-right ${styles['truncate-element']} ${styles['dynamic-cell']}`}>
                  <div className={styles['truncate-element-text']} title={entry.remarks}>
                    {entry.remarks}
                  </div>
                </td>
                </IsFeatureDisabledForRearch>
                </IsFeatureDisabledForRearch>
                <td data-title="Gross Pay" className="text-right">
                  <GrossPayComponent
                    entry={entry}
                    isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                    newJoinerArrearPaymentMonthExists={newJoinerArrearPaymentMonthExists}
                    newJoinerArrearExists={newJoinerArrearExistsInEntry(entry)}
                  />
                </td>
                <td data-title="Edit" className="text-center">
                  <div className="flex justify-center"> 
                    {entry.isBlocked ? (
                      <Pill
                        type={pillClassTypeMap.success}
                        content='Paid'
                        tooltipContent={getStatusPillTooltipContent(entry, newJoinerArrearPaymentMonthExists)}
                      />
                    ) : entry.isFinal ? (
                      <Pill
                        type={pillClassTypeMap.neutral}
                        content='Final'
                        tooltipContent={getStatusPillTooltipContent(entry, newJoinerArrearPaymentMonthExists)}
                      />
                    ) : entry.isChangeRequestPendingOnEdit ? (
                      <Pill
                        type={pillClassTypeMap.warning}
                        content='Pending'
                        tooltipContent={getStatusPillTooltipContent(entry, newJoinerArrearPaymentMonthExists)}
                      />
                    ) : (hasEditPermission && !isPayrollBeingFinalized && isEditCtaVisible) ? (
                      <div className="w-full flex justify-center">
                        <div className={styles['edit-button']} onClick={() => !entry.changesStagingId && onEdit(entry)}>
                          <img src={getStaticMediaUrl(entry.changesStagingId ? EditIconDisabled : EditIcon)} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {hasAdhocPayments && (
        <div className="mt-8">
          <small>
            # One-time payments are included. These payments have already been made to your
            employees, and will be excluded from the payroll amount automatically.
          </small>
        </div>
      )}
      {(limit || 0) < dataSize && (
        <div className={`${styles['run-payroll-data-grid-pagination']}`}>
          {dataSize > page * limit && (
            <StandardSecondaryButton disabled={isLoading} onClick={goNextPage} className="m-0">
              Next
            </StandardSecondaryButton>
          )}
          {page > 1 && (
            <StandardSecondaryButton disabled={isLoading} onClick={goPrevPage} className="m-0">
              Prev
            </StandardSecondaryButton>
          )}
        </div>
      )}
    </>
  );
};

export default DataDisplay;
