import React, { useEffect, useState } from 'react';
import BotThumbnailImage from '../../../assets/payrollAiAssistant/BotThumbnail.svg';
import MessageLoadingImage from '../../../assets/payrollAiAssistant/MessageLoading.gif';
import { getStaticMediaUrl } from 'utils/Urls';

interface BotThumbnailProps {
  width?: string;
  height?: string;
  isStreaming?: boolean;
  streamDuration?: number;
}

const BotThumbnail = ({ width, height, isStreaming, streamDuration }: BotThumbnailProps) => {
  const [showAnimation, setShowAnimation] = useState(isStreaming);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isStreaming) {
      timeoutId = setTimeout(
        () => {
          setShowAnimation(false);
        },
        streamDuration ? streamDuration * 1000 + 2000 : 3000,
      );
    }

    return () => {
      if (isStreaming) {
        clearTimeout(timeoutId);
      }
    };
  }, [isStreaming, streamDuration]);

  return (
    <img
      src={getStaticMediaUrl(showAnimation ? MessageLoadingImage : BotThumbnailImage)}
      alt="Bot thumbnail"
      style={{
        width: width ?? '20px',
        height: height ?? '20px',
      }}
    />
  );
};

export { BotThumbnail };
