import React, { ReactElement, RefObject } from 'react';

import AddressInfo from './AddressInfo';
import InfoCardList from './InfoCardList';
import AddressForm from './AddressForm';
import { DeliveryType } from 'constants/flexibenefits';
import { DispatchAddress, FlexiDetailsProps } from '../../utils/init';
import { getInfoCards } from './infoCardData';

const DeliveryAddress = ({
    flexiDetails, 
    setFlexiDetails, 
    isUpdateLoading,
    addressFormRef
}: FlexiDetailsProps & {
    addressFormRef: RefObject<HTMLFormElement>
}): ReactElement => {
    const selectedDeliveryType = flexiDetails.deliveryType || DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION;
    const dispatchAddress = flexiDetails.cardDeliveryDetails;

    const setDeliveryLocationType = (type?: DeliveryType) => {
        setFlexiDetails({
            ...flexiDetails, 
            deliveryType: type ?? DeliveryType.DISPATCH_TO_EMPLOYEE
        });
    }

    const setDispatchAddress = (fullAddress: DispatchAddress) => {
        setFlexiDetails({
            ...flexiDetails, 
            cardDeliveryDetails: fullAddress,
        });
    }

    const infoCards = getInfoCards(selectedDeliveryType);

    return (
        <>
            <AddressInfo />
            <InfoCardList infoCardList={infoCards} onSelect={setDeliveryLocationType} selectedCardId={selectedDeliveryType}/>
            {selectedDeliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION &&
                <AddressForm 
                    addressFormRef={addressFormRef}
                    cardDeliveryDetails={dispatchAddress} 
                    setDispatchAddress={setDispatchAddress}
                    isPending={!!isUpdateLoading}/>}
        </>
    )
}

export default DeliveryAddress;