import { routePaths } from 'components/Routes/data';
import Card from 'components/Settings/ZohoSettings/components/Card';
import { WarningAlert } from 'components/ui/Alert';
import { StandardButton } from 'components/ui/Button';
import ErrorMessage from 'components/ui/ErrorMessage';
import { useModal } from 'components/ui/Modals';
import { RadioButton } from 'components/ui/RadioButton';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { LoanEditRequestSchemaContract } from 'schemas/LoanEditRequestSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import api from '../../api/index';
import { LoanDataArraySchemaContract } from '../../schemas/LoanDataArraySchema';
import LoanConfirmChanges from './components/LoanConfirmChanges';
import LoanRecoveryPreviewTable from './components/LoanRecoveryPreviewTable';
import { LoanRecoveryOption, LoanStatus, SkipEmiOption } from './constants';
import styles from './index.module.scss';
import { BASIC_USER_LOAN_DATA, FETCH_SINGLE_LOAN_RECORD } from './queries';
import { useSearchParams } from 'hooks/useSearchParams';

const SkipEmi = function () {
  const { openModal, closeModal } = useModal();
  const [requestData, setRequestData] = useState<LoanEditRequestSchemaContract>({
    action: LoanRecoveryOption.SKIP_EMI,
    option: SkipEmiOption.INCREASE_TENURE,
  });

  const queryParam = useSearchParams().urlParams;
  const loanId = queryParam.get('loanId');
  const [peopleId, setPeopleId] = useState<null | number>(null);
  const [error, setError] = useState<string | null>(null);
  const [previewError, setPreviewError] = useState<typeof AppError | null>(null);

  const history = useHistory();
  const [isEmiSkipped, setIsEmiSkipped] = useState(false);

  const errorsToShowBelowTable = [
    'LOAN_SKIP_EMI_OPTION_NOT_POSSIBLE_KEEPING_TENURE_SAME',
    'INVALID_DATA',
  ];

  let showPreviewErrorOnTop = false;
  let previewErrorMessage = '';
  if (previewError) {
    const previewErrorDetails = getAppErrorDetails(previewError);
    showPreviewErrorOnTop = !errorsToShowBelowTable.includes(previewErrorDetails.code);
    previewErrorMessage = previewErrorDetails.messageForUser;
  }

  const redirectIfLoanIsClosed = (fetchLoanData: LoanDataArraySchemaContract | null) => {
    if (fetchLoanData?.data[0].status === LoanStatus.CLOSED) {
      history.push(`${routePaths.loans.manageLoan}?loanId=${loanId}`);
    }
  };

  const getBasicUserLoanDetails = useQuery(
    [BASIC_USER_LOAN_DATA, peopleId],
    () => {
      if (!peopleId) {
        return null;
      }
      return api.loans.basicUserLoanData(peopleId);
    },
    {
      onSuccess: (data) => {
        if (data && data.last_payroll_month) {
          setRequestData({
            action: LoanRecoveryOption.SKIP_EMI,
            option: SkipEmiOption.INCREASE_TENURE,
            payroll_month: data.last_payroll_month,
          });
        }
      },

      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        setError(response.messageForUser);
      },
    },
  );

  const fetchLoan = useQuery(
    FETCH_SINGLE_LOAN_RECORD,
    () => {
      if (!loanId) {
        return null;
      }
      return api.loans.fetchLoanData({ loan_id: parseInt(loanId), page: 1 });
    },
    {
      onSuccess: (data) => {
        setPeopleId(data && data.data[0].people_id);
        getBasicUserLoanDetails.refetch();
        redirectIfLoanIsClosed(data);
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        setError(response.messageForUser);
      },
    },
  );

  const emi = fetchLoan.data?.data[0].monthly_total_emi;

  const formattedMonth =
    requestData.payroll_month && format(new Date(requestData.payroll_month), 'MMMM yy'); // date-fns
  let emiAmountContent = `Loading...`;

  if (formattedMonth && emi) {
    let employeeName = 'the employee';
    if (getBasicUserLoanDetails.data?.employee_name) {
      employeeName = getBasicUserLoanDetails.data?.employee_name;
    }
    emiAmountContent = `EMI amount of ₹${emi} for ${formattedMonth} will be skipped for ${employeeName}`;
  }

  const handleSelectedOption = (option: SkipEmiOption) => {
    setRequestData({
      action: LoanRecoveryOption.SKIP_EMI,
      option: option,
      payroll_month: requestData.payroll_month,
    });
  };

  const handleErrorOnConfirm = (error: typeof AppError) => {
    const response = getAppErrorDetails(error);
    setError(response.messageForUser);
    document.querySelector('#react')?.scrollTo(0, 0);
  };

  return (
    <div className={styles['skip-emi-parent']}>
      {error && <WarningAlert className="my-6">{error}</WarningAlert>}
      {showPreviewErrorOnTop && (
        <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
          <ErrorMessage title={''}>{previewErrorMessage}</ErrorMessage>
          <Link to={`${routePaths.loans.manageLoan}?loanId=${loanId}`}>
            <StandardButton className="mt-12">Go Back</StandardButton>
          </Link>
        </Card>
      )}
      {!isEmiSkipped && !showPreviewErrorOnTop && (
        <>
          <div className={styles['skip-emi-title']}> Skip EMI</div>
          <Card className={styles['skip-emi-amount-text-box']}>⚡️ {emiAmountContent}</Card>
          <div className={styles['skip-emi-options']}>
            {loanId && requestData && requestData.payroll_month && (
              <LoanRecoveryPreviewTable
                requestData={requestData}
                loanId={parseInt(loanId)}
                setPreviewError={setPreviewError}>
                {({ isLoading }) => {
                  return (
                    <>
                      <div className={styles['skip-emi-question']}>
                        How does skipping of this EMI impact the future payments ?
                      </div>
                      <div className={styles['skip-emi-radio-button-wrapper']}>
                        <Card className={styles['skip-emi-radio-button']}>
                          <RadioButton
                            isDisabled={isLoading}
                            isSelected={requestData.option === SkipEmiOption.INCREASE_TENURE}
                            onSelect={() => handleSelectedOption(SkipEmiOption.INCREASE_TENURE)}>
                            Increase Number of EMIs
                          </RadioButton>
                        </Card>
                        <Card className={styles['skip-emi-radio-button']}>
                          <RadioButton
                            isDisabled={isLoading}
                            isSelected={requestData.option === SkipEmiOption.INCREASE_AMOUNT}
                            onSelect={() => handleSelectedOption(SkipEmiOption.INCREASE_AMOUNT)}>
                            Increase EMI amount
                          </RadioButton>
                        </Card>
                      </div>
                      <p className={styles['recovery-preview-text']}>Preview changes</p>
                    </>
                  );
                }}
              </LoanRecoveryPreviewTable>
            )}
          </div>
          {loanId && requestData && requestData.payroll_month && !previewError && (
            <StandardButton
              className={styles['confirm-button']}
              onClick={function () {
                openModal(
                  <LoanConfirmChanges
                    content={emiAmountContent}
                    requestData={requestData}
                    loanId={parseInt(loanId)}
                    handleErrorOnConfirm={handleErrorOnConfirm}
                  />,
                );
              }}>
              Confirm
            </StandardButton>
          )}
        </>
      )}
    </div>
  );
};

export default SkipEmi;
