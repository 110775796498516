import { routePaths } from 'components/Routes/data';

export const complianceSteps = {
  STEP_TDS: '1',
  STEP_PT: '2',
  STEP_PF: '3',
  STEP_ESIC: '4',
};

export const routeToStepMap: { [key: string]: string } = {
  [routePaths.companyOnboarding.compliance.root]: complianceSteps.STEP_TDS,
  [routePaths.companyOnboarding.compliance.tds]: complianceSteps.STEP_TDS,
  [routePaths.companyOnboarding.compliance.pt]: complianceSteps.STEP_PT,
  [routePaths.companyOnboarding.compliance.pf]: complianceSteps.STEP_PF,
  [routePaths.companyOnboarding.compliance.esic]: complianceSteps.STEP_ESIC,
};

export const stepToRouteMap: { [key: string]: string } = {
  [complianceSteps.STEP_TDS]: routePaths.companyOnboarding.compliance.tds,
  [complianceSteps.STEP_PT]: routePaths.companyOnboarding.compliance.pt,
  [complianceSteps.STEP_PF]: routePaths.companyOnboarding.compliance.pf,
  [complianceSteps.STEP_ESIC]: routePaths.companyOnboarding.compliance.esic,
};
