import React from 'react';
import { EarningsListView, EarningsDetailView, EarningsFilters } from './index';
import { Box } from '@razorpay/blade/components';

const EarningsView = () => {
  return (
    <>
      <EarningsFilters />
      <Box paddingX="spacing.8">
        <EarningsListView />
        <EarningsDetailView />
      </Box>
    </>
  );
};

export default EarningsView;
