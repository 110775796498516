import React from 'react';
import { FormProvider } from 'forms';
import CreateEarningsForm from './CreateEarningsForm';
import KnowMoreView from '../KnowMoreView/KnowMoreView';

const CreateEarningsView = () => {
  return (
    <FormProvider>
      <CreateEarningsForm />
      <KnowMoreView />
    </FormProvider>
  );
};

export default CreateEarningsView;
