import {
  Box,
  Button,
  CalendarIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import { useFilters } from '../useFilters';
import DatePicker from 'components/ui/DatePickerBlade/DatePicker';
import { WorkflowRequestsFiltersMap } from '../constants';

export interface DateRange {
  from: Date | null;
  to: Date | null;
}

export interface DatePickerProps {
  isDisabled?: boolean;
}

const DateFilter = ({ isDisabled }: DatePickerProps) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: new Date(),
    to: new Date(),
  });
  const [isOpen, setIsOpen] = useState(false);
  const { applyFilter } = useFilters();
  const from = dateRange?.from;
  const to = dateRange?.to;

  const disableApplyCta = !from || !to;

  const handleCtaClick = () => {
    setIsOpen(true);
  };

  const handleFromChange = (from: Date | null) => {
    setDateRange({
      ...dateRange,
      from,
    });
  };

  const handleToChange = (to: Date | null) => {
    setDateRange({
      ...dateRange,
      to,
    });
  };

  const handleApplyClick = () => {
    const { from, to } = dateRange;
    if (from) {
      applyFilter(WorkflowRequestsFiltersMap.fromDate, from.toString());
    }

    if (to) {
      applyFilter(WorkflowRequestsFiltersMap.toDate, to.toString());
    }

    setIsOpen(false);
  };

  return (<>
    <Button
      variant="tertiary"
      icon={CalendarIcon}
      iconPosition="left"
      isDisabled={isDisabled}
      onClick={handleCtaClick}>
      All Time
    </Button>
    <Modal
      size="medium"
      isOpen={isOpen}
      onDismiss={() => {
        setIsOpen(false);
      }}>
      <ModalHeader title="Select dates" />
      <ModalBody>
        <Box display="flex" gap="spacing.5" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="spacing.4">
            <Text size="medium" weight="semibold">
              Start date
            </Text>
            <DatePicker value={dateRange.from} onChange={handleFromChange} />
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.4">
            <Text size="medium" weight="semibold">
              End date
            </Text>
            <DatePicker value={dateRange.to} onChange={handleToChange} />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button onClick={handleApplyClick} isDisabled={disableApplyCta}>
            Apply
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  </>);
};

export { DateFilter };
