import { addMonths, format, parse } from 'date-fns';
import pluralize from 'pluralize';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import { dateFormats } from 'utils/Dates';
import { payrollCutoffDate } from '../../../constants/config';
import { arrearTypes } from '../constants';
import { ArrearComponent, RunPayrollData, orderValues } from './types';

export const getNextOrder = (currentOrder: string | null) => {
  const idx = orderValues.findIndex((order) => order === currentOrder);
  return orderValues[(idx + 1) % orderValues.length];
};

export const getUserName = (data: RunPayrollData) => {
  let name = '';
  if (data.name) {
    name = data.name;
  } else if (data.email) {
    name = data.email;
  } else {
    name = 'Unknown';
  }
  // Restore the &
  name = name.replaceAll('&amp;', '&');
  return name;
};

export const getCurrentPayrollMonth = () => {
  let payrollMonth = new Date();
  if ((payrollMonth.getDate() as number) <= payrollCutoffDate) {
    payrollMonth.setMonth(payrollMonth.getMonth());
  }
  let firstOfTheMonth = new Date(payrollMonth.getFullYear(), payrollMonth.getMonth(), 1);
  payrollMonth = firstOfTheMonth;
  payrollMonth.setHours(0, 0, 0, 0);
  return payrollMonth;
};

export const getArrearSumFromBreakdown = (
  breakdown: GetArrearsSchemaContract['arrear_breakdown'],
) => {
  let arrearSum: { [key: string]: ArrearComponent } = {};

  if (breakdown) {
    const arrearMonths = Object.keys(breakdown);
    arrearMonths.forEach((key: string) => {
      const arrearForMonth = breakdown[key];
      arrearForMonth.forEach((arrearEntry) => {
        const mapKey = arrearEntry.name + arrearEntry.type;
        if (arrearSum[mapKey]) {
          arrearSum[mapKey].amount += arrearEntry.amount;
        } else {
          arrearSum[mapKey] = {
            name: arrearEntry.name,
            type: arrearEntry.type,
            amount: arrearEntry.amount,
          };
        }
      });
    });
    return Object.values(arrearSum);
  }

  return null;
};

export const getArrearWithName = (
  arrearsDistribution: {
    name: string;
    value: number;
  }[],
  name: string,
) => {
  return arrearsDistribution.find((arrear) => arrear.name === name);
};

export const getArrearWithNameFromDistribution = (
  name: string,
  arrearsDistribution?: RunPayrollData['arrearsDistribution'],
) => {
  return arrearsDistribution?.find((arrear) => arrear.name === name);
};

export const getNextMonth = (month: string) => {
  const parsedMonth = parse(month, dateFormats.yearMonthDate, new Date());
  return format(addMonths(parsedMonth, 1), dateFormats.yearMonthDate);
};

export const getPrevMonth = (dateString: string) => {
  const parsedMonth = parse(dateString, dateFormats.yearMonthDate, new Date());
  return format(addMonths(parsedMonth, -1), dateFormats.yearMonthDate);
};

export const combineArrears = (arrears: ArrearComponent[]) => {
  const map = new Map<string, ArrearComponent>();
  for (const arrear of arrears) {
    const key = arrear.name + arrear.type;
    const component = map.get(key);
    if (component) {
      component.amount += arrear.amount;
    } else {
      map.set(key, arrear);
    }
  }
  return Array.from(map.values());
};

export const getArrearsToDisplay = (
  data: RunPayrollData,
  isRlopFeatureEnabled: boolean,
  isNewJoinerArrearFeatureEnabled: boolean
) => {
  const salaryRevisionArrears = getArrearWithName(
    data.arrearsDistribution ?? [],
    arrearTypes.salary_revision,
  );
  const arrears: ArrearComponent[] = [];

  if (isRlopFeatureEnabled || isNewJoinerArrearFeatureEnabled) {
    
    if (salaryRevisionArrears && salaryRevisionArrears.value > 0) {
      arrears.push({
        name: 'Salary Revision arrears',
        amount: salaryRevisionArrears.value,
        type: '',
      });
    }

    if (isRlopFeatureEnabled) {
      const rlopArrears = getArrearWithName(data.arrearsDistribution ?? [], arrearTypes.rlop);
      if (rlopArrears && rlopArrears.value > 0) {
        arrears.push({
          name: 'Reverse LOP arrears',
          amount: rlopArrears.value,
          type: ''
        });
      }
    }

    if (isNewJoinerArrearFeatureEnabled) {
      const newJoinerArrears = getArrearWithName(
        data.arrearsDistribution ?? [],
        arrearTypes.new_joiner
      );

      if (newJoinerArrears && data.newJoinerInfo && newJoinerArrears.value > 0) {
        arrears.push({
          name: `New Joiner arrears (${data.newJoinerInfo.arrearDays + pluralize(' day', data.newJoinerInfo.arrearDays)})`,
          amount: newJoinerArrears.value,
          type: ''
        });
      }
    }

    const complianceDistribution = data.complianceDistribution ?? [];
    for (const complianceArrear of complianceDistribution) {
      if (complianceArrear.value > 0) {
        arrears.push({
          name: `${complianceArrear.name} arrears`,
          amount: complianceArrear.value,
          type: '',
        });
      }
    }
  }
  else {
    if (data.arrearsSum) {
      arrears.push(data.arrearsSum);
    }
  }
  return arrears;
};

export const newJoinerArrearExistsInEntry = (payrollEntry: RunPayrollData) => {
  const newJoinerArrears = getArrearWithName(
    (payrollEntry && payrollEntry.arrearsDistribution) ? payrollEntry.arrearsDistribution : [],
    arrearTypes.new_joiner
  );

  return (newJoinerArrears ? newJoinerArrears.value > 0 : false);
}

export const getStatusPillTooltipContent = (entry: RunPayrollData, newJoinerArrearPaymentMonthExists: boolean,) => {
  if (newJoinerArrearPaymentMonthExists && entry.newJoinerArrearPaymentMonth) {
    const parsedDate = parse(
      entry.newJoinerArrearPaymentMonth,
      dateFormats.yearMonthDate,
      (new Date())
    );
    return `Employee joined post new joiner cut-off date. Prorated salary will be paid in
  ${format(parsedDate,dateFormats.monthYear)} payroll as arrears.`
  }
  else {
    return ''
  };
}

export const formatPayrollMonth = (month: string, dateFormat: string) => {
  return format(parse(month, dateFormats.yearMonthDate, new Date()), dateFormat);
};

export const getNextPayrollMonth = (month: string) => {
  const parsedMonth = parse(month, dateFormats.yearMonthDate, new Date());
  return addMonths(parsedMonth, 1);
};

export const shouldShowPfWarning = (payrollMonth: string) => {
  // Should return true if the payroll month is August 2024 or later
  const parsedMonth = parse(payrollMonth, dateFormats.yearMonthDate, new Date());
  return parsedMonth >= new Date(2024, 7, 1);
};