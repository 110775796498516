import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import { getFingerPrintRegistration } from 'utils/links';
import { useTodaysAttendanceData } from './hooks/useTodaysAttendanceData';
import useSplitz from 'hooks/useSplitz';
import { isSplitzExperimentActive } from 'utils/splitz';
import { splitzExperiments } from 'constants/splitz';
import styled from 'styled-components';
import permissions from 'constants/permissions';
import useHasPermission from 'utils/useHasPermission';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';
import { ATTENDANCE_STATUS } from './constants';

// temporary components till design is ready
const CardFromV1 = styled.div(
  ({ theme }) => `
    border: ${theme.border.width.thin}px solid ${theme.colors.surface.border.gray.subtle};
    padding: ${theme.spacing[4]}px;
    background: ${theme.colors.surface.background.gray.intense};
    border-radius: ${theme.border.radius.medium}px;
    &:hover {
      border-color: ${theme.colors.surface.border.primary.normal};
    }
    max-width: 300px;
    @media (max-width: ${theme.breakpoints.m}px) {
      max-width: 100%;
    }
  `,
);

const SideBarCard = ({
  header,
  description,
  href,
  accessibilityLabel,
}: {
  header: string;
  description: string;
  href: string;
  accessibilityLabel: string;
}) => {
  return (
    <a href={href} aria-label={accessibilityLabel}>
      <CardFromV1>
        <Heading as="h3" size="small" color="surface.text.gray.subtle">
          {header}
        </Heading>
        <Text size="small" color="surface.text.gray.subtle" marginTop="spacing.4">
          {description}
        </Text>
      </CardFromV1>
    </a>
  );
};

export function AttendanceSidebar() {
  const [isLeavesTakenModalOpen, setIsLeavesTakenModalOpen] = useState(false);
  const splitzQuery = useSplitz();
  const isBladeMigrationPhase1Enabled = isSplitzExperimentActive(
    splitzQuery.data,
    splitzExperiments.bladeMigrationPhase1,
  );
  const todaysData = useTodaysAttendanceData({ enabled: isBladeMigrationPhase1Enabled });
  const hasAttendanceApprovalPermission = useHasPermission([permissions.ATTENDANCE_APPROVAL]);
  const leaveAttendanceConfig = useReduxSelector(loggedInUserSelectors.leaveAttendanceConfig);

  // we have a loader in the parent already
  if (todaysData.isLoading) {
    return null;
  }

  return (
    <Box
      width={{ base: '100%', l: '25%' }}
      backgroundColor={{ base: 'transparent', l: 'surface.background.gray.moderate' }}
      padding="spacing.6">
      {todaysData.data?.has_biometric && hasAttendanceApprovalPermission && (
        <SideBarCard
          accessibilityLabel={`Add fingerprint for ${todaysData.data?.employee_name}`}
          href={getFingerPrintRegistration(todaysData.userId ? todaysData.userId : undefined)}
          header="Add fingerprint"
          description={`Record ${todaysData.data?.employee_name}'s fingerprint so they are able to use your biometric device.`}
        />
      )}
      {leaveAttendanceConfig?.typesOfLeaves &&
        leaveAttendanceConfig.typesOfLeaves.length > 0 &&
        todaysData.data?.leave_balance && (
          <Box>
            <Heading as="h2" size="medium" color="surface.text.gray.subtle" marginTop="spacing.8">
              Your leave balance
            </Heading>
            <Box display="flex" flexDirection="column" gap="spacing.4" marginTop="spacing.4">
              {Object.values(todaysData.data.leave_balance).map((leave) => {
                return (
                  <Box key={leave.leave_description}>
                    <Text color="surface.text.gray.muted">{leave.leave_description}</Text>
                    <Text color="surface.text.gray.subtle">
                      {leave.leave_balance} / {leave.leave_total}
                    </Text>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      <Box marginTop={'spacing.8'}>
        <Button onClick={() => setIsLeavesTakenModalOpen(true)}>View leaves taken</Button>
        <Modal
          isOpen={isLeavesTakenModalOpen}
          onDismiss={() => setIsLeavesTakenModalOpen(false)}
          size="small">
          <ModalHeader title="Leaves taken" />
          <ModalBody>
            <Box>
              {Object.values(todaysData.data?.leaves_taken ?? {}).map((leave) => {
                return (
                  <Box key={leave.leave_description} marginBottom="spacing.4">
                    <Text
                      weight="semibold"
                      size="large"
                      color="surface.text.gray.subtle"
                      marginBottom="spacing.4">
                      {leave.leave_description}
                    </Text>
                    {leave.leaves.map((leaveEntry) => {
                      return (
                        <Box key={leaveEntry.date.date}>
                          <Text color="surface.text.gray.subtle">
                            {getFormattedDateValue({
                              date: leaveEntry.date.date.substring(0, 10),
                              formatString: dateFormats.dateMonthFullYearWithSlash,
                            })}
                            {leaveEntry.status === ATTENDANCE_STATUS.STATUS_HALF_DAY
                              ? ' (Half Day)'
                              : ''}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </ModalBody>
        </Modal>
      </Box>
    </Box>
  );
}
