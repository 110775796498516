import React, { ReactElement } from 'react';
import { Card } from '../../ui/Card/index';
import ErrorMessage from '../../ui/ErrorMessage/index';
import { StandardSecondaryButton } from '../../ui/Button/index';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';

export default function ErrorCard( {error,buttonText,onClick, showErrors=true } : {error: typeof AppError; buttonText : string; onClick : () => void, showErrors?: boolean } ) : ReactElement {

    return (
        <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
          <ErrorMessage title="Sorry! An error has occured!">
            {showErrors && <Errors errorResponse={error} />}
            <StandardSecondaryButton
            className='mt-2'
              children={buttonText}
              onClick={onClick}
            />
          </ErrorMessage>
        </Card>
      );
}