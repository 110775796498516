export const arrearTypes = {
  platform: 'platform',
  rlop: 'rlop',
  salary_revision: 'salary_revision',
  pf_arrears: 'PF Employer Contribution',
  esi_arrears: 'ESI Employer Contribution',
  new_joiner: 'new_joiner',
};

export const nonEditableArrearComponents = [
  'ESI Employer Contribution',
  'PF Employer Contribution',
];

export const reverseLopDocumentationLink =
  'https://razorpay.com/docs/x/xpayroll/employers/salary/run-payroll/#how-to-pay-lop-reversal-arrears';

export const pfPaymentsDocumentationLink =
  'https://razorpay.com/docs/payroll/provident-fund/#make-pf-payments-on-epfo';

export const ALLOWANCES_LIMIT = 10;

// TODO: Add link here once available
export const pastPayrollExecutionHelpLink = 'https://razorpay.com/docs/payroll/tds/form-24Q/';
