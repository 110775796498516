import { Box, Divider, Text } from '@razorpay/blade/components';
import { useWorkflowDetailsView } from 'components/ApprovalWorkflows/MyRequests/useWorkflowDetailsView';
import React from 'react';
import { useQueryClient } from 'react-query';
import Timeline from '../Timeline';
import { useWorkflowRequestDetails } from '../useWorkflowRequestDetails';
import { DetailsViewWithdraw } from '../../DetailsViewWithdraw';
import { invalidateEditPayrollCache } from 'components/ApprovalWorkflows/MyRequests/EditPayroll/utils';
import { EditPayrollDetails } from '../../EditPayrollDetails';
import { WorkflowRequestsStatusMap } from 'components/ApprovalWorkflows/MyRequests/constants';
import { ErrorResultsTableView } from '../../ErrorResultsTableView';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const InitiatedByMe = () => {
  const queryClient = useQueryClient();
  const { closeDetailsView, workflowRequestId } = useWorkflowDetailsView();
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];
  const { data, isLoading, isFetching, refetch, isError } = useWorkflowRequestDetails({
    requestId: Number(workflowRequestId),
    isGraphQLEnabled,
  });

  const isTimelineLoading = isLoading || isFetching;

  const showWithdrawCta = data?.workflow_requests_data.status === WorkflowRequestsStatusMap.pending;

  const onWithdraw = () => {
    closeDetailsView();
    invalidateEditPayrollCache(queryClient);
  };

  if (isError) {
    return (
      <ErrorResultsTableView
        onRetry={refetch}
        description={<Box textAlign="center">Couldn’t fetch request details.</Box>}
        isRetrying={isFetching}
      />
    );
  }

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.7">
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text weight="semibold" color="surface.text.gray.muted">
          Initiated
        </Text>
        <Text variant="caption" color="surface.text.gray.disabled" size='small'>
          Edit Payroll
        </Text>
      </Box>
      {showWithdrawCta && (
        <DetailsViewWithdraw requestId={Number(workflowRequestId)} withdrawCallback={onWithdraw} />
      )}
      <Timeline workflowRequestDetails={data} isLoading={isTimelineLoading} />
      <Divider width="100%" />
      <EditPayrollDetails workflowRequestDetails={data} isLoading={isTimelineLoading} />
    </Box>)
  );
};

export { InitiatedByMe };
