import { Box } from '@razorpay/blade/components';
import FilterTag from 'components/ui/FilterTag/FilterTag';
import { useFilters } from 'hooks/useFilters';
import React from 'react';

interface FilterTagsProps {
  filtersMap: Record<string, string>;
  filtersLabelMap: Record<string, string>;
}

const FilterTags = ({ filtersMap, filtersLabelMap }: FilterTagsProps) => {
  const { currentFilters, removeFilter } = useFilters({ filtersMap });

  const isAnyFilterApplied = Object.keys(currentFilters).length > 0;

  if (!isAnyFilterApplied) {
    return null;
  }

  return (
    <Box
      marginTop="spacing.7"
      display="flex"
      gap="spacing.5"
      overflowX="auto"
      flexWrap="wrap"
      paddingX="spacing.7">
      {Object.entries(currentFilters).map(([key, value]) => {
        return (
          <FilterTag
            key={key}
            name={filtersLabelMap[key]}
            value={value}
            onDismiss={() => removeFilter(key)}
          />
        );
      })}
    </Box>
  );
};

export default FilterTags;
