import { Box, Divider, ExternalLinkIcon, Link, Text } from '@razorpay/blade/components';
import { useWorkflowDetailsView } from 'components/ApprovalWorkflows/MyRequests/useWorkflowDetailsView';
import React from 'react';
import { DetailsViewApproveReject } from '../../DetailsViewApproveReject';
import Timeline from '../Timeline';
import { useWorkflowRequestDetails } from '../useWorkflowRequestDetails';
import { useQueryClient } from 'react-query';
import { invalidateFinalizePayrollCache } from 'components/ApprovalWorkflows/MyRequests/FinalizePayroll/utils';
import { FinalizePayrollDetails } from '../../FinalizePayrollDetails';
import { WorkflowRequestsStatusMap } from 'components/ApprovalWorkflows/MyRequests/constants';
import { format } from 'date-fns';
import { ErrorResultsTableView } from '../../ErrorResultsTableView';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const PendingApproval = () => {
  const queryClient = useQueryClient();
  const { closeDetailsView, workflowRequestId } = useWorkflowDetailsView();
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];
  const { data, isLoading, isFetching, isError, refetch } = useWorkflowRequestDetails({
    requestId: Number(workflowRequestId),
    isGraphQLEnabled,
  });
  const isTimelineLoading = isLoading || isFetching;

  const showApproveRejectCtas =
    data?.workflow_requests_data.status === WorkflowRequestsStatusMap.pending;

  const payrollMonth = (data?.workflow_requests_data.payload as any)?.payroll_month;

  const onApproveReject = () => {
    closeDetailsView();
    invalidateFinalizePayrollCache(queryClient);
  };

  if (isError) {
    return (
      <ErrorResultsTableView
        onRetry={refetch}
        description={<Box textAlign="center">Couldn’t fetch request details.</Box>}
        isRetrying={isFetching}
      />
    );
  }

  return (
    (<Box display="flex" flexDirection="column" gap="spacing.7">
      <Box display="flex" flexDirection="column" gap="spacing.4">
        <Box display="flex" gap="spacing.2" alignItems="baseline">
          <Text weight="semibold" color="surface.text.gray.muted">
            Approval
          </Text>
          <Text variant="caption" color="surface.text.gray.disabled" size='small'>
            Finalize Payroll
          </Text>
        </Box>
        {payrollMonth && (
          <Link
            href={`/reports/salaryRegister?pd=${format(
              new Date(payrollMonth),
              'dd-MM-yy',
            )}&show_skipped=false&show_only_pending=true`}
            target="_blank"
            icon={ExternalLinkIcon}
            iconPosition="right">
            Salary Register
          </Link>
        )}
      </Box>
      {showApproveRejectCtas && (
        <DetailsViewApproveReject
          requestId={Number(workflowRequestId)}
          approveCallback={onApproveReject}
          rejectCallback={onApproveReject}
        />
      )}
      <Timeline workflowRequestDetails={data} isLoading={isTimelineLoading} />
      <Divider width="100%" />
      <FinalizePayrollDetails workflowRequestDetails={data} isLoading={isTimelineLoading} />
    </Box>)
  );
};

export { PendingApproval };
