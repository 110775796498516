import { Alert, Box, Button, Heading, Link, Spinner, Text } from '@razorpay/blade/components';
import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import React from 'react';
import { ActionCard } from './components/ActionCard';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import { showApiErrorNotification } from 'utils/Api';
import { useGetKnitHrmsIntegrationQuery } from 'components/Knit/KnitHrms/useGetKnitHrmsIntegrationQuery';
import { format, minutesToHours } from 'date-fns';
import { SyncInProgressLazy } from 'components/Knit/KnitHrms/components/SyncInProgressLazy';
import { ErrorWithRetry } from 'components/ui/ErrorWithRetry';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { hrmsAppManageContent } from './constants';

const Manage = () => {
  const history = useHistory();
  const { knitHrmsApp, hrmsAppMetadata, hrmsAppRoutePaths } = useKnitHrmsApp();
  const {
    knitHrmsIntegrationQuery,
    isIntegrationActive,
    isSyncInProgress,
    isSyncFailed,
    retryLimitExhausted,
    invalidateKnitHrmsIntegrationQueryCache,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);
  const manageAppContent = hrmsAppManageContent[knitHrmsApp];

  const initiateHrmsSyncMutation = useMutation({
    mutationFn: api.knit.initiateHrmsSync,
    onSuccess: async (data) => {
      if (data.success) {
        await invalidateKnitHrmsIntegrationQueryCache();

        showToastViaEvent({
          text: 'Sync initiated successfully',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }
    },
    onError: (error) => {
      showApiErrorNotification(error);
    },
  });

  if (knitHrmsIntegrationQuery.isSuccess && !isIntegrationActive) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  if (knitHrmsIntegrationQuery.isLoading || knitHrmsIntegrationQuery.isFetching) {
    // @TODO - Replace this with the skeleton loader once designs are ready
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner accessibilityLabel="loading" />
      </Box>
    );
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <ErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={retryLimitExhausted}
      />
    );
  }

  const handleModifyConfigurationClick = () => {
    history.push(hrmsAppRoutePaths.settings.root, {
      totalSteps: 4,
      currentStep: 1,
    });
  };

  const handleSyncNowClick = () => {
    initiateHrmsSyncMutation.mutate({
      sync_type: 'delta_sync',
      hrms_provider: knitHrmsApp,
    });
  };

  const handleDisableIntegrationClick = () => {
    history.push(hrmsAppRoutePaths.settings.disableIntegration);
  };

  const syncFrequencyInHours = knitHrmsIntegrationQuery.data
    ? minutesToHours(knitHrmsIntegrationQuery.data.sync_frequency.duration)
    : '';
  const lastSyncAtTime = knitHrmsIntegrationQuery.data?.last_synced_at
    ? format(new Date(knitHrmsIntegrationQuery.data?.last_synced_at), 'h:m a')
    : '';

  return (
    (<PageWrapper>
      <Breadcrumb
        name={hrmsAppMetadata.name}
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase type="fullWidth">
        <Box
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          backgroundColor="surface.background.gray.intense"
          padding={['spacing.10', '0px']}>
          <Box display="flex" flexDirection="column" alignItems="center" maxWidth="592px">
            {isSyncFailed && (
              <Alert
                color="negative"
                title="Employee sync failed"
                description="The employee sync failed due to an unexpected error. Please retry syncing employees again"
                marginBottom="spacing.11"
              />
            )}
            <Box width="258px" height="208px" marginBottom="spacing.7">
              {isSyncInProgress ? (
                <SyncInProgressLazy knitHrmsApp={knitHrmsApp} />
              ) : (
                <img src={manageAppContent.integrationCompleteLogo} alt="" />
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap="spacing.4">
              <Box display="flex" flexDirection="column" gap="spacing.2">
                <Heading textAlign="center" size="medium">
                  {isSyncInProgress
                    ? `Syncing data from ${hrmsAppMetadata.name} to RazorpayX Payroll`
                    : `${hrmsAppMetadata.name} integration is active`}
                </Heading>
                {knitHrmsIntegrationQuery.data?.entity_name && (
                  <Box display="flex" gap="spacing.2" justifyContent="center">
                    <Text>Entity synced:</Text>
                    <Text weight="semibold">{knitHrmsIntegrationQuery.data?.entity_name}</Text>
                  </Box>
                )}
              </Box>
              <Text
                textAlign="center"
                marginTop={knitHrmsIntegrationQuery.data?.entity_name ? 'spacing.5' : 'none'}>
                {lastSyncAtTime ? `Last sync at ${lastSyncAtTime} | ` : ''}Sync every{' '}
                {syncFrequencyInHours} hours
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" gap="spacing.7" marginTop="spacing.11">
              <ActionCard
                title={`Updated employee details in ${hrmsAppMetadata.name}?`}
                key="sync-now">
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box maxWidth="345px">
                    <Text color="surface.text.gray.muted">
                      If you have updated information in {hrmsAppMetadata.name}, you can do a manual
                      sync to immediately sync information.
                    </Text>
                  </Box>
                  <Button
                    variant="tertiary"
                    isDisabled={isSyncInProgress}
                    onClick={handleSyncNowClick}
                    isLoading={initiateHrmsSyncMutation.isLoading}>
                    Sync now
                  </Button>
                </Box>
              </ActionCard>
              <ActionCard title="Manage data synced and mapping configuration" key="modify-config">
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box maxWidth="345px">
                    <Text color="surface.text.gray.muted">
                      Modify {hrmsAppMetadata.name} integration setup if you want to manage fields
                      sync or manage employee type mapping
                    </Text>
                  </Box>
                  <Button
                    variant="tertiary"
                    isDisabled={isSyncInProgress}
                    onClick={handleModifyConfigurationClick}>
                    Modify configuration
                  </Button>
                </Box>
              </ActionCard>
              <ActionCard title="Disable integration" key="modify-config">
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box maxWidth="345px">
                    <Text color="surface.text.gray.muted">
                      Please note after disabling, employee information will not sync from{' '}
                      {hrmsAppMetadata.name}
                      to RazorpayX Payroll.
                    </Text>
                  </Box>
                  <Button
                    color="negative"
                    variant="secondary"
                    isDisabled={isSyncInProgress}
                    onClick={handleDisableIntegrationClick}>
                    Disable
                  </Button>
                </Box>
              </ActionCard>
            </Box>
            <Box display="flex" justifyContent="center" marginTop="spacing.9">
              <Link>Learn more about {hrmsAppMetadata.name} integration</Link>
            </Box>
          </Box>
        </Box>
      </MainCase>
    </PageWrapper>)
  );
};

export { Manage };
