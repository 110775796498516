// Knit category IDs
export const KnitCategories = {
  hris: 'HRIS',
} as const;

// Knit app IDs
export const KnitApps = {
  darwinbox: 'darwinbox',
  hibob: 'hibob',
  worq: 'worq',
  workline: 'workline',
} as const;

export const KnitAppsMetadata = {
  [KnitApps.darwinbox]: {
    name: 'Darwinbox',
  },
  [KnitApps.hibob]: {
    name: 'HiBob',
  },
  [KnitApps.worq]: {
    name: 'Worq',
  },
  [KnitApps.workline]: {
    name: 'Workline',
  }
};

export const KnitHrmsAppsFeatureFlags = {
  [KnitApps.darwinbox]: 'darwinboxKnitIntegration',
  [KnitApps.hibob]: 'hibobKnitIntegration',
  [KnitApps.worq]: 'worqKnitIntegration',
  [KnitApps.workline]: 'worklineKnitIntegration',
} as const;