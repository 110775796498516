import React, { useEffect, useState } from 'react';
import { OTPInput } from '@razorpay/blade/components';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import api from 'api';
import otpIcon from 'assets/verify-otp.png';
import { getStaticMediaUrl } from 'utils/Urls';
import { LinkButtonV2, PrimaryButton, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import OtpTimer from '../OtpTimer';
import { showToastViaEvent } from 'utils/ToastEvents';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import Spinner from 'components/ui/Spinner';
import { useModal } from 'components/ui/Modals';
import ResetOtpMethod from './ResetOtpMethod';
import { OTP_ACTION_LOGIN } from '../constants';

import styles from './index.module.scss';
import { SUPPORT_EMAIL } from 'constants/companyContacts';
import { OTP_REGEX } from 'utils/regexes';

export const FETCH_OTP_DATA = 'FETCH_OTP_DATA';

const VerifyOtp = () => {
  const [otp, setOtp] = useState<string>('');
  const queryParam = new URLSearchParams(useLocation().search);
  const redirect = queryParam.get('redirect');
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();

  const [isResendLoading, setResendLoading] = useState<boolean>(false);

  const { isLoading, data } = useQuery(
    FETCH_OTP_DATA,
    () => {
      return api.login.getUserData();
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );

  const verify = useMutation(
    (otp: string) => {
      return api.login.verifyOtp(otp);
    },
    {
      onSuccess: () => {
        window.location.href = redirect ? redirect : '/dashboard';
      },
      onError: (error: typeof AppError) => {
        const response = getAppErrorDetails(error);

        if(response.code == 'OTP_LIMIT_EXCEEDED') {
          showToastViaEvent({
            text: response.messageForUser,
            timeout: 5000,
            type: 'danger',
            icon: 'info',
          });
        }
      },
    },
  );

  const resend = (callback: () => void) => {
    verify.reset();
    setResendLoading(true);
    setOtp("");
    api.login
      .resendOtp(OTP_ACTION_LOGIN)
      .then((res) => {
        callback();
        showToastViaEvent({
          text: 'OTP resent to your email. Please check your inbox for the new OTP.',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }).catch((error: typeof AppError) => {
        const response = getAppErrorDetails(error);
        showToastViaEvent({
          text: response.messageForUser,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }) 
      .finally(() => {
        setResendLoading(false);
      });
  };

  const onOtpChange = (event: any) => {
    const enteredOtp = event.value;

    if (OTP_REGEX.test(enteredOtp)) {
      setOtp(enteredOtp);
    } else {
      setOtp('');
    }
  };

  const openRevertModal = () => {
    openModal(
      <ResetOtpMethod />
    )
  }

  if (isLoading) {
    return (
        <div className={styles['otp-page']}>
            <Spinner size={40} />
        </div>
    );
  }

  return (
    <>
      <div className="login-bg" style={{ zIndex: 1 }}></div>

      <form className={styles['otp-page']} onSubmit={(e) => {
          e.preventDefault();
          verify.mutate(otp);
        }}>
        <div className={styles['otp-component']}>
          <img src={getStaticMediaUrl(otpIcon)} alt="" width="120px" />
          <div className={styles['otp-title']}>
            {
              data?.is_authenticator_enabled 
                ? 'Authentication code'
                : 'Enter OTP for verification'
            }
          </div>
          <div className={styles['otp-sub-title']}>
            {
              data?.is_authenticator_enabled 
                ? 'Open the two-factor authenticator app on your mobile device to view the authentication code'
                : `One Time Password sent successfully to ${data?.email}`
            }
          </div>
          <OTPInput
            label=""
            errorText={`The ${!data?.is_authenticator_enabled 
              ? 'OTP'
              : 'authentication code'} entered is incorrect. Please try again.`}
            name="otp"
            value={otp}
            onChange={onOtpChange}
            otpLength={6}
            placeholder=""
            validationState={getAppErrorDetails(verify.error).code == 'OTP_VERIFICATION_ERROR' ? 'error' : 'none'}
          />
          <div className="flex-1"></div>
          <div className="flex items-center mb-10">
            {!data?.is_authenticator_enabled ? (<>
              <div className={styles['otp-time-text']}> Did not receive OTP yet?</div>
              <OtpTimer duration={30} format="mmss" isVisible={true}>
                {({ timerDone, resetTimer }: { timerDone: boolean; resetTimer: () => void }) => (
                  <div>
                    {timerDone ? (
                      <LinkButtonV2
                        onClick={() => {
                          resend(resetTimer);
                        }}
                        disabled={isResendLoading}>
                        Resend
                      </LinkButtonV2>
                    ) : (
                      <div style={{ fontSize: 15, color: 'rgba(255, 255, 255, 0.38)' }}>
                        Resend in
                      </div>
                    )}
                  </div>
                )}
              </OtpTimer>
              </>)
              : (<>
              <div className={styles['otp-time-text']}> Having problems?</div>
              <LinkButtonV2
                onClick={openRevertModal}
                >
                Get OTP via Email
              </LinkButtonV2>
              </>)
            }
          </div>
          <PrimaryButton
            className={'w-full mb-12'}
            disabled={!otp}
            type="submit"
            isPending={verify.isLoading || verify.isSuccess}
            >
            Verify
          </PrimaryButton>
            {
              !data?.is_authenticator_enabled && 
                <>
                <div className={`${styles['otp-help']} mb-4`}>
                  If the OTP isn't in your inbox, check your spam folder.
                </div>
                <div className={styles['otp-help']}>
                  Can't access this email? 
                  <a target="_blank" href="https://intercom.help/XPayroll/en/articles/9626549-not-able-to-access-email-id" className='ml-2'>
                    <LinkButtonV2>Click here.</LinkButtonV2>
                  </a>
                </div>
                </>
            }
        </div>
        <div className={styles['otp-why']}>
          Need help? Contact
          <a target="_blank" href={`mailto:${SUPPORT_EMAIL}`} className='mx-2'>
            <LinkButtonV2>{SUPPORT_EMAIL}</LinkButtonV2>
          </a>
          | 
          <a target="_blank" href="https://intercom.help/XPayroll/en/articles/9620219-2-factor-authentication" className='ml-2'>
            <LinkButtonV2>Learn about 2FA</LinkButtonV2>
          </a>
        </div>
      </form>
    </>
  );
};

export default VerifyOtp;
