import {
  ActionList,
  ActionListItem,
  Box,
  CalendarIcon,
  Dropdown,
  DropdownOverlay,
  SelectInput,
  Text,
} from '@razorpay/blade/components';
import React from 'react';
import { DeclarationErrors, MonthlyDeclarationType } from '../types';
import { daysList } from 'utils/Dates';

interface MonthDeclarationSelectorProp extends Partial<MonthlyDeclarationType> {
  onChange: (value: string, isStart: boolean) => void;
  isProofUploadWindowSettings?: boolean;
  isLoading: boolean;
  errors?: DeclarationErrors;
}

export const MonthDeclarationSelector = ({
  endDate = 0,
  startDate = 0,
  onChange,
  isProofUploadWindowSettings,
  isLoading,
  errors = {},
}: MonthDeclarationSelectorProp) => {
  const handleMonthChange = (value: string, isStart: boolean = true) => {
    onChange(value, isStart);
  };

  const isMessageVisible = parseInt(endDate.toString()) > 0 && parseInt(endDate.toString()) > 0;

  const getMessage = () => {
    if (!isMessageVisible) return null;
    return `⚡️ ${
      isProofUploadWindowSettings ? 'Proof upload' : 'Declaration'
    } window will be open ${
      startDate === endDate ? 'on ' + startDate : 'from ' + startDate + ' - ' + endDate
    } of every month.`;
  };

  return (
    <Box
      borderWidth="thin"
      borderRadius="medium"
      borderColor="surface.border.gray.muted"
      display="flex"
      flexDirection="column"
      gap="spacing.6"
      marginTop="spacing.7"
      padding={['spacing.5', 'spacing.5', 'spacing.6']}
      backgroundColor="surface.background.gray.moderate">
      <Box
        borderBottomColor="surface.border.gray.subtle"
        borderBottomWidth="thin"
        paddingBottom={'spacing.4'}
        gap="spacing.3"
        display="flex"
        alignItems="center">
        <Box
          backgroundColor="surface.background.gray.intense"
          display="grid"
          borderRadius="medium"
          placeItems="center"
          width="30px"
          height="30px">
          <CalendarIcon margin="auto" color="interactive.icon.information.subtle" size="medium" />
        </Box>
        <Box flex="1">
          <Text weight="semibold">Select the date range for the month</Text>
        </Box>
      </Box>
      <Box maxWidth="340px">
        <Dropdown selectionType="single">
          <SelectInput
            isDisabled={isLoading}
            labelPosition="left"
            isRequired
            value={startDate.toString().padStart(2, '0')}
            label="Month start date"
            placeholder="Select date"
            onChange={({ values }) => handleMonthChange(values[0])}
            validationState={errors.startDate ? 'error' : 'none'}
            errorText={errors.startDate || ''}
          />
          <DropdownOverlay>
            <ActionList>
              {daysList.map((e) => (
                <ActionListItem
                  key={e.toString()}
                  title={e.toString()}
                  value={e.toString().padStart(2, '0')}
                />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        <Dropdown selectionType="single" marginTop="spacing.5">
          <SelectInput
            isDisabled={isLoading}
            labelPosition="left"
            isRequired
            value={endDate.toString().padStart(2, '0')}
            label="Month end date"
            placeholder="Select date"
            onChange={({ values }) => handleMonthChange(values[0], false)}
            validationState={errors.endDate ? 'error' : 'none'}
            errorText={errors.endDate || ''}
          />
          <DropdownOverlay>
            <ActionList>
              {daysList.map((e) => (
                <ActionListItem
                  key={e.toString()}
                  title={e.toString()}
                  value={e.toString().padStart(2, '0')}
                />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
      </Box>
      {isMessageVisible && (
        <Text variant="caption" color="interactive.text.information.subtle" size="small">
          {getMessage()}
        </Text>
      )}
    </Box>
  );
};
