import React from 'react';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import { DeliveryType } from 'constants/flexibenefits';

export function EmployeePersonalDetails({
                                          employeeFlexiBenefitsDetails,
                                          detailsToSync,
                                          setDetailsToSync,
                                          setErrorMessage,
                                          partnerName,
                                        }: {
  employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract,
  detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract,
  setDetailsToSync: (detailsToSync: UpdateUserOrgFlexiPartnerDetailsSchemaContract) => void,
  setErrorMessage: (message: string) => void,
  partnerName: string
}) {

  if (employeeFlexiBenefitsDetails.isCardDetailsSent) {
    return null;
  }
  return <div className={'space-y-5'}>
    <div className={'text-5xl text-white'}>Employee personal details</div>
    <div>The below details will be shared with our partner, {partnerName} for processing the physical card.</div>
    <form>
      
      <label htmlFor='name'>Full name*
        <input type='text' name='name'
               defaultValue={detailsToSync.name ?? ''}
               aria-describedby='Full name'
               onChange={(event) => {
                 setDetailsToSync({
                   ...detailsToSync,
                   name: event.currentTarget.value,
                 });
               }}
               placeholder='Name' />
      </label>
      <label htmlFor='dateOfBirth'>Date of birth*
        <input type='date' name='dateOfBirth'
               defaultValue={detailsToSync.dateOfBirth ?? ''}
               aria-describedby='Date of birth'
               onChange={(event) => {
                 setDetailsToSync({
                   ...detailsToSync,
                   dateOfBirth: event.currentTarget.value,
                 });
               }}
               placeholder='Date of birth' />
      </label>
      <label htmlFor='phoneNumber'>Phone number*
        <input type='text' name='phoneNumber'
               defaultValue={detailsToSync.phoneNumber ?? ''}
               aria-describedby='Phone number'
               onChange={(event) => {
                 let phoneStr = event.currentTarget.value;
                 phoneStr = phoneStr.replace(/^\+91-/, '');
                 phoneStr = phoneStr.replace(/^91-/, '');
                 phoneStr = phoneStr.replace(/^\+91/, '');
                 phoneStr = '+91-' + phoneStr;
                 setDetailsToSync({
                   ...detailsToSync,
                   phoneNumber: phoneStr,
                 });
               }}
               placeholder='Phone number' />
      </label>
      <label htmlFor='pan' className={'prompt--text'}>Pan number*
        <input type='text' name='pan'
               defaultValue={detailsToSync.pan ?? ''}
               aria-describedby='Pan number'
               onChange={(event) => {
                 setDetailsToSync({
                   ...detailsToSync,
                   pan: event.currentTarget.value,
                 });
               }}
               placeholder='Pan number' />
        <small className={'omnipresent'}>
          Your Permanent Account Number (10 characters).
        </small>
      </label>
    </form>
  </div>;
}