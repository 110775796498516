import React, { ReactElement } from 'react';
import styles from './index.module.scss';
import { ProgressBar } from '../../../ui/ProgressBar';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import closeIcon from 'assets/icon--close-grey.svg';

export function HeaderWithProgressBar({
    headerText,
    currentStep,
    totalSteps,
    callbackOnCloseButtonClick = () => {
        window.location.href = '/dashboard';
    }
}: {
    headerText: string;
    currentStep: number;
    totalSteps: number;
    callbackOnCloseButtonClick?:Function
}): ReactElement {

    return (
        <>
            <header className={styles['header']}>
                <span className={styles['header-main-text']}>{headerText}
                    <span className={styles['header-sub-text']}>{`${currentStep.toString()}/${totalSteps.toString()} ⚡️`}</span>
                </span>
                <div>
                    <img className={styles['header-img']} src={getStaticMediaUrl(closeIcon)} onClick={() => {
                        callbackOnCloseButtonClick();
                        }} />
                </div>
            </header>
            <ProgressBar count={currentStep} total={totalSteps} className={'mt-0'}></ProgressBar>
        </>
    );
}