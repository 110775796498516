import { routePaths } from 'components/Routes/data';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { BulkUploadTypes, PreviewMetric, UploadTypeConfig } from 'constants/bulkUpload';
import permissions from 'constants/permissions';
import React from 'react';

const config: UploadTypeConfig = {
  permissions: [permissions.EMPLOYEE_DISMISSAL],
  initiation: {
    feature: 'bulk-fnf',
    permissions: [],
    pageTitle: 'Bulk Full & Final Settlement',
    step1Title: '1. Download the Full & Final Settlement sheet to be updated',
    instructions: [
      "Template is pre-filled with list of employees whose Full & final settlement is initiated. Delete the rows of employees who don't need to settled right now",
      'Fill "No. of days" column only for LOP or Leave Encashment.',
      'Fill "Amount" column only for Addition/Deduction/Leave Encashment',
      'Add label wherever needed and you are done!',
      'The maximum number of rows you can upload is 500',
    ],
  },
  preview: {
    feature: 'bulk-fnf',
    columns: [
      {
        title: 'ID',
        render: (item) => item.file_row?.commonProperties?.employeeId,
      },
      {
        title: 'Name',
        render: (item) => item.file_row?.commonProperties?.employeeName,
      },
      {
        title: 'Email ID',
        render: (item) => item.file_row?.commonProperties?.email,
      },
      {
        title: 'Action',
        render: (item) => {
          const value = (item.file_row?.action || '') as string;
          switch (value) {
            case 'Addition':
            case 'Leave Encashment Amount':
            case 'Gratuity':
              return <Pill content={value} type={pillClassTypeMap.success} />;
            case 'Net Pay Deduction':
            case 'Gross Pay Deduction':
              return <Pill content={value} type={pillClassTypeMap.danger} />;
            case 'Loss Of Pay':
              return <Pill content={value} type={pillClassTypeMap.warning} />;
            case 'Leave Encashment':
              return <Pill content={value} type={pillClassTypeMap.default} />;
            default:
              return <Pill content={value} type={pillClassTypeMap.neutral} />;
          }
        },
      },
      {
        title: 'No. of days',
        render: (item) => item.file_row?.numberOfDays,
      },
      {
        title: 'Amount',
        render: (item) => item.file_row?.amount,
      },
      {
        title: 'Label',
        render: (item) => item.file_row?.label,
      },
      {
        title: 'Gratuity',
        render: (item) => item.file_row?.gratuity,
      },
    ],
    renderConfirmationText: (data) =>
      `Almost done! Please confirm to add Full & final settlement details for the ${data.total_number_of_rows} employees`,
    tableTopView: (bulkUploadRecord) => (
      <div className="flex gap-10 mb-[27px] mt-2 flex-wrap">
        <PreviewMetric
          title="Total additions"
          value={bulkUploadRecord?.file_summary?.numberOfAdditions as unknown as string}
        />
        <PreviewMetric
          title="Total deductions"
          value={bulkUploadRecord?.file_summary?.numberOfDeductions as unknown as string}
        />
        <PreviewMetric
          title="Total loss of pay"
          value={bulkUploadRecord?.file_summary?.numberOfLossOfPay as string}
        />
        <PreviewMetric
          title="Total leave encashment"
          value={bulkUploadRecord?.file_summary?.numberOfLeaveEncashment as string}
        />
        <PreviewMetric
          title="Total gratuity"
          value={bulkUploadRecord?.file_summary?.numberOfGratuity as string}
        />
      </div>
    ),
    successModalCtaText: 'Go to People',
    successModalSubText:
      'The Full and Final settlement has been processed for the employees mentioned in the file',
    onSuccessModalCtaClick() {
      window.location.href = '/people';
    },
    onDownloadErrorReport(history) {
      history?.push({
        pathname: routePaths.bulkUploads.initiate,
        search: `type=${BulkUploadTypes.TYPE_BULK_FNF_SETTLEMENT}`,
      });
    },
  },
};

export default config;
