import KYC from 'constants/kyc';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import AddressFromGSTIN from './AddressFromGSTIN';
import CompanyAddress from './CompanyAddress';
import CompanyNameFromPanView from './CompanyNameFromPanView';
import { AddressConfirmationPayload, PanConfirmationPayload, PrefillViewType } from './types';
import { Switch, useHistory } from 'react-router-dom';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';

interface Props {
  onSuccess?: () => void;
}

const CompanyDetailsWrapper = ({ onSuccess }: Props) => {
  const history = useHistory();
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const isAddressSetupComplete =
    currentOrganization?.onboardingConfig?.isCompanyAdressSetupComplete || false;
  const isKycVerified = currentOrganization?.kycStatus === KYC.STATUS_VERIFIED;

  const [fieldsFromPrefill, setFieldsFromPrefill] = useState<AddressConfirmationPayload>(
    {} as AddressConfirmationPayload,
  );

  const handleOnAddressConfirmation = (payload: AddressConfirmationPayload) => {
    setFieldsFromPrefill(payload);
    history.push(routePaths.companyOnboarding.companyDetails, { previousView: 'gstin' });
  };

  const handleProceedManually = () => {
    setFieldsFromPrefill({} as AddressConfirmationPayload);
    history.push(routePaths.companyOnboarding.panPrefill);
  };

  const handleOnBackClick = () => {
    setFieldsFromPrefill({} as AddressConfirmationPayload);
    history.push(routePaths.companyOnboarding.gstinPrefill);
  };

  const handleOnPanConfirmation = (payload: PanConfirmationPayload) => {
    setFieldsFromPrefill(payload);
    history.push(routePaths.companyOnboarding.companyDetails, { previousView: 'pan' });
  };

  const handleEditClick = (previousView: PrefillViewType) => {
    if (!previousView || previousView === 'gstin') {
      history.push(routePaths.companyOnboarding.gstinPrefill);
    } else {
      history.push(routePaths.companyOnboarding.panPrefill);
    }
  };

  useEffect(() => {
    if (isKycVerified || isAddressSetupComplete) {
      history.push(routePaths.companyOnboarding.companyDetails);
    } else {
      if (!fieldsFromPrefill.gstin) history.push(routePaths.companyOnboarding.gstinPrefill);
      else if (!fieldsFromPrefill.pan) history.push(routePaths.companyOnboarding.panPrefill);
    }
  }, [isKycVerified, isAddressSetupComplete]);

  return (
    <Switch>
      <RestrictedRoute
        allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        path={[routePaths.companyOnboarding.gstinPrefill]}
        exact>
        <AddressFromGSTIN
          initialGstin={currentOrganization?.gstin || ''}
          onConfirm={handleOnAddressConfirmation}
          onProceedManually={handleProceedManually}
        />
      </RestrictedRoute>
      <RestrictedRoute
        allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        path={[routePaths.companyOnboarding.panPrefill]}
        exact>
        <CompanyNameFromPanView
          initialPan={currentOrganization?.pan || ''}
          initialOrgType={currentOrganization?.orgType as number}
          onNext={handleOnPanConfirmation}
          onBack={handleOnBackClick}
        />
      </RestrictedRoute>
      <RestrictedRoute
        allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        path={[routePaths.companyOnboarding.companyDetails]}
        exact>
        <CompanyAddress
          onSuccess={onSuccess}
          fieldsFromPrefill={fieldsFromPrefill as AddressConfirmationPayload}
          onEditClick={handleEditClick}
        />
      </RestrictedRoute>
    </Switch>
  );
};

export default CompanyDetailsWrapper;
