import React from 'react';
import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Text,
} from '@razorpay/blade/components';
import HeaderSection from './HeaderSection';

const ArrearsInfo = () => {
  return (
    <>
      <HeaderSection
        title={'Calculate Arrears'}
        description={
          `Choose whether the component can create generate arrears. Arrears occur when the employees' dues (due to salary revision, missed payments, etc.) are yet to be paid. It is a corrective method to restore employee's pay.`
        }
      />

      <Divider marginY={'spacing.7'} />

      <Box display={'flex'} flexDirection={'column'}>
        <Text weight={'semibold'}>
          If Arrear calculation is{'  '}
          <Badge emphasis={'subtle'} color={'positive'}>
            Enabled
          </Badge>
        </Text>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'}>
          <Text size="small" color={'surface.text.gray.muted'}>
            The system will automatically adjust salary for past periods if there's a change, like a
            salary revision.
          </Text>
          <Text size="small" color={'surface.text.gray.muted'}>
            Example:
          </Text>
          <List size={'small'} variant={'unordered'}>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                An employee’s Basic Salary component increased from ₹30,000 to ₹35,000, effective
                from January, but the change was updated in March.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                If arrears are enabled for the Basic Salary component, the employee will receive
                ₹10,000 extra in March (₹5,000 for January and ₹5,000 for February).
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>

      <Divider marginY={'spacing.7'} />

      <Box display={'flex'} flexDirection={'column'}>
        <Text weight={'semibold'}>
          If Arrear calculation is{'  '}
          <Badge emphasis={'subtle'} color={'information'}>
            Disabled
          </Badge>
        </Text>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'}>
          <Text size="small" color={'surface.text.gray.muted'}>
            The component's value will only change going forward, without adjusting for prior
            months.
          </Text>
          <Text size="small" color={'surface.text.gray.muted'}>
            Example:
          </Text>
          <List size={'small'} variant={'unordered'}>
            <ListItem>
              <ListItemText color={'surface.text.gray.muted'}>
                In the same case, if arrears are disabled for the Basic Salary component, the
                employee won’t receive any back pay. Their Basic Salary will simply update to
                ₹35,000 starting in March.
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default ArrearsInfo;
