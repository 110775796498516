import React, { useState, ReactElement } from 'react';
import OnboardingStatus, { OnboardingItem } from 'components/ui/OnboardingStatus';
import { routePaths } from '../Routes/data';
import { useLocation, useHistory } from 'react-router-dom';
import PageWrapper from 'components/ui/PageWrapper';
import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import { LoanRecoveryOption, LoanRecoverySteps, LoanStatus, SkipEmiOption, stepMap } from './constants';
import styles from './index.module.scss';
import { getInitialRecoveryFormData } from './props';
import { PrimaryButton, SecondaryButton } from 'components/ui/Button';
import LoanRecoveryFormBlock from './components/LoanRecoveryFormBlock';
import LoanRecoveryConfirmFormBlock from './components/LoanRecoveryConfirmFormBlock';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { ModalTitle } from 'components/ui/Modals/components/Modal';
import { Card } from 'components/ui/Card';
import { toIndianCurrencyString } from 'utils/Numbers';
import { useQuery, useMutation } from 'react-query';
import { FETCH_SINGLE_LOAN_RECORD, FETCH_FULL_RECOVERY_AMOUNT } from './queries';
import api from '../../api/index';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { LoanDataSchemaContract } from 'schemas/LoanDataSchema';
import { LoanEditRequestSchemaContract } from '../../schemas/LoanEditRequestSchema';
import { Alert } from '@razorpay/blade/components';


export interface RecoveryFormProps {
  recoveryType: LoanRecoveryOption,
  recoveryAmount: string
}


export default function LoanRecovery(): ReactElement {

  const history = useHistory();
  const queryParam = new URLSearchParams(useLocation().search);
  const [currentStep, setCurrentStep] = useState<LoanRecoverySteps>(LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT);
  const [formProps, setFormProps] = useState<RecoveryFormProps>(() => getInitialRecoveryFormData());
  const [error, setError] = useState<string | null>();
  const [showFullAmountAlert, setShowFullAmountAlert] = useState<boolean>(false);
  const [fullRecoveryAmount, setFullRecoveryAmount] = useState<number | null>();
  const { openModal } = useModal();

  const loanId = queryParam.get('loanId');

  const updateFormProps = (propName: string, value: any) => {
    setFormProps((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
  };

  const { isLoading, isRefetching, data } = useQuery(FETCH_SINGLE_LOAN_RECORD, () => {
    if (!loanId) {
      return null;
    }
    return api.loans.fetchLoanData({ loan_id: parseInt(loanId), page: 1 });
  }, {
    onSuccess: (data) => {
      if (data?.data[0].status === LoanStatus.CLOSED) {
        window.location.href = `/loans/manage?loanId=${data?.data[0].id}`;
      }
    },
    onError: (error: typeof AppError) => {
      return error;
    }
  });

  const fetchLoanRecordQuery = useQuery(FETCH_FULL_RECOVERY_AMOUNT, () => {
    if (!loanId) {
      return null;
    }
    return api.loans.getFullRecoveryAmountForLoan(parseInt(loanId));
  }, {
    onSuccess: (data) => {
      setFullRecoveryAmount(data?.amount);

    },
    onError: (error: typeof AppError) => {
      document.querySelector('#react')?.scrollTo(0, 0);
    }
  });

  const editLoanMutation = useMutation(
    ({ requestData, loanId }: { requestData: LoanEditRequestSchemaContract, loanId: number }) =>
      api.loans.editLoan(requestData, loanId),
    {
      onSuccess: (data: LoanDataSchemaContract) => {
        window.location.href = `/loans/manage?loanId=${data[0].id}`;
      },
      onError: (error: typeof AppError) => { },
    },
  );


  const handleConfirm = () => {
    if (loanId && fullRecoveryAmount) {

      editLoanMutation.mutate({
        requestData: {
          action: formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ? LoanRecoveryOption.FULL_RECOVERY : LoanRecoveryOption.PARTIAL_RECOVERY,
          amount: formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ? fullRecoveryAmount : parseInt(formProps.recoveryAmount)
        },
        loanId: parseInt(loanId)
      });

    }
    else {
      setError("Data required for this action was not found, please try again later.");
    }
  }

  const openConfirmModal = () => {
    const value = (formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY && fullRecoveryAmount) ?
      fullRecoveryAmount : parseInt(formProps.recoveryAmount);
    openModal(
      <ConfirmModal confirmText={formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ? "Close Loan" : "Confirm Changes"} cancelText="Cancel" onConfirm={handleConfirm} className={styles['confirm-modal']}>
        <ModalTitle>Confirm changes</ModalTitle>
        <Card className={styles['confirm-modal-text-card']}>{formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ?
          `Loan will be closed for the employee, please ensure the outstanding amount ${toIndianCurrencyString(value)} is recovered externally. `
          : `Partial recovery of ${toIndianCurrencyString(value)} will be recorded for this loan. Please ensure the amount is recovered externally. `}
        </Card>
      </ConfirmModal>
    )
  }




  const onStepChange = (value: number) => {
    setCurrentStep(value);
  };

  if (!loanId) {
    history.push(routePaths.unknown);
  }

  return (
    <PageWrapper>
      <Breadcrumb
        name="Loan Recovery"
        urlMaps={{
          Loans: `${routePaths.loans.manageLoan}?loanId=${loanId}`
        }}
      />
      <MainCase type='fullWidth'>
        <OnboardingStatus
          className="mb-16 mr-10"
          title={stepMap[currentStep].name}
          value={currentStep}
          onChange={onStepChange}>

          <OnboardingItem
            title={stepMap[LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT].name}
            value={LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT}
            status={currentStep === LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT ? 'active' :
              (currentStep === LoanRecoverySteps.STEP_CONFIRM ? 'completed' : undefined)}
          />

          <OnboardingItem
            title={stepMap[LoanRecoverySteps.STEP_CONFIRM].name}
            value={LoanRecoverySteps.STEP_CONFIRM}
            status={currentStep === LoanRecoverySteps.STEP_CONFIRM ? 'active' : undefined}
          />
        </OnboardingStatus>
        <div className={styles['form-body']}>
          {fetchLoanRecordQuery.status === "error" || editLoanMutation.status === 'error' &&
            (<WarningAlert>
              <Errors errorResponse={fetchLoanRecordQuery.error || editLoanMutation.error} />
            </WarningAlert>)
          }
          {
            error && (
              <WarningAlert>
                <span>{error}</span>
              </WarningAlert>
            )}

          {currentStep === LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT &&
            <LoanRecoveryFormBlock formProps={formProps} updateFormProps={updateFormProps} fullRecoveryAmount={fullRecoveryAmount} />
          }

          {currentStep === LoanRecoverySteps.STEP_CONFIRM && loanId &&
            <LoanRecoveryConfirmFormBlock formProps={formProps} updateFormProps={updateFormProps} fullRecoveryAmount={fullRecoveryAmount} loanId={parseInt(loanId)} />
          }

          {
            showFullAmountAlert &&
            <Alert isDismissible={true} onDismiss={() => setShowFullAmountAlert(false)} color={'information'} description={`The partial recovery amount cannot be equal to or more than the principal amount ${ data?.data[0].principal ? `( ${toIndianCurrencyString(data?.data[0].principal, false)} )` : ''}. If you would like to pay the full amount please use the Full Recovery option.`} />
          }

          {currentStep === LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT &&
            <PrimaryButton className={`w-32 ${styles['button-text']}`} onClick={
              () => {
                if (data?.data[0].principal && parseInt(formProps.recoveryAmount) >= data?.data[0].principal) {
                  setShowFullAmountAlert(true);
                }
                else {
                  setShowFullAmountAlert(false);
                  setCurrentStep(LoanRecoverySteps.STEP_CONFIRM);
                }
              }
            }>
              Next
            </PrimaryButton>
          }

          {currentStep === LoanRecoverySteps.STEP_CONFIRM &&
            <div className='flex flex-row'>
              <SecondaryButton className={`w-32 ${styles['button-text']}`} onClick={() => { setCurrentStep(LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT) }}>
                Back
              </SecondaryButton>

              <PrimaryButton className={`w-32 ${styles['button-text']} ${styles['button-confirm']}`}
                onClick={() => { openConfirmModal() }}
                isPending={editLoanMutation.isLoading}>
                Confirm
              </PrimaryButton>
            </div>
          }
        </div>




      </MainCase>

    </PageWrapper>
  )
}