import { Amount, Box, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';

export const SummaryCard = ({
  icon,
  title,
  amount,
}: {
  icon: ReactNode;
  title: string;
  amount: number;
}) => {
  return (
    <Box
      backgroundColor="surface.background.gray.moderate"
      paddingY={'spacing.6'}
      paddingX={'spacing.4'}
      borderRadius="large"
      display="flex"
      alignItems="center"
      width={'100%'}>
      <Box
        marginRight="spacing.4"
        display="flex"
        width={'36px'}
        height={'36px'}
        alignItems={'center'}
        justifyContent={'center'}
        backgroundColor={'surface.background.gray.intense'}
        borderRadius={'round'}>
        {icon}
      </Box>
      <Box display="flex" flexDirection="column" gap="spacing.2">
        <Text size="small" weight="regular" color="surface.text.gray.muted">
          {title}
        </Text>
        <Amount
          value={amount}
          type="heading"
          size="medium"
          weight="semibold"
          isAffixSubtle={true}
          currency="INR"
          currencyIndicator="currency-symbol"
        />
      </Box>
    </Box>
  );
};