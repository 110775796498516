import {
  ActionList,
  ActionListItem,
  Alert,
  Box,
  Dropdown,
  DropdownOverlay,
  Heading,
  SelectInput,
  Text,
} from '@razorpay/blade/components';
import { uniqueId } from 'utils/Numbers';
import { CustomDeclarationType, DeclarationErrors, DeclarationWindowType } from '../../../ui/DeclarationWindow/types';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MonthDeclarationSelector } from 'components/ui/DeclarationWindow/DeclarationSettings/MonthDeclarationSelector';
import { CustomDeclarationSelector } from 'components/ui/DeclarationWindow/DeclarationSettings/CustomDeclarationSelector';
import { FlexiBenefitsSettings } from '../types';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';

interface Props {
  isProofUploadWindowSettings?: boolean;
  title: string;
  description: string;
  settings: FlexiBenefitsSettings;
  onChange: (value: GenericDeclaration) => void;
  isLoading: boolean;
  errors?: DeclarationErrors;
  onWindowPeriodChange: () => void;
}

const DeclarationSettings = ({
  title,
  description,
  isProofUploadWindowSettings = false,
  settings,
  onChange,
  isLoading,
  errors = {},
  onWindowPeriodChange,
}: Props) => {
  const history = useHistory();
  const declarationData = isProofUploadWindowSettings
    ? settings.employeeProofUploadWindow
    : settings.employeeDeclarationWindow;

  const setDeclarationData = (fn: (previous: GenericDeclaration) => GenericDeclaration) => {
    onChange(fn(declarationData));
  };

  const handleDeclarationWindowSelection = (windowType: DeclarationWindowType) => {
    if (windowType === 'custom') {
      setDeclarationData((p) => {
        const newState = { ...p, type: windowType } as CustomDeclarationType;
        const customRanges = newState.dates || {};
        if (Object.keys(customRanges).length === 0) {
          newState.dates = { [uniqueId()]: { endDate: null, startDate: null } };
        }
        return newState as GenericDeclaration;
      });
    } else {
      setDeclarationData((p) => ({ ...p, type: windowType } as GenericDeclaration));
    }
    onWindowPeriodChange();
  };

  const handleMonthlyDeclarationChange = (value: string, isStart: boolean) => {
    setDeclarationData(
      (p) => ({ ...p, [isStart ? 'startDate' : 'endDate']: value } as GenericDeclaration),
    );
  };

  const handleCustomDeclarationChange = (id: string, value: Date | null, isStart: boolean) => {
    setDeclarationData((p) => {
      const data = { ...p };
      if (data.type === 'custom') {
        data.dates = {
          ...data.dates,
          [id]: { ...data.dates[id], [isStart ? 'startDate' : 'endDate']: value },
        };
      }
      return data as GenericDeclaration;
    });
  };

  const handleAddClick = () => {
    setDeclarationData((p) => {
      const data = { ...p };
      if (data.type === 'custom') {
        data.dates = {
          ...data.dates,
          [uniqueId()]: { startDate: null, endDate: null },
        };
      }
      return data as GenericDeclaration;
    });
  };

  const handleDeleteClick = (id: string) => {
    setDeclarationData((p) => {
      const data = { ...p };
      if (data.type === 'custom') {
        delete data.dates[id];
      }
      return data as GenericDeclaration;
    });
  };

  const isEmployeeDeclarationDisabled = isProofUploadWindowSettings
    ? false
    : settings.employeeCanDeclareFlexiBenefits === false;

  if (isEmployeeDeclarationDisabled) {
    return (
      (<Box marginX={{ base: 'spacing.8', l: 'auto' }} width="500px" paddingY="spacing.11">
        <Alert
          isDismissible={false}
          title="Employee declaration is currently disabled for employees"
          description={`Please go to the General tab and enable the option "Employees can choose the flexible benefits and enable declaration".`}
          actions={{
            primary: {
              text: 'Update Settings',
              onClick: () => history.push(routePaths.flexibleBenefits.settings),
            },
          }}
          color="information"
        />
      </Box>)
    );
  }

  return (
    (<Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" paddingY="spacing.11">
      <Heading size="small">{title}</Heading>
      <Text marginTop="spacing.4" color="surface.text.gray.disabled">
        {description}
      </Text>
      <Dropdown selectionType="single" marginTop={'spacing.9'}>
        <SelectInput
          isDisabled={isLoading}
          isRequired
          value={declarationData.type}
          label={`Select employee ${isProofUploadWindowSettings ? 'proof upload' : 'declaration'
            } window period`}
          placeholder="Select"
          name="windowPeriod"
          onChange={({ values }) =>
            handleDeclarationWindowSelection(values[0] as DeclarationWindowType)
          }
        />
        <DropdownOverlay>
          <ActionList>
            <ActionListItem key={'always'} title={'Always open'} value={'always'} />
            <ActionListItem
              key={'monthly'}
              title={'Every month at a certain period'}
              value={'monthly'}
            />
            <ActionListItem key={'custom'} title={'Custom range'} value={'custom'} />
          </ActionList>
        </DropdownOverlay>
      </Dropdown>
      {declarationData.type === 'monthly' && (
        <MonthDeclarationSelector
          isLoading={isLoading}
          isProofUploadWindowSettings={isProofUploadWindowSettings}
          endDate={declarationData.endDate}
          startDate={declarationData.startDate}
          onChange={handleMonthlyDeclarationChange}
          errors={errors}
        />
      )}
      {declarationData.type === 'custom' && (
        <CustomDeclarationSelector
          isLoading={isLoading}
          isProofUploadWindowSettings={isProofUploadWindowSettings}
          dates={declarationData.dates}
          onChange={handleCustomDeclarationChange}
          onAdd={handleAddClick}
          onDelete={handleDeleteClick}
          errors={errors}
        />
      )}
      {declarationData.type === 'always' && (
        <Alert
          isDismissible={false}
          marginTop="spacing.7"
          description={`💡️ FBP ${isProofUploadWindowSettings ? 'proof upload' : 'declaration'
            } window will be open throughout the year for the employees. Employee can ${isProofUploadWindowSettings ? 'upload FBP proof' : 'edit thier FBP declaration'
            } anytime.`}
          color="information"
        />
      )}
    </Box>)
  );
};

export default DeclarationSettings;
