import {
  ActionList,
  ActionListItem,
  Alert,
  Box,
  Dropdown,
  DropdownOverlay,
  InfoIcon,
  SelectInput,
  Spinner,
  Text,
  Heading,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import { UploadKycDocumentApiPayload } from 'api/kyc';
import { routePaths } from 'components/Routes/data';
import { MimeType } from 'components/ui/FileUpload/constants';
import Errors from 'components/ui/Form/Errors';
import KYC from 'constants/kyc';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { AppError } from 'utils/AppError';
import { KycDocumentsContext } from './KycDocumentsContext';
import ManualBankVerificationCtas from './ManualBankVerificationCtas';
import SuccessViewModal from './SuccessViewModal';
import DocumentUpload from './components/DocumentUpload';
import Section from './components/Section';
import SidebarHeader from './components/SidebarHeader';
import SidebarSection from './components/SidebarSection';
import {
  BANK_VERIFICATION_MAX_ATTEMPTS,
  DocumentStatus,
  bankDetailsHelpfulLinks,
  bankVerificationProofOptipons,
  manualDocumentTypes,
  needHelpLinks,
} from './constants';
import useKycInfo from './hooks/useKycInfo';
import { useKycValidations } from './hooks/useKycValidations';
import {
  ErrorMessage,
  FooterActionsContainer,
  KycPageMainViewWrapper,
  KycPageSideViewWrapper,
  KycPageWrapper,
  KycViewContent,
  KycViewFooter,
  KycViewHeader,
  TextPair,
} from './styles';
import { BankDocumentTypes, OrgType, ProgressPillState } from './types';
import { getErrorsAsString, getMessageFromStatus } from './utils';
import { useIsSelfServeP3M1Enabled } from './hooks/useIsSelfServeP3M1Enabled';
import { useCompanyVerificationData } from './hooks/useCompanyVerificationDetails';

const BankDetailsManualView = () => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const orgType = org?.type as OrgType;
  const isL1KycCompleted = org?.levelOneKyc?.status === true;
  const { kycValues, setKycValues, updateBankDetails } = useContext(KycDocumentsContext);
  const proofType = kycValues.bankVerificationDetails.proofType.name;
  const data = kycValues.bankVerificationDetails;

  const { kycInfo, initateKycVerification, setIsVerifying, isVerifying } = useKycInfo();

  const { status: kycStatus, autoKyc } = kycInfo.data?.kycInfo || {};
  const { attempts_remaining = BANK_VERIFICATION_MAX_ATTEMPTS } =
    kycInfo.data?.bankAccountInfo || {};
  const { verificationType } = autoKyc || {};

  const { isVerifyAvailable, isBankAccountVerified, isMethodPennyTesting } = useKycValidations({
    kycStatus,
  });

  const { isSelfServeP3M1Enabled, isLoading: isFetchingSplitzExperiment } =
    useIsSelfServeP3M1Enabled();

  const { isCompanyVerified } = useCompanyVerificationData();

  const isKycFlowManual = verificationType === 'manual';
  const isKycVerified =
    kycInfo.data?.kycInfo.status === KYC.STATUS_VERIFIED || org?.kycStatus === KYC.STATUS_VERIFIED;

  const progress = useMemo<ProgressPillState[]>(() => {
    if (isSelfServeP3M1Enabled) {
      return [
        'COMPLETED',
        isBankAccountVerified ? 'COMPLETED' : 'IN_PROGRESS',
        isKycVerified ? 'COMPLETED' : 'IN_PROGRESS',
      ];
    } else {
      return [
        isBankAccountVerified ? 'COMPLETED' : 'IN_PROGRESS',
        isKycVerified ? 'COMPLETED' : 'IN_PROGRESS',
      ];
    }
  }, [isSelfServeP3M1Enabled, isBankAccountVerified, isKycVerified]);

  const onFileSelect = (
    documentType: BankDocumentTypes,
    file: File,
    upload: (payload: UploadKycDocumentApiPayload) => void,
  ) => {
    updateBankDetails(documentType, {
      file,
      status: DocumentStatus.UPLOADING,
      saved_filename: '',
      message: getMessageFromStatus({ status: DocumentStatus.UPLOADING }),
    });
    upload({
      documentType,
      file,
      orgType,
    });
  };

  const onSuccess = (
    documentType: BankDocumentTypes,
    response: UploadKycDocumentSchemaContract,
  ) => {
    updateBankDetails(documentType, {
      status: DocumentStatus.SUCCESS,
      saved_filename: response.filename || '',
      url: response.fileS3Url || '',
      message: getMessageFromStatus({ status: DocumentStatus.SUCCESS }),
    });
  };
  const onError = (documentType: BankDocumentTypes, error: AppError) => {
    updateBankDetails(documentType, {
      status: DocumentStatus.ERROR,
      message: getMessageFromStatus({
        status: DocumentStatus.ERROR,
        error: getErrorsAsString(error),
      }),
    });
  };

  const handleDocumentTypeSelect = (name: string) => {
    setKycValues((p) => ({
      ...p,
      bankVerificationDetails: {
        ...p.bankVerificationDetails,
        proofType: {
          ...p.bankVerificationDetails.proofType,
          name: name as BankDocumentTypes,
          message: '',
        },
      },
    }));
  };

  const isDocumentTypeAccountStatement = proofType === manualDocumentTypes.ACCOUNT_STATEMENT;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isVerifyAvailable) return;
    setIsVerifying(true);
    initateKycVerification.mutate();
  };

  const checkIsSelectionDisabled = () => {
    if (!proofType) return false;
    const isVerifyingOrVerified = [DocumentStatus.VERIFIED, DocumentStatus.VERIFYING];

    return isVerifyingOrVerified.includes(data[proofType]?.status || DocumentStatus.NOT_INITIATED);
  };

  const onFileNameClick = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const isProofSelectionDisabled = checkIsSelectionDisabled();

  if ((!isL1KycCompleted || !orgType) && !kycInfo.isLoading) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  if (!kycInfo.isLoading && isBankAccountVerified && isMethodPennyTesting) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  // Redirecting to company verification if company not yet verified
  if (isSelfServeP3M1Enabled && !isCompanyVerified) {
    return <Redirect to={routePaths.kyc.companyDetailsVerification.root} />;
  }

  const attemptsRemaining = attempts_remaining || 0;
  const isMaxAttemptExceeded = attemptsRemaining <= 0;

  if (isFetchingSplitzExperiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  return (
    (<KycPageWrapper>
      <KycPageSideViewWrapper>
        <Box
          overflowY={'auto'}
          display={'flex'}
          flexDirection={'column'}
          paddingY={'spacing.9'}
          paddingX={'spacing.10'}>
          <SidebarHeader
            onBackClick={() => (window.location.href = '/dashboard')}
            progress={progress}
          />
          <Box display={'flex'} gap={'spacing.2'} alignItems={'center'} marginTop={'spacing.4'}>
            <Text color="surface.text.gray.muted">Why do KYC?</Text>
            <Tooltip
              content={'Complete KYC to unlock salary slips and salary transfers'}
              placement="right">
              <TooltipInteractiveWrapper>
                <InfoIcon color="interactive.icon.gray.normal" size="small" />
              </TooltipInteractiveWrapper>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.10" marginTop="spacing.10">
            <SidebarSection title="Helpful Links" links={bankDetailsHelpfulLinks} />
            <SidebarSection title="Need Help?" links={needHelpLinks} />
          </Box>
        </Box>
      </KycPageSideViewWrapper>
      <KycPageMainViewWrapper as="form" onSubmit={handleSubmit}>
        {kycInfo.isLoading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
          </Box>
        ) : (
          <Box height="100vh" display={'grid'} gridTemplateRows={'1fr auto'}>
            <Box overflowY={'auto'}>
              <KycViewHeader>
                <Heading size="xlarge">📑 Verify Manually</Heading>
                <Text marginTop={'spacing.3'} color="surface.text.gray.muted">
                  💳 We will verify if your registered business name matches your bank account
                </Text>
              </KycViewHeader>

              <KycViewContent>
                <Section title="🏢 Upload" subtitle="documents">
                  <Box>
                    <Dropdown selectionType="single">
                      <SelectInput
                        isDisabled={isProofSelectionDisabled}
                        labelPosition="left"
                        isRequired
                        value={proofType}
                        label="Verify via"
                        placeholder="Select document"
                        name="documentType"
                        onChange={({ values }) =>
                          handleDocumentTypeSelect(values[0] as BankDocumentTypes)
                        }
                      />
                      <DropdownOverlay>
                        <ActionList>
                          {bankVerificationProofOptipons.map((item) => (
                            <ActionListItem key={item.name} title={item.name} value={item.value} />
                          ))}
                        </ActionList>
                      </DropdownOverlay>
                    </Dropdown>
                    {proofType ? (
                      <DocumentUpload
                        marginTop={'spacing.4'}
                        allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
                        id="document"
                        value={data[proofType]?.file?.name || data[proofType]?.saved_filename}
                        onChange={(files, upload) => {
                          const file = files?.[0] || null;
                          if (!file) return;
                          onFileSelect(proofType, file, upload);
                        }}
                        onSuccess={(response) => onSuccess(proofType, response)}
                        onError={(error) => onError(proofType, error)}
                        status={data[proofType]?.status}
                        subText={data[proofType]?.message}
                        onFilenameClick={() => onFileNameClick(data[proofType]?.url)}
                      />
                    ) : null}
                  </Box>
                  {isDocumentTypeAccountStatement && (
                    <Alert
                      isDismissible={false}
                      description="Please ensure the Bank A/C Statement is not password protected"
                      color="information"
                    />
                  )}
                </Section>
              </KycViewContent>
            </Box>
            <KycViewFooter>
              {initateKycVerification.error ? (
                <ErrorMessage message={<Errors errorResponse={initateKycVerification.error} />} />
              ) : (
                <TextPair
                  mutedText="🔍 Verification can take upto"
                  highlightedText="2 business days"
                />
              )}
              <FooterActionsContainer>
                <ManualBankVerificationCtas
                  isVerifying={isVerifying}
                  kycStatus={kycStatus}
                  isKycFlowManual={isKycFlowManual}
                  isMaxAttemptExceeded={isMaxAttemptExceeded}
                />
              </FooterActionsContainer>
            </KycViewFooter>
          </Box>
        )}
      </KycPageMainViewWrapper>
      <SuccessViewModal orgName={org?.name || ''} isOpen={isKycVerified} />
    </KycPageWrapper>)
  );
};

export default BankDetailsManualView;
