import { Alert, Box, Radio, RadioGroup } from '@razorpay/blade/components';
import React from 'react';

interface QuestionCardProps {
  title: React.ReactNode;
  description: React.ReactNode;
  answerOptions: string[];
  noticeText?: string;
  onSelect: (selectionIndex: number) => void;
}

const QuestionCard = ({
  title,
  description,
  answerOptions,
  noticeText,
  onSelect,
}: QuestionCardProps) => {
  const handleSelection = ({ value }: { name?: string; value: string }) => {
    onSelect(Number(value));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="spacing.7"
      padding="spacing.7"
      borderRadius="medium"
      borderWidth="thin"
      borderColor="surface.border.gray.subtle">
      <Box display="flex" flexDirection="column" gap="spacing.3">
        {title}
        {description}
      </Box>
      <RadioGroup label="" name="answer" onChange={handleSelection}>
        <Box display="flex" flexDirection="column" gap="spacing.3">
          {answerOptions.map((option, index) => (
            <Box
              padding={['spacing.3', 'spacing.7', 'spacing.3', 'spacing.3']}
              borderRadius="medium"
              backgroundColor="surface.background.primary.subtle"
              key={index}
              width="fit-content">
              <Radio size="small" value={index.toString()} key={index}>
                {option}
              </Radio>
            </Box>
          ))}
        </Box>
      </RadioGroup>
      {noticeText && <Alert isDismissible={false} description={noticeText} color="notice" />}
    </Box>
  );
};

export { QuestionCard };
