import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';
import api from 'api';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import ExpansionPanel from 'components/ui/Accordion/ExpansionPanel';
import { ExpansionList } from 'components/ui/Accordion/ExpansionPanel/ExpansionList';
import useExpansionPanel from 'components/ui/Accordion/ExpansionPanel/useExpansionPanel';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import Modal, { ModalTitle } from 'components/ui/Modals/components/Modal';
import Spinner from 'components/ui/Spinner';
import { getDaysInMonth } from 'date-fns';
import { useSelector } from 'react-redux';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import { GetComputedRlopArrearBreakdownContract } from 'schemas/GetComputedRlopArrearBreakdown';
import { RunPayrollUpdateSchemaContract } from 'schemas/RunPayrollUpdateSchema';
import { AppError } from 'utils/AppError';
import { showToastViaEvent } from 'utils/ToastEvents';
import { trackXPayrollEvent } from 'utils/analytics';
import { DECIMAL_REGEX } from 'utils/regexes';
import loggedInUserSelectors from '../../../reducers/loggedInUser/selectors';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';
import { FETCH_ARREARS, FETCH_COMPLIANCE_ARREARS, FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import {
  ArrearComponent,
  DeductFrom,
  DeductionComponent,
  DeductionType,
  DefinedRunPayrollData,
  RunPayrollData,
} from '../utils/types';
import { APPROVAL_DATA_FETCH } from '../utils/useApprovalLink';
import useIsRlopEnabled from '../utils/useIsRlopEnabled';
import useNewJoinerArrearEnabled from '../utils/useNewJoinerArrearEnabled';
import useRlopInfo from '../utils/useRlopInfo';
import { combineArrears, getArrearSumFromBreakdown, getNextMonth } from '../utils/utils';
import AddReverseLopModal from './AddReverseLopModal';
import ArrearBreakdown from './ArrearBreakdown';
import {
  Additions,
  Arrears,
  Deductions,
  PayrollBulkActions,
  Remarks,
} from './EditSalary/components';
import useAllowances from './EditSalary/hooks/useAllowances';
import styles from './index.module.scss';

const EditSalary = ({
  payrollEntry,
  onError,
  payrollMonth,
}: { payrollEntry: RunPayrollData; payrollMonth: string } & SetErrorMessageType) => {
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { newJoinerArrearPaymentMonthExists, newJoinerArrearExists } =
    useNewJoinerArrearEnabled(payrollEntry);
  const expansionPanel = useExpansionPanel(newJoinerArrearPaymentMonthExists ? 2 : 4);

  const features = useSelector(loggedInUserSelectors.features);
  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const isPFArrearsEnabled = !!features?.['pf-arrears'];
  const { isRlopFeatureEnabled } = useIsRlopEnabled();
  const [isPlatformArrears, setIsPlatformArrears] = useState(false);
  const lastFinalizedPayrollMonth = payrollEntry.lastFinalizedPayrollMonth;

  const doesEditNeedApproval = actionsWithActiveWorkflows.includes(WorkflowTypesMap.EDIT_PAYROLL);

  const { allowances, onAllowanceChange } = useAllowances({
    payrollEntry,
  });

  let initialNonLOPDeductions = payrollEntry.deductions?.non_lop_deductions;
  if (!initialNonLOPDeductions || initialNonLOPDeductions.length === 0) {
    initialNonLOPDeductions = [
      {
        name: '',
        amount: 0,
        deduct_from: DeductFrom.GROSS_PAY,
        lop_days: '0',
        type: DeductionType.TYPE_MANUAL,
      },
      {
        name: '',
        amount: 0,
        deduct_from: DeductFrom.GROSS_PAY,
        lop_days: '0',
        type: DeductionType.TYPE_MANUAL,
      },
    ];
  }

  const [deductions, setDeductions] = useState<DeductionComponent>({
    lop_deduction: {
      lop_days: payrollEntry.deductions?.lop_deduction?.lop_days ?? '0',
      name: payrollEntry.deductions?.lop_deduction?.name ?? '',
      amount: payrollEntry.deductions?.lop_deduction?.amount ?? 0,
      deduct_from: payrollEntry.deductions?.lop_deduction?.deduct_from ?? 1,
      type:
        payrollEntry.deductions?.lop_deduction?.type ?? DeductionType.TYPE_MANUAL_ATTENDANCE_LOP,
    },
    non_lop_deductions: initialNonLOPDeductions,
    total_deduction: payrollEntry.deductions?.total_deduction ?? 0,
  });

  const setNonLOPDeductions = (nonLOPDeductions: DeductionComponent['non_lop_deductions']) => {
    setDeductions({
      ...deductions,
      non_lop_deductions: nonLOPDeductions,
    });
  };

  const [arrears, setArrears] = useState<ArrearComponent[]>([]);
  const [rlopInfo, setRlopInfo] = useState<DefinedRunPayrollData>(payrollEntry.rlopInfo ?? []);
  const [arrearsBreakdown, setArrearsBreakdown] =
    useState<GetArrearsSchemaContract['arrear_breakdown']>(null);

  const [rlopArrearsBreakdown, setRlopArrearsBreakdown] =
    useState<GetArrearsSchemaContract['rlop_breakdown']>(null);

  const [salaryRevisionArrearsBreakdown, setSalaryRevisionArrearsBreakdown] =
    useState<GetArrearsSchemaContract['salary_revision_breakdown']>(null);

  const [existingSalaryRevisionBreakdown, setExistingSalaryRevisionBreakdown] =
    useState<GetArrearsSchemaContract['salary_revision_breakdown']>(null);

  const [remarks, setRemarks] = useState(payrollEntry.remarks);

  const arrearsQuery = useQuery(
    [FETCH_ARREARS, payrollEntry.peopleOrgId, payrollMonth],
    ({ queryKey }) => {
      const peopleOrgId = queryKey[1] as number;
      const payrollMonth = queryKey[2] as string;

      return api.runPayroll.getArrearsData(peopleOrgId, payrollMonth);
    },
    {
      onError: (error: AppError) => {},
      onSuccess: (data) => {
        setArrears(data.arrears);
        setArrearsBreakdown(data.arrear_breakdown);

        if (data.salary_revision_breakdown) {
          setSalaryRevisionArrearsBreakdown(data.salary_revision_breakdown);
          setExistingSalaryRevisionBreakdown(data.salary_revision_breakdown);
        }
        if (data.rlop_breakdown) {
          setRlopArrearsBreakdown(data.rlop_breakdown);
        }
        if (
          (!data.salary_revision_breakdown &&
            !data.rlop_breakdown &&
            data.is_system_calculated === false) ||
          newJoinerArrearExists
        ) {
          setIsPlatformArrears(true);
        }
      },
    },
  );

  const isArrearsBeingFetched = arrearsQuery.status === 'loading' || arrearsQuery.isRefetching;

  const platformArrears = useMemo(
    () => arrearsQuery.data?.platform_arrears ?? [],
    [arrearsQuery.data?.platform_arrears],
  );

  const existingComplianceArrears = useMemo(
    () => arrearsQuery.data?.compliance_arrears ?? [],
    [arrearsQuery.data?.compliance_arrears],
  );

  const complianceArrearsQuery = useQuery({
    queryKey: [
      FETCH_COMPLIANCE_ARREARS,
      payrollEntry.peopleOrgId,
      payrollMonth,
      rlopArrearsBreakdown,
      salaryRevisionArrearsBreakdown,
      platformArrears,
      rlopInfo,
    ],
    queryFn: ({ queryKey }) => {
      const peopleOrgId = queryKey[1] as string;
      const payrollMonth = queryKey[2] as string;
      const rlopArrearsBreakdown = queryKey[3] as GetArrearsSchemaContract['rlop_breakdown'];
      const salaryRevisionArrearsBreakdown =
        queryKey[4] as GetArrearsSchemaContract['salary_revision_breakdown'];
      const platform_arrears = queryKey[5] as GetArrearsSchemaContract['arrears'];
      const rlop_info = queryKey[6] as DefinedRunPayrollData;

      return api.runPayroll.getComputedComplianceArrears({
        userOrg: peopleOrgId.toString(),
        requestData: {
          rlop_breakdown: rlopArrearsBreakdown,
          salary_revision_breakdown: salaryRevisionArrearsBreakdown,
          payroll_month: payrollMonth,
          source_details: rlop_info.map((item) => ({
            rlop_days: item.rlopDays,
            source_month: item.month,
          })),
          platform_arrears,
        },
      });
    },
    enabled:
      !isArrearsBeingFetched &&
      (Boolean(rlopArrearsBreakdown) ||
        Boolean(salaryRevisionArrearsBreakdown) ||
        isPlatformArrears),
  });

  const complianceArrears = useMemo(() => {
    if (isRlopFeatureEnabled) {
      return complianceArrearsQuery.data?.compliance_arrears ?? [];
    } else {
      return existingComplianceArrears;
    }
  }, [
    isRlopFeatureEnabled,
    complianceArrearsQuery.data?.compliance_arrears,
    existingComplianceArrears,
  ]);

  const update = useMutation(
    (payload: RunPayrollUpdateSchemaContract) => {
      return api.runPayroll.updatePayroll(payload);
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        if (response['hasChangeRequest']) {
          queryClient.invalidateQueries(APPROVAL_DATA_FETCH);
          showToastViaEvent({
            text: `Approval Request Sent!`,
            timeout: 5000,
            type: 'success',
            icon: 'success',
          });
        }
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const { updateRlopDays } = useRlopInfo({});

  const skip = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runPayroll.bulkSkipPayroll(payrollMonth, [payrollId]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const skipAllExceptThis = useMutation(
    ({ payrollId }: { payrollId: number }) => {
      return api.runPayroll.skipAllExceptThisPayroll(payrollId, payrollMonth);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      },
    },
  );

  const onLOPDaysChange = (value: string) => {
    if (!value.match(DECIMAL_REGEX)) {
      return;
    }

    const daysInCurrentMonth = getDaysInMonth(new Date(payrollMonth));

    if (parseFloat(value) > daysInCurrentMonth) {
      return;
    }

    setDeductions({
      ...deductions,
      lop_deduction: {
        ...deductions.lop_deduction,
        lop_days: value ?? '0',
      },
    });
  };

  const onArrearChange = (componentToUpdate: string, value: string) => {
    setArrears((oldArrears) => {
      return oldArrears.map((component) => {
        if (component.name === componentToUpdate) {
          component.amount = parseInt(value);
          return component;
        }
        return component;
      });
    });
  };

  const isActionLoading =
    update.isLoading ||
    skip.isLoading ||
    skipAllExceptThis.isLoading ||
    complianceArrearsQuery.isFetching;

  const { combinedArrears, rlopArrears, salaryRevisionArrears } = useMemo(() => {
    let rlopArrears: ArrearComponent[] = [];
    let salaryRevisionArrears: ArrearComponent[] = [];
    if (rlopArrearsBreakdown) {
      rlopArrears = getArrearSumFromBreakdown(rlopArrearsBreakdown) ?? [];
    }

    if (salaryRevisionArrearsBreakdown) {
      salaryRevisionArrears = getArrearSumFromBreakdown(salaryRevisionArrearsBreakdown) ?? [];
    }

    const combinedArrears = isRlopFeatureEnabled
      ? isPlatformArrears
        ? arrears
        : combineArrears([...salaryRevisionArrears, ...rlopArrears, ...complianceArrears])
      : arrears;

    return { combinedArrears, rlopArrears, salaryRevisionArrears };
  }, [
    isRlopFeatureEnabled,
    isPlatformArrears,
    rlopArrearsBreakdown,
    salaryRevisionArrearsBreakdown,
    complianceArrears,
    arrears,
  ]);

  const isRlopVisible = isRlopFeatureEnabled
    ? (lastFinalizedPayrollMonth
        ? getNextMonth(lastFinalizedPayrollMonth) === payrollMonth
        : false) || rlopArrears.length > 0
    : false;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    trackXPayrollEvent(`edit_salary.DONE`);

    if (isArrearsBeingFetched) {
      return;
    }

    if (isRlopFeatureEnabled) {
      if (isRlopVisible) {
        await updateRlopDays.mutateAsync({
          userOrg: payrollEntry.peopleOrgId.toString(),
          requestData: {
            payroll_month: payrollMonth,
            source_details: rlopInfo
              .filter((item) => item.rlopDays > 0)
              .map((item) => ({
                rlop_days: item.rlopDays,
                source_month: item.month,
              })),
          },
        });
      }

      // TODO: Update schema to include salary revision and rlop arrears
      await update.mutateAsync({
        payroll_id: payrollEntry.id,
        allowances: allowances,
        deductions: {
          lop_days: deductions.lop_deduction.lop_days,
          non_lop_deductions: deductions.non_lop_deductions,
        },
        remarks: remarks,
        arrears: arrears as RunPayrollUpdateSchemaContract['arrears'],
        arrear_breakdown: arrearsBreakdown,
        rlop_breakdown: rlopArrearsBreakdown,
        salary_revision_breakdown: salaryRevisionArrearsBreakdown,
      });
    } else {
      await update.mutateAsync({
        payroll_id: payrollEntry.id,
        allowances: allowances,
        deductions: {
          lop_days: deductions.lop_deduction.lop_days,
          non_lop_deductions: deductions.non_lop_deductions,
        },
        remarks: remarks,
        arrears: arrears as RunPayrollUpdateSchemaContract['arrears'],
        arrear_breakdown: arrearsBreakdown as RunPayrollUpdateSchemaContract['arrear_breakdown'],
      });
    }
  };

  const applyArrearBreakdown = (breakdown: GetArrearsSchemaContract['arrear_breakdown']) => {
    setArrearsBreakdown(breakdown);
    const arrears = getArrearSumFromBreakdown(breakdown);
    if (arrears) {
      setArrears(arrears);
    }
  };

  const applySalaryRevisionArrearBreakdown = (
    breakdown: Exclude<GetArrearsSchemaContract['salary_revision_breakdown'], undefined>,
  ) => {
    setSalaryRevisionArrearsBreakdown(breakdown);
    setExistingSalaryRevisionBreakdown(breakdown);
    setIsPlatformArrears(false);
  };

  const applyRlopArrearBreakdown = (
    breakdown: Exclude<GetArrearsSchemaContract['rlop_breakdown'], undefined>,
  ) => {
    setRlopArrearsBreakdown(breakdown);
  };

  const onArrearEdit = () => {
    trackXPayrollEvent(`edit_salary.edit_month_wise_arrear`);

    const arrearsBrakdownToUse = isRlopFeatureEnabled
      ? salaryRevisionArrearsBreakdown
      : arrearsBreakdown;

    if (arrearsBrakdownToUse) {
      openModal(
        <ArrearBreakdown
          breakdown={arrearsBrakdownToUse}
          setBreakdown={
            isRlopFeatureEnabled ? applySalaryRevisionArrearBreakdown : applyArrearBreakdown
          }
        />,
      );
    }
  };

  const handleRLopDaysUpdate = (
    rlopInfo: DefinedRunPayrollData,
    response: GetComputedRlopArrearBreakdownContract,
  ) => {
    const {
      rlop_arrears_breakdown: breakdown,
      salary_revision_breakdown,
      is_salary_revision_arrears_modified: isSalaryRevisionBreakdownModified,
    } = response;

    let modifiedSalaryRevisionArrears = salaryRevisionArrears;

    if (isSalaryRevisionBreakdownModified && salary_revision_breakdown) {
      setExistingSalaryRevisionBreakdown(salaryRevisionArrearsBreakdown);
      setSalaryRevisionArrearsBreakdown(salary_revision_breakdown);
      if (salaryRevisionArrearsBreakdown) {
        modifiedSalaryRevisionArrears =
          getArrearSumFromBreakdown(salaryRevisionArrearsBreakdown) ?? [];
      }
    } else {
      setSalaryRevisionArrearsBreakdown(existingSalaryRevisionBreakdown);
    }
    if (rlopInfo.length > 0 && rlopInfo.every((item) => item.rlopDays > 0)) {
      setIsPlatformArrears(false);
    } else {
      if (
        modifiedSalaryRevisionArrears.length > 0 &&
        modifiedSalaryRevisionArrears.every((item) => item.amount > 0)
      ) {
        setIsPlatformArrears(false);
      } else {
        setArrears(platformArrears);
        setIsPlatformArrears(true);
      }
    }
    setRlopInfo(rlopInfo);
    applyRlopArrearBreakdown(breakdown);
  };

  const openRlopDaysModal = (isEditing?: boolean) => {
    openModal(
      <AddReverseLopModal
        payrollMonth={payrollMonth}
        payrollEntry={payrollEntry}
        breakdown={JSON.parse(JSON.stringify(rlopArrearsBreakdown))}
        salaryRevisionBreakdown={JSON.parse(JSON.stringify(salaryRevisionArrearsBreakdown))}
        onSave={(...params) => {
          handleRLopDaysUpdate(...params);
          closeModal();
        }}
        existingRlopInfo={JSON.parse(JSON.stringify(rlopInfo))}
        isEditing={isEditing}
      />,
    );
  };

  const isArrearBreakdownPopupEnabled =
    isPFArrearsEnabled && !!arrearsQuery.data?.is_system_calculated;

  const isAnyPlatformAdded =
    arrears.length > 0 && arrears.reduce((acc, current) => acc + current.amount, 0) > 0;

  const onAddReverseLOP = () => {
    if (isPlatformArrears && isAnyPlatformAdded) {
      openModal(
        <ConfirmModal
          confirmText="Continue to add RLOP"
          className="p-0"
          footerContainerClassName="pt-4 pb-10 px-7"
          onConfirm={() => {
            openRlopDaysModal();
          }}>
          <div>
            <ModalTitle className="border-b border-modal-header-border py-6 px-10 border-solid">
              Arrears will be overriden?
            </ModalTitle>
            <div className="p-10">
              Please note once you add reverse LOP , the current arrears added will be overridden.
            </div>
          </div>
        </ConfirmModal>,
      );
    } else {
      openRlopDaysModal();
    }
  };

  const onRlopArrearEdit = () => {
    if (rlopArrearsBreakdown) {
      openRlopDaysModal(true);
    }
  };

  return (
    <Modal>
      <div className="max-h-screen overflow-y-auto">
        <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
          <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
            Edit Salary
          </div>
          <form className={`${styles['run-payroll-modal-content']}`} onSubmit={onSubmit}>
            <ExpansionList className="mb-16">
              {!newJoinerArrearPaymentMonthExists && (
                <IsFeatureDisabledForRearch feature="isAdditionsDisabled">
                  <ExpansionPanel
                    {...expansionPanel.panelProps[0]}
                    onHeaderClick={expansionPanel.onHeaderClick}
                    header={'Addition'}
                    scrollable={true}
                    childrenClassName={styles['run-payroll-addition-expansion-child']}>
                    <Additions
                      allowances={allowances}
                      onAllowanceChange={onAllowanceChange}
                      payrollEntry={payrollEntry}
                      payrollMonth={payrollMonth}
                    />
                  </ExpansionPanel>
                </IsFeatureDisabledForRearch>
              )}
              <IsFeatureDisabledForRearch feature={'isDeductionsDisabled'}>
                <ExpansionPanel
                  {...expansionPanel.panelProps[newJoinerArrearPaymentMonthExists ? 0 : 1]}
                  onHeaderClick={expansionPanel.onHeaderClick}
                  header={'Deduction'}
                  scrollable={true}
                  className="pt-0"
                  childrenClassName={styles['run-payroll-deduction-expansion-child']}>
                  <Deductions
                    deductions={deductions}
                    onLOPDaysChange={onLOPDaysChange}
                    payrollEntry={payrollEntry}
                    setNonLOPDeductions={setNonLOPDeductions}
                    payrollMonth={payrollMonth}
                  />
                </ExpansionPanel>
              </IsFeatureDisabledForRearch>
              <IsFeatureDisabledForRearch feature="isArrearsDisabled">
                {!newJoinerArrearPaymentMonthExists && (
                  <ExpansionPanel
                    {...expansionPanel.panelProps[2]}
                    onHeaderClick={expansionPanel.onHeaderClick}
                    disabled={isArrearsBeingFetched}
                    header={
                      <>
                        Arrears{' '}
                        {isArrearsBeingFetched ? (
                          <span className="ml-4">
                            <Spinner size={12} />
                          </span>
                        ) : null}
                      </>
                    }
                    scrollable={true}>
                    <Arrears
                      arrearsData={arrearsQuery.data}
                      isArrearBreakdownPopupEnabled={isArrearBreakdownPopupEnabled}
                      isFetchingComplianceArrears={complianceArrearsQuery.isFetching}
                      isPlatformArrears={isPlatformArrears}
                      onAddReverseLOP={onAddReverseLOP}
                      onArrearChange={onArrearChange}
                      onArrearEdit={onArrearEdit}
                      onRlopArrearEdit={onRlopArrearEdit}
                      payrollEntry={payrollEntry}
                      payrollMonth={payrollMonth}
                      lastFinalizedPayrollMonth={lastFinalizedPayrollMonth}
                      salaryRevisionArrears={salaryRevisionArrears}
                      rlopArrears={rlopArrears}
                      combinedArrears={combinedArrears}
                    />
                  </ExpansionPanel>
                )}
              </IsFeatureDisabledForRearch>
              <IsFeatureDisabledForRearch
                feature="isMileStone1Enabled"
                enabledForFeature="isM2S1Enabled">
                <ExpansionPanel
                  {...expansionPanel.panelProps[newJoinerArrearPaymentMonthExists ? 1 : 3]}
                  onHeaderClick={expansionPanel.onHeaderClick}
                  header={'Remark'}>
                  <Remarks remarks={remarks} onRemarkChange={(value) => setRemarks(value)} />
                </ExpansionPanel>
              </IsFeatureDisabledForRearch>
            </ExpansionList>
            <div className="flex gap-[8px]">
              <StandardButton
                isPending={update.isLoading}
                disabled={isActionLoading || isArrearsBeingFetched}
                type={'submit'}
                className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                {doesEditNeedApproval ? 'Request For Approval' : 'Done'}
              </StandardButton>
              {!newJoinerArrearPaymentMonthExists && (
                <>
                  <StandardSecondaryButton
                    onClick={() => {
                      trackXPayrollEvent(`edit_salary.skip_this`);
                      skip.mutate({ payrollId: payrollEntry.id });
                    }}
                    isPending={skip.isLoading}
                    disabled={isActionLoading}
                    className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                    Skip
                  </StandardSecondaryButton>
                  <StandardSecondaryButton
                    onClick={() => {
                      trackXPayrollEvent(`edit_salary.skip_all_except_this`);
                      skipAllExceptThis.mutate({ payrollId: payrollEntry.id });
                    }}
                    isPending={skipAllExceptThis.isLoading}
                    disabled={isActionLoading}
                    className={doesEditNeedApproval ? styles['run-payroll-edit-model-button'] : ''}>
                    Skip All Except This
                  </StandardSecondaryButton>
                </>
              )}
            </div>
          </form>
        </div>
        <PayrollBulkActions />
      </div>
    </Modal>
  );
};

export default EditSalary;
