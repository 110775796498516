import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import api from 'api';
import {
  GetEmployeeAdditionsDeductionsResponseContent,
  LopSourceToDeductionTypeMap,
} from 'api/gql-utils/salaryComponents/types';
import {
  AdditionsV2,
  DeductFrom,
  DeductFromLabelToNumberMap,
  DeductionComponentV2,
  DeductionType,
} from 'components/RunPayroll/utils/types';
import { format } from 'date-fns';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AppError } from 'utils/AppError';
import { dateFormats } from 'utils/Dates';
import { paiseAmountToRupee } from 'utils/Numbers';
import queryKeys from '../components/queries';
import {
  EditPayrollProps,
  PayrollAllowanceV2,
  UpdateAdditionsDeductionsLopPayload,
} from '../types';
import { makeArrayOfMinimumLength } from '../utils';

interface UseAdditionsDeductionsLopProps extends EditPayrollProps {
  onUpdateSuccess: () => void;
}

const defaultDeduction = {
  id: '',
  name: '',
  amount: 0,
  deduct_from: DeductFrom.GROSS_PAY,
  lop_days: '0',
  type: DeductionType.TYPE_MANUAL,
};

const useAdditionsDeductionsLop = ({
  payrollEntry,
  payrollMonth,
  onUpdateSuccess,
}: UseAdditionsDeductionsLopProps) => {
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();
  const [allowanceEntries, setAllowanceEntires] = useState<AdditionsV2[]>([]);
  const [deductions, setDeductions] = useState<DeductionComponentV2>({
    lop_deduction: defaultDeduction,
    non_lop_deductions: Array.from({ length: 2 }, () => defaultDeduction),
    total_deduction: payrollEntry.deductions?.total_deduction ?? 0,
  });
  const initialAllowances = (allowanceEntries: AdditionsV2[]) => {
    const initialItems = allowanceEntries.length <= 2 ? 2 : allowanceEntries.length;

    return Array.from({ length: initialItems }, (_, index) => ({
      id: allowanceEntries[index]?.id ?? '',
      name: allowanceEntries[index]?.name ?? '',
      amount: allowanceEntries[index]?.amount ?? 0,
    }));
  };
  const [allowances, setAllowances] = useState<PayrollAllowanceV2[]>(
    initialAllowances(allowanceEntries),
  );

  const onSuccessHandler = (data: GetEmployeeAdditionsDeductionsResponseContent) => {
    // Extract the data from the additions response and format it into AdditionsV2 type
    let additions = data.additions.map((addition) => {
      return {
        id: addition.componentDetails.id,
        name: addition.componentDetails.name,
        amount: paiseAmountToRupee(addition.amount),
        type: addition.type,
      };
    });
    setAllowanceEntires(additions);
    setAllowances(initialAllowances(additions)); // Set the allowances

    // Extract the data from the deductions and LOP Days response and format it into DeductionsV2 type
    setDeductions((prev) => {
      const nonLOPDeductions = data.deductions.map((deduction) => ({
        id: deduction.componentDetails.id,
        name: deduction.componentDetails.name,
        amount: paiseAmountToRupee(deduction.amount),
        deduct_from:
          DeductFromLabelToNumberMap[
            deduction.componentDetails.settings.deductionDetails.deductFrom ?? 'GROSS_PAY'
          ],
        lop_days: '0',
        type: Number(deduction.type),
      }));

      const firstLopData = data.lopData?.[0];
      return {
        ...prev,
        non_lop_deductions: makeArrayOfMinimumLength(nonLOPDeductions, 2, defaultDeduction),
        lop_deduction: {
          ...prev.lop_deduction,
          type: firstLopData?.source
            ? LopSourceToDeductionTypeMap[firstLopData.source]
            : prev.lop_deduction.type,
          lop_days: firstLopData?.lopDays
            ? String(firstLopData.lopDays)
            : prev.lop_deduction.lop_days,
        },
      };
    });
  };

  const { error, isFetching } = useQuery({
    queryKey: [queryKeys.QUERY_LIST_ADDITIONS_DEDUCTIONS_LOP, payrollMonth, payrollEntry.peopleId],
    queryFn: () =>
      api.salaryComponents.queries.getEmployeeAdditionsDeductions({
        userId: String(payrollEntry.peopleId),
        effectiveFrom: format(new Date(payrollMonth), dateFormats.yearMonthDate),
        effectiveTo: format(new Date(payrollMonth), dateFormats.yearMonthDate),
      }),
    onSuccess: (response) => {
      onSuccessHandler(response.payrollGetEmployeeAdditionsDeductions.data);
    },
    enabled: isM2S1Enabled,
    onError: (error: AppError) => {},
  });

  const updateAdditionsDeductionsLop = useMutation(
    (payload: UpdateAdditionsDeductionsLopPayload) => {
      return api.salaryComponents.mutations.updateEmployeesAdditionsDeductionsLop(payload);
    },
    {
      onSuccess: (response) => {
        onSuccessHandler(response.payrollUpdateEmployeeAdditionsDeductions.data);
        onUpdateSuccess();
      },
      onError: (error: AppError) => {},
    },
  );

  const onAllowanceChange = (updateIndex: number, updates: Partial<PayrollAllowanceV2>) => {
    setAllowances(
      allowances.map((val, i) => {
        if (i === updateIndex) {
          return {
            ...val,
            ...updates,
          };
        }
        return val;
      }),
    );
  };

  const onAllowanceAdd = () => {
    setAllowances((prev) => [
      ...prev,
      {
        id: '',
        amount: 0,
        name: '',
      },
    ]);
  };

  const onAllowanceDelete = (deleteIndex: number) => {
    setAllowances((prev) => prev.filter((_, index) => index !== deleteIndex));
  };

  return {
    allowances,
    onAllowanceChange,
    onAllowanceAdd,
    onAllowanceDelete,
    deductions,
    setDeductions,
    updateAdditionsDeductionsLop,
    isAdditionsDeductionsListLoading: isFetching,
    errorMessage: (error || updateAdditionsDeductionsLop.error)?.message,
  };
};

export default useAdditionsDeductionsLop;
