import { useCallback, useRef, useState } from 'react';

interface UseTimeThresholdProps {
  thresholdMs: number; // Threshold in milliseconds
  onThresholdBreach?: () => void;
}

export const useTimeThreshold = ({ thresholdMs, onThresholdBreach }: UseTimeThresholdProps) => {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const hasBreached = useRef(false);

  const start = useCallback(() => {
    setStartTime(new Date());
    hasBreached.current = false;
  }, []);

  const reset = useCallback(() => {
    setStartTime(null);
    hasBreached.current = false;
  }, []);

  const tick = useCallback(() => {
    if (!startTime || hasBreached.current) return false;

    const currentTime = new Date();
    const timeDiff = currentTime.getTime() - startTime.getTime();

    if (timeDiff >= thresholdMs && !hasBreached.current) {
      hasBreached.current = true;
      onThresholdBreach?.();
      return true;
    }

    return false;
  }, [startTime, thresholdMs, onThresholdBreach]);

  return {
    startTime,
    start,
    reset,
    tick,
    hasBreachedThreshold: hasBreached.current,
    getStartTime: () => startTime,
    setStartTime: (time: Date | null) => setStartTime(time),
  };
};
