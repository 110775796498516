import React, { ReactElement } from 'react';
import { Option, Select } from 'components/ui/Form/Select';

interface FormElementProps {
  onStatusChange: (newStatus: string) => void;
  selectedStatus: string;
}

function StatusFilter({ onStatusChange, selectedStatus }: FormElementProps): ReactElement {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.target.value;
    onStatusChange(newStatus);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-16 w-3/5">
        <div className="flex items-end">
          <label htmlFor="timeline " className="w-4/12 flex-auto pr-10 m-0">
            <Select
              className="m-0"
              id="status"
              name="status"
              value={selectedStatus}
              onChange={handleStatusChange}>
              <Option value={''}>Select Status</Option>
              <Option value={'DISABLED'}>Disabled</Option>
              <Option value={'REGISTERED'}>Success</Option>
              <Option value={'IN_PROGRESS'}>In Progress</Option>
              <Option value={'FAILED'}>Failed</Option>
              <Option value={'NOT_INITIATED'}>Not Initiated</Option>
            </Select>
          </label>
        </div>
      </form>
    </>
  );
}
export default StatusFilter;
