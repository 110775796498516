import { Box, Text } from '@razorpay/blade/components';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface Tab {
  text: ReactNode;
  href: string;
  onClick?: () => void;
  actionableCount?: number;
  isActive?: boolean;
}

export interface TabsPanelProps {
  tabItems: Tab[];
}

const TabsPanel = ({ tabItems }: TabsPanelProps) => {
  return (
    <Box as="nav" display="flex" alignItems="center" gap="spacing.5">
      {tabItems.map(({ text, href, actionableCount, isActive, onClick }, index) => {
        const tabTitle = actionableCount ? `${text} (${actionableCount})` : text;
        return (
          <Link to={href} key={index} onClick={onClick}>
            <Box
              padding={['spacing.6', 'spacing.2']}
              borderBottomWidth="thin"
              borderBottomColor="surface.border.gray.muted"
              position="relative"
              key={index}>
              <Text
                weight="semibold"
                color={isActive ? 'surface.text.staticWhite.normal' : 'surface.text.gray.muted'}>
                {tabTitle}
              </Text>

              {isActive && (
                <Box
                  width="100%"
                  position="absolute"
                  bottom="0px"
                  left="0px"
                  height="1px"
                  backgroundColor="surface.background.primary.intense"
                />
              )}
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};

export { TabsPanel };
