import React, { PropsWithChildren } from 'react';
import greenTick from 'assets/icons/icon_tick.svg';
import redCross from 'assets/icons/icon_cross.svg';

import styles from './index.module.scss';

function Field({ children, isEnabled }: PropsWithChildren<{ isEnabled: boolean }>) {
  return (
    <span className={styles['field']}>
      <img
        src={isEnabled ? greenTick : redCross}
        alt=""
        className={isEnabled ? styles['success-icon'] : styles['cross-icon']}
      />
      {children}
    </span>
  );
}

export default Field;
