import React, { ReactElement } from 'react';
import RightSidebar from '../../ui/RightSidebar/index';
import styles from '../index.module.scss';
import { LoanCalculatorResponseSchemaContract } from '../../../schemas/LoanCalculatorResponseSchema';
import Spinner from 'components/ui/Spinner';
import { toIndianCurrencyString } from 'utils/Numbers';

export default function LoanPreviewBlock({ loanPreview, isLoading }: { loanPreview: LoanCalculatorResponseSchemaContract | null, isLoading: boolean }): ReactElement {

    return (
        <RightSidebar>
            <div className='fixed'>
                <div className={styles['preview']}>
                    <div className={styles['preview-heading']}>
                        Loan Preview 🔎
                    </div>
                    <div>
                        <div className={styles['preview-subheading']}>
                            Total Repayment Amount
                        </div>
                        <div className={styles['preview-amount']}>
                            {isLoading ? <Spinner size={14} /> : (loanPreview ? toIndianCurrencyString(loanPreview?.total_amount) : '₹ 0')}
                        </div>
                    </div>
                    <div>
                        <div className={styles['preview-subheading']}>
                            Monthly EMI
                        </div>
                        <div className={styles['preview-amount']}>
                            {isLoading ? <Spinner size={14} /> : (loanPreview ? toIndianCurrencyString(loanPreview?.monthly_total_emi) : '₹ 0')}
                        </div>
                    </div>
                    <div>
                        <div className={styles['preview-subheading']}>
                            Total Interest Amount
                        </div>
                        <div className={styles['preview-amount']}>
                            {isLoading ? <Spinner size={14} /> : (loanPreview ? toIndianCurrencyString(loanPreview?.total_interest) : '₹ 0')}
                        </div>
                    </div>
                </div>
            </div>
        </RightSidebar>
    );
}