import add from 'date-fns/add';
import { getQuarterFromDate } from 'utils/Dates';

export const getNextFourQuarters = () => {
  const quarters: { quarter: number; financialYear: number }[] = [];
  let date = new Date();

  for (let i = 0; i < 4; i++) {
    date = add(date, { months: 3 });
    const quarter = getQuarterFromDate(date);
    quarters.push(quarter);
  }

  return quarters;
};
