import KYC from 'constants/kyc';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useReduxSelector from 'utils/useReduxSelector';

const useKycStatus = () => {
  const kycStatus = useReduxSelector(loggedInUserSelectors.kycStatus);
  const isKycVerified = kycStatus === KYC.STATUS_VERIFIED;
  const isKycRejected = kycStatus === KYC.STATUS_REJECTED;
  const isKycNotInitiated = kycStatus === KYC.STATUS_NOT_INITIATED;
  return { kycStatus, isKycNotInitiated, isKycRejected, isKycVerified };
};

export default useKycStatus;
