import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from '../../../reducers/loggedInUser/selectors';
import { useParams } from 'react-router-dom';
import { FlexiWalletBudget } from '../../../api/flexiPartnerDetails';
import { useQuery } from 'react-query';
import { FETCH_EMPLOYEE_FLEXI_PARTNER_DETAILS } from './queries';
import api from '../../../api';
import { ZaggleIntro } from './ZaggleIntro';
import EmployeeDeclarationForm from './EmployeeDeclarationForm';
import Breadcrumb from '../../ui/Breadcrumb';
import { DeliveryType, FlexiPartners } from '../../../constants/flexibenefits';
import { getFlexiPartnerName } from './utils';
import {
  UpdateUserOrgFlexiPartnerDetailsSchemaContract,
} from '../../../schemas/UpdateUserOrgFlexiPartnerDetailsSchema';
import { getTodayInYmdFormat } from '../../../utils/Dates';
import Spinner from '../../ui/Spinner';
import Errors from '../../ui/Form/Errors';
import { AppError } from '../../../utils/AppError';
import { WarningAlert } from '../../ui/Alert';

export function PartnerFlexiBenefitsEmployee(): ReactElement {

  let peopleOrgId = useSelector(loggedInUserSelectors.peopleOrgId);
  let peopleId = useSelector(loggedInUserSelectors.peopleId);
  const urlParams = useParams<{ employeeId?: string }>();
  const userOrgIDFromUrlParam = urlParams.employeeId ? +urlParams.employeeId : null;
  peopleOrgId = userOrgIDFromUrlParam ? userOrgIDFromUrlParam : peopleOrgId;
  let [detailsToSync, setDetailsToSync] = useState<UpdateUserOrgFlexiPartnerDetailsSchemaContract>(() => {
    return {
      optedOn: getTodayInYmdFormat(),
      walletBudgets: [],
      name: '',
      dateOfBirth: '',
      phoneNumber: '',
      pan: '',
      title: '',
      deliveryType: DeliveryType.DISPATCH_TO_EMPLOYEE,
      cardDeliveryDetails: {
        address: '',
        pinCode: '',
        city: '',
        state: '',
      },
    };
  });
  const { isLoading, error, data } = useQuery(FETCH_EMPLOYEE_FLEXI_PARTNER_DETAILS, () => {
    if (!peopleOrgId) {
      return null;
    }
    return api.flexiPartnerDetails.fetchEmployeeFlexiPartnerDetails(peopleOrgId);
  }, {
    enabled: !!peopleOrgId,
    onSuccess: (data) => {

      if (!data) {
        return null;
      }

      let initDetails: UpdateUserOrgFlexiPartnerDetailsSchemaContract = { ...detailsToSync };

      initDetails = {
        ...data,
        optedOn: data.optedOn ? data.optedOn : detailsToSync.optedOn,
        walletBudgets: [],
        name: data.name ?? '',
        pan: data.pan ?? '',
        phoneNumber: data.phoneNumber ?? '',
        deliveryType: data.deliveryType ?? DeliveryType.DISPATCH_TO_EMPLOYEE,
        email: data.email ?? '',
        title: data.title ?? '',
        dateOfBirth: data.dateOfBirth ?? '',
        cardDeliveryDetails: { ...data.cardDeliveryDetails },
      };

      for (let wallet of data.walletBudgets) {
        let newWallet: FlexiWalletBudget = {
          id: wallet.id,
          amount: wallet.amount,
          compliance: {
            case: wallet.currentCompliance.case,
          },
        };
        initDetails.walletBudgets.push(newWallet);
      }

      setDetailsToSync(initDetails);
    },
    onError: (error: typeof AppError) => {
    }
  });

  const [showIntro, setShowIntro] = useState(true);
  if (isLoading) return <div className={'flex justify-center'}>
    <Spinner size={40}/>
  </div>;

  if (error) return <WarningAlert><Errors errorResponse={error}/></WarningAlert>;
  if (!data?.partnerId) {
    return <></>;
  }
  const partnerName = getFlexiPartnerName(data?.partnerId);

  if (!data?.optedOn && showIntro) {
    if (data?.partnerId === FlexiPartners.ZAGGLE) {
      return <ZaggleIntro onStart={() => {
        setShowIntro(false);
      }} />;
    }
  }


  if (data && peopleOrgId) {
    return <div>
      <Breadcrumb
        name={partnerName + ' flexible benefits'}
        urlMaps={{
          'Tax deductions': '/taxDeductions?userId=' + peopleId,
        }}
      />
      <EmployeeDeclarationForm
        peopleOrgId={peopleOrgId}
        partnerName={partnerName}
        employeeFlexiBenefitsDetails={data}
        detailsToSync={detailsToSync}
        setDetailsToSync={setDetailsToSync} />
    </div>;
  }

  return <></>;
}