import {
  Alert,
  ArrowUpRightIcon,
  Box,
  Button,
  Spinner,
  Text,
  Heading,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import KYC from 'constants/kyc';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { getFlashMessage } from 'utils/FlashFields';
import SuccessViewModal from './SuccessViewModal';
import SidebarHeader from './components/SidebarHeader';
import { kycErrorMapping } from './constants';
import useKycInfo from './hooks/useKycInfo';
import { useKycValidations } from './hooks/useKycValidations';
import {
  KycPageMainViewWrapper,
  KycPageSideViewWrapper,
  KycPageWrapper,
  KycViewContent,
  KycViewHeader,
  SpanWrapper,
} from './styles';
import { KycErrorType, OrgType } from './types';

const UserSelectionView = () => {
  const history = useHistory();
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const orgType = org?.type as OrgType;
  const isL1KycCompleted = org?.levelOneKyc?.status === true;
  const { kycInfo } = useKycInfo();
  const { status: kycStatus, autoKyc } = kycInfo.data?.kycInfo || {};
  const { verificationType, status: isSSOVerified } = autoKyc || {};
  const { isBankAccountVerified, isMethodPennyTesting, isBankAccountDetailsAdded } =
    useKycValidations({
      kycStatus,
    });

  const isKycFlowManual = verificationType === 'manual';

  const responseType = getFlashMessage('sso_response_type') as KycErrorType;
  // Handling the case of redirecting back with response
  const [isRzpUser, setIsRzpUser] = useState(responseType ? true : false);

  const handleIsRzpCustomerClick = () => {
    if (isRzpUser) {
      window.location.href = '/verifyWithRazorpay';
    }
    history.replace({
      search: '',
    });
    setIsRzpUser(true);
  };

  const handleIsNotRzpCustomerClick = () => {
    history.push(routePaths.kyc.documentsUpload);
  };

  const title = isRzpUser ? (
    'Awesome! How would you like to verify?'
  ) : (
    <SpanWrapper>
      Are you an existing Razorpay user (apart
      <br /> from Payroll)?
    </SpanWrapper>
  );

  const isSSOVerificationSuccess = isSSOVerified === true || responseType === 'success';

  const isKycVerified = org?.kycStatus === KYC.STATUS_VERIFIED || kycStatus === KYC.STATUS_VERIFIED;

  const error = isKycVerified ? null : kycErrorMapping[responseType];

  if ((!isL1KycCompleted || !orgType) && !kycInfo.isLoading) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  if (!isBankAccountDetailsAdded && !kycInfo.isFetching) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  if (isBankAccountDetailsAdded && !kycInfo.isFetching) {
    if (isMethodPennyTesting && !isBankAccountVerified) {
      return <Redirect to={routePaths.kyc.bankDetails} />;
    }
  }

  const isVerificationInprogress = isKycFlowManual && isSSOVerificationSuccess;

  const getContent = () => {
    return (
      (<Box height="100vh" display={'grid'} gridTemplateRows={'auto 1fr auto'}>
        <KycViewHeader marginBottom={'64px'}>
          <Heading size="xlarge">{title}</Heading>
          {isVerificationInprogress && (
            <Alert
              marginTop={'spacing.4'}
              isDismissible={false}
              title="Verification in Progress"
              description="Hold on tight while we verify your account!"
            />
          )}
          {error && isRzpUser ? (
            <Alert
              marginTop={'spacing.4'}
              isDismissible={false}
              title="Verification failed"
              description={error}
              color="negative"
            />
          ) : null}
        </KycViewHeader>
        <KycViewContent>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap="spacing.5"
            paddingTop={'spacing.2'}
            width={'max-content'}>
            <Button
              size="large"
              onClick={handleIsRzpCustomerClick}
              isLoading={isVerificationInprogress}>
              {isRzpUser
                ? error
                  ? 'Retry verify via Razorpay'
                  : 'Verify via Razorpay'
                : "Yes, I've used Razorpay products before"}
            </Button>
            <Button
              size="large"
              variant="secondary"
              iconPosition="right"
              icon={ArrowUpRightIcon}
              isDisabled={isVerificationInprogress}
              onClick={handleIsNotRzpCustomerClick}>
              {isRzpUser ? 'Upload my docs manually' : "No, haven't used Razorpay before"}
            </Button>
          </Box>
        </KycViewContent>
      </Box>)
    );
  };

  return (
    (<KycPageWrapper>
      <KycPageSideViewWrapper>
        <Box
          overflowY={'auto'}
          display={'flex'}
          flexDirection={'column'}
          paddingY={'spacing.9'}
          paddingX={'spacing.10'}>
          <SidebarHeader
            onBackClick={() => (window.location.href = '/dashboard')}
            progress={[
              isBankAccountVerified ? 'COMPLETED' : 'IN_PROGRESS',
              isKycVerified ? 'COMPLETED' : 'IN_PROGRESS',
            ]}
          />
          <Box display={'flex'} gap={'spacing.2'} alignItems={'center'} marginTop={'spacing.4'}>
            <Text color="surface.text.gray.muted">Skip KYC if you’re an existing user at Razorpay</Text>
          </Box>
        </Box>
      </KycPageSideViewWrapper>
      <KycPageMainViewWrapper>
        {kycInfo.isLoading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
          </Box>
        ) : (
          getContent()
        )}
      </KycPageMainViewWrapper>
      <SuccessViewModal isOpen={isKycVerified} orgName={org?.name || ''} />
    </KycPageWrapper>)
  );
};

export default UserSelectionView;
