import { useQuery, UseQueryOptions } from 'react-query';
import attendance from 'api/attendance';
import { TodaysAttendanceResponse } from 'schemas/Attendance';
import { CACHE_TTL } from 'utils/cache';
import { useSearchParams } from 'hooks/useSearchParams';
import { useSelector } from 'react-redux';
import { peopleId } from 'reducers/loggedInUser/selectors';
import { AppError } from 'utils/AppError';

type TodayAttendanceQueryKey = ['attendance-today', userId: string | null];

interface UseTodaysAttendanceDataOptions<TData = TodaysAttendanceResponse>
  extends Omit<
    UseQueryOptions<TodaysAttendanceResponse, AppError, TData, TodayAttendanceQueryKey>,
    'queryKey' | 'queryFn'
  > {}

export function useTodaysAttendanceData<TData = TodaysAttendanceResponse>(
  options: UseTodaysAttendanceDataOptions<TData> = {},
) {
  const { urlParams } = useSearchParams();
  const loggedInUserId = useSelector(peopleId);
  const userId = urlParams.get('user_id') || (loggedInUserId ? loggedInUserId.toString() : null);

  const queryKey: TodayAttendanceQueryKey = ['attendance-today', userId];

  const queryResult = useQuery<TodaysAttendanceResponse, AppError, TData, TodayAttendanceQueryKey>(
    queryKey,
    () => attendance.fetchTodaysAttendance(userId),
    {
      staleTime: CACHE_TTL.MEDIUM,
      ...options,
      onError: (error: AppError) => {
        options.onError?.(error);
      },
    },
  );

  return {
    ...queryResult,
    userId,
    isForLoggedInUser: userId === loggedInUserId?.toString(),
  };
}
