import { useEffect } from 'react';

const useStickyColumns = () => {
  useEffect(() => {
    const calculateStickyColumns = () => {
      const tableMap: Record<string, any> = {};
      const stickyCols = document.querySelectorAll('.sticky-col');

      stickyCols.forEach((col) => {
        const tableId = col.closest('table')?.getAttribute('id');
        if (tableId) {
          if (!tableMap[tableId]) {
            tableMap[tableId] = { 0: 0 };
          }

          const tableColIndex = (col as HTMLTableCellElement).cellIndex;
          const precalculatedAdjustment = tableMap[tableId][tableColIndex];
          if (precalculatedAdjustment) {
            (col as HTMLElement).style.left = `${precalculatedAdjustment || 0}px`;
          }
          if (!tableMap[tableId][tableColIndex + 1]) {
            const currentColWidth = (col as HTMLElement).getBoundingClientRect().width;
            tableMap[tableId][tableColIndex + 1] = currentColWidth + precalculatedAdjustment;
          }
        }
      });
    };

    calculateStickyColumns();

    window.addEventListener('resize', calculateStickyColumns);

    return () => {
      window.removeEventListener('resize', calculateStickyColumns);
    };
  }, []);
};

export default useStickyColumns;
