import React, { createContext, useContext, useState } from 'react';
import { complianceActionsMap } from 'components/RunPayroll/ComplianceActions/data';
import { isObjectEmpty } from 'utils/Objects';

export type ComplianceAction = 'run-payroll' | 'pay-pt';

type SelectedComplianceAction = {
  actionName: ComplianceAction;
};

type ShowComplianceActionDetails =
  | {
      actionName: ComplianceAction;
    }
  | undefined;

export type ComplianceActionsContextData = {
  selectedComplianceAction: SelectedComplianceAction;
  setSelectedComplianceAction: React.Dispatch<SelectedComplianceAction>;
  showComplianceActionDetails: ShowComplianceActionDetails;
  setShowComplianceActionDetails: React.Dispatch<ShowComplianceActionDetails>;
};

export const ComplianceActionsContext = createContext<ComplianceActionsContextData>(
  {} as ComplianceActionsContextData,
);

export const ComplianceActionsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [selectedComplianceAction, setSelectedComplianceAction] =
    useState<SelectedComplianceAction>({
      actionName: complianceActionsMap.runPayroll,
    });

  const [showComplianceActionDetails, setShowComplianceActionDetails] =
    useState<ShowComplianceActionDetails>();

  const memoisedContextValue = React.useMemo(() => {
    return {
      selectedComplianceAction,
      setSelectedComplianceAction,
      showComplianceActionDetails,
      setShowComplianceActionDetails,
    };
  }, [selectedComplianceAction, showComplianceActionDetails]);

  return (
    <ComplianceActionsContext.Provider value={memoisedContextValue}>
      {children}
    </ComplianceActionsContext.Provider>
  );
};

export const useComplianceActionsContext = () => {
  const value = useContext(ComplianceActionsContext);
  if (!value || isObjectEmpty(value)) {
    throw new Error(
      'useComplianceActionsContext is supposed to be used only inside the ComplianceActionsContextProvider',
    );
  }
  return value;
};
