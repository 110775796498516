import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import React, { ReactElement } from 'react';
import SpringVerifyIntro from './components/SpringVerifyIntro';
import styles from './index.module.scss';

function SpringVerify(): ReactElement {
  return (
    <div className={`${styles['sectionWidth']}`}>
      <SpringVerifyIntro />
    </div>
  );
}

function SpringVerifySettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <MainCase center>
        <SpringVerify/>
      </MainCase>
    </PageWrapper>
  );
}

export default SpringVerifySettingsWithPageWrapper;
