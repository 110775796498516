
import React, { ReactElement, useState, useEffect } from 'react';
import { TableWithLoader } from '../../ui/Table/index';
import styles from '../index.module.scss';
import whiteSortArrow from 'assets/icon--sort-white.svg';
import blueSortArrow from 'assets/icon--sort-blue.svg';
import { getStaticMediaUrl } from '../../../utils/Urls';
import Pill, { pillClassTypeMap } from 'components/ui/Pill';
import { toIndianCurrencyString } from 'utils/Numbers';
import { BulkUploadStagingDetailsSchemaContract } from 'schemas/BulkUploadStagingDetailsSchema';
import Pagination from 'components/ui/Pagination/index';
import Spinner from '../../ui/Spinner/index';
import { GET_BULK_UPLOAD_STAGING_RECORD } from '../queries/queries';
import { useQuery } from 'react-query';
import api from 'api';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import ErrorCard from '../components/ErrorCard';
import { AppError } from 'utils/AppError';



function getStatusPill(action?: string) {
  switch (action) {
    case 'Addition':
      return <Pill content='Addition' type={pillClassTypeMap.success} />;

    case 'Gross pay deduction':
    case 'Deduction':
      return <Pill content='Gross pay deduction' type={pillClassTypeMap.warning} />;

    case 'Net pay deduction':
      return <Pill content='Net pay deduction' type={pillClassTypeMap.warning} />;

    case 'Loss of Pay':
      return <Pill content='Loss Of Pay' type={pillClassTypeMap.danger} />;

  }
}


export default function PreviewTable({
  bulkUploadRecord
}:
  {
    bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
  }): ReactElement {

  const [rowLimit, setRowLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(1);
  const [bulkUploadStagingRecord, setBulkUploadStagingRecord] = useState<BulkUploadStagingDetailsSchemaContract | null>(null);


  const { isLoading, refetch, isRefetching, error, data } = useQuery(GET_BULK_UPLOAD_STAGING_RECORD, () => {

    if (!bulkUploadRecord) {
      return null;
    }

    return api.bulkUploads.fetchBulkUploadStagingRecord(bulkUploadRecord ? bulkUploadRecord.id : 0, page, rowLimit);

  }, {
    onSuccess: (data: BulkUploadStagingDetailsSchemaContract) => {
      setBulkUploadStagingRecord(data);
      setTotalRows(data.bulk_uploads_staging?.total);
      setTotalPages(Math.ceil(data.bulk_uploads_staging?.total / rowLimit));
    },
    onError: (error: typeof AppError) => {
      console.error("Error on fetching org's bulk upload preview data : ", error);
    }
  });

  useEffect(() => {
    refetch();
  }, [rowLimit, page, bulkUploadRecord?.id]);

  if (isLoading || isRefetching) {
    return (
      <div className={'flex justify-center'}>
        <Spinner size={40} />
      </div>);
  }

  if(error){
    return <ErrorCard error={error} buttonText= {'Back to Dashboard'} onClick={() => {
      window.location.href = '/dashboard';
    }} />
  }


  return (
    <>
      <TableWithLoader isLoading={isLoading} showAlternateColors={true} className={`w-full mt-10 ${styles['preview-table']} `}>
        <tbody>
          <tr className={styles['dynamic-row']}>
            <th className="text-left">ID</th>
            <th className="text-left">Name</th>
            <th className="text-left">Email ID</th>
            <th className={`${styles['sort-header']} text-left`}>
              Action
              <img
                src={getStaticMediaUrl(whiteSortArrow)}
                alt=""
              />
            </th>
            <th className="text-left">No. of Days (LOP)</th>
            <th className="text-left">Amount</th>
            <th className="text-left">Label</th>
          </tr>
          {bulkUploadStagingRecord && bulkUploadStagingRecord?.bulk_uploads_staging.data?.map((stagingRecord) => (
            <tr key={stagingRecord.id}>
              <td>{stagingRecord.file_row?.commonProperties?.employeeId}</td>
              <td>{stagingRecord.file_row?.commonProperties?.employeeName}</td>
              <td>{stagingRecord.file_row?.commonProperties?.email}</td>
              <td>{getStatusPill(stagingRecord.file_row?.type)}</td>
              <td>{stagingRecord.file_row?.lopDays}</td>
              <td>{stagingRecord.file_row?.amount ? toIndianCurrencyString(parseInt(stagingRecord.file_row?.amount), true) : '-'}</td>
              <td>{stagingRecord.file_row?.label}</td>
            </tr>
          ))}
          {!bulkUploadStagingRecord && <tr>
            <td className='text-center' colSpan={7}>No data found</td>
          </tr>}
        </tbody>
      </TableWithLoader>
      <Pagination
        className='mt-4'
        totalRows={totalRows}
        limitOptions={[5, 10, 15, 20]}
        limit={rowLimit}
        onLimitChange={setRowLimit}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={setPage}
      />
    </>
  );
}