import {
  CheckboxProps,
  RadioGroupProps,
  SelectInputProps,
  SwitchProps,
  TextInputProps,
  DatePickerProps,
} from '@razorpay/blade/components';
import { FormContextValue } from './types';

type RegisterTextFieldReturnType = Pick<
  TextInputProps,
  'name' | 'onChange' | 'errorText' | 'validationState' | 'value' | 'isDisabled'
>;

type RegisterSelectReturnType = Pick<
  SelectInputProps,
  'name' | 'onChange' | 'errorText' | 'validationState' | 'value' | 'isDisabled'
>;

type RegisterCheckboxReturnType = Pick<
  CheckboxProps,
  'name' | 'onChange' | 'errorText' | 'validationState' | 'isChecked' | 'isDisabled'
>;

type RegisterSwitchReturnType = Pick<SwitchProps, 'name' | 'onChange' | 'isChecked' | 'isDisabled'>;

type RegisterRadioGroupReturnType = Pick<
  RadioGroupProps,
  'name' | 'onChange' | 'errorText' | 'validationState' | 'value' | 'isDisabled'
>;

type RegisterDateRangePickerReturnType = Pick<
  DatePickerProps<'range'>,
  'name' | 'onChange' | 'value' | 'isDisabled'
>;

export function useRegister<T>(context: FormContextValue<T>) {
  const { setFieldValue, errors, formData, isSubmitting } = context;

  function register<
    K extends 'text' | 'select' | 'checkbox' | 'switch' | 'radiogroup' | 'daterange' = 'text',
  >(
    name: keyof T,
    type: K = 'text' as K,
  ): K extends 'text'
    ? RegisterTextFieldReturnType
    : K extends 'select'
    ? RegisterSelectReturnType
    : K extends 'checkbox'
    ? RegisterCheckboxReturnType
    : K extends 'switch'
    ? RegisterSwitchReturnType
    : K extends 'radiogroup'
    ? RegisterRadioGroupReturnType
    : K extends 'daterange'
    ? RegisterDateRangePickerReturnType
    : RegisterTextFieldReturnType {
    const fieldError = errors[name];
    const value = formData[name];

    switch (type) {
      case 'text':
        return {
          name,
          errorText: fieldError,
          validationState: fieldError ? 'error' : 'none',
          value: value ?? '',
          isDisabled: isSubmitting,
          onChange: (props) => {
            setFieldValue(name, props.value);
          },
        } as RegisterTextFieldReturnType as any;
      case 'select':
        return {
          name,
          errorText: fieldError,
          validationState: fieldError ? 'error' : 'none',
          value: value ?? null,
          isDisabled: isSubmitting,
          onChange: (props) => {
            setFieldValue(name, props.values);
          },
        } as RegisterSelectReturnType as any;
      case 'checkbox':
        return {
          name,
          errorText: fieldError,
          validationState: fieldError ? 'error' : 'none',
          value: value ?? '',
          isDisabled: isSubmitting,
          onChange: (props) => {
            setFieldValue(name, props.isChecked);
          },
        } as RegisterCheckboxReturnType as any;
      case 'switch':
        return {
          name,
          isChecked: value,
          isDisabled: isSubmitting,
          onChange: (props) => {
            setFieldValue(name, props.isChecked);
          },
        } as RegisterSwitchReturnType as any;
      case 'radiogroup':
        return {
          name,
          errorText: fieldError,
          validationState: fieldError ? 'error' : 'none',
          value: value ?? '',
          isDisabled: isSubmitting,
          onChange: (props) => {
            setFieldValue(name, props.value);
          },
        } as RegisterRadioGroupReturnType as any;
      case 'daterange':
        return {
          name,
          errorText: fieldError, // TODO: needs to support date range
          validationState: fieldError ? 'error' : 'none',
          value: value,
          isDisabled: isSubmitting,
          onChange: (range) => {
            setFieldValue(name, range);
          },
        } as RegisterRadioGroupReturnType as any;
      default:
        throw new Error('Unsupported field type');
    }
  }

  return { register };
}
