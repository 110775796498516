import React from "react";
import {getStaticMediaUrl} from "../../../../utils/Urls";

export function ZaggleIntroHowDoesItWorkItem({
                                                 description,
                                                 iconSrc,
                                             }: { iconSrc: string, description: React.ReactNode }) {
    return (<li className={'flex md:flex-col h-32 md:h-auto text-5xl w-1/3'}>
        <img className={'h-24 md:mx-auto my-auto'} src={getStaticMediaUrl(iconSrc)}/>
        <div className={'md:text-center text-3xl p-5 text-1.5xl'}>
            {description}
        </div>
    </li>);
}