import React from 'react';
import { Box, Heading, Text, Spinner } from '@razorpay/blade/components';
import { SideBarItemsType } from './types';

type SideBarBladeProps = {
  sidebarItems: SideBarItemsType;
  isLoading?: boolean;
};

const SideBarBlade = ({ sidebarItems, isLoading }: SideBarBladeProps) => {
  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <Spinner size="large" accessibilityLabel="loading" />
      </Box>
    );
  }

  return (
    (<Box
      display="flex"
      gap="36px"
      width="280px"
      right="spacing.0"
      paddingLeft="40px"
      paddingRight="40px"
      flexDirection={'column'}
      position="absolute"
      paddingTop="80px"
      height="100%"
      borderLeftColor="surface.border.gray.subtle"
      borderLeftWidth="thin"
      backgroundColor="surface.background.gray.subtle">
      {sidebarItems.map((item) => {
        return (
          (<Box key={item.heading}>
            <Text marginBottom="12px" size="large">{item.heading}</Text>
            <Box display="flex" flexDirection="column" gap="spacing.4">
              {item.subHeadings.map((subItem) => {
                return (
                  (<Box key={subItem.subHeadingName}>
                    <Text marginBottom="spacing.1" color="surface.text.gray.muted">
                      {subItem.subHeadingName}
                    </Text>
                    <Text weight="semibold" color="surface.text.gray.subtle">
                      {subItem.subHeadingValue}
                    </Text>
                  </Box>)
                );
              })}
            </Box>
          </Box>)
        );
      })}
    </Box>)
  );
};

export default SideBarBlade;
