import React from 'react';
import { Box, Divider, Skeleton } from '@razorpay/blade/components';

const SettingsCardSkeleton = () => {
  return (
    <Box borderColor="surface.border.gray.normal" borderWidth="thin" borderRadius="medium">
      <Box padding="spacing.7" display="flex" flexDirection="column" gap="spacing.7">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap="spacing.4">
            <Skeleton width="spacing.8" height="spacing.7" />
            <Skeleton width="293px" height="spacing.7" />
          </Box>
          <Skeleton width="150px" height="spacing.5" />
        </Box>
        <Skeleton height="spacing.9" flexGrow="1" />
      </Box>
      <Divider width="100%" />
      <Box padding="spacing.7" display="flex">
        <Skeleton height="spacing.6" flexGrow={1} />
      </Box>
    </Box>
  );
};

const HrmsSettingsSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap="spacing.9" width="592px">
      <Box display="flex" flexDirection="column" gap="spacing.3">
        <Skeleton width="100%" height="spacing.8" />
        <Skeleton width="495px" height="spacing.6" />
      </Box>
      <Box display="flex" flexDirection="column" gap="spacing.7">
        <SettingsCardSkeleton />
        <SettingsCardSkeleton />
      </Box>
    </Box>
  );
};

export { HrmsSettingsSkeleton };
