export const mockPredefinedComponents = [
  {
    id: 'sal_' + Math.random().toString(36).substring(2, 9),
    name: 'Basic Salary',
    amount: '50',
    type: 'Percentage',
    taxable: 'Yes',
    isComponentEditDisabled: true,
    isTypeEditDisabled: true,
  },
  {
    id: 'da_' + Math.random().toString(36).substring(2, 9),
    name: 'DA',
    amount: '0',
    type: 'Percentage',
    taxable: 'Yes',
  },
  {
    id: 'hra_' + Math.random().toString(36).substring(2, 9),
    name: 'House rent allowance',
    amount: '25',
    type: 'Percentage',
    taxable: 'Partially',
    isTaxabilityEditDisabled: true,
  },
  {
    id: 'lta_' + Math.random().toString(36).substring(2, 9),
    name: 'LTA',
    amount: '15',
    type: 'Percentage',
    taxable: 'Yes',
  },
  {
    id: 'spl_' + Math.random().toString(36).substring(2, 9),
    name: 'Special allowance',
    amount: 'Residual component',
    type: '',
    taxable: 'Yes',
    info: 'Special Allowance is a residual component of salary that is left after allocations are made for all other components.',
    isComponentEditDisabled: true,
    isAmountEditDisabled: true,
  },
];
