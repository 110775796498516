import LongPoll, { PollStatuses } from './longPoll';
import { showToastViaEvent, closeToastViaEvent } from './ToastEvents';
import api from '../api/index';
import { BulkUploadStatus } from '../constants/bulkUpload';
import { BulkUploadDetailsSchemaContract } from '../schemas/BulkUploadDetailsSchema';
import { PollingAction } from 'types';

function pollForBulkUploads(id: number,
    action: PollingAction,
    tries: number,
    interval: number,
    setBulkUploadRecord: (bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null) => void,
    onSuccess?: () => void,
    onFailure?: () => void) {
    sessionStorage.setItem('bulkUploadPollId', String(id));

    const poll = new LongPoll({
        pollFunction: async () => {
            if (sessionStorage.getItem('bulkUploadPollId')) {
                const apiResponse = await api.bulkUploads.fetchBulkUploadRecord(id);

                setBulkUploadRecord(apiResponse[0]);

                switch (apiResponse[0].status) {
                    case BulkUploadStatus.STATUS_VALIDATION_FAILED:
                        throw 'File upload failed';

                    case BulkUploadStatus.STATUS_EXECUTION_FAILED:
                        throw 'Bulk upload execution failed';

                    case BulkUploadStatus.STATUS_VALIDATION_SUCCESS:
                    case BulkUploadStatus.STATUS_EXECUTION_SUCCESS:
                        return {
                            status: PollStatuses.POLL_STATUS_SUCCESS,
                            data: apiResponse[0]
                        };

                    case BulkUploadStatus.STATUS_CANCELED:
                        return {
                            status: PollStatuses.POLL_STATUS_CANCELLED,
                        };

                    default:
                        return {
                            status: PollStatuses.POLL_STATUS_PROCESSING,
                            data: apiResponse[0]
                        };
                }

            } 
            return {
                status: PollStatuses.POLL_STATUS_CANCELLED,
            };
        },
        successCallback: (data: BulkUploadDetailsSchemaContract[0]) => {
            sessionStorage.removeItem('bulkUploadPollId');
            closeToastViaEvent('bulk-upload-' + id);
            if (action === 'file-upload') {
                showToastViaEvent({
                    text: 'Your file upload was successful',
                    timeout: 5000,
                    type: 'success',
                    icon: 'success',
                });
            }
            else if (action === 'file-execution') {
                showToastViaEvent({
                    text: 'Your bulk execution was successful',
                    timeout: 5000,
                    type: 'success',
                    icon: 'success',
                });
            }


            if (onSuccess && (data.status === BulkUploadStatus.STATUS_VALIDATION_SUCCESS || data.status === BulkUploadStatus.STATUS_EXECUTION_SUCCESS)) {
                onSuccess();
            }

            else if (onFailure && (data.status === BulkUploadStatus.STATUS_VALIDATION_FAILED || data.status === BulkUploadStatus.STATUS_EXECUTION_FAILED)) {
                onFailure();
            }

        },
        failureCallback: (e) => {
            sessionStorage.removeItem('bulkUploadPollId');
            const error = e || 'There was an error performing this bulk action';
            closeToastViaEvent('bulk-upload-' + id);
            showToastViaEvent({
                text: error,
                timeout: 5000,
                type: 'danger',
                icon: 'info',
            });
        },
        cancelCallback: () => {
            sessionStorage.removeItem('bulkUploadPollId');
            setBulkUploadRecord(null);
        },
        tries: tries, // 200 times at an interval of 3500 = 12 minutes
        pollInterval: interval,
    });


    poll.start();
}

export default pollForBulkUploads;