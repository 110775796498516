import {
  ActionList,
  ActionListItem,
  Amount,
  Box,
  Dropdown,
  DropdownOverlay,
  Link,
  SearchIcon,
  SelectInput,
  Text,
  TextInput,
} from '@razorpay/blade/components';
import { useFilters } from 'hooks/useFilters';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { debounceV2 } from 'utils/Debounce';
import { NetpayOnHoldListFiltersMap } from './constants';
import { NetpayOnHoldListFilterTypes } from './types';
import { getLocationNamesFromKeys } from './utils';
import FilterTag from 'components/ui/FilterTag/FilterTag';

const Filters = ({ balance }: { balance: number }) => {
  const departments = useSelector(loggedInUserSelectors.departments);
  const locations = useSelector(loggedInUserSelectors.locations);
  const [search, setSearch] = useState('');

  const { currentFilters, applyFilter, removeFilter, isAnyFilterApplied } = useFilters<NetpayOnHoldListFilterTypes>({
    filtersMap: NetpayOnHoldListFiltersMap,
    multipleValueFilters: [
      NetpayOnHoldListFiltersMap.locations,
      NetpayOnHoldListFiltersMap.departments,
    ],
  });
  const selectedLocations =
    (currentFilters[NetpayOnHoldListFiltersMap.locations] as string[]) ?? [];
  const selectedDepartments =
    (currentFilters[NetpayOnHoldListFiltersMap.departments] as string[]) ?? [];

  const showFilterTags = isAnyFilterApplied || !!search;

  const handleClearSearch = () => {
    setSearch('');
  };

  const debouncedSearch = debounceV2(async (searchText: string) => {
    applyFilter(NetpayOnHoldListFiltersMap.search, searchText);
  }, 1000);

  useEffect(() => {
    if (search === '') {
      debouncedSearch.cancel();
      removeFilter(NetpayOnHoldListFiltersMap.search);
      return;
    }

    debouncedSearch(search);
  }, [search]);

  return (
    (<Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap="spacing.5">
        <Box display="flex" gap="spacing.5" flexWrap="wrap">
          <Box width="275px">
            <TextInput
              label=""
              showClearButton={true}
              icon={SearchIcon}
              placeholder="Search via employee name/Emp ID"
              onClearButtonClick={handleClearSearch}
              value={search}
              onChange={(e) => setSearch(e.value || '')}
            />
          </Box>
          <Box width="230px">
            <Dropdown selectionType="multiple">
              <SelectInput
                label=""
                maxRows="single"
                value={currentFilters[NetpayOnHoldListFiltersMap.locations] ?? []}
                placeholder="Location"
                onChange={({ name, values }) => {
                  applyFilter(NetpayOnHoldListFiltersMap.locations, values ?? []);
                }}
              />
              <DropdownOverlay>
                <ActionList>
                  {locations.map((department) => (
                    <ActionListItem
                      key={department.code?.toString() || ''}
                      title={department.name || ''}
                      value={department.code?.toString() || ''}
                    />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          </Box>
          <Box width="230px">
            <Dropdown selectionType="multiple">
              <SelectInput
                label=""
                maxRows="single"
                value={currentFilters[NetpayOnHoldListFiltersMap.departments] ?? []}
                placeholder="Department"
                onChange={({ name, values }) => {
                  applyFilter(NetpayOnHoldListFiltersMap.departments, values ?? []);
                }}
              />
              <DropdownOverlay>
                <ActionList>
                  {departments.map((department) => (
                    <ActionListItem key={department} title={department} value={department} />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          </Box>
        </Box>
        <Box display="flex" gap="spacing.2" alignItems="center">
          <Text marginLeft="spacing.2">Account Balance:</Text>
          <Amount value={balance} type="body" size="large" weight="semibold" />
        </Box>
      </Box>
      {showFilterTags ? (
        <Box marginTop="spacing.7" display="flex" gap="spacing.5" overflowX="auto" flexWrap="wrap">
          {search ? (
            <FilterTag
              name="Search"
              value={search}
              onDismiss={() => {
                setSearch('');
              }}
            />
          ) : null}
          {selectedLocations.length > 0 ? (
            <FilterTag
              name="Location"
              value={getLocationNamesFromKeys(selectedLocations, locations)}
              onDismiss={() => {
                removeFilter(NetpayOnHoldListFiltersMap.locations);
              }}
            />
          ) : null}
          {selectedDepartments.length > 0 ? (
            <FilterTag
              name="Department"
              value={selectedDepartments}
              onDismiss={() => {
                removeFilter(NetpayOnHoldListFiltersMap.departments);
              }}
            />
          ) : null}
          <Link
            variant="button"
            onClick={() => {
              setSearch('');
              removeFilter(NetpayOnHoldListFiltersMap.locations);
              removeFilter(NetpayOnHoldListFiltersMap.departments);
            }}>
            CLEAR ALL
          </Link>
        </Box>
      ) : null}
    </Box>)
  );
};

export default Filters;
