import React, { ReactElement } from 'react';

interface Props {
  children?: React.ReactNode;
}

function RightSidebar({ children }: Props): ReactElement {
  return <aside className="sidebar--shell">{children}</aside>;
}

export default RightSidebar;
