import React, { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { CheckBox, Input } from 'components/ui/Form/Input';
import Note from 'components/ui/Note';
import Label from 'components/ui/Label';
import { Table } from 'components/ui/Table';
import { Select, Option } from 'components/ui/Form/Select';
import { statesOfIndia } from 'utils/StatesOfIndia';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { PTUpdateRequest } from 'api/companyOnboarding';
import api from 'api';
import { fetchLoggedInUser } from 'reducers/loggedInUser';
import { routePaths } from 'components/Routes/data';
import { AppError } from 'utils/AppError';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { CredentialsType } from './types';
import { transformPTCredentials } from './utils';
import { trackSegment } from 'utils/segment';


const PT_ENABLED = 1;
const PT_DISABLED = 0;

const PtSetup = () => {
  const currentOrganization = useSelector(loggedInUserSelectors.currentOrganization);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPTPaymentEnabled, setIsPTPaymentEnabled] = useState(
    !!currentOrganization?.paymentOptions?.payPt,
  );
  const [ptStatus, setPtStatus] = useState(currentOrganization?.isPt ? PT_ENABLED : PT_DISABLED);
  const [showPassword, setShowPassword] = useState(false);
  const ptCredentials = transformPTCredentials({
    credentials: currentOrganization?.externalCredentials,
    maximumCredentialsAllowed: 5,
  });

  const updatePTMutation = useMutation(
    ({ isPTEnabled, isPTPaymentEnabled, credentials }: PTUpdateRequest) =>
      api.companyOnboarding.updatePTSetup({
        isPTEnabled,
        isPTPaymentEnabled,
        credentials,
      }),
    {
      onSuccess: (data) => {
        trackSegment("onboarding.setup_compliance.professional_tax.save_and_next_click");
        dispatch(fetchLoggedInUser());
        history.push(routePaths.companyOnboarding.compliance.pf);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const formObject: { [k: string]: FormDataEntryValue } = {};
    for (var pair of formData.entries()) {
      const name = pair[0];
      const value = pair[1];
      formObject[name] = value;
    }
    const credentials: CredentialsType[] = [];

    for (const key in formObject) {
      if (key.startsWith('pt-state-name')) {
        if (formObject[key]) {
          const idx = key.split('-')[3];
          credentials.push({
            state: parseInt(formObject[`pt-state-name-${idx}`].toString()),
            username: formObject[`pt-state-username-${idx}`].toString(),
            password: formObject[`pt-state-password-${idx}`].toString(),
            info: formObject[`pt-state-info-${idx}`].toString(),
          });
        }
      }
    }

    const isPTEnabled = ptStatus === PT_ENABLED;
    const requestObject: PTUpdateRequest = {
      isPTEnabled,
      isPTPaymentEnabled: !isPTEnabled ? false : isPTPaymentEnabled,
      credentials,
    };

    updatePTMutation.mutate(requestObject);
  };

  const isFormBeingSubmitted = updatePTMutation.status === 'loading';

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit}>
        {updatePTMutation.status === 'error' && (
          <WarningAlert className="mb-4">
            <Errors errorResponse={updatePTMutation.error} />
          </WarningAlert>
        )}
        <Label>Professional tax Status</Label>
        <Select
          value={ptStatus}
          onChange={(e) => {
            setPtStatus(parseInt(e.target.value));
          }}
          disabled={isFormBeingSubmitted}
          className="mb-20"
        >
          <Option value={PT_ENABLED}>Enabled</Option>
          <Option value={PT_DISABLED}>Disabled</Option>
        </Select>

        {ptStatus === PT_ENABLED && (
          <>
            <Label>Professional tax payments</Label>
            <Note>
              Please note that disabling the payment of professional tax does not disable the
              deduction of the same in your employees' payslips.
            </Note>
            <CheckBox
              id="pt-payment-enabled"
              label="Professional tax payments and filing (if applicable)"
              value="is-pt-enabled"
              checked={isPTPaymentEnabled}
              onChange={(event) => {
                setIsPTPaymentEnabled(event.target.checked);
              }}
              disabled={isFormBeingSubmitted}
            />

            {isPTPaymentEnabled && (
              <>
                <Note className="mt-20">
                  Your company's professional tax login (for your state's PT portal) is required to
                  pay PT dues and file returns.
                </Note>
                <Table className="w-full mb-10">
                  <thead>
                    <tr>
                      <th>State</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Additional info(Optional)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ptCredentials.map((credential, idx) => {
                      return (
                        // there is no unique ID for this
                        <tr key={idx}>
                          <td>
                            <Select
                              defaultValue={credential.state?.toString()}
                              disabled={isFormBeingSubmitted}
                              name={`pt-state-name-${idx}`}
                            >
                              <>
                                <Option value=""></Option>
                                {statesOfIndia.map((state) => (
                                  <Option key={state.code} value={state.code}>
                                    {state.name}
                                  </Option>
                                ))}
                              </>
                            </Select>
                          </td>
                          <td>
                            <Input
                              defaultValue={credential.username}
                              type="text"
                              name={`pt-state-username-${idx}`}
                              disabled={isFormBeingSubmitted}
                            />
                          </td>
                          <td>
                            <td>
                              <Input
                                defaultValue={credential.password}
                                type={showPassword ? 'text' : 'password'}
                                name={`pt-state-password-${idx}`}
                                disabled={isFormBeingSubmitted}
                              />
                            </td>
                          </td>
                          <td>
                            <Input
                              defaultValue={credential.info}
                              type="text"
                              name={`pt-state-info-${idx}`}
                              disabled={isFormBeingSubmitted}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <StandardSecondaryButton
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  Click here to {showPassword ? 'hide' : 'show'} passwords
                </StandardSecondaryButton>
                <br />
              </>
            )}
          </>
        )}
        <StandardButton type="submit" className="mt-16" isPending={isFormBeingSubmitted}>
          Save & Next
        </StandardButton>
      </form>
    </div>
  );
};

export default PtSetup;
