import React, { ReactElement } from 'react';
import { Option, Select } from 'components/ui/Form/Select';

function ViewOptions({
  handleViewChange,
  selectedView,
}: {
  handleViewChange: React.ChangeEventHandler<HTMLSelectElement>;
  selectedView: string;
}): ReactElement {
  return (
    <Select
      id="timeline"
      className="w-56 flex-auto pr-10"
      name="timeline"
      value={selectedView}
      onChange={handleViewChange}
    >
      <Option value="chart">Chart View</Option>
      <Option value="table">Table View</Option>
    </Select>
  );
}

export default ViewOptions;
