import { useSearchParams } from 'hooks/useSearchParams';
import { WorkflowRequestsFiltersMap } from './constants';
import { WorkflowRequestFilter } from './types';

const useFilters = () => {
  const { addSearchParams, removeSearchParams, urlParams } =
    useSearchParams<WorkflowRequestFilter>();

  const applyFilter = (filter: WorkflowRequestFilter, value: string) => {
    addSearchParams([{ key: filter, value }]);
  };

  const removeFilter = (filter: WorkflowRequestFilter) => {
    removeSearchParams([filter]);
  };

  const currentFilters: {
    [key in WorkflowRequestFilter]?: any;
  } = {};

  Object.entries(WorkflowRequestsFiltersMap).forEach(([_, filter]) => {
    const filterValue =
      filter === WorkflowRequestsFiltersMap.status
        ? urlParams.getAll(WorkflowRequestsFiltersMap.status)
        : urlParams.get(filter);
    if (filterValue) {
      currentFilters[filter] = filterValue;
    }
  });

  return {
    applyFilter,
    removeFilter,
    urlParams,
    currentFilters,
  };
};

export { useFilters };
