import React from 'react';
import { CompensationBreakdown } from '../components/CompensationBreakdown';
import { SummarySection } from '../components/SummarySection';
import { usePayDetails } from '../context/PayDetailsContext';

export const CompensationView = () => {
  const { userData, compensationData } = usePayDetails();

  if (!compensationData || !userData) {
    return null;
  }

  return (
    <>
      <SummarySection />
      <CompensationBreakdown />
    </>
  );
};
