import React, { ReactElement, useState, useEffect } from 'react';
import { BulkUploadDetailsSchemaContract } from '../../../schemas/BulkUploadDetailsSchema';
import MainCase from '../../ui/MainCase/index';
import styles from '../index.module.scss';
import BreadcrumbStyleHeader from '../components/BreadcrumbStyleHeader';
import FileSummaries from '../components/FileSummaries';
import PreviewTable from './AdditionDeductionPreviewTable';
import { ExecutionStatusModal, STATUS_FAILED, STATUS_IN_PROGRESS } from '../components/ExecutionModals/index';
import ErrorBlock from '../components/ErrorBlock';
import StickyFooterModal from '../components/StickyFooterModal/index';
import { SecondaryButton } from 'components/ui/Button';
import { PrimaryButtonV2 } from '../../ui/Button/index';
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from '../queries/queries';
import api from '../../../api/index';
import { BulkUploadTypes, BulkUploadStatus } from 'constants/bulkUpload';
import BulkUploadsPolling from '../../BulkUploadsPolling/index';
import { BulkUploadsExecutionPollStatuses } from '../../../constants/bulkUpload';
import ErrorCard from '../components/ErrorCard';
import { AppError } from 'utils/AppError';


export enum BulkAdditionDeductionModals {
    SHOW_NO_MODALS = 0,
    SHOW_IN_PROGRESS_MODAL = 1,
    SHOW_PARTIAL_UPLOAD_MODAL = 2,
}

export default function PreviewPage(): ReactElement {

    const [showConfirmModal, setConfirmModal] = useState(false);
    const history = useHistory();
    const [bulkUploadRecord, setBulkUploadRecord] = useState<BulkUploadDetailsSchemaContract[0] | null>(null);
    const [modalToDisplay, setModalToDisplay] = useState<BulkAdditionDeductionModals>(BulkAdditionDeductionModals.SHOW_NO_MODALS);

    const getBulkUploadRecord = useQuery(GET_BULK_UPLOAD_RECORD, () => {

        return api.bulkUploads.fetchBulkUploadRecordByType(BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP);

    }, {
        onSuccess: (data) => {
            if (data) {
                setBulkUploadRecord((data.length > 0) ? data[0] : null);
            }

            if (!data[0].id) {
                history.push(routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload);
            }

        },
        onError: (error: typeof AppError) => {
            console.error("Error on fetching org's bulk upload data : ", error);
            return <ErrorCard error={error} buttonText={'Back to Dashboard'} onClick={() => {
                window.location.href = '/dashboard';
            }} />
        }
    });


    const onConfirmClick = () => {
        if (bulkUploadRecord) {

            sessionStorage.removeItem('bulkUploadPollId');

            api.bulkUploads.sendExecuteRequest(bulkUploadRecord.id)
                .then((response) => {
                    setBulkUploadRecord(response[0] ?? bulkUploadRecord);
                    sessionStorage.setItem('bulkUploadPollId', String(response[0].id));
                })
                .catch((error: Error) => {
                    console.error("Error on fetching org's bulk upload data : ", error);
                });

        }
    }

    const onCancel = () => {
        setConfirmModal(false);
    }

    const onBack = () => {
        history.push(routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload);
    }

    const onClick = () => {
        setModalToDisplay(BulkAdditionDeductionModals.SHOW_NO_MODALS);
        history.push(routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload);
    }

    function renderModal(bulkUploadRecord: BulkUploadDetailsSchemaContract[0], modalToDisplay: BulkAdditionDeductionModals) {
        switch (modalToDisplay) {

            case BulkAdditionDeductionModals.SHOW_IN_PROGRESS_MODAL:
                return <ExecutionStatusModal
                    mainText={'Sit tight while we process your upload'}
                    subText={`Processing...${(bulkUploadRecord.percentage_completed !== null && bulkUploadRecord.percentage_completed !== undefined) ? bulkUploadRecord.percentage_completed + '%' : ''}`}
                    status={STATUS_IN_PROGRESS} />;

            case BulkAdditionDeductionModals.SHOW_PARTIAL_UPLOAD_MODAL:
                return <ExecutionStatusModal
                    mainText={'Uploading failed'}
                    subText={bulkUploadRecord && bulkUploadRecord.error_file_url ?
                        `${bulkUploadRecord?.number_of_rows_with_error}/${bulkUploadRecord?.total_number_of_rows} employees failed to upload due to error in employee data. Please retry uploading after fixing the errors. ` :
                        `There was an unknown issue uploading this file`}
                    status={STATUS_FAILED}
                    errorBlock={
                        bulkUploadRecord &&
                            [BulkUploadStatus.STATUS_EXECUTION_FAILED, BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS].includes(bulkUploadRecord.status) &&
                            bulkUploadRecord.error_file_url ?
                            <ErrorBlock mainText={`⚠️ ${bulkUploadRecord?.number_of_rows_with_error} errors found`}
                                subText={'Please download error report > fix ALL errors > re-upload file '}
                                buttonText={'Get error report'}
                                downloadURL={bulkUploadRecord?.error_file_url}
                                onClick={onClick}
                            /> :
                            <ErrorBlock mainText={`⚠️ There was an unexpected error .`}
                                subText={'Please contact support or try again later'}
                                buttonText={'Go Back'}
                                onClick={onClick}
                            />} />;

            default:
                return <></>;

        }
    }


    useEffect(() => {

        switch (bulkUploadRecord?.status) {

            case BulkUploadStatus.STATUS_VALIDATION_PENDING:
            case BulkUploadStatus.STATUS_VALIDATION_PROGRESS:
            case BulkUploadStatus.STATUS_VALIDATION_FAILED:

                history.push(routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload);
                break;

            case BulkUploadStatus.STATUS_EXECUTION_PENDING:
            case BulkUploadStatus.STATUS_EXECUTION_PROGRESS:

                setModalToDisplay(BulkAdditionDeductionModals.SHOW_IN_PROGRESS_MODAL);
                break;

            case BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS:
            case BulkUploadStatus.STATUS_EXECUTION_FAILED:

                setModalToDisplay(BulkAdditionDeductionModals.SHOW_PARTIAL_UPLOAD_MODAL);
                break;

            case BulkUploadStatus.STATUS_EXECUTION_SUCCESS:

                setModalToDisplay(BulkAdditionDeductionModals.SHOW_NO_MODALS);
                window.location.href = `/run-payroll?payroll_month=${bulkUploadRecord.input_config?.payrollMonth}`;
                break;

        }

    }, [bulkUploadRecord]);


    const previewSummary = [
        { id: 'total-employee-additions', title: 'Total Employee Additions', count: bulkUploadRecord?.file_summary?.numberOfAdditions },
        { id: 'total-employee-deductions', title: 'Total Employee Deductions', count: bulkUploadRecord?.file_summary?.numberOfDeductions },
        { id: 'total-employee-lop', title: 'Total Employee Loss of Pay', count: bulkUploadRecord?.file_summary?.numberOfLOPDays },
    ]

    const selectedDate = bulkUploadRecord?.input_config?.payrollMonth ? new Date(bulkUploadRecord?.input_config?.payrollMonth) : null;
    const payrollMonth = selectedDate?.toLocaleString('default', { month: 'long' });


    return (
        <>
            {bulkUploadRecord && renderModal(bulkUploadRecord,modalToDisplay)}

            {bulkUploadRecord?.id &&
                BulkUploadsExecutionPollStatuses.includes(bulkUploadRecord?.status ?? BulkUploadStatus.STATUS_VALIDATION_SUCCESS) &&
                <BulkUploadsPolling
                    id={bulkUploadRecord?.id.toString()}
                    action={'file-execution'}
                    setBulkUploadRecordData={setBulkUploadRecord}
                />}

            {bulkUploadRecord &&
                <>
                    <MainCase type="fullWidth" >
                        <div className={styles['centered-body-80']}>
                            <BreadcrumbStyleHeader bulkUploadRecord={bulkUploadRecord} />
                            <div className={'text-4xl text-white font-semibold mt-12 mb-8'}>Preview</div>
                            <FileSummaries summaryKeyValueArray={previewSummary} />
                            <PreviewTable bulkUploadRecord={bulkUploadRecord} />

                            <div className='flex flex-row mt-10'>
                                <SecondaryButton className={styles['button-left']} onClick={onBack}>Back</SecondaryButton>
                                <PrimaryButtonV2 className={styles['button-right']} onClick={() => setConfirmModal(true)}>Proceed</PrimaryButtonV2>
                            </div>
                        </div>
                    </MainCase>
                    <StickyFooterModal warningText={
                        <div className='ml-4'>Note: The changes made in this sheet will reflect in your <b>{payrollMonth} month's payroll</b>.</div>
                    }
                        showModal={showConfirmModal}
                        onCancel={onCancel}
                        onConfirmClick={onConfirmClick}
                    />
                </>}

        </>

    );
}