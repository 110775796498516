import { Box } from '@razorpay/blade/components';
import React from 'react';
import { renderCustomReportField } from './reportHelper';
import { ReportInput } from './types';

interface ReportInputsProps {
  inputs: ReportInput[];
  onChange: (key: string, value: string | string[] | null) => void;
  currentInputs: Record<string, string>;
  rightSideContent?: React.ReactNode;
  centerLayout?: React.ReactNode;
  isLoading: boolean;
  emptyState?: boolean;
}

const ReportInputs = ({
  inputs,
  onChange,
  rightSideContent,
  centerLayout,
  currentInputs,
  isLoading,
  emptyState
}: ReportInputsProps) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap="spacing.6"
      justifyContent="space-between"
      alignItems="flex-end"
      paddingX="spacing.7">
      <Box display="flex" gap="spacing.5" flexWrap="wrap" alignItems="flex-end">
        <Box display="flex" gap="spacing.5" flexWrap="wrap" maxWidth={emptyState ? '400px' : undefined}>
          {inputs.map((input) => (
            <Box width="160px" marginX={ emptyState ? 'auto' : undefined }>
              {renderCustomReportField({
                field: input,
                isLoading,
                onChange,
                currentfields: currentInputs,
              })}
            </Box>
          ))}
        </Box>
        <Box paddingTop={ emptyState ? '20px' : undefined } marginY={emptyState ? 'auto' : undefined}>
          {centerLayout}
        </Box>
      </Box>
      {rightSideContent}
    </Box>
  );
};

export default ReportInputs;
