
import { GetWorkflowRequestsContract } from '../../../../schemas/GetWorkflowRequests';
import { Allowance, SalaryRevisionListItem } from './types';
import { format } from 'date-fns';
import { WorkflowRequestsCacheKeys, WorkflowRequestsStatusMap } from '../constants';
import { QueryClient } from 'react-query';
import { WorkflowRequestDetailsQueryKey } from '../components/DetailsView/constants';

export const transformSalaryRevisionRequestData = (
    data: GetWorkflowRequestsContract['data'][0],
    peopleMetadata: GetWorkflowRequestsContract['people_metadata'],
  ): SalaryRevisionListItem => {
    const forPeopleId = data['for_people_id'] as number;
    const oldData = data.old_data as any;
    const newData = data.new_data as any;
    const payload = data.payload as any;
  
    const forPersonData = peopleMetadata[forPeopleId];
    return {
      id: data.id,
      employeeDetails: {
        name: forPersonData.name,
        employeeId: `${forPersonData.id_prefix ?? ''}${forPersonData.employee_id}`,
      },
      effectiveFrom: format(new Date(payload.effectiveFrom), 'yyyy-MM-dd HH:mm:ss'),
      approvalLevel: data.pending_on_level as number,
      status: data.status,
      ctc: {
        old: ( oldData && oldData.salary ) ?? 0 as number,
        new: newData.salary ?? 0 as number,
      },
      basicSalary: {
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.basic as number : 0,
        new: newData.salaryModel ? newData.salaryModel.basic as number : 0 
      },
      da: {
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.da as number : 0,
        new: newData.salaryModel ? newData.salaryModel.da as number : 0
      },
      lta: {
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.lta as number : 0,
        new: newData.salaryModel ? newData.salaryModel.lta as number : 0
      },
      pfContribution: {
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.pfContribution as number : 0,
        new: newData.salaryModel ? newData.salaryModel.pfContribution as number : 0
      },
      esiContribution: {
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.esiContribution as number : 0,
        new: newData.salaryModel ? newData.salaryModel.esiContribution as number : 0
      },
      vpf:{
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.vpf as number : 0,
        new: newData.salaryModel ? newData.salaryModel.vpf as number : 0
      },
      hra:{
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.hra.actualHRA as number : 0,
        new: ( newData.salaryModel ) ? newData.salaryModel.hra.actualHRA as number : 0
      },
      specialAllowance:{
        old: ( oldData && oldData.salaryModel ) ? oldData.salaryModel.specialAllowance as number : 0,
        new: newData.salaryModel ? newData.salaryModel.specialAllowance as number : 0
      },
      npsEmployerContributionLimit:{
        old: ( oldData && oldData.salaryModel && oldData.salaryModel.npsEmployerContributionLimit ) ? oldData.salaryModel.npsEmployerContributionLimit as number : 0,
        new: newData.salaryModel && newData.salaryModel.npsEmployerContributionLimit ? newData.salaryModel.npsEmployerContributionLimit as number : 0
      },
      allowances: newData.allowances as Allowance[],
      arrears: payload.sumOfArrears ? payload.sumOfArrears as number : 0,
      vp: payload.variablePay ? payload.variablePay as number : 0,
      perquisites: payload.perquisites ? payload.perquisites as number : 0,
      totalAllowances: newData.sumOfAllowances as number,
      totalDeductions: newData.sumOfDeductions as number,
      allowAction: data.status === WorkflowRequestsStatusMap.pending,
      isCustomSalaryStructure: payload.isCustomSalaryStructure
    };
  };

  export const invalidateSalaryRevisionCache = (queryClient: QueryClient) => {
    queryClient.invalidateQueries(WorkflowRequestsCacheKeys.salaryRevision.pending);
    queryClient.invalidateQueries(WorkflowRequestsCacheKeys.salaryRevision.initiatedByMe);
    queryClient.invalidateQueries(WorkflowRequestsCacheKeys.salaryRevision.completed);
  
    // invalidating workflow-summary cache
    queryClient.invalidateQueries(WorkflowRequestsCacheKeys.summaryForUser);
  
    // invalidating workflow-request-details cache
    queryClient.invalidateQueries(WorkflowRequestDetailsQueryKey);
  };