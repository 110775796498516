import React from 'react';
import { Box, RadioGroup, Text } from '@razorpay/blade/components';
import { earningFiltersMap, statusFilters } from 'components/SalaryComponents/constants';
import BoxedRadioButton from 'components/ui/CustomBladeComponents/BoxedRadioButton';
import { useFilters } from 'hooks/useFilters';

const StatusFilter = () => {
  const { currentFilters, applyFilter } = useFilters({
    filtersMap: { status: earningFiltersMap.status },
  });

  return (
    <RadioGroup
      onChange={({ value }) => applyFilter(earningFiltersMap.status, value)}
      defaultValue="all"
      value={currentFilters.status}>
      <Box display="flex" gap="spacing.4" alignItems="center">
        <Text color="surface.text.gray.muted" weight="semibold">
          Filters :
        </Text>

        {Object.entries(statusFilters).map(([filterKey, filterLabel]) => {
          return (
            <BoxedRadioButton
              key={filterKey}
              isSelected={currentFilters.status === filterKey}
              value={filterKey}
              label={filterLabel}
            />
          );
        })}
      </Box>
    </RadioGroup>
  );
};

export default StatusFilter;
