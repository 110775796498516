import React from 'react';
import { IInsuranceEmployee } from '../../../../reducers/insuranceEmployee';
import { AnchorCard } from '../../../ui/SidebarCard';
export function RightSidebar({ insuranceEmployee }: { insuranceEmployee: IInsuranceEmployee }) {
  if (!insuranceEmployee.data) {
    return <></>;
  }
  return (
    <>
      {insuranceEmployee.data.isSyncedWithVendor && (
        <AnchorCard
          imageSymbolType="help"
          newTab={true}
          href={`/v2/user-organization/${insuranceEmployee.data.id}/insurance/password-less-provider-link`}
          heading="Claims and Support"
        >
          This will lead you to the Plum's portal where you can claim and query.
        </AnchorCard>
      )}
    </>
  );
}
