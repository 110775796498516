import React, { ReactElement } from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

function Note({ children, className = '' }: Props): ReactElement {
  return <h3 className={`note ${className}`}>{children}</h3>;
}

export default Note;
