import React, { useState } from 'react';
import { IntegrationCard } from '../ui/IntegrationCard';
import { SuggestionCard } from './components/SuggestionCard';
import { Tags } from './components/Tags';
import Spinner from 'components/ui/Spinner';
import api from 'api';
import { useQuery } from 'react-query';
import { GET_ORG_INTEGRATIONS_QUERY } from './queries';

import APIError from 'types/apiError';
import ErrorMessage from 'components/ui/ErrorMessage';
import { trackSegment } from 'utils/segment';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { IntegrationsV1RestrictedFlag } from './constants';
import { routePaths } from 'components/Routes/data';
import { Redirect, useLocation } from 'react-router-dom';

function Heading() {
  return <div className="font-heading font-bold text-center text-5xl mb-4">Integrations</div>;
}

export default function Integrations() {
  const location = useLocation();
  const [filter, setFilter] = useState('All Integrations');
  const [interestedList, setInterestedList] = useState<string[]>([]);
  const restrictedFeatures = useSelector(loggedInUserSelectors.features);
  const isIntegrationsV2Enabled = !!restrictedFeatures?.[IntegrationsV1RestrictedFlag];
  const pathname = location.pathname;

  const integrationQuery = useQuery(
    GET_ORG_INTEGRATIONS_QUERY,
    () => {
      return api.integration.getIntegrationStatus();
    },
    {
      onError: (error: APIError) => {},
    },
  );

  if (isIntegrationsV2Enabled && pathname === routePaths.integrations.root) {
    return <Redirect to={routePaths.integrations.v2} />;
  }

  const integrationData = integrationQuery.data?.data || [];

  if (integrationQuery.status === 'loading') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (integrationQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        {integrationQuery.error?.message || 'Something went wrong, please contact support'}
      </ErrorMessage>
    );
  }

  function handleFilter(item: string) {
    trackSegment('Interested in ' + item + ' Integrations');
    setFilter(item);
  }

  const filteredData =
    filter === 'All Integrations'
      ? integrationData
      : integrationData?.filter((item) => item.type === filter);

  const sortedData = filteredData?.sort(function (integrationDataItemA, integrationDataItemB) {
    if (integrationDataItemA.isConnected) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <>
      <div className="flex flex-col items-start w-9/12 m-auto my-16">
        <Heading />
        <Tags onChange={handleFilter} value={filter} />
        <div className="grid mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5">
          {sortedData.map((item) => (
            <IntegrationCard
              key={item.id}
              id={item.id}
              name={item.name}
              type={item.type}
              description={item.description}
              info={item.info}
              isConnected={item.isConnected}
              isAvailable={item.isAvailable}
              interestedList={interestedList}
              setInterestedList={setInterestedList}
            />
          ))}
          <SuggestionCard />
        </div>
      </div>
    </>
  );
}
