import React, { ReactNode, useState } from 'react';
import { LinkButtonV2, StandardButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import { Table } from 'components/ui/Table';
import { fieldsToBeSynced } from '../../utils';

import styles from './index.module.scss';

const ListItem = ({ children }: { children: ReactNode }) => (
  <li className="my-8 text-2xl">{children}</li>
);

const FreshTeamsHelpModal = () => {
  const { closeModal } = useModal();
  const [areFieldsOpen, setAreFieldsOpen] = useState(false);

  return (
    <div className={`${styles['HelpModalWrapper']} p-10`}>
      <h3 className="text-4xl font-bold text-white-o-80">Integration details</h3>
      <ol className="list-decimal list-outside mt-12 pl-6">
        <ListItem>
          The following fields will be synced from Freshteam:{' '}
          {areFieldsOpen ? fieldsToBeSynced.join(', ') : fieldsToBeSynced.slice(0, 7).join(', ')}.{' '}
          {!areFieldsOpen ? (
            <LinkButtonV2
              onClick={() => {
                setAreFieldsOpen(true);
              }}>
              ...more
            </LinkButtonV2>
          ) : null}
        </ListItem>
        <ListItem>All members from Freshteam will be either an employee or contractor.</ListItem>
        <ListItem>Only Indian employees will be synced from Freshteam to XPayroll.</ListItem>
        <ListItem>
          Role mapping from Freshteam to XPayroll account
          <Table className="mt-4">
            <tbody>
              <tr>
                <td>{'Account Admin -> Administrator'}</td>
              </tr>
              <tr>
                <td>{'HR Partner -> Human Resources'}</td>
              </tr>
              <tr>
                <td>{'XPayroll Report Viewer -> Report Viewer'}</td>
              </tr>
              <tr>
                <td>{'Others -> Employee'}</td>
              </tr>
            </tbody>
          </Table>
        </ListItem>
      </ol>
      <div className="flex justify-end">
        <StandardButton onClick={closeModal}>Got it</StandardButton>
      </div>
    </div>
  );
};

export default FreshTeamsHelpModal;
