import { Alert, Box, Breadcrumb, BreadcrumbItem, Spinner } from '@razorpay/blade/components';
import { CompensationView } from 'components/PayDetails/views/CompensationView';
import { routePaths } from 'components/Routes/data';
import PageLayout from 'components/ui/Layout/PageLayout';
import { useSearchParams } from 'hooks/useSearchParams';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { getEmployeeDetailsLink, getPayDetailsLink, getPeoplePage } from 'utils/links';
import { Header } from '../components/Header';
import { PayDetailsProvider } from '../context/PayDetailsContext';
import useFetchPayDetailsData from '../hooks/useFetchPayDetailsData';

export const PayDetailsView = () => {
  const { urlParams } = useSearchParams();
  const userId = urlParams.get('userId');
  const loggedInUserId = useSelector(loggedInUserSelectors.peopleId);
  const peopleId = String(userId ?? loggedInUserId);

  const { userData, compensationData, firstName, error, isLoading, refetch } =
    useFetchPayDetailsData(peopleId);

  return (
    <>
      <PayDetailsProvider userData={userData!} compensationData={compensationData!}>
        <PageLayout
          hideHeading
          withoutBackgroundImg
          boxProps={{
            marginX: 'spacing.4',
            marginY: 'spacing.7',
            width: 'initial',
          }}
          breadcrumb={
            <Breadcrumb color="neutral">
              <BreadcrumbItem href={getPeoplePage()}>People</BreadcrumbItem>
              <BreadcrumbItem href={getEmployeeDetailsLink(Number(peopleId))}>
                {firstName || '...'}
              </BreadcrumbItem>
              <BreadcrumbItem href={getPayDetailsLink()} isCurrentPage>
                Pay
              </BreadcrumbItem>
            </Breadcrumb>
          }>
          <Box
            display={'flex'}
            flexDirection={'column'}
            marginTop={'spacing.8'}
            gap={'spacing.7'}
            paddingLeft={{ xl: '124px' }}
            paddingRight={{ xl: '192px' }}
            maxWidth={'1224px'}
            width={'100%'}
            marginX={'auto'}>
            {error && (
              <Alert
                isFullWidth
                isDismissible={false}
                color="negative"
                title="An error occured while fetching your user details, please contact support if this persists."
                description={errorParser(getAppErrorDetails(error))[0]}
                actions={{
                  primary: {
                    text: 'Retry',
                    onClick: refetch,
                  },
                }}
              />
            )}
            {isLoading && <Spinner accessibilityLabel="" alignSelf={'center'} marginY={'auto'} />}
            {userData && compensationData && (
              <>
                <Header />
                <Switch>
                  <Route path={routePaths.payDetails.root} exact>
                    <Redirect
                      to={{
                        pathname: routePaths.payDetails.compensation.root,
                        search: `?userId=${peopleId}`,
                      }}
                    />
                  </Route>
                  <Route
                    path={routePaths.payDetails.compensation.root}
                    component={CompensationView}
                  />
                  <Route>
                    <Redirect to={routePaths.unknown} />
                  </Route>
                </Switch>
              </>
            )}
          </Box>
        </PageLayout>
      </PayDetailsProvider>
    </>
  );
};
