import React, { ReactNode, useState } from 'react';

import {
  Alert,
  Amount,
  ArrowRightIcon,
  Box,
  Button,
  Divider,
  InfoIcon,
  Link,
  Spinner,
  Text,
} from '@razorpay/blade/components';
import api from 'api';
import { SideSheet } from 'components/ApprovalWorkflows/MyRequests/components/SideSheet';
import { GET_NET_PAY_LIST_DATA, GET_NET_PAY_LIST_ITEM } from 'components/Fnf/queries';
import OtpModalBlade from 'components/Otp/OtpModalBlade';
import { routePaths } from 'components/Routes/data';
import { FETCH_BALANCE, FETCH_RX_BALANCE } from 'components/RunPayroll/utils/queries';
import { format, parse } from 'date-fns';
import useTwoFactorAuth from 'hooks/useTwoFactorAuth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { parseError } from 'utils/Api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { dateFormats } from 'utils/Dates';
import { showErrorToastNotification, showToastViaEvent } from 'utils/ToastEvents';
import { getOtpModalProps } from 'utils/otp';
import ConfirmationSheet from '../ConfirmationSheet';
import { useReleaseNetpayDetailsView } from '../useReleseNetpayDetailsView';

const TableContainer = styled.table`
  border-collapse: separate;
  width: 100%;

  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  tr:first-child td {
    padding-top: 0;
  }

  tr:last-child td {
    padding-bottom: 0;
  }
`;

type DetailsViewSectionProps = {
  title: string;
  subtitle: string;
  items: ReactNode[][];
};

const DetailsViewSection = ({ title, subtitle, items }: DetailsViewSectionProps) => {
  return (
    <Box>
      <Box display="flex" gap="spacing.2" alignItems="baseline">
        <Text size="large" weight="semibold">
          {title}
        </Text>
        <Text size="large" color="surface.text.gray.disabled">
          {subtitle}
        </Text>
      </Box>
      <Box marginTop="spacing.7">
        <TableContainer>
          {items.map((childrens, index) => (
            <tr key={index.toString()}>{childrens}</tr>
          ))}
        </TableContainer>
      </Box>
    </Box>
  );
};

const DetailsView = ({ balance }: { balance: number }) => {
  const { closeDetailsView, netpayRequestId } = useReleaseNetpayDetailsView();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [netPayReleaseMonth, setNetPayReleaseMonth] = useState<string>();
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { splitzQuery, isPhase2ExperimentActive, email, isAuthenticatorAppEnabled } =
    useTwoFactorAuth();
  const [isResendAttempt, setIsResendAttempt] = useState(false);

  const isOpen = !!netpayRequestId;

  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: [GET_NET_PAY_LIST_ITEM, netpayRequestId],
    queryFn: () => api.fnf.getNetpayOnHoldListItem(netpayRequestId as string),
    enabled: !!netpayRequestId,
    onError: (error: typeof AppError) => {},
  });

  const releaseNetPay = useMutation({
    mutationFn: api.fnf.releaseNetpay,
    onSuccess: () => {
      showToastViaEvent({
        text: 'Netpay released successfully!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
      setIsConfirmationOpen(false);
      setNetPayReleaseMonth(undefined);
      queryClient.invalidateQueries([GET_NET_PAY_LIST_DATA]);
      queryClient.invalidateQueries([FETCH_RX_BALANCE]);
      queryClient.invalidateQueries([FETCH_BALANCE]);
      closeDetailsView();
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 3000,
      });
    },
  });

  const releaseNetPayV2 = useMutation({
    mutationFn: api.fnf.releaseNetpayV2,
    onSuccess: () => {
      showToastViaEvent({
        text: 'Otp generated successfully!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
      setIsOtpGenerated(true);
    },
    onError: (error: typeof AppError) => {
      const message = parseError(error);
      showErrorToastNotification({
        text: message,
        timeout: 3000,
      });
    },
  });

  const releaseNetPayV2WithOtp = useMutation({
    mutationFn: api.fnf.releaseNetpayV2WithOtp,
    onSuccess: () => {
      showToastViaEvent({
        text: 'Netpay released successfully!',
        timeout: 3000,
        type: 'success',
        icon: 'success',
      });
      setIsConfirmationOpen(false);
      setNetPayReleaseMonth(undefined);
      queryClient.invalidateQueries([GET_NET_PAY_LIST_DATA]);
      queryClient.invalidateQueries([FETCH_RX_BALANCE]);
      queryClient.invalidateQueries([FETCH_BALANCE]);
      setIsOtpGenerated(false);
      closeDetailsView();
    },
    onError: (error: typeof AppError) => {},
  });

  const onConfirmRelease = () => {
    if (!netpayRequestId) return;
    if (isPhase2ExperimentActive) {
      releaseNetPayV2.mutate({ peopleId: netpayRequestId, month: netPayReleaseMonth });
    } else {
      releaseNetPay.mutate({ peopleId: netpayRequestId, month: netPayReleaseMonth });
    }
  };

  const handleReleaseNetpay = (month?: string) => {
    if (!netpayRequestId) return;
    setNetPayReleaseMonth(month);
    setIsConfirmationOpen(true);
  };

  const handleManageFnfResettlementClick = () => {
    history.push({
      pathname: routePaths.fnf.root,
      search: `userId=${netpayRequestId}`,
    });
  };

  const netPayAmount = data?.net_pay_amount || 0;
  const netPayBreakupItems = data?.net_pay_breakup ?? [];
  const isFnfPayrollProcessed = data?.fnf_payroll_processed ?? false;
  const isFnfPayrollReleased = data?.fnf_payroll_released ?? false;
  const settlementAmount = data?.settlement_amount || 0;

  const isInsufficientBalance = balance < netPayAmount;

  const requiredBalance = netPayAmount - balance;

  const getNetPayReleaseAmount = (month?: string) => {
    if (!month) return netPayAmount;
    const netPayBreakupItem = netPayBreakupItems.find((item) => item.month === month);
    return netPayBreakupItem?.amount || 0;
  };

  const otpError = releaseNetPayV2.error || releaseNetPayV2WithOtp.error;

  const handleVerify = (e: React.SyntheticEvent<Element, Event>, otp: string) => {
    if (!netpayRequestId) return;
    releaseNetPayV2WithOtp.mutate({
      peopleId: netpayRequestId,
      month: netPayReleaseMonth,
      two_factor_auth_otp: otp,
    });
  };

  const handleResendClick = async (
    e: React.SyntheticEvent<Element, Event>,
    resetTimerCallback: Function,
  ) => {
    if (!netpayRequestId) return;
    setIsResendAttempt(false);
    await releaseNetPayV2.mutateAsync({ peopleId: netpayRequestId, month: netPayReleaseMonth });
    setIsResendAttempt(true);
    resetTimerCallback();
  };

  const content = (
    <Box>
      <Text weight="semibold" color="surface.text.gray.muted">
        Total Amount to be paid
      </Text>
      <Amount value={netPayAmount} type="heading" size="xlarge" />
      <Divider marginY="spacing.7" />
      <DetailsViewSection
        title="Net pay breakup"
        subtitle="Details"
        items={netPayBreakupItems.map((item) => [
          <td>
            <Text color="surface.text.gray.muted">
              {item.month
                ? format(
                    parse(item.month, dateFormats.yearMonthDate, new Date()),
                    dateFormats.monthYear,
                  )
                : ''}
            </Text>
          </td>,
          <td>
            <Amount value={item.amount} type="body" size="medium" />
          </td>,
          item.month && (!isFnfPayrollProcessed || isFnfPayrollReleased) ? (
            <td>
              <Link
                variant="button"
                icon={ArrowRightIcon}
                iconPosition="right"
                size="small"
                isDisabled={releaseNetPay.isLoading}
                onClick={() => handleReleaseNetpay(item.month as string)}>
                Pay Now
              </Link>
            </td>
          ) : null,
        ])}
      />
      <Divider marginY="spacing.7" />
      <DetailsViewSection
        title="Settlement"
        subtitle="Details"
        items={[
          [
            <td>
              <Text color="surface.text.gray.muted">Total amount</Text>
            </td>,
            <td>
              <Amount
                {...(settlementAmount < 0 ? { color: 'feedback.text.negative.intense' } : {})}
                value={settlementAmount}
                type="body"
                size="medium"
              />
            </td>,
          ],
        ]}
      />
      <Button
        marginTop="spacing.5"
        variant="tertiary"
        isFullWidth={true}
        icon={ArrowRightIcon}
        isDisabled={releaseNetPay.isLoading}
        onClick={handleManageFnfResettlementClick}
        iconPosition="right">
        Manage FNF Settlement
      </Button>
      <Divider marginY="spacing.7" />

      <Box>
        <Text size="large">Release FNF</Text>
        <Text variant="caption" marginTop="spacing.3" color="surface.text.gray.muted" size="small">
          The FNF will be released to the employee now
          {data?.employee_email
            ? `, and the payslip will be emailed to
          ${data.employee_email}`
            : '.'}
        </Text>
      </Box>

      {isFnfPayrollProcessed && !isFnfPayrollReleased && (
        <Button
          marginTop="spacing.8"
          isFullWidth={true}
          isDisabled={isInsufficientBalance || releaseNetPay.isLoading || netPayAmount < 0}
          onClick={() => handleReleaseNetpay()}>
          Release Full & final Settlement
        </Button>
      )}

      {isInsufficientBalance && (
        <Box display="flex" gap="spacing.2" alignItems="center" marginTop="spacing.4">
          <InfoIcon size="small" color="feedback.icon.notice.intense" />
          <Text variant="caption" color="feedback.text.notice.intense" size="small">
            Insufficient balance. Load ₹{requiredBalance} to release net pay
          </Text>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <SideSheet isOpen={isOpen} onDismiss={closeDetailsView}>
        {isError || splitzQuery.isError ? (
          <Alert
            color="negative"
            description={
              splitzQuery.isError ? 'Some error occurred. Please retry.' : parseError(error!)
            }
            actions={{
              primary: {
                text: 'Retry',
                onClick: () => (splitzQuery.isError ? splitzQuery.refetch() : refetch()),
              },
            }}
          />
        ) : isFetching || splitzQuery.isFetching ? (
          <Box height="90vh" display="grid" placeItems="center">
            <Spinner accessibilityLabel="Loading" />
          </Box>
        ) : (
          content
        )}
      </SideSheet>
      <ConfirmationSheet
        title="Release net pay"
        description={`Net amount of ₹${getNetPayReleaseAmount(
          netPayReleaseMonth,
        )} will be released for ${data?.employee_name} (${data?.employee_id}). Release now?`}
        isLoading={releaseNetPay.isLoading || releaseNetPayV2.isLoading}
        isOpen={isConfirmationOpen}
        onConfirm={onConfirmRelease}
        onDismiss={() => setIsConfirmationOpen(false)}
      />
      <OtpModalBlade
        isLoading={releaseNetPayV2.isLoading || releaseNetPayV2WithOtp.isLoading}
        isOpen={isOtpGenerated}
        data={{
          ...getOtpModalProps({
            error: otpError,
            isAuthenticatorAppEnabled,
            email: email,
          }),
          isResendAttempt,
        }}
        onDismiss={() => setIsOtpGenerated(false)}
        onVerify={handleVerify}
        onResend={handleResendClick}
        actionName='release-net-pay'
      />
    </>
  );
};

export { DetailsView };
