import React, { useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import DownloadAndUploadPage from './AdditionDeductionLOP/AdditionDeductionDownloadAndUploadPage';
import { BulkUploadStep, BulkUploadTypes, routeToStepMap } from '../../constants/bulkUpload';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import { RestrictedRoute } from 'components/Routes/RestrictedRoute';
import permissions from '../../constants/permissions';
import { routePaths } from 'components/Routes/data';
import MainCase from '../ui/MainCase/index';
import { BulkUploadDetailsSchemaContract } from '../../schemas/BulkUploadDetailsSchema';
import { useQuery } from 'react-query';
import { GET_BULK_UPLOAD_RECORD } from './queries/queries';
import api from '../../api/index';
import Spinner from '../ui/Spinner/index';
import PreviewPage from './AdditionDeductionLOP/AdditionDeductionPreviewPage';
import { HeaderWithProgressBar } from './components/HeaderWithProgressBar/index';
import styles from './index.module.scss';
import ErrorCard from './components/ErrorCard';
import { AppError } from 'utils/AppError';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';


export default function BulkAdditionsDeductionsLOP(): ReactElement {
    const location = useLocation();
    const history = useHistory();
    const currentStep = routeToStepMap[BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP][location.pathname] || BulkUploadStep.DOWNLOAD_AND_UPLOAD_STEP;
    const [bulkUploadRecord, setBulkUploadRecord] = useState<BulkUploadDetailsSchemaContract[0] | null>(null);
    const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);

    const { isLoading, isRefetching, error, data } = useQuery(GET_BULK_UPLOAD_RECORD, () => {

        return api.bulkUploads.fetchBulkUploadRecordByType(BulkUploadTypes.TYPE_BULK_ADDITION_DEDUCTION_LOP);

    }, {
        onSuccess: (data) => {
            if (data) {
                setBulkUploadRecord((data.length > 0) ? data[0] : null);
              }
        },
        onError: (error: typeof AppError) => {
            console.error("Error on fetching org's bulk upload data : ", error);

        }
    });

    if(!isOnProPlan){
        return <Redirect to={routePaths.unknown} />
    }
    
    if (isLoading) {
        return (
            <div className={'flex justify-center'}>
                <Spinner size={40} />
            </div>
        );
    }

    if (error) {
        return <ErrorCard error={error} buttonText= {'Back to Dashboard'} onClick={() => {
            window.location.href = '/dashboard';
          }} />
    }


    return (
        <div className={styles['main-case-no-padding']}>
            <HeaderWithProgressBar headerText={'Bulk Additions/Deductions/Loss of Pay'} currentStep={currentStep} totalSteps={3} />

            <MainCase type="fullWidth" >
                <Switch>
                    <RestrictedRoute
                        allowedPermissions={[permissions.BULK_ADDITION_DEDUCTION]}
                        path={[
                            routePaths.bulkUploads.bulkAdditionDeductionLOP.fileUpload,
                            routePaths.bulkUploads.bulkAdditionDeductionLOP.root
                        ]}
                        component={DownloadAndUploadPage}
                        exact
                    />
                    <RestrictedRoute
                        allowedPermissions={[permissions.BULK_ADDITION_DEDUCTION]}
                        path={[
                            routePaths.bulkUploads.bulkAdditionDeductionLOP.previewScreen
                        ]}
                        component={PreviewPage}
                        exact
                    />
                    <Route>
                        <Redirect to={routePaths.unknown} />
                    </Route>
                </Switch>
            </MainCase>

        </div>

    );
}