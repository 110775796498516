import { format } from 'date-fns';
import { prepareUrlV2 } from './Urls';

export const getEmployeeDetailsLink = (peopleId?: number | string) => {
  return prepareUrlV2('employeeDetail', { userId: peopleId });
};

export const getPeoplePage = () => {
  return `/people`;
};

export const getPayDetailsLink = (peopleId?: number | string) => {
  return prepareUrlV2('pay-details', { userId: peopleId });
};

export const getAttendancePage = (peopleId?: number | string, month?: Date) => {
  const payload: { user_id?: number | string; month?: string } = { user_id: peopleId };
  if (month) {
    payload['month'] = format(month, 'yyyy-MM');
  }
  return prepareUrlV2('attendance-v2', payload);
};

export const getFingerPrintRegistration = (peopleId?: number | string) => {
  return prepareUrlV2('addFingerprint', { userId: peopleId });
};
