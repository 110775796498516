export const adminArticleLinks = [
  {
    id: 1,
    title: 'As an employer, how do I verify investment proofs submitted by employees?',
    url: 'https://razorpay.com/docs/payroll/tax-verifications/',
  },
  {
    id: 2,
    title: 'When will XPayroll file my TDS returns?',
    url: 'https://razorpay.com/docs/payroll/tds/#file-tds-return',
  },
  {
    id: 3,
    title: 'When will XPayroll pay my TDS/PF/ESI/PT dues?',
    url: 'https://razorpay.com/docs/payroll/statutory-compliance/#payment-due-dates',
  },
  {
    id: 4,
    title: 'How do I set up a custom salary structure?',
    url: 'https://razorpay.com/docs/payroll/salary/#custom-salary-structure',
  },
  {
    id: 5,
    title: 'What are some best practices to transfer funds to my Payroll account?',
    url: 'https://razorpay.com/docs/payroll/payroll-payouts/#fund-transfer-best-practices',
  },
  {
    id: 6,
    title: 'How long does it take to process salary payouts?',
    url: 'https://razorpay.com/docs/payroll/payroll-payouts/#fund-credit-timelines',
  },
  {
    id: 7,
    title: 'How can I check the status of my salary payouts?',
    url: 'https://razorpay.com/docs/payroll/payroll-payouts/#check-payment-status',
  },
  {
    id: 8,
    title: 'Administrators FAQs',
    url: 'https://razorpay.com/docs/payroll/faqs/',
  },
  {
    id: 9,
    title: 'About 2FA rule on Professional Tax payments',
    url: 'https://razorpay.com/docs/payroll/pt/#pt-payment-for-karnataka-employees',
  },
  {
    id: 10,
    title: 'About 2FA rule on Provident Fund payments',
    url: 'https://razorpay.com/docs/payroll/provident-fund/',
  },
];

export const apiLinks = [
  {
    id: 1,
    title: 'XPayroll General APIs',
    url: 'https://documenter.getpostman.com/view/11662503/Tzm5HckE',
  },
  {
    id: 2,
    title: 'XPayroll Partner APIs',
    url: 'https://documenter.getpostman.com/view/23869703/2s847MrAqf',
  },
];

export const employeeArticleLinks = [
  {
    id: 1,
    title: 'How do I declare or modify investment declarations and proofs?',
    url: 'https://razorpay.com/docs/payroll/employees/declarations/#declare-upload-proofs',
  },
  {
    id: 2,
    title: 'How can I change my tax regime?',
    url: 'https://razorpay.com/docs/payroll/employees/declarations/#regime-changes',
  },
  {
    id: 3,
    title: 'I have received an email that XPayroll has changed/removed by tax declaration. Why is that?',
    url: 'https://razorpay.com/docs/payroll/employees/declarations/#resolve-failed-declarations',
  },
  {
    id: 4,
    title: 'How can I reduce my tax liability?',
    url: 'https://razorpay.com/docs/payroll/employees/declarations/#reduce-tax-liability',
  },
  {
    id: 5,
    title: 'How do I check my payslips and apply for reimbursements via WhatsApp?',
    url: 'https://razorpay.com/docs/payroll/employees/whatsapp-integration/',
  },
  {
    id: 6,
    title: 'What other benefits can I avail on the Payroll Dashboard?',
    url: 'https://razorpay.com/docs/payroll/employees/benefits/',
  },
  {
    id: 7,
    title: 'How can track my leaves and attendance?',
    url: 'https://razorpay.com/docs/payroll/employees/attendance-leaves/',
  },
  {
    id: 8,
    title: 'Employee FAQs',
    url: 'https://razorpay.com/docs/payroll/faqs/employees/',
  },
  {
    id: 9,
    title: 'Employees - Get Started',
    url: 'https://razorpay.com/docs/payroll/employees/',
  },
  {
    id: 10,
    title: 'Explore Video Tutorials',
    url: 'https://razorpay.com/docs/payroll/video-tutorials/',
  },
];

export const videoLinks = [
  {
    id: 1,
    title: 'Executing a payroll',
    url: 'https://intercom.help/XPayroll/en/articles/5190078-executing-a-payroll',
  },
  {
    id: 2,
    title: 'XPayroll - User Access',
    url: 'https://intercom.help/XPayroll/en/articles/5540444-xpayroll-user-access',
  },
  {
    id: 3,
    title: 'Contractor Payments',
    url: 'https://intercom.help/XPayroll/en/articles/5200192-contractor-payments',
  },
  {
    id: 4,
    title: 'How to pay and recover a Salary Advance?',
    url: 'https://intercom.help/XPayroll/en/articles/5279075-how-to-pay-and-recover-a-salary-advance',
  },
  {
    id: 5,
    title: 'Time, Attendance & Leave',
    url: 'https://intercom.help/XPayroll/en/articles/5160742-time-attendance-leave',
  },
];

export const adminArticleLinksM1 = adminArticleLinks.filter(
  (article) => ![2, 9, 11].includes(article.id),
);

export const employeeArticleLinksM1 = employeeArticleLinks.filter(
  (article) => ![2, 4, 6, 7].includes(article.id),
);

export const videoLinksM1 = videoLinks.filter((article) => ![4].includes(article.id));
