import { useCallback, useState } from 'react';

export interface UseIdsType {
  value: number[];
  setValue: React.Dispatch<React.SetStateAction<number[]>>;
  push: (element: number) => void;
  remove: (element: number) => void;
  isEmpty: () => boolean;
  hasId: (element: number) => boolean;
}

export type UseIdsProps  = Array<number>;

const useIds = (initialValue: UseIdsProps = []) : UseIdsType => {
  const [value, setValue] = useState<number[]>(initialValue);

  const push = useCallback((element: number) => {
    setValue((oldValue) => [...oldValue, element]);
  }, []);

  const remove = useCallback((element: number) => {
    setValue((oldValue) => oldValue.filter((v) => v !== element));
  }, []);

  const isEmpty = () => value.length === 0;

  const hasId = (element: number) => value.includes(element);

  return { value, setValue, push, remove, isEmpty, hasId };
};

export default useIds;