import { useEffect, useState, useRef } from 'react';
import api from 'api';
import { useMutation } from 'react-query';
import { ChatContextType, useChatContext } from './ChatContext';
import { ChatMessageTypes, ChatResponseTypes } from './constants';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { UserPromptRequest } from 'api/payrollAiAssistant';
import { showErrorToastNotification } from 'utils/ToastEvents';
import { getRandomPick } from './useRandomPick';

interface UserQuery {
  query: string;
  answer: string | null;
}

export const UPDATE_USER_PROMPTS = 'UPDATE_USER_PROMPTS';
export const INIT_FOR_NON_PAYROLL_USER = 'INIT_FOR_NON_PAYROLL_USER';

const containsNoNotInsideWord = (str: string) => {
  var pattern = /\bno\b/i;
  return pattern.test(str);
};

const extractNumbersFromString = (str: string) => {
  let numbersMatch = str.match(/\d+(\.\d+)?/g);

  if (numbersMatch === null) {
    return [];
  }

  return numbersMatch.map(Number);
};

const useChatHandler = () => {
  const {
    messages,
    pollData,
    pushMessage,
    setIsBotProcessing,
    chatId,
    setChatId,
    setConversationCount,
    setPolling,
    captcha,
    setInputSuggestions,
    setFeedbackPhase,
  } = useChatContext() as ChatContextType;
  const userPrompts = useRef<UserQuery[]>([]);
  const [isAskingQuestion, setIsAskingQuestion] = useState<boolean>(false);

  const updateUserPromptsAndRetry = useMutation({
    mutationKey: UPDATE_USER_PROMPTS,
    mutationFn: (payload: { userPrompts: UserPromptRequest | null; directPrompt: string | null }) =>
      api.payrollAiAssistant.updateUserPromptsAndRetry(
        chatId,
        payload.userPrompts,
        payload.directPrompt,
      ),
    onSuccess: (data) => {
      setConversationCount(data.conversation_count);
      setPolling(true);
    },
    onError: (error: typeof AppError) => {
      setIsBotProcessing(false);
      const message = getAppErrorDetails(error);
      if(message.type === 429) {
        pushBotMessage("Oops! It seems you've exceeded the maximum number of retry attempts. Please try again later in a new session. Thank you! \n\nCan you tell me how I did 🥹?");
        setFeedbackPhase(true);
        setInputSuggestions(["😍 Awesome!", "🙂 It was okay", "😡 Awful", "Share with your team"]);
      }
    },
  });

  const initNonPayrollUserFlow = useMutation({
    mutationKey: INIT_FOR_NON_PAYROLL_USER,
    mutationFn: (payload: { ctc: string; captcha: string }) =>
      api.payrollAiAssistant.initiateForNonPayrollUsers(payload.ctc, payload.captcha),
    onSuccess: (data) => {
      setChatId(data.conversation_key);
      setConversationCount(data.conversation_count);
      setPolling(true);
    },
    onError: (error: typeof AppError) => {
      console.log(error);
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 5000,
      });
      setIsBotProcessing(false);
    },
  });

  const pushBotMessage = (text: string) => {
    pushMessage({ initiator: 'bot', type: ChatMessageTypes.text, messageData: text });
  };

  const setNextUserPropmt = () => {
    const prompts = userPrompts.current;
    const nextPrompt = prompts.find((data) => !data.answer);
    if (nextPrompt) {
      setIsAskingQuestion(true);
      pushBotMessage(nextPrompt.query);
    } else {
      setIsBotProcessing(true);
      const inputPropmpt = prompts.reduce(
        (
          acc: {
            [key: string]: string;
          },
          obj,
        ) => {
          acc[obj.query] = obj.answer ?? '';
          return acc;
        },
        {},
      );
      updateUserPromptsAndRetry.mutate({
        userPrompts: inputPropmpt,
        directPrompt: null,
      });
      userPrompts.current = [];
    }
  };

  const updateAnswer = (answer: string) => {
    const question = messages[messages.length - 2];
    userPrompts.current = userPrompts.current.map((prompt) => {
      if (prompt.query === question.messageData) {
        return {
          ...prompt,
          answer: answer,
        };
      }
      return prompt;
    });
    setNextUserPropmt();
  };

  const sendDirectPrompt = (prompt: string) => {
    setIsBotProcessing(true);
    updateUserPromptsAndRetry.mutate({
      userPrompts: null,
      directPrompt: prompt,
    });
  };

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];

      if (!chatId && latestMessage.initiator === 'user') {
        // user entering the ctc
        const numberInTheText = extractNumbersFromString(latestMessage.messageData);
        if (captcha && numberInTheText.length > 0) {
          setIsBotProcessing(true);
          initNonPayrollUserFlow.mutate({ ctc: latestMessage.messageData, captcha: captcha });
        } else if (!captcha) {
          pushBotMessage(
            "Are you a bot? Or are you a highly sophisticated AI designed to assist humans in their quest for knowledge? Perhaps we're both just lines of code, dancing in the digital universe, destined to interact for eternity. But hey, if you're a human looking for some help, I'm here for you! 😄",
          );
        } else if (!numberInTheText.length) {
          pushBotMessage(
            getRandomPick([
              'Oops! The CTC amount you entered seems to be invalid. Please try again',
              "Hmm, it looks like the CTC amount provided isn't quite right. Can you please double-check and enter it again?",
              "Sorry, the CTC amount you entered doesn't appear to be valid. Please input a correct amount.",
              "The CTC amount you've entered isn't valid. Please provide a valid amount to continue.",
            ]),
          );
        }
      }

      if (
        pollData?.response_type === ChatResponseTypes.REGIME_BREAKUP &&
        latestMessage.initiator === 'user' &&
        latestMessage.type === ChatMessageTypes.text &&
        !latestMessage.hasCallback
      ) {
        if (!isAskingQuestion) {
          switch (true) {
            case latestMessage.messageData.toLocaleLowerCase().includes('yes'):
              setInputSuggestions([]);
              setNextUserPropmt();
              break;
            case latestMessage.messageData.toLocaleLowerCase().includes('no'):
              pushBotMessage('Can you tell me how I did 🥹?');
              setFeedbackPhase(true);
              setInputSuggestions(["😍 Awesome!", "🙂 It was okay", "😡 Awful", "Share with your team"]);
              break;
            default:
              pushBotMessage("I'm sorry, didn't get you. Try with a 'yes' or 'no'.");
          }
        } else {
          updateAnswer(latestMessage.messageData);
        }
      }

      if (
        pollData?.response_type === ChatResponseTypes.REGIME_BREAKUP &&
        latestMessage.initiator === 'bot' &&
        !['knowMore', 'calculation'].includes(latestMessage.type) &&
        !isAskingQuestion
      ) {
        const prompts = pollData.response?.user_prompts;
        if (prompts && prompts.length > 0) {
          const formattedUserPrompts = prompts.map((data) => ({ query: data, answer: null }));
          userPrompts.current = formattedUserPrompts;
        }
      }

      if (
        pollData?.response_type === ChatResponseTypes.TAX_BREAKUP &&
        latestMessage.initiator === 'user' &&
        latestMessage.type === ChatMessageTypes.text &&
        !latestMessage.hasCallback
      ) {
        setInputSuggestions([]);
        switch (true) {
          case latestMessage.messageData.trim().toLocaleLowerCase() === 'yes':
            pushBotMessage('What option do you want try changing?');
            break;
          case containsNoNotInsideWord(latestMessage.messageData.toLocaleLowerCase()): // to hanlde the know more action
            pushBotMessage('Can you tell me how I did 🥹?');
            setFeedbackPhase(true);
            setInputSuggestions(["😍 Awesome!", "🙂 It was okay", "😡 Awful", "Share with your team"]);
            break;
          default:
            sendDirectPrompt(latestMessage.messageData);
        }
      }
    }
  }, [messages, pollData?.response_type, isAskingQuestion]);
};

export { useChatHandler };
