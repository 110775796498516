import React, { PropsWithChildren } from 'react';
export function ContentContainer({
  parentClass,
  childClass,
  rightSidebarClass,
  children,
  rightSidebar,
  showSidebar,
}: PropsWithChildren<{ rightSidebar?: React.ReactNode; showSidebar: boolean, parentClass?:string, childClass? :string, rightSidebarClass? :string }>) {
  return (
    <div className={ `flex w-full min-h-full ${parentClass? parentClass : ""}`}>
      <div className={`flex flex-col flex-1 p-20 w-full ${childClass? childClass : ""}`}>{children}</div>
      {showSidebar && <aside className={`flex flex-col bg-rhino w-128 p-10 ${rightSidebarClass? rightSidebarClass : ""}`}>{rightSidebar}</aside>}
    </div>
  );
}
