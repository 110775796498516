import { format } from 'date-fns';
import React, { useState } from 'react';

import { LinkButtonV2, StandardButton, StandardSecondaryButton } from 'components/ui/Button';
import { Input, InputElement } from 'components/ui/Form/Input';
import { useModal } from 'components/ui/Modals';
import Modal from 'components/ui/Modals/components/Modal';
import { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';

import { RunPayrollData } from '../utils/types';
import styles from './index.module.scss';
import { nonEditableArrearComponents } from '../constants';

type ExcludeNull<T> = T extends null ? never : T;

const RlopArrearBreakdown = ({
  breakdown,
  onSubmission,
  onEdit,
}: {
  breakdown: GetArrearsSchemaContract['rlop_breakdown'];
  payrollEntry: RunPayrollData;
  onSubmission: (breakdown: ExcludeNull<GetArrearsSchemaContract['arrear_breakdown']>) => void;
  onEdit: () => void;
}) => {
  const { closeModal } = useModal();

  const [arrearsBreakdown, setArrearsBreakdown] = useState<
    ExcludeNull<GetArrearsSchemaContract['arrear_breakdown']>
  >(breakdown ?? {});

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmission(arrearsBreakdown);
  };

  const onArrearChange = (
    monthKey: string,
    componentToUpdate: string,
    type: string,
    value: string,
  ) => {
    const oldArrears = arrearsBreakdown[monthKey];
    const newArrears = oldArrears?.map((component) => {
      if (component.name === componentToUpdate && type == component.type) {
        return {
          ...component,
          amount: isNaN(parseInt(value)) ? 0 : parseInt(value),
        };
      }
      return component;
    });
    setArrearsBreakdown({
      ...arrearsBreakdown,
      [monthKey]: newArrears,
    });
  };

  const arrearMonths = Object.keys(arrearsBreakdown)
    .map((key) => {
      const monthMap: { [key: string]: number } = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
      };
      const match = key.match(/(\w{3}) (\d{4})/);
      if (match) {
        const [, month, year] = match;
        const dateObject = new Date(+year, monthMap[month]);
        return dateObject;
      }
      return null;
    })
    .sort((month1, month2) => {
      // doing this as the array might contain null values as well
      if (month1 === null) return 1;
      if (month2 === null) return -1;
      return month1.getTime() - month2.getTime();
    });

  const handleEditClick = () => {
    onEdit();
  };

  const isComponentNonEditable = (component: typeof arrearsBreakdown[string][number]) => {
    return nonEditableArrearComponents.includes(component.name);
  };

  return (
    <Modal>
      <div
        className={`grid max-w-xl ${styles['run-payroll-modal']} max-h-screen`}
        style={{
          gridTemplateRows: 'auto 1fr',
        }}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Add Reverse LOP
        </div>
        <form
          onSubmit={onSubmit}
          className="grid w-full p-10 overflow-y-auto"
          style={{
            gridTemplateRows: '1fr auto',
          }}>
          <div className="flex-1 overflow-y-auto">
            <div className="flex items-center justify-between bg-white-o-05 px-6 py-4">
              <div>Change reverse Loss of pay days</div>
              <LinkButtonV2 type="button" onClick={handleEditClick}>
                EDIT
              </LinkButtonV2>
            </div>
            <div className={`flex flex-col gap-10 mb-10 mt-16`}>
              {arrearMonths.map((arrearMonth) => {
                if (!arrearMonth) {
                  return null;
                }

                const keyFormat = format(arrearMonth, 'MMM yyyy');

                const arrearComponents = arrearsBreakdown[keyFormat];

                return (
                  <div key={keyFormat}>
                    <span>Reverse LOP arrear breakdown for {format(arrearMonth, 'MMMM yyyy')}</span>
                    <>
                      {arrearComponents.length > 0 && (
                        <>
                          <table className="w-full mt-5">
                            <thead>
                              <tr>
                                <th>Label</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            {arrearComponents.map((component) => {
                              return (
                                <tr key={component.name}>
                                  <td>
                                    <Input
                                      type="text"
                                      name={`name-${component.name}`}
                                      value={component.name ?? ''}
                                    />
                                  </td>
                                  <td>
                                    <InputElement>
                                      <Input
                                        type="number"
                                        className={styles['run-payroll-edit-number']}
                                        name={`amount-${component.amount}`}
                                        value={component.amount}
                                        onChange={(event) => {
                                          onArrearChange(
                                            keyFormat,
                                            component.name,
                                            component.type,
                                            event.target.value,
                                          );
                                        }}
                                        disabled={isComponentNonEditable(component)}
                                      />
                                    </InputElement>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </>
                      )}
                    </>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pt-10">
            <StandardButton isPending={false} disabled={false} type={'submit'}>
              ADD RLOP ARREARS
            </StandardButton>
            <StandardSecondaryButton
              onClick={() => {
                closeModal();
              }}
              isPending={false}
              disabled={false}>
              CLOSE
            </StandardSecondaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RlopArrearBreakdown;
