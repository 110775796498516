import { getStaticMediaUrl } from '../../utils/Urls';
import { routePaths } from 'components/Routes/data';
import { useState } from 'react';
import plumIcon from 'assets/icon--plum.png';
import freshTeamLogo from 'assets/logo-freshteam.png';
import jupiterLogo from 'assets/logo--jupiter.png';
import slackLogo from 'assets/logo--slack.png';
import quickbooksLogo from 'assets/logo--quickbooks.png';
import whatsappLogo from 'assets/logo--whatsapp.png';
import camsLogo from 'assets/logo--cams.png';
import zaggleLogo from 'assets/logo-zaggle.png';
import cleartaxLogo from 'assets/logo--cleartax.png';
import klaarLogo from 'assets/logo--klaar.png';
import zohoPeopleLogo from 'assets/logo--zoho-people.png';
import springVerifyLogo from 'assets/logo--spring-verify.png';
import jibbleLogo from 'assets/jibble/logo--jibble.png';
import pazcareLogo from 'assets/logo--pazcare.png';
import darwinboxLogo from 'assets/logo--darwinbox.png';
import hibobLogo from 'assets/logo--hibob.png';
import worqLogo from 'assets/logo--worq.png';
import worklineLogo from 'assets/logo--workline.png';

import { store } from './../../reducers/index';
import { getActionItemsCount } from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/utils/helpers';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import loggedInUserSelectors from "reducers/loggedInUser/selectors";
import api from '../../api';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from "../PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/utils/queries";
import { AppError } from "utils/AppError";
import { GetOrgFlexiPartnerDetailsSchemaContract } from 'schemas/GetOrgFlexiPartnerDetailsSchema';
import { getOrgFlexiDetails } from "../PartnerFlexiBenefits/PartnerFlexiBenefitsAdmin/utils/init";


interface integrationUrlAndImageDataType {
  [id: number]: {
    name: string;
    imgSrc: string;
    exploreUrl: string;
    manageUrl: string;
    isExternalLink?: boolean;
    isRecentlyLaunched?: boolean;
  };
}

const isZaggleOrgAdminScreenEnabled = (): boolean => {
  const features = store.getState()?.loggedInUser?.data?.currentOrganization?.features || null;
  return !!features?.enableZaggle;
};

const ShowAdminActionItemsScreen = (): boolean => {

  const [flexiDetails, setFlexiDetails] = useState<GetOrgFlexiPartnerDetailsSchemaContract>(() => getOrgFlexiDetails());
  const orgId = useSelector(loggedInUserSelectors.organizationID);
  
  const { isLoading, isRefetching, error, data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
      if (!orgId) {
        return null;
      }
      return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
    }, {
      onSuccess: (data) => data && setFlexiDetails(data),
      onError: (error: typeof AppError) => {
          console.log("Error on fetching Org's flexi details data : ", error);
      }
    });

    return getActionItemsCount(flexiDetails)>0;
  
}

export const getIntegrationUrlAndImageData = (): integrationUrlAndImageDataType => ({
  1: {
    name: 'Slack',
    imgSrc: getStaticMediaUrl(slackLogo),
    exploreUrl: '/slack-settings',
    manageUrl: '/slack-settings',
    isRecentlyLaunched: false,
  },
  2: {
    name: 'QuickBooks',
    imgSrc: getStaticMediaUrl(quickbooksLogo),
    exploreUrl: '/editExternalAccountingIntegration?type=1',
    manageUrl: '/editExternalAccountingIntegration?type=1',
    isRecentlyLaunched: false,
  },
  3: {
    name: 'Freshteam',
    imgSrc: getStaticMediaUrl(freshTeamLogo),
    exploreUrl: '/freshteam-settings',
    manageUrl: '/freshteam-settings',
    isRecentlyLaunched: false,
  },
  4: {
    name: 'Plum',
    imgSrc: getStaticMediaUrl(plumIcon),
    exploreUrl: '/insurance/admin',
    manageUrl: '/insurance/admin',
    isRecentlyLaunched: false,
  },
  5: {
    name: 'Cams',
    imgSrc: getStaticMediaUrl(camsLogo),
    isExternalLink: false,
    exploreUrl: '/cams-intro',
    manageUrl: '/cams-intro',
    isRecentlyLaunched: false,
  },
  6: {
    name: 'Jupiter',
    imgSrc: getStaticMediaUrl(jupiterLogo),
    exploreUrl: '/jupiter-settings',
    manageUrl: '',
    isRecentlyLaunched: false,
  },
  7: {
    name: 'Whatsapp',
    imgSrc: getStaticMediaUrl(whatsappLogo),
    exploreUrl: '/whatsapp-settings',
    manageUrl: '/whatsapp-settings',
    isRecentlyLaunched: false,
  },
  8: {
    name: 'Zaggle',
    imgSrc: getStaticMediaUrl(zaggleLogo),
    exploreUrl: isZaggleOrgAdminScreenEnabled()
      ? routePaths.partnerFlexiBenefits.admin.info
      : routePaths.partnerFlexiBenefits.admin.scheduleACall,
    manageUrl: ShowAdminActionItemsScreen()
      ? routePaths.partnerFlexiBenefits.admin.actionItems
      : routePaths.partnerFlexiBenefits.admin.connectedStatus,
    isRecentlyLaunched: true,
  },
  9: {
    name: 'Cleartax',
    imgSrc: getStaticMediaUrl(cleartaxLogo),
    exploreUrl: routePaths.viewForm16,
    manageUrl: '',
    isRecentlyLaunched: false,
  },
  10: {
    name: 'Pazcare',
    imgSrc: getStaticMediaUrl(pazcareLogo),
    exploreUrl: routePaths.settings.pazcare.settings,
    manageUrl: routePaths.settings.pazcare.deactivation,
    isRecentlyLaunched: true,
  },
  11: {
    name: 'Klaar',
    imgSrc: getStaticMediaUrl(klaarLogo),
    exploreUrl: routePaths.settings.klaar.settings,
    manageUrl: routePaths.settings.klaar.activation,
    isRecentlyLaunched: true,
  },
  12: {
    name: 'Zoho People',
    imgSrc: getStaticMediaUrl(zohoPeopleLogo),
    exploreUrl: routePaths.settings.zoho.introduction,
    manageUrl: routePaths.settings.zoho.home,
    isRecentlyLaunched: true,
  },
  13: {
    name: 'SpringVerify',
    imgSrc: getStaticMediaUrl(springVerifyLogo),
    exploreUrl: routePaths.settings.springVerify.settings,
    manageUrl: routePaths.settings.springVerify.deactivation,
    isRecentlyLaunched: true,
  },
  14: {
    name: 'Jibble',
    imgSrc: getStaticMediaUrl(jibbleLogo),
    exploreUrl: routePaths.settings.jibble.introduction,
    manageUrl: routePaths.settings.jibble.manage,
    isRecentlyLaunched: true,
  },
  15: {
    name: 'Darwinbox',
    imgSrc: getStaticMediaUrl(darwinboxLogo),
    exploreUrl: routePaths.settings.darwinbox.introduction,
    manageUrl: routePaths.settings.darwinbox.introduction,
    isRecentlyLaunched: true,
  },
  16: {
    name: 'HiBoB',
    imgSrc: getStaticMediaUrl(hibobLogo),
    exploreUrl: routePaths.settings.hibob.introduction,
    manageUrl: routePaths.settings.hibob.introduction,
    isRecentlyLaunched: true,
  },
  17: {
    name: 'Worq',
    imgSrc: getStaticMediaUrl(worqLogo),
    exploreUrl: routePaths.settings.worq.introduction,
    manageUrl: routePaths.settings.worq.introduction,
    isRecentlyLaunched: true,
  },
  18: {
    name: 'Workline',
    imgSrc: getStaticMediaUrl(worklineLogo),
    exploreUrl: routePaths.settings.workline.introduction,
    manageUrl: routePaths.settings.workline.introduction,
    isRecentlyLaunched: true,
  },
});
