import {
    Box,
    Button,
    Divider,
    Heading,
    IconButton,
    IconButtonProps,
    PlusIcon,
    Spinner,
    Text,
    TrashIcon,
} from '@razorpay/blade/components';
import { format, parse } from 'date-fns';
import React, { useState } from 'react';
import { dateFormats } from 'utils/Dates';
import { windowTypeNames } from '../constants';
import { AdvancedWindow, NewAdvancedWindow } from '../types';
import { Table } from './styles';
import useAdvancedWindowConfigQuery from './useAdvancedWindowConfigQuery';
import CreateCustomWindowModal from '../../../ui/CustomWindow/CreateCustomWindowModal';
import DeleteConfiration from 'components/ui/CustomWindow/DeleteConfirmation';
import EmployeeListing from 'components/ui/CustomWindow/EmployeeListing';

interface TableRowData {
    startDate: string;
    endDate: string;
    type: string;
    employees: Array<AdvancedWindow>;
}

interface TableRowProps extends TableRowData {
    onDelete: IconButtonProps['onClick'];
    onEdit: IconButtonProps['onClick'];
    onEmployeeCountClick: () => void;
    id: string;
}

const TableRow = ({
    id,
    type,
    startDate,
    endDate,
    employees,
    onDelete,
    onEdit,
    onEmployeeCountClick,
}: TableRowProps) => {
    return (
        (<tr>
            <td>
                <Text color="surface.text.gray.muted">{windowTypeNames[type as keyof typeof windowTypeNames]}</Text>
            </td>
            <td>
                <Text color="surface.text.gray.muted">
                    {format(parse(startDate, dateFormats.yearMonthDate, new Date()), dateFormats.dateMonthYear)}
                </Text>
            </td>
            <td>
                <Text color="surface.text.gray.muted">
                    {format(parse(endDate, dateFormats.yearMonthDate, new Date()), dateFormats.dateMonthYear)}
                </Text>
            </td>
            <td style={{ cursor: 'pointer' }} onClick={onEmployeeCountClick}>
                <Text color="surface.text.primary.normal">
                    {employees.length || 0}
                </Text>
            </td>
            <td>
                <Box display="flex" gap="spacing.5" justifyContent="flex-end">
                    {/* <IconButton icon={EditInlineIcon} accessibilityLabel="Edit" onClick={onEdit} /> */}
                    <IconButton icon={TrashIcon} accessibilityLabel="Delete" onClick={onDelete} />
                </Box>
            </td>
        </tr>)
    );
};

const CustomWindowSettings = ({ orgId }: { orgId: string | number }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);

    const {
        advancedWindowItems,
        deleteAdvancedWindowConfig,
        updateAdvancedWindowConfig,
        addAdvancedWindowConfig,
        isLoading,
    } = useAdvancedWindowConfigQuery({
        orgId,
        onAddSuccess: () => {
            setIsOpen(false);
        },
        onDeleteSuccess: () => {
            setIsDeleteConfirmationOpen(false);
        },
        onUpdateSuccess: () => { },
    });

    const groupedItems = advancedWindowItems.reduce((a, c) => {
        const key = c.startDate + c.endDate + c.type;
        const arr = a[key] && a[key].employees ? [...a[key].employees, c] : [c];
        return {
            ...a,
            [key]: {
                startDate: c.startDate,
                endDate: c.endDate,
                type: c.type,
                employees: arr,
            },
        };
    }, {} as Record<string, { startDate: string; endDate: string; type: string; employees: Array<AdvancedWindow> }>);

    const onDismiss = () => {
        setIsOpen(false);
    };

    const onDeleteConfirmationDismiss = () => {
        setIsDeleteConfirmationOpen(false);
    };

    const onEmployeeListingDismiss = () => {
        setIsEmployeeListOpen(false);
    };

    const handleCreateNewWindowClick = () => {
        setIsOpen(true);
    };

    const onDeleteConfirmation = () => {
        if (selectedRow) {
            deleteAdvancedWindowConfig.mutate({
                org: orgId,
                ids: selectedRow.employees.map((e) => e.id),
            });
        }
    };

    const handleEmployeeCountClick = (id: string) => {
        setSelectedRow(groupedItems[id]);
        setIsEmployeeListOpen(true);
    };

    const handleDeleteClick = (id: string) => {
        setSelectedRow(groupedItems[id]);
        setIsDeleteConfirmationOpen(true);
    };

    const isTableVisible = advancedWindowItems.length > 0;

    const onCreateNewWindow = (window: NewAdvancedWindow) => {
        addAdvancedWindowConfig.mutate({
            data: {
                employeeEmails: window.employees.split(',').filter((e) => e.trim().length > 0),
                endDate: format(window.endDate, dateFormats.yearMonthDate),
                startDate: format(window.startDate, dateFormats.yearMonthDate),
                type: window.type,
            },
            org: orgId,
        });
    };

    if (isLoading) {
        return (
            <Box display="grid" placeItems="center" marginTop="42px">
                <Spinner accessibilityLabel="Loading custom window settings" />
            </Box>
        );
    }

    return (
        (<Box marginTop="42px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="column" gap="spacing.2" maxWidth="300px">
                    <Text size="large">Advance window configuration</Text>
                    <Text size="small" color="surface.text.gray.disabled">
                        Custom windows opened for employees which are currently active
                    </Text>
                </Box>
                <Button
                    size="small"
                    variant="secondary"
                    icon={PlusIcon}
                    onClick={handleCreateNewWindowClick}>
                    Create new window
                </Button>
            </Box>
            {isTableVisible ? (
                <Box
                    marginTop={'spacing.7'}
                    borderColor={'surface.border.gray.subtle'}
                    borderWidth="thin"
                    borderRadius={'medium'}
                    padding={'spacing.7'}
                    zIndex="1"
                    backgroundColor="surface.background.gray.moderate">
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <Text weight="semibold">Window</Text>
                                </th>
                                <th align="left">
                                    <Text weight="semibold">From</Text>
                                </th>

                                <th align="left">
                                    <Text weight="semibold">To</Text>
                                </th>
                                <th>
                                    <Text weight="semibold">Employee(s)</Text>
                                </th>
                                <th>
                                    <Box />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <Divider />
                                </td>
                            </tr>
                            {Object.entries(groupedItems).map(([key, row]) => (
                                <TableRow
                                    key={key}
                                    id={key}
                                    type={row.type}
                                    startDate={row.startDate}
                                    endDate={row.endDate}
                                    employees={row.employees ?? []}
                                    onDelete={() => handleDeleteClick(key)}
                                    onEdit={() => null}
                                    onEmployeeCountClick={() => handleEmployeeCountClick(key)}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Box>
            ) : null}
            <CreateCustomWindowModal
                isOpen={isOpen}
                onDismiss={onDismiss}
                onSubmit={onCreateNewWindow}
                isLoading={addAdvancedWindowConfig.isLoading}
            />
            <DeleteConfiration
                isOpen={isDeleteConfirmationOpen}
                onDismiss={onDeleteConfirmationDismiss}
                onConfirm={onDeleteConfirmation}
                isLoading={deleteAdvancedWindowConfig.isLoading}
            />
            <EmployeeListing
                employees={selectedRow?.employees || []}
                isOpen={isEmployeeListOpen}
                onDismiss={onEmployeeListingDismiss}
            />
        </Box>)
    );
};

export default CustomWindowSettings;