import api from 'api';
import { useQuery } from 'react-query';
import { GET_HRMS_EMPLOYEE_TYPES_QUERY_KEY, KNIT_HRMS_QUERY_RETRY_LIMIT } from './constants';
import { useState } from 'react';

const useGetKnitHrmsEmployeeTypesLovQuery = (knitHrmsApp: string) => {
  const [retryAttempts, setRetryAttempts] = useState(0);
  const knitHrmsEmployeeTypesLovQuery = useQuery({
    queryFn: async () => {
      const lovData = await api.knit.getHrmsEmployeeTypes(knitHrmsApp);
      if (lovData.success) {
        lovData.data.fields = lovData.data.fields.map(({ id, label }) => ({
          id: id ?? label,
          label,
        }));
      }

      return lovData;
    },
    queryKey: [GET_HRMS_EMPLOYEE_TYPES_QUERY_KEY, knitHrmsApp],
    staleTime: 300000, // setting 5 minute stale time as this data won't change frequently
    onError: () => {
      setRetryAttempts((prev) => prev + 1);
    },
  });

  const retryLimitExhausted = retryAttempts > KNIT_HRMS_QUERY_RETRY_LIMIT;

  return {
    knitHrmsEmployeeTypesLovQuery,
    retryLimitExhausted,
  };
};

export { useGetKnitHrmsEmployeeTypesLovQuery };
