import React from 'react';

import styles from './index.module.scss';

const LineLoader = ({ isLoading }: { isLoading: boolean }) => {
  if (!isLoading) {
    return <div className={styles['line-space']}></div>;
  }
  return <div className={styles['line-loader']}></div>;
};

export default LineLoader;
