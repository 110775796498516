import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryButton, StandardButton } from 'components/ui/Button';
import { useMutation } from 'react-query';
import api from 'api';
import slackCurrentlyInstalled from 'assets/slack/slack_currently_installed.png';
import slackUninstalled from 'assets/integration_uninstalled.png';
import { getStaticMediaUrl } from 'utils/Urls';
import ErrorMessage from 'components/ui/ErrorMessage';
import { SuccessAlert, WarningAlert } from 'components/ui/Alert';
import { useModal } from 'components/ui/Modals';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import APIError from 'types/apiError';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

interface Props {}

function SlackUninstall({}: Props): ReactElement {
  const { openModal } = useModal();
  const brandName = useSelector(loggedInUserSelectors.organizationBrandName);
  const scopes = useSelector(loggedInUserSelectors.slackScopes);
  const feature = useSelector(loggedInUserSelectors.features);
  const uninstallMutation = useMutation(() => api.slackSettings.uninstall(), {
    onError: (error: APIError) => {},
  });

  const handleUninstall = () => {
    uninstallMutation.mutate();
  };

  const confirmUninstall = () => {
    openModal(
      <ConfirmModal onConfirm={handleUninstall}>
        <div className="flex flex-col items-center max-w-xl">
          <img
            className={styles['HeadlineImage']}
            src={getStaticMediaUrl(slackUninstalled)}
            style={{ width: 227, height: 190, marginBottom: 16 }}
          />
          <div className={'text-4xl font-bold text-white mb-8 mt-4 text-center'}>Uninstall?</div>
          <div className="mb-8">
            We are sad to see you go :( <br />
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  if (uninstallMutation.status === 'error') {
    return (
      <ErrorMessage title="Sorry! Something went wrong!">
        {uninstallMutation.error?.message}
      </ErrorMessage>
    );
  }

  if (uninstallMutation.status === 'success') {
    return (
      <div className="flex flex-col items-center">
        <img
          className={styles['HeadlineImage']}
          src={getStaticMediaUrl(slackUninstalled)}
          style={{ width: 227, height: 190, marginBottom: 16 }}
        />
        <div className={styles.Headline}>
          The XPayroll app has been uninstalled from Slack. You can always install it again from the
          settings page.
        </div>
        <Link to={routePaths.integrations.root}>
          <StandardButton>BACK TO INTEGRATIONS</StandardButton>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(slackCurrentlyInstalled)} />
      <div className={styles.Headline}>
        The XPayroll app is currently installed on {brandName}'s Slack workspace
      </div>
      <div>
        {!scopes?.split(',').includes('chat:write') && (
          <WarningAlert>
            You have not granted us permission to send you messages. <br />
            Please reinstall the app or you won't be able to receive Slack notifications from us.
          </WarningAlert>
        )}
        <br />
        <SuccessAlert>
          Click uninstall to disconnect XPayroll from your company's Slack workspace. <br />
          Admins and employees will be unable to access commands until reinstalled.
        </SuccessAlert>
      </div>
      <div className="mt-10"></div>
      <PrimaryButton onClick={confirmUninstall} isPending={uninstallMutation.status === 'loading'}>
        Uninstall
      </PrimaryButton>
    </div>
  );
}

export default SlackUninstall;
