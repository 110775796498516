import api from 'api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AppError } from 'utils/AppError';
import {
  DELETE_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
  GET_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
  UPDATE_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
} from '../queries';
import { showToastViaEvent } from 'utils/ToastEvents';
import { useState } from 'react';
import { AdvancedWindow } from '../types';

interface Props {
  orgId: string | number;
  onAddSuccess: () => void;
  onDeleteSuccess: () => void;
  onUpdateSuccess: () => void;
}

const useAdvancedWindowConfigQuery = ({
  orgId,
  onAddSuccess,
  onDeleteSuccess,
  onUpdateSuccess,
}: Props) => {
  const queryClient = useQueryClient();
  const [advancedWindowItems, setAdvancedWindowItems] = useState<Array<AdvancedWindow>>([]);

  const { isFetching } = useQuery({
    queryKey: GET_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
    queryFn: () => api.flexibleBenefits.getExceptionWindowSettings(orgId),
    onSuccess: (data) => {
      setAdvancedWindowItems(data);
    },
    onError: (e: typeof AppError) => {},
  });

  const updateAdvancedWindowConfig = useMutation({
    mutationKey: UPDATE_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
    mutationFn: api.flexibleBenefits.updateExceptionWindowSettings,
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ADVANCED_FLEXIBLE_BENEFITS_WINDOW);
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      onUpdateSuccess();
    },
    onError: (e: typeof AppError) => {},
  });

  const addAdvancedWindowConfig = useMutation({
    mutationKey: UPDATE_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
    mutationFn: api.flexibleBenefits.addExceptionWindowSettings,
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ADVANCED_FLEXIBLE_BENEFITS_WINDOW);
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      onAddSuccess();
    },
    onError: (e: typeof AppError) => {},
  });

  const deleteAdvancedWindowConfig = useMutation({
    mutationKey: DELETE_ADVANCED_FLEXIBLE_BENEFITS_WINDOW,
    mutationFn: api.flexibleBenefits.deleteExceptionWindowSetting,
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ADVANCED_FLEXIBLE_BENEFITS_WINDOW);
      showToastViaEvent({
        text: 'Configuration saved successfully!',
        timeout: 5000,
        type: 'success',
        icon: 'success',
      });
      onDeleteSuccess();
    },
    onError: (e: typeof AppError) => {},
  });

  return {
    updateAdvancedWindowConfig,
    deleteAdvancedWindowConfig,
    isLoading: isFetching,
    advancedWindowItems,
    addAdvancedWindowConfig,
  };
};

export default useAdvancedWindowConfigQuery;
