import React from "react";
import { Box, Spinner, Text, Alert } from '@razorpay/blade/components';
import { GenericDeclaration, DeclarationErrors } from 'components/ui/DeclarationWindow/types';
import DeclarationWindow from '../../ui/DeclarationWindow/DeclarationWindow';
import { TaxDeclarationSettingsSchemaContract } from 'schemas/TaxDeclarationSettingsSchema';
import ProofIcon from 'assets/window-url.svg';
import { convertToOrdinal } from "utils/Dates";


const ProofUploadWindowSettings = (
    {
        proofUploadWindow,
        onChange,
        settings,
        isLoading,
        hasProPlan,
        errors
    }:
        {
            proofUploadWindow: GenericDeclaration;
            onChange: (proofUploadWindow: GenericDeclaration) => void;
            settings: TaxDeclarationSettingsSchemaContract;
            isLoading?: boolean;
            hasProPlan?: boolean;
            errors?: DeclarationErrors;
        }
) => {

    const xpayrollStartDate = proofUploadWindow.type === 'custom' ? `${Object.entries(proofUploadWindow.dates)[0][1].startDate?.toLocaleDateString('en-us', { month:"long" })} ${ convertToOrdinal(Object.entries(proofUploadWindow.dates)[0][1].startDate?.getDate() ?? 0)}` : 'N/A';
    const xpayrollEndDate = proofUploadWindow.type === 'custom' ? `${Object.entries(proofUploadWindow.dates)[0][1].endDate?.toLocaleDateString('en-us', { month:"long" })} ${ convertToOrdinal(Object.entries(proofUploadWindow.dates)[0][1].endDate?.getDate() ?? 0)}` : 'N/A';

    const getComponent = () => {
        if (isLoading) {
            return <Spinner accessibilityLabel="Loading" marginLeft={'35%'} />
        }

        else if (!settings.allowEmployeeToUpdateTaxDeductions) {
            return (
                <Alert
                    isDismissible={false}
                    title="Employee Proof Upload window is currently disabled for employees"
                    description="Please go to General > Allow employees to update their tax deductions and enable declaration."
                    marginTop={'spacing.11'}
                    color="information"
                />
            );
        }

        else if (settings.letXpayrollVerifyDocs) {
            return (
                <Box
                    backgroundColor="surface.background.gray.moderate"
                    alignItems="center"
                    padding={['spacing.8', 'spacing.6']}
                    marginTop={'spacing.11'}
                    borderRadius={'medium'}
                >
                    <Box display={'flex'} flexDirection={'row'} gap={'spacing.5'}>
                        <img src={ProofIcon} alt="" width={'106px'} height={'106px'} />
                        <Box display={'flex'} flexDirection={'column'} gap={'spacing.7'}>
                            <Text variant={'body'} weight={'semibold'} size={'medium'}>
                                {`Proof upload window will be open for employees from
                                ${xpayrollStartDate} to ${xpayrollEndDate}`}
                            </Text>
                            <Box display={'flex'} flexDirection={'column'} gap={'spacing.3'}>
                                <Text
                                    variant={'body'}
                                    size={'small'}
                                    color="surface.text.gray.normal">
                                    Verification of proofs will be managed by RazorpayX Payroll.
                                </Text>
                                <Text
                                    variant={'body'}
                                    size={'small'}
                                    color="surface.text.gray.normal">
                                    After RazorpayX Payroll completes verification, you will be notified. Any proofs uploaded outside this window will have to be verified by the organization
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            );
        }

        else {
            return <DeclarationWindow
                heading={'📄 Proof Upload Window'}
                description={'Define the period during which employees upload their proof for their IT declaration'}
                declarationWindow={ hasProPlan ? proofUploadWindow : { type: 'always' } }
                onChange={(val) => onChange(val)}
                isProofUploadWindowSettings={true}
                hasProPlan={hasProPlan}
                errors={errors}
                disableMonthlyRange
            />
        }
    }

    return (
        <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" paddingBottom="spacing.11">
            {getComponent()}
        </Box>
    );
}

export default ProofUploadWindowSettings;