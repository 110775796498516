import { Component, ErrorInfo, PropsWithChildren, PropsWithRef } from 'react';
import { ErrorBoundaryProps, FallbackProps } from './types';
import { capturePrometheusMetrics, Metrics } from '../../utils/Lumberjack';
import { getRedactedPath } from '../../utils/Urls';
import { captureErrorInSentry } from 'utils/sentry';
import { AppError } from 'utils/AppError';

type ErrorBoundaryState = { didCatch: boolean; error: any };

const initialState: ErrorBoundaryState = {
  didCatch: false,
  error: null,
};

export class ErrorBoundary extends Component<
  PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error) {
    return { didCatch: true, error };
  }

  componentDidCatch(error: Error | AppError, info: ErrorInfo) {
    const appError = error as AppError;
    if (appError.getDetails) {
      if (appError.getDetails()) {
        if (['Failed to fetch', 'Load failed'].includes(appError.getDetails().internalMessage)) {
          return;
        }
      }
    } else {
      if (['Failed to fetch', 'Load failed'].includes(error.message)) {
        return;
      }
    }

    capturePrometheusMetrics(Metrics.ERROR_COUNT, {
      pathname: getRedactedPath(window.location.pathname),
      source: "error-boundary"
    });
    captureErrorInSentry(error);
    this.props.onError?.(error, info);
  }

  render() {
    const { didCatch, error } = this.state;

    if (didCatch) {
      const props: FallbackProps = {
        error,
      };

      return this.props.fallbackRender(props);
    }

    return this.props.children;
  }
}
