import React, { ReactElement } from 'react';

import zaggleIntegration from '../../../../../assets/partnerFlexiBenefits/zaggleIntegrationConfimation.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import { PrimaryButtonV2 as PrimaryButton, StandardSecondaryButton } from 'components/ui/Button';
import { FlexiDetailsProps } from '../../utils/init';
import { DeliveryType } from 'constants/flexibenefits';
import { toIndianCurrencyString } from 'utils/Numbers';

import styles from './../index.module.scss';

const IntegrationConfirmation = ({
    flexiDetails, 
    isUpdateLoading,
    onSaveFlexiDetails,
    goBack
    }: FlexiDetailsProps): ReactElement => {

    const onConfirm = () => onSaveFlexiDetails && onSaveFlexiDetails({...flexiDetails, isOpted: true});

    const {address, state, city, pinCode, fullName, phoneNumber} = flexiDetails.cardDeliveryDetails;

    return (
        <div className='space-y-3'>
            <div className='font-bold text-5xl mb-10'>Confirm intergration with Zaggle</div>
            <div className={styles['confirmation-wrapper']}>
                <div className='flex w-full'>
                    <div className='w-full md:w-2/3'>
                        <div className='mb-10'>
                            <div className='font-bold text-white pb-4'>Flexible wallets selected</div>
                            {
                                flexiDetails.enabledWallets?.length
                                    ? (
                                        <ul className='list-disc ml-10'>
                                            {
                                            [...flexiDetails.walletBudgets]
                                                .filter(wallet => 
                                                    flexiDetails.enabledWallets.includes(wallet.id?.toString()))
                                                .map(wallet => wallet.wallet?.name)
                                                .map(name => (<li key={name}>{name}</li>))
                                            }
                                        </ul>
                                    )
                                    : '-'
                            }
                        </div>
                        <div className='mb-10'>
                            <div className='font-bold text-white pb-4'>Flexible Benefit component</div>
                            <div>
                                Monthly Amount of <strong>Rs. {toIndianCurrencyString(flexiDetails.totalAmountAllocated, true)}</strong> will be added “{flexiDetails.flexiBenefitComponentName ?? "Flexible benefit"}” component in your employee salary structure.
                            </div>
                        </div>
                        <div className='mb-10'>
                            <div className='font-bold text-white pb-4'>Zaggle card delivery address</div>
                            <div>
                                {flexiDetails.deliveryType === DeliveryType.DISPATCH_TO_EMPLOYEE 
                                    ? "Employee address"
                                    : <span>Organization address - {`${fullName? `${fullName}, `:""} ${address? `${address}, `:""}${city? `${city}, `:""}${state? `${state} `:""}${pinCode? `- ${pinCode}`:""}${phoneNumber? `, Ph: ${phoneNumber}`:""}`}</span>
                                }
                            </div>
                        </div>
                        <div className='mb-16'>
                            <div className='font-bold text-white pb-4'>Next steps</div>
                            <ul className='list-disc ml-10'>
                                <li>Once the integration is successful employees can declare their individual flexible plans. The amount chosen will be credited to the employee’s Zaggle card during the payroll execution.</li>
                                <li>The physical cards will be delivered within in 15 days.</li>
                            </ul>
                        </div>
                        <div className='flex'>
                            <PrimaryButton 
                                onClick={onConfirm}
                                isPending={isUpdateLoading}
                            >
                                Confirm
                            </PrimaryButton>
                            <StandardSecondaryButton 
                                onClick={goBack}
                                className='ml-5'
                                disabled={isUpdateLoading}
                            >
                                BACK
                            </StandardSecondaryButton>
                        </div>
                    </div>
                    <div className='w-0 md:w-1/3'>
                        <img className="md:absolute right-0 mr-10" src={getStaticMediaUrl(zaggleIntegration)}></img>
                    </div>
                </div>

            </div>
        </div>);
}

export default IntegrationConfirmation;