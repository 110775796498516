
import { GenericDeclaration, WindowPayloadType } from './types';
import { parse, format } from 'date-fns';
import { uniqueId } from '../../../utils/Numbers';
import { dateFormats } from '../../../utils/Dates';

export const parseWindow = (
    window: GenericDeclaration,
): GenericDeclaration => {
    if (window.type === 'custom') {
        if (window.dates && Array.isArray(window.dates)) {
            let dates = window.dates || [];
            const parsedDates = dates.reduce(
                (a, c) => ({
                    ...a,
                    [uniqueId()]: {
                        startDate: parse(c.startDate, dateFormats.dayMonth, new Date()),
                        endDate: parse(c.endDate, dateFormats.dayMonth, new Date()),
                    },
                }),
                {},
            );
            window.dates = parsedDates;
        }
    }

    return window
};

export const createPayload = (
    window: GenericDeclaration,
): WindowPayloadType => {

    const payload: WindowPayloadType = { type: window.type };

    if (window.type === 'custom') {
        const dates = window.dates || {};

        if (Object.values(dates).length < 1) {
            throw new Error("Please provide one or more date windows");
        }

        const parsedDates = Object.values(dates).map((e) => ({
            startDate: e.startDate ? format(e.startDate, dateFormats.dayMonth) : null,
            endDate: e.endDate ? format(e.endDate, dateFormats.dayMonth) : null,
        }));
        payload.dates = parsedDates;
    }
    else if(window.type === 'monthly'){
        payload.startDate = window.startDate;
        payload.endDate = window.endDate;
    }

    return payload;
};