import {
  ActionList,
  ActionListItem,
  ArrowLeftIcon,
  Button,
  Divider,
  Dropdown,
  DropdownOverlay,
  Radio,
  RadioGroup,
  SelectInput,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import { useComponentParams, useDetailsView } from 'components/SalaryComponents/hooks';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
  SwitchDescription,
  SwitchField,
} from 'components/WizardViews/components';
import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import { useFormWithProvider } from 'forms';
import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { salaryComponentParams } from 'components/SalaryComponents/constants';
import {
  earningSteps,
  earningsTaxSections,
  earningsTaxationDetailsStepValidationSchema,
  knowMoreItems,
  taxDeductionBehavior,
} from '../constants';
import { EarningsFormData } from '../types';
import { checkIsPayFrequencyAdhoc, checkIsPayFrequencyVariable, isTaxExempt } from '../utils';

const TaxInfoStep: React.FC = () => {
  const { formData, validate, register } = useFormWithProvider<EarningsFormData>();
  const { updateStepStatus } = useWizardInfo();
  const history = useHistory();
  const { searchString } = useComponentParams();
  const { openDetailsView } = useDetailsView(salaryComponentParams.knowMore);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validate(earningsTaxationDetailsStepValidationSchema)) {
      updateStepStatus(earningSteps.step2, 'success');
      history.push({
        pathname: routePaths.salaryComponents.create.earnings.review,
        search: searchString,
      });
    }
  };

  const goBack = () => history.goBack();

  const isPayFrequencyAdhocOrVariable =
    checkIsPayFrequencyAdhoc(formData.payType) || checkIsPayFrequencyVariable(formData.payType);
  const isExemptionVisible = isTaxExempt(
    formData.taxExemptInNewRegime,
    formData.taxExemptInOldRegime,
  );

  const isExemptInBothRegimes = Boolean(
    formData.taxExemptInNewRegime && formData.taxExemptInOldRegime,
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Taxation" subtitle="Configuration" />
        <FieldGroup
          title="Taxability"
          subtitle="Choose if the component is taxable in the respective income tax regimes."
          infoLabel="Know more✨"
          onInfoClick={() => openDetailsView(knowMoreItems.taxability)}>
          <SwitchField
            label="Exempt in the old tax regime"
            accessibilityLabel="Exempt in the old tax regime"
            size="small"
            {...register('taxExemptInOldRegime', 'switch')}
          />
          <SwitchField
            label="Exempt in the new tax regime"
            accessibilityLabel="Exempt in the new tax regime"
            size="small"
            {...register('taxExemptInNewRegime', 'switch')}
          />
          {isExemptionVisible && (
            <Dropdown>
              <SelectInput
                label="Exempt under which section"
                placeholder="Exempt under which section"
                {...register('taxExemptionSection', 'select')}
              />
              <DropdownOverlay>
                <ActionList>
                  {earningsTaxSections.map((section) => (
                    <ActionListItem
                      key={section.key}
                      title={section.label}
                      value={section.key}
                      description={section.description}
                    />
                  ))}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          )}
        </FieldGroup>

        <Divider marginY="spacing.8" />

        {isPayFrequencyAdhocOrVariable && (
          <>
            <FieldGroup
              title="Tax deduction"
              subtitle="Define when to deduct the tax."
              infoLabel="Know more✨"
              onInfoClick={() => openDetailsView(knowMoreItems.taxDeductions)}>
              <RadioGroup {...register('taxDeductionBehaviour', 'radiogroup')}>
                <Radio value={'pro_rated'} helpText={taxDeductionBehavior.pro_rated.helpText}>
                  {taxDeductionBehavior.pro_rated.label}
                </Radio>
                <Radio value={'instant'} helpText={taxDeductionBehavior.instant.helpText}>
                  {taxDeductionBehavior.instant.label}
                </Radio>
              </RadioGroup>
            </FieldGroup>
            <Divider marginY="spacing.8" />
          </>
        )}

        <FieldGroup
          title="Wage calculation"
          subtitle="Define if the component is included in the calculation of statutory wages."
          infoLabel="Know more✨"
          onInfoClick={() => openDetailsView(knowMoreItems.wageCalculation)}>
          <SwitchField
            label="Part of PF wage "
            accessibilityLabel="Part of PF wage"
            size="small"
            {...register('includeInPfWages', 'switch')}>
            <SwitchDescription content="When Basic is less than 15k" />
          </SwitchField>
          <SwitchField
            label="Part of ESI wage"
            accessibilityLabel="Part of ESI wage"
            size="small"
            {...register('includeInEsiWages', 'switch')}
          />
          <SwitchField
            label="Part of PT wage"
            accessibilityLabel="Part of PT wage"
            size="small"
            {...register('includeInPtWages', 'switch')}
          />
          <SwitchField
            label="Part of LWF wage"
            accessibilityLabel="Part of LWF wage"
            size="small"
            {...register('includeInLwfWages', 'switch')}
          />
        </FieldGroup>
      </StepContentWrapper>
      <StepActionsWrapper>
        <Button variant="secondary" icon={ArrowLeftIcon} onClick={goBack}>
          Basic: Pay Details
        </Button>
        <Button type="submit">Next: Review</Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default TaxInfoStep;
