import React, { ReactElement, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import { useQuery } from 'react-query';
import api from 'api';
import Chart from './components/Chart';
import TableView from './components/TableView';
import FormElements from './components/Form';
import reportConstants from './constants';
import Spinner from 'components/ui/Spinner';
import ErrorMessage from 'components/ui/ErrorMessage';
import Breadcrumb from 'components/ui/Breadcrumb';

interface Props {}

function VarianceReport({}: Props): ReactElement {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let attribute = params.get('attribute') || reportConstants.ATTRIBUTE_SALARY;
  let timeline = params.get('timeline') || reportConstants.TIMELINE_CURRENT_YEAR;
  let view = params.get('view') || 'chart';

  const querydata = useQuery(['checkRead', { attribute, timeline }], () => {
    return api.varianceReportData.fetchData(attribute, timeline);
  });

  if (querydata.status === 'loading' || querydata.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (querydata.status === 'error') {
    return (
      <ErrorMessage title="Something went wrong!">
        <div>Please contact support.</div>
      </ErrorMessage>
    );
  }

  return (
    <>
      <Breadcrumb
        name="Variance Report"
        urlMaps={{
          Reports: '/reports',
        }}
      />
      <FormElements />

      {view === 'chart' && querydata.data && <Chart data={querydata.data} />}
      {view === 'table' && querydata.data && <TableView data={querydata.data} />}
    </>
  );
}

function VarianceReportWithPageWrapper(): ReactElement {
  return (
    <MainCase type={'fullWidth'}>
      <VarianceReport />
    </MainCase>
  );
}

export default VarianceReportWithPageWrapper;
