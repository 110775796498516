import React, { ReactElement } from 'react';

interface Props {
  children?: React.ReactNode;
}

function PageWrapper({ children }: Props): ReactElement {
  return <div className="wrapper">{children}</div>;
}

export default PageWrapper;
