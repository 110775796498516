import React from 'react';
import { Box, Text } from '@razorpay/blade/components';

type InfoSectionProps = {
  title: string;
  children: React.ReactNode;
};
const InfoSection = ({ title, children }: InfoSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
      <Text weight={'semibold'}>{title}</Text>
      {children}
    </Box>
  );
};

export default InfoSection;
