import { FormState, FormAction } from './types';

export function formReducer<T>(state: FormState<T>, action: FormAction<T>): FormState<T> {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: action.payload,
      };
    case 'SET_FIELD_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'CLEAR_FIELD_VALUE': {
      const newFormData = { ...state.formData };
      delete newFormData[action.payload];
      return {
        ...state,
        formData: newFormData,
      };
    }
    case 'SET_FIELD_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      };
    case 'CLEAR_FIELD_ERROR': {
      const newErrors = { ...state.errors };
      delete newErrors[action.payload];
      return {
        ...state,
        errors: newErrors,
      };
    }
    case 'CLEAR_ALL_ERRORS':
      return {
        ...state,
        errors: {},
      };
    case 'CLEAR_FORM_DATA':
      return {
        ...state,
        formData: {},
      };
    case 'SET_SHOULD_VALIDATE':
      return {
        ...state,
      };
    case 'SET_IS_SUBMITTING':
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case 'SET_IS_VALIDATING':
      return {
        ...state,
        isValidating: action.payload,
      };
    default:
      return state;
  }
}
