import React, {useState} from "react";
import PageWrapper from "../../../../../ui/PageWrapper";
import MainCase from "../../../../../ui/MainCase";
import {useMutation, useQuery} from "react-query";
import {
    JIBBLE_INITIAL_EMPLOYEES_SYNC,
    JIBBLE_INTEGRATION_STATUS_QUERY
} from "../../../utils/queries";
import api from "../../../../../../api";
import APIError from "../../../../../../types/apiError";
import ErrorMessage from "../../../../../ui/ErrorMessage";
import {routePaths} from "../../../../../Routes/data";
import jibbleCommonStyles from "../../../index.module.scss";
import styles from "./index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleSignature from "../../../../../../assets/jibble/signature--jibble.svg";
import iconX from "assets/icons/icon_x.png";
import iconJibble from "assets/jibble/logo--jibble.png";
import iconUser from "assets/icon--user.svg";
import bulletSync from "assets/bullet--sync.png";
import bulletUser from "assets/image--user-green.png";
import {StandardButton} from "../../../../../ui/Button";
import { Link, useHistory } from "react-router-dom";
import {useModal} from "../../../../../ui/Modals";
import EmployeeListModal from "../../PopUps/EmployeeListModal";
import {
    JibbleInititalEmployeeSyncResponseSchemaContract
} from "../../../../../../schemas/JibbleInititalEmployeeSyncResponseSchema";
import Spinner from "../../../../../ui/Spinner";
import {JibbleAccountCreationRequestBody} from "../../../../../../api/jibbleSettings";
import {HeaderWithProgressBar} from "../../../../../BulkUploads/components/HeaderWithProgressBar";

const JibbleSyncEmployees= () => {
    const [mappedEmployees, setMappedEmployees] = useState<JibbleInititalEmployeeSyncResponseSchemaContract['mapped_employees']>([]);
    const [unSyncedEmployees, setUnSyncedEmployees] = useState<JibbleAccountCreationRequestBody[]>([]);
    const [totalXPayrollEmployees, setTotalXPayrollEmployees] = useState<number>();
    const history = useHistory();
    const { openModal } = useModal();

    const { isLoading, isFetching } = useQuery(
        JIBBLE_INITIAL_EMPLOYEES_SYNC,
        () => { return api.jibbleSettings.xpayrollEmployeesInitialSync(); },
        {
            onError: (error: APIError) => {},
            onSuccess: response => {
                setTotalXPayrollEmployees(response.xpayroll_employees.length);
                setMappedEmployees(response.mapped_employees);
                const mappedEmployeeEmails = response.mapped_employees.map((employee) => employee.email);
                setUnSyncedEmployees((response.xpayroll_employees as JibbleAccountCreationRequestBody[]).filter(
                    (employee) => !mappedEmployeeEmails.includes(employee.email))
                );
            },
            refetchOnMount: 'always'
        },
    )

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    const bulkJibbleAccountCreationMutation = useMutation(() => api.jibbleSettings.bulkOnboardEmployeesToJibble({
        check_for_existing_accounts: false,
        employees_to_be_added: unSyncedEmployees,
        is_onboarding: false,
    }), {
        onSuccess: () => {
            history.push(routePaths.settings.jibble.manage);
        }
    });

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occurred!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (isLoading || isFetching || integrationStatusQuery.isLoading) {
        return (
            <div className={jibbleCommonStyles['container']}>
                <img src={getStaticMediaUrl(jibbleSignature)} alt="" className="mb-16"/>
                <h1 className="text-2.14xl font-bold opacity-90">Searching for your employees on Jibble</h1>
                <div className="flex justify-center items-center h-full">
                    <Spinner />
                </div>
            </div>
        );
    }

    if (integrationStatusQuery?.data && !integrationStatusQuery.data.is_enabled) {
        history.push(routePaths.settings.jibble.onboarding.activation);
    }

    if (integrationStatusQuery?.data && integrationStatusQuery.data.is_enabled && integrationStatusQuery.data.is_onboarding_completed) {
        history.push(routePaths.settings.jibble.manage);
    }

    const displayMappedEmployees = () => {
        openModal(
            <EmployeeListModal header="RazorpayX Payroll employees already on Jibble" employeeData={mappedEmployees}/>
        )
    }

    const displayUnsyncedEmployees = () => {
        openModal(
            <EmployeeListModal header="RazorpayX Payroll employees not on Jibble" employeeData={unSyncedEmployees}/>
        )
    }

    const handlePageSubmit = () => {
        bulkJibbleAccountCreationMutation.mutate();
    };

    const mappedMoreThanZero = mappedEmployees.length > 0;
    const mappedMoreThanOne = mappedEmployees.length > 1;
    const unSyncedMoreThanZero = unSyncedEmployees.length > 0;
    const unSyncedMoreThanOne = unSyncedEmployees.length > 1;

    return (
        <div className={jibbleCommonStyles['container']}>
            <img src={getStaticMediaUrl(jibbleSignature)} alt="" className="mb-16"/>
            <h1 className="text-2.14xl font-bold opacity-90 mb-16">Sync employees to Jibble</h1>
            <div className={styles['detailCard']}>
                <div className="flex w-9/12 items-center">
                    <div className="h-8 w-8 mr-3">
                        <img src={getStaticMediaUrl(iconX)} alt="" className="h-8"/>
                    </div>
                    <p className="opacity-75">Total employees on RazorpayX Payroll</p>
                </div>
                <div className={styles['figureBox']}>
                    <h1 className={styles['figure']}>{totalXPayrollEmployees}</h1>
                </div>
            </div>
            { mappedMoreThanZero && (
                <div className={styles['detailCard']}>
                    <div className="flex w-9/12 items-center">
                        <div className="h-8 w-8 mr-3">
                            <img src={getStaticMediaUrl(iconJibble)} alt="" className="h-8"/>
                        </div>
                        <p className="opacity-75">
                            RazorpayX Payroll {' '}
                            {mappedMoreThanOne ? "employees " : "employee "}
                            <strong>already on Jibble</strong>
                        </p>
                    </div>
                    <div className={styles['figureBox']}>
                        <h1 className={styles['figure']}>{mappedEmployees.length} / {totalXPayrollEmployees}</h1>
                        <p className={styles['modalCta']} onClick={displayMappedEmployees}>View employees</p>
                    </div>
                </div>
            )}
            { unSyncedMoreThanZero && (
                <div className={styles['detailCard']}>
                    <div className="flex w-9/12 items-center">
                        <div className="h-8 w-8 mr-3">
                            <img src={getStaticMediaUrl(iconUser)} alt="" className="h-8"/>
                        </div>
                        <p className="opacity-75">
                            RazorpayX Payroll {' '}
                            {unSyncedMoreThanOne ? "employees " : "employee "}
                            <strong>to be added to Jibble</strong>
                        </p>
                    </div>
                    <div className={styles['figureBox']}>
                        <h1 className={styles['figure']}>{unSyncedEmployees.length} / {totalXPayrollEmployees}</h1>
                        <p className={styles['modalCta']} onClick={displayUnsyncedEmployees}>View employees</p>
                    </div>
                </div>
            )}
            <p className="font-bold opacity-80 mt-12"> Sync details</p>
            { mappedMoreThanZero && (
                <div className="flex mt-6 items-center">
                    <img src={getStaticMediaUrl(bulletSync)} alt="*" className={styles['bullet']} />
                    <p className="opacity-75">
                        {mappedMoreThanOne
                            ? `Existing Jibble accounts of the above ${mappedEmployees.length} employees will be linked to their respective RazorpayX Payroll accounts for syncing attendance`
                            : `Existing Jibble account of the above 1 employee will be linked to their respective RazorpayX Payroll account for syncing attendance`
                        }
                    </p>
                </div>
            )}
            { unSyncedMoreThanZero && (
                <div className="flex mt-6 items-center">
                    <img src={getStaticMediaUrl(bulletUser)} alt="*" className={styles['bullet']} />
                    <p className="opacity-75">
                        {unSyncedMoreThanOne
                            ? `New Jibble accounts will be created for the remaining ${unSyncedEmployees.length} employees and invitation links will be sent to them`
                            : `New Jibble account will be created for the 1 remaining employee and an invitation link will be sent to them`
                        }
                    </p>
                </div>
            )}
            <Link to={routePaths.settings.jibble.onboarding.settingsConfiguration}>
                <StandardButton className={styles['backButton']}>Back</StandardButton>
            </Link>
            <StandardButton
                className="mt-24 float-right mr-0"
                onClick={handlePageSubmit}
                disabled={isLoading || bulkJibbleAccountCreationMutation.isLoading}
            >
                Sync Employees & Complete Integration
            </StandardButton>
        </div>
    );
}

const JibbleSyncEmployeesPageWrapper = () => (
    <PageWrapper>
        <div className={"flex flex-col w-full"}>
            <HeaderWithProgressBar
                headerText="Jibble Integration"
                currentStep={3}
                totalSteps={3}
            />
            <MainCase center>
                <JibbleSyncEmployees/>
            </MainCase>
        </div>
    </PageWrapper>
)

export default JibbleSyncEmployeesPageWrapper;