import React from 'react';
import { Amount, ArrowDownRightIcon, Box, Heading, Skeleton, Text } from '@razorpay/blade/components';
import { ColoredDiv } from '../styles';

const EstimateCardSkeleton = () => {

    return (
        (<Box width={'100%'} paddingX={'spacing.6'} paddingY={'spacing.7'} borderRadius={'medium'} backgroundColor={'surface.background.gray.intense'} borderColor={'surface.border.gray.subtle'} borderWidth={'thick'}>
            <Box display={'flex'} flexDirection={'row'}>
                <Skeleton width={'36px'} height={'36px'} borderRadius={'medium'} />
                <Box display={'flex'} flexDirection={'column'} marginLeft={'spacing.5'} gap={'spacing.2'} marginBottom={'spacing.4'}>
                    <Skeleton width={'140px'} height={'spacing.5'} borderRadius={'medium'} />
                    <Skeleton width={'106px'} height={'spacing.5'} borderRadius={'medium'} />
                </Box>
            </Box>
            <Skeleton width={'100%'} height={'spacing.8'} borderRadius={'medium'} />
        </Box>)
    );
}

export default EstimateCardSkeleton;