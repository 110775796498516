import React, { PropsWithChildren } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import trendingUpLogo from 'assets/trending-up.png';
import greenTick from 'assets/tick-green.png';
import { getIntegrationUrlAndImageData } from '../../Integrations/integrationsData';
import { useMutation } from 'react-query';
import api from '../../../api';
import APIError from 'types/apiError';
import { PrimaryButton } from '../Button';
import { trackSegment } from 'utils/segment';

export interface IntegrationCardSchema {
  id: number;
  name: string;
  type: string;
  description: string;
  info: string;
  isConnected: boolean;
  isAvailable: boolean;
  interestedList: Array<string>;
  setInterestedList: CallableFunction;
}

export function IntegrationCard({
  id,
  name,
  type,
  description,
  info,
  isConnected,
  isAvailable,
  interestedList,
  setInterestedList,
}: PropsWithChildren<IntegrationCardSchema>) {
  const applyInterestMutation = useMutation(
    (companyName: string) => {
      return api.integration.addSuggestion({
        suggestion: companyName,
      });
    },
    {
      onSuccess: (companyName) => setInterestedList([...interestedList, companyName]),
      onError: (error: APIError) => {},
    },
  );
  function handleInterestedButtonClick(companyName: string) {
    trackSegment('Interested in ' + companyName);
    applyInterestMutation.mutate(companyName);
  }
  const integrationUrlAndImageData = getIntegrationUrlAndImageData();
  const exploreUrl = integrationUrlAndImageData[id].exploreUrl;
  const manageUrl = integrationUrlAndImageData[id].manageUrl;
  const imgSrc = integrationUrlAndImageData[id].imgSrc;
  const isExternalLink = !!integrationUrlAndImageData[id].isExternalLink;
  const isRecentlyLaunched = integrationUrlAndImageData[id].isRecentlyLaunched;

  return (
    <>
      <div className="flex flex-col justify-end">
        <div className="flex flex-col justify-end flex-grow items-baseline p-8 bg-persian-blue rounded-t-md hover:bg-pickled-bluewood">
          <div className="flex flex-row justify-between items-start w-full">
            <img src={imgSrc} />
            {id ===2 && (
                <span className="text-xl font-bold text-accent-negative bg-accent-negative-background px-3 py-1 rounded">
                {'Deprecating soon'}
              </span>
            )}
            {isConnected ? (
              <span className="text-xl text-feedbackTextPositiveIntense bg-success-green-light px-3 py-1 rounded">
                {'Connected'}
              </span>
            ) : (
              ' '
            )}
          </div>

          <div className="font-heading font-bold text-center text-3xl mt-4">{name}</div>
          <div className="text-xl uppercase mt-3 font-bold text-silver">{type}</div>
          <div className="mt-5 mb-12 flex-grow text-100">{description}</div>

          {isConnected && (
            <a
              href={manageUrl}
              target={isExternalLink ? '_blank' : ''}
              rel={isExternalLink ? 'noreferrer noopener' : ''}
            >
              <button className="btn btn--secondary leading-none px-6 py-2.5 mt-4 text-2xl">
                {'Manage'}
              </button>
            </a>
          )}
          {!isConnected && !isAvailable && (
            <>
              {applyInterestMutation.status === 'success' || interestedList.includes(name) ? (
                <div className="flex flex-row items-center mt-10 text-2xl bg-pill-green-light px-3 py-1 rounded">
                  <img className="mr-4" src={getStaticMediaUrl(greenTick)} />
                  <span className="text-feedbackTextPositiveIntense">{"We'll update you soon"}</span>
                </div>
              ) : (
                <PrimaryButton
                  size={'sm'}
                  className="px-6 py-2.5 mt-8 text-2xl"
                  isPending={applyInterestMutation.status === 'loading'}
                  onClick={() => handleInterestedButtonClick(name)}
                >
                  {"I'm Interested"}
                </PrimaryButton>
              )}
            </>
          )}

          {!isConnected && isAvailable && (
            <a
              href={exploreUrl}
              target={isExternalLink ? '_blank' : ''}
              rel={isExternalLink ? 'noreferrer noopener' : ''}
            >
              <PrimaryButton
                size='sm'
                onClick={() => trackSegment('Explored ' + name, { queue: true })}
                className="px-6 py-2.5"
              >
                {'Explore'}
              </PrimaryButton>
            </a>
          )}
        </div>

        <div className="w-full flex flex-row justify-start px-5 bg-pickled-bluewood p-4 rounded-b-md">
          {!isAvailable ? (
            <span className="text-xl text-message-orange">{info}</span>
          ) : (
            <>
              <div>
                <img src={getStaticMediaUrl(trendingUpLogo)} />
              </div>
              <span className="text-xl ml-4 flex flex-row items-center">
                <span>{info}</span>
                {isRecentlyLaunched ? (
                  <span className="bg-success-green text-base p-2 text-white-o-80 px-2 py-1 ml-4 rounded">
                    NEW
                  </span>
                ) : (
                  ''
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
}
