import React, { useState } from 'react';
import { Table } from '../components/Table';
import {
  Box,
  Text,
  IconButton,
  RefreshIcon,
  ProgressBar,
  Badge,
  Link,
} from '@razorpay/blade/components';
import { Pagination } from '../components/Table/Pagination';
import { Column, RowClickHandler } from '../components/Table/types';
import { useWorkflowDetailsView } from '../useWorkflowDetailsView';
import { useFilters } from '../useFilters';
import {
  WorkflowRequestsCacheKeys,
  WorkflowRequestsStatusLabelsMap,
  WorkflowRequestsStatusMap,
} from '../constants';
import { GetWorkflowRequestsData } from 'api/workflows';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import { useQuery } from 'react-query';
import api from 'api';
import { NoResultsTableView } from '../components/NoResultsTableView';
import { ErrorResultsTableView } from '../components/ErrorResultsTableView';
import { AllRowsCheckSkeleton } from '../components/AllRowsCheckSkeleton';
import { EditPayrollListItem } from './types';
import { transformEditPayrollRequestData } from './utils';
import { NewToOldAmount } from '../components/NewToOldAmount';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import { transformDataForWorkflowRequests } from 'api/gql-utils/workflows/transformers';
import { usePagination } from 'hooks/usePagination';

// @TODO - update the column render function according to the UI
export const editPayrollCompletedTableColumns: Column<EditPayrollListItem>[] = [
  {
    title: 'Employee Details',
    render: (item) => {
      const cellData = item.employeeDetails;
      return (
        (<Box display="flex" flexDirection="column">
          <Text color="surface.text.gray.muted">{cellData.name}</Text>
          <Text variant="caption" color="surface.text.gray.muted" size='small'>
            {cellData.employeeId}
          </Text>
        </Box>)
      );
    },
  },
  {
    title: 'Status',
    render: (item) => {
      if (item.approvalLevel) {
        return <Badge color="notice">L{item.approvalLevel.toString()}</Badge>;
      }

      switch (item.status) {
        case WorkflowRequestsStatusMap.approved:
          return <Badge color="positive">Approved</Badge>;
        case WorkflowRequestsStatusMap.rejected:
          return <Badge color="negative">Rejected</Badge>;
      }
    },
    styles: {
      textAlign: 'center',
    },
  },
  {
    title: 'Payroll Month',
    render: (item) => {
      return <Text color="surface.text.gray.muted">{item.payrollMonth}</Text>;
    },
  },
  {
    title: 'Deductions',
    render: (item) => {
      return (
        <NewToOldAmount newAmount={item.deductions.newSum} oldAmount={item.deductions.oldSum} />
      );
    },
    styles: {
      textAlign: 'right',
    },
  },
  {
    title: 'Arrears',
    render: (item) => {
      return <NewToOldAmount newAmount={item.arrears.new} oldAmount={item.arrears.old} />;
    },
    styles: {
      textAlign: 'right',
    },
  },
  {
    title: 'Additions',
    render: (item) => {
      return <NewToOldAmount newAmount={item.additions.newSum} oldAmount={item.additions.oldSum} />;
    },
    styles: {
      textAlign: 'right',
    },
  },
];

const Completed = () => {
  const [tableData, setTableData] = useState<EditPayrollListItem[]>([]);
  const { workflowRequestId } = useWorkflowDetailsView();
  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const { currentPage, itemsPerPage, setCurrentPage, setItemsPerPage } = usePagination({
    page: 1,
    limit: 10,
  });
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];

  const { currentFilters } = useFilters();
  const queryParams: GetWorkflowRequestsData = {
    ...currentFilters,
    action_type: WorkflowTypesMap.EDIT_PAYROLL,
    is_my_approval: 1,
    'status[]':
      currentFilters['status[]'].length > 0
        ? currentFilters['status[]']
        : [
            WorkflowRequestsStatusLabelsMap.approved,
            WorkflowRequestsStatusLabelsMap.approved_by_me,
            WorkflowRequestsStatusLabelsMap.rejected,
            WorkflowRequestsStatusLabelsMap.rejected_by_me,
          ],
  };

  const { data, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: [WorkflowRequestsCacheKeys.editPayroll.completed, queryParams],
    queryFn: () => api.workflowApis.getRequests(queryParams, isGraphQLEnabled),
    onSuccess: ({ data, people_metadata }) => {
      const tableData =
        data?.map((rowData) => transformEditPayrollRequestData(rowData, people_metadata)) ?? [];
      setTableData(tableData);
    },
    select: transformDataForWorkflowRequests,
    cacheTime: 0,
  });

  const { openDetailsView } = useWorkflowDetailsView();

  const onRowClicked: RowClickHandler<EditPayrollListItem> = (rowData) => {
    const id = rowData.id;

    openDetailsView(id);
  };

  const tableTopContent = tableData.length ? (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <Text weight="semibold" color="surface.text.gray.muted">
        Showing {data?.from}-{data?.to} of {data?.total} completed requests
      </Text>
      <Box display="flex" gap="spacing.5" alignItems="center">
        <Link
          icon={RefreshIcon}
          variant="button"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            refetch();
          }}
        />
      </Box>
    </Box>
  ) : null;

  const tableTopSkeletonContent = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <AllRowsCheckSkeleton />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflowY="auto"
      height={tableData.length === 0 || isError ? '100%' : 'auto'}>
      {isFetching && <ProgressBar isIndeterminate />}
      {isLoading ? tableTopSkeletonContent : tableTopContent}
      <Box display="flex" overflowY="scroll" flexGrow={1}>
        <Table
          columns={editPayrollCompletedTableColumns}
          data={tableData.map((data) => ({
            ...data,
            isClicked: data.id === Number(workflowRequestId),
          }))}
          onRowClick={onRowClicked}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          noResultsView={
            <NoResultsTableView
              onRefresh={refetch}
              workflowExists={actionsWithActiveWorkflows.includes(WorkflowTypesMap.EDIT_PAYROLL)}
              workflowType={WorkflowTypesMap.EDIT_PAYROLL}
            />
          }
          errorResultsView={<ErrorResultsTableView onRetry={refetch} />}
        />
      </Box>
      {tableData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={data?.total ?? 0}
          itemsPerPage={itemsPerPage}
          onCurrentPageChange={setCurrentPage}
          onItemsPerPageChange={setItemsPerPage}
          isDisabled={isLoading}
        />
      )}
    </Box>
  );
};

export { Completed };
