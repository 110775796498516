import { TableCell, TableEditableCell } from '@razorpay/blade/components';
import React from 'react';

interface AmountCellProps {
  amount: string;
  isAmountEditDisabled: boolean;
  onAmountChange: (value?: string) => void;
}

const areEqual = (prevProps: AmountCellProps, nextProps: AmountCellProps) => {
  return (
    prevProps.amount === nextProps.amount &&
    prevProps.isAmountEditDisabled === nextProps.isAmountEditDisabled
  );
};

const AmountCell = ({ amount, isAmountEditDisabled, onAmountChange }: AmountCellProps) => {
  if (isAmountEditDisabled) {
    return <TableCell>{amount}</TableCell>;
  }

  return (
    <TableEditableCell
      placeholder="Enter amount"
      accessibilityLabel="Amount"
      value={amount}
      onChange={(e) => onAmountChange(e.value)}
    />
  );
};

AmountCell.displayName = 'AmountCell';

export default React.memo(AmountCell, areEqual);
