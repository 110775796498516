
import React, { useState, ReactElement } from 'react';
import styles from './../index.module.scss';
import FileDownloadButton from '../components/DownloadButton/index';
import PayrollMonthSelector from 'components/RunPayroll/SideDisplay/PayrollMonthSelector';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { trackXPayrollEvent } from 'utils/analytics';


const DownloadSection = ({
    payrollMonth,
    setPayrollMonth,
    bulkUploadRecord,
    setBulkUploadRecord
}: {
    payrollMonth: string | null;
    setPayrollMonth: (payrollMonth: string | null) => void;
    bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null;
    setBulkUploadRecord : (bulkUploadRecord: BulkUploadDetailsSchemaContract[0] | null) => void;
}): ReactElement => {

    const [fileDownloaded, setFileDownloaded] = useState(false);

    const tempDate = payrollMonth ? `${payrollMonth.substring(0, 4)}-${payrollMonth.substring(5, 7)}-01` : null;

    function onClick(){
        
        trackXPayrollEvent("addition_deduction_lop.template_download","interaction","bulk-action")

    }


    return (
        <div className={styles['section']}>
            <span className={styles['section-main-text']}>{'1. Download the template'}</span>

            <PayrollMonthSelector
                label={'🗓 Select payroll month'}
                payrollMonth={payrollMonth}
                setPayrollMonth={setPayrollMonth}
                className={styles['dropdown']} />

            <div className='mt-10 ml-2'><span>💡 Instructions To fill</span></div>
            <div className='mt-6'>
                <ul className={'list-disc ml-4'}>
                    <li className={styles['section-secondary-text']}>{"All changes will reflect in the payroll month selected above."}</li>
                    <li className={styles['section-secondary-text']}>{"ADD new rows for employees that have a combination of additions, deductions and LOP"}</li>
                    <li className={styles['section-secondary-text']}>{'Skipped employees from the above payroll month will not be a part of the template. Unskip employees from the Run Payroll screen to automatically add them.'}</li>
                    <li className={styles['section-secondary-text']}>{'DELETE rows for employees that you do not need.'}</li>
                    <li className={styles['section-secondary-text']}>{'The maximum number of rows you can upload is 500.'}</li>
                </ul>
            </div>
            <div className={'mt-10'}>
                <FileDownloadButton 
                    fileDownloaded={fileDownloaded} 
                    setFileDownloaded={setFileDownloaded} 
                    disabled={(payrollMonth === null)} 
                    href={tempDate ? `/v2/api/bulk-addition-deduction/download-template?payroll_month=${tempDate}` : ''}
                    onClick={onClick}
                    />
            </div>
        </div>
    )
}

export default DownloadSection;