import {
  ActionList,
  ActionListItem,
  Alert,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  EditIcon,
  Link,
  SelectInput,
  Text,
  TextInput,
  Heading,
} from '@razorpay/blade/components';
import React, { useEffect, useRef, useState } from 'react';
import { orgTypes } from 'utils/OrganizationTypes';
import { FormContainer } from './styles';
import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { BUSINES_PAN_REGEX, PAN_REGEX } from 'utils/regexes';
import { PanDetailsVerificationRequestContract } from 'api/onboarding';
import { GetCompanyNameFromPanSchemaContract } from 'schemas/GetCompanyNameFromPanSchema';
import { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import Errors from 'components/ui/Form/Errors';
import { AppError } from 'utils/AppError';
import { useL1KycData } from '../hooks/useL1KycData';
import { useOnboardingContext } from '../OnboardingContext';
import { PanNameView } from '../components/PanNameView';
import { BvsDataLoadingView } from '../components/BvsDataLoadingView';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { trackGtag, trackRazorpayAnalytics } from 'utils/razorpayAnalytics';

const PanPrefillForm = () => {
  const { orgName, pan, orgType, isRazorpayMerchant, isActivatedOnRazorpay } = useL1KycData();
  const [companyName, setCompanyName] = useState(orgName ?? '');
  const [formData, setFormData] = useState({
    orgType: orgType?.toString() ?? '1',
    pan: pan ?? '',
  });
  const [panValidationError, setPanValidationError] = useState('');
  const panFieldRef = useRef<HTMLInputElement>(null);
  const { setDelightScreen } = useOnboardingContext();
  const currentOrganization = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const uiPreferences = currentOrganization?.employeeDetails?.uiPreferences;
  const hasVisitedL1Kyc = uiPreferences?.['hasVisitedL1Kyc'] ?? false;

  const {
    isLoading: isPanVerificationInProgress,
    mutate: updatePanDetailsAndVerifyMutation,
    isError: isPanVerificationError,
    error: panVerificationError,
  } = useMutation<
    SuccessResponseSchemaContract,
    typeof AppError,
    PanDetailsVerificationRequestContract
  >({
    mutationFn: api.onboarding.updatePanDetailsAndVerify,
    onSuccess: (data) => {
      if (data && data.success) {
        setDelightScreen({
          isVisible: true,
          content: delightScreenContent,
          onFinish: handleDelightFinish,
        });

        // firing tracking pixels after completing L1 KYC
        triggerOnboardingPixels();
      }
    },
  });

  const {
    refetch: companyNameByPanRefetch,
    isFetching: isCompanyNameByPanFetching,
    isError: isCompanyNameFetchError,
    error: companyNameFetchError,
  } = useQuery<GetCompanyNameFromPanSchemaContract, typeof AppError>({
    queryFn: () => api.companyOnboarding.getCompanyNameFromPan(formData.pan),
    onSuccess: (data) => {
      setCompanyName(data.name ?? '');
      setPanValidationError('');
    },
    enabled: false,
  });

  const userPreferencesMutation = useMutation({
    mutationFn: api.people.saveUserUiPreference,
  });

  useEffect(() => {
    // setting in UI preference if L1 KYC screen is viewed for the first time
    if (!hasVisitedL1Kyc) {
      userPreferencesMutation.mutate({
        hasVisitedL1Kyc: true,
      });
    }
  }, [hasVisitedL1Kyc]);

  useEffect(() => {
    const { orgType, pan } = formData;
    if (orgType && pan && pan.length >= 10) {
      const isValidPan = new RegExp(PAN_REGEX).test(formData.pan);
      const isValidBizPan = new RegExp(BUSINES_PAN_REGEX).test(formData.pan);
      if (orgType === '2') {
        if (!isValidPan) {
          setPanValidationError(
            isValidBizPan
              ? 'This is a Business PAN. Please enter Personal PAN or change your Business type'
              : 'Please enter a valid personal PAN',
          );
          if (!isPanSsoPrefilledAndSsoActivated) {
            setCompanyName('');
          }
          return;
        }
      } else {
        if (!isValidBizPan) {
          setPanValidationError(
            isValidPan
              ? 'This is a Personal PAN. Please enter Business PAN or change your Business type'
              : 'Please enter a valid business PAN',
          );
          if (!isPanSsoPrefilledAndSsoActivated) {
            setCompanyName('');
          }
          return;
        }
      }

      setPanValidationError('');
      if (!isPanSsoPrefilledAndSsoActivated) {
        setCompanyName('');
        companyNameByPanRefetch();
      }
    }
  }, [formData.pan, formData.orgType]);

  const handleOrgTypeChange = (e: { name?: string; values: string[] }) => {
    if (!e.name) return;

    setFormData({
      ...formData,
      [e.name]: e.values[0],
    });
  };

  const handlePanChange = (e: { name?: string; value?: string }) => {
    if (!e.name) return;

    setFormData({
      ...formData,
      [e.name]: formData.orgType === '2' ? e.value?.toUpperCase() : e.value,
    });

    setPanValidationError('');
  };

  const handleNotCorrectCompanyCtaClick = () => {
    setTimeout(() => {
      panFieldRef.current?.focus();
    }, 0);
  };

  const delightScreenContent = (
    <Box
      display="flex"
      flexDirection="column"
      gap="44px"
      justifyContent="center"
      alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing.4"
        justifyContent="center"
        alignItems="center">
        <Heading size="xlarge">Hello 🎉</Heading>
        <Heading size="xlarge">{companyName}</Heading>
      </Box>
      <Text size="large">Taking you to your dashboard...</Text>
    </Box>
  );

  const handleDelightFinish = () => {
    window.location.href = '/dashboard';
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    updatePanDetailsAndVerifyMutation({
      company_name: companyName,
      type: Number(formData.orgType),
      pan: formData.pan,
    });
  };

  const triggerOnboardingPixels = () => {
    trackRazorpayAnalytics('fb', 'Payroll_Company_Details');
    trackRazorpayAnalytics('linkedin_onboarding_tax_details', { conversion_id: 7377292 });
    trackRazorpayAnalytics('twitter_onboarding_tax_details', {
      tw_sale_amount: 0,
      tw_order_quantity: 0,
    });
    trackGtag('event', 'conversion', { send_to: 'AW-928471290/3YacCLyypZsDEPqx3boD' });
    trackGtag('event', 'PRL_PAN_VERIFIED', { organization_type: formData.orgType });
  };

  const isCompanyNameFetched = !!companyName;

  // @TODO - Update this as required for different flows
  const showRazorpaySsoLink =
    !isCompanyNameFetched &&
    !isCompanyNameByPanFetching &&
    !isPanVerificationInProgress &&
    !isRazorpayMerchant;

  const isDisabled = isCompanyNameByPanFetching;

  const isPanSsoPrefilledAndSsoActivated = !!pan && !!isActivatedOnRazorpay;

  return (
    (<form onSubmit={handleSubmit}>
      <FormContainer>
        {hasVisitedL1Kyc && (
          <Alert
            color="information"
            isDismissible={false}
            description="We need to verify your organization for the best onboarding experience" />
        )}
        <Dropdown>
          <SelectInput
            name="orgType"
            value={formData.orgType}
            label="Organization Type"
            onChange={handleOrgTypeChange}
            isDisabled={isDisabled}
          />
          <DropdownOverlay>
            <ActionList>
              {orgTypes.map((item) => (
                <ActionListItem title={item.name} value={item.code.toString()} key={item.name} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        <TextInput
          name="pan"
          value={formData.pan}
          label={formData.orgType === '2' ? 'Personal PAN' : 'Business PAN'}
          placeholder="Eg. BZBPJ57T6Y"
          onChange={handlePanChange}
          isDisabled={isDisabled || isPanSsoPrefilledAndSsoActivated}
          ref={panFieldRef}
          validationState={panValidationError ? 'error' : undefined}
          errorText={panValidationError}
          helpText={
            isPanSsoPrefilledAndSsoActivated
              ? 'PAN prefilled from your Razorpay account'
              : undefined
          }
        />
        {isCompanyNameFetchError && (
          <Alert
            description={<Errors errorResponse={companyNameFetchError} />}
            isDismissible
            color="negative"
          />
        )}
        {showRazorpaySsoLink && (
          <Link href="/verifyWithRazorpay">Existing Razorpay user? Verify here →</Link>
        )}
        {isCompanyNameByPanFetching && (
          <BvsDataLoadingView description="Finding your company details..." />
        )}
        {isCompanyNameFetched && <PanNameView title="Registered name" value={companyName} />}
        {isPanVerificationError && (
          <Alert
            description={<Errors errorResponse={panVerificationError} />}
            isDismissible
            color="negative"
          />
        )}
      </FormContainer>
      {isCompanyNameFetched && (
        <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.5">
          <Box>
            <Button
              type="submit"
              isLoading={isPanVerificationInProgress}
              isDisabled={!!panValidationError}>
              Confirm & Proceed
            </Button>
          </Box>
          {!isPanSsoPrefilledAndSsoActivated && (
            <Box>
              <Button
                variant="secondary"
                iconPosition="right"
                icon={EditIcon}
                onClick={handleNotCorrectCompanyCtaClick}>
                Not the correct company? Edit PAN
              </Button>
            </Box>
          )}
        </Box>
      )}
    </form>)
  );
};

export { PanPrefillForm };
