import React  from 'react';
import { Table } from '../../../../../ui/Table';
import jibbleCommonStyles from '../../../index.module.scss';
import styles from '../index.module.scss';
import CheckBox from '../../../../../ui/Checkbox';
import { StandardButton } from '../../../../../ui/Button';
import { getStaticMediaUrl } from '../../../../../../utils/Urls';
import EditIcon from '../../../../../../assets/icon--edit.svg';
import Pagination from '../../../../../ui/Pagination';
import useIds from '../../../../../RunPayroll/utils/useIds';
import { JibbleAttendanceDataSchemaContract } from '../../../../../../schemas/JibbleAttendanceDataSchema';
import EditAttendanceLopModal, {
  EditAttendanceLopModalProps,
} from '../../PopUps/EditAttendanceLopModal';
import { useModal } from '../../../../../ui/Modals';
import AddDeductionsConfirmation from '../../PopUps/AddDeductionsConfirmation';
import cx from 'classnames';

const DataDisplay = ({
    payrollMonth,
    fromDate,
    toDate,
    attendanceData,
    payrollBlockedIds,
    payrollFinalizedIds,
    canAddDeductionIds,
    canEditLopDaysIds,
    page,
    onPageChange,
    rowsLimit,
    onLimitChange,
    totalPages,
    totalRows,
    fetchData
}:{
    payrollMonth: string,
    fromDate: Date | null,
    toDate: Date | null,
    attendanceData: JibbleAttendanceDataSchemaContract['attendance_data']['data'],
    payrollBlockedIds: number[],
    payrollFinalizedIds: number[],
    canAddDeductionIds: number[],
    canEditLopDaysIds: number[],
    page: number,
    onPageChange: (pageNumber: number) => void;
    rowsLimit: number,
    onLimitChange: (limit: number) => void;
    totalPages: number,
    totalRows: number,
    fetchData: () => void
}) => {
    const selectedIds = useIds(canAddDeductionIds);

    const { openModal, closeModal } = useModal();

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isSelected = event.target.checked;
        selectedIds.setValue(isSelected ? canAddDeductionIds : []);
    };

    const closeModalAndRefetchData = () => {
        closeModal();
        fetchData();
    }

    const handleAddDeductionRequest = () => {
        openModal(
            <AddDeductionsConfirmation
                payrollMonth={payrollMonth}
                fromDate={fromDate}
                toDate={toDate}
                onClose={closeModal}
                onCloseAndRefetch={closeModalAndRefetchData}
                selectedIds={selectedIds.value}
            />
        )
    }

    const handleEditLopRequest = (individualAttendanceData: EditAttendanceLopModalProps['individualData']) => (
        openModal(
            <EditAttendanceLopModal
                individualData={individualAttendanceData}
                onCloseModal={closeModal}
                onSubmitModal={closeModalAndRefetchData}
            />
        )
    )

    const canTakeActions = canAddDeductionIds.length > 0;

    return (
        <>
            <Table className={jibbleCommonStyles['table']}>
                <tbody>
                <tr className={jibbleCommonStyles['paginationHeader']}>
                    {canTakeActions &&
                        <th colSpan={1} className={jibbleCommonStyles['paginationCheckbox']}>
                            <CheckBox
                                name="select-all-attendance-records"
                                className={jibbleCommonStyles['selectCheckbox']}
                                title={'Select All'}
                                onChange={onSelectAll}
                                checked={selectedIds.value.length === canAddDeductionIds.length}
                                isIntermediate={
                                    !!selectedIds.value.length && selectedIds.value.length !== canAddDeductionIds.length
                                }
                                disabled={canAddDeductionIds.length < 1}
                            />
                        </th>
                    }
                    <th colSpan={4} className={cx(jibbleCommonStyles['paginationInfoDisplay'],
                        {[jibbleCommonStyles['active']]: !canTakeActions}
                    )}>
                        {!!selectedIds.value.length
                            ? `Selected ${selectedIds.value.length} of ${totalRows} employees`
                            : `Showing ${(page - 1) * rowsLimit + 1}-${Math.min(
                                page * rowsLimit,
                                totalRows,
                            )} of ${totalRows} employees`}
                    </th>

                        <th colSpan={canTakeActions ? 3 : 2}>
                            {canTakeActions &&
                                <StandardButton
                                    className={jibbleCommonStyles['actionButton']}
                                    disabled={selectedIds.value.length < 1}
                                    onClick={handleAddDeductionRequest}
                                >
                                    Add As Deduction ({selectedIds.value.length})
                                </StandardButton>
                            }
                        </th>
                </tr>
                <tr>
                    {canTakeActions && <th></th>}
                    <th className={cx(jibbleCommonStyles['tableHeader'],
                        {[jibbleCommonStyles['active']]: !canTakeActions}
                    )}>EMPLOYEE NAME</th>
                    <th className={jibbleCommonStyles['tableHeader']}>JIBBLE MEMBER CODE</th>
                    <th className={jibbleCommonStyles['tableHeader']}>LOSS OF PAY DAYS</th>
                    <th className={jibbleCommonStyles['tableHeader']}>WORKING DAYS</th>
                    <th className={jibbleCommonStyles['tableHeader']}>DEDUCTION AMOUNT</th>
                    <th className={jibbleCommonStyles['tableHeader']}>DEDUCTION ADDED TO PAYROLL?</th>
                    {canTakeActions &&
                        <th className={jibbleCommonStyles['tableHeader']}>EDIT LOP DAYS</th>
                    }

                </tr>
                {attendanceData.map((individualData) => {
                    const lopDays = individualData.attendance_data.lopDays;
                    const totalWorkingDays = individualData.attendance_data.totalWorkingDays;
                    const lopDeductionAmount = individualData.lop_amount;
                    const isPayrollCreated = individualData.payroll_staging_id !== null;
                    const isPayrollBlocked = isPayrollCreated && payrollBlockedIds.includes(individualData.id);
                    const isPayrollFinalized = isPayrollCreated && payrollFinalizedIds.includes(individualData.id);
                    const isPayrollFinalOrBlocked = isPayrollBlocked || isPayrollFinalized;
                    const isSelected = (selectedIds.hasId(individualData.id)) ||
                        (isPayrollFinalOrBlocked && individualData.payroll_attendance_data?.addedAsDeduction);
                    const addedAsDeduction = isPayrollFinalOrBlocked
                        ? individualData.payroll_attendance_data?.addedAsDeduction
                        : individualData.attendance_data.addedAsDeduction;
                    const lopDaysAddedToPayroll = isPayrollFinalOrBlocked
                        ? individualData.payroll_attendance_data?.lopDays
                        : individualData.attendance_data.lopDays;

                    const isEditButtonDisabled = !canEditLopDaysIds.includes(individualData.id);

                    return (
                         <tr key={`attendance-${individualData.id}`}>
                            {canTakeActions &&
                                <td>
                                    <CheckBox
                                        name={`select-attendance-record-${individualData.id}`}
                                        checked={isSelected}
                                        className={styles['skip-checkbox']}
                                        disabled={!canAddDeductionIds.includes(individualData.id)}
                                        onChange={(event) => {
                                            if (individualData.id !== null) {
                                                if (event.target.checked) {
                                                    selectedIds.push(individualData.id);
                                                } else {
                                                    selectedIds.remove(individualData.id);
                                                }
                                            }
                                        }}
                                    />
                                </td>
                            }
                            <td className={cx(jibbleCommonStyles['tableData'],
                                {[jibbleCommonStyles['active']]: !canTakeActions}
                            )}>{individualData.name}</td>
                            <td className={jibbleCommonStyles['tableData']}>{individualData.jibble_member_code}</td>
                            <td className={jibbleCommonStyles['tableData']}>{lopDays}</td>
                            <td className={jibbleCommonStyles['tableData']}>{individualData.attendance_data.employeeWorkingDays}</td>
                            <td className={jibbleCommonStyles['tableData']}>{lopDeductionAmount}</td>
                            <td className={jibbleCommonStyles['tableData']}>
                                {
                                    addedAsDeduction
                                        ? <>Yes <span
                                            className={"italic opacity-75"}>(LOP days: {lopDaysAddedToPayroll})</span></>
                                        : "No"
                                }
                            </td>
                            {canTakeActions &&
                                <td>
                                    {!isPayrollFinalOrBlocked &&
                                        <div className="w-full flex">
                                            <div
                                                className={`${jibbleCommonStyles['editButton']} ${
                                                    isEditButtonDisabled ? jibbleCommonStyles['disabled'] : ''
                                                }`}
                                                onClick={() => {
                                                    if (!isEditButtonDisabled) {
                                                    handleEditLopRequest(individualData);
                                                    }
                                                }}>
                                                <img src={getStaticMediaUrl(EditIcon)} alt="Edit" />
                                            </div>
                                        </div>
                                    }
                                </td>
                            }
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            <Pagination
                totalRows={totalRows}
                limitOptions={[10, 25, 50]}
                limit={rowsLimit}
                onLimitChange={(value) => onLimitChange(value)}
                totalPages={totalPages}
                currentPage={page}
                onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
            />
        </>
    )
}

export default DataDisplay;