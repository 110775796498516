import React, {ReactElement, useEffect, useState} from "react";
import PageWrapper from "../../../../ui/PageWrapper";
import MainCase from "../../../../ui/MainCase";
import {useMutation, useQuery} from "react-query";
import {JIBBLE_GET_EMPLOYEE_DATA, JIBBLE_INTEGRATION_STATUS_QUERY} from "../../utils/queries";
import api from "../../../../../api";
import APIError from "../../../../../types/apiError";
import Spinner from "../../../../ui/Spinner";
import {JibbleEmployeesDataSchemaContract} from "../../../../../schemas/JibbleEmployeesDataSchema";
import styles from "./index.module.scss";
import {getStaticMediaUrl} from "utils/Urls";
import {useHistory} from "react-router-dom";
import ErrorMessage from "../../../../ui/ErrorMessage";
import {routePaths} from "../../../../Routes/data";
import {accountCreationStatuses} from "../../constants";
import JibbleEmployeeDataFilters from "./Filters";
import Pagination from "../../../../ui/Pagination";
import {JibbleAccountCreationRequestBody} from "../../../../../api/jibbleSettings";
import employeeSyncPendingImage from 'assets/jibble/employee-sync-pending.png';
import {StandardButton} from "../../../../ui/Button";
import DataDisplay from "./DataDisplay";
import {WarningAlertYellow} from "../../../../ui/Alert";

const JibbleManageEmployees = (): ReactElement => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [accountCreationStatusFilter, setAccountCreationStatusFilter] = useState('default');
    const [employeesData, setEmployeesData] = useState<JibbleEmployeesDataSchemaContract['jibble_employee_data']>();
    const [unsyncedEmployees, setUnsyncedEmployees] = useState<JibbleAccountCreationRequestBody[]>([]);
    const [page, setPage] = useState(1);
    const [rowsLimit, setRowsLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [alert, setAlert] = useState('');

    const history = useHistory();

    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    const { isLoading, refetch } = useQuery(
        JIBBLE_GET_EMPLOYEE_DATA,
        () => {
            const accountCreationStatusValues = accountCreationStatuses[accountCreationStatusFilter].values;
            return api.jibbleSettings.getEmployeeData({
                searchPhrase, accountCreationStatusValues , page, rowsLimit
            }); },
        {
            onError: (error: APIError) => {},
            onSuccess: data => {
                setEmployeesData(data.jibble_employee_data);
                setTotalRows(data.jibble_employee_data.total);
                setTotalPages(Math.ceil(data.jibble_employee_data.total / rowsLimit));
                setUnsyncedEmployees(data.unsynced_xpayroll_employees as JibbleAccountCreationRequestBody[]);
            }
        },
    );

    const bulkJibbleAccountCreationMutation = useMutation(() => api.jibbleSettings.bulkOnboardEmployeesToJibble({
        check_for_existing_accounts: false,
        employees_to_be_added: unsyncedEmployees,
        is_onboarding: false,
    }), {
        onSuccess: (data) => {
            if (data.success) {
                refetch();
            } else {
                setAlert(data.message as string);
            }
        },
        onError: (error: APIError) => {}
    });

    useEffect(() => {
        refetch();
    }, [accountCreationStatusFilter, page, rowsLimit]);

    if (integrationStatusQuery.status === 'loading' || integrationStatusQuery.status === 'idle') {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occured!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery?.data) {
        if (!integrationStatusQuery.data.is_enabled) {
            history.push(routePaths.settings.jibble.onboarding.activation);
        }
        if (!integrationStatusQuery.data.is_onboarding_completed) {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    const onBannerAction = () => {
        bulkJibbleAccountCreationMutation.mutate();
    }

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        refetch();
    };

    const onRowsLimitChange = (value: number) => {
        setRowsLimit(value);
        setTotalPages(Math.ceil(totalRows / value));
    }

    return (
        <div>
            <h1 className="text-4.5xl font-bold opacity-90">Jibble employee account integration status</h1>
            {alert && <WarningAlertYellow className="mt-8">{alert}</WarningAlertYellow>}
            {unsyncedEmployees.length>0 && (
                <div className={`${styles['banner']} flex`}>
                    <img src={getStaticMediaUrl(employeeSyncPendingImage)} alt={""}/>
                    <div className="ml-8">
                        <h1 className={styles['bannerHeader']}>
                            {unsyncedEmployees.length} RazorpayX Payroll {unsyncedEmployees.length>1 ? "employees are" : "employee is"} not synced to Jibble
                        </h1>
                        <p className={styles['bannerNote']}>Sync employees to Jibble to fetch their attendance data and calculate loss of pay</p>
                    </div>
                    <StandardButton className={styles['bannerAction']} onClick={onBannerAction}>
                        Sync employees to Jibble
                    </StandardButton>
                </div>
            )}
            <JibbleEmployeeDataFilters
                searchPhrase={searchPhrase}
                accountCreationStatusFilter={accountCreationStatusFilter}
                onSearchPhraseChange={(value) => {setSearchPhrase(value)}}
                onAccountCreationStatusFilterChange={(value) => {setAccountCreationStatusFilter(value)}}
                onSubmit={(event) => {onSubmit(event)}}
                disabled={isLoading}
            />
            <DataDisplay
                employeesData={employeesData}
                fetchData={refetch}
                isLoading={isLoading}
            />
            <Pagination
                totalRows={totalRows}
                limitOptions={[10, 25, 50]}
                limit={rowsLimit}
                onLimitChange={(value) => onRowsLimitChange(value)}
                totalPages={totalPages}
                currentPage={page}
                onPageChange={(pageNumber: number) => setPage(pageNumber)}
            />
        </div>
    );
}

function JibbleManageEmployeesPageWrapper(): ReactElement {
    return (
        <PageWrapper>
            <MainCase className={styles['mainCase']}>
                <JibbleManageEmployees/>
            </MainCase>
        </PageWrapper>
    );
}

export default JibbleManageEmployeesPageWrapper;