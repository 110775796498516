import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@razorpay/blade/components';
import React from 'react';

type Props = Pick<React.ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  employees: Array<{ name: string; email: string }>;
};

const EmployeeListing = ({ isOpen, onDismiss, employees }: Props) => {
  return (
    (<Modal isOpen={isOpen} onDismiss={onDismiss} size="small">
      <ModalHeader title="Employee details" />
      <ModalBody>
        <Box display="flex" flexDirection="column">
          {employees.map((emp) => (
            <Box
              key={emp.email}
              paddingY="spacing.3"
              borderBottomWidth="thin"
              borderBottomColor="surface.border.gray.subtle">
              <Text color="surface.text.gray.subtle">{emp.name}</Text>
              <Text size="small" color="surface.text.gray.disabled">
                {emp.email}
              </Text>
            </Box>
          ))}
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
          <Button variant="secondary" onClick={() => onDismiss()}>
            Close
          </Button>
        </Box>
      </ModalFooter>
    </Modal>)
  );
};

export default EmployeeListing;
