import React, { useEffect } from 'react';
import pollForBulkUploads from '../../utils/pollForBulkUploads';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { PollingAction } from 'types';

const BulkUploadsPolling = ({ id, 
    action, 
    tries = 200,
    interval = 3500,
    setBulkUploadRecordData,
    onSuccess, 
    onFailure
}: 
    { 
    id: string | null; 
    action: PollingAction;
    tries? : number;
    interval? : number;
    setBulkUploadRecordData: (bulkUploadRecord : BulkUploadDetailsSchemaContract[0] | null) => void;
    onSuccess?:() => void;
    onFailure?:() => void;
}) => {

  useEffect(() => {
    if (id) {
      pollForBulkUploads(parseInt(id),action,tries,interval,setBulkUploadRecordData ,onSuccess, onFailure);
    }
  }, [id]);

  return null;
};

export default BulkUploadsPolling;
