
import { ProgressPillState } from '../../components/Kyc/types';
import { BonusDataArraySchemaContract } from '../../schemas/BonusDataArraySchema';
import { ReactNode } from 'react';


export interface HeaderContent {
    title: string;
    description: string[];
    progress: Array<ProgressPillState>;
    titleIcon?: ReactNode
}

export interface FetchBonusRequestData {
    bonus_id?: number;
    people_id?: number;
    payout_month?: string;
    status?: BonusStatus;
    source?: BonusSource;
    page?: number;
    page_size?: number;
}

export interface FormProps {
    organization_bonus_setting_id: number | null;
    amount: number | null;
    source: string | null;
    payout_month: string | null;
    tds_recovery_option: string | null;
    part_of_ctc: boolean | null;
    clawback_applicable: boolean | null;
    clawback_period: number | null;
    remarks: string;
}

export interface ValidationStates {
    name: 'success' | 'error' | 'none';
    amount: 'success' | 'error' | 'none';
    clawback_period: 'success' | 'error' | 'none';
}

export interface DeleteModalProps {
    isOpen: boolean;
    bonusData: BonusDataArraySchemaContract['data'][0] | null;
}

export const getDefaultFormProps = (): FormProps => {
    return {
        organization_bonus_setting_id: null,
        amount: 0,
        source: bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL,
        payout_month: '',
        tds_recovery_option: bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_INSTANT,
        part_of_ctc: false,
        clawback_applicable: false,
        clawback_period: null,
        remarks: '',
    };
}

export type BonusStatus = keyof typeof bonusStatus;
export type BonusSource = keyof typeof bonusSource;
export type BonusTDSDeduction = keyof typeof bonusTDSDeduction;

export const bonusStatus = {
    BONUS_STATUS_PENDING: '1',
    BONUS_STATUS_PAID: '2',
    BONUS_STATUS_CLAWBACK_APPLIED: '3',
} as const;

export const bonusSource = {
    BONUS_SOURCE_WITHIN_XPAYROLL: 'WITHIN_XPAYROLL', // when bonus is paid via payroll
    BONUS_SOURCE_OUTSIDE_XPAYROLL: 'OUTSIDE_XPAYROLL' // when bonus is paid outside payroll
} as const;

export const bonusTDSDeduction = {
    BONUS_TDS_RECOVERY_OPTION_INSTANT: 'IMMEDIATE', // when tds is recovered instantly
    BONUS_TDS_RECOVERY_OPTION_PRORATE: 'PRORATE' // when tds is recovered is split across finanacial year
} as const;

export const BonusStatusTextMap: { [key: string]: string } = {
    [bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL]: 'Yet To Be Paid',
    [bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL]: 'Paid Already (Outside XPayroll)',
};

export const BonusTDSDeductionTextMap: { [key: string]: string } = {
    [bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_INSTANT]: 'Instant Deduction',
    [bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_PRORATE]: 'Prorated Deduction',
};