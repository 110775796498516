import React from 'react';
import { GetUserOrgFlexiPartnerDetailsSchemaContract } from '../../../../schemas/GetUserOrgFlexiPartnerDetailsSchema';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import { getCurrentFinancialYear } from '../utils';

function StatItem({ heading, value, className }: { heading: string, value: number, className?: string }) {
  return <div className={'w-1/3 p-4 space-y-6 bg-persian-blue mr-5'}>
    <div className={'text-2xl'}>{heading}</div>
    <strong
      className={(className ? className : '') + ' text-white text-3xl'}>{toIndianCurrencyString(value)}</strong>
  </div>;
}

export function Stats({ employeeFlexiBenefitsDetails }: { employeeFlexiBenefitsDetails: GetUserOrgFlexiPartnerDetailsSchemaContract }) {
  return <div className={'flex '}>
    <StatItem heading={'Maximum monthly allocation'} value={employeeFlexiBenefitsDetails.totalAmountAllocated} />
    <StatItem heading={'Current monthly declaration'} value={employeeFlexiBenefitsDetails.expensesDeclaredMonthly} />
    {!!employeeFlexiBenefitsDetails.optedOn ? <StatItem
      heading={'Tax saving for FY ' + getCurrentFinancialYear()}
      value={employeeFlexiBenefitsDetails.annualSavings}
      className={'text-green-600'} /> : <></>}
  </div>;
}