import React from "react";

import { TickList, TickListItem } from "components/ui/TickList";
import {Card} from "../../../ui/Card";

export const ZaggleChargesInfo = () => {
    return (<Card withTopBorder={true} className={'border-accent-negative px-2 shadow-none'}>
        <div className={`text-1.92xl font-bold text-white`}>
            Our charges are simple!
        </div>
        <br />
        <div>
            <TickList>
                <TickListItem>
                    Rs. 99 for card + delivery charges as a one time fee for physical card.
                </TickListItem>
                <TickListItem>
                    No Platform fees.
                </TickListItem>
            </TickList>
        </div>
    </Card>)
};