import React, { useEffect, useRef, useState, KeyboardEvent, forwardRef, useCallback } from 'react';
import { Input } from '../Form/Input';
import { debounceV2 } from 'utils/Debounce';
import { keyCodes } from 'constants/keyboard';
import iconCross from 'assets/icon-cross.svg';
import cx from 'classnames';
import { scrollIntoView } from 'utils/scrollIntoView';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from './index.module.scss';

export type TypeItem = {
  name: string;
  label: string;
};

const TypeAhead = ({
  searchFunction,
  value,
  onChange,
  disabled,
  name,
  placeholder,
}: {
  searchFunction: (search: string) => Promise<TypeItem[]>;
  value: TypeItem | null;
  onChange: (value: TypeItem | null) => void;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
}) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<TypeItem[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);
  const typeaheadRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const selectedItemRef = useRef<HTMLSpanElement>(null);
  const optionsWrapperRef = useRef<HTMLSpanElement>(null);

  function clearOptions() {
    setOptions([]);
    setError(null);
  }

  function closeDropdown() {
    setIsOpen(false);
    clearOptions();
    setSearch('');
    setCurrentIndex(-1);
  }

  function focusOnSearch() {
    inputRef.current && inputRef.current.focus();
  }

  useEffect(() => {
    function handleOutsideClick(e: Event) {
      if (
        typeaheadRef.current &&
        e.target instanceof HTMLElement &&
        !typeaheadRef.current.contains(e.target)
      ) {
        closeDropdown();
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const debouncedSearch = useCallback(
    debounceV2(
      (search: string) =>
        searchFunction(search)
          .then((result) => {
            navigateToItem(0);
            setOptions(result);
            if (result.length === 0) {
              setError('No results found');
            } else {
              setError(null);
              setIsOpen(true);
            }
          })
          .catch((error: string) => {
            setError(error);
          }),
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (search === '') {
      clearOptions();
      setIsOpen(false);
      debouncedSearch.cancel();
    } else {
      debouncedSearch(search);
    }
  }, [search]);

  useEffect(() => {
    // force the listitem to scroll into view
    if (optionsWrapperRef.current && selectedItemRef.current && isOpen) {
      // optionsWrapperRef.current.scrollTop = selectedItemRef.current.offsetTop;
      scrollIntoView(optionsWrapperRef.current, selectedItemRef.current);
    }
  }, [currentIndex]);

  const navigateToItem = (position: number) => {
    setCurrentIndex(position);
  };

  const goDownList = () => {
    navigateToItem(Math.min(options.length - 1, currentIndex + 1));
  };

  const goUpList = () => {
    navigateToItem(Math.max(0, currentIndex - 1));
  };

  const handleSelect = (item: TypeItem | null) => {
    clearOptions();
    setIsOpen(false);
    setSearch('');
    onChange(item);
    inputRef.current?.blur();
  };

  const handleHover = (item: TypeItem | null) => {
    navigateToItem(options.findIndex((option) => option.name === item?.name));
  };

  const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    switch (e.key) {
      case keyCodes.ARROW_DOWN: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          goDownList();
        }
        break;
      }
      case keyCodes.ARROW_UP: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          goUpList();
        } else {
          closeDropdown();
        }
        break;
      }
      case keyCodes.ENTER: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen && currentIndex >= 0) {
          const selectedValue = options[currentIndex];
          handleSelect(selectedValue);
        }
        break;
      }

      case keyCodes.ESCAPE:
      case keyCodes.ESCAPE_IE:
      case keyCodes.TAB: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          closeDropdown();
        }

        break;
      }

      default:
        break;
    }
  };

  return (
    <div ref={typeaheadRef} className={styles['TypeAheadContainer']}>
      <input type="hidden" name={name} value={value?.name || ''} />
      <Input
        className={styles['SearchBox']}
        disabled={disabled}
        ref={inputRef}
        onFocus={() => {
          setError(null);
        }}
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={handleSearchKeyDown}
      />
      {value && (
        <img
          className={styles['Clear']}
          alt="clear"
          src={getStaticMediaUrl(iconCross)}
          onClick={() => handleSelect(null)}
        />
      )}
      {value && search === '' && (
        <span className={styles['SelectedItemDisplay']} onClick={focusOnSearch}>
          {value.label}
        </span>
      )}
      {placeholder && search === '' && !value && (
        <span className={styles['SelectedItemDisplay']} onClick={focusOnSearch}>
          {placeholder}
        </span>
      )}
      {!disabled && isOpen && (
        <span className={styles['Options']} ref={optionsWrapperRef}>
          {options.map((option, index) => {
            return (
              <TypeAheadItem
                ref={index === currentIndex ? selectedItemRef : null}
                key={option.name}
                value={option}
                onSelect={handleSelect}
                isHovered={index === currentIndex}
                isSelected={false} // wil enable after discussion with design
                onHover={handleHover}
              />
            );
          })}
        </span>
      )}
      {error && <span className={styles['Error']}>{error}</span>}
    </div>
  );
};

const TypeAheadItem = forwardRef<
  HTMLSpanElement,
  {
    value: TypeItem;
    onSelect: (item: TypeItem) => void;
    isHovered: boolean;
    isSelected: boolean;
    onHover: (item: TypeItem) => void;
  }
>(function TypeAheadItemWithRef({ value, onSelect, isHovered, isSelected, onHover }, ref) {
  return (
    <span
      ref={ref}
      className={cx(styles['TypeAheadItem'], {
        [styles['hovered']]: isHovered,
        [styles['selected']]: isSelected,
      })}
      onMouseOver={() => onHover(value)}
      onClick={() => {
        onSelect(value);
      }}>
      {value.label}
    </span>
  );
});

export default TypeAhead;
