import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import api from 'api';
import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import RightSidebar from 'components/ui/RightSidebar';
import { AppError } from 'utils/AppError';
import ErrorDisplay from './ErrorDisplay';
import Grid from './Grid';
import MessageDisplay from './MessageDisplay';
import SideDisplay from './SideDisplay';
import { RunPayrollDataContext } from './contexts/RunPayrollDataContext';
import { RunPayrollDataSelectionContext } from './contexts/RunPayrollDataSelectionContext';
import { ErrorMessageType, RunPayrollErrorContext } from './contexts/RunPayrollErrorContext';
import { RunPayrollFilterContext } from './contexts/RunPayrollFiltersContext';
import {
  MessageType,
  MessageTypes,
  RunPayrollMessageContext,
} from './contexts/RunPayrollMessageContext';
import { RunPayrollPaginationContext } from './contexts/RunPayrollPaginationContext';
import { FETCH_RUNPAYROLL_DATA } from './utils/queries';
import { Filters, OrderConfig } from './utils/types';
import useIds from './utils/useIds';
import { getNextOrder } from './utils/utils';

import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import APIError from 'types/apiError';
import { JIBBLE_INTEGRATION_STATUS_QUERY } from '../Settings/JibbleSettings/utils/queries';
import { ComplianceActions } from './ComplianceActions';
import styles from './index.module.scss';
import { useSearchParams } from 'hooks/useSearchParams';

const RunPayroll = () => {
  const queryParam = useSearchParams().urlParams;
  const queryPayrollMonth = queryParam.get('payroll_month');
  const searchPhrase = queryParam.get('search_phrase');
  const features = useSelector(loggedInUserSelectors.features);
  const numOfEmployees = useSelector(loggedInUserSelectors.numOfEmployees);
  const isJibbleFeatureEnabled = !!features?.['jibble-integration'];

  const history = useHistory();

  const [payrollMonth, setPayrollMonth] = useState<string | null>(queryPayrollMonth ?? null);
  const [error, setError] = useState<ErrorMessageType | null>(null);
  const [message, setMessage] = useState<MessageType | null>(null);

  const [filters, setFilter] = useState<Filters>({
    searchPhrase: searchPhrase ?? '',
    departments: [],
    locations: [],
    statusFilter: null,
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<OrderConfig | null>(null);
  const selectedIds = useIds();

  const [isJibbleEnabled, setIsJibbleEnabled] = useState(false);

  const getErrorContextValue = useCallback(
    () => ({
      error,
      setError,
    }),
    [error],
  );

  const getMessageContextValue = useCallback(
    () => ({
      message,
      setMessage,
    }),
    [message],
  );

  const { isLoading, isRefetching, refetch, data } = useQuery(
    FETCH_RUNPAYROLL_DATA,
    () => {
      return api.runPayroll.getRunPayrollData(
        payrollMonth ?? '',
        filters.searchPhrase,
        filters.departments,
        filters.locations,
        page,
        limit,
        order,
        filters.statusFilter,
      );
    },
    {
      onSuccess: (data) => {
        setPayrollMonth(data?.payrollMonth ?? null);
        const searchPhrase = filters.searchPhrase ? `&search_phrase=${filters.searchPhrase}` : '';
        history.replace(`/run-payroll?payroll_month=${data?.payrollMonth}${searchPhrase}`);
        setError(null);
      
        if (!data.isPayrollFinalized && !data.isExecutionRequested) {
          setMessage(null);
        }
        if (data.isPayrollBeingFinalized) {
          setMessage({ type: MessageTypes.FINALIZE_IN_PROGRESS });
        } else if ((data.finalizationErrorCount ?? 0) > 0) {
          setMessage({ type: MessageTypes.FINALIZED_ERROR_REPORT, data: payrollMonth });
        } else if (!data.hasFinalizePayrollErrors) {
          if (message?.type === MessageTypes.FINALIZE_IN_PROGRESS) {
            setMessage({ type: MessageTypes.FINALIZED, data: payrollMonth });
          }
        } 
      },
      onError: (error: typeof AppError) => {
        console.error('Error on fetching runpayroll data : ', error);
        setError({ message: 'Something went wrong, please contact support' });
      },
      refetchInterval: (data) => {
        if (data?.isPayrollBeingFinalized) {
          if (numOfEmployees) {
            if (numOfEmployees < 50) {
              return 10 * 1000;
            } else if (numOfEmployees < 100) {
              return 15 * 1000;
            } else if (numOfEmployees < 500) {
              return 30 * 1000;
            }
          }

          return 60 * 1000;
        } else {
          return false;
        }
      },
    },
  );

  useQuery(
    JIBBLE_INTEGRATION_STATUS_QUERY,
    () => {
      return api.jibbleSettings.fetchIntegrationStatus();
    },
    {
      onSuccess: (data) => {
        setIsJibbleEnabled(data.is_enabled);
      },
      onError: (error: APIError) => {},
      enabled: isJibbleFeatureEnabled,
    },
  );

  const resetErrorMessage = () => {
    setError(null);
    setMessage(null);
  };

  useEffect(() => {
    const payrollMonthData = data?.payrollMonth;
    if (payrollMonth && payrollMonthData !== payrollMonth) {
      if (page !== 1) {
        setPage(1);
      } else {
        refetch();
      }
      selectedIds.setValue([]);
      resetErrorMessage();
    }
  }, [payrollMonth]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      refetch();
    }
    selectedIds.setValue([]);
    resetErrorMessage();
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [order]);

  useEffect(() => {
    refetch();
    resetErrorMessage();
  }, [page]);

  const goNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const goPrevPage = () => {
    setPage((prev) => prev - 1);
  };

  const orderBy = (key: 'ORDER_BY_NAME' | 'ORDER_BY_CANCELLED') => {
    if (order) {
      const nextOrder = getNextOrder(order[key] ?? null);
      if (nextOrder) {
        setOrder({ [key]: nextOrder });
      } else {
        setOrder(null);
      }
    } else {
      setOrder({ [key]: getNextOrder(null) });
    }
  };

  const hasFilters = () => {
    return (
      !!filters?.searchPhrase ||
      filters?.departments.length > 0 ||
      filters?.locations.length > 0 ||
      !!filters.statusFilter
    );
  };

  const setSearchPhraseFilter = (searchPhrase: string | null) => {
    setFilter((prevValue) => ({
      ...prevValue,
      searchPhrase: searchPhrase ?? prevValue.searchPhrase,
    }));
  };

  const setDepartmentsFilter = (departments: string[]) => {
    setFilter((prevValue) => ({
      ...prevValue,
      departments: departments,
    }));
  };

  const setLocationsFilter = (locations: string[]) => {
    setFilter((prevValue) => ({
      ...prevValue,
      locations: locations,
    }));
  };

  const setStatusFilter = (statusFilter: string | null) => {
    setFilter((prevValue) => ({
      ...prevValue,
      statusFilter,
    }));
  };

  return (
    <RunPayrollDataContext.Provider value={data}>
      <RunPayrollFilterContext.Provider
        value={{
          filters,
          setSearchPhraseFilter,
          setDepartmentsFilter,
          setLocationsFilter,
          hasFilters,
          setStatusFilter,
        }}>
        <RunPayrollPaginationContext.Provider
          value={{
            page,
            limit,
            setPage,
            goNextPage,
            goPrevPage,
            order,
            orderBy,
          }}>
          <RunPayrollDataSelectionContext.Provider value={selectedIds}>
            <RunPayrollErrorContext.Provider value={getErrorContextValue()}>
              <RunPayrollMessageContext.Provider value={getMessageContextValue()}>
                <div className={`runPayroll ${styles['run-payroll-wrapper']}`}>
                  <PageWrapper>
                    <Breadcrumb name="Run Payroll" urlMaps={{}} className="pb-0" />
                    <MainCase>
                      <ComplianceActions />
                      <ErrorDisplay setPayrollMonth={setPayrollMonth} />
                      <MessageDisplay />
                      <Grid
                        payrollMonth={payrollMonth}
                        isLoading={isLoading}
                        isRefetching={isRefetching}
                      />
                    </MainCase>
                    <RightSidebar>
                      <SideDisplay
                        payrollMonth={payrollMonth}
                        setPayrollMonth={setPayrollMonth}
                        isSelected={selectedIds.value.length > 0}
                        isJibbleEnabled={isJibbleEnabled}
                      />
                    </RightSidebar>
                  </PageWrapper>
                </div>
              </RunPayrollMessageContext.Provider>
            </RunPayrollErrorContext.Provider>
          </RunPayrollDataSelectionContext.Provider>
        </RunPayrollPaginationContext.Provider>
      </RunPayrollFilterContext.Provider>
    </RunPayrollDataContext.Provider>
  );
};

export default RunPayroll;
