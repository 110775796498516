import React from 'react';

import { ClickableCard } from 'components/ui/SidebarCard';

const BookADemoFAQ = () => {

  return (
    <ClickableCard
      heading='Book a demo'
      onClick={() => {
        window.open('https://calendly.com/xpayroll-zaggle/30min', '_blank');
      }}
      imageSymbolType={'requests'}
    >
        Our team of experts will help you setup Zaggle.
    </ClickableCard>
  );
};

export default BookADemoFAQ;