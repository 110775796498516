export const tabItems = {
  payslips: 'payslips',
  compensation: 'compensation',
  form16: 'form16',
};

export const compensationTabItems = {
  ctc: 'ctc',
  deductions: 'deductions',
  // perquisites: 'perquisites',
  // flexi: 'flexi',
};

export const queryKeys = {
  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  FETCH_COMPENSATION_DETAILS: 'FETCH_COMPENSATION_DETAILS',
};
