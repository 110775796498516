import { Box, Text } from '@razorpay/blade/components';
import React from 'react';

const FeaturePill = ({ text }: { text: string }) => {
  return (
    <Box
      padding={['spacing.3', 'spacing.4']}
      borderRadius="medium"
      borderColor="surface.border.gray.muted"
      borderWidth="thinner"
      backgroundColor="surface.background.gray.intense">
      <Text color="surface.text.gray.muted">{text}</Text>
    </Box>
  );
};

export { FeaturePill };
