import React from 'react';

import { DeliveryType } from "constants/flexibenefits";
import { InfoCard } from './InfoCardList';

export const getInfoCards = (deliveryType: DeliveryType): InfoCard[] => ([
    {
        id: DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION,
        title: "Organization address",
        content: "Zaggle card will be delivered to the organization registered address. ",
        img: (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.42332 18.4612H14.3463C14.7707 18.4612 15.1155 18.8056 15.1155 19.2303C15.1155 19.6549 14.7707 19.9993 14.3463 19.9993H6.6541C6.22915 19.9993 5.88489 19.6554 5.88489 19.2303V0.769041C5.88489 0.344082 6.22915 0 6.6541 0H14.3463C14.7712 0 15.1155 0.344086 15.1155 0.769041V16.1534C15.1155 16.578 14.7707 16.9224 14.3463 16.9224C13.9218 16.9224 13.5771 16.578 13.5771 16.1534V1.53782H7.42332V18.4612Z" fill={deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION ? "#5A99E8": "#FBFBFC"}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M2.03879 18.4615H3.57705C4.00166 18.4615 4.34626 18.8063 4.34626 19.2307C4.34626 19.6552 4.00166 19.9999 3.57705 19.9999H1.26939C0.844605 19.9999 0.5 19.6555 0.5 19.2307V3.84637C0.5 3.42158 0.844601 3.07715 1.26939 3.07715H6.65391C7.0787 3.07715 7.42331 3.42158 7.42331 3.84637V19.2307C7.42331 19.6552 7.07871 19.9999 6.65391 19.9999C6.22912 19.9999 5.88452 19.6552 5.88452 19.2307V4.61558H2.03879V18.4615Z" fill={deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION ? "#5A99E8": "white"} fillOpacity="0.74"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M15.1154 4.61558V16.1538C15.1154 16.5783 14.7708 16.9231 14.3461 16.9231C13.9213 16.9231 13.5767 16.5783 13.5767 16.1538V3.84637C13.5767 3.42158 13.9213 3.07715 14.3461 3.07715H19.7306C20.1554 3.07715 20.5 3.42158 20.5 3.84637V19.2307C20.5 19.6552 20.1554 19.9999 19.7306 19.9999C19.3058 19.9999 18.9612 19.6552 18.9612 19.2307V4.61558H15.1154Z" fill={deliveryType === DeliveryType.DELIVERY_TYPE_DISPATCH_TO_ORGANIZATION ? "#5A99E8": "white"} fillOpacity="0.74"/>
            </svg>

        )
    },
    {
        id: DeliveryType.DISPATCH_TO_EMPLOYEE,
        title: "Employee address",
        content: "Zaggle card will be delivered to the employee’s address. We will ask for confirmation during the employee setup. ",
        img: (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5116 1.00855C10.2107 0.774495 9.78927 0.774495 9.48834 1.00855L1.98834 6.84188C1.78535 6.99976 1.66663 7.24252 1.66663 7.49967V16.6663C1.66663 18.0471 2.78591 19.1663 4.16663 19.1663H15.8333C17.214 19.1663 18.3333 18.0471 18.3333 16.6663V7.49967C18.3333 7.24252 18.2146 6.99976 18.0116 6.84188L10.5116 1.00855ZM13.3333 17.4997H15.8333C16.2935 17.4997 16.6666 17.1266 16.6666 16.6663V7.90724L9.99996 2.72206L3.33329 7.90724V16.6663C3.33329 17.1266 3.70639 17.4997 4.16663 17.4997H6.66663V9.99967C6.66663 9.53944 7.03972 9.16634 7.49996 9.16634H12.5C12.9602 9.16634 13.3333 9.53944 13.3333 9.99967V17.4997ZM8.33329 17.4997V10.833H11.6666V17.4997H8.33329Z" 
                fill={deliveryType ===  DeliveryType.DISPATCH_TO_EMPLOYEE? "#5A99E8": "white"}/>
            </svg>
        )
    }
]);