
import { convertToOrdinal, daysList } from '../../../utils/Dates';

export const daysKeyValueList = daysList.map((day) => {
    return {
        key: day.toString(),
        title: day.toString(),
        value: day.toString().padStart(2, '0')
    }
});

export const getCustomCycleDaysKeyValueList = (startDay: number, dateType: string) => {

    const dayList = startDay ? daysList.slice(startDay - 1) : daysList;

    return dayList.map((day) => {
        
        let monthString = '';

        if(dateType === 'start'){
            monthString = (day <= 15 ? 'payroll month' : 'previous month');
        }
        else{
            monthString = (day < 15 ? 'next month' : 'payroll month');
        }

        if (day === 31 || day === 0) {
            return {
                key: day.toString(),
                title: `Last day of a month`,
                value: day.toString().padStart(2, '0')
            }
        }
        else {
            return {
                key: day.toString(),
                title: `${convertToOrdinal(day)} of ${monthString}`,
                value: day.toString().padStart(2, '0')
            }
        }

    })
};

export const customCyclePayrollDateKeyValueList = daysList.map((day) => {

    return {
        key: day.toString(),
        title: `${convertToOrdinal(day)} of the month`,
        value: day.toString().padStart(2, '0')
    }

});