import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';

const ImageSymbolAssetMap = {
  requests: 'symbol--requests.svg',
  ADD: 'symbol--add.svg',
  'add--help': 'symbol--add--help.svg',
  'add--search': 'symbol--add--search.svg',
  'add--white': 'symbol--add--white.svg',
  add: 'symbol--add.svg',
  add1: 'symbol--add1.png',
  'cross--accent': 'symbol--cross--accent.svg',
  'cross--dark': 'symbol--cross--dark.svg',
  cross: 'symbol--cross.svg',
  expand: 'symbol--expand.png',
  help: 'symbol--help.svg',
  'incomplete--accent': 'symbol--incomplete--accent.svg',
  'incomplete--dark': 'symbol--incomplete--dark.svg',
  incomplete: 'symbol--incomplete.svg',
  invite: 'symbol--invite.svg',
  remind: 'symbol--remind.svg',
  'search--white': 'symbol--search--white.svg',
  subtract: 'symbol--subtract.svg',
  subtract1: 'symbol--subtract1.png',
  'tick--accent': 'symbol--tick--accent.svg',
  'tick--dark': 'symbol--tick--dark.svg',
  tick: 'symbol--tick.svg',
  chat: 'chat-icon.svg',
};

export function AnchorCard({
  href,
  heading,
  children,
  newTab = false,
  imageSymbolType,
}: PropsWithChildren<{
  href?: string;
  heading: string;
  newTab?: boolean;
  imageSymbolType: keyof typeof ImageSymbolAssetMap;
}>) {
  return (
    <a href={href} target={newTab ? '_blank' : '_self'} className="button__cta">
      <img src={`https://payroll-assets.razorpay.com/assets/${ImageSymbolAssetMap[imageSymbolType]}`} alt={imageSymbolType} />
      <h3 className="text-2xl font-heading mb-6">{heading}</h3>
      {children ? <h5 className={styles['CardBody']}>{children}</h5> : null}
    </a>
  );
}

export function ClickableCard({
  heading,
  children,
  imageSymbolType,
  onClick,
  className = '',
}: PropsWithChildren<{
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  heading: string;
  imageSymbolType: keyof typeof ImageSymbolAssetMap;
  className?: string;
}>) {
  return (
    <span onClick={onClick} className={`button__cta cursor-pointer ${className}`}>
      <img src={`https://payroll-assets.razorpay.com/assets/${ImageSymbolAssetMap[imageSymbolType]}`} alt={imageSymbolType} />
      <h3 className="text-2xl font-heading mb-6">{heading}</h3>
      {children ? <h5 className={styles['CardBody']}>{children}</h5> : null}
    </span>
  );
}

export function PlainCard({
  href,
  heading,
  children,
  target
}: PropsWithChildren<{
  href?: string;
  heading: string;
  target?: string;
}>) {
  return (
    <a href={href} target={target ? target : '_self'} className="button__cta">
      <h3 className="text-2xl font-heading mb-6">{heading}</h3>
      {children ? <div className={styles['CardText']}>{children}</div> : null}
    </a>
  );
}