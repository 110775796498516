import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styles from './index.module.css';
import { LinkButtonV2, PrimaryButton, StandardSecondaryButton } from 'components/ui/Button';
import {
  EmployeeOnboardingAnnualCompensationDataType,
  employeeOnboardingAnnualCompensationPageStateActionType,
  employeeOnboardingAnnualCompensationPageStateType,
} from './types';
import api from 'api';
import { useHistory, useParams } from 'react-router-dom';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { routePaths } from 'components/Routes/data';
import { InfoAlert, WarningAlert, WarningAlertYellow } from 'components/ui/Alert';
import EmployeeAnnualCompensationSuccessUpdateModal from './EmployeeAnnualCompensationSuccessUpdateModal';
import AnnualCompensationModifyDetails from './AnnualCompensationModifyDetails';
import { useQuery } from 'react-query';
import { EMP_ANNUAL_COMPENSATION_DETAILS } from '../queries';
import { EmployeeOnBoardingFetchAnnualCompensationResponse, SalaryRevisionValidationResponse } from 'api/employeeOnboarding';
import Spinner from 'components/ui/Spinner';
import { Card } from 'components/ui/Card';
import ErrorMessage from 'components/ui/ErrorMessage';
import AnnualCompensationDetails from './AnnualCompensationDetails';
import ConfirmAnnualCompensationModifiedDetails from './ConfirmAnnualCompensationModifiedDetails';
import Breadcrumb from 'components/ui/Breadcrumb';
import { startOfMonth } from 'date-fns';
import useApprovalLink from 'components/RunPayroll/utils/useApprovalLink';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';

export const VIEW_COMPENSATION_DETAILS = 'VIEW_COMPENSATION_DETAILS';
export const VIEW_UPDATE_COMPENSATION = 'VIEW_UPDATE_COMPENSATION';
export const VIEW_CONFIRM_UPDATED_COMPENSATION = 'VIEW_CONFIRM_UPDATED_COMPENSATION';

export const TAXABLE_NO = 0;
export const TAXABLE_YES = 1;
export const TAXABLE_FLEXIBLE_BENEFIT = 2;

export const InitialEmployeeOnboardingAnnualCompensationData: EmployeeOnboardingAnnualCompensationDataType =
  {
    id: 0,
    organizatinId: 0,
    salaryModel: {
      da: 0,
      pf: 0,
      hra: {
        actualHRA: 0,
        excessRent: 0,
        finalExemption: 0,
        allowedPercentageOfBasic: 0,
      },
      lta: 0,
      vpf: 0,
      basic: 0,
      allowances: [],
      deductions: [],
      foodAllowance: 0,
      pfContribution: 0, // ref based on isPF
      esiContribution: 0, // ref based on isEsi and includeEmployerEsiContributionInCtc
      npsEmployerContributionLimit: 0,
      totalDeductions: 0,
      specialAllowance: 0,
      overrideIncludeEmployerPfContribution: null
    },
    isPf: false,
    annualSalary: 0,
    customSalaryStructure: false,
    customStructuresAdvisory: '',
    isEmployeeESIContributionEnabled: false,
    flexiPartnerDetails: {
      hasOrgOptedForPartnerFlexiBenefit: false,
      flexiPartnerName: '',
      flexiPartnerAllowanceAmount: 0,
    },
    customAllowancesDetails: {
      countOfCustomAllowancesAllowed: 10,
      allowances: []
    },
    variablePay: 0,
    loanPerquisiteDetails:[],
    perquisitesDetails: {
      perquisite: {},
      perquisitesType: {},
      perquisitesWithTypeAndDescription: {},
    },
    deductibleBenefitsDetails: {
      countOfDeductibleBenefits: 4
    },
    isUserAuthorisedToChangeEmployeeSalary: false,
    isEligibleForEmployerESIContribution: false,
    isEligibleForVoluntaryProvidenFund: false,
    isEligibleForEmployerPFContribution: false,
    name: '',
    areThereAnyArrearsPending: false,
    validationErrors:[],
    shouldShowFutureCompensation:false,
    futureCompensationAmount:0,
    futureCompensationDate: new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'short' }),
    isCombinedSalary : false,
    effectiveFromDate: null,
    workflowEnabled: false,
    pendingWorkflowRequestExists: false,
    isPayrollBeingFinalized: false,
    isNpsEnabled: false
  };

export enum pageStateActionEnum {
  showVpf = 'showVpf',
  showPerquisites = 'showPerquisites',
  showLoanPerquisites = 'showLoanPerquisites',
  showDeductibleBenefits = 'showDeductibleBenefits',
  errorOnPage = 'errorOnPage',
  annualCompensationDetailsUpdateState = 'annualCompensationDetailsUpdateState',
  pageView = 'pageView',
  showEffectiveDate= 'showEffectiveDate'
}

function employeeOnboardingAnnualCompensationPageStateReducer(
  state: employeeOnboardingAnnualCompensationPageStateType,
  action: employeeOnboardingAnnualCompensationPageStateActionType,
) {
  switch (action.type) {
    case 'showVpf': {
      return { ...state, showVpf: action.data };
    }
    case 'showDeductibleBenefits': {
      return { ...state, showDeductibleBenefits: action.data };
    }
    case 'showLoanPerquisites': {
      return { ...state, showLoanPerquisites: action.data };
    }
    case 'showPerquisites': {
      return { ...state, showPerquisites: action.data };
    }
    case 'errorOnPage': {
      if (Array.isArray(action.data)) {
        if (action.data.length === 0) {
          return { ...state, errorOnPage: [] };
        }
        return { ...state, errorOnPage: [...state.errorOnPage, ...action.data] };
      } else {
        return { ...state, errorOnPage: [...state.errorOnPage, action.data] };
      }
    }
    case 'annualCompensationDetailsUpdateState': {
      return { ...state, annualCompensationDetailsUpdateState: action.data };
    }
    case 'pageView': {
      return { ...state, pageView: action.data };
    }
    case 'showEffectiveDate':{
      return { ...state, showEffectiveDate: action.data };
    }
  }
  return { ...state };
}

const initialEmployeeOnboardingAnnualCompensationPageState: employeeOnboardingAnnualCompensationPageStateType =
  {
    showVpf: false,
    showLoanPerquisites: false,
    showPerquisites: false,
    showDeductibleBenefits: false,
    errorOnPage: [],
    annualCompensationDetailsUpdateState: false,
    pageView: VIEW_COMPENSATION_DETAILS,
    showEffectiveDate :false
  };
const EmployeeAnnualCompensation = () => {
  const [pageState, pageStateDispatch] = useReducer(
    employeeOnboardingAnnualCompensationPageStateReducer,
    initialEmployeeOnboardingAnnualCompensationPageState,
  );
  const [annualCompensationData, setAnnualCompensationData] =
    useState<EmployeeOnboardingAnnualCompensationDataType>(
      InitialEmployeeOnboardingAnnualCompensationData,
    );
  const [effectiveDate,setEffectiveDate] = useState(startOfMonth(new Date()));
  const [validationResponse, setValidationResponse] = useState<SalaryRevisionValidationResponse|null>(null);

  const urlParams = useParams<{ employeeId?: string }>();
  const employeeId = urlParams?.employeeId ? +urlParams?.employeeId : null;
  const history = useHistory();
  const approvalLinks = useApprovalLink(employeeId ?? undefined);


  const employeeAnnualCompensationQuery = useQuery(
    EMP_ANNUAL_COMPENSATION_DETAILS,
    () => {
      if (employeeId) {
        return api.employeeOnboarding.fetchEmployeeAnnualyCompensation(employeeId);
      }
    },
    {
      onSuccess: (apiResponse: EmployeeOnBoardingFetchAnnualCompensationResponse) => {
        setAnnualCompensationData({
          ...apiResponse.data,
          salaryModel: apiResponse.data.salaryModel
            ? apiResponse.data.salaryModel
            : InitialEmployeeOnboardingAnnualCompensationData.salaryModel,
          customAllowancesDetails : {
            countOfCustomAllowancesAllowed :apiResponse.data.customAllowancesDetails.countOfCustomAllowancesAllowed,
            allowances: Object.values(apiResponse.data.customAllowancesDetails.allowances)
          }
        });
      },
      onError: (error: AppError) => {
        window.scrollTo(0, 0);
        const response = getAppErrorDetails(error);
        pageStateDispatch({ type: pageStateActionEnum.errorOnPage, data: response.messageForUser });
        if (response.type === 403) {
          history.replace(routePaths.accessDenied);
        }
      },
    },
  );

  if (!employeeId) {
    return (
      <div className="text-center leading-12 mt-12">
        <p style={{ fontSize: '2em', margin: '10px auto 0' }}>No Employee Found.</p>
        <br />

        <PrimaryButton
          className={`${styles['employee-annual-compensation-continue-button']} mt-10`}
          children="Go Back"
          onClick={() => history.goBack()}
        />
      </div>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    employeeAnnualCompensationQuery.refetch();
  }, [employeeId]);

  if (
    employeeAnnualCompensationQuery.status === 'loading' ||
    employeeAnnualCompensationQuery.status === 'idle'
  ) {
    return (
      <div className="flex justify-center items-center h-full mt-10">
        <Spinner />
      </div>
    );
  }

  if (employeeAnnualCompensationQuery.status === 'error') {
    return (
      <Card className="mt-24 max-w-4xl mx-auto flex flex-col items-center">
        <ErrorMessage title="Sorry! An error has occured!">
          <p className="m-4">
            {employeeAnnualCompensationQuery.error?.message ||
              'Something went wrong, please contact support'}
          </p>
          <StandardSecondaryButton
            children="Back to Employee Details"
            onClick={() => {
              history.push(routePaths.emploteeDetails.root + employeeId);
              window.location.reload(); //this change is only for revamp in phase 2 - I will redirect them to new react View page; currently chaning to route is having issue as it tries to search a react page of that route and gives error.
            }}
          />
        </ErrorMessage>
      </Card>
    );
  }

  const getApprovalLink = (type: number) => {
    if(approvalLinks.isLoading) {
        return <Spinner size={14} />
    }

    if(!approvalLinks.links[type]) {
        return null;
    }

    return (
        <LinkButtonV2 onClick={() => history.push(approvalLinks.links[type])} className={styles['approval-link']}>
            Go to Approvals
        </LinkButtonV2>
    )
  }

  return (
    <>
      <Breadcrumb
          name="edit"
          urlMaps={{
            People: `/people`,
            [annualCompensationData.name]: `/employeeDetail?userId=${employeeId}`,
          }}
          className="pt-0 pb-0"
        />
      {annualCompensationData.areThereAnyArrearsPending && (
        <InfoAlert
          className="mt-10 mr-10 mb-10"
          children="There are outstanding arrears for this employee. Please note if the salary structure is modified, all outstanding arrears will be removed."
        />
      )}
      {approvalLinks.links[WorkflowTypesMap.SALARY_REVISION] && (
        <WarningAlertYellow
          className="mt-10 mr-10 mb-10"
          children={
          <div className='flex flex-row'><p>1 request already pending for salary revision</p>
          {getApprovalLink(WorkflowTypesMap.SALARY_REVISION)}
          </div>}
          />
      )}


      {annualCompensationData.isPayrollBeingFinalized && (
        <InfoAlert
          className="mt-10 mr-10 mb-10"
          children="Salary revisions are temporarily disabled as your payroll is being finalized. You can revise salaries once it's completed"
        />
      )}
      <div className={styles['employee-annual-compensation-main-page']}>
        {pageState.pageView === VIEW_COMPENSATION_DETAILS ? (
          <AnnualCompensationDetails
            pageStateDispatch={pageStateDispatch}
            employeeAnnualCompensationQuery={employeeAnnualCompensationQuery}
            annualCompensationData={annualCompensationData}
          />
        ) : pageState.pageView === VIEW_UPDATE_COMPENSATION ? (
          <AnnualCompensationModifyDetails
            employeeId={employeeId}
            annualCompensationData={annualCompensationData}
            setAnnualCompensationData={setAnnualCompensationData}
            pageState={pageState}
            pageStateDispatch={pageStateDispatch}
            effectiveDate={effectiveDate}
            setEffectiveDate={setEffectiveDate}
            setValidationResponse={setValidationResponse}
          />
        ) : pageState.pageView === VIEW_CONFIRM_UPDATED_COMPENSATION ? (
          <ConfirmAnnualCompensationModifiedDetails
            employeeId={employeeId}
            annualCompensationData={annualCompensationData}
            pageStateDispatch={pageStateDispatch}
            effectiveDate={effectiveDate}
            validationResponse={validationResponse}
          />
        ) : (
          <AnnualCompensationDetails
            pageStateDispatch={pageStateDispatch}
            employeeAnnualCompensationQuery={employeeAnnualCompensationQuery}
            annualCompensationData={annualCompensationData}
          />
        )}

        <EmployeeAnnualCompensationSuccessUpdateModal
          pageState={pageState}
          pageStateDispatch={pageStateDispatch}
          isWorkflowEnabled={annualCompensationData.workflowEnabled}
        />
      </div>
    </>
  );
};

export default EmployeeAnnualCompensation;