export enum InterestType {
  FLAT_INTEREST = 1,
  REDUCING_INTEREST = 2,
  REDUCING_EMI = 3
}

export enum LoanRecoverySteps{
  STEP_RECOVERY_TYPE_AND_AMOUNT = 1,
  STEP_CONFIRM = 2
}

export enum LoanRecoverySource{
  LOAN_SOURCE_VIA_XPAYROLL= 1,
  LOAN_SOURCE_OUTSIDE_XPAYROLL = 2
}

export const stepMap: { [key: number]: { name: string; step: number } } = {
  1: { name: 'Recovery Type & Amount', step: LoanRecoverySteps.STEP_RECOVERY_TYPE_AND_AMOUNT },
  2: { name: 'Verify & Confirm', step: LoanRecoverySteps.STEP_CONFIRM },
}

export enum LoanStatus {
  ACTIVE = 3,
  CLOSED = 5
}

export const InterestTypeStringMap: { [key: number]: string } = {
  [InterestType.FLAT_INTEREST]:
    "Flat Interest",
  [InterestType.REDUCING_INTEREST]:
    "Reducing Interest",
  [InterestType.REDUCING_EMI]:
    "Reducing EMI",
};

export enum LoanRecoveryStatus {
  PAID = 1,
  PENDING = 2,
  SKIPPED = 3 ,
  PARTIAL_RECOVERY = 4,
  FULL_RECOVERY = 5,
}

export enum SkipEmiOption {
  INCREASE_AMOUNT = 1,
  INCREASE_TENURE = 2
}

export enum LoanRecoveryOption {
SKIP_EMI = "skip-emi",
PARTIAL_RECOVERY = "partial-recovery",
FULL_RECOVERY = "full-recovery",
CHANGE_TENURE = "change-tenure"
}
