import { useTheme } from '@razorpay/blade/components';
import { useBreakpoint } from '@razorpay/blade/utils';

const useTextSize = () => {
  const { theme } = useTheme();
  const { matchedBreakpoint } = useBreakpoint({ breakpoints: theme.breakpoints });

  if (matchedBreakpoint === 's' || matchedBreakpoint === 'xs' || matchedBreakpoint === 'base') {
    return 'medium';
  }

  return 'large';
};

export { useTextSize };
