export const splitzExperiments = {
  selfServeP3M1: 'self-serve-onboarding-milestone1',
  mandatory2FA: 'mandatory-2FA',
  xpayroll2fa: 'xpayroll-2fa',
  xpayroll2faPhase2: 'xpayroll-2fa-phase2',
  xpayroll2faPhase3: 'xpayroll-2fa-phase3',
  bladeMigrationPhase1: 'blade-migration-phase1',
} as const;

export const SPLITZ_EXPERIMENTS_QUERY_CACHE_KEY = 'SPLITZ_EXPERIMENTS';
