import { TabItemProps } from "@razorpay/blade/components";
import { useHistory } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

export const WINDOW_TYPE = {
    'PROOF': 'proof',
    'DECLARATION': 'declaration',
} as const;

export const CUSTOM_WINDOW_TYPE = {
    'PROOF': 'proof_upload_window',
    'DECLARATION': 'declaration_window',
} as const;

export const WINDOW_TARGET = {
    WINDOW_TARGET_ORGANIZATION: 1,
    WINDOW_TARGET_CUSTOM_EMPLOYEE: 2,
    WINDOW_TARGET_FNF: 3,
    WINDOW_TARGET_NEW_EMPLOYEE: 4
} as const;

export const TAB_VALUES = {
    GENERAL: 'general',
    DECLARATION_WINDOW: 'declaration_window',
    PROOF_UPLOAD_WINDOW: 'proof_upload_window',
    CUSTOM_WINDOW: 'custom_window'
}

export type WindowType = 'proof' | 'declaration';
export type CustomWindowType = 'proof_upload_window' | 'declaration_window';
export type WindowTarget = keyof typeof WINDOW_TARGET;
export type TabValues = keyof typeof TAB_VALUES;

export const CustomWindowTypeMap: { [key: string]: WindowType } = {
    [CUSTOM_WINDOW_TYPE.PROOF]: WINDOW_TYPE.PROOF,
    [CUSTOM_WINDOW_TYPE.DECLARATION]: WINDOW_TYPE.DECLARATION
};

export const tabItems = (history: ReturnType<typeof useHistory>): TabItemProps[] => {

    return [
        {
            children: 'General',
            value: TAB_VALUES.GENERAL,
            onClick: () => history.push(routePaths.taxDeclarationAndProofSettings.settingsGeneral),
        },
        {
            children: 'Declaration Window',
            value: TAB_VALUES.DECLARATION_WINDOW,
            onClick: () => history.push(routePaths.taxDeclarationAndProofSettings.settingsDeclaration),
        },
        {
            children: 'Proof Upload Window',
            value: TAB_VALUES.PROOF_UPLOAD_WINDOW,
            onClick: () => history.push(routePaths.taxDeclarationAndProofSettings.settingsProofUpload),
        },
        {
            children: 'Custom Window',
            value: TAB_VALUES.CUSTOM_WINDOW,
            onClick: () => history.push(routePaths.taxDeclarationAndProofSettings.settingsCustomWindow),
        },
    ];
}

export const getTabValue = (pathname: string): string => {
    switch (pathname) {
        case routePaths.taxDeclarationAndProofSettings.settings:
        case routePaths.taxDeclarationAndProofSettings.settingsGeneral:
            return TAB_VALUES.GENERAL;
        case routePaths.taxDeclarationAndProofSettings.settingsDeclaration:
            return TAB_VALUES.DECLARATION_WINDOW;
        case routePaths.taxDeclarationAndProofSettings.settingsProofUpload:
            return TAB_VALUES.PROOF_UPLOAD_WINDOW;
        case routePaths.taxDeclarationAndProofSettings.settingsCustomWindow:
            return TAB_VALUES.CUSTOM_WINDOW;
        default:
            return TAB_VALUES.GENERAL;
    }
};