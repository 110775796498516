import React, { ReactElement } from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { StandardTable } from 'components/ui/Table';
import { arrearsBreakdownType } from './SalaryRevisionPreviewTable';

import styles from '../index.module.scss';

export function SalaryRevisionArrearBreakdownModal({
  arrearsDetails,
}: {
  arrearsDetails: arrearsBreakdownType[];
}): ReactElement {
  return (
    <Modal>
      <div style={{ minWidth: '31.25rem', padding: '1.25rem' }}>
        <h1 className='mb-10'>Arrears Details</h1>
        <StandardTable className="w-full flex-1 py-2 mt-1 mb-1">
          <tbody>
            <tr>
              <th>Component</th>
              <th>Amount</th>
            </tr>
            {arrearsDetails.map((item: arrearsBreakdownType, index) => {
              return (
                <tr className={`${styles['table-border']}`} key={index}>
                  <td className={`${styles['table-border']}`} style={{ width: '50%' }}>
                    {item.name}
                  </td>
                  <td className={`${styles['table-border']}`}>{item.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </StandardTable>
      </div>
    </Modal>
  );
}
