import { Box, BoxRefType, TextInput } from '@razorpay/blade/components';
import React, { useRef } from 'react';
import { ChatContextProvider, ChatContextType, useChatContext } from './ChatContext';
import { WelcomeTitle } from './components/WelcomeTitle';
import { ChatRenderer } from './ChatRenderer';
import { UserTextInput } from './components/UserTextInput';
import styled from 'styled-components';
import { Header } from './components/Header';

const PayrollAiAssistantContainer = styled.div(
  ({ theme }) => `
  display: flex;
  position: relative;
  background: ${theme.colors.surface.background.gray.subtle};
  padding: 52px ${theme.spacing[4]}px 0 ${theme.spacing[4]}px;
  height: 100svh;
  overflow: hidden;

  @media screen and (min-width: ${theme.breakpoints.m}px) {
    padding: 64px 70px 0 70px;
  }
`,
);

const ChatBoxContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  background-color: rgba(209, 210, 218, 0.03);
  border-width: ${theme.border.width.thin}px;
  border-radius: ${theme.border.radius.large}px;
  border-color: rgba(129, 245, 230, 0.07);
  border-style: solid;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  box-shadow: rgba(0,0,0,0.3) 3px 4px 40px;

  @media (min-width: ${theme.breakpoints.m}px) {
    max-width: 1440px
  }
`,
);

const ChatScrollContainer = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing[7]}px ${theme.spacing[4]}px 0px ${theme.spacing[4]}px;
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;

  @media screen and (min-width: ${theme.breakpoints.m}px) {
    padding: ${theme.spacing[10]}px ${theme.spacing[8]}px 0px ${theme.spacing[8]}px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
`,
);

const AiAssistantBgGradient = styled.div(
  () => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: radial-gradient(circle, rgb(38 61 75 / 53%), rgb(17 46 82), rgb(11, 40, 60), rgb(8, 13, 41)) 0% 0% / 400% 400%;
  filter: blur(195.85000610351562px);
	animation: gradient 35s ease infinite;

  @keyframes gradient {
    	0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
  }
`,
);

const PayrollAIAssistant = () => {
  return (
    <PayrollAiAssistantContainer>
      <Header />
      <AiAssistantBgGradient />
      <ChatContextProvider>
        <ChatBoxContainer>
          <ChatScrollContainer>
            <WelcomeTitle />
            <ChatRenderer />
          </ChatScrollContainer>
          <UserTextInput />
        </ChatBoxContainer>
      </ChatContextProvider>
    </PayrollAiAssistantContainer>
  );
};

export { PayrollAIAssistant };
