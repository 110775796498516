import { Box, TextInput } from '@razorpay/blade/components';
import { keyCodes } from 'constants/keyboard';
import React, { useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { debounceV2 } from 'utils/Debounce';

interface BladeTypeAheadProps<T> {
  label: string;
  searchFn: (searchText: string) => Promise<T[]>;
  resultItemView: (props: { data: T }) => JSX.Element;
  onSearchItemClick: (data: T) => void;
  placeholder?: string;
  focusOnMount?: boolean;
}

const SearchResultItemContainer = styled.div<{ inFocus?: boolean }>(
  ({ theme, inFocus }) => `
    padding: ${theme.spacing[3]}px;
    border-radius: ${theme.border.radius.medium}px;
    flex-grow: 1;
    cursor: pointer;
    background-color: ${
      inFocus ? theme.colors.surface.background.primary.subtle : theme.colors.surface.background.gray.intense
    };
    
    &:hover {
        background-color: ${theme.colors.surface.background.primary.subtle};
    }
`,
);

const BladeTypeAhead = <T,>({
  label,
  placeholder,
  searchFn,
  resultItemView,
  onSearchItemClick,
  focusOnMount,
}: BladeTypeAheadProps<T>) => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchErrorText, setSearchErrorText] = useState('');
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [indexInFocus, setIndexInFocus] = useState(0);
  const typeAheadRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const isOpen = searchResults.length > 0;

  const debouncedSearch = useCallback(
    debounceV2(async (searchText: string) => {
      try {
        setSearchErrorText('');
        setIsSearching(true);
        setNoResultsFound(false);
        closeDropdown();

        const results = await searchFn(searchText);
        setSearchResults(results);
        if (results.length === 0) {
          setNoResultsFound(true);
        }
      } catch (e) {
        closeDropdown();
        setSearchErrorText('Unable to fetch results, please try again.');
      } finally {
        setIsSearching(false);
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    if (searchText === '') {
      closeDropdown();
      setSearchErrorText('');
      setNoResultsFound(false);

      debouncedSearch.cancel();
      return;
    }

    debouncedSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    const handleOutsideClick = (e: Event) => {
      if (
        typeAheadRef.current &&
        e.target instanceof HTMLElement &&
        !typeAheadRef.current.contains(e.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (focusOnMount) {
      textInputRef.current?.focus();
    }
  }, [focusOnMount]);

  const handleSearchTextChange = ({ value }: { value?: string }) => {
    setSearchText(value ?? '');
  };

  const handleClearButtonClick = () => {
    setSearchText('');
  };

  const handleSearchItemClick = (data: T) => {
    setSearchText('');
    onSearchItemClick(data);

    closeDropdown();
  };

  const goDownList = () => {
    setIndexInFocus((indexInFocus) => {
      if (indexInFocus < searchResults.length - 1) {
        return indexInFocus + 1;
      }

      return 0;
    });
  };

  const goUpList = () => {
    setIndexInFocus((indexInFocus) => {
      if (indexInFocus > 0) {
        return indexInFocus - 1;
      }

      return searchResults.length - 1;
    });
  };

  const closeDropdown = () => {
    setSearchResults([]);
    setIndexInFocus(0);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case keyCodes.ARROW_DOWN: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          goDownList();
        }
        break;
      }
      case keyCodes.ARROW_UP: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          goUpList();
        } else {
          closeDropdown();
        }
        break;
      }
      case keyCodes.ENTER: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen && indexInFocus >= 0) {
          const selectedValue = searchResults[indexInFocus];
          handleSearchItemClick(selectedValue);
        }
        break;
      }

      case keyCodes.ESCAPE:
      case keyCodes.ESCAPE_IE:
      case keyCodes.TAB: {
        e.preventDefault();
        e.stopPropagation();

        if (isOpen) {
          closeDropdown();
        }

        break;
      }

      default:
        break;
    }
  };

  return (
    <Box
      ref={typeAheadRef}
      display="flex"
      flexDirection="column"
      gap="spacing.3"
      position="relative">
      {/* Blade does not support onKeyDown handler in TextInput component, hence using dummy parent div for capturing keyboard events */}
      <div onKeyDown={handleKeyDown}>
        <TextInput
          type="search"
          showClearButton
          onClearButtonClick={handleClearButtonClick}
          label={label}
          placeholder={placeholder}
          value={searchText}
          onChange={handleSearchTextChange}
          isLoading={isSearching}
          validationState={searchErrorText ? 'error' : 'none'}
          errorText={searchErrorText}
          helpText={noResultsFound ? 'No results found!' : ''}
          ref={textInputRef}
        />
      </div>
      {searchResults.length > 0 && (
        <Box
          position="absolute"
          top="120%"
          width="100%"
          zIndex="1"
          display="flex"
          flexGrow={1}
          flexDirection="column"
          borderWidth="thin"
          borderRadius="medium"
          borderColor="surface.border.gray.subtle"
          padding="spacing.3"
          backgroundColor="surface.background.gray.intense"
          overflowY="scroll">
          {searchResults.map((result, index) => (
            <SearchResultItemContainer
              key={index}
              onClick={() => {
                handleSearchItemClick(result);
              }}
              inFocus={index === indexInFocus}>
              <Box tabIndex={0}>{resultItemView({ data: result })}</Box>
            </SearchResultItemContainer>
          ))}
        </Box>
      )}
    </Box>
  );
};

export { BladeTypeAhead };
