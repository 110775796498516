import React from 'react';
import {
    Box,
    Heading,
    Text,
    SelectInput,
    Dropdown,
    DropdownOverlay,
    ActionList,
    ActionListItem,
    StarIcon,
    Badge,
    Alert
} from '@razorpay/blade/components';
import { CustomDeclarationType, DeclarationErrors, DeclarationWindowType, GenericDeclaration } from './types';
import { uniqueId } from '../../../utils/Numbers';
import { MonthDeclarationSelector } from './DeclarationSettings/MonthDeclarationSelector';
import { CustomDeclarationSelector } from './DeclarationSettings/CustomDeclarationSelector';
import { BladeProPill, BladeProPillActionListItem } from '../BladeProPill/index';


interface DeclarationProps {
    heading?: string;
    description?: string;
    declarationWindow: GenericDeclaration;
    onChange: (value: GenericDeclaration) => void;
    isLoading?: boolean;
    errors?: DeclarationErrors;
    disableCustomMonthSelector?: boolean;
    disableCustomYearSelector?: boolean;
    disableMonthlyRange?: boolean;
    customMonthOffset?: number;
    isProofUploadWindowSettings?: boolean;
    hasProPlan?: boolean;
}

const DeclarationWindow = ({
    declarationWindow,
    onChange,
    isLoading,
    errors = {},
    disableCustomYearSelector = false,
    disableCustomMonthSelector = false,
    disableMonthlyRange = false,
    customMonthOffset = 0,
    heading = '🪟 Declaration Window',
    description,
    isProofUploadWindowSettings,
    hasProPlan
}: DeclarationProps) => {

    const showProPill = (hasProPlan !== undefined && !hasProPlan);

    const setDeclarationData = (fn: (previous: GenericDeclaration) => GenericDeclaration) => {
        onChange(fn(declarationWindow));
    };

    const handleDeclarationWindowSelection = (windowType: DeclarationWindowType) => {
        if (windowType === 'custom') {
            setDeclarationData((p) => {
                const newState = { ...p, type: windowType } as CustomDeclarationType;
                const customRanges = newState.dates || {};
                if (Object.keys(customRanges).length === 0) {
                    newState.dates = { [uniqueId()]: { endDate: null, startDate: null } };
                }
                return newState as GenericDeclaration;
            });
        } else {
            setDeclarationData((p) => ({ ...p, type: windowType } as GenericDeclaration));
        }
    };

    const handleMonthlyDeclarationChange = (value: string, isStart: boolean) => {
        setDeclarationData(
            (p) => ({ ...p, [isStart ? 'startDate' : 'endDate']: value } as GenericDeclaration),
        );
    };

    const handleCustomDeclarationChange = (id: string, value: Date | null, isStart: boolean) => {
        setDeclarationData((p) => {
            const data = { ...p };
            if (data.type === 'custom') {
                data.dates = {
                    ...data.dates,
                    [id]: { ...data.dates[id], [isStart ? 'startDate' : 'endDate']: value },
                };
            }
            return data as GenericDeclaration;
        });
    };

    const handleAddClick = () => {
        setDeclarationData((p) => {
            const data = { ...p };
            if (data.type === 'custom') {
                data.dates = {
                    ...data.dates,
                    [uniqueId()]: { startDate: null, endDate: null },
                };
            }
            return data as GenericDeclaration;
        });
    };

    const handleDeleteClick = (id: string) => {
        setDeclarationData((p) => {
            const data = { ...p };
            if (data.type === 'custom') {
                delete data.dates[id];
            }
            return data as GenericDeclaration;
        });
    };


    return (
        (<Box
            display="flex"
            flexDirection="column"
            gap="spacing.3"
            marginTop={'56px'}
        >
            <Box
                display="flex"
                flexDirection="row"
                gap="spacing.3"
            >
                <Heading size="small">
                    {heading}
                </Heading>
                {showProPill && BladeProPill}
            </Box>
            {description && <Text size="small">
                {description}
            </Text>}
            <Box paddingTop={'spacing.7'}>
                <Box width={'60%'}>
                    <Dropdown selectionType="single">
                        <SelectInput
                            isDisabled={isLoading}
                            isRequired
                            value={showProPill ? 'always' : declarationWindow.type}
                            label={`Select employee declaration window period`}
                            placeholder="Select"
                            name="windowPeriod"
                            onChange={({ values }) =>
                                handleDeclarationWindowSelection(values[0] as DeclarationWindowType)
                            }
                        />
                        <DropdownOverlay>
                            <ActionList>
                                <ActionListItem key={'always'} title={'Always open'} value={'always'} />
                                {!disableMonthlyRange &&
                                    <ActionListItem key={'monthly'}
                                        title={'Every month at a certain period'}
                                        value={'monthly'}
                                        isDisabled={showProPill}
                                        titleSuffix={showProPill ? BladeProPillActionListItem : undefined} />
                                }
                                <ActionListItem key={'custom'}
                                    title={'Custom range'}
                                    value={'custom'}
                                    isDisabled={showProPill}
                                    titleSuffix={showProPill ? BladeProPillActionListItem : undefined}
                                />
                            </ActionList>
                        </DropdownOverlay>
                    </Dropdown>
                </Box>
                {declarationWindow.type === 'monthly' && !disableMonthlyRange && (
                    <MonthDeclarationSelector
                        isLoading={!!isLoading}
                        endDate={declarationWindow.endDate}
                        startDate={declarationWindow.startDate}
                        onChange={handleMonthlyDeclarationChange}
                        errors={errors}
                    />
                )}
                {declarationWindow.type === 'custom' && (
                    <CustomDeclarationSelector
                        isLoading={!!isLoading}
                        dates={declarationWindow.dates}
                        onChange={handleCustomDeclarationChange}
                        onAdd={handleAddClick}
                        onDelete={handleDeleteClick}
                        errors={errors}
                        disableYearSelector={disableCustomYearSelector}
                        disableMonthSelector={disableCustomMonthSelector}
                        monthOffset={customMonthOffset}
                        isProofUploadWindowSettings={isProofUploadWindowSettings}
                    />
                )}

                {declarationWindow.type === 'always' && (
                    <Alert
                    isDismissible={false}
                    marginTop="spacing.7"
                    description={`💡️ ${ isProofUploadWindowSettings ? 'Proof upload' : 'Declaration' } window will be open throughout the year for the employees. Employees can edit their ${ isProofUploadWindowSettings ? 'proofs' : 'declaration'} anytime.`}
                    color="information"
                    />
                )}
            </Box>
        </Box>)
    );
}

export default DeclarationWindow;