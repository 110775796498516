import { useMutation, useQueryClient } from 'react-query';
import { StandardButton } from 'components/ui/Button';
import React, { useState } from 'react';
import Card from 'components/Settings/ZohoSettings/components/Card';
import { getMissingFields, getTransformedAvailableFields } from '../ZohoPreviewFields/utils';
import { WarningAlert, WarningAlertYellow } from 'components/ui/Alert';
import { AppError } from 'utils/AppError';
import { CheckBox } from 'components/ui/Form/Input';
import cx from 'classnames';
import api from 'api';
import { ZOHO_ACTIVATION_STATUS_QUERY } from 'components/Settings/ZohoSettings/queries';
import { AvailableField, SyncedField, TransformedAvailableField } from '../types';
import Errors from 'components/ui/Form/Errors';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { useModal } from 'components/ui/Modals';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import { updateSyncedFields, getUncheckedFields } from './utils';
import { trackSegment } from 'utils/segment';

import styles from './index.module.scss';

interface Props {
  onPreview: () => void;
  availableFields: AvailableField[];
  allFields: { field: string; name: string }[];
  syncedFields: SyncedField[];
}

type CheckedFieldsState = { [k: string]: TransformedAvailableField };

function ZohoEditFields({ onPreview, availableFields, allFields, syncedFields }: Props) {
  const [checkedFields, setCheckedFields] = useState<CheckedFieldsState>(
    getTransformedAvailableFields(syncedFields, availableFields),
  );
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const updateFieldsMutation = useMutation(
    ({ fields, checkedFields }: { fields: string[]; checkedFields: CheckedFieldsState }) =>
      api.zoho.updateFields(fields),
    {
      onError: (error: typeof AppError) => {},
      onSuccess: (data, variables) => {
        queryClient.setQueryData<ZohoSettingsContract | undefined>(
          ZOHO_ACTIVATION_STATUS_QUERY,
          (oldData) => {
            if (oldData) {
              return updateSyncedFields(oldData, variables.checkedFields);
            }
          },
        );
        queryClient.invalidateQueries(ZOHO_ACTIVATION_STATUS_QUERY);
        onPreview();
      },
    },
  );

  const toggleField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedFields((state) => {
      const selectedField = e.target.value;

      // extra check to prevent this security vulerability
      // https://github.com/eslint-community/eslint-plugin-security/blob/main/docs/the-dangers-of-square-bracket-notation.md
      // its not really required on FE apps, but semgrep reports it
      if (!!allFields.find((field) => field.field === selectedField)) {
        const currentFieldOption = state[selectedField];
        return {
          ...state,
          [selectedField]: {
            ...currentFieldOption,
            isSelectedForSync: e.target.checked,
          },
        };
      }

      return state;
    });
  };

  const handleSave = () => {
    trackSegment('zoho.edit_fields.save.click');
    const fieldsToSync = Object.keys(checkedFields).filter((field) => {
      return !!checkedFields[field].isSelectedForSync;
    });
    updateFieldsMutation.mutate({ fields: fieldsToSync, checkedFields });
  };

  const unavailableFields = getMissingFields(allFields, availableFields);
  const mandatoryFields = availableFields
    .filter((field) => field.isMandatoryXPayrollFieldToSync)
    .map((field) => field.xpayrollFieldName)
    .join(', ');

  const showConfirmationModal = () => {
    const uncheckedFields = getUncheckedFields(checkedFields);
    const fieldsThatWontBeSynced = [...unavailableFields, ...uncheckedFields];

    if (fieldsThatWontBeSynced.length > 0) {
      openModal(
        <ConfirmModal confirmText="Confirm" cancelText="Cancel" onConfirm={handleSave}>
          <div className="flex flex-col max-w-4xl">
            <div className={'text-3xl font-bold text-white-o-80 mb-8 mt-4'}>
              The following employee information will not sync from Zoho People to RazorpayX Payroll
            </div>
            <div className="grid grid-cols-3 mb-20 gap-y-10 gap-x-4 mt-10">
              {fieldsThatWontBeSynced.map((field) => (
                <div
                  className="italic border-solid border-white-o-20 py-3 px-4 border rounded"
                  key={field.field}>
                  {field.name}
                </div>
              ))}
            </div>
          </div>
        </ConfirmModal>,
      );
    } else {
      handleSave();
    }
  };

  return (
    <div className="mt-12">
      {updateFieldsMutation.status === 'error' && (
        <WarningAlert className="my-6">
          <Errors errorResponse={updateFieldsMutation.error} />
        </WarningAlert>
      )}
      <h3 className={styles['title']}>Edit employee details</h3>
      <Card className="mt-12">
        <h3 className="font-bold text-white-o-70">
          Unchecked fields won't be synced from Zoho People to RazorpayX Payroll
        </h3>
        <div className="grid grid-cols-1 gap-x-3 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-10 mt-10">
          {Object.keys(checkedFields).map((field) => (
            <CheckBox
              id={`zoho-field-${checkedFields[field].type}`}
              key={checkedFields[field].type}
              value={checkedFields[field].type}
              label={checkedFields[field].name}
              labelClassName="text-white-o-70"
              checked={checkedFields[field].isMandatory || !!checkedFields[field].isSelectedForSync}
              disabled={
                checkedFields[field].isMandatory || updateFieldsMutation.status === 'loading'
              }
              onChange={toggleField}
            />
          ))}
        </div>
        <p className={cx(styles['disclaimer-text'], 'text-white-o-50 mt-16')}>
          <span className="font-bold italic text-white-o-50">{mandatoryFields}</span> are mandatory
          fields which cannot be disabled.
        </p>
      </Card>
      {unavailableFields.length > 0 && (
        <WarningAlertYellow className="mt-10">
          <span className="text-white-o-50">
            The following fields are disabled in Zoho People and won't be synced with RazorpayX
            Payroll:{' '}
          </span>
          <span className="text-white-o-70 font-bold">
            {unavailableFields.map((field) => field.name)}.
          </span>
        </WarningAlertYellow>
      )}
      <StandardButton
        className="mt-24 mr-0"
        onClick={showConfirmationModal}
        isPending={updateFieldsMutation.status === 'loading'}>
        Save and continue
      </StandardButton>
    </div>
  );
}

export default ZohoEditFields;
