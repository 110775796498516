import { KnitApps } from 'components/Knit/constants';
import DarwinboxIntegrationComplete from '../../../../assets/darwinbox/IntegrationComplete.png';
import HibobIntegrationComplete from '../../../../assets/hibob/hibobIntegrationComplete.png';
import WorqIntegrationComplete from '../../../../assets/worq/worqIntegrationComplete.png';
import WorklineIntegrationComplete from '../../../../assets/workline/worklineIntegrationComplete.png';

export const hrmsAppManageContent = {
  [KnitApps.darwinbox]: {
    integrationCompleteLogo: DarwinboxIntegrationComplete,
  },
  [KnitApps.hibob]: {
    integrationCompleteLogo: HibobIntegrationComplete,
  },
  [KnitApps.worq]: {
    integrationCompleteLogo: WorqIntegrationComplete,
  },
  [KnitApps.workline]: {
    integrationCompleteLogo: WorklineIntegrationComplete,
  },
};
