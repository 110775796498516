
import React from "react";
import style from './pagination.module.css';

export enum paginationStateTypeEnum {
	rowsPerPage = 'rowsPerPage',
	perPage = 'perPage',
    currentPageUrl = 'currentPageUrl',
    totalPages = 'totalPages',
    previousPageUrl = 'previousPageUrl',
    nextPageUrl = 'nextPageUrl',
    allPageLinkCollection = 'allPageLinkCollection',
	InitialStateUpdate = 'InitialStateUpdate'
}

export type allPageLinkCollectionType = {
	url :string,
	label: string,
	active: boolean
} 

export type paginationStateType = {
    rowsPerPage :  Array<number>,
    perPage : number,
    currentPageUrl : string,
	currentPage : number,
    totalPages? : number | null,
    previousPageUrl? :string | null,
    nextPageUrl? :string | null,
    allPageLinkCollection? : {
		url?: string | null;
		label?: string | null;
		active?: boolean | null;
	}[]
};

export type paginationStateActionType ={
    type: paginationStateTypeEnum,
    data: paginationStateType
}


const initialStatePagination:paginationStateType = {
    'rowsPerPage' : [10,25,50],
	'perPage' : 10,
    'currentPageUrl' : '?page=1',
	'currentPage' : 1,
    'totalPages' : 1,
    'previousPageUrl' : null,
    'nextPageUrl' : null,
    'allPageLinkCollection': [ {
		"url": "/?page=1",
		"label": "1",
		"active": true
	}],
  }

const paginationReducer = (state :paginationStateType ,action :paginationStateActionType)=>{
	switch (action.type) {
		case paginationStateTypeEnum.rowsPerPage: {
			return { ...state, 
				rowsPerPage: action.data.rowsPerPage,
				currentPageUrl: action.data.currentPageUrl  };
		}
		case paginationStateTypeEnum.perPage: {
			return { ...state, perPage: action.data.perPage , 
				currentPageUrl: action.data.currentPageUrl,
				currentPage: 1   };
		}
		case paginationStateTypeEnum.totalPages: {
			return { ...state, totalPages: action.data.totalPages };
		}
		case paginationStateTypeEnum.previousPageUrl: {
			return { ...state, previousPageUrl: action.data.previousPageUrl };
		}
		case paginationStateTypeEnum.nextPageUrl: {
			return { ...state, nextPageUrl: action.data.nextPageUrl };
		}
		case paginationStateTypeEnum.currentPageUrl: {
			return { ...state, currentPageUrl: action.data.currentPageUrl, currentPage: Number(action.data.currentPageUrl.split("=").slice(-1)[0])};
		}
		case paginationStateTypeEnum.allPageLinkCollection: {
			return { ...state, allPageLinkCollection: action.data.allPageLinkCollection };
		}
		case paginationStateTypeEnum.InitialStateUpdate:{
			return { ...state, 
					allPageLinkCollection: action.data.allPageLinkCollection,
					previousPageUrl       : action.data.previousPageUrl,
					nextPageUrl           : action.data.nextPageUrl,
					totalPages            : action.data.totalPages };
		}
	}
	return {...state};
};


interface Props {
	paginationState: paginationStateType,
	paginationDispatch: React.Dispatch<paginationStateActionType>
}

const Pagination:React.FC<Props> = ({paginationState,paginationDispatch}) =>
{
	const onChangePageNumber = (event:any)=>{
		paginationDispatch({ type: paginationStateTypeEnum.currentPageUrl, data: {...paginationState, currentPageUrl :event?.target?.value} });
	}

	const onChangeRowsPerPage = (event:any)=>{
		paginationDispatch({ type: paginationStateTypeEnum.perPage, data: {...paginationState ,perPage :event?.target?.value,currentPageUrl :'?page=1'} });
	}
	
	return (
			<div className={`${style['pagination-form']}`}>
				<div className={`${style['pagination-page-select-container']} flex`}>
					<select name="rows" className={`${style['pagination-page-select']}`} onChange={(event)=>onChangeRowsPerPage(event)}>
						{
							paginationState?.rowsPerPage?.map((item:number,index:number) =>
							(
								<option value={item} selected={item === paginationState?.perPage} key={index}>{item}</option>
							))
						}
					</select>
					<label className="pagination-form-label" htmlFor="rows-select">rows/page</label>
				</div>
				<div className="flex items-center">
					<div className="flex">
						<div className={`${style['pagination-page-select-container']} flex`}>
							<label className="pagination-form-label" htmlFor="page-select" style={{textAlign:'right',flexBasis:0}}>Page</label>
							<select name="page" id="pagination-page-select" onChange={(event)=>onChangePageNumber(event)}  className={`${style['pagination-page-select']}`}>  
							{
								paginationState?.allPageLinkCollection?.map((item:any,index:any) =>
								(
									item.url && !isNaN(item?.label) && <option value={item.url} selected={item?.active} key={index}>{item.label}</option>
								))
							}  
							</select>
							<label className="pagination-form-label" htmlFor="page-select" style={{flexBasis:'35%'}}> of {paginationState?.totalPages} </label>
						</div>
					</div>
					<div className="flex ">
						<button className={`${style['pagination-arrow-btn']} `} 
							style={{color:(paginationState?.previousPageUrl ? "" : "#34407b")}}  
							onClick={()=>{
								if(paginationState?.previousPageUrl) paginationDispatch({ type: paginationStateTypeEnum.currentPageUrl, data: {...paginationState, currentPageUrl :paginationState?.previousPageUrl} });
							}}
							>
								&#8592;
						</button>
						<button className={`${style['pagination-arrow-btn']}`}  
						style={{color:(paginationState?.nextPageUrl ? "" : "#34407b")}}  
						onClick={()=>{
							if(paginationState?.nextPageUrl) paginationDispatch({ type: paginationStateTypeEnum.currentPageUrl, data: {...paginationState, currentPageUrl :paginationState?.nextPageUrl} });
						}}
						>
							&#8594;
						</button>
					</div>
				</div>
			</div>
	)
}

export default Pagination;
export const Paginator = {
	Pagination,
	initialStatePagination,
	paginationReducer
}