import React from 'react';
import { useMutation, useQueryClient } from "react-query";

import Modal from 'components/ui/Modals/components/Modal';
import api from 'api';
import { FETCH_RUNPAYROLL_DATA } from '../utils/queries';
import { useModal } from 'components/ui/Modals';
import { StandardButton } from 'components/ui/Button';
import { SetErrorMessageType } from '../contexts/RunPayrollErrorContext';

import styles from './index.module.scss';

const UnfinalizePayroll = ({
  payrollMonth,
  onError,
}: { payrollMonth: string } & SetErrorMessageType) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const unfinalize = useMutation(
    (payrollMonth: string) => {
      return api.runPayroll.unfinalizePayroll(payrollMonth);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_RUNPAYROLL_DATA);
        closeModal();
      },
      onError: (error) => {
        onError && onError({ message: (error as Error).message });
        closeModal();
      }
    },
  );

  const onUnfinalizePayroll = () => {
    unfinalize.mutate(payrollMonth);
  };

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Make changes to payroll
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          Your payroll has already been finalized. If you make changes now, then finalization will
          need to be done again.
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardButton onClick={onUnfinalizePayroll} isPending={unfinalize.isLoading}>
            MAKE CHANGES
          </StandardButton>
        </div>
      </div>
    </Modal>
  );
};

export default UnfinalizePayroll;
