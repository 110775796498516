import React from 'react';
import { Box, Spinner, Text } from '@razorpay/blade/components';
import { StyledActionSummaryWrapper } from '../styles';

export const ActionsListLoadingState = () => {
  return (
    <StyledActionSummaryWrapper>
      <Box display="flex" flexDirection="column" alignItems="center" gap="spacing.5">
        <Spinner size="xlarge" color="white" accessibilityLabel="laoding action items" />
        <Text size="small" color="surface.text.gray.muted">
          Please wait a moment while we fetch your data
        </Text>
      </Box>
    </StyledActionSummaryWrapper>
  );
};
