import { Alert, Box, Spinner, useToast } from '@razorpay/blade/components';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import { DeductFrom } from 'components/RunPayroll/utils/types';
import PageLayout from 'components/ui/Layout/PageLayout';
import format from 'date-fns/format';
import { useSearchParams } from 'hooks/useSearchParams';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { dateFormats } from 'utils/Dates';
import useReduxSelector from 'utils/useReduxSelector';
import FnfInputs from './components/FnfInputs';
import FnfSideBar from './components/FnfSideBar';
import { GET_FNF_DATA, GET_FNF_DISMISSAL_DATE, UPDATE_FNF_DATA } from './queries';
import useIsHoldSalaryPayComplianceEnabled from './useIsHoldSalaryPayComplianceEnabled';
import {
  makeAdditionOfMinimumLenth,
  makeNonLopDeductionsOfMinimumLength,
  prepareFnfDataForUpdate,
} from './utils';
import api from 'api';

const FnfPage = () => {
  const [fnfData, setFnfData] = useState<null | GetFnfResponseSchemaContract>(null);
  const { urlParams } = useSearchParams();
  const peopleId = urlParams.get('userId');
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false);
  const [dismissalDateInput, setDismissalDateInput] = useState<null | Date>();
  const [updateErrorString, setUpdateErrorString] = useState<string | null>(null);
  const history = useHistory();
  const { state } = useLocation<{ from?: { pathname?: string; search?: string } }>();
  const loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const orgId = loggedInUser.data?.currentOrganization?.id;
  const isFnfProcessed = fnfData?.is_fnf_processed ?? false;
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();
  const toast = useToast();

  const { isHoldSalaryPayComplianceEnabled } = useIsHoldSalaryPayComplianceEnabled();

  const getDismissalDateQuery = useQuery(
    [GET_FNF_DISMISSAL_DATE, peopleId],
    () => {
      if (!peopleId) {
        return Promise.reject('Give people id');
      }
      if (isM2S1Enabled) {
        return api.salaryComponents.queries.getDismissalDate({ userId: peopleId });
      } else {
        return api.fnf.getDismissalDate(parseInt(peopleId));
      }
    },
    {
      onSuccess: (data) => {
        let dismissalDateForInput = new Date();
        if (data.deactivate_date) {
          dismissalDateForInput = new Date(data.deactivate_date);
        } else if (data.resignation_date) {
          dismissalDateForInput = new Date(data.resignation_date);
        }
        setDismissalDateInput(dismissalDateForInput);
      },
      onError(err: AppError) {},
    },
  );

  const getFnfData = useQuery({
    enabled: !!peopleId && !!dismissalDateInput,
    queryKey: [
      GET_FNF_DATA,
      peopleId,
      dismissalDateInput,
      isHoldSalaryPayComplianceEnabled,
      isFnfProcessed,
    ],
    queryFn: () => {
      if (peopleId && dismissalDateInput) {
        const dismissalDateParam = format(new Date(dismissalDateInput), dateFormats.yearMonthDate);
        if (isM2S1Enabled) {
          return api.salaryComponents.queries.getFnfDetails({
            userId: peopleId,
            deactivateDate: dismissalDateParam,
          });
        } else {
          return api.fnf.getFnfData(parseInt(peopleId), dismissalDateParam);
        }
      }
      return Promise.reject('People id and dismissal date is required');
    },
    onSuccess: (data) => {
      data.additions = makeAdditionOfMinimumLenth(data.additions);
      data.deductions.non_lop_deductions = makeNonLopDeductionsOfMinimumLength(
        data.deductions.non_lop_deductions,
        isHoldSalaryPayComplianceEnabled && isFnfProcessed
          ? DeductFrom.NET_PAY
          : DeductFrom.GROSS_PAY,
        isHoldSalaryPayComplianceEnabled && isFnfProcessed,
      );
      setFnfData(data);
    },
    onError: (err: AppError) => {},
  });

  const processFnf = useMutation({
    mutationKey: UPDATE_FNF_DATA,
    mutationFn: () => {
      if (fnfData && peopleId && dismissalDateInput) {
        if (isM2S1Enabled) {
          return api.salaryComponents.mutations.updateFnfDetails(
            fnfData,
            dismissalDateInput,
            peopleId,
          );
        } else {
          return api.fnf.updateFnfData(
            prepareFnfDataForUpdate(fnfData, dismissalDateInput, parseInt(peopleId)),
          );
        }
      } else {
        return Promise.reject('Fnf data is required');
      }
    },
    onSuccess: () => {
      document.querySelector('#react')?.scrollIntoView();
      setShowSuccessBanner(true);
      setUpdateErrorString(null);
      if (isHoldSalaryPayComplianceEnabled) {
        if (state?.from?.pathname) {
          history.push({
            pathname: state.from.pathname,
            search: state.from.search || '',
          });
        }
      } else {
        toast.show({
          content: 'Fnf details updated successfully!',
          color: 'positive',
        });
        getFnfData.refetch();
      }
    },
    onError: (error: typeof AppError) => {
      const response = getAppErrorDetails(error);
      setUpdateErrorString(response.messageForUser);
      setShowSuccessBanner(false);
      document.querySelector('#react')?.scrollIntoView();
    },
  });

  const handleProcessFnf = () => {
    processFnf.mutate();
  };

  if (getDismissalDateQuery.isFetching) {
    return (
      <Box display="grid" placeItems="center" padding="spacing.7">
        <Spinner accessibilityLabel="Fetching fnf details" />
      </Box>
    );
  }

  const error = getFnfData.error || getDismissalDateQuery.error;

  if (error) {
    return (
      <Box display="grid" placeItems="center" padding="spacing.7">
        <Alert
          color="negative"
          title="There was an error in fetching the fnf data"
          description={error.message}
          actions={{
            primary: {
              text: 'Retry',
              onClick: getFnfData.error ? getFnfData.refetch : getDismissalDateQuery.refetch,
            },
          }}
        />
      </Box>
    );
  }

  return (
    <>
      {orgId && peopleId && dismissalDateInput && (
        <Box display="flex" flexDirection="row" justifyContent="center" height="100%">
          <PageLayout
            hideHeading={true}
            boxProps={{ height: 'auto', display: 'flex', flexDirection: 'column' }}
            isLoading={getFnfData.isFetching}>
            <Box display="flex" justifyContent="center">
              <FnfInputs
                setFnfData={setFnfData}
                fnfData={fnfData}
                setDismissalDateInput={setDismissalDateInput}
                dismissalDateInput={dismissalDateInput}
                peopleId={peopleId ?? ''}
                handleProcessFnf={handleProcessFnf}
                updateErrorString={updateErrorString}
                showSuccessBanner={showSuccessBanner}
                isFnfDataLoading={getFnfData.isFetching}
                isFnfUpdating={processFnf.isLoading}
                fnfUpdateResponse={processFnf.data ?? null}
                isHoldSalaryPayComplianceEnabled={isHoldSalaryPayComplianceEnabled}
                isFnfProcessed={isFnfProcessed}
              />
            </Box>
          </PageLayout>

          <Box width="18%">
            <FnfSideBar data={fnfData} isLoading={getFnfData.isFetching} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default FnfPage;
