import { Box, Text } from '@razorpay/blade/components';
import React from 'react';

interface UserThumbnailProps {
  name?: string;
}

const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1.33301C6.15905 1.33301 4.66667 2.82539 4.66667 4.66634C4.66667 6.50729 6.15905 7.99967 8 7.99967C9.84095 7.99967 11.3333 6.50729 11.3333 4.66634C11.3333 2.82539 9.84095 1.33301 8 1.33301ZM6 4.66634C6 3.56177 6.89543 2.66634 8 2.66634C9.10457 2.66634 10 3.56177 10 4.66634C10 5.77091 9.10457 6.66634 8 6.66634C6.89543 6.66634 6 5.77091 6 4.66634Z"
        fill="#BBBDC8"
      />
      <path
        d="M5.33333 9.33301C3.49238 9.33301 2 10.8254 2 12.6663V13.9997C2 14.3679 2.29848 14.6663 2.66667 14.6663C3.03486 14.6663 3.33333 14.3679 3.33333 13.9997V12.6663C3.33333 11.5618 4.22876 10.6663 5.33333 10.6663H10.6667C11.7712 10.6663 12.6667 11.5618 12.6667 12.6663V13.9997C12.6667 14.3679 12.9651 14.6663 13.3333 14.6663C13.7015 14.6663 14 14.3679 14 13.9997V12.6663C14 10.8254 12.5076 9.33301 10.6667 9.33301H5.33333Z"
        fill="#BBBDC8"
      />
    </svg>
  );
};

const UserThumbnail = ({ name }: UserThumbnailProps) => {
  const firstCharCapital = name?.[0]?.toUpperCase();

  return (
    <Box
      width="25px"
      height="25px"
      backgroundColor="surface.background.gray.intense"
      borderRadius="round"
      display="flex"
      alignItems="center"
      justifyContent="center">
      {firstCharCapital ? <Text>{firstCharCapital}</Text> : <UserIcon />}
    </Box>
  );
};

export { UserThumbnail };
