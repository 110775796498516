import React, { FormEvent, ReactElement, useState, useCallback } from 'react';
import { Redirect } from 'react-router';
import { routePaths } from 'components/Routes/data';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ErrorMessage from 'components/ui/ErrorMessage';
import APIError from 'types/apiError';
import { Input, InputElement } from 'components/ui/Form/Input';
import { StandardButton, LinkButtonV2 } from 'components/ui/Button';
import { WarningAlert } from 'components/ui/Alert';
import { FRESHTEAMS_ACTIVATION_STATUS_QUERY } from '../../queries';
import Breadcrumb from 'components/ui/Breadcrumb';
import FreshTeamsHelpSidebarElement from '../FreshTeamsHelpSidebarElement';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import { useModal } from 'components/ui/Modals';
import { ModalTitle } from 'components/ui/Modals/components/Modal';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import domainNameHelpImage from 'assets/freshteam/domain_name.png';
import { getStaticMediaUrl } from 'utils/Urls';
import FreshTeamActivationConfirmation from './components/FreshTeamActivationConfirmation';

import styles from './index.module.scss';

interface Props {}

function FreshTeamsActivation({}: Props): ReactElement {
  const [domain, setDomain] = useState('');
  const [key, setKey] = useState('');
  const { openModal, closeModal } = useModal();

  const queryClient = useQueryClient();
  const activationQuery = useQuery(
    FRESHTEAMS_ACTIVATION_STATUS_QUERY,
    () => {
      return api.freshteamsSettings.fetchActivationStatus();
    },
    {
      onError: (error: APIError) => {},
    },
  );

  const activationMutation = useMutation(
    ({ domain, key }: { domain: string; key: string }) =>
      api.freshteamsSettings.activate(domain, key),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FRESHTEAMS_ACTIVATION_STATUS_QUERY);
      },
      onError: (error: APIError) => {},
    },
  );

  const handleActivation = () => {
    activationMutation.mutate({ domain, key });
    closeModal();
  };

  const handleActivationConfirmation = (event: FormEvent) => {
    event.preventDefault();
    openModal(<FreshTeamActivationConfirmation onConfirm={handleActivation} />);
  };

  const handleShowDomainHelp = useCallback(() => {
    openModal(
      <ConfirmModal confirmOnly confirmText="Got it">
        <ModalTitle>What is my domain?</ModalTitle>
        <div className={`${styles['Modal']} mt-8 mb-8`}>
          <p>
            You can get your domain by going to your Freshteam dashboard and copying the domain part
            of it, similar to the highlighted portion in the example below:
          </p>
          <img className="mt-4" src={getStaticMediaUrl(domainNameHelpImage)} />
        </div>
      </ConfirmModal>,
    );
  }, []);

  const handleShowAPIKeyTutorial = useCallback(() => {
    openModal(
      <ConfirmModal confirmOnly confirmText="Got it">
        <ModalTitle>Where can I find the API Key?</ModalTitle>
        <div className={`${styles['Modal']} mt-8 mb-8`}>
          <p>You can get your API key from the Freshteam dashboard:</p>
          <video
            className="mt-4"
            autoPlay
            playsInline
            muted
            controls
            src="https://payroll-assets.razorpay.com/assets/freshteam/freshteam_api_key_tutorial.mp4"
          />
        </div>
      </ConfirmModal>,
    );
  }, []);

  if (activationQuery.status === 'loading' || activationQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (activationQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        {activationQuery.error?.message || 'Something went wrong, please contact support'}
      </ErrorMessage>
    );
  }

  if (activationQuery?.data?.enabled) {
    return (
      <div>
        <Redirect to={routePaths.settings.freshteamsSettings} />
      </div>
    );
  }

  if (activationQuery.data.active_integration) {
    return <Redirect to={routePaths.settings.freshteamsSettings} />;
  }

  return (
    <div>
      <h1 className="text-4xl font-bold">Let's get started</h1>
      <div className="mt-6">
        Please provide your API keys to initiate the connection. Once you connect, XPayroll will
        automatically start downloading user data from Freshteam.
      </div>
      {activationMutation.status === 'error' && (
        <WarningAlert className="mt-8">
          {activationMutation.error?.message || 'Something went wrong, please contact support'}
        </WarningAlert>
      )}
      <form className="mt-12" onSubmit={handleActivationConfirmation}>
        <InputElement required label="Domain" className="my-auto mt-4">
          <Input
            type="text"
            value={domain}
            required
            disabled={activationMutation.status === 'loading' || activationQuery.isFetching}
            name="domain"
            onChange={(event) => setDomain(event.target.value)}
            placeholder="sample-company.freshteam.com"
          />
          <InputInfo>
            What is my domain? <LinkButtonV2 onClick={handleShowDomainHelp}>Know here</LinkButtonV2>
          </InputInfo>
        </InputElement>
        <InputElement required label="API Key" className="my-auto mt-12 mb-8">
          <Input
            type="password"
            value={key}
            required
            name="freshteam-apikey"
            disabled={activationMutation.status === 'loading' || activationQuery.isFetching}
            onChange={(event) => setKey(event.target.value)}
            placeholder="e.g xk4FT9r4U75E-HG5U0zJAQ"
          />
          <InputInfo>
            Login to Freshteam {'>'} Click profile Avatar {'>'} API Key. To know more{' '}
            <LinkButtonV2 onClick={handleShowAPIKeyTutorial}>click here</LinkButtonV2>.
          </InputInfo>
        </InputElement>
        <StandardButton
          className="mt-20"
          type="submit"
          isPending={activationMutation.status === 'loading'}
          disabled={activationQuery.isFetching}>
          Connect
        </StandardButton>
      </form>
    </div>
  );
}

function FreshTeamsActivationWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Freshteam"
        urlMaps={{
          Settings: '/settings',
        }}
      />
      <MainCase center>
        <FreshTeamsActivation />
      </MainCase>
      <RightSidebar>
        <FreshTeamsHelpSidebarElement />
      </RightSidebar>
    </PageWrapper>
  );
}

export default FreshTeamsActivationWithPageWrapper;
