import { Box, Button, EditIcon, Link, TextInput } from '@razorpay/blade/components';
import { FormContainer } from 'components/Onboarding/CompanyDetails/styles';
import { routePaths } from 'components/Routes/data';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import useReduxSelector from 'utils/useReduxSelector';
import { orgTypes } from '../constants';
import { useMutation, useQuery } from 'react-query';
import api from 'api';
import { PanNameView } from 'components/Onboarding/components/PanNameView';
import { BvsDataLoadingView } from 'components/Onboarding/components/BvsDataLoadingView';
import { useVerifyCompanyDetailsContext } from './VerifyCompanyDetailsContext';
import { errorParser } from 'utils/Api';
import { getAppErrorDetails } from 'utils/AppError';
import { useCompanyVerificationData } from '../hooks/useCompanyVerificationDetails';
import { GSTIN_REGEX, PAN_REGEX } from 'utils/regexes';
import { useDispatch } from 'react-redux';
import { fetchLoggedInUser } from 'reducers/loggedInUser';

const GstinWithPan = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const orgType = org?.type;
  const { setAlertBannerPropsContext } = useVerifyCompanyDetailsContext();
  const { isCompanyVerified, gstin, authorizedSignatory } = useCompanyVerificationData();
  const [formData, setFormData] = useState({
    gstin: gstin ?? '',
    signatoryPan: authorizedSignatory?.pan ?? '',
  });
  const [validationErrors, setValidationErrors] = useState({
    gstin: '',
    signatoryPan: '',
  });
  const [signatoryNameFromPan, setSignatoryNameFromPan] = useState('');
  const panFieldRef = useRef<HTMLInputElement>(null);

  const signatoryPanQuery = useQuery({
    queryFn: () => api.companyOnboarding.getCompanyNameFromPan(formData.signatoryPan),
    onSuccess: (data) => {
      setSignatoryNameFromPan(data.name ?? '');
    },
    onError: (error) => {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        signatoryPan: errorParser(getAppErrorDetails(error))[0],
      }));
    },
    enabled: false,
  });

  useEffect(() => {
    const { signatoryPan } = formData;
    if (
      signatoryPan &&
      signatoryPan.length >= 10 &&
      new RegExp(PAN_REGEX).test(signatoryPan) &&
      !isCompanyVerified
    ) {
      setSignatoryNameFromPan('');
      signatoryPanQuery.refetch();
    }
  }, [formData.signatoryPan]);

  const companyVerificationByGstinMutation = useMutation({
    mutationFn: api.companyOnboarding.verifyCompanyDetailsWithGstin,
    onSuccess: (data) => {
      if (data.success) {
        setAlertBannerPropsContext(undefined);
        dispatch(fetchLoggedInUser());

        if (data.addressPrefilled) {
          history.push(routePaths.kyc.bankDetails);
        } else {
          history.push({
            pathname: routePaths.kyc.companyDetailsVerification.manual,
            state: {
              gstin: formData.gstin,
              signatoryPan: formData.signatoryPan,
              errorFetchingAddressFromBvs: true,
            },
          });
        }
      }
    },
    onError: (error) => {
      setAlertBannerPropsContext({
        title: 'Verification failed',
        color: 'negative',
        description: errorParser(getAppErrorDetails(error))[0],
        isDismissible: false,
      });
    },
  });

  const redirectToDashboard = () => {
    window.location.href = '/dashboard';
  };

  // if the company verification is already completed, redirect back to dashboard
  if (isCompanyVerified) {
    redirectToDashboard();
    return null;
  }

  const handleChange = (e: { name?: string; value?: string }) => {
    if (!e.name) return;

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      [e.name as string]: '',
    }));

    setFormData((formData) => ({
      ...formData,
      [e.name as string]: e.value,
    }));
  };

  const handleAnotherWayClick = (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    let redirectLink;

    switch (orgType) {
      case orgTypes.PRIVATE_LIMITED:
        redirectLink = routePaths.kyc.companyDetailsVerification.cinOrLlpin;
        break;
      case orgTypes.PARTNERSHIPS:
      case orgTypes.TRUST:
        redirectLink = routePaths.kyc.companyDetailsVerification.pan;
        break;
      case orgTypes.SOLE_PROP:
        redirectLink = routePaths.kyc.companyDetailsVerification.addAddress;
        break;
      default:
        redirectLink = routePaths.kyc.companyDetailsVerification.cinOrLlpin;
        break;
    }

    history.push(redirectLink);
  };

  const validateFields = () => {
    let result = true;
    if (!new RegExp(GSTIN_REGEX).test(formData.gstin)) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        gstin: 'Please enter a valid GSTIN',
      }));
      result = false;
    }

    if (!new RegExp(PAN_REGEX).test(formData.signatoryPan)) {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        signatoryPan: 'Please enter a valid personal PAN',
      }));
      result = false;
    }

    return result;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    companyVerificationByGstinMutation.mutate({
      gstin: formData.gstin,
      signatoryPan: formData.signatoryPan,
      signatoryName: signatoryNameFromPan,
    });
  };

  const handleNotCorrectSignatoryNameClick = () => {
    panFieldRef.current?.focus();
  };

  const isSignatoryNameFetched = signatoryNameFromPan.length > 0;

  const isAuthorizedSignatoryPrefilled =
    orgType === orgTypes.SOLE_PROP && !!authorizedSignatory?.pan && !!authorizedSignatory?.name;

  const showAlternateVerificationLink = !signatoryPanQuery.isFetching;

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <TextInput
          name="gstin"
          label="GSTIN"
          placeholder="Eg. 22AAAAA0000A1Z5"
          helpText="Claim GST credit on invoices by adding GST"
          value={formData.gstin}
          onChange={handleChange}
          isRequired
          autoFocus
          validationState={validationErrors.gstin ? 'error' : 'none'}
          errorText={validationErrors.gstin}
        />
        <TextInput
          name="signatoryPan"
          label="Authorised Signatory PAN"
          placeholder="Eg. BZBIJ7819G"
          value={formData.signatoryPan}
          onChange={handleChange}
          isRequired
          ref={panFieldRef}
          validationState={validationErrors.signatoryPan ? 'error' : 'none'}
          errorText={validationErrors.signatoryPan}
          isDisabled={isAuthorizedSignatoryPrefilled}
          helpText={
            isAuthorizedSignatoryPrefilled
              ? 'Authorized signatory PAN is same as your personal PAN for Sole-Prop business type'
              : ''
          }
        />
        {isSignatoryNameFetched && (
          <PanNameView title="Authorised Signatory Name" value={signatoryNameFromPan} />
        )}
        {showAlternateVerificationLink && (
          <Link onClick={handleAnotherWayClick}>Don't have GSTIN? Verify another way→</Link>
        )}
        {signatoryPanQuery.isFetching && (
          <BvsDataLoadingView description="Fetching your details..." />
        )}
      </FormContainer>
      {isSignatoryNameFetched && (
        <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.5">
          <Box>
            <Button type="submit" isLoading={companyVerificationByGstinMutation.isLoading}>
              Confirm & Proceed
            </Button>
          </Box>
          {!isAuthorizedSignatoryPrefilled && (
            <Box>
              <Button
                variant="secondary"
                iconPosition="right"
                icon={EditIcon}
                onClick={handleNotCorrectSignatoryNameClick}
                isDisabled={companyVerificationByGstinMutation.isLoading}>
                Not the correct signatory name? Edit PAN
              </Button>
            </Box>
          )}
        </Box>
      )}
    </form>
  );
};

export { GstinWithPan };
