import { addMonths, format, parse } from 'date-fns';
import { dateFormats } from 'utils/Dates';
import { ArrearComponent, DefinedRunPayrollData } from './utils/types';

export const getRlopMonthList = (
  idx: number,
  month: string | null,
  data: DefinedRunPayrollData,
) => {
  if (!month) {
    return [];
  }

  let monthList: Date[] = [];
  const currentMonthValue = data[idx].month;
  const parsedDate = parse(month, dateFormats.yearMonthDate, new Date());
  for (let i = -4; i < 8; i++) {
    const date = addMonths(parsedDate, i);
    if (
      !data.some(
        (entry, i) =>
          entry.month === format(date, dateFormats.yearMonthDate) &&
          currentMonthValue !== entry.month,
      )
    ) {
      monthList.push(date);
    }
  }
  return monthList;
};

export const getArrearsTotalAmount = (arrears: ArrearComponent[]) => {
  return arrears.reduce((acc, arrear) => acc + arrear.amount, 0);
};
