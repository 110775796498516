import React from "react";

import { TickList, TickListItem } from "../../../ui/TickList";
import { Card } from "../../../ui/Card";

import styles from './index.module.scss';

export const WhyXPayrollSection = () => {
    return (
    <Card 
        withTopBorder={true} 
        className={'border-accent-negative px-2 shadow-none zaggle-info-card-bg ' + styles['zaggle-info-card-bg']}
        >
        <div
            className={`text-1.92xl font-bold text-white`}>How to Setup Zaggle with XPayroll
        </div>
        <br />
        <div>
            <TickList>
                <TickListItem>
                    Select the flexible benefits wallets for your employee.
                </TickListItem>
                <TickListItem>
                    Create a new salary structure component.
                </TickListItem>
                <TickListItem>
                    Select the Zaggle card delivery address and confirm. 
                </TickListItem>
            </TickList>
        </div>
    </Card>);
}