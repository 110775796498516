import React, {FormEvent, useState} from "react";
import PageWrapper from "../../../../../ui/PageWrapper";
import MainCase from "../../../../../ui/MainCase";
import {useMutation, useQuery, useQueryClient} from "react-query";
import api from "../../../../../../api";
import APIError from "../../../../../../types/apiError";
import {JIBBLE_INTEGRATION_STATUS_QUERY} from "../../../utils/queries";
import Spinner from "../../../../../ui/Spinner";
import ErrorMessage from "../../../../../ui/ErrorMessage";
import {routePaths} from "../../../../../Routes/data";
import {WarningAlert} from "../../../../../ui/Alert";
import {Input, InputElement} from "../../../../../ui/Form/Input";
import {LinkButtonV2, StandardButton, StandardSecondaryButton} from "../../../../../ui/Button";
import {Card} from "components/ui/Card";
import styles from "./index.module.scss";
import jibbleCommonStyles from "../../../index.module.scss";
import { getStaticMediaUrl } from 'utils/Urls';
import jibbleSignature from "assets/jibble/signature--jibble.svg";
import infoIcon from "assets/icons/icon_info.svg";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../../../reducers/loggedInUser/selectors";
import {HeaderWithProgressBar} from "../../../../../BulkUploads/components/HeaderWithProgressBar";

const JibbleActivation= () => {
    const { jibbleApiPageLink } = useSelector(loggedInUserSelectors.referenceLinks);
    const [jibbleSecretId, setJibbleSecretId] = useState('');
    const [jibbleSecret, setJibbleSecret] = useState('');
    const [activationError, setActivationError] = useState('');

    const history = useHistory();
    const queryClient = useQueryClient();
    const integrationStatusQuery = useQuery(
        JIBBLE_INTEGRATION_STATUS_QUERY,
        () => {
            return api.jibbleSettings.fetchIntegrationStatus();
        },
        {
            onError: (error: APIError) => {},
        },
    );

    const activationMutation = useMutation(
        ({ jibbleSecretId, jibbleSecret }: { jibbleSecretId: string; jibbleSecret: string }) =>
            api.jibbleSettings.activate(jibbleSecretId, jibbleSecret),
        {
            onSuccess: (data) => {
                if (!data.success) {
                    setActivationError(data.error as string);
                }
                queryClient.invalidateQueries(JIBBLE_INTEGRATION_STATUS_QUERY);
            },
            onError: (error: APIError) => {},
        },
    );

    const handleActivation = (event: FormEvent) => {
        event.preventDefault();
        activationMutation.mutate({jibbleSecretId, jibbleSecret});
    };

    if (integrationStatusQuery.status === 'loading' || integrationStatusQuery.status === 'idle') {
        return (
            <div className="flex justify-center items-center h-full">
                <Spinner />
            </div>
        );
    }

    if (integrationStatusQuery.status === 'error') {
        return (
            <ErrorMessage title="Sorry! An error has occurred!">
                {integrationStatusQuery.error?.message || 'Something went wrong, please contact support'}
            </ErrorMessage>
        );
    }

    if (integrationStatusQuery?.data?.is_enabled) {
        if (integrationStatusQuery.data.is_onboarding_completed){
            history.push(routePaths.settings.jibble.manage);
        }
        else {
            history.push(routePaths.settings.jibble.onboarding.settingsConfiguration);
        }
    }

    return (
        <div className={jibbleCommonStyles['container']}>
            <img src={getStaticMediaUrl(jibbleSignature)} alt="" className="mb-16"/>
            <p className="text-2.14xl font-bold ">Let’s start! Input API credentials from Jibble</p>
            <Card className={styles['infoCard']}>
                <p className="text-1.7xl font-bold mb-8">👉 How to generate a unique Jibble API credentials</p>
                <p className="text-1.5xl opacity-75">
                    1. Go to Jibble’s {' '}
                    <a target="_blank" rel="noreferrer" href={jibbleApiPageLink}>
                    <LinkButtonV2 className={"text-1.5xl"}>API page</LinkButtonV2>
                    </a><br />
                    2. Click on the <strong>Create new secret</strong> button <br />
                    3. Give your API key a name and click on Save. Your API key is Ready!
                </p>
                <p className="text-1.5xl mt-12 opacity-75">💡 View detailed guide to generate Jibble API key {' '}
                    <a
                        href="https://www.jibble.io/help/overall-organization-settings/using-jibbles-api-for-your-custom-needs"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkButtonV2>here</LinkButtonV2>
                    </a>

                </p>
            </Card>
            {activationMutation.status === 'error' && (
                <WarningAlert className="mt-8">
                    {activationMutation.error?.message || 'Something went wrong, please contact support'}
                </WarningAlert>
            )}
            <form className="mt-12 w-full" onSubmit={(event) => handleActivation(event)}>
                <InputElement required label="API Key ID" className={styles['inputBox']}>
                    <Input
                        type="text"
                        value={jibbleSecretId}
                        required
                        disabled={activationMutation.status === 'loading' || integrationStatusQuery.isFetching}
                        name="jibbleSecretId"
                        onChange={(event) => setJibbleSecretId(event.target.value)}
                    />
                </InputElement>
                <InputElement required label="API Key Secret" className={styles['inputBox']}>
                    <Input
                        type="password"
                        value={jibbleSecret}
                        required
                        name="jibbleSecret"
                        disabled={activationMutation.status === 'loading' || integrationStatusQuery.isFetching}
                        onChange={(event) => setJibbleSecret(event.target.value)}
                    />
                </InputElement>
                {activationError && <WarningAlert className="text-left">{activationError}</WarningAlert>}
                <p className={styles['info']}>
                    <img src={getStaticMediaUrl(infoIcon)} alt="" className={styles['infoIcon']} />
                    {' '}API Keys can only be generated by Admins and owners of Jibble account
                </p>
                <Link to={routePaths.settings.jibble.accountConfirmation}>
                    <StandardSecondaryButton className={jibbleCommonStyles['backButton']}>Back</StandardSecondaryButton>
                </Link>
                <StandardButton
                    className="mt-32 float-right mr-0"
                    type="submit"
                    isPending={activationMutation.status === 'loading'}
                    disabled={integrationStatusQuery.isFetching || jibbleSecretId==='' || jibbleSecret===''}
                >
                    Verify Account
                </StandardButton>
            </form>
        </div>
    );
}

const JibbleActivationWithPageWrapper = () => (
    <PageWrapper>
        <div className={"flex flex-col w-full"}>
            <HeaderWithProgressBar
                headerText="Jibble Integration"
                currentStep={1}
                totalSteps={3}
            />
            <MainCase center>
                <JibbleActivation/>
            </MainCase>
        </div>
    </PageWrapper>
)

export default JibbleActivationWithPageWrapper;