import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const useIsRlopEnabled = () => {
  const features = useSelector(loggedInUserSelectors.features);
  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const isRunPayrollEditWorkflowEnabled = actionsWithActiveWorkflows.includes(
    WorkflowTypesMap.EDIT_PAYROLL,
  );
  const isRlopFeatureEnabled = !!features?.['rlop'];
  const isPfArrearsFeatureEnabled = !!features?.['pf-arrears'];

  return {
    isRlopFeatureEnabled:
      isRlopFeatureEnabled && isPfArrearsFeatureEnabled && !isRunPayrollEditWorkflowEnabled,
  };
};

export default useIsRlopEnabled;
