import React, { ReactElement } from "react";

const SideNote = (): ReactElement => (
    <div className={'space-y-3 mb-16'}>
        <div className={'font-bold text-white/87'}>Please note 📝</div>
        <ul className="list-disc mt-10 pl-6 text-white/54">
            <li>This is the maximum limit for flexible benefits in a month. The employee will be able to choose wallets and their amounts based on this limit. They cannot exceed this limit.</li>
            <li>The amount in the new component will be adjusted from the other CTC components. Don’t worry! 🙂 The total CTC won’t change and the tax calculation will also not get affected.
            </li>
            <li>If you want to have different flexible amounts for different employees you can individually customize the employee salary structure.
            </li>
        </ul>
    </div>
)

export default SideNote;