import {
  ActionList,
  ActionListItem,
  Box,
  Button,
  Dropdown,
  DropdownOverlay,
  SearchIcon,
  SelectInput,
  TextInput,
} from '@razorpay/blade/components';
import { customReportInputTypes, customReportFilterTypes } from './constants';
import { ReportFilter, ReportInput } from './types';
import DatePickerBlade from 'components/ui/DatePickerBlade';
import { format, parse } from 'date-fns';
import React, { useRef } from 'react';
import { dateFormats } from 'utils/Dates';

const SearchPeopleField = ({
  field,
  value,
  onChange,
  isLoading,
  onSearchClick,
}: {
  field: ReportFilter;
  value: string;
  onChange: (key: string, value: string) => void;
  isLoading: boolean;
  onSearchClick?: () => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <Box display="flex" alignItems="flex-end">
      <Box flex={1}>
        <TextInput
          isDisabled={isLoading}
          ref={ref}
          label={field.label}
          placeholder={field.placeHolder}
          onChange={(e) => onChange(field.key, e.value || '')}
          value={value}
        />
      </Box>
      <Button
        isDisabled={isLoading}
        icon={SearchIcon}
        variant="secondary"
        onClick={() =>
          onSearchClick ? onSearchClick() : onChange(field.key, ref.current?.value || '')
        }></Button>
    </Box>
  );
};

export const renderFields = ({
  fields,
  isLoading,
  onChange,
  currentfields,
}: {
  fields: ReportInput[];
  isLoading: boolean;
  onChange: (key: string, value: string | string[] | null) => void;
  currentfields: Record<string, string>;
}) => {
  return fields.map((field) => {
    return renderCustomReportField({ field, isLoading, onChange, currentfields });
  });
};

export const renderCustomReportField = ({
  field,
  isLoading,
  onChange,
  currentfields,
  onSearchClick,
}: {
  field: ReportInput;
  isLoading: boolean;
  onChange: (key: string, value: string | string[] | null) => void;
  currentfields: Record<string, string>;
  onSearchClick?: () => void;
}) => {
  switch (field.type) {
    case customReportInputTypes.TYPE_DROPDOWN:
    case customReportInputTypes.TYPE_PAYROLL_MONTH:
    case customReportInputTypes.TYPE_FINANCIAL_YEAR:
      return (
        <Dropdown selectionType="single" key={field.key}>
          <SelectInput
            isDisabled={isLoading}
            label={field.label}
            name={field.key}
            placeholder={field.placeHolder}
            value={currentfields[field.key] || field.defaultValue || ''}
            onChange={({ name, values }) => {
              onChange(field.key, values[0]);
            }}
          />
          <DropdownOverlay zIndex={99999}>
            <ActionList>
              {field.options.map((option) => (
                <ActionListItem
                  key={option.key.toString()}
                  title={option.value.toString()}
                  value={option.key.toString()}
                />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
      );
    case customReportInputTypes.TYPE_PAYROLL_DATE:
      return (
        <DatePickerBlade
          isDisabled={isLoading}
          key={field.key}
          label={field.label}
          dateFormat={dateFormats.yearMonthDate}
          placeholder={field.placeHolder}
          value={parse(
            currentfields[field.key] || field.defaultValue,
            dateFormats.yearMonthDate,
            new Date(),
          )}
          onChange={(date) => {
            onChange(field.key, date ? format(date, dateFormats.yearMonthDate) : null);
          }}
        />
      );
    case customReportFilterTypes.TYPE_FILTER_SEARCH_PEOPLE_BY_ID:
    case customReportFilterTypes.TYPE_FILTER_SEARCH_PEOPLE_BY_EMAIL:
    case customReportFilterTypes.TYPE_FILTER_SEARCH_PEOPLE_BY_NAME:
      return (
        <SearchPeopleField
          isLoading={isLoading}
          key={field.key}
          field={field}
          value={currentfields[field.key]}
          onChange={(key, value) => onChange(key, value)}
          onSearchClick={onSearchClick}
        />
      );
    default:
      throw new Error(`Invalid field type: ${field.type}`);
  }
};
