import { Badge, Box, Button, Text } from '@razorpay/blade/components';
import React from 'react';
import { useModal } from 'components/ui/Modals';
import { useErrorMessageContext } from 'components/RunPayroll/contexts/RunPayrollErrorContext';
import { useMessageContext } from 'components/RunPayroll/contexts/RunPayrollMessageContext';
import { useRunPayrollDataContext } from 'components/RunPayroll/contexts/RunPayrollDataContext';
import ExecutePayroll from 'components/RunPayroll/Popups/ExecutePayroll';
import {
  actionStatusesMap,
  badgeColorLabelMap,
  complianceActionsMap,
  payrollSummaryDescriptionMap,
} from 'components/RunPayroll/ComplianceActions/data';
import { PayrollActionStatus } from 'components/RunPayroll/ComplianceActions/types';
import { EventTimestampWrapper } from 'components/RunPayroll/ComplianceActions/styles';
import { useGetComplianceActionDetails } from 'components/RunPayroll/ComplianceActions/hooks/useGetComplianceActionDetails';
import { useQueryClient } from 'react-query';
import { GET_COMPLIANCE_ACTIONS } from 'components/RunPayroll/ComplianceActions/queries';
import { useIsTdsFilingConfigRequired } from 'components/RunPayroll/hooks/useIsTdsFilingConfigRequired';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const PayrollEventLog = ({
  status,
  isRxIntegrated,
}: {
  status: PayrollActionStatus;
  isRxIntegrated: boolean;
}) => {
  const payoutProcessingTime = isRxIntegrated ? '3-4 hours' : '24 hours';
  if (
    status === actionStatusesMap.completelyExecuted ||
    status === actionStatusesMap.partiallyExecuted
  ) {
    return (
      <EventTimestampWrapper borderColor="positive">
        <Text color="surface.text.gray.muted">
          Your payroll request has been received and successfully executed.
        </Text>
      </EventTimestampWrapper>
    );
  }

  if (status === actionStatusesMap.processing) {
    return (
      <EventTimestampWrapper borderColor="primary">
        <Text color="surface.text.gray.muted">
          Your payroll has been finalised and your request has been received.
        </Text>
      </EventTimestampWrapper>
    );
  }

  if (status === actionStatusesMap.finalized) {
    return (
      <EventTimestampWrapper borderColor="primary">
        <Text color="surface.text.gray.muted">Average time taken to complete this step</Text>
        <Text weight="semibold">{payoutProcessingTime}</Text>
      </EventTimestampWrapper>
    );
  }

  if (status === actionStatusesMap.pending) {
    return (
      <EventTimestampWrapper borderColor="information">
        <Text color="surface.text.gray.muted">
          Finalization request has been sent for approval and is currently pending.
        </Text>
      </EventTimestampWrapper>
    );
  }

  return (
    <EventTimestampWrapper borderColor="primary">
      <Text color="surface.text.gray.muted">Average time taken to complete this step</Text>
      <Text weight="semibold">{payoutProcessingTime}</Text>
    </EventTimestampWrapper>
  );
};

const TdsDeductorDetailsSummary = () => {
  const payrollMonth = useRunPayrollDataContext()?.payrollMonth || '';

  const moveToTdsSetupPage = () => {
    window.location.href = `/companyOnboarding?step=799&redirect=%2Frun-payroll?payroll_month=${payrollMonth}`;
  };
  return (
    <Box>
      <Text marginBottom="spacing.2" size="large" weight="semibold">
        TDS Deductor Details Missing/Unverified!
      </Text>
      <Text marginBottom="spacing.5" color="surface.text.gray.muted">
        Collect and verify TDS deductor details before proceeding to payroll execution.
      </Text>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="spacing.4">
        <Box>
          <EventTimestampWrapper borderColor="notice">
            <Text color="surface.text.gray.muted">
              This step typically takes a few minutes to complete
            </Text>
          </EventTimestampWrapper>
        </Box>
        <Box>
          <Button onClick={moveToTdsSetupPage}>Update Details</Button>
        </Box>
      </Box>
    </Box>
  );
};

export const PayrollActionSummary = () => {
  const { actionsApiResponse } = useGetComplianceActionDetails();
  const errCtx = useErrorMessageContext();
  const { openModal } = useModal();
  const message = useMessageContext();
  const data = useRunPayrollDataContext();
  const queryClient = useQueryClient();
  const isTdsFilingConfigRequired = useIsTdsFilingConfigRequired();
  const isRXIntegrated = useSelector(loggedInUserSelectors.currentOrganization)?.isRXIntegrated;

  const onExecute = () => {
    openModal(
      <ExecutePayroll
        payrollMonth={data?.payrollMonth || ''}
        isFirstPayroll={!!data?.isFirstPayroll}
        onError={errCtx?.setError}
        showMessage={message?.setMessage}
        onSuccess={() => {
          queryClient.invalidateQueries({
            queryKey: [GET_COMPLIANCE_ACTIONS, data?.payrollMonth],
          });
        }}
      />,
    );
  };

  const action = actionsApiResponse?.[complianceActionsMap.runPayroll];

  if (!action) {
    return null;
  }

  const badgeProps = badgeColorLabelMap[action.status];
  const isPayrollFinalised = action.status === actionStatusesMap.finalized;

  if (isPayrollFinalised && isTdsFilingConfigRequired) {
    return <TdsDeductorDetailsSummary />;
  }

  return (
    <Box>
      {badgeProps ? (
        <Badge
          testID={`badge-test-${badgeProps.label}`}
          color={badgeProps.color}
          marginBottom="spacing.3">
          {badgeProps.label}
        </Badge>
      ) : null}
      <Text marginBottom="spacing.2" size="large" weight="semibold">
        Payroll Execution
      </Text>
      <Text marginBottom="spacing.5" color="surface.text.gray.muted">
        {payrollSummaryDescriptionMap[action.status]}
      </Text>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="spacing.4">
        <Box>
          <PayrollEventLog status={action.status} isRxIntegrated={!!isRXIntegrated} />
        </Box>
        {isPayrollFinalised ? (
          <Box>
            <Button onClick={onExecute}>Request Execution</Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
