import api from 'api';
import { useQuery } from 'react-query';
import { FETCH_RX_INTEGRATION_DETAILS } from '../queries';
import { AppError } from 'utils/AppError';
import { CACHE_TTL } from 'utils/cache';

const useRxIntegration = () => {
  const rxIntegrationQuery = useQuery({
    queryFn: api.rxIntegration.fetchDetails,
    queryKey: FETCH_RX_INTEGRATION_DETAILS,
    onError: (error: typeof AppError) => {},
    staleTime: CACHE_TTL.MEDIUM,
  });

  return {
    rxIntegrationQuery,
  };
};

export default useRxIntegration;
