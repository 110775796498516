export const wordWrapText = (text: string, maxLineLength: number = 80) => {
  if (!text) return ['', ''];
  if (text.length <= maxLineLength) return [text, ''];
  const words = text.split(' ');
  let lineLength = 0;

  // use functional reduce, instead of for loop
  const formatted = words.reduce((result, word) => {
    if (lineLength + word.length >= maxLineLength) {
      lineLength = word.length;
      return result + `\n${word}`; // don't add spaces upfront
    } else {
      lineLength += word.length + (result ? 1 : 0);
      return result ? result + ` ${word}` : `${word}`; // add space only when needed
    }
  }, '');
  const lines = formatted.split('\n');
  if (lines.length === 1) lines.push('');
  return lines;
};
