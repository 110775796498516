import { RunPayrollData } from 'components/RunPayroll/utils/types';
import useFeature from 'hooks/useFeature';
import { useState } from 'react';
import { PayrollAllowance } from '../types';

type UseAllowancesProps = {
  payrollEntry: RunPayrollData;
};

const useAllowances = ({ payrollEntry }: UseAllowancesProps) => {
  const allowanceEntries = payrollEntry.allowances || [];
  const { isReArchM2S1Enabled } = useFeature('reArchM2S1');

  const initialItems = isReArchM2S1Enabled
    ? allowanceEntries.length <= 2
      ? 2
      : allowanceEntries.length
    : 5;

  const initialAllowances = Array.from({ length: initialItems }, (_, index) => ({
    name: allowanceEntries && allowanceEntries[index] ? allowanceEntries[index]?.name : '',
    amount: allowanceEntries && allowanceEntries[index] ? allowanceEntries[index]?.amount : 0,
  }));

  const [allowances, setAllowances] = useState<PayrollAllowance[]>(initialAllowances);

  const onAllowanceChange = (updateIndex: number, propToUpdate: string, value: string | number) => {
    setAllowances(
      allowances.map((val, i) => {
        if (i === updateIndex) {
          return {
            ...val,
            [propToUpdate]: value,
          };
        }
        return val;
      }),
    );
  };

  const onAllowanceAdd = () => {
    setAllowances((prev) => [
      ...prev,
      {
        amount: 0,
        name: '',
      },
    ]);
  };

  const onAllowanceDelete = (deleteIndex: number) => {
    setAllowances((prev) => prev.filter((_, index) => index !== deleteIndex));
  };

  return { allowances, onAllowanceChange, onAllowanceAdd, onAllowanceDelete };
};

export default useAllowances;
