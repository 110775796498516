import { currentOrganization } from 'reducers/loggedInUser/selectors';
import useReduxSelector from 'utils/useReduxSelector';
import { orgTypes } from '../constants';

const useCompanyVerificationData = () => {
  const currentOrg = useReduxSelector(currentOrganization);
  const orgType = currentOrg?.type;
  const gstin = currentOrg?.gstin;
  const cin = currentOrg?.cin;
  const llpin = currentOrg?.llpin;
  const pincode = currentOrg?.pincode;
  const stateCode = currentOrg?.state;
  const address1 = currentOrg?.data?.address1;
  const address2 = currentOrg?.data?.address2;
  const authorizedSignatory = currentOrg?.authorizedSignatory;

  const isAddressCollected = !!pincode && !!address1;
  const isAuthorizedSignatoryVerified = !!authorizedSignatory?.name && !!authorizedSignatory?.pan;

  let isCompanyVerified = false;
  switch (orgType) {
    case orgTypes.PRIVATE_LIMITED:
      isCompanyVerified =
        isAddressCollected && isAuthorizedSignatoryVerified && (!!gstin || !!cin || !!llpin);
      break;
    case orgTypes.PARTNERSHIPS:
    case orgTypes.TRUST:
      isCompanyVerified = isAddressCollected && isAuthorizedSignatoryVerified;
      break;
    case orgTypes.SOLE_PROP:
      isCompanyVerified = isAddressCollected;
      break;
    default:
      isCompanyVerified = false;
      break;
  }

  return {
    gstin,
    cin,
    llpin,
    pincode,
    stateCode,
    address1,
    address2,
    authorizedSignatory,
    isCompanyVerified,
  };
};

export { useCompanyVerificationData };
