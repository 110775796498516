import React, { useState, useRef, ReactNode } from 'react';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useHover,
    size,
    useInteractions,
    Placement,
    FloatingArrow,
    arrow
} from '@floating-ui/react';
import styles from './index.module.scss';

interface TooltipProps {
    content: string;
    position: Placement;
    children: ReactNode;
    textAlign?: 'left' | 'right';
}

function Tooltip({ content, position, children, textAlign = 'left' }: TooltipProps) {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip(),
            shift(),
            arrow({
                element: arrowRef,
            }),
            size({
                apply({availableWidth, availableHeight, elements}) {
                  // Change styles, e.g.
                  Object.assign(elements.floating.style, {
                    maxWidth: `250px`,
                    maxHeight: `${availableHeight}px`,
                  });
                },
              })
        ],
        whileElementsMounted: autoUpdate,
        placement: position
    });
    

    const hover = useHover(context, { move: false });

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </div>
            {isOpen && (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className={`${styles['tooltip']} text-${textAlign}`}
                    {...getFloatingProps()}
                >
                    <FloatingArrow ref={arrowRef} context={context} fill='#393F5C' stroke='#4F546E' strokeWidth={0.5}/>
                    {content}
                </div>
            )}
        </>
    );

}

export default Tooltip;