import styled from 'styled-components';

interface DashboardContentProps {
  isSideBarOpen?: boolean;
  noHeader?: boolean;
}

export const DashboardContent = styled.main<DashboardContentProps>(
  ({ theme, noHeader, isSideBarOpen }) => `
  left: 0;
  width: 100%;
  height: ${noHeader ? '100%' : 'calc(100vh - 56px)'};
  margin: 0 auto;
  overflow-x: auto;
  position: absolute;
  flex: 1;
  background: ${theme.colors.surface.background.gray.subtle};

  ${
    isSideBarOpen &&
    `
    left: 245px;
    width: calc(100vw - 245px);
    
    @media (max-width: ${theme.breakpoints.m}px) {
      left: 0;
      width: 100%;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      left: 264px;
      width: calc(100vw - 264px);
    }
  `
  }

  ${
    !isSideBarOpen &&
    `
      padding-left: 0px;
      padding-right: 0px
    `
  }
`,
);

export const AppShell = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;
