import React from 'react';
import { Heading, Text } from '@razorpay/blade/components';
import { KnitApps } from 'components/Knit/constants';

export const hrmsAppIntegrationQuestions = {
  [KnitApps.darwinbox]: [
    {
      title: <Text size="large">🔑 Darwinbox API credentials is available for your account</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          For integration with Darwinbox you need access to Darwinbox API credentials. Without
          credentials integration is not possible. You can check if you have Darwinbox API
          credentials here→
        </Text>
      ),
      selectionOptions: [
        'I have access to API credentials',
        'I have not yet purchased the credentials',
      ],
      validSelectionIndex: 0,
      noticeText:
        'Integration is only possible if Darwinbox credentials are available. Please purchase the credentials if you are darwinbox admin',
    },
    {
      title: <Text size="large">🙋‍♂️ All your employees are onboarded to Darwinbox</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          We recommend to onboard all your employees to Darwinbox. This will help sync all employees
          at once to RazorpayX Payroll.
        </Text>
      ),
      selectionOptions: [
        'All employees are onboarded in Darwinbox',
        'Yet to onboard all employees',
      ],
      validSelectionIndex: 0,
      noticeText:
        'We recommend to add all your employees to Darwinbox. This allows seamless sync of all data',
    },
  ],
  [KnitApps.hibob]: [
    {
      title: <Text size="large">🔑 HiBob API credentials is available for your account</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          For integration with HiBob you need access to HiBob API credentials. Without credentials
          integration is not possible. You can check if you have HiBob API credentials here→
        </Text>
      ),
      selectionOptions: [
        'I have access to API credentials',
        'I have not yet purchased the credentials',
      ],
      validSelectionIndex: 0,
      noticeText:
        'Integration is only possible if HiBob credentials are available. Please purchase the credentials if you are hibob admin',
    },
    {
      title: <Text size="large">🙋‍♂️ All your employees are onboarded to HiBob</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          We recommend to onboard all your employees to HiBob. This will help sync all employees at
          once to RazorpayX Payroll.
        </Text>
      ),
      selectionOptions: ['All employees are onboarded in HiBob', 'Yet to onboard all employees'],
      validSelectionIndex: 0,
      noticeText:
        'We recommend to add all your employees to HiBob. This allows seamless sync of all data',
    },
  ],
  [KnitApps.worq]: [
    {
      title: <Text size="large">🔑 Worq API credentials is available for your account</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          For integration with Worq you need access to Worq API credentials. Without credentials
          integration is not possible. You can check if you have Worq API credentials here→
        </Text>
      ),
      selectionOptions: [
        'I have access to API credentials',
        'I have not yet purchased the credentials',
      ],
      validSelectionIndex: 0,
      noticeText:
        'Integration is only possible if Worq credentials are available. Please purchase the credentials if you are worq admin',
    },
    {
      title: <Text size="large">🙋‍♂️ All your employees are onboarded to Worq</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          We recommend to onboard all your employees to Worq. This will help sync all employees at
          once to RazorpayX Payroll.
        </Text>
      ),
      selectionOptions: ['All employees are onboarded in Worq', 'Yet to onboard all employees'],
      validSelectionIndex: 0,
      noticeText:
        'We recommend to add all your employees to Worq. This allows seamless sync of all data',
    },
  ],
  [KnitApps.workline]: [
    {
      title: <Text size="large">🔑 Workline API credentials is available for your account</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          For integration with Workline you need access to Workline API credentials. Without
          credentials integration is not possible. You can check if you have Workline API credentials
          here→
        </Text>
      ),
      selectionOptions: [
        'I have access to API credentials',
        'I have not yet purchased the credentials',
      ],
      validSelectionIndex: 0,
      noticeText:
        'Integration is only possible if Workline credentials are available. Please purchase the credentials if you are workline admin',
    },
    {
      title: <Text size="large">🙋‍♂️ All your employees are onboarded to Workline</Text>,
      description: (
        <Text variant="caption" color="surface.text.gray.muted" size='small'>
          We recommend to onboard all your employees to Workline. This will help sync all employees at
          once to RazorpayX Payroll.
        </Text>
      ),
      selectionOptions: ['All employees are onboarded in Workline', 'Yet to onboard all employees'],
      validSelectionIndex: 0,
      noticeText:
        'We recommend to add all your employees to Workline. This allows seamless sync of all data',
    },
  ],
};
