import { type NpsSettings } from '../NPS/types';
import { parse, format } from 'date-fns';
import { dateFormats } from '../../../utils/Dates';
import { uniqueId } from '../../../utils/Numbers';
import { CustomDeclarationType, GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import { GetEmployeeNpsDeclarationResponse, GetOrgNpsSettingsResponse, UpdateOrgNpsSettingsPayload, initDeclarationValidations } from 'components/Settings/NPS/types';
import { DeclarationValidations } from './types';

export const parseSettings = (
    settings: GetOrgNpsSettingsResponse,
): NpsSettings => {
    var { declarationWindow, ...rest } = settings;
    const parsed = { ...rest } as NpsSettings;
    const npsDeclarationWindow = { ...declarationWindow } as GenericDeclaration;
    if (npsDeclarationWindow.type === 'custom') {
        declarationWindow = declarationWindow as CustomDeclarationType;
        if (declarationWindow.dates && Array.isArray(declarationWindow.dates)) {
            let dates = declarationWindow.dates || [];
            const parsedDates = dates.reduce(
                (parsedObj, dateObj) => ({
                    ...parsedObj,
                    [uniqueId()]: {
                        startDate: parse(dateObj.startDate, dateFormats.dayMonth, new Date()),
                        endDate: parse(dateObj.endDate, dateFormats.dayMonth, new Date()),
                    },
                }),
                {},
            );
            npsDeclarationWindow.dates = parsedDates;
        }
    }

    parsed.NpsDeclarationWindow = npsDeclarationWindow;
    return parsed as NpsSettings;
};

export const createPayload = (
    settings: NpsSettings,
): Partial<UpdateOrgNpsSettingsPayload> => {
    const {
        NpsDeclarationWindow,
        ...rest
    } = settings;
    const payload = { ...rest } as Partial<UpdateOrgNpsSettingsPayload>;

    const declarationWindow = {
        ...NpsDeclarationWindow,
    } as UpdateOrgNpsSettingsPayload['declarationWindow'];
    if (declarationWindow.type === 'custom' && NpsDeclarationWindow.type === 'custom') {
        const dates = NpsDeclarationWindow.dates || {};

        if (Object.values(dates).length < 1) {
            throw new Error("Please provide one or more date windows");
        }

        const parsedDates = Object.values(dates).map((e) => ({
            startDate: e.startDate ? format(e.startDate, dateFormats.dayMonth) : null,
            endDate: e.endDate ? format(e.endDate, dateFormats.dayMonth) : null,
        }));
        declarationWindow.dates = parsedDates;
    }

    payload.declarationWindow = declarationWindow;

    return payload;
};

export const validateDeclarationData = (
    declarationData: Partial<GetEmployeeNpsDeclarationResponse>,
    setValidationState: (value: DeclarationValidations) => void,
    onSubmit?: boolean): DeclarationValidations => {
    var validateDeclarationData: DeclarationValidations = initDeclarationValidations();
    if (onSubmit) {
        if (declarationData.pran) {
            if (declarationData.pran.length !== 12) {
                validateDeclarationData.pran.state = 'error';
                validateDeclarationData.pran.message = 'Please enter a valid PRAN';
            }
        }
        else {
            validateDeclarationData.pran.state = 'error';
            validateDeclarationData.pran.message = 'Please enter a valid PRAN';
        }
    }
    
    if (declarationData.npsEmployeeDeclarationPercentage && (declarationData.npsEmployeeDeclarationPercentage > (declarationData.npsEmployerContributionLimitPercentage ?? 10) || declarationData.npsEmployeeDeclarationPercentage < 0)) {
        validateDeclarationData.npsDeclaration.state = 'error';
        validateDeclarationData.npsDeclaration.message = `Please enter a declaration value between 0% and ${declarationData.npsEmployerContributionLimitPercentage}%`;
    }

    if (declarationData.npsEmployeeDeclarationPercentage && declarationData.npsEmployerContributionLimitPercentage && declarationData.salary?.basic && declarationData.salary?.da && (((declarationData.salary?.basic + declarationData.salary.da) * (declarationData.npsEmployeeDeclarationPercentage * 0.01)) < 500)) {
        validateDeclarationData.npsDeclaration.state = 'error';
        validateDeclarationData.npsDeclaration.message = `Please raise your declaration percentage to have the NPS contribution amount between ₹500 and ${(declarationData.salary?.basic + declarationData.salary.da) * (declarationData.npsEmployerContributionLimitPercentage * 0.01)}`;
    }

    setValidationState(validateDeclarationData);
    return validateDeclarationData;
}

interface BadgeVariant {
    variant: string;
    text: string;
}
export const getBadgeVariant = (declarationData: GetEmployeeNpsDeclarationResponse): BadgeVariant => {
    var variant = 'neutral';
    var text = '';

    if (!declarationData.isNpsEnabled) {
        variant = 'negative';
        text = 'NPS Disabled'
    }
    else if (!declarationData.employeeCanDeclareNpsLimit) {
        variant = 'negative';
        text = 'NPS Disabled'
    }
    else if (!declarationData.isDeclarationWindowOpen) {
        variant = 'notice';
        text = 'Declaration Window Closed'
    }
    else if (!declarationData.npsEmployeeDeclarationPercentage) {
        variant = 'neutral';
        text = 'Not Declared'
    }
    else if (declarationData.npsEmployeeDeclarationPercentage) {
        variant = 'positive';
        text = 'Declared'
    }

    return { variant: variant, text: text };
}