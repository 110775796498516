
import styled, { css } from 'styled-components';

export const CardFooterTextBold = styled.span`
color: rgba(255, 255, 255, 0.38);
font-size: 14px;
font-style: italic;
font-weight: 700;
line-height: 18px;
margin-left: 4px;
`;

export const CardFooterTextRegular = styled.span`
color: rgba(255, 255, 255, 0.38);
font-size: 14px;
font-style: italic;
font-weight: 400;
line-height: 18px;
margin-left: 4px;
`;

export const CardWrapper = styled.div<{ allowClick?: boolean }>(({ theme, allowClick }) => {
  let borderColor = allowClick ? theme.colors.surface.border.gray.normal : theme.colors.surface.border.gray.subtle;
  let cursor = allowClick ? 'pointer' : 'default';
    return css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing[6]}px;
      padding: ${theme.spacing[5]}px ${theme.spacing[6]}px;
      border-radius: ${theme.border.radius.large}px;
      border: ${theme.border.width.thick}px solid ${theme.colors.surface.border.gray.subtle};
      background-color: ${theme.colors.surface.background.gray.subtle};

      :hover {
        border-color: ${ borderColor };
        cursor: ${ cursor };
      }

    `;
  });