import api from 'api';
import { useQuery } from 'react-query';
import { WorkflowRequestDetailsQueryKey } from './constants';
import { transformDataForWorkflowRequest } from 'api/gql-utils/workflows/transformers';

interface UseWorkflowRequestDetails {
  requestId?: number;
  isGraphQLEnabled?: boolean;
}

const useWorkflowRequestDetails = ({
  requestId,
  isGraphQLEnabled = false,
}: UseWorkflowRequestDetails) => {
  const workflowRequestDetailsQuery = useQuery({
    queryKey: [WorkflowRequestDetailsQueryKey, { requestId }],
    queryFn: () =>
      api.workflowApis.getWorkflowRequestDetails({
        requestId: requestId as number,
        isGraphQLEnabled,
      }),
    enabled: !!requestId,
    staleTime: 5000,
    select: transformDataForWorkflowRequest,
  });

  return workflowRequestDetailsQuery;
};

export { useWorkflowRequestDetails };
