import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { ILoggedInUser } from '../../../../reducers/loggedInUser';
import {
  IInsurance,
  IUpdateInsurancePayload,
  updateInsurance,
} from '../../../../reducers/insurance';
import { PrimaryButton } from '../../../ui/Button';
import { TableLayout } from '../../../ui/Table';
import { RadioButton } from '../../../ui/RadioButton';
import { SelectButton } from '../../../ui/SelectButton';
import { useDispatch } from 'react-redux';
import { IInsuranceEstimate } from '../../../../reducers/insuranceEstimate';
import { Route } from 'react-router-dom';
import { getConditionsNotMetToGetExactEstimate } from '../computeMinRequirements';
import { canSelectPlan } from '../canSelectPlan';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import { routePaths } from 'components/Routes/data';
import { ContentContainer } from '../../../ui/ContentContainer';
import pro from '../../../../assets/pro.svg';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import pluralize from 'pluralize';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

function PolicyRequirementQuestions({
  insurance,
  insuranceEstimate,
  updateInsuranceAction,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();
  const withChildrenAndSpouse = insurance.data?.withChildrenAndSpouse ? true : false;
  const withParentsOrInLaws = insurance.data?.withParentsOrInLaws ? true : false;
  const shouldCoverEmployeeFromEsi = insurance.data?.shouldCoverEmployeeFromEsi ? true : false;
  const totalEsiEmployees = insuranceEstimate.data?.totalEsiEmployees
    ? insuranceEstimate.data.totalEsiEmployees
    : 0;
  const sumInsured = insurance.data?.sumInsured ? insurance.data?.sumInsured : 100000;
  const patchInsurance = (payload: IUpdateInsurancePayload) => {
    const insuranceId = insurance.data?.id;
    if (insuranceId) {
      dispatch(updateInsuranceAction(insuranceId, payload));
    }
  };

  return (
    <>
      <SelectYourPolicyHeading className="hidden lg:block" />
      <ul className="my-5 mt-0 mx-2 space-y-10">
        <PolicyRequirementQuestion
          question="1. Policy selection*"
          description="Select the policy you want to go ahead with"
        >
          <RadioButton
            className="lg:mr-4"
            isSelected={insurance.data?.planId === 1}
            onSelect={() => {
              patchInsurance({ planId: 1 });
            }}
            isDisabled={!canSelectPlan(insurance, insuranceEstimate, 1, loggedInUser)}
          >
            Starter
          </RadioButton>
          <RadioButton
            className="lg:mr-4"
            isSelected={insurance.data?.planId === 2}
            onSelect={() => {
              patchInsurance({ planId: 2 });
            }}
            isDisabled={!canSelectPlan(insurance, insuranceEstimate, 2, loggedInUser)}
          >
            Essential
          </RadioButton>
          <RadioButton
            className="lg:mr-4"
            isSelected={insurance.data?.planId === 3}
            onSelect={() => {
              patchInsurance({ planId: 3 });
            }}
            isDisabled={!canSelectPlan(insurance, insuranceEstimate, 3, loggedInUser)}
          >
            Premium
          </RadioButton>
        </PolicyRequirementQuestion>
        <PolicyRequirementQuestion
          question="2. Additional coverage"
          description="Select all the family members of an employee you want to cover"
        >
          <RadioButton
            isSelected={withChildrenAndSpouse && !withParentsOrInLaws}
            onSelect={() => {
              patchInsurance({ 
                              withParentsOrInLaws: false,
                              withChildrenAndSpouse: true
                            });
            }}
            className="lg:mr-4"
          >
            Spouse and children (1+4)
          </RadioButton>
          <RadioButton
            isSelected={withParentsOrInLaws}
            onSelect={() => {
              patchInsurance({ 
                              withParentsOrInLaws: true,
                              withChildrenAndSpouse: true 
                            });
            }}
            className="lg:mr-4"
          >
            Spouse, children and Parents/In-laws (2)
          </RadioButton>
        </PolicyRequirementQuestion>
        <PolicyRequirementQuestion
          question="3. Maximum Sum Insured"
          description="This is the maximum claim that can be made per employee"
        >
          <RadioButton
            className="lg:mr-4"
            isSelected={sumInsured === 100000}
            onSelect={() => {
              patchInsurance({ sumInsured: 100000 });
            }}
          >
            ₹1 lakh
          </RadioButton>
          <RadioButton
            className="lg:mr-4"
            isSelected={sumInsured === 200000}
            onSelect={() => {
              patchInsurance({ sumInsured: 200000 });
            }}
          >
            ₹2 lakh
          </RadioButton>
          <RadioButton
            className="lg:mr-4"
            isSelected={sumInsured === 300000}
            onSelect={() => {
              patchInsurance({ sumInsured: 300000 });
            }}
          >
            ₹3 lakh
          </RadioButton>
          <RadioButton
            className="lg:mr-4"
            isSelected={sumInsured === 500000}
            onSelect={() => {
              patchInsurance({ sumInsured: 500000 });
            }}
          >
            ₹5 lakh
          </RadioButton>
        </PolicyRequirementQuestion>
        {totalEsiEmployees > 0 && (
          <PolicyRequirementQuestion
            question="4. Include employees under ESI?"
            description={
              'Your organization has ' +
              totalEsiEmployees +
              pluralize(' employee', totalEsiEmployees) +
              ' under ESI'
            }
          >
            <RadioButton
              className="lg:mr-4"
              isSelected={shouldCoverEmployeeFromEsi === true}
              onSelect={() => {
                patchInsurance({ shouldCoverEmployeeFromEsi: true });
              }}
            >
              Yes
            </RadioButton>
            <RadioButton
              className="lg:mr-4"
              isSelected={shouldCoverEmployeeFromEsi === false}
              onSelect={() => {
                patchInsurance({ shouldCoverEmployeeFromEsi: false });
              }}
            >
              No &nbsp;
            </RadioButton>
          </PolicyRequirementQuestion>
        )}
      </ul>
    </>
  );
}

function PolicyRequirementQuestion({
  children,
  question,
  description,
}: PropsWithChildren<{ question: string; description: string }>) {
  return (
    <li>
      <div className="font-semibold py-1 text-white">{question}</div>
      <div className="secondary-text py-1">{description}</div>
      <div className="flex flex-col lg:flex-row py-2 space-y-3 lg:space-y-0">{children}</div>
    </li>
  );
}

export function GetAnExactQuote({
  insurance,
  insuranceEstimate,
  updateInsuranceAction,
  loggedInUser,
}: {
  insurance: IInsurance;
  insuranceEstimate: IInsuranceEstimate;
  updateInsuranceAction: typeof updateInsurance;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();
  const conditionsNotMet = getConditionsNotMetToGetExactEstimate(insuranceEstimate, loggedInUser);
  const isOnProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
  if (insurance.data?.status == 'DATA_COLLECTION_IN_PROGRESS') {
    return (
      <Route
        render={({ history }) => (
          <PrimaryButton
            onClick={() => history.push(routePaths.insurance.admin.finalize)}
            className="w-full"
            disabled={conditionsNotMet.length > 0}
          >
            Continue
          </PrimaryButton>
        )}
      />
    );
  }
  const patchInsurance = (payload: IUpdateInsurancePayload) => {
    const insuranceId = insurance.data?.id;
    if (insuranceId) {
      dispatch(updateInsuranceAction(insuranceId, payload));
    }
  };
  return (
    <>
      <div className="text-4xl font-semibold">
        Next Step
        {isOnProPlan ? (
          ''
        ) : (
          <img
            style={{ display: 'inline-block', marginLeft: '8px' }}
            className="h-full"
            src={getStaticMediaUrl(pro)}
          />
        )}
      </div>
      <ul className="space-y-1 mt-2">
        {conditionsNotMet.map((message) => (
          <li> {message} </li>
        ))}
      </ul>

      <Route
        render={({ history }) => (
          <PrimaryButton
            onClick={() => history.push(routePaths.insurance.admin.finalize)}
            className="w-full"
            disabled={conditionsNotMet.length > 0}
          >
            Get An Exact Quote
          </PrimaryButton>
        )}
      />

      {/* <div className="w-full text-center my-4">
                OR
            </div>
            <LinkButton onClick={() => patchInsurance({registeredInterestAt:formatStringToISO((new Date()).toISOString())})} className="w-full text-center"> Not sure yet? Let us reach out to you</LinkButton> */}
    </>
  );
}

export function SelectYourPolicyHeading({ className }: { className: string }) {
  return (
    <div className={`font-bold mb-8 text-5xl text-white ${className} font-heading`}>
      Select your policy requirements
    </div>
  );
}

export function EstimateBox({
  className,
  insuranceEstimate,
  insurance,
}: {
  insurance: IInsurance;
  className: string;
  insuranceEstimate: IInsuranceEstimate;
}) {
  const totalEmployees = insuranceEstimate.data?.totalEligibleEmployees
    ? insuranceEstimate.data.totalEligibleEmployees
    : 0;
  const totalCost = insuranceEstimate.data?.roughEstimate.amount
    ? insuranceEstimate.data.roughEstimate.amount
    : 0;
  let totalLives = insuranceEstimate.data?.totalMissingEmployeeDetails
    ? insuranceEstimate.data.totalMissingEmployeeDetails +
      insuranceEstimate.data.roughEstimate.totalChildrenEstimatedForMissingEmployees +
      insuranceEstimate.data.roughEstimate.totalParentsEstimatedForMissingEmployees +
      insuranceEstimate.data.roughEstimate.totalSpousesEstimatedForMissingEmployees
    : 0;
  totalLives =
    totalLives +
    (insuranceEstimate.data?.exactEstimate.estimatedForDependentIds.length
      ? insuranceEstimate.data.exactEstimate.estimatedForDependentIds.length
      : 0);
  totalLives =
    totalLives +
    (insuranceEstimate.data?.exactEstimate.estimatedForEmployeeIds.length
      ? insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length
      : 0);

  return (
    <div className={`${className} bg-rhino p-5 relative`}>
      <div
        className={`${
          insuranceEstimate.isFetching ? '' : 'opacity-0'
        } absolute right-0 left-0 top-0 bottom-0 flex flex-col text-center`}
      >
        <div className="my-auto">loading</div>
      </div>
      <TableLayout
        className={`w-full break-all lg:break-normal ${
          insuranceEstimate.isFetching ? 'opacity-0' : ''
        }`}
      >
        <tr className="h-20">
          <td className="text-3xl text-white">Cost / employee</td>
          <td className="font-semibold text-right text-white text-3xl">
            {totalEmployees > 0
              ? toIndianCurrencyString(Math.round(totalCost / totalEmployees))
              : 'N/A'}
            <sup>*</sup>
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl text-white">Number of employees</td>
          <td className="font-semibold text-right text-white text-3xl">{totalEmployees}</td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl text-white">Total lives insured</td>
          <td className="font-semibold text-right text-white text-3xl">{totalLives}</td>
        </tr>
        <tr className="h-20">
          <td className="text-4xl font-semibold font-heading">Total Estimate</td>
          <td className="font-semibold text-right text-primary text-5xl font-heading">
            {toIndianCurrencyString(totalCost)}
          </td>
        </tr>
      </TableLayout>
    </div>
  );
}

export function EstimateConditions({
  insurance,
  insuranceEstimate,
}: {
  insurance: IInsurance;
  insuranceEstimate: IInsuranceEstimate;
}) {
  let estimateGivenFor = 'Employee';
  let estimateGivenForDependents = [];
  if (insuranceEstimate.data?.isSpousedAllowed && insurance.data?.withChildrenAndSpouse) {
    estimateGivenForDependents.push(' Spouse');
  }

  if (
    insuranceEstimate.data?.maxChildrenAllowed &&
    insuranceEstimate.data?.maxChildrenAllowed >= 2 &&
    insurance.data?.withChildrenAndSpouse
  ) {
    estimateGivenForDependents.push(' Children');
  }

  if (insuranceEstimate.data?.areParentsAllowed && insurance.data?.withParentsOrInLaws) {
    estimateGivenForDependents.push(' Parents');
  }
  let dependentText = '';
  if (estimateGivenForDependents.length > 0) {
    dependentText = 'and ' + estimateGivenForDependents.pop();
    if (estimateGivenForDependents.length > 0) {
      dependentText = estimateGivenForDependents.join(',') + ' ' + dependentText;
      estimateGivenFor = estimateGivenFor + ',' + dependentText;
    } else {
      estimateGivenFor = estimateGivenFor + ' ' + dependentText;
    }
  } else {
    estimateGivenFor = 'Only Employee';
  }

  return (
    <div className="bg-rhino p-5">
      <ol className="space-y-4 list-decimal p-5 pl-8 text-sm">
        <li>The above mentioned rates are exclusive of GST (18%).</li>
        <li>Estimate prices may vary based on actual age of spouse, children and parents.</li>
        <li>Estimate is basis heuristics for {estimateGivenFor}. Exact quote on the next step.</li>
      </ol>
    </div>
  );
}

export function InsuranceEstimate({
  insurance,
  updateInsuranceAction,
  insuranceEstimate,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  return (
    <ContentContainer showSidebar={false}>
      <div className="p-3 lg:p-10 lg:pr-16">
        <div className="flex flex-col-reverse lg:flex-row space-y-10">
          <section className="lg:hidden w-full mt-10">
            <GetAnExactQuote
              insurance={insurance}
              insuranceEstimate={insuranceEstimate}
              updateInsuranceAction={updateInsuranceAction}
              loggedInUser={loggedInUser}
            />
          </section>
          <section className="w-full lg:flex-1">
            <PolicyRequirementQuestions
              insurance={insurance}
              updateInsuranceAction={updateInsuranceAction}
              insuranceEstimate={insuranceEstimate}
              loggedInUser={loggedInUser}
            />
          </section>
          <section className="w-full lg:w-144 space-y-6">
            <SelectYourPolicyHeading className="lg:hidden" />
            <EstimateBox
              className="mt-2"
              insuranceEstimate={insuranceEstimate}
              insurance={insurance}
            />
            <EstimateConditions insurance={insurance} insuranceEstimate={insuranceEstimate} />
            <div className="mt-10 hidden lg:block">
              <GetAnExactQuote
                insurance={insurance}
                insuranceEstimate={insuranceEstimate}
                updateInsuranceAction={updateInsuranceAction}
                loggedInUser={loggedInUser}
              />
            </div>
          </section>
        </div>
      </div>
    </ContentContainer>
  );
}
