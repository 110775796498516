import {
  ActionList,
  ActionListItem,
  DropdownOverlay,
  SelectInput,
  TableCell,
  TableEditableDropdownCell,
} from '@razorpay/blade/components';
import React from 'react';

interface TaxabilityCellProps {
  taxable: string;
  isTaxabilityEditDisabled: boolean;
  onTaxableChange: (value: string) => void;
}

const areEqual = (prevProps: TaxabilityCellProps, nextProps: TaxabilityCellProps) => {
  return (
    prevProps.taxable === nextProps.taxable &&
    prevProps.isTaxabilityEditDisabled === nextProps.isTaxabilityEditDisabled
  );
};

const TaxabilityCell = ({
  taxable,
  isTaxabilityEditDisabled,
  onTaxableChange,
}: TaxabilityCellProps) => {
  if (isTaxabilityEditDisabled) {
    return <TableCell>{taxable}</TableCell>;
  }

  return (
    <TableEditableDropdownCell>
      <SelectInput
        label=""
        placeholder="Select"
        value={taxable}
        onChange={(e) => onTaxableChange(e.values[0])}
      />
      <DropdownOverlay>
        <ActionList>
          <ActionListItem title="Yes" value="Yes" />
          <ActionListItem title="No" value="No" />
        </ActionList>
      </DropdownOverlay>
    </TableEditableDropdownCell>
  );
};

TaxabilityCell.displayName = 'TaxabilityCell';

export default React.memo(TaxabilityCell, areEqual);
