import { routePaths } from 'components/Routes/data';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PendingApproval } from './PendingApproval';
import { InitiatedByMe } from './InitiatedByMe';
import { Completed } from './Completed';

const EditPayroll = () => {
  return (
    <Switch>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals} exact>
        <PendingApproval />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe} exact>
        <InitiatedByMe />
      </Route>
      <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.completed} exact>
        <Completed />
      </Route>
    </Switch>
  );
};

export { EditPayroll };
