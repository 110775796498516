import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';

export function TickListItem({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (<li className={styles['TickListItem'] + (className ?? '')}>
    {children}
  </li>);
}

export function TickList({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (<ul className={styles['TickList'] + (className ?? '')}>
    {children}
  </ul>);
}