import React from 'react';
import { Button, ExternalLinkIcon, Link } from '@razorpay/blade/components';
import { PTPaymentErrorDetails } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/ProfessionalTaxDetailsView/types';
import {
  actionStatusesMap,
  karnatakaManualPTPaymentDocsLink,
} from 'components/RunPayroll/ComplianceActions/data';
import { ComplianceActionStatus } from 'components/RunPayroll/ComplianceActions/types';

export const bankAccountUnavailableModalDetails = ({
  onDismiss,
}: {
  onDismiss: () => void;
}): PTPaymentErrorDetails => {
  return {
    heading: {
      title: 'An internal error has occurred',
      subTitle: 'Try again in sometime',
    },
    description: {
      title: 'What’s the alternative?',
      subtitle:
        "We're sorry, due to high traffic we’re unable to initiate the payment now. You can make the PT Payment manually instead.",
      actions: (
        <Link
          icon={ExternalLinkIcon}
          target="_blank"
          iconPosition="right"
          href={karnatakaManualPTPaymentDocsLink}>
          Make the PT Payment manually
        </Link>
      ),
    },
    footerActions: (
      <Button variant="tertiary" onClick={onDismiss}>
        Close
      </Button>
    ),
  };
};

export const missingCredentialsModalDetails = ({
  onDismiss,
  payrollMonth,
}: {
  onDismiss: () => void;
  payrollMonth: string;
}): PTPaymentErrorDetails => {
  return {
    heading: {
      title: 'Missing Credentials',
    },
    description: {
      title: 'Missing Username or Password.',
      subtitle:
        "To proceed with paying PT Payment dues and filing returns, your company's correct PT Payment login credentials (Username and/or Password) are required.",
    },
    footerActions: (
      <>
        <Button variant="tertiary" onClick={onDismiss}>
          Close
        </Button>
        <Button
          variant="primary"
          icon={ExternalLinkIcon}
          iconPosition="right"
          target="_blank"
          href={`/companyOnboarding?step=999&redirect=%2Frun-payroll?payroll_month=${payrollMonth}`}>
          Review
        </Button>
      </>
    ),
  };
};

export const genericErrorModalDetails = ({
  onDismiss,
  reInitiatePayment,
}: {
  onDismiss: () => void;
  reInitiatePayment: () => void;
}): PTPaymentErrorDetails => {
  return {
    heading: {
      title: 'Second factor authentication',
    },
    description: {
      title: 'Something went wrong',
      subtitle: 'Please try again later or contact support if the issue persists.',
    },
    footerActions: (
      <>
        <Button variant="tertiary" onClick={onDismiss}>
          Close
        </Button>
        <Button variant="primary" onClick={reInitiatePayment}>
          Try again
        </Button>
      </>
    ),
  };
};

export const initiatePtErrorCodes = {
  bankUnavailable: 'ERR_BANK_NOT_AVAILABLE',
  missingCreds: 'ERR_CREDENTIALS_NOT_PROVIDED',
  generic: 'UNKNOWN',
} as const;

export const ptAmountLabelMap: Record<ComplianceActionStatus | 'overdue', string> = {
  [actionStatusesMap.success]: 'Paid',
  [actionStatusesMap.paidManually]: 'Refunded',
  [actionStatusesMap.failed]: 'Payable',
  [actionStatusesMap.returnFailed]: 'Payable',
  [actionStatusesMap.notInitiated]: 'Payable',
  [actionStatusesMap.inProgress]: 'Payable',
  overdue: 'Overdue',
};

export const ptModalKeys = {
  twoFa: 'twoFa',
  markAsPaid: 'markAsPaid',
  error: 'error',
  payrollPartiallyProcessed: 'payrollPartiallyProcessed',
} as const;
