import { Box, Button, Link } from '@razorpay/blade/components';
import leftImg from 'assets/layouts/circle_left.svg';
import rightImg from 'assets/layouts/right_circle.svg';
import React from 'react';
import styled from 'styled-components';
import emptyViewImg from '../../assets/404_view.svg';
import { LeftImg, RightImg } from '../ui/Layout/styles';

const TextWrapper = styled.span`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.typography.fonts.family};
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing['7']}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.surface.text.gray.muted};
  * {
    font-style: italic;
    font-size: 1rem;
  }
`;

const UnknownPage = () => {
    return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh">
      <LeftImg height="300px" src={leftImg} />
      <RightImg src={rightImg} />
      <Box
        backgroundColor={'surface.background.gray.intense'}
        overflowY={'auto'}
        flex="1"
        height="100%">
          <Box display="grid" placeItems="center" marginTop="76px" marginX="auto" width="538px">
            <img src={emptyViewImg} alt="" />
            <TextWrapper>
                Sorry, looks like you came to the wrong page. If you think this is an error, please contact your
                company administrator, or email us at{' '}
                <Link variant="anchor" href="mailto:xpayroll@razorpay.com">
                    xpayroll@razorpay.com
                </Link>{' '}
                    along with steps to replicate the issue.
            </TextWrapper>
            <Button
              size="large"
              variant="secondary"
              marginTop="spacing.10"
              onClick={() => (window.location.href = '/dashboard')}>
              Back to dashboard
            </Button>
          </Box>
      </Box>
    </Box>
  );
};

export default UnknownPage;
