import {
  Box,
  InfoIcon,
  Text,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import { MainContent, OnboardingLayout, Sidebar } from 'components/ui/OnboardingLayout';
import permissions from 'constants/permissions';
import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { GstinWithPan } from './GstinWithPan';
import { CinOrLlpinWithPan } from './CinOrLlpinWithPan';
import { verifyCompanyDetailsScreensData } from './constants';
import { Manual } from './Manual';
import { useVerifyCompanyDetailsContext } from './VerifyCompanyDetailsContext';
import { AddAddress } from './AddAddress';
import { PanOnly } from './PanOnly';
import { documentUploadLinks, needHelpLinks } from '../constants';
import SidebarSection from '../components/SidebarSection';

const VerifyCompanyDetails = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { alertBannerPropsContext } = useVerifyCompanyDetailsContext();

  const mainContentTitle = verifyCompanyDetailsScreensData[pathname].main.title;
  const mainContentDescription = verifyCompanyDetailsScreensData[pathname].main.description;

  const sideBarContent = (
    <Box display="flex" flexDirection="column" gap="spacing.10">
      <SidebarSection title="Helpful Links" links={documentUploadLinks} />
      <SidebarSection title="Need Help?" links={needHelpLinks} />
    </Box>
  );

  const sideBarDescription = (
    <Box display="flex" gap="spacing.2" alignItems="center">
      <Text>Why do KYC?</Text>
      <Tooltip content="Complete KYC to unlock salary slips and salary transfers" placement="right">
        <TooltipInteractiveWrapper>
          <InfoIcon size="medium" color="interactive.icon.gray.normal" />
        </TooltipInteractiveWrapper>
      </Tooltip>
    </Box>
  );

  return (
    <OnboardingLayout>
      <Sidebar
        title="Account Activation"
        description={sideBarDescription}
        onBackClick={() => {
          window.location.href = '/dashboard';
        }}
        progress={['IN_PROGRESS', 'INCOMPLETE', 'INCOMPLETE']}
        footnoteText="☁️ Your progress till now is saved">
        {sideBarContent}
      </Sidebar>
      <MainContent
        title={mainContentTitle}
        description={mainContentDescription}
        alertBannerProps={alertBannerPropsContext}>
        <Switch>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={[
              routePaths.kyc.companyDetailsVerification.root,
              routePaths.kyc.companyDetailsVerification.gstin,
            ]}
            exact>
            <GstinWithPan />
          </RestrictedRoute>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.kyc.companyDetailsVerification.cinOrLlpin}
            exact>
            <CinOrLlpinWithPan />
          </RestrictedRoute>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.kyc.companyDetailsVerification.manual}
            exact>
            <Manual />
          </RestrictedRoute>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.kyc.companyDetailsVerification.addAddress}
            exact>
            <AddAddress />
          </RestrictedRoute>
          <RestrictedRoute
            allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
            path={routePaths.kyc.companyDetailsVerification.pan}
            exact>
            <PanOnly />
          </RestrictedRoute>
        </Switch>
      </MainContent>
    </OnboardingLayout>
  );
};

export { VerifyCompanyDetails };
