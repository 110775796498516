import React from 'react';
import ZohoSyncingImage from 'assets/zoho/zoho_syncing_logo.png';
import { getStaticMediaUrl } from 'utils/Urls';
import syncIcon from 'assets/sync-icon.svg';

import styles from './index.module.scss';

function ZohoSyncing() {
  return (
    <div className="text-center flex flex-col items-center">
      <img src={ZohoSyncingImage} alt="" className={styles['syncing-logo']} />
      <img
        className={`${styles['sync-icon']} mt-12 animate-spin`}
        src={getStaticMediaUrl(syncIcon)}
      />
      <div className="text-white-o-80 font-bold mt-12">
        Syncing data from Zoho People to RazorpayX Payroll
      </div>
      <div className="mt-4 text-white-o-50 text-2xl">
        It will be done in few minutes. You can navigate away from this screen. <br />
        We will notify you once the integration is complete
      </div>
    </div>
  );
}

export default ZohoSyncing;
