import React from 'react';
import { format } from 'date-fns';

import { RunPayrollSchemaContract } from 'schemas/RunPayrollSchema';
import { ErrorMessageType } from '../contexts/RunPayrollErrorContext';

export const getPageError = (
  setPayrollMonth: (payrollMonth: string | null) => void,
  data?: RunPayrollSchemaContract,
  isOrgKycRestricted?: boolean,
  links?: any
) => {
  let pageError = null;
  if (isOrgKycRestricted) {
    pageError = (
      <>
        Please complete your organization's <a href={links?.orgKyc}>KYC</a>. Payroll execution
        is disabled.
      </>
    );
  } else if (data && data.payrollIds?.length && data.hasTDSReturnsBeenProcessed) {
    pageError = (
      <>
        <strong>Warning:</strong> TDS returns have already been filed for this quarter. Please see{' '}
        <a href={links?.payrollAfterFilingAdvisory} target="_blank">
          this
        </a>{' '}
        FAQ.
      </>
    );
  } else if (data && data.isPreviousMonthNotProcessed) {
    const currentDate = new Date(data.payrollMonth+ "T00:00:00");
    let previousMonth = new Date(data.payrollMonth+ "T00:00:00");
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    pageError = (
      <>
        <strong>Warning:</strong> You are viewing{' '}
        <strong>{format(currentDate, 'MMMM yyyy')}</strong> payroll, but{' '}
        <strong>
          <a onClick={() => setPayrollMonth(format(previousMonth, 'yyyy-MM-dd'))}>{format(previousMonth, 'MMMM yyyy')}</a>
        </strong>{' '}
        payroll has not been processed.
      </>
    );
  } else if(data && data.hasFinalizePayrollErrors) {
    pageError = (
      <>
        <strong>Warning:</strong> There was an error finalizing your Payroll. Please contact support.
      </>
    );
  }
  return pageError;
};

export const getError = (
  errorObj: ErrorMessageType,
  data: RunPayrollSchemaContract | null,
  setPayrollMonth: (payrollMonth: string | null) => void,
  links: any
) => {
  let error = null;

  switch (errorObj.type) {
    case 'PAYROLL_ANOTHER_MONTH_FINALIZED':
      let errorDate: string = errorObj.data?.finalizedMonth?.date ?? null; 
      let currentMonth = format(new Date(data?.payrollMonth+ "T00:00:00"), 'MMMM yyyy');
      const errDateStr = errorDate.split(" ");
      errorDate = errDateStr[0];
      const finalizedMonthDate = new Date(errorDate+ "T00:00:00");
      const finalizedMonth = format(finalizedMonthDate, 'MMMM yyyy');
      error = (
        <>
          Unable to finalize {currentMonth} payroll because payroll is already finalized for{' '}
          <a onClick={() => setPayrollMonth(errorDate)}>{finalizedMonth}</a>. Either
          execute that payroll first, or un-finalize it. <br />
          For help, please click{' '}
          <a href={links?.payrollFinalizationError} target="_blank">
            here
          </a>
          .
        </>
      );
      break;
    case 'PAYROLL_ADHOC_PAYMEMTS_INPROGRESS':
      currentMonth = format(new Date(data?.payrollMonth+ "T00:00:00"), 'MMMM yyyy');
      error = (
        <>
          Unable to finalize as there are{' '}
          <a target="_blank" href={links?.adhocPayments}>
            few one-time payments
          </a>{' '}
          in queue for payroll month {currentMonth}. Please wait for them to get processed as it
          will affect the payroll calculations.
        </>
      );
      break;
    default:
        error = errorObj?.message ?? "Something went wrong, please contact support";
      break;
  }

  return error;
};
