import React from 'react';
import Tooltip from '../../ui/Tooltip/index';
import { SecondaryButton } from 'components/ui/Button';
import { getPrevMonth, getStatusPillTooltipContent } from './utils';
import { RunPayrollData } from './types';
import { toIndianCurrencyString } from 'utils/Numbers';
import gridStyles from '../Grid/index.module.scss';
import { format } from 'date-fns';
import { dateFormats } from 'utils/Dates';


export const rlopButton = ({
    text,
    onClick,
    isRlopVisible,
    newJoinerArrearExists,
}: {
    text: string,
    onClick: () => void,
    isRlopVisible: boolean,
    newJoinerArrearExists?: boolean
}) => {
    const pastMonthYear = getPrevMonth(format(new Date(), dateFormats.yearMonthDate))

    if (isRlopVisible) {
        const component =
            <SecondaryButton
                size="sm"
                type="button"
                onClick={onClick}
                id="add-reverse-lop"
                disabled={newJoinerArrearExists}
            >
                {text}
            </SecondaryButton>;

        if (newJoinerArrearExists) {
            return <Tooltip
                content={
                    `RLOP cannot be added as the employee joined after the cut-off date in ${pastMonthYear}. 
                    Instead unfinalize the payroll for ${pastMonthYear} and add LOP`
                }
                position='right'
            >
                {component}
            </Tooltip>
        }
        else {
            return component;
        }
    }
    return <></>;

}

export const GrossPayComponent = (
    {
        entry,
        isHoldSalaryPayComplianceEnabled,
        newJoinerArrearPaymentMonthExists,
        newJoinerArrearExists
    }:
        {
            entry: RunPayrollData,
            isHoldSalaryPayComplianceEnabled: boolean,
            newJoinerArrearPaymentMonthExists: boolean,
            newJoinerArrearExists: boolean
        }) => {

    if (entry.isCancelled) {
        if (newJoinerArrearPaymentMonthExists) {
            return <Tooltip content={getStatusPillTooltipContent(entry, newJoinerArrearPaymentMonthExists)} position='right'><span className={gridStyles['runpayroll-gross-pay-plain-text']}>On-Hold</span></Tooltip>;
        }
        return <b><span className={gridStyles['runpayroll-gross-pay-plain-text']}>Skipped</span></b>;
    }
    else {
        return <div id={`gross_${entry.peopleId}`} className='flex flex-col'>
            {
                entry.gross ?
                    (
                        newJoinerArrearExists ?
                            (
                                entry.payslipLink  ?
                                    <a href={entry.payslipLink} target="_blank" rel="noreferrer">
                                        {toIndianCurrencyString(entry.gross, true)}
                                    </a> :
                                    <span className={gridStyles['runpayroll-gross-pay-plain-text']}>
                                        {toIndianCurrencyString(entry.gross, true)}
                                    </span>
                            )
                            :
                            <a href={entry.payslipLink} target="_blank" rel="noreferrer">
                                {toIndianCurrencyString(entry.gross, true)}
                            </a>
                    ) :
                    <></>
            }

            {entry.isNetPayOnHold && isHoldSalaryPayComplianceEnabled ? (
                <span className={gridStyles['net-pay-on-hold-label']}>Net pay on-hold</span>
            ) : null}
        </div>
    }

}