import { Button, PlusIcon } from '@razorpay/blade/components';
import closeIcon from 'assets/icon--close-grey.svg';
import crossIcon from 'assets/icons/red_cross.png';
import { ALLOWANCES_LIMIT } from 'components/RunPayroll/constants';
import { useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { EarningsComponentType } from 'components/SalaryComponents/types';
import AutoCompleteV2 from 'components/ui/AutoCompleteV2/AutoCompleteV2';
import { SecondaryButton } from 'components/ui/Button';
import { Input } from 'components/ui/Form/Input';
import { Table } from 'components/ui/Table';
import React from 'react';
import { toIndianCurrencyString } from 'utils/Numbers';
import { getStaticMediaUrl } from 'utils/Urls';
import styles from '../../index.module.scss';
import useSalaryComponentsList from '../hooks/useSalaryComponentsList';
import { EditPayrollProps, PayrollAllowanceV2 } from '../types';
import { getSearchDropdownDescription } from '../utils';

type AdditionsProps = EditPayrollProps & {
  allowances: PayrollAllowanceV2[];
  onAllowanceChange: (updateIndex: number, updates: Partial<PayrollAllowanceV2>) => void;
  onAllowanceAdd: () => void;
  onAllowanceDelete: (index: number) => void;
};

const AdditionsV2 = ({
  payrollEntry,
  allowances,
  onAllowanceChange,
  onAllowanceDelete,
  onAllowanceAdd,
  payrollMonth,
}: AdditionsProps) => {
  const { openEarningsCreation } = useSalaryComponentHelpers();

  const { searchSalaryComponents, isFetchingSalaryComponents } =
    useSalaryComponentsList<EarningsComponentType>({
      requestParams: {
        category: 'EARNINGS_CATEGORY',
        payType: 'LIST_ADHOC_TYPE',
        status: 'COMPONENT_STATUS_ACTIVE',
      },
    });

  const onHoverDeleteButton = (idx: number) => {
    const row = document.getElementById(`addition-row-${idx}`);
    row?.classList.add(styles['run-payroll-deduction-row-hover']);
  };

  const onMouseoutOfDeleteButton = (idx: number) => {
    const row = document.getElementById(`addition-row-${idx}`);
    row?.classList.remove(styles['run-payroll-deduction-row-hover']);
  };

  const getAllowancesSearchSkipList = (
    allowances: PayrollAllowanceV2[],
    currentId: string,
  ): string[] => {
    return allowances
      .filter((allowance) => allowance.id !== '' && allowance.id !== currentId)
      .map((allowance) => allowance.id);
  };

  return (
    <div>
      <Table
        className="w-full  border border-solid border-surfaceBorderGraySubtle"
        showAlternateColors={false}>
        <thead>
          <tr>
            <th>Label</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {payrollEntry.adhocAllowances?.map((otp) => {
            return (
              <tr key={otp.name}>
                <td>{otp.name}*</td>
                <td>{toIndianCurrencyString(otp.amount, true)}</td>
                <td></td>
              </tr>
            );
          })}
          {allowances.map((item, idx) => {
            return (
              <tr key={idx} id={`addition-row-${idx}`}>
                <td>
                  <AutoCompleteV2
                    options={searchSalaryComponents(
                      getAllowancesSearchSkipList(allowances, item.id),
                    )}
                    itemToKey={(item) => item.id}
                    itemToLabel={(item) => item.name}
                    itemToDescription={(item) => getSearchDropdownDescription(item) ?? ''}
                    data-testid={`allowance-${allowances[idx].name}-name`}
                    name={`name-${idx}`}
                    value={allowances[idx].id}
                    placeholder="Eg. Bonus, Travel Allowance etc."
                    isDisabled={isFetchingSalaryComponents}
                    onChange={(event) => {
                      onAllowanceChange(idx, {
                        name: event.name,
                        id: event.id,
                      });
                    }}
                    onClearSelection={() => {
                      onAllowanceChange(idx, {
                        name: '',
                        id: '',
                      });
                    }}
                    footer={
                      <Button
                        variant="tertiary"
                        size="small"
                        icon={PlusIcon}
                        onClick={openEarningsCreation}
                        isFullWidth>
                        Create addition
                      </Button>
                    }
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    data-testid={`allowance-${allowances[idx].name}-value`}
                    className={styles['run-payroll-edit-number']}
                    name={`amount-${idx}`}
                    value={allowances[idx].amount ? allowances[idx].amount : ''}
                    onChange={(event) => {
                      onAllowanceChange(idx, { amount: Number(event.target.value) });
                    }}
                  />
                </td>
                <td
                  onMouseOver={() => {
                    onHoverDeleteButton(idx);
                  }}
                  onMouseOut={() => {
                    onMouseoutOfDeleteButton(idx);
                  }}>
                  {
                    <>
                      <img
                        className={styles['run-payroll-deduction-red-cross']}
                        src={getStaticMediaUrl(crossIcon)}
                        alt=""
                        onClick={() => onAllowanceDelete(idx)}
                      />
                      <img
                        className={styles['run-payroll-deduction-grey-close']}
                        src={getStaticMediaUrl(closeIcon)}
                        alt=""
                        onClick={() => onAllowanceDelete(idx)}
                      />
                    </>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div>
        {allowances.length < ALLOWANCES_LIMIT && (
          <SecondaryButton
            onClick={onAllowanceAdd}
            disabled={false}
            className={styles['run-payroll-new-deduction-button']}
            type="button">
            + New addition
          </SecondaryButton>
        )}
      </div>
      {payrollEntry.adhocAllowances && payrollEntry.adhocAllowances.length > 0 && (
        <div className="mt-5">* indicates one-time payment paid to employee.</div>
      )}
    </div>
  );
};

export default AdditionsV2;
