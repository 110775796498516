import {
  ActionList,
  ActionListItem,
  Box,
  Dropdown,
  DropdownOverlay,
  SelectInput,
} from '@razorpay/blade/components';
import { UploadKycDocumentApiPayload } from 'api/kyc';
import { KycDocumentsContext } from 'components/Kyc/KycDocumentsContext';
import {
  DocumentStatus,
  bvsDocumentTypes,
  manualDocumentTypes,
  solePropIdentityProofs,
} from 'components/Kyc/constants';
import { ProofDocuments, UploadDocumentSectionProps } from 'components/Kyc/types';
import { getErrorsAsString, getMessageFromStatus } from 'components/Kyc/utils';
import { MimeType } from 'components/ui/FileUpload/constants';
import React, { useContext } from 'react';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { AppError } from 'utils/AppError';
import DocumentUpload from '../DocumentUpload';

const Fields = [
  bvsDocumentTypes.SALES_TAX,
  bvsDocumentTypes.SHOP_ESTABLISHMENT,
  bvsDocumentTypes.GST_CERTIFICATE,
  manualDocumentTypes.VAT_TIN_REGISTRATION,
  manualDocumentTypes.TRADE_LICENSE,
];

type FieldNames = typeof Fields[number];

export const SolePropFields = ({ orgType, gstin }: UploadDocumentSectionProps) => {
  const { kycValues, setKycValues, updateCompanyDetailsDocument } = useContext(KycDocumentsContext);
  const proofType = kycValues.companyDetails.identityProof?.name;
  const isProofSelected = !!proofType;
  const data = kycValues.companyDetails;

  const onFileSelect = (
    documentType: FieldNames,
    file: File,
    upload: (payload: UploadKycDocumentApiPayload) => void,
  ) => {
    updateCompanyDetailsDocument(documentType, {
      file,
      status: DocumentStatus.UPLOADING,
      message: getMessageFromStatus({ status: DocumentStatus.UPLOADING }),
      saved_filename: '',
    });
    upload({
      documentType,
      file,
      orgType,
    });
  };

  const onSuccess = (documentType: FieldNames, response: UploadKycDocumentSchemaContract) => {
    updateCompanyDetailsDocument(documentType, {
      status: DocumentStatus.SUCCESS,
      saved_filename: response.filename || '',
      url: response.fileS3Url || '',
      message: getMessageFromStatus({ status: DocumentStatus.SUCCESS }),
    });
  };

  const onError = (documentType: FieldNames, error: AppError) => {
    updateCompanyDetailsDocument(documentType, {
      status: DocumentStatus.ERROR,
      message: getMessageFromStatus({
        status: DocumentStatus.ERROR,
        error: getErrorsAsString(error),
      }),
    });
  };

  const setProofType = (name: ProofDocuments) => {
    setKycValues((p) => ({
      ...p,
      companyDetails: {
        ...p.companyDetails,
        identityProof: {
          name,
          message: '',
        },
      },
    }));
  };

  const checkIsSelectionDisabled = () => {
    if (!proofType) return false;
    const isVerifyingOrVerified = [DocumentStatus.VERIFIED, DocumentStatus.VERIFYING];

    return isVerifyingOrVerified.includes(data[proofType]?.status || DocumentStatus.NOT_INITIATED);
  };

  const onFileNameClick = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const isProofSelectionDisabled = checkIsSelectionDisabled();

  return (
    <Box>
      <Dropdown selectionType="single">
        <SelectInput
          labelPosition="left"
          isRequired
          isDisabled={isProofSelectionDisabled}
          value={proofType}
          label="Business Identity Proof*"
          placeholder="Select document"
          name="addressProofType"
          onChange={({ values }) => setProofType(values[0] as ProofDocuments)}
        />
        <DropdownOverlay>
          <ActionList>
            {solePropIdentityProofs.map((item) => (
              <ActionListItem key={item.name} title={item.name} value={item.value} />
            ))}
          </ActionList>
        </DropdownOverlay>
      </Dropdown>
      {isProofSelected && (
        <DocumentUpload
          marginTop={'spacing.4'}
          value={data[proofType]?.file?.name || data[proofType]?.saved_filename}
          allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
          onChange={(files, upload) => {
            const file = files?.[0] || null;
            if (!file) return;
            onFileSelect(proofType, file, upload);
          }}
          onSuccess={(response) => onSuccess(proofType, response)}
          onError={(error) => onError(proofType, error)}
          status={data[proofType]?.status}
          subText={data[proofType]?.message}
          onFilenameClick={() => onFileNameClick(data[proofType]?.url)}
        />
      )}
    </Box>
  );
};
