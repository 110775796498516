import useReduxSelector from 'utils/useReduxSelector';
import { GlobalSearchProps } from 'components/GlobalSearch/types';

interface IsSuperAdminResult {
  isSuperAdmin: boolean;
}

const useIsSuperAdmin = (): IsSuperAdminResult => {
  const loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const specialPlatformPermissions = (loggedInUser.data?.specialPlatformPermissions || []) as GlobalSearchProps['specialPlatformPermissions'];
  const isSuperAdmin = (specialPlatformPermissions || []).length > 0;

  return { isSuperAdmin };
};

export default useIsSuperAdmin;
