import React, { useState, FormEvent } from 'react';
import { Input } from '../../../ui/Form/Input';
import api from '../../../../api';
import { getStaticMediaUrl } from 'utils/Urls';
import tick from 'assets/tick.png';
import { PrimaryButton } from 'components/ui/Button';
import { useMutation } from 'react-query';
import APIError from 'types/apiError';

export function SuggestionCard() {
  const [suggestion, setSuggestion] = useState('');

  function handleInputChange(eventValue: string) {
    setSuggestion(eventValue);
  }

  const formSubmitMutation = useMutation(
    () => {
      return api.integration.addSuggestion({ suggestion: suggestion });
    },
    {
      onError: (error: APIError) => {},
    },
  );
  function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    formSubmitMutation.mutate();
  }

  return (
    <>
      <div className='flex flex-col justify-between'>
        <div className="flex flex-col flex-grow justify-between items-baseline p-8 bg-persian-blue rounded-t-md hover:bg-pickled-bluewood">
          <div className="font-heading font-bold text-left text-3xl mt-4">
            Suggest a tool you would like us to integrate with.
          </div>
          <div className="text-2xl mt-10 mb-4">Type your recommendation below</div>

          <div className="w-full">
            <form onSubmit={handleFormSubmit} className="inline">
              <Input
                required
                className="mt-8 mb-9"
                type="text"
                onChange={(event) => {
                  handleInputChange(event.target.value);
                }}
              />
              {formSubmitMutation.status !== 'success' && (
                <div className="mt-8">
                  <PrimaryButton
                    className="px-6 py-2.5 mt-14"
                    type="submit"
                    isPending={formSubmitMutation.status === 'loading'}
                    size={'sm'}
                  >
                    Submit
                  </PrimaryButton>
                </div>
              )}

              {formSubmitMutation.status === 'success' && (
                <button
                  disabled
                  className="px-6 py-2.5 mt-12 text-2xl text-white-400 disabled cursor-not-allowed"
                >
                  <div className="flex flex-row mr-8 justify-center">
                    <img className="mr-4" src={getStaticMediaUrl(tick)} />
                    <span>{'Submitted'}</span>
                  </div>
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="w-full flex flex-row justify-start px-5 bg-pickled-bluewood p-4 rounded-b-md ">
          {<span className="text-xl">We will carefully store your response!</span>}
        </div>
      </div>
    </>
  );
}
