export const getNamesInSentence = (names: string[]) => {
  let result = '';

  names.forEach((name, index) => {
    if (index === 0) {
      result += name;
      return;
    }

    if (index === names.length - 1) {
      result += ` and ${name}`;
      return;
    }

    result += `, ${name}`;
  });

  return result;
};
