import React, { useState } from 'react';
import { Box, Button, Link, Heading, ExternalLinkIcon, Card, CardHeader, CardHeaderLeading, CardHeaderTrailing, CardHeaderBadge, CardBody, Text, TextInput, Amount, Divider, InfoIcon, StampIcon, ArrowDownRightIcon, CheckCircleIcon, Skeleton, Alert, BadgeProps, Tooltip, TooltipInteractiveWrapper } from '@razorpay/blade/components';
import PageLayout from 'components/ui/Layout/PageLayout';
import { ColoredDiv } from './styles';
import EstimateCardSkeleton from './components/EstimateCardSkeleton';
import { useQuery, useMutation } from 'react-query';
import { GET_EMPLOYEE_NPS_DECLARATION, UPDATE_EMPLOYEE_NPS_DECLARATION, FETCH_TAX_AND_SALARY_ESTIMATE } from './queries';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useLocation } from 'react-router-dom';
import { showToastViaEvent } from 'utils/ToastEvents';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { GetEmployeeNpsDeclarationResponse, initDeclarationValidations } from 'components/Settings/NPS/types';
import { useRef } from 'react';
import { NpsSalaryAndTaxEstimateSchemaContract } from '../../../schemas/NpsSalaryAndTaxEstimateSchema';
import { DeclarationValidations, initDeclarationData } from './types';
import { getBadgeVariant, validateDeclarationData } from './utils';
import Errors from 'components/ui/Form/Errors';
import { useEffect } from 'react';


const EmployeeDeclaration = () => {

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const employeeId = urlParams.get('employeeId');
    const [validationStates, setValidationStates] = useState<DeclarationValidations>(initDeclarationValidations);
    const userOrg = parseInt(employeeId ?? '0');
    const org = useSelector(loggedInUserSelectors.currentOrganization);
    const containerRef = useRef<HTMLDivElement>(null);
    const [initalData, setInitalData] = useState<GetEmployeeNpsDeclarationResponse>(initDeclarationData());

    const [declarationData, setDeclarationData] = useState<GetEmployeeNpsDeclarationResponse>(initDeclarationData());

    var changeInDeclarationPercentage = initalData.npsEmployeeDeclarationPercentage ? (initalData.npsEmployeeDeclarationPercentage !== declarationData.npsEmployeeDeclarationPercentage) : true;
    var changeInPran = initalData.pran ? (initalData.pran !== declarationData.pran) : true;
    var badgeVariant = getBadgeVariant(initalData);
    var basicPlusDa = (declarationData.salary.basic !== null && declarationData.salary.da !== null) ? (declarationData.salary.basic as number + declarationData.salary.da as number) : 0;
    var npsContribution = (declarationData.salary.basic !== null && declarationData.salary.da !== null && declarationData.npsEmployeeDeclarationPercentage !== null) ? (basicPlusDa * (declarationData.npsEmployeeDeclarationPercentage * 0.01)) : 0;
    const [taxEstimates, setTaxEstimates] = useState<NpsSalaryAndTaxEstimateSchemaContract>({
        estimatedTaxSavings: null,
        monthlyInHand: null,
        taxableIncome: null,
        estimatedTotalTax: null,
    });

    const disableSubmitButton = () => {

        if (!declarationData.isDeclarationWindowOpen || !declarationData.employeeCanDeclareNpsLimit) {
            return true;
        }

        else if (declarationData.isDeclarationWindowOpen && (!changeInDeclarationPercentage && !changeInPran)) {
            return true;
        }

        return false;

    }

    const { refetch, isLoading, error } = useQuery({
        queryKey: GET_EMPLOYEE_NPS_DECLARATION,
        queryFn: () => api.nps.getEmployeeNpsDeclarationConfig(userOrg),
        onSuccess: (response) => {
            setDeclarationData(response);
            setInitalData(response);
        },
        onError: (error: typeof AppError) => { },
    });

    const handleDeclarationDataChange = (
        key: keyof GetEmployeeNpsDeclarationResponse,
        value: string | boolean | number | null
    ) => {
        setDeclarationData((p) => ({ ...p, [key]: value }));
    };

    useEffect(() => {
        validateDeclarationData(declarationData, setValidationStates);
    }, [declarationData]);

    const handleSave = () => {
        const validation = validateDeclarationData(declarationData, setValidationStates, true);
        if (validation.npsDeclaration.state === 'none' && validation.pran.state === 'none') {

            org?.id && !disableSubmitButton() && updateDeclaration.mutate({
                npsEmployeeDeclarationPercentage: declarationData.npsEmployeeDeclarationPercentage,
                pran: declarationData.pran
            });

        }
    };

    const updateDeclaration = useMutation({
        mutationKey: UPDATE_EMPLOYEE_NPS_DECLARATION,
        mutationFn: (payload: Partial<GetEmployeeNpsDeclarationResponse>) => api.nps.updateEmployeeNpsDeclaration(userOrg, payload),
        onSuccess: () => {
            showToastViaEvent({
                text: 'Declaration saved successfully!',
                timeout: 3000,
                type: 'success',
                icon: 'success',
            });
            setValidationStates(initDeclarationValidations);
            refetch();
        },
        onError: (e: typeof AppError) => {
            window.requestAnimationFrame(() => {
                containerRef.current?.scrollIntoView();
            });
        },
    });

    const taxEstimate = useQuery({
        queryKey: FETCH_TAX_AND_SALARY_ESTIMATE,
        queryFn: () => api.nps.getTaxAndSalaryEstimates({ userOrg: userOrg, npsEmployeeDeclarationPercentage: declarationData.npsEmployeeDeclarationPercentage }),
        onSuccess: (data: NpsSalaryAndTaxEstimateSchemaContract) => {
            setTaxEstimates(data);
        },
        enabled: (declarationData.npsEmployeeDeclarationPercentage !== null),
        staleTime: Infinity
    });

    return (
        (<PageLayout
            title="Employer NPS"
            onBackClick={() => window.history.back()}
            RightSideLayout={
                <Link
                    variant="button"
                    icon={ExternalLinkIcon}
                    iconPosition="right"
                    onClick={() => { window.open('https://razorpay.com/docs/x/xpayroll/employees/declarations/nps/', '_blank'); }}>
                    Learn more about NPS
                </Link>
            }
            withoutBackgroundImg={true}
        >
            <Box display={'flex'} height={'100%'} flexDirection={'row'}>
                <Box width={'70%'} paddingX={'spacing.10'} paddingY={'spacing.9'} borderRightColor={'surface.border.gray.subtle'} borderRightWidth={'thick'}>
                    <Box borderRadius={'medium'}>
                        <Card
                            elevation="midRaised"
                            padding="spacing.7"
                            backgroundColor="surface.background.gray.intense"

                        >
                            <CardHeader>
                                <CardHeaderLeading title="Employer NPS" />
                                <CardHeaderTrailing visual={<CardHeaderBadge color={badgeVariant.variant as BadgeProps['color']}>{badgeVariant.text}</CardHeaderBadge>} />
                            </CardHeader>
                            <CardBody>
                                <Box display={'flex'} flexDirection={'row'} paddingLeft={'spacing.6'} paddingTop={'spacing.3'} paddingBottom={'spacing.8'}>
                                    <Box width={'20%'} >
                                        <Text weight={'semibold'} >
                                            Declaration
                                        </Text>
                                    </Box>
                                    <Box width={'80%'} display={'flex'} flexDirection={'column'} gap={'spacing.7'}>
                                        <TextInput
                                            label="Contribution"
                                            placeholder="Eg. 5"
                                            helpText={`Enter % value upto ${declarationData.npsEmployerContributionLimitPercentage ?? 10}% of your Basic`}
                                            onChange={(e) => {
                                                handleDeclarationDataChange(
                                                    'npsEmployeeDeclarationPercentage', (e.value ? parseInt(e.value) : null)
                                                );
                                            }
                                            }
                                            suffix='% of Basic'
                                            value={declarationData.npsEmployeeDeclarationPercentage ? declarationData.npsEmployeeDeclarationPercentage?.toString() : undefined}
                                            validationState={validationStates.npsDeclaration.state}
                                            errorText={validationStates.npsDeclaration.message ?? undefined}
                                            isDisabled={!declarationData.isDeclarationWindowOpen || !declarationData.employeeCanDeclareNpsLimit}
                                        />
                                        <TextInput
                                            label="PRAN"
                                            placeholder="Eg. 500071286103"
                                            value={declarationData.pran ?? ""}
                                            isDisabled={!declarationData.isDeclarationWindowOpen || !declarationData.employeeCanDeclareNpsLimit}
                                            onChange={(e) => {
                                                handleDeclarationDataChange('pran', e.value?.toString() ?? "");
                                            }}
                                            validationState={validationStates.pran.state}
                                            errorText={validationStates.pran.message ?? undefined}
                                        />
                                    </Box>
                                </Box>
                                <Divider orientation='horizontal' variant="muted" thickness='thick' />
                                <Box display={'flex'} flexDirection={'row'} paddingLeft={'spacing.6'} paddingTop={'spacing.8'} paddingBottom={'spacing.8'}>
                                    <Box width={'20%'}>
                                        <Text weight={'semibold'} >
                                            Summary
                                        </Text>
                                    </Box>
                                    <Box width={'80%'}>
                                        <Box minWidth={'274px'} width={'274px'} display={'flex'} flexDirection={'column'}>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Tooltip content="NPS contribution is calculated on the Basic + DA component of your salary" >
                                                    <TooltipInteractiveWrapper>
                                                        <Text
                                                            marginRight={'auto'}
                                                            variant={'body'}
                                                            weight='regular'
                                                            display={'flex'}
                                                            color="surface.text.gray.muted">Basic + DA<InfoIcon marginLeft={'spacing.2'} size="medium" color={'interactive.icon.gray.normal'} /></Text>
                                                    </TooltipInteractiveWrapper>
                                                </Tooltip>
                                                <Text marginLeft={'auto'}><Amount isAffixSubtle={true} suffix='decimals' type="body" size="large" weight="semibold" value={basicPlusDa} currency={'INR'} /></Text>
                                            </Box>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Text
                                                    marginRight={'auto'}
                                                    variant={'body'}
                                                    weight='regular'
                                                    color="surface.text.gray.muted">NPS Contribution</Text>
                                                <Text marginLeft={'auto'}><Amount color={npsContribution < 500 ? 'feedback.text.negative.intense' : undefined} isAffixSubtle={true} suffix='decimals' type="body" size="large" weight="semibold" value={npsContribution} currency={'INR'} /></Text>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardBody>
                        </Card>
                    </Box>
                    {error && (
                        <Box
                            ref={containerRef}
                            marginX={{ base: 'spacing.8', l: 'auto' }}
                            width="100%"
                            paddingTop="spacing.8"
                        >
                            <Alert color="negative" description={<Errors errorResponse={error} />} />
                        </Box>
                    )}
                    {updateDeclaration.error && (
                        <Box
                            ref={containerRef}
                            marginX={{ base: 'spacing.8', l: 'auto' }}
                            width="100%"
                            paddingTop="spacing.8"
                        >
                            <Alert color="negative" description={<Errors errorResponse={updateDeclaration.error} />} />
                        </Box>
                    )}
                    <Box display={'flex'} marginTop={'spacing.7'} flexDirection={'row'} gap={'spacing.7'}>
                        <Button
                            iconPosition='left'
                            icon={CheckCircleIcon}
                            variant='primary'
                            onClick={handleSave}
                            isDisabled={disableSubmitButton()}>
                            Save Declaration
                        </Button>
                        <Button variant='secondary' onClick={() => {
                            taxEstimate.refetch();
                        }
                        }>Calculate how much you'll save</Button>
                    </Box>

                </Box>
                <Box paddingX={'spacing.10'} paddingY={'spacing.9'} width={'30%'} display={'flex'} flexDirection={'column'} gap={'spacing.7'}>
                    {taxEstimate.isFetching ?
                        <>
                            <EstimateCardSkeleton />
                            <EstimateCardSkeleton />
                            <Box flexDirection={'column'} gap={'spacing.4'}>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text
                                        size={'small'}
                                        marginRight={'auto'}
                                        color="surface.text.gray.normal">Taxable Income</Text>
                                    <Skeleton height={'spacing.4'} width={'90px'} />
                                </Box>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text
                                        size={'small'}
                                        marginRight={'auto'}
                                        color="surface.text.gray.normal">Estimated Total Tax</Text>
                                    <Skeleton height={'spacing.4'} width={'90px'} />
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            <Box width={'100%'} paddingX={'spacing.6'} paddingY={'spacing.7'} borderRadius={'medium'} backgroundColor={'surface.background.gray.intense'} borderColor={'surface.border.gray.subtle'} borderWidth={'thick'}>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <ColoredDiv color='rgba(0, 156, 92, 0.18)'>
                                        <StampIcon color={'feedback.icon.positive.intense'} size={'large'} />
                                    </ColoredDiv>
                                    <Box display={'flex'} flexDirection={'column'} marginLeft={'spacing.5'} marginBottom={'spacing.4'}>
                                        <Text size="large">
                                            You’ll save
                                        </Text>
                                        <Text
                                            size="small"
                                            color="surface.text.gray.muted"
                                        >
                                            est. tax savings under this section
                                        </Text>
                                    </Box>
                                </Box>
                                <Amount
                                    value={taxEstimates.estimatedTaxSavings ? taxEstimates.estimatedTaxSavings : 0}
                                    isAffixSubtle
                                    suffix='decimals'
                                    currencyIndicator='currency-symbol'
                                    type="heading"
                                    size="large" /> :
                            </Box>

                            <Box width={'100%'} paddingX={'spacing.6'} paddingY={'spacing.7'} borderRadius={'medium'} backgroundColor={'surface.background.gray.intense'} borderColor={'surface.border.gray.subtle'} borderWidth={'thick'}>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <ColoredDiv color='rgba(0, 140, 177, 0.18)'>
                                        <ArrowDownRightIcon size="large" color="feedback.icon.information.intense" />
                                    </ColoredDiv>
                                    <Box display={'flex'} flexDirection={'column'} marginLeft={'spacing.5'} marginBottom={'spacing.4'}>
                                        <Text size="large">
                                            You’ll receive
                                        </Text>
                                        <Text
                                            size="small"
                                            color="surface.text.gray.muted"
                                        >
                                            est. monthly in-hand salary
                                        </Text>
                                    </Box>
                                </Box>
                                <Amount
                                    value={taxEstimates.monthlyInHand ? taxEstimates.monthlyInHand : 0}
                                    isAffixSubtle
                                    suffix='decimals'
                                    currencyIndicator='currency-symbol'
                                    type="heading"
                                    size="large" />
                            </Box>
                            <Box gap={'spacing.5'}>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text
                                        variant='body'
                                        size={'small'}
                                        marginRight={'auto'}
                                        marginTop={'spacing.2'}
                                        color="surface.text.gray.normal">Taxable Income</Text>
                                    <Amount marginLeft={'auto'} value={taxEstimates.taxableIncome ?? 0} />
                                </Box>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text
                                        variant='body'
                                        size={'small'}
                                        marginRight={'auto'}
                                        marginTop={'spacing.2'}
                                        color="surface.text.gray.normal">Estimated Total Tax</Text>
                                    <Amount marginLeft={'auto'} value={taxEstimates.estimatedTotalTax ?? 0} />
                                </Box>
                            </Box>
                        </>}
                </Box>
            </Box>
        </PageLayout>)
    );
}

export default EmployeeDeclaration;