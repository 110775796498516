import styled from 'styled-components';

export const TableHeadCell = styled.th<{
  isLastCell?: boolean;
  isFirstCell?: boolean;
  isSticky?: boolean;
  backgroundColor?: string;
}>(
  ({ theme, isLastCell, isFirstCell, isSticky, backgroundColor }) => `
  text-align: left;
  padding: ${theme.spacing[4]}px ${theme.spacing[5]}px;
  padding-right: ${isLastCell ? theme.spacing[8] : theme.spacing[5]}px;
  padding-left: ${isFirstCell ? theme.spacing[8] : theme.spacing[5]}px;
  cursor: pointer;
  background-color: ${theme.colors.interactive.background.gray.default};

  ${isSticky ? `position: sticky; left: 0; top:0; background-color:${backgroundColor}` : ''}
`,
);

export const TableHeadHoverTrayCell = styled.th`
  width: 0px;
`;

export const TableDataCell = styled.td<{
  isLastCell?: boolean;
  isFirstCell?: boolean;
  isSticky?: boolean;
  backgroundColor?: string;
}>(
  ({ theme, isLastCell, isFirstCell, isSticky, backgroundColor }) => `
  padding: ${theme.spacing[4]}px ${theme.spacing[5]}px;
  padding-right: ${isLastCell ? theme.spacing[8] : theme.spacing[5]}px;
  padding-left: ${isFirstCell ? theme.spacing[8] : theme.spacing[5]}px;
  color: ${theme.colors.surface.text.gray.muted};
  ${isSticky ? `position: sticky; left: 0; top:0; background-color:${backgroundColor}` : ''}
`,
);

export const TableCheckboxCell = styled.td(
  ({ theme }) => `
    padding: ${theme.spacing[4]}px 0 ${theme.spacing[4]}px ${theme.spacing[8]}px;
`,
);

export const TableContainer = styled.table<{ layout: string }>`
  table-layout: ${(props) => props.layout};
  width: 100%;
  flex-grow: 1;
`;

export const TableRow = styled.tr<{
  isClickable?: boolean;
  isChecked?: boolean;
  isClicked?: boolean;
}>(
  ({ theme, isClickable, isChecked, isClicked }) => `
    position: relative;
    cursor: ${isClickable ? 'pointer' : 'inherit'};
    background-color: ${
      isChecked || isClicked ? theme.colors.surface.background.gray.moderate : 'inherit'
    };
    ${
      isChecked
        ? `& > td { background-color: ${theme.colors.surface.background.gray.moderate};}`
        : ''
    }
   
    &:hover {
        background-color: ${
          isChecked
            ? theme.colors.surface.background.gray.moderate
            : theme.colors.surface.background.gray.moderate
        };
    }

    &:hover > td {
      background-color: inherit
    }
`,
);

export const HoverTrayContainer = styled.td<{ isVisible?: boolean; isChecked?: boolean }>(
  ({ theme, isVisible, isChecked }) => `
    position: absolute;
    right: ${theme.spacing[0]};
    z-index: 1;
    height: 100%;
    display: ${isVisible ? 'block' : 'none'};
    background-color: ${
      isChecked
        ? theme.colors.surface.background.gray.intense
        : theme.colors.surface.background.gray.intense
    };
`,
);
