import { getEditEmployeeInsuranceDetailsRoute } from 'components/Routes/data';
import insurancePlanBlocked from 'constants/insurancePlanBlocked';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { camelCaseToWords, capitalizeFirstLetter } from 'utils/Strings';
import { IInsurance } from '../../../../reducers/insurance';
import { sendReminder } from '../../../../reducers/insuranceEmployee';
import {
  IInsuranceEndorsements,
  InsuranceEndorsementEmployeeObject,
  fetchInsuranceEndorsements,
  fetchNextInsuranceEndorsements,
  purchaseEndorsement,
} from '../../../../reducers/insuranceEndorsements';
import { GetAllEndorsementsContract } from '../../../../schemas/GetAllEndorsements';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import useReduxSelector from '../../../../utils/useReduxSelector';
import { SuccessAlert, WarningAlert } from '../../../ui/Alert';
import Breadcrumb from '../../../ui/Breadcrumb';
import { SecondaryButton } from '../../../ui/Button';
import { SimpleListLoader } from '../../../ui/Loaders';
import { Modal } from '../../../ui/Modal';
import { Table, TableLayout } from '../../../ui/Table';
import InsuranceDataCollectionProgressCard from '../../InsuranceDataCollectionProgressCard';
import { ShowBalanceConfirmationModal } from '../../ShowBalanceConfirmationModal';
import { EmployeesListBox } from '../EmployeesListBox';
import { getConditionsNotMetForEndorsement } from '../computeMinRequirements';

const totalSpouse = (employeeObject: InsuranceEndorsementEmployeeObject) =>
  employeeObject.dependents.filter((dependent) => ['spouse'].includes(dependent.relation)).length;
const totalParents = (employeeObject: InsuranceEndorsementEmployeeObject) =>
  employeeObject.dependents.filter((dependent) =>
    ['father', 'mother', 'fatherInLaw', 'motherInLaw'].includes(dependent.relation),
  ).length;
const totalChildren = (employeeObject: InsuranceEndorsementEmployeeObject) =>
  employeeObject.dependents.filter((dependent) => ['son', 'daughter'].includes(dependent.relation))
    .length;

function QuoteBox({
  insuranceEndorsements,
  orgBalance,
  insurance,
}: {
  insuranceEndorsements: IInsuranceEndorsements;
  orgBalance: number;
  insurance: IInsurance;
}) {
  const dispatch = useDispatch();
  const totalEmployeesGivenDetails = insuranceEndorsements.data
    ? insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length
    : 0;
  const totalEligibleEmployees = insuranceEndorsements.data
    ? insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length +
      insuranceEndorsements.data.summary.employeeIdsWhoseDetailsAreMissing.length
    : 0;
  const conditionsNotMet = getConditionsNotMetForEndorsement(insuranceEndorsements);
  return (
    <div className={`bg-rhino p-5 relative w-full space-y-5`}>
      {insuranceEndorsements.isFetching && insuranceEndorsements.data === null && (
        <div
          className={`${
            insuranceEndorsements.isFetching ? '' : 'opacity-0'
          } absolute right-0 left-0 top-0 bottom-0 flex flex-col text-center`}>
          <div className="">loading</div>
        </div>
      )}

      <div className="font-bold text-4xl font-heading">Quote</div>
      <div className="text-normal text-secondary">
        Quotation includes only those employees who have filled their insurance details.
      </div>

      <TableLayout className={`w-full`}>
        <tr className="h-20">
          <td className="text-3xl">Total Lives</td>
          <td className="font-semibold text-right text-primary-dark text-3xl">
            {insuranceEndorsements.data
              ? (
                  insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length +
                  insuranceEndorsements.data.summary.newDependentsForNewEmployeeIds.length
                ).toLocaleString()
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl">Cost of insurance</td>
          <td className="font-semibold text-right text-primary-dark text-3xl">
            {insuranceEndorsements.data
              ? toIndianCurrencyString(insuranceEndorsements.data.summary.amount)
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl">GST (18%)</td>
          <td className="font-semibold text-right text-primary-dark text-3xl">
            {insuranceEndorsements.data
              ? toIndianCurrencyString(insuranceEndorsements.data.summary.gst)
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-4xl font-semibold font-heading">Grand Total</td>
          <td className="font-semibold text-right text-primary text-5xl">
            {insuranceEndorsements.data
              ? toIndianCurrencyString(insuranceEndorsements.data.summary.totalAmount)
              : 'N/A'}
          </td>
        </tr>
      </TableLayout>
      {conditionsNotMet.length > 0 ? (
        <ul className="space-y-1 mt-2 list-disc px-10 bg-persian-blue py-4">
          {conditionsNotMet.map((message) => (
            <li className="text-3xl font-bold"> {message} </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      <ShowBalanceConfirmationModal
        disabled={
          !insuranceEndorsements.data ||
          totalEmployeesGivenDetails < totalEligibleEmployees ||
          conditionsNotMet.length > 0 ||
          insuranceEndorsements.data.summary.totalAmount === 0 ||
          insuranceEndorsements.isPurchased ||
          (insurance.data?.planId !== undefined &&
            insurance.data?.planId !== null &&
            insurancePlanBlocked[insurance.data?.planId])
        }
        purchaseCallback={() => {
          dispatch(purchaseEndorsement());
        }}
        totalAmount={
          insuranceEndorsements.data ? insuranceEndorsements.data.summary.totalAmount : 0
        }
        orgBalance={orgBalance}
      />
      {/* <PrimaryButton className="w-full" onClick={finalize} disabled={!insuranceEndorsements.data || totalEmployeesGivenDetails < totalEligibleEmployees}>PURCHASE</PrimaryButton> */}
      {(!insuranceEndorsements.data || totalEmployeesGivenDetails < totalEligibleEmployees) && (
        <div className="">You can proceed to purchase after collecting all employees details</div>
      )}
    </div>
  );
}

function EmployeesTable({
  insuranceEndorsements,
}: {
  insuranceEndorsements: IInsuranceEndorsements;
}) {
  const dispatch = useDispatch();
  if (insuranceEndorsements.isNeverFetched) {
    return <></>;
  }

  if (
    insuranceEndorsements.isFetching &&
    insuranceEndorsements.fetchingFresh &&
    !insuranceEndorsements.fetchingSilently
  ) {
    return <SimpleListLoader>Fetching employees</SimpleListLoader>;
  }

  if (insuranceEndorsements.hasError) {
    return (
      <div className="w-full text-center py-10 font-bold">
        {insuranceEndorsements.errorData?.messageForUser}
      </div>
    );
  }

  if (insuranceEndorsements.data.total === 0) {
    return <div className="w-full text-center py-10 font-bold">No Employees</div>;
  }

  const premiumBreakup = insuranceEndorsements.data.summary.premiumBreakup;

  return (
    <>
      <div className="text-primary font-semibold block sm:hidden">
        Total: {insuranceEndorsements.data.total}
      </div>
      <Table className="break-all md:break-normal w-full mt-8">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Employee ID</th>
          <th>Add-ons</th>
          <th>Premium</th>
          <th></th>
          <th></th>
        </tr>
        {insuranceEndorsements.data.employees.map((employee) => {
          const premium = premiumBreakup['newEmployeesWithDependents'][employee.id]
            ? premiumBreakup['newEmployeesWithDependents'][employee.id]
            : 0;
          return (
            <tr>
              <td>{employee.name}</td>
              <td>{employee.email}</td>
              <td>{employee.isDataCollected ? 'Data collected' : 'Missing details'}</td>
              <td>{employee.employeeID}</td>
              <td>
                {[
                  totalSpouse(employee) ? '' + totalSpouse(employee) + ' Spouse' : null,
                  totalParents(employee) ? '' + totalParents(employee) + ' Parent(s)' : null,
                  totalChildren(employee) ? '' + totalChildren(employee) + ' Children' : null,
                ]
                  .filter((toShow) => toShow !== null)
                  .join(' + ')}
              </td>
              <td>
                {premium > 0 ? (
                  <PremiumBreakup
                    premiumBreakup={insuranceEndorsements.data.summary.premiumBreakup}
                    employee={employee}>
                    {toIndianCurrencyString(premium)}
                  </PremiumBreakup>
                ) : (
                  toIndianCurrencyString(premium)
                )}
              </td>
              <td>
                {' '}
                <Link
                  to={getEditEmployeeInsuranceDetailsRoute(employee.id)}
                  className="text-primary font-bold">
                  {' '}
                  Edit{' '}
                </Link>
              </td>
            </tr>
          );
        })}
      </Table>
      {insuranceEndorsements.data.employees.length < insuranceEndorsements.data.total && (
        <div className="flex justify-center">
          <SecondaryButton
            onClick={() => dispatch(fetchNextInsuranceEndorsements())}
            disabled={insuranceEndorsements.isFetching && !insuranceEndorsements.fetchingFresh}>
            {' '}
            {insuranceEndorsements.isFetching && !insuranceEndorsements.fetchingFresh
              ? 'Loading...'
              : 'Load more'}{' '}
          </SecondaryButton>
        </div>
      )}
    </>
  );
}

function InsuranceEndorsements({
  insuranceEndorsements,
  insurance,
}: {
  insuranceEndorsements: IInsuranceEndorsements;
  insurance: IInsurance;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  if (
    !insuranceEndorsements.isNeverFetched &&
    !insuranceEndorsements.isFetching &&
    insuranceEndorsements.data.summary.totalMissingEmployeeDetails +
      insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length ===
      0
  ) {
    return (
      <div>
        <Breadcrumb
          name="Endorsements"
          urlMaps={{
            'Employee details': '/insurance/admin/details',
          }}
        />
        <div className="text-center text-secondary font-heading text-4xl"> No new endorsements</div>
        <div className="flex justify-center mt-10">
          <SecondaryButton onClick={() => history.goBack()}>Go back</SecondaryButton>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb
        name="Endorsements"
        urlMaps={{
          'Employee details': '/insurance/admin/details',
        }}
      />

      <div className="font-bold">Insurance endorsements</div>

      {insuranceEndorsements.isPurchased && (
        <SuccessAlert className="mt-5">
          {' '}
          Endorsement of amount{' '}
          <span className="font-bold">
            {toIndianCurrencyString(insuranceEndorsements.data.summary.totalAmount)}
          </span>{' '}
          is done successfully.
        </SuccessAlert>
      )}
      {insuranceEndorsements.purchaseErrorData && (
        <WarningAlert className="mt-5">
          {' '}
          Looks like something went wrong while processing the endorsement. Please contact
          administrator.{' '}
        </WarningAlert>
      )}

      <div className="flex flex-col-reverse space-y-10 space-y-reverse lg:space-y-0 lg:flex-row py-10">
        <div className="flex-1 space-y-12">
          <InsuranceDataCollectionProgressCard
            className=""
            areEstimatesAvailable={true}
            totalEligibleEmployees={
              insuranceEndorsements.data.summary.totalMissingEmployeeDetails +
              insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length
            }
            totalEmployeesGivenDetails={
              insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length
            }
            totalEmployees={
              insuranceEndorsements.data.summary.totalMissingEmployeeDetails +
              insuranceEndorsements.data.summary.newEmployeeIdsWithDependents.length
            }
          />
          <EmployeesListBox
            search={
              insuranceEndorsements.fetchedFor.search
                ? insuranceEndorsements.fetchedFor.search
                : null
            }
            totalEmployees={insuranceEndorsements.data.total}
            onSearch={(event) =>
              insurance.data?.id &&
              dispatch(
                fetchInsuranceEndorsements({
                  ...insuranceEndorsements.fetchedFor,
                  search: event.currentTarget.value,
                  insuranceId: insurance.data?.id,
                  fetchFresh: true,
                }),
              )
            }
            onConfirmSendReminder={(setShowModal: (show: boolean) => any) => () => {
              insurance.data && insurance.data.id && dispatch(sendReminder(insurance.data.id));
              setShowModal(false);
            }}
            onChangeFilter={(event) =>
              dispatch(
                fetchInsuranceEndorsements({
                  ...insuranceEndorsements.fetchedFor,
                  filters:
                    event.currentTarget.value === 'MISSING_DETAILS' ||
                    event.currentTarget.value === 'DATA_COLLECTED'
                      ? [event.currentTarget.value]
                      : [],
                }),
              )
            }
            isFetching={insuranceEndorsements.isFetching && !insuranceEndorsements.fetchingSilently}
            currentFilters={
              insuranceEndorsements.fetchedFor?.filters
                ? insuranceEndorsements.fetchedFor.filters
                : []
            }
            filters={{
              MISSING_DETAILS: 'Missing details',
              DATA_COLLECTED: 'Data collected',
            }}
            totalMissingEmployees={insuranceEndorsements.data.summary.totalMissingEmployeeDetails}>
            <EmployeesTable insuranceEndorsements={insuranceEndorsements} />
          </EmployeesListBox>
        </div>
        <div className="lg:px-16 w-full lg:w-2/5">
          <div>
            <QuoteBox
              insuranceEndorsements={insuranceEndorsements}
              orgBalance={insuranceEndorsements.data.currentOrganizationBalance}
              insurance={insurance}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ({ insurance }: { insurance: IInsurance }) {
  const dispatch = useDispatch();

  const insuranceEndorsements = useReduxSelector((state) => state.insuranceEndorsements);

  useEffect(() => {
    if (insurance.data?.id) {
      dispatch(fetchInsuranceEndorsements({ insuranceId: insurance.data.id, fetchFresh: true }));
    }
  }, [insurance.data?.id]);

  return (
    <InsuranceEndorsements insuranceEndorsements={insuranceEndorsements} insurance={insurance} />
  );
}

function PremiumBreakup({
  employee,
  children,
  premiumBreakup,
}: PropsWithChildren<{
  employee: GetAllEndorsementsContract['employees'][0];
  premiumBreakup: GetAllEndorsementsContract['summary']['premiumBreakup'];
}>) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        className="cursor-pointer underline text-primary font-bold"
        onClick={() => setShowModal(true)}>
        {children}
      </span>
      <Modal showModal={showModal} heading="Premium Breakup" closeModal={() => setShowModal(false)}>
        <TableLayout className="w-full">
          <tr>
            <th>Name</th>
            <th>Relation</th>
            <th>Premium</th>
          </tr>
          <tr>
            <td>{employee.name}</td>
            <td>Self</td>
            <td>
              {toIndianCurrencyString(
                premiumBreakup['newEmployees'][employee.id]
                  ? premiumBreakup['newEmployees'][employee.id]
                  : 0,
              )}
            </td>
          </tr>
          {employee.dependents.map((dependent) => (
            <tr>
              <td>{dependent.name}</td>
              <td>{capitalizeFirstLetter(camelCaseToWords(dependent.relation))}</td>
              <td>
                {toIndianCurrencyString(
                  premiumBreakup['newDependents'][dependent.id]
                    ? premiumBreakup['newDependents'][dependent.id]
                    : 0,
                )}
              </td>
            </tr>
          ))}
        </TableLayout>
      </Modal>
    </>
  );
}
