import { useWizardInfo } from 'components/WizardViews/providers/WizardInfoProvider';
import React from 'react';

const WizardStep: React.FC<{ component?: React.FC; step: number }> = ({ component: Component }) => {
  if (Component) {
    return <Component />;
  } else {
    throw new Error('No component to render');
  }
};

const Wizard: React.FC<{
  children: React.ReactElement<typeof WizardStep>[];
}> = ({ children }) => {
  const { currentStep } = useWizardInfo();
  return (
    <>
      {React.Children.map(children, (child) => {
        if (
          React.isValidElement<React.ComponentProps<typeof WizardStep>>(child) &&
          child.props.step === currentStep
        ) {
          return <>{child}</>;
        }
      })}
    </>
  );
};

export { Wizard, WizardStep };
