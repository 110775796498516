import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  useToast,
} from '@razorpay/blade/components';
import { useMutation, useQueryClient } from 'react-query';
import attendance from 'api/attendance';
import { ErrorsAlert } from 'components/ui/Form/Errors';
import { AppError } from 'utils/AppError';

interface DeleteRequestsButtonProps {
  selectedRequests: number[];
  onSuccess: () => void;
}

export const DeleteRequestsButton: React.FC<DeleteRequestsButtonProps> = ({
  selectedRequests,
  onSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();

  const deleteMutation = useMutation((data: { ids: number[] }) => attendance.deleteRequests(data), {
    onSuccess: (data) => {
      toast.show({
        content: data.message,
        color: 'positive',
      });
      queryClient.invalidateQueries(['attendance']);
      queryClient.invalidateQueries(['attendance-today']);
      setIsModalOpen(false);
      onSuccess();
    },
    onError: (error: typeof AppError) => {
      console.error('Error deleting requests:', error);
    },
  });

  const handleDelete = () => {
    deleteMutation.mutate({ ids: selectedRequests });
  };

  return (
    <>
      <Button
        variant="primary"
        color="negative"
        isDisabled={selectedRequests.length === 0}
        onClick={() => setIsModalOpen(true)}>
        Delete Requests
      </Button>
      <Modal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} size="small">
        <ModalHeader title="Confirm Deletion" />
        <ModalBody>
          <Text>Are you sure you want to delete the selected requests?</Text>
          {deleteMutation.error && (
            <Box marginTop="spacing.4">
              <ErrorsAlert errorResponse={deleteMutation.error} />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" gap="spacing.3" justifyContent="flex-end" width="100%">
            <Button
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
              isDisabled={deleteMutation.isLoading}>
              Cancel
            </Button>
            <Button
              variant="primary"
              color="negative"
              onClick={handleDelete}
              isLoading={deleteMutation.isLoading}>
              Delete
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
    </>
  );
};
