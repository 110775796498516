import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import pluralize from 'pluralize';

import api from 'api';
import Breadcrumb from 'components/ui/Breadcrumb';
import { PrimaryButton } from 'components/ui/Button';
import { TableWithLoader } from 'components/ui/Table';
import { AppError } from 'utils/AppError';
import BankSelector from './BankSelector';
import { UpdatePaymentBankMappingData } from 'api/compliance';
import { CheckBox, Input, InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';

const GET_PAYMENT_BANK_MAPPING = 'GET_PAYMENT_BANK_MAPPING';

const PaymentBankMapping = () => {
  const [updateData, setUpdateData] = useState<UpdatePaymentBankMappingData[]>([]);
  const [processingCount, setProcessingCount] = useState<number>(0);

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch, error } = useQuery(
    GET_PAYMENT_BANK_MAPPING,
    () => {
      return api.compliance.getPaymentBankMapping();
    },
    {
      onSuccess: (res) => {
        setUpdateData([]);
        setProcessingCount(res.processing_count);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const update = useMutation(
    (updateData: UpdatePaymentBankMappingData[]) => {
      return api.compliance.updatePaymentBankMapping(updateData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PAYMENT_BANK_MAPPING);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  const updateCount = useMutation(
    (count: number) => {
      return api.compliance.updateProcessingCount(count);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_PAYMENT_BANK_MAPPING);
      },
      onError: (error: typeof AppError) => {},
    },
  );

  if (!data) {
    return null;
  }

  const onChangeValues = (payment: string, bank: string, isActive: boolean, priority: number) => {
    const shouldUpdate = !data.payment_mapping.find(
      (mapping) => mapping.paymentType == payment 
        && mapping.bankName == bank 
        && mapping.isActive == isActive
        && mapping.priority == priority
    );
    if (shouldUpdate) {
      const newUpdateData = updateData.filter((data) => data.payment !== payment);

      setUpdateData([
        ...newUpdateData,
        {
          payment: payment,
          bank: bank,
          isActive: isActive,
          priority: priority
        },
      ]);
    } else {
      setUpdateData(updateData.filter((data) => data.payment !== payment));
    }
  };

  const getBankValue = (oldValue: string, paymentType: string) => {
    const newValue = updateData.find((data) => data.payment == paymentType)?.bank;
    return newValue ? newValue : oldValue;
  };

  const getIsActive = (oldValue: boolean, paymentType: string) => {
    const newValue = updateData.find((data) => data.payment == paymentType);
    return newValue ? newValue.isActive : oldValue;
  };

  const getPriority = (oldValue: number, paymentType: string) => {
    const newValue = updateData.find((data) => data.payment == paymentType);
    return newValue ? newValue.priority : oldValue;
  };

  const onUpdate = () => {
    update.mutate(updateData);
  };

  return (
    <>
      <Breadcrumb
        name="Payment Type Bank Mapping"
        urlMaps={{
          'Compliance Service': `/admin/indexComplianceService`,
        }}
        className="pb-0"
      />
      <div style={{ width: '700px' }}>
        <div className="font-bold text-4xl mt-20">Map the payment type with respective banks</div>
        <TableWithLoader isLoading={isLoading || isRefetching}>
          <tbody>
            <tr>
              <th style={{ width: '200px' }}>Payment Type</th>
              <th style={{ width: '300px' }}>Bank</th>
              <th style={{ width: '300px' }}>Active</th>
              <th style={{ width: '300px' }}>Priority</th>
              <th style={{ width: '300px' }}>Task Count</th>
            </tr>
            {data.payment_mapping?.map((paymentMapping) => {
              const isActive = getIsActive(paymentMapping.isActive, paymentMapping.paymentType);
              const priority = getPriority(paymentMapping.priority, paymentMapping.paymentType);
              const bank = getBankValue(paymentMapping.bankName, paymentMapping.paymentType);
              return (
                <tr key={paymentMapping.paymentType}>
                  <td>{paymentMapping.paymentType}</td>
                  <td>
                    <BankSelector
                      value={bank}
                      options={data.banks}
                      onChange={(event) => {
                        onChangeValues(paymentMapping.paymentType, event.target.value, isActive, priority);
                      }}
                    />
                  </td>
                  <td>
                    <CheckBox
                      checked={isActive}
                      onChange={(event) => {
                        onChangeValues(paymentMapping.paymentType, bank, event.target.checked, priority);
                      }}
                      disabled={isLoading || isRefetching || update.isLoading}
                    />
                  </td>
                  <td>
                  <Input type='text' value={priority} onChange={(event) => {
                      const numberValue = +event.target.value;
                      if(!isNaN(numberValue)) {
                        onChangeValues(paymentMapping.paymentType, bank, isActive, numberValue);
                      }
                    }}
                    disabled={isLoading || isRefetching || update.isLoading}
                    />
                  </td>
                  <td>
                    {data.pending_task_count[paymentMapping.paymentType]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableWithLoader>
        <p style={{fontSize: '12px'}}>* Only active payment task entries will get picked up</p>
        <p style={{fontSize: '12px'}}>* Priority number indicates the order in which the payment tasks are picked up, lower the number higher the priority</p>
        <PrimaryButton
          onClick={onUpdate}
          isPending={isLoading || isRefetching || update.isLoading}
          size={'sm'}
          className={'mt-5'}
          disabled={updateData.length == 0}
          >
          Save{' '}
          {updateData.length > 0 &&
            `(${updateData.length} ${pluralize('change', updateData.length)})`}
        </PrimaryButton>
        <div className='mt-16'>
          <InputElement label="Compliance Payments - Task Processing Count" className="my-auto mt-4 mb-14">
            <Input
              type="text"
              maxLength={255}
              value={processingCount}
              name=""
              onChange={(event) => setProcessingCount(+event.target.value)}
              readOnly={updateCount.isLoading}
            />
            <InputInfo>
              This count is used to schedule the exact number of payment task per cycle.
            </InputInfo>
            <PrimaryButton
              onClick={() => updateCount.mutate(processingCount)}
              isPending={isLoading || isRefetching || updateCount.isLoading}
              size={'sm'}
              className={'mt-5'}
              disabled={data.processing_count == processingCount}
            >
            Update
          </PrimaryButton>
          </InputElement>
        </div>
      </div>
    </>
  );
};

export default PaymentBankMapping;
