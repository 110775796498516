import React, { Fragment, PropsWithChildren } from 'react';
import { RearchFeatureNames } from './types';
import useIsFeatureDisabledForRearch from './useIsFeatureDisabledForRearch';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

type Props = PropsWithChildren<{
  feature: RearchFeatureNames | RearchFeatureNames[];
  enabledForFeature?: RearchFeatureNames | RearchFeatureNames[];
  redirect?: boolean;
}>;

/**
 * Component to conditionally render children based on feature flags.
 *
 * This component uses the `useIsFeatureDisabledForRearch` hook to determine if a feature is disabled
 * or enabled based on the feature flags provided in the Redux store.
 *
 * @param {Props} props - The props for the component.
 * @param {React.ReactNode} props.children - The children to render if the feature is enabled.
 * @param {RearchFeatureNames | RearchFeatureNames[]} props.feature - The feature or list of features to check if disabled.
 * @param {RearchFeatureNames | RearchFeatureNames[]} [props.enabledForFeature] - The feature or list of features to check if enabled.
 * @param {boolean} [props.redirect] - Whether to redirect to an unknown route if the feature is disabled.
 *
 * @returns {React.ReactNode} - The rendered children if the feature is enabled, null if the feature is disabled and no redirect is specified,
 * or a redirect component if the feature is disabled and redirect is specified.
 *
 * The logic works as follows:
 * - It fetches the disabled features using the `useIsFeatureDisabledForRearch` hook.
 * - It checks if the provided `feature` or any of the features in the `feature` array are disabled.
 * - It checks if the provided `enabledForFeature` or any of the features in the `enabledForFeature` array are enabled.
 * - If any feature in `enabledForFeature` is enabled, it renders the children.
 * - If any feature in `feature` is disabled and `redirect` is true, it redirects to the unknown route.
 * - If any feature in `feature` is disabled and `redirect` is false, it returns null.
 * - If none of the above conditions are met, it renders the children.
 *
 * Example usage:
 * ```tsx
 * <IsFeatureDisabledForRearch feature="mileStone1" redirect>
 *   <div>This content will only be shown if "mileStone1" is enabled.</div>
 * </IsFeatureDisabledForRearch>
 *
 * <IsFeatureDisabledForRearch feature="isAdditionsDisabled" enabledForFeature="milestone2" redirect>
 *   <div>This content will only be shown if "isAdditionsDisabled" is enabled and "milestone2" is enabled.</div>
 * </IsFeatureDisabledForRearch>
 * ```
 */
const IsFeatureDisabledForRearch = ({ children, feature, enabledForFeature, redirect }: Props) => {
  const isFeatureDisabledMap = useIsFeatureDisabledForRearch();

  const isDisabled = Array.isArray(feature)
    ? feature.some((item) => isFeatureDisabledMap[item])
    : isFeatureDisabledMap[feature];

  let isEnabled = false;
  if (Array.isArray(enabledForFeature)) {
    isEnabled = enabledForFeature.some((item) => isFeatureDisabledMap[item]);
  } else if (enabledForFeature) {
    isEnabled = isFeatureDisabledMap[enabledForFeature];
  }

  if (isEnabled) {
    return <Fragment>{children}</Fragment>;
  } else if (isDisabled) {
    if (redirect) {
      return <Redirect to={routePaths.unknown} />;
    }
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default IsFeatureDisabledForRearch;
