import React, { ChangeEventHandler } from 'react';
import { ReactElement } from 'react';

const FileInputBlock = ( {label, changeHandler, condition, id, value, sampleFile, warningMessage } : 
    { 
        label:string,
        changeHandler: ChangeEventHandler<HTMLInputElement>, 
        condition?: boolean | null, 
        id:string, 
        value:string | null ,
        sampleFile? : string | null,
        warningMessage?: string | null,
    }  
    ) : ReactElement => {
    let comp = <></>;
    if(condition){
        comp =  (<>
            <div className='mb-4'>
                <label>{label} *</label>
            </div>
            
            <div className='mb-1'>
                <label><a href={ sampleFile? sampleFile : ''} target="_blank" className={` text-primary font-bold ${ sampleFile ? '' : 'hidden' }`}>View Sample File</a></label>
            </div>

            <div>
                { warningMessage !== null && (<span className='text-yellow-500'>{'Note : ' + warningMessage}</span>) }
            </div>

            <div className='mb-8'>
                <div>
                    <input type="file" id={id} name={id} onChange={changeHandler} className="inputfile" required/>
                    <label htmlFor={id} className='label--file w-2/3'>
                        <span className="label--span w-4/5">{value? value:''}</span>
                        <strong className='centered'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/></svg> Choose file(s)&hellip;</strong>
                    </label>
                </div>
                
            </div>
        </>);
    }
    return comp;

}

export default FileInputBlock;