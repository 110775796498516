import {
  ActionList,
  ActionListItem,
  ActionListItemIcon,
  ActionListSection,
  CheckCircleIcon,
  Dropdown,
  DropdownLink,
  DropdownOverlay,
  FilterIcon,
  UserCheckIcon,
  UserXIcon,
  XCircleIcon,
} from '@razorpay/blade/components';
import React from 'react';
import { useFilters } from '../useFilters';
import { WorkflowRequestsFiltersMap, WorkflowRequestsStatusLabelsMap } from '../constants';
import { useLocation } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';

interface StatusFilterProps {
  isDisabled?: boolean;
}

const StatusFilter = ({ isDisabled }: StatusFilterProps) => {
  const { applyFilter } = useFilters();
  const location = useLocation();
  const pathname = location.pathname;

  const handleApprovedRequestsClick = () => {
    applyFilter(WorkflowRequestsFiltersMap.status, WorkflowRequestsStatusLabelsMap.approved);
  };

  const handleRejectedRequestsClick = () => {
    applyFilter(WorkflowRequestsFiltersMap.status, WorkflowRequestsStatusLabelsMap.rejected);
  };

  const handleApprovedByMeRequestsClick = () => {
    applyFilter(WorkflowRequestsFiltersMap.status, WorkflowRequestsStatusLabelsMap.approved_by_me);
  };

  const handleRejectedByMeRequestsClick = () => {
    applyFilter(WorkflowRequestsFiltersMap.status, WorkflowRequestsStatusLabelsMap.rejected_by_me);
  };

  const initiatedByMeFilterContent = [
    <ActionListItem
      leading={<ActionListItemIcon icon={CheckCircleIcon} />}
      title="Approved requests"
      value="approvedRequests"
      onClick={() => {
        handleApprovedRequestsClick();
      }}
    />,
    <ActionListItem
      leading={<ActionListItemIcon icon={UserXIcon} />}
      title="Rejected requests"
      value="rejectedRequests"
      onClick={handleRejectedRequestsClick}
    />,
  ];

  const completedByMeFiltersContent = [
    <ActionListItem
      leading={<ActionListItemIcon icon={UserCheckIcon} />}
      title="Approved by me"
      value="approvedRequests"
      onClick={handleApprovedByMeRequestsClick}
    />,
    <ActionListItem
      leading={<ActionListItemIcon icon={CheckCircleIcon} />}
      title="All approved requests"
      value="approvedRequests"
      onClick={() => {
        handleApprovedRequestsClick();
      }}
    />,
    <ActionListItem
      leading={<ActionListItemIcon icon={UserXIcon} />}
      title="Rejected by me"
      value="rejectedRequests"
      onClick={handleRejectedByMeRequestsClick}
    />,
    <ActionListItem
      leading={<ActionListItemIcon icon={XCircleIcon} />}
      title="All rejected requests"
      value="rejectedRequests"
      onClick={handleRejectedRequestsClick}
    />,
  ];

  return (
    <Dropdown>
      <DropdownLink icon={FilterIcon} iconPosition="right" isDisabled={isDisabled}>
        Filters
      </DropdownLink>
      <DropdownOverlay>
        <ActionListSection title="Filter by">
          <ActionList>
            {((pathname) => {
              switch (pathname) {
                case routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe:
                case routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe:
                  return initiatedByMeFilterContent;
                case routePaths.approvalWorkflows.myRequests.editPayroll.completed:
                case routePaths.approvalWorkflows.myRequests.finalizePayroll.completed:
                  return completedByMeFiltersContent;
                default:
                  return initiatedByMeFilterContent;
              }
            })(pathname)}
          </ActionList>
        </ActionListSection>
      </DropdownOverlay>
    </Dropdown>
  );
};

export { StatusFilter };
