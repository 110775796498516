import api from 'api';
import BulkUploadsPolling from 'components/BulkUploadsPolling';
import { routePaths } from 'components/Routes/data';
import { PrimaryButtonV2, SecondaryButton } from 'components/ui/Button';
import {
  BulkUploadStatus,
  BulkUploadTypes,
  BulkUploadsExecutionPollStatuses,
  uploadTypeConfigMap,
} from 'constants/bulkUpload';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { BulkUploadDetailsSchemaContract } from 'schemas/BulkUploadDetailsSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import BreadcrumbStyleHeader from './components/BreadcrumbStyleHeader';
import PreviewTable from './components/PreviewTable';
import StickyFooterModal from './components/StickyFooterModal';
import usePreviewModals from './hooks/usePreviewModals';
import styles from './index.module.scss';
import { GET_BULK_UPLOAD_RECORD } from './queries/queries';
import { BulkUploadPreviewModals } from './types';

type BulkUploadsPreviewProps = {
  bulkUploadType: BulkUploadTypes;
  pollingRequestId: string;
  renderConfirmationText?: (details: BulkUploadDetailsSchemaContract[0]) => React.ReactNode;
  onBackClick?: () => void;
  onSucessClick?: () => void;
};

const BulkUploadsPreview = ({
  bulkUploadType,
  pollingRequestId,
  renderConfirmationText,
  onBackClick,
  onSucessClick,
}: BulkUploadsPreviewProps) => {
  const [showConfirmModal, setConfirmModal] = useState(false);
  const history = useHistory();
  const [bulkUploadRecord, setBulkUploadRecord] = useState<
    BulkUploadDetailsSchemaContract[0] | null
  >(null);
  const [modalToDisplay, setModalToDisplay] = useState<BulkUploadPreviewModals>(
    BulkUploadPreviewModals.SHOW_NO_MODALS,
  );
  const callPauseRef = useRef(true);
  const previewConfig = uploadTypeConfigMap[bulkUploadType].preview;

  useQuery(
    GET_BULK_UPLOAD_RECORD,
    () => {
      return api.bulkUploads.fetchBulkUploadRecordByType(bulkUploadType);
    },
    {
      onSuccess: (data) => {
        setBulkUploadRecord(data.length > 0 ? data[0] : null);
        if (localStorage.getItem(pollingRequestId) === null) {
          const temp = data ? data[0].id : null;
          if (temp) {
            localStorage.setItem(pollingRequestId, temp.toString());
          } else {
            onBackClick?.();
          }
        }
      },
      onError: (error: typeof AppError) => {
        window.scrollTo(0, 0);
        const response = getAppErrorDetails(error);
        if (response.type === 403) {
          history.replace(routePaths.accessDenied);
        }
      },
    },
  );

  const onConfirmClick = () => {
    if (bulkUploadRecord) {
      sessionStorage.removeItem('bulkUploadPollId');

      api.bulkUploads
        .sendExecuteRequest(bulkUploadRecord.id)
        .then((response) => {
          setBulkUploadRecord(response[0] ?? bulkUploadRecord);
          sessionStorage.setItem('bulkUploadPollId', String(response[0].id));
        })
        .catch((error: Error) => {});
    }
  };

  const onCancel = () => {
    setConfirmModal(false);
  };

  const hideModal = () => {
    setModalToDisplay(BulkUploadPreviewModals.SHOW_NO_MODALS);
  };

  useEffect(() => {
    switch (bulkUploadRecord?.status) {
      case BulkUploadStatus.STATUS_VALIDATION_PENDING:
      case BulkUploadStatus.STATUS_VALIDATION_PROGRESS:
      case BulkUploadStatus.STATUS_VALIDATION_FAILED:
        onBackClick?.();
        break;
      case BulkUploadStatus.STATUS_EXECUTION_PENDING:
      case BulkUploadStatus.STATUS_EXECUTION_PROGRESS:
        setModalToDisplay(BulkUploadPreviewModals.SHOW_IN_PROGRESS_MODAL);
        break;
      case BulkUploadStatus.STATUS_EXECUTION_PARTIAL_SUCCESS:
      case BulkUploadStatus.STATUS_EXECUTION_FAILED:
        setModalToDisplay(BulkUploadPreviewModals.SHOW_PARTIAL_UPLOAD_MODAL);
        break;
      case BulkUploadStatus.STATUS_EXECUTION_SUCCESS:
        (() => {
          if (callPauseRef.current) {
            callPauseRef.current = false;

            setModalToDisplay(BulkUploadPreviewModals.SHOW_SUCCESS_MODAL);
          }
        })();
        break;
    }
  }, [bulkUploadRecord, onBackClick]);

  const shouldDisplayExecutionPolling =
    bulkUploadRecord?.id &&
    BulkUploadsExecutionPollStatuses.includes(
      bulkUploadRecord?.status ?? BulkUploadStatus.STATUS_VALIDATION_SUCCESS,
    );

  const columns = previewConfig.columns ?? [];
  const tableTopView = previewConfig.tableTopView;
  const successModalCtaText = previewConfig.successModalCtaText;

  const previewModalToShow = usePreviewModals({
    bulkUploadRecord: bulkUploadRecord ?? ({} as BulkUploadDetailsSchemaContract[0]),
    modalToDisplay,
    labelMap: {
      success: {
        buttonText: successModalCtaText as string,
        mainText: previewConfig.successModalMainText,
        subText: previewConfig.successModalSubText,
      },
    },
    clickHandlers: {
      onClickGetErrorReport() {
        hideModal();
        previewConfig.onDownloadErrorReport?.(history);
      },
      onClickGoBack: hideModal,
      onClickSuccessCallback() {
        onSucessClick?.();
      },
    },
  });

  return (
    <>
      {bulkUploadRecord ? previewModalToShow : null}

      {shouldDisplayExecutionPolling ? (
        <BulkUploadsPolling
          id={bulkUploadRecord?.id.toString()}
          action={'file-execution'}
          setBulkUploadRecordData={setBulkUploadRecord}
        />
      ) : null}

      {bulkUploadRecord && (
        <>
          <div className={styles['centered-body-80']}>
            <BreadcrumbStyleHeader
              bulkUploadRecord={bulkUploadRecord}
              bulkUploadTypeRequest={bulkUploadType}
              isInputDataHidden
            />
            <div className="flex justify-between">
              <div className={'text-4xl text-white font-semibold mt-20 mb-8'}>
                Preview and verify
              </div>
            </div>
            <PreviewTable
              tableTopView={tableTopView}
              bulkUploadRecord={bulkUploadRecord}
              columns={columns}
              tableBottomView={() => (
                <div className="flex flex-row mt-10">
                  <SecondaryButton className={styles['button-left']} onClick={onBackClick}>
                    Back
                  </SecondaryButton>
                  <PrimaryButtonV2
                    className={styles['button-right']}
                    onClick={() => setConfirmModal(true)}>
                    Proceed to Confirm
                  </PrimaryButtonV2>
                </div>
              )}
            />
          </div>
          <StickyFooterModal
            warningText={<div className="ml-4">{renderConfirmationText?.(bulkUploadRecord)}</div>}
            showModal={showConfirmModal}
            onCancel={onCancel}
            onConfirmClick={onConfirmClick}
            showAlertWithWarningText={false}
          />
        </>
      )}
    </>
  );
};
export default BulkUploadsPreview;
