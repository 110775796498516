import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

const useIsHoldSalaryPayComplianceEnabled = () => {
  const features = useSelector(loggedInUserSelectors.features);
  const isHoldSalaryPayComplianceEnabled = !!features?.['holdSalaryPayCompliance'];
  return { isHoldSalaryPayComplianceEnabled };
};

export default useIsHoldSalaryPayComplianceEnabled;
