import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import React, { ReactElement, useState } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';
import springVerifyDeactivationModalLogo from 'assets/springVerify/springVerify-deactivation-modal.svg';
import springVerifyDeactivation from 'assets/springVerify/springVerify-deactivation.svg';
import { Card } from 'components/ui/Card';
import { TickList, TickListItem } from 'components/ui/TickList';
import { LinkButtonV2, PrimaryButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';
import { AppError } from 'utils/AppError';
import api from 'api';
import { useMutation, useQuery } from 'react-query';
import partnerIntegrations from 'constants/partnerIntegrations';
import { GET_PARTNER_ID_QUERY, GET_PARTNER_LAST_SYNC_TIME } from './queries';
import { WarningAlert } from 'components/ui/Alert';
import Errors from 'components/ui/Form/Errors';
import { Redirect } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import styles from './index.module.scss';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { StandardTable } from 'components/ui/Table';
import { formatDateForDisplay } from 'utils/Dates';

function SpringVerifyDeactivation(): ReactElement {
  const [isDisableSuccess, setIsDisableSuccess] = useState(false);
  const { openModal, closeModal } = useModal();
  const getPartnerDetailsQuery = useQuery(
    GET_PARTNER_ID_QUERY,
    () => {
      return api.partnerSettings.getPartnerDetails(partnerIntegrations.SPRING_VERIFY);
    },
    {
      onError: (error: typeof AppError) => {},
    },
  );
  const partnerId = getPartnerDetailsQuery.data?.partnerId;
  const getPartnerLastSyncTimeQuery = useQuery(
    GET_PARTNER_LAST_SYNC_TIME,
    () => {
      return api.partnerSettings.getLastSyncTime({partnerId});
    },
    {
      enabled: !!partnerId,
      onError: (error: typeof AppError) => {},
    },
    
  ); 
  
  const lastSyncTime = getPartnerLastSyncTimeQuery.data?.lastFetchedTime;

  const handleDisableMutation = useMutation(
    () => {
      return api.partnerSettings.disableIntegration({
        partnerId: partnerId,
      });
    },
    {
      onSuccess: () => {
        closeModal();
        setIsDisableSuccess(true);
      },
      onError: (error: typeof AppError) => {
        closeModal();
      },
    },
  );
  function handleDisable() {
    handleDisableMutation.mutate();
  }
  function openDisableModal() {
    openModal(
      <>
        <div
          className={`flex flex-col items-center flex-grow pt-8 mb-8  ${styles['DeactivationModal']}`}>
          <div className="flex flex-col items-center px-16 py-10">
            <img
              src={getStaticMediaUrl(springVerifyDeactivationModalLogo)}
              alt="deactivation modal logo"
            />
            <div className={`text-4xl text-white-o-80 font-bold text-white mb-8 mt-4 text-center`}>
              You are about to disable the client key!
            </div>
            <p className="text-center px-8 mb-11 text-1.7xl text-white-o-70">
              Please note if you are not connected to SpringVerify, employee data will not sync from
              RazorpayX Payroll after disabling.
            </p>
          </div>
          <PrimaryButton
            className="text-1.5xl"
            onClick={handleDisable}
            isPending={handleDisableMutation.status === 'loading'}>
            Disable Now
          </PrimaryButton>
        </div>
      </>,
    );
  }
  function openDetailsModal() {
    openModal(
      <ConfirmModal confirmOnly confirmText="Got it">
        <div className={`${styles['HelpModalWrapper']} pl-10 pr-5 py-10`}>
          <h3 className="text-4xl font-bold text-white-o-80">Integration details</h3>
          <p>The following fields will be synced from RazorpayX Payroll to SpringVerify</p>
          <StandardTable className="mt-8">
            <tbody>
              <tr>
                <td>{'RazorpayX Payroll field'}</td>
                <td>{'SpringVerify field mapping'}</td>
              </tr>
              <tr>
                <td>{'Employee id'}</td>
                <td>{'Employee id'}</td>
              </tr>
              <tr>
                <td>{'Email'}</td>
                <td>{'Email'}</td>
              </tr>
              <tr>
                <td>{'Name'}</td>
                <td>{'Name'}</td>
              </tr>
              <tr>
                <td>{'Phone Number'}</td>
                <td>{'Phone Number'}</td>
              </tr>
            </tbody>
          </StandardTable>
        </div>
      </ConfirmModal>,
    );
  }
  if (isDisableSuccess) {
    return <Redirect to={routePaths.settings.springVerify.settings} />;
  }
  return (
    <>
      {handleDisableMutation.status === 'error' && (
        <WarningAlert>
          <Errors errorResponse={handleDisableMutation.error} />
        </WarningAlert>
      )}
      <img src={getStaticMediaUrl(springVerifyDeactivation)} alt="SpringVerify Intro Logo" />
      {lastSyncTime ?
      <div className={`text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center`}>
        Last synced at {formatDateForDisplay(lastSyncTime)}
      </div> : ""}
      <p className="text-center text-1.7xl text-white-o-70">
        Employee data will be sent from RazorpayX Payroll to SpringVerify once every 24 hours.
      </p>
      <Card className={`mt-12 px-10 py-10 border-spring-verify-green border-t-2`}>
        <p className="font-bold text-white-o-80 mb-8">How does the integration work ?</p>
        <p className="mb-8">When you add any employee to RazorpayX Payroll:</p>
        <TickList className="pl-6">
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              <LinkButtonV2 onClick={openDetailsModal}>Employee </LinkButtonV2> details get
              auto-synced to SpringVerify
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Automatic initiation of candidate background verification from SpringVerify
            </span>
          </TickListItem>
          <TickListItem>
            <span className="text-white-o-70 text-1.5xl">
              Track background verification progress on SpringVerify
            </span>
          </TickListItem>
        </TickList>
      </Card>
      <LinkButtonV2 className="mt-8" onClick={openDisableModal}>
        Disable Integration
      </LinkButtonV2>
    </>
  );
}

function SpringVerifyDeactivationWithWrapper(): ReactElement {
  const getPartnerDetailsQuery = useQuery(
    GET_PARTNER_ID_QUERY,
    () => {
      return api.partnerSettings.getPartnerDetails(partnerIntegrations.SPRING_VERIFY);
    },
    {
      onError: (error: typeof AppError) => {},
      
    },
  );
  const isOrgConnectedToPartner = getPartnerDetailsQuery.data?.isOrgIntegratedToPartner;
  if(getPartnerDetailsQuery.isLoading){
    return <></>
  }
  if(!isOrgConnectedToPartner){
    return <Redirect to = {routePaths.settings.springVerify.settings}/>
  }
  return (
    <PageWrapper>
      <MainCase center>
        <SpringVerifyDeactivation />
      </MainCase>
    </PageWrapper>
  );
}

export default SpringVerifyDeactivationWithWrapper;
