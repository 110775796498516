import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import style from './index.module.css';
import { Table, TableLayout } from '../../../ui/Table';
import { PrimaryButton } from '../../../ui/Button';
import { Modal } from '../../../ui/Modal';
import {
  IInsurance,
  updateInsuranceAndRedirect,
} from '../../../../reducers/insurance';
import { useDispatch } from 'react-redux';
import { canSelectPlan } from '../canSelectPlan';
import { IInsuranceEstimate } from '../../../../reducers/insuranceEstimate';
import { planBenefits, planNames, standardBenefits } from '../../insuranceData';
import { FAQ } from '../../FAQ';
import { routePaths } from 'components/Routes/data';
import { ContentContainer } from '../../../ui/ContentContainer';
import { ILoggedInUser } from '../../../../reducers/loggedInUser';
import { useQuery } from 'react-query';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import Spinner from 'components/ui/Spinner';

type Benefits = Record<
  string,
  { benefit: React.ReactNode | string; description: React.ReactNode | string } | string
>;

function BenefitsList({ benefits }: { benefits: Benefits }) {
  let trs = [];
  for (const [name, elements] of Object.entries(benefits)) {
    trs.push(
      <tr>
        <td className="secondary-text">{typeof elements === 'string' ? name : elements.benefit}</td>
        <td className="text-white">
          <b className="text-white">
            {' '}
            {typeof elements === 'string' ? elements : elements.description}{' '}
          </b>
        </td>
      </tr>,
    );
  }
  return <TableLayout className="w-full flex-1 py-2 -mx-4">{trs}</TableLayout>;
}

function PlanItem({
  planId,
  heading,
  insuranceEstimate,
  description,
  benefits,
  selected,
  recommended,
  insurance,
  updateInsuranceAndRedirectAction,
  sumInsured,
  baseBenefits,
  loggedInUser,
  isLoading
}: {
  planId: number;
  heading: string;
  description: string;
  benefits: Benefits;
  baseBenefits: string;
  selected: boolean;
  recommended: boolean;
  insurance: IInsurance;
  updateInsuranceAndRedirectAction: typeof updateInsuranceAndRedirect;
  sumInsured?: number;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
  isLoading?: boolean;
}) {
  const dispatch = useDispatch();
  let SelectButton = null;
  const insuranceId = insurance.data?.id ? insurance.data.id : null;
  if (insuranceId !== null) {
    SelectButton = (
      <Route
        render={({ history }) => (
          <PrimaryButton
            disabled={!canSelectPlan(insurance, insuranceEstimate, planId, loggedInUser)}
            onClick={() => {
              dispatch(
                updateInsuranceAndRedirectAction(
                  insuranceId,
                  {
                    planId,
                  },
                  history,
                  `${routePaths.insurance.admin.estimate}?planId=${planId}`,
                ),
              );
            }}
          >
            {insurance.data?.status !== 'NO_ACTIVE_INSURANCE' && insurance.data?.planId === planId
              ? 'Continue'
              : 'Select & Proceed'}
          </PrimaryButton>
        )}
      />
    );
  }
  return (
    <li className={`${style['plan-item']} ${selected ? 'selected' : ''} xl:w-1/3`}>
      <div className="flex items-center">
        <span className="text-white flex-1 text-6xl font-semibold font-heading">{heading}</span>
        {!insurance.data?.planId && recommended ? (
          <small className="font-bold text-primary">Recommended</small>
        ) : (
          ''
        )}
      </div>
      <div className="secondary-text py-4">{description}</div>
      <div className="flex-1">
        <div className="text-white text-opacity-95 py-4 font-heading">{baseBenefits} +</div>
        <BenefitsList benefits={benefits} />
      </div>
      <div className="pt-4">
        <div className="secondary-text py-4">
          <span className="secondary-text">
            Starts from <br />
          </span>
          {isLoading ? (
            <Spinner />
          ) : (
            <span className="font-bold text-primary text-7xl font-heading mt-8">
              ₹{sumInsured ? sumInsured.toLocaleString() : '--'}{' '}
            </span>
          )}
          <span className="font-heading text-white">/life/year</span>
        </div>
        {SelectButton}
      </div>
    </li>
  );
}

function AllPlans({
  insurance,
  updateInsuranceAndRedirectAction,
  insuranceEstimate,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAndRedirectAction: typeof updateInsuranceAndRedirect;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  const pricingDataQuery = useQuery({
    queryFn: () => api.insurance.getInsurancePricingData(),
    cacheTime: 60000,
    onError: () => {
      showToastViaEvent({
        text: 'Failed to fetch pricing data',
        timeout: 5000,
        type: 'danger',
        icon: 'info',
      });
    }
  });

  const pricingData = pricingDataQuery.data;

  // @TODO - remove this function after 3rd Oct 2023
  const getTemporaryPremiumPricing = () => {
    const currentDate = new Date();

    // temporary price date range
    const startDate = new Date('2023-10-01');
    const endDate = new Date('2023-10-03');

    // Check if the current date is within the specified range
    if (currentDate >= startDate && currentDate <= endDate) {
      return 3390;
    }

    return pricingData?.Premium.startingPrice;
  };

  return (
    <ul className="flex flex-col max-w-4xl mx-auto space-y-10 w-full xl:flex-row xl:max-w-none xl:space-y-0">
      <PlanItem
        planId={1}
        heading={planNames[1]}
        insurance={insurance}
        description="Covers all basic requirements for a vibrant, young workforce"
        benefits={(() => {
          let benefits = planBenefits[1];
          if (
            benefits['Per disease capping'] &&
            benefits['Per disease capping']['description'] === 'Yes'
          ) {
            return {
              ...benefits,
              'Per disease capping': {
                benefit: (
                  <DiseaseWiseSubLimitsList>
                    <span className="secondary-text">
                      {benefits['Per disease capping']['benefit']}
                    </span>
                  </DiseaseWiseSubLimitsList>
                ),
                description: benefits['Per disease capping']['description'],
              },
            };
          }
          return benefits;
        })()}
        updateInsuranceAndRedirectAction={updateInsuranceAndRedirectAction}
        selected={insurance.data?.planId === 1}
        sumInsured={pricingData?.Starter.startingPrice}
        recommended={false}
        baseBenefits="Standard benefits"
        insuranceEstimate={insuranceEstimate}
        loggedInUser={loggedInUser}
        isLoading={pricingDataQuery.isLoading}
      />
      <PlanItem
        planId={2}
        heading={planNames[2]}
        insurance={insurance}
        description="Get instant protection with instant pre-existing coverage. No waiting period. "
        selected={insurance.data?.planId === 2}
        sumInsured={pricingData?.Essential.startingPrice}
        recommended={true}
        updateInsuranceAndRedirectAction={updateInsuranceAndRedirectAction}
        insuranceEstimate={insuranceEstimate}
        baseBenefits="Starter benefits"
        benefits={planBenefits[2]}
        loggedInUser={loggedInUser}
        isLoading={pricingDataQuery.isLoading}
      />
      <PlanItem
        planId={3}
        heading={planNames[3]}
        insurance={insurance}
        description="Take care of your family’s 360 degree needs. Maternity coverage included."
        selected={insurance.data?.planId === 3}
        sumInsured={getTemporaryPremiumPricing()}
        recommended={false}
        updateInsuranceAndRedirectAction={updateInsuranceAndRedirectAction}
        insuranceEstimate={insuranceEstimate}
        baseBenefits="Essential benefits"
        benefits={planBenefits[3]}
        loggedInUser={loggedInUser}
        isLoading={pricingDataQuery.isLoading}
      />
    </ul>
  );
}

function StandardBenefits({ benefits }: { benefits: Record<string, string> }) {
  return (
    <div className="">
      <div className="text-center font-semibold py-10 text-5xl font-heading">Standard Benefits</div>
      <ul className="grid grid-cols-3 grid-rows-2 gap-5">
        {Object.entries(benefits).map(([name, description]) => (
          <li key={name} className="w-full h-full">
            <div className="p-10 bg-rhino h-full">
              <small className="text-2xl secondary-text">{name}</small>
              <div className="font-semibold mt-2 text-2xl text-white">{description}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function CommonTerms({ commonTerms }: { commonTerms: Record<string, string | React.ReactNode> }) {
  return (
    <div className="">
      <div className="text-center font-semibold py-10 text-5xl font-heading">Common terms</div>
      <Table>
        {Object.entries(commonTerms).map(([name, description]) => (
          <tr key={name}>
            <td className={`text-white font-semibold ${style['common-term-label']}`}>{name}</td>
            <td>{description}</td>
          </tr>
        ))}
      </Table>
    </div>
  );
}

function Heading() {
  return (
    <div className="text-6xl justify-center py-4 align-items-center flex font-heading text-white font-bold">
      {' '}
      Choose your fit
    </div>
  );
}

function SpecificIllnessWaitingList({ children }: { children: React.ReactNode }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <span
        className="cursor-pointer underline text-primary font-bold"
        onClick={() => setShowModal(true)}
      >
        {children}
      </span>
      <Modal
        showModal={showModal}
        heading="Specific Illness Waiting List"
        closeModal={() => setShowModal(false)}
      >
        <ol className="list-decimal p-5 space-y-2">
          <li>Cataract</li>
          <li>Benign Prostatic Hypertrophy</li>
          <li>Hysterectomy for menorrhagia or fibromyoma</li>
          <li>Hernia</li>
          <li>Hydrocele</li>
          <li>Congenital internal diseases</li>
          <li>Fistula in anus</li>
          <li>Piles</li>
          <li>Sinusitis and related disorders</li>
        </ol>
      </Modal>
    </>
  );
}

function DiseaseWiseSubLimitsList({ children }: { children: React.ReactNode }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <span
        className="cursor-pointer underline text-primary font-bold"
        onClick={() => setShowModal(true)}
      >
        {children}
      </span>
      <Modal
        showModal={showModal}
        heading="Disease Wise Sub-Limits"
        closeModal={() => setShowModal(false)}
      >
        <TableLayout>
          <tr className="">
            <th>Disease-wise sublimits</th>
            <th>Metro</th>
            <th>Non-Metro</th>
          </tr>
          <tr className="h-12">
            <td>Appendix</td>
            <td>₹20,000</td>
            <td>₹18,000</td>
          </tr>
          <tr className="h-12">
            <td>Eye related</td>
            <td>₹25,000</td>
            <td>₹22,000</td>
          </tr>
          <tr className="h-12">
            <td>Gall Bladder</td>
            <td>₹22,000</td>
            <td>₹22,000</td>
          </tr>
          <tr className="h-12">
            <td>Hernia</td>
            <td>₹20,000</td>
            <td>₹18,000</td>
          </tr>
          <tr className="h-12">
            <td>Hydrocele</td>
            <td>₹15,000</td>
            <td>₹12,000</td>
          </tr>
          <tr className="h-12">
            <td>Hysterectomy</td>
            <td>₹28,000</td>
            <td>₹25,000</td>
          </tr>
          <tr className="h-12">
            <td>Piles</td>
            <td>₹18,000</td>
            <td>₹15,000</td>
          </tr>
          <tr className="h-12">
            <td>Urinary Stone (incl DJ stent removal for same stone)</td>
            <td>₹35,000</td>
            <td>₹30,000</td>
          </tr>
          <tr className="h-12">
            <td>Joint Replacement including Vertebral joints (Per knee) &nbsp;</td>
            <td>₹85,000</td>
            <td>₹80,000</td>
          </tr>
        </TableLayout>
        <div className="text-2xl">
          Note: This is applicable only in the Starter Plan. Other plans have no such limits
        </div>
      </Modal>
    </>
  );
}

export function InsurancePlan({
  insurance,
  updateInsuranceAndRedirectAction,
  insuranceEstimate,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAndRedirectAction: typeof updateInsuranceAndRedirect;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  return (
    <ContentContainer showSidebar={false}>
      <div className="flex flex-col px-2 self-center sm:w-4/5 space-y-8 w-full pt-12">
        <Heading />
        <AllPlans
          insurance={insurance}
          updateInsuranceAndRedirectAction={updateInsuranceAndRedirectAction}
          insuranceEstimate={insuranceEstimate}
          loggedInUser={loggedInUser}
        />
        <StandardBenefits benefits={standardBenefits(insurance.data?.sumInsured)} />
        <CommonTerms
          commonTerms={{
            'Pre Existing Disease Coverage': (
              <span>
                A pre-existing disease is a condition, ailment or injury that already exists at the
                time you buy a health insurance policy
              </span>
            ),
            'Per Disease Capping': (
              <span>
                <DiseaseWiseSubLimitsList>Here</DiseaseWiseSubLimitsList> is a list of specific
                diseases/treatments which have an upper limit on the amount to be capped. This is
                applicable only in the Starter Plan. Other plans have no such limits
              </span>
            ),
            'Waiting Period': (
              <span>
                The initial waiting period before the insured person can make an insurance claim.
                Zero-day on injuries & accidents and 30 days on all other treatments
              </span>
            ),
            'Specific Illness Waiting': (
              <span>
                A list of specific diseases that have a waiting period. This is applicable only in
                the Starter Plan. Other plans have no such limits. List of diseases listed{' '}
                <SpecificIllnessWaitingList>here</SpecificIllnessWaitingList>
              </span>
            ),
            'Ambulance Cover': (
              <span>Maximum amount that is reimbursed for each ambulance trip to the hospital</span>
            ),
            'New Born baby cover': (
              <span>
                Coverage for a new born baby.Starter and Essential Plans have coverage from Day 91
                Premium has Day 1 coverage
              </span>
            ),
            'Maternity Coverage': <span>Maximum Insurance coverage for maternity benefits</span>,
            'Maternity Waiting Period': (
              <span>
                Waiting period to claim maternity benefits. In the Premium plan there is no waiting
                period
              </span>
            ),
            'Room Rent Limit': (
              <span>Maximum of 2% of the Sum Insured amount. There is no sublimit in this</span>
            ),
            'ICU Rent Limit': (
              <span>Maximum of 4% of the Sum Insured amount. There is no sublimit in this</span>
            ),
            'Day Care Procedures': (
              <span>
                These are the 150 medical treatments that are covered under Insurance which require
                hospitalisation for less than 24-hours{' '}
                <a
                  className="underline text-primary"
                  href="https://www.icicilombard.com/health-insurance/ListOfDayCareSurgeries.pdf"
                >
                  {' '}
                  here{' '}
                </a>
              </span>
            ),
          }}
        />
        <div className="">
          <div className="text-center font-semibold py-10 text-5xl font-heading">FAQs</div>
          <FAQ />
        </div>
      </div>
    </ContentContainer>
  );
}
