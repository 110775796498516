export const customReportStatus = {
  STATUS_CREATED: 1,
  STATUS_STARTED: 2,
  STATUS_SUCCEEDED: 3,
  STATUS_FAILED: 4,
};

export const customReportInputTypes = {
  TYPE_DROPDOWN: 'TYPE_DROPDOWN',
  TYPE_PAYROLL_MONTH: 'TYPE_PAYROLL_MONTH',
  TYPE_PAYROLL_DATE: 'TYPE_PAYROLL_DATE',
  TYPE_FINANCIAL_YEAR: 'TYPE_FINANCIAL_YEAR',
};

export const customReportFilterTypes = {
  TYPE_FILTER_SEARCH_PEOPLE_BY_NAME: 'TYPE_FILTER_SEARCH_PEOPLE_BY_NAME',
  TYPE_FILTER_SEARCH_PEOPLE_BY_EMAIL: 'TYPE_FILTER_SEARCH_PEOPLE_BY_EMAIL',
  TYPE_FILTER_SEARCH_PEOPLE_BY_ID: 'TYPE_FILTER_SEARCH_PEOPLE_BY_ID',
};
