import React, { ReactElement } from 'react';
import styles from '../index.module.scss';

export interface SummaryObject {
    id: string;
    title: string;
    value?: string | number | null;
}

export function SummaryTiles({ summaryKeyValueArray }: { summaryKeyValueArray?: Array<SummaryObject> }): ReactElement {

    return (
        <>
        <div className={`${styles['summary-bar']}`}>
            {summaryKeyValueArray && summaryKeyValueArray.map((summary) => (
                    <div className={styles['summary']}>
                        <div className={styles['title']}>{summary.title}</div>
                        <div className={styles['value']}>{summary.value ?? '-'}</div>
                    </div>
            ))}
        </div>
        </>
    );

}