import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowRightIcon, AlertTriangleIcon, Button } from '@razorpay/blade/components';

import { StandardButton } from 'components/ui/Button';
import { useModal } from 'components/ui/Modals';

import FinalizePayroll from '../Popups/FinalizePayroll';
import UnfinalizePayroll from '../Popups/UnfinalizePayroll';
import ExecutePayroll from '../Popups/ExecutePayroll';
import { useErrorMessageContext } from '../contexts/RunPayrollErrorContext';
import useHasPermission from 'utils/useHasPermission';
import permissions from 'constants/permissions';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useMessageContext } from '../contexts/RunPayrollMessageContext';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import api from 'api';
import { useMutation } from 'react-query';
import useNewJoinerArrearEnabled from '../utils/useNewJoinerArrearEnabled';
import { useGetComplianceActionDetails } from '../ComplianceActions/hooks/useGetComplianceActionDetails';
import { isCompliancePaymentInProgress } from '../ComplianceActions/utils';
import { useIsTdsFilingConfigRequired } from '../hooks/useIsTdsFilingConfigRequired';
import { routePaths } from 'components/Routes/data';

import FinalizeCard from './FinalizeCard';
import ExecuteCard from './ExecuteCard';
import styles from './index.module.scss';

const PayrollActions = () => {
  const { openModal } = useModal();
  const data = useRunPayrollDataContext();
  const errCtx = useErrorMessageContext();
  const message = useMessageContext();
  const { isNewJoinerArrearFeatureEnabled } = useNewJoinerArrearEnabled();

  const isAuthorizedToExecute = useHasPermission([permissions.PAYROLL_EXECUTION]);
  const isAuthorizedToFinalize = useHasPermission([permissions.PAYROLL_FINALIZE]);
  const isOrgKycRestricted = useSelector(loggedInUserSelectors.isOrgKycRestricted);
  const specialPlatformPermissions = useSelector(loggedInUserSelectors.specialPlatformPermissions);
  const orgId = useSelector(loggedInUserSelectors.organizationID);

  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const features = useSelector(loggedInUserSelectors.features);
  const isAsyncFinalizeEnabled = !!features?.['asyncPayrollFinalize'];

  const doesFinalizeNeedApproval = actionsWithActiveWorkflows.includes(
    WorkflowTypesMap.FINALIZE_PAYROLL,
  );

  const recalculateAmountMutation = useMutation({
    mutationFn: orgId ? api.runPayroll.adminRecalculatePayrollAmount(orgId) : undefined,
  });

  const { actionsApiResponse } = useGetComplianceActionDetails();

  const isTdsFilingConfigRequired = useIsTdsFilingConfigRequired();

  const isPayPtOnRunPayrollEnabled = !!features?.enablePayPtOnRunPayroll;

  if (!data) {
    return null;
  }
  const {
    isPayrollFinalized,
    numFinalized,
    numFinalizedExcludingNewJoiners,
    isExecutionRequested,
    isLocalOrRXBalanceSufficient,
    payrollMonth,
    payrollIds,
    isFirstPayroll,
    isPayrollBeingFinalized,
    numPendingEditRequest,
    isChangeRequestPendingOnFinalize,
    finalizationErrorCount = 0,
  } = data;

  const canMakeChanges =
    !!payrollIds?.find((meta) => meta.isFinal && !meta.isBlocked) &&
    !isPayrollBeingFinalized &&
    !isExecutionRequested &&
    !isCompliancePaymentInProgress({
      actionsApiResponse,
    });

  const canFinalizePayroll =
    !!payrollIds?.some((meta) => !meta.isFinal) &&
    !isPayrollBeingFinalized &&
    !isExecutionRequested &&
    numPendingEditRequest == 0 &&
    !isChangeRequestPendingOnFinalize;

  const canRecalculatePayrollAmount = specialPlatformPermissions.includes(
    'PLATFORM_PERMISSION_RECALCULATE_PAYROLL_AMOUNT',
  );

  const onFinalize = () => {
    openModal(
      <FinalizePayroll
        payrollMonth={payrollMonth}
        onError={errCtx?.setError}
        showMessage={message?.setMessage}
        doesFinalizeNeedApproval={doesFinalizeNeedApproval}
      />,
    );
  };

  const onUnfinalize = () => {
    openModal(<UnfinalizePayroll payrollMonth={payrollMonth} onError={errCtx?.setError} />);
  };

  const onExecute = () => {
    if (isTdsFilingConfigRequired) {
      return;
    }
    openModal(
      <ExecutePayroll
        payrollMonth={payrollMonth}
        isFirstPayroll={isFirstPayroll}
        onError={errCtx?.setError}
        showMessage={message?.setMessage}
      />,
    );
  };

  const handleRecalculateAmount = () => {
    recalculateAmountMutation.mutate(payrollMonth);
  };
  

  return (
    <div className="flex flex-wrap gap-6">
      {isAuthorizedToFinalize && (
        <>
          {canMakeChanges && (
            <StandardButton type="button" onClick={onUnfinalize} className="mb-5 !w-auto">
              Make Changes
            </StandardButton>
          )}
          {canFinalizePayroll && (
            <FinalizeCard
              doesFinalizeNeedApproval={doesFinalizeNeedApproval}
              isBulkAdditionDeductionInProgress={data.isBulkAdditionDeductionInProgress}
              onFinalizePayroll={onFinalize}
              finalizationErrorCount={finalizationErrorCount}
              payrollMonth={payrollMonth}
            />
          )}
          {isAsyncFinalizeEnabled &&
            canMakeChanges &&
            isPayrollFinalized &&
            canRecalculatePayrollAmount &&
            recalculateAmountMutation.status !== 'success' && (
              <StandardButton
                className="mb-5 !w-auto"
                type="button"
                onClick={handleRecalculateAmount}
                isPending={recalculateAmountMutation.status === 'loading'}>
                Re-calculate
              </StandardButton>
            )}
        </>
      )}
      {!isPayPtOnRunPayrollEnabled &&
        isPayrollFinalized &&
        (isNewJoinerArrearFeatureEnabled
          ? numFinalizedExcludingNewJoiners > 0
          : numFinalized > 0) &&
        !isOrgKycRestricted &&
        !isExecutionRequested &&
        isLocalOrRXBalanceSufficient &&
        isAuthorizedToExecute && (
          <ExecuteCard
            isTdsFilingConfigRequired={isTdsFilingConfigRequired}
            onExecutePayroll={onExecute}
          />
        )}
    </div>
  );
};

export default PayrollActions;
