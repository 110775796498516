import { Box, Heading, HomeIcon, Link, Text } from '@razorpay/blade/components';
import { KycPageSideViewWrapper, ProgressPill } from 'components/Kyc/styles';
import { ProgressPillState } from 'components/Kyc/types';
import React, { ReactNode } from 'react';

interface SidebarProps {
  children?: JSX.Element[] | JSX.Element;
  title: string;
  description?: ReactNode;
  footnoteText?: string;
  progress?: Array<ProgressPillState>;
  hideBackButton?: boolean;
  backButtonTitle?: string;
  onBackClick?: (e: React.SyntheticEvent<Element, Event>) => void;
}

const Sidebar = ({
  children,
  hideBackButton,
  backButtonTitle,
  title,
  description,
  footnoteText,
  progress,
  onBackClick,
}: SidebarProps) => {
  return (
    (<KycPageSideViewWrapper>
      <Box
        overflowY="auto"
        display="flex"
        flexDirection="column"
        paddingY="spacing.9"
        paddingX="spacing.10"
        height="100%">
        {!hideBackButton && (
          <Link
            variant="button"
            size="small"
            icon={HomeIcon}
            onClick={onBackClick}
            marginBottom="spacing.11">
            {backButtonTitle ?? 'BACK TO DASHBOARD'}
          </Link>
        )}
        {progress && progress.length && (
          <Box display={'flex'} gap={'spacing.2'} marginBottom={'spacing.6'}>
            {progress.map((item, i) => (
              <ProgressPill progressState={item} key={i} />
            ))}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-between"
          gap="spacing.10">
          <Box display="flex" flexDirection="column" gap="spacing.4">
            <Heading size="medium" color="surface.text.gray.muted">
              {title}
            </Heading>
            {description && <Text color="surface.text.gray.muted">{description}</Text>}
          </Box>
          {children && <Box flexGrow={1}>{children}</Box>}
          {footnoteText && (
            <Text variant="caption" textAlign="center" size='small'>
              {footnoteText}
            </Text>
          )}
        </Box>
      </Box>
    </KycPageSideViewWrapper>)
  );
};

export { Sidebar };
