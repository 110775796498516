import {
  Box,
  Button,
  CloseIcon,
  Divider,
  IconButton,
  PlusIcon,
  Text,
  TextInput,
} from '@razorpay/blade/components';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';
import useSalaryComponentsList from 'components/RunPayroll/Popups/EditSalary/hooks/useSalaryComponentsList';
import { getSearchDropdownDescription } from 'components/RunPayroll/Popups/EditSalary/utils';
import { useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { EarningsComponentType } from 'components/SalaryComponents/types';
import AutoCompleteV2 from 'components/ui/AutoCompleteV2/AutoCompleteV2';
import React from 'react';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { isNumeric } from 'utils/Numbers';
import { capitalizeAllWordsInString } from 'utils/Strings';
import InputBoxWrapper from './FnfInputBoxWrapper';

type FnfAdditionsProps = {
  additions: GetFnfResponseSchemaContract['additions'];
  onChangeAdditions: (additions: GetFnfResponseSchemaContract['additions']) => void;
  isFnfProcessed: boolean;
  isHoldSalaryPayComplianceEnabled: boolean;
};

const FnfAdditions = ({
  additions = [],
  onChangeAdditions,
  isFnfProcessed,
  isHoldSalaryPayComplianceEnabled,
}: FnfAdditionsProps) => {
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();
  const { openEarningsCreation } = useSalaryComponentHelpers();
  const { searchSalaryComponents, isFetchingSalaryComponents } =
    useSalaryComponentsList<EarningsComponentType>({
      requestParams: {
        category: 'EARNINGS_CATEGORY',
        payType: 'LIST_ADHOC_TYPE',
        status: 'COMPONENT_STATUS_ACTIVE',
      },
      enabled: isM2S1Enabled,
    });

  const onChangeAdditionValues = (idx: number, propToUpdate: string, value: number | string) => {
    if (additions) {
      const newAdditions = [...additions];
      newAdditions[idx] = { ...newAdditions[idx], [propToUpdate]: value };

      onChangeAdditions(newAdditions);
    }
  };

  const onSelectAddition = (idx: number, item: EarningsComponentType) => {
    if (additions) {
      const newAdditions = [...additions];
      newAdditions[idx] = { ...newAdditions[idx], name: item.name, id: item.id };

      onChangeAdditions(newAdditions);
    }
  };

  const onClearSelection = (idx: number) => {
    if (additions) {
      const newAdditions = [...additions];
      newAdditions[idx] = { ...newAdditions[idx], name: '', id: null };

      onChangeAdditions(newAdditions);
    }
  };

  const onAdd = () => {
    const newAdditions = additions ? [...additions] : [];
    newAdditions.push({
      amount: 0,
      name: '',
    });
    onChangeAdditions(newAdditions);
  };

  const onDeleteItem = (idx: number) => {
    if (!additions) return;

    const newAdditions = additions.filter((_, index) => {
      return index !== idx;
    });

    onChangeAdditions(newAdditions);
  };

  const isFieldDisabled = isHoldSalaryPayComplianceEnabled && isFnfProcessed;

  const additionsInternal = Array.isArray(additions) ? additions : [];

  // Adding existing behaviour, where we do not render this section if there are no additions
  if (!isM2S1Enabled && additionsInternal.length === 0) {
    return null;
  }

  const getAllowancesSearchSkipList = (
    allowances: NonNullable<FnfAdditionsProps['additions']>,
    currentId?: string | null,
  ): string[] => {
    return allowances
      .filter((allowance) => allowance.id !== '' && allowance.id !== currentId && !!allowance.id)
      .map((allowance) => allowance.id!);
  };

  return (
    <>
      <InputBoxWrapper heading="Additions (excluding gratuity)">
        <Box display="flex" flexDirection="column" gap="28px">
          <Box display="flex" gap="12px" flexDirection="column">
            <Box display="flex" alignItems="center" gap="32px">
              <Box flex="1" textAlign="left">
                <Text weight="semibold" size="small" color="surface.text.gray.disabled">
                  Label
                </Text>
              </Box>
              <Box flex="1" textAlign="left">
                <Text weight="semibold" size="small" color="surface.text.gray.disabled">
                  Amount
                </Text>
              </Box>
              {isM2S1Enabled && <Box textAlign="left">&nbsp;</Box>}
            </Box>
            <Box marginTop="spacing.0">
              <Divider thickness="thin" variant="subtle" />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="20px">
            {additionsInternal.map((addition, idx) => {
              return (
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="32px"
                  alignItems="center"
                  key={`fnf-addition-${idx}`}>
                  <Box flex="1" textAlign="left">
                    {isM2S1Enabled ? (
                      <AutoCompleteV2
                        options={searchSalaryComponents(
                          getAllowancesSearchSkipList(additionsInternal, addition.id),
                        )}
                        isDisabled={isFieldDisabled || isFetchingSalaryComponents}
                        itemToKey={(item) => item.id}
                        itemToLabel={(item) => capitalizeAllWordsInString(item.name)}
                        itemToDescription={(item) => getSearchDropdownDescription(item) ?? ''}
                        placeholder="Eg. Earned Leave"
                        value={addition.id}
                        label=""
                        onChange={(item) => onSelectAddition(idx, item)}
                        onClearSelection={() => onClearSelection(idx)}
                        footer={
                          <Button
                            isFullWidth
                            variant="tertiary"
                            size="small"
                            icon={PlusIcon}
                            onClick={(e) => openEarningsCreation()}>
                            Create addition
                          </Button>
                        }
                      />
                    ) : (
                      <TextInput
                        isDisabled={isFieldDisabled}
                        placeholder="Eg. Earned Leave"
                        value={addition.name}
                        label=""
                        onChange={(e) => {
                          onChangeAdditionValues(idx, 'name', e.value ?? '');
                        }}
                      />
                    )}
                  </Box>
                  <Box flex="1" textAlign="left">
                    <TextInput
                      isDisabled={isFieldDisabled}
                      placeholder="Eg. Earned amount here"
                      label=""
                      prefix="₹"
                      value={addition.amount.toString()}
                      onChange={(e) => {
                        if (isNumeric(e.value)) {
                          onChangeAdditionValues(idx, 'amount', Number(e.value));
                        }
                      }}
                    />
                  </Box>
                  {isM2S1Enabled && (
                    <Box>
                      <IconButton
                        icon={CloseIcon}
                        emphasis="subtle"
                        accessibilityLabel="Delete item"
                        onClick={() => onDeleteItem(idx)}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          {additionsInternal.length < 10 && !isFieldDisabled && (
            <Box width="36px">
              <Button
                accessibilityLabel="Add new addition"
                variant="secondary"
                icon={PlusIcon}
                color="white"
                isFullWidth={true}
                onClick={onAdd}
              />
            </Box>
          )}
        </Box>
      </InputBoxWrapper>
    </>
  );
};

export default FnfAdditions;
