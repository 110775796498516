export const initRazorpayAnalytics = () => {
  var razorpayAnalytics = (window.razorpayAnalytics = window.razorpayAnalytics || []);
  if (razorpayAnalytics) {
    razorpayAnalytics.init && razorpayAnalytics.init(
      ['fb', 'linkedin', 'twitter'],
      {
        twitter_onboarding_tax_details: 'o8rr2',
        twitter_onboarding_payroll_date: 'o8rr3',
        twitter_onboarding_salary_structure: 'o8rr4',
        linkedin_onboarding_tax_details: 7377292,
        linkedin_onboarding_payroll_date: 7377300,
        linkedin_onboarding_salary_structure: 7377308,
      },
      false,
    );
  }
};

export const trackRazorpayAnalytics = (pixelLabel, event) => {
  window.razorpayAnalytics && window.razorpayAnalytics.track(pixelLabel, event);
};

export function trackGtag(...data) {
  window.gtag && window.gtag(...data);
}
