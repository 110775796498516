import React from "react";
import {TableWithLoader} from "../../../../ui/Table";
import {accountCreationStatuses, accountCreationStatusValuesMapping} from "../../constants";
import jibbleCommonStyles from "./../../index.module.scss";
import Pill, {pillClassTypeMap} from "../../../../ui/Pill";
import {getStaticMediaUrl} from "../../../../../utils/Urls";
import EditIcon from "../../../../../assets/icon--edit.svg";
import JibbleEmployeesDataSchema, {JibbleEmployeesDataSchemaContract} from "../../../../../schemas/JibbleEmployeesDataSchema";
import EditJibbleMemberCodeModal from "../PopUps/EditJibbleMemberCodeModal";
import {useModal} from "../../../../ui/Modals";

const DataDisplay = ({
    employeesData,
    fetchData,
    isLoading
}: {
    employeesData?: JibbleEmployeesDataSchemaContract['jibble_employee_data'],
    fetchData: () => void,
    isLoading: boolean
}) => {
    const { openModal, closeModal } = useModal();

    const onSubmitModal = () => {
        closeModal();
        fetchData();
    }

    const handleEditJibbleMemberCodeClick = (employeeData: typeof JibbleEmployeesDataSchema['jibble_employee_data']['data']) => {
        openModal(
            <EditJibbleMemberCodeModal employeeData={employeeData} onCloseModal={closeModal} onSubmitModal={onSubmitModal}/>
        )
    }

    return (
        <TableWithLoader className={jibbleCommonStyles['table']} isLoading={isLoading}>
            <tbody>
            <tr>
                <th className="text-1.29xl font-bold  opacity-90">
                    EMPLOYEE NAME
                </th>
                <th className="text-1.29xl font-bold opacity-90">EMPLOYEE EMAIL</th>
                <th className="text-1.29xl font-bold  opacity-90">INTEGRATION STATUS</th>
                <th className="text-1.29xl font-bold  opacity-90">JIBBLE CODE</th>
                <th className="text-1.29xl font-bold  opacity-90">ACTION</th>
            </tr>
            {
                employeesData?.data &&
                employeesData.data.map((employeeData) => {
                    const accountCreationStatus = accountCreationStatusValuesMapping[employeeData.account_creation_status].status;
                    return (
                        <tr key={`user-${employeeData.people_organization_id}`}>
                            <td className="text-1.7xl">{employeeData.name !== "" ? employeeData.name : '-'}</td>
                            <td>
                                <span className={jibbleCommonStyles['tableData']}>{employeeData.email}</span>
                            </td>
                            <td>
                                <Pill content={accountCreationStatuses[accountCreationStatus].label} type={
                                    accountCreationStatus === 'not_synced' ? pillClassTypeMap.danger :
                                        accountCreationStatus === 'in_progress' ? pillClassTypeMap.warning :
                                            pillClassTypeMap.success
                                }/>
                            </td>
                            <td>
                                    <span className={jibbleCommonStyles['tableData']}>
                                        {employeeData.jibble_member_code ? employeeData.jibble_member_code : "-"}
                                    </span>
                            </td>
                            <td>
                                <div className="w-full flex">
                                    <div className={jibbleCommonStyles['editButton']} onClick={() => handleEditJibbleMemberCodeClick(employeeData)}>
                                        {
                                            accountCreationStatus === 'synced'
                                                ? <img src={getStaticMediaUrl(EditIcon)} alt="Edit" />
                                                : accountCreationStatus === 'not_synced'
                                                    ? "Sync via Jibble code" : ""
                                        }
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }
            )}
            </tbody>
        </TableWithLoader>
    )
}

export default DataDisplay;