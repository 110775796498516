import api from 'api';

export function trackXPayrollEvent(
  eventName: string,
  eventType: string = 'interaction',
  source: string | null = null,
  properties: object | null = null,
) {
  return api.analytics.push({
    eventName,
    eventType,
    ...(source && { source }),
    ...(properties && { properties }),
  });
}
