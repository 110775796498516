import React, { useState } from 'react';
import {
  Box,
  Button,
  TextArea,
  Dropdown,
  SelectInput,
  DropdownOverlay,
  ActionList,
  ActionListItem,
  DatePicker,
  DatesRangeValue,
  Checkbox,
  Alert,
} from '@razorpay/blade/components';
import { useSelector } from 'react-redux';
import { leaveAttendanceConfig } from 'reducers/loggedInUser/selectors';
import { generateStatusOptions, isStatusLeave } from './utils/leaveOptions';
import { useMutation, useQueryClient } from 'react-query';
import attendance from 'api/attendance';
import { ApplyLeaveRequest, ApplyLeaveResponse } from 'schemas/Attendance';
import { AppError } from 'utils/AppError';
import { getFormattedDateValue, dateFormats } from 'utils/Dates';
import { ErrorsAlert } from 'components/ui/Form/Errors';
import { ATTENDANCE_STATUS } from './constants';
import { useForm, ValidationSchema } from 'forms';
import { useAttendanceData } from './hooks/useAttendanceData';
import { useTodaysAttendanceData } from './hooks/useTodaysAttendanceData';

interface ApplyLeaveProps {
  onSuccess: (status: number, data: ApplyLeaveResponse) => void;
  onCancel: () => void;
}

type FormFields = {
  dateRange: DatesRangeValue;
  remarks: string;
  status: string[];
  considerLeaveOnHoliday: boolean;
};

export const ApplyLeave: React.FC<ApplyLeaveProps> = ({ onSuccess, onCancel }) => {
  const { register, validate, formData, setIsSubmitting, isSubmitting } = useForm<FormFields>();
  const leaveAttendanceConfiguration = useSelector(leaveAttendanceConfig);
  const [considerLeaveOnHoliday, setConsiderLeaveOnHoliday] = useState(true);
  const queryClient = useQueryClient();
  const statusOptions = generateStatusOptions(leaveAttendanceConfiguration);
  const attendanceData = useAttendanceData();
  const todaysAttendanceData = useTodaysAttendanceData();
  const selectedStatus = statusOptions.find(
    (option) => formData.status && option.value === formData.status[0],
  );

  const applyLeaveMutation = useMutation((data: ApplyLeaveRequest) => attendance.applyLeave(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['attendance']);
      queryClient.invalidateQueries(['attendance-today']);
      setIsSubmitting(false);

      if (selectedStatus) {
        onSuccess(selectedStatus.status, data);
      }
    },
    onError: (error: typeof AppError) => {
      setIsSubmitting(false);
    },
  });

  const isRemarksCompulsory =
    !!leaveAttendanceConfiguration?.compulsoryLeaveRemarks &&
    selectedStatus &&
    [ATTENDANCE_STATUS.STATUS_LEAVE, ATTENDANCE_STATUS.STATUS_HALF_DAY].includes(
      selectedStatus.status,
    );

  const validationSchema: ValidationSchema<FormFields> = {
    dateRange: (formData) => (formData.dateRange ? null : 'Date range is required'),
    remarks: (formData) =>
      !isRemarksCompulsory || formData.remarks ? null : 'Remarks is required',
    status: (formData) => (formData.status ? null : 'Status is required'),
  };

  const handleSubmit = () => {
    const { dateRange, remarks } = formData;

    if (!validate(validationSchema)) {
      return;
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      if (selectedStatus && attendanceData) {
        setIsSubmitting(true);
        const payload: ApplyLeaveRequest = {
          'from-date': getFormattedDateValue({
            date: dateRange[0],
            formatString: dateFormats.yearMonthDate,
          }),
          'to-date': getFormattedDateValue({
            date: dateRange[1],
            formatString: dateFormats.yearMonthDate,
          }),
          status: selectedStatus.status,
          'leave-type': selectedStatus.leaveType,
          remarks: remarks ? remarks : '',
          'consider-leave-on-holiday':
            selectedStatus.status === ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE
              ? considerLeaveOnHoliday
              : false,
        };
        if (attendanceData.userId && !attendanceData.isForLoggedInUser) {
          payload['user-id'] = parseInt(attendanceData.userId);
        }
        applyLeaveMutation.mutate(payload);
      }
    }
  };

  const isUnpaidLeave = selectedStatus?.status === ATTENDANCE_STATUS.STATUS_UNPAID_LEAVE;

  return (
    <form>
      <Box display="flex" flexDirection="column" gap="spacing.4">
        <Dropdown selectionType="single">
          <SelectInput
            {...register('status', 'select')}
            label="Status"
            placeholder="Status"
            isRequired
          />
          <DropdownOverlay>
            <ActionList>
              {statusOptions.map((option) => (
                <ActionListItem key={option.value} title={option.title} value={option.value} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>

        {todaysAttendanceData.data &&
        selectedStatus &&
        selectedStatus.leaveType !== null &&
        isStatusLeave(selectedStatus.status) ? (
          <Alert
            isDismissible={false}
            color="information"
            description={`${
              todaysAttendanceData.data.leave_balance[selectedStatus.leaveType].leave_balance
            } leaves available`}
          />
        ) : (
          <></>
        )}

        <DatePicker
          label={{
            start: 'From',
            end: 'to',
          }}
          {...register('dateRange', 'daterange')}
          selectionType="range"
          isRequired
          allowSingleDateInRange
        />

        <TextArea
          accessibilityLabel="Remarks"
          {...register('remarks')}
          label="Remarks"
          necessityIndicator={isRemarksCompulsory ? 'required' : 'optional'}
          isRequired={isRemarksCompulsory}
        />

        {isUnpaidLeave && (
          <Checkbox
            {...register('considerLeaveOnHoliday', 'checkbox')}
            isChecked={considerLeaveOnHoliday}
            onChange={({ isChecked }) => setConsiderLeaveOnHoliday(isChecked)}>
            Consider weekends/holidays as unpaid leave
          </Checkbox>
        )}
      </Box>

      {applyLeaveMutation.error && (
        <Box marginY="spacing.4">
          <ErrorsAlert errorResponse={applyLeaveMutation.error} />
        </Box>
      )}

      <Box display="flex" marginTop="spacing.4">
        <Button variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
          Submit
        </Button>
        <Button
          variant="tertiary"
          marginLeft="spacing.3"
          isDisabled={isSubmitting}
          onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </form>
  );
};
