import {
  ActionList,
  ActionListItem,
  ActionListSection,
  Box,
  Button,
  Divider,
  Dropdown,
  DropdownOverlay,
  ExternalLinkIcon,
  Link,
  SelectInput,
  Text,
} from '@razorpay/blade/components';
import React, { Fragment, useState } from 'react';
import { Workflow } from '../types';
import { WorkflowTypeLabelsMap } from 'components/ApprovalWorkflows/constants';
import { routePaths } from '../../../Routes/data';

interface NoWorkflowViewProps {
  onClickSetUp: () => void;
  existingWorkflows?: Workflow[];
  onExistingWorkflowSelect?: (workflow: Workflow) => void;
  pathname: string;
}

const NoWorkflowView = ({
  onClickSetUp,
  existingWorkflows,
  onExistingWorkflowSelect,
  pathname
}: NoWorkflowViewProps) => {
  const [showWorkflowsDropdown, setShowWorkflowsDropdown] = useState(false);
  const showExistingWorkflowsCta =
    existingWorkflows && existingWorkflows.length > 0 && !showWorkflowsDropdown;

  const noWorkflowPointers = [];
  switch(pathname){
    case routePaths.approvalWorkflows.workflow.editPayroll:
      noWorkflowPointers.push(
      <Text>
        💡 Edit Payroll requests include <strong>additions</strong>, <strong>deductions</strong> and{' '}
        <strong>arrears</strong>
      </Text>
      );
      break;

    case routePaths.approvalWorkflows.workflow.salaryRevision:
      noWorkflowPointers.push(
        <Text>
        💡 Salary Revision requests include <strong>changes to salary structure</strong>, and{' '}
        <strong>arrears</strong>
      </Text>
      );
      break;
      
    case routePaths.approvalWorkflows.workflow.finalizePayroll:
    default:
        break;

  }

  noWorkflowPointers.push(<Text>
    👉🏻 See how to set up workflow{' '}
    <Link icon={ExternalLinkIcon} iconPosition="right" target='_blank' href={'https://razorpay.com/docs/x/xpayroll/employers/approval-workflow/'}>
      here
    </Link>
  </Text>
  );

  const handleExistingWorkflowCtaClicked = () => {
    setShowWorkflowsDropdown(true);
  };

  const handleWorkflowSelection = ({ values }: { name?: string; values: string[] }) => {
    const selectedWorkflow = existingWorkflows?.filter(
      (workflow) => workflow.type === Number(values[0]),
    )[0];

    onExistingWorkflowSelect && onExistingWorkflowSelect(selectedWorkflow as Workflow);
  };

  return (
    <Box display="flex" flexDirection="column" gap="spacing.6" alignItems="center">
      <Button onClick={onClickSetUp} size="large">
        Set-up workflow
      </Button>
      {(showExistingWorkflowsCta || showWorkflowsDropdown) && (
        <Box display="flex" gap="spacing.3" alignItems="center">
          <Divider width="59px" />
          <Text>or</Text>
          <Divider width="59px" />
        </Box>
      )}
      {showExistingWorkflowsCta && (
        <Button variant="secondary" onClick={handleExistingWorkflowCtaClicked} size="large">
          Copy an existing workflow
        </Button>
      )}
      {!showWorkflowsDropdown && (
        <Box
          display="flex"
          flexDirection="column"
          gap="spacing.2"
          padding={['spacing.5', 'spacing.7']}
          borderRadius="medium"
          backgroundColor="surface.background.gray.moderate">
          {noWorkflowPointers.map((content, index) => (
            <Fragment key={index}>{content}</Fragment>
          ))}
        </Box>
      )}
      {showWorkflowsDropdown && existingWorkflows && existingWorkflows.length > 0 && (
        <Box width="280px">
          <Dropdown>
            <SelectInput
              label="Copy workflow from"
              placeholder="select from existing workflows"
              onChange={handleWorkflowSelection}
            />
            <DropdownOverlay>
              <ActionListSection title="Suggested">
                <ActionList>
                  {existingWorkflows?.map(({ type }) => {
                    return (
                      <ActionListItem
                        key={WorkflowTypeLabelsMap[type]}
                        title={WorkflowTypeLabelsMap[type]}
                        value={type.toString()}
                      />
                    );
                  })}
                </ActionList>
              </ActionListSection>
            </DropdownOverlay>
          </Dropdown>
        </Box>
      )}
    </Box>
  );
};

export { NoWorkflowView };
