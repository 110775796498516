import { Skeleton } from '@razorpay/blade/components';
import { KnitApps } from 'components/Knit/constants';
import { KnitHrmsApp } from 'components/Knit/types';
import React, { Suspense } from 'react';

interface SyncInProgressProps {
  knitHrmsApp: KnitHrmsApp;
}

const DarwinboxInProgresssGraphic = React.lazy(() => import('./DarwinboxSyncInProgressGraphic'));
const HiBobInProgressGraphic = React.lazy(() => import('./HiBobSyncInProgressGraphic'));
const WorqInProgressGraphic = React.lazy(() => import('./WorqSyncInProgressGraphic'));

const SyncInProgressLazy = ({ knitHrmsApp }: SyncInProgressProps) => {
  return (
    <Suspense fallback={<Skeleton width="258px" height="208px" borderRadius="medium" />}>
      {(() => {
        switch (knitHrmsApp) {
          case KnitApps.hibob:
            return <HiBobInProgressGraphic />;
          case KnitApps.darwinbox:
            return <DarwinboxInProgresssGraphic />;
          case KnitApps.worq:
            return <WorqInProgressGraphic />;
          case KnitApps.workline:
            // @TODO hrms-integration: Add Workline graphic
            return <WorqInProgressGraphic />
          default:
            return null;
        }
      })()}
    </Suspense>
  );
};

export { SyncInProgressLazy };
