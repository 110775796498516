import React, { ReactElement } from 'react';

import { Table } from 'components/ui/Table';
import ComponentInfo from './ComponentInfo';
import SideNote from './SideNote';
import { FlexiDetailsProps } from '../../utils/init';
import { Input } from 'components/ui/Form/Input';
import { getNumberFromIndianCurrencyString, toIndianCurrencyString } from 'utils/Numbers';

import styles from "./../index.module.scss";

const SalaryComponent = ({ flexiDetails, setFlexiDetails, isUpdateLoading}: FlexiDetailsProps): ReactElement => {

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const numberValue = getNumberFromIndianCurrencyString(event.target.value);
        if(!isNaN(numberValue)) {
            setFlexiDetails({ ...flexiDetails, totalAmountAllocated: numberValue })
        }
    }

    const componentName = flexiDetails.flexiBenefitComponentName ?? "Flexible benefit";

    return (
        <>
            <ComponentInfo flexiBenefitComponentName={componentName} />
            <Table className="md:w-2/3 w-full mt-16 mb-20">
                <tbody className={styles['flexi-amount']}>
                    <tr>
                        <td>Component name</td>
                        <td>Monthly amount</td>
                    </tr>
                    <tr>
                        <td>{componentName}</td>
                        <td>
                            <Input
                                type="text"
                                value={toIndianCurrencyString(flexiDetails.totalAmountAllocated, true)}
                                onChange={onAmountChange} 
                                disabled={isUpdateLoading}
                                />
                        </td>
                    </tr>
                </tbody>
            </Table>
            <SideNote />
        </>
    )
}

export default SalaryComponent;