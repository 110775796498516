import {useQuery} from "react-query";
import {JIBBLE_GET_SYNCED_ATTENDANCE_REPORT} from "../utils/queries";
import api from "../../../../api";
import APIError from "../../../../types/apiError";
import {JibbleAttendanceDataSchemaContract} from "../../../../schemas/JibbleAttendanceDataSchema";
import {useState} from "react";

interface pollAttendanceDataProps {
    reportMonth: string | null;
    page: number;
    rowsLimit: number;
    onError: (param: string) => void;
    onSuccess: (data: JibbleAttendanceDataSchemaContract) => void;
}
export const usePollAttendanceData = ({reportMonth, page, rowsLimit, onError, onSuccess}: pollAttendanceDataProps) => {
    const [isPolling, setIsPolling] = useState(false);
    const {refetch, isFetching} = useQuery(
        JIBBLE_GET_SYNCED_ATTENDANCE_REPORT,
        () => {
            return api.jibbleSettings.getSyncedAttendanceData({
                payrollMonth: reportMonth ?? '',
                page,
                rowsLimit
            });
        },
        {
            onError: (error: APIError) => onError(error.message),
            onSuccess: (data) => !data?.is_attendance_sync_in_progress && onSuccess(data),
            refetchInterval: (data) => {
                const isAttendanceSyncing = data?.is_attendance_sync_in_progress;
                if (isAttendanceSyncing === undefined || isAttendanceSyncing === null || isAttendanceSyncing === true) {
                    setIsPolling(true);
                    return 10000;
                } else {
                    setIsPolling(false);
                    return false;
                }
            },
        },
    );
    return {
        isPolling,
        beginPolling: () => {
            refetch();
            setIsPolling(true);
        },
        refetch,
        isFetching
    };
}