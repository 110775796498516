import { AlertCircleIcon, Box, BoxProps, Heading, Text } from '@razorpay/blade/components';
import React, { PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ProgressPillState } from './types';
import bottomImg from '../../assets/kyc/sidebar_bottom.svg';
import topRightImg from '../../assets/kyc/sidebar_top_right.svg';

export const SidebarTopImg = styled.img`
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
`;

export const SidebarBottomImg = styled.img`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.2;
`;

export const SpanWrapper = styled.span`
  font-family: inherit;
`;

export const SidebarLink = styled.a`
  font-size: ${({ theme }) => theme.typography.fonts.size[100]}px;
  font-family: ${({ theme }) => theme.typography.fonts.family.text};
  font-weight: ${({ theme }) => theme.typography.fonts.weight.regular};
  line-height: ${({ theme }) => theme.typography.lineHeights[100]}px;
  color: ${({ theme }) => theme.colors.surface.text.gray.muted};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing[3]}px;
  align-items: center;

  :hover {
    color: ${({ theme }) => theme.colors.interactive.text.primary.normal};
  }
  :active {
    color: ${({ theme }) => theme.colors.interactive.text.primary.normal};
  }
  :visited {
    color: ${({ theme }) => theme.colors.interactive.text.primary.disabled};
  }
`;

export const KycPageSideViewWrapper = ({ children }: { children: ReactNode }) => (
  <Box
    backgroundColor={'surface.background.gray.subtle'}
    overflowY={'auto'}
    position={'relative'}
    display={{ base: 'none', l: 'block' }}>
    {children}
    <SidebarBottomImg src={bottomImg} />
    <SidebarTopImg src={topRightImg} />
  </Box>
);

export const KycPageMainViewWrapper = styled.form(({ theme }) => ({
  backgroundColor: theme.colors.surface.background.gray.intense,
  overflowY: 'auto',
  position: 'relative',
}));

export const KycPageWrapper = ({ children }: { children: ReactNode }) => (
  <Box
    display={'grid'}
    height={'100vh'}
    gridTemplateColumns={{ base: '1fr', l: '3fr 7fr' }}
    gridTemplateRows={'100vh'}>
    {children}
  </Box>
);

export const KycViewFooter = ({ children }: { children: ReactNode }) => (
  <Box
    backgroundColor={'surface.background.gray.moderate'}
    borderTopColor={'surface.border.gray.normal'}
    borderTopWidth={'thin'}
    width={'100%'}
    padding={'spacing.7'}
    display={'flex'}
    justifyContent={'space-between'}
    alignItems={'center'}>
    {children}
  </Box>
);

export const KycViewHeader = ({ children, ...boxProps }: { children: ReactNode } & BoxProps) => (
  <Box paddingTop={'spacing.10'} paddingX={'98px'} {...boxProps}>
    {children}
  </Box>
);

export const KycViewContent = ({ children }: { children: ReactNode }) => (
  <Box paddingX={'98px'} paddingY={'spacing.7'}>
    {children}
  </Box>
);

export const ProgressPill = styled.div<{
  progressState: ProgressPillState;
}>(({ theme, progressState }) => {
  let progress = '0';
  switch (progressState) {
    case 'IN_PROGRESS':
      progress = '50';
      break;
    case 'COMPLETED':
      progress = '100';
      break;
    case 'INCOMPLETE':
    default:
      progress = '0';
      break;
  }
  return {
    width: `${theme.spacing[9]}px`,
    position: 'relative',
    height: '4px',
    borderRadius: theme.border.radius.medium,
    backgroundColor: theme.colors.surface.border.gray.normal,
    margin: `0 ${theme.spacing[1]}px 0 ${theme.spacing[1]}px`,
    '&:before': {
      position: 'absolute',
      left: 0,
      content: "''",
      width: `${progress}%`,
      transition: `width 400ms linear 0ms`,
      height: '100%',
      background: 'linear-gradient(222.47deg, #00FFA3 -6.13%, #0500FF 138.33%)',
      borderRadius: theme.border.radius.medium,
    },
  };
});

// Success View Styles

const SubText = styled.span`
  color: ${({ theme }) => theme.colors.surface.text.gray.disabled};
  font-size: ${({ theme }) => theme.typography.fonts.size[300]}px;
  font-style: italic;
  font-weight: ${({ theme }) => theme.typography.fonts.weight.regular};
  line-height: ${({ theme }) => theme.typography.fonts.size[600]}px;
  font-family: ${({ theme }) => theme.typography.fonts.family.text};
`;

const FooterTextContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]}px;
  & + & {
    margin-left: ${({ theme }) => theme.spacing[2]}px;
  }
`;

export const SectionHeader = ({
  title,
  subtitle,
  ...boxProps
}: { title: string; subtitle: string } & BoxProps) => {
  return (
    (<Box display="flex" gap="spacing.1" alignItems="center" {...boxProps}>
      <Heading color="surface.text.gray.subtle" size="small">
        {title}
      </Heading>
      <SubText>{subtitle}</SubText>
    </Box>)
  );
};

export const TextPair = ({
  mutedText,
  highlightedText,
}: {
  mutedText?: ReactNode;
  highlightedText?: string;
}) => {
  return (
    (<FooterTextContainer>
      {mutedText ? (
        <Text as="span" color="surface.text.gray.muted">
          {mutedText}
        </Text>
      ) : null}
      {highlightedText ? (
        <Text as="span" weight="semibold" color="surface.text.gray.subtle">
          {highlightedText}
        </Text>
      ) : null}
    </FooterTextContainer>)
  );
};

export const FileUploadLabelWrapper = styled.div`
  width: 136px;
`;

export const FileUploadWrapper = ({
  label,
  children,
  ...boxProps
}: React.PropsWithChildren<{ label?: ReactNode }> & BoxProps) => {
  return (
    (<Box display={'flex'} alignItems={'center'} {...boxProps}>
      <FileUploadLabelWrapper>
        {label ? (
          <Text weight="semibold" color="surface.text.gray.muted">
            {label}
          </Text>
        ) : null}
      </FileUploadLabelWrapper>
      {children}
    </Box>)
  );
};

export const FooterActionsContainer = ({ children, ...boxProps }: PropsWithChildren<BoxProps>) => {
  return (
    <Box display={'flex'} gap={'spacing.5'} {...boxProps}>
      {children}
    </Box>
  );
};

export const SectionWrapper = styled.div<{ maxWidth?: string }>(({ theme, maxWidth }) => {
  return css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing[7]}px;
    max-width: ${maxWidth ?? '550px'};
    padding: ${theme.spacing[7]}px;
    border-radius: ${theme.border.radius.medium}px;
    border: 1px solid ${theme.colors.surface.border.gray.subtle};
    & + & {
      margin-top: ${theme.spacing[7]}px;
    }
  `;
});

export const ErrorMessage = ({ message }: { message: ReactNode }) => {
  return (
    <Box display="flex" gap="spacing.2" alignItems="center">
      <AlertCircleIcon size="small" color="feedback.icon.negative.intense" />
      <Text variant="caption" color="feedback.text.negative.intense" size='small'>
        {message}
      </Text>
    </Box>
  );
};
