import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { fetchInsurance, IInsurance } from '../../../reducers/insurance';
import { fetchInsuranceEmployee, IInsuranceEmployee } from '../../../reducers/insuranceEmployee';
import useReduxSelector from '../../../utils/useReduxSelector';
import { WarningAlert } from '../../ui/Alert';
import EmployeeDetails from './EmployeeDetails';
import { EmployeeInsuranceDetails } from './EmployeeInsuranceDetails';
import { routePaths } from 'components/Routes/data';

export function EmployeeInsurance({
  insuranceEmployee,
  insurance,
}: {
  insuranceEmployee: IInsuranceEmployee;
  insurance: IInsurance;
}) {
  if (!insurance.data) {
    return <></>;
  }

  if (insurance.data.isBlocked) {
    return (
      <div className="text-center mt-20">
        Sorry, currently we don't have any insurance offer for you. Please contact admin for further
        queries.
      </div>
    );
  }

  if (!insuranceEmployee.data) {
    return <></>;
  }
  return (
    <div className="flex flex-col justify-start">
      <Switch>
        <Route path={routePaths.insurance.user.details}>
          {insuranceEmployee.data.status === 'PURCHASE_IN_PROGRESS' ||
          insuranceEmployee.data.status === 'PURCHASED' ||
          insuranceEmployee.data.status === 'PURCHASE_PENDING' ? (
            <EmployeeInsuranceDetails insuranceEmployee={insuranceEmployee} insurance={insurance} />
          ) : (
            <Redirect to={routePaths.insurance.user.notEligible} />
          )}
        </Route>
        <Route path={routePaths.insurance.user.employeeDetails}>
          {insurance.data &&
          !['NO_ACTIVE_INSURANCE', 'ESTIMATION_IN_PROGRESS'].includes(insurance.data.status) &&
          (insuranceEmployee.data.status === 'HAS_MISSING_DATA' ||
            insuranceEmployee.data.status === 'HAS_NO_INSURANCE') ? (
            <EmployeeDetails
              insurance={insurance}
              isAdmin={false}
              insuranceEmployee={insuranceEmployee}
            />
          ) : (
            <Redirect to={routePaths.insurance.user.details} />
          )}
        </Route>
        <Route path={routePaths.insurance.user.notEligible}>
          {!['NO_ACTIVE_INSURANCE', 'ESTIMATION_IN_PROGRESS'].includes(insurance.data.status) &&
          ['PURCHASE_IN_PROGRESS', 'PURCHASED', 'PURCHASE_PENDING', 'HAS_MISSING_DATA'].includes(
            insuranceEmployee.data.status,
          ) ? (
            <Redirect from="/" to={'/employeeDetails'} />
          ) : (
            <div className="p-12">
              <WarningAlert>
                Looks like there is no insurance for you. Please contact administrator.
              </WarningAlert>
            </div>
          )}
        </Route>

        <Redirect to={routePaths.insurance.user.employeeDetails} />
      </Switch>
    </div>
  );
}

export default function EmployeeInsuranceContainer() {
  const dispatch = useDispatch();
  const loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const insuranceEmployee = useReduxSelector((state) => state.insuranceEmployee);
  const employeeId = loggedInUser.data?.currentOrganization?.employeeDetails?.id;
  const insurance = useReduxSelector((state) => state.insurance);

  useEffect(() => {
    dispatch(
      fetchInsurance(
        loggedInUser.data?.currentOrganization?.id
          ? loggedInUser.data?.currentOrganization?.id
          : null,
      ),
    );
  }, [loggedInUser.data?.currentOrganization?.employeeDetails?.id]);
  useEffect(() => {
    employeeId && dispatch(fetchInsuranceEmployee(employeeId));
  }, [employeeId]);

  if (!employeeId) {
    return (
      <div className="text-center leading-12"> Sorry you are not the part of any organization.</div>
    );
  }

  return <EmployeeInsurance insuranceEmployee={insuranceEmployee} insurance={insurance} />;
}
