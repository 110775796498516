import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { RunPayrollData } from 'components/RunPayroll/utils/types';
import { getArrearWithName } from './utils';
import { arrearTypes } from '../constants';

const useNewJoinerArrearEnabled = (payrollEntry?:RunPayrollData) => {
    const features = useSelector(loggedInUserSelectors.features);
    const isNewJoinerArrearFeatureEnabled = !!features?.['newJoinerFlag'] && !!features?.['customPayrollCycleFlag'] && !!features?.['pf-arrears'] && !!features?.['esiArrear'];
    const newJoinerArrearPaymentMonth = payrollEntry ? !!payrollEntry.newJoinerArrearPaymentMonth : false;

    const newJoinerArrears = getArrearWithName(
        (payrollEntry && payrollEntry.arrearsDistribution) ? payrollEntry.arrearsDistribution : [],
        arrearTypes.new_joiner
      );

    if(payrollEntry){
        return {
            isNewJoinerArrearFeatureEnabled: isNewJoinerArrearFeatureEnabled,
            newJoinerArrearPaymentMonthExists: isNewJoinerArrearFeatureEnabled && newJoinerArrearPaymentMonth,
            newJoinerArrearExists: isNewJoinerArrearFeatureEnabled && newJoinerArrears && newJoinerArrears.value > 0
        }
    }
    else{
        return {
            isNewJoinerArrearFeatureEnabled: isNewJoinerArrearFeatureEnabled
        }
    }

    
}

export default useNewJoinerArrearEnabled;