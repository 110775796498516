import { DeductFrom, DeductionType } from 'components/RunPayroll/utils/types';
import {
  addMonths,
  format,
  formatDistance,
  isBefore,
  isSameMonth,
  parse,
  startOfMonth,
} from 'date-fns';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import React from 'react';
import { FnfUpdateRequestSchemaContract } from 'schemas/FnfUpdateRequestSchema';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { dateFormats } from 'utils/Dates';
import {DECIMAL_REGEX, NUMBER_REGEX} from 'utils/regexes';

export const isValidLop = (lopDays: string | undefined, payrollMonth: Date) => {
  lopDays = lopDays ? lopDays.trim() : '0';
  if (!lopDays.match(DECIMAL_REGEX)) {
    return false;
  }

  const daysInCurrentMonth = getDaysInMonth(payrollMonth);

  if (parseFloat(lopDays) > daysInCurrentMonth) {
    return false;
  }

  return true;
};

export const isValidLeaveEncashMentDays = (days: string | undefined) => {
  days = days ? days.trim() : '0';
  if (!days.match(DECIMAL_REGEX)) {
    return false;
  }

  return true;
};

export const isValidLeaveEncashmentDaysFeatureFlag = (days: string | undefined) => {
  days = days ? days.trim() : '0';
  if (!days.match(NUMBER_REGEX)) {
    return false;
  }

  return true;
};

export const getErrorMessageForDismissalDate = (
  payrollStatus: GetFnfResponseSchemaContract['payroll_status'],
) => {
  let erroMessage = '';

  if (payrollStatus.is_finalized && !payrollStatus.is_paid) {
    erroMessage =
      "This month's payroll is already finalised for this user. Please unfinalise the payroll first to dismiss the user.";
  } else if (payrollStatus.is_finalized && payrollStatus.is_paid) {
    erroMessage =
      "This month's payroll is already executed for this employee. If you still dismiss the employee, it will convert the employee's latest payslip to full and final settlement without changing any payroll data.";
  }

  return erroMessage;
};

export const makeAdditionOfMinimumLenth = (
  additions: GetFnfResponseSchemaContract['additions'],
) => {
  if (additions) {
    const newAdditions = [...additions];
    const newAdditionsLength = newAdditions?.length ?? 0;
    if (newAdditionsLength < 3) {
      for (let i = 0; i < 3 - newAdditionsLength; i++) {
        newAdditions?.push({ name: '', amount: 0 });
      }
    }

    return newAdditions;
  }

  return additions;
};

export const makeNonLopDeductionsOfMinimumLength = (
  nonLopDeductions: GetFnfResponseSchemaContract['deductions']['non_lop_deductions'],
  deductFrom: number = DeductFrom.GROSS_PAY,
  isDisabled = false,
) => {
  if (nonLopDeductions) {
    const newDeductions = [...nonLopDeductions];
    const deductionLength = newDeductions?.length ?? 0;

    if (deductionLength < 3) {
      for (let i = 0; i < 2 - deductionLength; i++) {
        newDeductions.push({
          name: '',
          amount: 0,
          deduct_from: deductFrom,
          lop_days: 0,
          type: DeductionType.TYPE_MANUAL,
        });
      }
    }

    return newDeductions.map((deduction) => ({
      ...deduction,
      isDisabled,
    }));
  }

  return nonLopDeductions;
};

export const prepareFnfDataForUpdate = (
  fnfData: GetFnfResponseSchemaContract,
  dismissalDate: Date,
  people_id: number,
  startingMonth: string | null = null,
) => {
  const {
    reason_for_leaving,
    personal_email_address,
    additions,
    deductions,
    leave_encashments,
    gratuity,
  } = fnfData;

  const newDeductions = {
    non_lop_deductions: deductions?.non_lop_deductions,
    lop_days: deductions?.lop_deduction?.lop_days,
  };

  const fnfDataForUpdate: FnfUpdateRequestSchemaContract = {
    people_id,
    reason_for_leaving,
    personal_email_address,
    deactivate_date: format(dismissalDate, dateFormats.yearMonthDate),
    additions,
    deductions: newDeductions,
    leave_encashments,
    gratuity_amount: gratuity?.gratuity_amount ?? null,
    hold_from: startingMonth,
  };

  return fnfDataForUpdate;
};

export const isNotEmptyElement = (element: React.ReactNode): boolean => {
  return React.isValidElement(element) && React.Children.count(element.props.children) > 0;
};

export const formatDOJ = (hireDate?: string | null) => {
  if (!hireDate) return '';
  const date = parse(hireDate, dateFormats.yearMonthDate, new Date());
  return format(date, dateFormats.dateMonthFullYearWithSlash);
};

export const formatTenure = (hireDate?: string | null) => {
  if (!hireDate) return '';
  const date = parse(hireDate, dateFormats.yearMonthDate, new Date());
  return formatDistance(date, new Date());
};

export const getPayrollMonthsAvailableForHold = (
  lastFinalizalizedPayrollMonth: string | null | undefined,
  dismissalDate: Date | null | undefined,
) => {
  const lastFinalizalizedPayrollMonthDate = lastFinalizalizedPayrollMonth
    ? addMonths(parse(lastFinalizalizedPayrollMonth, dateFormats.yearMonthDate, new Date()), 1)
    : new Date();
  const dismissalDateDate = dismissalDate || new Date();
  const dismissalMonth = startOfMonth(dismissalDateDate);
  const payrollMonthsAvailableForHold = [];
  let month = startOfMonth(lastFinalizalizedPayrollMonthDate);

  const isPayrollSettlementMonth = isSameMonth(month, dismissalMonth);

  while (isBefore(month, dismissalMonth) || isSameMonth(month, dismissalMonth)) {
    payrollMonthsAvailableForHold.push({
      formattedValue: format(month, dateFormats.yearMonthDate),
      displayFormat: format(month, dateFormats.monthYear),
      date: month,
    });
    month = addMonths(month, 1);
  }

  return { isPayrollSettlementMonth, payrollMonthsAvailableForHold };
};

export const getNetPayOnHoldMessage = (
  payrollMonthsAvailableForHold: Array<{
    displayFormat: string;
    formattedValue: string;
    date: Date;
  }>,
  netPayOnHoldFrom?: string | null,
) => {
  const lastNetPayOnHoldMonth =
    payrollMonthsAvailableForHold.length > 0
      ? payrollMonthsAvailableForHold[payrollMonthsAvailableForHold.length - 1]
      : null;
  const settlementMonth = lastNetPayOnHoldMonth ? lastNetPayOnHoldMonth.date : null;
  const fnfSettlementMonthMessage = settlementMonth
    ? `Full and final settlement amount can be released from ${format(
        settlementMonth,
        dateFormats.monthYear,
      )} payroll`
    : '';

  const messageForNetPayOnHoldFrom = netPayOnHoldFrom
    ? `Netpay will be on hold from ${format(
        parse(netPayOnHoldFrom, dateFormats.yearMonthDate, new Date()),
        dateFormats.monthYear,
      )}. ` + fnfSettlementMonthMessage
    : '';

  return messageForNetPayOnHoldFrom;
};
