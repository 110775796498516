import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { WarningAlert } from 'components/ui/Alert';
import { toIndianCurrencyString } from 'utils/Numbers';
import { useModal } from 'components/ui/Modals';
import PayrollAmountBreakdown from '../Popups/PayrollAmountBreakdown';
import RazorpayChargesBreakdown from '../Popups/RazorpayChargesBreakdown';
import useHasPermission from 'utils/useHasPermission';
import permissions from 'constants/permissions';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { FETCH_RX_BALANCE } from '../utils/queries';
import api from 'api';
import { LinkButtonV2 } from 'components/ui/Button';

import styles from "./index.module.scss";

const AmountDisplay = () => {
  const { openModal } = useModal();
  const isAuthorizedToViewBalance = useHasPermission([permissions.COMPANY_VIEW_BALANCE]);
  
  const payrollData = useRunPayrollDataContext();
  const orgId = useSelector(loggedInUserSelectors.organizationID);
  const isRXIntegrated = useSelector(loggedInUserSelectors.isOrgRXIntegrated);

  const { isLoading, isRefetching, data } = useQuery(
    FETCH_RX_BALANCE,
    () => {
      if(orgId) {
        return api.runPayroll.getRXBalance(orgId);
      }
    },
    {
      enabled: !!isRXIntegrated,
    }
  );

  if (!payrollData) {
    return null;
  }

  const rxBalance = data?.balance;

  const {
    totalGross,
    opfinCharges,
    hasOtherCharges,
    orgBalance,
    requiredAmount,
    isPayrollFinalized,
    flexiPartnerName,
    isPayrollBeingFinalized,
    isBulkAdditionDeductionInProgress,
    isChangeRequestPendingOnFinalize,
    numPendingEditRequest
  } = payrollData;

  const showBreakdown = () => {
    if (requiredAmount) {
      openModal(
        <PayrollAmountBreakdown
          requiredAmount={requiredAmount}
          flexiPartnerName={flexiPartnerName}
        />,
      );
    }
  };

  const showRPChargesBreakdown = () => {
    if (opfinCharges > 0) {
      openModal(
        <RazorpayChargesBreakdown
          requiredAmount={requiredAmount}
          flexiPartnerName={flexiPartnerName}
          opfinCharges={opfinCharges}
        />,
      );
    }
  };
  
  let finalizeMessage = "Finalize your payroll to view the required amount.";
  if(isBulkAdditionDeductionInProgress) {
    finalizeMessage = "Finalize your payroll to view the required amount. This is currently disabled as there is an ongoing bulk operation on your payroll data.";
  }

  if(isPayrollBeingFinalized || isChangeRequestPendingOnFinalize || numPendingEditRequest > 0) {
    return null;
  }

  if ((!isPayrollFinalized || requiredAmount == null)) {
    return (
      <WarningAlert className="mb-10">
        {finalizeMessage}
      </WarningAlert>
    );
  }

  
  return (
    <>
      <ul className={`${styles['amount-list']} mb-10 mt-5`}>
        <li className={styles['amount-list-item']}>
          <h4>Payroll Amount</h4>
          <p>
            <LinkButtonV2
              onClick={showBreakdown}
              id="amount-components"
              className={`font-bold ${styles['run-payroll-link-button']}`}>
              {toIndianCurrencyString(totalGross, false, 2)}
            </LinkButtonV2>
          </p>
        </li>
        {opfinCharges > 0 && (
          <>
            <li className={styles['amount-list-item']}>
              <h4>Razorpay Charges (incl. GST)</h4>
              {hasOtherCharges ? (
                <p>
                  <LinkButtonV2
                    onClick={showRPChargesBreakdown}
                    id="xpayroll-charge-components"
                    className={`font-bold ${styles['run-payroll-link-button']}`}>
                    {toIndianCurrencyString(opfinCharges, false, 2)}
                  </LinkButtonV2>
                </p>
              ) : (
                <p>
                  <strong>{toIndianCurrencyString(opfinCharges, false, 2)}</strong>
                </p>
              )}
            </li>
          </>
        )}

        <li className={styles['amount-list-item']}>
          <h4>Total Required Balance</h4>
          <p>
            <strong>{toIndianCurrencyString(totalGross + opfinCharges, false, 2)}</strong>
          </p>
        </li>

        {isAuthorizedToViewBalance && (
          <>
            {isRXIntegrated ? (
              <li className={styles['balance-list-item']}>
                <h4>Current Balance</h4>
                <p>
                  <strong>
                    <span id="rx-acount-balance">
                      {isLoading || isRefetching
                        ? 'Loading...'
                        : toIndianCurrencyString(rxBalance ?? 0, false, 2)}
                    </span>
                  </strong>
                </p>
              </li>
            ) : (
              <li className={styles['balance-list-item']}>
                <h4>Current Balance</h4>
                <p>
                  <strong>{toIndianCurrencyString(orgBalance, false, 2)}</strong>
                </p>
              </li>
            )}
            <li className={styles['amount-list-item']}>
              <p>
                <a
                  href="https://razorpay.com/docs/payroll/faqs/#1-when-will-payroll-update-my-account-balance"
                  target="_blank"
                  rel="noreferrer">
                  Balance not updated?
                </a>
              </p>
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export default AmountDisplay;
