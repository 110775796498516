import React from 'react';
import { Box, Text } from '@razorpay/blade/components';
import { toIndianCurrencyString } from 'utils/Numbers';
import styled from 'styled-components';

type PayableTaxPercentProps = {
  regime: string;
  tax: number;
  percent: number;
  variant?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const PayableTaxWrapper = styled.div<{ variant: string; width: number }>(
  ({ theme, variant, width }) => `
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    min-width: 250px;
    justify-content: space-between;
    cursor: pointer;
    gap: ${theme.spacing[6]}px;
    padding: ${theme.spacing[6]}px ${theme.spacing[7]}px;
    border-radius: ${theme.border.radius.none}px ${theme.border.radius.large}px ${theme.border.radius.large}px ${theme.border.radius.none}px;
    border-top: 1px solid ${
      variant === 'grey' ? theme.colors.surface.border.gray.subtle : 'rgba(134, 255, 233, 0.18)'
    };
    border-right: 1px solid ${
      variant === 'grey' ? theme.colors.surface.border.gray.subtle : 'rgba(134, 255, 233, 0.18)'
    };
    border-bottom: 1px solid ${
      variant === 'grey' ? theme.colors.surface.border.gray.subtle : 'rgba(134, 255, 233, 0.18)'
    };
    width: ${width}%;
    background: ${
      variant === 'grey'
        ? 'rgba(209, 210, 218, 0.06)'
        : 'rgba(29, 75, 99, 0.40)'
    };
  `,
);

export const AmountText = styled.div<{ variant: string }>(
  ({ theme, variant }) => `
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: ${variant === 'grey' ? theme.colors.surface.text.gray.muted : '#8BFFEB'};
  `,
);

const PayableTaxPercent = ({ regime, tax, percent, variant, onClick }: PayableTaxPercentProps) => {
  return (
    (<PayableTaxWrapper variant={variant ?? 'grey'} width={percent} onClick={onClick}>
      <Box>
        <Text color="surface.text.gray.normal" size="large" weight="semibold">
          {regime}
        </Text>
      </Box>
      <AmountText variant={variant ?? 'grey'}>{toIndianCurrencyString(tax)}</AmountText>
      {variant === 'green' && (
        <Box position={'absolute'} right="-50px">
          <p style={{ fontSize: '36px' }}>🤑</p>
        </Box>
      )}
    </PayableTaxWrapper>)
  );
};

export default PayableTaxPercent;
