import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextArea,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import { MyRequestsContextType, useMyRequestsContext } from '../MyRequestsContext';
import { useMutation } from 'react-query';
import api from 'api';
import { showToastViaEvent } from 'utils/ToastEvents';
import { getAppErrorDetails } from 'utils/AppError';
import { errorParser } from 'utils/Api';

const BulkApprovalModal = () => {
  const { bulkApproveRejectWithdrawRequests, closeBulkApprovalModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { requestIds, isApprovalModalOpen, callback } = bulkApproveRejectWithdrawRequests;
  const [remark, setRemark] = useState('');
  const requestsCount = requestIds.length;
  const title = `Approve ${requestsCount} requests`;
  const isApprovalCtaDisabled = !remark;

  const bulkApprovalMutation = useMutation({
    mutationFn: api.workflowApis.approveRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: `${success.length} requests successfully approved`,
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: `${failure.length} requests could not be approved`,
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeBulkApprovalModal();
      callback?.();
    },
  });

  const handleApproveButtonClick = () => {
    bulkApprovalMutation.mutate({
      ids: requestIds,
      remarks: remark,
    });
  };

  return (
    <Modal isOpen={isApprovalModalOpen} onDismiss={closeBulkApprovalModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <TextArea
          value={remark}
          label="Add Remarks"
          placeholder="Eg. Bulk approving as discussed on mail."
          onChange={({ value }) => {
            setRemark(value ?? '');
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={bulkApprovalMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {bulkApprovalMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(bulkApprovalMutation.error))[0]}
            </Text>
          )}
          <Button
            isLoading={bulkApprovalMutation.isLoading}
            onClick={handleApproveButtonClick}
            isDisabled={isApprovalCtaDisabled}>
            Approve
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

const SingleApprovalModal = () => {
  const { singleApproveRejectWithdrawRequests, closeSingleApprovalModal } =
    useMyRequestsContext() as MyRequestsContextType;
  const { isApprovalModalOpen, requestId, callback } = singleApproveRejectWithdrawRequests;
  const [remark, setRemark] = useState('');
  const title = 'Approve request';
  const isApprovalCtaDisabled = !remark;


  const singleApprovalMutation = useMutation({
    mutationFn: api.workflowApis.approveRequests,
    onSuccess: (data) => {
      const { success, failure } = data;
      if (success?.length) {
        showToastViaEvent({
          text: 'Request successfully approved',
          timeout: 5000,
          type: 'success',
          icon: 'success',
        });
      }

      if (failure?.length) {
        showToastViaEvent({
          text: 'Request could not be approved',
          timeout: 5000,
          type: 'danger',
          icon: 'info',
        });
      }

      closeSingleApprovalModal();
      callback?.();
    },
  });

  const handleApproveButtonClick = () => {
    singleApprovalMutation.mutate({
      ids: [requestId],
      remarks: remark,
    });
  };

  return (
    <Modal isOpen={isApprovalModalOpen} onDismiss={closeSingleApprovalModal}>
      <ModalHeader title={title} />
      <ModalBody>
        <TextArea
          value={remark}
          label="Add Remarks"
          placeholder="Eg. Approving as discussed on mail."
          onChange={({ value }) => {
            setRemark(value ?? '');
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Box
          display="flex"
          justifyContent={singleApprovalMutation.isError ? 'space-between' : 'flex-end'}
          alignItems="baseline"
          width="100%">
          {singleApprovalMutation.isError && (
            <Text size="small" color="feedback.text.negative.intense">
              {errorParser(getAppErrorDetails(singleApprovalMutation.error))[0]}
            </Text>
          )}
          <Button
            isLoading={singleApprovalMutation.isLoading}
            onClick={handleApproveButtonClick}
            isDisabled={isApprovalCtaDisabled}>
            Approve
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

export { SingleApprovalModal, BulkApprovalModal };
