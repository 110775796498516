import { Divider } from '@razorpay/blade/components';
import React from 'react';
import PayrollLogo from '../../../assets/payroll-logo.svg';
import RayName from '../../../assets/payrollAiAssistant/RayName.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import styled from 'styled-components';

const HeaderContainer = styled.div(
  ({ theme }) => `
    z-index: 2;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: ${theme.spacing[4]}px ${theme.spacing[6]}px;

    @media (min-width: ${theme.breakpoints.m}px) {
        padding: ${theme.spacing[6]}px ${theme.spacing[7]}px;
    }
`,
);

const Header = () => {
  return (
    <HeaderContainer>
      <img src={getStaticMediaUrl(PayrollLogo)} width="158px" />
      <Divider orientation="vertical" />
      <img src={getStaticMediaUrl(RayName)} />
    </HeaderContainer>
  );
};

export { Header };
