import {
  Box,
  Button,
  CheckCircle2Icon,
  Divider,
  Drawer,
  DrawerBody,
  RefreshIcon,
  StampIcon,
  Text,
} from '@razorpay/blade/components';
import api from 'api';
import { ModalActionButtons } from 'components/SalaryComponents/components/ModalActionButtons';
import {
  DetailsContainer,
  DetailsFooter,
  DetailsHeader,
  DetailsSection,
  DetailsSectionHeading,
  DetailsSectionItem,
  DetailsSectionItemsContainer,
  DetailsViewSkeleton,
} from 'components/SalaryComponents/components/details/DetailsViewHelpers';
import { useDetailsView, useSalaryComponentHelpers } from 'components/SalaryComponents/hooks';
import { ActionModals } from 'components/SalaryComponents/modals';
import { useQuery, useQueryClient } from 'react-query';
import { salaryComponentParams, salaryComponentsQueries } from '../../constants';

import { EarningsComponentType } from 'components/SalaryComponents/types';
import { getEarningPayTypes } from 'components/WizardViews/SalaryComponents/constants';
import {
  earningsExemptionDisplayText,
  isTaxExempt,
} from 'components/WizardViews/SalaryComponents/utils';
import useFeatures from 'hooks/useFeatures';
import React, { useState } from 'react';
import { EmptyView } from '../../components/EmptyView';

export const EarningsDetailView = () => {
  const {
    paramId: salaryComponentId,
    closeDetailsView,
    payType,
  } = useDetailsView(salaryComponentParams.componentId);
  const { openEarningsEditPage, openPredefinedEditPage } = useSalaryComponentHelpers();
  const queryClient = useQueryClient();
  const [currentActiveModal, setCurrentActiveModal] = useState<string | null>(null);
  const isOpen = !!salaryComponentId;
  const { features } = useFeatures();

  const openModal = (modal: string) => {
    setCurrentActiveModal(modal);
  };

  const closeModal = () => {
    setCurrentActiveModal(null);
  };

  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery],
    queryFn: () =>
      api.salaryComponents.queries.getSalaryComponent<EarningsComponentType>({
        id: salaryComponentId!,
        category: 'EARNINGS_CATEGORY',
        payType,
      }),
    enabled: isOpen,
    select(data) {
      return data.payrollGetSalaryComponent.data.salaryComponent;
    },
    onError(error) {
      console.log(error);
    },
    cacheTime: 0,
  });

  const onDismiss = () => {
    queryClient.invalidateQueries({
      queryKey: [salaryComponentsQueries.getSalaryComponentDetailsQuery, salaryComponentId],
    });
    closeModal();
    closeDetailsView();
  };

  const componentData = data as EarningsComponentType;
  const { earningDetails, behaviour } = componentData?.settings || {};

  const {
    arrearBehaviour,
    displayBehaviour,
    esiBehaviour,
    lwfBehaviour,
    pfBehaviour,
    prorationBehaviour,
    ptBehaviour,
    taxBehaviour,
  } = behaviour || {};

  const earningPayTypes = getEarningPayTypes(features);
  const earningPayTypeLabel = earningDetails?.payType
    ? earningPayTypes[earningDetails?.payType].label
    : 'N/A';

  const handleEditClick = () => {
    if (componentData.isPredefined) {
      openPredefinedEditPage(salaryComponentId!);
    } else {
      openEarningsEditPage(salaryComponentId!, payType);
    }
  };

  const taxExempt = isTaxExempt(
    taxBehaviour?.taxExemptInNewRegime,
    taxBehaviour?.taxExemptInOldRegime,
  );

  return (
    <>
      <Drawer isOpen={isOpen} onDismiss={onDismiss} zIndex={900}>
        <DrawerBody>
          <DetailsContainer onDismiss={onDismiss}>
            {isLoading && <DetailsViewSkeleton onDismiss={onDismiss} />}
            {isError && (
              <EmptyView
                description={
                  <Text color={'surface.text.gray.muted'} weight={'medium'} textAlign={'center'}>
                    Due to an unknown error, we could not load the component details page.
                  </Text>
                }>
                <Box display={'flex'} justifyContent={'center'}>
                  <Box width={'104px'}>
                    <Button icon={RefreshIcon} size={'small'} onClick={() => refetch()}>
                      Retry
                    </Button>
                  </Box>
                </Box>
              </EmptyView>
            )}
            {componentData ? (
              <>
                <DetailsHeader
                  heading={componentData.name}
                  description={componentData.description ?? ''}
                  isActive={Boolean(componentData.isActive)}
                  isPredefined={Boolean(componentData.isPredefined)}
                />

                <Divider marginY={'spacing.7'} />

                {/* Basic and Pay section */}
                <DetailsSection>
                  <DetailsSectionHeading
                    icon={<CheckCircle2Icon size={'large'} color={'surface.icon.gray.subtle'} />}
                    label="Basic and Pay"
                  />
                  <DetailsSectionItemsContainer>
                    <DetailsSectionItem label="Component Name" value={componentData.name || ''} />
                    <DetailsSectionItem
                      label="Display Name"
                      value={displayBehaviour?.displayName || ''}
                    />
                    <DetailsSectionItem label="Pay Type" value={earningPayTypeLabel} />
                    {earningDetails?.payType !== 'adhoc' && (
                      <DetailsSectionItem
                        label="Proration"
                        value={
                          prorationBehaviour
                            ? prorationBehaviour.supportProration
                              ? 'Enabled'
                              : 'Disabled'
                            : '-'
                        }
                      />
                    )}
                    {/* commenting out till we have support for arrears */}
                    {/* <DetailsSectionItem
                      label="Arrear Calculation"
                      value={
                        arrearBehaviour
                          ? arrearBehaviour.considerInArrear
                            ? 'Enabled'
                            : 'Disabled'
                          : '-'
                      }
                    /> */}
                  </DetailsSectionItemsContainer>
                </DetailsSection>

                <Divider marginY={'spacing.7'} />

                {/* Taxation section */}
                <DetailsSection>
                  <DetailsSectionHeading
                    icon={<StampIcon size={'large'} color={'surface.icon.gray.subtle'} />}
                    label="Taxation"
                  />
                  <DetailsSectionItemsContainer>
                    <DetailsSectionItem
                      label="Tax Exempt - Old Regime"
                      value={taxBehaviour?.taxExemptInOldRegime ? 'Yes' : 'No'}
                    />
                    <DetailsSectionItem
                      label="Tax Exempt - New Regime"
                      value={taxBehaviour?.taxExemptInNewRegime ? 'Yes' : 'No'}
                    />
                    {taxExempt && (
                      <DetailsSectionItem
                        label="Exemption under"
                        value={
                          earningsExemptionDisplayText(taxBehaviour?.taxExemptionSection) ?? 'N/A'
                        }
                      />
                    )}
                    {taxBehaviour?.taxDeductionBehaviour &&
                      taxBehaviour.taxDeductionBehaviour != 'not_applicable' && (
                        <DetailsSectionItem
                          label="TDS Deduction"
                          value={
                            taxBehaviour.taxDeductionBehaviour === 'instant'
                              ? 'Instant'
                              : 'Prorated'
                          }
                        />
                      )}
                    <DetailsSectionItem
                      label="Part of PF wage"
                      value={pfBehaviour ? (pfBehaviour.includeInPfWages ? 'Yes' : 'No') : '-'}
                    />
                    <DetailsSectionItem
                      label="Part of ESI wage"
                      value={esiBehaviour ? (esiBehaviour.includeInEsiWages ? 'Yes' : 'No') : '-'}
                    />
                    <DetailsSectionItem
                      label="Part of PT wage"
                      value={ptBehaviour ? (ptBehaviour.includeInPtWages ? 'Yes' : 'No') : '-'}
                    />
                    <DetailsSectionItem
                      label="Part of LWF wage"
                      value={lwfBehaviour ? (lwfBehaviour.includeInLwfWages ? 'Yes' : 'No') : ''}
                    />
                  </DetailsSectionItemsContainer>
                </DetailsSection>

                <Divider marginY={'spacing.7'} />

                <DetailsFooter isSticky={true}>
                  <ModalActionButtons
                    isActive={Boolean(componentData.isActive)}
                    showEnableDisableButton={!Boolean(componentData.isPredefined)}
                    openModal={openModal}
                    onEditClick={handleEditClick}
                  />
                </DetailsFooter>
              </>
            ) : null}
          </DetailsContainer>
        </DrawerBody>
      </Drawer>
      <ActionModals
        salaryComponent={componentData}
        currentActiveModal={currentActiveModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default EarningsDetailView;
