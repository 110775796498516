import { Amount, Box, Text, ArrowUpIcon, ArrowDownIcon } from '@razorpay/blade/components';
import React from 'react';
import { NewToOldAmount } from './NewToOldAmount';

export interface NewToOldAmountProps {
  oldAmount: number;
  newAmount: number;
}

const NewToOldAmountWithDelta = ({ newAmount, oldAmount }: NewToOldAmountProps) => {
  const diff = newAmount - oldAmount;

  return (
    <Box display="grid" gridTemplateColumns={'1fr 1fr'} gap={'spacing.6'} justifyItems={'left'}>
      <Box alignItems={'right'}>
        {
          <NewToOldAmount newAmount={newAmount} oldAmount={diff === 0 ? undefined : oldAmount} />
        }
      </Box>
      <Box display="flex" flexDirection="row" alignItems={'right'}>
        {diff === 0 ?
          <></> : (diff > 0 ?
            <ArrowUpIcon
              size='small'
              color="feedback.icon.positive.intense"
              marginTop={'spacing.2'}
            /> :
            <ArrowDownIcon
              size='small'
              color="feedback.icon.negative.intense"
              marginTop={'spacing.2'}
            />)
        }
        {diff === 0 ?
          <></> : (diff > 0 ?
            <Amount
              color="feedback.text.positive.intense"
              value={diff}
              isAffixSubtle={false}
              type="body"
              size="small" />
            :
            <Amount
              color="feedback.text.negative.intense"
              value={Math.abs(diff)}
              isAffixSubtle={false}
              type="body"
              size="small" />)
        }
      </Box>
    </Box>
  );
};

export { NewToOldAmountWithDelta };
