import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import BankDetailsManualView from './BankDetailsManualView';
import BankDetailsView from './BankDetailsView';
import { KycDocumentsContextProvider } from './KycDocumentsContext';
import UploadDocumentsView from './UploadDocumentsView';
import UserSelectionView from './UserSelectionView';
import { kycVersions } from './constants';
import { VerifyCompanyDetails } from './VerifyCompanyDetails';
import { VerifyCompanyDetailsContextProvider } from './VerifyCompanyDetails/VerifyCompanyDetailsContext';
import useReduxSelector from 'utils/useReduxSelector';
import { Box, Spinner } from '@razorpay/blade/components';

const KycView = () => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const isFetchingMeApiData = useReduxSelector(({ loggedInUser }) => loggedInUser.isFetching);
  const isKycFlowV2 = org?.onboardingConfig?.kycFlowType === kycVersions.V2;

  if (isFetchingMeApiData)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <Spinner accessibilityLabel="loading" />
      </Box>
    );

  const isViewUnAvailable = !isKycFlowV2;

  if (isViewUnAvailable) {
    window.location.href = routePaths.unknown;
    return null;
  }

  return (
    <KycDocumentsContextProvider>
      <Switch>
        <RestrictedRoute
          path={[routePaths.kyc.root, routePaths.kyc.bankDetails]}
          exact
          component={BankDetailsView}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.kyc.bankDetailsManual}
          exact
          component={BankDetailsManualView}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.kyc.documentsUpload}
          exact
          component={UploadDocumentsView}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.kyc.userSelection}
          exact
          component={UserSelectionView}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}
        />
        <RestrictedRoute
          path={routePaths.kyc.companyDetailsVerification.root}
          allowedPermissions={[permissions.EDIT_COMPANY_DETAILS]}>
          <VerifyCompanyDetailsContextProvider>
            <VerifyCompanyDetails />
          </VerifyCompanyDetailsContextProvider>
        </RestrictedRoute>
        <Route>
          <Redirect to={routePaths.unknown} />
        </Route>
      </Switch>
    </KycDocumentsContextProvider>
  );
};

export default KycView;
