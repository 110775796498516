import React from 'react';
import cx from 'classnames';
import bannerLeft from 'assets/onboarding/banner-left.png';
import bannerRight from 'assets/onboarding/banner-right.png';
import successTick from 'assets/successTick.svg';
import { getStaticMediaUrl } from 'utils/Urls';

import styles from './index.module.scss';

type OnboardingStatusProps = {
  onChange: (value: number) => void;
  title: string;
  className?: string;
  value: number;
};

const OnboardingStatus: React.FC<OnboardingStatusProps> = ({
  title,
  children,
  className = '',
  value,
  onChange,
}) => {
  return (
    <div className={cx(styles['Container'], 'bg-persian-blue relative', className)}>
      <img
        className={styles['BannerLeftImage']}
        src={getStaticMediaUrl(bannerLeft)}
        alt="banner-left-img"
      />
      <img
        className={styles['BannerRightImage']}
        src={getStaticMediaUrl(bannerRight)}
        alt="banner-right-img"
      />
      <h3 className={`${styles['Title']} font-bold text-center text-white-o-80`}>{title}</h3>
      <div className={`flex justify-center`}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && child.type === OnboardingItem) {
            return React.cloneElement(child, {
              onClick: () => {
                onChange(child.props.value);
              },
              status: child.props.value === value ? 'active' : child.props.status,
            } as React.ComponentProps<typeof child.type>);
          } else {
            throw new Error('Only OnboardingItem can be passed');
          }
        })}
      </div>
    </div>
  );
};

type OnboardingItemProps = {
  title: string;
  value: number;
  onClick?: React.MouseEventHandler<HTMLElement>; // dont set this prop yourself, the parent will handle it
  disabled?: boolean;
  status?: 'completed' | 'active'; // there may be an error/warning state in the future
};

export const OnboardingItem = ({
  title,
  value, // dont remove this, its used by parent
  onClick,
  disabled,
  status,
}: OnboardingItemProps) => {
  return (
    <span
      className={cx(styles['NumberedItem'], {
        [styles['NumberedItem--disabled']]: disabled,
        [styles['NumberedItem--completed']]: status === 'completed',
        [styles['NumberedItem--active']]: status === 'active',
      })}
      onClick={disabled ? undefined : onClick}
    >
      <span
        className={styles['NumberIndicator']}
        style={{
          backgroundImage:
            status === 'completed' ? `url(${getStaticMediaUrl(successTick)})` : undefined,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></span>
      <span className={styles['ItemTitle']}>{title}</span>
      <span className={styles['ItemSeperator']}></span>
    </span>
  );
};

export default OnboardingStatus;
