import React, { ReactElement } from 'react';
import { Input, InputElement } from 'components/ui/Form/Input';
import { Card } from 'components/ui/Card';
import {RadioButton} from "../../ui/RadioButton";
import { RecoveryFormProps } from '../LoanRecovery';
import { LoanRecoveryOption } from '../constants';
import { NUMERIC_REGEX } from '../../../utils/regexes';
import styles from '../index.module.scss';
import { toIndianCurrencyString } from 'utils/Numbers';
import { FullRecoveryAmountSchemaContract } from '../../../schemas/FullRecoveryAmountSchema';

export default function LoanRecoveryFormBlock(
    {
        formProps,
        updateFormProps,
        fullRecoveryAmount
    }:
        {
            formProps: RecoveryFormProps;
            updateFormProps: (field: string, type: LoanRecoveryOption | string) => void;
            fullRecoveryAmount?: number | null;
        }
): ReactElement {

    const updateFormStateWithEvent = (event:React.ChangeEvent<HTMLInputElement | HTMLSelectElement> , regex?: string) => {
        let name = event.target.name;
        let value = event.target.value;
        if(regex){
            value = (new RegExp(regex)).test(event.target.value.replaceAll(',','')) ? event.target.value.replaceAll(',','') : '';
        }
        updateFormProps(name, value);
    }


    return (
        <>
            <InputElement label="Loan Recovery Type" className="my-auto">

                <Card className={styles['recovery-type']}>
                    <RadioButton
                        onSelect={() => { updateFormProps('recoveryType', LoanRecoveryOption.FULL_RECOVERY) }}
                        isSelected={formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY}
                    >
                        Full Recovery
                    </RadioButton>
                    <div className={styles['recovery-type-sub-text']}>Recover the entire outstanding loan amount.</div>
                </Card>
                <Card className={styles['recovery-type']}>
                    <RadioButton
                        onSelect={() => updateFormProps('recoveryType', LoanRecoveryOption.PARTIAL_RECOVERY)}
                        isSelected={formProps.recoveryType === LoanRecoveryOption.PARTIAL_RECOVERY}
                    >
                        Partial Recovery
                    </RadioButton>
                    <div className={styles['recovery-type-sub-text']}>Recover a partial amount from the outstanding loan amount.</div>

                </Card>
            </InputElement>

            <InputElement required label="Total Amount to be Recovered" className="gap-4">
                <Input
                    type="text"
                    maxLength={7}
                    value={
                        formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY ? (fullRecoveryAmount ? toIndianCurrencyString(fullRecoveryAmount) : '-/-' ) : 
                        formProps.recoveryAmount ? toIndianCurrencyString(parseInt(formProps.recoveryAmount.replaceAll(',','')), true) : formProps.recoveryAmount
                    }
                    required
                    disabled={formProps.recoveryType === LoanRecoveryOption.FULL_RECOVERY}
                    name="recoveryAmount"
                    onChange={(event) => updateFormStateWithEvent(event,NUMERIC_REGEX) }
                    placeholder="Enter recovery amount"
                    customErrorMessage="Invalid amount"
                    className={styles['recovery-input-field']}
                />
            </InputElement>
        </>
    )
}