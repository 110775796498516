import { AvailableField, SyncedField, TransformedAvailableField } from '../types';

export function getMissingFields(
  allFields: { field: string; name: string }[],
  availableFields: AvailableField[],
) {
  return allFields.filter((field) => {
    return !availableFields.find((availableField) => {
      return availableField.xpayrollFieldType === field.field;
    });
  });
}

export function getTransformedAvailableFields(
  syncedFields: SyncedField[],
  availableFields: AvailableField[],
): { [k: string]: TransformedAvailableField } {
  return availableFields.reduce(
    (accumulator: { [k: string]: TransformedAvailableField }, field) => {
      // employee-status is related to dismissal, and is not required to be shown on the UI
      if (field.xpayrollFieldType === 'employee-status') {
        return accumulator;
      }

      accumulator[field.xpayrollFieldType] = {
        type: field.xpayrollFieldType,
        name: field.xpayrollFieldName,
        isMandatory: field.isMandatoryXPayrollFieldToSync,
        isSelectedForSync: !!syncedFields.find(
          (syncedField) => syncedField.field === field.xpayrollFieldType,
        ),
      };

      return accumulator;
    },
    {},
  );
}
