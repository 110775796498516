import React, { ReactElement, ReactNode } from 'react';

import alertTriangle from '../../../../assets/icon--alert--triangle-blue.svg';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import { SecondaryButton } from 'components/ui/Button';
import { PrimaryButtonV2 } from '../../../ui/Button/index';

import styles from './index.module.scss';

export default function StickyFooterModal({
  warningText,
  showModal,
  onCancel,
  onConfirmClick,
  showAlertWithWarningText = true,
  confirmText,
  isConfirmPending = false,
}: {
  warningText: ReactElement;
  showModal: boolean;
  onCancel: () => void;
  onConfirmClick: React.MouseEventHandler<HTMLButtonElement>;
  showAlertWithWarningText?: boolean;
  confirmText?: ReactNode;
  isConfirmPending?: boolean;
}): ReactElement {
  return (
    <div className={`${styles['modal-background']} ${showModal ? 'block' : 'hidden'}`}>
      <div className={styles['modal-content']}>
        <div className={`${styles['modal-body-left']}`}>
          {showAlertWithWarningText && <img src={getStaticMediaUrl(alertTriangle)}></img>}
          {warningText}
        </div>
        <div className={styles['modal-body-right']}>
          <SecondaryButton size="sm" disabled={isConfirmPending} onClick={() => onCancel()}>
            Back
          </SecondaryButton>
          <PrimaryButtonV2
            isPending={isConfirmPending}
            disabled={isConfirmPending}
            onClick={(e) => onConfirmClick(e)}>
            {confirmText ? confirmText : 'Confirm →'}
          </PrimaryButtonV2>
        </div>
      </div>
    </div>
  );
}
