import RestrictedRoute from 'components/Routes/RestrictedRoute';
import { routePaths } from 'components/Routes/data';
import permissions from 'constants/permissions';
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { FieldsSync } from './FieldsSync';
import { EmployeeContractorMapping } from './EmployeeContractorMapping';
import { SyncConfirmation } from './SyncConfirmation';
import { SyncInProgress } from './SyncInProgress';
import { DisableIntegration } from './DisableIntegration';
import { EntitySelection } from './EntitySelection';
import { KnitHrmsAppsFeatureFlags } from 'components/Knit/constants';

const KnitHrmsSettings = () => {
  const knitHrmsAppsFeatureFlags = useMemo(() => Object.values(KnitHrmsAppsFeatureFlags), []);

  return (
    <Switch>
      <RestrictedRoute
        path={[
          routePaths.settings.knitHrms.settings.root,
          routePaths.settings.knitHrms.settings.entitySelection,
        ]}
        exact
        component={EntitySelection}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.fields}
        exact
        component={FieldsSync}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.employeeContractorMapping}
        exact
        component={EmployeeContractorMapping}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.syncConfirmation}
        exact
        component={SyncConfirmation}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.syncInProgress}
        exact
        component={SyncInProgress}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
      <RestrictedRoute
        path={routePaths.settings.knitHrms.settings.disableIntegration}
        exact
        component={DisableIntegration}
        allowedPermissions={[permissions.MANAGE_INTEGRATIONS]}
        allowedFeatures={knitHrmsAppsFeatureFlags}
      />
    </Switch>
  );
};

export { KnitHrmsSettings };
