import React, { useState, MouseEventHandler } from 'react';
import { IInsuranceEstimate } from '../../../reducers/insuranceEstimate';
import { IInsurance, purchaseInsurance } from '../../../reducers/insurance';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../ui/Button';
import { Modal } from '../../ui/Modal';
import { TableLayout } from '../../ui/Table';
import { toIndianCurrencyString } from '../../../utils/Numbers';

export function InsurancePurchaseTermsAndCondition({ className }: { className?: string }) {
  return (
    <div className={`${className ? className : ''} pb-10 pb-5`}>
      <div className="text-3xl text-center font-heading pb-5">Terms and conditions</div>
      <ol className="list-decimal ml-10">
        <li>
          {' '}
          The Master Policy is held in the name of Razorpay Software Private Limited and health id
          cards and Certificate of Insurances will be provided to all the employees and their
          dependents for your organization.
        </li>
        <li>
          {' '}
          You are confirming that all your employees and their family members (if applicable) are
          getting covered in this Insurance program{' '}
        </li>
        <li>
          {' '}
          Your organization needs to continue the usage of RazorpayX Payroll for Employee Salary
          Transfers and applicable Compliance Payments like (TDS, PF, PT, ESI) in order to be
          eligible for the Insurance program.
        </li>
        <li>
          {' '}
          In case you stop using RazorpayX Payroll, then Razorpay reserves the right to terminate
          your Insurance program.{' '}
        </li>
        <li>
          {' '}
          Employee Additions - All new employees who join your organization need to be a part of the
          Insurance program. Each member shall get enrolled from his/her Date of Joining /
          onboarding on RazorpayX Payroll for a period of 12 months. The premium paid in these cases
          will also be for a period of 12 months.{' '}
        </li>
        <li>
          {' '}
          Employee Exits - In cases when an employee leaves the organization, you will need to
          Dismiss that employee on RazorpayX Payroll. After doing this, we notify our Insurance
          partners about this employee exit. Within 15 working days, you will get a refund in your
          account. This is a pro-rated refund that is calculated basis the employee’s enrolment date
          and exit date.{' '}
        </li>
        <li>
          {' '}
          If the employee or their dependents have made a claim then there will be no refund that is
          issued.{' '}
        </li>
        <li>
          {' '}
          For example. if the annual premium for a 30-yr old member is Rs. 2000, and if they leave
          after 6 months, then you get refunded Rs. 1000, provided there are no claims
        </li>
        <li>
          {' '}
          Your organization will not be allowed to change the selected plan in the middle of the
          year after purchasing; this option to change the plan may be available at the time of
          renewal
        </li>
        <li> Your organizations cannot opt-out of the policy mid-term.</li>
        <li>
          {' '}
          You confirm that your organization is not a part of this Caution Group{' '}
          <ul className="ml-10 list-disc">
            <li> Diagnostic centers </li>
            <li> Hospitals </li>
            <li> Nursing homes </li>
            <li> Medical colleges </li>
            <li> Doctor associations </li>
            <li> Politicians </li>
            <li> Lawyers / associations of lawyers </li>
            <li> Groups selling OPD packages / schemes </li>
            <li> Trusts involved in charity </li>
            <li>
              {' '}
              Any associations of patients of same diseases – Eg – Cancer association, Thalassemia
              etc{' '}
            </li>
            <li> Media and entertainment </li>
            <li>
              {' '}
              Groups in the business of health, which offer free consultation / tie ups with
              hospitals / offering discounted treatment / free camps{' '}
            </li>
          </ul>
        </li>
        <li>
          {' '}
          If you are a part of this Caution Group, then the Insurance company evaluates your
          organization and takes the final decision on providing Insurance. In such cases, if the
          coverage doesn’t go through, then a full-refund is provided.{' '}
        </li>
      </ol>
    </div>
  );
}

export function ShowBalanceConfirmationModal({
  orgBalance,
  totalAmount,
  disabled,
  purchaseCallback,
}: {
  orgBalance?: number;
  totalAmount: number;
  disabled: boolean;
  purchaseCallback: MouseEventHandler<HTMLButtonElement>;
}) {
  const [readTermsAndCondition, markTermsAndConditionStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [insufficientBalanceShowModal, insufficientBalanceSetShowModal] = useState(false);

  return (
    <div className="">
      <PrimaryButton
        className="w-full"
        onClick={() => {
          console.log('clicked on purchase', orgBalance, totalAmount);
          if (!(orgBalance !== undefined)) {
            return;
          }
          if (totalAmount <= orgBalance) {
            setShowModal(true);
          } else {
            insufficientBalanceSetShowModal(true);
          }
        }}
        disabled={disabled}
      >
        PURCHASE
      </PrimaryButton>
      <Modal
        showModal={showModal}
        heading="Confirm payment"
        className=""
        contentClass="flex flex-col h-full"
        closeModal={() => setShowModal(false)}
      >
        <>
          <InsurancePurchaseTermsAndCondition className="overflow-y-auto flex-grow-1" />
          <hr className="my-6" />
          <TableLayout className="-mx-4 w-full">
            <tr>
              <td>Insurance premium total</td>
              <td className="font-semibold text-right text-primary text-3xl">
                {toIndianCurrencyString(totalAmount)}
              </td>
            </tr>
            <tr>
              <td>XPayroll wallet balance</td>
              <td className="font-semibold text-right text-primary text-3xl">
                {orgBalance !== undefined ? toIndianCurrencyString(orgBalance) : 'NA'}
              </td>
            </tr>
          </TableLayout>
          <div className="w-full justify-center flex pb-10">
            <div className="space-x-2 mt-8">
              <input
                type="checkbox"
                id="agreeTermsAndCondition"
                name="confirm"
                value={readTermsAndCondition ? 'checked' : 'unchecked'}
                checked={readTermsAndCondition ? true : false}
                onChange={(event) => {
                  console.log('checked', readTermsAndCondition, event.currentTarget.value);
                  markTermsAndConditionStatus(event.currentTarget.value === 'unchecked');
                }}
              />
              <label htmlFor="agreeTermsAndCondition">
                I agree with above Terms and Conditions.
              </label>
            </div>
          </div>

          <PrimaryButton
            className="w-full"
            onClick={(event) => {
              purchaseCallback(event);
              setShowModal(false);
            }}
            disabled={disabled || !readTermsAndCondition}
          >
            PAY {toIndianCurrencyString(totalAmount)}
          </PrimaryButton>
        </>
      </Modal>
      <Modal
        showModal={insufficientBalanceShowModal}
        heading="Add Balance"
        closeModal={() => insufficientBalanceSetShowModal(false)}
      >
        <TableLayout className="-mx-4 w-full">
          <tr>
            <td className="font-bold">Insurance premium total</td>
            <td className="font-semibold text-right text-bold">
              {toIndianCurrencyString(totalAmount)}
            </td>
          </tr>
          <tr>
            <td className="font-bold">XPayroll wallet balance</td>
            <td className="font-semibold text-right text-bold">
              {orgBalance !== undefined ? toIndianCurrencyString(orgBalance) : 'NA'}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Deficit Balance</td>
            <td className="font-semibold text-right text-primary text-4xl">
              {orgBalance !== undefined ? toIndianCurrencyString(totalAmount - orgBalance) : 0}
            </td>
          </tr>
        </TableLayout>
        <hr className="my-6" />
        <p>
          {' '}
          Please wire the deficit amount to XPayroll account <a href="/moneyTransfer">here</a> and
          re-try purchase{' '}
        </p>
      </Modal>
    </div>
  );
}

