import { routePaths } from 'components/Routes/data';
import { BulkUploadTypes } from 'constants/bulkUpload';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../index.module.scss';
import FileUploadStep, { FileUploadStepProps } from './FileUploadStep';
import InstructionsView, { InstructionsViewProps } from './InstructionsView';
import UploadTemplateView from './UploadTemplateView';

type Props = {
  bulkUploadType: BulkUploadTypes;
  organizationId: number;
  viewTitle: string;
} & InstructionsViewProps &
  Pick<FileUploadStepProps, 'pollingRequestId'>;

const BulkUploadView = ({
  bulkUploadType,
  instructions,
  organizationId,
  viewTitle,
  pollingRequestId,
}: Props) => {
  const history = useHistory();

  const nextStep = () => {
    history.push({
      pathname: routePaths.bulkUploads.preview,
      search: `?type=${bulkUploadType}`,
    });
  };

  return (
    <div className={styles['centered-body-50'] + ' pr-[12.5%]'}>
      <div className={styles['section'] + ' flex flex-col w-auto gap-[28px]'}>
        <span className={styles['section-main-text']}>{viewTitle}</span>

        <UploadTemplateView reportType={bulkUploadType} organizationId={organizationId} />
        <InstructionsView instructions={instructions} />
      </div>

      <FileUploadStep
        bulkUploadType={bulkUploadType}
        onNext={nextStep}
        pollingRequestId={pollingRequestId}
      />
    </div>
  );
};

export default BulkUploadView;
