import React from 'react';
import {
  Box,
  Text,
  Switch,
  Tabs,
  TabList,
  TabItem,
  Dropdown,
  SelectInput,
  DropdownOverlay,
  ActionList,
  ActionListItem,
  Card,
  CardHeader,
  CardHeaderLeading,
  CardBody,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Link,
  Divider,
} from '@razorpay/blade/components';
import leftImg from 'assets/layouts/circle_left.svg';
import rightImg from 'assets/layouts/right_circle.svg';
import { LeftImg, RightImg } from 'components/ui/Layout/styles';
import Breadcrumb from 'components/ui/Breadcrumb';
import SummaryCard from './components/SummaryCard';
import DescriptionAndComponentCard from './components/DescriptionAndComponentCard';
import { CustomRangeSelectorCard } from '../../ui/CustomRangeSelectorCard/CustomRangeSelectorCard';
import {
  getCustomCycleDaysKeyValueList,
  customCyclePayrollDateKeyValueList,
} from 'components/ui/CustomRangeSelectorCard/constants';
import { Reveal } from 'components/ui/Reveal/Reveal';
import { useQuery, useMutation } from 'react-query';
import {
  GET_CUSTOM_PAYROLL_SETTINGS,
  UPDATE_CUSTOM_PAYROLL_SETTINGS,
  DELETE_FUTURE_CYCLE,
} from './queries';
import api from 'api';
import { AppError } from 'utils/AppError';
import { useState, useEffect, useRef } from 'react';
import {
  checkIsCycleChanged,
  getEffectiveFromMonthList,
  getInitialData,
  getHelpText,
  checkIsSettingsChanged,
  getCycleDescriptionText,
  parseDropdownDate,
  getFutureCyclesText,
} from './utils';
import { GetPayrollSetupSchemaContract } from 'schemas/GetPayrollSetupSchema';
import { CYCLE_TYPE, PayrollCycleTypeMap, PAYROLL_CYCLE } from './types';
import {
  showErrorToastNotification,
  showSuccessToastNotification,
} from '../../../utils/ToastEvents';
import Errors from 'components/ui/Form/Errors';
import { convertToOrdinal, dateFormats } from 'utils/Dates';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import { BladeProPill } from '../../ui/BladeProPill/index';
import { deepEqual } from '../../../utils/Objects';
import { format } from 'date-fns';
import { NewJoinerCutoffDropdown } from './components/NewJoinerCutoffDropdown';
import useNewJoinerArrearEnabled from 'components/RunPayroll/utils/useNewJoinerArrearEnabled';
import IsFeatureDisabledForRearch from 'access/IsFeatureDisabledForRearch';

const CustomPayrollAndAttendanceCycle = () => {
  const [settings, setSettings] = useState(getInitialData);
  const [oldSettings, setOldSettings] = useState(getInitialData);
  const [payrollCycleSelector, setPayrollCycleSelector] = useState(PAYROLL_CYCLE.CALENDAR_CYCLE);
  const [sameAsPayroll, setSameAsPayroll] = useState(true);
  const [isCycleChanged, setIsCycleChanged] = useState(false);
  const [isSettingChanged, setIsSettingChanged] = useState(false);
  const [deleteCycleModal, setDeleteCycleModal] = useState(false);
  const hasProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
  const { isNewJoinerArrearFeatureEnabled } = useNewJoinerArrearEnabled();
  const containerRef = useRef<HTMLDivElement>(null);
  const futureCycleExists = !!(
    settings.upcomingCycles && settings.upcomingCycles.effectiveFromMonth
  );

  const fetchCustomCycleSettings = useQuery({
    queryKey: GET_CUSTOM_PAYROLL_SETTINGS,
    queryFn: api.customPayrollCycle.getSettings,
    onSuccess: (response) => {
      if (response.payoutDate === null) {
        response.payoutDate = 1;
      }

      if (!response.hasFinalizedAPayroll) {
        settings.effectiveFromMonth = null;
      }

      if (!deepEqual(response.payrollCycle, response.payrollInputCycle)) {
        setSameAsPayroll(false);
      }

      let parsedResponse = {
        ...response,
        newJoinerArrearEnabled: hasProPlan ? response.newJoinerArrearEnabled : false,
      };

      setSettings(parsedResponse);
      setOldSettings(parsedResponse);
      setPayrollCycleSelector(
        hasProPlan
          ? response.payrollCycle.startDate === 1
            ? PAYROLL_CYCLE.CALENDAR_CYCLE
            : PAYROLL_CYCLE.CUSTOM_CYCLE
          : PAYROLL_CYCLE.CALENDAR_CYCLE,
      );
    },
    onError: (error: typeof AppError) => {},
  });

  const updateCustomCycleSettings = useMutation({
    mutationKey: UPDATE_CUSTOM_PAYROLL_SETTINGS,
    mutationFn: api.customPayrollCycle.updateSettings,
    onSuccess: (data) => {
      fetchCustomCycleSettings.refetch();
      showSuccessToastNotification({
        text: 'Configuration saved successfully!',
        timeout: 3000,
      });
      window.location.href = '/settings';
    },
    onError: (e: typeof AppError) => {
      showErrorToastNotification({
        text: 'Unable to update configuration',
        timeout: 3000,
      });
      window.requestAnimationFrame(() => {
        containerRef.current?.scrollIntoView();
      });
    },
  });

  const deleteFutureCycles = useMutation({
    mutationKey: DELETE_FUTURE_CYCLE,
    mutationFn: api.customPayrollCycle.deleteFutureCycle,
    onSuccess: () => {
      fetchCustomCycleSettings.refetch();
      setDeleteCycleModal(false);
      showSuccessToastNotification({
        text: 'Configuration saved successfully!',
        timeout: 3000,
      });
    },
    onError: (e: typeof AppError) => {
      setDeleteCycleModal(false);
      showErrorToastNotification({
        text: 'Unable to update configuration',
        timeout: 3000,
      });
      window.requestAnimationFrame(() => {
        containerRef.current?.scrollIntoView();
      });
    },
  });

  const handleSettingsChange = (
    key: keyof GetPayrollSetupSchemaContract,
    value: string | boolean | number,
  ) => {
    setSettings((p) => ({ ...p, [key]: value }));
  };

  const handleSwitchChange = ({
    isChecked,
    value,
  }: {
    isChecked: boolean;
    event?: React.ChangeEvent;
    value?: string;
  }) => {
    handleSettingsChange(value as keyof GetPayrollSetupSchemaContract, isChecked);
  };

  const handleDateChange = (
    key: keyof GetPayrollSetupSchemaContract,
    value: {
      startDate?: number | null;
      endDate?: number | null;
    },
  ) => {
    if (value.startDate && value.startDate > 0) {
      value.endDate = (value.startDate - 1) % 31; // we are restricting the date between 1st - 31st
      setSettings((p) => ({ ...p, [key]: value }));

      if (key === 'payrollCycle') {
        if (sameAsPayroll) {
          setSettings((p) => ({
            ...p,
            ['payrollInputCycle' as keyof GetPayrollSetupSchemaContract]: value,
          }));
        }

        if (value !== settings.payrollInputCycle) {
          setSameAsPayroll(false);
        }
      }

      if (key === 'payrollInputCycle') {
        if (value !== settings.payrollCycle) {
          setSameAsPayroll(false);
        }
      }
    }
  };

  const handlePayrollCycleChange = (value: string) => {
    if (!hasProPlan && PayrollCycleTypeMap[value] === PAYROLL_CYCLE.CUSTOM_CYCLE) {
      return;
    }
    setPayrollCycleSelector(PayrollCycleTypeMap[value]);

    if (PayrollCycleTypeMap[value] === PAYROLL_CYCLE.CALENDAR_CYCLE) {
      handleDateChange('payrollCycle', {
        startDate: 1,
        endDate: 31,
      });
    }

    if (PayrollCycleTypeMap[value] === PAYROLL_CYCLE.CUSTOM_CYCLE) {
      handleDateChange('payrollCycle', {
        startDate: 2,
        endDate: 1,
      });

      handleDateChange('payrollInputCycle', {
        startDate: 2,
        endDate: 1,
      });
    }
  };

  const handleSave = () => {
    if (futureCycleExists) {
      updateCustomCycleSettings.mutate({
        payoutDate: settings.payoutDate,
        payrollEnabled: settings.payrollEnabled,
        autoExecutePayroll: settings.autoExecutePayroll,
      });
    } else {
      updateCustomCycleSettings.mutate({
        ...settings,
        effectiveFromMonth: settings.hasFinalizedAPayroll
          ? settings.effectiveFromMonth ?? settings.nextPayrollMonthToFinalize
          : null,
        newJoinerCutoffDate:
          isNewJoinerArrearFeatureEnabled && settings.newJoinerArrearEnabled
            ? settings.newJoinerCutoffDate
            : null,
      });
    }
  };

  const handleSameAsPayrollChange = (isChecked: boolean) => {
    if (isChecked) {
      handleDateChange('payrollInputCycle', {
        startDate: settings.payrollCycle.startDate,
        endDate: settings.payrollCycle.endDate,
      });
    }

    setSameAsPayroll(isChecked);
  };

  const handleNewJoinerCutoffToggle = (isChecked: boolean) => {
    handleSwitchChange({ isChecked: isChecked, value: 'newJoinerArrearEnabled' });
    if (isChecked) {
      setSettings((p) => ({
        ...p,
        ['newJoinerCutoffDate' as keyof GetPayrollSetupSchemaContract]: parseDropdownDate(
          settings.payrollInputCycle.endDate,
        ),
      }));
    } else {
      setSettings((p) => ({
        ...p,
        ['newJoinerCutoffDate' as keyof GetPayrollSetupSchemaContract]: null,
      }));
    }
  };

  useEffect(() => {
    checkIsCycleChanged(oldSettings, settings, setIsCycleChanged);
    checkIsSettingsChanged(oldSettings, settings, setIsSettingChanged);

    if (payrollCycleSelector === PAYROLL_CYCLE.CUSTOM_CYCLE || !hasProPlan) {
      setSameAsPayroll(true);
    }
  }, [settings, payrollCycleSelector]);

  const errorDescription =
    fetchCustomCycleSettings.error || updateCustomCycleSettings.error || deleteFutureCycles.error;

  return (
    <>
      <Modal
        isOpen={
          deleteCycleModal &&
          settings.upcomingCycles !== null &&
          settings.upcomingCycles.effectiveFromMonth !== null
        }
        onDismiss={() => setDeleteCycleModal(false)}>
        <ModalHeader title="Cancel Upcoming Cycle" />
        <ModalBody>
          <Box display="flex" flexDirection="column" gap="spacing.7">
            <Text>
              The upcoming cycle will be not be applicable from{' '}
              {settings.upcomingCycles && settings.upcomingCycles.effectiveFromMonth
                ? format(
                    new Date(settings.upcomingCycles.effectiveFromMonth),
                    dateFormats.monthCommaYear,
                  )
                : 'N/A'}{' '}
              payroll
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Box display={'flex'} flexDirection={'row'} gap="spacing.5">
            <Button variant="secondary" onClick={() => setDeleteCycleModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteFutureCycles.mutate()}
              isLoading={deleteFutureCycles.isLoading}>
              Cancel
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
      <Box
        display="flex"
        backgroundColor={'surface.background.gray.intense'}
        flexDirection="column"
        height="100%"
        position="relative"
        marginLeft="-24px"
        paddingX={'spacing.7'}>
        <LeftImg height="300px" src={leftImg} />
        <RightImg src={rightImg} />
        <Box display="flex">
          <Box marginRight={'auto'}>
            <Breadcrumb
              name="Payroll Setup"
              urlMaps={{
                Settings: '/settings',
              }}
            />
          </Box>
        </Box>
        <Box
          height="100%"
          gap="spacing.11"
          width="884px"
          marginX={'auto'}
          marginTop={'80px'}
          display="flex"
          flexDirection="column">
          {settings.upcomingCycles && settings.upcomingCycles.effectiveFromMonth && (
            <Box borderRadius={'medium'}>
              <Alert
                color="information"
                isDismissible={false}
                title={`New cycle effective from ${format(
                  new Date(settings.upcomingCycles.effectiveFromMonth),
                  dateFormats.monthYear,
                )}`}
                description={getFutureCyclesText(
                  settings.upcomingCycles,
                  settings.payoutDate,
                  isNewJoinerArrearFeatureEnabled,
                )}
                actions={{
                  primary: {
                    text: 'Cancel Upcoming Cycle',
                    onClick: () => {
                      setDeleteCycleModal(true);
                    },
                  },
                }}
                isFullWidth
              />
            </Box>
          )}

          {errorDescription ? (
            <Box
              ref={containerRef}
              marginX={{ base: 'spacing.8', l: 'auto' }}
              borderRadius={'large'}
              width="max-content"
              backgroundColor="surface.background.gray.moderate">
              <Alert
                isFullWidth
                description={<Errors errorResponse={errorDescription} />}
                color="negative"
              />
            </Box>
          ) : null}

          <DescriptionAndComponentCard
            descriptionComponent={
              <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                Payroll enabled
              </Text>
            }
            rightComponent={
              <Box display="flex" flexDirection="row">
                <Switch
                  isChecked={settings.payrollEnabled}
                  size="small"
                  name="payrollEnabled"
                  value="payrollEnabled"
                  accessibilityLabel="Let RazorpayX Payroll do the payments"
                  onChange={handleSwitchChange}
                  marginRight={'spacing.3'}
                />
                <Text color="surface.text.gray.subtle">
                  Let RazorpayX Payroll do the salary payments
                </Text>
              </Box>
            }
          />
          {/* {is loading state to be added} */}
          <SummaryCard
            payrollDate={settings.payoutDate ?? 1}
            attendanceCycle={{
              from: settings.payrollInputCycle.startDate,
              to: settings.payrollInputCycle.endDate,
            }}
            payrollCycle={{
              from: settings.payrollCycle.startDate,
              to: settings.payrollCycle.endDate,
            }}
            isNewJoinerArrearEnabled={isNewJoinerArrearFeatureEnabled}
            newJoinerCutOff={settings.newJoinerCutoffDate}
          />

          <DescriptionAndComponentCard
            descriptionComponent={
              <Box display="flex" flexDirection="column" gap="spacing.2">
                <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                  Payroll Cycle
                </Text>
                <Text size="small" color="surface.text.gray.subtle">
                  Set your Payroll cycle
                </Text>
              </Box>
            }
            rightComponent={
              <Box display="flex" flexDirection="column" gap="spacing.4">
                <Tabs
                  isFullWidthTabItem
                  variant="filled"
                  size="medium"
                  orientation={'horizontal'}
                  onChange={(value) => handlePayrollCycleChange(value)}
                  defaultValue={futureCycleExists ? undefined : PAYROLL_CYCLE.CALENDAR_CYCLE}
                  value={futureCycleExists ? undefined : payrollCycleSelector}>
                  <TabList>
                    <TabItem value={PAYROLL_CYCLE.CALENDAR_CYCLE} isDisabled={futureCycleExists}>
                      Calendar Cycle
                    </TabItem>
                    <TabItem
                      value={PAYROLL_CYCLE.CUSTOM_CYCLE}
                      isDisabled={!hasProPlan || futureCycleExists}>
                      + Create Custom Cycle {!hasProPlan && BladeProPill}
                    </TabItem>
                  </TabList>
                </Tabs>
                <CustomRangeSelectorCard
                  fieldName={'payrollCycle'}
                  titleText="Payroll Cycle Dates"
                  startText="Start Date"
                  endText="End Date"
                  startDateList={getCustomCycleDaysKeyValueList(
                    payrollCycleSelector === PAYROLL_CYCLE.CUSTOM_CYCLE ? 2 : 1,
                    'start',
                  )}
                  endDateList={getCustomCycleDaysKeyValueList(1, 'end')}
                  isLoading={false}
                  endDate={settings.payrollCycle.endDate === 0 ? 31 : settings.payrollCycle.endDate}
                  startDate={settings.payrollCycle.startDate}
                  onChange={(value, name) => handleDateChange(name, value)}
                  disableStartDate={
                    payrollCycleSelector === PAYROLL_CYCLE.CALENDAR_CYCLE || futureCycleExists
                  }
                  disableEndDate={true}
                  showTooltip={futureCycleExists}
                  toolTipText="The payroll cycle cannot be changed as a future cycle is already in place. Please delete the future cycle to update."
                  descriptionText={getCycleDescriptionText({
                    cycleType: CYCLE_TYPE.PAYROLL,
                    startDate: settings.payrollCycle.startDate,
                    endDate:
                      settings.payrollCycle.endDate === 0 ? 31 : settings.payrollCycle.endDate,
                    payrollCycleType: payrollCycleSelector,
                  })}
                />
              </Box>
            }
          />

          <DescriptionAndComponentCard
            descriptionComponent={
              <Box display="flex" flexDirection="column">
                <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                  Attendance Cycle
                </Text>
                <Text size="small" color="surface.text.gray.subtle">
                  Set period for tracking employee work days to calculate wages.
                </Text>
                <Link
                  href="https://razorpay.com/docs/x/xpayroll/employers/setup/account/custom-cycle/"
                  target="_blank">
                  Learn more
                </Link>
              </Box>
            }
            rightComponent={
              <Box display="flex" flexDirection="column" gap={'spacing.4'}>
                <Box display="flex" flexDirection="row" gap={'spacing.4'}>
                  <Switch
                    isChecked={sameAsPayroll}
                    size="small"
                    accessibilityLabel="Same as payroll cycle"
                    onChange={({ isChecked }) => handleSameAsPayrollChange(isChecked)}
                    isDisabled={
                      payrollCycleSelector === PAYROLL_CYCLE.CUSTOM_CYCLE ||
                      !hasProPlan ||
                      futureCycleExists
                    }
                  />
                  <Text color="surface.text.gray.subtle">Same as payroll cycle</Text>
                  {!hasProPlan && BladeProPill}
                </Box>
                <CustomRangeSelectorCard
                  fieldName={'payrollInputCycle'}
                  titleText="Attendance Cycle Dates"
                  startText="Start Date"
                  endText="End Date"
                  startDateList={getCustomCycleDaysKeyValueList(
                    payrollCycleSelector === PAYROLL_CYCLE.CUSTOM_CYCLE ? 2 : 1,
                    'start',
                  )}
                  endDateList={getCustomCycleDaysKeyValueList(1, 'end')}
                  isLoading={false}
                  endDate={
                    settings.payrollInputCycle.endDate === 0
                      ? 31
                      : settings.payrollInputCycle.endDate
                  }
                  startDate={settings.payrollInputCycle.startDate}
                  onChange={(value, name) => handleDateChange(name, value)}
                  disableStartDate={
                    payrollCycleSelector === PAYROLL_CYCLE.CUSTOM_CYCLE ||
                    sameAsPayroll ||
                    !hasProPlan ||
                    futureCycleExists
                  }
                  disableEndDate={true}
                  showTooltip={futureCycleExists}
                  toolTipText="The attendance cycle cannot be changed as a future cycle is already in place. Please delete the future cycle to update."
                  descriptionText={getCycleDescriptionText({
                    cycleType: CYCLE_TYPE.ATTENDANCE,
                    startDate: settings.payrollInputCycle.startDate,
                    endDate:
                      settings.payrollInputCycle.endDate === 0
                        ? 31
                        : settings.payrollInputCycle.endDate,
                    payrollCycleType: payrollCycleSelector,
                  })}
                />
              </Box>
            }
          />

          {isNewJoinerArrearFeatureEnabled && (
            <DescriptionAndComponentCard
              descriptionComponent={
                <Box display="flex" flexDirection="column" gap="spacing.2">
                  <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                    New Joiner Cut-Off
                  </Text>
                  <Text size="small" color="surface.text.gray.subtle">
                    Deadline by which an employee must join to be included in a current payroll.
                  </Text>
                  <Link
                    href="https://razorpay.com/docs/payroll/account/custom-cycle/new-joiners-arrears/"
                    target="_blank">
                    Learn more
                  </Link>
                </Box>
              }
              rightComponent={
                <NewJoinerCutoffDropdown
                  settings={settings}
                  isDisabled={futureCycleExists}
                  items={customCyclePayrollDateKeyValueList}
                  showTooltip={futureCycleExists}
                  tooltipText="The payroll cycle cannot be changed as a future cycle is already in place. Please delete the future cycle to update."
                  onChangeDropdown={({ name, values }) =>
                    handleSettingsChange(
                      name as keyof GetPayrollSetupSchemaContract,
                      parseInt(values[0]),
                    )
                  }
                  onChangeSwitch={({ isChecked }) => handleNewJoinerCutoffToggle(isChecked)}
                  hasProPlan={hasProPlan}
                />
              }
            />
          )}

          <Reveal isOpen={settings.hasFinalizedAPayroll && isCycleChanged}>
            <DescriptionAndComponentCard
              descriptionComponent={
                <Box display="flex" flexDirection="column" gap="spacing.2">
                  <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                    New Cycle Effective Month
                  </Text>
                  <Text size="small" color="surface.text.gray.subtle">
                    Month from which you would like the above cycle to begin
                  </Text>
                </Box>
              }
              rightComponent={
                <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
                  <Dropdown selectionType="single">
                    <SelectInput
                      name={'effectiveFromMonth'}
                      labelPosition="top"
                      isRequired
                      defaultValue={settings.nextPayrollMonthToFinalize}
                      value={settings.effectiveFromMonth ?? settings.nextPayrollMonthToFinalize}
                      label={'Select Month'}
                      placeholder="Select Month"
                      isDisabled={futureCycleExists}
                      onChange={({ name, values }) =>
                        handleSettingsChange(name as keyof GetPayrollSetupSchemaContract, values[0])
                      }
                    />
                    <DropdownOverlay>
                      <ActionList>
                        {getEffectiveFromMonthList(settings.nextPayrollMonthToFinalize).map(
                          ({ key, title, value }) => (
                            <ActionListItem key={key} title={title} value={value} />
                          ),
                        )}
                      </ActionList>
                    </DropdownOverlay>
                  </Dropdown>
                  <Text variant={'caption'} color={'interactive.text.positive.subtle'}>
                    {`The new cycle will be effective from ${
                      getEffectiveFromMonthList(settings.nextPayrollMonthToFinalize).find(
                        (month) =>
                          month.value ===
                          (settings.effectiveFromMonth ?? settings.nextPayrollMonthToFinalize),
                      )?.title
                    } payroll`}
                  </Text>
                </Box>
              }
            />
            <Divider marginTop={'spacing.11'} />
          </Reveal>

          <Reveal isOpen={settings.payrollEnabled}>
            <DescriptionAndComponentCard
              descriptionComponent={
                <Box display="flex" flexDirection="column" gap="spacing.2">
                  <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                    Payroll Date
                  </Text>
                  <Text size="small" color="surface.text.gray.subtle">
                    Choose when you would like to pay employees
                  </Text>
                </Box>
              }
              rightComponent={
                <Dropdown selectionType="single">
                  <SelectInput
                    name={'payoutDate'}
                    labelPosition="top"
                    isRequired
                    value={settings.payoutDate?.toString().padStart(2, '0')}
                    label={'Select Date'}
                    placeholder="Select date"
                    onChange={({ name, values }) =>
                      handleSettingsChange(
                        name as keyof GetPayrollSetupSchemaContract,
                        parseInt(values[0]),
                      )
                    }
                  />
                  <DropdownOverlay>
                    <ActionList>
                      {customCyclePayrollDateKeyValueList.map(({ key, title, value }) => (
                        <ActionListItem key={key} title={title} value={value} />
                      ))}
                    </ActionList>
                  </DropdownOverlay>
                </Dropdown>
              }
            />
          </Reveal>

          <IsFeatureDisabledForRearch feature={['isPayrollAutoPilotDisabled']}>
            <Reveal isOpen={settings.payrollEnabled}>
              <DescriptionAndComponentCard
                descriptionComponent={
                  <Box display="flex" flexDirection="column" gap="spacing.2">
                    <Text size="large" weight="semibold" color="surface.text.gray.subtle">
                      Payroll Autopilot
                    </Text>
                    <Text size="small" color="surface.text.gray.subtle">
                      XPayroll will automatically execute your payroll on your chosen date.
                    </Text>
                  </Box>
                }
                rightComponent={
                  <Box display="flex" flexDirection="row">
                    <Switch
                      name={'autoExecutePayroll'}
                      value={'autoExecutePayroll'}
                      isChecked={settings.autoExecutePayroll}
                      size="small"
                      accessibilityLabel="Let RazorpayX Payroll do the payments"
                      onChange={handleSwitchChange}
                      marginRight={'spacing.3'}
                    />
                    <Box display="flex" flexDirection="row" gap={'spacing.1'}>
                      <Text color="surface.text.gray.subtle">Automatically run payroll on </Text>
                      <Text weight="semibold" color="surface.text.gray.subtle">
                        {' '}
                        {settings.payoutDate ? convertToOrdinal(settings.payoutDate) : 'N/A'} of
                        every month
                      </Text>
                    </Box>
                  </Box>
                }
              />
            </Reveal>
          </IsFeatureDisabledForRearch>

          <Box
            borderWidth={'thick'}
            borderRadius={'medium'}
            borderColor={'surface.border.gray.normal'}>
            <Card padding="spacing.7">
              <CardHeader>
                <CardHeaderLeading title="✅ What does it mean?" />
              </CardHeader>
              <CardBody>
                <Text>{getHelpText(settings)}</Text>
              </CardBody>
            </Card>
          </Box>

          <Box width={'128px'} marginBottom={'spacing.11'}>
            <Button
              onClick={handleSave}
              isDisabled={!isSettingChanged}
              isLoading={updateCustomCycleSettings.isLoading}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomPayrollAndAttendanceCycle;
