import { PayrollPeopleOrganization } from 'api/gql-utils/user/types';
import React, { createContext, useContext } from 'react';
import { FetchCompensationDetailsResponse } from '../hooks/useFetchCompensationDetails';

interface PayDetailsContextType {
  compensationData: FetchCompensationDetailsResponse;
  userData: PayrollPeopleOrganization;
}

const PayDetailsContext = createContext<PayDetailsContextType | undefined>(undefined);

export const PayDetailsProvider: React.FC<
  PayDetailsContextType & { children: React.ReactNode }
> = ({ children, ...value }) => {
  return <PayDetailsContext.Provider value={value}>{children}</PayDetailsContext.Provider>;
};

export const usePayDetails = () => {
  const context = useContext(PayDetailsContext);
  if (context === undefined) {
    throw new Error('usePayDetails must be used within a PayDetailsProvider');
  }
  return context;
};
