import React, { ReactElement } from 'react';
import api from 'api';
import { useQuery } from 'react-query';
import { SLACK_OAUTH_MAPPING_QUERY } from 'components/Settings/SlackSettings/queries';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import slackInstallImage from 'assets/slack/slack_install.png';
import { getStaticMediaUrl } from 'utils/Urls';
import Spinner from 'components/ui/Spinner';
import BulletList, { BulletListItem } from 'components/ui/BulletList';

import { getFeatureList } from './utils';

import styles from './index.module.scss';
import { Card } from 'components/ui/Card';

interface Props {}

const scopes = 'commands,chat:write';

function AddToSlack({}: Props): ReactElement {
  const oauthMappingQuery = useQuery(SLACK_OAUTH_MAPPING_QUERY, () => {
    return api.slackSettings.getOauthMapping();
  });

  const clientId = useSelector(loggedInUserSelectors.slackClientId);
  const organizationId = useSelector(loggedInUserSelectors.organizationID);

  if (oauthMappingQuery.status === 'loading' || oauthMappingQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (oauthMappingQuery.status === 'success') {
    return (
      <div className={`${styles['SuccessWrapper']} flex flex-col items-center`}>
        <img className={styles['HeadlineImage']} src={getStaticMediaUrl(slackInstallImage)} />
        <div className={styles.Headline}>XPayroll is now available on Slack!</div>
        <Card className='slack-container'>
        <div className={styles.InstallText}>Add it to your workspace and take actions easily:</div>
        <div className={styles.Features}>
          <BulletList>
            {!!organizationId &&
              getFeatureList(organizationId).map((feature) => {
                return <BulletListItem key={feature}>{feature}</BulletListItem>;
              })}
          </BulletList>
        </div>
        <div className="self-start mt-8">...all within Slack</div>
        <div className="self-start mt-4">
          Here's{' '}
          <a
            className="text-primary text-2xl"
            target="_blank"
            rel="noopener noreferrer"
            href="https://intercom.help/XPayroll/en/articles/5264610-slack-app-for-xpayroll-a-guide"
          >
            a quick guide
          </a>{' '}
          to get you started.
        </div>
        </Card>
        <a
          className="mt-12"
          href={`https://slack.com/oauth/v2/authorize?scope=${scopes}&client_id=${clientId}&state=${oauthMappingQuery.data?.state_param}`}
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, 
              https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
    );
  }

  return <></>;
}

export default AddToSlack;
