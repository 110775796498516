export const planBenefits = {
  1: {
    'Pre-existing coverage': 'Post 1 year',
    'Per disease capping': {
      benefit: 'Per disease capping',
      description: 'Yes',
    },
    'Waiting period': '30 Days',
    'Specific illness waiting': {
      benefit: 'Specific illness waiting',
      description: 'Yes',
    },
    'Ambulance cover': '₹1,000',
    'New born baby cover': 'From Day 91',
  },
  2: {
    'Pre-existing coverage': 'Instant',
    'Per disease capping': 'None',
    'Waiting period': 'None',
    'Specific illness waiting': 'None',
    'Ambulance cover': '₹3,000',
    'New born baby cover': 'From Day 91',
  },
  3: {
    'Maternity coverage': '₹50,000',
    'Maternity waiting period': 'Instant',
    'New born baby cover': 'From Day 1',
  },
};

export const planNames = {
  1: 'Starter',
  2: 'Essential',
  3: 'Premium',
};

export const standardBenefits = (sumInsured?: number | null) => ({
  'Optional add-ons': 'Spouse, children, Parents & inLaws',
  'Day care procedure limit': '150',
  'Room rent limit': `₹${
    sumInsured ? ((sumInsured * 2) / 100).toLocaleString() : ''
  } (2% of Sum insured)`,
  'ICU Room rent limit': `₹${
    sumInsured ? ((sumInsured * 4) / 100).toLocaleString() : ''
  } (4% of sum insured)`,
  'Pre-hospitalisation': '60 Days',
  'Post hospitalisation': '90 days',
});
