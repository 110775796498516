import { Box, Button, Text, Heading } from '@razorpay/blade/components';
import React from 'react';
import checkIcon from 'assets/bulkUploads/file--download--success--tick.svg';
import {
  AnimatedContainer,
  SuccessViewModalContainer,
  SuccessViewModalContentWrapper,
  TitleContainer,
} from 'components/ui/FullscreenSuccessModal/styles';

const SuccessModal = ({ isOpen = false, onClose }: { isOpen?: boolean; onClose: () => void }) => {
  if (!isOpen) return null;

  return (
    (<SuccessViewModalContainer>
      <SuccessViewModalContentWrapper>
        <AnimatedContainer>
          <TitleContainer>
            <img src={checkIcon} width={80} height={80} alt="" />
            <Heading marginTop={'spacing.7'} textAlign="center" size="xlarge">
              Your integration with RazorpayX is active! 🎉
            </Heading>
          </TitleContainer>
        </AnimatedContainer>
        <AnimatedContainer delay={1200}>
          <Box display={'flex'} justifyContent={'center'} marginTop="spacing.5">
            <Button onClick={onClose}>Close</Button>
          </Box>
        </AnimatedContainer>
      </SuccessViewModalContentWrapper>
    </SuccessViewModalContainer>)
  );
};

export default SuccessModal;
