import React from 'react';
import { WorkFlowStatus } from './types';
import {
  Badge,
  Box,
  CheckCircleIcon,
  ClockIcon,
  Indicator,
  Text,
  XCircleIcon,
} from '@razorpay/blade/components';
import { format } from 'date-fns';
import { ACTION_APPROVED } from 'components/ApprovalWorkflows/constants';
import { getPeopleDataByPeopleId } from './utils';
import ActorWithComments from './ActorWithComments';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';

type WorkflowLevelProps = {
  levelNumber: number;
  status: WorkFlowStatus;
  activityLogs: GetWorkflowRequestDetailsContract['activity_log'][string];
  requiredApprovers: number[];
  minApprovals: number;
  peopleMetaData: GetWorkflowRequestDetailsContract['people_metadata'];
};

function getPendingApproversForLevel(
  requiredApprovers: number[],
  activityLogs: GetWorkflowRequestDetailsContract['activity_log'][string],
) {
  const peopleWhoApproved = activityLogs
    .filter((activity) => activity.activity === ACTION_APPROVED)
    .map((activity) => activity.by_people_id);

  return requiredApprovers.filter((approver) => !peopleWhoApproved.includes(approver));
}

function getSummarizedPendingApproverNames(
  approverNames: string[],
  remainingApprovals: number,
): string {
  if (approverNames.length === 0) {
    return '';
  }

  if (approverNames.length === 1) {
    return approverNames[0];
  }

  if (remainingApprovals === 1 && approverNames.length === 2) {
    return `${approverNames.join(' or ')}`;
  }

  if (remainingApprovals === 2 && approverNames.length === 2) {
    return approverNames.join(' and ');
  }

  let summarizedText = `${approverNames[0]}, ${approverNames[1]}`;

  if (remainingApprovals === 3 && approverNames.length === 3) {
    return `${summarizedText} and ${approverNames[2]}`;
  }

  if (remainingApprovals === 2 && approverNames.length === 3) {
    return `Either ${remainingApprovals} of ${summarizedText} and ${approverNames[2]}`;
  }

  if (remainingApprovals === approverNames.length) {
    return `${summarizedText} and ${approverNames.length - 2} more`;
  } else {
    return `Either ${remainingApprovals} of ${summarizedText} and ${approverNames.length - 2} more`;
  }
}

const SmallDot = styled.span(
  ({ theme }) => `
    width: 8px;
    height: 8px;
    position: relative;
    top 7px;
    border-radius: 50%;
    background-color: ${theme.colors.surface.text.gray.disabled};
  `,
);

const TimelineBorder = styled.div(
  ({ theme }) => `
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      left: 4px;
      background-color: ${theme.colors.surface.border.gray.normal};
      width: 1px;
      z-index: -1;
      height: calc(100% + 20px);
    }
  `,
);

function WorkflowLevel({
  levelNumber,
  status,
  activityLogs,
  requiredApprovers,
  minApprovals,
  peopleMetaData,
}: WorkflowLevelProps) {
  const loggedInPeopleId = useSelector(loggedInUserSelectors.peopleId);

  const pendingApproverNames = getPendingApproversForLevel(requiredApprovers, activityLogs).map(
    (approverId) => {
      const person = getPeopleDataByPeopleId(peopleMetaData, approverId.toString());

      let name = person.name;
      if (person.people_id === loggedInPeopleId) {
        name = `${name} (You)`;
      }

      return name;
    },
  );
  
function formatDate(date : Date){
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 5); 
  newDate.setMinutes(newDate.getMinutes() + 30);
  return format(newDate, 'do MMM, h:mm a');
}

  return (
    (<TimelineBorder>
      <Box display="flex" gap="spacing.6" alignItems="baseline">
        {((status) => {
          switch (status) {
            case 'approved':
              return <Indicator accessibilityLabel="requested" color="positive" />;
            case 'pending':
              return <Indicator accessibilityLabel="pending" color="notice" />;
            case 'rejected':
              return <Indicator accessibilityLabel="rejected" color="negative" />;
            case 'upcoming':
              return <Indicator accessibilityLabel="upcoming" />;
          }
        })(status)}
        <Box>
          <Box display="flex" gap="spacing.3" marginBottom="spacing.4">
            <Text
              size="medium"
              color={
                status === 'upcoming'
                  ? 'surface.text.gray.disabled'
                  : 'surface.text.gray.subtle'
              }>
              Level {levelNumber}
            </Text>
            {((status) => {
              switch (status) {
                case 'approved':
                  return (<Badge size="medium" icon={CheckCircleIcon} color="positive">Approved</Badge>);
                case 'pending':
                  return (<>
                    <SmallDot />
                    <Text
                      size="small"
                      color="surface.text.gray.muted"
                      marginTop={'spacing.1'}>{`${activityLogs.length}/${minApprovals} approvals done`}</Text>
                    <Badge size="medium" icon={ClockIcon} color="notice">
                      Pending
                    </Badge>
                  </>);
                case 'rejected':
                  return (<Badge size="medium" icon={XCircleIcon} color="negative">Rejected</Badge>);
                default:
                  return null;
              }
            })(status)}
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.3">
            {(status === 'pending' || status === 'upcoming') && (
              <Box display="flex" gap="spacing.1">
                <Box
                  display="flex"
                  gap="spacing.3"
                  padding={['spacing.2', 'spacing.2', 'spacing.2', 'spacing.1']}>
                  <ClockIcon
                    size="small"
                    color="interactive.icon.gray.normal"
                    position="relative"
                    top="-2px"
                  />
                </Box>
                <Text size="small" color="surface.text.gray.muted">
                  {getSummarizedPendingApproverNames(
                    pendingApproverNames,
                    minApprovals - activityLogs.length,
                  )}
                </Text>
              </Box>
            )}
            {activityLogs
              .sort((log1, log2) => log2.updated_at - log1.updated_at)
              .map((log) => (
                <ActorWithComments
                  key={log.id}
                  comment={log.remarks ?? undefined}
                  status={log.activity}
                  date={formatDate(new Date(log.updated_at * 1000))}
                  person={getPeopleDataByPeopleId(peopleMetaData, log.by_people_id.toString())}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </TimelineBorder>)
  );
}

export default WorkflowLevel;
