import React from 'react';

import { orgTypes } from 'components/Kyc/constants';
import Section from '../Section';
import { PartnerShipFields } from './PartnerShipFields';
import { PrivateLimitedFields } from './PrivateLimitedFields';
import { SolePropFields } from './SolePropFields';
import { TrustFields } from './TrustFields';

interface CompanyDetailsSectionProps {
  orgType?: number | null;
  gstin?: string | null;
}

const CompanyDetailsSection = ({ orgType, gstin }: CompanyDetailsSectionProps) => {
  const getFields = () => {
    if (orgType === orgTypes.PRIVATE_LIMITED) {
      return <PrivateLimitedFields gstin={gstin} orgType={orgType} />;
    } else if (orgType === orgTypes.SOLE_PROP) {
      return <SolePropFields gstin={gstin} orgType={orgType} />;
    } else if (orgType === orgTypes.PARTNERSHIPS) {
      return <PartnerShipFields gstin={gstin} orgType={orgType} />;
    } else if (orgType === orgTypes.TRUST) {
      return <TrustFields gstin={gstin} orgType={orgType} />;
    } else {
      return null;
    }
  };

  return (
    <Section title="🏢 Company" subtitle="details">
      {getFields()}
    </Section>
  );
};

export default CompanyDetailsSection;
