import {
  Alert,
  Box,
  InfoIcon,
  Spinner,
  Text,
  Heading,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import Errors from 'components/ui/Form/Errors';
import KYC from 'constants/kyc';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import DocumentUploadCtas from './DocumentUploadCtas';
import SuccessViewModal from './SuccessViewModal';
import KycRejectedView from './components/KycRejectedView';
import SidebarHeader from './components/SidebarHeader';
import SidebarSection from './components/SidebarSection';
import AddressProofSection from './components/UploadDocuments/AddressProofSection';
import CompanyDetailsSection from './components/UploadDocuments/CompanyDetailsSection';
import { customerTypes, documentUploadLinks, needHelpLinks } from './constants';
import { useCompanyVerificationData } from './hooks/useCompanyVerificationDetails';
import { useIsSelfServeP3M1Enabled } from './hooks/useIsSelfServeP3M1Enabled';
import useKycInfo from './hooks/useKycInfo';
import { useKycValidations } from './hooks/useKycValidations';
import {
  ErrorMessage,
  FooterActionsContainer,
  KycPageMainViewWrapper,
  KycPageSideViewWrapper,
  KycPageWrapper,
  KycViewContent,
  KycViewFooter,
  KycViewHeader,
  TextPair,
} from './styles';
import { OrgType, ProgressPillState } from './types';

const UploadDocumentsView = () => {
  const org = useSelector(loggedInUserSelectors.currentOrganization);
  const isL1KycCompleted = org?.levelOneKyc?.status === true;
  const orgType = org?.type as OrgType;
  const gstin = org?.gstin;

  const { kycInfo, initateKycVerification, setIsVerifying, isVerifying } = useKycInfo();

  const {
    status: kycStatus = KYC.STATUS_NOT_INITIATED,
    autoKyc,
    isRazorpayCustomer,
  } = kycInfo.data?.kycInfo || {};
  const { verificationType } = autoKyc || {};

  const { isSelfServeP3M1Enabled, isLoading: isFetchingSplitzExperiment } =
    useIsSelfServeP3M1Enabled();

  const { isVerifyAvailable, isBankAccountDetailsAdded, isBankAccountVerified } = useKycValidations(
    {
      kycStatus,
      isSelfServeP3M1Enabled,
    },
  );

  const { isCompanyVerified } = useCompanyVerificationData();

  const isKycFlowManual = verificationType === 'manual';
  const isKycVerified = kycStatus === KYC.STATUS_VERIFIED || org?.kycStatus === KYC.STATUS_VERIFIED;
  const isKycRejected = kycStatus === KYC.STATUS_REJECTED || org?.kycStatus === KYC.STATUS_REJECTED;

  const progress = useMemo<ProgressPillState[]>(() => {
    if (isSelfServeP3M1Enabled) {
      return [
        'COMPLETED',
        isBankAccountVerified ? 'COMPLETED' : 'IN_PROGRESS',
        isKycVerified ? 'COMPLETED' : 'IN_PROGRESS',
      ];
    } else {
      return [
        isBankAccountVerified ? 'COMPLETED' : 'IN_PROGRESS',
        isKycVerified ? 'COMPLETED' : 'IN_PROGRESS',
      ];
    }
  }, [isSelfServeP3M1Enabled, isBankAccountVerified, isKycVerified]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isVerifyAvailable) return;
    setIsVerifying(true);
    initateKycVerification.mutate();
  };

  const getFooterMessage = () => {
    if (isKycRejected) return null;
    if (initateKycVerification.error) {
      return <ErrorMessage message={<Errors errorResponse={initateKycVerification.error} />} />;
    }
    if (kycStatus === KYC.STATUS_PENDING) {
      if (isKycFlowManual) {
        return (
          <TextPair
            mutedText="⏳ Verifying... some documents are taking longer,"
            highlightedText="might take up to 2 business days"
          />
        );
      }
      return <TextPair mutedText="⏳ Verifying..." highlightedText="takes up to 2 minutes" />;
    } else if (kycStatus === KYC.STATUS_ON_HOLD) {
      if (!isVerifyAvailable) {
        return (
          <Box>
            <TextPair mutedText="Please" highlightedText="re-upload correct documents" />
            <TextPair mutedText="to verify" />
          </Box>
        );
      }
    } else {
      return (
        <Box>
          <TextPair mutedText="📑 Please upload" highlightedText="all documents" />
          <TextPair mutedText="to start verification" />
        </Box>
      );
    }
  };

  const isRzpUser = isRazorpayCustomer === customerTypes.RZP_CUSTOMER;

  if ((!isL1KycCompleted || !orgType) && !kycInfo.isLoading) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  if (isRzpUser && kycStatus !== KYC.STATUS_NOT_INITIATED && !kycInfo.isFetching) {
    // If self-serve p3m1 is enabled, then redirecting the user to dashboard instead
    if (isSelfServeP3M1Enabled) {
      window.location.href = '/dashboard';
      return null;
    }

    return <Redirect to={routePaths.kyc.userSelection} />;
  }

  if (kycStatus === KYC.STATUS_NOT_INITIATED && !isBankAccountDetailsAdded && !kycInfo.isFetching) {
    return <Redirect to={routePaths.kyc.bankDetails} />;
  }

  if (isKycRejected) {
    return <KycRejectedView />;
  }

  // Redirecting to company verification if company not yet verified
  if (isSelfServeP3M1Enabled && !isCompanyVerified) {
    return <Redirect to={routePaths.kyc.companyDetailsVerification.root} />;
  }

  if (isFetchingSplitzExperiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  return (
    (<KycPageWrapper>
      <KycPageSideViewWrapper>
        <Box
          overflowY={'auto'}
          display={'flex'}
          flexDirection={'column'}
          paddingY={'spacing.9'}
          paddingX={'spacing.10'}>
          <SidebarHeader
            onBackClick={() => (window.location.href = '/dashboard')}
            progress={progress}
          />
          <Box display={'flex'} gap={'spacing.2'} alignItems={'center'} marginTop={'spacing.4'}>
            <Text color="surface.text.gray.muted">Why do KYC?</Text>
            <Tooltip
              content={'Complete KYC to unlock salary slips and salary transfers'}
              placement="right">
              <TooltipInteractiveWrapper>
                <InfoIcon color="interactive.icon.gray.normal" size="small" />
              </TooltipInteractiveWrapper>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" gap="spacing.10" marginTop="spacing.10">
            <SidebarSection title="Helpful Links" links={documentUploadLinks} />
            <SidebarSection title="Need Help?" links={needHelpLinks} />
          </Box>
        </Box>
      </KycPageSideViewWrapper>
      <KycPageMainViewWrapper onSubmit={handleSubmit} noValidate>
        {kycInfo.isLoading ? (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
            width={'100%'}>
            <Spinner accessibilityLabel="loading" />
          </Box>
        ) : (
          <Box height="100vh" display={'grid'} gridTemplateRows={'1fr auto'}>
            <Box overflowY={'auto'}>
              <KycViewHeader>
                <Heading size="xlarge">📑 Upload Documents</Heading>
              </KycViewHeader>

              <KycViewContent>
                <Box maxWidth="550px" paddingBottom="spacing.7">
                  <Alert
                    isDismissible={false}
                    description="Please ensure the uploaded documents are not password protected"
                    color="information"
                  />
                </Box>
                <AddressProofSection orgType={orgType} />
                <CompanyDetailsSection orgType={orgType} gstin={gstin} />
              </KycViewContent>
            </Box>
            <KycViewFooter>
              <Box>{getFooterMessage()}</Box>
              <FooterActionsContainer>
                <DocumentUploadCtas isVerifying={isVerifying} kycStatus={kycStatus} />
              </FooterActionsContainer>
            </KycViewFooter>
          </Box>
        )}
      </KycPageMainViewWrapper>
      <SuccessViewModal isOpen={isKycVerified} orgName={org?.name || ''} />
    </KycPageWrapper>)
  );
};

export default UploadDocumentsView;
