import { FeatureFlags } from 'types';

const MILESTONE1 = 'reArchMilestone1';
const M2S1 = 'reArchM2S1';
const M2S2 = 'reArchM2S2';


export const isFeatureDisabled = (
  features: Record<FeatureFlags, boolean>,
  enabledAfterMilestones: string[] = [],
): boolean => {
  const isMilestone1Enabled = Boolean(features?.[MILESTONE1]);

  if (isMilestone1Enabled) {
    if (enabledAfterMilestones.length > 0) {
      const enabledMilestones = enabledAfterMilestones.filter((milestone) =>
        Boolean(features?.[milestone as FeatureFlags]),
      );
      return enabledMilestones.length === 0;
    }
    return true;
  }

  return false;
};

export const isMilestone1Enabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isM2S1Enabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return Boolean(features?.[M2S1]);
};

export const isM2S2Enabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return Boolean(features?.[M2S2]);
};

export const isCustomSalaryStructureDisabled = (
  features: Record<FeatureFlags, boolean>,
): boolean => {
  return isFeatureDisabled(features);
};

export const isDeductibleBenefitsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isVariablePayDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isPerquisiteDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isAdditionsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features, [M2S1]);
};

export const isDeductionsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features, [M2S1]);
};

export const isApprovalWorkflowsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isFBPDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isEmployerNPSDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isZaggleDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isBonusDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isLoansDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isAdhocPaymentsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isRLOPDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isLOPDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features, [M2S1]);
};

export const isCustomEmployeeFieldDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isReimbursementsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isAdvanceSalaryDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isImprestDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isArrearsDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isHoldSalaryPayComplianceDisabled = (
  features: Record<FeatureFlags, boolean>,
): boolean => {
  return isFeatureDisabled(features);
};

export const isOfferLetterGenerationDisabled = (
  features: Record<FeatureFlags, boolean>,
): boolean => {
  return isFeatureDisabled(features);
};

export const isVPFDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isGratuityDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isLeaveEncashmentDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isPayrollAutoPilotDisabled = (features: Record<FeatureFlags, boolean>): boolean => {
  return isFeatureDisabled(features);
};

export const isUseOnlyBasicForPfSettingDisabled = (
  features: Record<FeatureFlags, boolean>,
): boolean => {
  return isFeatureDisabled(features);
};

export const getDisabledFeaturesForRearch = (features: Record<FeatureFlags, boolean>) => {
  return {
    isMileStone1Enabled: isMilestone1Enabled(features),
    isM2S1Enabled: isM2S1Enabled(features),
    isM2S2Enabled: isM2S2Enabled(features),
    isCustomSalaryStructureDisabled: isCustomSalaryStructureDisabled(features),
    isDeductibleBenefitsDisabled: isDeductibleBenefitsDisabled(features),
    isVariablePayDisabled: isVariablePayDisabled(features),
    isPerquisiteDisabled: isPerquisiteDisabled(features),
    isAdditionsDisabled: isAdditionsDisabled(features),
    isDeductionsDisabled: isDeductionsDisabled(features),
    isApprovalWorkflowsDisabled: isApprovalWorkflowsDisabled(features),
    isFBPDisabled: isFBPDisabled(features),
    isEmployerNPSDisabled: isEmployerNPSDisabled(features),
    isZaggleDisabled: isZaggleDisabled(features),
    isBonusDisabled: isBonusDisabled(features),
    isLoansDisabled: isLoansDisabled(features),
    isAdhocPaymentsDisabled: isAdhocPaymentsDisabled(features),
    isRLOPDisabled: isRLOPDisabled(features),
    isLOPDisabled: isLOPDisabled(features),
    isCustomEmployeeFieldDisabled: isCustomEmployeeFieldDisabled(features),
    isReimbursementsDisabled: isReimbursementsDisabled(features),
    isAdvanceSalaryDisabled: isAdvanceSalaryDisabled(features),
    isImprestDisabled: isImprestDisabled(features),
    isArrearsDisabled: isArrearsDisabled(features),
    isHoldSalaryPayComplianceDisabled: isHoldSalaryPayComplianceDisabled(features),
    isOfferLetterGenerationDisabled: isOfferLetterGenerationDisabled(features),
    isVPFDisabled: isVPFDisabled(features),
    isGratuityDisabled: isGratuityDisabled(features),
    isLeaveEncashmentDisabled: isLeaveEncashmentDisabled(features),
    isPayrollAutoPilotDisabled: isPayrollAutoPilotDisabled(features),
    isUseOnlyBasicForPfSettingDisabled: isUseOnlyBasicForPfSettingDisabled(features),
  };
};
