import { Box } from '@razorpay/blade/components';
import React, { useMemo } from 'react';
import { NavItemProps, SideNav } from './components/SideNav';
import { routePaths } from 'components/Routes/data';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { EditPayroll } from './EditPayroll';
import { SalaryRevision } from './SalaryRevision';
import { FinalizePayroll } from './FinalizePayroll';
import { Tab, TabsPanel } from './components/TabsPanel';
import { DateFilter } from './components/DateFilter';
import { StatusFilter } from './components/StatusFilter';
import { FiltersChips } from './components/FiltersChips';
import { MyRequestContextProvider } from './MyRequestsContext';
import { BulkApprovalModal, SingleApprovalModal } from './components/ApprovalModal';
import { BulkRejectModal, SingleRejectModal } from './components/RejectModal';
import { DetailsView } from './components/DetailsView';
import {
  MainContentContainer,
  SideNavContainer,
  WorkflowLayout,
} from '../components/WorkflowLayout';
import { useQuery } from 'react-query';
import api from 'api';
import { getTitle, WorkflowRequestsCacheKeys } from './constants';
import { WorkflowTypesMap } from '../constants';
import { BulkWithdrawModal, SingleWithdrawModal } from './components/WithdrawModal';
import { RestrictedRoute } from 'components/Routes/RestrictedRoute';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

const MyRequests = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { isApprovalWorkflowsDisabled } = useIsFeatureDisabledForRearch();
  const { data: workflowSummaryData } = useQuery({
    queryFn: () => api.workflowApis.getWorkflowSummaryForUser(),
    queryKey: WorkflowRequestsCacheKeys.summaryForUser,
  });



  const tabItems: Tab[] = useMemo(() => {
    if (pathname.includes(routePaths.approvalWorkflows.myRequests.finalizePayroll.root)) {
      return [
        {
          text: 'Pending approvals',
          href: routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
          actionableCount:
            workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.FINALIZE_PAYROLL],
          isActive: pathname.includes(
            routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
          ),
        },
        {
          text: 'Completed by me',
          href: routePaths.approvalWorkflows.myRequests.finalizePayroll.completed,
          isActive: pathname.includes(
            routePaths.approvalWorkflows.myRequests.finalizePayroll.completed,
          ),
        },
        {
          text: 'Initiated by me',
          href: routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe,
          isActive: pathname.includes(
            routePaths.approvalWorkflows.myRequests.finalizePayroll.initiatedByMe,
          ),
        },
      ];
    }

    if (pathname.includes(routePaths.approvalWorkflows.myRequests.salaryRevision.root)) {

      return [
        {
          text: 'Pending approvals',
          href: routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
          actionableCount: workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.SALARY_REVISION],
          isActive: pathname.includes(
            routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
          ),
        },
        {
          text: 'Completed by me',
          href: routePaths.approvalWorkflows.myRequests.salaryRevision.completed,
          isActive: pathname.includes(routePaths.approvalWorkflows.myRequests.salaryRevision.completed),
        },
        {
          text: 'Initiated by me',
          href: routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe,
          isActive: pathname.includes(
            routePaths.approvalWorkflows.myRequests.salaryRevision.initiatedByMe,
          ),
        },
      ];

    }

    // returning for edit-payroll by default
    return [
      {
        text: 'Pending approvals',
        href: routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
        actionableCount: workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.EDIT_PAYROLL],
        isActive: pathname.includes(
          routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
        ),
      },
      {
        text: 'Completed by me',
        href: routePaths.approvalWorkflows.myRequests.editPayroll.completed,
        isActive: pathname.includes(routePaths.approvalWorkflows.myRequests.editPayroll.completed),
      },
      {
        text: 'Initiated by me',
        href: routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe,
        isActive: pathname.includes(
          routePaths.approvalWorkflows.myRequests.editPayroll.initiatedByMe,
        ),
      },
    ];
  }, [pathname, workflowSummaryData]);

  if (isApprovalWorkflowsDisabled) {
    return <Redirect to={routePaths.unknown} />;
  }

  if (pathname === routePaths.approvalWorkflows.myRequests.root) {
    return <Redirect to={routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals} />;
  }

  const navItems: NavItemProps[] = [
    {
      text: 'Edit Payroll',
      href: routePaths.approvalWorkflows.myRequests.editPayroll.root,
      isActive: pathname.includes(routePaths.approvalWorkflows.myRequests.editPayroll.root),
      actionableCount: workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.EDIT_PAYROLL],
      isFeatureEnabled: true
    },
    {
      text: 'Finalize Payroll',
      href: routePaths.approvalWorkflows.myRequests.finalizePayroll.root,
      isActive: pathname.includes(routePaths.approvalWorkflows.myRequests.finalizePayroll.root),
      actionableCount: workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.FINALIZE_PAYROLL],
      isFeatureEnabled: true
    },
    {
      text: 'Salary Revision',
      href: routePaths.approvalWorkflows.myRequests.salaryRevision.root,
      isActive: pathname.includes(routePaths.approvalWorkflows.myRequests.salaryRevision.root),
      actionableCount: workflowSummaryData?.pending_approvals?.[WorkflowTypesMap.SALARY_REVISION],
      isFeatureEnabled: true
    },
  ];

  const isStatusFilterDisabled = [
    routePaths.approvalWorkflows.myRequests.editPayroll.pendingApprovals,
    routePaths.approvalWorkflows.myRequests.finalizePayroll.pendingApprovals,
    routePaths.approvalWorkflows.myRequests.salaryRevision.pendingApprovals,
  ].includes(pathname);

  const handleBackCtaClick = () => {
    window.location.href = '/dashboard';
  };

  return (
    <MyRequestContextProvider>
      <WorkflowLayout onBackButtonClick={handleBackCtaClick} title={`Approvals | ${getTitle(pathname)}`}>
        <SideNavContainer>
          <SideNav navItems={navItems} />
        </SideNavContainer>
        <MainContentContainer>
          <Box
            display="flex"
            height="60px"
            alignItems="center"
            justifyContent="space-between"
            padding={['0px', 'spacing.8']}
            backgroundColor="surface.background.gray.intense"
            borderBottomWidth="thin"
            borderBottomColor="surface.border.gray.normal">
            <TabsPanel tabItems={tabItems} />
            <Box display="flex" gap="spacing.6" alignItems="center">
              <StatusFilter isDisabled={isStatusFilterDisabled} />
              <DateFilter />
            </Box>
          </Box>
          <FiltersChips />
          <Switch>
            <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.root}>
              <EditPayroll />
            </Route>
            <Route path={routePaths.approvalWorkflows.myRequests.finalizePayroll.root}>
              <FinalizePayroll />
            </Route>
            <RestrictedRoute
              path={routePaths.approvalWorkflows.myRequests.salaryRevision.root}
              component={SalaryRevision}
            />
          </Switch>
        </MainContentContainer>
      </WorkflowLayout>
      <DetailsView />
      <BulkApprovalModal />
      <BulkRejectModal />
      <BulkWithdrawModal />
      <SingleApprovalModal />
      <SingleRejectModal />
      <SingleWithdrawModal />
    </MyRequestContextProvider>
  );
};

export { MyRequests };
