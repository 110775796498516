import React from 'react';
import { SecondaryButton } from 'components/ui/Button';
import classes from '../../EsicRegistrationDashboard/index.module.scss';

function ButtonWrapper({ onClick, disabled, children }: any) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <SecondaryButton
        onClick={onClick}
        disabled={disabled}
        className={classes['status-button']}
        type="button"
      >
        {children}
      </SecondaryButton>
    </div>
  );
}

export default ButtonWrapper;