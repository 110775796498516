import React from "react";
import {ZaggleIntroHowDoesItWorkItem} from "./ZaggleIntroHowDoesItWorkItem";
import zaggleWallet from "../../../../assets/zaggleWallet.svg";
import zaggleCard from "../../../../assets/zaggleCard.svg";
import zaggleBillAndReceipt from "../../../../assets/zaggleBillAndReceipt.svg";
import zaggleManageFromPlatform from "../../../../assets/zaggleManageFromPlatform.svg";
import zaggleEmployeeManageFromMobile from "../../../../assets/zaggleEmployeeManageFromMobile.svg";

export function BenefitsSection() {
    return <div className={"space-y-10"}>
        <ul className={"flex flex-col md:flex-row "}>
            <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleWallet}
                                          description={"Manage your tax-saving employee benefits without any hassle. "}/>
            <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleCard}
                                          description={"Add tax-free benefits electronically to the card and help your employee save tax "}/>
            <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleBillAndReceipt}
                                          description={"No need for the organisation to manually verify bill and reciepts "}/>
        </ul>
        <ul className={"flex flex-col md:flex-row justify-center"}>
            <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleManageFromPlatform}
                                          description={"Organisation can manage and disburse digital benefits directly from XPayroll."}/>
            <ZaggleIntroHowDoesItWorkItem iconSrc={zaggleEmployeeManageFromMobile}
                                          description={"Employee can manage tax benefits using the Zaggle card and on mobile app."}/>
        </ul>
    </div>;
}