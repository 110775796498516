import api from 'api';
import { SPLITZ_EXPERIMENTS_QUERY_CACHE_KEY } from 'constants/splitz';
import { useQuery, useQueryClient } from 'react-query';

interface UseSplitzParams {
  disabled?: boolean;
}

const useSplitz = ({ disabled = false }: UseSplitzParams = {}) => {
  const queryClient = useQueryClient();

  const splitz = useQuery({
    queryKey: SPLITZ_EXPERIMENTS_QUERY_CACHE_KEY,
    queryFn: api.splitz.getExperiments,
    enabled: !disabled,
    staleTime: 300000, // keeping the stale-time as 5 mins as splitz experiment don't change often for a user
  });

  const invalidateSplitzQueryCache = () => {
    queryClient.invalidateQueries(SPLITZ_EXPERIMENTS_QUERY_CACHE_KEY);
  };

  return {
    ...splitz,
    data: splitz.data || {},
    invalidateSplitzQueryCache,
  };
};

export default useSplitz;
