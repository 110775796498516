import { GetWorkflowRequestsContract } from 'schemas/GetWorkflowRequests';
import { QueryClient } from 'react-query';
import { WorkflowRequestsCacheKeys, WorkflowRequestsStatusMap } from '../constants';
import { FinalizePayrollListItem } from './types';
import { WorkflowRequestDetailsQueryKey } from '../components/DetailsView/constants';

export const transformFinalizePayrollRequestData = (
  data: GetWorkflowRequestsContract['data'][0],
): FinalizePayrollListItem => {
  const payload = data.payload as any;

  return {
    id: data.id,
    payrollMonth: payload.payroll_month, //format(new Date(payload.payroll_month as string), 'MMMM'),
    approvalLevel: data.pending_on_level as number,
    finalizedEmployees: payload?.finalized_employee_count ?? 0,
    skippedEmployees: payload?.skipped_employees_count ?? 0,
    allowAction: data.status === WorkflowRequestsStatusMap.pending,
    status: data.status,
  };
};

export const invalidateFinalizePayrollCache = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.finalizePayroll.pending);
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.finalizePayroll.initiatedByMe);
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.finalizePayroll.completed);

  // invalidating workflow-summary cache
  queryClient.invalidateQueries(WorkflowRequestsCacheKeys.summaryForUser);

  // invalidating workflow-request-details cache
  queryClient.invalidateQueries(WorkflowRequestDetailsQueryKey);
};
