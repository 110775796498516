import { Box, Heading, Switch, Text, TextInput } from '@razorpay/blade/components';
import { Reveal } from 'components/ui/Reveal/Reveal';
import React from 'react';
import { FlexiBenefitsSettings } from '../types';
import CustomWindowSettings from './CustomWindowSettings';

interface Props {
  settings: FlexiBenefitsSettings;
  onChange: (key: keyof FlexiBenefitsSettings, value: string | boolean) => void;
  isLoading: boolean;
  orgId?: string | number;
}

const AdvancedWindowConfiguration = ({ isLoading, settings, onChange, orgId }: Props) => {
  if (!orgId) {
    return null;
  }

  return (
    <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="500px" paddingY="spacing.11">
      <Heading size="small">Advance window configuration</Heading>
      <Box marginTop="spacing.10" display="flex" flexDirection="column" gap="spacing.7">
        <Box
          borderWidth="thin"
          borderColor="surface.border.gray.muted"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Auto open declaration window for new employees
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                Auto-open declaration window for new joiners with FBP component in their salary
                structure for a certain time after joining. Employees will be notified via email.
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.isAutoOpenWindowForNewEmployeesEnabled}
              size="small"
              accessibilityLabel="FBP Allowance Toggle"
              alignSelf="flex-start"
              onChange={({ isChecked }) =>
                onChange('isAutoOpenWindowForNewEmployeesEnabled', isChecked)
              }
            />
          </Box>
          <Reveal isOpen={settings.isAutoOpenWindowForNewEmployeesEnabled}>
            <Box paddingTop="spacing.7">
              <Box
                paddingTop="spacing.7"
                borderTopWidth="thin"
                borderColor="surface.border.gray.subtle">
                <Box display="flex" gap="spacing.3" alignItems="center">
                  <Text weight="semibold" color="surface.text.gray.muted">
                    How many days should the declaration window remain open after the employee joins
                  </Text>
                </Box>

                <Box paddingTop={'spacing.4'}>
                  <Box width="50%">
                    <TextInput
                      value={settings.autoOpenDeclarationWindowForNewEmployees || ''}
                      accessibilityLabel=""
                      onChange={({ value }) =>
                        onChange('autoOpenDeclarationWindowForNewEmployees', value || '')
                      }
                    />
                  </Box>
                  {settings.autoOpenDeclarationWindowForNewEmployees ? (
                    <Text
                      marginTop="spacing.4"
                      variant="caption"
                      color="interactive.text.information.subtle"
                      size="small">
                      {`⚡️ Declaration window will be open for ${settings.autoOpenDeclarationWindowForNewEmployees} days after the employee's joining date`}
                    </Text>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Reveal>
        </Box>

        {/* Salary Revision */}
        <Box
          borderWidth="thin"
          borderColor="surface.border.gray.muted"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Auto-open declaration window for salary-revised employees
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                Auto-open declaration for salary-revised employees with flexible benefit components
                in their salary structure. Employees will be notified via email.
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.isAutoOpenWindowForSalaryRevisedEmployeesEnabled}
              size="small"
              accessibilityLabel="FBP Allowance Toggle"
              alignSelf="flex-start"
              onChange={({ isChecked }) =>
                onChange('isAutoOpenWindowForSalaryRevisedEmployeesEnabled', isChecked)
              }
            />
          </Box>
          <Reveal isOpen={settings.isAutoOpenWindowForSalaryRevisedEmployeesEnabled}>
            <Box paddingTop="spacing.7">
              <Box
                paddingTop="spacing.7"
                borderTopWidth="thin"
                borderColor="surface.border.gray.subtle">
                <Box display="flex" gap="spacing.3" alignItems="center">
                  <Text weight="semibold" color="surface.text.gray.muted">
                    Specify the declaration window duration for salary-revised employees
                  </Text>
                </Box>

                <Box paddingTop={'spacing.4'}>
                  <Box width="50%">
                    <TextInput
                      accessibilityLabel=""
                      value={settings.autoOpenDeclarationWindowForSalaryRevisedEmployees || ''}
                      onChange={({ value }) =>
                        onChange('autoOpenDeclarationWindowForSalaryRevisedEmployees', value || '')
                      }
                    />
                  </Box>
                  {settings.autoOpenDeclarationWindowForSalaryRevisedEmployees ? (
                    <Text
                      marginTop="spacing.4"
                      variant="caption"
                      color="interactive.text.information.subtle"
                      size="small">
                      {`⚡️ Declaration window will be open for ${settings.autoOpenDeclarationWindowForSalaryRevisedEmployees} days after salary is revised for an employee. `}
                    </Text>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Reveal>
        </Box>

        {/* Dismissal */}
        <Box
          borderWidth="thin"
          borderColor="surface.border.gray.muted"
          borderRadius="medium"
          padding="spacing.7"
          backgroundColor="surface.background.gray.moderate">
          <Box display="flex" gap="spacing.8" justifyContent="space-between">
            <Box>
              <Text weight="semibold" color="surface.text.gray.muted">
                Allow employees to upload FBP proofs until dismissal
              </Text>
              <Text
                variant="caption"
                marginTop="spacing.2"
                color="surface.text.gray.disabled"
                size="small">
                Employees under the dismissals status, can submit their proofs before their exit to
                ensure they get the flexible benefits
              </Text>
            </Box>
            <Switch
              isDisabled={isLoading}
              isChecked={settings.allowProofsUploadTillDismissal}
              size="small"
              accessibilityLabel="FBP Allowance Toggle"
              alignSelf="flex-start"
              onChange={({ isChecked }) => onChange('allowProofsUploadTillDismissal', isChecked)}
            />
          </Box>
        </Box>
      </Box>
      <CustomWindowSettings orgId={orgId} />
    </Box>
  );
};

export default AdvancedWindowConfiguration;
