import React from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { useModal } from 'components/ui/Modals';
import { StandardSecondaryButton } from 'components/ui/Button';
import { RunPayrollSchemaContract } from 'schemas/RunPayrollSchema';
import { toIndianCurrencyString } from 'utils/Numbers';

import styles from './index.module.scss';
import useIsHoldSalaryPayComplianceEnabled from 'components/Fnf/useIsHoldSalaryPayComplianceEnabled';

const totalNetpayOnHoldAmountComponentName = 'net-pay-on-hold';

const PayrollAmountBreakdown = ({
  requiredAmount,
  flexiPartnerName,
}: {
  requiredAmount?: RunPayrollSchemaContract['requiredAmount'];
  flexiPartnerName?: string;
}) => {
  const { closeModal } = useModal();
  const { isHoldSalaryPayComplianceEnabled } = useIsHoldSalaryPayComplianceEnabled();

  const componentsMap = {
    'total-tds': 'TDS',
    'total-pf': 'PF (all contributions)',
    'total-pf-admin': 'PF EDLI & admin charges',
    'total-esi': 'ESI (all contributions)',
    'total-pt': 'Professional tax',
    'tds-interest': 'Interest on TDS',
    'total-flexi-partner-wallets': flexiPartnerName
      ? `${flexiPartnerName} flexible wallets`
      : 'Flexible wallets',
  };

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Payroll Amount Components
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <table className="w-full">
            <tbody>
              <tr>
                <th>Component</th>
                <th>Amount</th>
              </tr>
              <tr>
                <td>Employee net pay</td>
                <td>
                  {toIndianCurrencyString(requiredAmount?.components?.['total-salary'] ?? 0, true)}
                </td>
              </tr>
              {Object.keys(componentsMap)
                .filter((key) => ((requiredAmount?.components?.[key] as number) ?? 0) > 0)
                .map((key) => {
                  return (
                    <tr key={key}>
                      <td>{(componentsMap as any)[key]}</td>
                      <td>
                        {toIndianCurrencyString(
                          (requiredAmount?.components?.[key] as number) ?? 0,
                          true,
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td>
                  <strong>Total Amount</strong>
                </td>
                <td>
                  <strong>{toIndianCurrencyString(requiredAmount?.total ?? 0, true)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {isHoldSalaryPayComplianceEnabled && (
          <div className="px-10 italic text-2xl">
            Net pay on-hold amount is ₹
            {toIndianCurrencyString(
              requiredAmount?.components?.[totalNetpayOnHoldAmountComponentName] ?? 0,
              true,
            )}
          </div>
        )}
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardSecondaryButton onClick={() => closeModal()}>CLOSE</StandardSecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default PayrollAmountBreakdown;
