import React, { ReactElement } from 'react';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import api from 'api';
import Spinner from 'components/ui/Spinner';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ErrorMessage from 'components/ui/ErrorMessage';
import APIError from 'types/apiError';
import { StandardSecondaryButton, StandardButton, LinkButtonV2 } from 'components/ui/Button';
import { FRESHTEAMS_ACTIVATION_STATUS_QUERY } from './queries';
import permissions from 'constants/permissions';
import useHasPermission from 'utils/useHasPermission';
import { WarningAlert } from 'components/ui/Alert';
import FreshTeamsIntroduction from './components/FreshTeamsIntroduction';
import Breadcrumb from 'components/ui/Breadcrumb';
import { getStaticMediaUrl } from 'utils/Urls';
import freshTeamConnectedImage from 'assets/freshteam/freshteam_connected.png';
import syncIcon from 'assets/sync-icon.svg';
import FreshTeamsHelpSidebarElement from './components/FreshTeamsHelpSidebarElement';
import ConfirmModal from 'components/ui/Modals/components/ConfirmModal';
import { formatDistance } from 'date-fns';
import { useModal } from 'components/ui/Modals';
import integrationInstalled from 'assets/integration_uninstalled.png';

import styles from './index.module.scss';

interface Props {}

function FreshTeamsSettings({}: Props): ReactElement {
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const hasManageHRMSPermission = useHasPermission([permissions.MANAGE_HRMS_INTEGRATION]);
  const activationQuery = useQuery(
    FRESHTEAMS_ACTIVATION_STATUS_QUERY,
    () => {
      return api.freshteamsSettings.fetchActivationStatus();
    },
    {
      onError: (error: APIError) => {},
      // refetch status every 50 seconds
      refetchInterval: 50 * 1000,
    },
  );

  const deactivationMutation = useMutation(() => api.freshteamsSettings.disable(), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(FRESHTEAMS_ACTIVATION_STATUS_QUERY);
      showDeactivationSuccess();
    },
    onError: (error: APIError) => {},
  });

  const syncMutation = useMutation(() => api.freshteamsSettings.sync(), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(FRESHTEAMS_ACTIVATION_STATUS_QUERY);
    },
    onError: (error: APIError) => {},
  });

  const syncStatus = activationQuery?.data?.user_data_sync_status;

  const handleDeactivation = () => {
    deactivationMutation.mutate();
  };

  const handleDeactivationConfirmation = () => {
    openModal(
      <ConfirmModal onConfirm={handleDeactivation}>
        <div className="flex flex-col items-center max-w-xl">
          <img
            className={styles['HeadlineImage']}
            src={getStaticMediaUrl(integrationInstalled)}
            style={{ width: 227, height: 190, marginBottom: 16 }}
          />
          <div className={'text-4xl font-bold text-white mb-8 mt-4'}>Disable Freshteam?</div>
          <div className="mb-8">
            You will not lose any data which has already been synced. <br />
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  const showDeactivationSuccess = () => {
    openModal(
      <ConfirmModal confirmOnly confirmText="Ok">
        <div className="flex flex-col max-w-xl">
          <div className={'text-4xl font-bold text-white mb-8 mt-4'}>Freshteam disabled</div>
          <div className="mb-8">
            Your Freshteam integration has been disabled. You can reconnect to Freshteam anytime
            from this page.
            <br />
          </div>
        </div>
      </ConfirmModal>,
    );
  };

  const handleSync = () => {
    syncMutation.mutate();
  };

  const isUserDataSyncInProgress =
    syncStatus === 'STATUS_SYNC_IN_PROGRESS' || syncStatus === 'STATUS_SYNC_INITIATED';

  if (activationQuery.status === 'loading' || activationQuery.status === 'idle') {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (activationQuery.status === 'error') {
    return (
      <ErrorMessage title="Sorry! An error has occured!">
        {activationQuery.error?.message || 'Something went wrong, please contact support'}
      </ErrorMessage>
    );
  }

  if (!activationQuery?.data?.enabled) {
    if (hasManageHRMSPermission) {
      return (
        <FreshTeamsIntroduction
          currentlyActiveIntegration={activationQuery.data.active_integration}
        />
      );
    } else {
      return (
        <>
          <h1 className="text-4xl font-bold">Freshteam</h1>
          <div>Please reach out to your admins to connect your Freshteam account</div>
        </>
      );
    }
  }

  return (
    <>
      <img className={styles['HeadlineImage']} src={getStaticMediaUrl(freshTeamConnectedImage)} />
      {(deactivationMutation.status === 'error' || syncMutation.status === 'error') && (
        <WarningAlert className="mt-8">
          {deactivationMutation.error?.message ||
            syncMutation.error?.message ||
            'Something went wrong, please contact support'}
        </WarningAlert>
      )}
      {syncStatus === 'STATUS_SYNC_FAILURE' && (
        <WarningAlert className="mt-8">
          The data sync process has failed, please try syncing again, or reach out to support for
          help
        </WarningAlert>
      )}
      {isUserDataSyncInProgress && (
        <>
          <img
            className={`${styles['SyncImage']} mt-16 animate-spin`}
            src={getStaticMediaUrl(syncIcon)}
          />
          <div className="text-4xl font-bold text-white-o-80 mb-6 mt-8 text-center">
            Syncing data from Freshteam to your XPayroll account
          </div>
        </>
      )}
      {syncStatus === 'STATUS_SYNC_SUCCESSFULL' && (
        <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
          Your Freshteam integration is active!
        </div>
      )}
      <div className="mt-6 text-white-o-70 text-center">
        {isUserDataSyncInProgress ? (
          'It will be completed in a few minutes. You can navigate away from this screen. We will notify you once the sync is complete.'
        ) : (
          <>
            Click on 'Sync Now' if all your employees & contractors in Freshteam are not visible on
            XPayroll. If you want to add a new member, add them via the{' '}
            <a target="_blank" href={activationQuery?.data?.domain || ''} rel="noreferrer">
              <LinkButtonV2>Freshteam dashboard</LinkButtonV2>
            </a>
          </>
        )}
      </div>
      <div className="mt-20">
        {!isUserDataSyncInProgress && (
          <StandardButton
            isPending={syncMutation.status === 'loading'}
            disabled={deactivationMutation.status === 'loading' || activationQuery.isFetching}
            onClick={handleSync}
            className="mr-4">
            <img
              className={`${styles['SyncImageButton']} inline-block mr-4`}
              src={getStaticMediaUrl(syncIcon)}
            />
            Sync Now
          </StandardButton>
        )}
        {hasManageHRMSPermission && (
          <StandardSecondaryButton
            isPending={deactivationMutation.status === 'loading'}
            onClick={handleDeactivationConfirmation}
            disabled={
              deactivationMutation.status === 'loading' ||
              syncMutation.status === 'loading' ||
              activationQuery.isFetching
            }>
            Disable
          </StandardSecondaryButton>
        )}
      </div>
      {activationQuery?.data?.last_fetched_at && (
        <div className="mt-12 text-2xl text-white-o-50">
          Last synced{' '}
          {formatDistance(new Date(activationQuery?.data?.last_fetched_at), new Date(), {
            addSuffix: true,
          })}
          . Auto syncs every 24 hours.
        </div>
      )}
    </>
  );
}

function FreshTeamsSettingsWithPageWrapper(): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Freshteam"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase center>
        <FreshTeamsSettings />
      </MainCase>
      <RightSidebar>
        <FreshTeamsHelpSidebarElement />
      </RightSidebar>
    </PageWrapper>
  );
}

export default FreshTeamsSettingsWithPageWrapper;
