import { Box } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { salaryComponentsQueries } from 'components/SalaryComponents/constants';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import { DeductionsComponentType } from 'components/SalaryComponents/types';
import {
  WizardError,
  WizardHeader,
  WizardLoader,
  WizardProgressView,
  WizardWrapper,
} from 'components/WizardViews/components';
import { WizardInfoProvider } from 'components/WizardViews/providers';
import { useFormWithProvider } from 'forms';
import { Wizard, WizardStep } from 'forms/Wizard';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { deductionsSteps, editDeductionsRouteToStepMap } from '../constants';
import { DeductionsFormData } from '../types';
import { convertSalaryComponentToDeductionsFormData } from '../utils';
import GeneralDetailsStep from './GeneralDetailsStep';
import PayAndTaxStep from './PayAndTaxStep';
import ReviewStep from './ReviewStep';

const EditDeductionsForm = () => {
  const { setFormData } = useFormWithProvider<DeductionsFormData>();
  const { componentId } = useComponentParams();
  const location = useLocation();
  const history = useHistory();
  const isExistingComponent = Boolean(componentId);

  const currentStep =
    editDeductionsRouteToStepMap[location.pathname as keyof typeof editDeductionsRouteToStepMap] ||
    deductionsSteps.step1;

  const { isFetching, refetch, error } = useQuery({
    queryKey: [salaryComponentsQueries.getSalaryComponentById, componentId],
    queryFn: () =>
      api.salaryComponents.queries.getSalaryComponent({
        id: componentId!,
        category: 'DEDUCTIONS_CATEGORY',
      }),
    enabled: isExistingComponent,
    onSuccess(data) {
      const formData = convertSalaryComponentToDeductionsFormData(
        data.payrollGetSalaryComponent.data.salaryComponent as DeductionsComponentType,
      );
      setFormData(formData);
    },
  });

  const handleCloseClick = () => history.push(routePaths.salaryComponents.deductionsListView);

  if (isFetching) {
    return <WizardLoader />;
  }

  return (
    <WizardInfoProvider currentStep={currentStep} totalSteps={3}>
      <WizardWrapper>
        <WizardHeader
          heading="Modify"
          subHeading="Deductions Component"
          onCloseClick={handleCloseClick}>
          <WizardProgressView
            steps={[
              { label: 'General & Type', position: deductionsSteps.step1 },
              { label: 'Taxation', position: deductionsSteps.step2 },
              { label: 'Review', position: deductionsSteps.step3 },
            ]}
          />
        </WizardHeader>
        <Box flex={1} overflowY="auto" height="100%">
          <WizardError onRetry={refetch} error={error}>
            <Wizard>
              <WizardStep step={deductionsSteps.step1} component={GeneralDetailsStep} />
              <WizardStep step={deductionsSteps.step2} component={PayAndTaxStep} />
              <WizardStep step={deductionsSteps.step3} component={ReviewStep} />
            </Wizard>
          </WizardError>
        </Box>
      </WizardWrapper>
    </WizardInfoProvider>
  );
};

export default EditDeductionsForm;
