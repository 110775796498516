import React from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { StandardTable } from 'components/ui/Table';

export interface SalaryRevisionFileRowType {
  oldBasic: string;
  newBasic: string;
  oldDA: string;
  newDA: string;
  oldLTA: string;
  newLTA: string;
  oldHRA: string;
  newHRA: string;
  oldSA: string;
  newSA: string;
  oldPF: string;
  newPF: string;
  oldESI: string;
  newESI: string;
  oldNPS: string;
  newNPS: string;
  oldRawAllowances: any;
  newRawAllowances: any;
  [k: string]: unknown;
}

export function SalaryRevisionCustomSalaryInputModal({
  fileRow,
}: {
  fileRow: SalaryRevisionFileRowType | null;
}) {
  if (!fileRow) {
    return null;
  }

  const oldAllowances =
    Array.isArray(fileRow.oldRawAllowances) || !fileRow.oldRawAllowances
      ? []
      : Object.keys(fileRow.oldRawAllowances).map((key) => key.substring(4));

  const newAllowances =
    Array.isArray(fileRow.oldRawAllowances) || !fileRow.oldRawAllowances
      ? []
      : Object.keys(fileRow.oldRawAllowances).map((key) => key.substring(4));

  const combinedList = [...oldAllowances, ...newAllowances];
  const allowanceList = combinedList.filter((item, pos) => combinedList.indexOf(item) === pos);

  return (
    <Modal>
      <div style={{ minWidth: '31.25rem', padding: '1.25rem' }}>
        <h1 className='mb-10'>Salary Input Details</h1>
        <StandardTable className="w-full flex-1 py-2 mt-1 mb-1">
          <tbody>
            <tr>
              <th>Component</th>
              <th>Old Amount</th>
              <th>New Amount</th>
            </tr>
            <tr>
              <td>Basic Salary</td>
              <td>{fileRow.oldBasic ? fileRow.oldBasic : 0}</td>
              <td>{fileRow.newBasic ? fileRow.newBasic : 0}</td>
            </tr>
            <tr>
              <td>Dearness Allowance</td>
              <td>{fileRow.oldDA ? fileRow.oldDA : 0}</td>
              <td>{fileRow.newDA ? fileRow.newDA : 0}</td>
            </tr>
            <tr>
              <td>HRA</td>
              <td>{fileRow.oldHRA ? fileRow.oldHRA : 0}</td>
              <td>{fileRow.newHRA ? fileRow.newHRA : 0}</td>
            </tr>
            <tr>
              <td>LTA</td>
              <td>{fileRow.oldLTA ? fileRow.oldLTA : 0}</td>
              <td>{fileRow.newLTA ? fileRow.newLTA : 0}</td>
            </tr>
            <tr>
              <td>Special Allowance</td>
              <td>{fileRow.oldSA ? fileRow.oldSA : 0}</td>
              <td>{fileRow.newSA ? fileRow.newSA : 0}</td>
            </tr>
            <tr>
              <td>PF Employer Contribution</td>
              <td>{fileRow.oldPF ? fileRow.oldPF : 0}</td>
              <td>{fileRow.newPF ? fileRow.newPF : 0}</td>
            </tr>
            <tr>
              <td>ESI Employer Contribution</td>
              <td>{fileRow.oldESI ? fileRow.oldESI : 0}</td>
              <td>{fileRow.newESI ? fileRow.newESI : 0}</td>
            </tr>
            {fileRow.oldNPS != null && (
              <tr>
                <td>NPS Employer Contribution</td>
                <td>{fileRow.oldNPS ? fileRow.oldNPS : 0}</td>
                <td>{fileRow.newNPS ? fileRow.newNPS : 0}</td>
              </tr>
            )}
            {allowanceList.map((item: string, index) => {
              return (
                <>
                  <tr>
                    <td>{item}</td>
                    <td>
                      {fileRow.oldRawAllowances[`Old ${item}`]
                        ? fileRow.oldRawAllowances[`Old ${item}`]
                        : 0}
                    </td>
                    <td>
                      {fileRow.newRawAllowances[`New ${item}`]
                        ? fileRow.newRawAllowances[`New ${item}`]
                        : 0}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </StandardTable>
      </div>
    </Modal>
  );
}
