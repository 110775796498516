import React from 'react';
import { Box, Text, Badge } from '@razorpay/blade/components';
import { toIndianCurrencyString } from 'utils/Numbers';
import styled from 'styled-components';

type RegimeTaxBreakupProps = {
  regime: string;
  variant?: string;
  showSuggested: boolean;
  inHand: number;
  currentDeductions: number;
  maxDeductions: number;
  isCurrentRegime: boolean;
};

export const RegimeTaxBreakupWrapper = styled.div<{ variant: string }>(
  ({ theme, variant }) => `
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: ${theme.spacing[4]}px 0px;
    ${variant === 'grey' ? '' : 'border-top: 1px solid #1D6363; border-left: 1px solid #1D6363;'}
    max-width: 413px;
    flex-grow: 1;
    height: 262px;
    background: ${
      variant === 'grey'
        ? 'rgba(209, 210, 218, 0.05)'
        : 'linear-gradient(86deg, rgba(8, 13, 41, 0.89) -11.08%, rgba(29, 75, 99, 0.64) 116.23%)'
    };
  `,
);

const RegimeTaxBreakup = ({
  regime,
  variant,
  showSuggested,
  isCurrentRegime,
  inHand,
  currentDeductions,
  maxDeductions,
}: RegimeTaxBreakupProps) => {
  return (
    (<RegimeTaxBreakupWrapper variant={variant ?? 'grey'}>
      <Box height="46px" padding={['spacing.4', 'spacing.7']}>
        <Text color="surface.text.gray.normal" size="large" weight="semibold">
          {regime}
          {showSuggested && (
            <Badge marginLeft={'spacing.3'} size="small" color="information">
              Suggested
            </Badge>
          )}
        </Text>
        {isCurrentRegime && (
          <Text variant="caption" color="surface.text.gray.muted" size='small'>
            Selected as per your payslip
          </Text>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth="thin"
        borderBottomColor="surface.border.gray.subtle"
        padding={['spacing.4', 'spacing.7']}
        minHeight="64px">
        <Text size="large">In-hand</Text>
        <Text>{toIndianCurrencyString(inHand)}</Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth="thin"
        borderBottomColor="surface.border.gray.subtle"
        padding={['spacing.4', 'spacing.7']}
        minHeight="64px">
        <Text size="large">Current deductions</Text>
        <Text>{toIndianCurrencyString(currentDeductions)}</Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding={['spacing.4', 'spacing.7']}>
        <Box display="flex" flexDirection="column">
          <Text size="large">Max. deductions available*</Text>
          {regime.toLocaleLowerCase().includes('old') && (
            <Text size="small" variant="caption" color="surface.text.gray.disabled">
              Includes 80C, 80D etc.
            </Text>
          )}
        </Box>
        <Text>{toIndianCurrencyString(maxDeductions)}</Text>
      </Box>
    </RegimeTaxBreakupWrapper>)
  );
};

export default RegimeTaxBreakup;
