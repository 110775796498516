import React from 'react';
import { SectionHeader, SectionWrapper } from '../styles';

interface SectionProps {
  children?: React.ReactNode;
  title: string;
  subtitle: string;
}

const Section = ({ children, title, subtitle }: SectionProps) => {
  return (
    <SectionWrapper>
      <SectionHeader title={title} subtitle={subtitle} />
      {children}
    </SectionWrapper>
  );
};

export default Section;
