import React from 'react';
import { useLocation } from 'react-router-dom';
import { MONTHS_LIST } from 'utils/Dates';
import { routePaths } from '../Routes/data';
import { HeaderContent, FormProps, getDefaultFormProps, bonusStatus, bonusSource, ValidationStates } from './types';
import { EditBonusRequestSchemaContract } from '../../schemas/EditBonusRequestSchema';
import { BonusDataArraySchemaContract } from 'schemas/BonusDataArraySchema';
import { CreateBonusRequestSchemaContract } from 'schemas/CreateBonusRequestSchema';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { getNumberFromIndianCurrencyString } from 'utils/Numbers';
import { CalendarIcon, EditComposeIcon, FilePlusIcon } from '@razorpay/blade/components';


export const getClawbackMonthString = (date: string, clawbackPeriod: number | null) => {

    let payoutDate = new Date(date);
    let newMonth: number = parseInt(payoutDate.getMonth().toString()) + parseInt(clawbackPeriod ? clawbackPeriod.toString() : '0');
    let newDate = new Date(payoutDate.getFullYear(), newMonth, payoutDate.getDate() - 1);

    return `${MONTHS_LIST[newDate.getMonth()]}, ${newDate.getFullYear()}`;
}

export const getClawbackMonthStringFromDate = (date: string) => {

    let clawbackMonth = new Date(date);
    return `${MONTHS_LIST[clawbackMonth.getMonth()]}, ${clawbackMonth.getFullYear()}`;
}

export const ValidatePage = () => {

    const pathname = useLocation().pathname;
    const queryParam = new URLSearchParams(useLocation().search);
    const userId = queryParam.get('userId');
    const bonusId = queryParam.get('id');

    switch (pathname) {
        case routePaths.bonus.create:
        case routePaths.bonus.view:

            if (!userId) {
                return false;
            }
            return true;

        case routePaths.bonus.edit:

            if (!userId && !bonusId) {
                return false;
            }
            return true;
    }

}

export const headerContent = (pathname: string, stepCount: number): HeaderContent => {
    switch (stepCount) {
        case 1:
            return {
                title: pathname === routePaths.bonus.edit ? 'Manage Bonus' : 'Create New Bonus',
                description: pathname === routePaths.bonus.create ? ['Create a bonus and add a clawback period'] : ['Edit the bonus created for your employee'],
                progress: ['IN_PROGRESS', 'INCOMPLETE'],
                titleIcon: pathname === routePaths.bonus.edit ? <EditComposeIcon size={'2xlarge'} marginTop={'spacing.2'} marginRight={'spacing.1'} color="feedback.icon.notice.intense" /> : <FilePlusIcon size={'2xlarge'} marginTop={'spacing.2'} marginRight={'spacing.1'} color="feedback.icon.notice.intense" />
            };
        case 2:
            return {
                title: pathname === routePaths.bonus.edit ? 'Update Clawback Rules' : 'Add Clawback Rules',
                description: [
                    'Add a clawback rule if you want to recover the paid bonus if the employee leaves before a certain period.',
                    'The paid bonus will be recovered during full and final settlement'
                ],
                progress: ['COMPLETED', 'IN_PROGRESS'],
                titleIcon: <CalendarIcon size={'2xlarge'} marginTop={'spacing.2'} marginRight={'spacing.1'} color="feedback.icon.positive.intense" />
            };
        default:
            return {
                title: pathname === routePaths.bonus.edit ? 'Manage Bonus' : 'Create New Bonus',
                description: pathname === routePaths.bonus.create ? ['Create, manage and disburse bonuses to your employees'] : ['Edit the bonus created for your employee'],
                progress: ['IN_PROGRESS', 'INCOMPLETE'],
                titleIcon: pathname === routePaths.bonus.edit ? <EditComposeIcon size={'2xlarge'} marginTop={'spacing.2'} marginRight={'spacing.1'} color="feedback.icon.notice.intense" /> : <FilePlusIcon size={'2xlarge'} marginTop={'spacing.2'} marginRight={'spacing.1'} color="feedback.icon.notice.intense" />
            };
    }
};

export const updateFormProps = (dataArray?: BonusDataArraySchemaContract): FormProps => {

    const data = dataArray?.data[0];

    if (data) {
        return {
            organization_bonus_setting_id: data.organization_bonus_setting_id,
            amount: data.amount,
            source: data.source,
            payout_month: data.payout_month,
            tds_recovery_option: data.tds_recovery_option,
            part_of_ctc: data.part_of_ctc,
            clawback_applicable: data.clawback_applicable,
            clawback_period: data.clawback_period,
            remarks: data.remarks ?? '',
        };
    }
    else {
        return getDefaultFormProps();
    }
}

export const useShouldFetchUserData = (userId: string | null): boolean => {
    const loggedInPeopleId = useSelector(loggedInUserSelectors.peopleId);

    return userId ? parseInt(userId) !== loggedInPeopleId : false;
};

export const handleChange = (e: { name?: string; value?: string | number }, setFormProps: (value: React.SetStateAction<FormProps>) => void) => {
    const { name, value } = e;
    setFormProps((prev) => ({ ...prev, [name || '']: value }));
};

export const handleAmountChange = (amount: string, setFormProps: (value: React.SetStateAction<FormProps>) => void) => {
    setFormProps((prev) => ({ ...prev, amount: getNumberFromIndianCurrencyString(amount) }));
};

export const handleClawbackPeriodChange = (value: string, setFormProps: (value: React.SetStateAction<FormProps>) => void) => {

    if(Number(value) || Number(value) === 0){
        setFormProps((prev) => ({ ...prev, clawback_period: value ? parseInt(value) : null }));
    }

};

export const handleDateChange = (date: Date | null, setFormProps: (value: React.SetStateAction<FormProps>) => void) => {
    if (date) {
        setFormProps((prev) => ({ ...prev, payout_month: date.toUTCString() }));
    }
};

export const handleBooleanChange = (e: { name?: string; value?: boolean | string }, setFormProps: (value: React.SetStateAction<FormProps>) => void) => {
    const { name, value } = e;
    setFormProps((prev) => ({ ...prev, [name || '']: (value === 'true' || value === true) }));
};

export const validateFormVal = ( value : 'organization_bonus_setting_id' | 'amount' | 'clawback_period', formProps : FormProps, setValidationStates? : (value: React.SetStateAction<ValidationStates>) => void ) => {
    
    if(!formProps[value]){
        setValidationStates && setValidationStates((prev) => ({ ...prev, [value]: 'error' }));
        return false
    }
    return true

}

export const canEdit = (permission : boolean, bonusData : BonusDataArraySchemaContract['data'][0]) => {

    if(!permission){
        return false
    }

    switch(bonusData.status?.toString()){

        case bonusStatus.BONUS_STATUS_CLAWBACK_APPLIED:
            return false;
        
        case bonusStatus.BONUS_STATUS_PENDING:
        case bonusStatus.BONUS_STATUS_PAID:
            return true;

    }

}

export const canDelete = (permission : boolean, bonusData : BonusDataArraySchemaContract['data'][0]) => {

    if(!permission){
        return false
    }

    switch(bonusData.status?.toString()){

                
        case bonusStatus.BONUS_STATUS_PENDING:
            return true;

        case bonusStatus.BONUS_STATUS_PAID:
            if(bonusData.source === bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL){
                return false;
            }
            else{
                return true;
            }

        case bonusStatus.BONUS_STATUS_CLAWBACK_APPLIED:
            return false;

    }

}