import React, { ReactElement } from "react";
import styles from './../index.module.scss';
import MainCase from "components/ui/MainCase";

interface Props {
    children: React.ReactNode;
  }

function ZaggleAdminCenteredMainCase({children}:Props): ReactElement {
    return <MainCase type="fullWidth"><div className={`${styles['centered-body']}`}>{children}</div></MainCase>;
}

export default ZaggleAdminCenteredMainCase;