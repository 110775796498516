import React, { useState } from 'react';
import { Box, Checkbox, Heading } from '@razorpay/blade/components';
import { AttendanceRecord, AttendanceResponse } from 'schemas/Attendance';
import { useAttendanceData } from 'components/Attendance/hooks/useAttendanceData';
import { ATTENDANCE_STATUS } from './constants';
import { useSelector } from 'react-redux';
import { peopleId } from 'reducers/loggedInUser/selectors';
import { ApproveRequestsButton } from './ApproveRequestsButton';
import { RejectRequestsButton } from './RejectRequestsButton';
import { DeleteRequestsButton } from './DeleteRequestsButton';
import { useTodaysAttendanceData } from './hooks/useTodaysAttendanceData';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

interface AttendanceRecordWithRequiredId extends Omit<AttendanceRecord, 'id'> {
  id: number;
}

interface AttendanceRecordWithDescription extends AttendanceRecordWithRequiredId {
  description: string;
}

function transformAttendanceRecords({
  data,
  loggedInUserId,
  employeeName,
}: {
  data: AttendanceResponse;
  loggedInUserId: number | undefined;
  employeeName: string;
}): AttendanceRecordWithDescription[] {
  const attendanceRecords = data.filter(isValidRecord).map((record) => {
    let personText = 'You have';

    if (loggedInUserId && record.peopleId !== loggedInUserId) {
      personText = `${employeeName ? `${employeeName} has` : 'They have'}`;
    }
    let description = `${personText} requested `;

    if (record.requestedStatus) {
      if (record.requestedStatus === ATTENDANCE_STATUS.STATUS_DELETION_REQUESTED) {
        description += 'deletion of attendance';
      } else {
        description += `status ${record.requestedStatusDescription}, `;
      }
    }

    if (record.requestedCheckIn) {
      description += `check in at ${record.requestedCheckIn.substring(0, 5)}, `;
    }

    if (record.requestedCheckOut) {
      description += `check out at ${record.requestedCheckOut.substring(0, 5)}, `;
    }

    description = description.replace(/, $/, '');

    if (record.date) {
      const dateStr = record.date.date.substring(0, 10); // Extract YYYY-MM-DD
      const formattedDate = getFormattedDateValue({
        date: dateStr,
        formatString: dateFormats.dateMonthWeekday,
      });
      description += ` on ${formattedDate}`;
    }

    return { ...record, description };
  });

  return attendanceRecords;
}

function isValidRecord(record: AttendanceRecord): record is AttendanceRecordWithRequiredId {
  return (
    !!record.id &&
    (!!record.requestedCheckIn || !!record.requestedCheckOut || !!record.requestedStatus)
  );
}

export const OpenRequests: React.FC = () => {
  const [selectedRequests, setSelectedRequests] = useState<number[]>([]);
  const loggedInUserId = useSelector(peopleId);

  const { data: todayData } = useTodaysAttendanceData();
  const {
    data: attendanceData,
    isLoading,
    userId,
  } = useAttendanceData({
    select: (data) =>
      transformAttendanceRecords({
        data,
        loggedInUserId,
        employeeName: todayData?.employee_name || '',
      }),
    onSuccess: () => {
      setSelectedRequests([]);
    },
  });

  if (isLoading || !loggedInUserId) {
    // loading is handled by the calendar component
    // This component is shown conditionally, so showing a loader to determine if it needs to be renered does not make sense
    return null;
  }

  if (!attendanceData || attendanceData.length === 0) {
    return null;
  }

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked && attendanceData) {
      setSelectedRequests(attendanceData.map((request) => request.id));
    } else {
      setSelectedRequests([]);
    }
  };

  const handleSelectRequest = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRequests((prev) => [...prev, id]);
    } else {
      setSelectedRequests((prev) => prev.filter((requestId) => requestId !== id));
    }
  };

  const isAllSelected = selectedRequests.length === attendanceData.length;

  const handleRequestSuccess = () => {
    setSelectedRequests([]);
  };

  return (
    <Box backgroundColor="surface.background.gray.subtle">
      <Heading as="h2" marginBottom="spacing.4">
        Open Requests
      </Heading>
      <Box backgroundColor="surface.background.gray.moderate" overflow="hidden">
        <Box
          padding="spacing.4"
          backgroundColor="surface.background.gray.intense"
          display="flex"
          alignItems="center">
          <Checkbox
            isChecked={isAllSelected}
            onChange={({ isChecked }) => handleSelectAll(isChecked)}>
            Select all
          </Checkbox>
        </Box>
        {attendanceData.map((request) => (
          <Box
            key={request.id}
            padding="spacing.4"
            borderBottomColor="surface.border.gray.normal"
            borderBottomWidth="thin">
            <Checkbox
              isChecked={selectedRequests.includes(request.id)}
              onChange={({ isChecked }) => handleSelectRequest(request.id, isChecked)}>
              {request.description}
              {!!request.remarks && (
                <>
                  <br />
                  Remarks: {request.remarks}
                </>
              )}
            </Checkbox>
          </Box>
        ))}
      </Box>
      <Box display="flex" gap="spacing.4" marginTop="spacing.5">
        {userId && todayData?.can_approve_requests && (
          <>
            <ApproveRequestsButton
              selectedRequests={selectedRequests}
              onSuccess={handleRequestSuccess}
              userId={userId}
            />
            <RejectRequestsButton
              selectedRequests={selectedRequests}
              onSuccess={handleRequestSuccess}
              userId={userId}
            />
          </>
        )}
        {loggedInUserId.toString() === userId && (
          <DeleteRequestsButton
            selectedRequests={selectedRequests}
            onSuccess={handleRequestSuccess}
          />
        )}
      </Box>
    </Box>
  );
};
