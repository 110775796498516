import React, { ReactElement } from 'react';
import Breadcrumb from 'components/ui/Breadcrumb';
import MainCase from 'components/ui/MainCase';
import PageWrapper from 'components/ui/PageWrapper';
import OnboardingStatus, { OnboardingItem } from 'components/ui/OnboardingStatus';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import RestrictedRoute from 'components/Routes/RestrictedRoute';
import permissions from 'constants/permissions';
import ZohoEmployeeFieldsSetup from './ZohoEmployeeFieldsSetup';
import ZohoEmployeeTypeMappingSetup from './ZohoEmployeeTypeMappingSetup';
import { ZohoSettingsContract } from 'schemas/ZohoSettings';
import withActivationQueryWrapper from '../withActivationQueryWrapper';
import ZohoDismissalStatusMappingSetup from './ZohoDismissalStatusMappingSetup';

const STEP_EMPLOYEE_FIELDS = 1;
const STEP_EMPLOYEE_TYPE = 2;
const STEP_EMPLOYEE_DISMISSAL = 3;

const stepMap: { [key: number]: { name: string; route: string } } = {
  [STEP_EMPLOYEE_FIELDS]: {
    name: 'Choose fields to be synced',
    route: routePaths.settings.zoho.config.employeeFieldsSetup,
  },
  [STEP_EMPLOYEE_TYPE]: {
    name: 'Employee type mapping',
    route: routePaths.settings.zoho.config.employeeTypeSetup,
  },
  [STEP_EMPLOYEE_DISMISSAL]: {
    name: 'Employee status mapping',
    route: routePaths.settings.zoho.config.employeeDismissalTypeSetup,
  },
};

const routeToStepMap: { [key: string]: number } = {
  [routePaths.settings.zoho.config.employeeFieldsSetup]: STEP_EMPLOYEE_FIELDS,
  [routePaths.settings.zoho.config.employeeTypeSetup]: STEP_EMPLOYEE_FIELDS,
  [routePaths.settings.zoho.config.employeeTypeSetup]: STEP_EMPLOYEE_TYPE,
  [routePaths.settings.zoho.config.employeeDismissalTypeSetup]: STEP_EMPLOYEE_DISMISSAL,
};

interface Props {
  zohoData: ZohoSettingsContract;
}

function ZohoConfig({ zohoData }: Props) {
  const location = useLocation();
  const history = useHistory();
  const currentStep = routeToStepMap[location.pathname] || STEP_EMPLOYEE_FIELDS;
  const onStepChange = (value: number) => {
    history.push(stepMap[value].route);
  };

  if (!zohoData.enabled) {
    return <Redirect to={routePaths.settings.zoho.introduction} />;
  } else if (zohoData.is_user_data_sync_enabled) {
    return <Redirect to={routePaths.settings.zoho.home} />;
  }

  return (
    <div className="lg:pt-10 pb-8 max-w-screen-xl mx-auto">
      <OnboardingStatus
        className="mb-16 mx-auto"
        title="Zoho integration setup"
        value={currentStep}
        onChange={onStepChange}>
        <OnboardingItem
          title={stepMap[STEP_EMPLOYEE_FIELDS].name}
          value={STEP_EMPLOYEE_FIELDS}
          status={'completed'}
        />
        <OnboardingItem
          title={stepMap[STEP_EMPLOYEE_TYPE].name}
          value={STEP_EMPLOYEE_TYPE}
          status={zohoData.employee_types_full_time.length > 0 ? 'completed' : undefined}
        />
        <OnboardingItem
          disabled={zohoData.employee_types_full_time.length === 0}
          title={stepMap[STEP_EMPLOYEE_DISMISSAL].name}
          value={STEP_EMPLOYEE_DISMISSAL}
          status={zohoData.employee_active_statuses.length > 0 ? 'completed' : undefined}
        />
      </OnboardingStatus>
      <Switch>
        <RestrictedRoute
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          path={[
            routePaths.settings.zoho.config.employeeFieldsSetup,
            routePaths.settings.zoho.config.root,
          ]}
          component={ZohoEmployeeFieldsSetup}
          exact
        />
        <RestrictedRoute
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          path={routePaths.settings.zoho.config.employeeTypeSetup}
          component={ZohoEmployeeTypeMappingSetup}
          exact
        />
        <RestrictedRoute
          allowedFeatures={['zoho-integration']}
          allowedPermissions={[permissions.MANAGE_HRMS_INTEGRATION]}
          path={routePaths.settings.zoho.config.employeeDismissalTypeSetup}
          component={ZohoDismissalStatusMappingSetup}
          exact
        />
        <Route>
          <Redirect to={routePaths.unknown} />
        </Route>
      </Switch>
    </div>
  );
}

function ZohoConfigWithPageWrapper({ zohoData }: Props): ReactElement {
  return (
    <PageWrapper>
      <Breadcrumb
        name="Zoho People"
        urlMaps={{
          Integrations: '/integrations',
        }}
      />
      <MainCase type="none" className="w-full lg:pt-8">
        <ZohoConfig zohoData={zohoData} />
      </MainCase>
    </PageWrapper>
  );
}

export default withActivationQueryWrapper(ZohoConfigWithPageWrapper);
