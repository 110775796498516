import React from 'react';
import { IInsurance } from '../../../../reducers/insurance';
import { getStaticMediaUrl } from '../../../../utils/Urls';
import { Table } from '../../../ui/Table';
import { planBenefits, planNames, standardBenefits } from '../../insuranceData';
import processStepDone from '../../../../assets/processStepDone.svg';
import processStepInProgress from '../../../../assets/processStepInProgress.svg';
import processStepPending from '../../../../assets/processStepPending.svg';
import line from '../../../../assets/line.svg';
import dottedLine from '../../../../assets/dottedLine.svg';
import { IInsuranceEmployee } from '../../../../reducers/insuranceEmployee';
import { Accordion, AccordionItem } from '../../../ui/Accordion';
import { ContentContainer } from '../../../ui/ContentContainer';
import { RightSidebar } from './RightSidebar';
function isValidPlanId(planId: number): planId is keyof typeof planBenefits {
  return !!(planBenefits as Record<number, any>)[planId];
}

function getAddOns(insuranceEmployee: IInsuranceEmployee): string {
  if (insuranceEmployee.data?.planDetails?.withParentsOrInLaws && insuranceEmployee.data?.planDetails?.withChildrenAndSpouse) {
    return 'Spouse, children, Parents & Inlaws';
  }
  if (insuranceEmployee.data?.planDetails?.withParentsOrInLaws) {
    return 'Parents';
  }
  if (insuranceEmployee.data?.planDetails?.withChildrenAndSpouse) {
    return 'Spouse & children';
  }
  return 'No addons';
}

function FAQ() {
  return (
    <Accordion className="mx-5">
      <AccordionItem heading="How is XPayroll's Group health insurance better than other market offerings?">
        <li className="">
          Group health insurance is structured as a group cover. Under Group health insurance, you
          get the following benefit:
        </li>
        <br />
        <ol className="list-decimal ml-4">
          <li className="">
            Affordable: The premium payable will be lower than retail health insurance since the
            underwriter is pricing the risk for a large group of individuals. There is no broker or
            agent involved and this enables you to save further on commissions that wold otherwise
            be payable.
          </li>
          <li className="">
            Comprehensive yet customised: The solution is tailor-made for XPayroll clients and our
            client demographics enabled us to craft a well-priced health cover with ICICI Lombard
          </li>
          <li className="">
            Convenient: No documentation and medical check-up requirement and instant policy
            enrolment mean that you can enrol in minutes.
          </li>
          <li className="">
            XPayroll (Razorpay) is the master policyholder and, we've worked with our Insurer, ICICI
            Lombard to bring the same transparency, convenience, simplicity, and affordability that
            you have experienced with XPayroll and Razorpay products so far
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem heading="What is the Waiting Period for the 3 plans?">
        Zero-day on injuries & accidents and 30 days on all other treatments. The 30 day waiting
        period is waived off in the Essential and Premium Plans.
      </AccordionItem>
      <AccordionItem heading="Do I need to buy Group insurance for my employees?">
        On April 15th 2020, the Government of India, as part of order №40–3/2020-DM-I (A), issued as
        part of the consolidated revised guidelines by the ministry of home affairs, made it
        mandatory for all employers, who resume functioning post-COVID-19 lockdown in the country,
        to provide medical insurance to their employees. Not just that, insurance protection is the
        most preferred benefit by employees. That’s because it takes the challenge of finding the
        right policy, helps them in managing healthcare costs and ensures that they get easy claims.
        So, when you provide health insurance, your employees feel cared for.
      </AccordionItem>
      <AccordionItem heading="Is there any minimum requirement to take group insurance?">
        With XPayroll, we’ve worked with the Insurers to remove the minimum cap of 20 employees to
        purchase Group Health Insurance and have extended this benefit to organizations as small as
        2 employees. The Standard and Premium plan has a minimum cap of 5 employees.
      </AccordionItem>
      <AccordionItem heading="Can we opt to cover family members?">
        <p>Yes, you have three policy design options:</p>
        <ol className="list-decimal mt-1 ml-6">
          <li>E plan: Covers employees only</li>
          <li>ESC plan: Covers employees, spouse, and upto 4 dependent children</li>
          <li>
            ESCP plan: Covers employees, spouse, upto 4 dependent children, and 2 parents/in-laws
            Other relatives, like siblings or uncles, cannot be covered.
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem heading="Can I take policy for specific employees?">
        No, group covers don't allow any selection. You will have to cover ALL your employees who
        are present within XPayroll with no exception. Read more on why selection is not allowed.
      </AccordionItem>
      <AccordionItem heading="Can I take policy for specific family members?">
        No, group cover doesn't allow any selection. If you choose to take the ESC-plan, you will
        have to cover spouses of all married employees and all dependent children. If you choose to
        take the ESCP-plan, you will have to cover spouses of all married employees, all dependent
        children, and parents of all employees.
      </AccordionItem>
      <AccordionItem heading="Can I add members after the initial setup?">
        Yes, you can add new employees, add new dependents (at the time of marriage or childbirth).
        Additions are done automatically whenever you add an employee on XPayroll For additions, you
        would get charged based on a 1-year basis for employees. E.g., if the annual premium for a
        35-yr old member is ₹2000, and you add a member 6 months later, you would pay ₹2000 for that
        member.
      </AccordionItem>
      <AccordionItem heading="What if an employee leaves the organization after I’ve purchased Insurance?">
        When an employee leaves the organization, you get a pro-rated refund basis the time
        remaining in the 1-year policy period. For eg. if the annual premium for a 30-yr old member
        is Rs. 2000, and if they leave after 6 months, then you get refunded Rs. 1000 .Note - In
        case there are insurance claims made by the member there are no refunds.
      </AccordionItem>
      <AccordionItem heading="What is the timeline for the refund to come?">
        The checking of whether a claim is made is dependent on the Insurance partner, a refund can
        take upto 10 working days. The refund amount will be added back to your XPayroll account and
        you will see an entry in the Ledger for this.
      </AccordionItem>
      <AccordionItem heading="How do claims works?">
        Your team members and employees would get access to our partner, Plum’s app to understand
        their insurance cover, see what's covered and what's not covered. They can see cashless
        hospitals nearby. They would also see the step by step claims process on the app itself. The
        insurance covers both cashless claims and reimbursement claims.
      </AccordionItem>
    </Accordion>
  );
}

export default function ({
  insurance,
  insuranceEmployee,
}: {
  insurance: IInsurance;
  insuranceEmployee: IInsuranceEmployee;
}) {
  if (!insuranceEmployee.data?.planDetails) {
    return <div> Looks like there is no insurance. Please contact administrator. </div>;
  }
  if (!insuranceEmployee.data?.planDetails?.planId || !isValidPlanId(insuranceEmployee.data?.planDetails?.planId)) {
    return (
      <div>
        {' '}
        Looks like there is no plan selected for your insurance. Please contact administrator.{' '}
      </div>
    );
  }
  if (!insuranceEmployee.data) {
    return <></>;
  }
  const benefits = planBenefits[insuranceEmployee.data?.planDetails?.planId];
  return (
    <ContentContainer
      showSidebar={true}
      rightSidebar={<RightSidebar insuranceEmployee={insuranceEmployee} />}
    >
      <div className="w-full space-y-20 mt-10">
        <div className="text-7xl font-heading">Insurance</div>
        <div className="space-y-6">
          <div className="font-heading text-5xl">Covered members</div>
          <Table className="w-full">
            <tr>
              <th className="">Name</th>
              <th className="">Relation</th>
              <th className="">Date of birth</th>
              <th className="">Gender</th>
              <th className="">Card</th>
              <th className="">Covered Until</th>
            </tr>
            <tr>
              <td className="">{insuranceEmployee.data ? insuranceEmployee.data.name : 'N/A'}</td>
              <td className="">Employee</td>
              <td className="">
                {insuranceEmployee.data && insuranceEmployee.data.dob
                  ? new Date(insuranceEmployee.data.dob).toLocaleDateString('en-US', {
                      timeZone: 'UTC',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })
                  : 'N/A'}
              </td>
              <td className="">
                {insuranceEmployee.data && insuranceEmployee.data.gender === 'm'
                  ? 'Male'
                  : insuranceEmployee.data && insuranceEmployee.data.gender === 'f'
                  ? 'Female'
                  : 'Other'}
              </td>
              <td className="">
                {insuranceEmployee.data?.isSyncedWithVendor &&
                insuranceEmployee.data?.vendorCurrentInsuranceDetails?.healthId ? (
                  <a
                    target="_blank"
                    href={`/v2/user-organization/${insuranceEmployee.data.id}/insurance/health-card`}
                  >
                    Download
                  </a>
                ) : (
                  <span className="text-secondary">Not available yet</span>
                )}
              </td>
              <td className="">
                {insuranceEmployee.data && insuranceEmployee.data.endDate
                  ? new Date(insuranceEmployee.data.endDate).toLocaleDateString('en-US', {
                      timeZone: 'UTC',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })
                  : 'N/A'}
              </td>
            </tr>
            {Object.entries(insuranceEmployee.data?.dependents).map(([index, dependent]) => {
              switch (dependent.relation) {
                case 'spouse':
                case 'daughter':
                case 'son':
                  if (!insuranceEmployee.data?.planDetails?.withChildrenAndSpouse || !dependent.isCurrentlyCovered) {
                    return <></>;
                  }
                  break;
                case 'father':
                case 'fatherInLaw':
                case 'mother':
                case 'motherInLaw':
                  if (!insuranceEmployee.data?.planDetails?.withParentsOrInLaws || !dependent.isCurrentlyCovered) {
                    return <></>;
                  }
                  break;
              }
              return (
                <tr>
                  <td className="">{dependent.name}</td>
                  <td className="">
                    {(() => {
                      const relation = dependent.relation.replace(/([A-Z])/g, ' $1');
                      return relation.charAt(0).toUpperCase() + relation.slice(1);
                    })()}
                  </td>
                  <td className="">
                    {new Date(dependent.dateOfBirth).toLocaleDateString('en-US', {
                      timeZone: 'UTC',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </td>
                  <td className="">
                    {dependent.gender.charAt(0).toUpperCase() + dependent.gender.slice(1)}
                  </td>
                  <td className="">
                    {insuranceEmployee.data?.isSyncedWithVendor &&
                    insuranceEmployee.data?.vendorCurrentInsuranceDetails?.healthId ? (
                      <a
                        target="_blank"
                        href={`/v2/dependent/${dependent.id}/insurance/health-card`}
                      >
                        Download
                      </a>
                    ) : (
                      <span className="text-secondary">Not available yet</span>
                    )}
                  </td>
                  <td className="">
                    {dependent.endDate
                      ? new Date(dependent.endDate).toLocaleDateString('en-US', {
                          timeZone: 'UTC',
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })
                      : 'N/A'}
                  </td>
                </tr>
              );
            })}
          </Table>
        </div>

        <div className="space-y-6">
          <div className="font-heading text-5xl">
            {planNames[insuranceEmployee.data?.planDetails.planId]} plan benefits
          </div>
          <div className="flex flex-col xl:flex-row xl:space-x-5">
            <Table className="w-full xl:w-1/2">
              <tr>
                <td className="">Sum insured</td>
                <td className="font-bold">₹{insuranceEmployee.data?.planDetails.sumInsured?.toLocaleString()}</td>
              </tr>
              {Object.entries(benefits).map(([benefit, benefitValue]) => (
                <tr>
                  <td className="">{benefit}</td>
                  <td className="font-bold">
                    {typeof benefitValue === 'string' ? benefitValue : benefitValue['description']}
                  </td>
                </tr>
              ))}
              {Object.entries(standardBenefits(insuranceEmployee.data?.planDetails?.sumInsured)).map(
                ([benefit, benefitValue]) => (
                  <tr className="xl:hidden">
                    <td className="">{benefit}</td>
                    <td className="font-bold">{benefitValue}</td>
                  </tr>
                ),
              )}
            </Table>
            <Table className="w-full xl:w-1/2">
              {Object.entries(standardBenefits(insuranceEmployee.data?.planDetails?.sumInsured)).map(
                ([benefit, benefitValue]) => (
                  <tr className="hidden xl:table-row">
                    <td className="">{benefit}</td>
                    <td className="font-bold">
                      {benefit === 'Optional add-ons' ? getAddOns(insuranceEmployee) : benefitValue}
                    </td>
                  </tr>
                ),
              )}
            </Table>
          </div>
        </div>
        {/* <div className="space-y-6">
             <div className="font-heading text-5xl">FAQs</div>
             <div className="-mx-5">
                <FAQ/>
             </div>
         </div> */}
      </div>
    </ContentContainer>
  );
}
