import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect, Link, useParams } from 'react-router-dom';
import {
  fetchInsurance,
  IInsurance,
  IUpdateInsurance,
  IUpdateInsurancePayload,
  startInsurance,
  updateInsurance,
  updateInsuranceAndRedirect,
} from '../../../reducers/insurance';
import { fetchInsuranceEstimate, IInsuranceEstimate } from '../../../reducers/insuranceEstimate';
import { navigateAfter } from '../../../reducers/router';
import { getStaticMediaUrl } from '../../../utils/Urls';
import useAppAction from '../../../utils/useAppDispatch';
import useReduxSelector from '../../../utils/useReduxSelector';
import { PrimaryButton } from '../../ui/Button';
import { getConditionsNotMetToGetExactEstimate } from './computeMinRequirements';
import { InsuranceEstimate } from './InsuranceEstimation';
import InsuranceFinalize from './InsuranceFinalize';
import { InsuranceIntro } from './InsuranceIntro';
import { InsurancePlan } from './InsurancePlan';
import { InsuranceSelectionTab } from './InsuranceSelectionTab';
import successTick from '../../../assets/successTick.svg';
import { fetchInsuranceEmployee } from '../../../reducers/insuranceEmployee';
import EmployeeDetails from '../EmployeeInsurance/EmployeeDetails';
import InsurancePostPurchase from './InsurancePostPurchase';
import { routePaths } from 'components/Routes/data';
import InsuranceEndorsements from './InsuranceEndorsements';
import Breadcrumb from '../../ui/Breadcrumb';
import { BackButton } from '../../ui/BackButton';
import { loggedInUser, ILoggedInUser } from '../../../reducers/loggedInUser';
import { ContentContainer } from '../../ui/ContentContainer';
import { InsuranceRenewal } from './InsuranceRenewal';
import { InsuranceOrganizationRenewal } from './InsuranceOrganizationAndEmployeeRenewal';

export function ShowRequestSubmitted() {
  return (
    <div className="p-5 py-16 font-bold text-4xl text-center">
      <div className="py-10">
        <img className="mx-auto" src={getStaticMediaUrl(successTick)} />
      </div>
      <div className="text-white text-6xl font-bold font-heading py-4">Request submitted</div>
      <div className="text-2xl font-bold mt-8">Thank for your request.</div>
      <div className="text-2xl font-bold">
        We will reach out to you shortly and help you secure your team.
      </div>
      <div className="my-4">
        <PrimaryButton
          onClick={() => {
            window.location.href = '/dashboard';
          }}
        >
          BACK TO DASHBOARD
        </PrimaryButton>
      </div>
    </div>
  );
}

function computeRouteMap(
  insurance: IInsurance,
  insuranceEstimate: IInsuranceEstimate,
  loggedInUser: ILoggedInUser,
) {
  let routeMap: Record<string, string> = {
    [routePaths.insurance.admin.introduction]: routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.plan]: routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.estimate]: routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.finalize]: routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.employee]: routePaths.insurance.admin.employee,
    [routePaths.insurance.admin.details]: routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.renewal] : routePaths.insurance.admin.introduction,
    [routePaths.insurance.admin.newEndorsements]: routePaths.insurance.admin.newEndorsements,
    [routePaths.insurance.admin.organizationAndEmployeeRenewal]:routePaths.insurance.admin.organizationAndEmployeeRenewal,
  };

  const conditionsNotMet = getConditionsNotMetToGetExactEstimate(insuranceEstimate, loggedInUser);
  if(insurance.data?.insuranceUnderRenewal)
  {
    for (let path in routeMap)
    {
      routeMap[path] = routePaths.insurance.admin.renewal;
    }
    routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.employee;
    routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal]= routePaths.insurance.admin.organizationAndEmployeeRenewal;
  }
  else{
    switch (insurance.data?.status) {
      case 'PURCHASED':
        for (let path in routeMap) {
          routeMap[path] = routePaths.insurance.admin.details;
        }
        routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.employee;
        routeMap[routePaths.insurance.admin.newEndorsements] = routePaths.insurance.admin.newEndorsements;
        routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal]= routePaths.insurance.admin.organizationAndEmployeeRenewal;
        break;

      case 'PURCHASE_IN_PROGRESS':
        for (let path in routeMap) {
          routeMap[path] = routePaths.insurance.admin.details;
        }
        routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal] = routePaths.insurance.admin.organizationAndEmployeeRenewal;
        routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.employee;
        routeMap[routePaths.insurance.admin.newEndorsements] = routePaths.insurance.admin.newEndorsements;
        break;

      case 'DATA_COLLECTION_IN_PROGRESS':
        for (let path in routeMap) {
          routeMap[path] = routePaths.insurance.admin.finalize;
        }
        routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.employee;
        routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal]= routePaths.insurance.admin.organizationAndEmployeeRenewal;
        break;

      case 'ESTIMATION_IN_PROGRESS':
        if (insurance.data?.id && insurance.data?.planId && conditionsNotMet.length === 0) {
          routeMap[routePaths.insurance.admin.plan] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.estimate] = routePaths.insurance.admin.estimate;
          routeMap[routePaths.insurance.admin.finalize] = routePaths.insurance.admin.finalize;
          routeMap[routePaths.insurance.admin.details] = routePaths.insurance.admin.finalize;
          routeMap[routePaths.insurance.admin.newEndorsements] = routePaths.insurance.admin.finalize;
        }

        if (insurance.data?.id && insurance.data?.planId && conditionsNotMet.length !== 0) {
          routeMap[routePaths.insurance.admin.plan] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.estimate] = routePaths.insurance.admin.estimate;
          routeMap[routePaths.insurance.admin.finalize] = routePaths.insurance.admin.estimate;
          routeMap[routePaths.insurance.admin.details] = routePaths.insurance.admin.estimate;
          routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.estimate;
          routeMap[routePaths.insurance.admin.newEndorsements] = routePaths.insurance.admin.estimate;
        }

        if (insurance.data?.id && !insurance.data?.planId) {
          routeMap[routePaths.insurance.admin.plan] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.estimate] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.finalize] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.details] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.employee] = routePaths.insurance.admin.plan;
          routeMap[routePaths.insurance.admin.newEndorsements] = routePaths.insurance.admin.plan;
        }

        break;
    }
  }

  return routeMap;
}

export function InsuranceAdmin({
  insurance,
  startInsuranceAction,
  updateInsuranceAction,
  updateInsuranceAndRedirectAction,
  insuranceEstimate,
  loggedInUser,
}: {
  insurance: IInsurance;
  startInsuranceAction: typeof startInsurance;
  updateInsuranceAction: typeof updateInsurance;
  updateInsuranceAndRedirectAction: typeof updateInsuranceAndRedirect;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  if (!insurance.data) {
    return <></>;
  }

  if (insurance.data.isBlocked) {
    return (
      <div className="text-center mt-20">
        Sorry, currently we don't have any insurance offer for you. Please contact admin for further
        queries.
      </div>
    );
  }

  if (insurance.data && insurance.data.registeredInterestAt !== null) {
    return <ShowRequestSubmitted />;
  }

  const routeMap = computeRouteMap(insurance, insuranceEstimate, loggedInUser);

  const routesToDisable = Object.keys(routeMap).filter((key) => {
    switch (routeMap[key]) {
      case routePaths.insurance.admin.details:
        if (key === routePaths.insurance.admin.details) {
          return false;
        }
      case routePaths.insurance.admin.finalize:
        if (key === routePaths.insurance.admin.finalize) {
          return false;
        }
      case routePaths.insurance.admin.employee:
        if (key === routePaths.insurance.admin.employee) {
          return false;
        }
      case routePaths.insurance.admin.estimate:
        if (key === routePaths.insurance.admin.estimate) {
          return false;
        }
      case routePaths.insurance.admin.plan:
        if (key === routePaths.insurance.admin.plan) {
          return false;
        }
      case routePaths.insurance.admin.renewal:
        if(key === routePaths.insurance.admin.renewal){
          return false;
        }
      case routePaths.insurance.admin.organizationAndEmployeeRenewal:
        if(key === routePaths.insurance.admin.organizationAndEmployeeRenewal){
          return false;
        }
      case routePaths.insurance.admin.introduction:
        if (key === routePaths.insurance.admin.introduction) {
          return false;
        }
    }
    return true;
  });

  const planSelection = (
    <InsuranceSelectionTab routesNotAllowed={routesToDisable} className="m-2" />
  );

  return (
    <div className="flex flex-col justify-start pt-10">
      <Switch>
        <Route path={routePaths.insurance.admin.introduction}>
          {routeMap[routePaths.insurance.admin.introduction] ===
          routePaths.insurance.admin.introduction ? (
            <InsuranceIntro insurance={insurance} startInsuranceAction={startInsuranceAction} />
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.introduction]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.plan}>
          {routeMap[routePaths.insurance.admin.plan] === routePaths.insurance.admin.plan ||
          routeMap[routePaths.insurance.admin.plan] === routePaths.insurance.admin.estimate ||
          routeMap[routePaths.insurance.admin.plan] === routePaths.insurance.admin.finalize ? (
            <>
              {planSelection}
              <InsurancePlan
                insuranceEstimate={insuranceEstimate}
                insurance={insurance}
                updateInsuranceAndRedirectAction={updateInsuranceAndRedirectAction}
                loggedInUser={loggedInUser}
              />
            </>
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.plan]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.estimate}>
          {routeMap[routePaths.insurance.admin.estimate] === routePaths.insurance.admin.estimate ||
          routeMap[routePaths.insurance.admin.estimate] === routePaths.insurance.admin.finalize ? (
            <>
              {planSelection}
              <InsuranceEstimate
                insurance={insurance}
                updateInsuranceAction={updateInsuranceAction}
                insuranceEstimate={insuranceEstimate}
                loggedInUser={loggedInUser}
              />
            </>
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.plan]} />
          )}
        </Route>

        <Route path={routePaths.insurance.admin.employee}>
          {routeMap[routePaths.insurance.admin.employee] === routePaths.insurance.admin.employee ? (
            <EmployeeDetailsContainer insurance={insurance} />
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.employee]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.finalize}>
          {routeMap[routePaths.insurance.admin.finalize] === routePaths.insurance.admin.finalize ? (
            <>
              {planSelection}
              <InsuranceFinalize
                insurance={insurance}
                updateInsuranceAction={updateInsuranceAction}
                insuranceEstimate={insuranceEstimate}
                loggedInUser={loggedInUser}
              />
            </>
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.finalize]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.details}>
          {routeMap[routePaths.insurance.admin.details] === routePaths.insurance.admin.details ? (
            <InsurancePostPurchase insurance={insurance} loggedInUser={loggedInUser} />
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.details]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.newEndorsements}>
          {routeMap[routePaths.insurance.admin.newEndorsements] ===
          routePaths.insurance.admin.newEndorsements ? (
            <InsuranceEndorsements insurance={insurance} />
          ) : (
            <Redirect to={routeMap[routePaths.insurance.admin.details]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.renewal}>
          {routeMap[routePaths.insurance.admin.renewal] === routePaths.insurance.admin.renewal ? (
            <InsuranceRenewal insurance={insurance} />
          ):(
            <Redirect to={routeMap[routePaths.insurance.admin.renewal]} />
          )}
        </Route>
        <Route path={routePaths.insurance.admin.organizationAndEmployeeRenewal}>
          {routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal] === routePaths.insurance.admin.organizationAndEmployeeRenewal ? (
            <InsuranceOrganizationRenewal insurance={insurance} />
          ):(
            <Redirect to={routeMap[routePaths.insurance.admin.organizationAndEmployeeRenewal]} />
          )}
        </Route>
        <Redirect to={routePaths.insurance.admin.details} />
      </Switch>
    </div>
  );
}

function EmployeeDetailsContainer({ insurance }: { insurance: IInsurance }) {
  const dispatch = useDispatch();
  const urlParams = useParams<{ employeeId?: string }>();
  const employeeId = urlParams.employeeId ? +urlParams.employeeId : null;
  const insuranceEmployee = useReduxSelector((state) => state.insuranceEmployee);
  useEffect(() => {
    employeeId && dispatch(fetchInsuranceEmployee(employeeId));
    return () => {};
  }, [employeeId]);

  if (!employeeId) {
    return <div className="text-center leading-12"> no employee id.</div>;
  }

  return (
    <ContentContainer showSidebar={false}>
      <BackButton />
      {/* <Breadcrumb name={insuranceEmployee.data?.email} urlMaps={{
                "#/finalize": "Employee details"
            }}/> */}
      <EmployeeDetails isAdmin={true} insurance={insurance} insuranceEmployee={insuranceEmployee} />
    </ContentContainer>
  );
}

export function PostPurchase({ insurance }: { insurance: IInsurance }) {
  if (!insurance.data) {
    return <></>;
  }

  if (insurance.data.status === 'PURCHASE_IN_PROGRESS') {
    return (
      <div className="p-15 text-center font-bold mt-10">
        Thank you for the purchase. Your insurance purchase is in progress.{' '}
      </div>
    );
  }

  return (
    <div className="p-15 text-center font-bold mt-10">
      Your insurance is purchased. Please contact XPayroll team for any required details.
    </div>
  );
}

export default function InsuranceAdminContainer() {
  const dispatch = useDispatch();
  const loggedInUser = useReduxSelector((state) => state.loggedInUser);
  const orgId = loggedInUser.data?.currentOrganization?.id;
  useEffect(() => {
    dispatch(fetchInsurance(orgId ? orgId : null));
  }, [orgId]);

  const insurance = useReduxSelector((state) => state.insurance);

  useEffect(() => {
    dispatch(fetchInsuranceEstimate(insurance.data?.id ? insurance.data.id : null));
  }, [insurance.data]);

  const insuranceEstimate = useReduxSelector((state) => state.insuranceEstimate);

  return (
    <InsuranceAdmin
      insurance={insurance}
      startInsuranceAction={startInsurance}
      updateInsuranceAction={updateInsurance}
      updateInsuranceAndRedirectAction={updateInsuranceAndRedirect}
      insuranceEstimate={insuranceEstimate}
      loggedInUser={loggedInUser}
    />
  );
}
