const permissions = Object.freeze({
  EDIT_COMPANY_DETAILS: 'EDIT_COMPANY_DETAILS', // 1
  DOCUMENTS_TEMPLATE_EDIT: 'DOCUMENTS_TEMPLATE_EDIT', //2
  COMPANY_VIEW_BALANCE: 'COMPANY_VIEW_BALANCE', //3
  EMPLOYEE_ADD: 'EMPLOYEE_ADD', //100,
  EMPLOYEE_DISMISSAL: 'EMPLOYEE_DISMISSAL', //101,
  EMPLOYEE_CHANGE_SALARY: 'EMPLOYEE_CHANGE_SALARY', //102,
  EMPLOYEE_PAST_PAYROLL: 'EMPLOYEE_PAST_PAYROLL', //103,
  EMPLOYEE_BANK_INFORMATION: 'EMPLOYEE_BANK_INFORMATION', //104,
  EMPLOYEE_ASSIGN_USER_ROLE: 'EMPLOYEE_ASSIGN_USER_ROLE', //105,
  EMPLOYEE_DISABLE_LOGIN: 'EMPLOYEE_DISABLE_LOGIN', //106,
  EMPLOYEE_VIEW_PAYSLIPS: 'EMPLOYEE_VIEW_PAYSLIPS', //107,
  EMPLOYEE_DATA_CHANGES_APPROVAL: 'EMPLOYEE_DATA_CHANGES_APPROVAL', //108,
  EMPLOYEE_VIEW_PROFILE: 'EMPLOYEE_VIEW_PROFILE', //109,
  EMPLOYEE_UPDATE_TAX_DEDUCTIONS: 'EMPLOYEE_UPDATE_TAX_DEDUCTIONS', //110,
  EMPLOYEE_STOP_SALARY: 'EMPLOYEE_STOP_SALARY', //111,
  EMPLOYEE_EDIT_LEAVES_ATTENDANCE: 'EMPLOYEE_EDIT_LEAVES_ATTENDANCE', //112,
  EMPLOYEE_CONTRACTOR_PAYMENTS: 'EMPLOYEE_CONTRACTOR_PAYMENTS', //113,
  EMPLOYEE_GENERATE_LETTERS: 'EMPLOYEE_GENERATE_LETTERS', //114,
  PAYROLL_VIEW: 'PAYROLL_VIEW', //200,
  PAYROLL_EDIT: 'PAYROLL_EDIT', //201,
  PAYROLL_FINALIZE: 'PAYROLL_FINALIZE', //202,
  PAYROLL_PAY_ADVANCE_SALARY: 'PAYROLL_PAY_ADVANCE_SALARY', //203,
  PAYROLL_PAY_ADHOC_PAYMENT: 'PAYROLL_PAY_ADHOC_PAYMENT', //204
  PAYROLL_ADD_ADHOC_PAYMENT: 'PAYROLL_ADD_ADHOC_PAYMENT', //205
  PAYROLL_EXECUTION: 'PAYROLL_EXECUTION', //206
  BULK_ADDITION_DEDUCTION: 'BULK_ADDITION_DEDUCTION', //207
  BULK_SALARY_REVISION: 'BULK_SALARY_REVISION', //208
  BONUS_EDIT: 'BONUS_EDIT', //209
  PAY_CONTRACTORS: 'PAY_CONTRACTORS', //300,
  REIMBURSEMENTS_VIEW: 'REIMBURSEMENTS_VIEW', //400,
  REIMBURSEMENTS_ADD: 'REIMBURSEMENTS_ADD', //401,
  REIMBURSEMENTS_PAY: 'REIMBURSEMENTS_PAY', //402,
  REIMBURSEMENTS_APPROVE_REJECT: 'REIMBURSEMENTS_APPROVE_REJECT', //404,
  REIMBURSEMENTS_FLEXIBLE_BENEFITS: 'REIMBURSEMENTS_FLEXIBLE_BENEFITS', //403,
  ATTENDANCE_APPROVAL: 'ATTENDANCE_APPROVAL', //500,
  DOCUMENTS_VIEW: 'DOCUMENTS_VIEW', //600,
  DOCUMENTS_DELETE: 'DOCUMENTS_DELETE', //601,
  DOCUMENTS_EDIT_ORG_DOCS: 'DOCUMENTS_EDIT_ORG_DOCS', //602,
  REPORTS_VIEW: 'REPORTS_VIEW', //700,
  REPORTS_SALARY_REGISTER: 'REPORTS_SALARY_REGISTER', //701,
  REPORTS_HR_REGISTER: 'REPORTS_HR_REGISTER', //702,
  REPORTS_DOCUMENTS: 'REPORTS_DOCUMENTS', //703,
  REPORTS_MISSING_INFORMATION: 'REPORTS_MISSING_INFORMATION', //704,
  REPORTS_AUDIT: 'REPORTS_AUDIT', //705,
  REPORTS_REIMBURSEMENTS: 'REPORTS_REIMBURSEMENTS', //706,
  REPORTS_LEDGER: 'REPORTS_LEDGER', //707,
  REPORTS_UPCOMING_CONTRACTOR_PAYMENTS: 'REPORTS_UPCOMING_CONTRACTOR_PAYMENTS', //708,
  REPORTS_COMPLIANCE_DOCUMENTS: 'REPORTS_COMPLIANCE_DOCUMENTS', //709,
  REPORTS_TAX_DEDUCTIONS: 'REPORTS_TAX_DEDUCTIONS', //710,
  REPORTS_BULK_UPLOADS: 'REPORTS_BULK_UPLOADS', //712
  REPORTS_ESIC_REGISTRATION_DASHBOARD: 'REPORTS_ESIC_REGISTRATION_DASHBOARD', //716
  REPORTS_PF_REGISTRATION_DASHBOARD: 'REPORTS_PF_REGISTRATION_DASHBOARD', //717
  CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT', //800,
  MANAGE_INSURANCE: 'MANAGE_INSURANCE', //900,
  MANAGE_SLACK: 'MANAGE_SLACK', //1000
  MANAGE_HRMS_INTEGRATION: 'MANAGE_HRMS_INTEGRATION', //1001,
  TRIGGER_HRMS_SYNC: 'TRIGGER_HRMS_SYNC', //1002
  MANAGE_INTEGRATIONS: 'MANAGE_INTEGRATIONS', //1003
  MANAGE_WHATSAPP: 'MANAGE_WHATSAPP', //1004
  MANAGE_KLAAR: 'MANAGE_KLAAR', //1005
  MANAGE_SPRING_VERIFY: 'MANAGE_SPRING_VERIFY', //1006
  MANAGE_PAZCARE: 'MANAGE_PAZCARE', //1007
  MANAGE_WORKFLOW: 'MANAGE_WORKFLOW', //1008
  MANAGE_2FA: 'MANAGE_2FA', //1009
  MANAGE_SALARY_COMPONENTS: 'MANAGE_SALARY_COMPONENTS', //1010
});

export default permissions;
