import React, {FormEvent, useState} from 'react';
import Modal from 'components/ui/Modals/components/Modal';
import styles from './index.module.scss';
import {Card} from "../../../../../ui/Card";
import Checkbox from "../../../../../ui/Checkbox";
import {Input} from "../../../../../ui/Form/Input";
import {StandardButton} from "../../../../../ui/Button";
import JibbleEmployeesDataSchema from "../../../../../../schemas/JibbleEmployeesDataSchema";
import api from "../../../../../../api";
import ExternalLink from "../../../../../ui/ExternalLink";
import {useSelector} from "react-redux";
import loggedInUserSelectors from "../../../../../../reducers/loggedInUser/selectors";
import {useMutation} from "react-query";
import {SyncJibbleAccountByMemberCodeRequest} from "../../../../../../api/jibbleSettings";

interface EditJibbleMemberCodeModalProps {
    employeeData: typeof JibbleEmployeesDataSchema['jibble_employee_data']['data'];
    onCloseModal: () => void;
    onSubmitModal: () => void;
}

const EditJibbleMemberCodeModal = ({
    employeeData,
    onCloseModal,
    onSubmitModal
}: EditJibbleMemberCodeModalProps) => {
    const updatingExistingCode = employeeData['jibble_member_code'] !== null;
    const [updateConsent, setUpdateConsent] = useState(!updatingExistingCode);
    const [jibbleCode, setJibbleCode] = useState('');
    const { jibblePeopleLink } = useSelector(loggedInUserSelectors.referenceLinks);

    const syncAccountViaJibbleCodeMutation = useMutation(({employeeData, updatedJibbleMemberCode}: SyncJibbleAccountByMemberCodeRequest) =>
        api.jibbleSettings.syncJibbleAccountByMemberCode({employeeData, updatedJibbleMemberCode}), {
        onSuccess: data =>{
            onSubmitModal();
        }
    })
    const onFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        syncAccountViaJibbleCodeMutation.mutate({employeeData, updatedJibbleMemberCode: jibbleCode})
    }

    return (
        <Modal>
            <form className={styles['editJibbleCodeModal']} onSubmit={(event) => onFormSubmit(event)}>
                <h1 className={styles['modalHeader']}>
                    {
                        updatingExistingCode
                        ? 'Update Jibble code'
                        : 'Sync RazorpayX Payroll employee to Jibble via Jibble Code'
                    }
                </h1>
                { updatingExistingCode &&
                    <p className="text-1.5xl opacity-75 mt-5 text-left">
                        Update the Jibble code only if that in RazorpayX Payroll doesn't match the Jibble code of the respective employee's Jibble account
                    </p>
                }
                <Card className={styles['infoCard']}>
                    <h2 className={styles['cardHeader']}>👉 How to get the Jibble code for a particular employee</h2>
                    <p className={styles['infoPoint']}>
                        1. Go to Jibble’s {' '}
                        <ExternalLink className={"text-1.5xl"} href={jibblePeopleLink}>
                            People page
                        </ExternalLink>
                    </p>
                    <p className={styles['infoPoint']}>2. Search for the employee and click on the name</p>
                    <p className={styles['infoPoint']}>3. Copy the member code displayed to paste it below</p>
                </Card>
                { updatingExistingCode && (
                    <div className="flex">
                        <Checkbox
                            id="jibble-code-mismatch-confirmation"
                            checked={updateConsent}
                            onChange={(e) => {
                                setUpdateConsent(e.target.checked)
                            }}
                            label="Jibble code in RazorpayX Payroll does not match code in Jibble"
                            labelClassName={styles.consentLabel}
                        />
                    </div>
                )}
                { (!updatingExistingCode || updateConsent) && (
                    <div className={updatingExistingCode ? "mt-10" : ""}>
                        <label htmlFor="jibble-code">Jibble Code</label>
                        <Input
                            id="jibble-code"
                            className={styles['codeInput']}
                            value={jibbleCode}
                            onChange={(event) => setJibbleCode(event.target.value)}
                            autoFocus={true}
                        />
                    </div>
                )}
                <StandardButton
                    type="submit"
                    className="mt-20 normal-case rounded"
                    disabled={(updatingExistingCode && !updateConsent) || jibbleCode === ''}
                    isPending={syncAccountViaJibbleCodeMutation.isLoading}
                >
                    Sync employee to Jibble
                </StandardButton>
                <StandardButton
                    className={styles['closeButton']}
                    onClick={() => onCloseModal()}
                >
                    Close
                </StandardButton>
            </form>
        </Modal>
    )

}

export default EditJibbleMemberCodeModal;