import React, { ReactElement } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { VarianceReportSchemaContract } from 'schemas/VarianceReportSchema';
import {
  Chart as ChartJS,
  ChartDataset,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const plugins = [
  ChartDataLabels,
  {
    // cant find typedef for this that works with legend
    beforeInit(chart: any) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;

      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 15;
      };
    },
  },
];

function ChartView({ data }: { data: VarianceReportSchemaContract }): ReactElement {
  let months: string[] = [];

  let groupLabels: string[] = [];
  let groupedData: { [key: string]: number[] } = {};

  data.map((item) => {
    months.push(item.payroll_month);

    let itemdata = item.data;
    if (itemdata.length > 0) {
      itemdata.forEach((it) => {
        if (it['label'] && it['value'] !== undefined) {
          if (!groupLabels.includes(it['label'])) {
            groupLabels.push(it['label']);
            groupedData[it['label']] = [];
          }
          groupedData[it['label']].push(it['value']);
        }
      });
    }
  });
  let colors = ['#6E6ED9', '#5A99E8', '#4FB4B4', '#D4AC0F'];
  let index = 0;

  let dataset = Object.keys(groupedData).map((key) => {
    let singleData = {
      label:
        Object.keys(groupedData).length > 1
          ? key.toLocaleUpperCase()
          : key.charAt(0).toLocaleUpperCase() + key.substr(1).toLocaleLowerCase(),
      stack: 'variance',
      pointStyle: 'rectRounded',
      backgroundColor: colors[index],
      barThickness: 40,
      data: groupedData[key], //From API
    };
    index++;
    return singleData;
  });

  const dataBar = {
    labels: months,
    responsive: true,
    offset: true,
    datasets: dataset,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },

    plugins: {
      legend: {
        labels: {
          color: 'rgba(255,255,255,0.74)',
        },
      },
      datalabels: {
        formatter: (
          value: number,
          ctx: {
            active: boolean;
            chart: ChartJS;
            dataIndex: number;
            dataset: ChartDataset;
            datasetIndex: number;
          },
        ) => {
          let datasets = ctx.chart.data.datasets.filter((ds, datasetIndex) =>
            ctx.chart.isDatasetVisible(datasetIndex),
          );

          // If this is the last visible dataset of the bar :
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = 0;
            datasets.map((dataset) => {
              let value = dataset.data[ctx.dataIndex];
              if (value !== null && typeof value === 'number') {
                sum += value;
              }
            });
            return sum.toLocaleString(/* ... */);
          } else {
            return '';
          }
        },

        anchor: 'end',
        color: 'rgba(255,255,255,0.74)',
        align: 'end',
        offset: 5,
      },
    },
    interaction: {
      mode: 'index',
    },
    scales: {
      xAxis: {
        gridLines: {
          zeroLineColor: 'rgba(255,255,255,0.4)',
        },
        ticks: {
          color: 'rgba(255,255,255,0.87)',
        },
        grid: {
          display: false,
        },
      },
      yAxis: {
        gridLines: {
          zeroLineColor: 'rgba(255,255,255,0.4)',
        },

        ticks: {
          color: 'rgba(255,255,255,0.87)',
        },
      },
    },
  };

  return (
    <div style={{ height: '425px' }}>
      {/*@ts-ignore*/}
      <Bar data={dataBar} plugins={plugins} options={options} />
    </div>
  );
}

export default ChartView;
