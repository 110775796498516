import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

function useHasPermission(allowedPermissions: string[]) {
  const permissions = useSelector(loggedInUserSelectors.permissions);
  let isAllowed = false;

  if (allowedPermissions.length) {
    isAllowed = allowedPermissions.some((permission) => permissions.includes(permission));
  }

  return isAllowed;
}

export default useHasPermission;
