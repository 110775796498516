import { Box, CloseIcon, IconButton, Text } from '@razorpay/blade/components';
import React, { useMemo } from 'react';
import { useFilters } from '../useFilters';
import { DateRange } from './DateFilter';
import { WorkflowRequestStatus } from '../types';
import { WorkflowRequestsFiltersMap, WorkflowRequestsStatusLabelsMap } from '../constants';
import { format } from 'date-fns';

interface FilterChipProps {
  filterText: string;
  onClose: () => void;
}

const FilterChip = ({ filterText, onClose }: FilterChipProps) => {
  return (
    <Box
      display="flex"
      gap="spacing.2"
      padding={['6px', 'spacing.3']}
      borderWidth="thin"
      borderColor="surface.border.gray.subtle"
      backgroundColor="surface.background.primary.subtle"
      borderRadius="small">
      <Text>{filterText}</Text>
      <IconButton icon={CloseIcon} onClick={onClose} accessibilityLabel="close button" />
    </Box>
  );
};

type DateFilterChipProps = {
  dateRange: DateRange;
} & Pick<FilterChipProps, 'onClose'>;

const DateFilterChip = ({ dateRange, onClose }: DateFilterChipProps) => {
  const { from, to } = dateRange;
  const filterText = `${format(from as Date, 'do MMMM yyyy')} - ${format(
    to as Date,
    'do MMMM yyyy',
  )}`;

  return <FilterChip filterText={filterText} onClose={onClose} />;
};

type StatusFilterChipProps = {
  status: WorkflowRequestStatus;
} & Pick<FilterChipProps, 'onClose'>;

const StatusFilterChip = ({ status, onClose }: StatusFilterChipProps) => {
  const filterText = useMemo(() => {
    switch (status) {
      case WorkflowRequestsStatusLabelsMap.approved:
        return 'Approved';
      case WorkflowRequestsStatusLabelsMap.approved_by_me:
        return 'Approved by me';
      case WorkflowRequestsStatusLabelsMap.pending:
        return 'Pending';
      case WorkflowRequestsStatusLabelsMap.rejected:
        return 'Rejected';
      case WorkflowRequestsStatusLabelsMap.rejected_by_me:
        return 'Rejected by me';
      default:
        return '';
    }
  }, [status]);

  return <FilterChip filterText={filterText} onClose={onClose} />;
};

const FiltersChips = () => {
  const { urlParams, removeFilter } = useFilters();
  const dateFrom = urlParams.get(WorkflowRequestsFiltersMap.fromDate);
  const dateTo = urlParams.get(WorkflowRequestsFiltersMap.toDate);
  const status = urlParams.get(WorkflowRequestsFiltersMap.status);
  const showDateFilter = !!dateFrom && !!dateTo;
  const showStatusFilter = !!status;

  const showFilters = !!dateFrom || !!dateTo || !!status;

  const handleDateFilterChipClose = () => {
    removeFilter(WorkflowRequestsFiltersMap.fromDate);
    removeFilter(WorkflowRequestsFiltersMap.toDate);
  };

  const handleStatusFilterChipClose = () => {
    removeFilter(WorkflowRequestsFiltersMap.status);
  };
  return showFilters ? (
    <Box
      display="flex"
      padding={['spacing.4', 'spacing.4', 'spacing.4', 'spacing.8']}
      gap="spacing.3"
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      {showDateFilter && (
        <DateFilterChip
          dateRange={{
            from: new Date(dateFrom),
            to: new Date(dateTo),
          }}
          onClose={handleDateFilterChipClose}
        />
      )}
      {showStatusFilter && (
        <StatusFilterChip
          status={status as WorkflowRequestStatus}
          onClose={handleStatusFilterChipClose}
        />
      )}
    </Box>
  ) : null;
};

export { FiltersChips };
