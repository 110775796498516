import styled, { css, keyframes } from 'styled-components';
import { DocumentStatus } from '../../constants';
import { getBorderColor, getSubTextColor } from './utils';

export const FileUploadWrapper = styled.div<{
  status: DocumentStatus;
}>(({ theme, status }) => {
  return css`
    border-radius: ${theme.border.radius.medium}px;
    border: 1px dashed ${getBorderColor(status, theme)};
    display: flex;
    position: relative;
    flex: 1;
  `;
});

export const FileDropView = styled.div(({ theme }) => {
  return css`
    border-radius: ${theme.border.radius.medium}px;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: ${theme.colors.surface.background.gray.subtle};
    & * {
      pointer-events: none;
    }
  `;
});

export const SubText = styled.span<{
  status: DocumentStatus;
}>(({ theme, status }) => {
  return css`
    color: ${getSubTextColor(status, theme)};
    font-size: 0.6875rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1rem;
  `;
});

export const FileInput = styled.input.attrs({ type: 'file' })`
  width: 0;
  visibility: hidden;
`;

export const LabelWrapper = styled.span<{ isLink?: boolean }>`
  max-width: 18ch;
  line-clamp: 1;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: ${({ theme }) => theme.typography.fonts.family.text};
  color: ${({ theme }) => theme.colors.surface.text.gray.muted};

  :hover {
    color: ${({ theme, isLink }) =>
      isLink ? theme.colors.interactive.text.primary.normal : theme.colors.surface.text.gray.muted};
    cursor: ${({ isLink }) => (isLink ? 'pointer' : 'auto')};
  }
`;

export const animationSpin = keyframes`  
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  `;

export const Loader = styled.div`
  border-radius: 50%;
  animation: ${animationSpin} 1.5s linear infinite;
  border: 3px solid transparent;
  border-top: 3px solid rgba(98, 151, 255, 1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
