import React from 'react';
import { ClickableCard } from 'components/ui/SidebarCard';

export const PartnerFlexiBenefitsFAQ = () => {

  return (
    <ClickableCard
      heading='FAQs'
      onClick={() => {
        window.open('https://intercom.help/XPayroll/en/articles/6336727-zaggle-flexible-benefit-plan', '_blank');
      }}
      imageSymbolType={'requests'}
    >
      Setup your Zaggle app, how to use card, etc.
    </ClickableCard>
  );
};
