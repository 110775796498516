import React, { ReactElement } from 'react';
import styles from './index.module.scss';
import { prepareUrl } from 'utils/Urls';


const ErrorBlock = ({ mainText, subText, buttonText, downloadURL, onClick }:
    {
        mainText: string,
        subText: string,
        buttonText?: string,
        downloadURL?: string,
        onClick? : () => void
    }
): ReactElement => {
    return (
        <div className={styles['error-background']}>
            <div className={styles['error-background-left-section']}>
                <div className={styles['error-background-main-text']}>
                    {mainText}
                </div>
                <div className={styles['error-background-sub-text']}>
                    {subText}
                </div>
            </div>
            {buttonText &&
                <div className={styles['error-background-right-section']}>
                    <a
                        href={downloadURL }
                        target={"_blank"}
                        className={styles['error-button-a']}
                        >
                        <button id='error-button' className={styles['error-button']} onClick={onClick ? onClick : (() => {})}>
                            <span className={styles['error-button-text']}>{buttonText}</span>
                        </button>
                    </a>
                </div>}
        </div>
    );
}

export default ErrorBlock;