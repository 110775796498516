import { ReportFilter, ReportInput } from './types';

interface UseFiltersAndInputsProps {
  inputs: ReportInput[];
  filters: ReportFilter[];
}

const useFiltersAndInputs = ({ inputs, filters }: UseFiltersAndInputsProps) => {
  const inputsFieldsMap = inputs.reduce((acc, item) => ({ ...acc, [item.key]: item.key }), {});

  const filtersFieldsMap = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.key }), {});

  const inputsLabelMap = inputs.reduce((acc, item) => ({ ...acc, [item.key]: item.label }), {});

  const filtersLabelMap = filters.reduce((acc, item) => ({ ...acc, [item.key]: item.label }), {});
  return {
    inputsFieldsMap,
    filtersFieldsMap,
    filtersLabelMap,
    inputsLabelMap,
  };
};

export default useFiltersAndInputs;
