import { ActionItems } from '../../../../../constants/actionItems';
import { ActionItemsCardDataType } from './ActionItemsCardList';
import { routePaths } from "components/Routes/data";

export const getActionItemCardData = (): ActionItemsCardDataType[] => ([
    {
        id: ActionItems.KYC_VERIFICATION,
        title: 'KYC Verification',
        content: {
                    default :'🚨 KYC verification is must for Zaggle card disbursal and fund loading to the cards. Please upload your KYC documents. It will be sent to Zaggle and the associated bank partner for verification.',
                    inProgress : '🚨 Your KYC verification is in progress. Your KYC documents will be sent to Zaggle and the associated bank partner for verification.',
                    onHold : '🚨 Your KYC verification is on hold. Please re-upload the required documents and request verification again.'
                 },
        buttonLabel: 'START VERIFICATION',
        redirectURL: routePaths.partnerFlexiBenefits.admin.kycVerification,
    },
    {
        id: ActionItems.ENABLE_FOR_ALL_EMPLOYEES,
        title: 'Enable Zaggle flexible benefits for all employees',
        content: {
                    default :'Once you enable Zaggle, all employee will be able to declare their FBP amount through RazorpayX Payroll dashboard.',
                 },
        buttonLabel: 'ENABLE NOW',
        redirectURL: '',
    },
]);

export const filterActionItemCardData = (actionItemList : ActionItems[], actionItemsCardList : ActionItemsCardDataType[]): ActionItemsCardDataType[] =>(
    actionItemsCardList.filter( obj => {
        return actionItemList.includes(obj.id); 
    })
);