import React from 'react';
import { Skeleton, TableBody, TableCell, TableRow } from '@razorpay/blade/components';

export const TableSkeletonBody = ({
  rowCount,
  columnCount,
}: {
  rowCount: number;
  columnCount: number;
}) => {
  return (
    <TableBody>
      {Array(rowCount)
        .fill(0)
        .map((_, rowIndex) => (
          <TableRow key={rowIndex} item={{ id: rowIndex }} hoverActions={<></>}>
            {Array(columnCount)
              .fill(0)
              .map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton width="100%" height={'80%'} borderRadius="medium" />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </TableBody>
  );
};
