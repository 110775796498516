import React from 'react';
import { SideSheet } from '../SideSheet';
import { useWorkflowDetailsView } from '../../useWorkflowDetailsView';
import { Route, Switch } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { EditPayroll } from './EditPayroll';
import { FinalizePayroll } from './FinalizePayroll';
import { SalaryRevision } from './SalaryRevision';
import { RestrictedRoute } from 'components/Routes/RestrictedRoute';

const DetailsView = () => {
  const { closeDetailsView, workflowRequestId } = useWorkflowDetailsView();
  const isOpen = !!workflowRequestId;

  return (
    <SideSheet isOpen={isOpen} onDismiss={closeDetailsView}>
      <Switch>
        <Route path={routePaths.approvalWorkflows.myRequests.editPayroll.root}>
          <EditPayroll />
        </Route>
        <Route path={routePaths.approvalWorkflows.myRequests.finalizePayroll.root}>
          <FinalizePayroll />
        </Route>
        <Route path={routePaths.approvalWorkflows.myRequests.salaryRevision.root}>
          <SalaryRevision />
        </Route>
      </Switch>
    </SideSheet>
  );
};

export { DetailsView };
