import React from 'react';
import InputBoxWrapper from './FnfInputBoxWrapper';
import { Box, TextInput, Text, LockIcon } from '@razorpay/blade/components';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { useQuery } from 'react-query';
import { FETCH_LOP_AMOUNT } from 'components/RunPayroll/utils/queries';
import runpayrollApi from './../../../api/runPayroll';
import useLopTexts from 'components/RunPayroll/Popups/useLopTexts';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

type FnfLossOfPayProps = {
  onChangeLossOfPayDays: (lossOfPayDays: string | undefined) => void;
  lopDeduction: GetFnfResponseSchemaContract['deductions']['lop_deduction'] | null;
  payrollId: number | null;
  isFnfProcessed: boolean;
  isHoldSalaryPayComplianceEnabled: boolean;
};

const FnfLossOfPay = ({
  lopDeduction,
  onChangeLossOfPayDays,
  payrollId,
  isFnfProcessed,
  isHoldSalaryPayComplianceEnabled,
}: FnfLossOfPayProps) => {
  const { isM2S1Enabled } = useIsFeatureDisabledForRearch();
  const getLOPAmount = useQuery(
    [FETCH_LOP_AMOUNT, payrollId],
    () => {
      if (!payrollId || !lopDeduction) {
        return null;
      }
      const lopDays = lopDeduction.lop_days;
      return runpayrollApi.getLOPAmount(payrollId, lopDays ? parseFloat(lopDays) : 0);
    },
    { enabled: !isM2S1Enabled },
  );

  const lopAmountData = getLOPAmount.data;
  let lopAmountStatus = getLOPAmount.status;
  if (getLOPAmount.isFetching) {
    lopAmountStatus = 'loading';
  }

  const [isLOPEditable, lopText, lopAmountText, lopValue] = useLopTexts({
    lopAmountData,
    lopAmountStatus,
    lopDeduction,
    payrollId,
  });

  return (
    <InputBoxWrapper heading="Loss of pay deductions">
      <Box display="flex" flexDirection="column" gap="spacing.4">
        <TextInput
          label="Loss of pay days"
          placeholder="Number of days to deduct"
          helpText={lopText}
          value={lopValue}
          icon={isLOPEditable ? undefined : LockIcon}
          isDisabled={!isLOPEditable || (isHoldSalaryPayComplianceEnabled && isFnfProcessed)}
          onChange={(e) => {
            onChangeLossOfPayDays(e.value);
          }}
          onBlur={(event) => {
            if (!isM2S1Enabled) {
              getLOPAmount.refetch();
            }
          }}
        />
        <Text variant="caption" color="feedback.text.information.intense" size="small">
          {lopAmountText}
        </Text>
      </Box>
    </InputBoxWrapper>
  );
};

export default FnfLossOfPay;
