export const WorkflowTypesMap = {
  EDIT_PAYROLL: 4,
  FINALIZE_PAYROLL: 5,
  SALARY_REVISION: 6
} as const;

export const WorkflowTypeLabelsMap = {
  [WorkflowTypesMap.EDIT_PAYROLL]: 'Edit payroll',
  [WorkflowTypesMap.FINALIZE_PAYROLL]: 'Finalize payroll',
  [WorkflowTypesMap.SALARY_REVISION]: 'Salary Revision',
} as const;


export const ACTION_APPROVED = 'APPROVED';
export const ACTION_REJECTED = 'REJECTED';

export const WORKFLOW_STATUS_REJECTED = 3;