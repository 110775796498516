import {
  RowCheckHandler,
  RowData,
} from 'components/ApprovalWorkflows/MyRequests/components/Table/types';
import { Dispatch, SetStateAction } from 'react';

interface UseReleaseNetpayEventHandlers<T extends RowData> {
  tableData: T[];
  setTableData: Dispatch<SetStateAction<T[]>>;
}

const useReleaseNetpayEventHandlers = <T extends RowData>({
  tableData,
  setTableData,
}: UseReleaseNetpayEventHandlers<T>) => {
  const onRowChecked: RowCheckHandler<T> = ({ index, isChecked }) => {
    setTableData((data) =>
      data.map((rowData, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...rowData,
            isChecked,
          };
        }

        return rowData;
      }),
    );
  };

  const selectedRows = tableData.filter((rowData) => rowData.isChecked);

  const selectedRowsCount = selectedRows.length;

  return { onRowChecked, selectedRowsCount, selectedRows };
};

export default useReleaseNetpayEventHandlers;
