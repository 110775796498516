import api from 'api';
import { WarningAlert } from 'components/ui/Alert';
import { SecondaryButton, StandardButton } from 'components/ui/Button';
import Errors from 'components/ui/Form/Errors';
import { Input, InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import { Option, Select } from 'components/ui/Form/Select';
import React, { FormEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { AppError } from 'utils/AppError';
import { orgTypes } from 'utils/OrganizationTypes';
import { trackXPayrollEvent } from 'utils/analytics';
import { BUSINES_PAN_REGEX, PAN_REGEX } from 'utils/regexes';
import { FETCH_COMPANY_NAME_FROM_PAN } from '../queries';
import { PanConfirmationPayload } from './types';

interface Props {
  onNext: (payload: PanConfirmationPayload) => void;
  onBack: () => void;
  initialPan?: string;
  initialOrgType?: number;
}

const CompanyNameFromPanView = ({ onNext, onBack, initialPan = '', initialOrgType = 1 }: Props) => {
  const [pan, setPan] = useState(initialPan);
  const [orgType, setOrgType] = useState(initialOrgType);
  const { isLoading, isRefetching, refetch, error } = useQuery(
    FETCH_COMPANY_NAME_FROM_PAN,
    () => api.companyOnboarding.getCompanyNameFromPan(pan),
    {
      enabled: false,
      onError: (error: typeof AppError) => {},
      onSuccess: (data) => {
        trackXPayrollEvent('onboarding.fetched_details', 'interaction', 'company-onboarding');
        onNext({ pan, orgType, companyName: data?.name });
      },
    },
  );

  const handleSubmit = (e: FormEvent) => {
    trackXPayrollEvent('onboarding.entered_pan', 'interaction', 'company-onboarding');
    e.preventDefault();
    refetch();
  };

  const isPanAndBusinessTypeCompatible = () => {
    const isPanValid = new RegExp(PAN_REGEX).test(pan.toUpperCase());
    if (!isPanValid) return true;
    const isBusinessPan = new RegExp(BUSINES_PAN_REGEX).test(pan.toUpperCase());
    if (isBusinessPan) {
      return [1, 3, 4].includes(orgType);
    } else {
      return orgType === 2;
    }
  };

  const isPanValid = new RegExp(PAN_REGEX).test(pan.toUpperCase());
  const isRequesting = isRefetching || isLoading;

  return (
    <form onSubmit={handleSubmit}>
      {error ? (
        <WarningAlert className="mb-16">
          <Errors errorResponse={error} />
        </WarningAlert>
      ) : null}
      {!isPanAndBusinessTypeCompatible() ? (
        <WarningAlert className="mb-16">PAN and Organization Type are incompatible</WarningAlert>
      ) : null}

      <InputElement required label="Organization Type" className="mt-10 mb-10">
        <Select
          className="mb-0"
          required
          disabled={isRequesting}
          onChange={(event) => {
            setOrgType(parseInt(event.target.value));
          }}
          value={orgType}>
          {orgTypes.map((orgType) => (
            <Option key={orgType.code} value={orgType.code}>
              {orgType.name}
            </Option>
          ))}
        </Select>
        <InputInfo>Select business category</InputInfo>
      </InputElement>
      <InputElement required label="Company PAN" className="mt-4 mb-10">
        <Input
          type="text"
          maxLength={15}
          value={pan}
          required
          name="company-pan"
          onChange={(event) => setPan(event.target.value)}
          placeholder="Enter PAN"
          customErrorMessage="Invalid PAN"
          pattern={PAN_REGEX}
          disabled={isRequesting}
        />
        <InputInfo>We need your PAN to verify your business details.</InputInfo>
      </InputElement>

      <div className="flex justify-between align-center pt-12">
        <SecondaryButton
          type="button"
          className="normal-case"
          onClick={onBack}
          disabled={isRequesting}>
          Fill via GSTIN
        </SecondaryButton>
        <StandardButton
          type="submit"
          isPending={isRequesting}
          className="normal-case mr-0"
          disabled={!isPanValid || isRequesting || !isPanAndBusinessTypeCompatible()}>
          Continue
        </StandardButton>
      </div>
    </form>
  );
};

export default CompanyNameFromPanView;
