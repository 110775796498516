import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import {
  IInsurance,
  purchaseInsurance,
  updateInsurance,
} from '../../../../reducers/insurance';
import { sendReminder } from '../../../../reducers/insuranceEmployee';
import {
  fetchInsuranceEmployees,
  fetchNextInsuranceEmployees,
  IInsuranceEmployees,
  InsuranceEmployeeObject,
} from '../../../../reducers/insuranceEmployees';
import { IInsuranceEstimate, fetchInsuranceEstimate } from '../../../../reducers/insuranceEstimate';
import { toIndianCurrencyString } from '../../../../utils/Numbers';
import useReduxSelector from '../../../../utils/useReduxSelector';
import { PrimaryButton, SecondaryButton } from '../../../ui/Button';
import { Option, Select } from '../../../ui/Form/Select';
import { SimpleListLoader } from '../../../ui/Loaders';
import { Modal } from '../../../ui/Modal';
import { Table, TableLayout } from '../../../ui/Table';
import { getEditEmployeeInsuranceDetailsRoute, routePaths } from 'components/Routes/data';
import InsuranceDataCollectionProgressCard from '../../InsuranceDataCollectionProgressCard';
import { EmployeesListBox } from '../EmployeesListBox';
import { ShowBalanceConfirmationModal } from '../../ShowBalanceConfirmationModal';
import { ContentContainer } from '../../../ui/ContentContainer';
import { getConditionsNotMetToPurchaseInsurance } from '../computeMinRequirements';
import {  ILoggedInUser } from '../../../../reducers/loggedInUser';
import { GetOrgEmployeesForInsuranceSchemaContract } from 'schemas/GetOrgEmployeesForInsuranceSchema';
import { camelCaseToWords, capitalizeFirstLetter } from 'utils/Strings';
import insurancePlanBlocked from 'constants/insurancePlanBlocked';

function isCorrectOption(
  option: InsuranceEmployeeObject['status'] | string,
): option is InsuranceEmployeeObject['status'] {
  return ['HAS_MISSING_DATA', 'PURCHASE_PENDING', 'NOT_ELIGIBLE'].includes(option);
}

function getReadableStatus(status: string) {
  switch (status) {
    case 'HAS_MISSING_DATA':
      return 'Missing details';
    case 'PURCHASE_PENDING':
      return 'Data collected';
    case 'SKIPPED':
      return 'Skipped';
  }
  return 'Not eligible';
}

function RequestForDataCollection({
  insurance,
  updateInsuranceAction,
  insuranceEstimate,
  dispatch,
}: {
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  insuranceEstimate: IInsuranceEstimate;
  dispatch: ReturnType<typeof useDispatch>;
}) {
  const requestDataCollection = () => {
    insurance.data?.id &&
      dispatch(updateInsuranceAction(insurance.data.id, { status: 'DATA_COLLECTION_IN_PROGRESS' }));
  };
  const shouldShowEsiColumn =
    insurance.data?.shouldCoverEmployeeFromEsi === false &&
    insuranceEstimate.data?.totalEmployees &&
    insuranceEstimate.data?.totalEsiEmployees
      ? true
      : false;
  const esiEmployeesExcluded =
    insurance.data?.shouldCoverEmployeeFromEsi === false &&
    insuranceEstimate.data?.totalEsiEmployees
      ? insuranceEstimate.data.totalEsiEmployees
      : 0;
  return (
    <div className="p-3 sm:p-10">
      <div className="flex justify-center">
        <section className="flex-1 flex flex-col max-w-4xl space-y-8">
          <div className="p-2">
            <div className="text-5xl font-heading font-bold">Employee and add on details</div>
            <div className="secondary-text mt-4 text-3xl">
              XPayroll will send your employees a form to provide exact details of all the lives you
              want to cover under group health policy
            </div>
          </div>
          <div className="sm:flex hidden sm:display sm:flex-row">
            <TableLayout className="m-2">
              <tr>
                <td>Total employees on XPayroll</td>
                <td className="font-semibold px-2">
                  {insuranceEstimate.data?.totalEmployees
                    ? insuranceEstimate.data.totalEmployees
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Total employees being covered &nbsp;</td>
                <td className="font-semibold px-2">
                  {insuranceEstimate.data?.totalEmployees
                    ? insuranceEstimate.data.totalEmployees - esiEmployeesExcluded
                    : 'N/A'}
                </td>
              </tr>
            </TableLayout>
            {shouldShowEsiColumn && (
              <TableLayout className="m-2">
                <tr>
                  <td>ESI employees excluded ({esiEmployeesExcluded})</td>
                  <td className="px-2"></td>
                </tr>
              </TableLayout>
            )}
          </div>
          <div className="flex flex-col sm:hidden">
            <TableLayout className="m-2">
              <tr>
                <td>Total employees on XPayroll</td>
                <td className="font-semibold px-2">
                  {insuranceEstimate.data?.totalEmployees
                    ? insuranceEstimate.data.totalEmployees
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Total employees being covered</td>
                <td className="font-semibold px-2">
                  {insuranceEstimate.data?.totalEligibleEmployees
                    ? insuranceEstimate.data.totalEligibleEmployees - esiEmployeesExcluded
                    : 'N/A'}
                </td>
              </tr>
              {shouldShowEsiColumn && (
                <tr>
                  <td>ESI employees excluded</td>
                  <td className="font-semibold px-2"> {esiEmployeesExcluded} </td>
                </tr>
              )}
            </TableLayout>
          </div>
          <div className="p-2">
            <PrimaryButton onClick={requestDataCollection}>START COLLECTING DETAILS</PrimaryButton>
          </div>
        </section>
      </div>
    </div>
  );
}

function DataCollectionProgress({
  className,
  insuranceEstimate,
}: {
  className?: string;
  insuranceEstimate: IInsuranceEstimate;
}) {
  const totalEmployeesGivenDetails = insuranceEstimate.data
    ? insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length
    : 0;
  const totalEligibleEmployees = insuranceEstimate.data?.totalEligibleEmployees
    ? insuranceEstimate.data.totalEligibleEmployees
    : 0;
  const totalEmployees = insuranceEstimate.data?.totalEligibleEmployees
    ? insuranceEstimate.data.totalEmployees
    : 0;

  return (
    <InsuranceDataCollectionProgressCard
      className={className}
      areEstimatesAvailable={!!insuranceEstimate.data}
      totalEligibleEmployees={totalEligibleEmployees}
      totalEmployeesGivenDetails={totalEmployeesGivenDetails}
      totalEmployees={totalEmployees}
    />
  );
}

function EmployeesTable({
  insuranceEmployees,
  fetchNextInsuranceEmployeesAction,
  insuranceEstimate,
}: {
  insuranceEstimate: IInsuranceEstimate;
  insuranceEmployees: IInsuranceEmployees;
  fetchNextInsuranceEmployeesAction: typeof fetchNextInsuranceEmployees;
}) {
  const dispatch = useDispatch();
  if (insuranceEmployees.isNeverFetched) {
    return <></>;
  }

  if (
    insuranceEmployees.isFetching &&
    insuranceEmployees.fetchingFresh &&
    !insuranceEmployees.fetchingSilently
  ) {
    return <SimpleListLoader>Fetching employees</SimpleListLoader>;
  }

  if (insuranceEmployees.hasError) {
    return (
      <div className="w-full text-center py-10 font-bold">
        {insuranceEmployees.errorData?.messageForUser}
      </div>
    );
  }

  if (insuranceEmployees.data.total === 0) {
    return <div className="w-full text-center py-10 font-bold">No Employees</div>;
  }

  const totalSpouse = (employeeObject: typeof insuranceEmployees.data.items[0]) =>
    employeeObject.dependents.filter(
      (dependent) => ['spouse'].includes(dependent.relation) && dependent.isPurchasePending,
    ).length;
  const totalParents = (employeeObject: typeof insuranceEmployees.data.items[0]) =>
    employeeObject.dependents.filter(
      (dependent) =>
        ['father', 'mother', 'fatherInLaw', 'motherInLaw'].includes(dependent.relation) &&
        dependent.isPurchasePending,
    ).length;
  const totalChildren = (employeeObject: typeof insuranceEmployees.data.items[0]) =>
    employeeObject.dependents.filter(
      (dependent) =>
        ['son', 'daughter'].includes(dependent.relation) && dependent.isPurchasePending,
    ).length;

  return (
    <>
      <div className="text-primary font-semibold block sm:hidden">
        Total: {insuranceEmployees.data.total}
      </div>
      <Table className="break-all md:break-normal w-full mt-8">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Employee ID</th>
            <th>Add-ons</th>
            <th>Premium</th>
            <th></th>
          </tr>
          {insuranceEmployees.data.items.map((employeeObject) => {
            let premium = 0;
            if (
              insuranceEstimate.data &&
              insuranceEstimate.data.exactEstimate.premiumBreakup.newEmployeesWithDependents &&
              insuranceEstimate.data.exactEstimate.premiumBreakup.newEmployeesWithDependents[
                employeeObject.id
              ]
            ) {
              premium =
                insuranceEstimate.data.exactEstimate.premiumBreakup.newEmployeesWithDependents[
                  employeeObject.id
                ];
            }

            return (
              <tr>
                <td>{employeeObject.name ? employeeObject.name : 'Not available'}</td>
                <td>{employeeObject.email ? employeeObject.email : 'Not available'}</td>
                <td>{getReadableStatus(employeeObject.status)}</td>
                <td>{employeeObject.employeeId}</td>
                <td>
                  {[
                    totalSpouse(employeeObject)
                      ? '' + totalSpouse(employeeObject) + ' Spouse'
                      : null,
                    totalParents(employeeObject)
                      ? '' + totalParents(employeeObject) + ' Parent(s)'
                      : null,
                    totalChildren(employeeObject)
                      ? '' + totalChildren(employeeObject) + ' Children'
                      : null,
                  ]
                    .filter((toShow) => toShow !== null)
                    .join(' + ')}
                </td>
                <td>
                  {premium > 0 ? (
                    <PremiumBreakup insuranceEstimate={insuranceEstimate} employee={employeeObject}>
                      {toIndianCurrencyString(premium)}
                    </PremiumBreakup>
                  ) : (
                    toIndianCurrencyString(premium)
                  )}
                </td>
                <td>
                  {' '}
                  <Link
                    to={getEditEmployeeInsuranceDetailsRoute(employeeObject.id)}
                    className="text-primary font-bold"
                  >
                    {' '}
                    Edit{' '}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {insuranceEmployees.data.items.length < insuranceEmployees.data.total && (
        <div className="flex justify-center">
          <SecondaryButton
            onClick={() => dispatch(fetchNextInsuranceEmployeesAction())}
            disabled={insuranceEmployees.isFetching && !insuranceEmployees.fetchingFresh}
          >
            {' '}
            {insuranceEmployees.isFetching && !insuranceEmployees.fetchingFresh
              ? 'Loading...'
              : 'Load more'}{' '}
          </SecondaryButton>
        </div>
      )}
    </>
  );
}

function PremiumBreakup({
  employee,
  insuranceEstimate,
  children,
}: PropsWithChildren<{
  employee: GetOrgEmployeesForInsuranceSchemaContract['items'][0];
  insuranceEstimate: IInsuranceEstimate;
}>) {
  const [showModal, setShowModal] = useState(false);
  if (!insuranceEstimate.data) {
    return <></>;
  }
  const premiumBreakup = insuranceEstimate.data.exactEstimate.premiumBreakup;
  return (
    <>
      <span
        className="cursor-pointer underline text-primary font-bold"
        onClick={() => setShowModal(true)}
      >
        {children}
      </span>
      <Modal showModal={showModal} heading="Premium Breakup" closeModal={() => setShowModal(false)}>
        <TableLayout className="w-full">
          <tr>
            <th>Name</th>
            <th>Relation</th>
            <th>Premium</th>
          </tr>
          <tr>
            <td>{employee.name}</td>
            <td>Self</td>
            <td>
              {toIndianCurrencyString(
                premiumBreakup['newEmployees'][employee.id]
                  ? premiumBreakup['newEmployees'][employee.id]
                  : 0,
              )}
            </td>
          </tr>
          {employee.dependents.map((dependent) => (
            <tr>
              <td>{dependent.name}</td>
              <td>{capitalizeFirstLetter(camelCaseToWords(dependent.relation))}</td>
              <td>
                {toIndianCurrencyString(
                  premiumBreakup['newDependents'][dependent.id]
                    ? premiumBreakup['newDependents'][dependent.id]
                    : 0,
                )}
              </td>
            </tr>
          ))}
        </TableLayout>
      </Modal>
    </>
  );
}

function EmployeesList({
  sendReminderAction,
  insurance,
  fetchInsuranceEmployeesAction,
  insuranceEstimate,
  insuranceEmployees,
  fetchNextInsuranceEmployeesAction,
}: {
  insurance: IInsurance;
  fetchInsuranceEmployeesAction: typeof fetchInsuranceEmployees;
  insuranceEmployees: IInsuranceEmployees;
  fetchNextInsuranceEmployeesAction: typeof fetchNextInsuranceEmployees;
  insuranceEstimate: IInsuranceEstimate;
  sendReminderAction: typeof sendReminder;
}) {
  const dispatch = useDispatch();
  return (
    <EmployeesListBox
      search={insuranceEmployees.fetchedFor.search ? insuranceEmployees.fetchedFor.search : null}
      totalEmployees={insuranceEmployees.data.total}
      onSearch={(event) =>
        dispatch(
          fetchInsuranceEmployeesAction({
            search: event.currentTarget.value,
            organizationId: insurance.data?.organizationId ? insurance.data?.organizationId : null,
          }),
        )
      }
      onConfirmSendReminder={(setShowModal: (show: boolean) => any) => () => {
        insurance.data && insurance.data.id && dispatch(sendReminderAction(insurance.data.id));
        setShowModal(false);
      }}
      onChangeFilter={(event) =>
        dispatch(
          fetchInsuranceEmployeesAction({
            organizationId: insurance.data?.organizationId ? insurance.data?.organizationId : null,
            includes: isCorrectOption(event.currentTarget.value)
              ? [event.currentTarget.value]
              : null,
          }),
        )
      }
      isFetching={insuranceEmployees.isFetching && !insuranceEmployees.fetchingSilently}
      currentFilters={
        insuranceEmployees.fetchedFor.includes ? insuranceEmployees.fetchedFor.includes : []
      }
      filters={{
        HAS_MISSING_DATA: 'Missing details',
        PURCHASE_PENDING: 'Data collected',
        NOT_ELIGIBLE: 'Not eligible',
      }}
      totalMissingEmployees={
        insuranceEstimate.data?.totalMissingEmployeeDetails
          ? insuranceEstimate.data?.totalMissingEmployeeDetails
          : 0
      }
    >
      <EmployeesTable
        insuranceEmployees={insuranceEmployees}
        fetchNextInsuranceEmployeesAction={fetchNextInsuranceEmployeesAction}
        insuranceEstimate={insuranceEstimate}
      />
    </EmployeesListBox>
  );
}

function SelectBox({
  label,
  className,
  children,
}: PropsWithChildren<{ label: string; className?: string }>) {
  return (
    <div className={`${className} flex flex-col space-y-2 w-full`}>
      <label>{label}</label>
      {children}
    </div>
  );
}

function QuoteBox({
  insuranceEstimate,
  insurance,
  updateInsuranceAction,
  purchaseInsuranceAction,
  loggedInUser,
}: {
  insuranceEstimate: IInsuranceEstimate;
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  purchaseInsuranceAction: typeof purchaseInsurance;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();
  const totalEmployeesGivenDetails = insuranceEstimate.data
    ? insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length
    : 0;
  const totalEligibleEmployees = insuranceEstimate.data?.totalEligibleEmployees
    ? insuranceEstimate.data.totalEligibleEmployees
    : 0;
  const finalize = () => {
    insurance.data && insurance.data.id && dispatch(purchaseInsuranceAction(insurance.data.id));
  };
  return (
    <div className={`bg-rhino p-5 relative w-full space-y-5`}>
      {insuranceEstimate.isFetching && insuranceEstimate.data === null && (
        <div
          className={`${
            insuranceEstimate.isFetching ? '' : 'opacity-0'
          } absolute right-0 left-0 top-0 bottom-0 flex flex-col text-center`}
        >
          <div className="">loading</div>
        </div>
      )}

      <div className="font-bold text-4xl font-heading text-white">Quote</div>
      {insuranceEstimate.data?.exactEstimate.estimatedForEmployeeIds.length === 0 && (
        <div className="text-normal secondary-text">
          Quote will be generated after your employees start providing details.
        </div>
      )}
      {insuranceEstimate.data &&
        insuranceEstimate.data?.exactEstimate.estimatedForEmployeeIds.length > 0 && (
          <div className="text-normal secondary-text">
            Quotation includes only those employees who have filled their insurance details.
          </div>
        )}

      <div className="flex flex-row flex-wrap">
        <SelectBox label="Plan" className={`sm:w-56 my-2 sm:mr-2`}>
          <Select
            onChange={(event) => {
              insurance.data?.id &&
                dispatch(
                  updateInsuranceAction(insurance.data.id, {
                    planId: +event.target.value,
                  }),
                );
            }}
            value={insurance.data?.planId || 0}
          >
            <Option value={1}>Starter</Option>
            <Option value={2}>Essential</Option>
            <Option value={3}>Premium</Option>
          </Select>
        </SelectBox>
        <SelectBox label="Coverage" className={`sm:w-56  my-2 sm:mr-2`}>
          <Select
            onChange={(event) => {
              insurance.data?.id &&
                dispatch(
                  updateInsuranceAction(insurance.data.id, {
                    sumInsured: +event.target.value,
                  }),
                );
            }}
            value={insurance.data?.sumInsured || 0}
          >
            <Option value={100000}>₹1 Lakh</Option>
            <Option value={200000}>₹2 Lakh</Option>
            <Option value={300000}>₹3 Lakh</Option>
            <Option value={500000}>₹5 Lakh</Option>
          </Select>
        </SelectBox>
        <SelectBox label="Add ons" className={`my-2 sm:mr-2`}>
          <Select
            onChange={(event) => {
              let selections = event.target.value.split(',');

              insurance.data?.id &&
                dispatch(
                  updateInsuranceAction(insurance.data.id, {
                    withChildrenAndSpouse: selections[1] === '1' ? true : false,
                    withParentsOrInLaws: selections[2] === '1' ? true : false,
                  }),
                );
            }}
            value={(() => {
              let selections = [];
              selections.push(1);
              selections.push(insurance.data?.withChildrenAndSpouse ? 1 : 0);
              selections.push(insurance.data?.withParentsOrInLaws ? 1 : 0);
              return selections.join(',');
            })()}
          >
            <Option value={'1,1,1'}> Employee + Spouse & Children + Parents </Option>
            <Option value={'1,1,0'}> Employee + Spouse & Children </Option>
            <Option value={'1,0,0'}> No add ons </Option>
          </Select>
        </SelectBox>
      </div>

      <TableLayout
        className={`-mx-3 w-full ${
          insuranceEstimate.isFetching && !insuranceEstimate.data ? 'opacity-0' : ''
        }`}
      >
        <tr className="h-20">
          <td className="text-3xl">Total Lives</td>
          <td className="font-semibold text-right text-white text-3xl">
            {insuranceEstimate.data
              ? (
                  insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length +
                  insuranceEstimate.data.exactEstimate.estimatedForDependentIds.length
                ).toLocaleString()
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl">Cost of insurance</td>
          <td className="font-semibold text-right text-white text-3xl">
            {insuranceEstimate.data
              ? toIndianCurrencyString(insuranceEstimate.data.exactEstimate.amount)
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-3xl">GST (18%)</td>
          <td className="font-semibold text-right text-white text-3xl">
            {insuranceEstimate.data
              ? toIndianCurrencyString(insuranceEstimate.data.exactEstimate.gst)
              : 'N/A'}
          </td>
        </tr>
        <tr className="h-20">
          <td className="text-4xl font-semibold font-heading">Grand Total</td>
          <td className="font-semibold text-right text-primary text-5xl">
            {insuranceEstimate.data
              ? toIndianCurrencyString(insuranceEstimate.data.exactEstimate.totalAmount)
              : 'N/A'}
          </td>
        </tr>
      </TableLayout>
      <ShowBalanceConfirmation
        insuranceEstimate={insuranceEstimate}
        insurance={insurance}
        purchaseInsuranceAction={purchaseInsuranceAction}
        orgBalance={insuranceEstimate.data?.currentOrganizationBalance}
        loggedInUser={loggedInUser}
      />
      {/* <PrimaryButton className="w-full" onClick={finalize} disabled={!insuranceEstimate.data || totalEmployeesGivenDetails < totalEligibleEmployees}>PURCHASE</PrimaryButton> */}
      {insurance.data?.planId && insurancePlanBlocked[insurance.data?.planId] ? (
        <div className="">
          We are currently under maintenance for Insurance. Please contact support at
          xpayroll@razorpay.com for any queries.{' '}
        </div>
      ) : (
        (!insuranceEstimate.data || totalEmployeesGivenDetails < totalEligibleEmployees) && (
          <div className="">You can proceed to purchase after collecting all employees details</div>
        )
      )}
    </div>
  );
}

function ShowBalanceConfirmation({
  insuranceEstimate,
  insurance,
  purchaseInsuranceAction,
  orgBalance,
  loggedInUser,
}: {
  insuranceEstimate: IInsuranceEstimate;
  insurance: IInsurance;
  purchaseInsuranceAction: typeof purchaseInsurance;
  orgBalance?: number;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();
  const totalEmployeesGivenDetails = insuranceEstimate.data
    ? insuranceEstimate.data.exactEstimate.estimatedForEmployeeIds.length
    : 0;
  const totalEligibleEmployees = insuranceEstimate.data?.totalEligibleEmployees
    ? insuranceEstimate.data.totalEligibleEmployees
    : 0;
  const conditionsNotMet = getConditionsNotMetToPurchaseInsurance(
    insuranceEstimate,
    loggedInUser,
    insurance,
  );
  return (
    <>
      {' '}
      {conditionsNotMet.length > 0 ? (
        <ul className="space-y-1 mt-2 list-disc px-10 bg-persian-blue py-4">
          {conditionsNotMet.map((message) => (
            <li className="text-3xl font-bold"> {message} </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      <ShowBalanceConfirmationModal
        disabled={
          !insuranceEstimate.data ||
          totalEmployeesGivenDetails < totalEligibleEmployees ||
          conditionsNotMet.length > 0 ||
          (insurance.data?.planId !== undefined &&
            insurance.data?.planId !== null &&
            insurancePlanBlocked[insurance.data?.planId])
        }
        purchaseCallback={() => {
          insurance.data &&
            insurance.data.id &&
            dispatch(purchaseInsuranceAction(insurance.data.id));
        }}
        totalAmount={insuranceEstimate.data ? insuranceEstimate.data.exactEstimate.totalAmount : 0}
        orgBalance={orgBalance}
      />
    </>
  );
}

export function InsuranceFinalize({
  sendReminderAction,
  insurance,
  updateInsuranceAction,
  insuranceEstimate,
  fetchInsuranceEmployeesAction,
  insuranceEmployees,
  fetchNextInsuranceEmployeesAction,
  purchaseInsuranceAction,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  insuranceEstimate: IInsuranceEstimate;
  fetchInsuranceEmployeesAction: typeof fetchInsuranceEmployees;
  insuranceEmployees: IInsuranceEmployees;
  fetchNextInsuranceEmployeesAction: typeof fetchNextInsuranceEmployees;
  purchaseInsuranceAction: typeof purchaseInsurance;
  sendReminderAction: typeof sendReminder;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();
  if (insurance.data?.status === 'DATA_COLLECTION_IN_PROGRESS') {
    return (
      <ContentContainer showSidebar={false}>
        <div className="flex flex-col-reverse space-y-10 space-y-reverse lg:space-y-0 lg:flex-row lg:px-5 py-10">
          <div className="flex-1">
            <div className="space-y-12">
              <DataCollectionProgress insuranceEstimate={insuranceEstimate} />
              <EmployeesList
                sendReminderAction={sendReminderAction}
                insuranceEstimate={insuranceEstimate}
                insurance={insurance}
                fetchInsuranceEmployeesAction={fetchInsuranceEmployeesAction}
                insuranceEmployees={insuranceEmployees}
                fetchNextInsuranceEmployeesAction={fetchNextInsuranceEmployeesAction}
              />
            </div>
          </div>
          <div className="lg:px-16 w-full lg:w-2/5">
            <QuoteBox
              insuranceEstimate={insuranceEstimate}
              insurance={insurance}
              updateInsuranceAction={updateInsuranceAction}
              purchaseInsuranceAction={purchaseInsuranceAction}
              loggedInUser={loggedInUser}
            />
          </div>
        </div>
      </ContentContainer>
    );
  } else {
    return (
      <RequestForDataCollection
        insurance={insurance}
        updateInsuranceAction={updateInsuranceAction}
        insuranceEstimate={insuranceEstimate}
        dispatch={dispatch}
      />
    );
  }
}

export default function InsuranceFinalizeContainer({
  insurance,
  updateInsuranceAction,
  insuranceEstimate,
  loggedInUser,
}: {
  insurance: IInsurance;
  updateInsuranceAction: typeof updateInsurance;
  insuranceEstimate: IInsuranceEstimate;
  loggedInUser: ILoggedInUser;
}) {
  const dispatch = useDispatch();

  const insuranceEmployees = useReduxSelector((state) => state.insuranceEmployees);

  useEffect(() => {
    dispatch(fetchInsuranceEstimate(insurance.data?.id ? insurance.data.id : null));
  }, [insurance.data?.id]);

  useEffect(() => {
    if (insurance.data?.organizationId) {
      dispatch(fetchInsuranceEmployees({ organizationId: insurance.data.organizationId }));
    }
  }, [insurance.data?.organizationId]);

  return (
    <Switch>
      {/* <Route path="/finalize/employee/:employeeId" exact>
           <EmployeeDetailsContainer/>
        </Route> */}
      <Route path={routePaths.insurance.admin.finalize}>
        <InsuranceFinalize
          insurance={insurance}
          updateInsuranceAction={updateInsuranceAction}
          insuranceEstimate={insuranceEstimate}
          fetchInsuranceEmployeesAction={fetchInsuranceEmployees}
          insuranceEmployees={insuranceEmployees}
          sendReminderAction={sendReminder}
          fetchNextInsuranceEmployeesAction={fetchNextInsuranceEmployees}
          purchaseInsuranceAction={purchaseInsurance}
          loggedInUser={loggedInUser}
        />
      </Route>
    </Switch>
  );
}
