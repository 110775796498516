import {
    Alert,
    Box,
    Button,
    ExternalLinkIcon,
    Link,
} from '@razorpay/blade/components';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import GeneralSettings from './GeneralSettings';
import loggedInUserSelectors from '../../../reducers/loggedInUser/selectors';
import { routePaths } from 'components/Routes/data';
import TabLayout from '../../ui/Layout/TabLayout';
import Breadcrumb from 'components/ui/Breadcrumb';
import DeclarationWindowSettings from './DeclarationWindowSettings';
import ProofUploadWindowSettings from './ProofUploadWindowSettings';
import CustomWindowConfigSettings from './CustomWindowConfigSettings';
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AppError } from 'utils/AppError';
import Errors from 'components/ui/Form/Errors';
import { showToastViaEvent } from 'utils/ToastEvents';
import { initSettings, getWindowErrors } from './utils';
import { TaxDeclarationSettingsSchemaContract } from 'schemas/TaxDeclarationSettingsSchema';
import { GET_ORG_TAX_DECLARATION_SETTINGS, UPDATE_ORG_TAX_DECLARATION_SETTINGS, FETCH_GENERIC_WINDOW, CREATE_UPDATE_WINDOW } from './queries';
import { GenericDeclaration } from 'components/ui/DeclarationWindow/types';
import { getTabValue, tabItems, TAB_VALUES, WindowType, WINDOW_TARGET, WINDOW_TYPE } from './types';
import { createPayload, parseWindow } from '../../ui/DeclarationWindow/utils';


const TaxDeclarationAndProofSettings = () => {

    const location = useLocation();
    const org = useSelector(loggedInUserSelectors.currentOrganization);
    const pathname = location.pathname;
    const containerRef = useRef<HTMLDivElement>(null);
    const hasProPlan = useSelector(loggedInUserSelectors.isOnProPlan);
    const [settings, setSettings] = useState(initSettings());
    const [declarationWindow, setDeclarationWindow] = useState<GenericDeclaration>({ type: 'always' });
    const [proofUploadWindow, setProofUploadWindow] = useState<GenericDeclaration>({ type: 'always' });
    const [taxCalculationsOnVerifiedProofs, setTaxCalculationsOnVerifiedProofs] = useState(true);

    const history = useHistory();

    const [currentTab, setCurrentTab] = useState(getTabValue(pathname));

    const handleSettingsChange = (
        key: keyof TaxDeclarationSettingsSchemaContract,
        value: number | boolean | null,
    ) => {
        setSettings((p) => ({ ...p, [key]: value }));
    };

    const handleWindowChange = (
        value: GenericDeclaration
    ) => {
        switch (currentTab) {
            case TAB_VALUES.DECLARATION_WINDOW:
                setDeclarationWindow(value);
                break;

            case TAB_VALUES.PROOF_UPLOAD_WINDOW:
                setProofUploadWindow(value);
                break;
        }
    };

    const handleOnSave = () => {
        switch (currentTab) {
            case TAB_VALUES.GENERAL:

                updateSettings.mutate(settings);
                break;

            case TAB_VALUES.DECLARATION_WINDOW:

                updateWindow.mutate({
                    windowType: WINDOW_TYPE.DECLARATION,
                    windowObject: createPayload(declarationWindow),
                    windowTarget: WINDOW_TARGET.WINDOW_TARGET_ORGANIZATION,
                });
                break;

            case TAB_VALUES.PROOF_UPLOAD_WINDOW:

                updateWindow.mutate({
                    windowType: WINDOW_TYPE.PROOF,
                    windowObject: createPayload(proofUploadWindow),
                    windowTarget: WINDOW_TARGET.WINDOW_TARGET_ORGANIZATION,
                });
                break;
        }
    }

    const fetchOrgSettings = useQuery({
        queryKey: GET_ORG_TAX_DECLARATION_SETTINGS,
        queryFn: api.taxDeclarations.getSettings,
        onSuccess: (response) => {
            setSettings(response);
            setTaxCalculationsOnVerifiedProofs(!!response.taxCalculationsOnVerifiedProofs);
        },
        onError: (error: typeof AppError) => { },
    });

    const updateSettings = useMutation({
        mutationKey: UPDATE_ORG_TAX_DECLARATION_SETTINGS,
        mutationFn: api.taxDeclarations.updateSettings,
        onSuccess: (data) => {
            setSettings(data);
            showToastViaEvent({
                text: 'Configuration saved successfully!',
                timeout: 3000,
                type: 'success',
                icon: 'success',
            });
        },
        onError: (e: typeof AppError) => {
            window.requestAnimationFrame(() => {
                containerRef.current?.scrollIntoView();
            });
        },
    });

    const fetchGenericWindow = useQuery({
        queryKey: [FETCH_GENERIC_WINDOW, currentTab],
        queryFn: () => api.taxDeclarations.getGenericWindow(
            (currentTab === TAB_VALUES.DECLARATION_WINDOW ? WINDOW_TYPE.DECLARATION : WINDOW_TYPE.PROOF) as WindowType
        ),
        onSuccess: (response) => {
            switch (currentTab) {
                case TAB_VALUES.DECLARATION_WINDOW:
                    setDeclarationWindow(parseWindow(response));
                    break;

                case TAB_VALUES.PROOF_UPLOAD_WINDOW:
                    setProofUploadWindow(parseWindow(response));
                    break;
                
                case TAB_VALUES.CUSTOM_WINDOW:
                    if(settings.letXpayrollVerifyDocs){
                        setProofUploadWindow(parseWindow(response));
                    }
                    break;
            }
        },
        onError: (e: typeof AppError) => {
            window.requestAnimationFrame(() => {
                containerRef.current?.scrollIntoView();
            });
        },
        enabled: (
            currentTab === TAB_VALUES.DECLARATION_WINDOW || 
            currentTab === TAB_VALUES.PROOF_UPLOAD_WINDOW ||
            (settings.letXpayrollVerifyDocs && currentTab === TAB_VALUES.CUSTOM_WINDOW)
            )
    });

    const updateWindow = useMutation({
        mutationKey: CREATE_UPDATE_WINDOW,
        mutationFn: api.taxDeclarations.createUpdateWindow,
        onSuccess: (data) => {
            showToastViaEvent({
                text: 'Window saved successfully!',
                timeout: 3000,
                type: 'success',
                icon: 'success',
            });
        },
        onError: (e: typeof AppError) => {
            window.requestAnimationFrame(() => {
                containerRef.current?.scrollIntoView();
            });
        },
    });

    const disableSaveButton = () => {

        if (fetchOrgSettings.isLoading || fetchGenericWindow.isLoading) {
            return false
        }
        else if (!settings.allowEmployeeToUpdateTaxDeductions && currentTab !== TAB_VALUES.GENERAL) {
            return false
        }
        else if (settings.letXpayrollVerifyDocs && currentTab === TAB_VALUES.PROOF_UPLOAD_WINDOW) {
            return false
        }
        else if (currentTab === TAB_VALUES.DECLARATION_WINDOW && !getWindowErrors(declarationWindow).isValid) {
            return false
        }
        else if (currentTab === TAB_VALUES.PROOF_UPLOAD_WINDOW && !getWindowErrors(proofUploadWindow).isValid) {
            return false
        }
        else {
            return true;
        }

    }

    const isLoading = updateSettings.isLoading || updateWindow.isLoading;

    const apiError = fetchOrgSettings.error || updateSettings.error || fetchGenericWindow.error || updateWindow.error;

    return (
        <>
            <TabLayout
                tabs={tabItems(history)}
                navbarProps={{
                    tabsProps: { onChange: setCurrentTab, value: currentTab, variant: 'borderless' },
                }}
                BreadcrumbComponent={
                    <Breadcrumb
                        name="Tax Declaration and Proof Upload Settings"
                        urlMaps={{
                            Settings: '/settings',
                        }}
                    />
                }
                TabRightSideLayout={
                    <Link
                        href={'https://razorpay.com/docs/x/xpayroll/employers/it-declaration/'}
                        target="_blank"
                        icon={ExternalLinkIcon}
                        iconPosition="right">
                        Learn More
                    </Link>
                }
            >

                <Box width="100%" height="100%">
                    {
                        apiError &&
                        <Box marginX={{ base: 'spacing.8', l: 'auto' }} width="560px" marginTop="spacing.5">
                            <Alert
                                description={<Errors errorResponse={apiError} />}
                                color={'negative'}
                                isDismissible={false}
                            />
                        </Box>
                    }

                    <Switch>
                        <Route
                            path={routePaths.taxDeclarationAndProofSettings.settingsGeneral}
                            exact>
                            <GeneralSettings
                                settings={settings}
                                onChange={handleSettingsChange}
                                hasProPlan={hasProPlan}
                                taxCalculationsOnVerifiedProofs={taxCalculationsOnVerifiedProofs}
                            />
                        </Route>
                        <Route path={routePaths.taxDeclarationAndProofSettings.settingsDeclaration} exact>
                            <DeclarationWindowSettings
                                settings={settings}
                                declarationWindow={declarationWindow}
                                onChange={handleWindowChange}
                                isLoading={fetchGenericWindow.isLoading}
                                hasProPlan={hasProPlan}
                                errors={getWindowErrors(declarationWindow).errors}
                            />
                        </Route>
                        <Route path={routePaths.taxDeclarationAndProofSettings.settingsProofUpload} exact>
                            <ProofUploadWindowSettings
                                settings={settings}
                                proofUploadWindow={proofUploadWindow}
                                onChange={handleWindowChange}
                                isLoading={fetchGenericWindow.isLoading}
                                hasProPlan={hasProPlan}
                                errors={getWindowErrors(proofUploadWindow).errors}
                            />
                        </Route>
                        <Route path={routePaths.taxDeclarationAndProofSettings.settingsCustomWindow} exact>
                            <CustomWindowConfigSettings
                                settings={settings}
                                hasProPlan={hasProPlan}
                                proofUploadWindow={proofUploadWindow}
                            />
                        </Route>
                        <Route>
                            <Redirect to={routePaths.unknown} />
                        </Route>
                    </Switch>


                    {
                        disableSaveButton() &&
                        <Box paddingBottom="spacing.11" marginX={{ base: 'spacing.8', l: 'auto' }} width="560px">
                            <Button
                                onClick={handleOnSave}
                                isLoading={isLoading}>
                                Save & Confirm
                            </Button>
                        </Box>
                    }
                </Box>
            </TabLayout>
        </>
    );
};


export default TaxDeclarationAndProofSettings;