import React, { useState } from 'react';
import styles from './index.module.css';
function ToggleIcon({ isExpanded }: { isExpanded: boolean }) {
  if (!isExpanded) {
    return (
      <svg
        className="h-10 w-24 inline-block ml-auto"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#6297FF"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    );
  }

  return (
    <svg
      className="h-10 w-24 inline-block "
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#6297FF"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
    </svg>
  );
}
export function AccordionItem({
  heading,
  children,
}: {
  heading: string;
  children: React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <li className={`w-full ${styles['accordion-item']} ${isExpanded ? styles['active'] : ''}`}>
      <div
        className={`bg-pickled-bluewood pl-8 pr-3 py-3 cursor-pointer flex ${styles['heading']}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={styles['heading-title']}>{heading}</span>
        <div className="ml-auto flex flex-col justify-center">
          <ToggleIcon isExpanded={isExpanded} />
        </div>
      </div>
      <div className={`leading-normal pl-5 ${styles['description']}`}>
        {isExpanded ? <div className="p-5 pb-12">{children}</div> : <></>}
      </div>
    </li>
  );
}
export function Accordion({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  return <ul className={`${className ? className : ''}`}>{children}</ul>;
}
