import React from 'react';
import { Alert, Box, Text, Heading } from '@razorpay/blade/components';

interface MainContentProps {
  title: string;
  description?: string;
  children: JSX.Element[] | JSX.Element;
  alertBannerProps?: React.ComponentProps<typeof Alert>;
}

const MainContent = ({ title, description, children, alertBannerProps }: MainContentProps) => {
  return (
    (<Box
      backgroundColor="surface.background.gray.intense"
      position="relative"
      overflowY="auto">
      <Box
        height="100vh"
        padding={['spacing.10', '96px']}
        display="grid"
        gridTemplateRows="1fr auto">
        <Box display="flex" flexDirection="column" gap="spacing.10" maxWidth="512px">
          <Box display="flex" flexDirection="column" gap="spacing.3">
            <Heading size="large">{title}</Heading>
            {description && <Text color="surface.text.gray.muted">{description}</Text>}
            {alertBannerProps && <Alert marginTop="spacing.2" {...alertBannerProps} />}
          </Box>
          {children}
        </Box>
      </Box>
    </Box>)
  );
};

export { MainContent };
