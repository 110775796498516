import {
  Box,
  BoxProps,
  Spinner,
  TabItem,
  TabList,
  Tabs,
  TabsProps,
} from '@razorpay/blade/components';
import leftImg from 'assets/layouts/circle_left.svg';
import rightImg from 'assets/layouts/right_circle.svg';
import React from 'react';
import { LeftImg, RightImg } from './styles';

interface PageLayoutProps {
  tabs: Array<React.ComponentProps<typeof TabItem>>;
  isLoading?: boolean;
  wrapperProps?: BoxProps;
  containerProps?: BoxProps;
  TabRightSideLayout?: React.ReactNode;
  BreadcrumbComponent?: React.ReactNode;
  navbarProps?: {
    navContainerProps?: BoxProps;
    tabsProps?: Omit<TabsProps, 'children'>;
  };
}

const TabLayout = ({
  tabs,
  isLoading = false,
  wrapperProps = {},
  containerProps = {},
  TabRightSideLayout,
  BreadcrumbComponent,
  children,
  navbarProps,
}: React.PropsWithChildren<PageLayoutProps>) => {
  const { tabsProps, navContainerProps } = navbarProps || {};
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      position="relative"
      {...wrapperProps}>
      <LeftImg src={leftImg} />
      <RightImg src={rightImg} />
      <Box display="flex" flexDirection={'row'} marginX={'spacing.6'}>
        <Box marginRight={'auto'}>
          {BreadcrumbComponent}
        </Box>
      </Box>
      <Box
        borderBottomColor="surface.border.gray.normal"
        borderBottomWidth="thin"
        {...navContainerProps}>
        <Tabs {...tabsProps}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX="spacing.6">
            <TabList>
              {tabs.map((e) => (
                <TabItem {...e} key={e.value} />
              ))}
            </TabList>
            {TabRightSideLayout}
          </Box>
        </Tabs>
      </Box>

      <Box overflowY={'auto'} display={'flex'} flex="1" position={'relative'} {...containerProps}>
        {isLoading ? <Spinner accessibilityLabel="Loading" margin="auto" /> : children}
      </Box>
    </Box>
  );
};

export default TabLayout;
