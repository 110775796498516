import {
  GetWorkflowRequestDetailsGQLResponse,
  GetWorkflowRequestsGQLResponse,
  GetWorkflowRequestsGQLResponseTransformedForFE,
  TransformedActivityLog,
} from 'api/gql-utils/workflows/types';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';
import { GetWorkflowRequestsContract } from 'schemas/GetWorkflowRequests';

export const getTransformedPerson = (person: {
  peopleId: string;
  name: string;
  employeeId: string | null;
  employeeType: string;
  idPrefix: string | null;
}) => {
  return {
    people_id: parseInt(person.peopleId), // graphQL ids are string, our API uses numbers
    name: person.name,
    employee_id: person.employeeId,
    employee_type: person.employeeType,
    id_prefix: person.idPrefix,
  };
};

export const transformDataForWorkflowRequests = (
  data: GetWorkflowRequestsGQLResponse | GetWorkflowRequestsContract,
): GetWorkflowRequestsContract => {
  if (!('PayrollWorkflowRequests' in (data as GetWorkflowRequestsGQLResponse))) {
    return data as GetWorkflowRequestsContract;
  }

  const payrollWorkflowRequests = (data as GetWorkflowRequestsGQLResponse).PayrollWorkflowRequests;

  const peopleMetaData: {
    [k: string]: {
      people_id: number;
      employee_type: string;
      employee_id: string | null;
      name: string;
      id_prefix: string | null;
      [k: string]: unknown;
    };
  } = {};

  const transformedData: GetWorkflowRequestsGQLResponseTransformedForFE['data'] = [];

  payrollWorkflowRequests.data.forEach((item) => {
    peopleMetaData[item.person.peopleId] = getTransformedPerson(item.person);
    if (item.forPerson) {
      peopleMetaData[item.forPerson?.peopleId] = getTransformedPerson(item.forPerson);
    }
    if (item.updatedByPerson) {
      peopleMetaData[item.updatedByPerson.peopleId] = getTransformedPerson(item.updatedByPerson);
    }

    transformedData.push({
      id: parseInt(item.id),
      people_id: item.peopleId ? parseInt(item.peopleId) : null,
      person: item.person,
      organization_id: parseInt(item.organizationId),
      type: item.type,
      old_data: item.oldData,
      new_data: item.newData,
      created_at: item.createdAt,
      status: item.status,
      updated_at: item.updatedAt,
      updated_by: item.updatedBy,
      updated_by_person: item.updatedByPerson,
      deleted_at: item.deletedAt,
      pending_on_level: item.pendingOnLevel,
      source: item.source,
      for_people_id: item.forPeopleId ? parseInt(item.forPeopleId) : null,
      for_person: item.forPerson,
      payload: item.payload,
    });
  });

  return {
    current_page: payrollWorkflowRequests.currentPage,
    data: transformedData,
    from: payrollWorkflowRequests.from,
    last_page: payrollWorkflowRequests.lastPage,
    path: payrollWorkflowRequests.path,
    per_page: payrollWorkflowRequests.perPage,
    to: payrollWorkflowRequests.to,
    total: payrollWorkflowRequests.total,
    people_metadata: peopleMetaData,
  };
};

export const transformDataForWorkflowRequest = (
  data: GetWorkflowRequestDetailsGQLResponse | GetWorkflowRequestDetailsContract,
): GetWorkflowRequestDetailsContract => {
  if ('PayrollWorkflowRequest' in (data as GetWorkflowRequestDetailsGQLResponse)) {
    const payrollWorkflowRequest = (data as GetWorkflowRequestDetailsGQLResponse)
      .PayrollWorkflowRequest;

    const { workflowRequestsData, workflowData, activityLog } = payrollWorkflowRequest;

    const peopleMetaData: {
      [k: string]: {
        people_id: number;
        employee_type: string;
        employee_id: string | null;
        name: string;
        id_prefix: string | null;
        [k: string]: unknown;
      };
    } = {};
    if (workflowRequestsData.forPerson) {
      peopleMetaData[workflowRequestsData.forPerson.peopleId] = getTransformedPerson(
        workflowRequestsData.forPerson,
      );
    }

    peopleMetaData[workflowRequestsData.peopleId] = getTransformedPerson(
      workflowRequestsData.person,
    );

    const activityLogLevelOne = activityLog.level1 ?? [];
    const activityLogTotal = activityLogLevelOne.concat(activityLog.level2 ?? []);
    const transformedActivityLog: { [k: string]: TransformedActivityLog[] } = {};

    activityLogTotal.forEach((log) => {
      peopleMetaData[log.byPeopleId] = getTransformedPerson(log.byPerson);
    });

    Object.keys(activityLog).forEach((key) => {
      const log = activityLog[key];

      if (log) {
        transformedActivityLog[key] = log.map((activity) => ({
          id: parseInt(activity.id),
          organization_id: parseInt(activity.organizationId),
          changes_staging_id: parseInt(activity.changesStagingId),
          workflow_id: parseInt(activity.workflowId),
          level: activity.level,
          activity: activity.activity,
          by_people_id: parseInt(activity.byPeopleId),
          by_person: getTransformedPerson(activity.byPerson),
          updated_at: activity.updatedAt,
          remarks: activity.remarks,
        }));
      }
    });

    const approversByLevel = workflowData.approvers.levels ?? [];

    approversByLevel.forEach((level) => {
      const people = level.people ?? [];

      people.forEach((person) => {
        peopleMetaData[person.peopleId] = getTransformedPerson(person);
      });
    });

    if (workflowData.createdByPerson) {
      peopleMetaData[workflowData.createdByPerson.peopleId] = getTransformedPerson(
        workflowData.createdByPerson,
      );
    }

    if (workflowData.deletedByPerson) {
      peopleMetaData[workflowData.deletedByPerson.peopleId] = getTransformedPerson(
        workflowData.deletedByPerson,
      );
    }

    if (workflowRequestsData.updatedByPerson) {
      peopleMetaData[workflowRequestsData.updatedByPerson.peopleId] = getTransformedPerson(
        workflowRequestsData.updatedByPerson,
      );
    }

    return {
      workflow_requests_data: {
        id: parseInt(workflowRequestsData.id),
        people_id: parseInt(workflowRequestsData.peopleId),
        person: workflowRequestsData.person,
        organization_id: parseInt(workflowRequestsData.organizationId),
        type: workflowRequestsData.type,
        old_data: workflowRequestsData.oldData,
        new_data: workflowRequestsData.newData,
        created_at: workflowRequestsData.createdAt,
        status: workflowRequestsData.status,
        updated_at: workflowRequestsData.updatedAt,
        updated_by: workflowRequestsData.updatedBy,
        updated_by_person: workflowRequestsData.updatedByPerson,
        deleted_at: workflowRequestsData.deletedAt,
        pending_on_level: workflowRequestsData.pendingOnLevel,
        source: workflowRequestsData.source,
        for_people_id: workflowRequestsData.forPeopleId
          ? parseInt(workflowRequestsData.forPeopleId)
          : null,
        for_person: workflowRequestsData.forPerson,
        payload: workflowRequestsData.payload,
      },
      workflow_data: {
        id: parseInt(workflowData.id),
        organization_id: parseInt(workflowData.organizationId),
        action_type: workflowData.actionType,
        approvers: workflowData.approvers,
        status: workflowData.status,
        version: workflowData.version,
        created_by: workflowData.createdBy,
        created_by_person: workflowData.createdByPerson,
        deleted_by: workflowData.deletedBy,
        deleted_by_person: workflowData.deletedBy,
        created_at: workflowData.createdAt,
        updated_at: workflowData.updatedAt,
        deleted_at: workflowData.deletedAt,
      },
      activity_log: transformedActivityLog,
      people_metadata: peopleMetaData,
    };
  } else {
    return data as GetWorkflowRequestDetailsContract;
  }
};
