import React from 'react';
import { Badge, Box, Text } from '@razorpay/blade/components';
import { LabelValuePair } from 'components/RunPayroll/ComplianceActions/ActionDetailsView/LabelValuePair';
import {
  badgeColorLabelMap,
  statusColorLabelMap,
} from 'components/RunPayroll/ComplianceActions/data';
import { GetComplianceActionsSchemaContract } from 'schemas/GetComplianceActionsSchema';
import { dateFormats, getFormattedDateValue } from 'utils/Dates';

export const PaymentInformation = ({
  action,
  payrollActionDetails,
  state,
}: {
  action: GetComplianceActionsSchemaContract['actions']['pay-pt'][string];
  payrollActionDetails?: GetComplianceActionsSchemaContract['actions']['run-payroll'];
  state: string;
}) => {
  const dueDate = action.meta.due_date;

  const isPaymentOverdue = action.meta.is_over_due;

  const dueDateFormatted = getFormattedDateValue({
    date: dueDate,
    formatString: dateFormats.dateShortMonthCommaYear,
  });

  const payrollExecutedDateFormatted = getFormattedDateValue({
    date: payrollActionDetails?.meta.last_payroll_executed,
    formatString: dateFormats.dateMonthCommaYearCommaTime,
  });

  const paymentMadeOnFormatted = getFormattedDateValue({
    date: action?.meta.payment_made_on,
    formatString: dateFormats.dateShortMonthCommaYear,
  });

  const statusProps = statusColorLabelMap[action.status];

  return (
    <Box display="flex" flexDirection="column" gap="spacing.5" marginTop="spacing.7">
      <LabelValuePair
        label="Current status"
        value={
          <Badge testID={`details-badge-${statusProps.label}`} color={statusProps?.color}>
            {statusProps.label}
          </Badge>
        }
      />
      <LabelValuePair
        label="Due date"
        value={
          isPaymentOverdue ? (
            <Text color="surface.text.gray.subtle">
              {dueDateFormatted}
              <Badge
                testID={`details-badge-overdue`}
                color={badgeColorLabelMap.overdue?.color}
                marginLeft="spacing.2">
                Overdue
              </Badge>
            </Text>
          ) : (
            dueDateFormatted
          )
        }
      />

      <LabelValuePair label="Professional tax state" value={state || '--'} />

      {action.status === 'paid_manually' ? (
        <LabelValuePair label="Marked as paid on" value={paymentMadeOnFormatted} />
      ) : null}

      {/* x_tax_TODO: check which count */}
      <LabelValuePair
        label="Employee count"
        value={action.meta.pt_payment_employee_count || '--'}
      />
      <LabelValuePair label="Last payroll executed on" value={payrollExecutedDateFormatted} />
      <LabelValuePair label="Payment to" value={`${state} PT Portal`} />
    </Box>
  );
};
