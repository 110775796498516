export const keyCodes = {
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ESCAPE_IE: 'Esc', // IE/Edge specific value
  ESCAPE: 'Escape',
  TAB: 'Tab',
  DELETE: 'Delete',
  DELETE_IE: 'Del', // older IE/Firefox
  BACKSPACE: 'Backspace',
};
