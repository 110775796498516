import React, { forwardRef } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

export interface ExpansionListProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const ExpansionList = forwardRef<HTMLDivElement, ExpansionListProps>(function ExpansionList(
  { children, ...props },
  ref,
) {
  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
});
