import api from 'api';
import { useMutation } from 'react-query';
import { GetComputedRlopArrearBreakdownContract } from 'schemas/GetComputedRlopArrearBreakdown';
import { SuccessResponseSchemaContract } from 'schemas/SuccessResponseSchema';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { showErrorToastNotification } from 'utils/ToastEvents';

type UseRlopInfoProps = {
  onComputeRlopArrearsSuccess?: (data: GetComputedRlopArrearBreakdownContract) => void;
  onAddRlopDaysSuccess?: (data: SuccessResponseSchemaContract) => void;
};

const useRlopInfo = ({ onComputeRlopArrearsSuccess, onAddRlopDaysSuccess }: UseRlopInfoProps) => {
  const updateRlopDays = useMutation({
    mutationFn: api.runPayroll.addRlopDays,
    onSuccess: (data) => {
      onAddRlopDaysSuccess?.(data);
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 3000,
      });
    },
  });

  const computeRlopArrears = useMutation({
    mutationFn: api.runPayroll.computeRlopArrearBreakdown,
    onSuccess: (data) => {
      onComputeRlopArrearsSuccess?.(data);
    },
    onError: (error: typeof AppError) => {
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 3000,
      });
    },
  });

  return {
    updateRlopDays,
    computeRlopArrears,
  };
};

export default useRlopInfo;
