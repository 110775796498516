import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BannerImageSection } from "./BannerImageSection";
import { HeadingsAndSubHeadingsSection } from "./HeadingsAndSubHeadingsSection";
import { BenefitsSection } from "./BenefitsSection";
import ScheduleDemoLink from "./ScheduleDemoLink"; 
import { WhyXPayrollSection } from "./WhyXPayrollSection";
import { ZaggleChargesInfo } from "./ZaggleChargesInfo";
import { PrimaryButtonV2 as PrimaryButton } from 'components/ui/Button';
import { routePaths } from 'components/Routes/data';
import { FETCH_ORG_FLEXI_PARTNER_DETAILS } from '../utils/queries';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import api from '../../../../api';
import PageWrapper from 'components/ui/PageWrapper';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import { PartnerFlexiBenefitsFAQ } from 'components/PartnerFlexiBenefits/PartnerFlexiBenefitsFAQ';
import { hasStartedFillingTheDetailsAlready } from '../utils/helpers';

const ZaggleAdminInfo = () => {
    const history = useHistory();

    const redirectToManage = () => {
        history.push(routePaths.partnerFlexiBenefits.admin.manage);
    }
    
    const orgId = useSelector(loggedInUserSelectors.organizationID);
    const { data } = useQuery(FETCH_ORG_FLEXI_PARTNER_DETAILS, () => {
        if (!orgId) {
          return null;
        }
        return api.flexiPartnerDetails.fetchOrgFlexiPartnerDetails(orgId);
      });

    return <div className='space-y-20 w-full md:px-0 px-10 md:w-260 mx-auto'>
        <BannerImageSection />
        <HeadingsAndSubHeadingsSection />
        <BenefitsSection />
        <WhyXPayrollSection />
         <ZaggleChargesInfo />
        <ScheduleDemoLink /> 
        <div className="w-full flex content-center">
            <PrimaryButton className='mx-auto' onClick={redirectToManage}>
                { hasStartedFillingTheDetailsAlready(data) ? "Continue Setup" : "Start Setup" }
            </PrimaryButton>
        </div>
        <br />
    </div>;
}


const ZaggleAdminInfoWrapper = () => {
    return (
        <PageWrapper>
            <MainCase>
                <ZaggleAdminInfo />
            </MainCase>
            <RightSidebar>
                <PartnerFlexiBenefitsFAQ />
            </RightSidebar>
        </PageWrapper>
    );
}
export default ZaggleAdminInfoWrapper;