
import React, { MouseEvent, ReactElement, useState } from 'react';
import { Badge, Box, Heading, Text, Button, TrashIcon, Divider, Amount, BankIcon } from '@razorpay/blade/components';
import { CardFooterTextRegular, CardFooterTextBold, CardWrapper } from '../styles';
import { bonusSource, BonusSource, BonusStatus, bonusStatus } from '../types';
import { Platform } from '@razorpay/blade/utils';
import { toIndianCurrencyString } from 'utils/Numbers';

interface CardProps {
    key: string | number;
    title: string;
    amount: number;
    payoutMonth?: string;
    remark?: string;
    clawbackRulesText?: string;
    status: BonusStatus;
    source?: BonusSource;
    canEdit?: boolean;
    canDelete?: boolean;
    onEditClick?: () => void;
    onDeleteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const getPill = (status?: BonusStatus, source?: BonusSource) => {

    switch (status) {
        case bonusStatus.BONUS_STATUS_PAID as string:

            switch (source) {
                case bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL as string:
                    return <Badge emphasis="subtle" size="medium" color="positive">{`Paid via XPayroll`}</Badge>;

                case bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL as string:
                    return <Badge emphasis="subtle" size="medium" color="notice">{`Paid outside XPayroll`}</Badge>;

                default:
                    return <Badge emphasis="subtle" size="medium" color="primary">{`Paid`}</Badge>;
            }

        case bonusStatus.BONUS_STATUS_PENDING as string:
            return <Badge emphasis="subtle" size="medium" color="neutral">{`Yet to be Paid`}</Badge>;

        case bonusStatus.BONUS_STATUS_CLAWBACK_APPLIED as string:
            return <Badge emphasis="subtle" size="medium" color="information">{`Clawback Applied`}</Badge>;

        default:
            return <Badge emphasis="subtle" size="medium" color="neutral">{`Status Not Found`}</Badge>;


    }
}

const BonusCard = ({ key, title, amount, payoutMonth, remark, clawbackRulesText, status, source, canDelete = false, canEdit = false, onEditClick, onDeleteClick }: CardProps): ReactElement => {

    return (
        (<CardWrapper key={key} allowClick={canEdit} onClick={onEditClick}>
            <Box display="flex" gap="spacing.5" flex="1">
                <Box display="flex" flexDirection="column" gap="spacing.5">
                    <Box display="flex" flexDirection="column" gap="spacing.4">
                        <Box display="flex" gap="spacing.4" alignItems="center">
                            <Box display="flex" flexDirection="column" gap="spacing.0">
                                <Box display="flex" flexDirection="row" gap="spacing.3">
                                    <Box backgroundColor={'surface.background.gray.moderate'} height={'28px'} width={'28px'} marginTop={'spacing.1'} borderRadius={'medium'}>
                                        <BankIcon
                                            size="medium"
                                            color="feedback.icon.notice.intense"
                                            marginX={'auto'}
                                            marginTop={'6px'}
                                        />
                                    </Box>
                                    <Heading weight={'semibold'} color='surface.text.gray.muted' size="medium">
                                        {title}
                                    </Heading>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box marginLeft={'auto'}>
                    {getPill(status, source)}
                </Box>
            </Box>
            <Box display="flex" gap="spacing.8" flex="1">
                <Box display="flex" flexDirection="column" gap="spacing.1">
                    <Text weight="semibold" size="xsmall" color='surface.text.gray.disabled'>
                        PAYOUT AMOUNT
                    </Text >
                    <Text weight="semibold" size="large">
                        {toIndianCurrencyString(amount,false)}
                    </Text>
                </Box>
                <Box display="flex" flexDirection="column" gap="spacing.1">
                    <Text weight="semibold" size="xsmall" color='surface.text.gray.disabled'>
                        PAYOUT MONTH
                    </Text>
                    <Text weight="semibold" size="large">
                        {payoutMonth}
                    </Text>
                </Box>
                <Box display="flex" flexDirection="column" gap="spacing.1">
                    <Text weight="semibold" size="xsmall" color='surface.text.gray.disabled'>
                        CLAWBACK MONTH
                    </Text>
                    <Text weight="semibold" size="large">
                        {clawbackRulesText}
                    </Text>
                </Box>
            </Box>
            <Divider width={'100%'} />
            <Box display={'flex'} flexDirection={'row'} gap={'spacing.3'} justifyContent={'space-between'}>
                <Box marginY={'auto'}>
                    <CardFooterTextBold>{ remark ? `Remark: ` : '' }</CardFooterTextBold><CardFooterTextRegular>{(remark && remark !== '') ? remark : ''}</CardFooterTextRegular>
                </Box>
                {canDelete &&
                    <Box display={'flex'} flexDirection={'row'} gap={'spacing.3'}>
                        <Box width={'34px'}>
                            <Button icon={TrashIcon} onClick={onDeleteClick} variant='tertiary' size='small' isFullWidth={true} />
                        </Box>
                    </Box>
                }
            </Box>
        </CardWrapper>)
    );
}

export { BonusCard };