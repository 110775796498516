import React from 'react';
import { Box, Button, Modal, Text } from '@razorpay/blade/components';
import ActionModal from '../components/ActionModal';

type Props = Pick<React.ComponentProps<typeof Modal>, 'isOpen' | 'onDismiss'> & {
  componentName: string;
  onSubmit: () => void;
  isLoading?: boolean;
};

const DisableModal = ({ componentName, isOpen, onDismiss, onSubmit, isLoading }: Props) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      headingText={`Disable ${componentName} ?`}
      submitButton={
        <Button
          testID={`disable-component-button`}
          size={'medium'}
          onClick={onSubmit}
          color={'negative'}
          isLoading={isLoading}>
          Disable Now
        </Button>
      }>
      <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
          <Text size="small" color={'surface.text.gray.muted'} weight={'medium'}>
            What will happen?
          </Text>
          <Text color={'surface.text.gray.subtle'}>
            Once disabled, it cannot be used in an employee's salary structure.
          </Text>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'spacing.2'}>
          <Text size="small" color={'surface.text.gray.muted'} weight={'medium'}>
            When can a component be disabled?
          </Text>
          <Text color={'surface.text.gray.subtle'}>
            A component can only be disabled if not used in any salary structure of active employee.
          </Text>
        </Box>
      </Box>
    </ActionModal>
  );
};

export default DisableModal;
