import {
  ActionList,
  ActionListItem,
  Box,
  Dropdown,
  DropdownOverlay,
  SelectInput,
  Spinner,
} from '@razorpay/blade/components';
import { UploadKycDocumentApiPayload } from 'api/kyc';
import {
  getAddressProofSelectedDocumentType,
  getErrorsAsString,
  getMessageFromStatus,
} from 'components/Kyc/utils';
import { MimeType } from 'components/ui/FileUpload/constants';
import React, { useContext } from 'react';
import { UploadKycDocumentSchemaContract } from 'schemas/UploadKycDocumentSchema';
import { AppError } from 'utils/AppError';
import { KycDocumentsContext } from '../../KycDocumentsContext';
import {
  DocumentStatus,
  addressProofGroups,
  addressProofOptions,
  bvsDocumentTypes,
} from '../../constants';
import { ProofAddress, UploadDocumentSectionProps } from '../../types';
import DocumentUpload from '../DocumentUpload';
import Section from '../Section';
import { useIsSelfServeP3M1Enabled } from 'components/Kyc/hooks/useIsSelfServeP3M1Enabled';

const Fields = [
  bvsDocumentTypes.AADHAAR_BACK,
  bvsDocumentTypes.AADHAAR_FRONT,
  bvsDocumentTypes.AADHAAR,
  bvsDocumentTypes.PASSPORT,
  bvsDocumentTypes.PASSPORT_BACK,
  bvsDocumentTypes.VOTERS_ID,
  bvsDocumentTypes.VOTERS_ID_BACK,
  bvsDocumentTypes.PERSONAL_PAN,
];

type FieldNames = typeof Fields[number];

const AddressProofSection = ({ orgType }: UploadDocumentSectionProps) => {
  const { kycValues, setKycValues, updateAuthorizedSignatoryDetailsDocument } =
    useContext(KycDocumentsContext);
  const proofType = kycValues.authorizedSignatoryDetails.addressProof?.name;
  const data = kycValues.authorizedSignatoryDetails;

  const { isSelfServeP3M1Enabled, isLoading: isFetchingSelfServeP3M1Experiment } =
    useIsSelfServeP3M1Enabled();

  const onFileSelect = (
    documentType: FieldNames,
    files: File[] | null,
    upload: (payload: UploadKycDocumentApiPayload) => void,
  ) => {
    const file = files?.[0] || null;
    if (!file) return;
    updateAuthorizedSignatoryDetailsDocument(documentType, {
      file,
      status: DocumentStatus.UPLOADING,
      saved_filename: '',
      message: getMessageFromStatus({ status: DocumentStatus.UPLOADING }),
    });
    upload({
      documentType,
      file,
      orgType,
    });
  };

  const onSuccess = (documentType: FieldNames, response: UploadKycDocumentSchemaContract) => {
    updateAuthorizedSignatoryDetailsDocument(documentType, {
      status: DocumentStatus.SUCCESS,
      saved_filename: response.filename || '',
      url: response.fileS3Url || '',
      message: getMessageFromStatus({ status: DocumentStatus.SUCCESS }),
    });
  };

  const onError = (documentType: FieldNames, error: AppError) => {
    updateAuthorizedSignatoryDetailsDocument(documentType, {
      status: DocumentStatus.ERROR,
      message: getMessageFromStatus({
        status: DocumentStatus.ERROR,
        error: getErrorsAsString(error),
      }),
    });
  };

  const setProofType = (name: ProofAddress) => {
    setKycValues((p) => ({
      ...p,
      authorizedSignatoryDetails: {
        ...p.authorizedSignatoryDetails,
        addressProof: {
          ...p.authorizedSignatoryDetails.addressProof,
          name,
          message: '',
        },
      },
    }));
  };

  const selectedDocumentType = getAddressProofSelectedDocumentType(proofType);

  const isProofSelected = !!proofType && !!selectedDocumentType;

  const checkIsSelectionDisabled = () => {
    if (!proofType) return false;
    const isVerifyingOrVerified = [DocumentStatus.VERIFIED, DocumentStatus.VERIFYING];
    if (selectedDocumentType) {
      return addressProofGroups[selectedDocumentType].items.some((e) =>
        isVerifyingOrVerified.includes(data[e]?.status || DocumentStatus.NOT_INITIATED),
      );
    } else {
      return isVerifyingOrVerified.includes(
        data[proofType]?.status || DocumentStatus.NOT_INITIATED,
      );
    }
  };

  const onFileNameClick = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  const isProofSelectionDisabled = checkIsSelectionDisabled();

  if (isFetchingSelfServeP3M1Experiment) {
    return <Spinner accessibilityLabel="loading" />;
  }

  return (
    <Section title="👩‍💻 Authorised Signatory" subtitle="details">
      <Box>
        <Dropdown selectionType="single">
          <SelectInput
            labelPosition="left"
            isRequired
            isDisabled={isProofSelectionDisabled}
            value={proofType}
            label="Address Proof*"
            placeholder="Select document"
            name="addressProofType"
            onChange={({ values }) => setProofType(values[0] as ProofAddress)}
          />
          <DropdownOverlay>
            <ActionList>
              {addressProofOptions.map((item) => (
                <ActionListItem key={item.name} title={item.name} value={item.value} />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
        {isProofSelected && (
          <Box marginTop={'spacing.4'}>
            <Box display="flex" flexDirection="column" gap="spacing.4">
              {addressProofGroups[selectedDocumentType].items.map((item, index) => (
                <DocumentUpload
                  key={item}
                  value={data[item]?.file?.name || data[item]?.saved_filename}
                  {...(addressProofGroups[selectedDocumentType].items.length === 2
                    ? {
                        label: addressProofGroups[selectedDocumentType].labels[index],
                        labelJustifyContent: 'space-between',
                      }
                    : {})}
                  allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
                  onChange={(files, upload) => onFileSelect(item, files, upload)}
                  onSuccess={(response) => onSuccess(item, response)}
                  onError={(error) => onError(item, error)}
                  status={data[item]?.status}
                  subText={data[item]?.message}
                  onFilenameClick={() => onFileNameClick(data[item]?.url)}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {!isSelfServeP3M1Enabled && (
        <DocumentUpload
          wrapperlabel="Personal PAN*"
          value={data.personal_pan?.file?.name || data.personal_pan?.saved_filename}
          allowedMimeTypes={[MimeType.PDF, MimeType.JPEG, MimeType.PNG]}
          onChange={(files, upload) => onFileSelect('personal_pan', files, upload)}
          onSuccess={(response) => onSuccess('personal_pan', response)}
          onError={(error) => onError('personal_pan', error)}
          status={data.personal_pan?.status}
          subText={data.personal_pan?.message}
          onFilenameClick={() => onFileNameClick(data.personal_pan?.url)}
        />
      )}
    </Section>
  );
};

export default AddressProofSection;
