import { Box, LoaderIcon, Text } from '@razorpay/blade/components';
import React from 'react';

interface BvsDataLoadingViewProps {
  description: string;
}

const BvsDataLoadingView = ({ description }: BvsDataLoadingViewProps) => {
  return (
    <Box
      padding={['spacing.3', 'spacing.3', 'spacing.4', 'spacing.3']}
      display="flex"
      gap="spacing.3"
      backgroundColor="surface.background.gray.moderate">
      <LoaderIcon size="medium" color="interactive.icon.gray.normal" />
      <Text size="small">{description}</Text>
    </Box>
  );
};

export { BvsDataLoadingView };
