import React from 'react';
import SideBarBlade from 'components/SideBarBlade/SideBarBlade';
import { SideBarItemsType } from 'components/SideBarBlade/types';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

type FnfSideBarProps = {
  data: GetFnfResponseSchemaContract | null;
  isLoading: boolean;
};

const FnfSideBar = ({ data, isLoading }: FnfSideBarProps) => {
  const sidebarItems: SideBarItemsType = [];
  const {isAdvanceSalaryDisabled} = useIsFeatureDisabledForRearch()

  const leaveTypes = data?.leave_types ?? [];
  if (leaveTypes.length > 0) {
    const subHeadingForLeaveTypes = leaveTypes.map((leaveType) => ({
      subHeadingName: leaveType.type ?? 'Unknown Type',
      subHeadingValue: `${leaveType.balance}/${leaveType.total}`,
    }));
    sidebarItems.push({
      heading: 'Leave Balance',
      subHeadings: subHeadingForLeaveTypes,
      href: '',
    });
  }
if(!isAdvanceSalaryDisabled){
  sidebarItems.push({
    heading: 'Salary Advance',
    subHeadings: [
      {
        subHeadingName: 'Current Outstanding Amount',
        subHeadingValue: (data?.salary_advance?.total_amount ?? '0').toString(),
      },
      {
        subHeadingName: 'Emi',
        subHeadingValue: (data?.salary_advance?.emi ?? '0').toString(),
      },
    ],
    href: '',
  });

  if (
    data?.loan_amount_required_for_full_recovery &&
    data?.loan_amount_required_for_full_recovery > 0
  ) {
    sidebarItems.push({
      heading: 'Loan',
      subHeadings: [
        {
          subHeadingName: 'Current Outstanding Amount required for Full Recovery of Loan',
          subHeadingValue: data?.loan_amount_required_for_full_recovery.toString(),
        },
      ],
      href: '',
    });
  }
}

  return <SideBarBlade sidebarItems={sidebarItems} isLoading={isLoading} />;
};

export default FnfSideBar;
