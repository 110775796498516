import { useEffect, useState } from 'react';

interface AsyncScriptLoaderOptions {
  /**
   * URL of the script to be loaded
   */
  url: string;
  /**
   * Type of the script being loaded, i.e "module", "text/javascript"
   */
  type: string;
}

interface UseLoadAsyncScriptOptions extends AsyncScriptLoaderOptions {
  onSuccess?: () => void;
  onFailure?: () => void;
}

// algorithm stolen from - https://stackoverflow.com/a/7616484
const getStringHashCode = (str: string) => {
  const length = str.length;
  let hash = 0;
  if (length === 0) return hash;

  for (let i = 0; i < length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

const isValidUrl = (url: string) => {
  let urlUbj;

  try {
    urlUbj = new URL(url);
  } catch (_) {
    return false;
  }

  return urlUbj.protocol === 'http:' || urlUbj.protocol === 'https:';
};

const createAsyncScriptLoader = () => {
  const loadedScriptsSet = new Set<string>();

  const loader = ({ url, type }: AsyncScriptLoaderOptions) =>
    new Promise((resolve, reject) => {
      if (!isValidUrl(url)) {
        reject('url is invalid');
        return;
      }

      const scriptId = `script-${getStringHashCode(url)}`;
      const scriptSelector = `script#${scriptId}`;
      const existingScriptElement = document.querySelector(scriptSelector);
      const isScriptAlreadyLoaded = loadedScriptsSet.has(scriptId);

      // do nothing if the script already loaded
      if (isScriptAlreadyLoaded) {
        resolve(null);
        return;
      }

      if (existingScriptElement) {
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.type = type;
      scriptElement.src = url;
      scriptElement.id = scriptId;
      scriptElement.addEventListener('load', (e) => {
        loadedScriptsSet.add(scriptId);
        resolve(e);
      });
      scriptElement.addEventListener('error', (e) => {
        loadedScriptsSet.delete(scriptId);
        document.querySelector(scriptSelector)?.remove();
        reject(e);
      });

      document.head.appendChild(scriptElement);
    });

  return loader;
};

const loadAsyncScript = createAsyncScriptLoader();

const useLoadAsyncScript = (options: UseLoadAsyncScriptOptions) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { url, type, onSuccess, onFailure } = options;

  const handleLoadingInitiate = () => {
    setIsLoading(true);
    setIsError(false);
  };

  const handleLoadingError = () => {
    setIsError(true);

    onFailure?.();
  };

  const handleLoadingSuccess = () => {
    setIsError(false);

    onSuccess?.();
  };

  const handleLoadingFinally = () => {
    setIsLoading(false);
  };

  const refetch = () => {
    handleLoadingInitiate();
    loadAsyncScript({ url, type })
      .then(handleLoadingSuccess)
      .catch(handleLoadingError)
      .finally(handleLoadingFinally);
  };

  useEffect(() => {
    handleLoadingInitiate();
    loadAsyncScript({ url, type })
      .then(handleLoadingSuccess)
      .catch(handleLoadingError)
      .finally(handleLoadingFinally);
  }, [url]);

  return {
    isLoading,
    isError,
    refetch,
  };
};

export { useLoadAsyncScript, loadAsyncScript };
