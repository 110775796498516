import {
  Box,
  Button,
  Card,
  CardBody,
  ExternalLinkIcon,
  InfoIcon,
  Link,
  Text,
} from '@razorpay/blade/components';
import { routePaths } from 'components/Routes/data';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { pastPayrollExecutionHelpLink } from '../constants';
import { useRunPayrollDataContext } from '../contexts/RunPayrollDataContext';
import {
  resetIsSkippedCountConsentGranted,
  setIsSkippedCountConsentGranted,
} from '../contexts/runPayrollSlice';
import useIsFinalizationRestrictionEnabled from '../hooks/useIsFinalizationRestrictionEnabled';
import SkipConfirmationModal from './SkipConfirmationModal';

const Wrapper = styled.div`
  border-left: ${(props) => props.theme.border.width.thicker}px solid
    ${(props) => props.theme.colors.interactive.border.information.default};
  border-left-color: ${(props) => props.theme.colors.interactive.border.information.default};
  border-radius: ${(props) => props.theme.border.radius.medium}px;
`;

type Props = {
  isTdsFilingConfigRequired: boolean;
  onExecutePayroll: () => void;
};

const ExecuteCard = ({
  isTdsFilingConfigRequired,
  onExecutePayroll,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);
  const data = useRunPayrollDataContext();
  const dispatch = useDispatch();
  const { isFinalizationRestrictionEnabled } = useIsFinalizationRestrictionEnabled();
  const skippedEmployees = data.skippedEmployeeCountForFY;

  if (!skippedEmployees || skippedEmployees === 0 || !isFinalizationRestrictionEnabled) {
    return (
      <Button isDisabled={isTdsFilingConfigRequired} type="button" onClick={onExecutePayroll}>
        Request Execution
      </Button>
    );
  }

  return (
    <>
      <Wrapper>
        <Card padding="spacing.7" width="100%">
          <CardBody>
            <Box display="flex" alignItems="center" marginBottom="spacing.3">
              <InfoIcon color="interactive.icon.information.normal" size="medium" />
              <Text
                marginLeft="spacing.2"
                size="medium"
                weight="medium"
                color="interactive.text.information.normal">
                {skippedEmployees} pending payrolls
              </Text>
            </Box>
            <Text color="surface.text.gray.muted" marginTop="spacing.3">
              {skippedEmployees} pending payroll in this financial year. Execute past payroll if
              missed.{' '}
              <Link
                href={pastPayrollExecutionHelpLink}
                icon={ExternalLinkIcon}
                iconPosition="right">
                Learn more
              </Link>
            </Text>
            <Box marginTop="spacing.6">
              <Button
                isFullWidth
                variant="primary"
                marginBottom="spacing.5"
                onClick={() => {
                  dispatch(resetIsSkippedCountConsentGranted());
                  setIsOpen(true);
                }}
                isDisabled={isTdsFilingConfigRequired}>
                Request Execution
              </Button>
              <Button
                isFullWidth
                href={`${routePaths.customReport.root}?type=15&payrollMonth=${data.payrollMonth}`}
                target="_blank"
                variant="tertiary"
                icon={ExternalLinkIcon}
                iconPosition="right">
                View pending report
              </Button>
            </Box>
          </CardBody>
        </Card>
      </Wrapper>
      <SkipConfirmationModal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onCancel={() => setIsOpen(false)}
        onProceed={() => {
          dispatch(setIsSkippedCountConsentGranted(true));
          setIsOpen(false);
          onExecutePayroll();
        }}
      />
    </>
  );
};

export default ExecuteCard;
