import React from 'react';
import {
  IntegrationWindow,
  IntegrationWindowErrorWithRetry,
  IntegrationWindowSkeleton,
  IntegrationWindowTitle,
} from '../components/IntegrationWindow';
import { useIntegrationProgress } from '../useIntegrationProgress';
import { useKnitHrmsApp } from '../useKnitHrmsApp';
import { Redirect, useHistory } from 'react-router-dom';
import useReduxSelector from 'utils/useReduxSelector';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { useGetKnitHrmsIntegrationQuery } from '../useGetKnitHrmsIntegrationQuery';
import {
  Alert,
  AlertCircleIcon,
  Box,
  Button,
  Radio,
  RadioGroup,
  Text,
  Heading,
} from '@razorpay/blade/components';
import { AppError } from 'utils/AppError';
import { useGetKnitHrmsEntityTypesLovQuery } from '../useGetKnitHrmsEntityTypesLovQuery';

const EntitySelection = () => {
  const history = useHistory();
  const org = useReduxSelector(loggedInUserSelectors.currentOrganization);
  const { knitHrmsApp, hrmsAppRoutePaths, hrmsAppMetadata } = useKnitHrmsApp();

  const {
    knitHrmsIntegrationQuery,
    isAuthenticationComplete,
    isIntegrationActive,
    isSyncInProgress,
    retryLimitExhausted: integrationQueryRetryLimitExhausted,
    updateEntityName,
  } = useGetKnitHrmsIntegrationQuery(knitHrmsApp);

  const { hrmsEntityTypesQuery, retryLimitExhausted: entityTypesLovQueryRetryLimitExhausted } =
    useGetKnitHrmsEntityTypesLovQuery(knitHrmsApp, {
      enabled: !!isAuthenticationComplete && !isSyncInProgress && !isIntegrationActive, // disabling the query in cases where user is not allowed to access entities selection page
    });

  const { totalSteps, currentStep, integrationProgress } = useIntegrationProgress();

  const orgName = org?.name ?? org?.brandName;

  const hrmsEntityTypes = hrmsEntityTypesQuery.data?.data;

  const showSkeletonLoader =
    knitHrmsIntegrationQuery.isLoading ||
    knitHrmsIntegrationQuery.isFetching ||
    hrmsEntityTypesQuery.isLoading ||
    hrmsEntityTypesQuery.isFetching;

  const canProceedToNextStep = !!knitHrmsIntegrationQuery.data?.entity_name;

  // redirecting to hrms home if integration is not active
  if (knitHrmsIntegrationQuery.isSuccess && !isAuthenticationComplete) {
    return <Redirect to={hrmsAppRoutePaths.root} />;
  }

  // redirecting to sync-in-progress screen if employee data sync is in progress
  if (knitHrmsIntegrationQuery.isSuccess && isSyncInProgress) {
    return <Redirect to={hrmsAppRoutePaths.settings.syncInProgress} />;
  }

  if (hrmsEntityTypesQuery.isError) {
    const errorCode = (hrmsEntityTypesQuery.error as AppError).getDetails().code;

    // redirecting to next step if hrms does not support entity types
    if (errorCode === 'KNIT_PASSTHROUGH_ENTITY_TYPES_UNAVAILABLE_FOR_APP') {
      return (
        <Redirect
          to={{
            pathname: hrmsAppRoutePaths.settings.fields,
            state: {
              totalSteps: totalSteps - 1,
              currentStep: currentStep,
            },
          }}
        />
      );
    }
  }

  // redirecting to next step if integration is already in active state as entity change is not allowed once in integration is in active state
  if (isIntegrationActive) {
    return (
      <Redirect
        to={{
          pathname: hrmsAppRoutePaths.settings.fields,
          state: {
            totalSteps: totalSteps,
            currentStep: currentStep,
          },
        }}
      />
    );
  }

  if (showSkeletonLoader) {
    return <IntegrationWindowSkeleton />;
  }

  if (knitHrmsIntegrationQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={knitHrmsIntegrationQuery.refetch}
        isRetrying={knitHrmsIntegrationQuery.isFetching}
        retryLimitExhausted={integrationQueryRetryLimitExhausted}
      />
    );
  }

  if (hrmsEntityTypesQuery.isError) {
    return (
      <IntegrationWindowErrorWithRetry
        onRetry={hrmsEntityTypesQuery.refetch}
        isRetrying={hrmsEntityTypesQuery.isFetching}
        retryLimitExhausted={entityTypesLovQueryRetryLimitExhausted}
      />
    );
  }

  const handleCloseViewClick = () => {
    history.push(hrmsAppRoutePaths.root);
  };

  const handleBackCtaClick = () => {
    history.goBack();
  };

  const handleNextCtaClick = () => {
    history.push(hrmsAppRoutePaths.settings.fields, {
      totalSteps,
      currentStep: currentStep + 1,
    });
  };

  const handleEntitySelection = ({ value }: { name?: string; value: string }) => {
    updateEntityName(value);
  };

  return (
    (<IntegrationWindow
      progress={integrationProgress}
      title={
        <IntegrationWindowTitle
          appName={hrmsAppMetadata.name}
          orgName={orgName ?? undefined}
          mode={isIntegrationActive ? 'modify' : 'integrate'}
        />
      }
      onClose={handleCloseViewClick}>
      <Box display="flex" flexDirection="column" gap="spacing.9" maxWidth="592px">
        <Box display="flex" flexDirection="column" gap="spacing.2">
          <Heading as="h2" size="xlarge" color="surface.text.gray.muted">
            Select the {hrmsAppMetadata.name} entity to connect
          </Heading>
          <Text color="surface.text.gray.disabled">
            Select the {hrmsAppMetadata.name} entity which you want to sync to your RazorpayX
            Payroll account. Only employees from the selected account will be synced.
          </Text>
          <Box display="flex" alignItems="center" gap="spacing.2" marginTop="spacing.5">
            <AlertCircleIcon size="medium" color="interactive.icon.gray.normal" />
            <Text color="surface.text.gray.disabled">Your current RazorpayX Payroll organization: </Text>
            <Text
              weight="semibold"
              color="interactive.text.information.subtle">
              {orgName}
            </Text>
          </Box>
        </Box>
        <RadioGroup
          size="small"
          name="entity-name"
          value={knitHrmsIntegrationQuery.data?.entity_name ?? undefined}
          onChange={handleEntitySelection}>
          <Box display="flex" flexDirection="column" gap="spacing.3" maxWidth="256px">
            {hrmsEntityTypes?.map(({ entity_name, entity_code }) => {
              const isSelectedEntity = entity_name === knitHrmsIntegrationQuery.data?.entity_name;
              const borderProps: React.ComponentProps<typeof Box> = isSelectedEntity
                ? {
                    borderWidth: 'thin',
                    borderColor: 'surface.border.primary.normal',
                  }
                : {};

              return (
                <Box
                  key={entity_code}
                  padding={['spacing.4', 'spacing.7', 'spacing.4', 'spacing.3']}
                  borderRadius="medium"
                  backgroundColor="surface.background.primary.subtle"
                  {...borderProps}>
                  <Radio value={entity_name}>{entity_name}</Radio>
                </Box>
              );
            })}
          </Box>
        </RadioGroup>
        {knitHrmsIntegrationQuery.data?.entity_name && (
          <Alert
            color="information"
            isDismissible={false}
            description={`Information will only sync from ${hrmsAppMetadata.name} entity ${knitHrmsIntegrationQuery.data.entity_name} to RazorpayX Payroll account with company name ${orgName}`} />
        )}
        <Box display="flex" justifyContent="space-between" marginTop="spacing.8">
          <Button variant="secondary" onClick={handleBackCtaClick}>
            Back
          </Button>
          <Button onClick={handleNextCtaClick} isDisabled={!canProceedToNextStep}>
            Next
          </Button>
        </Box>
      </Box>
    </IntegrationWindow>)
  );
};

export { EntitySelection };
