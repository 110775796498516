import React, { useState } from 'react';
import {
  Box,
  Collapsible,
  CollapsibleBody,
  CollapsibleLink,
  Indicator,
  Skeleton,
  Text,
} from '@razorpay/blade/components';
import { format } from 'date-fns';
import WorkflowLevel from './WorkflowLevel';
import ActorWithComments from './ActorWithComments';
import { WorkFlowStatus } from './types';
import { getPeopleDataByPeopleId } from './utils';
import { GetWorkflowRequestDetailsContract } from 'schemas/GetWorkflowRequestDetails';

export interface TimelineProps {
  workflowRequestDetails?: GetWorkflowRequestDetailsContract;
  isLoading?: boolean;
}

const WORKFLOW_STATUS_REJECTED = 3;

function getLevelStatus(
  workflowLevel: GetWorkflowRequestDetailsContract['workflow_data']['approvers']['levels'][number],
  activityLogs: GetWorkflowRequestDetailsContract['activity_log'][string],
  pendingOnLevel: number | null,
): WorkFlowStatus {
  if (pendingOnLevel === null) {
    if (!!activityLogs.find((log) => log.activity === 'REJECTED')) {
      return 'rejected';
    }
    return 'approved';
  }

  if (pendingOnLevel > workflowLevel.level) {
    return 'approved';
  } else if (pendingOnLevel === workflowLevel.level) {
    return 'pending';
  } else {
    return 'upcoming';
  }
}

const TimelineSkeleton = ({ levels }: { levels: number }) => {
  return (
    <Box display="flex" flexDirection="column" gap="spacing.5">
      {((levels) => {
        const skeletons = [];
        for (let i = 0; i <= levels; i++) {
          skeletons.push(
            <Box display="flex" alignItems="flex-start" gap="spacing.4" key={i}>
              <Skeleton width="spacing.5" height="spacing.5" borderRadius="medium" />
              <Box display="flex" flexDirection="column" gap="spacing.4" flexGrow="1">
                <Skeleton width="100%" height="spacing.5" borderRadius="medium" />
                <Skeleton
                  marginLeft="spacing.5"
                  width="calc(100% - spacing.5)"
                  height="spacing.4"
                  borderRadius="medium"
                />
              </Box>
            </Box>,
          );
        }

        return skeletons;
      })(levels)}
    </Box>
  );
};

function Timeline({ workflowRequestDetails, isLoading }: TimelineProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandChange = ({ isExpanded }: { isExpanded: boolean }) => {
    setIsExpanded(isExpanded);
  };

  if (isLoading || !workflowRequestDetails) {
    return <TimelineSkeleton levels={2} />;
  }

  return (
    (<Collapsible onExpandChange={handleExpandChange} isExpanded={isExpanded}>
      <CollapsibleLink>{isExpanded ? 'Hide Timeline' : 'Show Timeline'}</CollapsibleLink>
      <CollapsibleBody>
        <Box display="flex" flexDirection="column" gap="spacing.6">
          {workflowRequestDetails.workflow_data.approvers.levels
            .sort((data1, data2) => data2.level - data1.level)
            .filter((data) => {
              // remove future workflow steps if a request was rejected
              if (
                workflowRequestDetails.workflow_requests_data.status === WORKFLOW_STATUS_REJECTED
              ) {
                const activityLogs = (workflowRequestDetails.activity_log[
                  `level_${data.level.toString()}`
                ] ??
                  workflowRequestDetails.activity_log[`level${data.level.toString()}`] ??
                  []) as GetWorkflowRequestDetailsContract['activity_log'][string];
                // if there's no activity, this stage was not reached yet
                if (activityLogs.length === 0) {
                  return false;
                }
              }

              return true;
            })
            .map((data) => {
              const currentLevel = workflowRequestDetails.workflow_data.approvers.levels.find(
                (level) => level.level === data.level,
              ) as GetWorkflowRequestDetailsContract['workflow_data']['approvers']['levels'][number];

              const activityLogs = (workflowRequestDetails.activity_log[
                `level_${data.level.toString()}`
              ] ??
                workflowRequestDetails.activity_log[`level${data.level.toString()}`] ??
                []) as GetWorkflowRequestDetailsContract['activity_log'][string];

              return (
                <WorkflowLevel
                  key={data.level}
                  levelNumber={data.level}
                  status={getLevelStatus(
                    currentLevel,
                    activityLogs,
                    workflowRequestDetails.workflow_requests_data?.pending_on_level ?? null,
                  )}
                  activityLogs={activityLogs}
                  requiredApprovers={currentLevel?.peopleIds ?? []}
                  minApprovals={currentLevel.minApprovals}
                  peopleMetaData={workflowRequestDetails.people_metadata}
                />
              );
            })}
          <Box display="flex" gap="spacing.4" alignItems="baseline">
            <Indicator accessibilityLabel="requested" color="information" />
            <Box display="flex" gap="spacing.3" marginBottom="spacing.3" flexDirection="column">
              <Text size="medium" color="surface.text.gray.subtle">
                Raised by
              </Text>
              <ActorWithComments
                status="raised"
                date={format(
                  new Date(workflowRequestDetails.workflow_requests_data.created_at * 1000),
                  'do MMM, h:mm a',
                )}
                person={
                  workflowRequestDetails.workflow_requests_data.people_id
                    ? getPeopleDataByPeopleId(
                        workflowRequestDetails.people_metadata,
                        workflowRequestDetails.workflow_requests_data.people_id.toString(),
                      )
                    : undefined
                }
              />
            </Box>
          </Box>
        </Box>
      </CollapsibleBody>
    </Collapsible>)
  );
}

export default Timeline;
