import React, { ReactElement, useState, useEffect } from 'react';
import { ActionList, ActionListItem, Box, Dropdown, DropdownOverlay, SelectInput, TextInput, Text, TextArea, RadioGroup, Radio, Link, Spinner } from '@razorpay/blade/components';
import { bonusSource, BonusStatusTextMap, BonusTDSDeductionTextMap, bonusTDSDeduction, BonusTDSDeduction, BonusSource, FormProps, ValidationStates } from '../types';
import { toIndianCurrencyString } from '../../../utils/Numbers';
import { MONTHS_LIST } from '../../../utils/Dates';
import { getClawbackMonthString, handleBooleanChange, handleDateChange, handleChange, handleAmountChange, validateFormVal, handleClawbackPeriodChange } from '../utils';
import { BonusEnabledTypesSchemaContract } from 'schemas/BonusEnabledTypesSchema';
import { routePaths } from 'components/Routes/data';


export interface CreateBonusFormProps {
    stepCount: number;
    formProps: FormProps;
    setFormProps: (value: React.SetStateAction<FormProps>) => void;
    bonusTypes: BonusEnabledTypesSchemaContract['types']
}

export default function CreateBonusForm({ stepCount, formProps, setFormProps, bonusTypes }: CreateBonusFormProps): ReactElement {

    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let [payoutMonth, setPayoutMonth] = useState<string>(formProps.payout_month ? (new Date(formProps.payout_month)).getMonth().toString() : currentDate.getMonth().toString());
    let [payoutYear, setPayoutYear] = useState<number>(formProps.payout_month ? (new Date(formProps.payout_month)).getFullYear() : currentDate.getFullYear());

    let [validationStates, setValidationStates] = useState<ValidationStates>({
        name: 'none',
        amount: 'none',
        clawback_period: 'none'
    });

    let YEAR_LIST = [];
    for (let i = -5; i < 5; i++) {
        YEAR_LIST.push((currentYear as number) + (i as number));
    }

    useEffect(() => {
        if (payoutMonth && payoutYear) {
            handleDateChange((new Date(payoutYear, parseInt(payoutMonth) + 1 as number, 1)), setFormProps);
        }
    }, [payoutMonth, payoutYear])


    switch (stepCount) {
        case 2:
            return (
              (<Box width={'376px'}>
                <RadioGroup
                    label=""
                    name="clawback_applicable"
                    onChange={(e) => handleBooleanChange(e, setFormProps)}
                    defaultValue="false"
                    value={formProps.clawback_applicable ? formProps.clawback_applicable.toString() : "false"}
                >
                    <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'}>
                        <Box width={'272px'} height={'42px'} borderRadius={'medium'} paddingY={'spacing.3'} paddingLeft={'spacing.3'} paddingRight={'spacing.7'} backgroundColor="surface.background.gray.subtle" ><Radio value="false">No, I don't have any clawback rule</Radio></Box>
                        <Box width={'272px'} height={'42px'} borderRadius={'medium'} paddingY={'spacing.3'} paddingLeft={'spacing.3'} paddingRight={'spacing.7'} backgroundColor="surface.background.gray.subtle" ><Radio value="true">Yes, I have clawback rule</Radio></Box>
                    </Box>
                </RadioGroup>
                {
                    formProps.clawback_applicable &&
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="spacing.6"
                        width="582px"
                        backgroundColor={'surface.background.gray.moderate'}
                        borderWidth={'thin'}
                        borderRadius={'medium'}
                        borderColor={'surface.border.gray.normal'}
                        paddingY={'spacing.7'}
                        paddingLeft={'spacing.7'}
                        marginTop={'spacing.8'}
                    >
                        <Text color='surface.text.gray.muted'>
                            Enter the clawback duration for the bonus. This will be effective from the payment date.
                        </Text>
                        <Box width={'376px'} display="flex" flexDirection="column" paddingRight={'48px'}>
                            <TextInput
                                name={'clawback_period'}
                                label='Number of Months *'
                                labelPosition='top'
                                value={formProps.clawback_period ? formProps.clawback_period.toString() : ''}
                                errorText={'If clawback is applciable, clawback period is required'}
                                onChange={(e) => handleClawbackPeriodChange(e.value as string, setFormProps)}
                                onBlur={() => validateFormVal('clawback_period', formProps, setValidationStates)}
                                validationState={validationStates.clawback_period}
                            />
                        </Box>

                        {
                            (formProps.clawback_period && formProps.payout_month) &&
                            <Text variant='body' color='feedback.text.positive.intense' size='small' weight="semibold">{`💡 The bonus amount will be recovered from the employee if they leave before ${getClawbackMonthString(formProps.payout_month, formProps.clawback_period)}`}</Text>
                        }

                    </Box>
                }
              </Box>)
            );

        case 1:
        default:
            return (<>
              <Box width={'376px'} display={'flex'} flexDirection={'column'} gap={'spacing.8'}>
                <Box>
                  <Dropdown selectionType="single">
                    <SelectInput
                      label="Bonus Type"
                      name="name"
                      value={`${formProps.organization_bonus_setting_id}`}
                      onChange={({ values }) =>
                        handleChange(
                          { name: 'organization_bonus_setting_id', value: values[0] },
                          setFormProps,
                        )
                      }
                      isRequired
                    />
                    <DropdownOverlay>
                      <ActionList>
                        {bonusTypes.map((bonusType) => (
                          <ActionListItem
                            title={bonusType.name}
                            value={bonusType.id.toString()}
                          />
                        ))}
                      </ActionList>
                    </DropdownOverlay>
                  </Dropdown>
                  <Box display="flex" gap="spacing.1">
                    <Text variant="caption" size='small'>To add a new bonus type,</Text>

                    <Link
                      size="small"
                      target="_blank"
                      variant="anchor"
                      href={routePaths.bonus.bonusTypeSetting}>
                      click here.
                    </Link>
                  </Box>
                </Box>
                <TextInput
                  label="Bonus Amount *"
                  placeholder="Total Bonus Amount"
                  value={formProps.amount ? toIndianCurrencyString(formProps.amount, true) : ''}
                  onChange={(e) => handleAmountChange(e.value as string, setFormProps)}
                  validationState={validationStates.amount}
                  onBlur={() => validateFormVal('amount', formProps, setValidationStates)}
                  errorText={'Bonus Amount is a required field'}
                  isRequired
                  prefix="₹"
                  name="amount"
                />
                <Dropdown selectionType="single">
                  <SelectInput
                    label="Bonus Status"
                    name="source"
                    value={formProps.source ?? undefined}
                    onChange={({ values }) =>
                      handleChange(
                        { name: 'source', value: values[0] as BonusSource },
                        setFormProps,
                      )
                    }
                    isRequired
                  />
                  <DropdownOverlay>
                    <ActionList>
                      <ActionListItem
                        title={BonusStatusTextMap[bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL]}
                        value={bonusSource.BONUS_SOURCE_WITHIN_XPAYROLL}
                      />
                      <ActionListItem
                        title={BonusStatusTextMap[bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL]}
                        value={bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL}
                      />
                    </ActionList>
                  </DropdownOverlay>
                </Dropdown>
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'spacing.1'}>
                <Text
                  variant="body"
                  weight="semibold"
                  size="small"
                  color="surface.text.gray.muted"
                  marginBottom={'spacing.2'}>
                  Payout Month
                </Text>
                <Box display={'flex'} flexDirection={'row'} gap={'spacing.3'}>
                  <Box width={'376px'}>
                    <Box display={'flex'} flexDirection={'row'} gap={'spacing.5'}>
                      <Box width={'180px'}>
                        <Dropdown selectionType="single">
                          <SelectInput
                            label=""
                            name="payoutMonth"
                            placeholder="Payout Month"
                            value={payoutMonth ?? ''}
                            onChange={({ values }) => setPayoutMonth(values[0] as string)}
                            isRequired
                          />
                          <DropdownOverlay>
                            <ActionList>
                              {MONTHS_LIST.map((month) => (
                                <ActionListItem
                                  title={month}
                                  value={MONTHS_LIST.indexOf(month).toString()}
                                />
                              ))}
                            </ActionList>
                          </DropdownOverlay>
                        </Dropdown>
                      </Box>
                      <Box width={'180px'}>
                        <Dropdown selectionType="single">
                          <SelectInput
                            label=""
                            name="payoutYear"
                            placeholder="Payout Year"
                            value={payoutYear ? payoutYear.toString() : ''}
                            onChange={({ values }) => setPayoutYear(parseInt(values[0]))}
                            isRequired
                          />
                          <DropdownOverlay>
                            <ActionList>
                              {YEAR_LIST.map((year) => (
                                <ActionListItem title={year.toString()} value={year.toString()} />
                              ))}
                            </ActionList>
                          </DropdownOverlay>
                        </Dropdown>
                      </Box>
                    </Box>
                  </Box>
                  {payoutMonth &&
                    payoutYear &&
                    formProps.source !== bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL && (
                      <Box width={'180px'}>
                        <Text size="small" color="feedback.text.positive.intense">
                          {`⚡️ The bonus amount will be paid with the ${`${
                            MONTHS_LIST[parseInt(payoutMonth)]
                          }, ${payoutYear}`} payroll`}
                        </Text>
                      </Box>
                    )}
                </Box>
                {formProps.source !== bonusSource.BONUS_SOURCE_OUTSIDE_XPAYROLL && (
                  <Box width={'376px'}>
                    <Text variant="caption" size='small'>
                      Choose the payroll month for bonus payments. We will automatically include
                      the bonus in that month's payroll and notify you
                    </Text>
                  </Box>
                )}
              </Box>
              <Box width={'376px'} display={'flex'} flexDirection={'column'} gap={'spacing.8'}>
                <Dropdown selectionType="single">
                  <SelectInput
                    label="TDS Deduction"
                    name="tds_recovery_option"
                    value={formProps.tds_recovery_option ?? undefined}
                    onChange={({ values }) =>
                      handleChange(
                        { name: 'tds_recovery_option', value: values[0] as BonusTDSDeduction },
                        setFormProps,
                      )
                    }
                    defaultValue={bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_INSTANT}
                    isRequired
                  />
                  <DropdownOverlay>
                    <ActionList>
                      <ActionListItem
                        title={
                          BonusTDSDeductionTextMap[
                            bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_INSTANT
                          ]
                        }
                        value={bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_INSTANT}
                      />
                      <ActionListItem
                        title={
                          BonusTDSDeductionTextMap[
                            bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_PRORATE
                          ]
                        }
                        value={bonusTDSDeduction.BONUS_TDS_RECOVERY_OPTION_PRORATE}
                      />
                    </ActionList>
                  </DropdownOverlay>
                </Dropdown>
                <TextArea
                  label="Remarks"
                  labelPosition="top"
                  name="remarks"
                  onChange={(e) => handleChange(e, setFormProps)}
                  showClearButton={true}
                  value={formProps.remarks ?? ''}
                  onClearButtonClick={() =>
                    handleChange({ name: 'remarks', value: '' }, setFormProps)
                  }
                  onFocus={function noRefCheck() {}}
                  placeholder="Enter remarks here"
                />
                {/* <Checkbox value={(formProps.part_of_ctc ?? false).toString()} onChange={(e) => handleBooleanChange({ name: 'part_of_ctc', value: e.isChecked }, setFormProps)}>
                          <Box display={'flex'} flexDirection={'row'}>
                              Include the bonus as part of employee’s CTC
                              <Tooltip content="The bonus will be included as a part of the employee's" >
                                  <TooltipInteractiveWrapper>
                                      <InfoIcon size='medium' color='surface.background.gray.subtle' marginLeft={'spacing.3'} marginTop={'spacing.1'} />
                                  </TooltipInteractiveWrapper>
                              </Tooltip>
                          </Box>
                      </Checkbox> */}
                {/* { disabling this temporarily  } */}
              </Box>
            </>);
    }



}