import styled from 'styled-components';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-evenly;
  & > * {
    width: 100%;
    flex: 1;
  }
`;

export const Table = styled.table`
  width: 100%;
  & tr th {
    padding-bottom: 10px;
    width: 20%;
  }
  & tr td {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 20%;
    text-align: end;
  }

  & tr th:first-of-type,
  tr td:first-of-type {
    text-align: start;
  }
`;

export const LinearGradientBox = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 32px 118px;
  margin-top: 24px;
  background: ${({theme})=>`linear-gradient(90deg, ${theme.colors.surface.background.gray.intense} 0.07%, hsla(217, 27%, 15%, 0) 103.19%)`} ;
`;