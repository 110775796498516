import React, { createContext, useContext, useState } from 'react';

export type ApproveRejectWithdrawCallback = () => void;

export interface ApproveRejectWithdrawContext {
  isApprovalModalOpen: boolean;
  isRejectionModalOpen: boolean;
  isWithdrawModalOpen: boolean;
  callback?: ApproveRejectWithdrawCallback;
}

export interface BulkApproveRejectWithdrawContext extends ApproveRejectWithdrawContext {
  requestIds: number[];
}

export interface SingleApproveRejectWithdrawContext extends ApproveRejectWithdrawContext {
  requestId: number;
}

const defaultBulkModalState: BulkApproveRejectWithdrawContext = {
  isApprovalModalOpen: false,
  isRejectionModalOpen: false,
  isWithdrawModalOpen: false,
  requestIds: [],
  callback: undefined,
};

const defaultSingleModalState: SingleApproveRejectWithdrawContext = {
  isApprovalModalOpen: false,
  isRejectionModalOpen: false,
  isWithdrawModalOpen: false,
  requestId: 0,
  callback: undefined,
};

export interface MyRequestsContextType {
  bulkApproveRejectWithdrawRequests: BulkApproveRejectWithdrawContext;
  singleApproveRejectWithdrawRequests: SingleApproveRejectWithdrawContext;
  openSingleApprovalModal: (requestId: number, callback?: ApproveRejectWithdrawCallback) => void;
  closeSingleApprovalModal: () => void;
  openSingleRejectionModal: (requestId: number, callback?: ApproveRejectWithdrawCallback) => void;
  closeSingleRejectionModal: () => void;
  openBulkApprovalModal: (requestIds: number[], callback?: ApproveRejectWithdrawCallback) => void;
  closeBulkApprovalModal: () => void;
  openBulkRejectionModal: (requestIds: number[], callback?: ApproveRejectWithdrawCallback) => void;
  closeBulkRejectionModal: () => void;
  openSingleWithdrawModal: (requestId: number, callback?: ApproveRejectWithdrawCallback) => void;
  closeSingleWithdrawModal: () => void;
  openBulkWithdrawModal: (requestIds: number[], callback?: ApproveRejectWithdrawCallback) => void;
  closeBulkWithdrawModal: () => void;
}

const MyRequestsContext = createContext<MyRequestsContextType | null>(null);

const MyRequestContextProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [bulkApproveRejectWithdrawRequests, setBulkApproveRejectWithdrawRequests] =
    useState<BulkApproveRejectWithdrawContext>(defaultBulkModalState);

  const [singleApproveRejectWithdrawRequests, setSingleApproveRejectWithdrawRequests] =
    useState<SingleApproveRejectWithdrawContext>(defaultSingleModalState);

  const openSingleApprovalModal = (requestId: number, callback?: ApproveRejectWithdrawCallback) => {
    setSingleApproveRejectWithdrawRequests({
      ...defaultSingleModalState,
      isApprovalModalOpen: true,
      requestId,
      callback,
    });
  };

  const closeSingleApprovalModal = () => {
    setSingleApproveRejectWithdrawRequests(defaultSingleModalState);
  };

  const openSingleRejectionModal = (
    requestId: number,
    callback?: ApproveRejectWithdrawCallback,
  ) => {
    setSingleApproveRejectWithdrawRequests({
      ...defaultSingleModalState,
      isRejectionModalOpen: true,
      requestId,
      callback,
    });
  };

  const closeSingleRejectionModal = () => {
    setSingleApproveRejectWithdrawRequests(defaultSingleModalState);
  };

  const openBulkApprovalModal = (
    requestIds: number[],
    callback?: ApproveRejectWithdrawCallback,
  ) => {
    setBulkApproveRejectWithdrawRequests({
      ...defaultBulkModalState,
      isApprovalModalOpen: true,
      requestIds,
      callback,
    });
  };

  const closeBulkApprovalModal = () => {
    setBulkApproveRejectWithdrawRequests(defaultBulkModalState);
  };

  const openBulkRejectionModal = (
    requestIds: number[],
    callback?: ApproveRejectWithdrawCallback,
  ) => {
    setBulkApproveRejectWithdrawRequests({
      ...defaultBulkModalState,
      isRejectionModalOpen: true,
      requestIds,
      callback,
    });
  };

  const closeBulkRejectionModal = () => {
    setBulkApproveRejectWithdrawRequests(defaultBulkModalState);
  };

  const openSingleWithdrawModal = (requestId: number, callback?: ApproveRejectWithdrawCallback) => {
    setSingleApproveRejectWithdrawRequests({
      ...defaultSingleModalState,
      isWithdrawModalOpen: true,
      requestId,
      callback,
    });
  };

  const closeSingleWithdrawModal = () => {
    setSingleApproveRejectWithdrawRequests(defaultSingleModalState);
  };

  const openBulkWithdrawModal = (
    requestIds: number[],
    callback?: ApproveRejectWithdrawCallback,
  ) => {
    setBulkApproveRejectWithdrawRequests({
      ...defaultBulkModalState,
      isWithdrawModalOpen: true,
      requestIds,
      callback,
    });
  };

  const closeBulkWithdrawModal = () => {
    setBulkApproveRejectWithdrawRequests(defaultBulkModalState);
  };

  return (
    <MyRequestsContext.Provider
      value={{
        bulkApproveRejectWithdrawRequests,
        singleApproveRejectWithdrawRequests,
        openBulkApprovalModal,
        closeBulkApprovalModal,
        openBulkRejectionModal,
        closeBulkRejectionModal,
        openSingleApprovalModal,
        closeSingleApprovalModal,
        openSingleRejectionModal,
        closeSingleRejectionModal,
        openSingleWithdrawModal,
        closeSingleWithdrawModal,
        openBulkWithdrawModal,
        closeBulkWithdrawModal,
      }}>
      {children}
    </MyRequestsContext.Provider>
  );
};

const useMyRequestsContext = () => {
  const myRequestContext = useContext(MyRequestsContext);

  return myRequestContext;
};

export { MyRequestContextProvider, useMyRequestsContext };
