import { routePaths } from 'components/Routes/data';

export const companyDetailsScreensData = {
  [routePaths.onboarding.companyDetails.basicDetails]: {
    sidebar: {
      title: 'Company Details',
      description:
        '🏢 Add some preliminary details about your business to help us curate the dashboard for you',
    },
    main: {
      title: 'Almost there 🏁',
      description: 'We need just a couple of details more to set up your account!',
    },
  },
  [routePaths.onboarding.companyDetails.panPrefill]: {
    sidebar: {
      title: 'Company Details',
      description: '✅ Quick check to verify your company',
    },
    main: {
      title: '🔎 Pre-fill company details via PAN',
      description: 'Fill in your PAN and let us find your company ⚡️',
    },
  },
};
