import { createContext, useContext } from 'react';

export interface MessageType {
  type?: string;
  data?: any;
}

export interface RunPayrollMessageContextType {
  message: MessageType | null;
  setMessage: (message: MessageType | null) => void;
}

export const RunPayrollMessageContext = createContext<RunPayrollMessageContextType | null>(null);

export const useMessageContext = () => {
  return useContext(RunPayrollMessageContext);
};

export const MessageTypes = {
  "FINALIZED" : "FINALIZED",
  "EXECUTION_REQUESTED" : "EXECUTION_REQUESTED",
  "FINALIZE_IN_PROGRESS": "FINALIZE_IN_PROGRESS",
  "FINALIZED_ERROR_REPORT": "FINALIZED_ERROR_REPORT",
}

export interface SetMessageType {
  showMessage?: (message: MessageType | null) => void;
}