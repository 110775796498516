import React, { useState } from 'react';

interface DragAndDropHook {
  isDragging: boolean;
  handleDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: React.DragEvent<HTMLDivElement>, onFileDrop: (files: File[]) => void) => void;
}

export const useDragAndDrop = (): DragAndDropHook => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    onFileDrop: (files: File[]) => void,
  ) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer?.files;

    if (files) {
      onFileDrop(Array.from(files));
    }
  };

  return { isDragging, handleDragEnter, handleDragOver, handleDragLeave, handleDrop };
};
