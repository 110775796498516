import React, { ReactElement } from 'react';
import { Card } from 'components/ui/Card';
import NumberedList, { NumberedListItem } from 'components/ui/NumberedList';
import { InfoMessage } from 'components/ui/Message';
import { getStaticMediaUrl } from 'utils/Urls';
import camsLogo from 'assets/logo--cams-intro.svg';
import { StandardButton } from 'components/ui/Button';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { Link } from 'react-router-dom';
import MainCase from 'components/ui/MainCase';
import RightSidebar from 'components/ui/RightSidebar';
import { AnchorCard } from 'components/ui/SidebarCard';
import PageWrapper from 'components/ui/PageWrapper';
import ExternalLink from 'components/ui/ExternalLink';

function CamsIntroduction(): ReactElement {
  const currentLoggedInUserIsOnFreePlan = useSelector(loggedInUserSelectors.isOnFreePlan);

  return (
    <>
      {' '}
      <PageWrapper>
        <MainCase center>
          <img src={getStaticMediaUrl(camsLogo)} />
          <div className="text-4xl font-bold text-white-o-80 mb-6 mt-12 text-center">
            Now integrate XPayroll with CAMS biometric devices
          </div>
          {currentLoggedInUserIsOnFreePlan && (
            <InfoMessage>
              Sorry, looks like you are on free plan. If you want to integrate with CAMS biometric
              devices please contact your company administrator, or email us at{' '}
              <ExternalLink className="text-primary" href="mailto:xpayroll@razorpay.com">
                xpayroll@razorpay.com
              </ExternalLink>{' '}
              to change your plan.
            </InfoMessage>
          )}
          <Card className={`mt-12 px-10 py-10`} withTopBorder>
            <div className="font-bold text-white-o-80 mb-8 text-1.92xl">
              Set up biometric devices for your organization with Xpayroll support.
            </div>
            <div className="font-bold text-white-o-70 mb-8">How to get started</div>
            <NumberedList className="pl-6">
              <NumberedListItem>
                Log in to <ExternalLink href={'https://camsunit.com/'}>CAMS</ExternalLink>
              </NumberedListItem>
              <NumberedListItem>
                Go to "Device Status", and confirm that your device is online.
              </NumberedListItem>
              <NumberedListItem>
                Note the <b>service tag id</b> and <b>auth token</b> after generating it from Device
                Management page. This will be used in the next step.
              </NumberedListItem>
              <NumberedListItem>
                Click on "Go to CAMS Settings" button below to start you integration.
              </NumberedListItem>
            </NumberedList>
            <p>
              To learn more about biometric device integration, please visit{' '}
              <ExternalLink href="https://intercom.help/XPayroll/en/articles/4754555-how-does-biometric-integration-for-attendance-work4">
                this{' '}
              </ExternalLink>
              link.
            </p>
          </Card>
          <Link to="/cams-settings" className="pt-8">
            <StandardButton disabled={currentLoggedInUserIsOnFreePlan}>
              Go to CAMS Settings
            </StandardButton>
          </Link>
        </MainCase>
        <RightSidebar>
          <AnchorCard
            href="https://intercom.help/XPayroll/en/articles/4754555-how-does-biometric-integration-for-attendance-work4"
            imageSymbolType={'help'}
            heading="Learn more about CAMS Integration"
            newTab></AnchorCard>
        </RightSidebar>
      </PageWrapper>
    </>
  );
}
export default CamsIntroduction;
