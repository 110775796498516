import React, { PropsWithChildren } from 'react';
import { getStaticMediaUrl } from 'utils/Urls';

import ExternalLink from 'components/ui/ExternalLink';

interface quickLinksInterface {
  id: number;
  title: string;
  url: string;
}

export default function QuickLinks({
  sectionHeading,
  quickLinks,
  icon,
}: PropsWithChildren<{
  sectionHeading: string;
  quickLinks: Array<quickLinksInterface>;
  icon: string;
}>) {
  return (
    <>
      <h1 className="text-3xl font-bold">{sectionHeading}</h1>
      <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-x-8 gap-y-8 pt-6 mt-4">
        {quickLinks.map((item) => (
          <div key={item.id} className="flex flex-row justify-start items-center">
            <img className="pr-2" src={getStaticMediaUrl(icon)} />
            <ExternalLink className="pl-3 font-normal pr-4 text-100" href={item.url}>
              {item.title}
            </ExternalLink>
          </div>
        ))}
      </div>
    </>
  );
}
