import React, { Fragment, ReactNode } from 'react';
import { WorkflowLevelConfig } from '../types';
import { Box, Button, Divider, PlusIcon } from '@razorpay/blade/components';
import { WorkflowLevelCard } from './WorkflowLevelCard';

interface WorkflowLevelsChainProps {
  firstStepContent: ReactNode;
  lastStepContent: ReactNode;
  workflowType: number;
  updateWorkflowConfig: (config: WorkflowLevelConfig[]) => void;
  levels?: number;
  workflowConfig?: WorkflowLevelConfig[];
  showEditControls?: boolean;
}

const WorkflowLevelsChain = ({
  levels = 2,
  workflowConfig = [
    {
      approvers: [],
      minApprovals: 1,
      isSaved: false,
    },
  ],
  workflowType,
  updateWorkflowConfig,
  firstStepContent,
  lastStepContent,
  showEditControls,
}: WorkflowLevelsChainProps) => {
  const nextLevel = workflowConfig.length + 1;
  const showAddNextLevelButton = workflowConfig.length < levels;
  const isLastLevelSaved = workflowConfig[workflowConfig.length - 1].isSaved;
  const isAddNextLevelButtonDisabled = !showEditControls || !isLastLevelSaved;

  const handleWorkflowLevelChange = ({
    workflowLevelConfig,
    level,
  }: {
    workflowLevelConfig: WorkflowLevelConfig;
    level: number;
  }) => {
    const workflowConfigCopy = [...workflowConfig];
    workflowConfigCopy[level - 1] = workflowLevelConfig;
    updateWorkflowConfig(workflowConfigCopy);
  };

  const handleWorkflowCardCloseIconClick = ({ level }: { level: number }) => {
    const updatedWorkflowConfig = workflowConfig.filter((_, index) => index !== level - 1);
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const handleWorkflowCardEditButtonClick = ({ level }: { level: number }) => {
    const updatedWorkflowConfig = workflowConfig.map((levelConfig, index) => {
      if (index === level - 1) {
        return {
          ...levelConfig,
          isSaved: false,
        };
      }

      return levelConfig;
    });
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const handleWorkflowCardSaveButtonClick = ({ level }: { level: number }) => {
    const updatedWorkflowConfig = workflowConfig.map((levelConfig, index) => {
      if (index === level - 1) {
        return {
          ...levelConfig,
          isSaved: true,
        };
      }

      return levelConfig;
    });
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const dividerContent = (
    <Box display="flex" width="100%" justifyContent="center">
      <Divider orientation="vertical" dividerStyle="dashed" height="60px" alignSelf="center" />
    </Box>
  );

  const workflowCardsContent = workflowConfig.map((config, index) => {
    return (
      <Fragment key={`${workflowType}${index}`}>
        <WorkflowLevelCard
          workflowConfig={config}
          updateWorkflowConfig={(workflowLevelConfig) => {
            handleWorkflowLevelChange({ workflowLevelConfig, level: index + 1 });
          }}
          level={index + 1}
          showCloseIcon={index !== 0}
          showEditButton={showEditControls}
          onCloseIconClick={() => {
            handleWorkflowCardCloseIconClick({ level: index + 1 });
          }}
          onEditButtonClick={() => {
            handleWorkflowCardEditButtonClick({ level: index + 1 });
          }}
          onSaveButtonClick={() => {
            handleWorkflowCardSaveButtonClick({ level: index + 1 });
          }}
        />
        {dividerContent}
      </Fragment>
    );
  });

  const handleAddNextLevelClick = () => {
    const updatedWorkflowConfig: WorkflowLevelConfig[] = [
      ...workflowConfig,
      {
        approvers: [],
        minApprovals: 1,
        isSaved: false,
      },
    ];
    updateWorkflowConfig(updatedWorkflowConfig);
  };

  const nextLevelWorkflowContent = (
    <>
      <Button
        variant="secondary"
        icon={PlusIcon}
        iconPosition="left"
        onClick={handleAddNextLevelClick}
        isDisabled={isAddNextLevelButtonDisabled}>
        Add Level {nextLevel} Approvers
      </Button>
      {dividerContent}
    </>
  );

  return (
    <Box
      padding={['72px', '0px']}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="spacing.2">
      {firstStepContent}
      {dividerContent}
      {workflowCardsContent}
      {showAddNextLevelButton && nextLevelWorkflowContent}
      {lastStepContent}
    </Box>
  );
};

export { WorkflowLevelsChain };
