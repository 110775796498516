import React from "react";
import styles from "./index.module.scss";
import {Input} from "../../../../ui/Form/Input";
import {getStaticMediaUrl} from "../../../../../utils/Urls";
import closeIcon from "../../../../../assets/icon--close-blue.svg";
import {StandardButton} from "../../../../ui/Button";
import searchIcon from "../../../../../assets/icon--search-blue.svg";
import {Option, Select} from "../../../../ui/Form/Select";
import {accountCreationStatuses, accountCreationStatusesType} from "../../constants";

interface FilterProps {
    searchPhrase: string;
    accountCreationStatusFilter: string;
    onSearchPhraseChange: (value: string) => void;
    onAccountCreationStatusFilterChange: (value: string) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled: boolean
}

const JibbleEmployeeDataFilters = ({
    searchPhrase,
    accountCreationStatusFilter,
    onSearchPhraseChange,
    onAccountCreationStatusFilterChange,
    onSubmit,
    disabled
}: FilterProps) => {
    return (
        <form className={styles['jibbleEmployeesSearchFilters']}>
            <div className={styles['jibbleEmployeesDataSearchFilter']}>
                <Input
                    type="search"
                    name="search"
                    placeholder="Search by employee name or email"
                    value={searchPhrase}
                    disabled={disabled}
                    onChange={(event) => onSearchPhraseChange(event.target.value)}
                />
                {searchPhrase !== '' && (
                    <img
                        className={styles['jibbleEmployeesSearchClear']}
                        src={getStaticMediaUrl(closeIcon)}
                        alt=""
                        onClick={() => onSearchPhraseChange('')}
                    />
                )}
                <StandardButton
                    onClick={(event) => onSubmit(event)}
                    disabled={disabled}
                    className={styles['jibbleEmployeesDataSearch']}>
                    <img width="30px" src={getStaticMediaUrl(searchIcon)} alt="Search" />
                </StandardButton>
            </div>
            <Select
                className={styles['jibbleEmployeeAccountCreationStatusFilter']}
                id="account-creation-status-filter"
                name="account-creation-status"
                value={accountCreationStatusFilter}
                onChange={(e) => {
                    onAccountCreationStatusFilterChange(e.target.value)
                }}
            >
                {Object.keys(accountCreationStatuses).map((option) => {
                    const status = accountCreationStatuses as accountCreationStatusesType;
                    return (<Option
                        key={status[option].label}
                        value={option}
                    >
                        {status[option].label}
                    </Option>)
                })}
            </Select>
        </form>
    )
}

export default JibbleEmployeeDataFilters;