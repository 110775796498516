import { BadgeProps } from '@razorpay/blade/components';
import styled from 'styled-components';

export const EventTimestampWrapper = styled.div<{ borderColor?: BadgeProps['color'] }>(
  ({ theme, borderColor }) => {
    return `
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing[1]}px;
        padding-left: ${theme.spacing[3]}px;
        padding-right: ${theme.spacing[3]}px;
        border-left: ${
          borderColor
            ? `${theme.border.width.thicker}px solid ${theme.colors.interactive.border[borderColor].default}`
            : 'none'
        };
    `;
  },
);

export const StyledPayrollProgressWrapper = styled.div(
  ({ theme }) => `
        background-color: ${theme.colors.interactive.background.gray.faded};
        border: ${theme.border.width.thinner}px solid ${theme.colors.surface.border.gray.muted};
        border-radius: ${theme.border.radius.medium}px;
        padding: ${theme.spacing[5]}px 0px 0px;
        overflow: hidden;
        margin-bottom: ${theme.spacing[7]}px;
    `,
);

export const StyledActionSummaryWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.surface.background.gray.intense};
    padding: ${theme.spacing[5]}px;
    flex: 1;
  `,
);

export const ResetLocalOverridingStyles = styled.div`
  button {
    height: unset;
  }
`;
