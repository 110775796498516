import {
  Box,
  Button,
  EditIcon,
  Link,
  PettyCashBudgetIcon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
  TrashIcon
} from '@razorpay/blade/components';
import React from 'react';
import ComponentContainer from '../components/generics/ComponentContainer';
import ComponentHeader from '../components/generics/ComponentHeader';
import ComponentSection from '../components/generics/ComponentSection';
import ComponentSectionItem from '../components/generics/ComponentSectionItem';

const RecurringDeductionsView = () => {
  return (
    <ComponentContainer>
      <ComponentSection>
        <ComponentHeader
          icon={<PettyCashBudgetIcon size="medium" color="surface.icon.primary.normal" />}
          iconBackground={'surface.background.primary.subtle'}
          title={'Recurring deductions'}
          description={'Monthly deductions which are not part of CTC'}
          button={
            <Button variant="tertiary" color="white" marginLeft={'auto'}>
              + New Deduction
            </Button>
          }
        />
        <ComponentSectionItem>
          <Box borderRadius={'large'} overflow={'hidden'}>
            <Table
              data={{
                nodes: [
                  {
                    id: '1',
                    component: 'Hotel Accommodation',
                    monthlyAmount: '₹ 3,000',
                  },
                  {
                    id: '2',
                    component: 'Monthly Cab',
                    monthlyAmount: '₹ 4,000',
                  },
                ],
              }}
              backgroundColor="transparent">
              {(data) => (
                <>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderCell>
                        <Text weight="semibold">Component</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Monthly</Text>
                      </TableHeaderCell>
                      <TableHeaderCell textAlign="right">
                        <Text weight="semibold">Action</Text>
                      </TableHeaderCell>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} item={row}>
                        <TableCell>
                          <Text>{row.component}</Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          <Text>{row.monthlyAmount}</Text>
                        </TableCell>
                        <TableCell textAlign="right">
                          <Box display="flex" gap="spacing.5">
                            <Link icon={EditIcon} onClick={() => {}} />
                            <Link icon={TrashIcon} onClick={() => {}} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </Box>
        </ComponentSectionItem>
      </ComponentSection>
    </ComponentContainer>
  );
};

export default RecurringDeductionsView;
