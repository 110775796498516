import React from "react";
import Modal from 'components/ui/Modals/components/Modal';
import styles from "./index.module.scss";
import {getStaticMediaUrl} from "../../../../../../utils/Urls";
import successImage from "assets/image-success.png";
import {StandardButton} from "../../../../../ui/Button";

const LopAdditionSuccessModal = ({
    onCloseModal,
    payrollMonth
}: {
    onCloseModal: () => void,
    payrollMonth: string
}) => {
    return (
        <Modal>
            <div className={styles['successModal']}>
                <img src={getStaticMediaUrl(successImage)} alt={'Success'} className={styles['modalImage']}/>
                <h1 className={styles['successModalHeader']}>
                    Loss of pay deductions added successfully
                </h1>
                <div className={styles['actions']}>
                    <StandardButton
                        className={styles['confirm']}
                        onClick={() => {
                            window.location.href = '/run-payroll?payroll_month=' + payrollMonth;
                        }}
                    >
                        Go To Run Payroll Page
                    </StandardButton>
                    <StandardButton
                        className={styles['cancel']}
                        onClick={onCloseModal}
                    >
                       Cancel
                    </StandardButton>
                </div>
            </div>
        </Modal>
    )
}

export default LopAdditionSuccessModal;